import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom';
import Layout from '../../components/Layout/Layout'
import { BackButton } from '../../components/shared/Buttons'
import { fetchCalculators, fetchCalculatorCategories } from '../../redux/slices/calculatorSlice'
import { fetchCalculatorsData } from '../../redux/slices/optionsSlice'
import Loading from '../../components/shared/Loading/Loading'
import CalculatorCard from '../../components/Cards/CalculatorCard'
import { FormSelect } from "../../components/shared/Form"
import isArray from 'lodash/isArray'
import { fetchTourStatus } from '../../redux/slices/additionalSlice'
import { isNull } from 'lodash';


const steps = [
  {
    target: '.step-calculators',
    content: <>
      <h6>Our calculators</h6>
      There are a ton of online calculators, but how do you know you can trust them when they’re sitting on a website that’s trying to sell you a mortgage or a car loan? ValueSoft’s online calculators are accurate, no strings attached!
    </>,
    disableBeacon: true,
  },
  {
    target: '.step-calculators-filters',
    content: <>
      <h6>Filters</h6>
      Select a category of calculators to make it easy to find the calculators that matter most for you.
    </>,
  },
  {
    target: '.calculators > .grid > div:first-child > .calculator-card',
    content: <>
      <h6>How to use</h6>
      Run scenarios within each calculator and as you do that, you'll learn how to dial up and down certain variables to make the financial outcome optimal for you.
    </>,
    placement: 'left'
  },
  {
    target: '.calculators > .grid > div:first-child > .calculator-card .step-info',
    content: <>
      <h6>More information</h6>
      Learn more about each calculator by clicking on the question mark icons.
    </>,
  },
]

const Calculators = ({ props }) => {
  const dispatch = useDispatch()
  const { id } = useSelector(state => state.user.user)
  const { loading, calculators } = useSelector(state => state.calculator)
  const categoriesList = useSelector(state => state.calculator.calculatorCategories) 
  const { permissions } = useSelector(state => state.companies.company.info);
  const { tours } = useSelector(state => state.additional)
  const [calculatorsList, setCalculatorsList] = useState([])
  const [categories, setCategories] = useState([])
  const [categoryId, setCategoryId] = useState(0)
  const [activeItem, setActiveItem] = useState(0)

  useEffect(() => {
    async function fetchOption() {
      await dispatch(fetchCalculatorCategories())
      await dispatch(fetchCalculators(props))
      await dispatch(fetchTourStatus())
      await dispatch(fetchCalculatorsData())
    }

    if (id) {
      fetchOption()
      }
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    if(categoryId < 1){
      setCalculatorsList(calculators)
    } else {
      setCalculatorsList(calculators.filter(item => item.category.id === categoryId))
    }
    // eslint-disable-next-line
  }, [categoryId, calculators])

  useEffect(() => {
    if (categoriesList) {
      setCategories(categoriesList.map(item => ({...item, key: item.id, value: item.id, label: item.name})))
    }
    // eslint-disable-next-line
  }, [categoriesList])

  if (!isNull(permissions?.calculators) && !permissions?.calculators) return (<Redirect to="/404" />)

  return (
    <Layout
      decor
      steps={steps}
      tourStatus={tours && !tours.calculators}
      tourParam="calculators"
      titleTour="Take a Tour of Calculators "
    >
      <BackButton title="Overview" path="/dashboard" className="back-button--position" />
      <h4><span className="step-calculators">Online financial planning calculators</span></h4>
      <div className="filters">
        <div className="filters__title">
          Filter by:
        </div>
        <div className="filters__select step-calculators-filters">
          <FormSelect
            options={[
              {
                key: 10000,
                value: 0,
                label: 'All categories'
              },
              ...categories
            ]}
            id="category"
            value={categoryId}
            medium
            onChange={(e) => {
              setCategoryId(e.target.value)
            }}
          />
        </div>
      </div>

      <div className="calculators">
        {loading
          ? <Loading small/>
          : <>
              {calculatorsList && isArray(calculatorsList) && calculatorsList.length > 0 ?
                <div className="grid grid-3">
                  {calculatorsList.map(item =>
                    <div key={item.id}>
                      <CalculatorCard
                        name={item.name}
                        icon={item.icon}
                        path={item.path}
                        isActive={item.id === activeItem}
                        className="step-info"
                        setActiveItem={() => setActiveItem(item.id)}
                        tooltip={
                          <>
                            <h6>{item.name}</h6>
                            {item.tooltip}
                            <hr className="tooltip__line"/>
                            <h6>Example</h6>
                            {item.example}
                          </>
                        }
                      />
                    </div>
                  )}
                </div>
                : calculatorsList &&
                  <div className="grid grid-3">
                    <div key={calculatorsList.id}>
                      <CalculatorCard
                        name={calculatorsList.name}
                        icon={calculatorsList.icon}
                        path={calculatorsList.path}
                        className="step-info"
                        tooltip={
                          <>
                            <h6>{calculatorsList.name}</h6>
                            {calculatorsList.tooltip}
                            <hr className="tooltip__line"/>
                            <h6>Example</h6>
                            {calculatorsList.example}
                          </>
                        }
                      />
                    </div>
                  </div>
              }
            </>
        }
      </div>
    </Layout>
  );
};

export default Calculators
