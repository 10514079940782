import React from 'react'
import { Form, Formik } from 'formik'
import { Button } from '@mui/material'
import { FormGroup, Input, InputCurrency } from '../shared/Form'
import Percentage from '../../assets/images/icons/percentage.svg'

const HomeRefinancingAdmin = ({ title, onSave, values, sending }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{...values}}
      onSubmit={values => onSave(values)}
    >
      {({ handleChange, handleBlur, values }) => (
        <Form>
          <h4 className="form__main-title">{title}</h4>
          <div className="form__flow-wrap">
          <FormGroup name="loan_amount" labelText="Loan amount">
            <InputCurrency
              name="loan_amount"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.loan_amount || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="refinance_years" labelText="Refinance years">
            <Input
              name="refinance_years"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.refinance_years || 0}
            />
          </FormGroup>
          <FormGroup name="marginal_tax_rate" labelText="Marginal tax rate">
            <Input
              name="marginal_tax_rate"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              step={0.1}
              value={values.marginal_tax_rate || 0} 
              iconName={Percentage}
            />
          </FormGroup>
          <FormGroup name="closing_costs" labelText="Closing costs">
            <InputCurrency
              name="closing_costs"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.closing_costs || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="points" labelText="Points">
            <Input
              name="points"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              step={0.1}
              value={values.points || 0} 
              iconName={Percentage}
            />
          </FormGroup>
          <FormGroup name="current_interest_rate" labelText="Current interest rate">
            <Input
              name="current_interest_rate"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              step={0.1}
              value={values.current_interest_rate || 0} 
              iconName={Percentage}
            />
          </FormGroup>
          <FormGroup name="refinancing_interest_rate" labelText="Refinancing interest rate">
            <Input
              name="refinancing_interest_rate"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              step={0.1}
              value={values.refinancing_interest_rate || 0} 
              iconName={Percentage}
            />
          </FormGroup>
          </div>
          <div className="form__button">
            <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
              Save
              {sending && <span className="spinner" />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default HomeRefinancingAdmin
