import { createSlice } from '@reduxjs/toolkit'
import { Statistics } from '../../api/Api'

const statisticsSlice = createSlice({
  name: 'statistics',
  initialState: {
    isLoading: false,
    pushNotification: {},
    pushNotificationView: {},
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setPushNotification: (state, action) => {
      state.pushNotification = action.payload
    },
    setPushNotificationView: (state, action) => {
      state.pushNotificationView = action.payload
    }
  }
})

export const fetchPushNotificationStatistics =
  (companyId, params) => async (dispatch) => {
    try {
      await dispatch(setIsLoading(true));
      const response = await Statistics.getPushNotificationTask(
        companyId, params
      );
      await dispatch(setPushNotification(response.data));
      await dispatch(setIsLoading(false));
    } catch (e) {
      console.error(e);
      await dispatch(setIsLoading(false));
    }
  };

export const fetchPushNotificationViewStatistics =
  (companyId, id) => async (dispatch) => {
    try {
      await dispatch(setIsLoading(true));
      const response = await Statistics.showPushNotificationTask(companyId, id);
      await dispatch(setPushNotificationView(response.data.data));
      await dispatch(setIsLoading(false));
    } catch (e) {
      console.error(e);
      await dispatch(setIsLoading(false));
    }
  };

export const {
  setIsLoading,
  setPushNotification,
  setPushNotificationView,
} = statisticsSlice.actions

export default statisticsSlice.reducer