import React, { useState, useEffect } from 'react'
import LayoutAdmin from '../../components/Layout/LayoutAdmin'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, Redirect, useParams } from 'react-router-dom'
import { fetchCompany } from '../../redux/slices/companiesSlice';
import Tabs, { TabPanel } from '../../components/shared/Tabs/Tabs'
import AdminPostsOverview from '../../pages/AdminPanel/AdminPostsOverview'
import AdminEditCategory from '../../pages/AdminPanel/AdminEditCategory'
import AdminAuthorOverview from '../../pages/AdminPanel/AdminAuthorOverview'
import { setIsOpen } from '../../redux/slices/modalSlice'
import { Button } from '@mui/material'
import { isNull } from 'lodash'


const AdminBlogOverview = ({ props, isAdmin }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { role_id } = useSelector((state) => state.user.user);
  const { permissions, image, email, name } = useSelector(state => state.companies.company.info);
  const [value, setValue] = useState(0);
  const { companyId } = useParams();
  const [isMobile, setIsMobile] = useState(false);

  const mobileView = 999;

  const handleResize = () => {
    mobileView >= window.innerWidth ? setIsMobile(true) : setIsMobile(false);
  };

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []);

  const handleValue = (event, newValue) => {
    setValue(newValue);
  }

  useEffect(() => {
    dispatch(fetchCompany(companyId));
    // eslint-disable-next-line
  }, []);
  
  if (!isNull(permissions?.blog) && !permissions?.blog) return (<Redirect to="/404" />)

  return (
    <LayoutAdmin companyId={companyId} isAdmin={isAdmin}>
      <div className="member-comapny">
        <div className="member-comapny__info">
          <div className="member-comapny__logo">
            <img src={image} alt="Company logo" />
          </div>
          <div className="member-comapny__title">
            <h3>{name}</h3>
            {!isMobile && (
              <p>
                Feedback Email: <span>{email}</span>
              </p>
            )}
          </div>
        </div>
        {isMobile && (
          <div className="mobile-feedback">
            Feedback Email: <span>{email}</span>
          </div>
        )}
      </div>
      <div className="admin__title">
        <h4 className="mr-0">Blog</h4>
        {value === 0 && (
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => history.push(`/admin/company/${companyId}/blog/create-post`)}
          >
            Create post
          </Button>
        )}
        {(role_id === 4 ? value === null : value === 1) && (
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => dispatch(setIsOpen({ isOpen: true }))}
          >
            Create category
          </Button>
        )}
        {(role_id === 4 ? value === 1 : value === 2) && (
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => history.push(`/admin/company/${companyId}/authors/create-author`)}
          >
            Create author
          </Button>
        )}
      </div>
      <Tabs
        handleChange={handleValue}
        value={value}
        tabs={
          role_id === 4
            ? [{ title: 'Articles' }, { title: 'Authors' }]
            : [{ title: 'Articles' }, { title: 'Categories' }, { title: 'Authors' }]
        }
      />
      {role_id === 4 ? (
        <>
          <TabPanel value={value} index={0}>
            <AdminPostsOverview props={props} isAdmin={isAdmin} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <AdminAuthorOverview props={props} isAdmin={isAdmin} />
          </TabPanel>
        </>
      ) : (
        <>
          <TabPanel value={value} index={0}>
            <AdminPostsOverview props={props} isAdmin={isAdmin} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <AdminEditCategory props={props} isAdmin={isAdmin} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <AdminAuthorOverview props={props} isAdmin={isAdmin} />
          </TabPanel>
        </>
      )}
    </LayoutAdmin>
  );

}

export default AdminBlogOverview
