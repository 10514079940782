import React from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { Button } from '@mui/material'
import Verification from '../../components/Auth/Verification'
import { useDispatch, useSelector } from 'react-redux'
import { emailConfirm, phoneConfirm } from '../../redux/slices/authSlice'


const VerificationType = ({ props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const email = useSelector(state => state.auth.registerUser.email);
  const phone = useSelector(state => state.auth.registerUser.phone);
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

  const onSubmit = (verifyType) => {
    if(verifyType === 'email') {
      dispatch(emailConfirm({
        email: email
      }))
    }

    if(verifyType === 'phone') {
      dispatch(phoneConfirm({
        phone: phone
      }))
    }
    
    localStorage.setItem('verify_time', 60);
    history.push({
      pathname: '/verification-code',
      search: `?verify_type=${verifyType}`
    })
  };

  if (!isLoggedIn) return (<Redirect to="/login" />)
  
  return (
    <Verification title="How do you want to verify your account?">
      <div className="verification__content--buttons">
        <Button variant="contained" size="large" color="primary" onClick={() => onSubmit('email')}>
          Verify via Email
        </Button>
        <Button variant="outlined" size="large" color="primary" onClick={() => onSubmit('phone')}>
          Verify via Phone number
        </Button>
      </div>
    </Verification>
  )
};

export default VerificationType;
