import React, { useState } from 'react'
import { useDispatch, useSelector} from 'react-redux'
import * as Yup from 'yup'
import { Button } from '@mui/material'
import SVGIcon from '../shared/SVGIcon/SVGIcon'
import { Form, Formik } from 'formik'
import { FormGroup, Input } from '../../components/shared/Form'
import ModalBox from '../shared/Modal/Modal'
import DropboxIcon from '../../assets/images/dropbox.svg'
import { registerDropbox } from '../../redux/slices/userSlice'

const WidgetDropbox = ({ isActivate, className, client_id, site_url }) => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const { id } = useSelector(state => state.user.user);
  const sending = useSelector(state => state.sending.isSending)
  const error = useSelector(state => state.user.error)

  const close = () => setIsOpen(false)

  const onSubmit = (values) => {
    dispatch(registerDropbox(values))
  }

  const encoded_user_id = btoa(id)

  return (
   <div className={`widget widget--dropbox ${className || ''}`}>
     <div className="widget__title">
       <SVGIcon icon={DropboxIcon} className="widget__icon" />
       <div>
         <h6>{isActivate ? 'View your files on Dropbox' : 'Upload documents with Dropbox'}</h6>
         <a href="https://dropbox.com" target="_blank" rel="noopener noreferrer">dropbox.com</a>
       </div>
     </div>
     {isActivate
       ? <a
            className="MuiButtonBase-root MuiButton-root MuiButton-containedBlue MuiButton-sizeSmall text-center"
            href="https://www.dropbox.com/home"
            target="_blank"
            rel="noopener noreferrer"
         >
          View
         </a>
       : <Button
          variant="contained"
          size="small"
          className="MuiButton-containedBlue"
          href={`https://www.dropbox.com/oauth2/authorize?client_id=${client_id}&state=${encoded_user_id}&redirect_uri=${site_url}&response_type=code`}
         >
          Connect
         </Button>
     }

     <ModalBox
       small
       open={isOpen}
       close={close}
     >
       <div className="modal__title-icon">
         <SVGIcon icon={DropboxIcon} />
         <h4>{isActivate ? 'Congratulations! ' : 'Upload documents with Dropbox'}</h4>
       </div>
       {isActivate
          ? <div>
              <p>You have successfully connected the dropbox and now there is a folder structure for you in which you can store all your documents.</p>
            </div>
          : <Formik
              enableReinitialize
              initialValues={{dropbox_api_token: ''}}
              validationSchema={Yup.object().shape({
                dropbox_api_token: Yup.string().required('Required')
              })}
              onSubmit={onSubmit}
            >
            {({ errors, handleChange, handleBlur, values, touched }) => (
              <Form>
                <FormGroup name="dropbox_api_token">
                  <Input
                    name="dropbox_api_token"
                    placeholder="Type Dropbox API token"
                    type="text"
                    onChange={handleChange}
                    value={values.dropbox_api_token || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.dropbox_api_token || !!error}
                  />
                  {errors.dropbox_api_token && <span className="form__msg-error">{errors.dropbox_api_token}</span>}
                  {error && !errors.dropbox_api_token && <span className="form__msg-error">{error}</span>}
                </FormGroup>
                <div className="form__button">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Connect
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
       }
     </ModalBox>
   </div>
  );
};

export default WidgetDropbox;
