import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Formik } from 'formik'
import Layout from '../../../components/Layout/Layout'
import LayoutForm from '../../../components/Layout/LayoutForm'
import { BackButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import { FormGroup, FormSelect } from '../../../components/shared/Form'
import { Button, FormControlLabel, Radio, RadioGroup, TextareaAutosize } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import Illustration from '../../../assets/images/illustrations/Tree-segment_protection-min.png'
import { normalizeBoolean } from '../../../utils/utils'
import medicalCare from '../../../Data/medicalCare'
import { fetchInsuranceHealth, postInsuranceHealth } from '../../../redux/slices/insuranceSlice'
import { fetchPercentages } from '../../../redux/slices/percentagesSlice'
import Modal from '../../../components/shared/Modal/Modal'
import ProgressCircle from '../../../components/shared/ProgressBar/ProgressCircle'
import { setIsOpen } from '../../../redux/slices/modalSlice'
import { insuranceHealthSchema } from '../../../utils/validationProtection'

const InsuranceHealth = ({ props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.user.user);
  const insurance = useSelector(state => state.insurance.health);
  const sending = useSelector(state => state.sending.isSending);
  const percents = useSelector(state => state.percentages.percents);
  const isOpen = useSelector(state => state.modal.isOpen);

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchPercentages({name: 'health-insurance'}));
      await dispatch(fetchInsuranceHealth());
    }
    if (id) {
      fetch()
    }

    // eslint-disable-next-line
  }, [id])

  const onSubmit = (values) => {
    dispatch(postInsuranceHealth(values, props))
  }

  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }))
  }

  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }))
    history.push('/insurance')
  }

  return (
    <Layout>
      <LayoutForm illustration={Illustration} small>
        <BackButton title="Back to Basic Insurance" />
        <PageTitle
          title="Health insurance"
          content="Tell us about the details of your health, home, auto and umbrella insurance policies, so ValueSoft can ensure you have the appropriate coverage you require."
          status={percents && percents.percent ? percents.percent : '0'}
        />
        <div className="layout-form__content-holder">
          <Formik
            enableReinitialize
            initialValues={insurance}
            validationSchema={insuranceHealthSchema}
            onSubmit={onSubmit}
          >
            {({ errors, handleChange, handleBlur, values, touched, setFieldValue }) => (
              <Form>
                <FormGroup
                  name="health_insurance"
                  labelText="Is each person in your household covered by health insurance?"
                >
                  <RadioGroup
                    name="health_insurance"
                    value={values.health_insurance || false}
                    onChange={(e) => {
                      setFieldValue('health_insurance', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                </FormGroup>

                <FormGroup
                  name="dental_insurance"
                  labelText="Is each person in your household covered by dental insurance?"
                >
                  <RadioGroup
                    name="dental_insurance"
                    value={values.dental_insurance || false}
                    onChange={(e) => {
                      setFieldValue('dental_insurance', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                </FormGroup>

                <FormGroup
                  name="eye_insurance"
                  labelText="Is each person in your household covered by eyecare insurance?"
                >
                  <RadioGroup
                    name="eye_insurance"
                    value={values.eye_insurance || false}
                    onChange={(e) => {
                      setFieldValue('eye_insurance', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                </FormGroup>
                <FormGroup
                  name="medical_care"
                  labelText="Are you currently enrolled in Medicare and if so, what Part(s)?"
                  info={{
                    content: <>Part A is basic hospital insurance. Part B is basic medical insurance.  Part A and B are typically covered by your basic Medicare coverage at no cost. Part C is an add-on Medicare Advantage plan commonly referred to as private PPO or POS services. Part D is for prescription drug benefits and is also an additional cost.  Many Part C insurance providers include basic prescription drug benefits along with it.</>
                  }}
                >
                  <FormSelect
                    options={medicalCare}
                    id="medical_care"
                    value={values.medical_care || ''}
                    name="medical_care"
                    onChange={(e) => {
                      setFieldValue(e.target.name, e.target.value)
                    }}
                    placeholder="Select"
                    hasError={!!errors.medical_care}
                  />
                  {errors.medical_care && <span className="form__msg-error">{errors.medical_care}</span>}
                </FormGroup>
                <FormGroup
                  name="medical_care_spouse"
                  labelText="Is your spouse currently enrolled in Medicare and if so, what Part(s)? (optional)"
                >
                  <FormSelect
                    options={medicalCare}
                    id="medical_care_spouse"
                    value={values.medical_care_spouse || ''}
                    name="medical_care_spouse"
                    onChange={(e) => {
                      setFieldValue(e.target.name, e.target.value)
                    }}
                    placeholder="Select"
                  />
                </FormGroup>
                <h5 className="mt--medium">Additional info</h5>
                <FormGroup name="additional_info" labelText="Is there any other Basic Insurance information that you believe is important for ValueSoft to know about? If so, please let us know about it:">
                  <TextareaAutosize
                    maxLength="200"
                    name="additional_info"
                    value={values.additional_info || ''}
                    className="form__textarea"
                    placeholder="Maximum characters: 200"
                    onChange={handleChange}
                  />
                </FormGroup>

                <div className="form__button">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Save
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <Modal
          open={isOpen}
          small
          button="Done"
          onClick={handleClick}
          close={onClose}
        >
          <h3>Good Job!</h3>
          <p>Your progress increased 100%</p>
          <ProgressCircle amount={100} small />
        </Modal>
      </LayoutForm>
    </Layout>
  );
};

export default InsuranceHealth;
