import React from 'react'
import PropTypes from 'prop-types'
import { Select, MenuItem } from "@mui/material";
import CreatableSelect from 'react-select/creatable';
import clsx from 'classnames'

const Dropdown = ({
  id,
  options,
  placeholder,
  value,
  name,
  onChange,
  hasError,
  small,
  medium,
  autoWidth,
  style,
  isCreatable,
  setFieldValue,
  ...rest
}) => {
  const onCustomChange = option => {
    if (!option) setFieldValue(name, '');

    return option && setFieldValue(name, option.value)
  };

  const getValue = () => options.find(option => value === option.value)

  return (
    <>
      {isCreatable ? (
        <CreatableSelect
          placeholder={placeholder}
          classNamePrefix="react-select"
          className={clsx("react-select", {
            "react-select--small": small,
            "react-select--error": hasError,
          })}
          isClearable
          name={name}
          value={getValue()}
          defaultInputValue={typeof value === "string" ? value : ""}
          onChange={onCustomChange}
          options={options}
        />
      ) : (
        <Select
          style={style}
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          displayEmpty
          className={clsx('custom-select', {
            'custom-select--error': hasError,
            'custom-select--small': small,
            'custom-select--medium': medium,
            'custom-select--auto': autoWidth,
            'custom-select--color': value === ''
          })}
          {...rest}
        >
          {placeholder && (
            <MenuItem aria-label="None" value="" disabled>
              {placeholder}
            </MenuItem>
          )}
          {options &&
            options.map((item) => (
              <MenuItem key={item.key} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
        </Select>
      )}
    </>
  );
}

Dropdown.propTypes = {
  id: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  name: PropTypes.string,
  hasError: PropTypes.bool,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  isCreatable: PropTypes.bool,
}

export default Dropdown
