import React, {useEffect, useState} from 'react'
import { Redirect, Link, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'classnames'
import PropTypes from 'prop-types'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import SplashPlan from '../Splash/SplashPlan'
import { Button } from '@mui/material'
import { postPlanTypeUser, reSaveData, fetchDropboxStatus, showDropbox, setPlanPopupDelay } from '../../redux/slices/userSlice'
import { fetchNotifyRelogin, fetchNotifications, removeNotification } from '../../redux/slices/notificationsSlice'
import { fetchPercentages, fetchTotalPercent } from '../../redux/slices/percentagesSlice'
import { setIsRelogin } from '../../redux/slices/loadingSlice'
import Loading from '../../components/shared/Loading/Loading'
import Modal from '../../components/shared/Modal/Modal'
import ModalBox from '../../components/shared/Modal/Modal'
import { setNewTasksCount } from '../../redux/slices/taskSlice'
import { setPlayTour, postTourStatus } from '../../redux/slices/additionalSlice'
import SVGIcon from '../shared/SVGIcon/SVGIcon'
import Icon from '../../assets/images/icons/icon-ongratulations.svg'
import DropboxIcon from '../../assets/images/dropbox.svg'
import Tour from '../Tour/Tour'
import { setOpenMenu } from '../../redux/slices/additionalSlice'
import { setCompanyData } from "../../redux/slices/companiesSlice";
import notification from '../../assets/images/icons/bell.svg'

const currentDate = (new Date().getTime() / 1000).toFixed();

const Layout = ({
  children,
  decor,
  tree,
  profile,
  sticky,
  isAdmin,
  isSearch,
  isBlockedPage = false,
  steps,
  handlers,
  handlersOnMobile,
  customCloseTour,
  tourStatus,
  tourParam,
  titleTour,
  isDisabled,
  totalPercent
}) => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const sending = useSelector(state => state.sending.isSending)
  const { id, is_plan_completed, email_verified_at, is_new_year, first_name, last_name, email, is_dropbox_connect, dropbox_popup_show, plan_popup_show_delay } = useSelector(state => state.user.user)
  const { isLoading, isRelogin } = useSelector(state => state.loading)
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const isOpen = useSelector(state => state.modal.isOpen)
  const plan_types = useSelector(state => state.options.plan_types)
  const { notifications } = useSelector(state => state.notifications)
  const newTasksCount = useSelector(state => state.task.newTasksCount)
  const { playTour } = useSelector(state => state.additional)
  const [openModal, setOpenModal] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [tourModal, setTourModal] = useState(false)
  const [tourInfoModal, setTourInfoModal] = useState(false)
  const [isResetModal, setResetModal] = useState(false)
  const [animate, setAnimate] = useState(false)
  const isBasic = useSelector((state) => state.user.user.plan_type === 1);
  const isComprehensive = useSelector((state) => state.user.user.plan_type === 2);
  const [planSwitch, setPlanSwitch] = useState(false);
  const [notPlanSwitch, setNotPlanSwitch] = useState(false);
  const [isPlanSwitch, setIsPlanSwitch] = useState(false);
  const [isCheck, setCheck] = useState(false);
  const mobileView = 999

  const handleResize = () => {
    (mobileView >= window.innerWidth) ? setIsMobile(true) : setIsMobile(false)
  }
  
  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true)
    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    dispatch(
      setCompanyData({
        image: null,
      })
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const token = localStorage.token;
    async function hendleRelogin () {
      await dispatch(fetchNotifyRelogin())
    }

    if(id && !token){
      hendleRelogin()
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchDropboxStatus());
      await dispatch(fetchNotifications());
      if (is_plan_completed) {
        await setTourModal(tourStatus || false);
      }
    }

    if (id) {
      fetch()
    }

    // eslint-disable-next-line
  }, [id, tourStatus])

  useEffect(
    () => {
      const timeoutId = setTimeout(() => {setAnimate(false)}, 4000);
      return () => {
        clearTimeout(timeoutId)
      }
      // eslint-disable-next-line
    }, [animate])

  const hendleDropboxPopup = () => {
    dispatch(showDropbox({dropbox_popup_show: true}))
  }

  useEffect(() => {
    async function fetchTasks() {
      if (!!newTasksCount && !isOpen) {
        await setOpenModal(!!newTasksCount)
      }
    }

    if (id) {
      fetchTasks()
    }

    // eslint-disable-next-line
  }, [id, newTasksCount])

  useEffect(() => {
    if (
      plan_popup_show_delay &&
      +currentDate > +plan_popup_show_delay &&
      totalPercent === 100
    ) {
      setPlanSwitch(true);
    }
    
    // eslint-disable-next-line
  }, [totalPercent])

  const handlePlan = async () => {
    await dispatch(postPlanTypeUser({ plan_type: 2 }));
    await dispatch(fetchPercentages());
    setIsPlanSwitch(false);
  };

  const onClose = async () => {
    await dispatch(
      setPlanPopupDelay({
        popup_not_show: isCheck,
      })
    );
    setNotPlanSwitch(false);
  };

  const handleTasks = async () => {
    await dispatch(setPlanPopupDelay({
      'popup_not_show': isCheck
    }));
    setPlanSwitch(false);
    history.push('/tasks');
  }

  const onCheck = () => {
    setCheck(!isCheck);
  };

  const onModalClick = async () => {
    await dispatch(
      setPlanPopupDelay({
        popup_not_show: isCheck,
      })
    );
    setNotPlanSwitch(false);
  };

  const onClick = async (item) => {
    await dispatch(postPlanTypeUser({ plan_type: item }))
    if (tourStatus) {
      await setTourModal(true)
    }
  }

  const remove = async id => {
    await dispatch(removeNotification(id))
  }

  const closeTaskModal = () => {
    setOpenModal(false)
    dispatch(setNewTasksCount(0))
  }

  const startTour = async steps => {
    if (steps && steps.length > 0) {
      await setTourModal(false)
      if (isMobile && !handlersOnMobile) {
        await dispatch(setOpenMenu(false))
        await dispatch(setPlayTour(true))
      } else if (isMobile && tourParam === 'dashboard') {
        await dispatch(setOpenMenu(true))
        await setTimeout(() => dispatch(setPlayTour(true)), 400)
      } else {
        await dispatch(setPlayTour(true))
      }
    } else {
      history.push('/dashboard')
      dispatch(setPlayTour(true))
    }
  }

  const stopTour = () => {
    dispatch(setPlayTour(false))
    dispatch(postTourStatus({[tourParam]: true}))
  }

  const closeTour = () => {
    setAnimate(true)
    setTourModal(false)
    dispatch(setPlayTour(false))
    dispatch(postTourStatus({[tourParam]: true}))

    if(customCloseTour) {
      customCloseTour()
    }
  }

  const closeTourAlternative = () => {
    setTourModal(false)
    setTourInfoModal(true)
    dispatch(setPlayTour(false))
    dispatch(postTourStatus({[tourParam]: true}))
    if(customCloseTour) {
      customCloseTour()
    }
  }

  const handleClick = async () => {
    await(dispatch(setIsRelogin({ isRelogin: false })));
    await dispatch(() => history.push('/login'));
  }

  const handleResave = async () => {
    await dispatch(reSaveData());
    await dispatch(fetchPercentages());
    await dispatch(fetchTotalPercent(isBasic))
  }

  if (!isLoggedIn && !localStorage.token) return (<Redirect to={`/login${location.search}`} />)

  if (!email_verified_at && id) return (<Redirect to="/signup-confirm" />)

  if (isBlockedPage) return (<Redirect to="/404" />)

  return (
    <>
      {id && !is_plan_completed ? (
        <SplashPlan onClick={onClick} items={plan_types} sending={sending} />
      ) : (
        <div
          className={clsx("layout-main", {
            "layout-main--decor": decor,
            "layout-main--tree": tree,
            "layout-main--decor layout-main--decor-profile": profile,
            "layout-main--sticky": sticky,
            "layout-main--search": isSearch,
            "layout-main--event-none": playTour,
            "layout-main--disabled": isDisabled,
          })}
        >
          <Header
            isAdmin={isAdmin}
            isMobile={isMobile}
            notifications={notifications}
            removeNotification={remove}
            runTour={() => setTourModal(true)}
            animate={animate}
          />
          {is_new_year && (
            <div className="alert alert--expand">
              <div className="alert__icon">
                <SVGIcon icon={notification} color="#ffffff" />
              </div>
              <div className="alert__content">
                <div>
                  You need to review all your information at the beginning of
                  the year to ensure your information is up to date. Or you can
                  approve your current input by re-saving your information from
                  last year.
                </div>
                <Button
                  variant="outlined"
                  onClick={() => setResetModal(true)}
                  size="small"
                  className="invert"
                >
                  Re-save all data
                </Button>
              </div>
            </div>
          )}
          <div className="layout-main__content">
            {isLoading || isRelogin ? <Loading /> : <>{children}</>}
          </div>
          <Footer user={{ first_name, last_name, email }} />
        </div>
      )}
      <Tour
        steps={steps}
        isRun={playTour}
        stop={stopTour}
        isMobile={isMobile}
        handlers={handlers}
        handlersOnMobile={handlersOnMobile}
      />
      <Modal open={openModal} small close={() => closeTaskModal()}>
        <div className="modal__title-icon">
          <SVGIcon icon={Icon} />
        </div>
        <h3>Congratulations!</h3>
        You filled out the step and{" "}
        <Link
          to="/tasks"
          className="text-success"
          onClick={() => closeTaskModal()}
        >
          <strong>{newTasksCount}</strong>
        </Link>{" "}
        new tasks were created.
      </Modal>
      <Modal
        open={tourModal}
        small
        close={
          isMobile && tourParam === "dashboard" && tourStatus
            ? closeTourAlternative
            : closeTour
        }
        button="Start the tour"
        closeButton="Later"
        onClick={() => startTour(steps)}
      >
        <div className="modal__title-icon">
          <SVGIcon icon={Icon} />
        </div>
        <h3>{titleTour ? titleTour : "Take a tour of ValueSoft"}</h3>
      </Modal>
      <Modal
        open={tourInfoModal}
        small
        close={() => setTourInfoModal(false)}
        button="Ok"
        onClick={() => setTourInfoModal(false)}
      >
        <div className="modal__title-icon">
          <SVGIcon icon={Icon} />
        </div>
        <h3>Check the Guide later</h3>
        <p>
          If you need to see the guide again, click on the top left menu icon
          and press on “Getting Started” at the bottom of the menu options.
          There is a user guide for every unique page so be sure to use it along
          the way.
        </p>
      </Modal>

      <Modal
        open={is_dropbox_connect && !dropbox_popup_show}
        disableEnforceFocus={true}
        onMouseDown={(event) => event.stopPropagation()}
        small
        close={(event) => {
          event.stopPropagation();
          hendleDropboxPopup();
        }}
        button="Ok"
        onClick={(event) => {
          event.stopPropagation();
          hendleDropboxPopup();
        }}
      >
        <div className="modal__title-icon">
          <SVGIcon icon={DropboxIcon} />
        </div>
        <h3>Congratulations!</h3>
        <p>
          You’ve now connected to Dropbox. ValueSoft has created a file
          directory under your main Dropbox directory called “ValueSoft”.  It
          has a bunch of underlying folders where you should store all your
          important financial planning documentation.
        </p>
      </Modal>

      <Modal
        open={isResetModal}
        small
        close={() => setResetModal(false)}
        button={is_new_year ? "Approve" : "Close"}
        closeButton={is_new_year ? "Cancel" : ""}
        onClick={is_new_year ? handleResave : () => setResetModal(false)}
        title={is_new_year ? "Re-save your data" : "Thank you!"}
      >
        {sending ? (
          <Loading xsmall />
        ) : is_new_year ? (
          <p>
            After you re-save your data, all information from last year will be
            saved to the current year.
          </p>
        ) : (
          <p>
            You have successfully updated all your information and now all your
            data is up to date for this year.
          </p>
        )}
      </Modal>
      <Modal
        open={isRelogin}
        small
        close={handleClick}
        button="Ok"
        onClick={handleClick}
      >
        <h3>You’ve been signed out due to inactivity!</h3>
        <p>Please sign back in to continue using the site.</p>
      </Modal>

      <ModalBox
        small
        largeButtons
        open={planSwitch && isBasic}
        remove={() => {
          setPlanSwitch(false);
          setIsPlanSwitch(true);
        }}
        close={() => {
          setPlanSwitch(false);
          setNotPlanSwitch(true);
        }}
        onClick={handleTasks}
        removeButton="Switch to Comprehensive Plan"
        button="Proceed with tasks"
        title="Congratulations!"
        children={
          <p className="modal__content--text-grey-sm">
            You have completed all the input in the Basic Plan. Now that you
            have completed your Basic input, would you like to switch to a
            Comprehensive Input Plan? Remember, a Сomprehensive Plan will
            require additional input but it will also provide you additional
            insights into your financial health.
          </p>
        }
      />
      <ModalBox
        small
        largeButtons
        open={planSwitch && isComprehensive}
        close={() => {
          setPlanSwitch(false);
          setNotPlanSwitch(true);
        }}
        onClick={handleTasks}
        button="Proceed with tasks"
        title="Congratulations!"
        children={
          <p className="modal__content--text-grey-sm">
            You have completed all your input. ValueSoft has created an action
            plan for you — in the form of tasks — based on your input, to help
            empower you towards better financial health.
          </p>
        }
      />
      <ModalBox
        small
        largeButtons
        checkbox
        open={notPlanSwitch}
        close={onClose}
        check={onCheck}
        isChecked={isCheck}
        onClick={onModalClick}
        button="Confirm"
        title="Confirmation"
        children={
          <p className="modal__content--text-grey-sm">
            You have opted to continue with the Basic Plan. We will remind you
            again in the two weeks if you'd like to switch to a Comprehensive
            Plan unless you check the box below to turn off this reminder.
          </p>
        }
      />
      <ModalBox
        small
        open={isPlanSwitch}
        close={() => {
          setIsPlanSwitch(false);
          setNotPlanSwitch(true);
        }}
        onClick={handlePlan}
        closeButton="Cancel"
        button="Confirm"
        title="Confirmation"
        children={
          <p className="modal__content--text-grey-sm">
            This will require additional input but a Comprehensive Plan is where
            you'll learn the most and fully capture the value of ValueSoft.
          </p>
        }
      />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
  decor: PropTypes.bool,
  tree: PropTypes.bool,
  sticky: PropTypes.bool,
  isBlockedPage: PropTypes.bool,
  isDisabled:  PropTypes.bool,
};

export default Layout
