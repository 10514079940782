import React from 'react'
import PropTypes from 'prop-types'
import Priority from '../shared/Priority/Priority'
import Icon from '../../assets/images/icons/drop.svg'
import SVGIcon from '../shared/SVGIcon/SVGIcon'

const TaskCardSimple = ({
  title,
  status,
  category,
  priority,
  content,
  icon,
}) => {
  return (
    <>
      <div className="task-card task-card--simple">
        <div className="task-card__inner">
          <div className="task-card__top">
            <div className="task-card__status task-card__status--new">
              {status}
            </div>
          </div>
          <div className="task-card__holder">
            <div className="task-card__title">
              <div className="task-card__image">
                {icon && <img src={icon} alt={title} />}
              </div>
              <h5>{title}</h5>
            </div>
            <div className="task-card__info">
              {category && (
                <div className="task-card__category">{category}</div>
              )}
              {priority && (
                <div className="task-card__priority">
                  <Priority option={priority} />
                  <div className="task-card__priority-text">
                    <SVGIcon icon={Icon} color="#0F9AFF" />
                    {priority}
                  </div>
                </div>
              )}
            </div>
            <div
              className="task-card__content"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

TaskCardSimple.propTypes = {
  title: PropTypes.string,
  status: PropTypes.string,
  category: PropTypes.string,
  priority: PropTypes.string,
  content: PropTypes.string,
  icon: PropTypes.string
}

export default TaskCardSimple



