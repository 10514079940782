import React from 'react'
import { Form, Formik } from 'formik'
import { Button } from '@mui/material'
import { FormGroup, Input } from '../shared/Form'

const ValueCircleAdmin = ({ title, onSave, values, sending }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{...values}}
      onSubmit={values => onSave(values)}
    >
      {({ handleChange, handleBlur, values }) => (
        <Form>
          <h4 className="form__main-title">{title}</h4>
          <h6>Your Current ranking</h6>
          <div className="form__flow-wrap">
          <FormGroup name="security" labelText="Security">
            <Input
              name="security"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.security || 0}
            />
          </FormGroup>
          <FormGroup name="obligation" labelText="Obligation">
            <Input
              name="obligation"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.obligation || 0}
            />
          </FormGroup>
          <FormGroup name="power" labelText="Power/Prestige">
            <Input
              name="power"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.power || 0}
            />
          </FormGroup>
          <FormGroup name="happiness" labelText="Happiness">
            <Input
              name="happiness"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.happiness || 0}
            />
          </FormGroup>
          <FormGroup name="health" labelText="Health">
            <Input
              name="health"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.health || 0}
            />
          </FormGroup>
          <FormGroup name="making_difference" labelText="Making a difference">
            <Input
              name="making_difference"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.making_difference || 0}
            />
          </FormGroup>
          </div>
          <h6>Your Preferred ranking</h6>
          <div className="form__flow-wrap">
          <FormGroup name="preferred_security" labelText="Security">
            <Input
              name="preferred_security"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.preferred_security || 0}
            />
          </FormGroup>
          <FormGroup name="preferred_obligation" labelText="Obligation">
            <Input
              name="preferred_obligation"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.preferred_obligation || 0}
            />
          </FormGroup>
          <FormGroup name="preferred_power" labelText="Power/Prestige">
            <Input
              name="preferred_power"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.preferred_power || 0}
            />
          </FormGroup>
          <FormGroup name="preferred_happiness" labelText="Happiness">
            <Input
              name="preferred_happiness"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.preferred_happiness || 0}
            />
          </FormGroup>
          <FormGroup name="preferred_health" labelText="Health">
            <Input
              name="preferred_health"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.preferred_health || 0}
            />
          </FormGroup>
          <FormGroup name="preferred_making_difference" labelText="Making a difference">
            <Input
              name="preferred_making_difference"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.preferred_making_difference || 0}
            />
          </FormGroup>
          </div>
          <h6>Spouse Current ranking</h6>
          <div className="form__flow-wrap">
          <FormGroup name="spouse_security" labelText="Security">
            <Input
              name="spouse_security"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.spouse_security || 0}
            />
          </FormGroup>
          <FormGroup name="spouse_obligation" labelText="Obligation">
            <Input
              name="spouse_obligation"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.spouse_obligation || 0}
            />
          </FormGroup>
          <FormGroup name="spouse_power" labelText="Power/Prestige">
            <Input
              name="spouse_power"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.spouse_power || 0}
            />
          </FormGroup>
          <FormGroup name="spouse_happiness" labelText="Happiness">
            <Input
              name="spouse_happiness"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.spouse_happiness || 0}
            />
          </FormGroup>
          <FormGroup name="spouse_health" labelText="Health">
            <Input
              name="spouse_health"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.spouse_health || 0}
            />
          </FormGroup>
          <FormGroup name="spouse_making_difference" labelText="Making a difference">
            <Input
              name="spouse_making_difference"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.spouse_making_difference || 0}
            />
          </FormGroup>
          </div>
          <h6>Spouse Preferred ranking</h6>
          <div className="form__flow-wrap">
          <FormGroup name="spouse_preferred_security" labelText="Security">
            <Input
              name="spouse_preferred_security"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.spouse_preferred_security || 0}
            />
          </FormGroup>
          <FormGroup name="spouse_preferred_obligation" labelText="Obligation">
            <Input
              name="spouse_preferred_obligation"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.spouse_preferred_obligation || 0}
            />
          </FormGroup>
          <FormGroup name="spouse_preferred_power" labelText="Power/Prestige">
            <Input
              name="spouse_preferred_power"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.spouse_preferred_power || 0}
            />
          </FormGroup>
          <FormGroup name="spouse_preferred_happiness" labelText="Happiness">
            <Input
              name="spouse_preferred_happiness"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.spouse_preferred_happiness || 0}
            />
          </FormGroup>
          <FormGroup name="spouse_preferred_health" labelText="Health">
            <Input
              name="spouse_preferred_health"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.spouse_preferred_health || 0}
            />
          </FormGroup>
          <FormGroup name="spouse_preferred_making_difference" labelText="Making a difference">
            <Input
              name="spouse_preferred_making_difference"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.spouse_preferred_making_difference || 0}
            />
          </FormGroup>
          </div>
          <div className="form__button">
            <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
              Save
              {sending && <span className="spinner" />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ValueCircleAdmin
