import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../../components/Layout/Layout'
import { BackButton } from '../../components/shared/Buttons'
import Tooltip from '../../components/shared/Tooltip/Tooltip'
import { Slider, Input, FormGroup, FormSelect, InputCurrency } from '../../components/shared/Form'
import PercentIcon from '../../assets/images/icons/percentage.svg'
import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import ModalBox from '../../components/shared/Modal/Modal'
import {
  fetchCalculators,
  fetchListResults,
  fetchCurrentResult,
  saveItemResult,
  removeItemResult, setCurrentCalculator
} from '../../redux/slices/calculatorSlice'
import { Bar } from 'react-chartjs-2'
import { optionsCalculator } from '../../utils/optionsBar'
import Tabs, { TabPanel } from '../../components/shared/Tabs/Tabs'
import { normalizeBoolean, convertDecimal } from '../../utils/utils'
import { fetchLifeInsuranceAdmin } from '../../redux/slices/calculatorsSetupSlice'

const baseValuesForAmount = {
  age: [0, 35, 40, 45, 50, 55, 60],
  maleNo: [0.060, 0.076, 0.092, 0.165, 0.239, 0.439, 0.639],
  maleYes: [0.131, 0.242, 0.353, 0.545, 0.738, 1.315, 1.891],
  femaleNo: [0.055, 0.067, 0.079, 0.130, 0.181, 0.312, 0.443],
  femaleYes: [0.116, 0.187, 0.258, 0.398, 0.539, 0.883, 1.228]
}

const baseDataForPercent = {
  faceValue: [0, 200000, 300000, 400000, 500000, 600000, 700000, 800000, 900000, 900001],
  monthlyCostNonSmoking: [0.128, 0.079, 0.071, 0.068, 0.065, 0.064, 0.063, 0.063, 0.062, 0.060],
  monthlyCostSmoking: [0,328, 0.308, 0.262, 0.259, 0.250, 0.249, 0.248, 0.247, 0.246, 0.246]
}

const LifeInsurance = ({ props }) => {
  const dispatch = useDispatch()
  const [tabActive, setActiveTab] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [isSavedModal, setIsSavedModal] = useState(false)
  const { id, company_id } = useSelector(state => state.user.user)
  const { calculators, current, listExisting, loading } = useSelector(state => state.calculator)
  const baseValues = useSelector(state => state.calculatorsSetup.lifeInsuranceAdmin);
  const [currentId, setCurrentId] = useState('')
  const [values, setValues] = useState(null)

  useEffect(() => {
    if(baseValues){
      setValues({
        currentId: '',
        name: '',
        ...baseValues
      })
    }
  // eslint-disable-next-line
  }, [baseValues])

  const [countValues, setCountValues] = useState({
    totalForYou: 0,
    totalForSpouse: 0,
    totalForChildren: 0,
    totalMonthly: 0
  })

  const data = {
    labels: [`$ ${countValues.totalForYou}`, `$ ${countValues.totalForSpouse}`, `$ ${countValues.totalForChildren}`],
    datasets: [
      {
        data: [countValues.totalForYou, countValues.totalForSpouse, countValues.totalForChildren],
        backgroundColor: ['#215E59', '#D4654D', '#FDB5A1'],
        maxBarThickness: 114
      }
    ],
  }

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchLifeInsuranceAdmin(company_id))
      await dispatch(fetchCalculators({
        path: 'life-insurance'
      }));
      await dispatch(fetchListResults('life-insurance'))
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const findIndex = (array, param)  => {
    let index = 0

    array.map((item, i) => {
      if (item <= param) {
        index = i
      }
      return false
    })
    return index
  }

  useEffect(() => {
  if(values) {
    let totalForYou = ((+values.monthly_household_expenses * (1 - +values.total_expense_attributable / 100) * 12 * +values.years_cover_expenses) + +values.funeral_costs + +values.estate_expenses + +values.current_outstanding_liability + (+values.pass_work * +values.spouse_take_home_income) - (+values.spouse_take_home_income * 12 * +values.years_cover_expenses) - +values.social_security_survivors_benefit - +values.current_total_assets - +values.current_life_insurance) <= 0
                      ? 0
                      : ((+values.monthly_household_expenses * (1 - +values.total_expense_attributable / 100) * 12 * +values.years_cover_expenses) + +values.funeral_costs + +values.estate_expenses + +values.current_outstanding_liability + (+values.pass_work * +values.spouse_take_home_income) - (+values.spouse_take_home_income * 12 * +values.years_cover_expenses) - +values.social_security_survivors_benefit - +values.current_total_assets - +values.current_life_insurance)
    let totalForSpouse   = (+values.spouse_pass_work * +values.take_home_monthly_income) + (+values.spouse_years_cover * (12 * +values.spouse_take_home_income)) - +values.spouse_current_life_insurance <=0
                            ? 0
                            : (+values.spouse_pass_work * +values.take_home_monthly_income) + (+values.spouse_years_cover * (12 * +values.spouse_take_home_income))- +values.spouse_current_life_insurance
    let totalForChildren = +values.number_of_children <= 0
                              ? 0
                              : (+values.child_pass_work * +values.take_home_monthly_income * +values.number_of_children) + (+values.child_pass_work_spouse * +values.spouse_take_home_income * +values.number_of_children) - (+values.child_current_life_insurance * +values.number_of_children)<=0
                                ? 0
                                : (+values.child_pass_work * +values.take_home_monthly_income * +values.number_of_children) + (+values.child_pass_work_spouse * +values.spouse_take_home_income * +values.number_of_children) - (+values.child_current_life_insurance * +values.number_of_children)


    let totalAdditionalNeeded = totalForYou + totalForSpouse + totalForChildren


    let indexForBaseAmount     = findIndex(baseValuesForAmount.age, +values.your_age)
    let indexForPremium        = findIndex(baseDataForPercent.faceValue, totalAdditionalNeeded)
    let baseAmount             = values.gender === 'male' && !values.is_smoke
                                  ? baseValuesForAmount.maleNo[indexForBaseAmount]
                                  : values.gender === 'male' && values.is_smoke
                                    ? baseValuesForAmount.maleYes[indexForBaseAmount]
                                    : values.gender === 'female' && !values.is_smoke
                                      ? baseValuesForAmount.femaleNo[indexForBaseAmount]
                                      : baseValuesForAmount.femaleYes[indexForBaseAmount]

    let percentPremium         = values.is_smoke
                                  ? (baseDataForPercent.monthlyCostSmoking[indexForPremium] - baseDataForPercent.monthlyCostSmoking[5])/baseDataForPercent.monthlyCostSmoking[5]
                                  : (baseDataForPercent.monthlyCostNonSmoking[indexForPremium] - baseDataForPercent.monthlyCostNonSmoking[5])/baseDataForPercent.monthlyCostNonSmoking[5]

    let totalMonthly = (totalAdditionalNeeded/1000)*((baseAmount*(1+percentPremium)).toFixed(5))
    setCountValues({
      totalForYou: +totalForYou.toFixed(2),
      totalForSpouse: +totalForSpouse.toFixed(2),
      totalForChildren: +totalForChildren.toFixed(2),
      totalMonthly: totalMonthly.toFixed(2)
    })
  }
    // eslint-disable-next-line
  }, [values])

  useEffect(() => {
    async function fetchNewData() {
      if (current) {
        setValues({
          name: current ? current.name : '',
          currentId: currentId,
          your_age: +current.your_age,
          number_of_children: current.number_of_children,
          gender: current.gender,
          is_smoke: current.is_smoke,
          take_home_monthly_income: +current.take_home_monthly_income,
          spouse_take_home_income: +current.spouse_take_home_income,
          social_security_survivors_benefit: +current.social_security_survivors_benefit,
          current_total_assets: +current.current_total_assets,
          current_life_insurance: +current.current_life_insurance,
          spouse_current_life_insurance: +current.spouse_current_life_insurance,
          child_current_life_insurance: +current.child_current_life_insurance,
          monthly_household_expenses: +current.monthly_household_expenses,
          total_expense_attributable: +current.total_expense_attributable,
          years_cover_expenses: +current.years_cover_expenses,
          funeral_costs: +current.funeral_costs,
          estate_expenses: +current.estate_expenses,
          current_outstanding_liability: +current.current_outstanding_liability,
          spouse_pass_work: +current.spouse_pass_work,
          spouse_years_cover: +current.spouse_years_cover,
          pass_work: +current.pass_work,
          child_pass_work: +current.child_pass_work,
          child_pass_work_spouse: +current.child_pass_work_spouse
        })
      }
    }

    if (currentId) {
      fetchNewData()
    }
    // eslint-disable-next-line
  }, [currentId, current])

  const handleInputChange = e => {
    setValues({
      ...values,
      [e.target.name]: +e.target.value
    })
  }

  // convert value to integer number
  const handleInputIntegerChange = e => {
    setValues({
      ...values,
      [e.target.name]: e.target.value.replace(/[^0-9]/g, "")
    })
  }

  const handleCurrencyChange = e => {
    setValues({
      ...values,
      [e.target.name]: +convertDecimal(e.target.value)
    })
  }

  const changeCurrentData = async e => {
    await setCurrentId(e.target.value)
    await dispatch(fetchCurrentResult(e.target.value, 'life-insurance'))
  }

  const onSave = async () => {
    dispatch(saveItemResult(values, 'life-insurance')).then(res => {
      const calculator = res?.data?.data;

      if(calculator) {
        setIsSavedModal(true);
        setCurrentId(calculator.id);
        dispatch(setCurrentCalculator(calculator));
      }
    }).finally(() => {
      setIsOpen(false);
    })
  }

  const resetForm = () => {
    setValues({
      currentId: '',
      name: '',
      ...baseValues
    })
    setCurrentId('')
  }

  const removeResult = async () => {
    await dispatch(removeItemResult(currentId, 'life-insurance'))
    await setCurrentId('')
    await setIsDeleteModal(false)
    await setActiveTab(0)
    await setValues({
      ...baseValues
    })
  }

  return (
    <Layout decor>
      <BackButton title="Back to Calculators" path="/calculators" className="back-button--position" />
      <div className="calculators__title">
        <div>
          {calculators && <h4>{calculators.name}</h4>}
          {calculators &&
          <Tooltip
            title={
              <>
                <h6>{calculators.name}</h6>
                {calculators.tooltip}
                <hr className="tooltip__line"/>
                <h6>Example</h6>
                {calculators.example}
              </>
            }
          />}
        </div>

        {listExisting && listExisting.length > 0 && <div className="calculators__title-select">
          <FormSelect
            options={listExisting}
            id="currentId"
            value={currentId}
            medium
            name="currentId"
            onChange={changeCurrentData}
            placeholder="Select result"
          />
        </div>}
      </div>

      <Tabs
        handleChange={(event, newValue) => {
          setActiveTab(newValue)
        }}
        variant="scrollable"
        value={tabActive}
        tabs={[
          {title: 'Profile info'},
          {title: 'Assets'},
          {title: 'Liabilities'},
          {title: 'Other info'}
        ]}
      />
    {values &&   
      <div className="calculators__holder">
        <div className="calculators__content">
          <TabPanel value={tabActive} index={0}>
            <h6 className="calculators__subtitle">Step 1: Profile</h6>
            <div className="calculators__area">
              <Input
                type="number"
                name="your_age"
                value={values.your_age.toString()}
                small
                smallHeight
                onChange={handleInputIntegerChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100}
                  step={1}
                  label="Your age"
                  value={+values.your_age}
                  onChange={(e, newValue) => setValues({...values, your_age: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="number_of_children"
                value={values.number_of_children?.toString() || ''}
                small
                smallHeight
                onChange={handleInputIntegerChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={10}
                  step={1}
                  label="Number of  children"
                  value={+values.number_of_children}
                  onChange={(e, newValue) => setValues({...values, number_of_children: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area-label">
              Your gender
            </div>
            <FormGroup>
              <RadioGroup
                name="gender"
                value={values.gender || 'male'}
                onChange={e => {
                  setValues({
                    ...values,
                    [e.target.name]: e.target.value
                  })
                }}
                className="radio-list-inline"
              >
                <FormControlLabel value="male" control={<Radio/>} label="Male"/>
                <FormControlLabel value="female" control={<Radio/>} label="Female"/>
              </RadioGroup>
            </FormGroup>
            <div className="calculators__area-label">
              Do you smoke?
            </div>
            <FormGroup>
              <RadioGroup
                name="is_smoke"
                value={values.is_smoke}
                onChange={e => {
                  setValues({
                    ...values,
                    [e.target.name]: normalizeBoolean(e.target.value)
                  })
                }}
                className="radio-list-inline"
              >
                <FormControlLabel value={false} control={<Radio />} label="No" />
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
              </RadioGroup>
            </FormGroup>
          </TabPanel>
          <TabPanel value={tabActive} index={1}>
            <h6 className="calculators__subtitle">Step 2: Input assets</h6>
            <div className="calculators__area">
              <InputCurrency
                name="take_home_monthly_income"
                value={values.take_home_monthly_income.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={25000}
                  step={1}
                  label="Your take-home monthly income"
                  value={+values.take_home_monthly_income}
                  onChange={(e, newValue) => setValues({...values, take_home_monthly_income: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <InputCurrency
                name="spouse_take_home_income"
                value={values.spouse_take_home_income.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={25000}
                  step={1}
                  label="Spouse’s take-home monthly income "
                  value={+values.spouse_take_home_income}
                  onChange={(e, newValue) => setValues({...values, spouse_take_home_income: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <InputCurrency
                name="social_security_survivors_benefit"
                value={values.social_security_survivors_benefit.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={10000}
                  step={1}
                  label="Social security survivorship benefit"
                  value={+values.social_security_survivors_benefit}
                  onChange={(e, newValue) => setValues({...values, social_security_survivors_benefit: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <InputCurrency
                name="current_total_assets"
                value={values.current_total_assets.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={2000000}
                  step={1}
                  label="Current total assets"
                  value={+values.current_total_assets}
                  onChange={(e, newValue) => setValues({...values, current_total_assets: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <InputCurrency
                name="current_life_insurance"
                value={values.current_life_insurance.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={2000000}
                  step={1}
                  label="Current life insurance (you)"
                  value={+values.current_life_insurance}
                  onChange={(e, newValue) => setValues({...values, current_life_insurance: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <InputCurrency
                name="spouse_current_life_insurance"
                value={values.spouse_current_life_insurance.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={2000000}
                  step={1}
                  label="Current life insurance (spouse)"
                  value={+values.spouse_current_life_insurance}
                  onChange={(e, newValue) => setValues({...values, spouse_current_life_insurance: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <InputCurrency
                name="child_current_life_insurance"
                value={values.child_current_life_insurance.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={2000000}
                  step={1}
                  label="Current life insurance (child)"
                  value={+values.child_current_life_insurance}
                  onChange={(e, newValue) => setValues({...values, child_current_life_insurance: newValue})}
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel value={tabActive} index={2}>
            <h6 className="calculators__subtitle">Step 3: Input liabilities</h6>
            <div className="calculators__area">
              <InputCurrency
                name="monthly_household_expenses"
                value={values.monthly_household_expenses.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={20000}
                  step={1}
                  label="Monthly household expenses"
                  value={+values.monthly_household_expenses}
                  onChange={(e, newValue) => setValues({...values, monthly_household_expenses: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="total_expense_attributable"
                value={values.total_expense_attributable.toString()}
                small
                smallHeight
                step={0.1}
                onChange={handleInputChange}
                iconName={PercentIcon}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100}
                  step={0.1}
                  label="% of total expenses attributable to you "
                  value={+values.total_expense_attributable}
                  onChange={(e, newValue) => setValues({...values, total_expense_attributable: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="years_cover_expenses"
                value={values.years_cover_expenses.toString()}
                small
                smallHeight
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100}
                  step={1}
                  label="Number of years looking to cover expenses for survivors"
                  value={+values.years_cover_expenses}
                  onChange={(e, newValue) => setValues({...values, years_cover_expenses: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <InputCurrency
                name="funeral_costs"
                value={values.funeral_costs.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={28000}
                  step={1}
                  label="Funeral сosts"
                  value={+values.funeral_costs}
                  onChange={(e, newValue) => setValues({...values, funeral_costs: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <InputCurrency
                name="estate_expenses"
                value={values.estate_expenses.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={28000}
                  step={1}
                  label="Estate expenses"
                  value={+values.estate_expenses}
                  onChange={(e, newValue) => setValues({...values, estate_expenses: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <InputCurrency
                name="current_outstanding_liability"
                value={values.current_outstanding_liability.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={2000000}
                  step={1}
                  label="Current outstanding liabilities"
                  value={+values.current_outstanding_liability}
                  onChange={(e, newValue) => setValues({...values, current_outstanding_liability: newValue})}
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel value={tabActive} index={3}>
            <h6 className="calculators__subtitle">Step 4: Input other info</h6>
            <div className="container--small">
              <FormGroup name="spouse_pass_work" labelText="If spouse passed away, how many months would you be unable to work (e.g. due to grieving, funeral arrangement,etc.)?">
                <Input
                  type="number"
                  name="spouse_pass_work"
                  value={values.spouse_pass_work.toString()}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup name="spouse_years_cover" labelText="Years looking to cover spouses’s income if spouse passes away">
                <Input
                  type="number"
                  name="spouse_years_cover"
                  value={values.spouse_years_cover.toString()}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup name="pass_work" labelText="If you passed away, how many months would your spouse be unable to work (e.g. due to grieving, funeral arrangement, etc.)?">
                <Input
                  type="number"
                  name="pass_work"
                  value={values.pass_work.toString()}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup name="child_pass_work" labelText="If child passed away, how many months would you be unable to work (e.g. due to grieving, funeral arrangement, etc.)?">
                <Input
                  type="number"
                  name="child_pass_work"
                  value={values.child_pass_work.toString()}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup name="child_pass_work_spouse" labelText="If child passed away, how many months would spouse be unable to work (e.g. due to grieving, funeral arrangement,etc.)?">
                <Input
                  type="number"
                  name="child_pass_work_spouse"
                  value={values.child_pass_work_spouse.toString()}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </div>
          </TabPanel>
        </div>
        <div className="calculators__preview">
          <h6 className="calculators__subtitle">Results: Life insurance needed</h6>
          <div className="calculators__preview-box">
            <div className="calculators__legend">
              <div className="calculators__legend-label">Total additional needed (for you)</div>
              <div className="calculators__legend-label calculators__legend-label-color3">Total additional needed (for spouse)</div>
              <div className="calculators__legend-label calculators__legend-label-color2">Total additional info (for children)</div>
            </div>
            <Bar data={data} options={optionsCalculator(null, true, true)} />
          </div>
          <div className="calculators__total">
            Total Monthly Premium: <strong>$ {countValues.totalMonthly}</strong>
          </div>
        </div>
      </div>
    }
      <div className="calculators__buttons">
        <Button variant="contained" size="large" color="primary" onClick={() => setIsOpen(true)}>Save result</Button>
        {currentId && <Button variant="contained" size="large"  onClick={() => setIsDeleteModal(true)}>Remove result</Button>}
        <Button size="large" className="MuiButton-textGray" onClick={() => resetForm()}>Reset</Button>
      </div>

      <ModalBox
        open={isOpen}
        close={() => setIsOpen(false)}
        onClick={onSave}
        button="Save"
        title="Save Result"
        isLoading={loading}
        isDisabled={values && !values.name && !values.currentId}
      >
        <FormGroup name="name" labelText="Name">
          <Input
            name='name'
            value={values ? values.name : ''}
            placeholder="Type a name of result"
            onChange={e => setValues({
              ...values,
              name: e.target.value
            })}
          />
        </FormGroup>
        {listExisting && listExisting.length > 0 &&
        <>
          <p className="text-center">or</p>
          <FormGroup labelText="Select  Existing Result">
            <FormSelect
              options={listExisting}
              value={values ? values.currentId : ''}
              name="currentId"
              onChange={e => setValues({
                ...values,
                currentId: e.target.value,
                name: listExisting.find(i => i.value === e.target.value).label
              })}
              placeholder="Select Existing Result"
            />
          </FormGroup>
        </>
        }
      </ModalBox>
      <ModalBox
        small
        open={isDeleteModal}
        close={() => setIsDeleteModal(false)}
        onClick={removeResult}
        button="Remove"
        title={`Do you want to remove ${current && current.name}?`}
      />

      <ModalBox
        small
        open={isSavedModal}
        close={() => setIsSavedModal(false)}
        button="Ok"
        onClick={() => setIsSavedModal(false)}
      >
        <div>
          {calculators && calculators.iconLeft &&
          <div className="modal__title-iconLeft">
            <img src={calculators.iconLeft} alt={calculators.name} />
          </div>}
          <h4>Your calculator scenario has been saved.</h4>
          <p>You can access your saved scenarios through the “Select result” dropdown menu at the top of each calculator page.</p>
        </div>
      </ModalBox>
    </Layout>
  )
}

export default LifeInsurance
