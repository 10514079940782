import React, { useEffect, useState } from 'react';
import LayoutAdmin from '../../components/Layout/LayoutAdmin';
import { useParams, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/shared/Loading/Loading';
import { Button, Switch, FormControlLabel } from '@mui/material';
import Modal from '../../components/shared/Modal/Modal';
import { setIsOpen } from '../../redux/slices/modalSlice';
import { setMode } from '../../redux/slices/modeSlice';
import {
  fetchCompany,
  fetchCompanyPermissions,
} from '../../redux/slices/companiesSlice';
import { postAuthSettings } from '../../redux/slices/authSlice';
import { isNull, isEmpty } from 'lodash';

const AdminSettings = ({ props, isAdmin }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.modal.isOpen);
  const mode = useSelector((state) => state.mode.mode);
  const sending = useSelector((state) => state.sending.isSending);
  const authInfo = useSelector((state) => state.auth.authInfo);
  const { id, permissions } = useSelector((state) => state.user.user);
  const { info } = useSelector((state) => state.companies.company);
  const { companyId } = useParams();
  const [values, setValues] = useState({ login: false, registration: false });
  const [isMobile, setIsMobile] = useState(false);

  const mobileView = 999;

  const handleResize = () => {
    mobileView >= window.innerWidth ? setIsMobile(true) : setIsMobile(false);
  };

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isEmpty(authInfo)) {
      setValues({
        login: authInfo[1].value,
        registration: authInfo[0].value,
      });
    }
    // eslint-disable-next-line
  }, [authInfo]);

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchCompany(companyId));
      await dispatch(fetchCompanyPermissions(companyId));
    }

    if (companyId) {
      fetch();
    }
    // eslint-disable-next-line
  }, [companyId]);

  const handleAuthSettingsChange = (e) => {
    if (e.target.name === 'login' && !e.target.checked) {
      setValues({
        ...values,
        [e.target.name]: e.target.checked,
        registration: false,
      });
    } else {
      setValues({
        ...values,
        [e.target.name]: e.target.checked,
      });
    }
  };

  const onSubmit = () => {
    dispatch(postAuthSettings(values));
  };

  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }));
    dispatch(setMode(null));
  };

  if (!!id && !permissions?.edit_settings) return <Redirect to='/admin' />;

  return (
    <LayoutAdmin companyId={companyId} isAdmin={isAdmin}>
      {isNull(values) ? (
        <Loading />
      ) : (
        <>
          <div className='member-comapny'>
            <div className='member-comapny__info'>
              <div className='member-comapny__logo'>
                <img src={info?.image} alt='Company logo' />
              </div>
              <div className='member-comapny__title'>
                <h3>{info?.name}</h3>
                {!isMobile && (
                  <p>
                    Feedback Email: <span>{info?.email}</span>
                  </p>
                )}
              </div>
            </div>
            {isMobile && (
              <div className='mobile-feedback'>
                Feedback Email: <span>{info?.email}</span>
              </div>
            )}
          </div>
          <div className='company'>
            <div className='switcher__wrap'>
              <div className='switcher__wrap--top'>
                <h4>Settings</h4>
                {values?.login && values?.registration ? (
                  <Button
                    variant='contained'
                    size='small'
                    color='primary'
                    onClick={() => {
                      setValues({
                        login: false,
                        registration: false,
                      });
                    }}
                  >
                    Deactivate all
                  </Button>
                ) : (
                  <Button
                    variant='contained'
                    size='small'
                    color='primary'
                    onClick={() => {
                      setValues({
                        login: true,
                        registration: true,
                      });
                    }}
                  >
                    Activate all
                  </Button>
                )}
              </div>
              <div className='switcher__wrap--content'>
                <FormControlLabel
                  control={
                    <Switch
                      className='custom-switcher'
                      checked={values?.login}
                      value={values?.login}
                      onChange={(e) => {
                        handleAuthSettingsChange(e);
                      }}
                      name='login'
                    />
                  }
                  label='Login'
                  labelPlacement='start'
                />

                <FormControlLabel
                  control={
                    <Switch
                      className='custom-switcher'
                      checked={values?.registration}
                      value={values?.registration}
                      onChange={(e) => {
                        handleAuthSettingsChange(e);
                      }}
                      name='registration'
                    />
                  }
                  label='Registration'
                  labelPlacement='start'
                />
              </div>
            </div>
            <div className='mt--small'>
              <Button
                variant='contained'
                size='large'
                color='primary'
                disabled={sending}
                onClick={onSubmit}
              >
                Save
                {sending && <span className='spinner' />}
              </Button>
            </div>
          </div>
        </>
      )}
      <Modal
        open={isOpen}
        small
        button='Ok'
        onClick={onClose}
        close={onClose}
        title={mode === 'success' ? 'Success!' : 'Error!'}
        children={
            mode === 'success' ? (
            <p>The settings have been changed successfully.</p>
          ) : (
            <p>Something went wrong. Try again.</p>
          )
        }
      />
    </LayoutAdmin>
  );
};

export default AdminSettings;
