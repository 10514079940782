import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Layout from '../../../components/Layout/Layout'
import LayoutForm from '../../../components/Layout/LayoutForm'
import { BackButton, DeleteButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import { Input, FormGroup, InputCurrency, InputPercent } from '../../../components/shared/Form'
import { Button, FormControlLabel, Radio, RadioGroup, TextareaAutosize } from '@mui/material'
import { fetchChildEducation, postChildEducation, fetchIdData, deleteChildEducation, removeChild } from '../../../redux/slices/childrenSlice'
import { EducationSchema } from '../../../utils/validation'
import Illustration from '../../../assets/images/illustrations/Tree-segment_aboutMe-min.png'
import Priority from '../../../components/shared/Priority/Priority'
import { normalizeBoolean } from '../../../utils/utils'
import Loading from '../../../components/shared/Loading/Loading'
import Modal from '../../../components/shared/Modal/Modal'
import ProgressCircle from '../../../components/shared/ProgressBar/ProgressCircle'
import {setIsOpen} from '../../../redux/slices/modalSlice'

const EductionDetails = ({ props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userId = useSelector(state => state.user.user.id);
  const sending = useSelector(state => state.sending.isSending);
  const isOpen = useSelector(state => state.modal.isOpen);
  const { education, currentChild, loading } = useSelector(state => state.children);
  const { id }  = useParams();
  const [isOpenDelete, setOpenDelete] = useState(false);

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchIdData(id));
      await dispatch(fetchChildEducation(id));
    }
    if (userId && id) {
      fetch()
    }

    // eslint-disable-next-line
  }, [userId, id])

  const onSubmit = values => {
    dispatch(postChildEducation(id, values, props))
  }

  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }))
  }

  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }));
    history.push('/education')
  }

  const deleteChild = async () => {
    await dispatch(removeChild(id, props));
    await history.push('/education');
  }

  const deleteEducation = async () => {
    await dispatch(deleteChildEducation(id));
    await history.push('/education');
  }
  
  return (
    <Layout>
      <LayoutForm illustration={Illustration} small>
        <BackButton handleClick={() => history.push('/education')} title="Back to Education Planning" />
        {currentChild && <DeleteButton className="delete-button--position" onClick={() => setOpenDelete(true)} />}
        {id && loading
          ? <Loading />
          : <>
              <PageTitle
                title={currentChild && currentChild.name ? currentChild.name : 'Student education'}
                content={currentChild && currentChild.type ? <p style={{textTransform: 'capitalize'}}>{currentChild.type}</p> : 'Student'}
                status={education && education.your_age_when_child_college ? 100 : '0'}
                info={
                  <>
                    <h6>Current Education Step</h6>
                    <Priority option="Very High" />
                    <div>The Education Planning Module asks you to specify whether you are currently funding or planning to fund your student's education, how much, what type, among other details.  ValueSoft will then utilize this information to figure out whether you are optimizing your education planning and ensures your education plan does not negatively impact other important aspects of your overall financial health.  If you're not planning to fund your student's education, simply leave this page blank.</div>
                  </>
                }
              />
              <div className="layout-form__content-holder">
              <Formik
                enableReinitialize
                initialValues={education && {...education}}
                validationSchema={EducationSchema}
                onSubmit={onSubmit}
              >
                {({ errors, handleChange, handleBlur, values, touched, setFieldValue }) => (
                  <Form>
                    <FormGroup
                      name="your_age_when_child_high_school"
                      labelText="Your age when student began/begins high school"
                    >
                      <Input
                        placeholder="Type an age"
                        type="number"
                        name="your_age_when_child_high_school"
                        onChange={handleChange}
                        value={values.your_age_when_child_high_school || ''}
                        onBlur={handleBlur}
                        hasError={!!errors.your_age_when_child_high_school && touched.your_age_when_child_high_school}
                      />
                      {touched.your_age_when_child_high_school && errors.your_age_when_child_high_school && <span className="form__msg-error">{errors.your_age_when_child_high_school}</span>}
                    </FormGroup>
                    <FormGroup
                      name="high_school_type"
                      labelText="High school type"
                      info={{
                        content:
                          <>
                            <h6>High School Type</h6>
                            <Priority option="Medium" />
                            <div>If you plan to send your student to public school, then enter that here.  Otherwise, if you plan to send them to private school, enter that instead.  The type of school will determine educational expense and hence impact your financial situation.</div>
                            <hr className="tooltip__line"/>
                            <h6>Example</h6>
                            <div>Say you plan to send your student to private school.  Enter that detail in this field and ValueSoft will approximate a private high school cost for you.</div>
                          </>
                      }}
                    >
                      <RadioGroup
                        name="high_school_type"
                        value={values.high_school_type || null}
                        onChange={handleChange}
                        className="radio-list-inline"
                      >
                        <FormControlLabel value="public" control={<Radio />} label="Public" />
                        <FormControlLabel value="private" control={<Radio />} label="Private" />
                      </RadioGroup>
                      {touched.high_school_type && errors.high_school_type && <span className="form__msg-error">{errors.high_school_type}</span>}
                    </FormGroup>
                    <FormGroup
                      name="your_age_when_child_college"
                      labelText="Your age when student began/begins college"
                    >
                      <Input
                        placeholder="Type an age"
                        type="number"
                        name="your_age_when_child_college"
                        onChange={handleChange}
                        value={values.your_age_when_child_college || ''}
                        onBlur={handleBlur}
                        hasError={!!errors.your_age_when_child_college && touched.your_age_when_child_college}
                      />
                      {touched.your_age_when_child_college && errors.your_age_when_child_college && <span className="form__msg-error">{errors.your_age_when_child_college}</span>}
                    </FormGroup>
                    <FormGroup
                      name="college_type"
                      labelText="College type"
                      info={{
                        content:
                          <>
                            <h6>College Type</h6>
                            <Priority option="Medium" />
                            <div>If you plan to send your student to public college, then enter that here.  Otherwise, if you plan to send them to an ivy-league school, enter that instead.  The type of school will determine educational expense and hence impact your financial situation.  Ivy league universities can cost well over $100,000 over the course of a standard 4 year education!</div>
                            <hr className="tooltip__line"/>
                            <h6>Example</h6>
                            <div>Say you plan to send your student to Harvard.  Enter 'Ivy League' in this field and ValueSoft will approximate the ivy league tuition, room and board cost for you.</div>
                          </>
                      }}
                    >
                      <RadioGroup
                        name="college_type"
                        value={values.college_type || null}
                        onChange={handleChange}
                        className="radio-list-inline"
                      >
                        <FormControlLabel value="public" control={<Radio />} label="Public" />
                        <FormControlLabel value="private" control={<Radio />} label="Private" />
                      </RadioGroup>
                      {touched.college_type && errors.college_type && <span className="form__msg-error">{errors.college_type}</span>}
                    </FormGroup>
                    <FormGroup
                      name="pay_for_tuition"
                      labelText="Will you pay for tuition?"
                      info={{
                        content:
                          <>
                            <h6>Tuition Payments</h6>
                            <Priority option="High" />
                            <div>Determine whether you will pay for the student's college tuition.  You can always decide to partially pay by entering a percentage you will plan to pay in the field immediately after this one.  You may decide not to pay for tuition because you expect the student to receive a scholarship, financial aid, or pay their way through college by taking up a job.</div>
                            <hr className="tooltip__line"/>
                            <h6>Example</h6>
                            <div>Say you expect your student to receive a full ride to college via an athletic scholarship.  Then enter No in this field.</div>
                          </>
                      }}
                    >
                      <RadioGroup
                        name="pay_for_tuition"
                        value={values.pay_for_tuition || false}
                        onChange={(e) => {
                          setFieldValue('pay_for_tuition', normalizeBoolean(e.target.value))
                        }}
                        className="radio-list-inline"
                      >
                        <FormControlLabel value={false} control={<Radio />} label="No" />
                        <FormControlLabel value={true} control={<Radio />} label="Yes" />
                      </RadioGroup>
                      {touched.pay_for_tuition && errors.pay_for_tuition && <span className="form__msg-error">{errors.pay_for_tuition}</span>}
                    </FormGroup>
                    <FormGroup name="tuition_amount"
                               labelText="Approximate % of total tuition you will pay (including room & board)"
                    >
                      <InputPercent
                        placeholder="Type an approximate % "
                        name="tuition_amount"
                        onChange={handleChange}
                        value={values.tuition_amount || ''}
                        onBlur={handleBlur}
                        hasError={!!errors.tuition_amount && touched.tuition_amount}
                        info={{
                          content:
                            <>
                              <h6>Tuition Percentage</h6>
                              <Priority option="High" />
                              <div>If you plan to pay for some of your student's college education expenses but not all of it, enter the percentage you plan to pay over the course of the college duration.</div>
                              <hr className="tooltip__line"/>
                              <h6>Example</h6>
                              <div>Say you send your student to Harvard and it will cost you $180,000 over the four years, but you plan on only paying $90,000.  Then enter 50 in this field.</div>
                            </>
                        }}
                      />
                      {touched.tuition_amount && errors.tuition_amount && <span className="form__msg-error">{errors.tuition_amount}</span>}
                    </FormGroup>
                    <FormGroup
                      name="pay_529b"
                      labelText="Have you begun funding a 529B plan? "
                      info={{
                        content:
                          <>
                            <h6>529B Education Funding</h6>
                            <Priority option="Medium" />
                            <div>A 529 plan is a tax-advantaged savings plan designed to encourage saving for future college costs. 529 plans, legally known as “qualified tuition plans,” are sponsored by states, state agencies, or educational institutions and are authorized by Section 529 of the Internal Revenue Code.  There are two types of 529 plans: pre-paid tuition plans and college savings plans. All fifty states and the District of Columbia sponsor at least one type of 529 plan.</div>
                          </>
                      }}
                    >
                      <RadioGroup
                        name="funding_529b_plan"
                        value={values.funding_529b_plan || false}
                        onChange={(e) => {
                          setFieldValue('funding_529b_plan', normalizeBoolean(e.target.value))
                          setFieldValue('yearly_funding_amount', '')
                          setFieldValue('account_amount', '')
                        }}
                        className="radio-list-inline"
                      >
                        <FormControlLabel value={false} control={<Radio />} label="No" />
                        <FormControlLabel value={true} control={<Radio />} label="Yes" />
                      </RadioGroup>
                      {touched.funding_529b_plan && errors.funding_529b_plan && <span className="form__msg-error">{errors.funding_529b_plan}</span>}
                    </FormGroup>
                    <FormGroup name="yearly_funding_amount" labelText="Yearly funding amount $ ">
                      <InputCurrency
                        placeholder="Type yearly funding amount"
                        type="text"
                        name="yearly_funding_amount"
                        onChange={handleChange}
                        value={values.funding_529b_plan ? values.yearly_funding_amount : ''}
                        onBlur={handleBlur}
                        disabled={!values.funding_529b_plan}
                        hasError={!!errors.yearly_funding_amount && touched.yearly_funding_amount && values.funding_529b_plan}
                        info={{
                          content:
                            <>
                              <h6>Yearly Funding Amount</h6>
                              <Priority option="Medium" />
                              <div>Please specify the yearly contribution amount you make to this student's 529B plan.</div>
                            </>
                        }}
                      />
                      {values.funding_529b_plan && touched.yearly_funding_amount && errors.yearly_funding_amount && <span className="form__msg-error">{errors.yearly_funding_amount}</span>}
                    </FormGroup>
                    <FormGroup name="account_amount" labelText="Account balance">
                      <InputCurrency
                        placeholder="Type an account balance"
                        type="text"
                        name="account_amount"
                        onChange={handleChange}
                        value={values.funding_529b_plan ? values.account_amount : ''}
                        onBlur={handleBlur}
                        disabled={!values.funding_529b_plan}
                        hasError={!!errors.account_amount && touched.account_amount && values.funding_529b_plan}
                        info={{
                          content:
                            <>
                              <h6>Current Account Balance</h6>
                              <Priority option="Medium" />
                              <div>Please specify the current account balance for this 529B plan.</div>
                            </>
                        }}
                      />
                      {values.funding_529b_plan && touched.account_amount && errors.account_amount && <span className="form__msg-error">{errors.account_amount}</span>}
                    </FormGroup>

                    <h5 className="mt--medium">Additional info</h5>
                    <FormGroup name="additional_info" labelText="Is there any other Education Funding information that you believe is important for ValueSoft to know about? If so, please let us know about it:">
                      <TextareaAutosize
                        maxLength="200"
                        name="additional_info"
                        value={values.additional_info || ''}
                        className="form__textarea"
                        placeholder="Maximum characters: 200"
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <div className="form__button">
                      <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                        Save
                        {sending && <span className="spinner" />}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </>
        }

        <Modal
          open={isOpen}
          small
          button="Done"
          onClick={handleClick}
          close={onClose}
        >
          <h3>Good Job!</h3>
          <p>Your progress increased 100%</p>
          <ProgressCircle amount={100} small />
        </Modal>

        <Modal
          small
          inline
          open={isOpenDelete}
          title="Are you sure?"
          button="Remove Item"
          closeButton="Cancel"
          onClick={['self', 'spouse', 'dependent'].includes(currentChild?.type) ? deleteChild : deleteEducation}
          close={() => setOpenDelete(false)}
        />

      </LayoutForm>
    </Layout>
  );
};

export default EductionDetails;
