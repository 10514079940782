import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'classnames'
import { fillStatus } from '../../utils/utils'

const TreeCount = ({
  value,
  loading,
  big,
  small
}) => (
  <div className={clsx(`tree__count tree__count--${fillStatus(value)}`,
                      {
                        'tree__count--loading' : loading,
                        'tree__count--big': big,
                        'tree__count--small': small
                      })}
  >
    {loading
      ? <span className="spinner spinner--small" />
      : <>{value}%</>
    }
  </div>
)

TreeCount.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  loading: PropTypes.bool,
  big: PropTypes.bool,
  small: PropTypes.bool
}

export default TreeCount
