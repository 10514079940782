import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import Layout from '../../../components/Layout/Layout'
import LayoutForm from '../../../components/Layout/LayoutForm'
import { BackButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import { FormGroup, Input, InputPercent } from '../../../components/shared/Form'
import { Button, Checkbox, FormControlLabel, Radio, RadioGroup, TextareaAutosize } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import Illustration from '../../../assets/images/illustrations/Tree-segment_protection-min.png'
import {
  fetchInsuranceDisability,
  postInsuranceDisability
} from '../../../redux/slices/insuranceSlice'
import { insuranceDisabilitySchema } from '../../../utils/validationProtection'
import TooltipCustom from '../../../components/shared/Tooltip/Tooltip'
import { fetchPercentages } from '../../../redux/slices/percentagesSlice'
import Modal from '../../../components/shared/Modal/Modal'
import ProgressCircle from '../../../components/shared/ProgressBar/ProgressCircle'
import { setIsOpen } from '../../../redux/slices/modalSlice'
import { getPercent } from '../../../utils/utils'
import isNull from 'lodash/isNull'


const Disability = ({ props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.user.user);
  const insurance = useSelector(state => state.insurance.disability);
  const sending = useSelector(state => state.sending.isSending);
  const percents = useSelector(state => state.percentages.percents);
  const isOpen = useSelector(state => state.modal.isOpen);
  const [isMobile, setIsMobile] = useState(false);
  const mobileView = 999;
  const handleResize = () => {
    (mobileView >= window.innerWidth) ? setIsMobile(true) : setIsMobile(false)
  }

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchPercentages({name: ''}));
      await dispatch(fetchInsuranceDisability());
    }
    if (id) {
      fetch()
    }

    // eslint-disable-next-line
  }, [id])

  const onSubmit = (values) => {
    dispatch(postInsuranceDisability(values, props))
  }

  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }))
  }

  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }))
    history.push("/input")
  }

  const handleChangeInput = (e, setValue) => {
    if([null, false].includes(e.target.value)) return false

    setValue(e.target.name, e.target.value)
  }

  const profile           = getPercent(percents, 'profile')
  const disabilityPercent = getPercent(percents, 'disability')

  return (
    <Layout isBlockedPage={percents && profile === 0}>
      <LayoutForm illustration={Illustration} small>
        <BackButton title={isMobile ? 'Back' : 'Back to Tree'} />
        <PageTitle
          title="Long term disability insurance"
          content="Long term disability should be an important aspect of your financial plan. Figure out if you and your spouse are over or under-insured in this aspect of your insurance planning.   ValueSoft will analyze your long term disability insurance needs and estimate your additional monthly premium."
          status={percents && disabilityPercent ? disabilityPercent : '0'}
          info={
            <div>You may not realize the potential danger of becoming disabled. The U.S. Census Bureau estimates that you have a one in five chance of becoming disabled.  Also, the average long-term disability (LTD) absence from work lasts 2.5 years, according to the Council for Disability Awareness (CDA). That's a long time to survive without a steady income.  As such, long term disability insurance should be a critical part of a person's financial plan.  ValueSoft looks at your income in the event of a major disability to assess the amount of long term disability insurance you would need.</div>
          }
        />
        <div className="layout-form__content-holder">
          <Formik
            enableReinitialize
            initialValues={insurance}
            validationSchema={insuranceDisabilitySchema}
            onSubmit={onSubmit}
          >
            {({ errors, handleChange, handleBlur, values, touched, setFieldValue }) => (
              <Form>
                <FormGroup name="percent_take_home"
                           labelText="Percent of your monthly take-home salary you are looking to cover each month"
                           info={{
                             content:
                               <>
                                 Typically, long-term disability insurance can be purchased to replace 50-70% of salary. Some employers allow employees to purchase extra insurance from the same company, sometimes raising the total to 80%. Note, however, that some policies have monthly maximum payouts, which may reduce the actual percentage of salary the policy owner receives. The 'salary' is set at the time the policy is purchased, and you will likely want to increase the value of the plan as your compensation increases.  Please note that if you pay your own premiums with after-tax dollars, your disability benefits will be tax-free. If your employer pays for the policy, most likely with pre-tax dollars, your disability benefits will be taxable.
                               </>
                           }}
                >
                  <InputPercent
                    placeholder="Type percent of your monthly"
                    name="percent_take_home"
                    onChange={handleChange}
                    value={values.percent_take_home || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.percent_take_home && touched.percent_take_home}
                  />
                  {touched.percent_take_home && errors.percent_take_home && <span className="form__msg-error">{errors.percent_take_home}</span>}
                </FormGroup>
                <FormGroup name="spouse_percent_take_home" labelText="Percent of your spouse's monthly take-home salary you are looking to cover each month (optional)">
                  <InputPercent
                    placeholder="Type percent of your spouse’s monthly"
                    name="spouse_percent_take_home"
                    onChange={handleChange}
                    value={values.spouse_percent_take_home || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.spouse_percent_take_home}
                  />
                  {errors.spouse_percent_take_home && <span className="form__msg-error">{errors.spouse_percent_take_home}</span>}
                </FormGroup>
                <FormGroup name="current_ltd" labelText="How many years of long term disability are currently covered by your employer or by an individual policy?	">
                  <Input
                    placeholder="Type years of long term disability"
                    type="number"
                    name="current_ltd"
                    step={0.01}
                    onChange={e => handleChangeInput(e, setFieldValue)}
                    value={values.current_ltd && !isNull(values.current_ltd) ? values.current_ltd : ''}
                    onBlur={handleBlur}
                    hasError={!!errors.current_ltd && touched.current_ltd}
                  />
                  {touched.current_ltd && errors.current_ltd && <span className="form__msg-error">{errors.current_ltd}</span>}
                </FormGroup>
                <FormGroup name="spouse_current_ltd" labelText="How many years of long term disability are currently covered by your spouse's employer or by an individual policy? (optional)">
                  <Input
                    placeholder="Type years of long term disability your spouses"
                    type="number"
                    name="spouse_current_ltd"
                    step={0.01}
                    onChange={e => handleChangeInput(e, setFieldValue)}
                    value={values.spouse_current_ltd || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.spouse_current_ltd}
                  />
                  {errors.spouse_current_ltd && <span className="form__msg-error">{errors.spouse_current_ltd}</span>}
                </FormGroup>
                <FormGroup
                  name="waiting_period"
                  labelText="What waiting period (in weeks) would you like to assume (waiting period is when your long term disability insurance would kick in after you are disabled)?"
                  info={{
                    content:
                      <>
                        Because most disability events are temporary, long term disability insurance coverage tends to be cheaper when the policyholder agrees to wait longer before receiving claim payments. For example, if you were in a major car accident, it may be 24 months before you are able to perform your job again. If you agreed to wait 180 days before receiving claim payments, then the insurer will not have to pay a claim for your event until 180 days have passed. This reduction to the insurance company's risk is reflected in the lower price that you'd pay to purchase coverage (lower premiums).
                      </>
                  }}
                >
                  <RadioGroup
                    name="waiting_period"
                    value={values.waiting_period || null}
                    onChange={handleChange}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value="thirteen_weeks" control={<Radio />} label="13 weeks" />
                    <FormControlLabel value="twenty_six_weeks" control={<Radio />} label="26 weeks" />
                  </RadioGroup>
                  {touched.waiting_period && errors.waiting_period && <span className="form__msg-error">{errors.waiting_period}</span>}
                </FormGroup>
                <FormGroup
                  name="years_cover_salary"
                  labelText="How long would you like to cover your salary should you be disabled?	"
                >
                  <RadioGroup
                    name="years_cover_salary"
                    value={values.years_cover_salary || ''}
                    onChange={handleChange}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value="five_years" control={<Radio />} label="5 years" />
                    <FormControlLabel value="ten_years" control={<Radio />} label="10 years" />
                    <FormControlLabel value="until_retirement" control={<Radio />} label="Until retirement" />
                  </RadioGroup>
                  {touched.years_cover_salary && errors.years_cover_salary && <span className="form__msg-error">{errors.years_cover_salary}</span>}
                </FormGroup>
                <FormGroup
                  name="spouse_years_cover_salary"
                  labelText="How long would you like to cover your spouse's salary should he/she be disabled? (optional)"
                >
                  <RadioGroup
                    name="spouse_years_cover_salary"
                    value={values.spouse_years_cover_salary || null}
                    onChange={handleChange}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value="five_years" control={<Radio />} label="5 years" />
                    <FormControlLabel value="ten_years" control={<Radio />} label="10 years" />
                    <FormControlLabel value="until_retirement" control={<Radio />} label="Until retirement" />
                  </RadioGroup>
                </FormGroup>
                <FormGroup
                  labelText="Riders: Which of the following add-ons would you like to include as part of your policy? (optional)"
                >
                  <ul className="checkbox__list">
                    <li>
                      <FormControlLabel
                        control={<Checkbox checked={values.residual_benefits || false} onChange={handleChange} name="residual_benefits" inputProps={{ 'aria-label': 'controlled' }} />}
                        label="Residual Benefits"
                      />
                      <TooltipCustom
                        size="small"
                        title="The residual benefits rider provides the difference between old and new salaries, in the event that the policy owner can get a new job, but not one with the same salary as his old one."
                      />
                    </li>
                    <li>
                      <FormControlLabel
                        control={<Checkbox checked={values.cost_of_living || false} onChange={handleChange} name="cost_of_living" />}
                        label="Cost of Living"
                      />
                      <TooltipCustom
                        size="small"
                        title="The cost of living rider allows the policy's value to increase with inflation."
                      />
                    </li>
                    <li>
                      <FormControlLabel
                        control={<Checkbox checked={values.own_occupation || false} onChange={handleChange} name="own_occupation" />}
                        label="Own Occupation"
                      />
                      <TooltipCustom
                        size="small"
                        title="Most policies are 'any-occupation', which means the policy owner must work when he is capable, even if not in the same capacity as before. An 'own-occupation' policy will allow the owner to collect benefits until he can resume the previous occupation. Typically, these policies are more beneficial to policy-owners with high-skill or high-paying jobs."
                      />
                    </li>
                    <li>
                      <FormControlLabel
                        control={<Checkbox checked={values.guaranteed_renewable || false} onChange={handleChange} name="guaranteed_renewable" />}
                        label="Guaranteed Renewable"
                      />
                      <TooltipCustom
                        size="small"
                        title="Guaranteed renewable means the insurance company cannot drop the policy, unless premium payments are skipped."
                      />
                    </li>
                    <li>
                      <FormControlLabel
                        control={<Checkbox checked={values.non_cancellable || false} onChange={handleChange} name="non_cancellable" />}
                        label="Non-Cancellable"
                      />
                      <TooltipCustom
                        size="small"
                        title="Non-cancelable means the insurance company can never raise the premium on the policy."
                      />
                    </li>
                    <li>
                      <FormControlLabel
                        control={<Checkbox checked={values.premium_forgiveness || false} onChange={handleChange} name="premium_forgiveness" />}
                        label="Premium Forgiveness During Disability"
                      />
                      <TooltipCustom
                        size="small"
                        title="If you become disabled and begin receiving benefits, you will no longer have to pay premiums. Most policies contain a 'waiver of premium' provision that states you can stop paying premiums if you are disabled for 90 days or longer."
                      />
                    </li>
                    <li>
                      <FormControlLabel
                        control={<Checkbox checked={values.childcare_support || false} onChange={handleChange} name="childcare_support" />}
                        label="Child-Care Support in Event Spouse Returns to Work"
                      />
                      <TooltipCustom
                        size="small"
                        title="Some insurers, though few, offer a dependant care reimbursement benefit, meaning they will also reimburse the insured individual for child care expenses if the individual's spouse must go back to work as a result of the disability."
                      />
                    </li>
                  </ul>
                </FormGroup>
                <h5 className="mt--medium">Additional info</h5>
                <FormGroup name="additional_info" labelText="Is there any other Long Term Disability Insurance information that you believe is important for ValueSoft to know about? If so, please let us know about it:">
                  <TextareaAutosize
                    maxLength="200"
                    name="additional_info"
                    value={values.additional_info || ''}
                    className="form__textarea"
                    placeholder="Maximum characters: 200"
                    onChange={handleChange}
                  />
                </FormGroup>

                <div className="form__button">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Save
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <Modal
          open={isOpen}
          small
          button="Done"
          onClick={handleClick}
          close={onClose}
        >
          <h3>Good Job!</h3>
          <p>Your progress increased 100%</p>
          <ProgressCircle amount={100} small />
        </Modal>
      </LayoutForm>
    </Layout>
  );
};

export default Disability;
