import React from 'react'
import { Form, Formik } from 'formik'
import { Button } from "@mui/material";
import {FormGroup, Input, InputCurrency} from '../../components/shared/Form'

const Estate = ({ title, onSave, values, coreValues, validationSchema, sending, noEdit }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{ ...values }}
      validationSchema={validationSchema}
      onSubmit={(values) => onSave(values)}
    >
      {({ errors, handleChange, handleBlur, values, touched }) => (
        <Form>
          <h4>{title}</h4>
          <div className="form__flow-wrap">
            <FormGroup name="life_expectancy" labelText="Average life expectancy age">
              <Input
                name="life_expectancy"
                placeholder="Type"
                type="text"
                onChange={handleChange}
                value={values.life_expectancy || ''}
                onBlur={handleBlur}
                className={coreValues?.life_expectancy && !noEdit && 'form__input--core'}
                hasError={!!errors.life_expectancy && touched.life_expectancy}
              />
              {touched.life_expectancy && errors.life_expectancy ? (
                <span className="form__msg-error">{errors.life_expectancy}</span>
              ) : coreValues && coreValues.life_expectancy && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.life_expectancy}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="funeral_costs" labelText="Funeral costs">
              <InputCurrency
                icon
                placeholder="$0.00"
                name="funeral_costs"
                onChange={handleChange}
                value={values.funeral_costs || ''}
                onBlur={handleBlur}
                className={coreValues?.funeral_costs && !noEdit && 'form__input--core'}
                hasError={!!errors.funeral_costs && touched.funeral_costs}
              />
              {touched.funeral_costs && errors.funeral_costs ? (
                <span className="form__msg-error">{errors.funeral_costs}</span>
              ) : coreValues && coreValues.funeral_costs && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.funeral_costs}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="estate_expenses" labelText="Estate expenses">
              <InputCurrency
                icon
                placeholder="$0.00"
                name="estate_expenses"
                onChange={handleChange}
                value={values.estate_expenses || ''}
                onBlur={handleBlur}
                className={coreValues?.estate_expenses && !noEdit && 'form__input--core'}
                hasError={!!errors.estate_expenses && touched.estate_expenses}
              />
              {touched.estate_expenses && errors.estate_expenses ? (
                <span className="form__msg-error">{errors.estate_expenses}</span>
              ) : coreValues && coreValues.estate_expenses && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.estate_expenses}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="tax_exemption" labelText="Estate tax exemption amount">
              <InputCurrency
                icon
                placeholder="$0.00"
                name="tax_exemption"
                onChange={handleChange}
                value={values.tax_exemption || ''}
                onBlur={handleBlur}
                className={coreValues?.tax_exemption && !noEdit && 'form__input--core'}
                hasError={!!errors.tax_exemption && touched.tax_exemption}
              />
              {touched.tax_exemption && errors.tax_exemption ? (
                <span className="form__msg-error">{errors.tax_exemption}</span>
              ) : coreValues && coreValues.tax_exemption && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.tax_exemption}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="single_max_tax_exempt" labelText="Maximum tax exempt single yearly gifting amount">
              <InputCurrency
                icon
                placeholder="$0.00"
                name="single_max_tax_exempt"
                onChange={handleChange}
                value={values.single_max_tax_exempt || ''}
                onBlur={handleBlur}
                className={coreValues?.single_max_tax_exempt && !noEdit && 'form__input--core'}
                hasError={!!errors.single_max_tax_exempt && touched.single_max_tax_exempt}
              />
              {touched.single_max_tax_exempt && errors.single_max_tax_exempt ? (
                <span className="form__msg-error">{errors.single_max_tax_exempt}</span>
              ) : coreValues && coreValues.single_max_tax_exempt && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.single_max_tax_exempt}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="joint_max_tax_exempt" labelText="Maximum tax exempt joint yearly gifting amount">
              <InputCurrency
                icon
                placeholder="$0.00"
                name="joint_max_tax_exempt"
                onChange={handleChange}
                value={values.joint_max_tax_exempt || ''}
                onBlur={handleBlur}
                className={coreValues?.joint_max_tax_exempt && !noEdit && 'form__input--core'}
                hasError={!!errors.joint_max_tax_exempt && touched.joint_max_tax_exempt}
              />
              {touched.joint_max_tax_exempt && errors.joint_max_tax_exempt ? (
                <span className="form__msg-error">{errors.joint_max_tax_exempt}</span>
              ) : coreValues && coreValues.joint_max_tax_exempt && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.joint_max_tax_exempt}</span>
              ) : (
                ''
              )}
            </FormGroup>
          </div>
          <div className="form__button">
            <Button variant="contained" size="large" color="primary" type="submit" disabled={sending || noEdit}>
              Save
              {sending && <span className="spinner" />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default Estate
