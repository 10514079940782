import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from "@mui/styles";
import Rating from "@mui/material/Rating";
import SVGIcon from '../SVGIcon/SVGIcon'
import Icon from '../../../assets/images/icons/drop.svg'
import { useSelector } from 'react-redux'

const StyledRating = withStyles({
  iconFilled: {
    color: '#0F9AFF',
  }
})(Rating);

const Priority = ({ option, large }) => {
  const priority = useSelector(state => state.options.priorities)

  const value = priority && priority.length > 0
                  ? typeof option === 'string'
                    ? priority.find(i => i.label === option).rate
                    : priority.find(i => i.id === option).rate
                  : 0;

  return (
    <StyledRating
      value={value}
      readOnly
      className={`${large ? "rating--large" : ""}`}
      icon={<SVGIcon icon={Icon} />}
      emptyIcon={<SVGIcon icon={Icon} />}
    />
  );
}

Priority.propTypes = {
  option: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  large: PropTypes.bool
}

export default Priority
