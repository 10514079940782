import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../../components/Layout/Layout'
import { BackButton } from '../../components/shared/Buttons'
import Tooltip from '../../components/shared/Tooltip/Tooltip'
import { Slider, Input, FormGroup, FormSelect, InputCurrency } from '../../components/shared/Form'
import PercentIcon from '../../assets/images/icons/percentage.svg'
import {Button, FormControlLabel, Radio, RadioGroup} from '@mui/material'
import ModalBox from '../../components/shared/Modal/Modal'
import Tabs, { TabPanel } from '../../components/shared/Tabs/Tabs'
import SpeedometerLimit from '../../components/shared/Speedometer/SpeedometerLimit'
import {
  fetchListResults,
  fetchCalculators,
  saveItemResult,
  fetchCurrentResult,
  removeItemResult, setCurrentCalculator
} from '../../redux/slices/calculatorSlice'
import { PMTFormula, convertDecimal } from '../../utils/utils'
import { fetchHomeAffordabilityAdmin } from '../../redux/slices/calculatorsSetupSlice'

const HomeAffordability = ({ props }) => {
  const dispatch = useDispatch()
  const [tabActive, setActiveTab] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [isSavedModal, setIsSavedModal] = useState(false)
  const { id, company_id } = useSelector(state => state.user.user)
  const baseValues = useSelector(state => state.calculatorsSetup.homeAffordabilityAdmin);
  const { calculators, current, listExisting, loading } = useSelector(state => state.calculator)
  const [currentId, setCurrentId] = useState('')
  const [values, setValues] = useState(null)

  useEffect(() => {
    if(baseValues){
      setValues({
        currentId: '',
        name: '',
        ...baseValues
      })
    }
  // eslint-disable-next-line
  }, [baseValues])

  // const closingCosts = 0.02
  const [countValues, setCountValues] = useState({
    standardMortgage: 0,
    valueSoftAdjustedMortgage: 0,
    standardHomePrice: 0,
    standardDebtIncomeRatio: 0,
    valueSoftAdjustedDebtIncomeRatio: 0
  })

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchHomeAffordabilityAdmin(company_id));
      await dispatch(fetchCalculators({ path: 'home-affordability' }));
      await dispatch(fetchListResults('home-affordability'))
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    if(values) {
      let homeValuePurchase = +values.home_value * Math.pow((1 + +values.home_appreciation_rate/100), (+values.age_when_want_buy_home - +values.current_age));
    // let closingCostsAmount = closingCosts * homeValuePurchase;
    // let fundsRequiredPurchase = homeValuePurchase * values.down_payment/100 + closingCostsAmount;
    let estimatedMortgage = (PMTFormula(+values.mortgage_financing_rate/100, +values.mortgage_term, homeValuePurchase - (homeValuePurchase * +values.down_payment/100)) / 12*-1) + values.annual_insurance_premium/12 + (values.property_tax_rate/100 * homeValuePurchase)/12;
    let valueSoftAdjusted = estimatedMortgage + +values.annual_home_maintenance/12 + +values.monthly_utilities

    let divider = ((+values.current_household_annual_gross_income + +values.additional_annual_gross_income)/12)*Math.pow(1 + +values.estimated_wage_growth_rate/100, (+values.age_when_want_buy_home - +values.current_age))
    let standardMortgage = estimatedMortgage/divider
    let valueSoftAdjustedMortgage = valueSoftAdjusted/divider
    let standardHomePrice = homeValuePurchase/((+values.current_household_annual_gross_income + +values.additional_annual_gross_income)*Math.pow((1 + +values.estimated_wage_growth_rate/100), (+values.age_when_want_buy_home - +values.current_age)))
    let standardDebtIncomeRatio = (+values.car_loan_monthly_payment + +values.student_loan_monthly_payment + +values.credit_balance_monthly_interest + +values.other_debt_monthly_payment + estimatedMortgage)/divider
    let valueSoftAdjustedDebtIncomeRatio = (+values.car_loan_monthly_payment + +values.student_loan_monthly_payment + +values.credit_balance_monthly_interest + +values.other_debt_monthly_payment + valueSoftAdjusted)/divider

    setCountValues({
      standardMortgage: standardMortgage.toFixed(2) * 100,
      valueSoftAdjustedMortgage: valueSoftAdjustedMortgage.toFixed(2) * 100,
      standardHomePrice: +standardHomePrice.toFixed(1),
      standardDebtIncomeRatio: standardDebtIncomeRatio.toFixed(2) * 100,
      valueSoftAdjustedDebtIncomeRatio: valueSoftAdjustedDebtIncomeRatio.toFixed(2) * 100
    })
    }
    // eslint-disable-next-line
  }, [values])

  useEffect(() => {
    async function fetchNewData() {
      if (current) {
        setValues({
          name: current ? current.name : '',
          currentId: currentId,
          current_age: +current.current_age,
          current_household_annual_gross_income: +current.current_household_annual_gross_income,
          additional_annual_gross_income: +current.additional_annual_gross_income,
          car_loan_monthly_payment: +current.car_loan_monthly_payment,
          student_loan_monthly_payment: +current.student_loan_monthly_payment,
          credit_balance_monthly_interest: +current.credit_balance_monthly_interest,
          other_debt_monthly_payment: +current.other_debt_monthly_payment,
          age_when_want_buy_home: +current.age_when_want_buy_home,
          home_value: +current.home_value,
          mortgage_financing_rate: +current.mortgage_financing_rate,
          mortgage_term: +current.mortgage_term,
          down_payment: +current.down_payment,
          annual_insurance_premium: +current.annual_insurance_premium,
          annual_home_maintenance: +current.annual_home_maintenance,
          monthly_utilities: +current.monthly_utilities,
          estimated_wage_growth_rate: +current.estimated_wage_growth_rate,
          home_appreciation_rate: +current.home_appreciation_rate,
          property_tax_rate: +current.property_tax_rate,
          affordability_ratio_standards: current.affordability_ratio_standards,
        })
      }
    }

    if (currentId) {
      fetchNewData()
    }
    // eslint-disable-next-line
  }, [currentId, current])

  const handleInputChange = e => {
    setValues({
      ...values,
      [e.target.name]: +e.target.value
    })
  }

  // convert value to integer number
  const handleInputIntegerChange = e => {
    setValues({
      ...values,
      [e.target.name]: e.target.value.replace(/[^0-9]/g, "")
    })
  }

  const handleCurrencyChange = e => {
    setValues({
      ...values,
      [e.target.name]: +convertDecimal(e.target.value)
    })
  }

  const changeCurrentData = async e => {
    await setCurrentId(e.target.value)
    await dispatch(fetchCurrentResult(e.target.value,'home-affordability'))
  }

  const onSave = async () => {
    dispatch(saveItemResult(values, 'home-affordability')).then(res => {
      const calculator = res?.data?.data;

      if(calculator) {
        setIsSavedModal(true);
        setCurrentId(calculator.id);
        dispatch(setCurrentCalculator(calculator));
      }
    }).finally(() => {
      setIsOpen(false);
    })
  }

  const resetForm = () => {
    setValues({
      currentId: '',
      name: '',
      ...baseValues
    })
    setCurrentId('')
  }

  const removeResult = async () => {
    await dispatch(removeItemResult(currentId, 'home-affordability'))
    await setCurrentId('')
    await setIsDeleteModal(false)
    await setActiveTab(0)
    await setValues({
      ...baseValues
    })
  }

  return (
    <Layout decor>
      <BackButton title="Back to Calculators" path="/calculators" className="back-button--position" />

      <div className="calculators__title">
        <div>
          {calculators && <h4>{calculators.name}</h4>}
          {calculators &&
          <Tooltip
            title={
              <>
                <h6>{calculators.name}</h6>
                {calculators.tooltip}
                <hr className="tooltip__line"/>
                <h6>Example</h6>
                {calculators.example}
              </>
            }
          />}
        </div>
        {listExisting && listExisting.length > 0 && <div className="calculators__title-select">
          <FormSelect
            options={listExisting}
            id="currentId"
            value={currentId}
            medium
            name="currentId"
            onChange={changeCurrentData}
            placeholder="Select result"
          />
        </div>}
      </div>

      <Tabs
        handleChange={(event, newValue) => {
          setActiveTab(newValue)
        }}
        variant="scrollable"
        value={tabActive}
        tabs={[
          {title: 'Profile info'},
          {title: 'Purchase details'},
          {title: 'Assumptions'}
        ]}
      />
    {values &&   
      <div className="calculators__holder">
        <div className="calculators__content">
          <TabPanel value={tabActive} index={0}>
            <h6 className="calculators__subtitle">Step 1: Input profile info</h6>
            <div className="calculators__area">
              <Input
                type="number"
                name="current_age"
                value={values.current_age.toString()}
                small
                smallHeight
                onChange={handleInputIntegerChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100}
                  step={1}
                  label="Current age"
                  value={+values.current_age}
                  onChange={(e, newValue) => setValues({...values, current_age: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <InputCurrency
                name="current_household_annual_gross_income"
                value={values.current_household_annual_gross_income.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={500000}
                  step={5}
                  label="Current household annual gross income "
                  value={+values.current_household_annual_gross_income}
                  onChange={(e, newValue) => setValues({
                    ...values,
                    current_household_annual_gross_income: newValue
                  })}
                />
              </div>
            </div>
            <div className="calculators__area">
              <InputCurrency
                name="additional_annual_gross_income"
                value={values.additional_annual_gross_income.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={40000}
                  step={1}
                  label="Additional annual gross income "
                  value={+values.additional_annual_gross_income}
                  onChange={(e, newValue) => setValues({...values, additional_annual_gross_income: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <InputCurrency
                name="car_loan_monthly_payment"
                value={values.car_loan_monthly_payment.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={40000}
                  step={1}
                  label="Car loan monthly payment"
                  value={+values.car_loan_monthly_payment}
                  onChange={(e, newValue) => setValues({...values, car_loan_monthly_payment: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <InputCurrency
                name="student_loan_monthly_payment"
                value={values.student_loan_monthly_payment.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={500}
                  step={1}
                  label="Student loan mothly payment"
                  value={+values.student_loan_monthly_payment}
                  onChange={(e, newValue) => setValues({...values, student_loan_monthly_payment: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <InputCurrency
                name="credit_balance_monthly_interest"
                value={values.credit_balance_monthly_interest.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={5000}
                  step={1}
                  label="Credit card balance monthly interest payment"
                  value={+values.credit_balance_monthly_interest}
                  onChange={(e, newValue) => setValues({...values, credit_balance_monthly_interest: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <InputCurrency
                name="other_debt_monthly_payment"
                value={values.other_debt_monthly_payment.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={5000}
                  step={1}
                  label="Other debt monthly payment"
                  value={+values.other_debt_monthly_payment}
                  onChange={(e, newValue) => setValues({...values, other_debt_monthly_payment: newValue})}
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel value={tabActive} index={1}>
            <h6 className="calculators__subtitle">Step 2: Input purchase details</h6>
            <div className="calculators__area">
              <Input
                type="number"
                name="age_when_want_buy_home"
                value={values.age_when_want_buy_home.toString()}
                small
                smallHeight
                onChange={handleInputIntegerChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100}
                  step={1}
                  label="Age you’d like to buy home"
                  value={+values.age_when_want_buy_home}
                  onChange={(e, newValue) => setValues({...values, age_when_want_buy_home: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <InputCurrency
                name="home_value"
                value={values.home_value.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={500000}
                  step={1}
                  label="Home value (Today’s $)"
                  value={+values.home_value}
                  onChange={(e, newValue) => setValues({...values, home_value: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="mortgage_financing_rate"
                value={values.mortgage_financing_rate.toString()}
                small
                smallHeight
                step={0.1}
                onChange={handleInputChange}
                iconName={PercentIcon}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100}
                  step={0.5}
                  label="Mortgage financing rate"
                  value={+values.mortgage_financing_rate}
                  onChange={(e, newValue) => setValues({...values, mortgage_financing_rate: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="mortgage_term"
                value={values.mortgage_term.toString()}
                small
                smallHeight
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100}
                  step={0.5}
                  label="Mortgage term"
                  value={+values.mortgage_term}
                  onChange={(e, newValue) => setValues({...values, mortgage_term: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="down_payment"
                value={values.down_payment.toString()}
                small
                smallHeight
                step={0.1}
                onChange={handleInputChange}
                iconName={PercentIcon}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100}
                  step={0.5}
                  label="Downpayment"
                  value={+values.down_payment}
                  onChange={(e, newValue) => setValues({...values, down_payment: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <InputCurrency
                name="annual_insurance_premium"
                value={values.annual_insurance_premium.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={5000}
                  step={1}
                  label="Annual insurance premium"
                  value={+values.annual_insurance_premium}
                  onChange={(e, newValue) => setValues({...values, annual_insurance_premium: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <InputCurrency
                name="annual_home_maintenance"
                value={values.annual_home_maintenance.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={5000}
                  step={1}
                  label="Annual home maintenance"
                  value={+values.annual_home_maintenance}
                  onChange={(e, newValue) => setValues({...values, annual_home_maintenance: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <InputCurrency
                name="monthly_utilities"
                value={values.monthly_utilities.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={1000}
                  step={1}
                  label="Monthly utilities"
                  value={+values.monthly_utilities}
                  onChange={(e, newValue) => setValues({...values, monthly_utilities: newValue})}
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel value={tabActive} index={2}>
            <h6 className="calculators__subtitle">Step 3: Input assumptions</h6>
            <div className="calculators__area">
              <Input
                type="number"
                name="estimated_wage_growth_rate"
                value={values.estimated_wage_growth_rate.toString()}
                small
                smallHeight
                step={0.1}
                onChange={handleInputChange}
                iconName={PercentIcon}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100}
                  step={0.5}
                  label="Estimated annual wage growth rate"
                  value={+values.estimated_wage_growth_rate}
                  onChange={(e, newValue) => setValues({...values, estimated_wage_growth_rate: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="home_appreciation_rate"
                value={values.home_appreciation_rate.toString()}
                small
                smallHeight
                step={0.1}
                onChange={handleInputChange}
                iconName={PercentIcon}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100}
                  step={0.5}
                  label="Home appreciation rate"
                  value={+values.home_appreciation_rate}
                  onChange={(e, newValue) => setValues({...values, home_appreciation_rate: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="property_tax_rate"
                value={values.property_tax_rate.toString()}
                small
                smallHeight
                step={0.1}
                onChange={handleInputChange}
                iconName={PercentIcon}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100}
                  step={0.5}
                  label="Property tax rate"
                  value={+values.property_tax_rate}
                  onChange={(e, newValue) => setValues({...values, property_tax_rate: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area-label">
              Which affordability ratio standards would you like to use?
            </div>
            <RadioGroup
              name="affordability_ratio_standards"
              value={values.affordability_ratio_standards || 'conventional'}
              onChange={e => {
                setValues({
                  ...values,
                  [e.target.name]: e.target.value
                })
              }}
              className="radio-list-inline"
            >
              <FormControlLabel value="fha" control={<Radio/>} label="FHA"/>
              <FormControlLabel value="conventional" control={<Radio/>} label="Conventional"/>
            </RadioGroup>
          </TabPanel>
        </div>
        <div className="calculators__preview">
          <h6 className="calculators__subtitle">Results: home affordability alerts</h6>
          <div className="calculators__preview-box">
            <div className="calculators__cases">
              <div>
                <SpeedometerLimit
                  limit={values.affordability_ratio_standard === 'fha' ? [29] : [28]}
                  count={countValues.standardMortgage}
                  large
                  label sign
                />
                <div className="calculators__case-title">
                  Standard Mortgage to Income Ratio
                </div>
              </div>
              <div>
                <SpeedometerLimit
                  limit={values.affordability_ratio_standard === 'fha' ? [29] : [28]}
                  count={countValues.valueSoftAdjustedMortgage}
                  large
                  label sign
                />
                <div className="calculators__case-title">
                  ValueSoft Adjusted Mortgage to Income Ratio
                </div>
              </div>
              <div>
                <SpeedometerLimit
                  limit={values.affordability_ratio_standard === 'fha' ? [3] : [3]}
                  count={countValues.standardHomePrice}
                  large
                  label number
                />
                <div className="calculators__case-title">
                  Standard Home Price to Income Ratio
                </div>
              </div>
              <div>
                <SpeedometerLimit
                  limit={values.affordability_ratio_standard === 'fha' ? [41] : [36]}
                  count={countValues.standardDebtIncomeRatio}
                  large
                  label sign
                />
                <div className="calculators__case-title">
                  Standard Debt to Income Ratio
                </div>
              </div>
              <div>
                <SpeedometerLimit
                  limit={values.affordability_ratio_standard === 'fha' ? [41] : [36]}
                  count={countValues.valueSoftAdjustedDebtIncomeRatio}
                  large
                  label sign
                />
                <div className="calculators__case-title">
                  ValueSoft Adjusted Debt to Income Ratio
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }  
      <div className="calculators__buttons">
        <Button variant="contained" size="large" color="primary" onClick={() => setIsOpen(true)}>Save result</Button>
        {currentId && <Button variant="contained" size="large" onClick={() => setIsDeleteModal(true)}>Remove result</Button>}
        <Button size="large" className="MuiButton-textGray" onClick={() => resetForm()}>Reset</Button>
      </div>

      <ModalBox
        open={isOpen}
        close={() => setIsOpen(false)}
        onClick={onSave}
        button="Save"
        title="Save Result"
        isLoading={loading}
        isDisabled={values && !values.name && !values.currentId}
      >
        <FormGroup name="name" labelText="Name">
          <Input
            name='name'
            value={values ? values.name : ''}
            placeholder="Type a name of result"
            onChange={e => setValues({
              ...values,
              name: e.target.value
            })}
          />
        </FormGroup>
        {listExisting && listExisting.length > 0 &&
        <>
          <p className="text-center">or</p>
          <FormGroup labelText="Select  Existing Result">
            <FormSelect
              options={listExisting}
              value={values ? values.currentId : ''}
              name="currentId"
              onChange={e => setValues({
                ...values,
                currentId: e.target.value,
                name: listExisting.find(i => i.value === e.target.value).label
              })}
              placeholder="Select Existing Result"
            />
          </FormGroup>
        </>
        }
      </ModalBox>
      <ModalBox
        small
        open={isDeleteModal}
        close={() => setIsDeleteModal(false)}
        onClick={removeResult}
        button="Remove"
        title={`Do you want to remove ${current && current.name}?`}
      />

      <ModalBox
        small
        open={isSavedModal}
        close={() => setIsSavedModal(false)}
        button="Ok"
        onClick={() => setIsSavedModal(false)}
      >
        <div>
          {calculators && calculators.iconLeft &&
          <div className="modal__title-iconLeft">
            <img src={calculators.iconLeft} alt={calculators.name} />
          </div>}
          <h4>Your calculator scenario has been saved.</h4>
          <p>You can access your saved scenarios through the “Select result” dropdown menu at the top of each calculator page.</p>
        </div>
      </ModalBox>
    </Layout>
  )
}

export default HomeAffordability
