import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { BackButton, AddButton } from '../../../components/shared/Buttons'
import Layout from '../../../components/Layout/Layout'
import LayoutForm from '../../../components/Layout/LayoutForm'
import PageTitle from '../../../components/PageTitle/PageTitle'
import ModalBox from '../../../components/shared/Modal/Modal'
import {FormGroup, FormSelect, InputCurrency} from '../../../components/shared/Form'
import { Button } from '@mui/material'
import { setIsOpen } from '../../../redux/slices/modalSlice'
import { setMode } from '../../../redux/slices/modeSlice'
import ExpenseCard from '../../../components/Cards/ExpenseCard'
import Illustration from '../../../assets/images/illustrations/Tree-segment_finances-min.png'
import { fetchUserExpenses,
  fetchUserExpensesById,
  updateExpenseItem,
  postExpenses,
  removeExpenseItem,
  fetchUserExpensesByParam,
  setIsOpenModal
} from '../../../redux/slices/financesSlice'
import IconOther from '../../../assets/images/icons/other.svg'
import { expensesSchema } from '../../../utils/validationFinances'
import { fetchTotalPercent } from '../../../redux/slices/percentagesSlice'
import { getPercent } from '../../../utils/utils'
import SVGIcon from '../../../components/shared/SVGIcon/SVGIcon'
import Icon from '../../../assets/images/icons/icon-ongratulations.svg'
import { setNewTasksCount } from '../../../redux/slices/taskSlice'
import Priority from '../../../components/shared/Priority/Priority'
import Loading from '../../../components/shared/Loading/Loading'
import ProgressCircle from '../../../components/shared/ProgressBar/ProgressCircle'

const typeList = [
  {
    key: 'monthly',
    value: 'monthly',
    label:  'Monthly'
  },
  {
    key: 'yearly',
    value: 'yearly',
    label:  'Yearly'
  }
]

const Expenses = ({ props }) => {
  const dispatch =useDispatch()
  const { id } = useSelector(state => state.user.user)
  const isBasic = useSelector((state) => state.user.user.plan_type === 1);
  const totalPercent = useSelector(state => state.percentages.total)
  const [isOpenTasksModal, setOpenTasksModal] = useState(false)
  const { expenses, listExpenses, currentExpenses, isLoading, isOpenModal } = useSelector(state => state.finances)
  const newTasksCount = useSelector(state => state.task.newTasksCount)
  const mode = useSelector(state => state.mode.mode)
  const isOpen = useSelector(state => state.modal.isOpen)
  const percents = useSelector(state => state.percentages.percents)
  const sending = useSelector(state => state.sending.isSending)
  const [isMobile, setIsMobile] = useState(false);
  const mobileView = 999;
  const handleResize = () => {
    (mobileView >= window.innerWidth) ? setIsMobile(true) : setIsMobile(false)
  }

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    async function fetch() {
      //await dispatch(fetchPercentages())
      await dispatch(fetchUserExpenses())
      await dispatch(fetchUserExpensesByParam({status: 'active'}))
      await dispatch(fetchTotalPercent(isBasic))
    }
    if(id) {
      fetch()
    }

    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    async function fetchTasks() {
      if (!!newTasksCount) {
        await setOpenTasksModal(!!newTasksCount)
      }
    }

    if (id) {
      fetchTasks()
    }

    // eslint-disable-next-line
  }, [newTasksCount])

  const convertExpenseData = item => {
    let { id, filled } = item
    let body = null
    if (id) { body = id }

    return {
      ...item,
      amount: filled ? filled.amount : 0,
      body
    }
  }

  const onClose = () => {
    dispatch(setIsOpen({isOpen: false}))
    dispatch(setMode(null))
  }

  const closeTasksModal = () => {
    dispatch(setNewTasksCount(0))
    setOpenTasksModal(false)
    dispatch(fetchTotalPercent(isBasic))
  }

  const onOpen = mode => {
    dispatch(setMode(mode))
    dispatch(setIsOpen({isOpen: true}))
  }

  const onEdit = id => {
    dispatch(setMode('edit'))
    dispatch(fetchUserExpensesById(id))
    dispatch(setIsOpen({isOpen: true}))
    dispatch(fetchTotalPercent(isBasic));
  }

  const onDelete = id => {
    dispatch(removeExpenseItem(id))
    dispatch(fetchTotalPercent(isBasic));
  }

  const updateExpense = values => {
    dispatch(updateExpenseItem(currentExpenses.id, values))
  }

  const createExpense = values => {
    dispatch(postExpenses(values))
  }

  const expensePercent = getPercent(percents, 'expense')
  const profile        = getPercent(percents, 'profile')

  return (
    <Layout totalPercent={totalPercent} sticky isBlockedPage={percents && profile === 0}>
      <LayoutForm medium illustration={Illustration}>
        <BackButton title={isMobile ? 'Back' : 'Back to Tree'} />
        <PageTitle
          title="Expenses"
          content="Tell us about your monthly expenses so we can better understand your budget."
          status={percents && expensePercent ? expensePercent : '0'}
          info={
            <>
              <h6>Expenses</h6>
              <Priority option="Very High" />
              <div>Enter all your itemized expenses. This is an important component to calculating your financial condition and estimating your current and future net worth.</div>
            </>
          }
        />
        <div className="layout-form__content-holder expense__holder">
          {isLoading && <Loading small />}
          {expenses && expenses.length > 0 && expenses.map(item => (
            <React.Fragment key={item.id}>
              <ExpenseCard
                id={item.id}
                icon={item.icon}
                name={item.name}
                amount={item.filled ? item.filled.amount : '0'}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            </React.Fragment>
          ))}

          {listExpenses && <div className="expense__button button__mobile-sticky">
            <AddButton onClick={() => onOpen('create')} title="Add a new expense" full smallMobile />
          </div>}
          <ModalBox
            open={isOpen}
            close={onClose}
            title={mode === 'create' ? 'New expense' : 'Edit expense'}
          >
            <Formik
              enableReinitialize
              initialValues={mode === 'edit' && currentExpenses
                ? {...convertExpenseData(currentExpenses)}
                : {
                  expense_id: null,
                  amount: null,
                  body: null,
                  type: null
                }
              }
              validationSchema={expensesSchema}
              onSubmit={mode === 'create' ? createExpense : updateExpense}
            >
              {({ errors, handleChange, handleBlur, values, touched, setFieldValue }) => (
                <Form>
                  {mode === 'edit'
                    ? (
                      <div className="expense__modal-title">
                        <div className="expense__modal-title-icon">
                          <img src={currentExpenses && currentExpenses.icon ? currentExpenses.icon : IconOther} alt={currentExpenses && currentExpenses.name} />
                        </div>
                        <strong>{currentExpenses && currentExpenses.name}</strong>
                      </div>
                    ) : (
                      <FormGroup
                        name="body"
                        labelText="Category"
                      >
                        <FormSelect
                          isCreatable
                          options={listExpenses}
                          value={values.body || ''}
                          name="body"
                          onChange={handleChange}
                          placeholder="Select or Create new"
                          setFieldValue={setFieldValue}
                          hasError={!!errors.body}
                        />
                      </FormGroup>
                    )
                  }
                  <FormGroup
                    name="type"
                    labelText="Type"
                  >
                    <FormSelect
                      options={typeList}
                      value={values.type || ''}
                      name="type"
                      onChange={handleChange}
                      placeholder="Monthly or Yearly"
                      setFieldValue={setFieldValue}
                      hasError={!!errors.type}
                    />
                    {errors.type && <span className="form__msg-error">{errors.type}</span>}
                  </FormGroup>
                  <FormGroup name="amount" labelText="Amount">
                    <InputCurrency
                      placeholder="Amount"
                      name="amount"
                      onChange={handleChange}
                      value={values.amount || ''}
                      onBlur={handleBlur}
                      hasError={!!errors.amount}
                    />
                    {errors.amount && <span className="form__msg-error">{errors.amount}</span>}
                  </FormGroup>
                  <div className="form__button form__button--center">
                    <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                      Save
                      {sending && <span className="spinner" />}
                    </Button>
                  </div>
                </Form>
                )}
            </Formik>
          </ModalBox>
        </div>

        <ModalBox
          open={isOpenTasksModal}
          small
          close={closeTasksModal}
        >
          <div className="modal__title-icon">
            <SVGIcon icon={Icon} />
          </div>
          <h3>Congratulations!</h3>
          You filled out the step and <Link to="/tasks" className="text-success" onClick={() => closeTasksModal()}><strong>{newTasksCount}</strong></Link> new tasks were created.
        </ModalBox>

        <ModalBox
          open={isOpenModal}
          small
          button="Done"
          onClick={() => dispatch(setIsOpenModal(false))}
          close={() => dispatch(setIsOpenModal(false))}
        >
          <h3>Good Job!</h3>
          <p>Your progress increased 100%</p>
          <ProgressCircle amount={100} small />
        </ModalBox>
      </LayoutForm>
    </Layout>
  )
}

export default Expenses
