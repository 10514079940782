import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Loading from '../shared/Loading/Loading'

const WrapWidget = ({ title, link, children, className, placeholder, isPlaceholder, loading, addClassName }) => {
  return (
    <div className={`widget ${className || ''}`}>
      <div className="widget__top">
        <div className={`widget__heading ${addClassName || ''}`}>{title}</div>
        {link && <Link to={link.path} className="widget__link">{link.title}</Link>}
      </div>
      <div className="widget__content">
        {
          loading
            ? <Loading small />
            : isPlaceholder
              ? <>{placeholder}</>
              : <>{children}</>
        }
      </div>
    </div>
  );
};

WrapWidget.propTypes = {
  title: PropTypes.string,
  link: PropTypes.object,
  children: PropTypes.any,
  className: PropTypes.string,
  isPlaceholder: PropTypes.bool,
  loading: PropTypes.bool
};

export default WrapWidget;
