import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import WrapWidget from "./WrapWidget";
import { Button } from "@mui/material";
import Slider from 'react-slick'
import ProgressBar from "../shared/ProgressBar/ProgessBar";
import Progress1 from "../../assets/images/progress/progress-tree.png";
import Progress2 from "../../assets/images/progress/progress-tree2.png";
import Progress3 from "../../assets/images/progress/progress-tree3.png";
import TasksImage from "../../assets/images/tree/tree-tasks.png";

const WidgetProgress = ({
  items,
  totalPercent,
  className,
  addClassName,
}) => {
  let history = useHistory();
  const sliderRef = useRef();

  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: "slick-dots custom-dots",
  };

  useEffect(() => {
    totalPercent === 100 ? sliderRef.current.slickGoTo(1, true) : sliderRef.current.slickGoTo(0, true);
  }, [totalPercent]);

  const progressImage = (val) => {
    if (val <= 33) {
      return Progress1;
    } else if (val >= 66) {
      return Progress3;
    }
    return Progress2;
  };

  return (
    <WrapWidget
      title="Progress"
      className={`widget--progress ${className || ""}`}
      addClassName={addClassName}
    >
      {items && items.length > 0 && (
        <Slider ref={sliderRef} {...settings}>
          {items.map((item, i) => (
            <div key={`slide-${i}`}>
              <div className={`widget__image ${!item.tree ? 'widget__image--acorn' : ''}`}>
                <img
                  src={item.tree ? progressImage(item.percent) : TasksImage}
                  alt="Tree"
                />
              </div>
              <div className="widget__info widget__info--slide">
                <div>
                  <div className="mb--medium">
                    <ProgressBar amount={item.percent} showLabel />
                  </div>
                  <h3>{item.title}</h3>
                  <p>{item.content}</p>
                </div>

                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={() => history.push(item.path)}
                >
                  {item.button}
                </Button>
              </div>
            </div>
          ))}
        </Slider>
      )}
    </WrapWidget>
  );
};

WidgetProgress.propTypes = {
  addClassName: PropTypes.string,
};

export default WidgetProgress;
