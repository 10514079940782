const optionsPriorities = [
  { id: 1, name: 'Profile Management', priority: 1 },
  { id: 2, name: 'Goals and Dreams', priority: 2 },
  { id: 3, name: 'Education Planning', priority: 3 },
  { id: 4, name: 'Budgeting', priority: 4 },
  { id: 5, name: 'Document management', priority: 5 },
  { id: 6, name: 'Retirement planning', priority: 6 },
  { id: 7, name: 'Insurance planning', priority: 7 },
  { id: 8, name: 'Estate planning', priority: 8 },
  { id: 9, name: 'Other Planning', priority: 9 },
]

export default optionsPriorities;