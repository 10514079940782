import axios from 'axios';
import {
  BASE_URL,
  AUTH,
  USER,
  COMPANIES,
  RISK,
  DEPENDETNS,
  USERCOMPANIES,
  EDUCATIONS,
  FUTURE_CHILDREN,
  EXPENSES,
  INCOMES,
  TASKS,
  INCREMENTAL,
  INSURANCE,
  STOCK,
  BLOG,
  ADMIN,
  ANALYSIS
} from '../constants/api'

import { refreshTokenResponse, refreshTokenRequest } from "./middlewares";


let headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const API = axios.create({
  baseURL: BASE_URL,
  headers
});

API.interceptors.request.use(refreshTokenRequest.onSuccess, refreshTokenRequest.onFailure)
API.interceptors.response.use(refreshTokenResponse.onSuccess, refreshTokenResponse.onFailure);

// let token = localStorage.token;
// API.defaults.headers.Authorization = `Bearer ${token}`;

const Auth = {
  authInfo: () => API.get(`${BASE_URL}/settings`),
  authSettings: data => API.post(`${BASE_URL}/settings`, data),
  getMemberInfo: token => API.get(`${AUTH}/register-company-member/${token}`),
  createMember: data => API.post(`${AUTH}/register-company-member`, data),
  create: data => API.post(`${AUTH}/step`, data),
  register: data => API.post(`${AUTH}/register`, data),
  regCompany: data => API.post(`${AUTH}/company-account-application`, data),
  resend: data => API.post(`${AUTH}/email/resend`, data),
  login: data => API.post(`${AUTH}/login`, data),
  mailConfirm: data => API.post(`${AUTH}/send-confirm-email`, data),
  phoneConfirm: data => API.post(`${AUTH}/send-confirm-sms`, data),
  codeConfirm: data => API.post(`${AUTH}/confirm-code`, data),
  forgot: data => API.post(`${AUTH}/password/email`, data),
  reset: data => API.post(`${AUTH}/password/reset`, data),
  refreshToken: data => API.post(`${AUTH}/refresh`, data)

};

const User = {
  postPlanningTypes: (id, data) => API.post(`${USER}/planning-types`, data),
  postPlanType: (data) => API.post(`${USER}/plan-type`, data),
  getInfo: () => API.get(`${BASE_URL}/info`),
  postInfo: (data) => API.post(`${BASE_URL}/info`, data),
  getDetails: () => API.get(`${BASE_URL}/details`),
  postDetails: (data) => API.post(`${BASE_URL}/details`, data),
  getAssets: () => API.get(`${BASE_URL}/assets`),
  postAssets: (data) => API.post(`${BASE_URL}/assets`, data),
  getFamilyData: () => API.get(`${BASE_URL}/family`),
  postFamilyData: (data) => API.post(`${BASE_URL}/family`, data),
  getChildrenData: () => API.get(`${USER}/children`),
  postChildrenData: (data) => API.post(`${USER}/children`, data),
  fetchUserValues: () => API.get(`${USER}/values`),
  postUserValues: (data) => API.post(`${USER}/values`, data),
  fetchUserDreams: () => API.get(`${USER}/dreams`),
  postUserDreams: (data) => API.post(`${USER}/dreams`, data),
  fetchUserGoals: () => API.get(`${USER}/goals`),
  postUserGoals: (data) => API.post(`${USER}/goals`, data),
  resetData: () => API.post(`${BASE_URL}/reset`),
  planPopupDelay: (data) => API.post(`${USER}/plan-popup-show-delay`, data),
  planTasksPopupDelay: (data) =>
    API.post(`${USER}/plan-choose-task-delay`, data),
  getPushNotificationPlan: () => API.get(`${BASE_URL}/push-notification-plan`),
  postPushNotificationPlan: (data) =>
    API.post(`${USER}/set-push-notification-plan`, data),
  postUnsubscribePushNotificationPlan: (data) =>
    API.post(`${BASE_URL}/unsubscribe`, data),
  getDelayTime: () => API.get(`${USER}/plan-delay-popups`),
  fetchExtraData: () => API.get(`${USER}/different-fields`),
};

const Companies = {
  get: (params) => API.get(`${COMPANIES}`, { params }),
  fetch: (id) => API.get(`${COMPANIES}/${id}`),
  postCompInfo: (data) => API.post(`${COMPANIES}`, data),
  postCompPermissions: (id, data) => API.post(`${COMPANIES}/${id}/permissions`, data),
  postCompMember: (id, data) => API.post(`${COMPANIES}/${id}/members`, data),
  putCompany: (id, data, params) => API.post(`${COMPANIES}/${id}`, data, { params }),
  updateLogo: (id, data) => API.post(`${COMPANIES}/${id}/replace-logo`, data),
  del: (id) => API.delete(`${COMPANIES}/${id}`),
  getMembers: (id, params) => API.get(`${COMPANIES}/${id}/members`, { params }),
  updateMember: (id, memberId, data, params) => API.post(`${COMPANIES}/${id}/members/${memberId}`, data, { params }),
  getMember: (id, memberId) => API.get(`${COMPANIES}/${id}/members/${memberId}`),
  deleteMember: (id, memberId) => API.delete(`${COMPANIES}/${id}/members/${memberId}`),
  getMemberUnconfirmed: (id, memberId) => API.get(`${COMPANIES}/${id}/unconfirmed-members/${memberId}`),
  updateMemberUnconfirmed: (id, memberId, data) => API.put(`${COMPANIES}/${id}/unconfirmed-members/${memberId}`, data),
  deleteMemberUnconfirmed: (id, memberId) => API.delete(`${COMPANIES}/${id}/unconfirmed-members/${memberId}`),
  postNewOwner: (id, memberId) => API.post(`${COMPANIES}/${id}/members/${memberId}/set-owner`),
  postSeatsLimit: (id, data) => API.put(`${COMPANIES}/${id}/edit-seats-limit`, data),
  putNotifySeatsLimit: (id) => API.put(`${COMPANIES}/${id}/notify-seats-limit-exhausted`),
  sendRequestSeatsLimit: (id) => API.post(`${COMPANIES}/${id}/send-request-seats-limit-increase`),
  getPermissions: (id) => API.get(`${COMPANIES}/${id}/permissions`),
  putCompPermissions: (id, data) => API.put(`${COMPANIES}/${id}/permissions`, data),
  getPlanners: (id) => API.get(`${COMPANIES}/${id}/members/planners`),
  assignPlanner: (id, data) => API.post(`${COMPANIES}/${id}/members/assign-planner`, data),
  postCompNewAdminCheck: (id, data) => API.post(`${COMPANIES}/${id}/members/search`, data),
  getUserToken: (companyId, memberId) => API.get(`${COMPANIES}/${companyId}/members/${memberId}/get-member-token`),
};

const Spouse = {
  get: () => API.get(`${BASE_URL}/spouse`),
  post: data => API.post(`${BASE_URL}/spouse`, data),
  getValues: () => API.get(`${USER}/values/spouse`),
  postValues: data => API.post(`${USER}/values/spouse`, data),
  getDreams: () => API.get(`${USER}/dreams/spouse`),
  postDreams: data => API.post(`${USER}/dreams/spouse`, data),
  getGoals: () => API.get(`${USER}/goals/spouse`),
  postGoals: data => API.post(`${USER}/goals/spouse`, data),
};

const Options = {
  fetchPlanningTypes: (params) => API.get(`${BASE_URL}/planning-types`,{ params }),
  fetchPlanTypes: () => API.get(`${BASE_URL}/plan-types`),
  fetchPriorities: () => API.get(`${BASE_URL}/priorities`),
  fetchExpenses: () => API.get(`${BASE_URL}/expenses`),
  fetchIncomes: () => API.get(`${BASE_URL}/incomes`),
  fetchValues: () => API.get(`${BASE_URL}/values`),
  fetchTasksStatus: (params) => API.get(`${BASE_URL}/task_statuses`, { params }),
  fetchStatus: (params) => API.get(`${BASE_URL}/statuses`, { params }),
  fetchCategories: () => API.get(`${BASE_URL}/categories`)
};

const Profile = {
  reset: data => API.post(`${BASE_URL}/password`, data),
  post: data => API.post(`${BASE_URL}/profile`, data),
  postAvatar: data => API.post(`${BASE_URL}/profile/image/upload`, data)
};

const Risk = {
  get: () => API.get(`${RISK}/profile`),
  post: data => API.post(`${RISK}/profile`, data),
  getPortfolio: data => API.get(`${RISK}/portfolio`, data),
  postPortfolio: data => API.post(`${RISK}/portfolio`, data),
};

const UserCompanies = {
  get: () => API.get(`${USERCOMPANIES}`),
  post: (data) => API.post(`${USERCOMPANIES}`, data),
  fetch: (id) => API.get(`${USERCOMPANIES}/${id}`),
  put: (id, data) => API.put(`${USERCOMPANIES}/${id}`, data),
  del: (id) => API.delete(`${USERCOMPANIES}/${id}`),
}

const Dependents = {
  get: () => API.get(`${DEPENDETNS}`),
  getEducation: () => API.get(`${EDUCATIONS}`),
  post: (data) => API.post(`${DEPENDETNS}`, data),
  fetch: (id) => API.get(`${DEPENDETNS}/${id}`),
  put: (id, data) => API.put(`${DEPENDETNS}/${id}`, data),
  del: (id) => API.delete(`${DEPENDETNS}/${id}`),
  delDpend: (id) => API.delete(`${DEPENDETNS}/${id}/education/${id}`),
  delFutureEducation: (id) =>
    API.delete(`${FUTURE_CHILDREN}/children/${id}/education/${id}`),
  getFutureChildren: () => API.get(`${FUTURE_CHILDREN}/children`),
  postFutureChild: (data) => API.post(`${FUTURE_CHILDREN}/children`, data),
  fetchFutureChild: (id) => API.get(`${FUTURE_CHILDREN}/children/${id}`),
  putFutureChild: (id, data) =>
    API.put(`${FUTURE_CHILDREN}/children/${id}`, data),
  delFutureChild: (id) => API.delete(`${FUTURE_CHILDREN}/children/${id}`),
  getUmbrellaChildren: () =>
    API.get(`${BASE_URL}/calculators/umbrella-children`),
  fetchUmbrellaChild: (id) =>
    API.get(`${BASE_URL}/calculators/umbrella-children/${id}`),
  postUmbrellaChild: (data) =>
    API.post(`${BASE_URL}/calculators/umbrella-children`, data),
  putUmbrellaChild: (id, data) =>
    API.put(`${BASE_URL}/calculators/umbrella-children/${id}`, data),
  delUmbrellaChild: (id) =>
    API.delete(`${BASE_URL}/calculators/umbrella-children/${id}`),
  deactivate: () => API.post(`${USER}/disable-future-children`),
  offEducation: () => API.post(`${USER}/disable-education`),
  offFutureEducation: () => API.post(`${USER}/disable-future-education`),
};

const Education = {
  get: (id) => API.get(`${DEPENDETNS}/${id}/education`),
  post: (id, data) => API.post(`${DEPENDETNS}/${id}/education`, data),
  getFutureEducationList: () => API.get(`${FUTURE_CHILDREN}/educations`),
  getFutureEducation: (id) =>
    API.get(`${FUTURE_CHILDREN}/children/${id}/education`),
  postFutureEducation: (id, data) =>
    API.post(`${FUTURE_CHILDREN}/children/${id}/education`, data),
};

const Incomes = {
  get: () => API.get(`${INCOMES}`),
  post: data => API.post(`${INCOMES}`, data),
  put: (id, data) => API.put(`${INCOMES}/${id}`, data),
  show: id => API.get(`${INCOMES}/${id}`),
  del: id => API.delete(`${INCOMES}/${id}`)
};

const Stock = {
  get: () => API.get(`${STOCK}`),
  post: data => API.post(`${STOCK}`, data),
  put: (id, data) => API.put(`${STOCK}/${id}`, data),
  show: id => API.get(`${STOCK}/${id}`),
  del: id => API.delete(`${STOCK}/${id}`)
};

const Expenses = {
  get: params => API.get(`${EXPENSES}`, { params }),
  post: data => API.post(`${EXPENSES}`, data),
  show: id  => API.get(`${EXPENSES}/${id}`),
  put: (id, data) => API.put(`${EXPENSES}/${id}`, data),
  del: id => API.delete(`${EXPENSES}/${id}`)
};

const Incremental = {
  get: () => API.get(`${INCREMENTAL}`),
  post: data => API.post(`${INCREMENTAL}`, data),
  show: id  => API.get(`${INCREMENTAL}/${id}`),
  put: (id, data) => API.put(`${INCREMENTAL}/${id}`, data),
  del: id => API.delete(`${INCREMENTAL}/${id}`),
  deactivate: () => API.post(`${USER}/disable-incremental`)
};

const Assets = {
  get: () => API.get(`${BASE_URL}/fixed-asset-loan`),
  post: data => API.post(`${BASE_URL}/fixed-asset-loan-main`, data),
  postDetails: data => API.post(`${BASE_URL}/fixed-asset-loan`, data),
  show: id  => API.get(`${BASE_URL}/fixed-asset-loan/${id}`),
  put: (id, data)  => API.put(`${BASE_URL}/fixed-asset-loan/${id}`, data),
  del: id  => API.delete(`${BASE_URL}/fixed-asset-loan/${id}`),
};

const Tasks = {
  get: params => API.get(`${TASKS}`, { params }),
  post: data => API.post(`${TASKS}`, data),
  fetch: id => API.get(`${TASKS}/${id}`),
  put: (id, data) => API.put(`${TASKS}/${id}`, data),
  del: id => API.delete(`${TASKS}/${id}`),
  done: id => API.post(`${TASKS}/${id}/done`),
  viewed: id => API.post(`${TASKS}/${id}/viewed`),
  send: (id, data) => API.post(`${TASKS}/${id}/email`, data),
  reminder: (id, data) => API.post(`${TASKS}/${id}/reminder`, data),
  revert: id => API.post(`${TASKS}/${id}/revert`),
  getCount: () => API.get(`${TASKS}/count`),
  getAnalysis: () => API.get(`${TASKS}/analysis`)
};

const Reminder = {
  get: () => API.get(`${BASE_URL}/tasks/reminder`),
  post: data => API.post(`${BASE_URL}/tasks/reminder`, data),
  getById: id => API.get(`${BASE_URL}/tasks/reminder/${id}`),
  put: (id, data) => API.put(`${BASE_URL}/tasks/reminder/${id}`, data),
  del: id => API.delete(`${BASE_URL}/tasks/reminder/${id}`),
};

const Notifications = {
  get: () => API.get(`${BASE_URL}/notifications`),
  put: id => API.put(`${BASE_URL}/notifications/${id}`),
  getMessage: () => API.get(`${USER}/alert/messages`),
  delMessage: id => API.delete(`${USER}/alert/messages/${id}`),
  getNotify: (id) => API.get(`${COMPANIES}/${id}/alert/messages`),
  postNotify: (id, data) =>  API.post(`${COMPANIES}/${id}/alert/messages`, data),
};

const Insurance = {
  getHealth: () => API.get(`${INSURANCE}/health`),
  postHealth: data => API.post(`${INSURANCE}/health`, data),
  getHome: () => API.get(`${INSURANCE}/home`),
  postHome: data => API.post(`${INSURANCE}/home`, data),
  getAuto: () => API.get(`${INSURANCE}/auto`),
  postAuto: data => API.post(`${INSURANCE}/auto`, data),
  getUmbrella: () => API.get(`${INSURANCE}/umbrella`),
  postUmbrella: data => API.post(`${INSURANCE}/umbrella`, data),
  getLife: () => API.get(`${INSURANCE}/life`),
  postLife: data => API.post(`${INSURANCE}/life`, data),
  getDisability: () => API.get(`${INSURANCE}/long-disability`),
  postDisability: data => API.post(`${INSURANCE}/long-disability`, data),
};

const Protection = {
  getEstate: () => API.get(`${BASE_URL}/estate-planning`),
  postEstate: data => API.post(`${BASE_URL}/estate-planning`, data),
  getUnemployment: () => API.get(`${BASE_URL}/unemployment-planning`),
  postUnemployment: data => API.post(`${BASE_URL}/unemployment-planning`, data),
  getBequeath: () => API.get(`${BASE_URL}/bequeathment`),
  postBequeath: data => API.post(`${BASE_URL}/bequeathment`, data),
  postBequeathDetails: (id, data) => API.put(`${BASE_URL}/bequeathment/${id}`, data),
  showBequeathDetails: id => API.get(`${BASE_URL}/bequeathment/${id}`),
  removeBequeath: id => API.delete(`${BASE_URL}/bequeathment/${id}`),
  deactivate: () => API.post(`${USER}/disable-bequeathment`)
};

const Worth = {
  getCash: () => API.get(`${BASE_URL}/cash-account`),
  postCash: data => API.post(`${BASE_URL}/cash-account`, data),
  getRetirement: () => API.get(`${BASE_URL}/retirement-account`),
  postRetirement: data => API.post(`${BASE_URL}/retirement-account`, data),
  getNonRetirement: () => API.get(`${BASE_URL}/non-retirement-account`),
  postNonRetirement: data => API.post(`${BASE_URL}/non-retirement-account`, data),
  getFixedAsset: () => API.get(`${BASE_URL}/fixed-asset`),
  postFixedAsset: data => API.post(`${BASE_URL}/fixed-asset`, data),
  getDebtCredit: () => API.get(`${BASE_URL}/debt-credit`),
  postDebtCredit: data => API.post(`${BASE_URL}/debt-credit`, data),
  getNecessity: () => API.get(`${BASE_URL}/overdue-necessity`),
  postNecessity: data => API.post(`${BASE_URL}/overdue-necessity`, data),
  getAssetsOther: () => API.get(`${BASE_URL}/other-asset`),
  postAssetsOther: data => API.post(`${BASE_URL}/other-asset`, data),
  getLiabilitiesOther: () => API.get(`${BASE_URL}/other-liability`),
  postLiabilitiesOther: data => API.post(`${BASE_URL}/other-liability`, data)
};

const AdditionalInfo = {
  get: () => API.get(`${BASE_URL}/additional-info`),
  post: data => API.post(`${BASE_URL}/additional-info`, data),
  show: params => API.get(`${BASE_URL}/additional-info`, { params })
};

const RetirementFunding = {
  get: () => API.get(`${BASE_URL}/retirement`),
  post: data => API.post(`${BASE_URL}/retirement`, data),
  getSpouse: () => API.get(`${BASE_URL}/spouse-retirement`),
  postSpouse: data => API.post(`${BASE_URL}/spouse-retirement`, data),
};

const EmploymentSabbatical = {
  get: () => API.get(`${BASE_URL}/employment-sabbatical`),
  post: data => API.post(`${BASE_URL}/employment-sabbatical`, data),
  getSpouse: () => API.get(`${BASE_URL}/employment-spouse-sabbatical`),
  postSpouse: data => API.post(`${BASE_URL}/employment-spouse-sabbatical`, data),
};

const Objectives = {
  get: () => API.get(`${BASE_URL}/fixed-asset-objective`),
  post: data => API.post(`${BASE_URL}/fixed-asset-objective`, data),
  show: id  => API.get(`${BASE_URL}/fixed-asset-objective/${id}`),
  put: (id, data) => API.put(`${BASE_URL}/fixed-asset-objective/${id}`, data),
  del: id => API.delete(`${BASE_URL}/fixed-asset-objective/${id}`),
  deactivate: () => API.post(`${USER}/disable-objectives`)
};



const Blog = {
  get: params => API.get(`${BLOG}`, { params }),
  post: data => API.post(`${BLOG}`, data),
  show: (id) => API.get(`${BLOG}/${id}`),
  put: (id, data) => API.put(`${BLOG}/${id}`, data),
  del: id => API.delete(`${BLOG}/${id}`),
  getCatalog: () => API.get(`${BASE_URL}/blog/catalog`),
  postText: data => API.post(`${BASE_URL}/blog/text`, data)
};

const Percent = {
  get: (params) => API.get(`${BASE_URL}/user/percent`, { params }),
  getTotalTasks: () => API.get(`${BASE_URL}/user/tasks-progress-percentage`),
  getTotal: () => API.get(`${BASE_URL}/user/percent/general`),
  postTotal: (data) => API.post(`${BASE_URL}/user/tasks-percentage`, data),
};

const Admin = {
  getPosts: (companyId, params) =>
    API.get(`${COMPANIES}/${companyId}/posts`, { params }),
  postPost: (companyId, data) =>
    API.post(`${COMPANIES}/${companyId}/posts`, data),
  showPost: (companyId, postId) =>
    API.get(`${COMPANIES}/${companyId}/posts/${postId}`),
  putPost: (companyId, postId, data, params) =>
    API.post(`${COMPANIES}/${companyId}/posts/${postId}`, data, { params }),
  delPost: (companyId, postId) =>
    API.delete(`${COMPANIES}/${companyId}/posts/${postId}`),
  updatePostActivation: (companyId, postId, data) =>
    API.post(`${COMPANIES}/${companyId}/posts/${postId}/set-status`, data),
  getCatalog: (companyId) => API.get(`${COMPANIES}/${companyId}/catalog`),
  postCatalog: (companyId, data) =>
    API.post(`${COMPANIES}/${companyId}/catalog`, data),
  showCatalog: (companyId, catalogId) =>
    API.get(`${COMPANIES}/${companyId}/catalog/${catalogId}`),
  putCatalog: (companyId, catalogId, data) =>
    API.put(`${COMPANIES}/${companyId}/catalog/${catalogId}`, data),
  delCatalog: (companyId, catalogId) =>
    API.delete(`${COMPANIES}/${companyId}/catalog/${catalogId}`),
  getTasks: (companyId, params) =>
    API.get(`${COMPANIES}/${companyId}/tasks`, { params }),
  postTask: (companyId, data) =>
    API.post(`${COMPANIES}/${companyId}/tasks`, data),
  showTask: (companyId, id) => API.get(`${COMPANIES}/${companyId}/tasks/${id}`),
  putTask: (companyId, id, data) =>
    API.put(`${COMPANIES}/${companyId}/tasks/${id}`, data),
  delTask: (companyId, id) =>
    API.delete(`${COMPANIES}/${companyId}/tasks/${id}`),
  getVariables: (params) => API.get(`${ADMIN}/tasks/variables`, { params }),
  getThresholds: (id) => API.get(`${COMPANIES}/${id}/thresholds`),
  postThresholds: (companyId, data) =>
    API.post(`${COMPANIES}/${companyId}/thresholds`, data),
  getTaskAlerts: (params) => API.get(`${ADMIN}/task-alerts`, { params }),
  postTaskAlerts: (data) => API.post(`${ADMIN}/task-alerts`, data),
  getAuthors: (companyId) => API.get(`${COMPANIES}/${companyId}/authors`),
  postAuthor: (companyId, data) =>
    API.post(`${COMPANIES}/${companyId}/authors`, data),
  putAuthor: (companyId, authorId, data, params) =>
    API.post(`${COMPANIES}/${companyId}/authors/${authorId}`, data, { params }),
  delAuthor: (companyId, authorId) =>
    API.delete(`${COMPANIES}/${companyId}/authors/${authorId}`),
  showAuthor: (companyId, authorId) =>
    API.get(`${COMPANIES}/${companyId}/authors/${authorId}`),
  updateTaskActivation: (companyId, taksId, data) =>
    API.put(`${COMPANIES}/${companyId}/tasks/${taksId}/change-status`, data),
  getTaskPlans: (companyId, params) =>
    API.get(`${COMPANIES}/${companyId}/push-notification-plan`, { params }),
  putTaskPlans: (id, data) =>
    API.put(`${ADMIN}/push-notification-plan/${id}`, data),
  postTaskPlansAll: (companyId, data) =>
    API.post(
      `${COMPANIES}/${companyId}/push-notification-plan/update-all`,
      data
    ),
  revertTaskPlansAll: companyId =>
    API.post(`${COMPANIES}/${companyId}/push-notification-plan/revert-all`),
  getPushNotificationSchedule: (companyId) =>
    API.get(`${COMPANIES}/${companyId}/push-notification-schedule`),
  postPushNotificationSchedule: (companyId, data) =>
    API.post(`${COMPANIES}/${companyId}/push-notification-schedule`, data),
  getStatisticsAgeRange: (companyId)  => API.get(`${COMPANIES}/${companyId}/ranges`),  
  getStatisticsStates: (companyId)  => API.get(`${COMPANIES}/${companyId}/states`),
  getStatisticsResult: (companyId, params)  => API.get(`${COMPANIES}/${companyId}/statistic`, { params }),
  getStatisticsCities: (companyId, data)  => API.post(`${COMPANIES}/${companyId}/cities`, data),
  getStatisticsUsers: (companyId, data, params)  => API.post(`${COMPANIES}/${companyId}/filter-users`, data, { params }),
};

const Search = {
  get: params => API.get(`${BASE_URL}/search`, {params}),
};

const Dropbox = {
  post: data => API.post(`${BASE_URL}/dropbox`, data),
  getConnect: () => API.get(`${BASE_URL}/dropbox-is-connect`),
  postNotify: data => API.post(`${BASE_URL}/dropbox-popup-show`, data),
};

const Assumptions = {
  getBudget: (companId) => API.get(`${COMPANIES}/${companId}/assumptions/budget`),
  postBudget: (companId, data) => API.post(`${COMPANIES}/${companId}/assumptions/budget`, data),
  getProperty: (companId) => API.get(`${COMPANIES}/${companId}/assumptions/property`),
  postProperty: (companId, data) => API.post(`${COMPANIES}/${companId}/assumptions/property`, data),
  getAuto: (companId) => API.get(`${COMPANIES}/${companId}/assumptions/auto`),
  postAuto: (companId, data) => API.post(`${COMPANIES}/${companId}/assumptions/auto`, data),
  getUmbrella: (companId) => API.get(`${COMPANIES}/${companId}/assumptions/umbrella`),
  postUmbrella: (companId, data) => API.post(`${COMPANIES}/${companId}/assumptions/umbrella`, data),
  getRetirement: (companId) => API.get(`${COMPANIES}/${companId}/assumptions/retirement`),
  postRetirement: (companId, data) => API.post(`${COMPANIES}/${companId}/assumptions/retirement`, data),
  getRetirementFunding: (companId) => API.get(`${COMPANIES}/${companId}/assumptions/retirement-funding`),
  postRetirementFunding: (companId, data) => API.post(`${COMPANIES}/${companId}/assumptions/retirement-funding`, data),
  getEducation: (companId) => API.get(`${COMPANIES}/${companId}/assumptions/education-funding`),
  postEducation: (companId, data) => API.post(`${COMPANIES}/${companId}/assumptions/education-funding`, data),
  getNetWorth: (companId) => API.get(`${COMPANIES}/${companId}/assumptions/net-worth`),
  postNetWorth: (companId, data) => API.post(`${COMPANIES}/${companId}/assumptions/net-worth`, data),
  getEstate: (companId) => API.get(`${COMPANIES}/${companId}/assumptions/estate-planning`),
  postEstate: (companId, data) => API.post(`${COMPANIES}/${companId}/assumptions/estate-planning`, data),
  getAdditional: (companId) => API.get(`${COMPANIES}/${companId}/assumptions/additional`),
  postAdditional: (companId, data) => API.post(`${COMPANIES}/${companId}/assumptions/additional`, data),
  revertAssumption: (companId, slug) => API.post(`${COMPANIES}/${companId}/assumptions/${slug}/revert`),
};

const CalculatorsSetup = {
  getMortgageAdmin: (companId) => API.get(`${COMPANIES}/${companId}/csv/mortgage`),
  postMortgageAdmin: (companId, data) => API.post(`${COMPANIES}/${companId}/csv/mortgage`, data),
  putMortgageAdmin: (companId, id, data) => API.put(`${COMPANIES}/${companId}/csv/mortgage/${id}`, data),

  getIncomeAndExpensesAdmin: (companId) => API.get(`${COMPANIES}/${companId}/csv/impact-income-and-expense`),
  postIncomeAndExpensesAdmin: (companId, data) => API.post(`${COMPANIES}/${companId}/csv/impact-income-and-expense`, data),
  putIncomeAndExpensesAdmin: (companId, id, data) => API.put(`${COMPANIES}/${companId}/csv/impact-income-and-expense/${id}`, data),

  getOwnVsRentAdmin: (companId) => API.get(`${COMPANIES}/${companId}/csv/own-vs-rent-home`),
  postOwnVsRentAdmin: (companId, data) => API.post(`${COMPANIES}/${companId}/csv/own-vs-rent-home`, data),
  putOwnVsRentAdmin: (companId, id, data) => API.put(`${COMPANIES}/${companId}/csv/own-vs-rent-home/${id}`, data),

  getCarLeaseVsLeaseEndAdmin: (companId) => API.get(`${COMPANIES}/${companId}/csv/new-car-lease-vs-lease-end`),
  postCarLeaseVsLeaseEndAdmin: (companId, data) => API.post(`${COMPANIES}/${companId}/csv/new-car-lease-vs-lease-end`, data),
  putCarLeaseVsLeaseEndAdmin: (companId, id, data) => API.put(`${COMPANIES}/${companId}/csv/new-car-lease-vs-lease-end/${id}`, data),

  getCarLeasePaymentAdmin: (companId) => API.get(`${COMPANIES}/${companId}/csv/car-lease-payment`),
  postCarLeasePaymentAdmin: (companId, data) => API.post(`${COMPANIES}/${companId}/csv/car-lease-payment`, data),
  putCarLeasePaymentAdmin: (companId, id, data) => API.put(`${COMPANIES}/${companId}/csv/car-lease-payment/${id}`, data),

  getLifeInsuranceAdmin: (companId) => API.get(`${COMPANIES}/${companId}/csv/life-insurance`),
  postLifeInsuranceAdmin: (companId, data) => API.post(`${COMPANIES}/${companId}/csv/life-insurance`, data),
  putLifeInsuranceAdmin: (companId, id, data) => API.put(`${COMPANIES}/${companId}/csv/life-insurance/${id}`, data),

  getTimeToBuyHomeAdmin: (companId) => API.get(`${COMPANIES}/${companId}/csv/time-to-buy-home`),
  postTimeToBuyHomeAdmin: (companId, data) => API.post(`${COMPANIES}/${companId}/csv/time-to-buy-home`, data),
  putTimeToBuyHomeAdmin: (companId, id, data) => API.put(`${COMPANIES}/${companId}/csv/time-to-buy-home/${id}`, data),

  getHomeAffordabilityAdmin: (companId) => API.get(`${COMPANIES}/${companId}/csv/home-affordability`),
  postHomeAffordabilityAdmin: (companId, data) => API.post(`${COMPANIES}/${companId}/csv/home-affordability`, data),
  putHomeAffordabilityAdmin: (companId, id, data) => API.put(`${COMPANIES}/${companId}/csv/home-affordability/${id}`, data),

  getHomeEvaluationAdmin: (companId) => API.get(`${COMPANIES}/${companId}/csv/home-evaluation`),
  postHomeEvaluationAdmin: (companId, data) => API.post(`${COMPANIES}/${companId}/csv/home-evaluation`, data),
  putHomeEvaluationAdmin: (companId, id, data) => API.put(`${COMPANIES}/${companId}/csv/home-evaluation/${id}`, data),

  getValueCircleAdmin: (companId) => API.get(`${COMPANIES}/${companId}/csv/value-circle`),
  postValueCircleAdmin: (companId, data) => API.post(`${COMPANIES}/${companId}/csv/value-circle`, data),
  putValueCircleAdmin: (companId, id, data) => API.put(`${COMPANIES}/${companId}/csv/value-circle/${id}`, data),

  getInvestmentFeesAdmin: (companId) => API.get(`${COMPANIES}/${companId}/csv/investment-fee`),
  postInvestmentFeesAdmin: (companId, data) => API.post(`${COMPANIES}/${companId}/csv/investment-fee`, data),
  putInvestmentFeesAdmin: (companId, id, data) => API.put(`${COMPANIES}/${companId}/csv/investment-fee/${id}`, data),

  getUmbrellaInsuranceAdmin: (companId) => API.get(`${COMPANIES}/${companId}/csv/umbrella-insurance`),
  postUmbrellaInsuranceAdmin: (companId, data) => API.post(`${COMPANIES}/${companId}/csv/umbrella-insurance`, data),
  putUmbrellaInsuranceAdmin: (companId, id, data) => API.put(`${COMPANIES}/${companId}/csv/umbrella-insurance/${id}`, data),

  getHomeRefinancingAdmin: (companId) => API.get(`${COMPANIES}/${companId}/csv/home-refinancing`),
  postHomeRefinancingAdmin: (companId, data) => API.post(`${COMPANIES}/${companId}/csv/home-refinancing`, data),
  putHomeRefinancingAdmin: (companId, id, data) => API.put(`${COMPANIES}/${companId}/csv/home-refinancing/${id}`, data),

  getHealthcareCoverageAdmin: (companId) => API.get(`${COMPANIES}/${companId}/csv/health-care`),
  postHealthcareCoverageAdmin: (companId, data) => API.post(`${COMPANIES}/${companId}/csv/health-care`, data),
  putHealthcareCoverageAdmin: (companId, id, data) => API.put(`${COMPANIES}/${companId}/csv/health-care/${id}`, data)
}

const Analysis = {
  getGraphRange: ()  => API.get(`${ANALYSIS}/ranges`),  
  getGraphState: ()  => API.get(`${ANALYSIS}/states`),  
  postGraphCity: (data)  => API.post(`${ANALYSIS}/cities`, data),
  getGraphData: params  => API.get(`${ANALYSIS}/graph`, {params}),
  getOpen: () => API.get(`${ANALYSIS}/tasks/open`),
  getHighestPriority: () => API.get(`${ANALYSIS}/tasks/highest-priority`),
  getHighPriority: () => API.get(`${ANALYSIS}/tasks/high-priority`),
  getNormalPriority: () => API.get(`${ANALYSIS}/tasks/normal-priority`),
  getLowPriority: () => API.get(`${ANALYSIS}/tasks/low-priority`),
  getLowestPriority: () => API.get(`${ANALYSIS}/tasks/lowest-priority`),
  getAge: () => API.get(`${ANALYSIS}/profile/age`),
  getNetWorth: () => API.get(`${ANALYSIS}/profile/net-worth`),
  getTotalExpense: () => API.get(`${ANALYSIS}/profile/total-expense`),
  getInsurance: () => API.get(`${ANALYSIS}/profile/insurance`),
  getTotalIncome: () => API.get(`${ANALYSIS}/profile/total-income`),
  getWorkPerDay: () => API.get(`${ANALYSIS}/profile/work-per-day`),
  getExpectedInheritance: () => API.get(`${ANALYSIS}/profile/expected-inheritance`),
  getTakeHome: () => API.get(`${ANALYSIS}/profile/take-home`),
  getHome: () => API.get(`${ANALYSIS}/expense/home`),
  getAuto: () => API.get(`${ANALYSIS}/expense/auto`),
  getNecessities: () => API.get(`${ANALYSIS}/expense/necessities`),
  getLoved: () => API.get(`${ANALYSIS}/expense/loved`),
  getLuxuries: () => API.get(`${ANALYSIS}/expense/luxuries`),
  getOther: () => API.get(`${ANALYSIS}/expense/other`),
  getCurrent: () => API.get(`${ANALYSIS}/values/current`),
  getPreferred: () => API.get(`${ANALYSIS}/values/preferred`),
  getBequests: () => API.get(`${ANALYSIS}/objective/bequests`),
  getFutureEducation: () => API.get(`${ANALYSIS}/objective/future-children-education`),
  getFutureChildren: () => API.get(`${ANALYSIS}/objective/future-children-number`),
  getSabbatical: () => API.get(`${ANALYSIS}/objective/sabbatical`),
  getSabbaticalDuration: () => API.get(`${ANALYSIS}/objective/sabbatical-duration`),
  getSupportChildren: () => API.get(`${ANALYSIS}/objective/support-children`),
};

const Calculator = {
  get: params => API.get(`${BASE_URL}/calculators`, {params}),
  show: id => API.get(`${BASE_URL}/calculators/${id}`),
  getCategories: params => API.get(`${BASE_URL}/calculator-categories`, {params}),

  getImpactIncome:    ()         => API.get(`${BASE_URL}/calculators/impact-income-and-expense`),
  postImpactIncome:   data       => API.post(`${BASE_URL}/calculators/impact-income-and-expense`, data),
  putImpactIncome:    (id, data) => API.put(`${BASE_URL}/calculators/impact-income-and-expense/${id}`, data),
  showImpactIncome:   id         => API.get(`${BASE_URL}/calculators/impact-income-and-expense/${id}`),
  deleteImpactIncome: id         => API.delete(`${BASE_URL}/calculators/impact-income-and-expense/${id}`),

  getMortgage:     ()         => API.get(`${BASE_URL}/calculators/mortgage`),
  postMortgage:    data       => API.post(`${BASE_URL}/calculators/mortgage`, data),
  putMortgage:     (id, data) => API.put(`${BASE_URL}/calculators/mortgage/${id}`, data),
  showMortgage:    id         => API.get(`${BASE_URL}/calculators/mortgage/${id}`),
  deleteMortgage:  id         => API.delete(`${BASE_URL}/calculators/mortgage/${id}`),

  getHomeAffordability:     ()         => API.get(`${BASE_URL}/calculators/home-affordability`),
  postHomeAffordability:    data       => API.post(`${BASE_URL}/calculators/home-affordability`, data),
  putHomeAffordability:     (id, data) => API.put(`${BASE_URL}/calculators/home-affordability/${id}`, data),
  showHomeAffordability:    id         => API.get(`${BASE_URL}/calculators/home-affordability/${id}`),
  deleteHomeAffordability:  id         => API.delete(`${BASE_URL}/calculators/home-affordability/${id}`),

  getOwnVsRent:    ()         => API.get(`${BASE_URL}/calculators/own-vs-rent-analyse`),
  postOwnVsRent:   data       => API.post(`${BASE_URL}/calculators/own-vs-rent-analyse`, data),
  putOwnVsRent:    (id, data) => API.put(`${BASE_URL}/calculators/own-vs-rent-analyse/${id}`, data),
  showOwnVsRent:   id         => API.get(`${BASE_URL}/calculators/own-vs-rent-analyse/${id}`),
  deleteOwnVsRent: id         => API.delete(`${BASE_URL}/calculators/own-vs-rent-analyse/${id}`),

  getTimeBuyHome:    ()         => API.get(`${BASE_URL}/calculators/time-to-buy-home`),
  postTimeBuyHome:   data       => API.post(`${BASE_URL}/calculators/time-to-buy-home`, data),
  putTimeBuyHome:    (id, data) => API.put(`${BASE_URL}/calculators/time-to-buy-home/${id}`, data),
  showTimeBuyHome:   id         => API.get(`${BASE_URL}/calculators/time-to-buy-home/${id}`),
  deleteTimeBuyHome: id         => API.delete(`${BASE_URL}/calculators/time-to-buy-home/${id}`),

  getHomeEvaluation:     ()         => API.get(`${BASE_URL}/calculators/home-evaluation`),
  postHomeEvaluation:    data       => API.post(`${BASE_URL}/calculators/home-evaluation`, data),
  putHomeEvaluation:     (id, data) => API.put(`${BASE_URL}/calculators/home-evaluation/${id}`, data),
  showHomeEvaluation:    id         => API.get(`${BASE_URL}/calculators/home-evaluation/${id}`),
  deleteHomeEvaluation:  id         => API.delete(`${BASE_URL}/calculators/home-evaluation/${id}`),

  getHomeRefinancing:    ()         => API.get(`${BASE_URL}/calculators/home-refinancing`),
  postHomeRefinancing:   data       => API.post(`${BASE_URL}/calculators/home-refinancing`, data),
  putHomeRefinancing:    (id, data) => API.put(`${BASE_URL}/calculators/home-refinancing/${id}`, data),
  showHomeRefinancing:   id         => API.get(`${BASE_URL}/calculators/home-refinancing/${id}`),
  deleteHomeRefinancing: id         => API.delete(`${BASE_URL}/calculators/home-refinancing/${id}`),

  getCarFinancingPayment:    ()         => API.get(`${BASE_URL}/calculators/car-financing-payment`),
  postCarFinancingPayment:   data       => API.post(`${BASE_URL}/calculators/car-financing-payment`, data),
  putCarFinancingPayment:    (id, data) => API.put(`${BASE_URL}/calculators/car-financing-payment/${id}`, data),
  showCarFinancingPayment:   id         => API.get(`${BASE_URL}/calculators/car-financing-payment/${id}`),
  deleteCarFinancingPayment: id         => API.delete(`${BASE_URL}/calculators/car-financing-payment/${id}`),

  getCarLeasePayment:    ()         => API.get(`${BASE_URL}/calculators/car-lease-payment`),
  postCarLeasePayment:   data       => API.post(`${BASE_URL}/calculators/car-lease-payment`, data),
  putCarLeasePayment:    (id, data) => API.put(`${BASE_URL}/calculators/car-lease-payment/${id}`, data),
  showCarLeasePayment:   id         => API.get(`${BASE_URL}/calculators/car-lease-payment/${id}`),
  deleteCarLeasePayment: id         => API.delete(`${BASE_URL}/calculators/car-lease-payment/${id}`),

  getNewCar:    ()         => API.get(`${BASE_URL}/calculators/new-car-lease-vs-lease-end`),
  postNewCar:   data       => API.post(`${BASE_URL}/calculators/new-car-lease-vs-lease-end`, data),
  putNewCar:    (id, data) => API.put(`${BASE_URL}/calculators/new-car-lease-vs-lease-end/${id}`, data),
  showNewCar:   id         => API.get(`${BASE_URL}/calculators/new-car-lease-vs-lease-end/${id}`),
  deleteNewCar: id         => API.delete(`${BASE_URL}/calculators/new-car-lease-vs-lease-end/${id}`),

  getHealthCare:    ()         => API.get(`${BASE_URL}/calculators/health-care`),
  postHealthCare:   data       => API.post(`${BASE_URL}/calculators/health-care`, data),
  putHealthCare:    (id, data) => API.put(`${BASE_URL}/calculators/health-care/${id}`, data),
  showHealthCare:   id         => API.get(`${BASE_URL}/calculators/health-care/${id}`),
  deleteHealthCare: id         => API.delete(`${BASE_URL}/calculators/health-care/${id}`),

  getInvestmentFee:    ()         => API.get(`${BASE_URL}/calculators/investment-fee`),
  postInvestmentFee:   data       => API.post(`${BASE_URL}/calculators/investment-fee`, data),
  putInvestmentFee:    (id, data) => API.put(`${BASE_URL}/calculators/investment-fee/${id}`, data),
  showInvestmentFee:   id         => API.get(`${BASE_URL}/calculators/investment-fee/${id}`),
  deleteInvestmentFee: id         => API.delete(`${BASE_URL}/calculators/investment-fee/${id}`),

  getLifeInsurance:    ()         => API.get(`${BASE_URL}/calculators/life-insurance`),
  postLifeInsurance:   data       => API.post(`${BASE_URL}/calculators/life-insurance`, data),
  putLifeInsurance:    (id, data) => API.put(`${BASE_URL}/calculators/life-insurance/${id}`, data),
  showLifeInsurance:   id         => API.get(`${BASE_URL}/calculators/life-insurance/${id}`),
  deleteLifeInsurance: id         => API.delete(`${BASE_URL}/calculators/life-insurance/${id}`),

  getUmbrellaInsurance:    ()         => API.get(`${BASE_URL}/calculators/umbrella-insurance`),
  postUmbrellaInsurance:   data       => API.post(`${BASE_URL}/calculators/umbrella-insurance`, data),
  putUmbrellaInsurance:    (id, data) => API.put(`${BASE_URL}/calculators/umbrella-insurance/${id}`, data),
  showUmbrellaInsurance:   id         => API.get(`${BASE_URL}/calculators/umbrella-insurance/${id}`),
  deleteUmbrellaInsurance: id         => API.delete(`${BASE_URL}/calculators/umbrella-insurance/${id}`),

  getValueCircle:    ()         => API.get(`${BASE_URL}/calculators/value-circle`),
  postValueCircle:   data       => API.post(`${BASE_URL}/calculators/value-circle`, data),
  putValueCircle:    (id, data) => API.put(`${BASE_URL}/calculators/value-circle/${id}`, data),
  showValueCircle:   id         => API.get(`${BASE_URL}/calculators/value-circle/${id}`),
  deleteValueCircle: id         => API.delete(`${BASE_URL}/calculators/value-circle/${id}`),
};

const FAQ = {
  get:  () => API.get(`${BASE_URL}/faqs`),
  post: data => API.post(`${BASE_URL}/faqs`, data),
  show: id => API.get(`${BASE_URL}/faqs/${id}`),
  put:  (id, data) => API.put(`${BASE_URL}/faqs/${id}`, data),
  del:  id => API.delete(`${BASE_URL}/faqs/${id}`),
};

const Export = {
  getSummary: () => API.get(`${BASE_URL}/export/summary`),
  getDetails: () => API.get(`${BASE_URL}/export/details`),
  getPercent: () => API.get(`${BASE_URL}/export/percent`),
  getTour: () => API.get(`${BASE_URL}/tours`),
  postTour: (data) => API.post(`${BASE_URL}/tours`, data)
};

const FeedbackAPI = {
  get:  () => API.get(`${BASE_URL}/feedback`),
  post: data => API.post(`${BASE_URL}/feedback`, data)
};

const Statistics = {
  getPushNotificationTask: (companyId, params) =>
    API.get(
      `${COMPANIES}/${companyId}/push-notification-task-statistic`,
      params
    ),
  showPushNotificationTask: (companyId, id) =>
    API.get(`${COMPANIES}/${companyId}/push-notification-task-statistic/${id}`),
};

export {
  API,
  Auth,
  Options,
  User,
  Companies,
  Spouse,
  Profile,
  Risk,
  Dependents,
  UserCompanies,
  Education,
  Expenses,
  Incomes,
  Tasks,
  Notifications,
  Insurance,
  Protection,
  Incremental,
  Assets,
  Worth,
  AdditionalInfo,
  Stock,
  RetirementFunding,
  EmploymentSabbatical,
  Objectives,
  Blog,
  Percent,
  Admin,
  Search,
  Dropbox,
  Assumptions,
  CalculatorsSetup,
  Analysis,
  Calculator,
  FAQ,
  Export,
  Reminder,
  FeedbackAPI,
  Statistics,
};
