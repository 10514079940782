import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Layout from '../../components/Layout/Layout'
import Tabs, { TabPanel } from '../../components/shared/Tabs/Tabs'
import { BackButton } from '../../components/shared/Buttons'
import TaskCard from '../../components/Task/TaskCard'
import { Button } from '@mui/material'
import { FormSelect } from '../../components/shared/Form'
import Loading from '../../components/shared/Loading/Loading'
import { setIsOpen } from '../../redux/slices/modalSlice'
import { setMode } from '../../redux/slices/modeSlice'
import {
  deleteTask,
  fetchAnalysisTasks,
  updateTaskDetails,
  postTask,
  taskDone,
  fetchTasks,
  revertTask,
  setIsLoading,
  setMsg,
  fetchTaskDetails,
  taskViewed,
  fetchReminders,
  fetchReminderById,
  deleteReminder,
  updateReminder, setTasks, setPagination
} from '../../redux/slices/taskSlice'
import {
  fetchStatusData,
  fetchTaskStatusData,
  fetchCategoriesData,
  fetchPlanningTypesData
} from '../../redux/slices/optionsSlice'
import Drawer from '../../components/Task/TaskDrawer'
import ReminderCard from '../../components/Task/TaskReminderCard'
import Tooltip from '../../components/shared/Tooltip/Tooltip'
import { Pagination } from '@mui/material';
import { fetchNotifications } from '../../redux/slices/notificationsSlice'
import Priority from '../../components/shared/Priority/Priority'
import PlaceholderIcon from '../../assets/images/placeholder-task-icon.png'
import { fetchTourStatus } from '../../redux/slices/additionalSlice'
import { Tasks as TasksApi } from '../../api/Api';
import ModalBox from "../../components/shared/Modal/Modal";
import { setPlanTasksPopupDelay } from "../../redux/slices/userSlice";

const steps = [
  {
    target: '.step-tasks',
    content: <>
      <h6>Tasks</h6>
      ValueSoft provides comprehensive alerts and recommendations. Think about this as your personal financial planning
      assistant!
    </>,
    disableBeacon: true,
  },
  {
    target: '.step-tasks-filters',
    content: <>
      <h6>Task filter</h6>
      Filter your action items by Planning Type, Priority, Status, and Substatus so you work on what matters most to
      you.
    </>,
  },
  {
    target: '.grid > div:first-child > .task-card .task-card__menu',
    content: <>
      <h6>Share task</h6>
      And there's more you can do with each task! You can email it to a loved one to share your next steps with them,
      update the task as you make progress on it, and more.
    </>,
  },
  {
    target: '.step-create-task',
    content: <>
      <h6>Create new task</h6>
      You can even add a new task as you learn more about your financial health. Want to hire a planner or accountant to
      help you? Want to share your plan with your spouse over dinner? Create a task for them and use this as your
      complete task management system for your financial health!
    </>,
  },
  {
    target: '.grid > div:first-child > .task-card .task-card__content',
    content: <>
      <h6>Task details</h6>
      Get detailed descriptions of your financial planning task and context on why it matters to you.
    </>,
  },
]

const currentDate = (new Date().getTime() / 1000).toFixed();

const Tasks = ({ props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const { tasks, taskLoading, editId, pagination, reminders, analysis } = useSelector(state => state.task);
  const isUserTask = useSelector(state => state.task.isUserTask);
  const { priorities, planning_types, status, categories, task_status } = useSelector(state => state.options);
  const userId = useSelector(state => state.user.user.id);
  const {
    plan_choose_task_delay,
    push_notification_plan_optional,
  } = useSelector((state) => state.user.user);
  const totalPercent = useSelector((state) => state.percentages.total);
  const isOpen = useSelector(state => state.modal.isOpen);
  const mode = useSelector(state => state.mode.mode);
  const { playTour, tours } = useSelector(state => state.additional);
  const [isPlanTasks, setPlanTasks] = useState(false);
  const [noPlanTasks, steNoPlanTasks] = useState(false);
  const [isCheck, setCheck] = useState(false);

  const planningTypeId = query.get('planning_type');
  const categoryId = query.get('category_id');
  const priorityId = query.get('priority_id');
  const overdueTab = query.get('tab') === 'overdue';

  const [value, setValue] = useState(overdueTab ? 3 : 0);
  const [page, setPage] = useState(1);

  const handlePath = () => {
    location.search !== '' && history.replace('/tasks');
  }

  useEffect(() => {
    if (
      plan_choose_task_delay &&
      +currentDate > +plan_choose_task_delay &&
      +push_notification_plan_optional === 0 &&
      totalPercent === 100
    ) {
      setPlanTasks(true);
    }
    // eslint-disable-next-line
  }, [totalPercent, plan_choose_task_delay]);

  const [filters, setFilters] = useState({
    taskStatus: null,
    status: null,
    priority: priorityId ? priorityId : null,
    tab: query.get("tab") || null,
    planningType: planningTypeId ? planningTypeId : null,
    category_id: categoryId ? categoryId : null,
  });

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchTasks());
      await dispatch(setIsLoading(false));
      await dispatch(setIsOpen({ isOpen: false }));
    }

    if (isUserTask === false) {
      fetch()
    }
    // eslint-disable-next-line
  }, [isUserTask])

  useEffect(() => {
    async function fetchOptions() {
      await dispatch(fetchAnalysisTasks());
      await dispatch(fetchTourStatus());
      await dispatch(fetchPlanningTypesData());
      await dispatch(fetchStatusData());
      await dispatch(fetchTaskStatusData());
      await dispatch(fetchCategoriesData());
    }

    if (userId) {
      fetchOptions()
    }

    // eslint-disable-next-line
  }, [userId])


  useEffect(() => {
    async function fetch() {
      await dispatch(fetchTasks(null, {
        page,
        ...filters
      }, props));
      await dispatch(setIsLoading(false));
    }

    if (userId) {
      fetch()
    }

    // eslint-disable-next-line
  }, [userId, filters, page])

  const handleValue = (event, newValue) => {
    dispatch(setIsLoading(true))
    setValue(newValue)
    setPage(1)
    handlePath()

    if (newValue === 0) {
      setFilters({
        status: null,
        priority: null,
        planningType: null,
        category_id: null
      })
    } else if (newValue === 1) {
      setFilters({
        status: 3,
        priority: null,
        planningType: null,
        category_id: null
      })
    } else if (newValue === 3) {
      setFilters({
        status: null,
        priority: null,
        planningType: null,
        category_id: null,
        tab: 'overdue'
      })
    } else {
      dispatch(fetchReminders())
    }
  }

  const onChangeFilters = e => {
    setPage(1)
    setFilters({
      ...filters,
      category_id: null,
      [e.target.name]: e.target.value
    })
    handlePath()
  }

  const removeFilters = (status, tab) => {
    setFilters({
      taskStatus: null,
      status: status,
      priority: null,
      planningType: null,
      category_id: null,
      tab: tab || null
    })
    handlePath()
  }

  const onChangePage = (event, value) => setPage(value)

  const closeDrawer = () => {
    dispatch(setIsOpen({ isOpen: false }));
    dispatch(setMode(null));
    dispatch(setMsg(''));
  }

  const openTaskCreator = () => {
    dispatch(setMode('create'))
    dispatch(setIsOpen({ isOpen: true }))
  }

  const removeTask = async id => {
    await dispatch(deleteTask(null, id))
    await dispatch(fetchTasks(null, {
      ...filters
    }, props))
    handlePath()
  }

  const updateTask = async (id, values) => {
    await dispatch(updateTaskDetails(null, id, values))
    await dispatch(fetchTasks(null, {
      ...filters,
      page
    }, props))
    handlePath()
  }

  const createTask = async values => {
    await dispatch(postTask(values, {
      ...filters,
      page
    }))
    handlePath()
  }

  const closeTask = async id => {
    await dispatch(taskDone(id))
    await dispatch(fetchTasks(null, {
      ...filters,
      page
    }, props))
    handlePath()
    await dispatch(fetchNotifications())
  }

  const revert = id => {
    dispatch(revertTask(id, {
      ...filters,
      page
    }))
    handlePath()
  }

  const filterTaskStatus = array => {
    return array.filter(i => i.label !== 'Closed')
  }

  const onCloseEditDrawer = async (status) => {
    if (status === 'New' || status === 'Updated') {
      const response = await TasksApi.get({
        ...filters,
        page
      });
      await dispatch(setTasks(response.data.data));
      await dispatch(setPagination({ ...response.data.meta, ...response.data.links }));
    }
  };

  const showDetails = async (id, status) => {
    await dispatch(fetchTaskDetails(null, id))
    if (status === 'New' || status === 'Updated') {
      await dispatch(taskViewed(id, {
        ...filters,
        page
      }, false))
    }
  }

  const fetchReminderDetails = async (id) => {
    await dispatch(fetchReminderById(id)
    )
  }

  const removeReminder = async id => {
    await dispatch(deleteReminder(id))
    await dispatch(fetchReminders())
  }

  const onCheck = () => {
    setCheck(!isCheck);
  };

  const onHandleClick = async () => {
    await dispatch(
      setPlanTasksPopupDelay({
        popup_choose_task: isCheck,
      })
    );
    steNoPlanTasks(false);
  };

  // const updateReminder = async (id, values) => {
  //   await dispatch(updateReminder(id, values))
  //   await dispatch(fetchReminders())
  // }

  return (
    <Layout
      sticky
      steps={steps}
      tourStatus={tours && !tours.tasks}
      tourParam="tasks"
      titleTour="Take a Tour of Tasks"
    >
      <BackButton title="Back to  Overview" path="/dashboard" />
      <div className="layout-main__title">
        <h4 className="step-tasks">
          Review my action items & implement my plan
        </h4>
        <Tooltip
          title={
            <>
              <h6>Your Tasks</h6>
              <Priority option="Very High" />
              <div>
                Alerts and tasks are categorized by priority and topic. You can
                add, delete, or update existing alerts and tasks. Please ensure
                your task list is up-to-speed at all times, by updating your
                alerts' or tasks' status.
              </div>
            </>
          }
        />
      </div>
      <Tabs
        handleChange={handleValue}
        value={value}
        tabs={[
          { title: "Open" },
          { title: "History" },
          { title: "Reminders" },
          { title: "Overdue" },
        ]}
      />
      <TabPanel value={value} index={0}>
        <div className="tabs-holder">
          <div className="filters">
            <div className="filters__title">Filter by:</div>
            <div className="filters__select step-tasks-filters">
              <FormSelect
                options={planning_types}
                id="planning_type"
                value={filters.planningType || ""}
                medium
                name="planningType"
                onChange={(e) => onChangeFilters(e)}
                placeholder="Planning Type"
              />
            </div>
            <div className="filters__select">
              <FormSelect
                options={priorities}
                id="priority"
                value={filters.priority || ""}
                medium
                name="priority"
                onChange={(e) => onChangeFilters(e)}
                placeholder="Priority"
              />
            </div>
            <div className="filters__select">
              <FormSelect
                options={filterTaskStatus(status)}
                id="status"
                value={filters.status || ""}
                medium
                name="status"
                onChange={(e) => onChangeFilters(e)}
                placeholder="Status"
              />
            </div>
            <div className="filters__select">
              <FormSelect
                options={task_status}
                id="taskStatus"
                value={filters.taskStatus || ""}
                medium
                name="taskStatus"
                onChange={(e) => onChangeFilters(e)}
                placeholder="Substatus"
              />
            </div>
            {(filters.priority ||
              filters.planningType ||
              filters.status ||
              filters.taskStatus) && (
              <div className="filters__select">
                <button
                  className="filters__remove"
                  onClick={() => removeFilters(null)}
                >
                  Close
                </button>
              </div>
            )}
          </div>
          <div className="grid grid-3">
            {taskLoading ? (
              <Loading small />
            ) : tasks && priorities.length > 1 && tasks.length > 0 ? (
              tasks.map((item) => (
                <div key={`task-${item.id}`}>
                  <TaskCard
                    id={item.id}
                    removeTask={removeTask}
                    updateTask={updateTask}
                    closeTask={closeTask}
                    revertTask={revert}
                    title={item.name}
                    status={item.status.name}
                    sub_status={item.task_status.name}
                    category={item.category.planning.name}
                    priority={item.priority.name}
                    content={item.description}
                    icon={item.category.planning.image}
                    priorityList={priorities}
                    categoryList={categories}
                    statusList={status}
                    showDetails={() =>
                      showDetails(item.id, item.task_status.name)
                    }
                    onCloseDrawer={onCloseEditDrawer}
                  />
                </div>
              ))
            ) : playTour ? (
              <div>
                <TaskCard
                  id={100}
                  removeTask={removeTask}
                  updateTask={updateTask}
                  closeTask={closeTask}
                  revertTask={revert}
                  title="Your current and preferred"
                  sub_status="New"
                  status="Open"
                  category="Document Management"
                  priority="Medium"
                  content="You do not have enough life insurance for your spouse."
                  icon={PlaceholderIcon}
                  priorityList={priorities}
                  categoryList={categories}
                  statusList={status}
                />
              </div>
            ) : (
              <div className="filters__no-results">
                <h5>No results</h5>
              </div>
            )}
          </div>
          {pagination.last_page > 1 && (
            <Pagination count={pagination.last_page} onChange={onChangePage} />
          )}
          <Button
            variant="contained"
            className="medium filters__button step-create-task"
            color="primary"
            onClick={openTaskCreator}
          >
            <span className="MuiButton-label">Add task</span>
          </Button>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="filters">
          <div className="filters__title">Filter by:</div>
          <div className="filters__select">
            <FormSelect
              options={planning_types}
              id="planning_type"
              value={filters.planningType || ""}
              medium
              name="planningType"
              onChange={(e) => onChangeFilters(e)}
              placeholder="Planning Type"
            />
          </div>
          <div className="filters__select">
            <FormSelect
              options={priorities}
              id="priority"
              value={filters.priority || ""}
              medium
              name="priority"
              onChange={(e) => onChangeFilters(e)}
              placeholder="Priority"
            />
          </div>
          {(filters.priority || filters.planningType) && (
            <div className="filters__select">
              <button
                className="filters__remove"
                onClick={() => removeFilters(3)}
              >
                Close
              </button>
            </div>
          )}
        </div>
        <div className="grid grid-3">
          {taskLoading ? (
            <Loading small />
          ) : tasks && priorities.length > 1 && tasks.length > 0 ? (
            tasks.map((item) => (
              <div key={`task-${item.id}`}>
                <TaskCard
                  id={item.id}
                  removeTask={removeTask}
                  updateTask={updateTask}
                  closeTask={closeTask}
                  revertTask={revert}
                  title={item.name}
                  status={item.status.name}
                  sub_status={item.task_status.name}
                  category={item.category.planning.name}
                  priority={item.priority.name}
                  content={item.description}
                  icon={item.category.planning.image}
                  priorityList={priorities}
                  categoryList={categories}
                  statusList={status}
                  showDetails={() =>
                    showDetails(item.id, item.task_status.name)
                  }
                  onCloseDrawer={onCloseEditDrawer}
                />
              </div>
            ))
          ) : (
            <div className="filters__no-results">
              <h5>No results</h5>
            </div>
          )}
        </div>
        {pagination.last_page > 1 && (
          <Pagination count={pagination.last_page} onChange={onChangePage} />
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className="grid grid-3">
          {taskLoading ? (
            <Loading small />
          ) : reminders && reminders.length > 0 ? (
            reminders.map((item) => (
              <div key={`task-${item.id}`}>
                <ReminderCard
                  title={item.task.name}
                  date={item.task_due_date}
                  name={item.organizer_name}
                  email={item.organizer_email}
                  location={item.meeting_location}
                  id={item.id}
                  remove={removeReminder}
                  update={updateReminder}
                  showDetails={() => fetchReminderDetails(item.id)}
                />
              </div>
            ))
          ) : (
            <div className="filters__no-results">
              <h5>No results</h5>
            </div>
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <div className="tabs-holder">
          <div className="filters">
            <div className="filters__title">Filter by:</div>
            <div className="filters__select step-tasks-filters">
              <FormSelect
                options={planning_types}
                id="planning_type"
                value={filters.planningType || ""}
                medium
                name="planningType"
                onChange={(e) => onChangeFilters(e)}
                placeholder="Planning Type"
              />
            </div>
            <div className="filters__select">
              <FormSelect
                options={priorities}
                id="priority"
                value={filters.priority || ""}
                medium
                name="priority"
                onChange={(e) => onChangeFilters(e)}
                placeholder="Priority"
              />
            </div>
            <div className="filters__select">
              <FormSelect
                options={filterTaskStatus(status)}
                id="status"
                value={filters.status || ""}
                medium
                name="status"
                onChange={(e) => onChangeFilters(e)}
                placeholder="Status"
              />
            </div>
            <div className="filters__select">
              <FormSelect
                options={task_status}
                id="taskStatus"
                value={filters.taskStatus || ""}
                medium
                name="taskStatus"
                onChange={(e) => onChangeFilters(e)}
                placeholder="Substatus"
              />
            </div>
            {(filters.priority ||
              filters.planningType ||
              filters.status ||
              filters.taskStatus) && (
              <div className="filters__select">
                <button
                  className="filters__remove"
                  onClick={() => removeFilters(null, "overdue")}
                >
                  Close
                </button>
              </div>
            )}
          </div>
          <p className="text-muted">
            These are your overdue and aging tasks. <br />
            Please check your{" "}
            <Link
              to="/profile?tab=plans"
              className="text-accent typography__semibold"
            >
              Settings > Plan
            </Link>{" "}
            for the task plan you selected.
          </p>
          <div className="grid grid-3">
            {taskLoading ? (
              <Loading small />
            ) : tasks && priorities.length > 1 && tasks.length > 0 ? (
              tasks.map((item) => (
                <div key={`task-${item.id}`}>
                  <TaskCard
                    id={item.id}
                    removeTask={removeTask}
                    updateTask={updateTask}
                    closeTask={closeTask}
                    revertTask={revert}
                    title={item.name}
                    status={item.status.name}
                    sub_status={item.task_status.name}
                    category={item.category.planning.name}
                    priority={item.priority.name}
                    content={item.description}
                    icon={item.category.planning.image}
                    priorityList={priorities}
                    categoryList={categories}
                    statusList={status}
                    showDetails={() =>
                      showDetails(item.id, item.task_status.name)
                    }
                    onCloseDrawer={onCloseEditDrawer}
                  />
                </div>
              ))
            ) : playTour ? (
              <div>
                <TaskCard
                  id={100}
                  removeTask={removeTask}
                  updateTask={updateTask}
                  closeTask={closeTask}
                  revertTask={revert}
                  title="Your current and preferred"
                  sub_status="New"
                  status="Open"
                  category="Document Management"
                  priority="Medium"
                  content="You do not have enough life insurance for your spouse."
                  icon={PlaceholderIcon}
                  priorityList={priorities}
                  categoryList={categories}
                  statusList={status}
                />
              </div>
            ) : (
              <div className="filters__no-results">
                <h5>No results</h5>
              </div>
            )}
          </div>
          {pagination.last_page > 1 && (
            <Pagination count={pagination.last_page} onChange={onChangePage} />
          )}
          <Button
            variant="contained"
            className="medium filters__button step-create-task"
            color="primary"
            onClick={openTaskCreator}
          >
            <span className="MuiButton-label">Add task</span>
          </Button>
        </div>
      </TabPanel>
      <Drawer
        isOpen={isOpen}
        onClose={closeDrawer}
        id={editId}
        priorities={priorities}
        categories={categories}
        status={status}
        remove={removeTask}
        createTask={createTask}
        mode={mode}
      />
      <ModalBox
        small
        largeButtons
        open={isPlanTasks}
        close={() => {
          setPlanTasks(false);
          steNoPlanTasks(true);
        }}
        onClick={async () => {
          await dispatch(
            setPlanTasksPopupDelay({
              popup_choose_task: isCheck,
            })
          );
          setPlanTasks(false);
          history.push("/profile?tab=plans");
        }}
        closeButton="No, thanks."
        button="Create action plan"
        title="Choose a task plan"
        children={
          <p className="modal__content--text-grey-sm">
            Congratulations! You have completed all the necessary input and all
            your tasks have been created. Your input has created{" "}
            {analysis?.openCount} tasks. Now comes the important part: reviewing
            and taking action on your tasks! You can easily use the "Implement
            Tasks" section to do this or ValueSoft can create a customized
            action plan for you and help guide you through task completion.
          </p>
        }
      />
      <ModalBox
        small
        largeButtons
        checkbox
        open={noPlanTasks}
        close={onHandleClick}
        check={onCheck}
        isChecked={isCheck}
        onClick={onHandleClick}
        button="Confirm"
        title="Confirmation"
        children={
          <p className="modal__content--text-grey-sm">
            We will remind you again in the two weeks unless you check the box
            below to turn off this reminder.
          </p>
        }
      />
    </Layout>
  );
}

export default Tasks
