import React from 'react'
import { Form, Formik } from 'formik'
import { Button } from '@mui/material'
import {FormGroup, InputCurrency} from '../../components/shared/Form'

const RetirementFunding = ({ title, onSave, values, coreValues, validationSchema, sending, noEdit }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{ ...values }}
      validationSchema={validationSchema}
      onSubmit={(values) => onSave(values)}
    >
      {({ errors, handleChange, handleBlur, values, touched }) => (
        <Form>
          <h4>{title}</h4>
          <div className="form__flow-wrap">
            <FormGroup name="max_401k_under_fifty" labelText="401K maximum contribution under 50 years of age">
              <InputCurrency
                icon
                placeholder="$0.00"
                name="max_401k_under_fifty"
                onChange={handleChange}
                value={values.max_401k_under_fifty || ''}
                onBlur={handleBlur}
                className={coreValues?.max_401k_under_fifty && !noEdit && 'form__input--core'}
                hasError={!!errors.max_401k_under_fifty && touched.max_401k_under_fifty}
              />
              {touched.max_401k_under_fifty && errors.max_401k_under_fifty ? (
                <span className="form__msg-error">{errors.max_401k_under_fifty}</span>
              ) : coreValues && coreValues.max_401k_under_fifty && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.max_401k_under_fifty}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="max_401k_over_fifty" labelText="401K maximum contribution over 50 years of age">
              <InputCurrency
                icon
                placeholder="$0.00"
                name="max_401k_over_fifty"
                onChange={handleChange}
                value={values.max_401k_over_fifty || ''}
                onBlur={handleBlur}
                className={coreValues?.max_401k_over_fifty && !noEdit && 'form__input--core'}
                hasError={!!errors.max_401k_over_fifty && touched.max_401k_over_fifty}
              />
              {touched.max_401k_over_fifty && errors.max_401k_over_fifty ? (
                <span className="form__msg-error">{errors.max_401k_over_fifty}</span>
              ) : coreValues && coreValues.max_401k_over_fifty && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.max_401k_over_fifty}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="ira_max_under_fifty" labelText="IRA maximum contribution under 50 years of age">
              <InputCurrency
                icon
                placeholder="$0.00"
                name="ira_max_under_fifty"
                onChange={handleChange}
                value={values.ira_max_under_fifty || ''}
                onBlur={handleBlur}
                className={coreValues?.ira_max_under_fifty && !noEdit && 'form__input--core'}
                hasError={!!errors.ira_max_under_fifty && touched.ira_max_under_fifty}
              />
              {touched.ira_max_under_fifty && errors.ira_max_under_fifty ? (
                <span className="form__msg-error">{errors.ira_max_under_fifty}</span>
              ) : coreValues && coreValues.ira_max_under_fifty && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.ira_max_under_fifty}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="ira_max_over_fifty" labelText="IRA maximum contribution over 50 years of age">
              <InputCurrency
                icon
                placeholder="$0.00"
                name="ira_max_over_fifty"
                onChange={handleChange}
                value={values.ira_max_over_fifty || ''}
                onBlur={handleBlur}
                className={coreValues?.ira_max_over_fifty && !noEdit && 'form__input--core'}
                hasError={!!errors.ira_max_over_fifty && touched.ira_max_over_fifty}
              />
              {touched.ira_max_over_fifty && errors.ira_max_over_fifty ? (
                <span className="form__msg-error">{errors.ira_max_over_fifty}</span>
              ) : coreValues && coreValues.ira_max_over_fifty && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.ira_max_over_fifty}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="sep_ira_max" labelText="SEP IRA maximum yearly contribution">
              <InputCurrency
                icon
                placeholder="$0.00"
                name="sep_ira_max"
                onChange={handleChange}
                value={values.sep_ira_max || ''}
                onBlur={handleBlur}
                className={coreValues?.sep_ira_max && !noEdit && 'form__input--core'}
                hasError={!!errors.sep_ira_max && touched.sep_ira_max}
              />
              {touched.sep_ira_max && errors.sep_ira_max ? (
                <span className="form__msg-error">{errors.sep_ira_max}</span>
              ) : coreValues && coreValues.sep_ira_max && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.sep_ira_max}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup
              name="married_ira_roth_income"
              labelText="Married IRA to Roth rollover qualified adjusted gross income"
            >
              <InputCurrency
                icon
                placeholder="$0.00"
                name="married_ira_roth_income"
                onChange={handleChange}
                value={values.married_ira_roth_income || ''}
                onBlur={handleBlur}
                className={coreValues?.married_ira_roth_income && !noEdit && 'form__input--core'}
                hasError={!!errors.married_ira_roth_income && touched.married_ira_roth_income}
              />
              {touched.married_ira_roth_income && errors.married_ira_roth_income ? (
                <span className="form__msg-error">{errors.married_ira_roth_income}</span>
              ) : coreValues && coreValues.married_ira_roth_income && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.married_ira_roth_income}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup
              name="unmarried_ira_roth_income"
              labelText="Unmarried IRA to Roth rollover qualified adjusted gross income"
            >
              <InputCurrency
                icon
                placeholder="$0.00"
                name="unmarried_ira_roth_income"
                onChange={handleChange}
                value={values.unmarried_ira_roth_income || ''}
                onBlur={handleBlur}
                className={coreValues?.unmarried_ira_roth_income && !noEdit && 'form__input--core'}
                hasError={!!errors.unmarried_ira_roth_income && touched.unmarried_ira_roth_income}
              />
              {touched.unmarried_ira_roth_income && errors.unmarried_ira_roth_income ? (
                <span className="form__msg-error">{errors.unmarried_ira_roth_income}</span>
              ) : coreValues && coreValues.unmarried_ira_roth_income && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.unmarried_ira_roth_income}</span>
              ) : (
                ''
              )}
            </FormGroup>
          </div>
          <div className="form__button">
            <Button variant="contained" size="large" color="primary" type="submit" disabled={sending || noEdit}>
              Save
              {sending && <span className="spinner" />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default RetirementFunding
