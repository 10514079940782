import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import AuthContent from '../../components/Auth/AuthContent'
import { Form, Formik } from 'formik'
import { FormGroup, Input } from '../../components/shared/Form'
import { BackButton } from '../../components/shared/Buttons'
import { Button } from '@mui/material'
import { emailFormat } from '../../utils/validation'
import Tree from '../../assets/images/door-lock.png'
import { forgotPassword } from '../../redux/slices/authSlice'

const ForgotPassword = ({ props }) => {
  const dispatch = useDispatch();
  const serverError = useSelector(state => state.auth.authError);
  const sending = useSelector(state => state.sending.isSending);
  const onSubmit = values => {
    dispatch(forgotPassword(values, props))
  };

  return (
    <AuthContent decor={{image: Tree}} back>
      <div className="auth__back">
        <BackButton title="Back to Sign In" path="/login"  />
      </div>
      <h4>Forgot password</h4>
      <p>To reset your password, enter your email address used to sign in to your ValueSoft account</p>
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={Yup.object().shape({
          email: emailFormat
        })}
        onSubmit={onSubmit}
      >
        {({ errors, handleChange, handleBlur, values, touched }) => (
          <Form>
            <FormGroup name="email" labelText="Email address">
              <Input
                placeholder="Type your email address"
                type="email"
                name="email"
                onChange={handleChange}
                value={values.email}
                onBlur={handleBlur}
                hasError={!!errors.email && touched.email}
              />

              {touched.email && errors.email
                ? <span className="form__msg-error">{errors.email}</span>
                : serverError && serverError.email && <span className="form__msg-error">{serverError.email}</span>
              }
            </FormGroup>

            <div className="form__button">
              <Button variant="contained" size="large" color="primary" type="submit" disabled={!values.email || sending}>
                Reset
                {sending && <span className="spinner" />}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </AuthContent>
  );
};

export default ForgotPassword;
