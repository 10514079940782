import React from 'react'
import { Form, Formik } from 'formik'
import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { FormGroup, Input, InputCurrency } from '../shared/Form'
import { normalizeBoolean } from '../../utils/utils'
import Percentage from '../../assets/images/icons/percentage.svg'

const LifeInsuranceAdmin = ({ title, onSave, values, sending }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{...values}}
      onSubmit={values => onSave(values)}
    >
      {({ handleChange, handleBlur, setFieldValue, values }) => (
        <Form>
          <h4 className="form__main-title">{title}</h4>
          <h6>Profile</h6>
          <div className="form__flow-wrap">
          <FormGroup name="your_age" labelText="Your age">
            <Input
              name="your_age"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.your_age || 0}
            />
          </FormGroup>
          <FormGroup name="number_of_children" labelText="Number of children">
            <Input
              name="number_of_children"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.number_of_children || 0}
            />
          </FormGroup>
          <FormGroup name="gender" labelText="Your gender">
            <RadioGroup
              name="gender"
              value={values.gender || 'male'}
              onChange={(e) => {
                setFieldValue('gender', normalizeBoolean(e.target.value))
              }}
              className="radio-list-inline"
            >
              <FormControlLabel value={'male'} control={<Radio />} label="Male" />
              <FormControlLabel value={'female'} control={<Radio />} label="Female" />
            </RadioGroup>
          </FormGroup>
          <FormGroup name="is_smoke" labelText="Do you smoke?">
            <RadioGroup
              name="is_smoke"
              value={values.is_smoke || false}
              onChange={(e) => {
                setFieldValue('is_smoke', normalizeBoolean(e.target.value))
              }}
              className="radio-list-inline"
            >
              <FormControlLabel value={false} control={<Radio />} label="No" />
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
            </RadioGroup>
          </FormGroup>
          </div>
          <h6>Assets</h6>
          <div className="form__flow-wrap">
          <FormGroup name="take_home_monthly_income" labelText="Your take-home monthly income">
            <InputCurrency
              name="take_home_monthly_income"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.take_home_monthly_income || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="spouse_take_home_income" labelText="Spouse’s take-home monthly income">
            <InputCurrency
              name="spouse_take_home_income"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.spouse_take_home_income || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="social_security_survivors_benefit" labelText="Social security survivorship benefit">
            <InputCurrency
              name="social_security_survivors_benefit"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.social_security_survivors_benefit || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="current_total_assets" labelText="Current total assets">
            <InputCurrency
              name="current_total_assets"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.current_total_assets || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="current_life_insurance" labelText="Current life insurance (you)">
            <InputCurrency
              name="current_life_insurance"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.current_life_insurance || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="spouse_current_life_insurance" labelText="Current life insurance (spouse)">
            <InputCurrency
              name="spouse_current_life_insurance"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.spouse_current_life_insurance || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="child_current_life_insurance" labelText="Current life insurance (child)">
            <InputCurrency
              name="child_current_life_insurance"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.child_current_life_insurance || 0}
              withoutSign
              icon
            />
          </FormGroup>
          </div>
          <h6>Liabilities</h6>
          <div className="form__flow-wrap">
          <FormGroup name="monthly_household_expenses" labelText="Monthly household expenses">
            <InputCurrency
              name="monthly_household_expenses"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.monthly_household_expenses || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="total_expense_attributable" labelText="% of total expenses attributable to you">
            <Input
              name="total_expense_attributable"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.total_expense_attributable || 0}
              iconName={Percentage}
            />
          </FormGroup>
          <FormGroup name="years_cover_expenses" labelText="Number of years looking to cover expenses for survivors">
            <Input
              name="years_cover_expenses"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.years_cover_expenses || 0}
            />
          </FormGroup>
          <FormGroup name="funeral_costs" labelText="Funeral сosts">
            <InputCurrency
              name="funeral_costs"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.funeral_costs || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="estate_expenses" labelText="Estate expenses">
            <InputCurrency
              name="estate_expenses"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.estate_expenses || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="current_outstanding_liability" labelText="Current outstanding liabilities">
            <InputCurrency
              name="current_outstanding_liability"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.current_outstanding_liability || 0}
              withoutSign
              icon
            />
          </FormGroup>
          </div>
          <h6>Other info</h6>
          <div className="form__flow-wrap">
          <FormGroup name="spouse_pass_work" labelText="If spouse passed away, how many months would you be unable to work (e.g. due to grieving, funeral arrangement,etc.)?">
            <Input
              name="spouse_pass_work"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.spouse_pass_work || 0}
            />
          </FormGroup>
          <FormGroup name="spouse_years_cover" labelText="Years looking to cover spouses’s income if spouse passes away">
            <Input
              name="spouse_years_cover"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.spouse_years_cover || 0}
            />
          </FormGroup>
          <FormGroup name="pass_work" labelText="If spouse passed away, how many months would your spouse be unable to work (e.g. due to grieving, funeral arrangement, etc.)?">
            <Input
              name="pass_work"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.pass_work || 0}
            />
          </FormGroup>
          <FormGroup name="child_pass_work" labelText="If child passed away, how many months would you be unable to work (e.g. due to grieving, funeral arrangement, etc.)?">
            <Input
              name="child_pass_work"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.child_pass_work || 0}
            />
          </FormGroup>
          <FormGroup name="child_pass_work_spouse" labelText="If child passed away, how many months would spouse be unable to work (e.g. due to grieving, funeral arrangement,etc.)?">
            <Input
              name="child_pass_work_spouse"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.child_pass_work_spouse || 0}
            />
          </FormGroup>
          </div>
          <div className="form__button">
            <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
              Save
              {sending && <span className="spinner" />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default LifeInsuranceAdmin
