import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Formik } from 'formik'
import Layout from '../../../components/Layout/Layout'
import LayoutForm from '../../../components/Layout/LayoutForm'
import { BackButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import {FormGroup, InputCurrency} from '../../../components/shared/Form'
import { Button, FormControlLabel, Radio, RadioGroup, TextareaAutosize } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import Illustration from '../../../assets/images/illustrations/Tree-segment_protection-min.png'
import { normalizeBoolean } from '../../../utils/utils'
import {fetchInsuranceAuto, postInsuranceAuto} from '../../../redux/slices/insuranceSlice'
import { fetchPercentages } from '../../../redux/slices/percentagesSlice'
import Modal from '../../../components/shared/Modal/Modal'
import ProgressCircle from '../../../components/shared/ProgressBar/ProgressCircle'
import { setIsOpen } from '../../../redux/slices/modalSlice'
import { insuranceAutoSchema } from '../../../utils/validationProtection'

const InsuranceAuto = ({ props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.user.user);
  const insurance = useSelector(state => state.insurance.auto);
  const sending = useSelector(state => state.sending.isSending);
  const percents = useSelector(state => state.percentages.percents);
  const isOpen = useSelector(state => state.modal.isOpen);

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchPercentages({name: 'auto-insurance'}));
      await dispatch(fetchInsuranceAuto());
    }
    if (id) {
      fetch()
    }

    // eslint-disable-next-line
  }, [id])

  const onSubmit = (values) => {
    dispatch(postInsuranceAuto(values, props))
  }

  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }))
  }

  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }))
    history.push('/insurance')
  }

  return (
    <Layout>
      <LayoutForm illustration={Illustration} small>
        <BackButton title="Back to Basic Insurance" />
        <PageTitle
          title="Auto insurance"
          content="Tell us about the details of your health, home, auto and umbrella insurance policies, so ValueSoft can ensure you have the appropriate coverage you require."
          status={percents && percents.percent ? percents.percent : '0'}
        />
        <div className="layout-form__content-holder">
          <Formik
            enableReinitialize
            initialValues={insurance}
            validationSchema={insuranceAutoSchema}
            onSubmit={onSubmit}
          >
            {({ errors, handleChange, handleBlur, values, touched, setFieldValue }) => (
              <Form>
                <FormGroup
                  name="insurance_for_each_car"
                  labelText="Do you have auto insurance on each car you own? (optional)"
                >
                  <RadioGroup
                    name="insurance_for_each_car"
                    value={values.insurance_for_each_car || false}
                    onChange={(e) => {
                      setFieldValue('insurance_for_each_car', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                </FormGroup>
                <FormGroup name="personal_liability_auto_per_person" labelText="Personal Liability coverage amount (per person): (optional)">
                  <InputCurrency
                    icon
                    placeholder="Type your Personal Liability coverage amount "
                    type="text"
                    name="personal_liability_auto_per_person"
                    onChange={handleChange}
                    value={values.personal_liability_auto_per_person || null}
                    onBlur={handleBlur}
                    hasError={!!errors.personal_liability_auto_per_person}
                  />
                  {errors.personal_liability_auto_per_person && <span className="form__msg-error">{errors.personal_liability_auto_per_person}</span>}
                </FormGroup>
                <FormGroup name="personal_liability_auto_per_incident" labelText="Personal Liability coverage amount (per incident): (optional)">
                  <InputCurrency
                    icon
                    placeholder="Type your Personal Liability coverage amount"
                    type="text"
                    name="personal_liability_auto_per_incident"
                    onChange={handleChange}
                    value={values.personal_liability_auto_per_incident || null}
                    onBlur={handleBlur}
                    hasError={!!errors.personal_liability_auto_per_incident}
                  />
                  {errors.personal_liability_auto_per_incident && <span className="form__msg-error">{errors.personal_liability_auto_per_incident}</span>}
                </FormGroup>
                <FormGroup name="property_damage" labelText="Property Damage coverage amount: (optional)">
                  <InputCurrency
                    icon
                    placeholder="Type your Property Damage coverage amount"
                    type="text"
                    name="property_damage"
                    onChange={handleChange}
                    value={values.property_damage || null}
                    onBlur={handleBlur}
                    hasError={!!errors.property_damage}
                  />
                  {errors.property_damage && <span className="form__msg-error">{errors.property_damage}</span>}
                </FormGroup>
                <FormGroup name="medical_payments" labelText="Medical Payments coverage amount: (optional)">
                  <InputCurrency
                    icon
                    placeholder="Type your Medical Payments coverage "
                    type="text"
                    name="medical_payments"
                    onChange={handleChange}
                    value={values.medical_payments || null}
                    onBlur={handleBlur}
                    hasError={!!errors.medical_payments}
                  />
                  {errors.medical_payments && <span className="form__msg-error">{errors.medical_payments}</span>}
                </FormGroup>
                <FormGroup name="uninsured_motorist_per_person" labelText="Uninsured Motorist coverage amount (per person): (optional)">
                  <InputCurrency
                    icon
                    placeholder="Type your Uninsured Motorist coverage "
                    type="text"
                    name="uninsured_motorist_per_person"
                    onChange={handleChange}
                    value={values.uninsured_motorist_per_person || null}
                    onBlur={handleBlur}
                    hasError={!!errors.uninsured_motorist_per_person}
                  />
                  {errors.uninsured_motorist_per_person && <span className="form__msg-error">{errors.uninsured_motorist_per_person}</span>}
                </FormGroup>
                <FormGroup name="uninsured_motorist_per_incident" labelText="Uninsured Motorist coverage amount (per incident): (optional)">
                  <InputCurrency
                    icon
                    placeholder="Type your Uninsured Motorist coverage "
                    type="text"
                    name="uninsured_motorist_per_incident"
                    onChange={handleChange}
                    value={values.uninsured_motorist_per_incident || null}
                    onBlur={handleBlur}
                    hasError={!!errors.uninsured_motorist_per_incident}
                  />
                  {errors.uninsured_motorist_per_incident && <span className="form__msg-error">{errors.uninsured_motorist_per_incident}</span>}
                </FormGroup>
                <FormGroup name="comprehensive" labelText="Comprehensive Deductible: (optional)">
                  <InputCurrency
                    icon
                    placeholder="Type your Comprehensive Deductible"
                    type="text"
                    name="comprehensive"
                    onChange={handleChange}
                    value={values.comprehensive || null}
                    onBlur={handleBlur}
                    hasError={!!errors.comprehensive}
                  />
                  {errors.comprehensive && <span className="form__msg-error">{errors.comprehensive}</span>}
                </FormGroup>
                <FormGroup name="towing" labelText="Towing and Labor Deductible: (optional)">
                  <InputCurrency
                    icon
                    placeholder="Type your Towing and Labor Deductible"
                    type="text"
                    name="towing"
                    onChange={handleChange}
                    value={values.towing || null}
                    onBlur={handleBlur}
                    hasError={!!errors.towing}
                  />
                  {errors.towing && <span className="form__msg-error">{errors.towing}</span>}
                </FormGroup>
                <FormGroup name="rental" labelText="Rental Reimbursement (per incident): (optional)">
                  <InputCurrency
                    icon
                    placeholder="Type your Rental Reimbursement"
                    type="text"
                    name="rental"
                    onChange={handleChange}
                    value={values.rental || null}
                    onBlur={handleBlur}
                    hasError={!!errors.rental}
                  />
                  {errors.rental && <span className="form__msg-error">{errors.rental}</span>}
                </FormGroup>
                <h5 className="mt--medium">Additional info</h5>
                <FormGroup name="additional_info" labelText="Is there any other Basic Insurance information that you believe is important for ValueSoft to know about? If so, please let us know about it:">
                  <TextareaAutosize
                    maxLength="200"
                    name="additional_info"
                    value={values.additional_info || ''}
                    className="form__textarea"
                    placeholder="Maximum characters: 200"
                    onChange={handleChange}
                  />
                </FormGroup>

                <div className="form__button">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Save
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <Modal
          open={isOpen}
          small
          button="Done"
          onClick={handleClick}
          close={onClose}
        >
          <h3>Good Job!</h3>
          <p>Your progress increased 100%</p>
          <ProgressCircle amount={100} small />
        </Modal>
      </LayoutForm>
    </Layout>
  );
};

export default InsuranceAuto;
