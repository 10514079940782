import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useLocation, useHistory } from "react-router-dom";
import { Pagination as MuiPagination } from "@mui/material";
import { useQuery } from "../../utils/hooks";

const Pagination = ({ count = 0, ...rest }) => {
  const location = useLocation();
  const history = useHistory();
  const query = useQuery();
  const [page, setPage] = useState(1);

  useEffect(() => {
    const queryPage = query.get('page');
    if (queryPage) setPage(+queryPage);
  }, [query]);

  const onChangePage = (e, page) => {
    query.set('page', page);

    history.push({ pathname: location.pathname, search: query.toString() });
  };

  return (
    count > 1
      ? <Box
        component={MuiPagination}
        mb={0}
        page={page}
        count={count}
        siblingCount={0}
        onChange={onChangePage}
        {...rest}
      />
      : <></>
  )
};

export default Pagination;