import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import SVGIcon from '../shared/SVGIcon/SVGIcon'
import IconOther from '../../assets/images/icons/other.svg'
import Edit from '../../assets/images/icons/edit.svg'
import Trash from '../../assets/images/icons/trash.svg'
import numeral from 'numeral'
import More from '../../assets/images/icons/more.svg'


const ExpenseCard = ({ name, amount, icon, onDelete, onEdit, id }) => {
  const [isOpen, setOpen] = useState(false)
  const [isLeaving, setIsLeaving] = useState(false)
  const popup = useRef()

  const onToggle = () => setOpen(!isOpen)

  useEffect(() => {
    const listener = event => {
      if (!popup.current || popup.current.contains(event.target)) {
        return;
      }

      setOpen(false)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [popup])

  const remove = async id => {
    await setIsLeaving(true)
    await onDelete(id)
  }

  return (
    <div className={`expense-card${isLeaving ? ' expense-card--animate' : ''}`}>
      <div className="expense-card__icon">
        <img src={icon ? icon : IconOther} alt={name} />
      </div>
      <div className="expense-card__content">
        <div className="expense-card__title">{name}</div>
        <div className="expense-card__amount">{numeral(amount).format('$0,0.00')}</div>
      </div>
      <button onClick={onToggle} className="expense-card__toggle">
        <SVGIcon icon={More} />
      </button>
      <div ref={popup} className={`expense-card__options${isOpen ? ' expense-card__options--open' : ''}`}>
        <ul>
          <li>
            <button onClick={() => onEdit(id)}>
              <SVGIcon icon={Edit} color="#8c8c8c" />
              Edit
            </button>
          </li>
          <li>
            <button onClick={() => remove(id)}>
              <SVGIcon icon={Trash} color="#8c8c8c" />
              Delete
            </button>
          </li>
        </ul>
      </div>
    </div>
  )
}

ExpenseCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  icon: PropTypes.string,
  amount: PropTypes.string,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
}

export default ExpenseCard
