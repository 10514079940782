import React from 'react'
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer'
import moment from 'moment'
import SourceSansProSemiBold from '../../assets/fonts/source-sans-pro-600.ttf'
import SourceSansPro from '../../assets/fonts/source-sans-pro.ttf'
import colors from '../../Data/colors'


Font.register({
  family: 'Source Sans Pro',
  src: SourceSansPro,
  fonts: [
    {
      src: SourceSansPro,
    },
    {
      src: SourceSansProSemiBold,
      fontWeight: 'semibold',
    },
  ],
});


const styles = StyleSheet.create({
  body: {
    paddingTop: 20,
    paddingBottom: 100,
    paddingHorizontal: 20,
    flexDirection: "column",
  },
  header: {
    flexDirection: "row",
    paddingBottom: 30,
  },
  date: {
    color: "#000",
    fontSize: 14,
    width: "77%",
    height: "50px",
    fontFamily: "Source Sans Pro",
    textAlign: "right",
    paddingTop: "16px"
  },
  logoBox: {
    width: "130px",
    height: "50px",
  },
  logo: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  pageTitle: {
    paddingBottom: 20,
    fontFamily: "Source Sans Pro",
    fontWeight: "semibold",
    fontSize: 18,
    flexDirection: "row",
  },
  pageTitleText: {
    width: "80%",
  },
  pageTitleCount: {
    width: "20%",
    textAlign: "right",
    fontSize: 12,
  },
  footer: {
    position: "absolute",
    fontSize: 9,
    lineHeight: 1.3,
    bottom: 0,
    left: 0,
    right: 0,
    flexGrow: 0,
    paddingTop: 10,
    paddingBottom: 20,
    paddingHorizontal: 20,
    color: "#8C8C8C",
    backgroundColor: "#F9F9F9",
    flexDirection: "row",
    fontFamily: "Source Sans Pro",
  },
  footerCount: {
    fontWeight: "semibold",
    width: "10%",
    textAlign: "right",
    color: "#000",
  },
  footerText: {
    width: "90%",
  },
  table: {
    fontSize: 12,
    fontFamily: "Source Sans Pro",
    display: "table",
    width: "auto",
    borderColor: "#F3F3F3",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    width: "auto",
    flexDirection: "row",
  },
  tableColRow: { width: "75%" },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderColor: "#F3F3F3",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left",
    padding: "15px 10px",
  },
  tableColTitle: {
    fontWeight: "semibold",
    color: "#fff",
    width: "25%",
    borderStyle: "solid",
    borderColor: "#215E59",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "3px 10px",
    backgroundColor: "#215E59",
  },
  tableCell: { fontWeight: "semibold" },
  alert: {
    textAlign: "center",
    fontFamily: "Source Sans Pro",
    fontSize: 14,
    fontWeight: "semibold",
    color: "#215E59",
  },
  chart: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  chartImage: {
    width: "300px",
    height: "300px",
    margin: "auto",
  },
  percent: {
    width: "100%",
    flexWrap: "wrap",
    flexDirection: "row",
    fontSize: 11,
    fontFamily: "Source Sans Pro",
  },
  percentLine: {
    width: "50%",
    flexDirection: "row",
    padding: "5px 20px 2px 0",
  },
  percentCol: {
    width: "80%",
    flexDirection: "row",
    alignItems: "center",
  },
  percentColSmall: {
    width: "20%",
    textAlign: "right",
    fontWeight: "semibold",
  },
  ball: {
    margin: "0 6px 0 0",
    width: "10px",
    height: "10px",
    borderRadius: 5,
  },
});

const MyDocumentDetails = ({ data, name, percent, image, logo }) => {
  const dateNow = new Date()
  const year    = dateNow.getFullYear()

  const countTasks = array => {
    return array.reduce((a, b) => {return a + b.tasks.length}, 0)
  }

  return (
    <Document>
      <Page size="A4" style={styles.body} wrap>
        <View fixed style={styles.header}>
          <View style={styles.logoBox}>
            <Image style={styles.logo} src={logo} />
          </View>
          <Text fixed style={styles.date}>
            {moment(dateNow).format("LL")}
          </Text>
        </View>

        <View fixed style={styles.pageTitle}>
          <Text style={styles.pageTitleText}>
            {name && `${name}'s Open Financial Planning Tasks`}
          </Text>
          <Text style={styles.pageTitleCount}>
            {data && data.length > 0 && `${countTasks(data)} tasks`}
          </Text>
        </View>

        {data && data.length > 0 ? (
          <>
            <View fixed style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableColTitle}>
                  <Text style={styles.tableCell}>Category</Text>
                </View>
                <View style={[styles.tableColTitle, { width: "50%" }]}>
                  <Text style={styles.tableCell}>Task</Text>
                </View>
                <View style={styles.tableColTitle}>
                  <Text style={styles.tableCell}>Priority</Text>
                </View>
              </View>
            </View>
            <View style={styles.table}>
              {data &&
                data.map((item) => (
                  <View style={styles.tableRow} key={item.id}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{item.name}</Text>
                    </View>
                    <View style={styles.tableColRow}>
                      {item.tasks.length > 0 &&
                        item.tasks
                          .sort((a, b) =>
                            a.priority_id > b.priority_id ? 1 : -1
                          )
                          .map((task) => (
                            <View
                              style={styles.tableRow}
                              key={`task-${task.id}`}
                            >
                              <View style={[styles.tableCol, { width: "67%" }]}>
                                <Text>{task.name}</Text>
                              </View>
                              <View style={[styles.tableCol, { width: "33%" }]}>
                                <Text>{task.priority.name}</Text>
                              </View>
                            </View>
                          ))}
                    </View>
                  </View>
                ))}
            </View>
          </>
        ) : (
          <Text style={styles.alert}>You don't have any tasks</Text>
        )}
        {image && (
          <View style={styles.chart} break>
            <Image style={styles.chartImage} src={image} />
          </View>
        )}

        {percent && (
          <View style={styles.percent}>
            {percent.map((item, i) => (
              <React.Fragment key={`percent-${i}`}>
                <View style={styles.percentLine}>
                  <View style={styles.percentCol}>
                    <Text
                      style={[styles.ball, { backgroundColor: colors[i] }]}
                    />
                    <Text>{item.name}</Text>
                  </View>
                  <View style={styles.percentColSmall}>
                    <Text>{item.percent} %</Text>
                  </View>
                </View>
              </React.Fragment>
            ))}
          </View>
        )}

        <View fixed style={styles.footer}>
          <Text style={styles.footerText}>
            ValueSoft, Inc. © {year}. All rights reserved. VALUESOFT, INC. SHALL
            HAVE NO LIABILITY, CONTINGENT OR OTHERWISE, FOR ANY DECISIONS MADE,
            OR ACTIONS TAKEN, IN RELIANCE UPON ITS PROGRAMS OR REPORTS. You
            alone are solely responsible for determining whether any investment,
            strategy or any other product or service is appropriate or suitable
            for you based on your investment objectives and personal and
            financial situation. You should consult an independent financial
            advisor, attorney or tax professional regarding your specific
            investment, legal or tax situation.
          </Text>
          <Text
            fixed
            style={styles.footerCount}
            render={({ pageNumber }) => `${pageNumber}`}
          />
        </View>
      </Page>
    </Document>
  );
}

export default MyDocumentDetails