import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../../components/Layout/Layout'
import LayoutForm from '../../../components/Layout/LayoutForm'
import { BackButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import { Button } from '@mui/material'
import { fetchRetirement, postRetirement } from '../../../redux/slices/worthSlice'
import { FormGroup, InputCurrency } from '../../../components/shared/Form'
import Priority from '../../../components/shared/Priority/Priority'
import FormSelect from '../../../components/shared/Form/Select'
import Modal from '../../../components/shared/Modal/Modal'
import ProgressCircle from '../../../components/shared/ProgressBar/ProgressCircle'
import { setIsOpen } from '../../../redux/slices/modalSlice'
import { worthRetirementSchema } from '../../../utils/validationFinances'

const WorthAssetsRetirement = ({ props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.user.user);
  const { retirement } = useSelector(state => state.worth);
  const isOpen = useSelector(state => state.modal.isOpen);
  const sending = useSelector(state => state.sending.isSending);

  useEffect(() => {
    async function fetch() {
      dispatch(fetchRetirement())
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const onSubmit = (values) => {
    dispatch(postRetirement(values, props))
  }

  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }))
  }

  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }));
    history.push('/worth/assets')
  }

  return (
    <Layout>
      <LayoutForm small>
        <BackButton title="Back" />
        <PageTitle
          title="Retirement accounts"
        />
        <div className="layout-form__content-holder">
          <Formik
            enableReinitialize
            initialValues={retirement && {...retirement}}
            onSubmit={onSubmit}
            validationSchema={worthRetirementSchema}
          >
            {({ errors, handleChange, handleBlur, values, setFieldValue }) => (
              <Form>
                <FormGroup name="account_401k" labelText="401K accounts ($)">
                  <InputCurrency
                    placeholder="$0.00"
                    type="text"
                    name="account_401k"
                    onChange={handleChange}
                    value={values.account_401k || ''}
                    onBlur={handleBlur}
                    info={{
                      content:
                        <>
                          <h6>Traditional 401K</h6>
                          <Priority option="High" />
                          <div>A defined contribution plan offered by a corporation to its employees, which allows employees to set aside tax-deferred income for retirement purposes, and in some cases employers will match their contribution dollar-for-dollar. Taking a distribution  of the funds before a certain specified age will trigger a penalty tax. The name 401(k) comes from the IRS section describing the program.</div>
                        </>
                    }}
                    hasError={!!errors.account_401k}
                  />
                  {errors.account_401k && <span className="form__msg-error">{errors.account_401k}</span>}
                </FormGroup>
                <FormGroup name="account_403b" labelText="403B accounts ($)">
                  <InputCurrency
                    placeholder="$0.00"
                    type="text"
                    name="account_403b"
                    onChange={handleChange}
                    value={values.account_403b || ''}
                    onBlur={handleBlur}
                    info={{
                      content:
                        <>
                          <h6>Traditional 403B</h6>
                          <Priority option="High" />
                          <div>A retirement plan similar to a 401(k) plan, but one which is offered by non-profit organizations, such as universities and some charitable organizations, rather than corporations. There are several advantages to 403(b) plans: contributions lower taxable income, larger contributions can be made to the account, earnings can grow tax-deferred, and some plans allow loans. Contributions can grow tax-deferred until withdrawal at which time the money is taxed as ordinary income</div>
                        </>
                    }}
                    hasError={!!errors.account_403b}
                  />
                  {errors.account_403b && <span className="form__msg-error">{errors.account_403b}</span>}
                </FormGroup>
                <FormGroup name="pension" labelText="Pension ($)">
                  <InputCurrency
                    placeholder="$0.00"
                    type="text"
                    name="pension"
                    onChange={handleChange}
                    value={values.pension || ''}
                    onBlur={handleBlur}
                    info={{
                      content:
                        <>
                          <h6>Pension</h6>
                          <Priority option="High" />
                          <div>Please enter your total pension balance owed to you upon your retirement.  If your employer is matching your pension contributions and you're fairly confident you will receive your employer pension benefits, please include the total pension value owed to you.  Please sum up all pension values, if you will be receiving pension benefits from multiple pension sources.</div>
                        </>
                    }}
                    hasError={!!errors.pension}
                  />
                  {errors.pension && <span className="form__msg-error">{errors.pension}</span>}
                </FormGroup>
                <FormGroup name="solo_401k_account" labelText="Solo 401K accounts ($)">
                  <InputCurrency
                    placeholder="$0.00"
                    type="text"
                    name="solo_401k_account"
                    onChange={handleChange}
                    value={values.solo_401k_account || ''}
                    onBlur={handleBlur}
                    info={{
                      content:
                        <>
                          <h6>Solo 401K</h6>
                          <Priority option="High" />
                          <div>A solo 401k plan is a regular 401k plan combined with a profit-sharing plan. However, unlike a regular 401k plan, a solo 401k can be activated only by self-employed individuals or small business owners who have no other full-time employees.</div>
                        </>
                    }}
                    hasError={!!errors.solo_401k_account}
                  />
                  {errors.solo_401k_account && <span className="form__msg-error">{errors.solo_401k_account}</span>}
                </FormGroup>
                <FormGroup name="roth_401k_account" labelText="Roth 401K accounts ($)">
                  <InputCurrency
                    placeholder="$0.00"
                    type="text"
                    name="roth_401k_account"
                    onChange={handleChange}
                    value={values.roth_401k_account || ''}
                    onBlur={handleBlur}
                    info={{
                      content:
                        <>
                          <h6>Roth 401K</h6>
                          <Priority option="High" />
                          <div>A new employer-sponsored investment savings account that is funded with after-tax money. After the investor reaches age 59.5, withdrawals of any money from the account (including investment gains) are tax-free. Unlike the Roth IRA, the Roth 401(k) has no income limitations for those investors who want to participate - anyone, no matter what his/her income, is allowed to invest up to the contribution limit into the plan.</div>
                        </>
                    }}
                    hasError={!!errors.roth_401k_account}
                  />
                  {errors.roth_401k_account && <span className="form__msg-error">{errors.roth_401k_account}</span>}
                </FormGroup>
                <FormGroup
                  name="is_401k_under_one"
                  labelText="Is your 401K balance consolidated under one account?"
                  info={{
                    content:
                      <>
                        <h6>Consolidated 401k</h6>
                        <Priority option="Medium" />
                        <div>If you have a 401K account at a previous employer, another 401K account at a current employer, then answer Yes to this question.  Consolidating 401K accounts may be a good way to reduce account fees, and potentially improve your overall returns.</div>
                      </>
                  }}
                >
                  <FormSelect
                    options={[
                      {
                        key: 1,
                        label: 'Yes',
                        value: 2,
                      },
                      {
                        key: 2,
                        label: 'No',
                        value: 1,
                      },
                      {
                        key: 3,
                        label: 'Not Applicable',
                        value: 3,
                      },
                    ]}
                    value={values.is_401k_under_one ? values.is_401k_under_one.toString() : ''}
                    name="is_401k_under_one"
                    onChange={(e) => {
                      setFieldValue(e.target.name, e.target.value)
                    }}
                    placeholder="Select"
                  />
                </FormGroup>
                <FormGroup name="traditional_ira_account" labelText="Traditional IRA accounts ($)">
                  <InputCurrency
                    placeholder="$0.00"
                    type="text"
                    name="traditional_ira_account"
                    onChange={handleChange}
                    value={values.traditional_ira_account || ''}
                    onBlur={handleBlur}
                    info={{
                      content:
                        <>
                          <h6>Traditional IRA</h6>
                          <Priority option="High" />
                          <div>Individual Retirement Account. A tax-deferred retirement account for an individual that permits individuals to set aside money each year, with earnings tax-deferred until withdrawals begin at age 59 and a half or later (or earlier, with a 10 percent penalty).</div>
                        </>
                    }}
                    hasError={!!errors.traditional_ira_account}
                  />
                  {errors.traditional_ira_account && <span className="form__msg-error">{errors.traditional_ira_account}</span>}
                </FormGroup>
                <FormGroup name="roth_ira_account" labelText="Roth IRA accounts ($)">
                  <InputCurrency
                    placeholder="$0.00"
                    type="text"
                    name="roth_ira_account"
                    onChange={handleChange}
                    value={values.roth_ira_account || ''}
                    onBlur={handleBlur}
                    info={{
                      content:
                        <>
                          <h6>Roth IRA</h6>
                          <Priority option="High" />
                          <div>A new type of IRA, established in the Taxpayer Relief Act of 1997, which allows taxpayers, subject to certain income limits, to save for retirement while allowing the savings to grow tax-free. Taxes  are paid on contributions, but withdrawals, subject to certain rules, are not taxed at all.</div>
                        </>
                    }}
                    hasError={!!errors.roth_ira_account}
                  />
                  {errors.roth_ira_account && <span className="form__msg-error">{errors.roth_ira_account}</span>}
                </FormGroup>
                <FormGroup name="simple_ira_account" labelText="Simple IRA accounts ($)">
                  <InputCurrency
                    placeholder="$0.00"
                    type="text"
                    name="simple_ira_account"
                    onChange={handleChange}
                    value={values.simple_ira_account || ''}
                    onBlur={handleBlur}
                    info={{
                      content:
                        <>
                          <h6>Simple IRA</h6>
                          <Priority option="High" />
                          <div>A savings incentive match plan for employees of small businesses with 100 or fewer employees. The plan is similar to, but more flexible than, a regular IRA. Employees enter into a qualified salary reduction agreement to contribute a percentage of their annual compensation to a tax-deferred retirement account. The employer may either match employee contributions or contribute a fixed percentage of all employees' pay. Earnings grow tax-deferred.</div>
                        </>
                    }}
                    hasError={!!errors.simple_ira_account}
                  />
                  {errors.simple_ira_account && <span className="form__msg-error">{errors.simple_ira_account}</span>}
                </FormGroup>
                <FormGroup name="sep_ira_account" labelText="SEP IRA accounts ($)">
                  <InputCurrency
                    placeholder="$0.00"
                    type="text"
                    name="sep_ira_account"
                    onChange={handleChange}
                    value={values.sep_ira_account || ''}
                    onBlur={handleBlur}
                    info={{
                      content:
                        <>
                          <h6>SEP IRA</h6>
                          <Priority option="High" />
                          <div>The SEP IRA is a tax-deductible, tax-deferred retirement plan designed to benefit self employed individuals and small business owners. Sole proprietorships, S and C corporations, partnerships and LLCs qualify.</div>
                        </>
                    }}
                    hasError={!!errors.sep_ira_account}
                  />
                  {errors.sep_ira_account && <span className="form__msg-error">{errors.sep_ira_account}</span>}
                </FormGroup>
                <div className="form__button form__button--center">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Save
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <Modal
          open={isOpen}
          small
          button="Done"
          onClick={handleClick}
          close={onClose}
        >
          <h3>Good Job!</h3>
          <p>Your progress increased 100%</p>
          <ProgressCircle amount={100} small />
        </Modal>
      </LayoutForm>
    </Layout>
  );
};

export default WorthAssetsRetirement;
