import React, { useState } from 'react'
import { IconButton } from '@mui/material'
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PropTypes from 'prop-types'

const TooltipCustom = ({ title, position, className, size, customHandler, onMouseEnter, onMouseLeave, isHover=false }) => {
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true);

    if(customHandler) {
      customHandler()
    }
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        arrow
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClose={handleClose}
        open={open || isHover}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={title}
        placement={position}
        TransitionComponent={Zoom}
      >
        <IconButton
          size={size}
          className={`MuiIconButton-default ${className ? className : ""}`}
          onClick={handleOpen}
        >
          <HelpOutlineIcon />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
}


TooltipCustom.propTypes = {
  className: PropTypes.string,
  position: PropTypes.string,
  size: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ])
}


export default TooltipCustom
