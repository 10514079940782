import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Switch } from '@mui/material';
import Priority from '../shared/Priority/Priority';
import Icon from '../../assets/images/icons/drop.svg';
import SVGIcon from '../shared/SVGIcon/SVGIcon';
import More from '../../assets/images/icons/more.svg';
import ModalBox from '../shared/Modal/Modal';

const TaskCardSmall = ({ companyId, id, title, status, category, priority, content, icon, removeTask, path, switcher, onSwitch, checked }) => {
  let history = useHistory();
  const [isOpen, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const popup = useRef();

  const onToggle = () => setOpen(!isOpen);

  useEffect(() => {
    const listener = (event) => {
      if (!popup.current || popup.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [popup]);

  const remove = (id) => {
    removeTask(id);
    setOpenDeleteModal(false);
  };

  return (
    <>
      <div className={`task-card task-card--small ${switcher ? 'task-card--switch' : ' '}`}>
        {switcher && (
          <div className="task-card__menu">
            <button onClick={onToggle}>
              <SVGIcon icon={More} />
            </button>
            <div ref={popup} className={`task-card__menu-drop${isOpen ? ' task-card__menu-drop--open' : ''}`}>
              <ul>
                <li>
                  <button onClick={() => history.push(`/admin/company/${companyId}/tasks/${id}`)}>Edit</button>
                </li>
                <li>
                  <button onClick={() => setOpenDeleteModal(true)}>Remove</button>
                </li>
              </ul>
            </div>

            <Switch
              className="custom-switcher custom-switcher--task"
              checked={checked}
              onChange={() => onSwitch(id, checked)}
            />
          </div>
        )}
        <Link to={path} className="task-card__inner">
          <div className="task-card__holder">
            <div className="task-card__title">
              <div className="task-card__image">{icon && <img src={icon} alt={title} />}</div>
              <h5>{title}</h5>
            </div>
            <div className="task-card__info">
              <div className="task-card__category">{category}</div>
              {priority && (
                <div className="task-card__priority">
                  <Priority option={priority} />
                  <div className="task-card__priority-text">
                    <SVGIcon icon={Icon} color="#0F9AFF" />
                    {priority}
                  </div>
                </div>)}
            </div>
            <div 
              className="task-card__content" 
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
        </Link>
      </div>

      <ModalBox
        small
        inline
        open={openDeleteModal}
        close={() => setOpenDeleteModal(false)}
        title="Do you want to delete this task?"
        button="No"
        onClick={() => setOpenDeleteModal(false)}
        removeButton="Yes"
        remove={() => remove(id)}
      />
    </>
  );
};

TaskCardSmall.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  status: PropTypes.string,
  category: PropTypes.string,
  priority: PropTypes.string,
  content: PropTypes.string,
  removeTask: PropTypes.func,
  path: PropTypes.string,
  icon: PropTypes.string,
};

export default TaskCardSmall;
