const taxFilingStatus = [
  {
    key: 1,
    label: 'Single',
    value: 'single',
  },
  {
    key: 2,
    label: 'Head of Household',
    value: 'head',
  },
  {
    key: 3,
    label: 'Married Filing Separately',
    value: 'married_separately',
  },
  {
    key: 4,
    label: 'Married Filing Jointly',
    value: 'married_jointly',
  },
  {
    key: 5,
    label: 'Qualifying Widow(er)',
    value: 'qualifying',
  }
]

export default taxFilingStatus;