import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import clsx from 'classnames'
import { fillStatus } from '../../utils/utils'
import Arrow from '../../assets/images/icons/arrow.svg'
import SVGIcon from '../shared/SVGIcon/SVGIcon'
import TreeCount from '../../components/Tree/TreeCount'
import { Backdrop } from "@mui/material";

const TreeItem = ({ value, title, count, children, setIsActive, isMobile, path, isDisabled, isLoading, className }) => {
  const [isHover, setIsHover] = useState(false);
  const [isTouched, setIsTouched] = useState(false);

  const hoverOn = () => {
    if(!isDisabled) {
      setIsActive(true)
      setIsHover(true)
    }
  }

  const hoverOff = () => {
    if(!isDisabled) {
      setIsActive(false)
      setIsHover(false)
    }
  }

  const handleClick = (event) => {
    if(isTouched) {
      event.preventDefault();
      event.stopPropagation();
    }
    setIsTouched(false);
  };

  const onTouchStart = (event) => {
    event.stopPropagation();

    if(!isHover) {
      setIsTouched(true);
      hoverOn();
    }
  };

  return (
    <>
      {isMobile
        ? (
          <Link
            className={clsx(`tree-item tree-item--${value}`, {
                'tree-item--disabled' : isDisabled,
                [`${className}`]: className
            })}
            to={path}>
              <div className="tree__label">
                <div>{title}</div>

                <TreeCount
                  value={count}
                  loading={isLoading}
                />
              </div>
              <SVGIcon icon={Arrow} color="#8C8C8C" className="tree-item__icon" />
          </Link>
        )
        : (
          <div className={clsx(`tree-item tree-item--${value} tree-item--${fillStatus(count)}`,
            {
              'tree-item--hover' : isHover,
              'tree-item--disabled' : isDisabled,
              [`${className}`]: className
            })}
               onMouseEnter={hoverOn}
               onMouseLeave={hoverOff}
               onClickCapture={handleClick}
               onTouchStartCapture={onTouchStart}
          >
            <div className="tree__label">
              <div>{title}</div>

              <TreeCount
                value={count}
                loading={isLoading}
              />
            </div>
            <div className="tree-item__holder">
              {children}
            </div>
          </div>
        )
      }
      <Backdrop style={{ zIndex: 1 }} open={isHover} onClick={() => hoverOff()} invisible />
    </>
  )
}

TreeItem.propTypes = {
  value: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  children: PropTypes.node,
  setIsActive: PropTypes.func,
  isMobile: PropTypes.bool,
  isDisabled: PropTypes.bool
}

export default TreeItem
