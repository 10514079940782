import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Redirect } from "react-router-dom";
import Modal from "../../components/shared/Modal/Modal";
import { Button } from "@mui/material";
import { Form, Formik } from "formik";
import { setIsOpen } from "../../redux/slices/modalSlice";
import { setMode } from "../../redux/slices/modeSlice";
import {
  fetchTaskPlans,
  updateTaskPlans,
  revertTaskPlans,
} from "../../redux/slices/taskSlice";
import { fetchCompany } from "../../redux/slices/companiesSlice";
import LayoutAdmin from "../../components/Layout/LayoutAdmin";
import TaskPlanCard from "../../components/Cards/TaskPlanCard";
import Loading from "../../components/shared/Loading/Loading";
import { AdminPlansSchema } from "../../utils/validation";
import priorities from '../../Data/optionsPriorities';

const AdminPlans = ({ isAdmin }) => {
  const dispatch = useDispatch();
  const { role_id } = useSelector((state) => state.user.user);
  const { taskPlans, taskPlansRevert, taskLoading } = useSelector(state => state.task);
  const isOpen = useSelector(state => state.modal.isOpen);
  const { image, email, name } = useSelector(
    (state) => state.companies.company.info
  );
  const mode = useSelector(state => state.mode.mode);
  const { companyId } = useParams();
  const [isMobile, setIsMobile] = useState(false);

  const mobileView = 999;

  const handleResize = () => {
    mobileView >= window.innerWidth ? setIsMobile(true) : setIsMobile(false);
  };

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(fetchTaskPlans(companyId));
    dispatch(fetchCompany(companyId));
    // eslint-disable-next-line
  }, []);

  const handleCloseInfoModal = () => {
    dispatch(setIsOpen({ isOpen: false }))
    dispatch(setMode(null));
  };

  const handleSubmit = values => {
    dispatch(updateTaskPlans(companyId, { data: values }));
  };

  if (role_id && role_id > 2) return <Redirect to="/404" />;

  return (
    <LayoutAdmin companyId={companyId} isAdmin={isAdmin}>
      <div className="member-comapny">
        <div className="member-comapny__info">
          <div className="member-comapny__logo">
            <img src={image} alt="Company logo" />
          </div>
          <div className="member-comapny__title">
            <h3>{name}</h3>
            {!isMobile && (
              <p>
                Feedback Email: <span>{email}</span>
              </p>
            )}
          </div>
        </div>
        {isMobile && (
          <div className="mobile-feedback">
            Feedback Email: <span>{email}</span>
          </div>
        )}
      </div>
      <div className="admin__plans">
        <h3 className="admin__plans__title">Choose task plan</h3>
        <p className="admin__plans__description">
          You have options on how you'd like to use and interact with ValueSoft.
          The "Input plan" allows you to make a tradeoff between your input
          burden and the comprehensiveness of your financial plan. The "Task
          plan" allows you to set the pace in which you'd like to accomplish
          your action items.
        </p>
        <h5 className="admin__plans__subtitle">Task plan</h5>
        {taskLoading ? (
          <Loading />
        ) : (
          <>
            <Formik
              enableReinitialize
              initialValues={taskPlans}
              validationSchema={AdminPlansSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, handleChange, values, touched }) => (
                <Form>
                  <div className="grid grid-3 align-items__start">
                    {values.map((item, index) => (
                      <div key={item.id}>
                        <div className="box admin__plans__box">
                          <TaskPlanCard
                            data={item}
                            index={index}
                            errors={errors}
                            touched={touched}
                            priorities={priorities}
                            onChange={handleChange}
                            tasks_of_week={item.default_plan_id === 1 || item.default_plan_id === 2}
                            number_of_week={item.default_plan_id === 3}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="admin__plans__buttons">
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      color="primary"
                    >
                      Save changes
                    </Button>
                    {taskPlansRevert && (
                      <Button
                        type="button"
                        size="large"
                        className="MuiButton-textGray"
                        onClick={() => dispatch(revertTaskPlans(companyId))}
                      >
                        Revert to original
                      </Button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </>
        )}
      </div>
      <Modal
        open={isOpen}
        small
        button="Ok"
        onClick={handleCloseInfoModal}
        close={handleCloseInfoModal}
        title={mode === "success" ? "Success!" : "Error!"}
        children={
          mode === "success" ? (
            <p>The values were updated successfully.</p>
          ) : (
            <p>Something went wrong. Try again.</p>
          )
        }
      />
    </LayoutAdmin>
  );
};

export default AdminPlans;
