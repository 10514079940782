import { createSlice } from '@reduxjs/toolkit'
import { Worth } from '../../api/Api'
import { setSending } from './sendingSlice'
import { convertDecimal } from '../../utils/utils'
import { setIsOpen } from './modalSlice'
import {setNewTasksCount} from "./taskSlice";
import { setIsRelogin } from "./loadingSlice";

const worthSlice = createSlice({
  name: 'worth',
  initialState: {
    cash: {
      checking: null,
      saving: null,
      cash_equivalent: null
    },
    retirement: {
      account_401k: null,
      account_403b: null,
      pension: null,
      solo_401k_account: null,
      roth_401k_account: null,
      is_401k_under_one: '',
      traditional_ira_account: null,
      roth_ira_account: null,
      simple_ira_account: null,
      sep_ira_account: null
    },
    nonRetirement: {
      brokerage_account: null,
      trust_account: null,
      annuities_account: null,
      cash_value: null,
      unvested_stock: null,
      vested_stock: null,
    },
    fixedAsset: {
      home_primary: null,
      appreciable_asset: null,
      depreciable_asset: null
    },
    debtCredit: {
      mortgage_balance: null,
      equity_credit_balance: null,
      credit_balance: null,
      overdue_balance: null
    },
    necessity: {
      utilities: null,
      phone_payments: null,
      mortgage_payments: null,
      car_payments: null,
      taxes: null,
      other_loan_balance: null
    },
    othersAssets: [],
    othersLiabilities: [],
    isLoading: false
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setCash: (state, action) => {
      state.cash = {
        ...state.cash,
        ...action.payload
      }
    },
    setRetirement: (state, action) => {
      state.retirement = {
        ...state.retirement,
        ...action.payload
      }
    },
    setNonRetirement: (state, action) => {
      state.nonRetirement = {
        ...state.nonRetirement,
        ...action.payload
      }
    },
    setFixedAsset: (state, action) => {
      state.fixedAsset = {
        ...state.fixedAsset,
        ...action.payload
      }
    },
    setDebtCredit: (state, action) => {
      state.debtCredit = {
        ...state.debtCredit,
        ...action.payload
      }
    },
    setNecessity: (state, action) => {
      state.necessity = {
        ...state.necessity,
        ...action.payload
      }
    },
    setOthersAssets: (state, action) => {
      state.othersAssets = action.payload
    },
    setOthersLiabilities: (state, action) => {
      state.othersLiabilities = action.payload
    },
  }
})

export const fetchCash = () => async (dispatch) => {
  try {
    await dispatch(setLoading(true));
    const response = await Worth.getCash();
    await dispatch(setCash(response.data.data));
    await dispatch(setLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postCash = (data, props) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    let { checking, saving, cash_equivalent } = data
    checking        = convertDecimal(checking);
    saving          = convertDecimal(saving);
    cash_equivalent = convertDecimal(cash_equivalent);


    const response = await Worth.postCash({...data, checking, saving, cash_equivalent});
    await dispatch(setCash(data));
    await dispatch(setSending({ isSending: false }));
    await dispatch(setNewTasksCount(response.data.newTasksCount))
    await dispatch(setIsOpen({ isOpen: true }));
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchRetirement = () => async (dispatch) => {
  try {
    await dispatch(setLoading(true));
    const response = await Worth.getRetirement();
    await dispatch(setRetirement(response.data.data));
    await dispatch(setLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postRetirement = (data, props) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    let {
      account_401k, account_403b,
      pension, solo_401k_account,
      roth_401k_account,
      traditional_ira_account, roth_ira_account,
      simple_ira_account, sep_ira_account } = data;

    account_401k            = convertDecimal(account_401k);
    account_403b            = convertDecimal(account_403b);
    pension                 = convertDecimal(pension);
    solo_401k_account       = convertDecimal(solo_401k_account);
    roth_401k_account       = convertDecimal(roth_401k_account);
    traditional_ira_account = convertDecimal(traditional_ira_account);
    roth_ira_account        = convertDecimal(roth_ira_account);
    simple_ira_account      = convertDecimal(simple_ira_account);
    sep_ira_account         = convertDecimal(sep_ira_account);

    const response = await Worth.postRetirement({
      ...data,
      account_401k, account_403b,
      pension, solo_401k_account,
      roth_401k_account,
      traditional_ira_account, roth_ira_account,
      simple_ira_account, sep_ira_account
    });
    await dispatch(setRetirement(data));
    await dispatch(setSending({ isSending: false }));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setNewTasksCount(response.data.newTasksCount))
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchNonRetirement = () => async (dispatch) => {
  try {
    await dispatch(setLoading(true));
    const response = await Worth.getNonRetirement();
    await dispatch(setNonRetirement(response.data.data));
    await dispatch(setLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postNonRetirement = (data, props) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    let { brokerage_account, trust_account, annuities_account, cash_value, vested_stock, unvested_stock } = data
    brokerage_account = convertDecimal(brokerage_account);
    trust_account     = convertDecimal(trust_account);
    annuities_account = convertDecimal(annuities_account);
    cash_value        = convertDecimal(cash_value);
    vested_stock        = convertDecimal(vested_stock);
    unvested_stock        = convertDecimal(unvested_stock);

    const response = await Worth.postNonRetirement({...data, brokerage_account, trust_account, annuities_account, cash_value, vested_stock, unvested_stock});
    await dispatch(setNonRetirement(data));
    await dispatch(setSending({ isSending: false }));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setNewTasksCount(response.data.newTasksCount))
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchFixedAsset = () => async (dispatch) => {
  try {
    await dispatch(setLoading(true));
    const response = await Worth.getFixedAsset();
    await dispatch(setFixedAsset(response.data.data));
    await dispatch(setLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postFixedAsset = (data, props) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    let { home_primary, appreciable_asset, depreciable_asset } = data
    home_primary      = convertDecimal(home_primary);
    appreciable_asset = convertDecimal(appreciable_asset);
    depreciable_asset = convertDecimal(depreciable_asset);

    const response = await Worth.postFixedAsset({...data, home_primary, appreciable_asset, depreciable_asset});
    await dispatch(setFixedAsset(data));
    await dispatch(setSending({ isSending: false }));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setNewTasksCount(response.data.newTasksCount));
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchDebtCredit = () => async (dispatch) => {
  try {
    await dispatch(setLoading(true));
    const response = await Worth.getDebtCredit();
    await dispatch(setDebtCredit(response.data.data));
    await dispatch(setLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postDebtCredit = (data, props) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    let { mortgage_balance, equity_credit_balance, credit_balance, overdue_balance } = data
    mortgage_balance      = convertDecimal(mortgage_balance);
    equity_credit_balance = convertDecimal(equity_credit_balance);
    credit_balance        = convertDecimal(credit_balance);
    overdue_balance       = convertDecimal(overdue_balance);

    const response = await Worth.postDebtCredit({...data, mortgage_balance, equity_credit_balance, credit_balance, overdue_balance});
    await dispatch(setDebtCredit(data));
    await dispatch(setSending({ isSending: false }));
    await dispatch(setIsOpen({ isOpen: true }))
    await dispatch(setNewTasksCount(response.data.newTasksCount));
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const updateDebtCredit = (data, props) => async (dispatch) => {
  try {
    await Worth.postDebtCredit(data);
    await dispatch(setDebtCredit(data));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchNecessity = () => async (dispatch) => {
  try {
    await dispatch(setLoading(true));
    const response = await Worth.getNecessity();
    await dispatch(setNecessity(response.data.data));
    await dispatch(setLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postNecessity = (data, props) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    let { utilities, phone_payments, mortgage_payments, car_payments, taxes, other_loan_balance } = data
    utilities          = convertDecimal(utilities);
    phone_payments     = convertDecimal(phone_payments);
    mortgage_payments  = convertDecimal(mortgage_payments);
    car_payments       = convertDecimal(car_payments);
    taxes              = convertDecimal(taxes);
    other_loan_balance = convertDecimal(other_loan_balance);

    const response = await Worth.postNecessity({
      ...data, 
      utilities,
      phone_payments,
      mortgage_payments,
      car_payments,
      taxes,
      other_loan_balance
    });
    await dispatch(setNecessity(data));
    await dispatch(setSending({ isSending: false }));
    await dispatch(setIsOpen({ isOpen: true }))
    await dispatch(setNewTasksCount(response.data.newTasksCount));
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchWorthOthers = isAssets => async (dispatch) => {
  try {
    await dispatch(setLoading(true));

    if (isAssets) {
      const response = await Worth.getAssetsOther();
      await dispatch(setOthersAssets(response.data));
    } else {
      const response = await Worth.getLiabilitiesOther();
      await dispatch(setOthersLiabilities(response.data));
    }

    await dispatch(setLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postWorthOthers = (data, props, isAssets) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    // let { balance, assets, liabilities } = data;
    // balance = convertDecimal(balance);

    data = data.map(item => {
      return {
        ...item,
        amount: convertDecimal(item.amount)
      }
    });

    if (isAssets) {
      const response = await Worth.postAssetsOther(data);
      await dispatch(setSending({ isSending: false }));
      await dispatch(setNewTasksCount(response.data.newTasksCount));
      await dispatch(() => props.history.push('/worth/assets'));

    } else {
      await Worth.postLiabilitiesOther(data);
      await dispatch(setSending({ isSending: false }));
      await dispatch(() => props.history.push('/worth/liabilities'));
    }

  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}




export const {
  setCash,
  setDebtCredit,
  setFixedAsset,
  setNecessity,
  setRetirement,
  setNonRetirement,
  setLoading,
  setOthersAssets,
  setOthersLiabilities
} = worthSlice.actions

export default worthSlice.reducer