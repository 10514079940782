import React from 'react'
import { Form, Formik } from 'formik'
import { Button } from '@mui/material'
import { FormGroup, Input, InputCurrency } from '../../components/shared/Form'

const Umbrella = ({ title, onSave, values, coreValues, validationSchema, sending, noEdit }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{ ...values }}
      validationSchema={validationSchema}
      onSubmit={(values) => onSave(values)}
    >
      {({ errors, handleChange, handleBlur, values, touched }) => (
        <Form>
          <h4>{title}</h4>
          <div className="form__flow-wrap">
            <FormGroup name="net_worth" labelText="Net worth that warrants umbrella insurance">
              <InputCurrency
                icon
                placeholder="Type net worth"
                name="net_worth"
                onChange={handleChange}
                value={values.net_worth || ''}
                onBlur={handleBlur}
                className={coreValues?.net_worth && !noEdit && 'form__input--core'}
                hasError={!!errors.net_worth && touched.net_worth}
              />
              {touched.net_worth && errors.net_worth ? (
                <span className="form__msg-error">{errors.net_worth}</span>
              ) : coreValues && coreValues.net_worth && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.net_worth}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup
              name="child_start_age"
              labelText="Child's starting age that warrants umbrella insurance coverage"
            >
              <Input
                name="child_start_age"
                placeholder="Type Child's starting age"
                type="number"
                onChange={handleChange}
                value={values.child_start_age || ''}
                onBlur={handleBlur}
                className={coreValues?.child_start_age && !noEdit && 'form__input--core'}
                hasError={!!errors.child_start_age && touched.child_start_age}
              />
              {touched.child_start_age && errors.child_start_age ? (
                <span className="form__msg-error">{errors.child_start_age}</span>
              ) : coreValues && coreValues.child_start_age && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.child_start_age}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="miles_per_day" labelText="Commute miles per day that warrants umbrella insurance">
              <Input
                name="miles_per_day"
                placeholder="Type commute miles per day"
                type="number"
                onChange={handleChange}
                value={values.miles_per_day || ''}
                onBlur={handleBlur}
                className={coreValues?.miles_per_day && !noEdit && 'form__input--core'}
                hasError={!!errors.miles_per_day && touched.miles_per_day}
              />
              {touched.miles_per_day && errors.miles_per_day ? (
                <span className="form__msg-error">{errors.miles_per_day}</span>
              ) : coreValues && coreValues.miles_per_day && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.miles_per_day}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="child_end_age" labelText="Child's ending age that warrants umbrella insurance coverage">
              <Input
                name="child_end_age"
                placeholder="Type Child's ending age"
                type="number"
                onChange={handleChange}
                value={values.child_end_age || ''}
                onBlur={handleBlur}
                className={coreValues?.child_end_age && !noEdit && 'form__input--core'}
                hasError={!!errors.child_end_age && touched.child_end_age}
              />
              {touched.child_end_age && errors.child_end_age ? (
                <span className="form__msg-error">{errors.child_end_age}</span>
              ) : coreValues && coreValues.child_end_age && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.child_end_age}</span>
              ) : (
                ''
              )}
            </FormGroup>
          </div>
          <div className="form__button">
            <Button variant="contained" size="large" color="primary" type="submit" disabled={sending || noEdit}>
              Save
              {sending && <span className="spinner" />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default Umbrella
