import React from 'react'
import { Link } from 'react-router-dom'
import staticAuthorAvatar from '../../assets/images/static-author-avatar.jpg'
import isNull from 'lodash/isNull'

const WidgetBlog = ({ items }) => {

  return (
    <ul className="blog-list">
      {items && items.map(item => (
        <li key={`blog-${item.id}`} className="blog-list__item">
          <div className="blog-list__item-image">
            <img src={item.image} alt="images" />
          </div>
          <div className="blog-list__item-holder">
            <div className="blog-list__item-text">
              <h5><Link to={`/blog/${item.id}`}>{item.title}</Link></h5>
              <div className="blog-list__item-content">
                {item.short_description}
              </div>
            </div>
            <div className="blog-list__item-author">
              <div className="blog-list__item-avatar">
                <img src={item && !(isNull(item.author.avatar)) ? item.author.avatar : staticAuthorAvatar} alt="Author" />
              </div>
              <div className="blog-list__item-author-name">
                {item && item.author ?
                  `${item.author.first_name} ${item.author.last_name}` :
                  'No authors'
                }
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default WidgetBlog;
