import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import PersonIcon from "@mui/icons-material/Person";
import Camera from '../../../assets/images/icons/camera.svg'
import SVGIcon from '../../../components/shared/SVGIcon/SVGIcon'
// import UserIcon from '../../../assets/images/icons/user.svg'


const UploadPhoto = ({ file, handleUpload, title, className, isUploading }) => {
  const [uploadedImage, setUploadImage] = useState(null);
  const [imgError, setImgError] = useState('');
  const imageUploader = useRef(null);

  useEffect(() => {
    setUploadImage(file)
  }, [file])

  const handleImageUpload = event => {
    setImgError('');
    const file = event.target.files[0];
    if(file.size > 3100000){
      setImgError('Image size must be a maximum of 3MB.');
      setUploadImage(null);
      return
    } else {
      const formData = new FormData();
      const newImage = URL.createObjectURL(file);

      formData.append('avatar', file);

      setUploadImage(newImage);

      handleUpload(formData, newImage);
    }
  }

  return (
    <>
      <div className={`upload-photo ${className || ''} ${isUploading ? 'upload-photo--loading' : ''}`}
         onClick={() => {imageUploader.current.click()}}
    >
      {!uploadedImage && (
        <div className="upload-photo__placeholder">
          <PersonIcon />
        </div>
      )}
      <input className="upload-photo__input" type="file" accept="image/*" onChange={handleImageUpload} ref={imageUploader} />
      {uploadedImage &&
        <>
          <div className="upload-photo__image">
            <img src={uploadedImage} alt={title || ''} />
          </div>
          <span className="upload-photo__button"><SVGIcon icon={Camera} color='#fff'/></span>
        </>
      }
      {isUploading && <div className="spinner spinner--dark" />}
    </div>
    {imgError && <p className="upload-photo--error">{imgError}</p>}
    </>
  );
};

UploadPhoto.propTypes = {
  file: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  handleUpload: PropTypes.func
};


export default UploadPhoto;
