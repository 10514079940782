import React from 'react'
import PropTypes from 'prop-types'
import { Drawer } from "@mui/material";
import TaskEdit from './TaskEdit'
import TaskView from './TaskView'
import TaskMail from './TaskMail'
import TaskReminder from './TaskReminder'
import Loading from '../../components/shared/Loading/Loading'

const TaskDrawer = ({
  isOpen,
  onClose,
  children,
  id,
  status,
  priorities,
  categories,
  removeTask,
  updateTask,
  createTask,
  closeTask,
  revertTask,
  data,
  mode,
  loadingTask,
  statusTask,
  isCreateReminder,
  titleReminder,
  reminderDetails,
  updateReminder
}) => {

  return (
    <Drawer
      disableEnforceFocus
      open={isOpen}
      onClose={onClose}
      anchor="right"
      className="drawer"
    >
      <button onClick={onClose} className="drawer__close">
        Close
      </button>
      {loadingTask ? (
        <div className="drawer__holder">
          <Loading small />
        </div>
      ) : (
        <>
          {mode === "view" && (
            <TaskView
              id={id}
              removeTask={removeTask}
              closeTask={closeTask}
              revertTask={revertTask}
              data={data}
              status={statusTask}
            />
          )}

          {mode === "edit" && (
            <TaskEdit
              id={id}
              close={onClose}
              status={status}
              priorities={priorities}
              categories={categories}
              update={updateTask}
              data={data}
              mode={mode}
            />
          )}
          {mode === "create" && (
            <TaskEdit
              close={onClose}
              status={status}
              priorities={priorities}
              categories={categories}
              create={createTask}
              mode={mode}
            />
          )}
          {mode === "reminder" && (
            <TaskReminder
              id={id}
              close={onClose}
              isCreate={isCreateReminder}
              title={titleReminder}
              reminderDetails={reminderDetails}
              updateReminder={updateReminder}
            />
          )}
          {mode === "mail" && <TaskMail id={id} close={onClose} />}
        </>
      )}
    </Drawer>
  );
}

TaskDrawer.propTypes = {
  isOpen: PropTypes.bool,
  loadingTask: PropTypes.bool,
  onClose: PropTypes.func,
  removeTask: PropTypes.func,
  updateTask: PropTypes.func,
  createTask: PropTypes.func,
  closeTask: PropTypes.func,
  status: PropTypes.array,
  priorities: PropTypes.array,
  categories: PropTypes.array,
  data: PropTypes.object,
  mode: PropTypes.string,
  statusTask: PropTypes.string
}

export default TaskDrawer
