import React, { useEffect, useState } from 'react'
import LayoutAdmin from '../../components/Layout/LayoutAdmin'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams, Redirect } from 'react-router-dom'
import Loading from '../../components/shared/Loading/Loading'
import TaskCardSmall from '../../components/Task/TaskCardSmall'
import Tabs, { TabPanel } from '../../components/shared/Tabs/Tabs' 
import { Button, Pagination } from '@mui/material';
import { fetchTasks, deleteTask, postTaskActivation } from '../../redux/slices/taskSlice'
import { fetchCompany } from '../../redux/slices/companiesSlice';
import { fetchThresholds, postThresholds } from '../../redux/slices/notificationsSlice'
import { Form, Formik } from 'formik'
import { FormSelect, Input, FormGroup } from '../../components/shared/Form'
import {
  fetchPlanningTypesData
} from "../../redux/slices/optionsSlice";
import Modal from "../../components/shared/Modal/Modal";
import { setIsOpen } from "../../redux/slices/modalSlice";
import { setMode } from "../../redux/slices/modeSlice";
import SVGIcon from '../../components/shared/SVGIcon/SVGIcon'
import SearchIcon from '../../assets/images/icons/search.svg'
import { ThresholdsSchema } from '../../utils/validation'
import debounce from 'lodash/debounce'



const AdminTasks = ({ props, isAdmin }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { companyId } = useParams();
  const [page, setPage] = useState(1);
  const thresholds = useSelector(state => state.notifications.thresholds)
  const { id, permissions } = useSelector(state => state.user.user)
  const { info } = useSelector((state) => state.companies.company);
  const { adminTasks, taskLoading, pagination } = useSelector(state => state.task);
  const { planning_types, priorities } = useSelector(state => state.options);
  const sending = useSelector(state => state.sending.isSending);
  const isOpen = useSelector((state) => state.modal.isOpen);
  const mode = useSelector((state) => state.mode.mode);
  const [filters, setFilters] = useState({
    limit: 12,
    planningType: null,
    priority: null,
    active: null,
    query: ''
  });
  const baseValues = {
    superb_number_of_tasks: null,
    great_number_of_tasks: null,
    ok_to_great_number_of_tasks: null,
    ok_number_of_tasks: null,
    ok_to_poor_number_of_tasks: null,
    poor_number_of_tasks: null,
    really_poor_number_of_tasks: null
  }
  const [isMobile, setIsMobile] = useState(false);

  const mobileView = 999;

  const handleResize = () => {
    mobileView >= window.innerWidth ? setIsMobile(true) : setIsMobile(false);
  };

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []);

  const [value, setValue] = useState(0);

  const handleValue = (event, newValue) => {
    setValue(newValue);
  }

  useEffect(() => {
    dispatch(fetchThresholds(companyId))
    dispatch(fetchCompany(companyId));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchTasks(companyId, {page, ...filters}, props, isAdmin))
      await dispatch(fetchPlanningTypesData());
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id, page, filters])

  const onChangePage = (event, value) => setPage(value)

  const remove = async id => {
    await dispatch(deleteTask(companyId, id, isAdmin))
    await dispatch(fetchTasks(companyId, {page, ...filters}, props, isAdmin))
  }

  const onChangeFilters = e => {
    setPage(1)
    setFilters({
      ...filters,
      [e.target.name]: e.target.value
    })
  }

  const removeFilters = () => {
    setPage(1)
    setFilters({
      ...filters,
      planningType: null,
      priority: null,
      active: null,
      query: ''
    })
  }

  const setSearch = async e => {
    const { value } = e.target
    setFilters({
      ...filters,
      query: value
    })

    return debounce(async () => {
      fetchTasks({...filters, query: value}, props, isAdmin)
    }, 500)()
  }

  const onSubmit = (values) => {
    dispatch(postThresholds(companyId, values));
  }

  const onSwitch = async (id, checked) => {
    await dispatch(postTaskActivation(companyId, id, { active: !checked}))
    await dispatch(fetchTasks(companyId, {page, ...filters}, props, isAdmin))
  }

  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }));
    dispatch(setMode(null));
  };

  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }));
    dispatch(setMode(null));
  };

  if (permissions && !permissions.view_company_tasks) return <Redirect to="/404" />;

  return (
    <LayoutAdmin companyId={companyId} isAdmin={isAdmin}>
      <div className="member-comapny">
        <div className="member-comapny__info">
          <div className="member-comapny__logo">
            <img src={info?.image} alt="Company logo" />
          </div>
          <div className="member-comapny__title">
            <h3>{info?.name}</h3>
            {!isMobile && (
              <p>
                Feedback Email: <span>{info?.email}</span>
              </p>
            )}
          </div>
        </div>
        {isMobile && (
          <div className="mobile-feedback">
            Feedback Email: <span>{info?.email}</span>
          </div>
        )}
      </div>
      <div className="admin__title">
        <h4 className="mr-0">Tasks</h4>
        {value === 0 && (
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() =>
              history.push(`/admin/company/${companyId}/tasks/create`)
            }
          >
            Create task
          </Button>
        )}
      </div>
      <Tabs
        handleChange={handleValue}
        value={value}
        tabs={
          info?.permissions.alerts
            ? [{ title: "All tasks" }, { title: "Alerts" }]
            : [{ title: "All tasks" }]
        }
      />
      <TabPanel value={value} index={0}>
        <div className="filters">
          <div className="filters__title">Filter by:</div>
          <div className="filters__select step-tasks-filters">
            <FormSelect
              options={planning_types}
              id="planning_type"
              value={filters.planningType || ""}
              medium
              name="planningType"
              onChange={(e) => onChangeFilters(e)}
              placeholder="Planning Type"
            />
          </div>
          <div className="filters__select step-tasks-filters">
            <FormSelect
              options={priorities}
              id="priority"
              value={filters.priority || ""}
              medium
              name="priority"
              onChange={(e) => onChangeFilters(e)}
              placeholder="Priority"
            />
          </div>
          <div className="filters__select step-tasks-filters">
            <FormSelect
              options={[
                {
                  id: 1,
                  key: 1,
                  value: "1",
                  label: "Active",
                },
                {
                  id: 0,
                  key: 0,
                  value: "0",
                  label: "Inactive",
                },
              ]}
              id="active"
              value={filters.active || ""}
              medium
              name="active"
              onChange={(e) => onChangeFilters(e)}
              placeholder="Status"
            />
          </div>
          <div className="filters__select">
            <div className="form__search-box">
              <SVGIcon icon={SearchIcon} color="#999" />
              <Input
                value={filters.query || ""}
                onChange={(e) => setSearch(e)}
                placeholder="Search"
              />
            </div>
          </div>
          {(filters.planningType || filters.priority || filters.active) && (
            <div className="filters__select">
              <button
                className="filters__remove"
                onClick={() => removeFilters(null)}
              >
                Close
              </button>
            </div>
          )}
        </div>

        <div className="grid grid-3-large">
          {taskLoading ? (
            <Loading small />
          ) : adminTasks && adminTasks.length > 0 ? (
            adminTasks.map((item) => (
              <div key={`task-${item.id}`}>
                <TaskCardSmall
                  switcher={permissions?.manage_company_task}
                  companyId={companyId}
                  checked={item.active}
                  id={item.id}
                  title={item.name}
                  path={`/admin/company/${companyId}/tasks/${item.id}`}
                  removeTask={remove}
                  category={item.category.name}
                  priority={item.priority.name}
                  content={item.description}
                  icon={item.category.planning.image}
                  onSwitch={onSwitch}
                />
              </div>
            ))
          ) : (
            <div className="filters__no-results">
              <h5>No results</h5>
            </div>
          )}
        </div>
        {adminTasks && pagination.last_page > 1 && (
          <Pagination
            size={isMobile ? "small" : "medium"}
            count={pagination.last_page}
            onChange={onChangePage}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="admin__content-section">
          <div className="admin__content-holder">
            <h4>Alert Thresholds</h4>
            <Formik
              enableReinitialize
              initialValues={thresholds ? { ...thresholds } : baseValues}
              validationSchema={ThresholdsSchema}
              onSubmit={onSubmit}
            >
              {({ errors, handleChange, handleBlur, values, touched }) => (
                <Form>
                  <div className="form__flow-wrap form__flow-wrap--alerts">
                    <FormGroup
                      name="superb_number_of_tasks"
                      labelText="Superb number of tasks"
                    >
                      <Input
                        readOnly={!permissions.edit_company_alert}
                        color="green"
                        name="superb_number_of_tasks"
                        type="number"
                        onChange={handleChange}
                        value={values.superb_number_of_tasks || ""}
                        onBlur={handleBlur}
                        hasError={
                          !!errors.superb_number_of_tasks &&
                          touched.superb_number_of_tasks
                        }
                      />
                      {touched.superb_number_of_tasks &&
                        errors.superb_number_of_tasks && (
                          <span className="form__msg-error">
                            {errors.superb_number_of_tasks}
                          </span>
                        )}
                    </FormGroup>
                    <FormGroup
                      name="great_number_of_tasks"
                      labelText="Great number of tasks"
                    >
                      <Input
                        readOnly={!permissions.edit_company_alert}
                        color="light-green"
                        name="great_number_of_tasks"
                        type="number"
                        onChange={handleChange}
                        value={values.great_number_of_tasks || ""}
                        onBlur={handleBlur}
                        hasError={
                          !!errors.great_number_of_tasks &&
                          touched.great_number_of_tasks
                        }
                      />
                      {touched.great_number_of_tasks &&
                        errors.great_number_of_tasks && (
                          <span className="form__msg-error">
                            {errors.great_number_of_tasks}
                          </span>
                        )}
                    </FormGroup>
                    <FormGroup
                      name="ok_to_great_number_of_tasks"
                      labelText="Ok to great number of tasks"
                    >
                      <Input
                        readOnly={!permissions.edit_company_alert}
                        color="yellow"
                        name="ok_to_great_number_of_tasks"
                        type="number"
                        onChange={handleChange}
                        value={values.ok_to_great_number_of_tasks || ""}
                        onBlur={handleBlur}
                        hasError={
                          !!errors.ok_to_great_number_of_tasks &&
                          touched.ok_to_great_number_of_tasks
                        }
                      />
                      {touched.ok_to_great_number_of_tasks &&
                        errors.ok_to_great_number_of_tasks && (
                          <span className="form__msg-error">
                            {errors.ok_to_great_number_of_tasks}
                          </span>
                        )}
                    </FormGroup>
                    <FormGroup
                      name="ok_number_of_tasks"
                      labelText="Ok number of tasks"
                    >
                      <Input
                        readOnly={!permissions.edit_company_alert}
                        color="dark-yellow"
                        name="ok_number_of_tasks"
                        type="number"
                        onChange={handleChange}
                        value={values.ok_number_of_tasks || ""}
                        onBlur={handleBlur}
                        hasError={
                          !!errors.ok_number_of_tasks &&
                          touched.ok_number_of_tasks
                        }
                      />
                      {touched.ok_number_of_tasks &&
                        errors.ok_number_of_tasks && (
                          <span className="form__msg-error">
                            {errors.ok_number_of_tasks}
                          </span>
                        )}
                    </FormGroup>
                    <FormGroup
                      name="ok_to_poor_number_of_tasks"
                      labelText="Ok to poor number of tasks"
                    >
                      <Input
                        readOnly={!permissions.edit_company_alert}
                        color="pink"
                        name="ok_to_poor_number_of_tasks"
                        type="number"
                        onChange={handleChange}
                        value={values.ok_to_poor_number_of_tasks || ""}
                        onBlur={handleBlur}
                        hasError={
                          !!errors.ok_to_poor_number_of_tasks &&
                          touched.ok_to_poor_number_of_tasks
                        }
                      />
                      {touched.ok_to_poor_number_of_tasks &&
                        errors.ok_to_poor_number_of_tasks && (
                          <span className="form__msg-error">
                            {errors.ok_to_poor_number_of_tasks}
                          </span>
                        )}
                    </FormGroup>
                    <FormGroup
                      name="poor_number_of_tasks"
                      labelText="Poor number of tasks"
                    >
                      <Input
                        readOnly={!permissions.edit_company_alert}
                        color="red"
                        name="poor_number_of_tasks"
                        type="number"
                        onChange={handleChange}
                        value={values.poor_number_of_tasks || ""}
                        onBlur={handleBlur}
                        hasError={
                          !!errors.poor_number_of_tasks &&
                          touched.poor_number_of_tasks
                        }
                      />
                      {touched.poor_number_of_tasks &&
                        errors.poor_number_of_tasks && (
                          <span className="form__msg-error">
                            {errors.poor_number_of_tasks}
                          </span>
                        )}
                    </FormGroup>
                    <FormGroup
                      name="really_poor_number_of_tasks"
                      labelText="Really poor number of tasks"
                    >
                      <Input
                        readOnly={!permissions.edit_company_alert}
                        color="dark-red"
                        name="really_poor_number_of_tasks"
                        type="number"
                        onChange={handleChange}
                        value={values.really_poor_number_of_tasks || ""}
                        onBlur={handleBlur}
                        hasError={
                          !!errors.really_poor_number_of_tasks &&
                          touched.really_poor_number_of_tasks
                        }
                      />
                      {touched.really_poor_number_of_tasks &&
                        errors.really_poor_number_of_tasks && (
                          <span className="form__msg-error">
                            {errors.really_poor_number_of_tasks}
                          </span>
                        )}
                    </FormGroup>
                  </div>
                  <div className="form__button">
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      type="submit"
                      disabled={sending || !permissions.edit_company_alert}
                    >
                      Save
                      {sending && <span className="spinner" />}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <Modal
          open={isOpen}
          small
          button="Ok"
          onClick={handleClick}
          close={onClose}
          title={mode === "success" ? "Success!" : "Error!"}
          children={
            mode === "success" ? (
              <p>The values were updated successfully.</p>
            ) : (
              <p>Something went wrong. Try again.</p>
            )
          }
        />
      </TabPanel>
    </LayoutAdmin>
  );

}

export default AdminTasks
