import React, { useEffect, useState } from "react";
import { Element, scroller } from "react-scroll";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { fetchPushNotificationPlan, postPushNotificationPlan } from "../../redux/slices/userSlice";
import { setIsOpen as setIsOpenSliceModal } from "../../redux/slices/modalSlice";
import { setMode } from "../../redux/slices/modeSlice";
import ProfileTaskCard from "../Cards/ProfileTaskCard";
import { useQuery } from "../../utils/hooks";
import Modal from "../shared/Modal/Modal";

const TaskPlanProfile = ({ ref }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const isOpen = useSelector(state => state.modal.isOpen);
  const mode = useSelector(state => state.mode.mode);
  const [cancel, setCancel] = useState(false);
  const [isOpenModal, setIsModalOpen] = useState(false);
  const isPlanTab = query.get("tab") === "plans";
  const { push_notification_plans, push_notification_plan_id, push_notification_plan_optional } = useSelector(state => state.user.user);

  useEffect(() => {
    if (isPlanTab) {
      scroller.scrollTo("planPush", {
        duration: 1000,
        delay: 100,
        smooth: true,
      });
    }
  }, [isPlanTab]);

  useEffect(() => {
    dispatch(fetchPushNotificationPlan());

    // eslint-disable-next-line
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    formik.setValues({
      push_notification_plan_optional: push_notification_plan_optional || 0,
      push_notification_plan_id: push_notification_plan_id || 0,
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      push_notification_plan_optional: push_notification_plan_optional || 0,
      push_notification_plan_id: push_notification_plan_id || 0,
    },
    onSubmit: handleOpenModal
  });

  const handleCloseInfoModal = () => {
    dispatch(setIsOpenSliceModal({ isOpen: false }));
    dispatch(setMode(null));
  };

  const handleCancelPushNotificationPlan = () => {
    setCancel(true);
    handleOpenModal();
  }

  const handleSubmit = e => {
    e.preventDefault();
    handleOpenModal();
  }

  const handlePostPushNotificationPlan = () => {
    const data = cancel ? {
      push_notification_plan_optional: 0,
      push_notification_plan_id: 0,
    } : formik.values;
    dispatch(postPushNotificationPlan(data));
    setIsModalOpen(false);
    setCancel(false);
  };

  const { values, handleChange, setValues } = formik;

  const handleChangePlanOptional = e => {
    setValues({ ...values, push_notification_plan_id: +e.target.value > 0 ? 0 : push_notification_plan_id });
    handleChange(e);
  }

  return (
    <Element name="planPush">
      <form onSubmit={handleSubmit}>
        <div className="splash__content profile__task-plan">
          <h5 className="profile__subtitle">Task plan (optional)</h5>
          <p className="text-muted mb--small">
            ValueSoft can email you weekly task reminders. <br />
            This reduces the hassle of you having to log in to check your
            progress.
          </p>
          <Grid container>
            <Grid item xs={12} md={5}>
              <RadioGroup
                value={`${values.push_notification_plan_optional}`}
                name="push_notification_plan_optional"
                onChange={handleChangePlanOptional}
              >
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label="Do not email me reminders. I will just use the task action items portal in ValueSoft."
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="Send me all overdue tasks and new ones for the week."
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="Do not send me new tasks until I complete my overdue tasks."
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </div>
        {+formik.values.push_notification_plan_optional > 0 && (
          <div className="profile__schedule-list">
            <Grid container spacing={3}>
              {push_notification_plans &&
                push_notification_plans.map((plan) => (
                  <Grid key={plan.id} item xs={12} md={4}>
                    <ProfileTaskCard
                      accent={plan.id === 2}
                      selected={values.push_notification_plan_id === plan.id}
                      name={plan.name}
                      canCancel={
                        +push_notification_plan_optional ===
                          +values.push_notification_plan_optional &&
                        +push_notification_plan_id ===
                          +values.push_notification_plan_id
                      }
                      nameDescription={plan.name_description}
                      title={plan.title}
                      titleDescription={plan.title_description}
                      number_of_tasks={plan.number_tasks_of_week}
                      number_of_weeks={plan.number_of_week}
                      onCancel={handleCancelPushNotificationPlan}
                      onChange={() =>
                        setValues({
                          ...values,
                          push_notification_plan_id: +plan.id,
                        })
                      }
                    />
                  </Grid>
                ))}
            </Grid>
          </div>
        )}
        <div className="text-center">
          <Button
            type="submit"
            size="large"
            color="primary"
            variant="contained"
            className="profile__submit"
            disabled={
              (values.push_notification_plan_optional > 0 &&
                !values.push_notification_plan_id) ||
              (+push_notification_plan_optional ===
                +values.push_notification_plan_optional &&
                +push_notification_plan_id ===
                  +values.push_notification_plan_id)
            }
          >
            Save changes
          </Button>
        </div>
      </form>
      <Modal
        open={isOpen}
        small
        button="Ok"
        onClick={handleCloseInfoModal}
        close={handleCloseInfoModal}
        title={mode === "success" ? "Success!" : "Error!"}
        children={
          mode === "success" ? (
            <p className="modal__content--text-grey-sm">
              The task plan was updated successfully.
              <br />
              Please don’t forget to check your spam folders for the email
              notifications.
            </p>
          ) : (
            <p>Something went wrong. Try again.</p>
          )
        }
      />
      <Modal
        open={isOpenModal}
        small
        button={cancel ? "Yes" : "Change"}
        closeButton={cancel ? "No" : "Cancel"}
        onClick={handlePostPushNotificationPlan}
        close={handleCloseModal}
        title={`Are you sure you want to ${
          cancel ? "cancel" : "change"
        } your plan?`}
      />
    </Element>
  );
};

export default TaskPlanProfile;