import React from "react";
import LayoutAdmin from "../../components/Layout/LayoutAdmin";
import { useParams, Redirect } from 'react-router-dom'
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Button, Switch, FormControlLabel } from "@mui/material";
import { postCompanyPermissions } from '../../redux/slices/companiesSlice';

const AdminCompanyAddPermissions = ({ props, isAdmin }) => {
  const dispatch = useDispatch();
  const { permissions } = useSelector(state => state.user.user)
  const userId = useSelector(state => state.user.user.id)
  const sending = useSelector((state) => state.sending.isSending);
  const { companyId }  = useParams();

  if (!!userId && !permissions?.ap_add_company) return (<Redirect to="/admin" />)

  return (
    <LayoutAdmin isAdmin={isAdmin}>
      <div className="admin__title">
        <h3>Add new company</h3>
      </div>
      <div className="company-step">
        <div className="company-step__block">
          <div className="company-step__number">1</div>
          <p className="company-step__title">Company info</p>
        </div>
        <div className="company-step__block">
          <div className="company-step__number step-active">2</div>
          <p className="company-step__title">Company Permissions</p>
        </div>
        <div className="company-step__block">
          <div className="company-step__number">3</div>
          <p className="company-step__title">Company SuperAdmin Info</p>
        </div>
      </div>
      <div className="company">
        <Formik
          enableReinitialize
          initialValues={{
            blog: true,
            calculators: true,
            alerts: true,
            assumptions: true
          }}
          onSubmit={(values) => {dispatch(postCompanyPermissions(companyId, values, props))}}
        >
          {({ handleChange, values, setValues }) => (
            <Form>
              <div className="switcher__wrap">
                <div className="switcher__wrap--top">
                  <h3>Permissions</h3>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => {
                      setValues({
                          'blog': true,
                          'calculators': true,
                          'alerts': true,
                          'assumptions': true
                      })
                    }}
                  >
                    Activate all
                  </Button>
                </div>
              <div className="switcher__wrap--content">
                
                  <FormControlLabel
                    control={<Switch 
                      className="custom-switcher" 
                      checked={values.blog} 
                      value={values.blog}
                      onChange={handleChange} 
                      name="blog" />}
                    label="Blog"
                    labelPlacement="start"
                  />
               
                  <FormControlLabel
                    control={<Switch 
                      className="custom-switcher" 
                      checked={values.calculators}
                      value={values.calculators} 
                      onChange={handleChange} 
                      name="calculators" />}
                    label="Calculators"
                    labelPlacement="start"
                  />
                
                  <FormControlLabel
                    control={<Switch 
                      className="custom-switcher" 
                      checked={values.alerts}
                      value={values.alerts} 
                      onChange={handleChange} 
                      name="alerts" />}
                    label="Alerts"
                    labelPlacement="start"
                  />
                
                  <FormControlLabel
                    control={<Switch 
                      className="custom-switcher" 
                      checked={values.assumptions}
                      value={values.assumptions} 
                      onChange={handleChange} 
                      name="assumptions" />}
                    label="Assumptions"
                    labelPlacement="start"
                  />
                
              </div>
              </div>
              <div className="form__button">
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  type="submit"
                  disabled={sending}
                >
                  Continue
                  {sending && <span className="spinner" />}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </LayoutAdmin>
  );
};

export default AdminCompanyAddPermissions;
