import React, {useEffect} from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import Layout from '../../../components/Layout/Layout'
import LayoutForm from '../../../components/Layout/LayoutForm'
import { BackButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import {Input, FormGroup, InputCurrency} from '../../../components/shared/Form'
import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { fetchUserSpouseData, postUserSpouseData, setError } from '../../../redux/slices/userSlice'
import {getPercent, normalizeBoolean} from '../../../utils/utils'
import { DetailsUserSpouseSchema } from '../../../utils/validation'
import Priority from '../../../components/shared/Priority/Priority'
import Illustration from '../../../assets/images/illustrations/Tree-segment_aboutMe-min.png'
import { fetchPercentages } from '../../../redux/slices/percentagesSlice'
import Modal from '../../../components/shared/Modal/Modal'
import ProgressCircle from '../../../components/shared/ProgressBar/ProgressCircle'
import { setIsOpen } from '../../../redux/slices/modalSlice'

const AboutYourSpouse = ({ props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.user.user);
  const details = useSelector(state => state.user.spouse.details);
  const error = useSelector(state => state.user.error);
  const sending = useSelector(state => state.sending.isSending);
  const percents = useSelector(state => state.percentages.percents);
  const isOpen = useSelector(state => state.modal.isOpen);
  const percent = getPercent(percents, 'about-your-spouse') || 0;

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchUserSpouseData());
      await dispatch(fetchPercentages());
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const onSubmit = (values, setErrors) => {
    dispatch(postUserSpouseData(values, props, setErrors))
  }

  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }))
  }

  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }));
    history.push('/profile-summary')
  }

  const handleFocus = () => {
    if(error && error.retirement_age) {
      dispatch(setError(null))
    }
  }

  return (
    <Layout>
      <LayoutForm illustration={Illustration} small>
        <BackButton title="Back to Profile Summary" path="/profile-summary" />
        <PageTitle
          title="About your spouse"
          content="You spouse’s personal information"
          status={(percents && percent) && percent}
        />
        <div className="layout-form__content-holder">
          <Formik
            enableReinitialize
            initialValues={{...details}}
            validationSchema={DetailsUserSpouseSchema}
            onSubmit={(values, { setErrors }) => onSubmit(values, setErrors)}
          >
            {({ errors, handleChange, handleBlur, values, touched, setFieldValue }) => (
              <Form>
                <FormGroup name="first_name" labelText="Spouse first name">
                  <Input
                    name="first_name"
                    placeholder="Type first name"
                    type="text"
                    onChange={handleChange}
                    value={values.first_name || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.first_name && touched.first_name}
                  />
                  {touched.first_name && errors.first_name && <span className="form__msg-error">{errors.first_name}</span>}
                </FormGroup>
                <FormGroup name="last_name" labelText="Spouse last name">
                  <Input
                    name="last_name"
                    placeholder="Type last name"
                    type="text"
                    onChange={handleChange}
                    value={values.last_name || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.last_name && touched.last_name}
                  />
                  {touched.last_name && errors.last_name && <span className="form__msg-error">{errors.last_name}</span>}
                </FormGroup>
                <FormGroup name="year_of_birth" labelText="Spouse year of birth">
                  <Input
                    name="year_of_birth"
                    small
                    placeholder="YYYY"
                    type="number"
                    onChange={handleChange}
                    value={values.year_of_birth || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.year_of_birth && touched.year_of_birth}
                  />
                  {touched.year_of_birth && errors.year_of_birth && <span className="form__msg-error">{errors.year_of_birth}</span>}
                </FormGroup>
                <FormGroup name="gross_salary" labelText="Spouse salary (optional)">
                  <InputCurrency
                    placeholder="$0.00"
                    type="text"
                    name="gross_salary"
                    onChange={handleChange}
                    value={values.gross_salary || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.gross_salary && touched.gross_salary}
                  />
                  {touched.gross_salary && errors.gross_salary && <span className="form__msg-error">{errors.gross_salary}</span>}
                </FormGroup>
                <FormGroup name="future_salary"
                           labelText="Will your spouse’s salary level off in the future and if so, what will it be (optional)">
                  <InputCurrency
                    placeholder="$0.00"
                    type="text"
                    name="future_salary"
                    onChange={handleChange}
                    value={values.future_salary || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.future_salary && touched.future_salary}
                    info={{
                      content:
                        <>
                          <h6>Spouse Salary Details</h6>
                          <Priority option="Medium" />
                          <div>It's realistic to assume your spouse's salary will level off at a certain point in your career.  Please estimate what your spouse's maximum salary will likely be at the peak of her/his career.</div>
                        </>
                    }}
                  />
                  {touched.future_salary && errors.future_salary && <span className="form__msg-error">{errors.future_salary}</span>}
                </FormGroup>
                <FormGroup name="retirement_age"
                           labelText="Spouse anticipated retirement age (optional)"
                           info={{
                             content:
                               <>
                                 <h6>Spouse Retirement Age</h6>
                                 <Priority option="High" />
                                 <div>This is specifically referring to retirement from professional work.  If your spouse is not working and does not plan to work, simply put your spouse's current age.</div>
                               </>
                           }}>
                  <Input
                    placeholder="Enter anticipated retired age"
                    type="number"
                    name="retirement_age"
                    onFocus={handleFocus}
                    onChange={handleChange}
                    value={values.retirement_age || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.retirement_age && touched.retirement_age}
                  />
                  {touched.retirement_age && errors.retirement_age && <span className="form__msg-error">{errors.retirement_age}</span>}
                  {error && error.retirement_age && <span className="form__msg-error">{!errors.retirement_age && error.retirement_age[0]}</span>}
                </FormGroup>
                <FormGroup name="is_first_marriage" labelText="Is this your first marriage? (optional)">
                  <RadioGroup
                    name="is_first_marriage"
                    value={values.is_first_marriage || false}
                    onChange={(e) => {
                      setFieldValue('is_first_marriage', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                </FormGroup>
                <FormGroup
                  name="has_life_insurance"
                  labelText="Does your spouse own life insurance?"
                  info={{
                    content:
                      <>
                        <h6>Life Insurance</h6>
                        <Priority option="High" />
                        <div>This can either be a policy you've taken out in your spouse's name or her/his own policy.</div>
                      </>
                  }}
                >
                  <RadioGroup
                    name="has_life_insurance"
                    value={values.has_life_insurance || false}
                    onChange={(e) => {
                      setFieldValue('has_life_insurance', normalizeBoolean(e.target.value))
                      setFieldValue('life_insurance_amount', '')
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                </FormGroup>
                <FormGroup name="life_insurance_amount" labelText="Spouse's life insurance policy amount">
                  <InputCurrency
                    placeholder="$0.00"
                    type="text"
                    name="life_insurance_amount"
                    onChange={handleChange}
                    value={values.has_life_insurance ? values.life_insurance_amount : ''}
                    onBlur={handleBlur}
                    disabled={!values.has_life_insurance}
                    info={{
                      content:
                        <>
                          <h6>Spouse Life Insurance</h6>
                          <Priority option="High" />
                          <div>Current Face Value is the amount your policy covers your spouse for.</div>
                          <hr className="tooltip__line"/>
                          <h6>Example</h6>
                          <div>Say you have a $100,000 policy for your spouse, such that if he/she died, the insurance company would pay out $100,000 to his/her beneficiaries.  In that case, enter $100,000 in this field.</div>
                        </>
                    }}
                    hasError={!!errors.life_insurance_amount && touched.life_insurance_amount && values.has_life_insurance}
                  />
                  {touched.life_insurance_amount && errors.life_insurance_amount && values.has_life_insurance && <span className="form__msg-error">{errors.life_insurance_amount}</span>}
                </FormGroup>
                <FormGroup name="is_smoke" labelText="Does your spouse smoke?">
                  <RadioGroup
                    name="is_smoke"
                    value={values.is_smoke || false}
                    onChange={(e) => {
                      setFieldValue('is_smoke', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                </FormGroup>
                <FormGroup name="spouse_credit_score" labelText="Spouse's credit score">
                  <Input
                    placeholder=""
                    type="number"
                    name="spouse_credit_score"
                    onChange={handleChange}
                    value={values.spouse_credit_score || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.spouse_credit_score && touched.spouse_credit_score}
                  />
                  {touched.spouse_credit_score && errors.spouse_credit_score && <span className="form__msg-error">{errors.spouse_credit_score}</span>}
                </FormGroup>
                <div className="form__button">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Save
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </LayoutForm>


      <Modal
        open={isOpen}
        small
        button="Done"
        onClick={handleClick}
        close={onClose}
      >
        <h3>Good Job!</h3>
        <p>Your progress increased 100%</p>
        <ProgressCircle amount={100} small />
      </Modal>
    </Layout>
  );
};

export default AboutYourSpouse;
