import { createSlice } from '@reduxjs/toolkit'
import { Insurance } from '../../api/Api'
import { setSending } from './sendingSlice'
import { convertDecimal } from '../../utils/utils'
import { setNewTasksCount } from './taskSlice'
import { setIsOpen } from './modalSlice'
import { setIsRelogin } from "./loadingSlice";

const insuranceSlice = createSlice({
  name: "insurance",
  initialState: {
    home: {
      disaster_area: false,
      disaster_insurance: false,
      home_insurance: false,
    },
    health: {
      eye_insurance: false,
      dental_insurance: false,
      health_insurance: false,
    },
    auto: {
      insurance_for_each_car: false,
    },
    umbrella: {
      umbrella_insurance: false,
    },
    life: {
      number_of_years_cover: "",
      percent_expenses_you: "",
    },
    disability: {
      percent_take_home: "",
      current_ltd: "",
      waiting_period: "",
      years_cover_salary: "",
      spouse_current_ltd: "",
      residual_benefits: false,
      cost_of_living: false,
      own_occupation: false,
      guaranteed_renewable: false,
      non_cancellable: false,
      premium_forgiveness: false,
      childcare_support: false,
    },
    isInsuranceLoading: false,
  },
  reducers: {
    setLoading: (state, action) => {
      state.isInsuranceLoading = action.payload;
    },
    setHomeInsurance: (state, action) => {
      state.home = {
        ...state.home,
        ...action.payload,
      };
    },
    setHealthInsurance: (state, action) => {
      state.health = {
        ...state.health,
        ...action.payload,
      };
    },
    setAutoInsurance: (state, action) => {
      state.auto = {
        ...state.auto,
        ...action.payload,
      };
    },
    setUmbrellaInsurance: (state, action) => {
      state.umbrella = {
        ...state.umbrella,
        ...action.payload,
      };
    },
    setLifeInsurance: (state, action) => {
      state.life = {
        ...state.life,
        ...action.payload,
      };
    },
    setDisabilityInsurance: (state, action) => {
      state.disability = {
        ...state.disability,
        ...action.payload,
      };
    },
  },
});

export const fetchInsuranceHome = () => async (dispatch) => {
  try {
    const response = await Insurance.getHome();
    await dispatch(setHomeInsurance(response.data.data));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postInsuranceHome = (data, props) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    let { dwelling_coverage,
      identity_fraud,
      jewelry,
      loss_of_use,
      personal_property,
      personalliability_home,
      replacement_value,
      separate_structure } = data;

    dwelling_coverage      = dwelling_coverage ? convertDecimal(dwelling_coverage) : ''
    identity_fraud         = identity_fraud ? convertDecimal(identity_fraud) : ''
    jewelry                = jewelry ? convertDecimal(jewelry) : ''
    loss_of_use            = loss_of_use ? convertDecimal(loss_of_use): ''
    personal_property      = personal_property ? convertDecimal(personal_property) : ''
    personalliability_home = personalliability_home ? convertDecimal(personalliability_home) : ''
    replacement_value      = replacement_value ? convertDecimal(replacement_value) : ''
    separate_structure     = separate_structure ? convertDecimal(separate_structure) : ''

    const response = await Insurance.postHome({
      ...data,
      dwelling_coverage,
      identity_fraud,
      jewelry,
      loss_of_use,
      personal_property,
      personalliability_home,
      replacement_value,
      separate_structure
    });
    await dispatch(fetchInsuranceHome());
    await dispatch(setSending({ isSending: false }));
    await dispatch(setIsOpen({ isOpen: true }))
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchInsuranceHealth = () => async (dispatch) => {
  try {
    const response = await Insurance.getHealth();
    await dispatch(setHealthInsurance(response.data.data));
  } catch (e) {
    console.error(e);
  }
}

export const postInsuranceHealth = (data, props) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    const response = await Insurance.postHealth(data);
    await dispatch(fetchInsuranceHealth());
    await dispatch(setSending({ isSending: false }));
    await dispatch(setIsOpen({ isOpen: true }))
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchInsuranceAuto = () => async (dispatch) => {
  try {
    await dispatch(setLoading(true));
    const response = await Insurance.getAuto();
    await dispatch(setAutoInsurance(response.data.data));
    await dispatch(setLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postInsuranceAuto= (data, props) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    let {
      personal_liability_auto_per_person,
      personal_liability_auto_per_incident,
      property_damage,
      medical_payments,
      uninsured_motorist_per_person,
      uninsured_motorist_per_incident,
      comprehensive,
      towing,
      rental
    } = data

    personal_liability_auto_per_person   = personal_liability_auto_per_person ? convertDecimal(personal_liability_auto_per_person) : ''
    personal_liability_auto_per_incident = personal_liability_auto_per_incident ? convertDecimal(personal_liability_auto_per_incident) : ''
    property_damage                      = property_damage ? convertDecimal(property_damage) : ''
    medical_payments                     = medical_payments ? convertDecimal(medical_payments) : ''
    uninsured_motorist_per_person        = uninsured_motorist_per_person ? convertDecimal(uninsured_motorist_per_person) : ''
    uninsured_motorist_per_incident      = uninsured_motorist_per_incident ? convertDecimal(uninsured_motorist_per_incident) : ''
    comprehensive                        = comprehensive ? convertDecimal(comprehensive) : ''
    towing                               = towing ? convertDecimal(towing) : ''
    rental                               = rental ? convertDecimal(rental) : ''

    const response = await Insurance.postAuto({
      ...data,
      personal_liability_auto_per_person,
      personal_liability_auto_per_incident,
      property_damage,
      medical_payments,
      uninsured_motorist_per_person,
      uninsured_motorist_per_incident,
      comprehensive,
      towing,
      rental
    });
    await dispatch(fetchInsuranceAuto());
    await dispatch(setSending({ isSending: false }));
    await dispatch(setIsOpen({ isOpen: true }))
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchInsuranceUmbrella = () => async (dispatch) => {
  try {
    await dispatch(setLoading(true));
    const response = await Insurance.getUmbrella();
    await dispatch(setUmbrellaInsurance(response.data.data));
    await dispatch(setLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postInsuranceUmbrella = (data, props) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    let {current_face_value} = data;
    current_face_value = current_face_value ? convertDecimal(current_face_value) : ''

    const response = await Insurance.postUmbrella({...data, current_face_value});
    await dispatch(fetchInsuranceUmbrella());
    await dispatch(setSending({ isSending: false }));
    await dispatch(setIsOpen({ isOpen: true }))
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchInsuranceLife = () => async (dispatch) => {
  try {
    await dispatch(setLoading(true));
    const response = await Insurance.getLife();
    await dispatch(setLifeInsurance(response.data.data));
    await dispatch(setLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postInsuranceLife = (data, props) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    let { percent_expenses_you } = data
    percent_expenses_you = percent_expenses_you ? percent_expenses_you.slice(0, -1) : ''

    const response = await Insurance.postLife({...data, percent_expenses_you});
    await dispatch(fetchInsuranceLife());
    await dispatch(setSending({ isSending: false }));
    await dispatch(setIsOpen({ isOpen: true }))
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchInsuranceDisability = () => async (dispatch) => {
  try {
    await dispatch(setLoading(true));
    const response = await Insurance.getDisability();
    await dispatch(setDisabilityInsurance(response.data.data));
    await dispatch(setLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postInsuranceDisability = (data, props) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    let { percent_take_home, spouse_percent_take_home } = data
    percent_take_home        = percent_take_home ? percent_take_home.slice(0,-1) : ''
    spouse_percent_take_home = spouse_percent_take_home ? spouse_percent_take_home.slice(0,-1) : ''
    const response = await Insurance.postDisability({...data, percent_take_home, spouse_percent_take_home});
    await dispatch(fetchInsuranceDisability());
    await dispatch(setSending({ isSending: false }));
    await dispatch(setIsOpen({ isOpen: true }));
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}


export const {
  setHomeInsurance,
  setHealthInsurance,
  setAutoInsurance,
  setUmbrellaInsurance,
  setLifeInsurance,
  setDisabilityInsurance,
  setLoading
} = insuranceSlice.actions

export default insuranceSlice.reducer