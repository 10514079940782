import React, { useEffect } from "react";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { fetchPushNotificationSchedule, postPushNotificationSchedule } from "../../redux/slices/userSlice";
import optionsDays from "../../Data/optionsDays";
import optionsScheduleTimezones from "../../Data/optionsScheduleTimezones";
import optionsHoers from "../../Data/optionsHous";
import { FormGroup, FormSelect } from "../shared/Form";
import Loading from "../shared/Loading/Loading";

const EditPushNotificationSchedule = ({
  onSubmit,
  isChecked,
  check,
  companyId,
}) => {
  const dispatch = useDispatch();
  const { pushNotificationSchedule } = useSelector((state) => state.user);
  const { isSending } = useSelector((state) => state.sending);

  useEffect(() => {
    dispatch(fetchPushNotificationSchedule(companyId));

    // eslint-disable-next-line
  }, []);

  const handleSubmit = (data) => {
    dispatch(postPushNotificationSchedule(companyId, data));
    onSubmit();
  };

  const renderDayValue = (value) =>
    `Every ${optionsDays.find((i) => i.value === value).label}`;

  return Object.keys(pushNotificationSchedule).length ? (
    <Formik
      enableReinitialize
      initialValues={pushNotificationSchedule}
      onSubmit={handleSubmit}
    >
      {({ handleChange, values }) => (
        <Form>
          <FormGroup name="day" labelText="Select a day for mailing">
            <FormSelect
              name="day_of_week"
              options={optionsDays}
              value={values.day_of_week}
              onChange={handleChange}
              renderValue={renderDayValue}
            />
          </FormGroup>
          <FormGroup name="timezone" labelText="Time zone">
            <FormSelect
              name="timezone"
              options={optionsScheduleTimezones}
              value={values.timezone}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup name="hour" labelText="Time to send">
            <FormSelect
              name="hour"
              options={optionsHoers}
              value={values.hour}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup name="schedule">
            <FormControlLabel
              className="MuiFormControlLabel-root--small"
              control={
                <Checkbox
                  checked={values.schedule || false}
                  value={values.schedule}
                  onChange={handleChange}
                  name="schedule"
                />
              }
              label={<>Disable task push notification.</>}
            />
          </FormGroup>
          <Button
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            disabled={isSending}
          >
            Save changes
          </Button>
        </Form>
      )}
    </Formik>
  ) : (
    <Loading small />
  );
};

export default EditPushNotificationSchedule;