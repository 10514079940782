import React from 'react'
import { Form, Formik } from 'formik'
import { Button } from "@mui/material";
import { FormGroup, Input, InputCurrency } from '../../components/shared/Form'
import Percentage from '../../assets/images/icons/percentage.svg'

const Property = ({ title, onSave, values, coreValues, validationSchema, sending, noEdit }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{ ...values }}
      validationSchema={validationSchema}
      onSubmit={(values) => onSave(values)}
    >
      {({ errors, handleChange, handleBlur, values, touched }) => (
        <Form>
          <h4>{title}</h4>
          <div className="form__flow-wrap">
            <FormGroup name="dwelling_coverage_replacement" labelText="Dwelling coverage replacement">
              <Input
                name="dwelling_coverage_replacement"
                placeholder="Type dwelling coverage"
                type="number"
                onChange={handleChange}
                value={values.dwelling_coverage_replacement || ''}
                onBlur={handleBlur}
                iconName={Percentage}
                className={coreValues?.dwelling_coverage_replacement && !noEdit && 'form__input--core'}
                hasError={!!errors.dwelling_coverage_replacement && touched.dwelling_coverage_replacement}
              />
              {touched.dwelling_coverage_replacement && errors.dwelling_coverage_replacement ? (
                <span className="form__msg-error">{errors.dwelling_coverage_replacement}</span>
              ) : coreValues && coreValues.dwelling_coverage_replacement && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.dwelling_coverage_replacement}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="personal_property_replacement" labelText="Personal property replacement">
              <Input
                name="personal_property_replacement"
                placeholder="Type personal property"
                type="number"
                onChange={handleChange}
                value={values.personal_property_replacement || ''}
                onBlur={handleBlur}
                iconName={Percentage}
                className={coreValues?.personal_property_replacement && !noEdit && 'form__input--core'}
                hasError={!!errors.personal_property_replacement && touched.personal_property_replacement}
              />
              {touched.personal_property_replacement && errors.personal_property_replacement ? (
                <span className="form__msg-error">{errors.personal_property_replacement}</span>
              ) : coreValues && coreValues.personal_property_replacement && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.personal_property_replacement}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="separate_structures_replacement" labelText="Separate structures replacement">
              <Input
                name="separate_structures_replacement"
                placeholder="Type separate structures"
                type="number"
                onChange={handleChange}
                value={values.separate_structures_replacement || ''}
                onBlur={handleBlur}
                iconName={Percentage}
                className={coreValues?.separate_structures_replacement && !noEdit && 'form__input--core'}
                hasError={!!errors.separate_structures_replacement && touched.separate_structures_replacement}
              />
              {touched.separate_structures_replacement && errors.separate_structures_replacement ? (
                <span className="form__msg-error">{errors.separate_structures_replacement}</span>
              ) : coreValues && coreValues.separate_structures_replacement && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.separate_structures_replacement}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="loss_of_use_replacement" labelText="Loss of use replacement">
              <Input
                name="loss_of_use_replacement"
                placeholder="Type loss of use replacement"
                type="number"
                onChange={handleChange}
                value={values.loss_of_use_replacement || ''}
                onBlur={handleBlur}
                iconName={Percentage}
                className={coreValues?.loss_of_use_replacement && !noEdit && 'form__input--core'}
                hasError={!!errors.loss_of_use_replacement && touched.loss_of_use_replacement}
              />
              {touched.loss_of_use_replacement && errors.loss_of_use_replacement ? (
                <span className="form__msg-error">{errors.loss_of_use_replacement}</span>
              ) : coreValues && coreValues.loss_of_use_replacement && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.loss_of_use_replacement}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="personal_liability_coverage" labelText="Personal liability coverage">
              <InputCurrency
                icon
                placeholder="Type personal liability"
                name="personal_liability_coverage"
                onChange={handleChange}
                value={values.personal_liability_coverage || ''}
                onBlur={handleBlur}
                className={coreValues?.personal_liability_coverage && !noEdit && 'form__input--core'}
                hasError={!!errors.personal_liability_coverage && touched.personal_liability_coverage}
              />
              {touched.personal_liability_coverage && errors.personal_liability_coverage ? (
                <span className="form__msg-error">{errors.personal_liability_coverage}</span>
              ) : coreValues && coreValues.personal_liability_coverage && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.personal_liability_coverage}</span>
              ) : (
                ''
              )}
            </FormGroup>
          </div>
          <div className="form__button">
            <Button variant="contained" size="large" color="primary" type="submit" disabled={sending || noEdit}>
              Save
              {sending && <span className="spinner" />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default Property
