import React from 'react';
import { useDispatch } from "react-redux";
import SVGIcon from '../shared/SVGIcon/SVGIcon';
import SearchIcon from '../../assets/images/icons/search.svg';
import Man from '../../assets/images/icons/user02.svg';
import { assignPlanner, setSortPlanner, fetchMembersList } from '../../redux/slices/companiesSlice';

const SearchUserBox = ({ filter, companyId, memberId, memberStatus, setSearch, value, result, open, resSearch }) => {
  const dispatch = useDispatch();

  const onSelect = async (plannerId) => {
    await dispatch(assignPlanner(companyId, { 
      planner_id: plannerId,
      member_id: memberId,
      member_confirmed: memberStatus
    }))
    await dispatch(fetchMembersList(companyId))
  };

  const onFilter = (plannerId, plannerName) => {
    dispatch(setSortPlanner({
      id: plannerId,
      name: plannerName
    }))
  }
  
  return (
    <div style={{top: filter ? 44 : 0}} ref={resSearch} className={`search search-user ${open ? ' search-user--open' : ''}`}>
      <div className="search search-user__box">
        <input
          type="text"
          placeholder="Search"
          value={value}
          className="search search-user__input"
          onChange={setSearch}
        />
        {!value && <SVGIcon icon={SearchIcon} color="#8c8c8c" />}
        <ul className="search search-user__result">
          {result && result.length === 0 ? (
            <li className="search search-user__result-item">No result</li>
          ) : (
            result &&
            result.map((item, i) => (
              <li
                key={i}
                style={{ animationDelay: 0.3 + i * 0.1 + 's' }}
                className="search search-user__result-item"
              >
                <button className="search" onClick={() => filter ? onFilter(item.id, `${item.first_name} ${item.last_name}`) : onSelect(item.id)}>
                  <div className="search result-item__logo">
                    {item?.avatar ? (
                      <img className="search" src={item.avatar} alt={item.name} />
                    ) : (
                      <SVGIcon icon={Man} color="#fff" />
                    )}
                  </div>
                  {item.first_name} {item.last_name}
                </button>
              </li>
            ))
          )}
        </ul>
      </div>
    </div>
  );
};

export default SearchUserBox;
