import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'classnames'
import NumberFormat from 'react-number-format'
import TooltipCustom from '../Tooltip/Tooltip'
import SVGIcon from '../SVGIcon/SVGIcon'
import Dollar from '../../../assets/images/icons/dollar.svg'

const InputCurrency = ({
 value,
 name,
 disabled,
 type,
 onChange,
 placeholder,
 hasError,
 onBlur,
 info,
 small,
 smallHeight,
 icon,
 iconLeft,
 withoutSign,
 noDecimal
}) => {
  const classNames = clsx('form__input', {
    'form__input--error' : hasError,
    'form__input--alt' : info || icon,
    'form__input--lpad' : iconLeft,
    'form__input--small' : smallHeight
  })

  return (
    <div className={`form__area ${small ? 'form__area--small' : ''}`}>
      {icon && <SVGIcon icon={Dollar} className="form__icon" color="#8C8C8C" />}
      {iconLeft && <SVGIcon icon={Dollar} className="form__icon--dollar" color="#8C8C8C" />}

      <NumberFormat
        prefix={withoutSign ? '' : '$'}
        decimalScale={!noDecimal && 2}
        thousandSeparator
        id={name}
        name={name}
        placeholder={placeholder}
        className={classNames}
        onChange={onChange}
        type={type || 'text'}
        value={value}
        disabled={disabled}
        onBlur={onBlur}
        inputMode='decimal'
        decimalSeparator='.'
        fixedDecimalScale
        allowedDecimalSeparators={[',', '.']}
      />
      {info &&
        <TooltipCustom
          title={info.content}
          className="form__input-icon"
          size="small"
        />
      }
    </div>
  );
};

InputCurrency.propTypes = {
  onChange: PropTypes.func,
  value:  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  type: PropTypes.string,
  name: PropTypes.string,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  smallHeight: PropTypes.bool,
  icon: PropTypes.bool,
  iconLeft: PropTypes.bool,
  noDecimal: PropTypes.bool,
  withoutSign: PropTypes.bool,
};

export default InputCurrency;
