import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import { BackButton, DeleteButton } from '../../../components/shared/Buttons'
import Layout from '../../../components/Layout/Layout'
import LayoutForm from '../../../components/Layout/LayoutForm'
import PageTitle from '../../../components/PageTitle/PageTitle'
import { Button, FormControlLabel, Radio, RadioGroup, TextareaAutosize } from '@mui/material'
import {FormGroup, Input, InputCurrency} from '../../../components/shared/Form'
import ModalBox from '../../../components/shared/Modal/Modal'
import { BequeathSchema } from '../../../utils/validationProtection'
import { fetchBequeathById, postBequeath ,removeBequeath } from '../../../redux/slices/protectionSlice'
import { setIsOpen } from '../../../redux/slices/modalSlice'
import Illustration from '../../../assets/images/illustrations/Tree-segment_protection-min.png'


const BequeathDetails = ({ props, isEdit }) => {
  const dispatch = useDispatch();
  const userId = useSelector(state => state.user.user.id);
  const { id }  = useParams();
  const bequeath = useSelector(state => state.protection.bequeath);
  const isOpen = useSelector(state => state.modal.isOpen);
  const sending = useSelector(state => state.sending.isSending);

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchBequeathById(id))
    }

    if (userId && id) {
      fetch()
    }

    // eslint-disable-next-line
  }, [userId])

  const deleteBequeath = () => {
    dispatch(removeBequeath(id, props));
    dispatch(setIsOpen({isOpen: false}))
  }

  const onSubmit = values => {
    dispatch(postBequeath(values, props, id, isEdit))
  }

  return (
    <Layout>
      <LayoutForm illustration={Illustration} small>
        {isEdit && <DeleteButton className="delete-button--position" onClick={() => dispatch(setIsOpen({isOpen: true}))} />}
        <BackButton title="Back to Bequethment" />
        <PageTitle
          title="Bequeathment"
          content="If you're planning to leave an inheritance to your children, donate assets to charities, or bequest your possessions to anyone else upon your death, tell us about it here."
          status={isEdit ? 100 : '0'}
          info={<>
              <h6>Bequeathment step</h6>
              <div>Enter any bequests you'd like to leave for your heirs, charities, etc.  It's important to enter these amounts since ValueSoft will factor these in as financial goals and let you know along the way if you will be able to meet these bequeathment goals based on your financial situation.</div>
              <hr className="tooltip__line"/>
              <h6>Example</h6>
              <div>Say you want to leave $1,000,000 for your kids and another $200,000 for your grandkids.  Then input two entries in this bequeathment step, one for $1,000,000 and another for $200,000.</div>
            </>
          }
        />
        <div className="layout-form__content-holder">
          <Formik
            enableReinitialize
            initialValues={isEdit && bequeath
              ? {...bequeath}
              : {
                name: '',
                value: '',
                type: ''
              }
            }
            validationSchema={BequeathSchema}
            onSubmit={onSubmit}
          >
            {({ errors, handleChange, handleBlur, values, touched, setFieldValue }) => (
              <Form>
                <FormGroup name="name" labelText="Name">
                  <Input
                    name="name"
                    placeholder="Enter name"
                    type="text"
                    onChange={handleChange}
                    value={values.name || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.name && touched.name}
                  />
                  {touched.name && errors.name && <span className="form__msg-error">{errors.name}</span>}
                </FormGroup>
                <FormGroup name="type" labelText="Type">
                  <RadioGroup
                    name="type"
                    value={values.type || ''}
                    onChange={handleChange}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value="leave" control={<Radio />} label="Leave an Inheritance" />
                    <FormControlLabel value="donate" control={<Radio />} label="Donate to Charity" />
                    <FormControlLabel value="other" control={<Radio />} label="Other" />
                  </RadioGroup>
                  {touched.type && errors.type && <span className="form__msg-error">{errors.type}</span>}
                </FormGroup>
                <FormGroup
                  name="value"
                  labelText="Value"
                >
                  <InputCurrency
                    icon
                    placeholder="Type value"
                    type="text"
                    name="value"
                    onChange={handleChange}
                    value={values.value || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.value && touched.value}
                  />
                  {touched.value && errors.value && <span className="form__msg-error">{errors.value}</span>}
                </FormGroup>
                <h5 className="mt--medium">Additional info</h5>
                <FormGroup name="additional_info" labelText="Is there any other Bequeathment information that you believe is important for ValueSoft to know about? If so, please let us know about it:	">
                  <TextareaAutosize
                    maxLength="200"
                    name="additional_info"
                    value={values.additional_info || ''}
                    className="form__textarea"
                    placeholder="Maximum characters: 200"
                    onChange={handleChange}
                  />
                </FormGroup>
                <div className="form__button">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Save
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <ModalBox
          small
          inline
          open={isOpen}
          title="Are you sure?"
          button="Remove Item"
          closeButton="Cancel"
          onClick={deleteBequeath}
          close={() => dispatch(setIsOpen({isOpen: false}))}
        />
      </LayoutForm>
    </Layout>
  );
};

export default BequeathDetails;
