import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../../components/Layout/Layout'
import { BackButton } from '../../components/shared/Buttons'
import Tooltip from '../../components/shared/Tooltip/Tooltip'
import { Bar } from 'react-chartjs-2'
import { Slider, Input, FormGroup, FormSelect, InputCurrency } from '../../components/shared/Form'
import { optionsCalculator } from '../../utils/optionsBar'
import PercentIcon from '../../assets/images/icons/percentage.svg'
import { Button } from '@mui/material'
import ModalBox from '../../components/shared/Modal/Modal'
import {
  fetchCalculators,
  fetchListResults,
  fetchCurrentResult,
  saveItemResult,
  removeItemResult,
  setCurrentCalculator
} from '../../redux/slices/calculatorSlice'
import { convertDecimal } from '../../utils/utils'
import { fetchIncomeAndExpensesAdmin } from '../../redux/slices/calculatorsSetupSlice'

const ImpactOfIncomeAndExpenses = ({ props }) => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [isSavedModal, setIsSavedModal] = useState(false)
  const { id, company_id } = useSelector((state) => state.user.user);
  const { calculators, current, listExisting, loading } = useSelector(state => state.calculator)
  const baseValues = useSelector(state => state.calculatorsSetup.incomeAndExpensesAdmin);
  const [count, setCount] = useState(0)
  const [currentId, setCurrentId] = useState('')
  const [values, setValues] = useState(null)

  useEffect(() => {
    if(baseValues){
      setValues({
        currentId: '',
        name: '',
        ...baseValues
      })
    }
  // eslint-disable-next-line
  }, [baseValues])

  const data = {
    labels: [count < 10000000000 ? `$ ${count}` : 'Never'],
    datasets: [
      {
        label: 'You',
        data: [count < 10000000000 ? count : 0],
        backgroundColor: '#215E59',
        maxBarThickness: 114
      }
    ],
  }

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchIncomeAndExpensesAdmin(company_id));
      await dispatch(fetchCalculators({
        path: 'impact-of-income-and-net-worth'
      }));
      await dispatch(fetchListResults('impact-of-income-and-net-worth'))
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    if (values) {
      if(+values.annual_rate === +values.annual_inflation){
        let total = (+values.reduce_expenses + +values.increase_expenses) * 12 * +values.evaluate_impact
        setCount(
          total.toFixed(2)
        )
      } else {
        let total = (+values.reduce_expenses + +values.increase_expenses) * ((Math.pow((1 + ((+values.annual_rate / 100 - +values.annual_inflation / 100) / 12)), (+values.evaluate_impact * 12))) - 1) / ((+values.annual_rate / 100 - +values.annual_inflation / 100) / 12)
        setCount(
          total.toFixed(2)
        )
      }
    } 
    // eslint-disable-next-line
   }, [values])

  useEffect(() => {
    async function fetchNewData() {
     setValues({
        name: current ? current.name : '',
        currentId: currentId,
        reduce_expenses: current ? +current.reduce_expenses : baseValues.reduce_expenses,
        increase_expenses: current ? +current.increase_expenses : baseValues.increase_expenses,
        annual_rate: current ? +current.annual_rate : baseValues.annual_rate,
        annual_inflation: current ? +current.annual_inflation : baseValues.annual_inflation,
        evaluate_impact: current ? +current.evaluate_impact : baseValues.evaluate_impact
      })
    }

    if (currentId) {
      fetchNewData()
    }
    // eslint-disable-next-line
  }, [currentId, current])

  const handleInputChange = e => {
    setValues({
      ...values,
      [e.target.name]: +e.target.value
    })
  }

  const handleCurrencyChange = e => {
    setValues({
      ...values,
      [e.target.name]: +convertDecimal(e.target.value)
    })
  }

  const changeCurrentData = async e => {
    await setCurrentId(e.target.value)
    await dispatch(fetchCurrentResult(e.target.value, 'impact-of-income-and-net-worth'))
  }

  const onSave = async () => {
    dispatch(saveItemResult(values, 'impact-of-income-and-net-worth')).then(res => {
      const calculator = res?.data?.data;

      if(calculator) {
        setIsSavedModal(true);
        setCurrentId(calculator.id);
        dispatch(setCurrentCalculator(calculator));
      }
    }).finally(() => {
      setIsOpen(false);
    })
  }

  const resetForm = () => {
    setValues({
      currentId: '',
      name: '',
      ...baseValues
    })
    setCurrentId('')
  }

  const removeResult = async () => {
    await dispatch(removeItemResult(currentId, 'impact-of-income-and-net-worth'))
    await setIsDeleteModal(false)
    await setValues({
      ...baseValues
    })
    await setCurrentId('')
  }

  return (
    <Layout decor>
      <BackButton title="Back to Calculators" path="/calculators" className="back-button--position" />
      <div className="calculators__title">
        <div>
          {calculators && <h4>{calculators.name}</h4>}
          {calculators &&
            <Tooltip
              title={
                <>
                  <h6>{calculators.name}</h6>
                  {calculators.tooltip}
                  <hr className="tooltip__line"/>
                  <h6>Example</h6>
                  {calculators.example}
                </>
              }
            />}
        </div>

        {listExisting && listExisting.length > 0 && <div className="calculators__title-select">
          <FormSelect
            options={listExisting}
            id="currentId"
            value={currentId}
            medium
            name="currentId"
            onChange={changeCurrentData}
            placeholder="Select result"
          />
        </div>}
      </div>
      
      <div className="calculators__holder">
        <div className="calculators__content">
          <h6 className="calculators__subtitle">Step 1: Input Details</h6>
          <div className="calculators__area">
            <InputCurrency
              id="reduce_expenses"
              name="reduce_expenses"
              value={values ? values.reduce_expenses.toString() : ''}
              small
              smallHeight
              onChange={handleCurrencyChange}
              withoutSign
              iconLeft
            />
            <div className="calculators__area-slider">
              <Slider
                max={600}
                step={5}
                label="Reduce Monthly Expenses by"
                value={values ? +values.reduce_expenses : 0}
                onChange={(e, newValue) => setValues({...values, reduce_expenses: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <InputCurrency
              name="increase_expenses"
              value={values ? +values.increase_expenses.toString() : ''}
              small
              smallHeight
              onChange={handleCurrencyChange}
              withoutSign
              iconLeft
            />
            <div className="calculators__area-slider">
              <Slider
                max={600}
                step={5}
                label="Increase Monthly Income by"
                value={values ? +values.increase_expenses : 0}
                onChange={(e, newValue) => setValues({...values, increase_expenses: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <Input
              type="number"
              name="annual_rate"
              value={values ? +values.annual_rate.toString() : ''}
              small
              smallHeight
              step={0.1}
              onChange={handleInputChange}
              iconName={PercentIcon}
            />
            <div className="calculators__area-slider">
              <Slider
                step={0.5}
                label="Annual Rate of Return"
                value={values ? +values.annual_rate : 0}
                onChange={(e, newValue) => setValues({...values, annual_rate: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <Input
              type="number"
              name="annual_inflation"
              value={values ? +values.annual_inflation.toString() : ''}
              small
              smallHeight
              step={0.1}
              onChange={handleInputChange}
              iconName={PercentIcon}
            />
            <div className="calculators__area-slider">
              <Slider
                step={0.5}
                label="Annual Inflation Rate"
                value={values ? +values.annual_inflation : 0}
                onChange={(e, newValue) => setValues({...values, annual_inflation: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <Input
              type="number"
              name="evaluate_impact"
              value={values ? +values.evaluate_impact.toString() : ''}
              small
              smallHeight
              onChange={handleInputChange}
            />
            <div className="calculators__area-slider">
              <Slider
                label="Years from today to evaluate impact"
                value={values ? +values.evaluate_impact : 0}
                onChange={(e, newValue) => setValues({...values, evaluate_impact: newValue})}
              />
            </div>
          </div>
        </div>
        <div className="calculators__preview">
          <h6 className="calculators__subtitle">Step 2: View results</h6>
          <div className="calculators__preview-box">
            <h6 className="calculators__subtitle">Net Worth Odometer</h6>
            <Bar data={data} options={optionsCalculator('10000', true, true)} />
          </div>
        </div>
      </div>
      <div className="calculators__buttons">
        <Button variant="contained" size="large" color="primary" onClick={() => setIsOpen(true)}>Save result</Button>
        {currentId && <Button variant="contained" size="large"  onClick={() => setIsDeleteModal(true)}>Remove result</Button>}
        <Button size="large"
                className="MuiButton-textGray"
                onClick={() => {
                    resetForm()
                    setCurrentId('')
                }}>
          Reset
        </Button>
      </div>

      <ModalBox
        open={isOpen}
        close={() => setIsOpen(false)}
        onClick={onSave}
        button="Save"
        title="Save Result"
        isLoading={loading}
        isDisabled={values && !values.name && !values.currentId}
      >
        <FormGroup name="name" labelText="Name">
          <Input
            name='name'
            value={values ? values.name : ''}
            placeholder="Type a name of result"
            onChange={e => setValues({
              ...values,
              name: e.target.value
            })}
          />
        </FormGroup>
        {listExisting && listExisting.length > 0 &&
          <>
            <p className="text-center">or</p>
            <FormGroup labelText="Select  Existing Result">
              <FormSelect
                options={listExisting}
                value={values && values.currentId}
                name="currentId"
                onChange={e => setValues({
                  ...values,
                  currentId: e.target.value,
                  name: listExisting.find(i => i.value === e.target.value).label
                })}
                placeholder="Select Existing Result"
              />
            </FormGroup>
          </>
        }
      </ModalBox>

      <ModalBox
        small
        open={isDeleteModal}
        close={() => setIsDeleteModal(false)}
        onClick={removeResult}
        button="Remove"
        title={`Do you want to remove ${current && current.name}?`}
      />

      <ModalBox
        small
        open={isSavedModal}
        close={() => setIsSavedModal(false)}
        button="Ok"
        onClick={() => setIsSavedModal(false)}
      >
        <div>
          {calculators && calculators.iconLeft &&
            <div className="modal__title-iconLeft">
              <img src={calculators.iconLeft} alt={calculators.name} />
            </div>}
          <h4>Your calculator scenario has been saved.</h4>
          <p>You can access your saved scenarios through the “Select result” dropdown menu at the top of each calculator page.</p>
        </div>
      </ModalBox>
    </Layout>
  )
}

export default ImpactOfIncomeAndExpenses
