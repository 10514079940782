import React from 'react'
import { Form, Formik } from 'formik'
import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { FormGroup, Input, InputCurrency } from '../shared/Form'
import { normalizeBoolean } from '../../utils/utils'
import Percentage from '../../assets/images/icons/percentage.svg'

const HomeAffordabilityAdmin = ({ title, onSave, values, sending }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{...values}}
      onSubmit={values => onSave(values)}
    >
      {({ handleChange, handleBlur, setFieldValue, values }) => (
        <Form>
          <h4 className="form__main-title">{title}</h4>
          <h6>Profile info</h6>
          <div className="form__flow-wrap">
          <FormGroup name="current_age" labelText="Current age">
            <Input
              name="current_age"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.current_age || 0}
            />
          </FormGroup>
          <FormGroup name="current_household_annual_gross_income" labelText="Current household annual gross income">
            <InputCurrency
              name="current_household_annual_gross_income"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.current_household_annual_gross_income || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="additional_annual_gross_income" labelText="Additional annual gross income">
            <InputCurrency
              name="additional_annual_gross_income"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.additional_annual_gross_income || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="car_loan_monthly_payment" labelText="Car loan monthly payment">
            <InputCurrency
              name="car_loan_monthly_payment"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.car_loan_monthly_payment || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="student_loan_monthly_payment" labelText="Student loan mothly payment">
            <InputCurrency
              name="student_loan_monthly_payment"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.student_loan_monthly_payment || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="credit_balance_monthly_interest" labelText="Credit card balance monthly interest payment">
            <InputCurrency
              name="credit_balance_monthly_interest"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.credit_balance_monthly_interest || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="other_debt_monthly_payment" labelText="Other debt monthly payment">
            <InputCurrency
              name="other_debt_monthly_payment"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.other_debt_monthly_payment || 0}
              withoutSign
              icon
            />
          </FormGroup>
          </div>
          <h6>Purchase details</h6>
          <div className="form__flow-wrap">
          <FormGroup name="age_when_want_buy_home" labelText="Age you’d like to buy home">
            <Input
              name="age_when_want_buy_home"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.age_when_want_buy_home || 0}
            />
          </FormGroup>
          <FormGroup name="home_value" labelText="Home value (Today’s $)">
            <InputCurrency
              name="home_value"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.home_value || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="mortgage_financing_rate" labelText="Mortgage financing rate">
            <Input
              name="mortgage_financing_rate"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              step={0.1}
              value={values.mortgage_financing_rate || 0}
              iconName={Percentage}
            />
          </FormGroup>
          <FormGroup name="mortgage_term" labelText="Mortgage term">
            <Input
              name="mortgage_term"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.mortgage_term || 0}
            />
          </FormGroup>
          <FormGroup name="down_payment" labelText="Downpayment">
            <Input
              name="down_payment"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              step={0.1}
              value={values.down_payment || 0}
              iconName={Percentage}
            />
          </FormGroup>
          <FormGroup name="annual_insurance_premium" labelText="Annual insurance premium">
            <InputCurrency
              name="annual_insurance_premium"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.annual_insurance_premium || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="annual_home_maintenance" labelText="Annual home maintenance">
            <InputCurrency
              name="annual_home_maintenance"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.annual_home_maintenance || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="monthly_utilities" labelText="Monthly utilities">
            <InputCurrency
              name="monthly_utilities"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.monthly_utilities || 0}
              withoutSign
              icon
            />
          </FormGroup>
          </div>
          <h6>Assumptions</h6>
          <div className="form__flow-wrap">
          <FormGroup name="estimated_wage_growth_rate" labelText="Estimated annual wage growth rate">
            <Input
              name="estimated_wage_growth_rate"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              step={0.1}
              value={values.estimated_wage_growth_rate || 0}
              iconName={Percentage}
            />
          </FormGroup>
          <FormGroup name="home_appreciation_rate" labelText="Home appreciation rate">
            <Input
              name="home_appreciation_rate"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              step={0.1}
              value={values.home_appreciation_rate || 0}
              iconName={Percentage}
            />
          </FormGroup>
          <FormGroup name="property_tax_rate" labelText="Property tax rate">
            <Input
              name="property_tax_rate"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              step={0.1}
              value={values.property_tax_rate || 0}
              iconName={Percentage}
            />
          </FormGroup>
          <FormGroup name="affordability_ratio_standards" labelText="Which affordability ratio standards would you like to use?">
            <RadioGroup
              name="affordability_ratio_standards"
              value={values.affordability_ratio_standards || 'fha'}
              onChange={(e) => {
                setFieldValue('affordability_ratio_standards', normalizeBoolean(e.target.value))
              }}
              className="radio-list-inline"
            >
              <FormControlLabel value={'fha'} control={<Radio />} label="FHA" />
              <FormControlLabel value={'conventional'} control={<Radio />} label="Conventional" />
            </RadioGroup>
          </FormGroup>
          </div>
          <div className="form__button">
            <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
              Save
              {sending && <span className="spinner" />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default HomeAffordabilityAdmin
