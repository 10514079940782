import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AuthContent from '../../components/Auth/AuthContent';
import { Form, Formik } from 'formik';
import { LoginSchema } from '../../utils/validation';
import { FormGroup, Input } from '../../components/shared/Form';
import { Button } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Door from '../../assets/images/door-img.png';
import { loginUser, setAuthError } from '../../redux/slices/authSlice';
import { setCompanyData } from '../../redux/slices/companiesSlice';
import isString from 'lodash/isString';
import { MAIN_SITE } from '../../constants/additional_links';
import { setOpenMenu } from '../../redux/slices/additionalSlice';
import Modal from '../../components/shared/Modal/Modal';
import { isEmpty, isNull } from 'lodash';

const SignIn = ({ props }) => {
  const dispatch = useDispatch();
  const serverError = useSelector((state) => state.auth.authError);
  const sending = useSelector((state) => state.sending.isSending);
  const openMenu = useSelector((state) => state.additional.menuOpen);
  const authInfo = useSelector((state) => state.auth.authInfo);
  const [authProps, setAuthProps] = useState(null);
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);
  const [isOpenSignUpModal, setIsOpenSignUpModal] = useState(false);

  useEffect(() => {
    if (!isEmpty(authInfo)) {
      setAuthProps({
        login: authInfo[1].value,
        registration: authInfo[0].value,
      });
    }
  }, [authInfo]);

  useEffect(() => {
    if (!isNull(authProps) && !authProps.login) {
      setIsOpenLoginModal(true);
    }
  }, [authProps]);

  useEffect(() => {
    if (serverError && serverError === 'Action disabled') {
      setIsOpenLoginModal(true);
    }
  }, [serverError]);

  useEffect(() => {
    dispatch(
      setCompanyData({
        image: null,
      })
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    openMenu
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'visible');
  }, [openMenu]);

  useEffect(() => {
    dispatch(setAuthError({ authError: false }));
    dispatch(setOpenMenu(false));
  }, [dispatch]);

  const onSubmit = (values) => {
    dispatch(loginUser(values, props));
  };

  return (
    <AuthContent decor={{ image: Door }} close={MAIN_SITE} top>
      <h2 className='mb--medium'>Welcome back</h2>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={LoginSchema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          setFieldValue,
          handleBlur,
          values,
          touched,
          isValid,
          dirty,
        }) => (
          <Form>
            {serverError && isString(serverError) && (
              <span className='form__msg-error'>{serverError}</span>
            )}
            <FormGroup name='email' labelText='Email address'>
              <Input
                placeholder='example@email.com'
                type='email'
                name='email'
                onChange={(e) => {
                  setFieldValue('email', e.target.value);
                  serverError && dispatch(setAuthError({ authError: false }));
                }}
                value={values.email}
                onBlur={handleBlur}
                hasError={!!errors.email && touched.email}
              />

              {touched.email && errors.email ? (
                <span className='form__msg-error'>{errors.email}</span>
              ) : serverError?.message ? (
                <span className='form__msg-error'>{serverError.message}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name='password' labelText='Password'>
              <Input
                name='password'
                placeholder='Your password'
                type='password'
                onChange={(e) => {
                  setFieldValue('password', e.target.value);
                  serverError && dispatch(setAuthError({ authError: false }));
                }}
                value={values.password}
                onBlur={handleBlur}
                hasError={!!errors.password && touched.password}
              />
              {touched.password && errors.password ? (
                <span className='form__msg-error'>{errors.password}</span>
              ) : serverError?.message ? (
                <span className='form__msg-error'>{serverError.message}</span>
              ) : (
                ''
              )}
            </FormGroup>
            {authProps?.login && (
              <Link to='/forgot-password' className='auth__form-link'>
                Don’t remember your password?
              </Link>
            )}
            <div className='form__button mb--medium'>
              <Button
                variant='contained'
                size='large'
                color='primary'
                type='submit'
                disabled={!(isValid && dirty) || sending}
              >
                Sign in
                {sending && <span className='spinner' />}
              </Button>
            </div>
            <div className='auth__form-info'>
              Don't have an account?
              {authProps?.registration ? (
                <Link to='/signup-type' className='auth__form-info-link'>
                  Sign up here <ChevronRightIcon />
                </Link>
              ) : (
                <button
                  onClick={() => setIsOpenSignUpModal(true)}
                  type='button'
                  className='auth__form-info-button'
                >
                  Sign up here <ChevronRightIcon />
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
      <Modal
        open={isOpenLoginModal || isOpenSignUpModal}
        button='OK'
        close={() => {
          setIsOpenLoginModal(false);
          setIsOpenSignUpModal(false);
        }}
        onClick={() => {
          setIsOpenLoginModal(false);
          setIsOpenSignUpModal(false);
        }}
        customClass='permission__modal'
        children={
          isOpenLoginModal ? (
            <>
              <h3>Login functionality is temporarily disabled</h3>
              <p>
                To get more information, contact us by email
                inquiries@valuesoftinc.com
              </p>
            </>
          ) : (
            <>
              <h3>Sign up functionality is temporarily disabled</h3>
              <p>
                To get more information, contact us by email
                inquiries@valuesoftinc.com
              </p>
            </>
          )
        }
      />
    </AuthContent>
  );
};

export default SignIn;
