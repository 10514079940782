import React, { useEffect } from 'react'
import { Form, Formik } from 'formik'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../../components/Layout/Layout'
import { BackButton, DeleteButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import LayoutForm from '../../../components/Layout/LayoutForm'
import {Button, FormControlLabel, Radio, RadioGroup, TextareaAutosize} from '@mui/material'
import { FormGroup, InputCurrency, FormSelect, Input, InputPercent } from '../../../components/shared/Form'
import { fetchObjectiveById, postObjective, removeObjective } from '../../../redux/slices/futureSlice'
import { setIsOpen } from '../../../redux/slices/modalSlice'
import ModalBox from '../../../components/shared/Modal/Modal'
import typeAssets from '../../../Data/typeAssets'
import { objectiveSchema } from '../../../utils/validationFuture'
import Illustration from '../../../assets/images/illustrations/Tree-segment_future-min.png'
import { normalizeBoolean } from '../../../utils/utils'


const ObjectivesDetails = ({ props, isEdit }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(state => state.modal.isOpen);
  const userId = useSelector(state => state.user.user.id);
  const sending = useSelector(state => state.sending.isSending);
  const objective = useSelector(state => state.future.objectives);
  const { id }  = useParams();

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchObjectiveById(id));
    }

    if (userId && id) {
      fetch()
    }

    // eslint-disable-next-line
  }, [userId, id])

  const deleteAsset = () => {
    dispatch(removeObjective(id, props));
    dispatch(setIsOpen({isOpen: false}))
  }

  const onSubmit = values => {
    dispatch(postObjective(values, props, id, isEdit))
  }

  const handleChangeInput = (e, setValue) => {
    if([null, false].includes(e.target.value)) return false

    setValue(e.target.name, e.target.value)
  }

  return (
    <Layout>
      <LayoutForm small illustration={Illustration}>
        <BackButton title="Back to Objectives" />
        {isEdit && <DeleteButton className="delete-button--position" onClick={() => dispatch(setIsOpen({isOpen: true}))} />}
        <PageTitle
          title={isEdit && objective && objective.name ? objective.name : 'Fixed assets'}
          content="Tell us about any fixed assets you plan to purchase."
          status={isEdit ? 100 : '0'}
          info={
              <>
                If you desire to purchase a fixed asset in the future, enter the items you plan to purchase or would like to purchase..  This information will be used in calculating your projected net worth, cash flow health and overall financial situation.  Since fixed assets play a big role in net worth and cash flow, it's important to accurately reflect their details, such as their appreciation and depreciation rates, financing rates and other important factors.
                <hr className="tooltip__line"/>
                <h6>Example</h6>
                Say you would like to buy a home in 2 years, a boat in 3 years, and a vacation property in 5 years.  Enter them all in this step.
              </>
          }
        />
        <div className="layout-form__content-holder">
          <Formik
            enableReinitialize
            initialValues={isEdit ? {...objective}: {liquidated_asset: false}}
            validationSchema={objectiveSchema}
            onSubmit={onSubmit}
          >
            {({ errors, handleChange, handleBlur, values, touched, setFieldValue }) => (
              <Form>
                <FormGroup name="name" labelText="Name">
                  <Input
                    name="name"
                    placeholder="Type name"
                    type="text"
                    onChange={handleChange}
                    value={values.name || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.name}
                  />
                  {errors.name && <span className="form__msg-error">{errors.name}</span>}
                </FormGroup>
                <FormGroup name="type" labelText="Type of asset / loan">
                  <FormSelect
                    options={typeAssets}
                    id="type"
                    value={values.type || ''}
                    name="type"
                    onChange={(e) => {
                      setFieldValue(e.target.name, e.target.value)
                    }}
                    placeholder="Select"
                    hasError={!!errors.type}
                  />
                  {errors.type && <span className="form__msg-error">{errors.type}</span>}
                </FormGroup>
                <FormGroup
                  name="age_when_purchased"
                  labelText="Your anticipated age when purchased"
                >
                  <Input
                    name="age_when_purchased"
                    placeholder="Type anticipated age when purchased"
                    type="number"
                    onChange={handleChange}
                    value={values.age_when_purchased || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.age_when_purchased}
                  />
                  {errors.age_when_purchased && <span className="form__msg-error">{errors.age_when_purchased}</span>}
                </FormGroup>
                <FormGroup
                  name="down_payment"
                  labelText="Downpayment %"
                >
                  <InputPercent
                    placeholder="Type downpayment"
                    name="down_payment"
                    onChange={handleChange}
                    value={values.down_payment || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.down_payment}
                  />
                  {errors.down_payment && <span className="form__msg-error">{errors.down_payment}</span>}
                </FormGroup>
                <FormGroup
                  name="category"
                  labelText="Category"
                  info={{
                    content:
                      <>
                        If you expect your asset to increase in value over time (such as a house), then enter 'Appreciating'.  If you expect it to decrease in value (such as a car), select 'Depreciating'.  For loans, please select 'Depreciating' since principal-payment loan balances are reduced each time you make a payment.
                      </>
                  }}
                >
                  <RadioGroup
                    name="category"
                    value={values.category || ''}
                    onChange={handleChange}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value="appreciating" control={<Radio />} label="Appreciating" />
                    <FormControlLabel value="depreciating" control={<Radio />} label="Depreciating" />
                  </RadioGroup>
                  {errors.category && <span className="form__msg-error">{errors.category}</span>}
                </FormGroup>
                <FormGroup
                  name="value_purchased"
                  labelText="Value (Today's $)"
                >
                  <InputCurrency
                    placeholder="Type Value (Today's $)"
                    name="value_purchased"
                    onChange={handleChange}
                    value={values.value_purchased || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.value_purchased}
                  />
                  {errors.value_purchased && <span className="form__msg-error">{errors.value_purchased}</span>}
                </FormGroup>
                <FormGroup
                  name="interest_rate"
                  labelText="Financing Rate % (optional)"
                  info={{
                    content:
                      <>
                        This is typically your loan financing rate.
                        <hr className="tooltip__line"/>
                        <h6>Example</h6>
                        <div>Say you would like to buy a home in 5 years for $150,000 and plan to put a 20% downpayment and finance the rest through a fixed rate 30 year mortgage at 6.75%.  Enter 6.75% in this field.</div>
                      </>
                  }}
                >
                  <InputPercent
                    placeholder="Type Financing Rate"
                    name="interest_rate"
                    onChange={handleChange}
                    value={values.interest_rate || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.interest_rate}
                  />
                  {errors.interest_rate && <span className="form__msg-error">{errors.interest_rate}</span>}
                </FormGroup>
                <FormGroup
                  name="financing_term"
                  labelText="Financing Term (Years) (optional)"
                >
                  <Input
                    name="financing_term"
                    placeholder="Type Financing Term (Years)"
                    type="number"
                    onChange={e => handleChangeInput(e, setFieldValue)}
                    value={values.financing_term || ''}
                    onBlur={handleBlur}
                    info={{
                      content:
                        <>
                          This is typically the number of years you have allotted to pay back your loan.  It is the total number of years including any years you have already paid.
                          <hr className="tooltip__line"/>
                          <h6>Example</h6>
                          Say you plan to buy a home in 5 years for $150,000 and plan to put a 20% downpayment and finance the rest through a fixed rate 30 year mortgage at 6.75%.  Enter 30 in this field.
                        </>
                    }}
                  />
                  {errors.financing_term && <span className="form__msg-error">{errors.financing_term}</span>}
                </FormGroup>
                <FormGroup
                  name="square_footage"
                  labelText="Square Footage (if property) (optional)"
                >
                  <Input
                    name="square_footage"
                    placeholder="Type Square Footage (if property)"
                    type="number"
                    onChange={e => handleChangeInput(e, setFieldValue)}
                    value={values.square_footage || ''}
                    onBlur={handleBlur}
                  />
                  {errors.square_footage && <span className="form__msg-error">{errors.square_footage}</span>}
                </FormGroup>
                <FormGroup
                  name="useful_life"
                  labelText="Useful Life (Years) (optional)"
                >
                  <Input
                    name="useful_life"
                    placeholder="Type Useful Life (Years)"
                    type="number"
                    onChange={e => handleChangeInput(e, setFieldValue)}
                    value={values.useful_life || ''}
                    onBlur={handleBlur}
                    info={{
                      content:
                        <>
                          Many fixed assets depreciate over time and often are no longer useful after a certain period of time.  Enter the estimated useful life of your fixed asset(s) here.  If you are using the basic method to entering your fixed assets, enter the average useful life across all your fixed assets of this particular fixed asset type.
                          <hr className="tooltip__line"/>
                          <h6>Example</h6>
                          Say you plan to purchase 2 depreciable fixed asset:  a yacht and a car.  You think it will take on average about 15 years to run both of them to the ground where they would no longer be drivable / sailable.  Then enter 15 in this field.
                        </>
                    }}
                  />
                  {errors.useful_life && <span className="form__msg-error">{errors.useful_life}</span>}
                </FormGroup>
                <FormGroup
                  name="liquidated_asset"
                  labelText="Liquidate asset in the future (if asset)?"
                  info={{
                    content:
                      <>
                        If you are planning to sell the fixed asset you mentioned, then you are planning to liquidate the asset in the future.
                        <hr className="tooltip__line"/>
                        <h6>Example</h6>
                        Say you are planning to own a yacht in 2 years and are planning to sell it in 12 years.  Then answer Yes to this question.
                      </>
                  }}
                >
                  <RadioGroup
                    name="liquidated_asset"
                    value={values.liquidated_asset || false}
                    onChange={(e) => {
                      setFieldValue('liquidated_asset', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                  {errors.liquidated_asset && <span className="form__msg-error">{errors.liquidated_asset}</span>}
                </FormGroup>
                <FormGroup
                  name="age_when_liquidated"
                  labelText="Your anticipated age when liquidated (optional)"
                >
                  <Input
                    name="age_when_liquidated"
                    placeholder="Type your anticipated age when liquidated"
                    type="number"
                    onChange={e => handleChangeInput(e, setFieldValue)}
                    value={values.age_when_liquidated || ''}
                    onBlur={handleBlur}
                    info={{
                      content:
                        <>
                          Enter the age you plan to sell your fixed asset if you plan to liquidate it in the future.
                          <hr className="tooltip__line"/>
                          <h6>Example</h6>
                          Say you are 30, plan to purchase a boat in 1 year and planning to sell it in 5 years.  Enter 35 in this field, which is your age when you plan to sell it.
                        </>
                    }}
                  />
                  {errors.age_when_liquidated && <span className="form__msg-error">{errors.age_when_liquidated}</span>}
                </FormGroup>
                <FormGroup
                  name="estimated_value"
                  labelText="Estimated Value at Time of Sale (Today's $) (optional)"
                >
                  <InputCurrency
                    placeholder="Type your Estimated Value at Time of Sale "
                    name="estimated_value"
                    onChange={handleChange}
                    value={values.estimated_value || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.estimated_value}
                    info={{
                      content:
                        <>
                          Assume that the salvage value is the money you expect to make when you sell your fixed asset.
                          <hr className="tooltip__line"/>
                          <h6>Example</h6>
                          Say you plan to purchase a boat this year for $190,000 and expect to sell it in 7 years.  If you expect it to sell for $60,000 in 7 years, enter $60,000 in this field.
                        </>
                    }}
                  />
                  {errors.estimated_value && <span className="form__msg-error">{errors.estimated_value}</span>}
                </FormGroup>
                <h5 className="mt--medium">Additional info</h5>
                <FormGroup name="additional_info" labelText="Is there any other Fixed Asset information that you believe is important for ValueSoft to know about? If so, please let us know about it:">
                  <TextareaAutosize
                    maxLength="200"
                    name="additional_info"
                    value={values.additional_info || ''}
                    className="form__textarea"
                    placeholder="Maximum characters: 200"
                    onChange={handleChange}
                  />
                </FormGroup>
                <div className="form__button">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Save
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <ModalBox
          small
          inline
          open={isOpen}
          title="Are you sure?"
          button="Remove Item"
          closeButton="Cancel"
          onClick={deleteAsset}
          close={() => dispatch(setIsOpen({isOpen: false}))}
        />
      </LayoutForm>
    </Layout>
  );
};

export default ObjectivesDetails;
