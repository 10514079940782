import React from "react";
import LayoutAdmin from "../../components/Layout/LayoutAdmin";
import { useParams, Redirect } from 'react-router-dom'
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Input, FormGroup } from "../../components/shared/Form";
import { Button } from "@mui/material";
import { AdminCompanySchema } from "../../utils/validation";
import { postCompanyMember  } from '../../redux/slices/companiesSlice';

const AdminCompanyAddAdmin = ({ props, isAdmin }) => {
  const dispatch = useDispatch();
  const { permissions } = useSelector(state => state.user.user)
  const userId = useSelector(state => state.user.user.id)
  const sending = useSelector((state) => state.sending.isSending);
  const { companyId }  = useParams();

  const onSubmit = (values, setErrors) => {
    let formData = new FormData();

    formData.append('first_name', values.first_name)
    formData.append('last_name', values.last_name)
    formData.append('email', values.email)
    formData.append('role_slug', 'superadmin_company')

    dispatch(postCompanyMember(companyId, formData, props, setErrors))
  };

  if (!!userId && !permissions?.ap_add_company) return (<Redirect to="/admin" />)

  return (
    <LayoutAdmin isAdmin={isAdmin}>
      <div className="admin__title">
        <h3>Add new company</h3>
      </div>
      <div className="company-step">
        <div className="company-step__block">
          <div className="company-step__number">1</div>
          <p className="company-step__title">Company info</p>
        </div>
        <div className="company-step__block">
          <div className="company-step__number">2</div>
          <p className="company-step__title">Company Permissions</p>
        </div>
        <div className="company-step__block">
          <div className="company-step__number step-active">3</div>
          <p className="company-step__title">Company SuperAdmin Info</p>
        </div>
      </div>
      <div className="company">
        <Formik
          enableReinitialize
          initialValues={{
            first_name: '',
            last_name: '',
            email: '',
            role: 'superadmin_company'
          }}
          validationSchema={AdminCompanySchema}
          onSubmit={(values, { setErrors }) => onSubmit(values, setErrors)}
        >
          {({ errors, handleChange, handleBlur, values, touched }) => (
            <Form>
              <h5 className="mt--medium">About Company SuperAdmin</h5>
              <div className="comapny__form">
                <FormGroup name="first_name" labelText="First name">
                  <Input
                    name="first_name"
                    placeholder="Enter first name"
                    type="text"
                    onChange={handleChange}
                    value={values.first_name || ""}
                    onBlur={handleBlur}
                    hasError={
                      !!errors.first_name && touched.first_name
                    }
                  />
                  {touched.first_name && errors.first_name && (
                    <span className="form__msg-error">
                      {errors.first_name}
                    </span>
                  )}
                </FormGroup>
                <FormGroup name="last_name" labelText="Last name">
                  <Input
                    name="last_name"
                    placeholder="Enter last name"
                    type="text"
                    onChange={handleChange}
                    value={values.last_name || ""}
                    onBlur={handleBlur}
                    hasError={!!errors.last_name && touched.last_name}
                  />
                  {touched.last_name && errors.last_name && (
                    <span className="form__msg-error">
                      {errors.last_name}
                    </span>
                  )}
                </FormGroup>
              </div>
              <h5 className="mt--medium">Contacts</h5>
              <div className="comapny__form">
                <FormGroup name="email" labelText="Email address">
                  <Input
                    name="email"
                    placeholder="Enter an email"
                    type="text"
                    onChange={handleChange}
                    value={values.email || ""}
                    onBlur={handleBlur}
                    hasError={!!errors.email && touched.email}
                  />
                  {touched.email && errors.email && (
                    <span className="form__msg-error">{errors.email}</span>
                  )}
                </FormGroup>
              </div>
              <div className="form__button">
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  type="submit"
                  disabled={sending}
                >
                  Create
                  {sending && <span className="spinner" />}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </LayoutAdmin>
  );
};

export default AdminCompanyAddAdmin;
