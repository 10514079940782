import { createSlice } from '@reduxjs/toolkit'
import { Analysis } from '../../api/Api'
import { setIsRelogin } from "./loadingSlice";

const analysisSlice = createSlice({
  name: 'analysis',
  initialState: {
    loading: false,
    open: null,
    graphState: null,
    graphCity: null,
    graphRange: null,
    highestPriority: null,
    highPriority: null,
    normalPriority: null,
    lowPriority: null,
    lowestPriority: null,
    age: null,
    netWorth: null,
    totalExpense: null,
    totalIncome: null,
    insurance: null,
    workPerDay: null,
    expectedInheritance: null,
    takeHome: null,
    home: null,
    auto: null,
    necessities: null,
    loved: null,
    luxuries: null,
    other: null,
    current: null,
    preferred: null,
    bequests: null,
    futureEducation: null,
    futureChildren: null,
    sabbatical: null,
    sabbaticalDuration: null,
    supportChildren: null,
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.loading = action.payload
    },
    setOpenTasks: (state, action) => {
      state.open = action.payload
    },
    setHighestPriorityTasks: (state, action) => {
      state.highestPriority = action.payload
    },
    setHighPriorityTasks: (state, action) => {
      state.highPriority = action.payload
    },
    setNormalPriorityTasks: (state, action) => {
      state.normalPriority = action.payload
    },
    setLowPriorityTasks: (state, action) => {
      state.lowPriority = action.payload
    },
    setLowestPriorityTasks: (state, action) => {
      state.lowestPriority = action.payload
    },
    setAgeAnalysis: (state, action) => {
      state.age = action.payload
    },
    setNetWorthAnalysis: (state, action) => {
      state.netWorth = action.payload
    },
    setTotalExpenseAnalysis: (state, action) => {
      state.totalExpense = action.payload
    },
    setTotalIncomeAnalysis: (state, action) => {
      state.totalIncome = action.payload
    },
    setInsuranceAnalysis: (state, action) => {
      state.insurance = action.payload
    },
    setWorkPerDayAnalysis: (state, action) => {
      state.workPerDay = action.payload
    },
    setExpectedInheritanceAnalysis: (state, action) => {
      state.expectedInheritance = action.payload
    },
    setTakeHomeAnalysis: (state, action) => {
      state.takeHome = action.payload
    },
    setHomeAnalysis: (state, action) => {
      state.home = action.payload
    },
    setAutoAnalysis: (state, action) => {
      state.auto = action.payload
    },
    setNecessitiesAnalysis: (state, action) => {
      state.necessities = action.payload
    },
    setLovedAnalysis: (state, action) => {
      state.loved = action.payload
    },
    setLuxuriesAnalysis: (state, action) => {
      state.luxuries = action.payload
    },
    setOtherAnalysis: (state, action) => {
      state.other = action.payload
    },
    setCurrentValuesAnalysis: (state, action) => {
      state.current = action.payload
    },
    setPreferredValuesAnalysis: (state, action) => {
      state.preferred = action.payload
    },
    setBequestsAnalysis: (state, action) => {
      state.bequests = action.payload
    },
    setFutureEducationAnalysis: (state, action) => {
      state.futureEducation = action.payload
    },
    setFutureChildrenAnalysis: (state, action) => {
      state.futureChildren = action.payload
    },
    setSabbaticalAnalysis: (state, action) => {
      state.sabbatical = action.payload
    },
    setSabbaticalDurationAnalysis: (state, action) => {
      state.sabbaticalDuration = action.payload
    },
    setSupportChildrenAnalysis: (state, action) => {
      state.supportChildren = action.payload
    },
    setGraphState: (state, action) => {
        state.graphState = action.payload
    },
    setGraphCity: (state, action) => {
        state.graphCity = action.payload
    },  
    setGraphRange: (state, action) => {
        state.graphRange = action.payload
    }
  }
})

export const fetchAnalysisOpenTasks = () => async (dispatch) => {
  try {
    await dispatch(setIsLoading( true));
    const response = await Analysis.getOpen();
    await dispatch(setOpenTasks(response.data));
    await dispatch(setIsLoading( false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading( false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchAnalysisHighestPriorityTasks = () => async (dispatch) => {
  try {
    await dispatch(setIsLoading( true));
    const response = await Analysis.getHighestPriority();
    await dispatch(setHighestPriorityTasks(response.data));
    await dispatch(setIsLoading( false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading( false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchAnalysisHighPriorityTasks = () => async (dispatch) => {
  try {
    await dispatch(setIsLoading( true));
    const response = await Analysis.getHighPriority();
    await dispatch(setHighPriorityTasks(response.data));
    await dispatch(setIsLoading( false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading( false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchAnalysisNormalPriorityTasks = () => async (dispatch) => {
  try {
    await dispatch(setIsLoading( true));
    const response = await Analysis.getNormalPriority();
    await dispatch(setNormalPriorityTasks(response.data));
    await dispatch(setIsLoading( false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading( false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchAnalysisLowPriorityTasks = () => async (dispatch) => {
  try {
    await dispatch(setIsLoading( true));
    const response = await Analysis.getLowPriority();
    await dispatch(setLowPriorityTasks(response.data));
    await dispatch(setIsLoading( false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading( false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchAnalysisLowestPriorityTasks = () => async (dispatch) => {
  try {
    await dispatch(setIsLoading( true));
    const response = await Analysis.getLowestPriority();
    await dispatch(setLowestPriorityTasks(response.data));
    await dispatch(setIsLoading( false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading( false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchFilterAnalysisState = () => async (dispatch) => {
    try {
        const response = await Analysis.getGraphState();
        await dispatch(setGraphState(response.data));
      } catch (e) {
        console.error(e);
        if (e.response && e.response.status === 401) {
          await dispatch(setIsRelogin({ isRelogin: true }));
        }
      }
}

export const fetchFilterAnalysisRange = () => async (dispatch) => {
    try {
        const response = await Analysis.getGraphRange();
        await dispatch(setGraphRange(response.data));
      } catch (e) {
        console.error(e);
        if (e.response && e.response.status === 401) {
          await dispatch(setIsRelogin({ isRelogin: true }));
        }
      }
}

export const fetchFilterAnalysisCity = (data) => async (dispatch) => {
    try {
        const response = await Analysis.postGraphCity(data);
        await dispatch(setGraphCity(response.data));
      } catch (e) {
        console.error(e);
        if (e.response && e.response.status === 401) {
          await dispatch(setIsRelogin({ isRelogin: true }));
        }
      }
}

export const fetchAnalysisGraphData = (params) => async (dispatch) => {
    try {
        await dispatch(setIsLoading(true));
        const response = await Analysis.getGraphData(params);
        await dispatch(setAgeAnalysis(response.data.data.age));
        await dispatch(setNetWorthAnalysis(response.data.data['net-worth']));
        await dispatch(setTotalExpenseAnalysis(response.data.data['total-expense']));
        await dispatch(setTotalIncomeAnalysis(response.data.data['total-income']));
        await dispatch(setExpectedInheritanceAnalysis(response.data.data['expected-inheritance']));
        await dispatch(setInsuranceAnalysis(response.data.data['insurance']));
        await dispatch(setWorkPerDayAnalysis(response.data.data['work-per-day']));
        await dispatch(setTakeHomeAnalysis(response.data.data['take-home']));
        await dispatch(setAutoAnalysis(response.data.data.auto));
        await dispatch(setHomeAnalysis(response.data.data.home));
        await dispatch(setNecessitiesAnalysis(response.data.data.necessities));
        await dispatch(setLovedAnalysis(response.data.data.loved));
        await dispatch(setLuxuriesAnalysis(response.data.data.luxuries));
        await dispatch(setOtherAnalysis(response.data.data.other));
        await dispatch(setCurrentValuesAnalysis(response.data.data.current));
        await dispatch(setPreferredValuesAnalysis(response.data.data.preferred));
        await dispatch(setBequestsAnalysis(response.data.data.bequests));
        await dispatch(setFutureChildrenAnalysis(response.data.data.children));
        await dispatch(setFutureEducationAnalysis(response.data.data.education));
        await dispatch(setSabbaticalAnalysis(response.data.data.sabbatical));
        await dispatch(setSabbaticalDurationAnalysis(response.data['sabbatical-duration']));
        await dispatch(setSupportChildrenAnalysis(response.data.support));
        await dispatch(setIsLoading(false));
      } catch (e) {
        console.error(e);
        await dispatch(setIsLoading(false));
        if (e.response && e.response.status === 401) {
          await dispatch(setIsRelogin({ isRelogin: true }));
        }
      }
}

export const fetchProfileAnalysis = option => async (dispatch) => {
  try {
    // await dispatch(setIsLoading( true));
    if (option === 'age') {
      const response = await Analysis.getAge();
      await dispatch(setAgeAnalysis(response.data));
    } else if (option === 'net-worth') {
      const response = await Analysis.getNetWorth();
      await dispatch(setNetWorthAnalysis(response.data));
    } else if (option === 'total-expense') {
      const response = await Analysis.getTotalExpense();
      await dispatch(setTotalExpenseAnalysis(response.data));
    } else if (option === 'total-income') {
      const response = await Analysis.getTotalIncome();
      await dispatch(setTotalIncomeAnalysis(response.data));
    } else if (option === 'expected-inheritance') {
      const response = await Analysis.getExpectedInheritance();
      await dispatch(setExpectedInheritanceAnalysis(response.data));
    } else if (option === 'insurance') {
      const response = await Analysis.getInsurance();
      await dispatch(setInsuranceAnalysis(response.data));
    } else if (option === 'work-per-day') {
      const response = await Analysis.getWorkPerDay();
      await dispatch(setWorkPerDayAnalysis(response.data));
    } else if (option === 'take-home') {
      const response = await Analysis.getTakeHome();
      await dispatch(setTakeHomeAnalysis(response.data));
    }
    // await dispatch(setIsLoading( false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading( false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}


export const fetchExpensesAnalysis = option => async (dispatch) => {
  try {
    // await dispatch(setIsLoading( true));
    if (option === 'auto') {
      const response = await Analysis.getAuto();
      await dispatch(setAutoAnalysis(response.data));
    } else if (option === 'home') {
      const response = await Analysis.getHome();
      await dispatch(setHomeAnalysis(response.data));
    } else if (option === 'necessities') {
      const response = await Analysis.getNecessities();
      await dispatch(setNecessitiesAnalysis(response.data));
    } else if (option === 'loved') {
      const response = await Analysis.getLoved();
      await dispatch(setLovedAnalysis(response.data));
    } else if (option === 'luxuries') {
      const response = await Analysis.getLuxuries();
      await dispatch(setLuxuriesAnalysis(response.data));
    } else if (option === 'other') {
      const response = await Analysis.getOther();
      await dispatch(setOtherAnalysis(response.data));
    }

    // await dispatch(setIsLoading( false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading( false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}


export const fetchValuesAnalysis = option => async (dispatch) => {
  try {
    // await dispatch(setIsLoading( true));
    if (option === 'current') {
      const response = await Analysis.getCurrent();
      await dispatch(setCurrentValuesAnalysis(response.data));
    } else if (option === 'preferred') {
      const response = await Analysis.getPreferred();
      await dispatch(setPreferredValuesAnalysis(response.data));
    }

    // await dispatch(setIsLoading( false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading( false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchObjectivesAnalysis = option => async (dispatch) => {
  try {
    // await dispatch(setIsLoading( true));
    let response = null

    switch(option) {
      case 'bequests':
        response = await Analysis.getBequests()
        await dispatch(setBequestsAnalysis(response.data))
        break

      case 'children':
        response = await Analysis.getFutureChildren()
        await dispatch(setFutureChildrenAnalysis(response.data))
        break

      case 'education':
        response = await Analysis.getFutureEducation()
        await dispatch(setFutureEducationAnalysis(response.data))
        break

      case 'sabbatical':
        response = await Analysis.getSabbatical()
        await dispatch(setSabbaticalAnalysis(response.data))
        break

      case 'sabbatical-duration':
        response = await Analysis.getSabbaticalDuration()
        await dispatch(setSabbaticalDurationAnalysis(response.data))
        break

      case 'support':
        response = await Analysis.getSupportChildren()
        await dispatch(setSupportChildrenAnalysis(response.data))
        break

      default:
        await dispatch(setIsLoading( false))
        break
    }

    // await dispatch(setIsLoading( false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading( false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}


export const {
  setIsLoading,
  setOpenTasks,
  setGraphRange,
  setHighestPriorityTasks,
  setHighPriorityTasks,
  setNormalPriorityTasks,
  setLowPriorityTasks,
  setLowestPriorityTasks,
  setAgeAnalysis,
  setAutoAnalysis,
  setHomeAnalysis,
  setLovedAnalysis,
  setLuxuriesAnalysis,
  setNecessitiesAnalysis,
  setNetWorthAnalysis,
  setOtherAnalysis,
  setTotalExpenseAnalysis,
  setTotalIncomeAnalysis,
  setTakeHomeAnalysis,
  setExpectedInheritanceAnalysis,
  setInsuranceAnalysis,
  setWorkPerDayAnalysis,
  setCurrentValuesAnalysis,
  setPreferredValuesAnalysis,
  setBequestsAnalysis,
  setFutureChildrenAnalysis,
  setFutureEducationAnalysis,
  setSabbaticalAnalysis,
  setSabbaticalDurationAnalysis,
  setSupportChildrenAnalysis,
  setGraphState,
  setGraphCity
} = analysisSlice.actions

export default analysisSlice.reducer