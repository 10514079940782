import { createSlice } from '@reduxjs/toolkit'
import { Dependents, Education } from '../../api/Api'
import { setIsLoading, setIsRelogin } from "./loadingSlice";
import { convertDecimal } from '../../utils/utils'
import { setNewTasksCount } from './taskSlice'
import { setIsOpen } from './modalSlice'
import { setSending } from './sendingSlice'

const childrenSlice = createSlice({
  name: "dependents",
  initialState: {
    current: null,
    currentEducation: null,
    futureEducation: null,
    future: null,
    education: {
      your_age_when_child_high_school: null,
      high_school_type: "",
      your_age_when_child_college: null,
      college_type: "",
      pay_for_tuition: false,
      tuition_amount: "",
      funding_529b_plan: false,
      yearly_funding_amount: null,
      account_amount: null,
    },
    currentChild: null,
    loading: false,
  },
  reducers: {
    setChildren: (state, action) => {
      state.current = action.payload;
    },
    setEducation: (state, action) => {
      state.currentEducation = action.payload;
    },
    setFutureEducation: (state, action) => {
      state.futureEducation = action.payload;
    },
    setChild: (state, action) => {
      state.currentChild = action.payload;
    },
    setFutureChildren: (state, action) => {
      state.future = action.payload;
    },
    setEducationData: (state, action) => {
      state.education = {
        ...state.education,
        ...action.payload,
      };
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const fetchData = () => async (dispatch) => {
  try {
    //await dispatch(setIsLoading({isLoading : true}));
    const response = await Dependents.get();
    await dispatch(setChildren(response.data.data));
    //await dispatch(setIsLoading({isLoading : false}));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading({isLoading : false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchDataEducation = () => async (dispatch) => {
  try {
    // await dispatch(setIsLoading({isLoading : true}));
    const response = await Dependents.getEducation();
    await dispatch(setEducation(response.data.data));
    // await dispatch(setIsLoading({isLoading : false}));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading({isLoading : false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetcFutureEducations = () => async (dispatch) => {
  try {
    // await dispatch(setIsLoading({isLoading : true}));
    const response = await Education.getFutureEducationList();
    await dispatch(setFutureEducation(response.data.data));
    // await dispatch(setIsLoading({isLoading : false}));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading({ isLoading: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
};

export const fetchIdData = id => async (dispatch) => {
  try {
    await dispatch(setLoading(true));
    const response = await Dependents.fetch(id);
    await dispatch(setChild(response.data.data))
    await dispatch(setLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postData = (data, props) => async (dispatch) => {
  try {
    const response = await Dependents.post(data);
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
    if (props) {
      await dispatch(() => props.history.push('/dependents'));
    }
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postDataStudent = (data, props) => async (dispatch) => {
  try {
    const response = await Dependents.post(data);
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
    if (props) {
      await dispatch(() => props.history.push(`/education/${response.data.dependent.id}`));
    }
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const updateChildData = (id, data, props) => async (dispatch) => {
  try {
    const response = await Dependents.put(id, data);
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
    if (props) {
      await dispatch(() => props.history.push('/dependents'));
    }
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const removeChild = (id, props) => async (dispatch) => {
  try {
    const response = await Dependents.del(id);
    if (response) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
    await dispatch(() => props.history.push('/dependents'));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchChildEducation = id => async (dispatch) => {
  try {
    // await dispatch(setIsLoading({isLoading : true}));
    const response = await Education.get(id);

    if(response.data) {
      await dispatch(setEducationData(response.data.data));
    } else {
      await dispatch(setEducationData({
        your_age_when_child_high_school: null,
        high_school_type: '',
        your_age_when_child_college: null,
        college_type: '',
        pay_for_tuition: false,
        tuition_amount: null,
        funding_529b_plan: false,
        yearly_funding_amount: null,
        account_amount: null
      }));
    }
    // await dispatch(setIsLoading({isLoading : false}));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
    // await dispatch(setIsLoading({isLoading : false}));
  }
}

export const postChildEducation = (id, data, props) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending : true }))
    let { yearly_funding_amount, account_amount, tuition_amount } = data
    yearly_funding_amount = yearly_funding_amount ? convertDecimal(yearly_funding_amount) : '0'
    account_amount        = account_amount ? convertDecimal(account_amount) : '0'
    tuition_amount        = tuition_amount ? tuition_amount.slice(0, -1) : ''

    const response = await Education.post(id, {...data, yearly_funding_amount, account_amount, tuition_amount});
    await dispatch(setEducationData({
      // your_age_when_child_high_school: null,
      // high_school_type: '',
      // your_age_when_child_college: null,
      // college_type: '',
      // pay_for_tuition: false,
      // tuition_amount: null,
      // funding_529b_plan: false,
      // yearly_funding_amount: null,
      // account_amount: null
    }));
    await dispatch(setSending({ isSending : false }))
    await dispatch(setIsOpen({ isOpen: true }))
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount))
    }
  } catch (e) {
    await dispatch(setSending({ isSending : false }))
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

/*export const resetChildEducation = (id) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending : true }))
    await Dependents.delDpend(id);
    await dispatch(setSending({ isSending : false }))
  } catch (e) {
    await dispatch(setSending({ isSending : false }))
    console.error(e);
  }
}*/

export const deleteChildEducation = (id) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending : true }))
    const response = await Dependents.delDpend(id);
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
    await dispatch(setSending({ isSending : false }))
  } catch (e) {
    await dispatch(setSending({ isSending : false }))
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const deleteFutureEducation = (id) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    await Dependents.delFutureEducation(id);
    await dispatch(setSending({ isSending: false }));
  } catch (e) {
    await dispatch(setSending({ isSending: false }));
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
};

export const deactivateCurrentEducation = (props) => async (dispatch) => {
  try {
    await Dependents.offEducation();
    await dispatch(() => props.history.push("/input"));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
};

export const deactivateFutureEducation = (props) => async (dispatch) => {
  try {
    await Dependents.offFutureEducation();
    await dispatch(() => props.history.push("/input"));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
};

//future children and education
export const fetchFutureChildrenData = () => async (dispatch) => {
  try {
    // await dispatch(setIsLoading({isLoading : true}));
    const response = await Dependents.getFutureChildren();
    await dispatch(setFutureChildren(response.data.data));
    await dispatch(setIsLoading({isLoading : false}));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading({isLoading : false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const deactivateFutureChildren = (props) => async (dispatch) => {
  try {
    await Dependents.deactivate();
    await dispatch(() => props.history.push("/input"));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchFutureChildData = id => async (dispatch) => {
  try {
    await dispatch(setIsLoading({isLoading : true}));
    const response = await Dependents.fetchFutureChild(id);
    await dispatch(setFutureChildren(response.data.data));
    await dispatch(setIsLoading({isLoading : false}));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading({isLoading : false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postFutureChildData = (data, type, props) => async (dispatch) => {
  try {
    await dispatch(setLoading(true));
    const response = await Dependents.postFutureChild(data);
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
    await dispatch(setLoading(false));
    if (type === 'education') {
      await dispatch(() =>
        props.history.push(`/future-education/${response.data.children.id}`)
      );
    } else {
      await dispatch(() => props.history.push("/future-children"));
    }
  } catch (e) {
    console.error(e);
    await dispatch(setLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const updateFutureChildData = (id, data, props) => async (dispatch) => {
  try {
    await dispatch(setLoading(true));
    const response = await Dependents.putFutureChild(id, data);
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
    await dispatch(setLoading(false));
    await dispatch(() => props.history.push('/future-children'));
  } catch (e) {
    console.error(e);
    await dispatch(setLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const removeFutureChild = (id, props) => async (dispatch) => {
  try {
    const response = await Dependents.delFutureChild(id);
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
    await dispatch(() => props.history.push('/future-children'));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchFutureChildEducation = id => async (dispatch) => {
  try {
    const response = await Education.getFutureEducation(id);
    await dispatch(setEducationData(response.data.data))
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postFutureChildEducation = (id, data, props) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending : true }))
    let { yearly_funding_amount, account_amount, tuition_amount } = data
    yearly_funding_amount = yearly_funding_amount ? convertDecimal(yearly_funding_amount) : '0'
    account_amount        = account_amount ? convertDecimal(account_amount) : '0'
    tuition_amount        = tuition_amount ? tuition_amount.slice(0, -1) : ''

    const response = await Education.postFutureEducation(id, {...data, yearly_funding_amount, account_amount, tuition_amount})
    await dispatch(setEducationData({
      your_age_when_child_high_school: null,
      high_school_type: '',
      your_age_when_child_college: null,
      college_type: '',
      pay_for_tuition: false,
      tuition_amount: null,
      funding_529b_plan: false,
      yearly_funding_amount: null,
      account_amount: null
    }))

    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount))
    }
    await dispatch(setSending({ isSending : false }))
    await dispatch(setIsOpen({ isOpen: true }))
  } catch (e) {
    console.error(e)
    await dispatch(setSending({ isSending : false }))
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}




export const fetchUmbrellaChildren = (id) => async (dispatch) => {
  try {
    await dispatch(setLoading(true));
    if(id) {
      const response = await Dependents.fetchUmbrellaChild(id);
      await dispatch(setChild(response.data.data))
    } else {
      const response = await Dependents.getUmbrellaChildren();
      await dispatch(setChildren(response.data.data));
    }

    await dispatch(setLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postUmbrellaChildren = (id, data) => async (dispatch) => {
  try {
    if (id) {
      await Dependents.putUmbrellaChild(id, data);
    } else {
      await Dependents.postUmbrellaChild(data);
    }

  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}


export const removeUmbrellaChild = (id) => async (dispatch) => {
  try {
    await Dependents.delUmbrellaChild(id);
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const {
  setChildren,
  setEducation,
  setFutureEducation,
  setFutureChildren,
  setEducationData,
  setChild,
  setLoading,
} = childrenSlice.actions;

export default childrenSlice.reducer