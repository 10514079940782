import { createSlice } from '@reduxjs/toolkit'
import { Percent, User } from '../../api/Api'
import { setSending } from './sendingSlice'
import { setIsRelogin } from './loadingSlice'
import { setUserData, fetchDelayTime } from "./userSlice";
import { getPercent } from '../../utils/utils'

const percentagesSlice = createSlice({
  name: 'percentages',
  initialState: {
    percents: null,
    isLoading: false,
    totalTasks: 0,
    total: 0
  },
  reducers: {
    setPercentages: (state, action) => {
      state.percents = action.payload
    },
    setTotalTasks: (state, action) => {
      state.totalTasks = action.payload;
    },
    setTotalCount: (state, action) => {
      state.total = action.payload
    }
  }
})

export const fetchPercentages = (params, props) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    const response = await Percent.get(params);
    await dispatch(setPercentages(response.data.data));
    await dispatch(setSending({ isSending: false }));
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401 && props) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postTotalPercentages = (data, props) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    await Percent.postTotal(data);
    await dispatch(
      setUserData({
        tasks_percentage: data.tasks_percentage,
      })
    );
    await dispatch(setSending({ isSending: false }));
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
  }
}

export const fetchTotalCount = () => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    const response = await Percent.getTotal();
    await dispatch(setTotalCount(response.data));
    await dispatch(setSending({ isSending: false }));
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchTotalTasks = () => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    const response = await Percent.getTotalTasks();
    await dispatch(setTotalTasks(response.data.data.task_progress_percentage));
    await dispatch(setSending({ isSending: false }));
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
  }
};

export const fetchTotalPercent = (isBasic) => async (dispatch) => {
  try {
    const response = await Percent.get();
    await dispatch(setPercentages(response.data.data));
    const percents = response.data.data;
    const extraData = await User.fetchExtraData();

    const {
      is_auto = false,
      paying_education = false,
      feature_paying_education = false, 
      leave_bequests = false,
      factor_in_a_period = false,
      significant_income = false,
      employment_sabbaticals = false,
      future_children_list = [],
      plan_to_purchase = false,
      own_or_rent_home = '',
      own_other_assets = false,
      total_children = false,
      want_more_children = false,
      stakes_in_companies = false
    } = extraData?.data || {};
  
    const profile    = getPercent(percents, 'profile')
    const values     = getPercent(percents, 'values')
    const risk       = getPercent(percents, 'risk')
    const companies  = getPercent(percents, 'companies')
    const children   = getPercent(percents, 'children')
    const education  = getPercent(percents, 'education')
    const totalAbout = (profile + values + (stakes_in_companies && companies) + (!isBasic && risk) + (!!paying_education  && education) + (!!total_children && children))/((isBasic ? 2 : 3) + (total_children ? 1 : 0) + (paying_education ? 1 : 0) + (stakes_in_companies ? 1 : 0))
    
    const bequeath     = getPercent(percents, 'bequeath')
    const unemployment = getPercent(percents, 'unemployment')
    const estate       = getPercent(percents, 'estate')
    const disability   = getPercent(percents, 'disability')
    const life         = getPercent(percents, 'life')

    const homeInsurance     = getPercent(percents, 'home-insurance')
    const healthInsurance   = getPercent(percents, 'health-insurance')
    const autoInsurance     = getPercent(percents, 'auto-insurance')
    const umbrellaInsurance = getPercent(percents, 'umbrella-insurance')
    const insurance         = (homeInsurance + healthInsurance + (is_auto && autoInsurance) + umbrellaInsurance) / (is_auto ? 4 : 3)

    const totalProtection = ((!isBasic && leave_bequests && bequeath) + (!isBasic && factor_in_a_period && unemployment) + (!isBasic && estate) + (!isBasic && disability) + insurance + life)/(isBasic ? 2 : 4 + (!isBasic && factor_in_a_period && 1) + (!isBasic && leave_bequests && 1))

    const incremental = getPercent(percents, 'incremental')
    const assets      = getPercent(percents, 'assets')
    const worth       = getPercent(percents, 'worth')
    const expense     = getPercent(percents, 'expense')
    const income      = getPercent(percents, 'income')
    const totalFinance =
      ((!isBasic && significant_income && incremental) +
        ((own_or_rent_home === "own" || own_other_assets) && assets) +
        worth +
        expense +
        income) /
      (3 +
        (!isBasic && significant_income && 1) +
        ((own_or_rent_home === "own" || own_other_assets) && 1));

    const sabbatical      = getPercent(percents, 'sabbatical')
    const futureEducation = getPercent(percents, 'future-education')
    const futureChildren  = getPercent(percents, 'future-children')
    const objectives      = getPercent(percents, 'objectives')
    const retirement      = getPercent(percents, 'retirement')
    const totalFuture =
      ((!isBasic && employment_sabbaticals && sabbatical) +
        (!isBasic && want_more_children && futureChildren) +
        (!isBasic &&
          future_children_list?.length > 0 &&
          feature_paying_education &&
          futureEducation) +
        (!isBasic && plan_to_purchase && objectives) +
        retirement) /
      (1 +
        (!isBasic && want_more_children && 1) +
        (!isBasic &&
          feature_paying_education &&
          future_children_list?.length > 0 &&
          1) +
        (!isBasic && employment_sabbaticals && 1) +
        (!isBasic && plan_to_purchase && 1));

    const total = Math.round((totalAbout + totalProtection + totalFinance + totalFuture)/4)
    
    await dispatch(setTotalCount(total));
    
    if (!!total) {
      await Percent.postTotal({
        tasks_percentage: total,
      });
    }

    await dispatch(fetchDelayTime());
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}


export const { setPercentages, setTotalCount, setTotalTasks } =
  percentagesSlice.actions;

export default percentagesSlice.reducer