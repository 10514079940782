import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Layout from '../../components/Layout/Layout'
import { BackButton } from '../../components/shared/Buttons'
import TreeSubItem from '../../components/Tree/TreeSubItem'
import { getPercent } from '../../utils/utils'
import { useSelector, useDispatch } from 'react-redux'
import { fetchUserFamilyAssets, fetchUserFamilyData, fetchUserDetails } from '../../redux/slices/userSlice'
import { fetchTotalPercent } from "../../redux/slices/percentagesSlice";
import TreeCount from '../../components/Tree/TreeCount'
import { fetchAnalysisTasks } from '../../redux/slices/taskSlice'

const Protection = () => {
  const dispatch = useDispatch()
  const { id } = useSelector(state => state.user.user)
  const { leave_bequests, factor_in_a_period } = useSelector(state => state.user.family.others)
  const isBasic = useSelector(state => state.user.user.plan_type === 1)
  const isAuto = useSelector(state => state.user.profile.details.is_auto);
  const percents = useSelector(state => state.percentages.percents)
  const loading = useSelector(state => state.sending.isSending)
  const analysis = useSelector(state => state.task.analysis)
  const totalPercent = useSelector((state) => state.percentages.total);

  useEffect(() => {
    async function fetch() {
      Promise.all([
        dispatch(fetchUserDetails()),
        //dispatch(fetchPercentages()),
        dispatch(fetchUserFamilyAssets(id)),
        dispatch(fetchUserFamilyData(id)),
        dispatch(fetchAnalysisTasks()),
        dispatch(fetchTotalPercent(isBasic)),
      ]);
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const bequeath     = getPercent(percents, 'bequeath')
  const unemployment = getPercent(percents, 'unemployment')
  const estate       = getPercent(percents, 'estate')
  const disability   = getPercent(percents, 'disability')
  const life         = getPercent(percents, 'life')

  const homeInsurance     = getPercent(percents, 'home-insurance')
  const healthInsurance   = getPercent(percents, 'health-insurance')
  const autoInsurance     = getPercent(percents, 'auto-insurance')
  const umbrellaInsurance = getPercent(percents, 'umbrella-insurance')
  const insurance         = (homeInsurance + healthInsurance + (isAuto && autoInsurance) + umbrellaInsurance) / (isAuto ? 4 : 3)

  const totalProtection = ((!isBasic && leave_bequests && bequeath) + (!isBasic && factor_in_a_period && unemployment) + (!isBasic && estate) + (!isBasic && disability) + insurance + life)/(isBasic ? 2 : 4 + (!isBasic && factor_in_a_period && 1) + (!isBasic && leave_bequests && 1))

  const menuList = [
    {
      path: '/insurance',
      count: insurance.toFixed() || '0',
      title: 'Insurance'
    },
    {
      path: '/life',
      count: life || '0',
      title: 'Life'
    },
    {
      path: '/estate',
      count: estate || '0',
      title: 'Estate',
      isBasic: isBasic,
      isDisabled: isBasic
    },
    {
      path: '/disability',
      count: disability || '0',
      title: 'Disability',
      isBasic: isBasic,
      isDisabled: isBasic
    },
    {
      path: '/unemployment',
      count: unemployment || '0',
      title: 'Unemployment',
      isBasic: isBasic,
      isDisabled: isBasic || !factor_in_a_period,
      redirect: '/profile-summary/about-your-family/others',
      modalContent: <p>You need to add the relevant information in the <strong className="text-success">About You</strong> page in order to activate this section. </p>
    },
    {
      path: '/bequeath',
      count: bequeath || '0',
      title: 'Bequeath',
      isBasic: isBasic,
      isDisabled: isBasic || !leave_bequests,
      redirect: '/profile-summary/about-your-family/others',
      modalContent: <p>You need to add the relevant information in the <strong className="text-success">About You</strong> page in order to activate this section. </p>
    }
  ]


  return (
    <Layout totalPercent={totalPercent} tree>
      <BackButton title="Back" className="back-button--position" path="/input" />
      <div className="tree tree--mobile-page">
        <div className="tree__title">
          <h2>Protection</h2>

          <TreeCount value={totalProtection.toFixed()} loading={loading} />
        </div>
        {menuList &&
          menuList.map((item, id) => (
            <TreeSubItem
              key={`menu-${id}`}
              path={item.path}
              title={item.title}
              count={item.count}
              isBasic={item.isBasic}
              isDisabled={item.isDisabled}
              redirect={item.redirect}
              modalContent={item.modalContent}
              isLoading={loading}
            />
          ))}
        <div className="tree__task">
          <Link to="/tasks">
            {analysis && analysis.openCount ? analysis.openCount : "0"}
          </Link>
        </div>
      </div>
    </Layout>
  );
}

export default Protection
