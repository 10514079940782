import { createSlice } from '@reduxjs/toolkit'
import {Tasks, Admin, Reminder} from '../../api/Api'
import { setIsOpen } from './modalSlice'
import { setMode } from "./modeSlice";
import { setSending } from './sendingSlice'
import { setIsRelogin } from './loadingSlice'
import moment from 'moment'

const taskSlice = createSlice({
  name: "task",
  initialState: {
    tasks: [],
    adminTasks: null,
    pagination: {},
    current: {},
    reminders: [],
    currentReminder: {},
    taskAlerts: {
      profile: {},
      goals: {},
      education: {},
      budgeting: {},
      document: {},
      retirement: {},
      insurance: {},
      estate: {},
    },
    taskPlans: [],
    taskPlansRevert: false,
    taskLoading: false,
    editId: null,
    msg: "",
    count: 0,
    analysis: null,
    newTasksCount: 0,
    error: null,
    isUserTask: true,
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.taskLoading = action.payload;
    },
    setTasks: (state, action) => {
      state.tasks = action.payload;
    },
    setAdminTasks: (state, action) => {
      state.adminTasks = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setEditId: (state, action) => {
      state.editId = action.payload;
    },
    setDetails: (state, action) => {
      state.current = action.payload;
    },
    setMsg: (state, action) => {
      state.msg = action.payload;
    },
    setVariables: (state, action) => {
      state.variables = action.payload;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    setAnalysis: (state, action) => {
      state.analysis = action.payload;
    },
    setNewTasksCount: (state, action) => {
      state.newTasksCount = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setReminders: (state, action) => {
      state.reminders = action.payload;
    },
    setReminderDetails: (state, action) => {
      state.currentReminder = action.payload;
    },
    setUserTaks: (state, action) => {
      state.isUserTask = action.payload;
    },
    setTaskAlerts: (state, action) => {
      state.taskAlerts = {
        ...state.taskAlerts,
        ...action.payload,
      };
    },
    setTaskPlans: (state, action) => {
      state.taskPlans = action.payload;
    },
    setTaskPlansRevert: (state, action) => {
      state.taskPlansRevert = action.payload;
    },
    setTaskAlertsProfile: (state, action) => {
      state.taskAlerts.profile = action.payload;
    },
    setTaskAlertsGoals: (state, action) => {
      state.taskAlerts.goals = action.payload;
    },
    setTaskAlertsEducation: (state, action) => {
      state.taskAlerts.education = action.payload;
    },
    setTaskAlertsBudgeting: (state, action) => {
      state.taskAlerts.budgeting = action.payload;
    },
    setTaskAlertsDocument: (state, action) => {
      state.taskAlerts.document = action.payload;
    },
    setTaskAlertsRetirement: (state, action) => {
      state.taskAlerts.retirement = action.payload;
    },
    setTaskAlertsInsurance: (state, action) => {
      state.taskAlerts.insurance = action.payload;
    },
    setTaskAlertsEstate: (state, action) => {
      state.taskAlerts.estate = action.payload;
    },
  },
});

export const fetchTasks = (companyId, params, props, isAdmin) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    if (isAdmin) {
      const response = await Admin.getTasks(companyId, params);
      await dispatch(setAdminTasks(response.data.data));
      await dispatch(setPagination({...response.data.meta, ...response.data.links}));
    } else {
      const response = await Tasks.get(params);
      await dispatch(setTasks(response.data.data));
      await dispatch(setPagination({...response.data.meta, ...response.data.links}));
    }
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
    if (e.response && e.response.status === 403) {
      await dispatch(setAdminTasks(null));
    }
  }
}

export const fetchTaskDetails = (companyId, id, isAdmin) => async (dispatch) => {
  try {
    if (isAdmin) {
      const response = await Admin.showTask(companyId, id);
      await dispatch(setDetails(response.data.data));
    } else {
      const response = await Tasks.fetch(id);
      await dispatch(setDetails(response.data.data));
    }
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postTask = (data, params) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true))
    await Tasks.post(data)
    const response = await Tasks.get(params);
    await dispatch(setTasks(response.data.data))
    await dispatch(setPagination({...response.data.meta, ...response.data.links}))
    await dispatch(setIsLoading(false))
  } catch (e) {
    console.error(e)
    await dispatch(setIsLoading(false))
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postAdminTask = (companyId, data, props, setError) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true))
    await Admin.postTask(companyId, data)
    await dispatch(setIsLoading(false))
    await dispatch(() => props.history.push(`/admin/company/${companyId}/tasks`))
  } catch (e) {
    console.error(e)

    await dispatch(setIsLoading(false))
    if(e.response && e.response.data.message) {
      await dispatch(setError({condition: e.response.data.message}));
    }
  }
}

export const updateTaskDetails = (companyId, id, data, props, isAdmin, setError) => async (dispatch) => {
  try {
    if (isAdmin) {
      await dispatch(setIsLoading(true))
      await Admin.putTask(companyId, id, data)
      await dispatch(setIsLoading(false))
      await dispatch(() => props.history.push(`/admin/company/${companyId}/tasks`))
    } else {
      await Tasks.put(id, data)
      await dispatch(setIsOpen({ isOpen: false }))
    }
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false))
    await dispatch(setIsOpen({ isOpen: false }))
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
    if (e.response && isAdmin && e.response.data.message) {
      await dispatch(setError({ condition: e.response.data.message }));
    }
  }
}

export const deleteTask = (companyId, id, isAdmin) => async (dispatch) => {
  try {
    if (isAdmin) {
      await Admin.delTask(companyId, id)
    } else {
      await Tasks.del(id)
    }
    await dispatch(setIsOpen({ isOpen: false }))
  } catch (e) {
    console.error(e);
    await dispatch(setIsOpen({ isOpen: false }))
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const taskDone = id => async (dispatch) => {
  try {
    await Tasks.done(id);
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const taskViewed = (id, params, shouldUpdateTasks = true) => async (dispatch) => {
  try {
    await Tasks.viewed(id);

    if(shouldUpdateTasks) {
      const response = await Tasks.get(params);
      await dispatch(setTasks(response.data.data));
      await dispatch(setPagination({...response.data.meta, ...response.data.links}));
    }
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const sendMail = (id, data) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    await Tasks.send(id, data);
    await dispatch(setSending({ isSending: false }));
    await dispatch(setMsg('Email has been sent'));
  } catch (e) {
    await dispatch(setSending({ isSending: false }));
    console.error(e);
    await dispatch(setMsg('Error'));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const reminderTask = (id, data) => async (dispatch) => {
  try {
    let { task_due_date } = data;
    task_due_date = moment(task_due_date).format('YYYY-MM-DD')

    await dispatch(setSending({ isSending: true }));
    await Reminder.post({ ...data, task_due_date});
    await dispatch(setSending({ isSending: false }));
    await dispatch(setMsg('Reminder was added.'));
  } catch (e) {
    await dispatch(setSending({ isSending: false }));
    console.error(e);
    await dispatch(setMsg('Error'));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const revertTask = (id, params) => async (dispatch) => {
  try {
    const revertData = await Tasks.revert(id);
    //await dispatch(fetchTaskDetails(id))
    const response = await Tasks.get(params);
    await dispatch(setUserTaks(revertData.data.user_task))
    await dispatch(setTasks(response.data.data));
    await dispatch(setPagination({...response.data.meta, ...response.data.links}));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchTasksVariables = params => async (dispatch) => {
  try {
    // await dispatch(setIsLoading(true));
    const response = await Admin.getVariables(params);
    await dispatch(setVariables(response.data.data));
    // await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchCountTasks = () => async (dispatch) => {
  try {
    const response = await Tasks.getCount();
    await dispatch(setCount(response.data.count));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchAnalysisTasks = () => async (dispatch) => {
  try {
    const response = await Tasks.getAnalysis();
    await dispatch(setAnalysis(response.data));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchReminders = () => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    const response = await Reminder.get();
    await dispatch(setReminders(response.data.data))
    await dispatch(setIsLoading(false));
  } catch (e) {
    await dispatch(setIsLoading(false));
    console.error(e);
    await dispatch(setMsg('Error'));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchReminderById = id => async (dispatch) => {
  try {
    const response = await Reminder.getById(id);
    await dispatch(setReminderDetails(response.data.data))
  } catch (e) {
    console.error(e);
    await dispatch(setMsg('Error'));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const updateReminder = (id, data) => async (dispatch) => {
  try {
    let { task_due_date } = data;
    task_due_date = moment(task_due_date).format("YYYY-MM-DD");
    await dispatch(setSending({ isSending: true }));
    const response = await Reminder.put(id, { ...data, task_due_date });
    await dispatch(setReminderDetails(response.data.data));
    const allReminders = await Reminder.get();
    await dispatch(setReminders(allReminders.data.data))
    await dispatch(setSending({ isSending: false }));
    await dispatch(setMsg("Reminder was updated."));
  } catch (e) {
    await dispatch(setSending({ isSending: false }));
    console.error(e);
    await dispatch(setMsg('Error'));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const deleteReminder = id => async (dispatch) => {
  try {
    await Reminder.del(id)
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchTaskAlerts = data => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    let { id } = data
    const response = await Admin.getTaskAlerts(data);

    switch(id) {
      case 1:
        await dispatch(setTaskAlertsProfile(response.data[0]))
        break

      case 2:
        await dispatch(setTaskAlertsGoals(response.data[0]))
        break

      case 3:
        await dispatch(setTaskAlertsEducation(response.data[0]))
        break

      case 4:
        await dispatch(setTaskAlertsBudgeting(response.data[0]))
        break

      case 5:
        await dispatch(setTaskAlertsDocument(response.data[0]))
        break

      case 6:
        await dispatch(setTaskAlertsRetirement(response.data[0]))
        break

      case 7:
        await dispatch(setTaskAlertsInsurance(response.data[0]))
        break

      case 8:
        await dispatch(setTaskAlertsEstate(response.data[0]))
        break

      default:
        await dispatch(setIsLoading(false));
        break
    }

    await dispatch(setIsLoading(false));
  } catch (e) {
    await dispatch(setIsLoading(false));
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postTaskAlerts = data => async (dispatch) => {
  try {
    const newData = data.map(item => {
      return {
        task_id: item.company_task ? item.company_task.task_id : item.id,
        priority_id: item.company_task ? item.company_task.priority_id : item.priority_id,
        active: item.active || false
      }
    })
    await Admin.postTaskAlerts(newData)
  } catch (e) {
    console.error(e)
    await dispatch(setIsLoading(false))
  }
}

export const postTaskActivation = (companyId, taskId, data) => async (dispatch) => {
  try {
    await Admin.updateTaskActivation(companyId, taskId, data)
  } catch (e) {
    console.error(e)
    await dispatch(setIsLoading(false))
  }
}

export const fetchTaskPlans = (companyId) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    const response = await Admin.getTaskPlans(companyId);
    await dispatch(setTaskPlans(response.data.data));
    await dispatch(setTaskPlansRevert(response.data.revert));
    await dispatch(setIsLoading(false));
  } catch (e) {
    await dispatch(setIsLoading(false));
    console.error(e);
  }
};

export const updateTaskPlans = (companyId,  data) => async (dispatch) => {
  try {
    const response = await Admin.postTaskPlansAll(companyId, data);
    await dispatch(setTaskPlans(response.data.data));
    await dispatch(setTaskPlansRevert(response.data.revert));
    dispatch(setMode("success"));
    dispatch(setIsOpen({ isOpen: true }));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
};

export const revertTaskPlans = (companyId) => async (dispatch) => {
  try {
    const response = await Admin.revertTaskPlansAll(companyId);
    await dispatch(setTaskPlans(response.data.data));
    await dispatch(setTaskPlansRevert(response.data.revert));
    dispatch(setMode("success"));
    dispatch(setIsOpen({ isOpen: true }));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
};

export const {
  setTasks,
  setIsLoading,
  setEditId,
  setDetails,
  setMsg,
  setPagination,
  setAdminTasks,
  setVariables,
  setCount,
  setAnalysis,
  setNewTasksCount,
  setError,
  setReminders,
  setReminderDetails,
  setTaskAlerts,
  setUserTaks,
  setTaskPlans,
  setTaskPlansRevert,
  setTaskAlertsProfile, setTaskAlertsGoals, setTaskAlertsEducation,
  setTaskAlertsBudgeting, setTaskAlertsDocument,
  setTaskAlertsRetirement, setTaskAlertsInsurance, setTaskAlertsEstate
} = taskSlice.actions

export default taskSlice.reducer
