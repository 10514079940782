import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Formik } from 'formik'
import Layout from '../../../components/Layout/Layout'
import LayoutForm from '../../../components/Layout/LayoutForm'
import { BackButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import {FormGroup, InputCurrency} from '../../../components/shared/Form'
import { Button, FormControlLabel, Radio, RadioGroup, TextareaAutosize } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { insuranceHomeSchema } from '../../../utils/validationProtection'
import Illustration from '../../../assets/images/illustrations/Tree-segment_protection-min.png'
import { normalizeBoolean } from '../../../utils/utils'
import { fetchInsuranceHome, postInsuranceHome } from '../../../redux/slices/insuranceSlice'
import { fetchPercentages } from '../../../redux/slices/percentagesSlice'
import Modal from '../../../components/shared/Modal/Modal'
import ProgressCircle from '../../../components/shared/ProgressBar/ProgressCircle'
import { setIsOpen } from '../../../redux/slices/modalSlice'
import Priority from '../../../components/shared/Priority/Priority';

const InsuranceHome = ({ props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.user.user);
  const insurance = useSelector(state => state.insurance.home);
  const sending = useSelector(state => state.sending.isSending);
  const percents = useSelector(state => state.percentages.percents);
  const isOpen = useSelector(state => state.modal.isOpen);

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchPercentages({name: 'home-insurance'}));
      await dispatch(fetchInsuranceHome());
    }
    if (id) {
      fetch()
    }

    // eslint-disable-next-line
  }, [id])

  const onSubmit = (values) => {
    dispatch(postInsuranceHome(values, props))
  }

  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }))
  }

  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }))
    history.push('/insurance')
  }

  return (
    <Layout>
      <LayoutForm illustration={Illustration} small>
        <BackButton title="Back to Basic Insurance" />
        <PageTitle
          title="Home insurance"
          content="Tell us about the details of your health, home, auto and umbrella insurance policies, so ValueSoft can ensure you have the appropriate coverage you require."
          status={percents && percents.percent ? percents.percent : '0'}
        />
        <div className="layout-form__content-holder">
          <Formik
            enableReinitialize
            initialValues={insurance}
            validationSchema={insuranceHomeSchema}
            onSubmit={onSubmit}
          >
            {({ errors, handleChange, handleBlur, values, touched, setFieldValue }) => (
              <Form>
                <FormGroup
                  name="home_insurance"
                  labelText="If you own a home, do you have home insurance? (optional)"
                >
                  <RadioGroup
                    name="home_insurance"
                    value={values.home_insurance || false}
                    onChange={(e) => {
                      setFieldValue('home_insurance', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                  {errors.home_insurance && <span className="form__msg-error">{errors.home_insurance}</span>}
                </FormGroup>
                <FormGroup
                  name="disaster_area"
                  labelText="Do you live in a disaster-prone area (earthquakes, mudslides, tornados, hurricanes)?"
                >
                  <RadioGroup
                    name="disaster_area"
                    value={values.disaster_area || false}
                    onChange={(e) => {
                      setFieldValue('disaster_area', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                  {errors.disaster_area && <span className="form__msg-error">{errors.disaster_area}</span>}
                </FormGroup>
                <FormGroup
                  name="disaster_insurance"
                  labelText="If available, do you have disaster insurance coverage? (optional)"
                >
                  <RadioGroup
                    name="disaster_insurance"
                    value={values.disaster_insurance || false}
                    onChange={(e) => {
                      setFieldValue('disaster_insurance', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                  {errors.disaster_insurance && <span className="form__msg-error">{errors.disaster_insurance}</span>}
                </FormGroup>
                <FormGroup name="replacement_value" labelText="Replacement value of your home (optional):">
                  <InputCurrency
                    icon
                    placeholder="Type your replacement value of your home"
                    type="text"
                    name="replacement_value"
                    onChange={handleChange}
                    value={values.replacement_value || null}
                    onBlur={handleBlur}
                    hasError={!!errors.replacement_value}
                  />
                  {errors.replacement_value && <span className="form__msg-error">{errors.replacement_value}</span>}
                </FormGroup>
                <FormGroup name="dwelling_coverage" labelText="Dwelling Insurance coverage amount (optional):">
                  <InputCurrency
                    icon
                    placeholder="Type your dwelling insurance coverage"
                    type="text"
                    name="dwelling_coverage"
                    onChange={handleChange}
                    value={values.dwelling_coverage || null}
                    onBlur={handleBlur}
                    hasError={!!errors.dwelling_coverage}
                  />
                  {errors.dwelling_coverage && <span className="form__msg-error">{errors.dwelling_coverage}</span>}
                </FormGroup>
                <FormGroup name="separate_structure" labelText="Separate Structures coverage amount (e.g. coverage on detached garage) (optional):">
                  <InputCurrency
                    icon
                    placeholder="Type Separate Structures coverage "
                    type="text"
                    name="separate_structure"
                    onChange={handleChange}
                    value={values.separate_structure || null}
                    onBlur={handleBlur}
                    hasError={!!errors.separate_structure}
                  />
                  {errors.separate_structure && <span className="form__msg-error">{errors.separate_structure}</span>}
                </FormGroup>
                <FormGroup name="personal_property" labelText="Personal Property coverage amount (optional):">
                  <InputCurrency
                    icon
                    placeholder="Type your Personal Property coverage "
                    type="text"
                    name="personal_property"
                    onChange={handleChange}
                    value={values.personal_property || null}
                    onBlur={handleBlur}
                    hasError={!!errors.personal_property}
                  />
                  {errors.personal_property && <span className="form__msg-error">{errors.personal_property}</span>}
                </FormGroup>
                <FormGroup 
                  name="loss_of_use" 
                  labelText="Loss of Use coverage amount (optional):" 
                  info={{
                    content:
                      <>
                        <h6>Loss of Use coverage amount</h6>
                        <Priority option="High" />
                        <div>
                        Loss of use coverage, also known as additional living expenses (ALE) insurance, or Coverage D, can help pay for the additional costs you might incur for reasonable housing and living expenses if a covered event makes your house temporarily uninhabitable while it's being repaired or rebuilt.
                      </div>
                      </>
                  }}
                >
                  <InputCurrency
                    icon
                    placeholder="Type your Loss of Use coverage amount"
                    type="text"
                    name="loss_of_use"
                    onChange={handleChange}
                    value={values.loss_of_use || null}
                    onBlur={handleBlur}
                    hasError={!!errors.loss_of_use}
                  />
                  {errors.loss_of_use && <span className="form__msg-error">{errors.loss_of_use}</span>}
                </FormGroup>
                <FormGroup name="personalliability_home" labelText="Personal Liability coverage amount (optional):">
                  <InputCurrency
                    icon
                    placeholder="Type your Personal Liability coverage "
                    type="text"
                    name="personalliability_home"
                    onChange={handleChange}
                    value={values.personalliability_home || null}
                    onBlur={handleBlur}
                    hasError={!!errors.personalliability_home}
                  />
                  {errors.personalliability_home && <span className="form__msg-error">{errors.personalliability_home}</span>}
                </FormGroup>
                <FormGroup name="identity_fraud" labelText="Identity Fraud coverage amount (optional):">
                  <InputCurrency
                    icon
                    placeholder="Type your Identity Fraud coverage amount"
                    type="text"
                    name="identity_fraud"
                    onChange={handleChange}
                    value={values.identity_fraud || null}
                    onBlur={handleBlur}
                    hasError={!!errors.identity_fraud}
                  />
                  {errors.identity_fraud && <span className="form__msg-error">{errors.identity_fraud}</span>}
                </FormGroup>
                <FormGroup name="jewelry" labelText="Jewelry coverage amount (optional):">
                  <InputCurrency
                    icon
                    placeholder="Type your Jewelry coverage amount"
                    type="text"
                    name="jewelry"
                    onChange={handleChange}
                    value={values.jewelry || null}
                    onBlur={handleBlur}
                    hasError={!!errors.jewelry}
                  />
                  {errors.jewelry && <span className="form__msg-error">{errors.jewelry}</span>}
                </FormGroup>
                <h5 className="mt--medium">Additional info</h5>
                <FormGroup name="additional_info" labelText="Is there any other Basic Insurance information that you believe is important for ValueSoft to know about? If so, please let us know about it:">
                  <TextareaAutosize
                    maxLength="200"
                    name="additional_info"
                    value={values.additional_info || ''}
                    className="form__textarea"
                    placeholder="Maximum characters: 200"
                    onChange={handleChange}
                  />
                </FormGroup>

                <div className="form__button">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Save
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <Modal
          open={isOpen}
          small
          button="Done"
          onClick={handleClick}
          close={onClose}
        >
          <h3>Good Job!</h3>
          <p>Your progress increased 100%</p>
          <ProgressCircle amount={100} small />
        </Modal>
      </LayoutForm>
    </Layout>
  );
};

export default InsuranceHome;
