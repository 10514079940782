import * as Yup from 'yup'

export const incrementalSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  flow: Yup.string().required('Required'),
  age: Yup.number().integer('Please check this value').max(100, 'Please check this value, must be less').required('Required'),
  amount: Yup.string().max(18, 'Please check this value').required('Required'),
  return_of_cash: Yup.string().max(8, 'Please check this value').required('Required'),
  type: Yup.string().required('Required')
});


export const assetsSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  type: Yup.string().required('Required'),
  age_when_purchased: Yup.number().integer('Please check this value').nullable().max(100, 'Please check this value, must be less').required('Required'),
  value_purchased: Yup.string().nullable().max(18, 'Please check this value, must be less').required('Required'),
  category: Yup.string().nullable().required('Required'),
  current_value: Yup.string().nullable().max(18, 'Please check this value, must be less').required('Required'),
  // down_payment: Yup.number().nullable().required('Required'),
});


export const assetsDetailsSchema = Yup.object().shape({
  // interest_rate: Yup.string().required('Required'),
  // financing_term: Yup.number().nullable().required('Required')
});


export const stockSchema = Yup.object().shape({
  stock_option: Yup.string().required('Required'),
  type: Yup.string().required('Required'),
  options_number: Yup.number().nullable().required('Required'),
  exercise_price: Yup.string().nullable().max(18, 'Please check this value, must be less').required('Required'),
  fair_value: Yup.string().nullable().max(18, 'Please check this value, must be less').required('Required'),
  vesting_date: Yup.string().nullable().required('Required'),
  desired_exercise_date: Yup.string().nullable().required('Required'),
});


export const expensesSchema = Yup.object().shape({
  amount: Yup.string().nullable().max(18, 'Please check this value').required('Required'),
  body: Yup.string().nullable().required('Required'),
  type: Yup.string().nullable().required('Required'),
});


export const incomeSchema = Yup.object().shape({
  monthly_income: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  spouse_monthly_income: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  yearly_bonus: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  spouse_yearly_bonus: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  other_monthly: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  estimated_taxes: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  others: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
});


export const worthCashSchema = Yup.object().shape({
  cash_equivalent: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  saving: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  checking: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
});


export const worthFixedSchema = Yup.object().shape({
  home_primary: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  appreciable_asset: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  depreciable_asset: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
});


export const worthNonRetirementSchema = Yup.object().shape({
  brokerage_account: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  trust_account: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  annuities_account: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  cash_value: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  vested_stock: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  unvested_stock: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
});


export const worthRetirementSchema = Yup.object().shape({
  account_401k: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  account_403b: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  pension: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  solo_401k_account: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  roth_401k_account: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  traditional_ira_account: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  roth_ira_account: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  simple_ira_account: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  sep_ira_account: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
});


export const worthDebtCreditSchema = Yup.object().shape({
  mortgage_balance: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  equity_credit_balance: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  credit_balance: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  overdue_balance: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
});


export const worthNecessitiesSchema = Yup.object().shape({
  utilities: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  phone_payments: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  mortgage_payments: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  car_payments: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  taxes: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  other_loan_balance: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
});

