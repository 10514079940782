import React, { useState, useEffect } from 'react';
import LayoutAdmin from '../../components/Layout/LayoutAdmin';
import { useHistory, Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Loading from '../../components/shared/Loading/Loading';
import { FormGroup, Input } from '../../components/shared/Form';
import SVGIcon from '../../components/shared/SVGIcon/SVGIcon';
import SearchIcon from '../../assets/images/icons/search.svg';
import Edit from '../../assets/images/icons/edit.svg';
import Trash from '../../assets/images/icons/trash.svg';
import Filter from '../../assets/images/icons/filter-icon.svg';
import ImageSearch from '../../assets/images/image-search.png';
import Modal from '../../components/shared/Modal/Modal';
import { fetchCompaniesList, removeCompany, setAvatarURL } from '../../redux/slices/companiesSlice';
import debounce from 'lodash/debounce';

const AdminCompany = ({ props, isAdmin }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, permissions } = useSelector((state) => state.user.user);
  const [isMobile, setIsMobile] = useState(false);
  const [isOpenDel, setOpenDel] = useState(false);
  const [isDelConfirm, setDelConfirm] = useState(false);
  const [inputVal, setInputVal] = useState('');
  const [isError, setError] = useState(true);
  const [isTextError, setTextError] = useState(false);
  const [isActiveItem, setActiveItem] = useState('');
  const { companies, companyLoading } = useSelector((state) => state.companies);
  const [filters, setFilters] = useState({
    sort: 'name',
    order: 'asc',
    q: '',
  });
  const [targetCompany, setTargetCompany] = useState(null);
  const [isMobFilter, setMobFilter] = useState(false);

  const mobileView = 999;

  const handleResize = () => {
    mobileView >= window.innerWidth ? setIsMobile(true) : setIsMobile(false);
  };

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (inputVal === 'DELETE') {
      setError(false);
      setTextError(false);
    } else {
      setError(true);
    }
  }, [inputVal]);

  const handleBlur = () => {
    if (isError) {
      setTextError(true);
    }
  };

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchCompaniesList({ ...filters }));
      await dispatch(setAvatarURL(''));
    }

    if (id) {
      fetch();
    }
    // eslint-disable-next-line
  }, [id, filters]);

  const onChangeFilters = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const setSearch = async (e) => {
    const { value } = e.target;
    setFilters({
      ...filters,
      q: value,
    });
    return debounce(async () => {
      fetchCompaniesList({ ...filters });
    }, 500)();
  };

  if (!!id && !permissions?.ap_view_company_list) return <Redirect to="/admin" />;

  return (
    <LayoutAdmin isAdmin={isAdmin}>
      {!isMobile && (
        <div className="admin__title">
          <h3>Companies</h3>
        </div>
      )}

      <div className="admin-filters">
        <div className="admin-filters__select">
          {isMobile && (
            <button
              disabled={!permissions?.ap_add_company}
              onClick={() => setMobFilter(true)}
            >
              <SVGIcon icon={Filter} color="#373737" />
            </button>
          )}
          <div className="form__search-box">
            <SVGIcon icon={SearchIcon} color="#999" />
            <Input
              value={filters.q || ""}
              onChange={(e) => setSearch(e)}
              placeholder="Search"
            />
          </div>
        </div>
        {!isMobile && (
          <Button
            disabled={!permissions?.ap_add_company}
            variant="contained"
            size="small"
            color="primary"
            onClick={() => history.push("/admin/companies/add-info")}
          >
            Add company
          </Button>
        )}
      </div>
      {isMobile && (
        <div className="admin__title">
          <h3>Companies</h3>
          <Button
            disabled={!permissions?.ap_add_company}
            variant="contained"
            size="small"
            color="primary"
            onClick={() => history.push("/admin/companies/add-info")}
          >
            Add company
          </Button>
        </div>
      )}
      <div className="company">
        <div className="company__table">
          <ul className="company-column__name">
            <button
              disabled={isMobile}
              onClick={() => {
                setFilters({
                  ...filters,
                  sort: "name",
                  order: filters.order === "asc" ? "desc" : "asc",
                });
              }}
              className="column__name titles titles-button"
            >
              Name{" "}
              <span
                className={`title-arrow ${
                  filters.sort === "name" && filters.order === "desc"
                    ? "title-arrow--rotate"
                    : ""
                }`}
              />
            </button>
            {!companyLoading && (
              <>
                {companies &&
                  companies.map((item, i) => (
                    <li
                      key={item.id}
                      style={{ animationDelay: 0.3 + i * 0.1 + "s" }}
                      className={`column__name ${
                        i === isActiveItem ? "hover" : ""
                      }`}
                      onMouseEnter={() => setActiveItem(i)}
                      onMouseLeave={() => setActiveItem("")}
                    >
                      <Link to={`/admin/company/${item.id}/details`}>
                        <div className="column__name-logo">
                          <img src={item.image} alt={item.name} />
                        </div>
                        {item.name}
                      </Link>
                    </li>
                  ))}
              </>
            )}
          </ul>
          <ul className="company-column__other">
            <li className="column__other titles">
              <button
                disabled={isMobile}
                onClick={() => {
                  setFilters({
                    ...filters,
                    sort: "created_at",
                    order: filters.order === "asc" ? "desc" : "asc",
                  });
                }}
                className="column__other--date titles-button"
              >
                Date added{" "}
                <span
                  className={`title-arrow ${
                    filters.sort === "created_at" && filters.order === "desc"
                      ? "title-arrow--rotate"
                      : ""
                  }`}
                />
              </button>
              <div className="column__other--owner titles">
                Owner/SuperAdmin
              </div>
              <button
                disabled={isMobile}
                onClick={() => {
                  setFilters({
                    ...filters,
                    sort: "status",
                    order: filters.order === "asc" ? "desc" : "asc",
                  });
                }}
                className="column__other--status titles-button"
              >
                Status{" "}
                <span
                  className={`title-arrow ${
                    filters.sort === "status" && filters.order === "desc"
                      ? "title-arrow--rotate"
                      : ""
                  }`}
                />
              </button>
              <div className="column__other--control titles" />
            </li>
            {!companyLoading && (
              <>
                {companies &&
                  companies.map((item, i) => (
                    <li
                      key={item.id}
                      style={{ animationDelay: 0.3 + i * 0.1 + "s" }}
                      className={`column__other ${
                        i === isActiveItem ? "hover" : ""
                      }`}
                      onMouseEnter={() => setActiveItem(i)}
                      onMouseLeave={() => setActiveItem("")}
                    >
                      <div className="column__other--date">{item.date}</div>
                      <div className="column__other--owner">{item.owner}</div>
                      <div
                        className={`column__other--status ${
                          item.status === "Active" ? "status-blue" : ""
                        }`}
                      >
                        {item.status}
                      </div>
                      <div className="column__other--control">
                        <button
                          onClick={() =>
                            history.push(`/admin/company/${item.id}/details`)
                          }
                        >
                          <SVGIcon icon={Edit} color="#8c8c8c" />
                        </button>
                        <button
                          disabled={item.id === 1}
                          onClick={() => {
                            setOpenDel(true);
                            setTargetCompany({
                              id: item.id,
                              name: item.name,
                              image: item.image,
                            });
                          }}
                        >
                          <SVGIcon icon={Trash} color="#8c8c8c" />
                        </button>
                      </div>
                    </li>
                  ))}
              </>
            )}
          </ul>
        </div>
        {companyLoading && <Loading />}
        {companies && companies.length < 1 && (
          <div className="search">
            <div className="search__title">
              <h5 className="tablet-hidden">
                Showing results for:{" "}
                <span className="text-accent">{filters.q}</span>
              </h5>
            </div>
            <div className="search__empty">
              <div className="search__empty-image">
                <img src={ImageSearch} alt="No results found" />
              </div>
              <div className="search__empty-text">
                <h5>No results found</h5>
                <p>We can’t find any company matching your search</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        open={isOpenDel}
        small
        inline
        reverse
        removeButton="Yes"
        button="No"
        onClick={() => setOpenDel(false)}
        remove={() => {
          setOpenDel(false);
          setDelConfirm(true);
        }}
        close={() => setOpenDel(false)}
      >
        <div className="company__modal">
          <h3>Are you sure you want to delete this company?</h3>
          <p>
            Deleting the company will delete all the company's information and
            all information of administrators, planners, and users associated to
            this company.
          </p>
          <div className="company__modal-icon">
            <img
              src={targetCompany?.image}
              alt={targetCompany?.name ?? "Logo"}
            />
          </div>
          <h6>{targetCompany?.name}</h6>
        </div>
      </Modal>
      <Modal
        open={isDelConfirm}
        button="Delete"
        onClick={async () => {
          await dispatch(removeCompany(targetCompany?.id));
          await dispatch(fetchCompaniesList());
          setInputVal("");
          await setDelConfirm(false);
        }}
        close={() => setDelConfirm(false)}
        isDisabled={isError}
      >
        <div className="company__modal">
          <h3>Are you sure you want to delete this company?</h3>
          <p>
            {" "}
            Deleting the company will delete all the company's information and
            all information of administrators, planners, and users associated to
            this company. This cannot be undone after you type DELETE in the
            field below.
          </p>
          <div className="company__modal-icon">
            <img
              src={targetCompany?.image}
              alt={targetCompany?.name ?? "Logo"}
            />
          </div>
          <h6>{targetCompany?.name}</h6>
          <FormGroup name="confirm-text" labelText="Enter">
            <Input
              name="confirm-text"
              placeholder="Enter DELETE"
              type="text"
              onChange={(e) => {
                setInputVal(e.target.value);
              }}
              value={inputVal || ""}
              onBlur={handleBlur}
              hasError={isTextError}
            />
            {isTextError && (
              <span className="form__msg-error">
                Please check entered text!
              </span>
            )}
          </FormGroup>
        </div>
      </Modal>
      <Modal
        open={isMobFilter}
        button="Apply"
        onClick={() => setMobFilter(false)}
        close={() => setMobFilter(false)}
      >
        <div className="company__modal--filter">
          <h2>Filter</h2>
          <div className="filter-panel">
            <div className="filter-panel__tab">
              <p>
                Sort by:{" "}
                <span style={{ color: "black" }}>
                  {filters.sort === "name"
                    ? "Alphabet"
                    : filters.sort === "created_at"
                    ? "Date added"
                    : "Status"}
                </span>
              </p>
              <FormGroup name="sort" labelText="">
                <RadioGroup
                  name="sort"
                  value={filters.sort || ""}
                  onChange={(e) => onChangeFilters(e)}
                >
                  <FormControlLabel
                    value="created_at"
                    control={<Radio />}
                    label="Date added"
                  />
                  <FormControlLabel
                    value="name"
                    control={<Radio />}
                    label="Alphabet"
                  />
                  <FormControlLabel
                    value="status"
                    control={<Radio />}
                    label="Status"
                  />
                </RadioGroup>
              </FormGroup>
            </div>
            <div className="filter-panel__tab">
              <p>
                Order by:{" "}
                <span style={{ color: "black" }}>
                  {filters.order === "asc" ? "ASC" : "DESC"}
                </span>
              </p>
              <FormGroup name="order" labelText="">
                <RadioGroup
                  name="order"
                  value={filters.order || ""}
                  onChange={(e) => onChangeFilters(e)}
                >
                  <FormControlLabel
                    value="asc"
                    control={<Radio />}
                    label="ASC"
                  />
                  <FormControlLabel
                    value="desc"
                    control={<Radio />}
                    label="DESC"
                  />
                </RadioGroup>
              </FormGroup>
            </div>
          </div>
        </div>
      </Modal>
    </LayoutAdmin>
  );
};

export default AdminCompany;
