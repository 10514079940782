import React from 'react'
import PropTypes from 'prop-types'
import { color } from '../../utils/utils'
import TooltipCustom from '../../components/shared/Tooltip/Tooltip'

const PageTitle = ({ title, content, status, info, small, subtitle }) => {
  const style = {
    backgroundColor: `${color(status)}`,
  }
  const convertStatus = status === 0 ? status.toString() : status

  return (
    <div className={`page-title${small ? ' page-title--small' : ''}`}>
      <div className="page-title__top">
        <h3>{title}</h3>
        {info &&
          <TooltipCustom
            title={info}
          />
        }
        {convertStatus && <span className="page-title__status" style={style}>{status}%</span>}
      </div>
      {content}
      <div className="page-title__subtitle">{subtitle}</div>
    </div>
  )
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  status: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  info: PropTypes.node,
  small: PropTypes.bool,
  subtitle: PropTypes.string
};



export default PageTitle
