import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Form, Formik } from 'formik'
import { FormGroup, FormSelect } from '../shared/Form'
import { Button, TextareaAutosize } from '@mui/material'
import { BackButton } from '../shared/Buttons'
import { setMode } from '../../redux/slices/modeSlice'
import { TaskCreateSchema } from '../../utils/validation'
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const TaskEdit = ({ id, close, status, priorities, categories, data, mode, update, create }) => {
  const dispatch = useDispatch()
  const sunEditor = useRef();
  const updateTask = values => {
    update(id, values)
    close()
  }
  const createTask = values => {
    create(values)
    close()
  }

  return (
    <div className="drawer__holder">
      {mode === "edit" && (
        <BackButton
          className="drawer__back-button"
          title={data && data.category.name}
          handleClick={() => dispatch(setMode("view"))}
        />
      )}
      <Formik
        enableReinitialize
        initialValues={
          mode === "edit" && data
            ? {
                priority_id: data.priority.id,
                status_id: data.status.id,
                category_id: data.category.id,
                name: data.name,
                description: data.description,
              }
            : {}
        }
        validationSchema={TaskCreateSchema}
        onSubmit={mode === "edit" ? updateTask : createTask}
      >
        {({ errors, handleChange, handleBlur, values, setFieldValue }) => (
          <Form>
            <div
              className={`drawer__title${
                mode === "create" ? " drawer__title--alt" : ""
              }`}
            >
              {mode === "edit" ? "Update my task" : "Add task"}
            </div>
            <FormGroup name="priority_id" labelText="Priority">
              <FormSelect
                options={priorities}
                value={values.priority_id || ""}
                name="priority_id"
                onChange={handleChange}
                placeholder="Select"
                hasError={!!errors.priority_id}
              />
              {errors.priority_id && (
                <span className="form__msg-error">{errors.priority_id}</span>
              )}
            </FormGroup>
            <FormGroup name="task_status_id" labelText="Status">
              <FormSelect
                options={status}
                value={values.status_id || ""}
                name="status_id"
                onChange={handleChange}
                placeholder="Select"
                hasError={!!errors.status_id}
              />
              {errors.status_id && (
                <span className="form__msg-error">{errors.status_id}</span>
              )}
            </FormGroup>
            <FormGroup name="category_id" labelText="Category">
              <FormSelect
                options={categories}
                value={values.category_id || ""}
                name="category_id"
                onChange={handleChange}
                placeholder="Select"
                hasError={!!errors.category_id}
              />
              {errors.category_id && (
                <span className="form__msg-error">{errors.category_id}</span>
              )}
            </FormGroup>
            <FormGroup name="name" labelText="Task title">
              <TextareaAutosize
                name="name"
                value={values.name || ""}
                className={`form__textarea form__textarea--alt form__textarea--resize ${
                  !!errors.name && "form__textarea--error"
                }`}
                placeholder="Type title"
                onChange={handleChange}
              />
              {errors.name && (
                <span className="form__msg-error">{errors.name}</span>
              )}
            </FormGroup>
            <FormGroup name="description" labelText="Description">
              <SunEditor
                ref={sunEditor}
                onChange={(content) => {
                  let val = sunEditor.current.editor.getText();
                  setFieldValue("description", val ? content : val);
                }}
                hasError={true}
                setContents={values.description}
                placeholder="Please type here..."
                setOptions={{
                  height: "200px",
                  stickyToolbar: false,
                  font: ["Arial", "Source Sans Pro", "Sora"],
                  buttonList: [
                    ["font", "fontSize", "formatBlock"], ["removeFormat"],
                  ],
                }}
              />
              {errors.description && (
                <span className="form__msg-error">{errors.description}</span>
              )}
            </FormGroup>
            <div className="form__button drawer__buttons">
              <Button
                variant="contained"
                size="large"
                color="primary"
                type="submit"
              >
                {mode === "edit" ? "Save" : "Create task"}
              </Button>
              <Button
                size="large"
                className="MuiButton-textGray"
                onClick={close}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

TaskEdit.propTypes = {
  id: PropTypes.number,
  close: PropTypes.func,
  update: PropTypes.func,
  create: PropTypes.func,
  status: PropTypes.array,
  priorities: PropTypes.array,
  categories: PropTypes.array,
  mode: PropTypes.string,
  data: PropTypes.object
}

export default TaskEdit
