import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { pdf } from '@react-pdf/renderer'
import { saveAs } from 'file-saver'
import { Button } from '@mui/material'
import { Export } from '../../api/Api'
import PDFDocument from './PDFDocument'
import PDFDocumentDetails from './PDFDocumentDetails'
import colors from '../../Data/colors'
import { Chart } from 'react-chartjs-2'
import Logo from "../../assets/images/logo-print.png";


export const PDFDownload = ({ title, data, className }) => {
  const popup = useRef()
  const chart = useRef()
  const { first_name, company_id, company_logo } = useSelector((state) => state.user.user);
  const [isOpen, setIsOpen] = useState(false)
  const [loader, setLoader] = useState(false)

  const onToggle = () => setIsOpen(!isOpen)

  useEffect(() => {
    const listener = event => {
      if (!popup.current || popup.current.contains(event.target)) {
        return;
      }

      setIsOpen(false);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [popup]);


  const exportSummaryData = async () => {
    await setIsOpen(false)
    await setLoader(true)
    const response = await Export.getSummary();
    // const response = {data: null}
    const percent = await Export.getPercent();

    const doughnutChart = new Chart(chart.current,{
      duration: 0,
      type: 'doughnut',
      options: {
        animation: {
          duration: 0
        },
        plugins: {
          datalabels: {
            display: false
          }
        },
        hover: {
          animationDuration: 0
        },
        responsiveAnimationDuration: 0
      },
      data: {
        datasets: [{
          borderWidth: 0,
          data: percent.data.map(item => item.percent),
          backgroundColor: [...colors]
        }]
      }
    });
    const imageChart = await doughnutChart.toBase64Image()
    const doc = await <PDFDocument data={response.data} logo={company_id === 1 ? Logo : company_logo} name={first_name} percent={percent.data} image={imageChart} fileName="somename.pdf" />;
    const asPdf = await pdf([], 'application/pdf');
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    const fileURL = URL.createObjectURL(blob);
    await setLoader(false)
    window.open(fileURL,"_blank");
    saveAs(blob, 'report.pdf');
  }

  const exportDetailsData = async () => {
    await setIsOpen(false)
    await setLoader(true)
    const response = await Export.getDetails();
    const percent = await Export.getPercent();
  
    const doughnutChart = new Chart(chart.current,{
      duration: 0,
      type: 'doughnut',
      options: {
        animation: {
          duration: 0
        },
        plugins: {
          datalabels: {
            display: false
          }
        },
        hover: {
          animationDuration: 0
        },
        responsiveAnimationDuration: 0,
      },
      data: {
        datasets: [{
          borderWidth: 0,
          data: percent.data.map(item => item.percent),
          backgroundColor: [...colors]
        }]
      }
    });
    const imageChart = await doughnutChart.toBase64Image()
    const doc = await <PDFDocumentDetails data={response.data.data} logo={company_id === 1 ? Logo : company_logo} name={first_name} percent={percent.data} image={imageChart}  />;
    const asPdf = await pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    const fileURL = URL.createObjectURL(blob);
    await setLoader(false)
    window.open(fileURL,"_blank");
    saveAs(blob, 'reportDetails.pdf');
  }


  return (
    <div className={`pdf-download ${className ? className : ''}`}>
      <Button variant="outlined"
              color="primary"
              size='small'
              onClick={onToggle}
              disabled={loader}
      >
        {loader && <span className="spinner" />}
        {title}
      </Button>
      <div ref={popup} className={`pdf-download__drop${isOpen ? ' pdf-download__drop--open' : ''}`}>
        <ul>
          <li>
            <button onClick={exportSummaryData}>Your alert & task summary</button>
          </li>
          <li>
            <button onClick={exportDetailsData}>Your alert & task details</button>
          </li>
        </ul>
      </div>

      <div style={{position: 'absolute', left: '-9999px'}}>
        <canvas ref={chart} width={800} height={800}  />
      </div>
    </div>
  )
}
