import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import Layout from '../../components/Layout/Layout'
import { FormGroup, Input, AddressAutocomplete } from '../../components/shared/Form'
import Tabs, { TabPanel } from '../../components/shared/Tabs/Tabs'
import Loading from '../../components/shared/Loading/Loading'
import { Button, FormControlLabel, Radio, RadioGroup, Grid } from '@mui/material'
import { createNewPassword, setAuthMessage, logout } from '../../redux/slices/authSlice'
import {
  uploadAvatar,
  fetchUserInfo,
  postProfileData,
  postPlanTypeUser,
  setUserAvatar
} from '../../redux/slices/userSlice'
import {
  fetchTotalPercent
} from "../../redux/slices/percentagesSlice";
import UploadPhoto from '../../components/shared/UploadPhoto/UploadPhoto'
import { useQuery } from "../../utils/hooks";
import { ProfileSchema, ResetPasswordSchema } from '../../utils/validation'
import Modal from '../../components/shared/Modal/Modal'
import SVGIcon from '../../components/shared/SVGIcon/SVGIcon'
import Icon from '../../assets/images/icons/icon-ongratulations.svg'
import SplashPlanItem from '../../components/Splash/SplashPlanItem'
import TaskPlanProfile from "../../components/Task/TaskPlanProfile";
import isArray from 'lodash/isArray'
import { phoneValidation } from '../../utils/utils'

const Profile = ({ props }) => {
  const dispatch = useDispatch()
  const query = useQuery()
  const [isOpen, setIsOpen] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [isOpenSaveModal, setIsOpenSaveModal] = useState(false)
  const plan_types = useSelector(state => state.options.plan_types)
  const plan_type = useSelector(state => state.user.user.plan_type)
  const loading = useSelector(state => state.loading.isLoading)
  const sending = useSelector(state => state.sending.isSending)
  const { authMessage } = useSelector(state => state.auth)
  const { id, first_name, last_name, year_of_birth, phone, address, email, gender } = useSelector(state => state.user.user)
  const { avatar } = useSelector(state => state.user.user)
  const uploadingAvatar = useSelector(state => state.user.uploadingAvatar)
  const isPlanTab = query.get('tab') === 'plans';
  const [planType, setPlanType] = useState(plan_type)
  const [planVal, setPlanVal] = useState(null)

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchUserInfo(id));
    }

    if (id) {
      fetch()
    }

    // eslint-disable-next-line
  }, [id])

  const [value, setValue] = useState(isPlanTab ? 2 : 0);

  const handleValue = (event, newValue) => {
    dispatch(setAuthMessage({ authMessage: false }));
    setValue(newValue);
  }

  const handleClick = value => {
    setPlanVal(value)
    return setPlanType(value);
  }

  const onSubmit = async (planVal) => {
    await dispatch(postPlanTypeUser({ plan_type: planVal }));
    setPlanType(planVal);
    setOpenModal(false);
    await dispatch(fetchTotalPercent(planVal === 1));
  }

  if (loading) return <Loading/>

  return (
    <Layout profile isDisabled={uploadingAvatar}>
      <div className="profile">
        <UploadPhoto
          className="profile__avatar"
          title={first_name}
          file={avatar}
          isUploading={uploadingAvatar}
          handleUpload={(data, image) => {
            dispatch(uploadAvatar(data))
            dispatch(setUserAvatar(image))
          }}
        />
        <div className="profile__title">{first_name} {last_name}</div>
        <span className="profile__email">{email}</span>
      </div>

      <div className="profile__tabs">
        <Tabs
          center
          handleChange={handleValue}
          value={value}
          tabs={[
            { title: 'Personal info' },
            { title: 'Password' },
            { title: 'Plans' }
          ]}
        />
        <TabPanel value={value} index={0}>
          <Formik
            enableReinitialize
            initialValues={{
              first_name: first_name || '',
              last_name: last_name || '',
              year_of_birth: year_of_birth || '',
              address: address || '',
              gender: gender || '',
              phone: phone || '',
              email: email || ''
            }}
            validationSchema={ProfileSchema}
            onSubmit={values => {
              dispatch(postProfileData(values))
              setIsOpenSaveModal(true)
            }}
          >
            {({ errors, handleChange, handleBlur, values, touched, setFieldValue }) => (
              <Form>
                <div className="layout-main__form">
                  <FormGroup name="first_name" labelText="First name">
                    <Input
                      name="first_name"
                      placeholder="First name"
                      type="text"
                      onChange={handleChange}
                      value={values.first_name}
                      onBlur={handleBlur}
                      hasError={!!errors.first_name && touched.first_name}
                    />
                    {touched.first_name && errors.first_name &&
                    <span className="form__msg-error">{errors.first_name}</span>}
                  </FormGroup>
                  <FormGroup name="last_name" labelText="Last name">
                    <Input
                      name="last_name"
                      placeholder="Last name"
                      type="text"
                      onChange={handleChange}
                      value={values.last_name}
                      onBlur={handleBlur}
                      hasError={!!errors.last_name && touched.last_name}
                    />
                    {touched.last_name && errors.last_name &&
                    <span className="form__msg-error">{errors.last_name}</span>}
                  </FormGroup>
                  <FormGroup name="gender" labelText="Gender">
                    <RadioGroup
                      name="gender"
                      value={values.gender || null}
                      onChange={handleChange}
                      className="radio-list-inline"
                    >
                      <FormControlLabel value="male" control={<Radio/>} label="Male"/>
                      <FormControlLabel value="female" control={<Radio/>} label="Female"/>
                    </RadioGroup>
                    {touched.gender && errors.gender && <span className="form__msg-error">{errors.gender}</span>}
                  </FormGroup>
                  <FormGroup name="year_of_birth" labelText="Year of birth">
                    <Input
                      name="year_of_birth"
                      placeholder="Year of birth"
                      type="number"
                      onChange={handleChange}
                      value={values.year_of_birth}
                      onBlur={handleBlur}
                      hasError={!!errors.year_of_birth && touched.year_of_birth}
                    />
                    {touched.year_of_birth && errors.year_of_birth &&
                    <span className="form__msg-error">{errors.year_of_birth}</span>}
                  </FormGroup>
                  <FormGroup name="phone" labelText="Phone number">
                  <Input
                    name="phone"
                    placeholder="(XXX) XXX-XXXX"
                    type="text"
                    onChange={(e) => {
                      setFieldValue(e.target.name, phoneValidation(e.target.value))
                    }}
                    value={values.phone || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.phone && touched.phone}
                  />
                  {touched.phone && errors.phone && <span className="form__msg-error">
                    {isArray(errors.phone)
                      ? errors.phone.map(i => i)
                      : errors.phone
                    }
                  </span>}
                </FormGroup>
                <FormGroup name="email" labelText="Email address">
                  <Input
                    placeholder="example@email.com"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    value={values.email || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.email && touched.email}
                  />
                  {touched.email && errors.email && <span className="form__msg-error">
                    {isArray(errors.phone)
                      ? errors.email.map(i => i)
                      : errors.email
                    }
                  </span>}
                </FormGroup>
                  <FormGroup name="address" labelText="Address">
                    <AddressAutocomplete
                      placeholder="Type your address"
                      name="address"
                      setFieldValue={setFieldValue}
                      initialValue={values.address || ''}
                      onBlur={handleBlur}
                    />
                  </FormGroup>

                  <div className="form__button form__button--center">
                    <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                      Save info
                      {sending && <span className="spinner"/>}
                    </Button>
                    <Button variant="outlined" size="large" onClick={() => dispatch(logout(props))}>
                      Log out
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Formik
            enableReinitialize
            initialValues={{
              old_password: '',
              password: '',
              password_confirmation: ''
            }}
            validationSchema={ResetPasswordSchema}
            onSubmit={
              (values, { resetForm }) => {
                dispatch(createNewPassword(values))
                setIsOpen(true)
                resetForm({ values: '' })
              }
            }
          >
            {({ errors, handleChange, handleBlur, values, touched }) => (
              <Form>
                <div className="layout-main__form">
                  <FormGroup name="old_password" labelText="Your old password">
                    <Input
                      name="old_password"
                      placeholder="Old password"
                      type="password"
                      onChange={handleChange}
                      value={values.old_password || ''}
                      onBlur={handleBlur}
                      hasError={!!errors.old_password && touched.old_password}
                    />
                    {touched.old_password && errors.old_password &&
                    <span className="form__msg-error">{errors.old_password}</span>}
                  </FormGroup>
                  <FormGroup name="password" labelText="Create new password">
                    <Input
                      name="password"
                      placeholder="New password"
                      type="password"
                      onChange={handleChange}
                      value={values.password || ''}
                      onBlur={handleBlur}
                      hasError={!!errors.password && touched.password}
                    />
                    {touched.password && errors.password && <span className="form__msg-error">{errors.password}</span>}
                  </FormGroup>
                  <FormGroup name="password_confirmation" labelText="Repeat new password">
                    <Input
                      name="password_confirmation"
                      placeholder="Repeat password"
                      type="password"
                      onChange={handleChange}
                      value={values.password_confirmation || ''}
                      onBlur={handleBlur}
                      hasError={!!errors.password_confirmation && touched.password_confirmation}
                    />
                    {touched.password_confirmation && errors.password_confirmation &&
                    <span className="form__msg-error">{errors.password_confirmation}</span>}
                  </FormGroup>
                  <div className="form__button form__button--center">
                    <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                      Password reset
                      {sending && <span className="spinner"/>}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div className="splash__content">
            <h1 className="profile__h1">Choose your plans</h1>
            <p className="text-muted profile__description fs__16">
              You have options on how you'd like to use and interact with ValueSoft. The "Input plan" allows you to
              make a tradeoff between your input burden and the comprehensiveness of your financial plan. The "Task
              plan" allows you to set the pace in which you'd like to accomplish your action items.
            </p>
            <h5 className="profile__subtitle">Input plan</h5>
            <p className="text-muted profile__caption">
              A basic plan will focus on the most important aspects of your financial health and will
              take half the time to fill out compared to a comprehensive plan. That said,
              a comprehensive plan is where you'll learn the most and fully capture the value of ValueSoft.
            </p>
            <Grid container spacing={2}>
              {plan_types && plan_types.map((item, i) => (
                <Grid item xs={12} sm={6} md={5} key={`option-${i}`}>
                  <SplashPlanItem
                    icon={item.icon}
                    title={item.label}
                    id={item.id}
                    selected={planType && planType === item.id}
                    handleClick={handleClick}
                  />
                </Grid>
              ))}
            </Grid>
            <div className="mt--medium">
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={() => setOpenModal(true)}
                disabled={sending || (planType === plan_type)}>
                Save changes
                {sending && <span className="spinner"/>}
              </Button>
            </div>
          </div>
          <TaskPlanProfile />
        </TabPanel>
      </div>
      <Modal
        open={isOpen}
        small
        close={() => {
          setIsOpen(false)
          dispatch(setAuthMessage({ authMessage: false }))
        }}
      >
        {authMessage && <>
          {(authMessage === 'Invalid old password')
            ? <h4>You entered invalid old password</h4>
            : <div>
              <div className="modal__title-icon">
                <SVGIcon icon={Icon}/>
              </div>
              <h4>You have successfully updated the password</h4>
            </div>
          }
        </>}
      </Modal>
      <Modal
        open={isOpenSaveModal}
        small
        button="Ok"
        close={() => setIsOpenSaveModal(false)}
        onClick={() => setIsOpenSaveModal(false)}
      >
        <div>
          <div className="modal__title-icon">
            <SVGIcon icon={Icon}/>
          </div>
          <h4>You have successfully updated your profile.</h4>
        </div>
      </Modal>
      <Modal
        open={openModal}
        small
        close={() => {
          setOpenModal(false)
          setPlanType(plan_type)
        }
        }
        button={planType !== 1 ? "Yes" : "Switch"}
        closeButton={planType !== 1 ? "No" : "Cancel"}
        onClick={() => onSubmit(planVal)}
        title={planType !== 1 ? "You selected the Comprehensive plan." : "Are you sure you want to switch from a Comprehensive to a Basic plan?"}
      >
        {planType !== 1 ?
          <p>A comprehensive plan will require additional input, but will provide you a more detailed financial plan in
            the form of additional action items. You can always revert back to a Basic plan in your personal settings.
            Are you sure you want to proceed?</p> :
          <p>Switching to the Basic plan will delete all input data and tasks associated to your Comprehensive
            plan.</p>}
      </Modal>
    </Layout>
  )
}

export default Profile
