import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isSending: false
}

const sendingSlice = createSlice({
  name: 'sending',
  initialState,
  reducers: {
    setSending (state, action) {
      state.isSending = action.payload.isSending
    }
  }
})

export const { setSending } = sendingSlice.actions
export default sendingSlice.reducer
