import React, { useState, useEffect, useRef } from 'react'
import { useLocation, Redirect } from 'react-router-dom'
import { Button } from '@mui/material'
import Verification from '../../components/Auth/Verification'
import { useDispatch, useSelector } from 'react-redux'
import { emailConfirm, phoneConfirm, codeConfirm } from '../../redux/slices/authSlice'
import { Input } from '../../components/shared/Form'


const VerificationCode = ({ props }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { email, phone, auth_token } = useSelector(state => state.auth.registerUser);
  const { isLoggedIn, authError } = useSelector(state => state.auth);
  const [verifyCode, setVerifyCode] = useState('');
  const [seconds, setSeconds] = useState(localStorage.getItem('verify_time') ?? 0);
  const [done, setDone] = useState(false);
  const foo = useRef();

  const query = new URLSearchParams(location.search);
  const verifyType = query.get('verify_type');

  const tick = () => (setSeconds(prevSeconds => prevSeconds - 1))
  const timeReseter = () => (setInterval(tick, 1000))

  useEffect(() => {
    if(seconds > 0) {
      tick()
      foo.current = timeReseter()
    }
    return () => clearInterval(foo.current);
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    localStorage.setItem('verify_time', seconds)
    if (seconds  <= 0) {
      clearInterval(foo.current);
      localStorage.removeItem('verify_time')
      setDone(true);
    }
  }, [seconds])

  const onResend = () => {

    if(verifyType === 'email') {
      dispatch(emailConfirm({
        email: email
      }))
    }

    if(verifyType === 'phone') {
      dispatch(phoneConfirm({
        phone: phone
      }))
    }

    localStorage.setItem('verify_time', 60)
    setSeconds(localStorage.getItem('verify_time'))
    setDone(false);
    tick()
    foo.current = timeReseter()
  }

  const onSubmit = () => {
    dispatch(codeConfirm({
      code: verifyCode,
      auth_token: auth_token
    }, props))
  };

  const validateCode = (e) => {
    let value = e.target.value
    const onlyNumb = /^[a-zA-Z]+$/;
    let phoneNumber = value.replace(/[a-zA-Z_+.,/]/g, "")
    if (onlyNumb.test(phoneNumber)) {
      setVerifyCode(phoneNumber.replace(onlyNumb, "").slice(0, 4))
    } else {
      setVerifyCode(phoneNumber.slice(0, 4))
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  const obscureEmail = (email) => {
    const [name, domain] = email.split('@');
    return `${name[0]}${new Array(name.length - 1).join('*')}${name[name.length - 1]}@${domain}`;
  };

  const obscurePhone = (phone) => {
    return `${phone.slice(0, 5)} ***-**${phone.slice(phone.length - 2, phone.length)}`;
  };

  if (!isLoggedIn) return (<Redirect to="/login" />)

  return (
    <Verification title={verifyType === 'email' ? 'Verify via Email' : 'Verify via Phone Number'}>
      <div className="verification__content--description">
        {verifyType === 'email' ? (
          <p>
            Enter the verification code we've sent to you at <br />
            {obscureEmail(email)} to confirm your email address.
          </p>
        ) : (
          <p>
            Enter the verification code we've sent to you at <br />
            {obscurePhone(phone)} to confirm your phone number.
          </p>
        )}
        <Input
          type="number"
          name="verify_code"
          value={verifyCode}
          placeholder="Verification code"
          onChange={validateCode}
          onKeyPress={handleKeyDown}
        />
        {authError?.code && <span className="form__msg-error">{authError?.code[0]}</span>}
        <div className="resend">
          Didn’t receive the code?{' '}
          <Button size="small" onClick={onResend} disabled={!done} variant="text">
            {done ? 'Resend' : `Resend in ${seconds}s`}
          </Button>
        </div>
      </div>

      <div className="verification__content--buttons">
        <Button variant="contained" size="large" color="primary" onClick={onSubmit}>
          Verify
        </Button>
      </div>
    </Verification>
  );
};

export default VerificationCode;
