import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import LayoutAdmin from '../../components/Layout/LayoutAdmin';
import AdminCalcNav from '../../Data/adminCalcNav';
import Modal from '../../components/shared/Modal/Modal';
import SVGIcon from '../../components/shared/SVGIcon/SVGIcon';
import More from '../../assets/images/icons/more.svg';
import {
  MortgageAdmin,
  IncomeExpensesNetWorthAdmin,
  OwnVsRentAdmin,
  CarLeaseVsLeaseEndAdmin,
  CarLeasePaymentAdmin,
  LifeInsuranceAdmin,
  TimeToBuyHomeAdmin,
  HomeAffordabilityAdmin,
  HomeEvaluationAdmin,
  ValueCircleAdmin,
  InvestmentFeesAdmin,
  UmbrellaInsuranceAdmin,
  HomeRefinancingAdmin,
  HealthcareCoverageAdmin,
} from '../../components/CalculatorsSetup';
import {
  fetchMortgageAdmin,
  updateMortgageAdmin,
  fetchIncomeAndExpensesAdmin,
  updateIncomeAndExpensesAdmin,
  fetchOwnVsRentAdmin,
  updateOwnVsRentAdmin,
  fetchCarLeaseVsLeaseEndAdmin,
  updateCarLeaseVsLeaseEndAdmin,
  fetchCarLeasePaymentAdmin,
  updateCarLeasePaymentAdmin,
  fetchLifeInsuranceAdmin,
  updateLifeInsuranceAdmin,
  fetchTimeToBuyHomeAdmin,
  updateTimeToBuyHomeAdmin,
  fetchHomeAffordabilityAdmin,
  updateHomeAffordabilityAdmin,
  fetchHomeEvaluationAdmin,
  updateHomeEvaluationAdmin,
  fetchValueCircleAdmin,
  updateValueCircleAdmin,
  fetchInvestmentFeesAdmin,
  updateInvestmentFeesAdmin,
  fetchUmbrellaInsuranceAdmin,
  updateUmbrellaInsuranceAdmin,
  fetchHomeRefinancingAdmin,
  updateHomeRefinancingAdmin,
  fetchHealthcareCoverageAdmin,
  updateHealthcareCoverageAdmin,
} from '../../redux/slices/calculatorsSetupSlice';
import { setIsOpen } from '../../redux/slices/modalSlice';
import { setMode } from '../../redux/slices/modeSlice';
import { fetchCompany } from '../../redux/slices/companiesSlice';
import Tabs, { TabPanel } from '../../components/shared/Tabs/Tabs';
import { isNull } from 'lodash';

const AdminCalculators = ({ props, isAdmin }) => {
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.user.user);
  const sending = useSelector((state) => state.sending.isSending);
  const { permissions, image, name, email } = useSelector((state) => state.companies.company.info);
  const isOpen = useSelector((state) => state.modal.isOpen);
  const mode = useSelector((state) => state.mode.mode);
  const {
    mortgageAdmin,
    incomeAndExpensesAdmin,
    ownVsRentAdmin,
    carLeaseVsLeaseEndAdmin,
    carLeasePaymentAdmin,
    lifeInsuranceAdmin,
    timeToBuyHomeAdmin,
    homeAffordabilityAdmin,
    homeEvaluationAdmin,
    valueCircleAdmin,
    investmentFeesAdmin,
    umbrellaInsuranceAdmin,
    homeRefinancingAdmin,
    healthcareCoverageAdmin,
  } = useSelector((state) => state.calculatorsSetup);
  const [isModal, setModal] = useState(false);
  const { companyId } = useParams();
  const [isMobile, setIsMobile] = useState(false);

  const mobileView = 999;

  const handleResize = () => {
    mobileView >= window.innerWidth ? setIsMobile(true) : setIsMobile(false);
  };

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function fetch() {
      Promise.all([
        dispatch(fetchIncomeAndExpensesAdmin(companyId)),
        dispatch(fetchMortgageAdmin(companyId)),
        dispatch(fetchCarLeasePaymentAdmin(companyId)),
        dispatch(fetchCarLeaseVsLeaseEndAdmin(companyId)),
        dispatch(fetchTimeToBuyHomeAdmin(companyId)),
        dispatch(fetchOwnVsRentAdmin(companyId)),
        dispatch(fetchValueCircleAdmin(companyId)),
        dispatch(fetchUmbrellaInsuranceAdmin(companyId)),
        dispatch(fetchHomeRefinancingAdmin(companyId)),
        dispatch(fetchHomeEvaluationAdmin(companyId)),
        dispatch(fetchHomeAffordabilityAdmin(companyId)),
        dispatch(fetchHealthcareCoverageAdmin(companyId)),
        dispatch(fetchInvestmentFeesAdmin(companyId)),
        dispatch(fetchLifeInsuranceAdmin(companyId)),
      ]);
    }
    if (id) {
      fetch();
    }
    // eslint-disable-next-line
  }, [id]);

  const [value, setValue] = useState(0);

  useEffect(() => {
    dispatch(fetchCompany(companyId));
    // eslint-disable-next-line
  }, []);

  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }));
    dispatch(setMode(null));
  };

  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }));
    dispatch(setMode(null));
  };

  const handleValue = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [value])

  if (!isNull(permissions?.calculators) && !permissions?.calculators) return <Redirect to="/404" />;

  return (
    <LayoutAdmin companyId={companyId} isAdmin={isAdmin} admin>
      <div className="admin-tasks">
        <div className="admin__side">
          <div className="admin__side-sticky">
            <Tabs orientation="vertical" handleChange={handleValue} value={value} tabs={AdminCalcNav.map((item) => ({ title: item.title }))} />
          </div>
        </div>
        <div className="admin__content">
          <div className="member-comapny member-comapny--top">
            <div className="member-comapny__info">
              <div className="member-comapny__logo">
                <img src={image} alt="Company logo" />
              </div>
              <div className="member-comapny__title">
                <h3>{name}</h3>
                {!isMobile && (
                  <p>
                    Feedback Email: <span>{email}</span>
                  </p>
                )}
              </div>
              <button className="more-menu" onClick={() => setModal(true)}>
                <SVGIcon icon={More} color="#8C8C8C" />
              </button>
            </div>
            {isMobile && (
              <div className="mobile-feedback">
                Feedback Email: <span>{email}</span>
              </div>
            )}
          </div>
          <TabPanel value={value} index={0} className="admin__content-section">
            <div className="admin__content-holder">
              <IncomeExpensesNetWorthAdmin
                title="Impact of Income and Expenses on Net Worth calculator"
                values={incomeAndExpensesAdmin}
                onSave={(data) => dispatch(updateIncomeAndExpensesAdmin(companyId, incomeAndExpensesAdmin?.id, data))}
                sending={sending}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={1} className="admin__content-section">
            <div className="admin__content-holder">
              <OwnVsRentAdmin
                title="Own vs Rent Home Calculator"
                values={ownVsRentAdmin}
                onSave={(data) => dispatch(updateOwnVsRentAdmin(companyId, ownVsRentAdmin?.id, data))}
                sending={sending}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={2} className="admin__content-section">
            <div className="admin__content-holder">
              <MortgageAdmin
                title="Mortgage calculator"
                values={mortgageAdmin}
                onSave={(data) => dispatch(updateMortgageAdmin(companyId, mortgageAdmin?.id, data))}
                sending={sending}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={3} className="admin__content-section">
            <div className="admin__content-holder">
              <CarLeaseVsLeaseEndAdmin
                title="New Car Lease vs Lease-End Purchase Calculator"
                values={carLeaseVsLeaseEndAdmin}
                onSave={(data) => dispatch(updateCarLeaseVsLeaseEndAdmin(companyId, carLeaseVsLeaseEndAdmin?.id, data))}
                sending={sending}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={4} className="admin__content-section">
            <div className="admin__content-holder">
              <CarLeasePaymentAdmin
                title="Car Lease Payment Calculator"
                values={carLeasePaymentAdmin}
                onSave={(data) => dispatch(updateCarLeasePaymentAdmin(companyId, carLeasePaymentAdmin?.id, data))}
                sending={sending}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={5} className="admin__content-section">
            <div className="admin__content-holder">
              <LifeInsuranceAdmin
                title="Life Insurance Calculator"
                values={lifeInsuranceAdmin}
                onSave={(data) => dispatch(updateLifeInsuranceAdmin(companyId, lifeInsuranceAdmin?.id, data))}
                sending={sending}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={6} className="admin__content-section">
            <div className="admin__content-holder">
              <TimeToBuyHomeAdmin
                title="Time to Buy Home Calculator"
                values={timeToBuyHomeAdmin}
                onSave={(data) => dispatch(updateTimeToBuyHomeAdmin(companyId, timeToBuyHomeAdmin?.id, data))}
                sending={sending}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={7} className="admin__content-section">
            <div className="admin__content-holder">
              <HomeAffordabilityAdmin
                title="Home Affordability Calculator"
                values={homeAffordabilityAdmin}
                onSave={(data) => dispatch(updateHomeAffordabilityAdmin(companyId, homeAffordabilityAdmin?.id, data))}
                sending={sending}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={8} className="admin__content-section">
            <div className="admin__content-holder">
              <HomeEvaluationAdmin
                title="Home Evaluation Calculator"
                values={homeEvaluationAdmin}
                onSave={(data) => dispatch(updateHomeEvaluationAdmin(companyId, homeEvaluationAdmin?.id, data))}
                sending={sending}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={9} className="admin__content-section">
            <div className="admin__content-holder">
              <ValueCircleAdmin
                title="Value Circle Calculator"
                values={valueCircleAdmin}
                onSave={(data) => dispatch(updateValueCircleAdmin(companyId, valueCircleAdmin?.id, data))}
                sending={sending}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={10} className="admin__content-section">
            <div className="admin__content-holder">
              <InvestmentFeesAdmin
                title="Investment Fees Calculator"
                values={investmentFeesAdmin}
                onSave={(data) => dispatch(updateInvestmentFeesAdmin(companyId, investmentFeesAdmin?.id, data))}
                sending={sending}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={11} className="admin__content-section">
            <div className="admin__content-holder">
              <UmbrellaInsuranceAdmin
                title="Umbrella Insurance Calculator"
                values={umbrellaInsuranceAdmin}
                onSave={(data) => dispatch(updateUmbrellaInsuranceAdmin(companyId, umbrellaInsuranceAdmin?.id, data))}
                sending={sending}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={12} className="admin__content-section">
            <div className="admin__content-holder">
              <HomeRefinancingAdmin
                title="Home Refinancing Calculator"
                values={homeRefinancingAdmin}
                onSave={(data) => dispatch(updateHomeRefinancingAdmin(companyId, homeRefinancingAdmin?.id, data))}
                sending={sending}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={13} className="admin__content-section">
            <div className="admin__content-holder">
              <HealthcareCoverageAdmin
                title="Healthcare Coverage Type Calculator"
                values={healthcareCoverageAdmin}
                onSave={(data) => dispatch(updateHealthcareCoverageAdmin(companyId, healthcareCoverageAdmin?.id, data))}
                sending={sending}
              />
            </div>
          </TabPanel>
        </div>
        <Modal
          open={isOpen}
          small
          button="Ok"
          onClick={handleClick}
          close={onClose}
          title={mode === 'success' ? 'Success!' : 'Error!'}
          children={
            mode === 'success' ? <p>The values were updated successfully.</p> : <p>Something went wrong. Try again.</p>
          }
        />
        <Modal
          open={isModal}
          small
          button="Apply"
          onClick={() => setModal(false)}
          close={() => setModal(false)}
          title="Calculators"
          children={
            <div className="tab__modal-wrap">
              <Tabs orientation="vertical" handleChange={handleValue} value={value} tabs={AdminCalcNav.map((item) => ({ title: item.title }))} />
            </div>
          }
        />
      </div>
    </LayoutAdmin>
  );
};

export default AdminCalculators;
