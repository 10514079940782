import { createSlice } from '@reduxjs/toolkit'
import { AdditionalInfo } from '../../api/Api'
import { setSending } from './sendingSlice'
import { setIsRelogin } from "./loadingSlice";

const infoSlice = createSlice({
  name: 'info',
  initialState: {
    info: ''
  },
  reducers: {
    setInfo: (state, action) => {
      state.info = action.payload;
    },
  }
})

export const fetchInfo = () => async (dispatch) => {
  try {
    const response = await AdditionalInfo.get();
    await dispatch(setInfo(response.data.data))
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchInfoByParams = params => async (dispatch) => {
  try {
    const response = await AdditionalInfo.show(params);
    await dispatch(setInfo(response.data.data))
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postInfo = data => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    const response = await AdditionalInfo.post(data);
    await dispatch(setInfo(response.data.data));
    await dispatch(setSending({ isSending: false }));
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}


export const { setInfo } = infoSlice.actions

export default infoSlice.reducer