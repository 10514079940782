import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import Layout from '../../components/Layout/Layout'
import {AddButton, BackButton} from '../../components/shared/Buttons'
import Tooltip from '../../components/shared/Tooltip/Tooltip'
import { Slider, Input, FormGroup, FormSelect } from '../../components/shared/Form'
import PercentIcon from '../../assets/images/icons/percentage.svg'
import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import ModalBox from '../../components/shared/Modal/Modal'
import {
  fetchCalculators,
  fetchListResults,
  fetchCurrentResult,
  saveItemResult,
  removeItemResult, setCurrentCalculator
} from '../../redux/slices/calculatorSlice'
import Tabs, { TabPanel } from '../../components/shared/Tabs/Tabs'
import SpeedometerLimit from '../../components/shared/Speedometer/SpeedometerLimit'
import isEmpty from 'lodash/isEmpty'
import { fetchHomeEvaluationAdmin } from '../../redux/slices/calculatorsSetupSlice'

const baseHomeData = {
  location_level: 'medium',
  neighborhood_curb_appeal_level: 'medium',
  home_curb_appeal_level: 'medium',
  ability_to_weather_level: 'medium',
  quality_of_backyard_level: 'medium',
  comfort_level_on_price_level: 'medium',
  size_level: 'medium',
  room_for_improvement_level: 'medium',
  practicality_level: 'medium',
  includes_amenities_level: 'medium',
  quiet_neighbors_and_community_level: 'medium',
  enthusiasm_for_home_level: 'medium',
  spouse_enthusiasm_for_home_level: 'medium',
  family_enthusiasm_for_home_level: 'medium'
}

const HomeEvaluation = ({ props }) => {
  const dispatch = useDispatch()
  const [tabActive, setActiveTab] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const [isSavedModal, setIsSavedModal] = useState(false)
  const { id, company_id } = useSelector(state => state.user.user)
  const { calculators, current, listExisting, loading } = useSelector(state => state.calculator)
  const baseValues = useSelector(state => state.calculatorsSetup.homeEvaluationAdmin);
  const highScore = 1
  const mediumScore = 0.67
  const lowScore = 0.33
  const [currentId, setCurrentId] = useState('')
  const [values, setValues] = useState(null)
  const [countValues, setCountValues] = useState({
    ideal: 0
  })
  const [homeValues, setHomeValues] = useState([])
  const [tabIndex, setTabIndex] = useState(2)
  const valuesReseter = () => {
    setHomeValues([]);
    setActiveTab(0);
    setTabIndex(2);
  }

  useEffect(() => {
    if(baseValues){
      setValues({
        currentId: '',
        name: '',
        homes: [],
        ...baseValues
      })
    }
  // eslint-disable-next-line
  }, [baseValues])

  useEffect(() => {     
    setTabIndex(homeValues.length + 2)

    // eslint-disable-next-line
  }, [homeValues])

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchHomeEvaluationAdmin(company_id))
      await dispatch(fetchCalculators({path: 'home-evaluation'}));
      await dispatch(fetchListResults('home-evaluation'))
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const getOption = option => {
    if (option === 'medium') {
      return mediumScore
    } else if (option === 'low') {
      return lowScore
    }
    return highScore
  }

  useEffect(() => {
    if (values) {
      const totalCount = +values.location + +values.neighborhood_curb_appeal + +values.home_curb_appeal + +values.ability_to_weather +
                        +values.quality_of_backyard + +values.comfort_level_on_price + +values.size + +values.room_for_improvement +
                        +values.practicality + +values.includes_amenities + +values.quiet_neighbors_and_community + +values.enthusiasm_for_home +
                        +values.spouse_enthusiasm_for_home + +values.family_enthusiasm_for_home

    const limit = totalCount * +values.score/100
    const ideal = getOption(values.location_level) * +values.location + getOption(values.neighborhood_curb_appeal_level) * +values.neighborhood_curb_appeal +
                  getOption(values.home_curb_appeal_level) * +values.home_curb_appeal + getOption(values.ability_to_weather_level) * +values.ability_to_weather +
                  getOption(values.quality_of_backyard_level) * +values.quality_of_backyard + getOption(values.comfort_level_on_price_level) * +values.comfort_level_on_price +
                  getOption(values.size_level) * +values.size + getOption(values.room_for_improvement_level) * +values.room_for_improvement +
                  getOption(values.practicality_level) * +values.practicality + getOption(values.includes_amenities_level) * +values.includes_amenities +
                  getOption(values.quiet_neighbors_and_community_level) * +values.quiet_neighbors_and_community + getOption(values.enthusiasm_for_home_level) * +values.enthusiasm_for_home +
                  getOption(values.spouse_enthusiasm_for_home_level) * +values.spouse_enthusiasm_for_home + getOption(values.family_enthusiasm_for_home_level) * +values.family_enthusiasm_for_home

    const scoreHome = values.homes.map(item => {
      let home = getOption(item.location_level) * +values.location + getOption(item.neighborhood_curb_appeal_level) * +values.neighborhood_curb_appeal +
                 getOption(item.home_curb_appeal_level) * +values.home_curb_appeal + getOption(item.ability_to_weather_level) * +values.ability_to_weather +
                 getOption(item.quality_of_backyard_level) * +values.quality_of_backyard + getOption(item.comfort_level_on_price_level) * +values.comfort_level_on_price +
                 getOption(item.size_level) * +values.size + getOption(item.room_for_improvement_level) * +values.room_for_improvement +
                 getOption(item.practicality_level) * +values.practicality + getOption(item.includes_amenities_level) * +values.includes_amenities +
                 getOption(item.quiet_neighbors_and_community_level) * +values.quiet_neighbors_and_community + getOption(item.enthusiasm_for_home_level) * +values.enthusiasm_for_home +
                 getOption(item.spouse_enthusiasm_for_home_level) * +values.spouse_enthusiasm_for_home + getOption(item.family_enthusiasm_for_home_level) * +values.family_enthusiasm_for_home

      return +home.toFixed(0)
    })
    setHomeValues([...scoreHome])

    setCountValues({
      ...countValues,
      limit,
      ideal,
      totalCount
    })
    }

    // eslint-disable-next-line
  }, [values])

  useEffect(() => {
    async function fetchNewData() {
      if (current) {
        setValues({
          name: current ? current.name : '',
          currentId: currentId,
          location: +current.location,
          neighborhood_curb_appeal: +current.neighborhood_curb_appeal,
          home_curb_appeal: +current.home_curb_appeal,
          ability_to_weather: +current.ability_to_weather,
          quality_of_backyard: +current.quality_of_backyard,
          comfort_level_on_price: +current.comfort_level_on_price,
          size: +current.size,
          score: +current.score,
          room_for_improvement: +current.room_for_improvement,
          practicality: +current.practicality,
          includes_amenities: +current.includes_amenities,
          quiet_neighbors_and_community: +current.quiet_neighbors_and_community,
          enthusiasm_for_home: +current.enthusiasm_for_home,
          spouse_enthusiasm_for_home: +current.spouse_enthusiasm_for_home,
          family_enthusiasm_for_home: +current.family_enthusiasm_for_home,
          location_level: current.location_level,
          neighborhood_curb_appeal_level: current.neighborhood_curb_appeal_level,
          home_curb_appeal_level: current.home_curb_appeal_level,
          ability_to_weather_level: current.ability_to_weather_level,
          quality_of_backyard_level: current.quality_of_backyard_level,
          comfort_level_on_price_level: current.comfort_level_on_price_level,
          size_level: current.size_level,
          room_for_improvement_level: current.room_for_improvement_level,
          practicality_level: current.practicality_level,
          includes_amenities_level: current.includes_amenities_level,
          quiet_neighbors_and_community_level: current.quiet_neighbors_and_community_level,
          enthusiasm_for_home_level: current.enthusiasm_for_home_level,
          spouse_enthusiasm_for_home_level: current.spouse_enthusiasm_for_home_level,
          family_enthusiasm_for_home_level: current.family_enthusiasm_for_home_level,
          homes: current.homes
        })
      }
    }

    if (currentId) {
      fetchNewData()
    }
    // eslint-disable-next-line
  }, [currentId, current])

  const handleInputChange = e => {
    setValues({
      ...values,
      [e.target.name]: +e.target.value
    })
  }

  const changeCurrentData = async e => {
    valuesReseter();
    setValues({
      ...baseValues,
      homes: []
    });
    await setCurrentId(e.target.value)
    await dispatch(fetchCurrentResult(e.target.value, 'home-evaluation'));
  }

  const onSave = async () => {
    dispatch(saveItemResult(values, 'home-evaluation')).then(res => {
      const calculator = res?.data?.data;

      if(calculator) {
        setIsSavedModal(true);
        setHomeValues([]);
        setActiveTab(0);
        setCurrentId(calculator.id);
        dispatch(setCurrentCalculator(calculator));
      }
    }).finally(() => {
      setIsOpen(false);
    })
  }

  const resetForm = () => {
    setValues({
      currentId: '',
      name: '',
      homes: [],
      ...baseValues
    })
    setCurrentId('');
  }

  const removeResult = async () => {
    await dispatch(removeItemResult(currentId, 'home-evaluation'))
    await setCurrentId('')
    await setIsDeleteModal(false)
    await setValues({
      homes: [],
      ...baseValues
    })
    valuesReseter();
  }

  const setHomeData = (e, id, param) => {
    const homes = values.homes.map(x => x[param] === id
      ? {...x, [e.target.name]: e.target.value}
      : x
    )
    setValues({
      ...values,
      homes
    })
  }

  return (
    <Layout decor>
      <BackButton title="Back to Calculators" path="/calculators" className="back-button--position" />
      <div className="calculators__title">
        <div>
          {calculators && <h4>{calculators.name}</h4>}
          {calculators &&
          <Tooltip
            title={
              <>
                <h6>{calculators.name}</h6>
                {calculators.tooltip}
                <hr className="tooltip__line"/>
                <h6>Example</h6>
                {calculators.example}
              </>
            }
          />}
        </div>

        {listExisting && listExisting.length > 0 && <div className="calculators__title-select">
          <FormSelect
            options={listExisting}
            id="currentId"
            value={currentId}
            medium
            name="currentId"
            onChange={changeCurrentData}
            placeholder="Select result"
          />
        </div>}
      </div>

      <div className="calculators__top-panel">
        <div className="calculators__top-panel-tabs">
          {values && values.homes && values.homes?.length > 0
            ? <Tabs
                handleChange={(event, newValue) => setActiveTab(newValue)}
                variant="scrollable"
                value={tabActive}
                tabs={[
                  {title: 'Relative Importance'},
                  {title: 'Ideal home'},
                  ...values.homes.map(item => {
                    return {
                      'title': item.name
                    }
                  })
                ]}
              />
            : <Tabs
                handleChange={(event, newValue) => setActiveTab(newValue)}
                variant="scrollable"
                value={tabActive}
                tabs={[
                  {title: 'Relative Importance'},
                  {title: 'Ideal home'}
                ]}
            />}
        </div>
        {values && values.homes?.length < 5 && <AddButton onClick={() => setIsOpenEditModal(true)} title="Add home" />}
      </div>
    {values &&  
      <div className="calculators__holder">
        <div className="calculators__content">
          <TabPanel value={tabActive} index={0}>
            <h6 className="calculators__subtitle">Step 1: Relative importance</h6>
            <div className="calculators__info">Please provide a score from 1-10 (10 being of highest importance) of how important each of these parameters are in terms of your home purchasing decision. </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="location"
                value={values.location.toString()}
                small
                smallHeight
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={10}
                  step={1}
                  label="Location"
                  value={+values.location}
                  onChange={(e, newValue) => setValues({...values, location: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="neighborhood_curb_appeal"
                value={values.neighborhood_curb_appeal.toString()}
                small
                smallHeight
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={10}
                  step={1}
                  label="Neighborhood curb appeal"
                  value={+values.neighborhood_curb_appeal}
                  onChange={(e, newValue) => setValues({...values, neighborhood_curb_appeal: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="home_curb_appeal"
                value={values.home_curb_appeal.toString()}
                small
                smallHeight
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={10}
                  step={1}
                  label="Home curb appeal"
                  value={+values.home_curb_appeal}
                  onChange={(e, newValue) => setValues({...values, home_curb_appeal: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="ability_to_weather"
                value={values.ability_to_weather.toString()}
                small
                smallHeight
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={10}
                  step={1}
                  label="Ability to Weather Catastrophic Events (Hurricane, Earthquake, etc)"
                  value={+values.ability_to_weather}
                  onChange={(e, newValue) => setValues({...values, ability_to_weather: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="quality_of_backyard"
                value={values.quality_of_backyard.toString()}
                small
                smallHeight
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={10}
                  step={1}
                  label="Quality of backyard"
                  value={+values.quality_of_backyard}
                  onChange={(e, newValue) => setValues({...values, quality_of_backyard: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="comfort_level_on_price"
                value={values.comfort_level_on_price.toString()}
                small
                smallHeight
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={10}
                  step={1}
                  label="Comfort level on price"
                  value={+values.comfort_level_on_price}
                  onChange={(e, newValue) => setValues({...values, comfort_level_on_price: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="size"
                value={values.size.toString()}
                small
                smallHeight
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={10}
                  step={1}
                  label="Size"
                  value={+values.size}
                  onChange={(e, newValue) => setValues({...values, size: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="room_for_improvement"
                value={values.room_for_improvement.toString()}
                small
                smallHeight
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={10}
                  step={1}
                  label="Room for improvement"
                  value={+values.room_for_improvement}
                  onChange={(e, newValue) => setValues({...values, room_for_improvement: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="practicality"
                value={values.practicality.toString()}
                small
                smallHeight
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={10}
                  step={1}
                  label="Practicality"
                  value={+values.practicality}
                  onChange={(e, newValue) => setValues({...values, practicality: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="includes_amenities"
                value={values.includes_amenities.toString()}
                small
                smallHeight
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={10}
                  step={1}
                  label="Includes amenities"
                  value={+values.includes_amenities}
                  onChange={(e, newValue) => setValues({...values, includes_amenities: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="quiet_neighbors_and_community"
                value={values.quiet_neighbors_and_community.toString()}
                small
                smallHeight
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={10}
                  step={1}
                  label="Quiet neighbors and community"
                  value={+values.quiet_neighbors_and_community}
                  onChange={(e, newValue) => setValues({...values, quiet_neighbors_and_community: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="enthusiasm_for_home"
                value={values.enthusiasm_for_home.toString()}
                small
                smallHeight
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={10}
                  step={1}
                  label="My enthusiasm for the home"
                  value={+values.enthusiasm_for_home}
                  onChange={(e, newValue) => setValues({...values, enthusiasm_for_home: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="spouse_enthusiasm_for_home"
                value={values.spouse_enthusiasm_for_home.toString()}
                small
                smallHeight
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={10}
                  step={1}
                  label="My spouse’s enthusiasm for the home"
                  value={+values.spouse_enthusiasm_for_home}
                  onChange={(e, newValue) => setValues({...values, spouse_enthusiasm_for_home: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="family_enthusiasm_for_home"
                value={values.family_enthusiasm_for_home.toString()}
                small
                smallHeight
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={10}
                  step={1}
                  label="Other family member’s enthusiasm for the home"
                  value={+values.family_enthusiasm_for_home}
                  onChange={(e, newValue) => setValues({...values, family_enthusiasm_for_home: newValue})}
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel value={tabActive} index={1}>
            <h6 className="calculators__subtitle">Step 2: Ideal home</h6>
            <div className="calculators__info">Please provide a relative value to each of these parameters if you were to think about what your ideal home would have. For example, if curb appeal is of the highest importance to you, please rate it as “High”</div>
            <div className="calculators__area-label">Location</div>
            <FormGroup>
              <RadioGroup
                name="location_level"
                value={values.location_level || 'medium'}
                onChange={e => setValues({ ...values, [e.target.name]: e.target.value})}
                className="radio-list-inline"
              >
                <FormControlLabel value="high" control={<Radio/>} label="High"/>
                <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
                <FormControlLabel value="low" control={<Radio/>} label="Low"/>
              </RadioGroup>
            </FormGroup>
            <div className="calculators__area-label">Neighborhood curb appeal</div>
            <FormGroup>
              <RadioGroup
                name="neighborhood_curb_appeal_level"
                value={values.neighborhood_curb_appeal_level || 'medium'}
                onChange={e => setValues({ ...values, [e.target.name]: e.target.value})}
                className="radio-list-inline"
              >
                <FormControlLabel value="high" control={<Radio/>} label="High"/>
                <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
                <FormControlLabel value="low" control={<Radio/>} label="Low"/>
              </RadioGroup>
            </FormGroup>
            <div className="calculators__area-label">Home curb appeal</div>
            <FormGroup>
              <RadioGroup
                name="home_curb_appeal_level"
                value={values.home_curb_appeal_level || 'medium'}
                onChange={e => setValues({ ...values, [e.target.name]: e.target.value})}
                className="radio-list-inline"
              >
                <FormControlLabel value="high" control={<Radio/>} label="High"/>
                <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
                <FormControlLabel value="low" control={<Radio/>} label="Low"/>
              </RadioGroup>
            </FormGroup>
            <div className="calculators__area-label">Ability to weather (hurricane and etc)</div>
            <FormGroup>
              <RadioGroup
                name="ability_to_weather_level"
                value={values.ability_to_weather_level || 'medium'}
                onChange={e => setValues({ ...values, [e.target.name]: e.target.value})}
                className="radio-list-inline"
              >
                <FormControlLabel value="high" control={<Radio/>} label="High"/>
                <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
                <FormControlLabel value="low" control={<Radio/>} label="Low"/>
              </RadioGroup>
            </FormGroup>
            <div className="calculators__area-label">Quality of backyard</div>
            <FormGroup>
              <RadioGroup
                name="quality_of_backyard_level"
                value={values.quality_of_backyard_level || 'medium'}
                onChange={e => setValues({ ...values, [e.target.name]: e.target.value})}
                className="radio-list-inline"
              >
                <FormControlLabel value="high" control={<Radio/>} label="High"/>
                <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
                <FormControlLabel value="low" control={<Radio/>} label="Low"/>
              </RadioGroup>
            </FormGroup>
            <div className="calculators__area-label">Comfort level on price</div>
            <FormGroup>
              <RadioGroup
                name="comfort_level_on_price_level"
                value={values.comfort_level_on_price_level || 'medium'}
                onChange={e => setValues({ ...values, [e.target.name]: e.target.value})}
                className="radio-list-inline"
              >
                <FormControlLabel value="high" control={<Radio/>} label="High"/>
                <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
                <FormControlLabel value="low" control={<Radio/>} label="Low"/>
              </RadioGroup>
            </FormGroup>
            <div className="calculators__area-label">Size</div>
            <FormGroup>
              <RadioGroup
                name="size_level"
                value={values.size_level || 'medium'}
                onChange={e => setValues({ ...values, [e.target.name]: e.target.value})}
                className="radio-list-inline"
              >
                <FormControlLabel value="high" control={<Radio/>} label="High"/>
                <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
                <FormControlLabel value="low" control={<Radio/>} label="Low"/>
              </RadioGroup>
            </FormGroup>
            <div className="calculators__area-label">Room for improvement</div>
            <FormGroup>
              <RadioGroup
                name="room_for_improvement_level"
                value={values.room_for_improvement_level || 'medium'}
                onChange={e => setValues({ ...values, [e.target.name]: e.target.value})}
                className="radio-list-inline"
              >
                <FormControlLabel value="high" control={<Radio/>} label="High"/>
                <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
                <FormControlLabel value="low" control={<Radio/>} label="Low"/>
              </RadioGroup>
            </FormGroup>
            <div className="calculators__area-label">Practicality</div>
            <FormGroup>
              <RadioGroup
                name="practicality_level"
                value={values.practicality_level || 'medium'}
                onChange={e => setValues({ ...values, [e.target.name]: e.target.value})}
                className="radio-list-inline"
              >
                <FormControlLabel value="high" control={<Radio/>} label="High"/>
                <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
                <FormControlLabel value="low" control={<Radio/>} label="Low"/>
              </RadioGroup>
            </FormGroup>
            <div className="calculators__area-label">Includes Amenities</div>
            <FormGroup>
              <RadioGroup
                name="includes_amenities_level"
                value={values.includes_amenities_level || 'medium'}
                onChange={e => setValues({ ...values, [e.target.name]: e.target.value})}
                className="radio-list-inline"
              >
                <FormControlLabel value="high" control={<Radio/>} label="High"/>
                <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
                <FormControlLabel value="low" control={<Radio/>} label="Low"/>
              </RadioGroup>
            </FormGroup>
            <div className="calculators__area-label">Quiet neighbors and community</div>
            <FormGroup>
              <RadioGroup
                name="quiet_neighbors_and_community_level"
                value={values.quiet_neighbors_and_community_level || 'medium'}
                onChange={e => setValues({ ...values, [e.target.name]: e.target.value})}
                className="radio-list-inline"
              >
                <FormControlLabel value="high" control={<Radio/>} label="High"/>
                <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
                <FormControlLabel value="low" control={<Radio/>} label="Low"/>
              </RadioGroup>
            </FormGroup>
            <div className="calculators__area-label">My enthusiasm for the home</div>
            <FormGroup>
              <RadioGroup
                name="enthusiasm_for_home_level"
                value={values.enthusiasm_for_home_level || 'medium'}
                onChange={e => setValues({ ...values, [e.target.name]: e.target.value})}
                className="radio-list-inline"
              >
                <FormControlLabel value="high" control={<Radio/>} label="High"/>
                <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
                <FormControlLabel value="low" control={<Radio/>} label="Low"/>
              </RadioGroup>
            </FormGroup>
            <div className="calculators__area-label">My spouse’s enthusiasm for the home</div>
            <FormGroup>
              <RadioGroup
                name="spouse_enthusiasm_for_home_level"
                value={values.spouse_enthusiasm_for_home_level || 'medium'}
                onChange={e => setValues({ ...values, [e.target.name]: e.target.value})}
                className="radio-list-inline"
              >
                <FormControlLabel value="high" control={<Radio/>} label="High"/>
                <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
                <FormControlLabel value="low" control={<Radio/>} label="Low"/>
              </RadioGroup>
            </FormGroup>
            <div className="calculators__area-label">Other family member’s enthusiasm for the home</div>
            <FormGroup>
              <RadioGroup
                name="family_enthusiasm_for_home_level"
                value={values.family_enthusiasm_for_home_level || 'medium'}
                onChange={e => setValues({ ...values, [e.target.name]: e.target.value})}
                className="radio-list-inline"
              >
                <FormControlLabel value="high" control={<Radio/>} label="High"/>
                <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
                <FormControlLabel value="low" control={<Radio/>} label="Low"/>
              </RadioGroup>
            </FormGroup>
          </TabPanel>

          {values && values.homes && values.homes.length > 0 && values.homes.map((item, i) =>
            <TabPanel value={tabActive} index={i+2} key={`home-tab-${i}`}>
              <h6 className="calculators__subtitle">Step {i+3}. {item.name}</h6>
              <div className="calculators__area-label">Location</div>
              <FormGroup>
                <RadioGroup
                  name="location_level"
                  value={item.location_level || 'medium'}
                  onChange={e =>
                    setHomeData(e,
                      item.id ? item.id : item.home_evaluation_id,
                          item.id ? 'id' : 'home_evaluation_id'
                    )
                  }
                  className="radio-list-inline"
                >
                  <FormControlLabel value="high" control={<Radio/>} label="High"/>
                  <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
                  <FormControlLabel value="low" control={<Radio/>} label="Low"/>
                </RadioGroup>
              </FormGroup>
              <div className="calculators__area-label">Neighborhood curb appeal</div>
              <FormGroup>
                <RadioGroup
                  name="neighborhood_curb_appeal_level"
                  value={item.neighborhood_curb_appeal_level || 'medium'}
                  onChange={e =>
                    setHomeData(e,
                      item.id ? item.id : item.home_evaluation_id,
                      item.id ? 'id' : 'home_evaluation_id'
                    )
                  }
                  className="radio-list-inline"
                >
                  <FormControlLabel value="high" control={<Radio/>} label="High"/>
                  <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
                  <FormControlLabel value="low" control={<Radio/>} label="Low"/>
                </RadioGroup>
              </FormGroup>
              <div className="calculators__area-label">Home curb appeal</div>
              <FormGroup>
                <RadioGroup
                  name="home_curb_appeal_level"
                  value={item.home_curb_appeal_level || 'medium'}
                  onChange={e =>
                    setHomeData(e,
                      item.id ? item.id : item.home_evaluation_id,
                      item.id ? 'id' : 'home_evaluation_id'
                    )
                  }
                  className="radio-list-inline"
                >
                  <FormControlLabel value="high" control={<Radio/>} label="High"/>
                  <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
                  <FormControlLabel value="low" control={<Radio/>} label="Low"/>
                </RadioGroup>
              </FormGroup>
              <div className="calculators__area-label">Ability to weather (hurricane and etc)</div>
              <FormGroup>
                <RadioGroup
                  name="ability_to_weather_level"
                  value={item.ability_to_weather_level || 'medium'}
                  onChange={e =>
                    setHomeData(e,
                      item.id ? item.id : item.home_evaluation_id,
                      item.id ? 'id' : 'home_evaluation_id'
                    )
                  }
                  className="radio-list-inline"
                >
                  <FormControlLabel value="high" control={<Radio/>} label="High"/>
                  <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
                  <FormControlLabel value="low" control={<Radio/>} label="Low"/>
                </RadioGroup>
              </FormGroup>
              <div className="calculators__area-label">Quality of backyard</div>
              <FormGroup>
                <RadioGroup
                  name="quality_of_backyard_level"
                  value={item.quality_of_backyard_level || 'medium'}
                  onChange={e =>
                    setHomeData(e,
                      item.id ? item.id : item.home_evaluation_id,
                      item.id ? 'id' : 'home_evaluation_id'
                    )
                  }
                  className="radio-list-inline"
                >
                  <FormControlLabel value="high" control={<Radio/>} label="High"/>
                  <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
                  <FormControlLabel value="low" control={<Radio/>} label="Low"/>
                </RadioGroup>
              </FormGroup>
              <div className="calculators__area-label">Comfort level on price</div>
              <FormGroup>
                <RadioGroup
                  name="comfort_level_on_price_level"
                  value={item.comfort_level_on_price_level || 'medium'}
                  onChange={e =>
                    setHomeData(e,
                      item.id ? item.id : item.home_evaluation_id,
                      item.id ? 'id' : 'home_evaluation_id'
                    )
                  }
                  className="radio-list-inline"
                >
                  <FormControlLabel value="high" control={<Radio/>} label="High"/>
                  <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
                  <FormControlLabel value="low" control={<Radio/>} label="Low"/>
                </RadioGroup>
              </FormGroup>
              <div className="calculators__area-label">Size</div>
              <FormGroup>
                <RadioGroup
                  name="size_level"
                  value={item.size_level || 'medium'}
                  onChange={e =>
                    setHomeData(e,
                      item.id ? item.id : item.home_evaluation_id,
                      item.id ? 'id' : 'home_evaluation_id'
                    )
                  }
                  className="radio-list-inline"
                >
                  <FormControlLabel value="high" control={<Radio/>} label="High"/>
                  <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
                  <FormControlLabel value="low" control={<Radio/>} label="Low"/>
                </RadioGroup>
              </FormGroup>
              <div className="calculators__area-label">Room for improvement</div>
              <FormGroup>
                <RadioGroup
                  name="room_for_improvement_level"
                  value={item.room_for_improvement_level || 'medium'}
                  onChange={e =>
                    setHomeData(e,
                      item.id ? item.id : item.home_evaluation_id,
                      item.id ? 'id' : 'home_evaluation_id'
                    )
                  }
                  className="radio-list-inline"
                >
                  <FormControlLabel value="high" control={<Radio/>} label="High"/>
                  <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
                  <FormControlLabel value="low" control={<Radio/>} label="Low"/>
                </RadioGroup>
              </FormGroup>
              <div className="calculators__area-label">Practicality</div>
              <FormGroup>
                <RadioGroup
                  name="practicality_level"
                  value={item.practicality_level || 'medium'}
                  onChange={e =>
                    setHomeData(e,
                      item.id ? item.id : item.home_evaluation_id,
                      item.id ? 'id' : 'home_evaluation_id'
                    )
                  }
                  className="radio-list-inline"
                >
                  <FormControlLabel value="high" control={<Radio/>} label="High"/>
                  <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
                  <FormControlLabel value="low" control={<Radio/>} label="Low"/>
                </RadioGroup>
              </FormGroup>
              <div className="calculators__area-label">Includes Amenities</div>
              <FormGroup>
                <RadioGroup
                  name="includes_amenities_level"
                  value={item.includes_amenities_level || 'medium'}
                  onChange={e =>
                    setHomeData(e,
                      item.id ? item.id : item.home_evaluation_id,
                      item.id ? 'id' : 'home_evaluation_id'
                    )
                  }
                  className="radio-list-inline"
                >
                  <FormControlLabel value="high" control={<Radio/>} label="High"/>
                  <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
                  <FormControlLabel value="low" control={<Radio/>} label="Low"/>
                </RadioGroup>
              </FormGroup>
              <div className="calculators__area-label">Quiet neighbors and community</div>
              <FormGroup>
                <RadioGroup
                  name="quiet_neighbors_and_community_level"
                  value={item.quiet_neighbors_and_community_level || 'medium'}
                  onChange={e =>
                    setHomeData(e,
                      item.id ? item.id : item.home_evaluation_id,
                      item.id ? 'id' : 'home_evaluation_id'
                    )
                  }
                  className="radio-list-inline"
                >
                  <FormControlLabel value="high" control={<Radio/>} label="High"/>
                  <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
                  <FormControlLabel value="low" control={<Radio/>} label="Low"/>
                </RadioGroup>
              </FormGroup>
              <div className="calculators__area-label">My enthusiasm for the home</div>
              <FormGroup>
                <RadioGroup
                  name="enthusiasm_for_home_level"
                  value={item.enthusiasm_for_home_level || 'medium'}
                  onChange={e =>
                    setHomeData(e,
                      item.id ? item.id : item.home_evaluation_id,
                      item.id ? 'id' : 'home_evaluation_id'
                    )
                  }
                  className="radio-list-inline"
                >
                  <FormControlLabel value="high" control={<Radio/>} label="High"/>
                  <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
                  <FormControlLabel value="low" control={<Radio/>} label="Low"/>
                </RadioGroup>
              </FormGroup>
              <div className="calculators__area-label">My spouse’s enthusiam for the home</div>
              <FormGroup>
                <RadioGroup
                  name="spouse_enthusiasm_for_home_level"
                  value={item.spouse_enthusiasm_for_home_level || 'medium'}
                  onChange={e =>
                    setHomeData(e,
                      item.id ? item.id : item.home_evaluation_id,
                      item.id ? 'id' : 'home_evaluation_id'
                    )
                  }
                  className="radio-list-inline"
                >
                  <FormControlLabel value="high" control={<Radio/>} label="High"/>
                  <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
                  <FormControlLabel value="low" control={<Radio/>} label="Low"/>
                </RadioGroup>
              </FormGroup>
              <div className="calculators__area-label">Other family member’s enthusiam for the home</div>
              <FormGroup>
                <RadioGroup
                  name="family_enthusiasm_for_home_level"
                  value={item.family_enthusiasm_for_home_level || 'medium'}
                  onChange={e =>
                    setHomeData(e,
                      item.id ? item.id : item.home_evaluation_id,
                      item.id ? 'id' : 'home_evaluation_id'
                    )
                  }
                  className="radio-list-inline"
                >
                  <FormControlLabel value="high" control={<Radio/>} label="High"/>
                  <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
                  <FormControlLabel value="low" control={<Radio/>} label="Low"/>
                </RadioGroup>
              </FormGroup>
            </TabPanel>
          )}
        </div>
        <div className="calculators__preview">
          <h6 className="calculators__subtitle">Results: Home Evaluation Scores</h6>
          <div className="calculators__info">The homes I’m evaluating need to score at least x% of ideal home score to be viable candidates</div>
          <div className="calculators__area">
            <Input
              type="number"
              name="score"
              value={values.score.toString()}
              small
              smallHeight
              onChange={handleInputChange}
              iconName={PercentIcon}
            />
            <div className="calculators__area-slider">
              <Slider
                max={100}
                step={0.5}
                label="Score"
                value={+values.score}
                onChange={(e, newValue) => setValues({...values, score: newValue})}
              />
            </div>
          </div>
          <div className="calculators__preview-box">
            <div className="calculators__cases">
              <div>
                <SpeedometerLimit
                  limit={[countValues.totalCount * 0.3, countValues.totalCount * 0.6]}
                  count={countValues.ideal || 0}
                  large
                  label
                  inverse
                />
                <div className="calculators__case-title">
                  Ideal Home's score
                </div>
              </div>
              {homeValues && !isEmpty(homeValues) && homeValues.map((value, i) =>
                <div key={`item-${i}`}>
                  <SpeedometerLimit
                    limit={[countValues.ideal - 25, countValues.ideal]}
                    count={value || 0}
                    large
                    label
                    inverse
                  />
                  <div className="calculators__case-title">
                    {values.homes && values.homes.length > 0 && `${values.homes[i].name}'s Score`}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    }
      <div className="calculators__buttons">
        <Button variant="contained" size="large" color="primary" onClick={() => setIsOpen(true)}>Save result</Button>
        {currentId && <Button variant="contained" size="large"  onClick={() => setIsDeleteModal(true)}>Remove result</Button>}
        <Button size="large" className="MuiButton-textGray" onClick={() => resetForm()}>Reset</Button>
      </div>

      <ModalBox
        open={isOpen}
        close={() => setIsOpen(false)}
        onClick={onSave}
        button="Save"
        title="Save Result"
        isLoading={loading}
        isDisabled={values && !values.name && !values.currentId}
      >
        <FormGroup name="name" labelText="Name">
          <Input
            name='name'
            value={values ? values.name : ''}
            placeholder="Type a name of result"
            onChange={e => setValues({
              ...values,
              name: e.target.value
            })}
          />
        </FormGroup>
        {listExisting && listExisting.length > 0 &&
        <>
          <p className="text-center">or</p>
          <FormGroup labelText="Select  Existing Result">
            <FormSelect
              options={listExisting}
              value={values ? values.currentId : ''}
              name="currentId"
              onChange={e => setValues({
                ...values,
                currentId: e.target.value,
                name: listExisting.find(i => i.value === e.target.value).label
              })}
              placeholder="Select Existing Result"
            />
          </FormGroup>
        </>
        }
      </ModalBox>
      <ModalBox
        small
        open={isDeleteModal}
        close={() => setIsDeleteModal(false)}
        onClick={removeResult}
        button="Remove"
        title={`Do you want to remove ${current && current.name}?`}
      />
      <ModalBox
        open={isOpenEditModal}
        close={() => setIsOpenEditModal(false)}
        title="Add Home"
      >
        <Formik
          enableReinitialize
          initialValues={{name: ''}}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Required'),
          })}
          onSubmit={data => {
            setTabIndex(tabIndex + 1)
            setValues({
              ...values,
              homes: [
                ...values.homes,
                {
                  home_evaluation_id: Date.now(),
                  name: data.name,
                  ...baseHomeData
                }
              ]
            })
            setActiveTab(tabIndex)
            setIsOpenEditModal(false)}}
        >
          {({ errors, handleChange, handleBlur, values, touched }) => (
            <Form>
              <FormGroup name="name" labelText="Home's name">
                <Input
                  name="name"
                  placeholder="Type a name"
                  type="text"
                  onChange={handleChange}
                  value={values.name || ''}
                  onBlur={handleBlur}
                  hasError={!!errors.name && touched.name}
                />
                {touched.name && errors.name && <span className="form__msg-error">{errors.name}</span>}
              </FormGroup>
              <div className="form__button form__button--center">
                <Button variant="contained" size="large" color="primary" type="submit">
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBox>

      <ModalBox
        small
        open={isSavedModal}
        close={() => setIsSavedModal(false)}
        button="Ok"
        onClick={() => setIsSavedModal(false)}
      >
        <div>
          {calculators && calculators.iconLeft &&
          <div className="modal__title-iconLeft">
            <img src={calculators.iconLeft} alt={calculators.name} />
          </div>}
          <h4>Your calculator scenario has been saved.</h4>
          <p>You can access your saved scenarios through the “Select result” dropdown menu at the top of each calculator page.</p>
        </div>
      </ModalBox>
    </Layout>
  )
}

export default HomeEvaluation
