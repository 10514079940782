import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import clsx from 'classnames'
import ModalBox from '../../components/shared/Modal/Modal'
import SVGIcon from '../shared/SVGIcon/SVGIcon'
import More from '../../assets/images/icons/more.svg'


const BlogCard = ({
  companyId,
  postId,
  title,
  content,
  path,
  image,
  category,
  small,
  large,
  isAdmin = false,
  onDelete,
  onEdit,
  isVSpost,
  handleStatus,
  page,
  active,
}) => {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const popup = useRef();

  const onToggle = () => setOpen(!isOpen);

  const deletePost = (companyId, postId) => {
    dispatch(onDelete(companyId, postId, null, { page }));
    setOpenDeleteModal(false);
  };

  useEffect(() => {
    setOpen(false);
  }, [active]);

  useEffect(() => {
    const listener = (event) => {
      if (!popup.current || popup.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [popup]);

  return (
    <>
      <div
        className={clsx('blog-card', {
          'blog-card--small': small,
          'blog-card--large': large,
          'blog-card--hide': !active,
        })}
      >
        <div className="blog-card__image">
          {image && (
            <Link to={path}>
              <img src={image} alt={title} />
            </Link>
          )}
        </div>
        <div className="blog-card__content">
          <div className="blog-card__category">{category}</div>
          <Link className="blog-card__title" to={path}>
            {title}
          </Link>
          <div className="blog-card__description">{content}</div>
          <Link className="blog-card__button" to={path}>
            Read article
          </Link>
          {isAdmin && (
            <>
              <button onClick={onToggle} className="blog-card__drop-button">
                <SVGIcon icon={More} />
              </button>
              <div ref={popup} className={`blog-card__drop${isOpen ? ' blog-card__drop--open' : ''}`}>
                <ul>
                  {isVSpost && (
                    <li>
                      <button onClick={onEdit}>Edit post</button>
                    </li>
                  )}
                  <li>
                    <button onClick={handleStatus}>{active ? 'Hide' : 'Show'}</button>
                  </li>
                  {isVSpost && (
                    <li>
                      <button onClick={() => setOpenDeleteModal(true)}>Delete</button>
                    </li>
                  )}
                </ul>
              </div>
            </>
          )}
        </div>
      </div>

      <ModalBox
        small
        inline
        open={openDeleteModal}
        close={() => setOpenDeleteModal(false)}
        title="Do you want to delete this article?"
        button="No"
        onClick={() => setOpenDeleteModal(false)}
        removeButton="Yes"
        remove={() => deletePost(companyId, postId)}
      >
        <p>After deletion you will not be able to return the article.</p>
      </ModalBox>
    </>
  );
};

BlogCard.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  path: PropTypes.string,
  category: PropTypes.string,
  small: PropTypes.bool,
  large: PropTypes.bool,
  isAdmin: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func
};

export default BlogCard;
