import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import SVGIcon from '../shared/SVGIcon/SVGIcon'
import Edit from '../../assets/images/icons/edit.svg'
import Trash from '../../assets/images/icons/trash.svg'
import ModalBox from '../shared/Modal/Modal'


const CategoryCard = ({ name, onDelete, onEdit, companyId, catalogId }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => setIsOpen(false)

  const remove =  (companyId, catalogId) => {
     dispatch(onDelete(companyId, catalogId, true))
     setIsOpen(false)
  }

  return (
    <>
      <div className="category-card">
        <div className="category-card__title">{name}</div>
        <div className="category-card__options">
          <button onClick={() => onEdit(catalogId)}>
            <SVGIcon icon={Edit} color="#8c8c8c" />
          </button>
          <button onClick={() => setIsOpen(true)}>
            <SVGIcon icon={Trash} color="#8c8c8c" />
          </button>
        </div>
      </div>
      <ModalBox
        small
        inline
        open={isOpen}
        close={closeModal}
        onClick={closeModal}
        title='Do you want to delete this category?'
        button="No"
        removeButton="Yes"
        remove={() => remove(companyId, catalogId)}
      />
    </>
  )
}

CategoryCard.propTypes = {
  catalogId: PropTypes.number.isRequired,
  name: PropTypes.string,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
}

export default CategoryCard
