import React from "react";
import PropTypes from "prop-types";
import { FormGroup, Input } from "../shared/Form";
import InputPriority from "../shared/Form/InputPriority";

const TaskPlanCard = ({ index, data, priorities, tasks_of_week, number_of_week, touched, errors, onChange }) => {
  return (
    <>
      <FormGroup name="title" labelText="">
        <Input
          name={`${index}.name`}
          value={data.name}
          onChange={onChange}
          className="admin__plans__box-name-input"
        />
      </FormGroup>
      <div className="form-group">
        <p className="admin__plans__box-label">Description</p>
        <Input
          readOnly
          name="name"
          value={data.name_description}
          onChange={() => {}}
          className="admin__plans__box-description-input"
        />
      </div>
      <FormGroup name="title" labelText="Title">
        <div className="admin__plans__box-textarea-wrap">
          <textarea
            rows={3}
            name={`${index}.title`}
            className={`form__textarea form__textarea--alt form__textarea--resize ${
              touched.message && !!errors.message && "form__textarea--error"
            }`}
            value={data.title}
            onChange={onChange}
          />
        </div>
      </FormGroup>
      <FormGroup name="description" labelText="Details">
        <div className="admin__plans__box-textarea-wrap">
          <textarea
            rows={5}
            name={`${index}.title_description`}
            className={`form__textarea form__textarea--alt form__textarea--resize ${
              touched.message && !!errors.message && "form__textarea--error"
            }`}
            value={data.title_description}
            onChange={onChange}
          />
        </div>
      </FormGroup>
      {tasks_of_week && (
        <FormGroup
          name="number_tasks_of_week"
          labelText="Number of tasks per week"
        >
          <Input
            min={0}
            type="number"
            name={`${index}.number_tasks_of_week`}
            value={data.number_tasks_of_week}
            onChange={onChange}
          />
        </FormGroup>
      )}
      {number_of_week && (
        <FormGroup name="number_of_week" labelText="Number of weeks">
          <Input
            type="number"
            name={`${index}.number_of_week`}
            value={data.number_of_week}
            onChange={onChange}
          />
        </FormGroup>
      )}
      {data.planning_type_priorities.length > 0 && (
        <FormGroup labelText="Category Priority (1 = highest, 9 = lowest):">
          {data.planning_type_priorities.map((priority, i) => (
            <InputPriority
              key={priority.id}
              hasError={errors.planning_type_priorities?.[i]}
              name={`${index}.planning_type_priorities.${i}.priority`}
              value={priority.priority}
              priorityName={priority.name}
              priorities={priorities}
              onChange={onChange}
            />
          ))}
        </FormGroup>
      )}
    </>
  );
};

TaskPlanCard.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  tasks_of_week: PropTypes.bool,
  number_of_week: PropTypes.bool,
}

export default TaskPlanCard;
