import React, { useEffect, useState } from 'react';
import LayoutAdmin from '../../components/Layout/LayoutAdmin';
import { useParams, useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Input, FormGroup } from '../../components/shared/Form';
import { Button } from '@mui/material';
import { BackButton } from '../../components/shared/Buttons';
import { AdminCompanyMemberSchema } from '../../utils/validation';
import {
  postCompanyMemberCheck,
  fetchCompany,
  setCompanyNewAdmin,
  postNewOwner,
} from '../../redux/slices/companiesSlice';
import { setIsOpen } from '../../redux/slices/modalSlice';
import { setMode } from '../../redux/slices/modeSlice';
import { setUserData } from '../../redux/slices/userSlice';
import Modal from '../../components/shared/Modal/Modal';
import ImageSearch from '../../assets/images/image-search.png';
import SVGIcon from '../../components/shared/SVGIcon/SVGIcon';
import Man from '../../assets/images/icons/user02.svg';
import { isNull } from 'lodash';

const AdminCompanyChangeAdmin = ({ props, isAdmin }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { companyId } = useParams();
  const { role_id, email } = useSelector((state) => state.user.user);
  const sending = useSelector((state) => state.sending.isSending);
  const { sendStatus, companyNewAdmin } = useSelector((state) => state.companies);
  const companyInfo = useSelector((state) => state.companies.company.info);
  const [isNoMember, setNoMember] = useState(false);
  const [isMember, setMember] = useState(false);
  const isOpen = useSelector((state) => state.modal.isOpen);
  const mode = useSelector((state) => state.mode.mode);
  const [isMobile, setIsMobile] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const mobileView = 999;

  const handleResize = () => {
    mobileView >= window.innerWidth ? setIsMobile(true) : setIsMobile(false);
  };

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(fetchCompany(companyId));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      !isNull(companyNewAdmin) &&
      (!companyNewAdmin?.user || (!companyNewAdmin.available_planner && companyNewAdmin.role === 'planner'))
    ) {
      setNoMember(true);
    }
    if (
      !isNull(companyNewAdmin) &&
      ((companyNewAdmin?.available_planner && companyNewAdmin.role === 'planner') ||
        (companyNewAdmin?.role && companyNewAdmin?.role !== 'planner'))
    ) {
      setMember(true);
    }
    
  }, [companyNewAdmin]);

  const onSubmit = (values, setErrors) => {
    let formData = new FormData();

    formData.append('email', values.email);

    dispatch(postCompanyMemberCheck(companyId, formData, props, setErrors));
  };

  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }));
    if (role_id === 2) {
      dispatch(
        setUserData({
          is_admin: false,
        })
      );
      history.push('/dashboard');
    }
    dispatch(setMode(null));
  };

  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }));
    if (role_id === 2) {
      dispatch(
        setUserData({
          is_admin: false,
        })
      );
      history.push('/dashboard');
    }
    dispatch(setMode(null));
  };

  return (
    <LayoutAdmin companyId={companyId} isAdmin={isAdmin}>
      <div className="member-comapny">
        <div className="member-comapny__info">
          <div className="member-comapny__logo">
            <img src={companyInfo?.image} alt="Company logo" />
          </div>
          <div className="member-comapny__title">
            <h3>{companyInfo?.name}</h3>
            {!isMobile && (
              <p>
                Feedback Email: <span>{companyInfo?.email}</span>
              </p>
            )}
          </div>
        </div>
        {isMobile && (
          <div className="mobile-feedback">
            Feedback Email: <span>{companyInfo?.email}</span>
          </div>
        )}
      </div>
      <BackButton
        title="Back to Members"
        className="back-button--company"
        path={`/admin/company/${companyId}/members`}
      />
      <div className="company">
        <Formik
          enableReinitialize
          initialValues={{
            email: '',
          }}
          validationSchema={AdminCompanyMemberSchema}
          onSubmit={(values, { setErrors }) => onSubmit(values, setErrors)}
        >
          {({ errors, handleChange, handleBlur, values, touched }) => (
            <Form>
              <h4 className="company__title">Change SuperAdmin of the Company</h4>
              <p className="company__subtitle">
                Please enter the new email address you would like to use as the company’s SuperAdmin. Then click the
                "Change" button in order for that user to receive a notification email of their new role.
              </p>
              {sendStatus && (
                <p className="company__subtitle--success">The SuperAdmin of the Company has been changed.</p>
              )}
              <div className="comapny__form">
                <FormGroup name="email" labelText="Email address">
                  <Input
                    disabled={sendStatus}
                    name="email"
                    placeholder="Enter an email"
                    type="text"
                    onChange={(e) => {
                      handleChange(e);
                      e.target.value === email ? setEmailError(true) : setEmailError(false);
                    }}
                    value={values.email || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.email && touched.email}
                  />
                  {touched.email && errors.email && <span className="form__msg-error">{errors.email}</span>}
                  {emailError && <span className="form__msg-error">You can't use own email!</span>}
                </FormGroup>
              </div>
              <div className="form__button">
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  type="submit"
                  disabled={sending || sendStatus || emailError}
                >
                  Change
                  {sending && <span className="spinner" />}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <Modal
        open={isNoMember}
        button={!companyNewAdmin?.user ? 'Invite member' : 'Go to members'}
        onClick={() =>
          !companyNewAdmin?.user
            ? history.push(`/admin/company/${companyId}/members/invite`)
            : history.push(`/admin/company/${companyId}/members`)
        }
        close={() => {
          setNoMember(false);
          dispatch(setCompanyNewAdmin(null));
        }}
        customClass="admin-search"
      >
        {!companyNewAdmin?.user ? (
          <div className="admin-search__modal">
            <h3>No results found</h3>
            <p>{`This user is not found in ${companyInfo?.name}. Please click the invite member button to invite a new user.`}</p>
            <div className="search__empty-image">
              <img src={ImageSearch} alt="No results found" />
            </div>
          </div>
        ) : (
          <div className="company__modal">
            <h3>This user is a planner</h3>
            <p>
              We found a user who’s role is a planner and he is assigned to several other users. If you want to make
              this user the SuperAdmin of your company, please delete the assign and try again.
            </p>
            <div className="company__modal-icon">
              {!isNull(companyNewAdmin?.user?.avatar) ? (
                <img src={companyNewAdmin?.user?.avatar} alt="Avatar" />
              ) : (
                <SVGIcon icon={Man} color="#fff" />
              )}
            </div>
            <h6>
              {companyNewAdmin?.user?.first_name} {companyNewAdmin?.user?.last_name}
            </h6>
          </div>
        )}
      </Modal>
      <Modal
        open={isMember}
        small={!companyNewAdmin?.available_planner}
        inline
        reverse
        removeButton="Yes"
        button="No"
        onClick={() => {
          dispatch(setCompanyNewAdmin(null));
          setMember(false);
        }}
        remove={() => {
          dispatch(postNewOwner(companyId, companyNewAdmin?.user?.id, role_id, props));
          setMember(false);
        }}
        close={() => {
          dispatch(setCompanyNewAdmin(null));
          setMember(false);
        }}
      >
        {companyNewAdmin?.role && companyNewAdmin?.role !== 'planner' ? (
          <div className="company__modal">
            <h3>Are you sure you want to make this user the SuperAdmin of company?</h3>
            <div className="company__modal-icon">
              {!isNull(companyNewAdmin?.user?.avatar) ? (
                <img src={companyNewAdmin?.user?.avatar} alt="Avatar" />
              ) : (
                <SVGIcon icon={Man} color="#fff" />
              )}
            </div>
            <h6>
              {companyNewAdmin?.user?.first_name} {companyNewAdmin?.user?.last_name}
            </h6>
          </div>
        ) : (
          <div className="company__modal">
            <h3>This user is a planner</h3>
            <p>
              We found a user who’s role is a planner. Are you sure you want to make this user the SuperAdmin of your
              company?
            </p>
            <div className="company__modal-icon">
              {!isNull(companyNewAdmin?.user?.avatar) ? (
                <img src={companyNewAdmin?.user?.avatar} alt="Avatar" />
              ) : (
                <SVGIcon icon={Man} color="#fff" />
              )}
            </div>
            <h6>
              {companyNewAdmin?.user?.first_name} {companyNewAdmin?.user?.last_name}
            </h6>
          </div>
        )}
      </Modal>
      <Modal
        open={isOpen}
        small
        button="Ok"
        onClick={handleClick}
        close={onClose}
        title={mode === 'success' ? 'Success!' : 'Error!'}
        children={
          mode === 'success' ? (
            <p>The superadmin of the company was changed successfully.</p>
          ) : (
            <p>Something went wrong. Try again.</p>
          )
        }
      />
    </LayoutAdmin>
  );
};

export default AdminCompanyChangeAdmin;
