import React, { useState, useEffect } from 'react'
import CheckIcon from "@mui/icons-material/Check";
import PropTypes from 'prop-types'

const PasswordStrength = ({ password, handleChange, title }) => {
  const [uppercase, setUppercase] = useState(false)
  const [lowercase, setLowercase] = useState(false)
  const [minchars, setMinchars] = useState(false)
  const [digit, setDigit] = useState(false)

  useEffect(() => {
    const capRegex = new RegExp('[A-Z]')
    setUppercase(capRegex.test(password))

    const lowRegex = new RegExp('[a-z]')
    setLowercase(lowRegex.test(password))

    const digitRegex = new RegExp('[0-9]')
    setDigit(digitRegex.test(password))

    setMinchars(password.length > 7)

    // const isValid = uppercase && lowercase && minchars && digit
    // handleChange(isValid)

    // eslint-disable-next-line
  }, [password]);

	return (
	  <div className="password-strength">
      {title && <div className="password-strength__title">{title}</div>}
      <ul className='password-strength__list'>
        <li className={minchars && digit ? 'done' : ''}>
          <CheckIcon />
          <span>Use at least 8 characters, including a number</span>
        </li>
        <li className={uppercase ? 'done' : ''}>
            <CheckIcon />
            <span>An uppercase letter</span>
        </li>
        <li className={lowercase ? 'done' : ''}>
            <CheckIcon />
            <span>A lowercase&nbsp;letter</span>
        </li>
  </ul>
    </div>
	)
};

PasswordStrength.propTypes = {
  password: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  title: PropTypes.string,
};

PasswordStrength.defaultProps = {
  password: '',
};

export default PasswordStrength
