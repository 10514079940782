import React, { useEffect, lazy, Suspense, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { Button, Checkbox, FormControlLabel } from '@mui/material'
import { Formik, Form } from 'formik'
import AuthContent from '../../components/Auth/AuthContent'
import { SignUpSchema } from '../../utils/validation'
import { FormGroup, Input, PasswordStrength } from '../../components/shared/Form'
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Screen from '../../assets/images/screen-img.png'
import { registerUser, setAuthError, setPersonalInfo } from '../../redux/slices/authSlice'
import { MAIN_SITE, RECAPTCHA_SITE_KEY } from '../../constants/additional_links'
import { setOpenMenu } from '../../redux/slices/additionalSlice'
import { phoneValidation } from '../../utils/utils'
import { isEmpty, isNull } from 'lodash';

const ReCAPTCHA = lazy(() => import('react-google-recaptcha'));

const SignUp = ({ props }) => {
  const dispatch = useDispatch();
  const base = useSelector(state => state.auth.registerUser);
  const authInfo = useSelector((state) => state.auth.authInfo);
  const serverError = useSelector(state => state.auth.authError);
  const sending = useSelector(state => state.sending.isSending);
  const openMenu = useSelector(state => state.additional.menuOpen);
  const [authProps, setAuthProps] = useState(null);

  useEffect(() => {
    if (!isEmpty(authInfo)) {
      setAuthProps({
        registration: authInfo[0].value
      });
    }
  }, [authInfo]);

  useEffect(() => {
    openMenu
      ? document.body.style.overflow = 'hidden'
      : document.body.style.overflow = 'visible'
  }, [openMenu])

  useEffect(() => {
    dispatch(setAuthError({ authError: false }));
    dispatch(setOpenMenu(false))
  }, [dispatch]);

  const onSubmit = values => {
    dispatch(registerUser(values, props))
  };

  if (!isNull(authProps) && !authProps.registration) return (<Redirect to="/login" />)

  return (
    <AuthContent decor={{image: Screen}} close={MAIN_SITE}>
      <h2>Create new <br /> personal account</h2>
      <Formik
        initialValues={base}
        validationSchema={SignUpSchema}
        onSubmit={onSubmit}
      >
        {({ errors, handleChange, handleBlur, setFieldValue, values, touched, isValid, dirty }) => (
          <Form>
            <FormGroup name="first_name" labelText="First Name">
              <Input
                placeholder="Enter your name"
                name="first_name"
                type="text"
                value={values.first_name}
                onChange={(e) => {
                  handleChange(e)
                  dispatch(setPersonalInfo(values))
                }}
                onBlur={handleBlur}
                hasError={!!errors.first_name && touched.first_name}
              />
              {touched.first_name && errors.first_name && <span className="form__msg-error">{errors.first_name}</span>}
            </FormGroup>
            <FormGroup name="last_name" labelText="Last Name">
              <Input
                placeholder="Enter your last name"
                name="last_name"
                type="text"
                value={values.last_name}
                onChange={(e) => {
                  handleChange(e)
                  dispatch(setPersonalInfo(values))
                }}
                onBlur={handleBlur}
                hasError={!!errors.last_name && touched.last_name}
              />
              {touched.last_name && errors.last_name && <span className="form__msg-error">{errors.last_name}</span>}
            </FormGroup>
            <FormGroup name="email" labelText="Email address">
              <Input
                placeholder="example@email.com"
                type="email"
                name="email"
                onChange={(e) => {
                  handleChange(e)
                  dispatch(setPersonalInfo(values))
                  serverError?.email && dispatch(setAuthError({ authError: {
                    ...serverError,
                    email: ""
                  }}));
                }}
                value={values.email}
                onBlur={handleBlur}
                hasError={!!errors.email && touched.email}
              />
              {touched.email && errors.email 
              ? <span className="form__msg-error">{errors.email}</span>
              : serverError?.email 
              ? <span className="form__msg-error">{serverError?.email}</span> 
              : ""}
            </FormGroup>
            <FormGroup name="phone" labelText="Phone number">
              <Input
                placeholder="(XXX) XXX-XXXX"
                name="phone"
                type="text"
                onChange={(e) => {
                  setFieldValue(e.target.name, phoneValidation(e.target.value))
                  dispatch(setPersonalInfo(values))
                  serverError?.phone && dispatch(setAuthError({ authError: {
                    ...serverError,
                    phone: ""
                  }}));
                }}
                value={values.phone.toString()}
                onBlur={handleBlur}
                hasError={!!errors.phone && touched.phone}
              />
              {touched.phone && errors.phone 
              ? <span className="form__msg-error">{errors.phone}</span> 
              : serverError?.phone 
              ? <span className="form__msg-error">{serverError?.phone}</span> 
              : ""}
            </FormGroup>
            <FormGroup name="password" labelText="Create password">
              <Input
                name="password"
                placeholder="Password"
                type="password"
                onChange={(e) => {
                  handleChange(e)
                  dispatch(setPersonalInfo(values))
                }}
                value={values.password}
                onBlur={handleBlur}
                hasError={!!errors.password && touched.password}
              />
              {touched.password && errors.password && <span className="form__msg-error">{errors.password}</span>}
            </FormGroup>
            <FormGroup name="password_confirmation" labelText="Confirm Password">
              <Input
                name="password_confirmation"
                placeholder="Confirm Password"
                type="password"
                onChange={(e) => {
                  handleChange(e)
                  dispatch(setPersonalInfo(values))
                }}
                value={values.password_confirmation}
                onBlur={handleBlur}
                hasError={!!errors.password_confirmation && touched.password_confirmation}
              />
              {touched.password_confirmation && errors.password_confirmation && <span className="form__msg-error">{errors.password_confirmation}</span>}
            </FormGroup>
            <PasswordStrength password={values.password} title="Password should consist of:" />
            <FormGroup name="agreed">
              <FormControlLabel
                className="MuiFormControlLabel-root--small"
                control={
                  <Checkbox checked={values.agreed || false}
                            onChange={(e) => {
                              handleChange(e)
                              dispatch(setPersonalInfo(values))
                            }}
                            name="agreed" />
                  }
                label={<>I agree with  <Link to="/terms">Terms and Conditions</Link></>}
              />
            </FormGroup>
            <FormGroup name="recaptcha">
              <Suspense fallback={<p>Loading...</p>}>
                <ReCAPTCHA
                  sitekey={RECAPTCHA_SITE_KEY}
                  onChange={value => setFieldValue('recaptcha', value)}
                />
                {touched.recaptcha && errors.recaptcha && <span className="form__msg-error">{errors.recaptcha}</span>}
              </Suspense>
            </FormGroup>
            <div className="form__button">
              <Button variant="contained" size="large" color="primary" type="submit" disabled={!(isValid && dirty) || sending}>
                Sign up
                {sending && <span className="spinner" />}
              </Button>
            </div>
            <div className="auth__form-info">
              Already have  an account?
              <Link to="/login" className="auth__form-info-link">Sign in here <ChevronRightIcon /></Link>
            </div>
          </Form>
        )}
      </Formik>
    </AuthContent>
  );
};

export default SignUp;
