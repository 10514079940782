import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Redirect } from 'react-router-dom'
import moment from 'moment';
import Slider from 'react-slick'
import Layout from '../../components/Layout/Layout'
import { BackButton } from '../../components/shared/Buttons'
import ModalBox from '../../components/shared/Modal/Modal'
import Loading from '../../components/shared/Loading/Loading'
import SVGIcon from '../../components/shared/SVGIcon/SVGIcon'
import Linkedin from '../../assets/images/icons/LinkedIn.svg'
import staticAuthorAvatar from '../../assets/images/static-author-avatar.jpg'
import BlogCard from '../../components/Cards/BlogCard'
import { fetchPostById, fetchAuthors, removePost } from '../../redux/slices/blogSlice'
import { setIsOpen } from '../../redux/slices/modalSlice'
import isNull from 'lodash/isNull'

const BlogDetails = ({ props }) => {
  const dispatch = useDispatch()
  const userId = useSelector(state => state.user.user.id)
  const loading = useSelector(state => state.blog.isLoading)
  const { permissions } = useSelector(state => state.companies.company.info);
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const { post, related } = useSelector(state => state.blog.currentPost)
  const isOpen = useSelector(state => state.modal.isOpen)
  const close = () => dispatch(setIsOpen({isOpen: false}))
  const { postId }  = useParams()

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchPostById(null, postId))
      await dispatch(fetchAuthors())
    }
    if (userId && postId) {
      fetch()
    }

    // eslint-disable-next-line
  }, [userId, postId])

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  const deletePost = id => {
    dispatch(removePost(id, props))
    setOpenDeleteModal(false)
  }

  if (!isNull(permissions?.blog) && !permissions?.blog) return (<Redirect to="/404" />)

  return (
    <Layout>
      <BackButton title="Back to  Blog" path="/blog" />
      <div className="container container--large">
        {loading
          ? <Loading/>
          : <>
              <div className="article">
                <div className="article__preview">
                  <div className="article__text">
                    <h2>{post && post.title}</h2>
                    <p>{post && post.short_description}</p>
                    <div className="article__author">
                    <img src={post && !(isNull(post.author.avatar)) ? post.author.avatar : staticAuthorAvatar} alt='Author avatar' className="article__author-avatar"/>
                    <div>
                      <span>By </span>
                      <button onClick={() => dispatch(setIsOpen({isOpen: !!post.author}))}>
                        {post && post.author ?
                          `${post.author.first_name} ${post.author.last_name}` :
                          'No authors'
                        },
                        </button>
                      <span> {moment(post?.catalog?.created_at).format('MMMM DD, YYYY')}</span>
                    </div>
                    </div>
                  </div>
                  <div className="article__image">
                    {post && post.image && <img src={post.image} alt={post.title}/>}
                  </div>
                </div>
                {post && post.content && <div className="article__content" dangerouslySetInnerHTML={{ __html: post.content }} />}
              </div>

              {related && related.length > 0 &&
                <Slider {...settings} className='article__slider'>
                  {related.map(item => (
                    <div key={item.id}>
                      <BlogCard
                        active={item.active}
                        title={item.title}
                        category={item.catalog && item.catalog.name ? item.catalog.name : 'Uncategorized'}
                        path={`/blog/${item.id}`}
                        image={item.image}
                      />
                    </div>
                  ))}
                </Slider>
              }
            </>
        }
      </div>
      <ModalBox
        small
        inline
        open={openDeleteModal}
        close={() => setOpenDeleteModal(false)}
        title='Do you want to delete this article?'
        button="No"
        onClick={() => setOpenDeleteModal(false)}
        removeButton="Yes"
        remove={() => deletePost(postId)}
      >
        <p>After deletion you will not be able to return the article.</p>
      </ModalBox>

      <ModalBox
        small
        open={isOpen}
        customClass={'modal__position'}
        close={close}>
        <div className="article__author-box">
          <div className="article__author-box-avatar">
            <img src={post && !(isNull(post.author.avatar)) ? post.author.avatar : staticAuthorAvatar} alt='Author avatar'/>
          </div>
          <div className="article__author-box-name">
            {post && post.author && `${post.author.first_name} ${post.author.last_name}`}
          </div>
          <div className="article__author-box-position">
            {post && post.author && post.author.author_title}
          </div>
          <div className="article__author-box-mail">
            {post && post.author && post.author.author_email}
          </div>
          <a href={post && post.author && post.author.author_social_link} className="article__author-box-linkedin" target="_blank" rel="noopener noreferrer">
            <SVGIcon icon={Linkedin} />
          </a>
          <div className="article__author-box-description">
            <h6>Biography</h6>
            {post && post.author && post.author.author_biography}
          </div>
        </div>
      </ModalBox>
    </Layout>
  );
};

export default BlogDetails;
