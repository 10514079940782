import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Button } from '@mui/material'
import Tooltip from '../shared/Tooltip/Tooltip'


const CalculatorCard = ({ name, tooltip, icon, path, className, setActiveItem, isActive}) => {
  const history = useHistory()

  return (
    <div className={`calculator-card ${isActive ? 'calculator-card--active' : ''}`}>
      <div className="calculator-card__icon">
        <img src={icon} alt={name} />
      </div>
      <h6 className="calculator-card__title">{name}</h6>
      <div className="calculator-card__info">
        <Button variant="contained" size="small" color="primary" onClick={() => history.push(`/calculators/${path}`)}>Details</Button>
        <Tooltip
          customHandler={setActiveItem}
          title={tooltip}
          className={className}
        />
      </div>
    </div>
  )
}

CalculatorCard.propTypes = {
  name: PropTypes.string,
  tooltip: PropTypes.node,
  icon: PropTypes.string,
  path: PropTypes.string,
  isActive: PropTypes.bool,
  setActiveItem:  PropTypes.func,
}

export default CalculatorCard
