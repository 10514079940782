import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from '@mui/material'

export const TabPanel = ({ children, index, value }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
    >
      {value === index && (
        <>{children}</>
      )}
    </div>
  );
}


const TabsCustom = ({ tabs, value, handleChange, center, variant, orientation }) => {
  return (
    <div >
      <Tabs
        orientation={orientation}
        className={`${center ? 'tabs-center' : ''}`}
        variant={variant ? variant : 'fullWidth'}
        value={value}
        onChange={handleChange}
        aria-label="nav tabs example"
      >
        {tabs && tabs.map((tab, index) => (
          <Tab
            key={`tab-${index}`}
            component="a"
            id={`nav-tab-${index}`}
            aria-controls={`nav-tabpanel-${index}`}
            label={tab.title}
            disableRipple
          />
        ))}
      </Tabs>
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

TabsCustom.propTypes = {
  tabs: PropTypes.array,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  center: PropTypes.bool
};

export default TabsCustom
