import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'classnames'
import TooltipCustom from '../Tooltip/Tooltip'
import IconButton from "@mui/material/IconButton";
import Visibility from '../../../assets/images/icons/eye.svg'
import VisibilityOff from '../../../assets/images/icons/eye-close.svg'
import SVGIcon from '../../../components/shared/SVGIcon/SVGIcon'

const Input = ({
  id,
  value,
  color,
  name,
  disabled,
  readOnly,
  type,
  onChange,
  placeholder,
  hasError,
  onBlur,
  onFocus,
  onKeyPress,
  info,
  small,
  smallHeight,
  xSmallHeight,
  iconName,
  max,
  min,
  pattern,
  step,
  prefixText,
  className
}) => {
  const [inputType, setInputType] = useState(type);
  const [prefixPadding, setPrefixPadding] = useState(16);
  const prefixRef = useRef(null);

  const toggleType = () => {
    const newType = inputType === 'password' ? 'text' : 'password';
    setInputType(newType)
  }

  useEffect(() => {
    prefixText && setPrefixPadding(prefixRef.current?.offsetWidth + 20)
  }, [prefixRef, prefixText])

  const handleMouseDownPassword = e => {
    e.preventDefault()
  }

  const classNames = clsx('form__input', className, {
    'form__input--error' : hasError,
    'form__input--alt' : info || inputType === 'password' || iconName,
    'form__input--small' : smallHeight,
    'form__input--xsmall' : xSmallHeight
  })

  return (
    <div
      className={`form__area${small ? " form__area--small" : ""} ${
        color ? `form__input-colored form__input-colored--${color}` : ""
      }`}
    >
        {prefixText && (
            <span ref={prefixRef} className="form__input-prefix">{prefixText}</span>
        )}
      <input
        style={{ paddingLeft: prefixPadding }}
        readOnly={readOnly}
        id={id ? id : name}
        name={name}
        placeholder={placeholder}
        className={classNames}
        onChange={onChange}
        type={inputType}
        value={value}
        onFocus={onFocus}
        disabled={disabled}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        max={max}
        min={min}
        inputMode={type === "number" ? "decimal" : "text"}
        pattern={pattern}
        step={step || 1}
      />
      {type === "password" && (
        <IconButton
          onClick={toggleType}
          onMouseDown={handleMouseDownPassword}
          className="form__input-icon"
        >
          {inputType === "text" ? (
            <SVGIcon icon={Visibility} color="#000" />
          ) : (
            <SVGIcon icon={VisibilityOff} color="#000" />
          )}
        </IconButton>
      )}
      {iconName && (
        <SVGIcon className="form__icon" icon={iconName} color="#8C8C8C" />
      )}
      {info && (
        <TooltipCustom
          size="small"
          title={info.content}
          className="form__input-icon"
        />
      )}
    </div>
  );
};

Input.defaultProps = {
  type: 'text',
};

Input.propTypes = {
  onChange: PropTypes.func,
  value:  PropTypes.any,
  type: PropTypes.string,
  name: PropTypes.string,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  smallHeight: PropTypes.bool,
  xSmallHeight: PropTypes.bool,
  className: PropTypes.string,
  prefixText: PropTypes.string
};

export default Input;
