import React, { useEffect, useState } from 'react'
import WrapWidget from './WrapWidget'
import WidgetPlaceholder from './WidgetPlaceholder'
import Image from '../../assets/images/image-analysis.png'
import Slider from 'react-slick'
import { Bar } from 'react-chartjs-2'
import { options } from '../../utils/optionsBar'
import isNull from 'lodash/isNull'

const WidgetAnalysis = ({ loading, items, className, addClassName }) => {
  const [isPlaceholder, setIsPlaceholder] = useState(true);
  useEffect(() => {
    (items && items.length < 1) ? setIsPlaceholder(true) : setIsPlaceholder(false)
  }, [items])
  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }
  const backgroundColor = ['#FDB5A1', '#215E59', '#D4654D' ]
  const [isMobile, setIsMobile] = useState(false);
  const mobileView = 795


  const handleMobileSize = () => {
    (mobileView >= window.innerWidth) ? setIsMobile(true) : setIsMobile(false)
  }

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true)
    window.addEventListener("resize", handleMobileSize)

    return () => window.removeEventListener("resize", handleMobileSize)
  }, [])


  const dataAnalysis = data => {
    if (data && !(isNull(data))) {
      const { labels, datasets } = data

      return {
        labels: labels,
        datasets: datasets && datasets.map((item, i) => {
          return {
            label: item.label,
            data: [...item.data],
            backgroundColor: backgroundColor[i],
            maxBarThickness: 100
          }})
      }
    }
  }

  return (
    <WrapWidget
        title="Analyze results"
        link={{
          path: '/analysis',
          title: 'View all'
        }}
        placeholder={
          <WidgetPlaceholder
            title="Sorry, but we cannot analyze your financial health against benchmarks quite yet."
            content="Please complete the input process first."
            image={Image}
            button={{
              title: 'Input',
              href: "/input"
            }}
          />
        }
        className={`widget--analysis ${className || ''}`}
        addClassName={addClassName}
        isPlaceholder={isPlaceholder}
        loading={loading}
    >
      {items && items.length > 0 &&
      <div className="analysis__box">
        <Slider {...settings} className='analysis__box-slider'>
          {items.map((item, i) =>
            <div key={`bar-${i}`}>
              <div className="analysis__box-title">
                <div>
                  <h6>{item.title}</h6>
                </div>
                <div className="analysis__box-legend">
                  <div className="analysis__box-legend-label analysis__box-legend-label-color2">
                    You
                  </div>
                  <div className="analysis__box-legend-label">
                    Client аverage
                  </div>
                  <div className="analysis__box-legend-label analysis__box-legend-label-color3">
                    Client Median
                  </div>
                </div>
              </div>
              <div className="analysis__box-content">
                <Bar
                  data={dataAnalysis(item.data)}
                  // onElementsClick={handleClick}
                  options={options(null, true, true, true, isMobile ? 15 : 30)}
                  height={isMobile ? 170 : 120}
                />
              </div>
            </div>
          )}
        </Slider>
      </div>
      }
    </WrapWidget>
  );
};

export default WidgetAnalysis;
