import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { BackButton } from '../../../components/shared/Buttons'
import Layout from '../../../components/Layout/Layout'
import { Button } from '@mui/material'
import isArray from 'lodash/isArray'
import { fetchData } from '../../../redux/slices/childrenSlice'
import TreeSubItem from '../../../components/Tree/TreeSubItem'
import { getPercent } from '../../../utils/utils'
import TreeImage from '../../../assets/images/tree/about/About-tree.png'
import ModalBox from '../../../components/shared/Modal/Modal'
import { setIsOpen } from '../../../redux/slices/modalSlice'
import { fetchTotalPercent } from '../../../redux/slices/percentagesSlice'
import { fetchUserFamilyChildren } from '../../../redux/slices/userSlice'
import TreeCount from '../../../components/Tree/TreeCount'

const Children = ({ props }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useSelector(state => state.user.user)
  const isBasic = useSelector((state) => state.user.user.plan_type === 1);
  const children = useSelector(state => state.children.current)
  const currentEducation = useSelector(state => state.children.currentEducation)
  const isOpen = useSelector(state => state.modal.isOpen)
  const percents = useSelector(state => state.percentages.percents)
  const totalPercent = useSelector(state => state.percentages.total)
  const loading = useSelector(state => state.sending.isSending)
  const [isMobile, setIsMobile] = useState(false)
  const mobileView = 999

  const handleResize = () => {
    (mobileView >= window.innerWidth) ? setIsMobile(true) : setIsMobile(false)
  }

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true)
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    async function fetch() {
      Promise.all([
        dispatch(fetchUserFamilyChildren(id)),
        dispatch(fetchData()),
        //dispatch(fetchPercentages()),
        dispatch(fetchTotalPercent(isBasic))
      ])
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const close = () => dispatch(setIsOpen({isOpen: false}))

  const handleClick = () => {
    if(currentEducation && currentEducation.length > 9) {
      return dispatch(setIsOpen({isOpen: true}))
    }

    return history.push('/dependents/create')
  }

  const profile = getPercent(percents, 'profile')

  return (
    <Layout
      totalPercent={totalPercent}
      tree
      isBlockedPage={percents && profile === 0}
    >
      <div className="tree__sticky-holder">
        <BackButton
          title={isMobile ? "Back" : "Back to Tree"}
          path={isMobile ? "/about-me" : "/input"}
          className="back-button--position"
        />
        <div className="tree__title tree__title--progress-bar">
          <div className="tree__title-main">
            <h2>Current Dependents</h2>
            <TreeCount
              value={children && children.length ? 100 : "0"}
              loading={loading}
              big={!isMobile}
            />
          </div>
          {!isMobile && (
            <>
              <p className="tree__title-other">
                Fill in information to complete this step.
              </p>
              <Button
                variant="contained"
                className="tree-bar"
                size="small"
                onClick={handleClick}
                color="primary"
              >
                Add Dependent
              </Button>
            </>
          )}
        </div>
        <div className="tree tree--sub-page">
          {!isMobile && (
            <div className="tree__base">
              <img src={TreeImage} alt="ValueSoft Tree Insurance" />
            </div>
          )}
          {children &&
            isArray(children) &&
            children.map((item, i) => (
              <React.Fragment key={item.id}>
                <TreeSubItem
                  key={item.id}
                  path={`/dependents/${item.id}`}
                  title={item.name}
                  count={100}
                  value={`children-${i + 1}`}
                />
              </React.Fragment>
            ))}
        </div>

        {isMobile && (
          <div className="tree__add-button">
            <Button
              variant="contained"
              size="small"
              onClick={handleClick}
              color="primary"
            >
              {!isMobile && "Add child"}
            </Button>
          </div>
        )}
      </div>

      <ModalBox
        small
        open={isOpen}
        close={close}
        onClick={() => {
          dispatch(setIsOpen({ isOpen: false }));
          history.push("/input");
        }}
        button="Back to tree"
        title="Sorry"
        children={<p>The limit of adding new sections is reached.</p>}
      />
    </Layout>
  );
}

export default Children