import { createSlice } from '@reduxjs/toolkit'
import { RetirementFunding, EmploymentSabbatical, Objectives } from '../../api/Api'
import { setSending } from './sendingSlice'
import { convertDecimal } from '../../utils/utils'
import { setNewTasksCount } from './taskSlice'
import { setIsOpen } from './modalSlice'
import { setIsRelogin } from "./loadingSlice";

const futureSlice = createSlice({
  name: 'future',
  initialState: {
    retirement: {
      user: {
        contribution_401k: null,
        roth_401k: null,
        roth_ira: null,
        traditional_ira: null,
        simple_ira: null,
        solo_401k: null,
        employer_match: '',
        match_amount: null,
        additional_info: '',
      },
      spouse: {
        contribution_401k: null,
        roth_401k: null,
        roth_ira: null,
        traditional_ira: null,
        simple_ira: null,
        solo_401k: null,
        employer_match: '',
        match_amount: null,
        additional_info: '',
      },
    },
    sabbatical: {
      user: {
        planning_sabbatical: false,
        sabbatical_last: '',
        salary_percentage: '',
        workforce_percent: '',
        current_refund: '',
      },
      spouse: {
        planning_sabbatical: false,
        sabbatical_last: '',
        salary_percentage: '',
        workforce_percent: '',
        current_refund: '',
      },
    },
    objectives: null,
    isLoading: false
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setRetirementUser: (state, action) => {
      state.retirement.user = {
        ...state.retirement.user,
        ...action.payload,
      };
    },
    setRetirementSpouse: (state, action) => {
      state.retirement.spouse = {
        ...state.retirement.spouse,
        ...action.payload,
      };
    },
    setSabbaticalUser: (state, action) => {
      state.sabbatical.user = {
        ...state.sabbatical.user,
        ...action.payload,
      };
    },
    setSabbaticalSpouse: (state, action) => {
      state.sabbatical.spouse = {
        ...state.sabbatical.spouse,
        ...action.payload,
      };
    },
    setObjectives: (state, action) => {
      state.objectives = action.payload;
    },
  },
});

export const fetchRetirementFunding = isSpouse => async (dispatch) => {
  try {
    await dispatch(setLoading(true));

    if (isSpouse) {
      const response = await RetirementFunding.getSpouse();
      await dispatch(setRetirementSpouse(response.data.data));
    } else {
      const response = await RetirementFunding.get();
      await dispatch(setRetirementUser(response.data.data));
    }

    await dispatch(setLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postRetirementFunding = (data, props, isSpouse) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    let response = null
    let { contribution_401k, roth_401k, roth_ira, traditional_ira, simple_ira, solo_401k, match_amount } = data
    contribution_401k = contribution_401k ? convertDecimal(contribution_401k) : '';
    roth_401k         = roth_401k ? convertDecimal(roth_401k) : '';
    roth_ira          = roth_ira ? convertDecimal(roth_ira) : '';
    traditional_ira   = traditional_ira ? convertDecimal(traditional_ira) : '';
    simple_ira        = simple_ira ? convertDecimal(simple_ira) : '';
    solo_401k         = solo_401k ? convertDecimal(solo_401k) : '';
    match_amount      = match_amount ? match_amount.slice(0, -1) : '';

    if (isSpouse) {
      response = await RetirementFunding.postSpouse({...data, contribution_401k, roth_401k, roth_ira, traditional_ira, simple_ira, solo_401k, match_amount});
    } else {
      response = await RetirementFunding.post({...data, contribution_401k, roth_401k, roth_ira, traditional_ira, simple_ira, solo_401k, match_amount});
    }

    await dispatch(setSending({ isSending: false }));
    await dispatch(setIsOpen({ isOpen: true }))
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
  } catch (e) {
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}


export const fetchEmploymentSabbatical = isSpouse => async (dispatch) => {
  try {
    await dispatch(setLoading(true));

    if (isSpouse) {
      const response = await EmploymentSabbatical.getSpouse();
      await dispatch(setSabbaticalSpouse(response.data.data));
    } else {
      const response = await EmploymentSabbatical.get();
      await dispatch(setSabbaticalUser(response.data.data));
    }

    await dispatch(setLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postEmploymentSabbatical = (data, props, isSpouse) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    let response = null
    let { salary_percentage, workforce_percent, current_refund } = data
    salary_percentage   = salary_percentage ? salary_percentage.slice(0, -1) : '';
    current_refund      = current_refund ? current_refund.slice(0, -1) : '';
    workforce_percent   = workforce_percent ? workforce_percent.slice(0, -1) : '';


    if (isSpouse) {
      response = await EmploymentSabbatical.postSpouse({...data, salary_percentage, workforce_percent, current_refund});
    } else {
      response = await EmploymentSabbatical.post({...data, salary_percentage, workforce_percent, current_refund});
    }

    await dispatch(setSending({ isSending: false }));
    await dispatch(setIsOpen({ isOpen: true }))
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}



export const fetchObjectives = () => async (dispatch) => {
  try {
    const response = await Objectives.get();
    await dispatch(setObjectives(response.data.data));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchObjectiveById = id => async (dispatch) => {
  try {
    const response = await Objectives.show(id);
    await dispatch(setObjectives(response.data.data))
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postObjective = (data, props, id, isEdit) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    let response = null
    let { value_purchased, estimated_value, down_payment, interest_rate } = data
    value_purchased = convertDecimal(value_purchased)
    estimated_value = convertDecimal(estimated_value)
    down_payment    = down_payment ? down_payment.slice(0, -1) : ''
    interest_rate   = interest_rate ? interest_rate.slice(0, -1) : ''

    if(isEdit) {
      response = await Objectives.put(id, {...data, estimated_value, value_purchased, down_payment, interest_rate});
    } else {
      response = await Objectives.post({...data, estimated_value, value_purchased, down_payment, interest_rate});
    }
    await dispatch(fetchObjectives());
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
    await dispatch(() => props.history.push('/objectives'));
    await dispatch(setSending({ isSending: false }));
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const removeObjective = (id, props) => async (dispatch) => {
  try {
    const response = await Objectives.del(id);
    await dispatch(fetchObjectives());
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
    await dispatch(() => props.history.push('/objectives'));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const deactivateObjective = (props) => async (dispatch) => {
  try {
    await Objectives.deactivate();
    await dispatch(() => props.history.push("/input"));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const {
  setRetirementUser,
  setRetirementSpouse,
  setSabbaticalUser,
  setSabbaticalSpouse,
  setObjectives,
  setLoading
} = futureSlice.actions

export default futureSlice.reducer