import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { BackButton } from '../../../components/shared/Buttons'
import Layout from '../../../components/Layout/Layout'
import TreeSubItem from '../../../components/Tree/TreeSubItem'
import TreeImage from '../../../assets/images/tree/finances/Finance-tree.png'
import { fetchTotalPercent } from '../../../redux/slices/percentagesSlice'
import { getPercent } from '../../../utils/utils'
import TreeCount from '../../../components/Tree/TreeCount'
import { fetchAnalysisTasks } from '../../../redux/slices/taskSlice'

const Worth = ({ props }) => {
  const dispatch = useDispatch()
  const { id } = useSelector(state => state.user.user)
  const isBasic = useSelector((state) => state.user.user.plan_type === 1);
  const totalPercent = useSelector(state => state.percentages.total)
  const percents = useSelector(state => state.percentages.percents)
  const loading = useSelector(state => state.sending.isSending)
  const analysis = useSelector(state => state.task.analysis)
  const [isMobile, setIsMobile] = useState(false)
  const mobileView = 999
  const handleResize = () => {
    (mobileView >= window.innerWidth) ? setIsMobile(true) : setIsMobile(false)
  }

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true)
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, []);

  useEffect(() => {
    async function fetch() {
      //await dispatch(fetchPercentages())
      await dispatch(fetchAnalysisTasks())
      await dispatch(fetchTotalPercent(isBasic))
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const worth       = getPercent(percents, 'worth');
  const assets      = getPercent(percents, 'your-assets');
  const liabilities = getPercent(percents, 'your-liabilities');
  const profile     = getPercent(percents, 'profile')

  return (
    <Layout totalPercent={totalPercent} tree isBlockedPage={percents && profile === 0}>
      <div className="tree__sticky-holder">
        <BackButton title={isMobile ? 'Back' : 'Back to Tree'} path={isMobile ? '/finance' : "/input"} className="back-button--position" />
        <div className="tree__title tree__title--progress-bar">
          <div className="tree__title-main">
            <h2>Current net worth</h2>
            <TreeCount
              value={worth}
              loading={loading}
              big={!isMobile}
            />
          </div>
          {!isMobile && (
            <p className="tree__title-other">Fill in information to complete this step.</p>
          )}
        </div>
        <div className="tree tree--sub-page">
          {!isMobile && (
            <div className="tree__base">
              <img src={TreeImage} alt="ValueSoft Tree Worth" />
            </div>
          )}
          <TreeSubItem path="/worth/assets"
                       title="Your assets"
                       value="your-assets"
                       count={assets}
                       isLoading={loading}
          />
          <TreeSubItem path="/worth/liabilities"
                       title="Your liabilities"
                       value="your-liabilities"
                       count={liabilities}
                       isLoading={loading}
          />
          {isMobile && <div className="tree__task">
            <Link to="/tasks">{analysis && analysis.openCount ? analysis.openCount : '0'}</Link>
          </div>}
        </div>
      </div>
    </Layout>
  )
}

export default Worth