import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../../components/Layout/Layout'
import { BackButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import LayoutForm from '../../../components/Layout/LayoutForm'
import { Button, FormControlLabel, Radio, RadioGroup, TextareaAutosize } from '@mui/material'
import {FormGroup, InputCurrency} from '../../../components/shared/Form'
import { fetchInsuranceUmbrella, postInsuranceUmbrella } from '../../../redux/slices/insuranceSlice'
import { normalizeBoolean } from '../../../utils/utils'
import { fetchPercentages } from '../../../redux/slices/percentagesSlice'
import Modal from '../../../components/shared/Modal/Modal'
import ProgressCircle from '../../../components/shared/ProgressBar/ProgressCircle'
import { setIsOpen } from '../../../redux/slices/modalSlice'
import { insuranceUmbrellaSchema } from '../../../utils/validationProtection'


const InsuranceUmbrella = ({ props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.user.user);
  const insurance = useSelector(state => state.insurance.umbrella);
  const sending = useSelector(state => state.sending.isSending);
  const percents = useSelector(state => state.percentages.percents);
  const isOpen = useSelector(state => state.modal.isOpen);

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchPercentages({name: 'umbrella-insurance'}));
      await dispatch(fetchInsuranceUmbrella());
    }
    if (id) {
      fetch()
    }

    // eslint-disable-next-line
  }, [id])

  const onSubmit = (values) => {
    dispatch(postInsuranceUmbrella(values, props))
  }

  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }))
  }

  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }))
    history.push('/insurance')
  }


  return (
    <Layout>
      <LayoutForm small>
        <BackButton title="Back to Basic Insurance" />
        <PageTitle
          title="Umbrella insurance"
          content="Umbrella insurance is designed to give one added liability protection above and beyond the limits on homeowners, auto, and watercraft personal insurance pocies."
          status={percents && percents.percent ? percents.percent : '0'}
          info={
            <div>Umbrella insurance is designed to give one added liability protection above and beyond the limits on homeowners, auto, and watercraft personal insurance policies. With an umbrella policy, depending on the insurance company, one can add an additional 1-5 million in liability protection. This protection is designed to “kick-in” when the liability on other current policies has been exhausted.</div>
          }
        />
        <div className="layout-form__content-holder">
          <Formik
            enableReinitialize
            initialValues={insurance}
            validationSchema={insuranceUmbrellaSchema}
            onSubmit={onSubmit}
          >
            {({ errors, handleChange, handleBlur, values, setFieldValue }) => (
              <Form>
                <FormGroup
                  name="umbrella_insurance"
                  labelText="Do you own an umbrella insurance policy?	"
                >
                  <RadioGroup
                    name="umbrella_insurance"
                    value={values.umbrella_insurance || false}
                    onChange={(e) => {
                      setFieldValue('umbrella_insurance', normalizeBoolean(e.target.value))
                      !values.umbrella_insurance && setFieldValue('current_face_value', '')
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                </FormGroup>
                <FormGroup name="current_face_value" labelText="Current face value of umbrella insurance policy (optional):">
                  <InputCurrency
                    icon
                    placeholder="Type your Current face value of umbrella "
                    type="text"
                    name="current_face_value"
                    onChange={handleChange}
                    value={values.umbrella_insurance ? values.current_face_value : ''}
                    onBlur={handleBlur}
                    disabled={!values.umbrella_insurance}
                    hasError={!!errors.current_face_value}
                  />
                  {errors.current_face_value && <span className="form__msg-error">{errors.current_face_value}</span>}
                </FormGroup>
                <h5 className="mt--medium">Additional info</h5>
                <FormGroup name="additional_info" labelText="Is there any other Basic Insurance information that you believe is important for ValueSoft to know about? If so, please let us know about it:">
                  <TextareaAutosize
                    maxLength="200"
                    name="additional_info"
                    value={values.additional_info || ''}
                    className="form__textarea"
                    placeholder="Maximum characters: 200"
                    onChange={handleChange}
                  />
                </FormGroup>

                <div className="form__button form__button--center">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Save
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <Modal
          open={isOpen}
          small
          button="Done"
          onClick={handleClick}
          close={onClose}
        >
          <h3>Good Job!</h3>
          <p>Your progress increased 100%</p>
          <ProgressCircle amount={100} small />
        </Modal>
      </LayoutForm>
    </Layout>
  );
};

export default InsuranceUmbrella;
