import React, { useEffect } from 'react'
import * as Yup from 'yup'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { Button } from '@mui/material'
import ModalBox from '../../components/shared/Modal/Modal'
import CategoryCard from '../../components/Cards/CategoryCard'
import { FormGroup, Input } from '../../components/shared/Form'
import { fetchCatalog, fetchCatalogById, createCatalog, updateCatalog, removeCatalog, setIsOpenModal, setError } from '../../redux/slices/blogSlice'
import { setMode } from '../../redux/slices/modeSlice'
import { setIsOpen } from '../../redux/slices/modalSlice'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required')
      .test('isNew', 'This value is reserved', (value) => {
        return value && value.toLowerCase() !== 'new';
      })
})

const AdminEditCategory = ({ props, isAdmin }) => {
  const dispatch = useDispatch()
  const { companyId } = useParams();
  const { id } = useSelector(state => state.user.user)
  const { catalog, currentCatalog, isOpenModal, error } = useSelector(state => state.blog)
  const mode = useSelector(state => state.mode.mode)
  const isOpen = useSelector(state => state.modal.isOpen)

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchCatalog(companyId, isAdmin))
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const close = () => {
    dispatch(setMode(null))
    dispatch(setError(null))
    dispatch(setIsOpen({isOpen: false}))
    dispatch(setIsOpenModal(false))
  }

  const onEdit = id => {
    dispatch(setMode('edit'))
    dispatch(fetchCatalogById(companyId, id))
    dispatch(setIsOpen({ isOpen: true }))
  }

  const createCategory = values => {
    dispatch(createCatalog(companyId, values, isAdmin))
  }

  const updateCategory = values => {
    dispatch(updateCatalog(companyId, currentCatalog.id, values, isAdmin))
  }

  return (
    <>
      {catalog &&
        <div className="grid grid-2">
          {catalog.map(item =>
            <div key={item.id}>
              <CategoryCard
                catalogId={item.id}
                companyId={companyId}
                name={item.name}
                onEdit={onEdit}
                onDelete={removeCatalog}
              />
            </div>
          )}
        </div>
      }
      <ModalBox
        open={isOpen}
        close={close}
        title={mode === 'edit' ? 'Edit category' : 'Create category'}
      >
        <Formik
          enableReinitialize
          initialValues={mode === 'edit' && currentCatalog
            ? {...currentCatalog}
            : {name: ''}
          }
          validationSchema={validationSchema}
          onSubmit={mode === 'edit' ? updateCategory : createCategory}
        >
          {({ errors, handleChange, handleBlur, values }) => (
            <Form>
              <FormGroup name="name" labelText="Category">
                <Input
                  name="name"
                  placeholder="Enter Name"
                  type="text"
                  onChange={handleChange}
                  value={values.name || ''}
                  onBlur={handleBlur}
                  hasError={!!errors.name}
                />
                {errors.name && <span className="form__msg-error">{errors.name}</span>}
                {error && <span className="form__msg-error">{error}</span>}
              </FormGroup>
              <div className="form__button">
                <Button variant="contained" size="large" color="primary" type="submit">
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBox>
      <ModalBox
        small
        open={isOpenModal}
        close={close}
        onClick={close}
        button="Got it!"
        title="Sorry"
        children= {<p>{error}</p>}
      />
    </>
  )

}

export default AdminEditCategory
