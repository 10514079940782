import React, { useEffect, useState } from "react";
import LayoutAdmin from "../../components/Layout/LayoutAdmin";
import { useParams } from 'react-router-dom'
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Input, FormGroup, FormSelect } from "../../components/shared/Form";
import { Button, FormControl } from "@mui/material";
import { BackButton } from '../../components/shared/Buttons'
import { AdminCompanyMemberSchema } from "../../utils/validation";
import { postCompanyMemberInvite, fetchCompany  } from '../../redux/slices/companiesSlice';

const rolesList = [
  {
    id: 4,
    key: 4,
    value: 'superadmin_company',
    label: 'Company Instance SuperAdmin',
  },
  {
    id: 3,
    key: 3,
    value: 'company_admin',
    label: 'Сompany Admin',
  },
  {
    id: 2,
    key: 2,
    value: 'planner',
    label: 'Planner',
  },
  {
    id: 1,
    key: 1,
    value: 'user',
    label: 'Member',
  }
];

const AdminCompanyInviteMember = ({ props, isAdmin }) => {
  const dispatch = useDispatch();
  const sending = useSelector((state) => state.sending.isSending);
  const { role_id } = useSelector((state) => state.user.user);
  const { sendStatus } = useSelector((state) => state.companies);
  const companyInfo = useSelector((state) => state.companies.company.info);
  const { companyId }  = useParams();
  const [isMobile, setIsMobile] = useState(false);

  const mobileView = 999;

  const handleResize = () => {
    mobileView >= window.innerWidth ? setIsMobile(true) : setIsMobile(false);
  };

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(fetchCompany(companyId))
    // eslint-disable-next-line
  }, []);

  const onSubmit = (values, setErrors) => {
    let formData = new FormData();

    formData.append('first_name', values.first_name)
    formData.append('last_name', values.last_name)
    formData.append('role_slug', values.role_slug)
    formData.append('email', values.email)

    dispatch(postCompanyMemberInvite(companyId, formData, props, setErrors))
  };

  const rolesListMaker = role => {
    if (role === 1 ){
      return rolesList;
    } else if (role > 1 && role < 4){
      return rolesList.slice(1, 4)
    } else {
      return []
    }
  }

  return (
    <LayoutAdmin companyId={companyId} isAdmin={isAdmin}>
      <div className="member-comapny">
        <div className="member-comapny__info">
          <div className="member-comapny__logo">
            <img src={companyInfo?.image} alt="Company logo" />
          </div>
          <div className="member-comapny__title">
            <h3>{companyInfo?.name}</h3>
            {!isMobile && (
              <p>
                Feedback Email: <span>{companyInfo?.email}</span>
              </p>
            )}
          </div>
        </div>
        {isMobile && (
          <div className="mobile-feedback">
            Feedback Email: <span>{companyInfo?.email}</span>
          </div>
        )}
      </div>
      <BackButton
        title="Back to Members"
        className="back-button--company"
        path={`/admin/company/${companyId}/members`}
      />
      <div className="company">
        <Formik
          enableReinitialize
          initialValues={{
            first_name: '',
            last_name: '',
            email: '',
            role_slug: '',
          }}
          validationSchema={AdminCompanyMemberSchema}
          onSubmit={(values, { setErrors }) => onSubmit(values, setErrors)}
        >
          {({ errors, handleChange, handleBlur, values, touched, setFieldValue }) => (
            <Form>
              <h4 className="company__title">Invite member</h4>
              <p className="company__subtitle">
                Enter the fields below and click the "Invite member" button, in order for the user to receive an
                invitation to activate their ValueSoft company account.
              </p>
              {sendStatus && <p className="company__subtitle--success">The member has been added.</p>}
              <div className="comapny__form">
                <FormGroup name="first_name" labelText="First name">
                  <Input
                    disabled={sendStatus}
                    name="first_name"
                    placeholder="Enter first name"
                    type="text"
                    onChange={handleChange}
                    value={values.first_name || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.first_name && touched.first_name}
                  />
                  {touched.first_name && errors.first_name && (
                    <span className="form__msg-error">{errors.first_name}</span>
                  )}
                </FormGroup>
              </div>
              <div className="comapny__form">
                <FormGroup name="last_name" labelText="Last name">
                  <Input
                    disabled={sendStatus}
                    name="last_name"
                    placeholder="Enter last name"
                    type="text"
                    onChange={handleChange}
                    value={values.last_name || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.last_name && touched.last_name}
                  />
                  {touched.last_name && errors.last_name && <span className="form__msg-error">{errors.last_name}</span>}
                </FormGroup>
              </div>
              <div className="comapny__form">
                <FormControl disabled={sendStatus}>
                  <FormGroup name="role_slug" labelText="Role">
                    <FormSelect
                      options={rolesListMaker(role_id)}
                      id="role_slug"
                      value={values.role_slug || ''}
                      name="role_slug"
                      onChange={(e) => {
                        setFieldValue(e.target.name, e.target.value);
                      }}
                      onBlur={handleBlur}
                      placeholder="Select role"
                      hasError={!!errors.role_slug && touched.role_slug}
                    />
                    {touched.role_slug && errors.role_slug && (
                      <span className="form__msg-error">{errors.role_slug}</span>
                    )}
                  </FormGroup>
                </FormControl>
              </div>
              <div className="comapny__form">
                <FormGroup name="email" labelText="Email address">
                  <Input
                    disabled={sendStatus}
                    name="email"
                    placeholder="Enter an email"
                    type="text"
                    onChange={handleChange}
                    value={values.email || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.email && touched.email}
                  />
                  {touched.email && errors.email && <span className="form__msg-error">{errors.email}</span>}
                </FormGroup>
              </div>
              <div className="form__button">
                <Button variant="contained" size="large" color="primary" type="submit" disabled={sending || sendStatus}>
                  Invite member
                  {sending && <span className="spinner" />}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </LayoutAdmin>
  );
};

export default AdminCompanyInviteMember;
