import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { BackButton } from '../../../components/shared/Buttons'
import Layout from '../../../components/Layout/Layout'
import TreeSubItem from '../../../components/Tree/TreeSubItem'
import TreeImage from '../../../assets/images/tree/protection/Protection-tree.png'
import { fetchPercentages, fetchTotalPercent } from '../../../redux/slices/percentagesSlice'
import { getPercent } from '../../../utils/utils'
import TreeCount from '../../../components/Tree/TreeCount'
import { fetchAnalysisTasks } from '../../../redux/slices/taskSlice'
import { fetchUserDetails } from '../../../redux/slices/userSlice'


const Insurance = ({ props }) => {
  const dispatch = useDispatch()
  const { id } = useSelector(state => state.user.user)
  const isBasic = useSelector((state) => state.user.user.plan_type === 1);
  const totalPercent = useSelector(state => state.percentages.total)
  const percents = useSelector(state => state.percentages.percents)
  const loading = useSelector(state => state.sending.isSending)
  const analysis = useSelector(state => state.task.analysis)
  const isAuto = useSelector(state => state.user.profile.details.is_auto);
  const [isMobile, setIsMobile] = useState(false)
  const mobileView = 999
  const handleResize = () => {
    (mobileView >= window.innerWidth) ? setIsMobile(true) : setIsMobile(false)
  }

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true)
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchUserDetails())
      await dispatch(fetchPercentages())
      await dispatch(fetchAnalysisTasks())
      await dispatch(fetchTotalPercent(isBasic))
    }
    if (id) {
      fetch()
    }

    // eslint-disable-next-line
  }, [id])


  const profile           = getPercent(percents, 'profile')
  const homeInsurance     = getPercent(percents, 'home-insurance')
  const healthInsurance   = getPercent(percents, 'health-insurance')
  const autoInsurance     = getPercent(percents, 'auto-insurance')
  const umbrellaInsurance = getPercent(percents, 'umbrella-insurance')
  const insurance         = (homeInsurance + healthInsurance + (isAuto && autoInsurance) + umbrellaInsurance) / (isAuto ? 4 : 3)

  return (
    <Layout totalPercent={totalPercent} tree isBlockedPage={percents && profile === 0}>
      <div className="tree__sticky-holder">
        <BackButton title={isMobile ? 'Back' : 'Back to Tree'} path={isMobile ? '/protection' : "/input"} className="back-button--position" />
        <div className="tree__title tree__title--progress-bar">
          <div className="tree__title-main">
            <h2>Basic Insurance</h2>
            <TreeCount
              value={insurance && insurance.toFixed()}
              loading={loading}
              big={!isMobile}
            />
          </div>
          {!isMobile && (
            <p className="tree__title-other">Fill in information to complete this step.</p>
          )}
        </div>
        <div className="tree tree--sub-page">
          {!isMobile && (
            <div className="tree__base">
              <img src={TreeImage} alt="ValueSoft Tree Insurance" />
            </div>
          )}
          <TreeSubItem path="/insurance/auto"
                       title="Auto Insurance"
                       value="auto"
                       count={autoInsurance}
                       isLoading={loading}
                       isDisabled={!isAuto}
                       redirect='/profile-summary/about-you'
                       modalContent={<p>You need to add the relevant information in the <strong className="text-success">About You</strong> page in order to activate this section. </p>}
          />
          <TreeSubItem path="/insurance/umbrella"
                       title="Umbrella Insurance"
                       value="umbrella"
                       count={umbrellaInsurance}
                       isLoading={loading}
          />
          <TreeSubItem path="insurance/health"
                       title="Health Insurance"
                       value="health"
                       count={healthInsurance}
                       isLoading={loading}
          />
          <TreeSubItem path="insurance/home"
                       title="Home Insurance"
                       value="home"
                       count={homeInsurance}
                       isLoading={loading}
          />

          {isMobile && <div className="tree__task">
            <Link to="/tasks">{analysis && analysis.openCount ? analysis.openCount : '0'}</Link>
          </div>}
        </div>
      </div>
    </Layout>
  )
}

export default Insurance
