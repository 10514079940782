import React, { useEffect } from 'react'
import { Form, Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import { Button, FormControlLabel, Radio, RadioGroup, TextareaAutosize } from '@mui/material'
import Layout from '../../../components/Layout/Layout'
import LayoutForm from '../../../components/Layout/LayoutForm'
import { BackButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import ProgressBar from '../../../components/shared/ProgressBar/ProgessBar'
import { Input, InputCurrency, FormGroup, FormSelect } from '../../../components/shared/Form'
import { fetchUserDetails, postUserDetails, setError } from '../../../redux/slices/userSlice'
import { setIsOpen } from '../../../redux/slices/modalSlice'
import { useDispatch, useSelector } from 'react-redux'
import { DetailsUserSchema } from '../../../utils/validation'
import { normalizeBoolean } from '../../../utils/utils'
import Modal from '../../../components/shared/Modal/Modal'
import Priority from '../../../components/shared/Priority/Priority'
import ProgressCircle from '../../../components/shared/ProgressBar/ProgressCircle'
import taxFilingStatus from '../../../Data/taxFilingStatus'
import Illustration from '../../../assets/images/illustrations/Tree-segment_aboutMe-min.png'
import { fetchPercentages } from '../../../redux/slices/percentagesSlice'


const AboutYouDetails = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.user.user);
  const detailsUser = useSelector(state => state.user.profile.details);
  const error = useSelector(state => state.user.error);
  const isOpen = useSelector(state => state.modal.isOpen);
  const percents = useSelector(state => state.percentages.percents);
  const sending = useSelector(state => state.sending.isSending);

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchUserDetails(id));
      await dispatch(fetchPercentages({name: 'about-your'}));
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const onSubmit = (values, setErrors) => {
    dispatch(postUserDetails(values, props, setErrors))
  };

  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }))
  };

  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }));
    history.push('/profile-summary')
  };

  const handleFocus = () => {
    if(error && error.retirement_age) {
      dispatch(setError(null))
    }
  }

  return (
    <Layout>
      <LayoutForm illustration={Illustration} small>
        <BackButton title="Back" path="/profile-summary/about-you" />
        <PageTitle
          title="About you"
          content="Please fill out your profile information."
          status={percents && percents.percent ? percents.percent : '50'}
        />
        <div className="layout-form__content-holder">
          <div className="mb--medium">
            <ProgressBar amount={percents && percents.percent ? percents.percent : '50'} />
          </div>
          <Formik
            enableReinitialize
            initialValues={detailsUser}
            validationSchema={DetailsUserSchema}
            onSubmit={(values, { setErrors }) => onSubmit(values, setErrors)}
          >
            {({ errors, handleChange, handleBlur, values, touched, setFieldValue }) => (
              <Form>
                <FormGroup name="gross_salary" labelText="Your gross salary">
                  <InputCurrency
                    id="gross_salary"
                    placeholder="Salary"
                    name="gross_salary"
                    onChange={handleChange}
                    value={values.gross_salary || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.gross_salary && touched.gross_salary}
                  />
                  {touched.gross_salary && errors.gross_salary && <span className="form__msg-error">{errors.gross_salary}</span>}
                </FormGroup>
                <FormGroup name="future_salary" labelText="Will your salary level off in the future and if so, what will it be? (optional)">
                  <InputCurrency
                    placeholder="Salary"
                    type="text"
                    name="future_salary"
                    onChange={handleChange}
                    value={values.future_salary || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.future_salary && touched.future_salary}
                    info={{
                      content:
                        <>
                          <h6>Salary Details</h6>
                          <Priority option="Medium" />
                          <div>It's realistic to assume your salary will level off at a certain point in your career.  Please estimate what your maximum salary will likely be at the peak of your career.</div>
                        </>
                    }}
                  />
                  {touched.future_salary && errors.future_salary && <span className="form__msg-error">{errors.future_salary}</span>}
                </FormGroup>
                <FormGroup name="retirement_age" labelText="Retirement age">
                  <Input
                    small
                    placeholder=""
                    type="number"
                    name="retirement_age"
                    onFocus={handleFocus}
                    onChange={handleChange}
                    value={values.retirement_age || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.retirement_age && touched.retirement_age}
                  />
                  {touched.retirement_age && errors.retirement_age && <span className="form__msg-error">{errors.retirement_age}</span>}
                  {error && error.retirement_age && <span className="form__msg-error">{!errors.retirement_age && error.retirement_age[0]}</span>}
                </FormGroup>
                <FormGroup
                  name="social_security_analysis"
                  labelText="Have you begun the process of researching your optimal age at when to receive social security benefits?"
                  info={{
                    content:
                      <>
                        <h6>Social Security Benefits</h6>
                        <Priority option="Medium" />
                        <div>You can receive benefits at the ages of 62, 67, and 70.  The longer you wait, the greater your monthly payment will be.  However, you must factor in your life expectancy to appropriately calculate breakeven. For more information, see https://secure.ssa.gov.</div>
                      </>
                  }}
                >
                  <RadioGroup
                    name="social_security_analysis"
                    value={values.social_security_analysis || false}
                    onChange={(e) => {
                      setFieldValue('social_security_analysis', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                  {touched.social_security_analysis && errors.social_security_analysis && <span className="form__msg-error">{errors.social_security_analysis}</span>}
                </FormGroup>
                <FormGroup name="has_life_insurance" labelText="Do you own life insurance?">
                  <RadioGroup
                    name="has_life_insurance"
                    value={values.has_life_insurance || false}
                    onChange={(e) => {
                      setFieldValue('has_life_insurance', normalizeBoolean(e.target.value))
                      setFieldValue("life_insurance_amount", "")
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                  {touched.has_life_insurance && errors.has_life_insurance && <span className="form__msg-error">{errors.has_life_insurance}</span>}
                </FormGroup>

                <FormGroup name="life_insurance_amount" labelText="Life insurance policy amount ($)">
                  <InputCurrency
                    placeholder="$0.00"
                    type="text"
                    name="life_insurance_amount"
                    onChange={handleChange}
                    value={values.has_life_insurance ? values.life_insurance_amount : ''}
                    onBlur={handleBlur}
                    disabled={!values.has_life_insurance}
                    hasError={!!errors.life_insurance_amount && touched.life_insurance_amount && values.has_life_insurance}
                    info={{
                      content:
                        <>
                          <h6>Life Insurance Policy</h6>
                          <Priority option="High" />
                          <div>Current Face Value is the amount your policy covers you for.</div>
                          <hr className="tooltip__line"/>
                          <h6>Example</h6>
                          <div>Say you have a $100,000 policy for yourself, such that if you died, the insurance company would pay out $100,000 to your beneficiaries.  In that case, enter $100,000 in this field.</div>
                        </>
                    }}
                  />
                  {values.has_life_insurance && touched.life_insurance_amount && errors.life_insurance_amount && <span className="form__msg-error">{errors.life_insurance_amount}</span>}
                </FormGroup>
                <FormGroup name="is_smoke" labelText="Do you smoke?">
                  <RadioGroup
                    name="is_smoke"
                    value={values.is_smoke || false}
                    onChange={(e) => {
                      setFieldValue('is_smoke', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                    checked={values.is_smoke}
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                  {touched.is_smoke && errors.is_smoke && <span className="form__msg-error">{errors.is_smoke}</span>}
                </FormGroup>
                <FormGroup name="is_married" labelText="Are you married?">
                  <RadioGroup
                    name="is_married"
                    value={values.is_married || false}
                    onChange={(e) => {
                      setFieldValue('is_married', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                  {touched.is_married && errors.is_married && <span className="form__msg-error">{errors.is_married}</span>}
                </FormGroup>
                <FormGroup name="is_auto" labelText="Do you own an automobile?">
                  <RadioGroup
                    name="is_auto"
                    value={values.is_auto || false}
                    onChange={(e) => {
                      setFieldValue('is_auto', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                  {touched.is_auto && errors.is_auto && <span className="form__msg-error">{errors.is_auto}</span>}
                </FormGroup>
                <FormGroup name="tax_filing_status" labelText="Tax filing status">
                  <FormSelect
                    options={taxFilingStatus}
                    id="tax_filing_status"
                    value={values.tax_filing_status || ''}
                    name="tax_filing_status"
                    onChange={(e) => {
                      setFieldValue(e.target.name, e.target.value)
                    }}
                    placeholder=""
                    hasError={!!errors.tax_filing_status && touched.tax_filing_status}
                  />
                  {touched.tax_filing_status && errors.tax_filing_status && <span className="form__msg-error">{errors.tax_filing_status}</span>}
                </FormGroup>
                <FormGroup name="credit_score" labelText="Credit score">
                  <Input
                    placeholder=""
                    type="number"
                    name="credit_score"
                    onChange={handleChange}
                    value={values.credit_score || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.credit_score && touched.credit_score}
                  />
                  {touched.credit_score && errors.credit_score && <span className="form__msg-error">{errors.credit_score}</span>}
                </FormGroup>
                <h5 className="mt--medium">Additional info</h5>
                <FormGroup name="additional_info" labelText="Is there any other Profile Summary information related to the fields above that you believe is important for ValueSoft to know about?">
                  <TextareaAutosize
                    maxLength="200"
                    name="additional_info"
                    value={values.additional_info || ''}
                    className="form__textarea"
                    placeholder="Maximum characters: 200"
                    onChange={handleChange}
                  />
                </FormGroup>

                <div className="form__button">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Save
                    {sending && <span className="spinner" />}
                  </Button>
                </div>

                <Modal
                  open={isOpen}
                  small
                  button="Done"
                  onClick={handleClick}
                  close={onClose}
                >
                  <h3>Good Job!</h3>
                  <p>Your progress increased 100%</p>
                  <ProgressCircle amount={100} small />
                </Modal>
              </Form>
            )}
          </Formik>
        </div>
      </LayoutForm>
    </Layout>
  );
};

export default AboutYouDetails;
