import React, { useEffect, lazy, Suspense, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { Button, TextareaAutosize } from '@mui/material'
import { Formik, Form } from 'formik'
import AuthContent from '../../components/Auth/AuthContent'
import { SignUpCompanySchema } from '../../utils/validation'
import { FormGroup, Input } from '../../components/shared/Form'
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Screen from '../../assets/images/screen-img.png'
import { registerCompany, setAuthError, setCompanyInfo } from '../../redux/slices/authSlice'
import { MAIN_SITE, RECAPTCHA_SITE_KEY } from '../../constants/additional_links'
import { setOpenMenu } from '../../redux/slices/additionalSlice'
import { phoneValidation } from '../../utils/utils'
import { isEmpty, isNull } from 'lodash';

const ReCAPTCHA = lazy(() => import('react-google-recaptcha'));

const SignUpCompany = ({ props }) => {
  const dispatch = useDispatch();
  const base = useSelector(state => state.auth.registerCompany);
  const authInfo = useSelector((state) => state.auth.authInfo);
  const serverError = useSelector(state => state.auth.authError);
  const sending = useSelector(state => state.sending.isSending);
  const openMenu = useSelector(state => state.additional.menuOpen);
  const [authProps, setAuthProps] = useState(null);

  useEffect(() => {
    if (!isEmpty(authInfo)) {
      setAuthProps({
        registration: authInfo[0].value
      });
    }
  }, [authInfo]);

  useEffect(() => {
    openMenu
      ? document.body.style.overflow = 'hidden'
      : document.body.style.overflow = 'visible'
  }, [openMenu])

  useEffect(() => {
    dispatch(setAuthError({ authError: false }));
    dispatch(setOpenMenu(false))
  }, [dispatch]);

  const onSubmit = values => {
    dispatch(registerCompany(values, props))
  };

  if (!isNull(authProps) && !authProps.registration) return (<Redirect to="/login" />)

  return (
    <AuthContent decor={{image: Screen}} close={MAIN_SITE}>
      <h2>Sign Up for a <br /> company account</h2>
      <Formik
        initialValues={base}
        validationSchema={SignUpCompanySchema}
        onSubmit={onSubmit}
      >
        {({ errors, handleChange, handleBlur, setFieldValue, values, touched, isValid, dirty }) => (
          <Form>
            <FormGroup name="first_name" labelText="First Name">
              <Input
                placeholder="Enter your name"
                name="first_name"
                type="text"
                value={values.first_name}
                onChange={(e) => {
                  handleChange(e)
                  dispatch(setCompanyInfo(values))
                }}
                onBlur={handleBlur}
                hasError={!!errors.first_name && touched.first_name}
              />
              {touched.first_name && errors.first_name && <span className="form__msg-error">{errors.first_name}</span>}
            </FormGroup>
            <FormGroup name="last_name" labelText="Last Name">
              <Input
                placeholder="Enter your last name"
                name="last_name"
                type="text"
                value={values.last_name}
                onChange={(e) => {
                  handleChange(e)
                  dispatch(setCompanyInfo(values))
                }}
                onBlur={handleBlur}
                hasError={!!errors.last_name && touched.last_name}
              />
              {touched.last_name && errors.last_name && <span className="form__msg-error">{errors.last_name}</span>}
            </FormGroup>
            <FormGroup name="email" labelText="Email address">
              <Input
                placeholder="example@email.com"
                type="email"
                name="email"
                onChange={(e) => {
                  handleChange(e)
                  dispatch(setCompanyInfo(values))
                }}
                value={values.email}
                onBlur={handleBlur}
                hasError={!!errors.email && touched.email}
              />
              {touched.email && errors.email && <span className="form__msg-error">{errors.email}</span>}
              {serverError.email && <span className="form__msg-error">{serverError.email}</span>}
            </FormGroup>
            <FormGroup name="phone" labelText="Phone number">
              <Input
                placeholder="(XXX) XXX-XXXX"
                name="phone"
                type="text"
                onChange={(e) => {
                  setFieldValue(e.target.name, phoneValidation(e.target.value))
                  dispatch(setCompanyInfo(values))
                }}
                value={values.phone.toString()}
                onBlur={handleBlur}
                hasError={!!errors.phone && touched.phone}
              />
              {touched.phone && errors.phone && <span className="form__msg-error">{errors.phone}</span>}
              {serverError.phone && <span className="form__msg-error">{serverError.phone}</span>}
            </FormGroup>
            <FormGroup name="company_name" labelText="Full company name">
              <Input
                placeholder="Enter company name"
                name="company_name"
                type="text"
                value={values.company_name}
                onChange={(e) => {
                  handleChange(e)
                  dispatch(setCompanyInfo(values))
                }}
                onBlur={handleBlur}
                hasError={!!errors.company_name && touched.company_name}
              />
              {touched.company_name && errors.company_name && <span className="form__msg-error">{errors.company_name}</span>}
            </FormGroup>
            <FormGroup name="company_website" labelText="Company website">
              <Input
                placeholder="http://yoursite.com"
                name="company_website"
                type="text"
                value={values.company_website}
                onChange={(e) => {
                  handleChange(e)
                  dispatch(setCompanyInfo(values))
                  dispatch(setAuthError({ authError: false }));
                }}
                onBlur={handleBlur}
                hasError={!!errors.company_website && touched.company_website}
              />
              {touched.company_website && touched.company_website && errors.company_website && <span className="form__msg-error">{errors.company_website}</span>}
              {serverError.company_website && <span className="form__msg-error">{serverError.company_website}</span>}
            </FormGroup>
            <FormGroup name="about_company" labelText="About company">
                  <TextareaAutosize
                    maxLength="200"
                    name="about_company"
                    value={values.about_company}
                    className="form__textarea"
                    placeholder="Maximum characters: 200"
                    onChange={(e) => {
                      handleChange(e)
                    }}
                    onBlur={(e) => {
                      handleBlur(e)
                      dispatch(setCompanyInfo(values))
                    }}
                  />
              {touched.about_company && errors.about_company && <span className="form__msg-error">{errors.about_company}</span>}    
            </FormGroup>
            <FormGroup name="recaptcha">
              <Suspense fallback={<p>Loading...</p>}>
                <ReCAPTCHA
                  sitekey={RECAPTCHA_SITE_KEY}
                  onChange={value => setFieldValue('recaptcha', value)}
                />
                {touched.recaptcha && errors.recaptcha && <span className="form__msg-error">{errors.recaptcha}</span>}
              </Suspense>
            </FormGroup>
            <div className="form__button">
              <Button variant="contained" size="large" color="primary" type="submit" disabled={!(isValid && dirty) || sending}>
              Apply
                {sending && <span className="spinner" />}
              </Button>
            </div>
            <div className="auth__form-info">
              Already have  an account?
              <Link to="/login" className="auth__form-info-link">Sign in here <ChevronRightIcon /></Link>
            </div>
          </Form>
        )}
      </Formik>
    </AuthContent>
  );
};

export default SignUpCompany;
