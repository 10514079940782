import React, {useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import { color, colorReverse } from '../../../utils/utils'
import check from '../../../assets/images/icons/check.svg'
import SVGIcon from '../../../components/shared/SVGIcon/SVGIcon'

const ProgressCircle = ({ amount, small, title, showPercent, reverse }) => {
  const path = useRef(null);

  useEffect(() => {
    const circle = path.current
    const svgElement = circle.getTotalLength()

    circle.style.strokeDasharray = `0 ${svgElement}`

    if (amount) {
      setTimeout(() => {
        circle.style.strokeDasharray = `${svgElement * amount / 100} ${svgElement}`
      }, 400);

    }
  }, [amount])

  const stylePath = {
    stroke: `${amount < 1 ? 'none' : reverse ? colorReverse(amount) : color(amount)}`
  }

  return (
    <div className={`progress-circle${small ? ' progress-circle--small' : ''}`}>
      <svg className="progress-circle__svg" width="100%" height="100%">
        <circle style={stylePath} ref={path} className="progress-circle__circle" stroke="green" cx="50%" cy="50%"/>
      </svg>
      <div className="progress-circle__holder">
        {title
          ? <div className="progress-circle__title">
              {title}
            </div>
          : <SVGIcon color="#8C8C8C" icon={check} />
        }
      </div>
    </div>
  )
}

ProgressCircle.propTypes = {
  title: PropTypes.node,
  amount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  small: PropTypes.bool,
  showPercent: PropTypes.bool,
}

export default ProgressCircle
