import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Layout from '../../components/Layout/Layout'
import { BackButton } from '../../components/shared/Buttons'
import TreeSubItem from '../../components/Tree/TreeSubItem'
import { getPercent } from '../../utils/utils'
import { useSelector, useDispatch } from 'react-redux'
import { fetchUserFamilyChildren, fetchUserFamilyData, fetchUserFamilyAssets } from '../../redux/slices/userSlice'
import { fetchTotalPercent } from "../../redux/slices/percentagesSlice";
import { fetchData } from '../../redux/slices/childrenSlice'
import TreeCount from '../../components/Tree/TreeCount'
import { fetchAnalysisTasks } from '../../redux/slices/taskSlice'


const AboutMe = () => {
  const dispatch = useDispatch()
  const { id } = useSelector(state => state.user.user)
  const { total_children } = useSelector(state => state.user.family.children)
  const { paying_education } = useSelector(state => state.user.family.others)
  const { stakes_in_companies } = useSelector(state => state.user.family.assets)
  const isBasic = useSelector(state => state.user.user.plan_type === 1)
  const percents = useSelector(state => state.percentages.percents)
  const loading = useSelector(state => state.sending.isSending)
  const analysis = useSelector(state => state.task.analysis)
  const totalPercent = useSelector((state) => state.percentages.total);


  useEffect(() => {
    async function fetch() {
      //await dispatch(fetchPercentages())
      await dispatch(fetchUserFamilyChildren(id))
      await dispatch(fetchUserFamilyAssets(id))
      await dispatch(fetchUserFamilyData(id))
      await dispatch(fetchData())
      await dispatch(fetchAnalysisTasks())
      dispatch(fetchTotalPercent(isBasic));
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const profile    = getPercent(percents, 'profile')
  const values     = getPercent(percents, 'values')
  const risk       = getPercent(percents, 'risk')
  const companies       = getPercent(percents, 'companies')
  const children   = getPercent(percents, 'children')
  const education  = getPercent(percents, 'education')
  const totalAbout = (profile + values + (stakes_in_companies && companies) + (!isBasic && risk) + (!!paying_education  && education) + (!!total_children && children))/((isBasic ? 2 : 3) + (total_children ? 1 : 0) + (paying_education ? 1 : 0) + (stakes_in_companies ? 1 : 0))
  
  const menuList = [
    {
      path: '/profile-summary',
      count: profile || '0',
      title: 'Profile'
    },
    {
      path: '/dependents',
      count: children  || '0',
      title: 'Dependents',
      isDisabled: !total_children,
      isBlocked: !!(percents && getPercent(percents, 'profile') === 0),
      redirect: '/profile-summary/about-your-family/children',
      modalContent: <p>You need to add the relevant information in the <strong className="text-success">About You</strong> page in order to activate this section. </p>
    },
    {
      path: '/values',
      count: values || '0',
      isBlocked: profile === 0,
      isDisabled: profile === 0,
      title: 'Values',
      redirect: '/profile-summary',
      modalContent: <p>You need to add the relevant information in the <strong className="text-success">About You</strong> page in order to activate this section. </p>

    },
    {
        path: '/companies',
        count: companies  || '0',
        title: 'Companies',
        isDisabled: !stakes_in_companies,
        isBlocked: !!(percents && getPercent(percents, 'profile') === 0),
        redirect: '/profile-summary/about-your-family',
        modalContent: <p>You need to add the relevant information in the <strong className="text-success">About You</strong> page in order to activate this section. </p>
    },
    {
      path: '/risk',
      count: risk || '0',
      isBasic: isBasic,
      isBlocked: isBasic || profile === 0,
      isDisabled: isBasic || profile === 0,
      title: 'Risk',
      redirect: '/profile-summary',
      modalContent: <p>You need to add the relevant information in the <strong className="text-success">About You</strong> page in order to activate this section. </p>

    },
    {
      path: '/education',
      count: education || '0',
      title: 'Education',
      isDisabled: !paying_education,
      redirect: '/profile-summary/about-your-family/others',
      modalContent: <p>You need to add the relevant information in the <strong className="text-success">About You</strong> page in order to activate this section. </p>
    },
  ]

  return (
    <Layout totalPercent={totalPercent} tree>
      <BackButton title="Back" className="back-button--position" path="/input" />
      <div className="tree tree--mobile-page">
        <div className="tree__title">
          <h2>About me</h2>

          <TreeCount value={+totalAbout.toFixed() || "0"} loading={loading} />
        </div>
        {menuList &&
          menuList.map((item, id) => (
            <TreeSubItem
              key={`menu-${id}`}
              path={item.path}
              title={item.title}
              count={item.count}
              isBasic={item.isBasic}
              isDisabled={item.isDisabled}
              isBlocked={item.isBlocked}
              redirect={item.redirect}
              modalContent={item.modalContent}
              isLoading={loading}
            />
          ))}
        <div className="tree__task">
          <Link to="/tasks">
            {analysis && analysis.openCount ? analysis.openCount : "0"}
          </Link>
        </div>
      </div>
    </Layout>
  );
}

export default AboutMe
