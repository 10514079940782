import React from 'react'
import WidgetPlaceholder from './WidgetPlaceholder'
import Image from '../../assets/images/icon-calc.png'

const WidgetCalculator = () => {

  return (
    <WidgetPlaceholder
      title="Online financial planning calculators."
      content="Over a dozen standalone calculators to help you run financial scenarios."
      image={Image}
      button={{
        title: 'Use them',
        href: '/calculators'
      }}
    />
  )
};

export default WidgetCalculator;
