import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../../components/Layout/Layout'
import { BackButton, DeleteButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import LayoutForm from '../../../components/Layout/LayoutForm'
import {Button, FormControlLabel, Radio, RadioGroup, TextareaAutosize} from '@mui/material'
import {FormGroup, InputCurrency, Input, InputPercent} from '../../../components/shared/Form'
import { postAssetsDetails, removeAssets } from '../../../redux/slices/financesSlice'
import { setIsOpen } from '../../../redux/slices/modalSlice'
import ModalBox from '../../../components/shared/Modal/Modal'
import Illustration from '../../../assets/images/illustrations/Tree-segment_finances-min.png'
import Priority from '../../../components/shared/Priority/Priority'
import {normalizeBoolean} from '../../../utils/utils'
import {assetsDetailsSchema} from '../../../utils/validationFinances'
import Modal from '../../../components/shared/Modal/Modal'
import ProgressCircle from '../../../components/shared/ProgressBar/ProgressCircle'
import isNull from 'lodash/isNull'


const AssetDetails = ({ props, isEdit }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const isOpen = useSelector(state => state.modal.isOpen);
  const sending = useSelector(state => state.sending.isSending);
  const currentAsset = useSelector(state => state.finances.currentAsset);
  const { id }  = useParams();

  const deleteAsset = () => {
    dispatch(removeAssets(id, props));
    setIsOpenDeleteModal(false)
  }

  const onSubmit = values => {
    dispatch(postAssetsDetails(values, props, id, isEdit))
  }

  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }))
  }

  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }));
    history.push('/assets')
  }

  const handleChangeInput = (e, setValue) => {
    if([null, false].includes(e.target.value)) return false

    setValue(e.target.name, e.target.value)
  }

  return (
    <Layout>
      <LayoutForm small illustration={Illustration}>
        <BackButton title="Back" />
        {isEdit && <DeleteButton className="delete-button--position" onClick={() => setIsOpenDeleteModal(true)} />}
        <PageTitle
          title={isEdit && currentAsset && currentAsset.name ? currentAsset.name : 'Fixed assets'}
          content="Tell us about any fixed assets or outstanding loans you own."
          status={isEdit ? 100 : 50}
        />
        <div className="layout-form__content-holder">
          <Formik
            enableReinitialize
            initialValues={{...currentAsset}}
            onSubmit={onSubmit}
            validationSchema={assetsDetailsSchema}
          >
            {({ errors, handleChange, handleBlur, values, touched, setFieldValue }) => (
              <Form>
                <FormGroup
                  name="interest_rate"
                  labelText="Interest rate % (optional)"
                >
                  <InputPercent
                    placeholder="Type %"
                    name="interest_rate"
                    onChange={handleChange}
                    value={values.interest_rate || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.interest_rate}
                    info={{
                      content:
                        <>
                          <h6>Financing Rate</h6>
                          <Priority option="Medium" />
                          <div>This is typically your loan financing rate.</div>
                          <hr className="tooltip__line"/>
                          <h6>Example</h6>
                          <div>Say you bought a home 5 years ago for $100,000 and put a 20 pct downpayment and financed the rest through a fixed rate 30 year mortgage at 6.75 pct.  Enter 6.75 in this field.</div>
                        </>
                    }}
                  />
                  {errors.name && <span className="form__msg-error">{errors.interest_rate}</span>}
                </FormGroup>
                <FormGroup
                  name="financing_term"
                  labelText="Financing term (years) (optional)"
                >
                  <Input
                    name="financing_term"
                    placeholder="Years"
                    type="number"
                    onChange={e => handleChangeInput(e, setFieldValue)}
                    value={!isNull(values.financing_term) ? values.financing_term : ''}
                    onBlur={handleBlur}
                    hasError={!!errors.financing_term}
                    info={{
                      content:
                        <>
                          <h6>Financing Term</h6>
                          <Priority option="Medium" />
                          <div>This is typically the number of years you have allotted to pay back your loan.  It is the total number of years including any years you have already paid.</div>
                          <hr className="tooltip__line"/>
                          <h6>Example</h6>
                          <div>Say you bought a home 5 years ago for $100,000 and put a 20 pct downpayment and financed the rest through a fixed rate 30 year mortgage at 6.75 pct.  Enter 30 in this field.</div>
                        </>
                    }}
                  />
                  {errors.name && <span className="form__msg-error">{errors.financing_term}</span>}
                </FormGroup>
                <FormGroup
                  name="useful_life"
                  labelText="Useful life (years) (optional)"
                >
                  <Input
                    name="useful_life"
                    placeholder="Years"
                    type="number"
                    onChange={e => handleChangeInput(e, setFieldValue)}
                    value={!isNull(values.useful_life) ? values.useful_life : ''}
                    onBlur={handleBlur}
                    info={{
                      content:
                        <>
                          <h6>Useful Life</h6>
                          <Priority option="Medium" />
                          <div>Many fixed assets depreciate over time and often are no longer useful after a certain period of time.  Enter the estimated useful life of your fixed asset(s) here.  If you are using the basic method to entering your fixed assets, enter the average useful life across all your fixed assets of this particular fixed asset type.</div>
                          <hr className="tooltip__line"/>
                          <h6>Example</h6>
                          <div>Say you have 2 depreciable fixed asset: a yacht and a car.  You think it will take on average about 15 years to run both of them to the ground where they would no longer be drivable / sailable.  Then enter 15 in this field.</div>
                        </>
                    }}
                  />
                </FormGroup>
                <FormGroup
                  name="liquidated_asset"
                  labelText="Liquidate asset in the future (if asset)?"
                  info={{
                    content:
                      <>
                        <h6>Liquidate Asset</h6>
                        <Priority option="Medium" />
                        <div>If you are planning to sell the fixed asset you mentioned, then you are planning to liquidate the asset in the future.</div>
                        <hr className="tooltip__line"/>
                        <h6>Example</h6>
                        <div>Say you own a yacht and are planning to sell it in 10 years.  Then answer Yes to this question.</div>
                      </>
                  }}
                >
                  <RadioGroup
                    name="liquidated_asset"
                    value={values.liquidated_asset || false}
                    onChange={(e) => {
                      setFieldValue('liquidated_asset', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                </FormGroup>
                <FormGroup
                  name="age_when_liquidated"
                  labelText="Your anticipated age when liquidated (optional)"
                >
                  <Input
                    name="age_when_liquidated"
                    placeholder="Type an age"
                    type="number"
                    onChange={e => handleChangeInput(e, setFieldValue)}
                    value={!isNull(values.age_when_liquidated) ? values.age_when_liquidated : ''}
                    onBlur={handleBlur}
                    info={{
                      content:
                        <>
                          <h6>Liquidation Age</h6>
                          <Priority option="Medium" />
                          <div>Enter the age you plan to sell your fixed asset if you plan to liquidate it in the future.</div>
                          <hr className="tooltip__line"/>
                          <h6>Example</h6>
                          <div>Say you are 30 and planning to sell your boat in 5 years.  Enter 35 in this field, which is your age when you plan to sell it.</div>
                        </>
                    }}
                  />
                </FormGroup>
                <FormGroup name="estimated_value" labelText="Estimated value at time of sale (today's $) (optional)">
                  <InputCurrency
                    placeholder="Type Estimated"
                    type="text"
                    name="estimated_value"
                    onChange={handleChange}
                    value={values.estimated_value || null}
                    onBlur={handleBlur}
                    info={{
                      content:
                        <>
                          <h6>Value at Sale</h6>
                          <Priority option="Medium" />
                          <div>Assume that the salvage value is the money you expect to make when you sell your fixed asset.</div>
                          <hr className="tooltip__line"/>
                          <h6>Example</h6>
                          <div>Say you bought a boat 5 years ago for $50,000 and expect to sell it in 2 years.  If you expect it to sell for $20,000 in 2 years, enter $20,000 in this field.</div>
                        </>
                    }}
                  />
                </FormGroup>
                <h5 className="mt--medium">Additional info</h5>
                <FormGroup name="additional_info" labelText="Is there any other current fixed assets information that you believe is important for ValueSoft to know about? If so, please let us know about it in this space:">
                  <TextareaAutosize
                    maxLength="200"
                    name="additional_info"
                    value={values.additional_info || ''}
                    className="form__textarea"
                    placeholder="Maximum characters: 200"
                    onChange={handleChange}
                  />
                </FormGroup>
                <div className="form__button">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Save
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <ModalBox
          small
          inline
          open={isOpenDeleteModal}
          title="Are you sure?"
          button="Remove Item"
          closeButton="Cancel"
          onClick={deleteAsset}
          close={() => setIsOpenDeleteModal(false)}
        />
        <Modal
          open={isOpen}
          small
          button="Done"
          onClick={handleClick}
          close={onClose}
        >
          <h3>Good Job!</h3>
          <p>Your progress increased 100%</p>
          <ProgressCircle amount={100} small />
        </Modal>
      </LayoutForm>
    </Layout>
  );
};

export default AssetDetails;
