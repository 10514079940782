import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import numeral from 'numeral'
import {AddButton, BackButton} from '../../../components/shared/Buttons'
import Layout from '../../../components/Layout/Layout'
import PageTitle from '../../../components/PageTitle/PageTitle'
import LayoutForm from '../../../components/Layout/LayoutForm'
import { Button, TextareaAutosize } from '@mui/material'
import { fetchDebtCredit, fetchNecessity, fetchWorthOthers, updateDebtCredit } from '../../../redux/slices/worthSlice'
import { postInfo, fetchInfoByParams } from '../../../redux/slices/infoSlice'
import Priority from '../../../components/shared/Priority/Priority'
import { fetchTotalPercent } from '../../../redux/slices/percentagesSlice'
import { getPercent } from '../../../utils/utils'
import Modal from '../../../components/shared/Modal/Modal'
import SVGIcon from '../../../components/shared/SVGIcon/SVGIcon'
import Icon from '../../../assets/images/icons/icon-ongratulations.svg'

const WorthLiabilities = ({ props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpenSaveModal, setIsOpenSaveModal] = useState(false)
  const [isMobile, setIsMobile] = useState(false);
  const { id } = useSelector(state => state.user.user);
  const isBasic = useSelector((state) => state.user.user.plan_type === 1);
  const totalPercent = useSelector(state => state.percentages.total)
  const { debtCredit, necessity, othersLiabilities } = useSelector(state => state.worth);
  const info = useSelector(state => state.info.info);
  const sending = useSelector(state => state.sending.isSending);
  const percents = useSelector(state => state.percentages.percents);
  const mobileView = 759;

  useEffect(() => {
    async function fetch() {
      Promise.all([
        dispatch(fetchInfoByParams({type: 'liabilities'})),
        dispatch(fetchDebtCredit()),
        dispatch(fetchNecessity()),
        dispatch(fetchWorthOthers()),
        dispatch(fetchTotalPercent(isBasic))
      ])
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const handleResize = () => {
    (mobileView >= window.innerWidth) ? setIsMobile(true) : setIsMobile(false)
  }

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onSubmit = async (values) => {
    await dispatch(postInfo(values))
    setIsOpenSaveModal(true);
    await dispatch(updateDebtCredit(debtCredit, props))
    await dispatch(fetchTotalPercent(isBasic))
  }

  return (
    <Layout totalPercent={totalPercent} sticky>
      <LayoutForm>
        <BackButton title="Back to Worth" path="/worth" />
        <PageTitle
          title="Your liabilities"
          content="Please provide your financial obligations. Liabilities include long-term and short-term debt, as well as any other amounts that are owed to others."
          status={percents ? getPercent(percents, "your-liabilities") : "0"}
          info={
            <>
              <h6>Liabilities</h6>
              <Priority option="High" />
              <div>
                A person's financial obligations. Liabilities include long-term
                and short-term debt, as well as any other amounts that are owed
                to others.
              </div>
              <hr className="tooltip__line" />
              <h6>Example</h6>
              <div>
                Say you have a mortgage, student loans, owe someone $1000. Enter
                all these and other liabilities in their respective liabilities
                fields.
              </div>
            </>
          }
        />
        <div className="layout-form__content-holder container container--large">
          <div className="layout-form__grid layout-form__grid--alt">
            <div>
              <div className="box">
                <h5>Debt & Credit Balances</h5>
                <div className="box__row">
                  <div>Mortgage balance ($):</div>
                  <div className="box__row-option">
                    {debtCredit && debtCredit.mortgage_balance
                      ? numeral(debtCredit.mortgage_balance).format("$0,0.00")
                      : "$0.00"}
                  </div>
                </div>
                <div className="box__row">
                  <div>Equity line of credit balance ($):</div>
                  <div className="box__row-option">
                    {debtCredit && debtCredit.equity_credit_balance
                      ? numeral(debtCredit.equity_credit_balance).format(
                          "$0,0.00"
                        )
                      : "$0.00"}
                  </div>
                </div>
                <div className="box__row">
                  <div>Credit card balance ($):</div>
                  <div className="box__row-option">
                    {debtCredit && debtCredit.credit_balance
                      ? numeral(debtCredit.credit_balance).format("$0,0.00")
                      : "$0.00"}
                  </div>
                </div>
                <div className="box__row">
                  <div>Overdue credit cards ($):</div>
                  <div className="box__row-option">
                    {debtCredit && debtCredit.overdue_balance
                      ? numeral(debtCredit.overdue_balance).format("$0,0.00")
                      : "$0.00"}
                  </div>
                </div>
                <div className="box__button">
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    onClick={() =>
                      history.push("/worth/liabilities/debt-credit")
                    }
                  >
                    Update
                  </Button>
                </div>
              </div>
            </div>
            <div>
              <div className="box">
                <h5>Overdue Necessities</h5>
                <div className="box__row">
                  <div>Overdue utilities ($):</div>
                  <div className="box__row-option">
                    {necessity && necessity.utilities
                      ? numeral(necessity.utilities).format("$0,0.00")
                      : "$0.00"}
                  </div>
                </div>
                <div className="box__row">
                  <div>Overdue phone payments ($):</div>
                  <div className="box__row-option">
                    {necessity && necessity.phone_payments
                      ? numeral(necessity.phone_payments).format("$0,0.00")
                      : "$0.00"}
                  </div>
                </div>
                <div className="box__row">
                  <div>Overdue mortgage payments ($):</div>
                  <div className="box__row-option">
                    {necessity && necessity.mortgage_payments
                      ? numeral(necessity.mortgage_payments).format("$0,0.00")
                      : "$0.00"}
                  </div>
                </div>
                <div className="box__row">
                  <div>Overdue car payments ($):</div>
                  <div className="box__row-option">
                    {necessity && necessity.car_payments
                      ? numeral(necessity.car_payments).format("$0,0.00")
                      : "$0.00"}
                  </div>
                </div>
                <div className="box__row">
                  <div>Overdue taxes ($):</div>
                  <div className="box__row-option">
                    {necessity && necessity.taxes
                      ? numeral(necessity.taxes).format("$0,0.00")
                      : "$0.00"}
                  </div>
                </div>
                <div className="box__row">
                  <div>Other loan balance:</div>
                  <div className="box__row-option">
                    {necessity && necessity.other_loan_balance
                      ? numeral(necessity.other_loan_balance).format("$0,0.00")
                      : "$0.00"}
                  </div>
                </div>
                <div className="box__button">
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    onClick={() =>
                      history.push("/worth/liabilities/necessities")
                    }
                  >
                    Update
                  </Button>
                </div>
              </div>
            </div>
            {othersLiabilities && othersLiabilities.length < 1 ? (
              !isMobile && (
                <div>
                  <AddButton
                    onClick={() => history.push("/worth/liabilities/others")}
                    title="Add other"
                  />
                </div>
              )
            ) : (
              <div>
                <div className="box">
                  <h5>Other</h5>
                  {othersLiabilities &&
                    othersLiabilities.length > 0 &&
                    othersLiabilities.map((item, index) => (
                      <div key={`item-${index}`}>
                        <div className="box__row">
                          <div>Additional liability name</div>
                          <div className="box__row-option">{item.name}</div>
                        </div>
                        <div className="box__row">
                          <div>Amount ($)</div>
                          <div className="box__row-option">
                            {item.amount
                              ? numeral(item.amount).format("$0,0.00")
                              : "$0.00"}
                          </div>
                        </div>
                      </div>
                    ))}
                  <div className="box__button">
                    <Button
                      variant="contained"
                      size="medium"
                      color="primary"
                      onClick={() => history.push("/worth/liabilities/others")}
                    >
                      Update
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="form__comment-box mt--medium">
            <Formik
              enableReinitialize
              initialValues={{
                name: info.name || "liabilities",
                content: info.content || "",
              }}
              onSubmit={onSubmit}
            >
              {({ errors, handleChange, handleBlur, values, touched }) => (
                <Form>
                  <h5>Additional info</h5>
                  <p>
                    Is there any other Current net worth information that you
                    believe is important for ValueSoft to know about? If so,
                    please let us know about it:
                  </p>
                  <TextareaAutosize
                    maxLength="200"
                    name="content"
                    value={values.content || ""}
                    className="form__textarea"
                    placeholder="Maximum characters: 200"
                    onChange={handleChange}
                  />
                  {errors.content && touched.content && (
                    <span className="form__msg-error">{errors.content}</span>
                  )}
                  <div className="form__button form__button--center">
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      type="submit"
                      disabled={sending}
                    >
                      Save
                      {sending && <span className="spinner" />}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        <div className="button__mobile-sticky">
          {isMobile && othersLiabilities.length < 1 && (
            <AddButton
              onClick={() => history.push("/worth/liabilities/others")}
              title="Add other"
              smallMobile
            />
          )}
        </div>
        <Modal
          open={isOpenSaveModal}
          small
          close={() => {
            setIsOpenSaveModal(false);
            history.push("/worth");
          }}
          button="Ok"
          onClick={() => {
            setIsOpenSaveModal(false);
            history.push("/worth");
          }}
          customClass="modal__top-index"
        >
          <div>
            <div className="modal__title-icon">
              <SVGIcon icon={Icon} />
            </div>
            <h4>You have successfully updated the data</h4>
          </div>
        </Modal>
      </LayoutForm>
    </Layout>
  );
};

export default WorthLiabilities;
