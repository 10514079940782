import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../../components/Layout/Layout'
import { BackButton } from '../../components/shared/Buttons'
import Tooltip from '../../components/shared/Tooltip/Tooltip'
import { Slider, Input, FormGroup, FormSelect, InputCurrency } from '../../components/shared/Form'
import PercentIcon from '../../assets/images/icons/percentage.svg'
import { Button } from '@mui/material'
import ModalBox from '../../components/shared/Modal/Modal'
import Tabs, { TabPanel } from '../../components/shared/Tabs/Tabs'
import {
  fetchListResults,
  fetchCalculators,
  saveItemResult,
  fetchCurrentResult,
  removeItemResult, setCurrentCalculator
} from '../../redux/slices/calculatorSlice'
import {convertDecimal, PMTFormula} from '../../utils/utils'
import ProgressCircle from '../../components/shared/ProgressBar/ProgressCircle'
import { Bar } from 'react-chartjs-2'
import { optionsCalculator } from '../../utils/optionsBar'
import { fetchOwnVsRentAdmin } from '../../redux/slices/calculatorsSetupSlice'

const OwnVsRent = ({ props }) => {
  const dispatch = useDispatch()
  const [tabActive, setActiveTab] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [isSavedModal, setIsSavedModal] = useState(false)
  const { id, company_id } = useSelector(state => state.user.user)
  const { calculators, current, listExisting, loading } = useSelector(state => state.calculator)
  const baseValues = useSelector(state => state.calculatorsSetup.ownVsRentAdmin);
  const [currentId, setCurrentId] = useState('')
  const inflation = 3.5
  const propertyMortgageInsurance = 80
  const taxBenefitPropertyMortgageInsurance = 0
  const [values, setValues] = useState(null)

  useEffect(() => {
    if(baseValues){
      setValues({
        currentId: '',
        name: '',
        ...baseValues
      })
    }
  // eslint-disable-next-line
  }, [baseValues])

  const [countValues, setCountValues] = useState({
    breakevenBegins: 0,
    breakevenEnds: 0,
    beginOwnProfit: 0,
    beginRentProfit: 0,
    endsOwnProfit: 0,
    endsRentProfit: 0,
  })

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchOwnVsRentAdmin(company_id))
      await dispatch(fetchCalculators({ path: 'own-vs-rent-home' }));
      await dispatch(fetchListResults('own-vs-rent-home'))
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    if(values) {
    let valueOnDatePurchase = +values.home_value * Math.pow((1 + +values.property_appreciation / 100), (+values.age_upon_home_purchase - +values.current_age))
    let downpaymentPurchase = valueOnDatePurchase * +values.down_payment / 100
    let mortgageAmountValue = valueOnDatePurchase - downpaymentPurchase
    let mortgagePayment     = -(PMTFormula(+values.financing_rate / 100, +values.years_financed, valueOnDatePurchase - downpaymentPurchase))

    let pointsAmount        = +values.income_tax_bracket / 100 > 0
                                ? +values.points_down / 100 * mortgageAmountValue * (1 - +values.income_tax_bracket / 100)
                                : +values.points_down / 100 * mortgageAmountValue

    let breakevenBegins = 0,
          breakevenEnds = 0,
         beginOwnProfit = 0,
        beginRentProfit = 0,
          endsOwnProfit = 0,
         endsRentProfit = 0

    let propertyMortgageInsuranceMonthly = 0

    let yearlyRentExpenses         = 0
    let homeAppreciation           = 0
    let cumulativeEquity           = 0
    let cumulativePrincipalOwned   = 0 // G
    let cumulativeMaintenance      = 0 // K
    let cumulativeMortgage         = 0 // I
    let cumulativeAdjustedMortgage = 0 // M
    let cumulativeInterest         = 0 // E
    let cumulativeYearlyIncome     = 0 // 0
    let cumulativeCostProfit       = 0 // R
    let interestYear               = 0
    let commission                 = 0
    let opportunityInvesting       = 0
    let opportunityIncremental     = 0
    let homeProfit                 = 0
    let mortgageAmount             = 0

    for (let i = +values.current_age; i <= 90; i++) {
      yearlyRentExpenses = i === +values.age_upon_home_purchase
                            ? yearlyRentExpenses = +values.monthly_rent * 12
                            : i < +values.age_upon_home_purchase
                              ? 0
                              : yearlyRentExpenses * (1 + +inflation / 100)

      cumulativeEquity  = homeAppreciation <= 0
                          ? 0
                          : (i === +values.age_upon_home_purchase)
                              ? valueOnDatePurchase - homeAppreciation + cumulativeEquity
                              : homeAppreciation * (1 + +values.property_appreciation / 100) - homeAppreciation + cumulativeEquity

      homeAppreciation  = i === +values.age_upon_home_purchase
                            ? valueOnDatePurchase
                            : homeAppreciation * (1 + +values.property_appreciation / 100)

      if (homeAppreciation > 0) {
                  mortgageAmount = i === +values.age_upon_home_purchase
                                    ? mortgageAmountValue
                                    : mortgageAmount - (mortgagePayment - interestYear) <= 0
                                      ? 0
                                      : (i - 1) === +values.age_upon_home_purchase
                                        ? (valueOnDatePurchase - downpaymentPurchase) - (mortgagePayment - interestYear)
                                        : mortgageAmount - (mortgagePayment - interestYear)
                    interestYear = mortgageAmount * (+values.financing_rate / 100)
        cumulativePrincipalOwned = interestYear <= 0
                                    ? cumulativePrincipalOwned
                                    : mortgagePayment - interestYear + cumulativePrincipalOwned
           cumulativeMaintenance = (i - +values.age_upon_home_purchase) * +values.home_maintenance_expenses
                      commission = (+values.closing_costs/100 * valueOnDatePurchase) + pointsAmount + (+values.commission_costs/100 * homeAppreciation)
            opportunityInvesting = downpaymentPurchase * Math.pow((1 + +values.stock_market_return/100),(i - +values.age_upon_home_purchase))
          opportunityIncremental = ((mortgagePayment - yearlyRentExpenses)+opportunityIncremental) * (1 + +values.stock_market_return/100)
            cumulativeCostProfit = opportunityInvesting+opportunityIncremental-downpaymentPurchase
      }

      cumulativeMortgage = cumulativeMortgage + mortgagePayment
      cumulativeAdjustedMortgage = cumulativeMortgage === 0
                                    ? 0
                                    : mortgageAmount/valueOnDatePurchase < +propertyMortgageInsurance/100
                                      ? cumulativeAdjustedMortgage
                                      : propertyMortgageInsuranceMonthly * (1 - +taxBenefitPropertyMortgageInsurance) * 12 + cumulativeAdjustedMortgage
      cumulativeInterest = cumulativeInterest + interestYear
      homeProfit  = cumulativeEquity + cumulativePrincipalOwned - cumulativeMaintenance - commission - cumulativeAdjustedMortgage
      cumulativeYearlyIncome = cumulativeInterest * +values.income_tax_bracket/100
      let dataBeginOwnProfit = (homeProfit + cumulativeYearlyIncome)/Math.pow((1 + +values.inflation_rate/100), (i - +values.current_age))
      let dataBeginRentProfit = cumulativeCostProfit/Math.pow((1 + +values.inflation_rate/100), (i - +values.current_age))

      if ((breakevenBegins === 0 && ((homeProfit+cumulativeYearlyIncome) >= cumulativeCostProfit && (homeProfit+cumulativeYearlyIncome) !== 0)) || cumulativeMortgage === 0) {
        breakevenBegins = i
        beginOwnProfit  = dataBeginOwnProfit
        beginRentProfit = dataBeginRentProfit
      }

      if ((breakevenEnds < 1 && breakevenBegins >= 1 && ((homeProfit+cumulativeYearlyIncome) < cumulativeCostProfit)) || cumulativeMortgage === 0) {
        breakevenEnds  = i
        endsOwnProfit  = dataBeginOwnProfit
        endsRentProfit = dataBeginRentProfit
      }
    }

    setCountValues({
      breakevenBegins,
      breakevenEnds,
      beginOwnProfit: beginOwnProfit.toFixed(2),
      beginRentProfit: beginRentProfit.toFixed(2),
      endsOwnProfit: endsOwnProfit.toFixed(2),
      endsRentProfit: endsRentProfit.toFixed(2)
    })
  }
  }, [values])

  useEffect(() => {
    async function fetchNewData() {
      if (current) {
        setValues({
          name: current ? current.name : '',
          currentId: currentId,
          current_age: +current.current_age,
          age_upon_home_purchase: +current.age_upon_home_purchase,
          home_value: +current.home_value,
          down_payment: +current.down_payment,
          financing_rate: +current.financing_rate,
          years_financed: +current.years_financed,
          home_maintenance_expenses: +current.home_maintenance_expenses,
          points_down: +current.points_down,
          income_tax_bracket: +current.income_tax_bracket,
          monthly_rent: +current.monthly_rent,
          property_appreciation: +current.property_appreciation,
          stock_market_return: +current.stock_market_return,
          closing_costs: +current.closing_costs,
          commission_costs: +current.commission_costs,
          inflation_rate: +current.inflation_rate
        })
      }
    }

    if (currentId) {
      fetchNewData()
    }
    // eslint-disable-next-line
  }, [currentId, current])

  const handleInputChange = e => {
    setValues({
      ...values,
      [e.target.name]: +e.target.value
    })
  }

  // convert value to integer number
  const handleInputIntegerChange = e => {
    setValues({
      ...values,
      [e.target.name]: e.target.value.replace(/[^0-9]/g, "")
    })
  }

  const handleCurrencyChange = e => {
    setValues({
      ...values,
      [e.target.name]: +convertDecimal(e.target.value)
    })
  }

  const changeCurrentData = async e => {
    await setCurrentId(e.target.value)
    await dispatch(fetchCurrentResult(e.target.value,'own-vs-rent-home'))
  }

  const onSave = async () => {
    dispatch(saveItemResult(values, 'own-vs-rent-home')).then(res => {
      const calculator = res?.data?.data;

      if(calculator) {
        setIsSavedModal(true);
        setCurrentId(calculator.id);
        dispatch(setCurrentCalculator(calculator));
      }
    }).finally(() => {
      setIsOpen(false);
    })
  }

  const resetForm = () => {
    setValues({
      currentId: '',
      name: '',
      ...baseValues
    })
    setCurrentId('')
  }

  const removeResult = async () => {
    await dispatch(removeItemResult(currentId, 'own-vs-rent-home'))
    await setCurrentId('')
    await setIsDeleteModal(false)
    await setActiveTab(0)
    await setValues({
      ...baseValues
    })
  }

  return (
    <Layout decor>
      <BackButton title="Back to Calculators" path="/calculators" className="back-button--position" />

      <div className="calculators__title">
        <div>
          {calculators && <h4>{calculators.name}</h4>}
          {calculators &&
          <Tooltip
            title={
              <>
                <h6>{calculators.name}</h6>
                {calculators.tooltip}
                <hr className="tooltip__line"/>
                <h6>Example</h6>
                {calculators.example}
              </>
            }
          />}
        </div>
        {listExisting && listExisting.length > 0 && <div className="calculators__title-select">
          <FormSelect
            options={listExisting}
            id="currentId"
            value={currentId}
            medium
            name="currentId"
            onChange={changeCurrentData}
            placeholder="Select result"
          />
        </div>}
      </div>

      <Tabs
        handleChange={(event, newValue) => {
          setActiveTab(newValue)
        }}
        variant="scrollable"
        value={tabActive}
        tabs={[
          {title: 'Home purchase'},
          {title: 'Rental details'},
          {title: 'ValueSoft Assumptions'}
        ]}
      />

      <div className="calculators__holder">
        <div className="calculators__content">
          <TabPanel value={tabActive} index={0}>
            <h6 className="calculators__subtitle">Step 1: Input Home Purchase Details</h6>
            <div className="calculators__area">
              <Input
                type="number"
                name="current_age"
                value={values ? +values.current_age.toString() : ''}
                small
                smallHeight
                onChange={handleInputIntegerChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100}
                  step={1}
                  label="Current age"
                  value={values ? +values.current_age : 0}
                  onChange={(e, newValue) => setValues({...values, current_age: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="age_upon_home_purchase"
                value={values ? +values.age_upon_home_purchase.toString() : ''}
                min={values && +values.current_age}
                small
                smallHeight
                onChange={handleInputIntegerChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100}
                  min={values && +values.current_age}
                  step={1}
                  label="Your age upon home purchase "
                  value={values ? +values.age_upon_home_purchase : 0}
                  onChange={(e, newValue) => setValues({...values, age_upon_home_purchase: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <InputCurrency
                name="home_value"
                value={values ? +values.home_value.toString() : ''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={500000}
                  step={5000}
                  label="Home value (today’s $)"
                  value={values ? +values.home_value : 0}
                  onChange={(e, newValue) => setValues({...values, home_value: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="down_payment"
                value={values ? +values.down_payment.toString() : ''}
                small
                smallHeight
                step={0.1}
                onChange={handleInputChange}
                iconName={PercentIcon}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100}
                  step={0.1}
                  label="Downpayment"
                  value={values ? +values.down_payment : 0}
                  onChange={(e, newValue) => setValues({...values, down_payment: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="financing_rate"
                value={values ? +values.financing_rate.toString() : ''}
                small
                smallHeight
                step={0.1}
                onChange={handleInputChange}
                iconName={PercentIcon}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100}
                  step={0.1}
                  label="Financing rate"
                  value={values ? +values.financing_rate : 0}
                  onChange={(e, newValue) => setValues({...values, financing_rate: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="years_financed"
                value={values ? +values.years_financed.toString() : ''}
                small
                smallHeight
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100}
                  step={1}
                  label="Years financed"
                  value={values ? +values.years_financed : 0}
                  onChange={(e, newValue) => setValues({...values, years_financed: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <InputCurrency
                name="home_maintenance_expenses"
                value={values ? +values.home_maintenance_expenses.toString() : ''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={50000}
                  step={25}
                  label="Annual home maintenance expenses"
                  value={values ? +values.home_maintenance_expenses : 0}
                  onChange={(e, newValue) => setValues({...values, home_maintenance_expenses: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="points_down"
                value={values ? +values.points_down.toString() : ''}
                small
                smallHeight
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={10}
                  step={1}
                  label="Points down"
                  value={values ? +values.points_down : 0}
                  onChange={(e, newValue) => setValues({...values, points_down: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="income_tax_bracket"
                value={values ? +values.income_tax_bracket.toString() : ''}
                small
                smallHeight
                step={0.1}
                onChange={handleInputChange}
                iconName={PercentIcon}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100}
                  step={0.1}
                  label="Income tax bracket"
                  value={values ? +values.income_tax_bracket : 0}
                  onChange={(e, newValue) => setValues({...values, income_tax_bracket: newValue})}
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel value={tabActive} index={1}>
            <h6 className="calculators__subtitle">Step 2: Input rental details</h6>
            <div className="calculators__area">
              <InputCurrency
                name="monthly_rent"
                value={values ? +values.monthly_rent.toString() : ''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={10000}
                  step={5}
                  label="Monthly rent"
                  value={values ? +values.monthly_rent : 0}
                  onChange={(e, newValue) => setValues({...values, monthly_rent: newValue})}
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel value={tabActive} index={2}>
            <h6 className="calculators__subtitle">Step 3: Input valuesoft assumptions</h6>
            <div className="calculators__area">
              <Input
                type="number"
                name="property_appreciation"
                value={values ? +values.property_appreciation.toString() : ''}
                small
                smallHeight
                step={0.1}
                onChange={handleInputChange}
                iconName={PercentIcon}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100}
                  step={0.1}
                  label="Property Appreciation"
                  value={values ? +values.property_appreciation : 0}
                  onChange={(e, newValue) => setValues({...values, property_appreciation: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="stock_market_return"
                value={values ? +values.stock_market_return.toString() : 0}
                small
                smallHeight
                step={0.1}
                onChange={handleInputChange}
                iconName={PercentIcon}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100}
                  step={0.1}
                  label="Stock market return "
                  value={values ? +values.stock_market_return : 0}
                  onChange={(e, newValue) => setValues({...values, stock_market_return: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="closing_costs"
                value={values ? +values.closing_costs.toString() : ''}
                small
                smallHeight
                step={0.1}
                onChange={handleInputChange}
                iconName={PercentIcon}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100}
                  step={0.1}
                  label="Closing costs"
                  value={values ? +values.closing_costs : 0}
                  onChange={(e, newValue) => setValues({...values, closing_costs: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="commission_costs"
                value={values ? +values.commission_costs.toString() : ''}
                small
                smallHeight
                step={0.1}
                onChange={handleInputChange}
                iconName={PercentIcon}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100}
                  step={0.1}
                  label="Commission costs"
                  value={values ? +values.commission_costs : 0}
                  onChange={(e, newValue) => setValues({...values, commission_costs: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="inflation_rate"
                value={values ? +values.inflation_rate.toString() : ''}
                small
                smallHeight
                step={0.1}
                onChange={handleInputChange}
                iconName={PercentIcon}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100}
                  step={0.1}
                  label="Inflation rate"
                  value={values ? +values.inflation_rate : 0}
                  onChange={(e, newValue) => setValues({...values, inflation_rate: newValue})}
                />
              </div>
            </div>
          </TabPanel>
        </div>
        <div className="calculators__preview">
          <h6 className="calculators__subtitle">Results: Own vs. Rent Breakeven Analysis</h6>
          <div className="calculators__preview-box">
            <div className="calculators__legend">
              <div className="calculators__legend-label">Own</div>
              <div className="calculators__legend-label calculators__legend-label-color3">Rent</div>
            </div>
            <div>
              <div className="calculators__preview-line">
                <div className="calculators__preview-line-holder">
                  <div className="calculators__preview-progress">
                    <ProgressCircle
                      amount={countValues.breakevenBegins}
                      title={countValues.breakevenBegins === 0 ? <>Never</> : <><strong>{countValues.breakevenBegins}</strong> age</>}
                      small reverse
                    />
                  </div>
                  <div>
                    <h6>Profit (Today’s $)</h6>
                    Your age at which owning begins to be more profitable than renting
                  </div>
                </div>
                <div className="calculators__preview-line-holder calculators__preview-line-holder--alt">
                  <Bar
                    height={80}
                    data={{
                      labels: [`$ ${countValues.beginOwnProfit}`, `$ ${countValues.beginRentProfit}`],
                      datasets: [
                        {
                          data: [countValues.beginOwnProfit, countValues.beginRentProfit],
                          backgroundColor: ['#215E59', '#FDB5A1'],
                          maxBarThickness: 114
                        }
                      ],
                    }}
                    options={optionsCalculator(null, true, true, false, false, false)}
                  />
                </div>
              </div>
            </div>
            <div className="calculators__preview-line">
              <div className="calculators__preview-line-holder">
                <div className="calculators__preview-progress">
                  <ProgressCircle
                    amount={countValues.breakevenEnds}
                    title={countValues.breakevenEnds === 0 ? <>Never</> : <><strong>{countValues.breakevenEnds}</strong> age</>}
                    small reverse
                  />
                </div>
                <div>
                  <h6>Profit (Today’s $)</h6>
                  Oftentimes, owning is more profitable for only a certain period of time. This tells you the age at which renting profit “catches up” with owning profit and hence becomes more profitable than owning in the long run.
                </div>
              </div>
              <div className="calculators__preview-line-holder calculators__preview-line-holder--alt">
                <Bar
                  height={80}
                  data={{
                    labels: [`$ ${countValues.endsOwnProfit}`, `$ ${countValues.endsRentProfit}`],
                    datasets: [
                      {
                        data: [countValues.endsOwnProfit, countValues.endsRentProfit],
                        backgroundColor: ['#215E59', '#FDB5A1'],
                        maxBarThickness: 114
                      }
                    ],
                  }}
                  options={optionsCalculator(null, true, true, false, false, false)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="calculators__buttons">
        <Button variant="contained" size="large" color="primary" onClick={() => setIsOpen(true)}>Save result</Button>
        {currentId &&
        <Button variant="contained" size="large" onClick={() => setIsDeleteModal(true)}>Remove result</Button>}
        <Button size="large" className="MuiButton-textGray" onClick={() => resetForm()}>Reset</Button>
      </div>

      <ModalBox
        open={isOpen}
        close={() => setIsOpen(false)}
        onClick={onSave}
        button="Save"
        title="Save Result"
        isLoading={loading}
        isDisabled={values && !values.name && !values.currentId}
      >
        <FormGroup name="name" labelText="Name">
          <Input
            name='name'
            value={values ? values.name : ''}
            placeholder="Type a name of result"
            onChange={e => setValues({
              ...values,
              name: e.target.value
            })}
          />
        </FormGroup>
        {listExisting && listExisting.length > 0 &&
        <>
          <p className="text-center">or</p>
          <FormGroup labelText="Select  Existing Result">
            <FormSelect
              options={listExisting}
              value={values ? values.currentId : ''}
              name="currentId"
              onChange={e => setValues({
                ...values,
                currentId: e.target.value,
                name: listExisting.find(i => i.value === e.target.value).label
              })}
              placeholder="Select Existing Result"
            />
          </FormGroup>
        </>
        }
      </ModalBox>
      <ModalBox
        small
        open={isDeleteModal}
        close={() => setIsDeleteModal(false)}
        onClick={removeResult}
        button="Remove"
        title={`Do you want to remove ${current && current.name}?`}
      />

      <ModalBox
        small
        open={isSavedModal}
        close={() => setIsSavedModal(false)}
        button="Ok"
        onClick={() => setIsSavedModal(false)}
      >
        <div>
          {calculators && calculators.iconLeft &&
          <div className="modal__title-iconLeft">
            <img src={calculators.iconLeft} alt={calculators.name} />
          </div>}
          <h4>Your calculator scenario has been saved.</h4>
          <p>You can access your saved scenarios through the “Select result” dropdown menu at the top of each calculator page.</p>
        </div>
      </ModalBox>
    </Layout>
  )
}

export default OwnVsRent
