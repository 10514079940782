const ranking = [
  {
    key: 1,
    label: '1',
    value: 1,
  },
  {
    key: 2,
    label: '2',
    value: 2,
  },
  {
    key: 3,
    label: '3',
    value: 3,
  },
  {
    key: 4,
    label: '4',
    value: 4,
  },
  {
    key: 5,
    label: '5',
    value: 5,
  },
  {
    key: 6,
    label: '6',
    value: 6,
  }
]

export default ranking;