import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import Layout from '../../components/Layout/Layout'
import { BackButton } from '../../components/shared/Buttons'
import TaskAnalysisCard from '../../components/Analysis/TaskAnalysisCard'
import { optionsStacked } from '../../utils/optionsBar'
import { Bar } from 'react-chartjs-2'
import { fetchAnalysisTasks } from '../../redux/slices/taskSlice'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchAnalysisOpenTasks,
  fetchAnalysisHighestPriorityTasks,
  fetchAnalysisHighPriorityTasks,
  fetchAnalysisLowestPriorityTasks,
  fetchAnalysisLowPriorityTasks,
  fetchAnalysisNormalPriorityTasks
} from '../../redux/slices/analysisSlice'
import isNull from 'lodash/isNull'
import isEmpty from 'lodash/isEmpty'
import Loading from '../../components/shared/Loading/Loading'
import { fetchThresholds } from '../../redux/slices/notificationsSlice'


const TasksAnalysis = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { analysis } = useSelector(state => state.task);
  const userId = useSelector(state => state.user.user.id);
  const { company_id } = useSelector((state) => state.user.user);
  const { loading, open, highestPriority, highPriority, normalPriority, lowPriority, lowestPriority } = useSelector(state => state.analysis)
  const thresholds = useSelector(state => state.notifications.thresholds)

  useEffect(() => {
    async function fetch() {
      Promise.all([
        await dispatch(fetchThresholds(company_id)),
        await dispatch(fetchAnalysisOpenTasks()),
        await dispatch(fetchAnalysisTasks()),
        await dispatch(fetchAnalysisOpenTasks()),
        await dispatch(fetchAnalysisHighestPriorityTasks()),
        await dispatch(fetchAnalysisHighPriorityTasks()),
        await dispatch(fetchAnalysisLowestPriorityTasks()),
        await dispatch(fetchAnalysisLowPriorityTasks()),
        await dispatch(fetchAnalysisNormalPriorityTasks())
      ])
    }

    if (userId) {
      fetch()
    }

    // eslint-disable-next-line
  }, [userId])

  const count = (total, current) => {
    if (total < current) return 100
    if (total === 0 || current === 0) {
      return '0'
    } else {
      return current * 100/total
    }
  }

  const dataAnalysis = data => {
    if (data && !isNull(data) && !isEmpty(data)) {
      const { labels, planningIds, categoryIds, priorityId, datasets } = data

      return {
        labels: labels,
        planningIds,
        categoryIds,
        priorityId,
        datasets: [
          {
            label: 'You',
            data: [...datasets[0].data],
            backgroundColor: '#FDB5A1',
            borderWidth: 4,
            borderColor: '#fff',
            borderSkipped: 'bottom',
            maxBarThickness: 80
          },
          {
            label: 'Client аverage',
            data: [...datasets[1].data],
            backgroundColor: '#215E59',
            borderWidth: 4,
            borderColor: '#fff',
            borderSkipped: 'bottom',
            maxBarThickness: 80
          }
        ],
      }
    }
  }

  const hendleClcik = (event, elements) => {
    if (elements[0]) {
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const planningId = chart.data.planningIds[element._index][1];
      const categoryId = chart.data.categoryIds[element._index][1];
      const priorityId = chart.data.priorityId;
      chart.active = [];
      chart.lastActive = [];
      chart.reset();
      history.push({
        pathname: "/tasks",
        search: `?planning_type=${planningId}&category_id=${categoryId}&priority_id=${priorityId}`,
      });
    }
  };


  return (
    <Layout decor>
      <BackButton title="Overview" path="/dashboard" className="back-button--position" />
      <h4>Analyze my action items</h4>
      <div className="analysis__holder">
        <div className="analysis__container">
          <div className="analysis__grid">
            <div>
              {open && !isNull(open) && !isEmpty(open)
                ? <div className="analysis__box">
                    <div className="analysis__box-title">
                      <h6>All open task by category</h6>
                      <div className="analysis__box-legend">
                        <div className="analysis__box-legend-label">
                          Client Average
                        </div>
                        <div className="analysis__box-legend-label analysis__box-legend-label-color2">
                          You
                        </div>
                      </div>
                    </div>
                    <Bar data={dataAnalysis(open)} options={optionsStacked('1', hendleClcik)} height={90} />
                  </div>
                : <strong className="text-success">You don't have any tasks</strong>}
            </div>
            <div className="analysis__grid-column-1-3 analysis__grid-column-flex">
              <div>
                <TaskAnalysisCard
                  title="All open tasks by owner"
                  tasks={analysis && analysis.openCount}
                  count={analysis && analysis.totalCount ? count(analysis.totalCount, analysis.openCount) : '0'}
                  limits={thresholds && !isEmpty(thresholds) && [
                    thresholds.superb_number_of_tasks,
                    thresholds.great_number_of_tasks,
                    thresholds.ok_to_great_number_of_tasks,
                    thresholds.ok_number_of_tasks,
                    thresholds.ok_to_poor_number_of_tasks,
                    thresholds.poor_number_of_tasks,
                    thresholds.really_poor_number_of_tasks
                  ]}
                />
              </div>
              <div>
                <TaskAnalysisCard
                  tooltip
                  title="Aging tasks"
                  tasks={analysis && analysis.agingTasks}
                  count={analysis && analysis.agingTasks ? count(analysis.openCount, analysis.agingTasks) : '0'}
                  limits={thresholds && !isEmpty(thresholds) && [
                    thresholds.superb_number_of_tasks,
                    thresholds.great_number_of_tasks,
                    thresholds.ok_to_great_number_of_tasks,
                    thresholds.ok_number_of_tasks,
                    thresholds.ok_to_poor_number_of_tasks,
                    thresholds.poor_number_of_tasks,
                    thresholds.really_poor_number_of_tasks
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        {(!isEmpty(highestPriority) || !isEmpty(highPriority)) &&
          <div className="analysis__container">
            <div className="analysis__grid analysis__grid-2">
              {highestPriority && !isNull(highestPriority) && !isEmpty(highestPriority) &&
              <div>
                <div className="analysis__box">
                  <div className="analysis__box-title">
                    <h6>Highest priority tasks</h6>
                    <div className="analysis__box-legend">
                      <div className="analysis__box-legend-label">
                        Client Average
                      </div>
                      <div className="analysis__box-legend-label analysis__box-legend-label-color2">
                        You
                      </div>
                    </div>
                  </div>
                  <Bar data={dataAnalysis(highestPriority)} options={optionsStacked('1', hendleClcik)} height={90}/>
                </div>
              </div>
              }
              {highPriority && !isNull(highPriority) && !isEmpty(highPriority) &&
              <div>
                <div className="analysis__box">
                  <div className="analysis__box-title">
                    <h6>High priority tasks</h6>
                    <div className="analysis__box-legend">
                      <div className="analysis__box-legend-label">
                        Client Average
                      </div>
                      <div className="analysis__box-legend-label analysis__box-legend-label-color2">
                        You
                      </div>
                    </div>
                  </div>
                  <Bar data={dataAnalysis(highPriority)} options={optionsStacked('1', hendleClcik)} height={90}/>
                </div>
              </div>
              }
            </div>
          </div>}

        {!isEmpty(normalPriority) &&
          <div className="analysis__container">
            <div className="analysis__box">
              <div className="analysis__box-title">
                <h6>Normal  priority tasks</h6>
                <div className="analysis__box-legend">
                  <div className="analysis__box-legend-label">
                    Client Average
                  </div>
                  <div className="analysis__box-legend-label analysis__box-legend-label-color2">
                    You
                  </div>
                </div>
              </div>
              {loading || isNull(normalPriority)
                ? <Loading xsmall/>
                : <Bar data={normalPriority && dataAnalysis(normalPriority)} options={optionsStacked('1', hendleClcik)} height={90} />
              }
            </div>
          </div>}
        {(!isEmpty(lowPriority) || !isEmpty(lowestPriority)) &&
          <div className="analysis__container">
            <div className="analysis__grid analysis__grid-2">

              {lowPriority && !isNull(lowPriority) && !isEmpty(lowPriority) &&
                <div>
                  <div className="analysis__box">
                    <div className="analysis__box-title">
                      <h6>Low priority tasks</h6>
                      <div className="analysis__box-legend">
                        <div className="analysis__box-legend-label">
                          Client Average
                        </div>
                        <div className="analysis__box-legend-label analysis__box-legend-label-color2">
                          You
                        </div>
                      </div>
                    </div>
                    {loading || isNull(lowPriority)
                      ? <Loading xsmall/>
                      : <Bar data={dataAnalysis(lowPriority)} options={optionsStacked('1', hendleClcik)} height={90}/>
                    }
                  </div>
                </div>}
              {lowestPriority && !isNull(lowestPriority) && !isEmpty(lowestPriority) &&
                <div>
                  <div className="analysis__box">
                    <div className="analysis__box-title">
                      <h6>Lowest priority tasks</h6>
                      <div className="analysis__box-legend">
                        <div className="analysis__box-legend-label">
                          Client Average
                        </div>
                        <div className="analysis__box-legend-label analysis__box-legend-label-color2">
                          You
                        </div>
                      </div>
                    </div>
                    {loading || isNull(lowestPriority)
                      ? <Loading xsmall/>
                      : <Bar data={dataAnalysis(lowestPriority)} options={optionsStacked('1', hendleClcik)} height={90} />
                    }
                  </div>
                </div>}
            </div>
          </div>}
        </div>
    </Layout>
  )
}

export default TasksAnalysis
