import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import { FormGroup, Input, InputDatePicker } from '../shared/Form'
import { Button, TextareaAutosize } from '@mui/material'
import { BackButton } from '../shared/Buttons'
import { setMsg, reminderTask } from '../../redux/slices/taskSlice'
import { setMode } from '../../redux/slices/modeSlice'
import { TaskReminderSchema } from '../../utils/validation'

const TaskReminder = ({ id, close, title, isCreate, reminderDetails, updateReminder }) => {
  const dispatch = useDispatch();
  const sending = useSelector(state => state.sending.isSending);
  const { current, msg } = useSelector(state => state.task);
  const { first_name, last_name, email } = useSelector(state => state.user.user);

  const onSubmit = values => {
    dispatch(reminderTask(id, values));
  }

  const onUpdate = values => {
    dispatch(updateReminder(id, values))
  }

  return (
    <div className="drawer__holder">
      {isCreate ? (
        <BackButton
          className="drawer__back-button"
          title={current && current.category.name}
          handleClick={() => {
            dispatch(setMsg(""));
            dispatch(setMode("view"));
          }}
        />
      ) : (
        <BackButton
          className="drawer__back-button"
          title="Back to Reminders"
          handleClick={close}
        />
      )}
      {msg ? (
        <div className="drawer__info">
          <div className={"drawer__title"}>{title}</div>
          <div>{msg}</div>
          <div
            className={`form__button drawer__buttons ${
              msg ? "drawer__buttons--alt" : ""
            }`}
          >
            <Button
              variant="contained"
              size="large"
              color="primary"
              type="submit"
              disabled={!!msg}
            >
              Save
              {sending && <span className="spinner" />}
            </Button>
            <Button size="large" className="MuiButton-textGray" onClick={close}>
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <Formik
          enableReinitialize
          initialValues={
            isCreate
              ? {
                  task_id: id,
                  organizer_name: `${first_name} ${last_name}`,
                  organizer_email: email,
                  personalized_message: "",
                  attendee_email: "",
                  required_attendee: "",
                  meeting_location: "",
                  task_due_date: "",
                }
              : {
                  task_id: reminderDetails.task_id,
                  organizer_name: reminderDetails.organizer_name,
                  organizer_email: reminderDetails.organizer_email,
                  personalized_message: reminderDetails.personalized_message,
                  attendee_email: reminderDetails.attendee_email,
                  required_attendee: reminderDetails.required_attendee,
                  meeting_location: reminderDetails.meeting_location,
                  task_due_date: reminderDetails.task_due_date,
                }
          }
          validationSchema={TaskReminderSchema}
          onSubmit={isCreate ? onSubmit : (values) => onUpdate(values)}
        >
          {({
            errors,
            handleChange,
            handleBlur,
            values,
            touched,
            setFieldValue,
          }) => (
            <Form>
              <div className={"drawer__title"}>{title}</div>
              <FormGroup name="organizer_name" labelText="Organizer Name">
                <Input
                  name="organizer_name"
                  placeholder="Type name"
                  type="text"
                  onChange={handleChange}
                  value={values.organizer_name || ""}
                  onBlur={handleBlur}
                  hasError={touched.organizer_name && !!errors.organizer_name}
                />
                {touched.organizer_name && errors.organizer_name && (
                  <span className="form__msg-error">
                    {errors.organizer_name}
                  </span>
                )}
              </FormGroup>
              <FormGroup name="required_attendee" labelText="Required attendee">
                <Input
                  name="required_attendee"
                  placeholder="Type name"
                  type="text"
                  onChange={handleChange}
                  value={values.required_attendee || ""}
                  onBlur={handleBlur}
                  hasError={
                    touched.required_attendee && !!errors.required_attendee
                  }
                />
                {touched.required_attendee && errors.required_attendee && (
                  <span className="form__msg-error">
                    {errors.required_attendee}
                  </span>
                )}
              </FormGroup>
              <FormGroup name="organizer_email" labelText="Organizer email">
                <Input
                  name="organizer_email"
                  placeholder="Type email"
                  type="email"
                  onChange={handleChange}
                  value={values.organizer_email || ""}
                  onBlur={handleBlur}
                  hasError={touched.organizer_email && !!errors.organizer_email}
                />
                {touched.organizer_email && errors.organizer_email && (
                  <span className="form__msg-error">
                    {errors.organizer_email}
                  </span>
                )}
              </FormGroup>
              <FormGroup name="attendee_email" labelText="Attendee email">
                <Input
                  name="attendee_email"
                  placeholder="Type email"
                  type="email"
                  onChange={handleChange}
                  value={values.attendee_email || ""}
                  onBlur={handleBlur}
                  hasError={touched.attendee_email && !!errors.attendee_email}
                />
                {touched.attendee_email && errors.attendee_email && (
                  <span className="form__msg-error">
                    {errors.attendee_email}
                  </span>
                )}
              </FormGroup>
              <FormGroup name="meeting_location" labelText="Meeting Location">
                <Input
                  name="meeting_location"
                  placeholder="Type a name of meeting location"
                  type="text"
                  onChange={handleChange}
                  value={values.meeting_location || ""}
                  onBlur={handleBlur}
                  hasError={
                    touched.meeting_location && !!errors.meeting_location
                  }
                />
                {touched.meeting_location && errors.meeting_location && (
                  <span className="form__msg-error">
                    {errors.meeting_location}
                  </span>
                )}
              </FormGroup>
              <FormGroup name="task_due_date" labelText="Task due date">
                <InputDatePicker
                  name="task_due_date"
                  hasError={touched.task_due_date && !!errors.task_due_date}
                  value={
                    values.task_due_date ? new Date(values.task_due_date) : null
                  }
                  setFieldValue={setFieldValue}
                />
                {touched.task_due_date && errors.task_due_date && (
                  <span className="form__msg-error">
                    {errors.task_due_date}
                  </span>
                )}
              </FormGroup>
              <FormGroup
                name="personalized_message"
                labelText="Personalized Reminder Message"
              >
                <TextareaAutosize
                  name="personalized_message"
                  value={values.personalized_message || ""}
                  className={`form__textarea form__textarea--alt form__textarea--resize ${
                    touched.personalized_message &&
                    !!errors.personalized_message &&
                    "form__textarea--error"
                  }`}
                  placeholder="Text message"
                  onChange={handleChange}
                />
                {touched.personalized_message &&
                  errors.personalized_message && (
                    <span className="form__msg-error">
                      {errors.personalized_message}
                    </span>
                  )}
              </FormGroup>
              <div className="form__button drawer__buttons">
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  type="submit"
                  disabled={sending || !!msg}
                >
                  Save
                  {sending && <span className="spinner" />}
                </Button>
                <Button
                  size="large"
                  className="MuiButton-textGray"
                  onClick={close}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}

TaskReminder.propTypes = {
  id: PropTypes.number,
  close: PropTypes.func.isRequired
}

export default TaskReminder
