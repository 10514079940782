import React, { useState } from 'react'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import { FormGroup, Input } from '../shared/Form'
import { Button, TextareaAutosize } from '@mui/material'
import { BackButton } from '../shared/Buttons'
import { sendMail, setMsg } from '../../redux/slices/taskSlice'
import { setMode } from '../../redux/slices/modeSlice'
import { emailFormat } from '../../utils/validation'

import Modal from '../../components/shared/Modal/Modal'
import SVGIcon from '../../components/shared/SVGIcon/SVGIcon'
import Icon from '../../assets/images/icons/icon-ongratulations.svg'

const TaskMail = ({ id, close }) => {
  const dispatch = useDispatch()
  const sending = useSelector(state => state.sending.isSending)
  const { current, msg } = useSelector(state => state.task)
  const [isOpen, setIsOpen] = useState(false)

  const onSubmit = values => {
    dispatch(sendMail(id, values))
    setIsOpen(true)
  }

  return (
    <div className="drawer__holder">
      <BackButton
        className="drawer__back-button"
        title={current && current.category.name}
        handleClick={() => {
          dispatch(setMsg(''))
          dispatch(setMode('view'))
        }}
      />
      <Formik
        enableReinitialize
        initialValues={{
          email: ''
        }}
        validationSchema={Yup.object().shape({
          email: emailFormat,
          name_recipient: Yup.string().required('Required'),
          description: Yup.string().required('Required')
        })}
        onSubmit={onSubmit}
      >
        {({ errors, handleChange, handleBlur, values }) => (
          <Form>
            {msg
              ?<div>{msg}</div>
              : <>
                <div className="drawer__title">
                  Prepare your email
                </div>

                <FormGroup name="email" labelText="Email to">
                  <Input
                    name="email"
                    placeholder="Type email of recipient"
                    type="text"
                    onChange={handleChange}
                    value={values.email || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.email}
                  />
                  {errors.email && <span className="form__msg-error">{errors.email}</span>}
                </FormGroup>
                <FormGroup name="name_recipient" labelText="Name of recipient">
                  <Input
                    name="name_recipient"
                    placeholder="Type name of recipient"
                    type="text"
                    onChange={handleChange}
                    value={values.name_recipient || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.name_recipient}
                  />
                  {errors.name_recipient && <span className="form__msg-error">{errors.name_recipient}</span>}
                </FormGroup>
                <FormGroup name="description" labelText="Description">
                  <TextareaAutosize
                    name="description"
                    value={values.description || ''}
                    className="form__textarea form__textarea--alt"
                    placeholder="Type description of task"
                    onChange={handleChange}
                  />
                  {errors.description && <span className="form__msg-error">{errors.description}</span>}
                </FormGroup>
              </>
            }

            <div className="form__button drawer__buttons">
              <Button variant="contained" size="large" color="primary" type="submit" disabled={sending || !!msg}>
                Send
                {sending && <span className="spinner" />}
              </Button>
              <Button
                size="large"
                className="MuiButton-textGray"
                onClick={close}
              >
                Cancel
              </Button>
            </div>

            <Modal
              open={isOpen}
              small
              button="Ok"
              close={() => setIsOpen(false)}
              onClick={() => setIsOpen(false)}
            >
              <div>
                <div className="modal__title-icon">
                  <SVGIcon icon={Icon} />
                </div>
                <h4>Your email has been sent. Please ensure recipient checks their junk mail in the event they haven’t received the email.</h4>
              </div>
            </Modal>
          </Form>
        )}
      </Formik>
    </div>
  )
}

TaskMail.propTypes = {
  id: PropTypes.number,
  close: PropTypes.func.isRequired
}

export default TaskMail
