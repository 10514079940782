import React, { memo, useEffect, useState } from "react";
import clsx from "classnames";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useQuery } from "../../../utils/hooks";

const TableCell = ({ sticky, nowrap, offsetLeft, sortBy, offsetRight, className, component = 'td', children, ...rest }) => {
  const Component = component;
  const classes = clsx(
    'table__cell',
    className,
    { 'table__cell__head': component === 'th' },
    { 'table__cell__body': component === 'td' },
    { 'table__cell-nowrap': nowrap },
    { 'table__cell-offset-left': offsetLeft },
    { 'table__cell-offset-right': offsetRight },
    { 'table__cell-sticky': sticky },
  );
  const location = useLocation();
  const history = useHistory();
  const query = useQuery();
  const [sortType, setSortType] = useState('ASC');

  useEffect(() => {
    const querySortType = query.get('sort_type');
    if (querySortType) setSortType(querySortType);
  }, [query]);

  const handleSort = () => {
    let type;
    if (query.get('sort_by') !== sortBy) {
      type = 'ASC'
    } else {
      type = sortType === 'ASC' ? 'DESC' : 'ASC';
    }
    query.set('sort_type', type);
    query.set('sort_by', sortBy);

    history.push({ pathname: location.pathname, search: query.toString() });
    setSortType(type);
  }

  return (
    <Component align="left" {...rest} className={classes}>
      {
        sortBy
          ? <span className="table__cell__sort" onClick={handleSort}>
              {children}
            {
              sortType === 'DESC' && sortBy.toLowerCase() === query.get('sort_by')
                ? <ExpandLessIcon/>
                : <ExpandMoreIcon/>
            }
            </span>
          : children
      }
    </Component>
  )
}

TableCell.propTypes = {
  sticky: PropTypes.bool,
  nowrap: PropTypes.bool,
  sortBy: PropTypes.string,
  offsetLeft: PropTypes.bool,
  offsetRight: PropTypes.bool,
  component: PropTypes.string,
  className: PropTypes.string,
}

export default memo(TableCell);