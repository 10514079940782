import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Formik } from 'formik'
import Layout from '../../../components/Layout/Layout'
import LayoutForm from '../../../components/Layout/LayoutForm'
import { BackButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import { FormGroup } from '../../../components/shared/Form'
import { Button, FormControlLabel, Radio, RadioGroup, TextareaAutosize } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import Illustration from '../../../assets/images/illustrations/Tree-segment_protection-min.png'
import { fetchEstate, postEstate } from '../../../redux/slices/protectionSlice'
import { getPercent, normalizeBoolean } from '../../../utils/utils'
import { fetchPercentages } from '../../../redux/slices/percentagesSlice'
import Modal from '../../../components/shared/Modal/Modal'
import ProgressCircle from '../../../components/shared/ProgressBar/ProgressCircle'
import { setIsOpen } from '../../../redux/slices/modalSlice'

const Estate = ({ props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.user.user);
  const { estate } = useSelector(state => state.protection);
  const sending = useSelector(state => state.sending.isSending);
  const percents = useSelector(state => state.percentages.percents);
  const isOpen = useSelector(state => state.modal.isOpen);
  const [isMobile, setIsMobile] = useState(false);
  const mobileView = 999;
  const handleResize = () => {
    (mobileView >= window.innerWidth) ? setIsMobile(true) : setIsMobile(false)
  }

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchPercentages());
      await dispatch(fetchEstate());
    }
    if (id) {
      fetch()
    }

    // eslint-disable-next-line
  }, [id])

  const onSubmit = (values) => {
    dispatch(postEstate(values, props))
  }

  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }))
  }

  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }))
    history.push("/input")
  }

  const profile       = getPercent(percents, 'profile')
  const estatePercent = getPercent(percents, 'estate')

  return (
    <Layout isBlockedPage={percents && profile === 0}>
      <LayoutForm illustration={Illustration} small>
        <BackButton title={isMobile ? 'Back' : 'Back to Tree'} />
        <PageTitle
          title="Estate planning"
          content="Please answer the following estate planning questionnaire, as it will help determine your current level of preparedness with respect to estate-related matters."
          status={percents && estatePercent ? estatePercent : '0'}
          info={
            <>
              <div>The Estate Tax Module allows you to either deduct your estate tax liability from your terminal net worth or displays estate tax liabilities alerts to keep you informed of potential estate tax liability.</div>
              <hr className="tooltip__line"/>
              <h6>Example</h6>
              <div>Estate planning is not just for wealthy people.  It's important to stay apprised of estate related items such as wills, trusts, beneficiaries, among other key items.</div>
            </>
          }
        />
        <div className="layout-form__content-holder">
          <Formik
            enableReinitialize
            initialValues={estate}
            onSubmit={onSubmit}
          >
            {({ errors, handleChange, handleBlur, values, touched, setFieldValue }) => (
              <Form>
                <FormGroup
                  name="will_upto_date"
                  labelText="Is your will or living trust up to date by ensuring it includes all your assets, all the loved ones you'd like to leave assets to and how much to leave each"
                  info={{
                    content:
                      <>
                        Ensuring you have a detailed will in place reduces legal expenses for your loved ones after you die, and ensures your loved ones get exactly what you wanted them to get.
                      </>
                  }}
                >
                  <RadioGroup
                    name="will_upto_date"
                    value={values.will_upto_date || false}
                    onChange={(e) => {
                      setFieldValue('will_upto_date', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                </FormGroup>
                <FormGroup
                  name="living_will"
                  labelText="Have you created a living will"
                  info={{
                    content:
                      <>
                        A living will (also known as an advance medical directive) is a statement of your wishes for the kind of life-sustaining medical intervention you want, or don't want, in the event that you become terminally ill and unable to communicate.
                      </>
                  }}
                >
                  <RadioGroup
                    name="living_will"
                    value={values.living_will || false}
                    onChange={(e) => {
                      setFieldValue('living_will', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                </FormGroup>
                <FormGroup
                  name="revocable_living_trust"
                  labelText="Have you created a revocable living trust"
                  info={{
                    content:
                      <>
                        A Revocable Living Trust can be used as a substitute for a will by providing for the distribution of assets upon the grantor's death. This allows for a faster and less costly method of asset transfer than a will, which requires court supervision or probate.  Since the assets are still part of the grantor's estate, however, estate taxes must be paid on the transfer of trust assets.
                      </>
                  }}
                >
                  <RadioGroup
                    name="revocable_living_trust"
                    value={values.revocable_living_trust || false}
                    onChange={(e) => {
                      setFieldValue('revocable_living_trust', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                </FormGroup>
                <FormGroup
                  name="irrevocable_living_trust"
                  labelText="Have you created an irrevocable living trust"
                  info={{
                    content:
                      <>
                        Similar to a revocable living trust with two key distinctions: 1) Downside: control of the trust is surrendered by the grantor and cannot be changed once implemented; 2) Upside: Escapes estate taxes.
                      </>
                  }}
                >
                  <RadioGroup
                    name="irrevocable_living_trust"
                    value={values.irrevocable_living_trust || false}
                    onChange={(e) => {
                      setFieldValue('irrevocable_living_trust', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                </FormGroup>
                <FormGroup
                  name="accounts_upto_date"
                  labelText="Do all your asset accounts and asset policies include primary and secondary beneficiaries, such as brokerage and retirement accounts and life insurance policies"
                  info={{
                    content:
                      <>
                        Ensure you have designated beneficiaries on all your accounts and policies reduces legal fees for your loved ones after you die since it provides a clear understanding of which loved ones should inherit your specific accounts.
                      </>
                  }}
                >
                  <RadioGroup
                    name="accounts_upto_date"
                    value={values.accounts_upto_date || false}
                    onChange={(e) => {
                      setFieldValue('accounts_upto_date', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                </FormGroup>
                <FormGroup
                  name="executor"
                  labelText="Have you named an executor to your estate as part of your will"
                  info={{
                    content:
                      <>
                        An executor is the person you would like to execute on all matters related to your financial and legal logistics upon your death and helps carry out the details of your will.  This can either be a loved one or a professional such as a trusted lawyer.
                      </>
                  }}
                >
                  <RadioGroup
                    name="executor"
                    value={values.executor || false}
                    onChange={(e) => {
                      setFieldValue('executor', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                </FormGroup>
                <FormGroup
                  name="join_estate"
                  labelText="Have you created a joint estate plan with your spouse"
                  info={{
                    content:
                      <>
                        For example, an estate shared equally between husband and wife can allow couple to take advantage of double the individual exemptions hence significantly reducing estate taxes.
                      </>
                  }}
                >
                  <RadioGroup
                    name="join_estate"
                    value={values.join_estate || false}
                    onChange={(e) => {
                      setFieldValue('join_estate', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                </FormGroup>
                <FormGroup
                  name="gifting"
                  labelText="Have you undertaken a gifting strategy"
                  info={{
                    content:
                      <>
                        Gifting loved ones a maximum of $12,000 per year ($13,000 in 2009) will help reduce gross estate value upon death, hence significantly reducing estate taxes owed.
                      </>
                  }}
                >
                  <RadioGroup
                    name="gifting"
                    value={values.gifting || false}
                    onChange={(e) => {
                      setFieldValue('gifting', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                </FormGroup>
                <FormGroup
                  name="charity"
                  labelText="Have you donated money to a charity as part of estate plan"
                  info={{
                    content:
                      <>
                        Leaving money to a charity upon death is typically deductible from gross estate value and hence can significantly reduce the estate tax liability due.
                      </>
                  }}
                >
                  <RadioGroup
                    name="charity"
                    value={values.charity || false}
                    onChange={(e) => {
                      setFieldValue('charity', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                </FormGroup>
                <FormGroup
                  name="ab_trust"
                  labelText="Have you created an AB Trust"
                  info={{
                    content:
                      <>
                        An AB trust states that when the first spouse dies, the AB trust must be split into two separate trusts: Trust A will contain the deceased spouse's property, and Trust B will hold the property of the surviving spouse. Because Trust A property never legally belongs to the surviving spouse, it is subject to tax only once, when the first spouse dies and hence allows the surviving spouse to take advantage of the deceased spouse's estate tax exemption. If the assets in Trust A appreciate in value before the second spouse dies, the increase isn't subject to estate tax. This is a major advantage of AB trusts.
                      </>
                  }}
                >
                  <RadioGroup
                    name="ab_trust"
                    value={values.ab_trust || false}
                    onChange={(e) => {
                      setFieldValue('ab_trust', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                </FormGroup>
                <FormGroup
                  name="qualified_tip"
                  labelText="Have you created a Qualified Terminal Interest Property Trust (QTIP)"
                  info={{
                    content:
                      <>
                        The QTIP grants a life interest to the surviving spouse, who is entitled to receive income from the trust regularly and make use of any trust assets, such as a house. The spouse is also allowed to spend trust principal to any extent that's allowed by the trust. The grantor of the trust names beneficiaries who will inherit the trust assets when the surviving spouse dies. Upon the death of the survivor, the net value of the property in the QTIP is included in the survivor's taxable estate. QTIP assets don't go through probate at either the grantor's or the surviving spouse's deaths.
                      </>
                  }}
                >
                  <RadioGroup
                    name="qualified_tip"
                    value={values.qualified_tip || false}
                    onChange={(e) => {
                      setFieldValue('qualified_tip', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                </FormGroup>
                <FormGroup
                  name="qualified_prt"
                  labelText="Have you created a qualified personal residence trust (QPRT)"
                  info={{
                    content:
                      <>
                        A qualified personal residence trust (QPRT) can remove the value of your home or vacation dwelling from your estate and is particularly useful if your home is likely to appreciate in value.
                      </>
                  }}
                >
                  <RadioGroup
                    name="qualified_prt"
                    value={values.qualified_prt || false}
                    onChange={(e) => {
                      setFieldValue('qualified_prt', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                </FormGroup>
                <FormGroup
                  name="insurance_trust"
                  labelText="Have you utilized an irrevocable life insurance trust"
                  info={{
                    content:
                      <>
                        An irrevocable life insurance trust (ILIT) can remove your life insurance from your taxable estate, help pay estate costs, and provide your heirs with cash for a variety of purposes. To remove the policy from your estate, you surrender ownership rights, which means you may no longer borrow against it or change beneficiaries. In return, the proceeds from the policy may be used to pay any estate costs after you die and provide your beneficiaries with tax-free income.
                      </>
                  }}
                >
                  <RadioGroup
                    name="insurance_trust"
                    value={values.insurance_trust || false}
                    onChange={(e) => {
                      setFieldValue('insurance_trust', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                </FormGroup>
                <FormGroup
                  name="medicaid"
                  labelText="Have you set up a Medicaid optimization strategy"
                  info={{
                    content:
                      <>
                        There is currently a 60 month look-back period on calculating your existing assets, as part of the calculation used to qualify for Medicaid.  You may be able to set up an estate planning strategy that allows you to effectively transfer your assets such that you qualify for Medicaid.
                      </>
                  }}
                >
                  <RadioGroup
                    name="medicaid"
                    value={values.medicaid || false}
                    onChange={(e) => {
                      setFieldValue('medicaid', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                </FormGroup>
                <h5 className="mt--medium">Additional info</h5>
                <FormGroup name="additional_info" labelText="Is there any other Estate Planning information that you believe is important for ValueSoft to know about? If so, please let us know about it:">
                  <TextareaAutosize
                    maxLength="200"
                    name="additional_info"
                    value={values.additional_info || ''}
                    className="form__textarea"
                    placeholder="Maximum characters: 200"
                    onChange={handleChange}
                  />
                </FormGroup>

                <div className="form__button">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Save
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <Modal
          open={isOpen}
          small
          button="Done"
          onClick={handleClick}
          close={onClose}
        >
          <h3>Good Job!</h3>
          <p>Your progress increased 100%</p>
          <ProgressCircle amount={100} small />
        </Modal>
      </LayoutForm>
    </Layout>
  );
};

export default Estate;
