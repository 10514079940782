import React, { useEffect } from 'react'
import { Form, Formik } from 'formik'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../../components/Layout/Layout'
import { BackButton, DeleteButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import LayoutForm from '../../../components/Layout/LayoutForm'
import {Button, FormControlLabel, Radio, RadioGroup, TextareaAutosize} from '@mui/material'
import {FormGroup, InputCurrency, FormSelect, Input, InputPercent} from '../../../components/shared/Form'
import { fetchIncrementalById, postUserIncremental, removeIncremental, setIsOpenModal } from '../../../redux/slices/financesSlice'
import { setIsOpen } from '../../../redux/slices/modalSlice'
import ModalBox from '../../../components/shared/Modal/Modal'
import typeIncremental from '../../../Data/typeIncremental'
import Illustration from '../../../assets/images/illustrations/Tree-segment_finances-min.png'
import { incrementalSchema } from '../../../utils/validationFinances'
import ProgressCircle from '../../../components/shared/ProgressBar/ProgressCircle'


const IncrementalIncomeDetails = ({ props, isEdit }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isOpen = useSelector(state => state.modal.isOpen);
  const userId = useSelector(state => state.user.user.id);
  const sending = useSelector(state => state.sending.isSending);
  const { incremental, isOpenModal } = useSelector(state => state.finances);
  const { id }  = useParams();

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchIncrementalById(id));
    }

    if (userId && id) {
      fetch()
    }

    // eslint-disable-next-line
  }, [userId, id])

  const deleteIncome = () => {
    dispatch(removeIncremental(id, props));
    dispatch(setIsOpen({isOpen: false}))
  }

  const onSubmit = values => {
    dispatch(postUserIncremental(values, props, id, isEdit))
  }

  const handleChangeInput = (e, setValue) => {
    if([null, false].includes(e.target.value)) return false

    setValue(e.target.name, e.target.value)
  }

  const handleClick = () => {
    dispatch(setIsOpenModal(false));
    history.push('/incremental-income')
  }

  return (
    <Layout>
      <LayoutForm small illustration={Illustration}>
        <BackButton title="Back to Incremental" />
        {isEdit && <DeleteButton className="delete-button--position" onClick={() => dispatch(setIsOpen({isOpen: true}))} />}
        <PageTitle
          title="Incremental income & expenses"
          content="Tell us about any one-time or infrequent cash inflows or outflows you expect to have over your lifetime, and ValueSoft will include it as part of your overall financial plan."
          status={isEdit ? 100 : '0'}
          info={
            <>
              Input any incremental income or expenses you expect, that is not a recurring component of your income or expenses.  This will allow ValueSoft to include this income and/or expenses when formulating your net worth, cash flow and other financial results calculations.  Remember, the more accurate you are, the more accurate ValueSoft will be!
              <hr className="tooltip__line"/>
              <h6>Example</h6>
              <div>Let's say you are 30 and expect an inheritance from a distant aunt for $10,000 in 2 years and another inheritance amount from your parents 18 years from now, and expect both to be invested in CDs that are yielding 2.50% until that time.  Just enter in these estimated inheritance figures since that's real income you'll get in the future that will impact your financial condition and help meet your goals and needs.</div>
            </>
          }
        />
        <div className="layout-form__content-holder">
          <Formik
            enableReinitialize
            initialValues={isEdit && incremental
              ? {...incremental}
              : {
                name: '',
                flow: '',
                age: '',
                amount: '',
                return_of_cash: '',
                type: ''
              }
            }
            validationSchema={incrementalSchema}
            onSubmit={onSubmit}
          >
            {({ errors, handleChange, handleBlur, values, touched, setFieldValue }) => (
              <Form>
                <FormGroup name="name" labelText="Name">
                  <Input
                    name="name"
                    placeholder="Enter name"
                    type="text"
                    onChange={handleChange}
                    value={values.name || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.name && touched.name}
                  />
                  {touched.name && errors.name && <span className="form__msg-error">{errors.name}</span>}
                </FormGroup>
                <FormGroup
                  name="flow"
                  labelText="Inflow or Outflow?"
                  info={{
                    content:
                      <>An inflow is a source of income, such as inheritance or lottery winnings.  An outflow is an expense, such as a wedding or honeymoon expense.</>
                  }}
                >
                  <RadioGroup
                    name="flow"
                    value={values.flow || ''}
                    onChange={handleChange}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value="inflow" control={<Radio />} label="Income inflow" />
                    <FormControlLabel value="outflow" control={<Radio />} label="Expenses Outflow" />
                  </RadioGroup>
                  {touched.flow && errors.flow && <span className="form__msg-error">{errors.flow}</span>}
                </FormGroup>
                <FormGroup
                  name="age"
                  labelText="Your age at Inflow/Outflow"
                >
                  <Input
                    name="age"
                    placeholder="Type age"
                    type="number"
                    onChange={e => handleChangeInput(e, setFieldValue)}
                    value={values.age || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.age && touched.age}
                    info={{
                      content:
                        <>
                          Enter the age in which you will receive or incur this inflow/outflow.
                          <hr className="tooltip__line"/>
                          <h6>Example</h6>
                          <div>Let's say you are 30 and expect an inheritance from a distant aunt for $10,000 in 2 years, and expect it to be invested in CDs that are yielding 2.50% until that time.  In this case, enter 32 here.</div>
                        </>
                    }}
                  />
                  {touched.age && errors.age && <span className="form__msg-error">{errors.age}</span>}
                </FormGroup>
                <FormGroup
                  name="amount"
                  labelText="Amount (Today's $)"
                >
                  <InputCurrency
                    placeholder="Amount"
                    name="amount"
                    onChange={handleChange}
                    value={values.amount || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.amount && touched.amount}
                    info={{
                      content:
                        <>
                          Enter the the amount of the inflow/outflow in today's dollars.
                          <hr className="tooltip__line"/>
                          <h6>Example</h6>
                          <div>Let's say you are 30 and expect an inheritance from a distant aunt for $10,000 in 2 years, and expect it to be invested in CDs that are yielding 2.50% until that time.  In this case, enter $10,000 here.</div>
                        </>
                    }}
                  />
                  {touched.amount && errors.amount && <span className="form__msg-error">{errors.amount}</span>}
                </FormGroup>
                <FormGroup
                  name="return_of_cash"
                  labelText="Approximate annual return of cash inflow / outflow funds until received / expended:"
                  info={{
                    content:
                      <>
                        This is the approximate annual return you expect to make on this money until it is given to you or until you expend it.
                        <hr className="tooltip__line"/>
                        <h6>Example</h6>
                        <div>Let's say you are 30 and expect an inheritance from a distant aunt for $10,000 in 2 years, and expect it to be invested in CDs that are yielding 2.50% until that time.  In this case, enter 2.50% here.</div>
                      </>
                  }}
                >
                  <InputPercent
                    placeholder="Type %"
                    name="return_of_cash"
                    onChange={handleChange}
                    value={values.return_of_cash || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.return_of_cash && touched.return_of_cash}
                  />
                  {touched.return_of_cash && errors.return_of_cash && <span className="form__msg-error">{errors.return_of_cash}</span>}
                </FormGroup>
                <FormGroup name="type" labelText="Type">
                  <FormSelect
                    options={typeIncremental}
                    id="type"
                    value={values.type || ''}
                    name="type"
                    onChange={(e) => {
                      setFieldValue(e.target.name, e.target.value)
                    }}
                    placeholder="Select"
                    hasError={!!errors.type && touched.type}
                  />
                  {touched.type && errors.type && <span className="form__msg-error">{errors.type}</span>}
                </FormGroup>
                <h5 className="mt--medium">Additional info</h5>
                <FormGroup name="additional_info" labelText="Is there any other Incremental Income & Expenses information that you believe is important for ValueSoft to know about? If so, please let us know about it:	">
                  <TextareaAutosize
                    maxLength="200"
                    name="additional_info"
                    value={values.additional_info || ''}
                    className="form__textarea"
                    placeholder="Maximum characters: 200"
                    onChange={handleChange}
                  />
                </FormGroup>
                <div className="form__button">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Save
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <ModalBox
          small
          inline
          open={isOpen}
          title="Are you sure?"
          button="Remove Item"
          closeButton="Cancel"
          onClick={deleteIncome}
          close={() => dispatch(setIsOpen({isOpen: false}))}
        />
        <ModalBox
          open={isOpenModal}
          small
          button="Done"
          onClick={handleClick}
          close={() => dispatch(setIsOpenModal(false))}
        >
          <h3>Good Job!</h3>
          <p>Your progress increased 100%</p>
          <ProgressCircle amount={100} small />
        </ModalBox>
      </LayoutForm>
    </Layout>
  );
};

export default IncrementalIncomeDetails;
