import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../../components/Layout/Layout'
import { BackButton } from '../../components/shared/Buttons'
import Tooltip from '../../components/shared/Tooltip/Tooltip'
import { Slider, Input, FormGroup, FormSelect, InputCurrency } from '../../components/shared/Form'
import PercentIcon from '../../assets/images/icons/percentage.svg'
import {Button} from '@mui/material'
import ModalBox from '../../components/shared/Modal/Modal'
import Tabs, { TabPanel } from '../../components/shared/Tabs/Tabs'
import SpeedometerLimit from '../../components/shared/Speedometer/SpeedometerLimit'
import {
  fetchListResults,
  fetchCalculators,
  saveItemResult,
  fetchCurrentResult,
  removeItemResult, setCurrentCalculator
} from '../../redux/slices/calculatorSlice'
import { convertDecimal } from '../../utils/utils'
import numeral from 'numeral'
import { fetchTimeToBuyHomeAdmin } from '../../redux/slices/calculatorsSetupSlice'

const TimeToBuyHome = ({ props }) => {
  const dispatch = useDispatch()
  const [tabActive, setActiveTab] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [isSavedModal, setIsSavedModal] = useState(false)
  const { id, company_id } = useSelector(state => state.user.user)
  const { calculators, current, listExisting, loading } = useSelector(state => state.calculator)
  const baseValues = useSelector(state => state.calculatorsSetup.timeToBuyHomeAdmin);
  const [currentId, setCurrentId] = useState('')
  const [values, setValues] = useState(null)

  useEffect(() => {
    if(baseValues){
      setValues({
        currentId: '',
        name: '',
        ...baseValues
      })
    }
  // eslint-disable-next-line
  }, [baseValues])

  const [countValues, setCountValues] = useState({
    fundsOnDateOfPurchase: 0,
    fundsAvailableForDownpayment: 0,
    numberMonths: 0
  })

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchTimeToBuyHomeAdmin(company_id));
      await dispatch(fetchCalculators({ path: 'time-to-buy-home' }));
      await dispatch(fetchListResults('time-to-buy-home'))
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
  if(values) {
    let homeValuePurchase = +values.home_value * Math.pow((1 + +values.home_appreciation_rate/100), (+values.age_buy_home - +values.current_age))
    let closingCostsAmount = +values.closing_costs/100 * homeValuePurchase
    let emergencyFundNeeds = +values.current_monthly_expenses * +values.months_of_emergency_available

    let fundsOnDateOfPurchase = homeValuePurchase * (+values.down_payment/100) + closingCostsAmount;
    let fundsAvailableForDownpayment = +values.additional_funds_available + +values.available_brokerage_funds + +values.available_checking_funds + +values.available_savings_funds - emergencyFundNeeds
    let numberMonths = (fundsOnDateOfPurchase - fundsAvailableForDownpayment)/(+values.monthly_savings * +values.savings_for_down_payment/100)

    setCountValues({
      fundsOnDateOfPurchase: fundsOnDateOfPurchase.toFixed(2),
      fundsAvailableForDownpayment: fundsAvailableForDownpayment.toFixed(2),
      numberMonths: numberMonths
    })
  }  
   // eslint-disable-next-line 
  }, [values])

  useEffect(() => {
    async function fetchNewData() {
      if (current) {
        setValues({
          name: current ? current.name : '',
          currentId: currentId,
          current_age: +current.current_age,
          additional_funds_available: +current.additional_funds_available,
          age_buy_home: +current.age_buy_home,
          available_brokerage_funds: +current.available_brokerage_funds,
          available_checking_funds: +current.available_checking_funds,
          available_savings_funds: +current.available_savings_funds,
          closing_costs: +current.closing_costs,
          current_monthly_expenses: +current.current_monthly_expenses,
          down_payment: +current.down_payment,
          home_appreciation_rate: +current.home_appreciation_rate,
          home_value: +current.home_value,
          monthly_savings: +current.monthly_savings,
          months_of_emergency_available: +current.months_of_emergency_available,
          savings_for_down_payment: +current.savings_for_down_payment,
        })
      }
    }

    if (currentId) {
      fetchNewData()
    }
    // eslint-disable-next-line
  }, [currentId, current])

  const handleInputChange = e => {
    setValues({
      ...values,
      [e.target.name]: +e.target.value
    })
  }

  // convert value to integer number
  const handleInputIntegerChange = e => {
    setValues({
      ...values,
      [e.target.name]: e.target.value.replace(/[^0-9]/g, "")
    })
  }

  const handleCurrencyChange = e => {
    setValues({
      ...values,
      [e.target.name]: +convertDecimal(e.target.value)
    })
  }

  const changeCurrentData = async e => {
    await setCurrentId(e.target.value)
    await dispatch(fetchCurrentResult(e.target.value,'time-to-buy-home'))
  }

  const onSave = async () => {
    dispatch(saveItemResult(values, 'time-to-buy-home')).then(res => {
      const calculator = res?.data?.data;

      if(calculator) {
        setIsSavedModal(true);
        setCurrentId(calculator.id);
        dispatch(setCurrentCalculator(calculator));
      }
    }).finally(() => {
      setIsOpen(false);
    })
  }

  const resetForm = () => {
    setValues({
      currentId: '',
      name: '',
      ...baseValues
    })
    setCurrentId('')
  }

  const removeResult = async () => {
    await dispatch(removeItemResult(currentId, 'time-to-buy-home'))
    await setCurrentId('')
    await setIsDeleteModal(false)
    await setActiveTab(0)
    await setValues({
      ...baseValues
    })
  }

  return (
    <Layout decor>
      <BackButton title="Back to Calculators" path="/calculators" className="back-button--position" />

      <div className="calculators__title">
        <div>
          {calculators && <h4>{calculators.name}</h4>}
          {calculators &&
          <Tooltip
            title={
              <>
                <h6>{calculators.name}</h6>
                {calculators.tooltip}
                <hr className="tooltip__line"/>
                <h6>Example</h6>
                {calculators.example}
              </>
            }
          />}
        </div>
        {listExisting && listExisting.length > 0 && <div className="calculators__title-select">
          <FormSelect
            options={listExisting}
            id="currentId"
            value={currentId}
            medium
            name="currentId"
            onChange={changeCurrentData}
            placeholder="Select result"
          />
        </div>}
      </div>

      <Tabs
        handleChange={(event, newValue) => {
          setActiveTab(newValue)
        }}
        variant="scrollable"
        value={tabActive}
        tabs={[
          {title: 'Profile info'},
          {title: 'Purchase details'},
          {title: 'Available funds'}
        ]}
      />
    {values &&   
      <div className="calculators__holder">
        <div className="calculators__content">
          <TabPanel value={tabActive} index={0}>
            <h6 className="calculators__subtitle">Step 1: Input profile info</h6>
            <div className="calculators__area">
              <Input
                type="number"
                name="current_age"
                value={values.current_age.toString()}
                small
                smallHeight
                onChange={handleInputIntegerChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100}
                  step={1}
                  label="Current age"
                  value={+values.current_age}
                  onChange={(e, newValue) => setValues({...values, current_age: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <InputCurrency
                name="current_monthly_expenses"
                value={values.current_monthly_expenses.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={500000}
                  step={5}
                  label="Current monthly expenses"
                  value={+values.current_monthly_expenses}
                  onChange={(e, newValue) => setValues({
                    ...values,
                    current_monthly_expenses: newValue
                  })}
                />
              </div>
            </div>
            <div className="calculators__area">
              <InputCurrency
                name="monthly_savings"
                value={values.monthly_savings.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={40000}
                  step={100}
                  label="Monthly Savings"
                  value={+values.monthly_savings}
                  onChange={(e, newValue) => setValues({...values, monthly_savings: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="savings_for_down_payment"
                value={values.savings_for_down_payment.toString()}
                small
                smallHeight
                step={0.1}
                max={100}
                onChange={handleInputChange}
                iconName={PercentIcon}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100}
                  step={0.1}
                  label="% of savings used for downpayment"
                  value={+values.savings_for_down_payment}
                  onChange={(e, newValue) => setValues({...values, savings_for_down_payment: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="months_of_emergency_available"
                value={values.months_of_emergency_available.toString()}
                small
                smallHeight
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={36}
                  step={1}
                  label="Months of emergency funds available"
                  value={+values.months_of_emergency_available}
                  onChange={(e, newValue) => setValues({...values, months_of_emergency_available: newValue})}
                />
              </div>
            </div>
            {values.months_of_emergency_available < 6 && <div className="calculators__error">This is an insufficient emergency fund duration and ValueSoft advises that you increase it so you have enough money for a rainy day</div>}
          </TabPanel>
          <TabPanel value={tabActive} index={1}>
            <h6 className="calculators__subtitle">Step 2: Input purchase details</h6>
            <div className="calculators__area">
              <Input
                type="number"
                name="age_buy_home"
                value={values.age_buy_home.toString()}
                small
                smallHeight
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100}
                  step={1}
                  label="Age you’d like to buy home"
                  value={+values.age_buy_home}
                  onChange={(e, newValue) => setValues({...values, age_buy_home: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <InputCurrency
                name="home_value"
                value={values.home_value.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={500000}
                  step={1}
                  label="Home value (Today’s $)"
                  value={+values.home_value}
                  onChange={(e, newValue) => setValues({...values, home_value: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="home_appreciation_rate"
                value={values.home_appreciation_rate.toString()}
                small
                smallHeight
                step={0.1}
                onChange={handleInputChange}
                iconName={PercentIcon}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100}
                  step={0.5}
                  label="Home appreciation rate"
                  value={+values.home_appreciation_rate}
                  onChange={(e, newValue) => setValues({...values, home_appreciation_rate: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="down_payment"
                value={values.down_payment.toString()}
                small
                smallHeight
                step={0.1}
                onChange={handleInputChange}
                iconName={PercentIcon}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100}
                  step={0.5}
                  label="Downpayment"
                  value={+values.down_payment}
                  onChange={(e, newValue) => setValues({...values, down_payment: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="closing_costs"
                value={values.closing_costs.toString()}
                small
                smallHeight
                step={0.1}
                onChange={handleInputChange}
                iconName={PercentIcon}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100}
                  step={0.5}
                  label="Closing Costs "
                  value={+values.closing_costs}
                  onChange={(e, newValue) => setValues({...values, closing_costs: newValue})}
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel value={tabActive} index={2}>
            <h6 className="calculators__subtitle">Step 3: Input available funds</h6>
            <div className="calculators__area">
              <InputCurrency
                name="available_checking_funds"
                value={values.available_checking_funds.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100000}
                  step={500}
                  label="Available checking funds"
                  value={+values.available_checking_funds}
                  onChange={(e, newValue) => setValues({...values, available_checking_funds: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <InputCurrency
                name="available_savings_funds"
                value={values.available_savings_funds.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100000}
                  step={100}
                  label="Available savings funds"
                  value={+values.available_savings_funds}
                  onChange={(e, newValue) => setValues({...values, available_savings_funds: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <InputCurrency
                name="available_brokerage_funds"
                value={values.available_brokerage_funds.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100000}
                  step={100}
                  label="Available brokerage funds"
                  value={+values.available_brokerage_funds}
                  onChange={(e, newValue) => setValues({...values, available_brokerage_funds: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <InputCurrency
                name="additional_funds_available"
                value={values.additional_funds_available.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100000}
                  step={100}
                  label="Additional funds slated to be available prior to desired year of purchase"
                  value={+values.additional_funds_available}
                  onChange={(e, newValue) => setValues({...values, additional_funds_available: newValue})}
                />
              </div>
            </div>
          </TabPanel>
        </div>
        <div className="calculators__preview">
          <h6 className="calculators__subtitle">Results: Time required before purchasing home</h6>
          <div className="calculators__preview-box">
            <div className="calculators__speed-box">
              <div className="calculators__speed">
                <SpeedometerLimit
                  limit={[25, 60]}
                  count={countValues.numberMonths}
                  countLabel={countValues.numberMonths < 0 && 'You Can Afford it Now'}
                  large
                  label
                  labelDesc={countValues.numberMonths > 1 ? 'months' : 'month'}
                />
              </div>
              <div>
                <p>Funds  required on date of purchase</p>
                <h6>{ numeral(countValues.fundsOnDateOfPurchase).format('$0,0.00')}</h6>
                <p>Funds  available for downpayment</p>
                <h6>{ numeral(countValues.fundsAvailableForDownpayment).format('$0,0.00')}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    }  
      <div className="calculators__buttons">
        <Button variant="contained" size="large" color="primary" onClick={() => setIsOpen(true)}>Save result</Button>
        {currentId && <Button variant="contained" size="large" onClick={() => setIsDeleteModal(true)}>Remove result</Button>}
        <Button size="large" className="MuiButton-textGray" onClick={() => resetForm()}>Reset</Button>
      </div>

      <ModalBox
        open={isOpen}
        close={() => setIsOpen(false)}
        onClick={onSave}
        button="Save"
        title="Save Result"
        isLoading={loading}
        isDisabled={values && !values.name && !values.currentId}
      >
        <FormGroup name="name" labelText="Name">
          <Input
            name='name'
            value={values ? values.name : ''}
            placeholder="Type a name of result"
            onChange={e => setValues({
              ...values,
              name: e.target.value
            })}
          />
        </FormGroup>
        {listExisting && listExisting.length > 0 &&
        <>
          <p className="text-center">or</p>
          <FormGroup labelText="Select  Existing Result">
            <FormSelect
              options={listExisting}
              value={values ? values.currentId : ''}
              name="currentId"
              onChange={e => setValues({
                ...values,
                currentId: e.target.value,
                name: listExisting.find(i => i.value === e.target.value).label
              })}
              placeholder="Select Existing Result"
            />
          </FormGroup>
        </>
        }
      </ModalBox>
      <ModalBox
        small
        open={isDeleteModal}
        close={() => setIsDeleteModal(false)}
        onClick={removeResult}
        button="Remove"
        title={`Do you want to remove ${current && current.name}?`}
      />

      <ModalBox
        small
        open={isSavedModal}
        close={() => setIsSavedModal(false)}
        button="Ok"
        onClick={() => setIsSavedModal(false)}
      >
        <div>
          {calculators && calculators.iconLeft &&
          <div className="modal__title-iconLeft">
            <img src={calculators.iconLeft} alt={calculators.name} />
          </div>}
          <h4>Your calculator scenario has been saved.</h4>
          <p>You can access your saved scenarios through the “Select result” dropdown menu at the top of each calculator page.</p>
        </div>
      </ModalBox>
    </Layout>
  )
}

export default TimeToBuyHome
