import React from 'react'
import PropTypes from 'prop-types'
import TooltipCustom from '../Tooltip/Tooltip'

const FormGroup = ({ children, name, labelText, info, hideLabel }) => {
  return (
    <div className="form-group">
      <label className={`form-group__label${hideLabel ? ' form-group__label--hide' : '' }`} htmlFor={name}>
        {labelText}
        {info && (
          <TooltipCustom
            size="small"
            title={info.content}
          />
        )}
      </label>
      {children}
    </div>
  )
};

FormGroup.propTypes = {
  children: PropTypes.node,
  labelText: PropTypes.string,
  name: PropTypes.string,
  hideLabel: PropTypes.bool
};

export default FormGroup;
