import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { IconButton } from '@mui/material'
import SVGIcon from '../shared/SVGIcon/SVGIcon'
import SearchIcon from '../../assets/images/icons/search.svg'
import Loading from '../../components/shared/Loading/Loading'

const SearchBox = ({ isMobile, path, setSearch, value }) => {
  const [isOpen, setIsOpen] = useState(false)
  const searchBox = useRef()
  const { results, isLoading } = useSelector(state => state.search)
  
  useEffect(() => {
    const listener = event => {
      if (!searchBox.current || searchBox.current.contains(event.target)) {
        return;
      }

      setIsOpen(false);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [searchBox]);

  const handleClick = () => setIsOpen(!isOpen)

  return (
    <div ref={searchBox} className={`search-box${isOpen ? ' search-box--open' : ''}`}>
      {isMobile
        ? <Link to={path}><IconButton><SVGIcon icon={SearchIcon}/></IconButton></Link>
        : <>
            <div className="search-box__area">
              <SVGIcon icon={SearchIcon} color="#000"/>
              <input
                type="text"
                value={value}
                className="search-box__input"
                onChange={e => setSearch(e)}
              />
            </div>
            <IconButton onClick={handleClick}><SVGIcon icon={SearchIcon}/></IconButton>
            {value && results &&
              <div className='search-box__drop'>
                {isLoading
                  ? <Loading xsmall />
                  : (results.tasks.length < 1 && results.posts.length < 1)
                    ? <div>No results</div>
                    : <>
                        {results.posts.length > 0 && <div className="search-box__case">
                          <div className="search-box__title">Articles</div>
                          {results.posts.map(item =>
                            <Link to={`/blog/${item.id}`} className="search-box__card" key={item.id}>
                              <div className="search-box__image">
                                <img src={item.image} alt={item.title} />
                              </div>
                              <div className="search-box__content">{item.title}</div>
                            </Link>
                          )}
                        </div>}
                        {results.tasks.length > 0 && <div className="search-box__case">
                          <div className="search-box__title">Tasks</div>
                          {results.tasks.map(item =>
                            <Link to={{
                              pathname: '/search',
                              state: {taskId:  item.id}
                            }} onClick={() => setIsOpen(false)} className="search-box__card search-box__card--tasks" key={item.id}>
                              <div className="search-box__image">
                                <img src={item.category.planning.image} alt={item.name} />
                              </div>
                              <div className="search-box__content">{item.name}</div>
                            </Link>
                          )}
                        </div>}
                        <Link to={`/search?query=${value}`} onClick={() => setIsOpen(false)} className="search-box__link">View all</Link>
                      </>
                }
              </div>
            }
        </>}
    </div>
  )
}

SearchBox.propTypes = {
  path: PropTypes.string,
  isMobile: PropTypes.bool
}

export default SearchBox
