import * as Yup from 'yup'
import { decimalNumber } from './validation'

export const insuranceHomeSchema = Yup.object().shape({
  disaster_area: Yup.boolean().required('Required'),
  disaster_insurance: Yup.boolean().required('Required'),
  home_insurance: Yup.boolean().required('Required'),
  dwelling_coverage: Yup.string().nullable().max(18, 'Please check this value, must be less').notRequired(),
  identity_fraud: Yup.string().nullable().max(18, 'Please check this value, must be less').notRequired(),
  jewelry: Yup.string().nullable().max(18, 'Please check this value, must be less').notRequired(),
  loss_of_use: Yup.string().nullable().max(18, 'Please check this value, must be less').notRequired(),
  personal_property: Yup.string().nullable().max(18, 'Please check this value, must be less').notRequired(),
  personalliability_home: Yup.string().nullable().max(18, 'Please check this value, must be less').notRequired(),
  replacement_value: Yup.string().nullable().max(18, 'Please check this value, must be less').notRequired(),
  separate_structure: Yup.string().nullable().max(18, 'Please check this value, must be less').notRequired(),
});

export const insuranceLifeSchema = Yup.object().shape({
  percent_expenses_you: Yup.string().nullable().max(8, 'Too large value').required('Required'),
  number_of_years_cover: Yup.number().max(100, 'Too large value').required('Required'),
  spouse_pass_work: decimalNumber.nullable().max(6, 'Too large value').notRequired(),
  spouse_years_cover: decimalNumber.nullable().max(2, 'Too large value').notRequired(),
  pass_work: decimalNumber.nullable().max(6, 'Too large value').notRequired(),
  child_pass_work: decimalNumber.nullable().max(6, 'Too large value').notRequired(),
  child_pass_work_spouse: decimalNumber.nullable().max(6, 'Too large value').notRequired(),
});

export const insuranceDisabilitySchema = Yup.object().shape({
  percent_take_home: Yup.string().max(8, 'Please check this value, must be less').required('Required'),
  current_ltd: Yup.string().max(12, 'Please check this value, must be less').required('Required'),
  waiting_period: Yup.string().required('Required'),
  years_cover_salary: Yup.string().required('Required'),
  spouse_percent_take_home: Yup.string().nullable().max(12, 'Please check this value, must be less').notRequired(),
  spouse_current_ltd: Yup.string().nullable().max(12, 'Please check this value, must be less').notRequired(),
});

export const insuranceAutoSchema = Yup.object().shape({
  personal_liability_auto_per_person: Yup.string().nullable().max(15, 'Please check this value, must be less').notRequired(),
  personal_liability_auto_per_incident: Yup.string().nullable().max(15, 'Please check this value, must be less').notRequired(),
  property_damage: Yup.string().nullable().max(15, 'Please check this value, must be less').notRequired(),
  medical_payments: Yup.string().nullable().max(15, 'Please check this value, must be less').notRequired(),
  uninsured_motorist_per_person: Yup.string().nullable().max(15, 'Please check this value, must be less').notRequired(),
  uninsured_motorist_per_incident: Yup.string().nullable().max(15, 'Please check this value, must be less').notRequired(),
  comprehensive: Yup.string().nullable().max(15, 'Please check this value, must be less').notRequired(),
  towing: Yup.string().nullable().max(15, 'Please check this value, must be less').notRequired(),
  rental: Yup.string().nullable().max(15, 'Please check this value, must be less').notRequired(),
});

export const insuranceHealthSchema = Yup.object().shape({
  medical_care: Yup.string().required('Required'),
});

export const insuranceUmbrellaSchema = Yup.object().shape({
  current_face_value: Yup.string().nullable().max(15, 'Please check this value, must be less').notRequired(),
});

export const UnemploymentSchema = Yup.object().shape({
  what_year: Yup.string().nullable().min(4, 'Please enter valid year').max(4, 'Please enter valid year').notRequired(),
  spouse_what_year: Yup.string().nullable().min(4, 'Please enter valid year').max(4, 'Please enter valid year').notRequired(),
  spouse_months: Yup.string().nullable().max(6, 'Please check this value, must be less').notRequired(),
  months: Yup.string().nullable().max(6, 'Please check this value, must be less').notRequired(),

  model_unemployment: Yup.bool().required('Required'),
  unemployment_health_care: Yup.bool().required('Required'),
  coverage_extended_to: Yup.string().required('Required'),
  weeks_severance: decimalNumber.max(6, 'Please check this value, must be less')
  .when('model_unemployment', {
    is: true,
    then: Yup.string().required('Required')
  }),
  accrued_vacation: decimalNumber.max(6, 'Please check this value, must be less')
  .when('model_unemployment', {
    is: true,
    then: Yup.string().required('Required')
  }),
  additional_severance: Yup.string().nullable().max(15, 'Please check this value, must be less').notRequired(),

  spouse_weeks_severance: decimalNumber.nullable().max(6, 'Please check this value, must be less').notRequired(),
  spouse_accrued_vacation: decimalNumber.nullable().max(6, 'Please check this value, must be less').notRequired(),
  spouse_additional_severance: Yup.string().nullable().max(15, 'Please check this value, must be less').notRequired(),
});

export const BequeathSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  type: Yup.string().required('Required'),
  value: Yup.string().max(18, 'Please check this value, must be less').required('Required'),
});

