import React from 'react'
import { Switch, BrowserRouter, Route, Redirect } from 'react-router-dom'
import ScrollToTop from '../components/ScrollToTop'
import PrivateRoute from '../components/HOC/PrivateRoute'
import Overview from '../pages/Overview'
import Blog from '../pages/Blog'
import BlogDetails from '../pages/Blog/BlogDetails'
import FAQ from '../pages/Faq'
import Tasks from '../pages/Tasks'
import Profile from '../pages/Profile'
import Analysis from '../pages/Analysis/Analysis'
import TasksAnalysis from '../pages/Analysis/TasksAnalysis'
import MainTree from '../pages/MainTree'
// Auth pages
import SignIn from '../pages/Auth/SignIn'
import VerificationType from '../pages/Auth/VerificationType'
import VerificationCode from '../pages/Auth/VerificationCode'
import SignUpType from '../pages/Auth/SignUpType'
import SignUp from '../pages/Auth/SignUp'
import SignUpMember from '../pages/Auth/SignUpMember'
import SignUpCompany from '../pages/Auth/SignUpCompany'
import SignUpPersonalInfo from '../pages/Auth/SignUpPersonalInfo'
import SignUpConfirm from '../pages/Auth/SignUpConfirm'
import SignUpCompanyConfirm from '../pages/Auth/SignUpCompanyConfirm'
import SignInConfirm from '../pages/Auth/SignInConfirm'
import ForgotPassword from '../pages/Auth/ForgotPassword'
import CreateNewPassword from '../pages/Auth/CreateNewPassord'
import EmailSend from '../pages/Auth/EmailSend'

// About You pages
import ProfileSummary from '../pages/AboutYou/ProfileSummary'
import AboutYou from '../pages/AboutYou/ProfileSummary/AboutYou'
import AboutYouDetails from '../pages/AboutYou/ProfileSummary/AboutYouDetails'
import AboutYourSpouse from '../pages/AboutYou/ProfileSummary/AboutYourSpouse'
import AboutYourFamily from '../pages/AboutYou/ProfileSummary/AboutYourFamily'
import AboutYourFamilyAssets from '../pages/AboutYou/ProfileSummary/AboutYourFamilyAssets'
import AboutYourFamilyChildren from '../pages/AboutYou/ProfileSummary/AboutYourFamilyChildren'
import AboutYourFamilyOthers from '../pages/AboutYou/ProfileSummary/AboutYourFamilyOthers'
import Risk from '../pages/AboutYou/Risk/Risk'
import RiskPortfolio from '../pages/AboutYou/Risk/RiskPortfolio'
import Education from '../pages/AboutYou/Education/index'
import EducationDetails from '../pages/AboutYou/Education/EducationDetails'
import Children from '../pages/AboutYou/Children'
import ChildrenDetails from '../pages/AboutYou/Children/ChildrenDetails'
import StudentsDetails from '../pages/AboutYou/Children/StudentsDetails'
import Companies from '../pages/AboutYou/Companies'
import CompanyDetails from '../pages/AboutYou/Companies/CompanyDetails'
import Values from '../pages/AboutYou/Values/Values'
import ValueDetails from '../pages/AboutYou/Values/ValueDetails'
import Goals from '../pages/AboutYou/Values/Goals'
import Dreams from '../pages/AboutYou/Values/Dreams'
//Future
import Sabbatical from '../pages/Future/Sabbatical'
import SabbaticalDetails from '../pages/Future/Sabbatical/SabbaticalDetails'
import Objectives from '../pages/Future/Objectives'
import ObjectivesDetails from '../pages/Future/Objectives/ObjectivesDetails'
import Retirement from '../pages/Future/Retirement'
import RetirementDetails from '../pages/Future/Retirement/RetirementDetails'
import FutureChildren from '../pages/Future/FutureChildren'
import FutureChildrenDetails from '../pages/Future/FutureChildren/FutureChildrenDetails'
import FutureEducation from '../pages/Future/FutureEducation'
import FutureEducationDetails from '../pages/Future/FutureEducation/FutureEducationDetails'
//Finance
import Assets from '../pages/Finance/Assets'
import Asset from '../pages/Finance/Assets/Asset'
import AssetDetails from '../pages/Finance/Assets/AssetDetails'
import Expenses from '../pages/Finance/Expenses/Expenses'
import Income from '../pages/Finance/Income/Income'
import IncomeDetails from '../pages/Finance/Income/IncomeDetails'
import IncrementalIncome from '../pages/Finance/IncrementalIncome'
import IncrementalIncomeDetails from '../pages/Finance/IncrementalIncome/IncrementalIncomeDetails'
import Worth from '../pages/Finance/Worth'
import WorthAssets from '../pages/Finance/Worth/WorthAssets'
import WorthAssetsCash from '../pages/Finance/Worth/WorthAssetsCash'
import WorthAssetsNonRetirement from '../pages/Finance/Worth/WorthAssetsNonRetirement'
import WorthAssetsRetirement from '../pages/Finance/Worth/WorthAssetsRetirement'
import WorthAssetsFixed from '../pages/Finance/Worth/WorthAssetsFixed'
import WorthLiabilities from '../pages/Finance/Worth/WorthLiabilities'
import WorthLiabilitiesDebtCredit from '../pages/Finance/Worth/WorthLiabilitiesDebtCredit'
import WorthLiabilitiesNecessities from '../pages/Finance/Worth/WorthLiabilitiesNecessities'
import WorthOthers from '../pages/Finance/Worth/WorthOthers'
import WorthOthersLiabilities from '../pages/Finance/Worth/WorthOthersLiabilities'
//Protection
import Bequeath from '../pages/Protection/Bequeath'
import BequeathDetails from '../pages/Protection/Bequeath/BequeathDetails'
import Disability from '../pages/Protection/Disability/Disability'
import Estate from '../pages/Protection/Estate/Estate'
import Insurance from '../pages/Protection/Insurance'
import InsuranceHome from '../pages/Protection/Insurance/InsuranceHome'
import InsuranceHealth from '../pages/Protection/Insurance/InsuranceHealth'
import InsuranceAuto from '../pages/Protection/Insurance/InsuranceAuto'
import InsuranceUmbrella from '../pages/Protection/Insurance/InsuranceUmbrella'
import Life from '../pages/Protection/Life/Life'
import Unemployment from '../pages/Protection/Unemployment/Unemployment'
//Mobile Additional Pages
import AboutMe from '../pages/MainTree/AboutMe'
import Protection from '../pages/MainTree/Protection'
import Finance from '../pages/MainTree/Finance'
import Future from '../pages/MainTree/Future'
//Search Page
import Search from '../pages/Search'
//AdminPanel
import AdminPage from '../pages/AdminPanel'
import AdminBlogOverview from '../pages/AdminPanel/AdminBlogOverview'
import AdminPostEdit from '../pages/AdminPanel/AdminPostEdit'
import AdminPostDetails from '../pages/AdminPanel/AdminPostDetails'
import AdminAuthorEdit from '../pages/AdminPanel/AdminAuthorEdit'
import AdminTasks from '../pages/AdminPanel/AdminTasks'
import AdminLoring from '../pages/AdminPanel/AdminLoring'
import AdminPlans from '../pages/AdminPanel/AdminPlans'
import AdminCalculators from '../pages/AdminPanel/AdminCalculators'
import AdminEditTask from '../pages/AdminPanel/AdminEditTask'
import AdminNotifications from '../pages/AdminPanel/AdminNotifications'
import AdminAlerts from '../pages/AdminPanel/AdminAlerts'
import AdminCompany from '../pages/AdminPanel/AdminCompany'
import AdminCompanyAddInfo from '../pages/AdminPanel/AdminCompanyAddInfo'
import AdminCompanyAddAdmin from '../pages/AdminPanel/AdminCompanyAddAdmin'
import AdminCompanyDetails from '../pages/AdminPanel/AdminCompanyDetails'
import AdminCompanySuccess from '../pages/AdminPanel/AdminCompanySuccess'
import AdminCompanyAddPermissions from '../pages/AdminPanel/AdminCompanyAddPermissions'
import AdminCompanyMembers from '../pages/AdminPanel/AdminCompanyMembers'
import AdminCompanyInviteMember from '../pages/AdminPanel/AdminCompanyInviteMember'
import AdminCompanyChangeAdmin from '../pages/AdminPanel/AdminCompanyChangeAdmin'
import AdminCompanyPermissions from '../pages/AdminPanel/AdminCompanyPermissions'
import AdminCompanyEditMember from '../pages/AdminPanel/AdminCompanyEditMember'
import AdminEmailStatistics from '../pages/AdminPanel/AdminEmailStatistics'
import AdminUserStatistics from '../pages/AdminPanel/AdminUserStatistics'
import AdminUserStatisticsResult from '../pages/AdminPanel/AdminUserStatisticsResult'
import AdminTaskPlanStatistics from '../pages/AdminPanel/AdminTaskPlanStatistics'
import AdminSettings from '../pages/AdminPanel/AdminSettings'

import NotFound from '../pages/NotFound'
import Terms from '../pages/Terms'
//Calculators
import Calculators from '../pages/Calculators'
import ImpactOfIncomeAndExpenses from '../pages/Calculators/ImpactOfIncomeAndExpenses'
import Mortgage from '../pages/Calculators/Mortgage'
import HomeAffordability from '../pages/Calculators/HomeAffordability'
import OwnVsRent from '../pages/Calculators/OwnVsRent'
import TimeToBuyHome from '../pages/Calculators/TimeToBuyHome'
import HomeRefinancing from '../pages/Calculators/HomeRefinancing'
import CarLeasePayment from '../pages/Calculators/CarLeasePayment'
import CarLeaseVsPurchase from '../pages/Calculators/CarLeaseVsPurchase'
import LifeInsurance from '../pages/Calculators/LifeInsurance'
import UmbrellaInsurance from '../pages/Calculators/UmbrellaInsurance'
import HealthcareCoverageType from '../pages/Calculators/HealthcareCoverageType'
import HomeEvaluation from '../pages/Calculators/HomeEvaluation'
import InvestmentCheatSheet from '../pages/Calculators/InvestmentCheatSheet'
import ValueCircle from '../pages/Calculators/ValueCircle'
import InvestmentFees from '../pages/Calculators/InvestmentFees'

const Routers = ({ isAdmin }) => {
  return (
    <BrowserRouter>
      <ScrollToTop />

      <Switch>
        <PrivateRoute
          path="/input"
          exact
          render={(props) => <MainTree props={props} />}
        />
        <PrivateRoute
          path="/unsubscribe-push-notification"
          exact
          render={(props) => <MainTree props={props} />}
        />
        <PrivateRoute
          path="/dashboard"
          exact
          render={(props) => <Overview props={props} />}
        />
        <PrivateRoute
          path="/search"
          exact
          render={(props) => <Search props={props} />}
        />
        <PrivateRoute
          path="/search?query=:value"
          exact
          render={(props) => <Search props={props} />}
        />

        <PrivateRoute
          path="/faq"
          exact
          render={(props) => <FAQ props={props} />}
        />
        <PrivateRoute
          path="/blog"
          exact
          render={(props) => <Blog props={props} />}
        />
        <PrivateRoute
          path="/blog/:postId"
          exact
          render={(props) => <BlogDetails props={props} />}
        />
        <PrivateRoute
          path="/profile"
          exact
          render={(props) => <Profile props={props} />}
        />
        <PrivateRoute
          path="/tasks"
          exact
          render={(props) => <Tasks props={props} />}
        />
        <PrivateRoute
          path="/analysis"
          exact
          render={(props) => <Analysis props={props} />}
        />

        <PrivateRoute
          path="/tasks-analysis"
          exact
          render={(props) => <TasksAnalysis props={props} />}
        />
        <PrivateRoute
          path="/profile-summary"
          exact
          render={(props) => <ProfileSummary props={props} />}
        />
        <PrivateRoute
          path="/profile-summary/about-you"
          exact
          render={(props) => <AboutYou props={props} />}
        />
        <PrivateRoute
          path="/profile-summary/about-you-details"
          exact
          render={(props) => <AboutYouDetails props={props} />}
        />
        <PrivateRoute
          path="/profile-summary/about-your-spouse"
          exact
          render={(props) => <AboutYourSpouse props={props} />}
        />
        <PrivateRoute
          path="/profile-summary/about-your-family"
          exact
          render={(props) => <AboutYourFamily props={props} />}
        />
        <PrivateRoute
          path="/profile-summary/about-your-family/assets"
          exact
          render={(props) => <AboutYourFamilyAssets props={props} />}
        />
        <PrivateRoute
          path="/profile-summary/about-your-family/children"
          exact
          render={(props) => <AboutYourFamilyChildren props={props} />}
        />
        <PrivateRoute
          path="/profile-summary/about-your-family/others"
          exact
          render={(props) => <AboutYourFamilyOthers props={props} />}
        />
        <PrivateRoute
          path="/risk"
          exact
          render={(props) => <Risk props={props} />}
        />
        <PrivateRoute
          path="/risk-portfolio"
          exact
          render={(props) => <RiskPortfolio props={props} />}
        />
        <PrivateRoute
          path="/education"
          exact
          render={(props) => <Education props={props} />}
        />
        <PrivateRoute
          path="/education/:id"
          exact
          render={(props) => <EducationDetails props={props} isEdit />}
        />
        <PrivateRoute
          path="/dependents"
          exact
          render={(props) => <Children props={props} />}
        />
        <PrivateRoute
          path="/dependents/create"
          exact
          render={(props) => <ChildrenDetails props={props} />}
        />
        <PrivateRoute
          path="/students/create"
          exact
          render={(props) => <StudentsDetails props={props} />}
        />
        <PrivateRoute
          path="/dependents/:id"
          exact
          render={(props) => <ChildrenDetails props={props} isEdit />}
        />
        <PrivateRoute
          path="/companies"
          exact
          render={(props) => <Companies props={props} />}
        />
        <PrivateRoute
          path="/companies/create"
          exact
          render={(props) => <CompanyDetails props={props} />}
        />
        <PrivateRoute
          path="/companies/:id"
          exact
          render={(props) => <CompanyDetails props={props} isEdit />}
        />
        <PrivateRoute
          path="/values"
          exact
          render={(props) => <Values props={props} />}
        />
        <PrivateRoute
          path="/values/details"
          exact
          render={(props) => <ValueDetails props={props} />}
        />
        <PrivateRoute
          path="/values/details-spouse"
          exact
          render={(props) => <ValueDetails props={props} isSpouse />}
        />
        <PrivateRoute
          path="/values/goals"
          exact
          render={(props) => <Goals props={props} />}
        />
        <PrivateRoute
          path="/values/goals-spouse"
          exact
          render={(props) => <Goals props={props} isSpouse />}
        />
        <PrivateRoute
          path="/values/dreams"
          exact
          render={(props) => <Dreams props={props} />}
        />
        <PrivateRoute
          path="/values/dreams-spouse"
          exact
          render={(props) => <Dreams props={props} isSpouse />}
        />
        <PrivateRoute
          path="/sabbatical"
          exact
          render={(props) => <Sabbatical props={props} />}
        />
        <PrivateRoute
          path="/sabbatical/your"
          exact
          render={(props) => <SabbaticalDetails props={props} />}
        />
        <PrivateRoute
          path="/sabbatical/your-spouse"
          exact
          render={(props) => <SabbaticalDetails props={props} isSpouse />}
        />
        <PrivateRoute
          path="/objectives"
          exact
          render={(props) => <Objectives props={props} />}
        />
        <PrivateRoute
          path="/objectives/create"
          exact
          render={(props) => <ObjectivesDetails props={props} />}
        />
        <PrivateRoute
          path="/objectives/:id"
          exact
          render={(props) => <ObjectivesDetails props={props} isEdit />}
        />
        <PrivateRoute
          path="/future-education"
          exact
          render={(props) => <FutureEducation props={props} />}
        />
        <PrivateRoute
          path="/future-education/:id"
          exact
          render={(props) => <FutureEducationDetails props={props} />}
        />
        <PrivateRoute
          path="/future-children"
          exact
          render={(props) => <FutureChildren props={props} />}
        />
        <PrivateRoute
          path="/future-children/create"
          exact
          render={(props) => <FutureChildrenDetails props={props} />}
        />
        <PrivateRoute
          path="/future-children/:id"
          exact
          render={(props) => <FutureChildrenDetails props={props} isEdit />}
        />
        <PrivateRoute
          path="/retirement"
          exact
          render={(props) => <Retirement props={props} />}
        />
        <PrivateRoute
          path="/retirement/your"
          exact
          render={(props) => <RetirementDetails props={props} />}
        />
        <PrivateRoute
          path="/retirement/your-spouse"
          exact
          render={(props) => <RetirementDetails props={props} isSpouse />}
        />
        <PrivateRoute
          path="/assets"
          exact
          render={(props) => <Assets props={props} />}
        />
        <PrivateRoute
          path="/assets/create"
          exact
          render={(props) => <Asset props={props} />}
        />
        <PrivateRoute
          path="/assets/create-details"
          exact
          render={(props) => <AssetDetails props={props} />}
        />
        <PrivateRoute
          path="/assets/:id"
          exact
          render={(props) => <Asset props={props} isEdit />}
        />
        <PrivateRoute
          path="/assets/:id/details"
          exact
          render={(props) => <AssetDetails props={props} isEdit />}
        />
        <PrivateRoute
          path="/expenses"
          exact
          render={(props) => <Expenses props={props} />}
        />
        <PrivateRoute
          path="/worth"
          exact
          render={(props) => <Worth props={props} />}
        />
        <PrivateRoute
          path="/worth/assets"
          exact
          render={(props) => <WorthAssets props={props} />}
        />
        <PrivateRoute
          path="/worth/assets/cash"
          exact
          render={(props) => <WorthAssetsCash props={props} />}
        />
        <PrivateRoute
          path="/worth/assets/non-retirement"
          exact
          render={(props) => <WorthAssetsNonRetirement props={props} />}
        />
        <PrivateRoute
          path="/worth/assets/fixed-assets"
          exact
          render={(props) => <WorthAssetsFixed props={props} />}
        />
        <PrivateRoute
          path="/worth/assets/others"
          exact
          render={(props) => <WorthOthers props={props} isAssets />}
        />
        <PrivateRoute
          path="/worth/assets/retirement"
          exact
          render={(props) => <WorthAssetsRetirement props={props} />}
        />
        <PrivateRoute
          path="/worth/liabilities"
          exact
          render={(props) => <WorthLiabilities props={props} />}
        />
        <PrivateRoute
          path="/worth/liabilities/debt-credit"
          exact
          render={(props) => <WorthLiabilitiesDebtCredit props={props} />}
        />
        <PrivateRoute
          path="/worth/liabilities/necessities"
          exact
          render={(props) => <WorthLiabilitiesNecessities props={props} />}
        />
        <PrivateRoute
          path="/worth/liabilities/others"
          exact
          render={(props) => <WorthOthersLiabilities props={props} />}
        />
        <PrivateRoute
          path="/income"
          exact
          render={(props) => <Income props={props} />}
        />
        <PrivateRoute
          path="/income-create"
          exact
          render={(props) => <IncomeDetails props={props} />}
        />
        <PrivateRoute
          path="/income/:id"
          exact
          render={(props) => <IncomeDetails props={props} isEdit />}
        />
        <PrivateRoute
          path="/incremental-income"
          exact
          render={(props) => <IncrementalIncome props={props} />}
        />
        <PrivateRoute
          path="/incremental-income/create"
          exact
          render={(props) => <IncrementalIncomeDetails props={props} />}
        />
        <PrivateRoute
          path="/incremental-income/:id"
          exact
          render={(props) => <IncrementalIncomeDetails props={props} isEdit />}
        />
        <PrivateRoute
          path="/bequeath"
          exact
          render={(props) => <Bequeath props={props} />}
        />
        <PrivateRoute
          path="/bequeath/create"
          exact
          render={(props) => <BequeathDetails props={props} />}
        />
        <PrivateRoute
          path="/bequeath/:id"
          exact
          render={(props) => <BequeathDetails props={props} isEdit />}
        />
        <PrivateRoute
          path="/disability"
          exact
          render={(props) => <Disability props={props} />}
        />
        <PrivateRoute
          path="/estate"
          exact
          render={(props) => <Estate props={props} />}
        />
        <PrivateRoute
          path="/insurance"
          exact
          render={(props) => <Insurance props={props} />}
        />
        <PrivateRoute
          path="/insurance/home"
          exact
          render={(props) => <InsuranceHome props={props} />}
        />
        <PrivateRoute
          path="/insurance/health"
          exact
          render={(props) => <InsuranceHealth props={props} />}
        />
        <PrivateRoute
          path="/insurance/auto"
          exact
          render={(props) => <InsuranceAuto props={props} />}
        />
        <PrivateRoute
          path="/insurance/umbrella"
          exact
          render={(props) => <InsuranceUmbrella props={props} />}
        />
        <PrivateRoute
          path="/life"
          exact
          render={(props) => <Life props={props} />}
        />
        <PrivateRoute
          path="/unemployment"
          exact
          render={(props) => <Unemployment props={props} />}
        />
        <PrivateRoute
          path="/about-me"
          exact
          render={(props) => <AboutMe props={props} />}
        />
        <PrivateRoute
          path="/protection"
          exact
          render={(props) => <Protection props={props} />}
        />
        <PrivateRoute
          path="/future"
          exact
          render={(props) => <Future props={props} />}
        />
        <PrivateRoute
          path="/finance"
          exact
          render={(props) => <Finance props={props} />}
        />
        <PrivateRoute
          path="/admin"
          exact
          render={(props) => <AdminPage props={props} isAdmin={isAdmin} />}
        />
        <PrivateRoute
          path="/admin/company/:companyId/blog"
          exact
          render={(props) => (
            <AdminBlogOverview props={props} isAdmin={isAdmin} />
          )}
        />
        <PrivateRoute
          path="/admin/company/:companyId/blog/create-post"
          exact
          render={(props) => <AdminPostEdit props={props} isAdmin={isAdmin} />}
        />
        <PrivateRoute
          path="/admin/company/:companyId/blog/:postId"
          exact
          render={(props) => (
            <AdminPostDetails props={props} isAdmin={isAdmin} />
          )}
        />
        <PrivateRoute
          path="/admin/company/:companyId/blog/:postId/edit"
          exact
          render={(props) => (
            <AdminPostEdit props={props} isAdmin={isAdmin} isEdit />
          )}
        />
        <PrivateRoute
          path="/admin/company/:companyId/authors/create-author"
          exact
          render={(props) => (
            <AdminAuthorEdit props={props} isAdmin={isAdmin} />
          )}
        />
        <PrivateRoute
          path="/admin/company/:companyId/authors/:authorId/edit"
          exact
          render={(props) => (
            <AdminAuthorEdit props={props} isAdmin={isAdmin} isEdit />
          )}
        />
        <PrivateRoute
          path="/admin/company/:companyId/tasks"
          exact
          render={(props) => <AdminTasks props={props} isAdmin={isAdmin} />}
        />
        <PrivateRoute
          path="/admin/company/:companyId/tasks/create"
          exact
          render={(props) => <AdminEditTask props={props} isAdmin={isAdmin} />}
        />
        <PrivateRoute
          path="/admin/company/:companyId/tasks/:taskId"
          exact
          render={(props) => (
            <AdminEditTask props={props} isAdmin={isAdmin} isEdit />
          )}
        />
        <PrivateRoute
          path="/admin/company/:companyId/loring"
          exact
          render={(props) => <AdminLoring props={props} isAdmin={isAdmin} />}
        />
        <PrivateRoute
          path="/admin/company/:companyId/calculators"
          exact
          render={(props) => (
            <AdminCalculators props={props} isAdmin={isAdmin} />
          )}
        />
        <PrivateRoute
          path="/admin/company/:companyId/notifications"
          exact
          render={(props) => (
            <AdminNotifications props={props} isAdmin={isAdmin} />
          )}
        />
        <PrivateRoute
          path="/admin/company/:companyId/alerts"
          exact
          render={(props) => <AdminAlerts props={props} isAdmin={isAdmin} />}
        />
        <PrivateRoute
          path="/admin/companies"
          exact
          render={(props) => <AdminCompany props={props} isAdmin={isAdmin} />}
        />
        <PrivateRoute
          path="/admin/companies/add-info"
          exact
          render={(props) => (
            <AdminCompanyAddInfo props={props} isAdmin={isAdmin} />
          )}
        />
        <PrivateRoute
          path="/admin/companies/:companyId/add-permissions"
          exact
          render={(props) => (
            <AdminCompanyAddPermissions props={props} isAdmin={isAdmin} />
          )}
        />
        <PrivateRoute
          path="/admin/companies/:companyId/add-admin"
          exact
          render={(props) => (
            <AdminCompanyAddAdmin props={props} isAdmin={isAdmin} />
          )}
        />
        <PrivateRoute
          path="/admin/company/:companyId/details"
          exact
          render={(props) => (
            <AdminCompanyDetails props={props} isAdmin={isAdmin} />
          )}
        />
        <PrivateRoute
          path="/admin/companies/:companyId/success"
          exact
          render={(props) => (
            <AdminCompanySuccess props={props} isAdmin={isAdmin} />
          )}
        />
        <PrivateRoute
          path="/admin/company/:companyId/members"
          exact
          render={(props) => (
            <AdminCompanyMembers props={props} isAdmin={isAdmin} />
          )}
        />
        <PrivateRoute
          path="/admin/company/:companyId/members/invite"
          exact
          render={(props) => (
            <AdminCompanyInviteMember props={props} isAdmin={isAdmin} />
          )}
        />
        <PrivateRoute
          path="/admin/company/:companyId/members/change-admin"
          exact
          render={(props) => (
            <AdminCompanyChangeAdmin props={props} isAdmin={isAdmin} />
          )}
        />
        <PrivateRoute
          path="/admin/company/:companyId/permissions"
          exact
          render={(props) => (
            <AdminCompanyPermissions props={props} isAdmin={isAdmin} />
          )}
        />
        <PrivateRoute
          path="/admin/company/:companyId/members/:memberId/edit"
          exact
          render={(props) => (
            <AdminCompanyEditMember props={props} isAdmin={isAdmin} />
          )}
        />
        <PrivateRoute
          path="/admin/company/:companyId/plans"
          exact
          render={(props) => <AdminPlans props={props} isAdmin={isAdmin} />}
        />
        <PrivateRoute
          path="/admin/company/:companyId/email-statistics"
          exact
          render={(props) => (
            <AdminEmailStatistics props={props} isAdmin={isAdmin} />
          )}
        />
        <PrivateRoute
          path="/admin/company/:companyId/email-statistics/task-plan-statistics/:id"
          exact
          render={(props) => (
            <AdminTaskPlanStatistics props={props} isAdmin={isAdmin} />
          )}
        />
        <PrivateRoute
          path="/admin/company/:companyId/users-statistics"
          exact
          render={(props) => (
            <AdminUserStatistics props={props} isAdmin={isAdmin} />
          )}
        />
        <PrivateRoute
          path="/admin/company/:companyId/users-statistics/result"
          exact
          render={(props) => (
            <AdminUserStatisticsResult props={props} isAdmin={isAdmin} />
          )}
        />
        <PrivateRoute
          path="/admin/company/:companyId/settings"
          exact
          render={(props) => (
            <AdminSettings props={props} isAdmin={isAdmin} />
          )}
        />

        <PrivateRoute
          path="/calculators"
          exact
          render={(props) => <Calculators props={props} />}
        />
        <PrivateRoute
          path="/calculators/impact-of-income-and-net-worth"
          exact
          render={(props) => <ImpactOfIncomeAndExpenses props={props} />}
        />
        <PrivateRoute
          path="/calculators/mortgage"
          exact
          render={(props) => <Mortgage props={props} />}
        />
        <PrivateRoute
          path="/calculators/home-affordability"
          exact
          render={(props) => <HomeAffordability props={props} />}
        />
        <PrivateRoute
          path="/calculators/own-vs-rent-home"
          exact
          render={(props) => <OwnVsRent props={props} />}
        />
        <PrivateRoute
          path="/calculators/time-to-buy-home"
          exact
          render={(props) => <TimeToBuyHome props={props} />}
        />
        <PrivateRoute
          path="/calculators/home-refinancing"
          exact
          render={(props) => <HomeRefinancing props={props} />}
        />
        <PrivateRoute
          path="/calculators/car-lease-payment"
          exact
          render={(props) => <CarLeasePayment props={props} />}
        />
        <PrivateRoute
          path="/calculators/new-car-lease-vs-lease-end"
          exact
          render={(props) => <CarLeaseVsPurchase props={props} />}
        />
        <PrivateRoute
          path="/calculators/life-insurance"
          exact
          render={(props) => <LifeInsurance props={props} />}
        />
        <PrivateRoute
          path="/calculators/umbrella-insurance"
          exact
          render={(props) => <UmbrellaInsurance props={props} />}
        />
        <PrivateRoute
          path="/calculators/healthcare-coverage-type"
          exact
          render={(props) => <HealthcareCoverageType props={props} />}
        />
        <PrivateRoute
          path="/calculators/home-evaluation"
          exact
          render={(props) => <HomeEvaluation props={props} />}
        />
        <PrivateRoute
          path="/calculators/investment-cheat-sheet"
          exact
          render={(props) => <InvestmentCheatSheet props={props} />}
        />
        <PrivateRoute
          path="/calculators/value-circle"
          exact
          render={(props) => <ValueCircle props={props} />}
        />
        <PrivateRoute
          path="/calculators/investment-fees"
          exact
          render={(props) => <InvestmentFees props={props} />}
        />

        <Route
          path="/login"
          exact
          render={(props) => <SignIn props={props} />}
        />
        <Route
          path="/forgot-password"
          exact
          render={(props) => <ForgotPassword props={props} />}
        />
        <Route
          path="/verification"
          exact
          render={(props) => <VerificationType props={props} />}
        />
        <Route
          path="/verification-code"
          exact
          render={(props) => <VerificationCode props={props} />}
        />
        <Route
          path="/signup-type"
          exact
          render={(props) => <SignUpType props={props} />}
        />
        <Route
          path="/signup-personal"
          exact
          render={(props) => <SignUp props={props} />}
        />
        <Route
          path="/register-company-member"
          exact
          render={(props) => <SignUpMember props={props} />}
        />
        <Route
          path="/signup-company"
          exact
          render={(props) => <SignUpCompany props={props} />}
        />
        <Route
          path="/signup-personal-info"
          exact
          render={(props) => <SignUpPersonalInfo props={props} />}
        />
        <Route
          path="/signup-confirm"
          exact
          render={(props) => <SignUpConfirm props={props} />}
        />
        <Route
          path="/signup-company-confirm"
          exact
          render={(props) => <SignUpCompanyConfirm props={props} />}
        />
        <Route
          path="/password-confirm"
          exact
          render={(props) => <SignInConfirm props={props} />}
        />
        <Route
          path="/create-new-password"
          exact
          render={(props) => <CreateNewPassword props={props} />}
        />
        <Route
          path="/email-send"
          exact
          render={(props) => <EmailSend props={props} />}
        />
        <Route
          path="/terms"
          exact
          render={(props) => <Terms props={props} />}
        />
        <Route path="/404" component={NotFound} />
        <Redirect from="/" to="/dashboard" />
      </Switch>
    </BrowserRouter>
  );
}

export default Routers;
