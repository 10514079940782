import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Layout from '../../../components/Layout/Layout'
import { BackButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import LayoutForm from '../../../components/Layout/LayoutForm'
import ProgressBar from '../../../components/shared/ProgressBar/ProgessBar'
import { Form, Formik } from 'formik'
import {Button, FormControlLabel, Radio, RadioGroup} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import Slider from 'react-slick'
import { fetchRiskPortfolioData, postRiskPortfolioData } from '../../../redux/slices/riskSlice'
import ProgressCircle from '../../../components/shared/ProgressBar/ProgressCircle'
import Modal from '../../../components/shared/Modal/Modal'
import { setIsOpen } from '../../../redux/slices/modalSlice'
import Graph1 from '../../../assets/images/graphs/1.svg'
import Graph2 from '../../../assets/images/graphs/2.svg'
import Graph3 from '../../../assets/images/graphs/3.svg'
import Graph4 from '../../../assets/images/graphs/4.svg'
import Graph5 from '../../../assets/images/graphs/5.svg'
import Very from '../../../assets/images/graphs/very_b.svg'
import Risky from '../../../assets/images/graphs/risky_b.svg'
import Conservative from '../../../assets/images/graphs/conservative_b.svg'
import Balanced from '../../../assets/images/graphs/balanced_b.svg'
import Averse from '../../../assets/images/graphs/averse_b.svg'
import { fetchPercentages } from '../../../redux/slices/percentagesSlice'
import { RiskPortfolioSchema } from '../../../utils/validation'
import TooltipCustom from '../../../components/shared/Tooltip/Tooltip'


const RiskPortfolio = ({ props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.user.user);
  const sending = useSelector(state => state.sending.isSending);
  const { portfolio_now, portfolio_thirty, portfolio_sixty, portfolio_eighty } = useSelector(state => state.risk);
  const isOpen = useSelector(state => state.modal.isOpen);
  const percents = useSelector(state => state.percentages.percents);

  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 600,
      },
      {
        breakpoint: 740,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 10000,
        settings: 'unslick'
      }
    ]
  };

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchPercentages({name: 'risk-portfolio'}));
      await dispatch(fetchRiskPortfolioData());
    }
    if (id) {
      fetch()
    }

    // eslint-disable-next-line
  }, [id])

  const onSubmit = (values) => {
    dispatch(postRiskPortfolioData(values))
  };

  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }))
  };

  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }));
    history.push("/input")
  };

  return (
    <Layout>
      <LayoutForm>
        <BackButton title="Back" path="/risk" />
        <PageTitle
          title="Risk profile"
          content="Please answer the following risk profile questionnaire as honestly as possible, as it will help determine your optimal asset allocation strategy."
          status={percents && percents.percent ? percents.percent : '50'}
        />
        <div className="layout-form__content-holder">
          <div className="container container--small mb--medium mobile-hidden">
            <ProgressBar amount={percents && percents.percent ? percents.percent : '50'} />
          </div>
          <Formik
            enableReinitialize
            initialValues={{
              portfolio_now: portfolio_now || null,
              portfolio_thirty: portfolio_thirty || null,
              portfolio_sixty: portfolio_sixty || null,
              portfolio_eighty: portfolio_eighty || null
            }}
            validationSchema={RiskPortfolioSchema}
            onSubmit={onSubmit}
          >
            {({ errors, handleChange, handleBlur, values, touched }) => (
              <Form>
                <div className="form__label">
                  <h5>Which of the following portfolios do you prefer?</h5>

                  <TooltipCustom
                    title={
                      <>
                        <h6>Current Portfolio Preference</h6>
                        Typically high risk investment are rewarded with high returns.  Unfortunately, no pain, no gain!  The green component of the each portfolio bar chart represents the positive return that example portfolio can make in any given year while the red portion of each portfolio represents the losses that same portfolio can make in any given year.
                      </>
                    }
                  />
                </div>
                {touched.portfolio_now && errors.portfolio_now && <span className="form__msg-error">{errors.portfolio_now}</span>}
                <RadioGroup
                  name="portfolio_now"
                  value={values.portfolio_now || null}
                  onChange={handleChange}
                  className="radio-list-slider"
                >
                  <Slider {...settings} className="radio-slider">
                    <FormControlLabel
                      value="highest"
                      control={<Radio />}
                      label={
                        <>
                          <div className="radio__label">Very risky</div>
                          <div className="radio__image"><img src={Graph1} alt="Very risky" /></div>
                        </>
                      }
                    />
                    <FormControlLabel
                      value="high"
                      control={<Radio />}
                      label={
                        <>
                          <div className="radio__label">Risky</div>
                          <div className="radio__image"><img src={Graph2} alt="Risky" /></div>
                        </>
                      }
                    />
                    <FormControlLabel
                      value="normal"
                      control={<Radio />}
                      label={
                        <>
                          <div className="radio__label">Balanced</div>
                          <div className="radio__image"><img src={Graph3} alt="Balanced" /></div>
                        </>
                      }
                    />
                    <FormControlLabel
                      value="low"
                      control={<Radio />}
                      label={
                        <>
                          <div className="radio__label">Conservative</div>
                          <div className="radio__image"><img src={Graph4} alt="Conservative" /></div>
                        </>
                      }
                    />
                    <FormControlLabel
                      value="lowest"
                      control={<Radio />}
                      label={
                        <>
                          <div className="radio__label">Risk averse</div>
                          <div className="radio__image"><img src={Graph5} alt="Risk averse" /></div>
                        </>
                      }
                    />
                  </Slider>
                </RadioGroup>
                <div className="form__label">
                  <h5>Which of the following portfolios do you prefer when you are/were 30 years of age?</h5>

                  <TooltipCustom
                    title={
                      <>
                        <h6>Portfolio Preference at 30</h6>
                        The more fixed income a portfolio has relative to equities, the less return you can expect to make on that portfolio in any given year.  On the other hand, that high allocation of fixed income also makes that portfolio safer than portfolios with higher mixes of equity.  The older you get, the safer you're likely going to want to become in your investments as you will not have as much time to recoup any given yearly major losses to your portfolio.
                      </>
                    }
                  />
                </div>
                {touched.portfolio_thirty && errors.portfolio_thirty && <span className="form__msg-error">{errors.portfolio_thirty}</span>}
                <RadioGroup
                  name="portfolio_thirty"
                  value={values.portfolio_thirty || null}
                  onChange={handleChange}
                  className="radio-list-slider"
                >
                  <Slider {...settings} className="radio-slider">
                    <FormControlLabel
                      value="highest"
                      control={<Radio />}
                      label={
                        <>
                          <div className="radio__label">Very risky</div>
                          <div className="radio__image"><img src={Very} alt="Very risky" /></div>
                        </>
                      }
                    />
                    <FormControlLabel
                      value="high"
                      control={<Radio />}
                      label={
                        <>
                          <div className="radio__label">Risky</div>
                          <div className="radio__image"><img src={Risky} alt="Risky" /></div>
                        </>
                      }
                    />
                    <FormControlLabel
                      value="normal"
                      control={<Radio />}
                      label={
                        <>
                          <div className="radio__label">Balanced</div>
                          <div className="radio__image"><img src={Balanced} alt="Balanced" /></div>
                        </>
                      }
                    />
                    <FormControlLabel
                      value="low"
                      control={<Radio />}
                      label={
                        <>
                          <div className="radio__label">Conservative</div>
                          <div className="radio__image"><img src={Conservative} alt="Conservative" /></div>
                        </>
                      }
                    />
                    <FormControlLabel
                      value="lowest"
                      control={<Radio />}
                      label={
                        <>
                          <div className="radio__label">Risk averse</div>
                          <div className="radio__image"><img src={Averse} alt="Risk averse" /></div>
                        </>
                      }
                    />
                  </Slider>
                </RadioGroup>
                <div className="form__label">
                  <h5>Which of the following portfolios do you prefer when you are/were 60 years of age?</h5>

                  <TooltipCustom
                    title={
                      <>
                        <h6>Portfolio Preference at 60</h6>
                        The more fixed income a portfolio has relative to equities, the less return you can expect to make on that portfolio in any given year.  On the other hand, that high allocation of fixed income also makes that portfolio safer than portfolios with higher mixes of equity.  The older you get, the safer you're likely going to want to become in your investments as you will not have as much time to recoup any given yearly major losses to your portfolio.
                      </>
                    }
                  />
                </div>
                {touched.portfolio_sixty && errors.portfolio_sixty && <span className="form__msg-error">{errors.portfolio_sixty}</span>}
                <RadioGroup
                  name="portfolio_sixty"
                  value={values.portfolio_sixty || null}
                  onChange={handleChange}
                  className="radio-list-slider"
                >
                  <Slider {...settings} className="radio-slider">
                    <FormControlLabel
                      value="highest"
                      control={<Radio />}
                      label={
                        <>
                          <div className="radio__label">Very risky</div>
                          <div className="radio__image"><img src={Very} alt="Very risky" /></div>
                        </>
                      }
                    />
                    <FormControlLabel
                      value="high"
                      control={<Radio />}
                      label={
                        <>
                          <div className="radio__label">Risky</div>
                          <div className="radio__image"><img src={Risky} alt="Risky" /></div>
                        </>
                      }
                    />
                    <FormControlLabel
                      value="normal"
                      control={<Radio />}
                      label={
                        <>
                          <div className="radio__label">Balanced</div>
                          <div className="radio__image"><img src={Balanced} alt="Balanced" /></div>
                        </>
                      }
                    />
                    <FormControlLabel
                      value="low"
                      control={<Radio />}
                      label={
                        <>
                          <div className="radio__label">Conservative</div>
                          <div className="radio__image"><img src={Conservative} alt="Conservative" /></div>
                        </>
                      }
                    />
                    <FormControlLabel
                      value="lowest"
                      control={<Radio />}
                      label={
                        <>
                          <div className="radio__label">Risk averse</div>
                          <div className="radio__image"><img src={Averse} alt="Risk averse" /></div>
                        </>
                      }
                    />
                  </Slider>
                </RadioGroup>
                <div className="form__label">
                  <h5>Which of the following portfolios do you prefer when you are/were 80 years of age?</h5>

                  <TooltipCustom
                    arrow
                    title={
                      <>
                        <h6>Portfolio Preference at 80</h6>
                        The more fixed income a portfolio has relative to equities, the less return you can expect to make on that portfolio in any given year.  On the other hand, that high allocation of fixed income also makes that portfolio safer than portfolios with higher mixes of equity.  The older you get, the safer you're likely going to want to become in your investments as you will not have as much time to recoup any given yearly major losses to your portfolio.
                      </>
                    }
                  />
                </div>
                {touched.portfolio_eighty && errors.portfolio_eighty && <span className="form__msg-error">{errors.portfolio_eighty}</span>}
                <RadioGroup
                  name="portfolio_eighty"
                  value={values.portfolio_eighty || null}
                  onChange={handleChange}
                  className="radio-list-slider"
                >
                  <Slider {...settings} className="radio-slider">
                    <FormControlLabel
                      value="highest"
                      control={<Radio />}
                      label={
                        <>
                          <div className="radio__label">Very risky</div>
                          <div className="radio__image"><img src={Very} alt="Very risky" /></div>
                        </>
                      }
                    />
                    <FormControlLabel
                      value="high"
                      control={<Radio />}
                      label={
                        <>
                          <div className="radio__label">Risky</div>
                          <div className="radio__image"><img src={Risky} alt="Risky" /></div>
                        </>
                      }
                    />
                    <FormControlLabel
                      value="normal"
                      control={<Radio />}
                      label={
                        <>
                          <div className="radio__label">Balanced</div>
                          <div className="radio__image"><img src={Balanced} alt="Balanced" /></div>
                        </>
                      }
                    />
                    <FormControlLabel
                      value="low"
                      control={<Radio />}
                      label={
                        <>
                          <div className="radio__label">Conservative</div>
                          <div className="radio__image"><img src={Conservative} alt="Conservative" /></div>
                        </>
                      }
                    />
                    <FormControlLabel
                      value="lowest"
                      control={<Radio />}
                      label={
                        <>
                          <div className="radio__label">Risk averse</div>
                          <div className="radio__image"><img src={Averse} alt="Risk averse" /></div>
                        </>
                      }
                    />
                  </Slider>
                </RadioGroup>
                <div className="form__button form__button--center form__button--no-padding">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Save
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
                <Modal
                  open={isOpen}
                  small
                  button="Done"
                  onClick={handleClick}
                  close={onClose}
                >
                  <h3>Good Job!</h3>
                  <p>Your progress increased 100%</p>
                  <ProgressCircle amount={100} small />
                </Modal>
              </Form>
            )}
          </Formik>
        </div>
      </LayoutForm>
    </Layout>
  );
};

export default RiskPortfolio;
