import React, {useEffect} from 'react'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../../components/Layout/Layout'
import { BackButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import LayoutForm from '../../../components/Layout/LayoutForm'
import ProgressBar from '../../../components/shared/ProgressBar/ProgessBar'
import { FormSelect, FormGroup } from '../../../components/shared/Form'
import { RiskSchema } from '../../../utils/validation'
import Priority from '../../../components/shared/Priority/Priority'
import { Button, FormControlLabel, Radio, RadioGroup, TextareaAutosize } from '@mui/material'
import { riskList, agreeList } from '../../../Data/risk'
import { fetchRiskData, postRiskData } from '../../../redux/slices/riskSlice'
import { fetchPercentages } from '../../../redux/slices/percentagesSlice'
import Illustration from '../../../assets/images/illustrations/Tree-segment_aboutMe-min.png'
import { getPercent } from '../../../utils/utils'

const Risk = ({ props }) => {
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.user.user);
  const sending = useSelector(state => state.sending.isSending);
  const { overall_risk, preferred_portfolio, return_risk, lose_twenty, lose_fifty, bengraham, additional_info } = useSelector(state => state.risk);
  const percents = useSelector(state => state.percentages.percents);

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchPercentages());
      await dispatch(fetchRiskData());
    }
    if (id) {
      fetch()
    }

    // eslint-disable-next-line
  }, [id])

  const onSubmit = (values) => {
    dispatch(postRiskData(values, props))
  }

  const risk = getPercent(percents, 'risk')

  return (
    <Layout>
      <LayoutForm illustration={Illustration} small>
        <BackButton title="Back to Tree" path="/input" />
        <PageTitle
          title="Risk profile"
          content="Please answer the following risk profile questionnaire as honestly as possible, as it will help determine your optimal asset allocation strategy."
          status={percents && risk ? risk : '0'}
          info={
            <>
              <h6>Risk Profile</h6>
              <Priority option="High" />
              <div>Answer the following questions so that ValueSoft can assess your willingness to take risk.  Your willingness to take risk will determine the ValueSoft suggested asset allocation strategy for your investable assets, and the asset allocation mix will thereby determine your overall estimated yearly return on your investments.</div>
              <hr className="tooltip__line"/>
              <h6>Example</h6>
              <div>Say the estimated annual return on fixed income is 5 percent while the estimated annual return of equities is 7 percent.  Say you answer the questions and ValueSoft determines you are not very risky.  It will likely suggest allocating the majority of your investable assets to fixed income and cash versus equities, thus impacting your estimated annual return on your investable assets, in this case would be closer to 5 percent than 7 percent.</div>
            </>
          }
        />
        <div className="layout-form__content-holder">
          <div className="mb--medium">
            <ProgressBar amount={percents && risk ? risk : '0'} />
          </div>
          <Formik
            enableReinitialize
            initialValues={{
              overall_risk: overall_risk || null,
              preferred_portfolio: preferred_portfolio || null,
              return_risk: return_risk || null,
              lose_twenty: lose_twenty || null,
              lose_fifty: lose_fifty || null,
              bengraham: bengraham || null,
              additional_info: additional_info || null
            }}
            validationSchema={RiskSchema}
            onSubmit={onSubmit}
          >
            {({ errors, handleChange, handleBlur, values, touched, setFieldValue }) => (
              <Form>
                <FormGroup
                  name="overall_risk"
                  labelText="On a scale of 1 to 10, how would you rate your overall willingness to take risk, 10 being extremely risky?"
                  info={{
                    content:
                      <>
                        <h6>Overall Risk</h6>
                        <Priority option="High" />
                        <div>Your answers to this and the remaining investment policy questions will help ValueSoft determine your overall risk profile and hence determine the appropriate asset allocation mix for your investable assets.</div>
                      </>
                  }}
                >
                  <FormSelect
                    options={riskList}
                    id="overall_risk"
                    value={values.overall_risk || ''}
                    name="overall_risk"
                    onChange={(e) => {
                      setFieldValue(e.target.name, e.target.value)
                    }}
                    placeholder="Select"
                    hasError={!!errors.overall_risk && touched.overall_risk}
                  />
                  {touched.overall_risk && errors.overall_risk && <span className="form__msg-error">{errors.overall_risk}</span>}
                </FormGroup>
                <FormGroup
                  name="preferred_portfolio"
                  labelText="Which of the following portfolios would you prefer?"
                  info={{
                    content:
                      <>
                        <h6>Portfolio Preference</h6>
                        <Priority option="High" />
                        <div>Your answers to this and the remaining investment policy questions will help ValueSoft determine your overall risk profile and hence determine the appropriate asset allocation mix for your investable assets.</div>
                      </>
                  }}
                >
                  <RadioGroup
                    name="preferred_portfolio"
                    value={values.preferred_portfolio || null}
                    onChange={handleChange}
                  >
                    <FormControlLabel value="low" control={<Radio />} label="Low risk, protects against inflation, but does not necessarily beat the broad based stock market;" />
                    <FormControlLabel value="medium" control={<Radio />} label="Medium risk, typically but not necessarily protects against inflation in any given year, but equals the broad based stock market;" />
                    <FormControlLabel value="high" control={<Radio />} label="High risk, may not protect against inflation in any given year, but beats the broad based stock market.	" />
                  </RadioGroup>
                  {touched.preferred_portfolio && errors.preferred_portfolio && <span className="form__msg-error">{errors.preferred_portfolio}</span>}
                </FormGroup>
                <FormGroup
                  name="return_risk"
                  labelText="What's your preference in terms of risk and return tradeoff?"
                  info={{
                    content:
                      <>
                        <h6>Risk and Return</h6>
                        <Priority option="High" />
                        <div>Typically high risk investment are rewarded with high returns.  Unfortunately, no pain, no gain!</div>
                      </>
                  }}
                >
                  <RadioGroup
                    name="return_risk"
                    value={values.return_risk || null}
                    onChange={handleChange}
                  >
                    <FormControlLabel value="high" control={<Radio />} label="High risk, high return;" />
                    <FormControlLabel value="medium" control={<Radio />} label="Medium risk, medium return;" />
                    <FormControlLabel value="low" control={<Radio />} label="Low risk, low return" />
                  </RadioGroup>
                  {touched.return_risk && errors.return_risk && <span className="form__msg-error">{errors.return_risk}</span>}
                </FormGroup>
                <FormGroup
                  name="lose_twenty"
                  labelText="On a scale of 1 to 10, how comfortable are you (10 being very comfortable) with losing 20% of your portfolio's value in a single year, with a long term annual return expectation of 7%?"
                  info={{
                    content:
                      <>
                        <h6>Losing 20 percent</h6>
                        <Priority option="High" />
                        <div>Typically high risk investment are rewarded with high returns. If you are unwilling to risk losing a lot of your portfolio's value in a single year, you are likely not going to make more than the average return in the market and perhaps even less than that.</div>
                        <hr className="tooltip__line"/>
                        <h6>Example</h6>
                        <div>If you are someone who would not be willing to see your investments tank in a given year due to a market downturn, then you are likely not very risky and hence cannot expect an above average return in your investments.</div>
                      </>
                  }}
                >
                  <FormSelect
                    options={riskList}
                    id="lose_twenty"
                    value={values.lose_twenty || ''}
                    name="lose_twenty"
                    onChange={(e) => {
                      setFieldValue(e.target.name, e.target.value)
                    }}
                    placeholder="Select"
                    hasError={!!errors.lose_twenty && touched.lose_twenty}
                  />
                  {touched.lose_twenty && errors.lose_twenty && <span className="form__msg-error">{errors.lose_twenty}</span>}
                </FormGroup>
                <FormGroup
                  name="lose_fifty"
                  labelText="On a scale of 1 to 10, how comfortable are you (10 being very comfortable) with losing 50% of your portfolio's value in a single year, with a long term annual return expectation of 15%?"
                  info={{
                    content:
                      <>
                        <h6>Losing 50 Percent</h6>
                        <Priority option="High" />
                        <div>Typically high risk investment are rewarded with high returns.  If you are unwilling to risk losing a lot of your portfolio's value in a single year, you are likely not going to make more than the average return in the market and perhaps even less than that.</div>
                        <hr className="tooltip__line"/>
                        <h6>Example</h6>
                        <div>If you are someone who would not be willing to see your investments tank in a given year due to a market downturn, then you are likely not very risky and hence cannot expect an above average return in your investments.</div>
                      </>
                  }}
                >
                  <FormSelect
                    options={riskList}
                    id="lose_fifty"
                    value={values.lose_fifty || ''}
                    name="lose_fifty"
                    onChange={(e) => {
                      setFieldValue(e.target.name, e.target.value)
                    }}
                    placeholder="Select"
                    hasError={!!errors.lose_fifty && touched.lose_fifty}
                  />
                  {touched.lose_fifty && errors.lose_fifty && <span className="form__msg-error">{errors.lose_fifty}</span>}
                </FormGroup>
                <FormGroup
                  name="bengraham"
                  labelText="Benjamin Graham, who was Warren Buffett's professor and mentor, advised that individual investors allocate their assets according to moderate risk, broad based market indexes. Do you agree with this investment strategy, as it relates to your own investable assets?"
                  info={{
                    content:
                      <>
                        <h6>Benjamin Graham</h6>
                        <Priority option="High" />
                        <div>Moderate risk, broad market indexes are mutual funds, index funds or exchange traded funds and track the overall market, such as the SP 500 or Dow Jones Industrial Index.  These indexes typically return what the overall market returns and carry about the same level of risk as the overall market.  You can further diversify by buying funds that combine domestic and international equities as well as diversify their holdings across asset classes, such as equities and fixed income.</div>
                      </>
                  }}
                >
                  <FormSelect
                    options={agreeList}
                    id="bengraham"
                    value={values.bengraham || ''}
                    name="bengraham"
                    onChange={(e) => {
                      setFieldValue(e.target.name, e.target.value)
                    }}
                    placeholder="Select"
                    hasError={!!errors.bengraham && touched.bengraham}
                  />
                  {touched.bengraham && errors.bengraham && <span className="form__msg-error">{errors.bengraham}</span>}
                </FormGroup>
                <h5 className="mt--medium">Additional info</h5>
                <FormGroup name="additional_info" labelText="Is there any other Risk Profile information that you believe is important for ValueSoft to know about? If so, please let us know about it:	">
                  <TextareaAutosize
                    maxLength="200"
                    name="additional_info"
                    value={values.additional_info || ''}
                    className="form__textarea"
                    placeholder="Maximum characters: 200"
                    onChange={handleChange}
                  />
                </FormGroup>
                <div className="form__button">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Next
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </LayoutForm>
    </Layout>
  );
};

export default Risk;
