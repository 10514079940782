import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Formik } from 'formik'
import Layout from '../../../components/Layout/Layout'
import LayoutForm from '../../../components/Layout/LayoutForm'
import { BackButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import {FormGroup, Input, InputCurrency} from '../../../components/shared/Form'
import { Button, FormControlLabel, Radio, RadioGroup, TextareaAutosize } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import Illustration from '../../../assets/images/illustrations/Tree-segment_protection-min.png'
import { fetchUnemployment, postUnemployment } from '../../../redux/slices/protectionSlice'
import { fetchUserDetails } from '../../../redux/slices/userSlice'
import { getPercent, normalizeBoolean } from '../../../utils/utils'
import { UnemploymentSchema } from '../../../utils/validationProtection'
import { fetchPercentages } from '../../../redux/slices/percentagesSlice'
import Modal from '../../../components/shared/Modal/Modal'
import ProgressCircle from '../../../components/shared/ProgressBar/ProgressCircle'
import { setIsOpen } from '../../../redux/slices/modalSlice'

const Unemployment = ({ props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.user.user);
  const { is_married } = useSelector(state => state.user.profile.details);
  const unemployment = useSelector(state => state.protection.unemployment);
  const sending = useSelector(state => state.sending.isSending);
  const percents = useSelector(state => state.percentages.percents);
  const isOpen = useSelector(state => state.modal.isOpen);
  const [isMobile, setIsMobile] = useState(false);
  const mobileView = 999;
  const handleResize = () => {
    (mobileView >= window.innerWidth) ? setIsMobile(true) : setIsMobile(false)
  }

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchPercentages());
      await dispatch(fetchUserDetails(id));
      await dispatch(fetchUnemployment());
    }
    if (id) {
      fetch()
    }

    // eslint-disable-next-line
  }, [id])

  const onSubmit = (values) => {
    if(!values.model_unemployment) {
      dispatch(postUnemployment({
        ...values,
        weeks_severance: 0,
        accrued_vacation: 0
      }, props))
    } else {
      dispatch(postUnemployment(values, props))
    }
  }

  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }))
  }

  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }))
    history.push("/input")
  }

  const handleChangeInput = (e, setValue) => {
    if([null, false].includes(e.target.value)) return false

    setValue(e.target.name, e.target.value)
  }

  const profile             = getPercent(percents, 'profile')
  const unemploymentPercent = getPercent(percents, 'unemployment')

  return (
    <Layout isBlockedPage={percents && profile === 0}>
      <LayoutForm illustration={Illustration} small>
        <BackButton title={isMobile ? 'Back' : 'Back to Tree'} />
        <PageTitle
          title="Unemployment planning"
          content="Please answer a few unemployment related questions so that ValueSoft can account for this in your liquidity plan and in other key aspects of your financial plan. "
          status={percents && unemploymentPercent ? unemploymentPercent : '0'}
          info={
            <div>Determine how unemployment will impact your net worth and liquidity and measures to take to minimize the hardship.</div>
          }
        />
        <div className="layout-form__content-holder">
          <Formik
            enableReinitialize
            initialValues={unemployment}
            validationSchema={UnemploymentSchema}
            onSubmit={onSubmit}
          >
            {({ errors, handleChange, handleBlur, values, touched, setFieldValue }) => (
              <Form>
                <FormGroup
                  name="model_unemployment"
                  labelText="Would you like to model in a specific period of unemployment for you/spouse into your financial plan to ensure you have enough liquidity to weather such a potential situation?"
                >
                  <RadioGroup
                    name="model_unemployment"
                    value={values.model_unemployment || false}
                    onChange={(e) => {
                      setFieldValue('model_unemployment', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                </FormGroup>
                <FormGroup name="who_utilized" labelText="Who should be utilized in the unemployment scenario?">
                  <RadioGroup
                    name="who_utilized"
                    value={values.who_utilized || 'me'}
                    onChange={handleChange}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value="me" control={<Radio />} label="Me" />
                    <FormControlLabel value="spouse" control={<Radio />} label="Spouse" />
                    <FormControlLabel value="both" control={<Radio />} label="Both" />
                  </RadioGroup>
                </FormGroup>
                <FormGroup name="what_year" labelText="What year do you expect to be unemployed (yyyy)?	(optional)">
                  <Input
                    name="what_year"
                    placeholder="Type your expect to be unemployed"
                    type="number"
                    onChange={handleChange}
                    value={values.what_year || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.what_year}
                  />
                  {errors.what_year && <span className="form__msg-error">{errors.what_year}</span>}
                </FormGroup>
                <FormGroup name="months" labelText="How many months do you expect to be unemployed? (optional)">
                  <Input
                    name="months"
                    placeholder="Type months do you expect"
                    type="number"
                    onChange={e => handleChangeInput(e, setFieldValue)}
                    value={values.months || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.months}
                  />
                  {errors.months && <span className="form__msg-error">{errors.months}</span>}
                </FormGroup>
                {is_married &&
                  <>
                    <FormGroup name="spouse_what_year" labelText="What year do you expect your spouse to be unemployed (yyyy)? (optional)	">
                      <Input
                        name="spouse_what_year"
                        placeholder="Type months you expect your spouse"
                        type="number"
                        onChange={handleChange}
                        value={values.spouse_what_year || ''}
                        onBlur={handleBlur}
                        hasError={!!errors.spouse_what_year}
                      />
                      {errors.spouse_what_year && <span className="form__msg-error">{errors.spouse_what_year}</span>}
                    </FormGroup>
                    <FormGroup name="spouse_months" labelText="How many months do you expect your spouse to be unemployed?	(optional)">
                      <Input
                        name="spouse_months"
                        placeholder="Type months of long term disability your spouses"
                        type="number"
                        onChange={e => handleChangeInput(e, setFieldValue)}
                        value={values.spouse_months || ''}
                        onBlur={handleBlur}
                        hasError={!!errors.spouse_months}
                      />
                      {errors.spouse_months && <span className="form__msg-error">{errors.spouse_months}</span>}
                    </FormGroup>
                  </>}
                <FormGroup
                  name="unemployment_health_care"
                  labelText="Include Unemployment Health Care Premium (COBRA) in Your Expenses?"
                  info={{
                    content:
                      <div>Unemployment health insurance costs money and if you will require it, you are encouraged to factor in this monthly expense into your budget plan during period of unemployment.</div>
                  }}
                >
                  <RadioGroup
                    name="unemployment_health_care"
                    value={values.unemployment_health_care || false}
                    onChange={(e) => {
                      setFieldValue('unemployment_health_care', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                </FormGroup>
                <FormGroup name="coverage_extended_to" labelText="Who Should Unemployment Health Care Coverage Be Extended to?	 ">
                  <RadioGroup
                    name="coverage_extended_to"
                    value={values.coverage_extended_to || 'me'}
                    onChange={handleChange}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value="me" control={<Radio />} label="Only me" />
                    <FormControlLabel value="family" control={<Radio />} label="My family" />
                  </RadioGroup>
                </FormGroup>
                <FormGroup name="leverage_each_other_plan" labelText="Will You/Spouse be able to leverage each other's employee healthcare plan if you are unemployed at separate times?">
                  <RadioGroup
                    name="leverage_each_other_plan"
                    value={values.leverage_each_other_plan || false}
                    onChange={(e) => {
                      setFieldValue('leverage_each_other_plan', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                </FormGroup>
                <FormGroup
                  name="weeks_severance"
                  labelText="Your number of weeks' severance pay"
                >
                  <Input
                    name="weeks_severance"
                    placeholder="Type number of weeks' severance pay"
                    type="number"
                    onChange={e => handleChangeInput(e, setFieldValue)}
                    value={values.weeks_severance.toString() || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.weeks_severance && touched.weeks_severance}
                    info={{
                      content:
                      <>
                        <div>This is your severance payout by your current employer if you were to get laid off.</div>
                        <hr className="tooltip__line"/>
                        <h6>Example</h6>
                        <div>Say you were laid off tomorrow and given a 4 week severance package.  In this case, enter '4' here.</div>
                      </>
                    }}
                  />
                  {touched.weeks_severance && errors.weeks_severance && <span className="form__msg-error">{errors.weeks_severance}</span>}
                </FormGroup>
                <FormGroup
                  name="accrued_vacation"
                  labelText="Your accrued, payable vacation weeks"
                >
                  <Input
                    name="accrued_vacation"
                    placeholder="Type accrued, payable vacation weeks"
                    type="number"
                    onChange={e => handleChangeInput(e, setFieldValue)}
                    value={values.accrued_vacation.toString() || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.accrued_vacation && touched.accrued_vacation}
                    info={{
                      content:
                        <>
                          This is your unused vacation time payout by your current employer if you were to get laid off.
                          <hr className="tooltip__line"/>
                          <h6>Example</h6>
                          <div>Say you were laid off tomorrow and had accumulated 2 weeks worth of unused vacation.  In this case, enter '2' here.</div>
                        </>
                    }}
                  />
                  {touched.accrued_vacation && errors.accrued_vacation && <span className="form__msg-error">{errors.accrued_vacation}</span>}
                </FormGroup>
                <FormGroup
                  name="additional_severance"
                  labelText="Your additional severance income (optional)"
                >
                  <InputCurrency
                    placeholder="Type your additional severance income"
                    type="text"
                    name="additional_severance"
                    onChange={handleChange}
                    value={values.additional_severance || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.additional_severance && touched.additional_severance}
                  />
                  {touched.additional_severance && errors.additional_severance && <span className="form__msg-error">{errors.additional_severance}</span>}
                </FormGroup>
                {is_married &&
                  <>
                    <FormGroup
                      name="spouse_weeks_severance"
                      labelText="Your spouse's number of weeks' severance pay (optional)"
                    >
                      <Input
                        name="spouse_weeks_severance"
                        placeholder="Type Your spouse's number of weeks'"
                        type="number"
                        onChange={e => handleChangeInput(e, setFieldValue)}
                        value={values.spouse_weeks_severance || ''}
                        onBlur={handleBlur}
                        hasError={!!errors.spouse_weeks_severance}
                      />
                      {errors.spouse_weeks_severance && <span className="form__msg-error">{errors.spouse_weeks_severance}</span>}
                    </FormGroup>
                    <FormGroup
                      name="spouse_accrued_vacation"
                      labelText="Your spouse's accrued, payable vacation weeks (optional):"
                    >
                      <Input
                        name="spouse_accrued_vacation"
                        placeholder="Type your spouse's accrued, payable"
                        type="number"
                        onChange={e => handleChangeInput(e, setFieldValue)}
                        value={values.spouse_accrued_vacation || ''}
                        onBlur={handleBlur}
                        hasError={!!errors.spouse_accrued_vacation}
                      />
                      {errors.spouse_accrued_vacation && <span className="form__msg-error">{errors.spouse_accrued_vacation}</span>}
                    </FormGroup>
                    <FormGroup
                      name="spouse_additional_severance"
                      labelText="Your spouse's additional severance income (optional):"
                    >
                      <InputCurrency
                        placeholder="Type your spouse's additional severance "
                        type="text"
                        name="spouse_additional_severance"
                        onChange={handleChange}
                        value={values.spouse_additional_severance || ''}
                        onBlur={handleBlur}
                        hasError={!!errors.spouse_additional_severance}
                      />
                      {errors.spouse_additional_severance && <span className="form__msg-error">{errors.spouse_additional_severance}</span>}
                    </FormGroup>
                  </>}
                <h5 className="mt--medium">Additional info</h5>
                <FormGroup name="additional_info" labelText="Is there any other Unemployment Planning information that you believe is important for ValueSoft to know about? If so, please let us know about it:">
                  <TextareaAutosize
                    maxLength="200"
                    name="additional_info"
                    value={values.additional_info || ''}
                    className="form__textarea"
                    placeholder="Maximum characters: 200"
                    onChange={handleChange}
                  />
                </FormGroup>

                <div className="form__button">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Save
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <Modal
          open={isOpen}
          small
          button="Done"
          onClick={handleClick}
          close={onClose}
        >
          <h3>Good Job!</h3>
          <p>Your progress increased 100%</p>
          <ProgressCircle amount={100} small />
        </Modal>
      </LayoutForm>
    </Layout>
  );
};

export default Unemployment;
