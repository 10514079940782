import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import SVGIcon from '../shared/SVGIcon/SVGIcon'
import Edit from '../../assets/images/icons/edit.svg'
import Trash from '../../assets/images/icons/trash.svg'
import ModalBox from '../shared/Modal/Modal'


const AuthorCard = ({ name, onDelete, onEdit, companyId, authorId }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => setIsOpen(false)

  const remove =  (companyId, authorId) => {
     dispatch(onDelete(companyId, authorId, true))
     setIsOpen(false)
  }

  return (
    <>
      <div className="author-card">
        <div className="author-card__title">{name}</div>
        <div className="author-card__options">
          <button onClick={() => onEdit(authorId)}>
            <SVGIcon icon={Edit} color="#8c8c8c" />
          </button>
          <button onClick={() => setIsOpen(true)}>
            <SVGIcon icon={Trash} color="#8c8c8c" />
          </button>
        </div>
      </div>
      <ModalBox
        small
        inline
        open={isOpen}
        close={closeModal}
        onClick={closeModal}
        title='Do you want to delete this author?'
        button="No"
        removeButton="Yes"
        remove={() => remove(companyId, authorId)}
      />
    </>
  )
}

AuthorCard.propTypes = {
  authorId: PropTypes.number.isRequired,
  name: PropTypes.string,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
}

export default AuthorCard
