import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import Layout from '../../../components/Layout/Layout'
import { BackButton } from '../../../components/shared/Buttons'
import { useDispatch, useSelector } from 'react-redux'
import TreeSubItem from '../../../components/Tree/TreeSubItem'
import {fetchUserDetails, fetchUserFamilyData} from '../../../redux/slices/userSlice'
import TreeImage from '../../../assets/images/tree/future/Future-tree.png'
import { fetchTotalPercent } from '../../../redux/slices/percentagesSlice'
import { getPercent } from '../../../utils/utils'
import TreeCount from '../../../components/Tree/TreeCount'
import { fetchAnalysisTasks } from '../../../redux/slices/taskSlice'

const Sabbatical = ({ props }) => {
  const dispatch = useDispatch()
  const { id } = useSelector(state => state.user.user)
  const isBasic = useSelector((state) => state.user.user.plan_type === 1);
  const totalPercent = useSelector(state => state.percentages.total)
  const isMarried = useSelector(state => state.user.profile.details.is_married)
  const percents = useSelector(state => state.percentages.percents)
  const loading = useSelector(state => state.sending.isSending)
  const analysis = useSelector(state => state.task.analysis)

  const [isMobile, setIsMobile] = useState(false);
  const mobileView = 999;
  const handleResize = () => {
    (mobileView >= window.innerWidth) ? setIsMobile(true) : setIsMobile(false)
  }

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true)
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchUserFamilyData(id))
      //await dispatch(fetchPercentages())
      await dispatch(fetchUserDetails(id))
      await dispatch(fetchAnalysisTasks())
      await dispatch(fetchTotalPercent(isBasic))
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])


  const sabbaticalYour = getPercent(percents, 'employment-sabbatical')
  const sabbaticalSpouse = getPercent(percents, 'spouse-employment-sabbatical')
  const sabbatical = (sabbaticalYour + (isMarried && sabbaticalSpouse))/(1 + (isMarried && 1))

  return (
    <Layout totalPercent={totalPercent} tree>
      <div className="tree__sticky-holder">
        <BackButton title={isMobile ? 'Back' : 'Back to Tree'} path={isMobile ? '/future' : "/input"} className="back-button--position" />
        <div className="tree__title tree__title--progress-bar">
          <div className="tree__title-main">
            <h2>Sabbatical</h2>
            <TreeCount
              value={sabbatical}
              loading={loading}
              big={!isMobile}
            />
          </div>
          {!isMobile && (
            <p className="tree__title-other">Fill in information to complete this step.</p>
          )}
        </div>
        <div className="tree tree--sub-page">
          {!isMobile && (
            <div className="tree__base">
              <img src={TreeImage} alt="ValueSoft Tree Profile Summary" />
            </div>
          )}
          <TreeSubItem path="/sabbatical/your"
                       title="Your Employment Sabbatical"
                       value="sabbatical-your"
                       count={sabbaticalYour}
                       isLoading={loading}
          />

          {isMarried && <TreeSubItem path="/sabbatical/your-spouse"
                                     title="Your Spouse's Employment Sabbatical"
                                     value="sabbatical-your-spouse"
                                     count={sabbaticalSpouse}
                                     isLoading={loading} />}

          {isMobile && <div className="tree__task">
            <Link to="/tasks">{analysis && analysis.openCount ? analysis.openCount : '0'}</Link>
          </div>}
        </div>
      </div>
    </Layout>
  )
}

export default Sabbatical
