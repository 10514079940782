import React from 'react'
import { Form, Formik } from 'formik'
import { Button } from '@mui/material'
import { FormGroup, InputCurrency } from '../shared/Form'

const HealthcareCoverageAdmin = ({ title, onSave, values, sending }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{...values}}
      onSubmit={values => onSave(values)}
    >
      {({ handleChange, handleBlur, values }) => (
        <Form>
          <h4 className="form__main-title">{title}</h4>
          <div className="form__flow-wrap">
          <FormGroup name="willing_to_pay" labelText="How much more per month would you be willing to pay for additional flexibility in your plan?">
            <InputCurrency
              name="willing_to_pay"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.willing_to_pay || 0}
              withoutSign
              icon
            />
          </FormGroup>
          </div>
          <div className="form__button">
            <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
              Save
              {sending && <span className="spinner" />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default HealthcareCoverageAdmin
