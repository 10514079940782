import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import LayoutAdmin from '../../components/Layout/LayoutAdmin';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { Button, TextareaAutosize } from '@mui/material';
import { FormGroup } from '../../components/shared/Form';
import Priority from '../../components/shared/Priority/Priority';
import { fetchNotifyAdmin, postNotifyAdmin } from '../../redux/slices/notificationsSlice';
import { fetchCompany } from '../../redux/slices/companiesSlice';
import Modal from '../../components/shared/Modal/Modal';

const AdminNotifications = ({ props, isAdmin }) => {
  const dispatch = useDispatch();
  const { id, permissions } = useSelector((state) => state.user.user);
  const sending = useSelector((state) => state.sending.isSending);
  const notify = useSelector((state) => state.notifications.notify);
  const { image, email, name } = useSelector((state) => state.companies.company.info);
  const [isOpen, setIsOpen] = useState(false);
  const { companyId } = useParams();
  const [isMobile, setIsMobile] = useState(false);

  const mobileView = 999;

  const handleResize = () => {
    mobileView >= window.innerWidth ? setIsMobile(true) : setIsMobile(false);
  };

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(fetchCompany(companyId));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function fetch() {
      dispatch(fetchNotifyAdmin(companyId));
    }

    if (id) {
      fetch();
    }
    // eslint-disable-next-line
  }, [id]);

  const onSubmit = (values) => {
    dispatch(postNotifyAdmin(companyId, values));
    setIsOpen(true)
  };

  if (permissions && !permissions?.update_header_notification) return <Redirect to="/404" />;

  return (
    <LayoutAdmin companyId={companyId} isAdmin={isAdmin}>
      <div className="member-comapny">
        <div className="member-comapny__info">
          <div className="member-comapny__logo">
            <img src={image} alt="Company logo" />
          </div>
          <div className="member-comapny__title">
            <h3>{name}</h3>
            {!isMobile && (
              <p>
                Feedback Email: <span>{email}</span>
              </p>
            )}
          </div>
        </div>
        {isMobile && (
          <div className="mobile-feedback">
            Feedback Email: <span>{email}</span>
          </div>
        )}
      </div>
      <div>
        <h4>Notifications</h4>
      </div>
      <div className="admin-notifications">
        <Formik
          enableReinitialize
          initialValues={notify ? { ...notify } : { message: "", insight: "" }}
          onSubmit={onSubmit}
        >
          {({ errors, handleChange, handleBlur, values }) => (
            <Form>
              <FormGroup
                name="message"
                labelText="Default email message for sharing action items"
                info={{
                  content: (
                    <>
                      <h6>Update default email message</h6>
                      <Priority option="High" />
                      <div>
                        This is the default message that will accompany any
                        action items you email out to your users. When you are
                        in the Implement Step and decide to email an action
                        item, your email will be sent with an introductory
                        message to the user. This will be the introductory
                        message.
                      </div>
                    </>
                  ),
                }}
              >
                <TextareaAutosize
                  name="message"
                  placeholder="Text"
                  onChange={handleChange}
                  value={values.message || ""}
                  onBlur={handleBlur}
                  className="form__textarea form__textarea--alt form__textarea--resize"
                />
              </FormGroup>
              <FormGroup
                name="insight"
                labelText="Insight of the week"
                info={{
                  content: (
                    <>
                      <h6>Insight of the week</h6>
                      <Priority option="High" />
                      <div>
                        This is the default insight of the week that appears on
                        the Overview tab and in the weekly status reports that
                        are emailed to your users. Update it as frequently as
                        you like!
                      </div>
                    </>
                  ),
                }}
              >
                <TextareaAutosize
                  name="insight"
                  placeholder="Text"
                  onChange={handleChange}
                  value={values.insight || ""}
                  onBlur={handleBlur}
                  className="form__textarea form__textarea--alt form__textarea--resize"
                />
              </FormGroup>
              <div className="form__button">
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  type="submit"
                  disabled={sending}
                >
                  Save
                  {sending && <span className="spinner" />}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <Modal
          open={isOpen}
          disableEnforceFocus={true}
          onMouseDown={(event) => event.stopPropagation()}
          small
          button="Ok"
          close={(event) => {
            event.stopPropagation();
            setIsOpen(false);
          }}
          onClick={(event) => {
            event.stopPropagation();
            setIsOpen(false);
          }}
          title="Success!"
          children={<p>The values were updated successfully.</p>}
        />
      </div>
    </LayoutAdmin>
  );
};

export default AdminNotifications;
