import React from 'react'
import PropTypes from 'prop-types'
import { color } from '../../../utils/utils'
import clsx from 'classnames'

const ProgressBar = ({ amount, showLabel }) => {

  const progressLineStyles = {
    width: `${amount}%`,
    backgroundColor: `${color(amount)}`,
  }

  const progressDotStyles = {
    left: `calc(${amount}% - 8px)`,
    border: `solid 2px ${color(amount)}`,
  }

  return (
    <div className="progress-bar">
      <div className="progress-bar__holder">
        <div className='progress-bar__line' style={progressLineStyles}>
          <div className={clsx('progress-bar__dot', {'progress-bar__dot--show': showLabel})} style={progressDotStyles}>
            <div className="progress-bar__dot-text">{amount}%</div>
          </div>
        </div>
      </div>
    </div>
  )
}

ProgressBar.propTypes = {
  amount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  showLabel: PropTypes.bool
}

export default ProgressBar
