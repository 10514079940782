const medicalCare = [
  {
    key: 1,
    label: 'None',
    value: 'None',
  },
  {
    key: 2,
    label: 'Plan A',
    value: 'Plan A',
  },
  {
    key: 3,
    label: 'Plan A, B',
    value: 'Plan A, B',
  },
  {
    key: 4,
    label: 'Plan A, B, C',
    value: 'Plan A, B, C',
  },
  {
    key: 5,
    label: 'Plan A, B, C, D',
    value: 'Plan A, B, C, D',
  },
  {
    key: 6,
    label: 'Plan A, B, D',
    value: 'Plan A, B, D',
  },
  {
    key: 7,
    label: 'Plan A, C',
    value: 'Plan A, C',
  },
  {
    key: 8,
    label: 'Plan A, C, D',
    value: 'Plan A, C, D',
  },
  {
    key: 9,
    label: 'Plan A, D',
    value: 'Plan A, D',
  }
]

export default medicalCare;