import numeral from 'numeral'
// import ChartDataLabels from 'chartjs-plugin-datalabels'

const generalOptions = {
  layout: {
    padding: {
      bottom: 0,
      top: 0
    }
  },
  cornerRadius: 8,
  responsive: true,
  legend: {
    display: false
  },
  tooltips: {
    backgroundColor: '#fff',
    titleFontFamily: '"Source Sans Pro", sans-serif',
    titleFontSize: 14,
    titleFontColor: '#000',
    titleFontStyle: 'normal',
    titleAlign: 'center',
    titleMarginBottom: 0,
    bodyFontColor: '#000',
    // callbacks: {
    //   label: function () {
    //     return false
    //   }
    // }
  },
  plugins: {
    datalabels: {
      display: false
    }
  },
}

export const optionsStacked = (step, onClick, showLabel, amount) => {
  
  return {
    ...generalOptions,
    onClick,
    scales: {
      xAxes: [{
        stacked: true,
        gridLines: {
          display: false,
        },
        ticks: {
          display: showLabel ? showLabel : false,
          fontColor: '#8C8C8C',
          fontFamily: '"Source Sans Pro", sans-serif',
          fontSize: 13
        }
      }],
      yAxes: [{
        gridLines: {
          borderDash: ['5', '7'],
          zeroLineColor: '#E8E8E8'
        },
        ticks: {
          fontColor: '#000',
          fontFamily: '"Source Sans Pro", sans-serif',
          fontSize: 13,
          min: 0,
          stepSize: step,
          callback: function(value) {
            if (amount) {
              return numeral(value).format('$0,0.00')
            } else {
              return value
            }
          }
        }
      }]
    }
  }
}

export const options = (step, showLabel, amount, split, maxLength = 30, percent, isMobile) => {
  
  return {
    ...generalOptions,
    tooltips: {
      ...generalOptions.tooltips,
      callbacks: {
        label: function(tooltipItem, data) {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          let value = amount ? numeral(tooltipItem.value).format('$0,0.00') : tooltipItem.value;
          return `${label} ${value}`
        }
      }
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        },
        ticks: {
          display: showLabel ? showLabel : false,
          fontColor: '#8C8C8',
          fontFamily: '"Source Sans Pro", sans-serif',
          fontSize: 13,
          // maxRotation: 0,
          callback: function(label, index, labels) {
            if (split && label.length > maxLength) {
              return wordWrap(label, maxLength)
            } else {
              return label
            }
          }
        },
      }],
      yAxes: [{
        gridLines: {
          borderDash: ['5', '7'],
          zeroLineColor: '#E8E8E8',
          drawBorder: false
        },
        ticks: {
          maxTicksLimit: 6,
          fontColor: '#000',
          fontFamily: '"Source Sans Pro", sans-serif',
          fontSize: 13,
          min: 0,
          stepSize: step,
          callback: function(value){
            if (amount) {
              return numeral(value).format('$0,0.00')
            } else if (percent) {
              return `${value} %`
            } else {
              return value
            }
          }
        }
      }]
    },
  }
}

export const optionsCalculator = (step, showLabel, amount, showLabelY = true, labelAmount, hideGridLines = true) => {
  return {
    ...generalOptions,
    tooltips: {
      callbacks: {
        label: function () {
          return false
        }
      }
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        },
        ticks: {
          display: showLabel ? showLabel : false,
          fontColor: '#8C8C8C',
          fontFamily: '"Source Sans Pro", sans-serif',
          fontSize: 13,
          callback: function(value) {
            if (labelAmount || amount) {
              return numeral(value).format('$0,0.00')
            } else {
              return value
            }
          }
        }
      }],
      yAxes: [{
        gridLines: {
          display: hideGridLines,
          borderDash: ['5', '7'],
          zeroLineColor: '#E8E8E8',
          drawBorder: false,
        },
        ticks: {
          display: showLabelY || false,
          maxTicksLimit: 6,
          fontColor: '#000',
          fontFamily: '"Source Sans Pro", sans-serif',
          fontSize: 13,
          min: 0,
          stepSize: step,
          callback: function(value) {
            if (amount) {
              return numeral(value).format('$0,0.00')
            } else {
              return value
            }
          }
        }
      }]
    },
  }
}

export const optionsPie = (handleClick) => {
  return {
    legend: {
      display: false
    },
    rotation: Math.PI,
    tooltips: {
      enabled: false
    },
    plugins: {
      datalabels: {
        font: {
          family: '"Source Sans Pro", sans-serif',
          size: 14,
          weight: '600'
        },
        formatter: function(value) {
          return `${value} %`
        }
      }
    },
    onClick: handleClick
  }
}

export const optionsAltChart = () => {
  return {
    legend: {
      display: false
    },
    cornerRadius: 8,
    responsive: true,
    tooltips: {
      enabled: true,
      callbacks: {
        label: function() {
          return false;
        },
        footer: function(tooltipItems, data) {
          let footer;
          tooltipItems.forEach(function(tooltipItem) {
            footer = data.datasets[tooltipItem.datasetIndex].footer;
          });
          return "Total fees: $" + footer;
        }
      }
    },
    scales: {
      yAxes:[{
        gridLines: {
          display:true,
          borderDash: ['5', '7'],
          zeroLineColor: '#E8E8E8',
          drawBorder: false,
        },
        ticks: {
          display: true,
          fontColor: '#000',
          maxTicksLimit: 8,
          fontFamily: '"Source Sans Pro", sans-serif',
          fontSize: 13,
          callback: function(value) {
            return `${value.toFixed(2)} %`
          }
        }
      }],
      xAxes:[{
        gridLines: {
          display: false
        },
        ticks: {
          display: false
        }
      }]
    },

    plugins: {
      datalabels: {
        display: false
      }
      // datalabels: {
      //   font: {
      //     family: '"Source Sans Pro", sans-serif',
      //     size: 14,
      //     weight: '600'
      //   },
      //   formatter: function(value) {
      //     return `${value} %`
      //   }
      // }
    },
  }
}

const wordWrap = (str, maxWidth) => {
  let newLineStr = '\n'
  let res = ''

  while (str.length > maxWidth) {
    let found = false
    // Inserts new line at first whitespace of the line
    for (let i = maxWidth - 1; i >= 0; i--) {
      if (testWhite(str.charAt(i))) {
        res = res + [str.slice(0, i), newLineStr].join('')
        str = str.slice(i + 1);
        found = true
        break
      }
    }
    // Inserts new line at maxWidth position, the word is too long to wrap
    if (!found) {
      res += [str.slice(0, maxWidth), newLineStr].join('')
      str = str.slice(maxWidth)
    }
  }

  return (res + str).split('\n');
}

const testWhite = (x) => {
  let white = new RegExp(/^\s$/)
  return white.test(x.charAt(0))
};