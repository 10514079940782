const adminNav = [
  {
    title: 'Budget Assumptions',
    name: 'budget',
    slug: 'budget',
  },
  {
    title: 'Property insurance assumptions',
    name: 'property',
    slug: 'property',
  },
  {
    title: 'Auto insurance assumptions',
    name: 'auto',
    slug: 'auto',
  },
  {
    title: 'Umbrella insurance assumptions',
    name: 'umbrella',
    slug: 'umbrella',
  },
  {
    title: 'Retirement assumptions',
    name: 'retirement',
    slug: 'retirement',
  },
  {
    title: 'Retirement funding assumptions',
    name: 'retirementFunding',
    slug: 'retirement-funding',
  },
  {
    title: 'Education funding assumptions',
    name: 'education',
    slug: 'education-funding',
  },
  {
    title: 'Net worth assumptions',
    name: 'netWorth',
    slug: 'net-worth',
  },
  {
    title: 'Estate planning assumptions',
    name: 'estate',
    slug: 'estate-planning',
  },
  {
    title: 'Additional assumptions & seasonal alerts',
    name: 'additional',
    slug: 'additional',
  },
]

export default adminNav;

