import React, {useEffect} from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { BackButton } from '../../../components/shared/Buttons'
import Layout from '../../../components/Layout/Layout'
import LayoutForm from '../../../components/Layout/LayoutForm'
import PageTitle from '../../../components/PageTitle/PageTitle'
import Illustration from '../../../assets/images/illustrations/Tree-segment_future-min.png'
import { Button, FormControlLabel, Radio, RadioGroup, TextareaAutosize } from '@mui/material'
import { FormGroup, Input, InputPercent } from '../../../components/shared/Form'
import { fetchEmploymentSabbatical, postEmploymentSabbatical } from '../../../redux/slices/futureSlice'
import { normalizeBoolean, getPercent } from '../../../utils/utils'
import { sabbaticalSchema } from '../../../utils/validationFuture'
import { fetchPercentages } from '../../../redux/slices/percentagesSlice'
import Modal from '../../../components/shared/Modal/Modal'
import ProgressCircle from '../../../components/shared/ProgressBar/ProgressCircle'
import { setIsOpen } from '../../../redux/slices/modalSlice'
import isNull from 'lodash/isNull'


const SpouseData = {
  title: 'Your spouse`s employment sabbatical',
  content: 'If your spouse is planning to take time off from work, then enter the relevant info in the fields below',
  info: <>
    <h6>Your Spouse's Employment Sabbatical</h6>
    <div>If your spouse is planning to take time off from work, then enter the relevant information in the fields below.</div>
    <hr className="tooltip__line"/>
    <h6>Example</h6>
    <div>Say your spouse is planning to take 3 years off from his/her job to spend time with your children.  In that case, enter the details of your spouse's employment sabbatical below.</div>
  </>,
  planning_sabbatical: {
    label: 'Is your spouse planning a sabbatical?',
    hint: 'Say your spouse is planning to take 3 years off from his/her job to spend time with your children.  In that case, enter the details of your spouse`s employment sabbatical below.'
  },
  age_sabbatical: {
    label: 'Your age when sabbatical occurs',
  },
  sabbatical_last: {
    label: 'How many years will your spouse`s sabbatical last?',
    hint: 'Enter the number of years your spouse plans to take time off from work.'
  },
  salary_percentage: {
    label: 'What percentage of your spouse`s current salary will your spouse forego during the sabbatical?',
  },
  workforce_percent: {
    label: 'Approximately what % of your spouse`s current salary will your spouse return to workforce making?',
    hint: <>
      <div>Often, when a person leaves the workforce for reasons beyond education or professional improvements, it may impact their ability to command the same salary when they return to the workforce after time off.</div>
      <hr className="tooltip__line"/>
      <h6>Example</h6>
      <div>Say you and your spouse both work, but your spouse plans to leave a $100,000 job to stay at home and raise the kids in a year and plans to take 3 years off from work. After that third year, say your spouse returns to work making 75% of the salary he/she was making before he/she left, which would turn out to be $75,000.  Then enter 75% in this field.</div>
    </>
  },
  current_refund: {
    label: 'Approximately what % of your current yearly tax payment/ refund is due to your spouse`s wage structure?',
    hint: <>
      <div>Because certain tax systems, such as the US tax system, are based on a graduated income scale, certain incomes above a threshold push you into a much higher income tax bracket.  As a result, time off from work can push you back down into a lower tax bracket, and help offset some of the income loss attributable to a work sabbatical.</div>
      <hr className="tooltip__line"/>
      <h6>Example</h6>
      <div>Say you and your spouse both work, but your spouse plan to leave a $100,000 job to stay at home and raise the kids in a year and plan to take 3 years off from work.  Say you file taxes under the 'married filing jointly' category.  Your wage pushed you both into a higher income tax bracket and as a result you had to pay $10,000 in income taxes that you would otherwise not need to pay if you were not working.  In this case, enter $10,000 in this field.</div>
    </>
  }
}

const Data = {
  title: 'Your employment sabbatical',
  content: 'If you are planning to take time off from work, then enter the relevant info in the fields below',
  info: <>
    <h6>Your Employment Sabbatical</h6>
    <div>If you are planning to take time off from work, then enter the relevant information in the fields below.</div>
    <hr className="tooltip__line"/>
    <h6>Example</h6>
    <div>Say you are planning to take 3 years off from your job to spend time with your children.  In that case, enter the details of your employment sabbatical below.</div>
  </>,
  planning_sabbatical: {
    label: 'Are you planning a sabbatical?',
    hint: 'Say you are planning to take 3 years off from your job to spend time with your children.  In that case, enter the details of your employment sabbatical below.'
  },
  age_sabbatical: {
    label: 'Your age when sabbatical occurs:',
  },
  sabbatical_last: {
    label: 'How many years will sabbatical last?',
    hint: 'Enter the number of years you plan to take time off from work.'
  },
  salary_percentage: {
    label: 'What percentage of your current salary will you forego during the sabbatical?',
  },
  workforce_percent: {
    label: 'Approximately what % of your current salary will you return to workforce making?',
    hint: <>
      <div>Often, when a person leaves the workforce for reasons beyond education or professional improvements, it may impact their ability to command the same salary when they return to the workforce after time off.</div>
      <hr className="tooltip__line"/>
      <h6>Example</h6>
      <div>Say you and your spouse both work, but you plan to leave a $100,000 job to stay at home and raise the kids in a year and plan to take 3 years off from work. After that third year, say you return to work making 75% of the salary you were making before you left, which would turn out to be $75,000.  Then enter 75% in this field.</div>
    </>
  },
  current_refund: {
    label: 'Approximately what % of your current yearly tax payment/ refund is due to your wage structure?',
    hint: <>
      <div>Because certain tax systems, such as the US tax system, are based on a graduated income scale, certain incomes above a threshold push you into a much higher income tax bracket.  As a result, time off from work can push you back down into a lower tax bracket, and help offset some of the income loss attributable to a work sabbatical.</div>
      <hr className="tooltip__line"/>
      <h6>Example</h6>
      <div>Say you and your spouse both work, but you plan to leave a $100,000 job to stay at home and raise the kids in a year and plan to take 3 years off from work.  Say you file taxes under the 'married filing jointly' category.  Your wage pushed you both into a higher income tax bracket and as a result you had to pay $10,000 in income taxes that you would otherwise not need to pay if you were not working.  In this case, enter $10,000 in this field.</div>
    </>
  }
}

const SabbaticalDetails = ({ props, isSpouse }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userId = useSelector(state => state.user.user.id);
  const sending = useSelector(state => state.sending.isSending);
  const { user, spouse } = useSelector(state => state.future.sabbatical);
  const percents = useSelector(state => state.percentages.percents);
  const isOpen = useSelector(state => state.modal.isOpen);

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchPercentages());
      await dispatch(fetchEmploymentSabbatical(isSpouse));
    }

    if (userId) {
      fetch()
    }

    // eslint-disable-next-line
  }, [userId, isSpouse])

  const sabbaticalYour = getPercent(percents, 'employment-sabbatical')
  const sabbaticalSpouse = getPercent(percents, 'spouse-employment-sabbatical')

  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }))
  }

  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }))
    history.push('/sabbatical')
  }

  const handleChangeInput = (e, setValue) => {
    if([null, false].includes(e.target.value)) return false

    setValue(e.target.name, e.target.value)
  }

  return (
    <Layout>
      <LayoutForm small illustration={Illustration}>
        <BackButton title="Back to Sabbatical" />
        <PageTitle
          title={isSpouse ? SpouseData.title : Data.title}
          content={isSpouse ? SpouseData.content : Data.content}
          status={isSpouse ? sabbaticalSpouse : sabbaticalYour}
          info={isSpouse ? SpouseData.info : Data.info}
        />
        <div className="layout-form__content-holder">
          <Formik
            enableReinitialize
            validationSchema={sabbaticalSchema}
            initialValues={isSpouse ? {...spouse} : {...user}}
            onSubmit={(values) => dispatch(postEmploymentSabbatical(values, props, isSpouse))}
          >
            {({ errors, handleChange, handleBlur, values, touched, setFieldValue }) => (
              <Form>
                <FormGroup
                  name="planning_sabbatical"
                  labelText={isSpouse
                    ? SpouseData.planning_sabbatical.label
                    : Data.planning_sabbatical.label }
                >
                  <RadioGroup
                    name="planning_sabbatical"
                    value={values.planning_sabbatical || false}
                    onChange={(e) => {
                      setFieldValue('planning_sabbatical', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                  {errors.planning_sabbatical && <span className="form__msg-error">{errors.planning_sabbatical}</span>}
                </FormGroup>
                <FormGroup
                  name="age_sabbatical"
                  labelText={isSpouse
                    ? SpouseData.age_sabbatical.label
                    : Data.age_sabbatical.label }
                >
                  <Input
                    placeholder="Type your age when sabbatical occurs"
                    name="age_sabbatical"
                    type="number"
                    onChange={handleChange}
                    value={values.age_sabbatical || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.age_sabbatical}
                  />
                  {errors.age_sabbatical && <span className="form__msg-error">{errors.age_sabbatical}</span>}
                </FormGroup>
                <FormGroup
                  name="sabbatical_last"
                  labelText={isSpouse
                    ? SpouseData.sabbatical_last.label
                    : Data.sabbatical_last.label }
                >
                  <Input
                    placeholder="Type your years will sabbatical last?"
                    name="sabbatical_last"
                    type="number"
                    onChange={e => handleChangeInput(e, setFieldValue)}
                    value={!isNull(values.sabbatical_last) ? values.sabbatical_last : ''}
                    onBlur={handleBlur}
                    hasError={!!errors.sabbatical_last}
                    info={isSpouse
                      ? {content: SpouseData.sabbatical_last.hint}
                      : {content: Data.sabbatical_last.hint}
                    }
                  />
                  {errors.sabbatical_last && <span className="form__msg-error">{errors.sabbatical_last}</span>}
                </FormGroup>
                <FormGroup
                  name="salary_percentage"
                  labelText={isSpouse
                    ? SpouseData.salary_percentage.label
                    : Data.salary_percentage.label }
                >
                  <InputPercent
                    placeholder="Type percentage"
                    name="salary_percentage"
                    onChange={handleChange}
                    value={values.salary_percentage || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.salary_percentage}
                  />
                  {errors.salary_percentage && <span className="form__msg-error">{errors.salary_percentage}</span>}
                </FormGroup>
                <FormGroup
                  name="workforce_percent"
                  labelText={isSpouse
                    ? SpouseData.workforce_percent.label
                    : Data.workforce_percent.label }
                  info={isSpouse
                    ? {content: SpouseData.workforce_percent.hint}
                    : {content: Data.workforce_percent.hint}
                  }
                >
                  <InputPercent
                    placeholder="Type percentage"
                    name="workforce_percent"
                    onChange={handleChange}
                    value={values.workforce_percent || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.workforce_percent}
                  />
                  {errors.workforce_percent && <span className="form__msg-error">{errors.workforce_percent}</span>}
                </FormGroup>
                <FormGroup
                  name="current_refund"
                  labelText={isSpouse
                    ? SpouseData.current_refund.label
                    : Data.current_refund.label }
                  info={isSpouse
                    ? {content: SpouseData.current_refund.hint}
                    : {content: Data.current_refund.hint}
                  }
                >
                  <InputPercent
                    placeholder="Type percentage"
                    name="current_refund"
                    onChange={handleChange}
                    value={values.current_refund || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.current_refund}
                  />
                  {errors.current_refund && <span className="form__msg-error">{errors.current_refund}</span>}
                </FormGroup>

                {isSpouse && <>
                  <h5 className="mt--medium">Additional info</h5>
                  <FormGroup name="additional_info" labelText="Is there any other Employment Sabbatical information that you believe is important for ValueSoft to know about? If so, please let us know about it:">
                    <TextareaAutosize
                      maxLength="200"
                      name="additional_info"
                      value={values.additional_info || ''}
                      className="form__textarea"
                      placeholder="Maximum characters: 200"
                      onChange={handleChange}
                    />
                  </FormGroup>
                </>}
                <div className="form__button">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Save
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <Modal
          open={isOpen}
          small
          button="Done"
          onClick={handleClick}
          close={onClose}
        >
          <h3>Good Job!</h3>
          <p>Your progress increased 100%</p>
          <ProgressCircle amount={100} small />
        </Modal>
      </LayoutForm>
    </Layout>
  );
};

export default SabbaticalDetails;
