import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import { useHistory } from 'react-router-dom'

const WidgetPlaceholder = ({ title, content, image, button }) => {
  const history = useHistory()
  return (
    <div className="widget-placeholder">
      {image &&
        <div className="widget-placeholder__image">
          <img src={image} alt={title} />
        </div>}
      <div className="widget-placeholder__content">
        <h5>{title}</h5>
        <div>{content}</div>
        {button && (
          <Button variant="contained" size="medium" color="primary" onClick={() => history.push(button.href)}>
            {button.title}
          </Button>
        )}
      </div>
    </div>
  );
};

WidgetPlaceholder.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  button: PropTypes.object
};

export default WidgetPlaceholder;
