import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import SVGIcon from '../shared/SVGIcon/SVGIcon'
import Bookmark from '../../assets/images/bookmark.svg'
import Speedometer from '../shared/Speedometer/SpeedometerMultiLimit'
import Tooltip from '../../components/shared/Tooltip/Tooltip'

const TaskAnalysisCard = ({ path, title, tasks, count, limits, tooltip }) => {
  const [onHover, setOnHover] = useState(false);

  return (
    <>
      {path
        ? <Link to={path} className="task-analysis-card task-analysis-card--hover">
            <div className="task-analysis-card__content">
              <h5 className={`${tooltip ? 'tooltip-align' : ''}`}>{title}
              {tooltip && (<Tooltip
                onMouseEnter={()=> setOnHover(true)}
                onMouseLeave={() => setOnHover(false)}
                isHover={onHover}
                title={
                  <>
                    <h6>{title}</h6>
                    Aging tasks are open or on hold tasks that are greater than a month old.
                  </>
                }
              />)}
              </h5>
              <div className="task-analysis-card__mark">
                <SVGIcon icon={Bookmark} />
                {tasks && <span>{tasks} task{tasks > 1 && 's'}</span>}
              </div>
            </div>
            <Speedometer count={tasks || '0'} limits={limits} />
          </Link>
        : <div className="task-analysis-card">
            <div className="task-analysis-card__content">
            <h5 className={`${tooltip ? 'tooltip-align' : ''}`}>{title}
              {tooltip && (<Tooltip
                title={
                  <>
                    <h6>{title}</h6>
                    Aging tasks are open or on hold tasks that are greater than a month old.
                  </>
                }
              />)}
              </h5>
              <div className="task-analysis-card__mark">
                <SVGIcon icon={Bookmark} />
                {tasks && <span>{tasks} task{tasks > 1 && 's'}</span>}
              </div>
            </div>
            <Speedometer count={tasks || '0'} limits={limits} />
          </div>
      }
    </>
  );
};

export default TaskAnalysisCard;
