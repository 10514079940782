const adminCalcNav = [
  {
    title: 'Impact of Income and Expenses on Net Worth',
    name: 'income-expenses-net-worth'
  },
  {
    title: 'Own vs Rent Home',
    name: 'own-vs-rent'
  },
  {
    title: 'Mortgage',
    name: 'mortgage'
  },
  {
    title: 'New Car Lease vs Lease-End Purchase',
    name: 'car-lease-vs-lease-end'
  },
  {
    title: 'Car Lease Payment',
    name: 'car-lease-payment'
  },
  {
    title: 'Life Insurance',
    name: 'life-insurance'
  },
  {
    title: 'Time to Buy Home',
    name: 'time-name-buy-home'
  },
  {
    title: 'Home Affordability',
    name: 'home-affordability'
  },
  {
    title: 'Home Evaluation',
    name: 'home-evaluation'
  },
  {
    title: 'Value Circle',
    name: 'value-circle'
  },
  {
    title: 'Investment Fees',
    name: 'investment-fees'
  },
  {
    title: 'Umbrella Insurance',
    name: 'umbrella-insurance'
  },
  {
    title: 'Home Refinancing',
    name: 'home-refinancing'
  },
  {
    title: 'Healthcare Coverage Type',
    name: 'healthcare-coverage'
  }
]

export default adminCalcNav;