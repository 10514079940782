import React from 'react'
import { Form, Formik } from 'formik'
import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { FormGroup, Input, InputCurrency } from '../shared/Form'
import { normalizeBoolean } from '../../utils/utils'
import Percentage from '../../assets/images/icons/percentage.svg'

const MortgageAdmin = ({ title, onSave, values, sending }) => {
  return (
    <Formik enableReinitialize initialValues={{ ...values }} onSubmit={(values) => onSave(values)}>
      {({ handleChange, handleBlur, values, setFieldValue }) => (
        <Form>
          <h4 className="form__main-title">{title}</h4>
          <div className="form__flow-wrap form__flow-wrap--column mortgage-h">
            <FormGroup name="current_age" labelText="Current age">
              <Input
                name="current_age"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.current_age || 0}
              />
            </FormGroup>
            <FormGroup name="age_upon_home_purchase" labelText="Your age upon home purchase">
              <Input
                name="age_upon_home_purchase"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.age_upon_home_purchase || 0}
              />
            </FormGroup>
            <FormGroup name="home_value" labelText="Home value (Today’s $)">
              <InputCurrency
                name="home_value"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.home_value || 0}
                withoutSign
                icon
              />
            </FormGroup>
            <FormGroup name="down_payment" labelText="Downpayment">
              <Input
                name="down_payment"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.down_payment || 0}
                iconName={Percentage}
              />
            </FormGroup>
            <FormGroup name="years_financed" labelText="Years Financed">
              <Input
                name="years_financed"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.years_financed || 0}
              />
            </FormGroup>
            <FormGroup name="finance_rate" labelText="Financing Rate">
              <Input
                name="finance_rate"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.finance_rate || 0}
                iconName={Percentage}
              />
            </FormGroup>
            <FormGroup name="income_tax_bracket" labelText="Income tax bracket">
              <Input
                name="income_tax_bracket"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.income_tax_bracket || 0}
                iconName={Percentage}
              />
            </FormGroup>
            <FormGroup name="points_down" labelText="Points down">
              <Input
                name="points_down"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.points_down || 0}
              />
            </FormGroup>
            <FormGroup name="closing_costs" labelText="Closing costs">
              <Input
                name="closing_costs"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.closing_costs || 0}
                iconName={Percentage}
              />
            </FormGroup>
            <FormGroup
              name="points_closing_added_to_mortgage"
              labelText="Points & closing costs added to mortgage amount"
            >
              <InputCurrency
                name="points_closing_added_to_mortgage"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.points_closing_added_to_mortgage || 0}
                withoutSign
                icon
              />
            </FormGroup>
            <FormGroup name="include_pmi" labelText="Include PMI in Calculations?">
              <RadioGroup
                name="include_pmi"
                value={values.include_pmi || false}
                onChange={(e) => {
                  setFieldValue('include_pmi', normalizeBoolean(e.target.value));
                }}
                className="radio-list-inline"
              >
                <FormControlLabel value={false} control={<Radio />} label="No" />
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
              </RadioGroup>
            </FormGroup>
            <FormGroup name="pmi_value" labelText="PMI value">
              <Input
                name="pmi_value"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.include_pmi ? values.pmi_value : 0}
                iconName={Percentage}
                disabled={!values.include_pmi}
              />
            </FormGroup>
            <FormGroup name="override_default_pmi_estimate" labelText="Override Default PMI Estimate?">
              <RadioGroup
                name="override_default_pmi_estimate"
                value={values.override_default_pmi_estimate || false}
                onChange={(e) => {
                  setFieldValue('override_default_pmi_estimate', normalizeBoolean(e.target.value));
                }}
                className="radio-list-inline"
              >
                <FormControlLabel value={false} control={<Radio />} label="No" />
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
              </RadioGroup>
            </FormGroup>
            <FormGroup name="pmi_estimate_value" labelText="Default PMI Estimate value">
              <Input
                name="pmi_estimate_value"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.override_default_pmi_estimate ? values.pmi_estimate_value : 0}
                iconName={Percentage}
                disabled={!values.override_default_pmi_estimate}
              />
            </FormGroup>
            <FormGroup name="property_tax_rate" labelText="Property tax rate">
              <Input
                name="property_tax_rate"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.property_tax_rate || 0}
              />
            </FormGroup>
            <FormGroup name="insurance_amount_in_year" labelText="Insurance amount/year">
              <InputCurrency
                name="insurance_amount_in_year"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.insurance_amount_in_year || 0}
                withoutSign
                icon
              />
            </FormGroup>
            <FormGroup name="utility_amount_in_month" labelText="Utility amount/month">
              <InputCurrency
                name="utility_amount_in_month"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.utility_amount_in_month || 0}
                withoutSign
                icon
              />
            </FormGroup>
            <FormGroup name="annual_home_maintenance_expenses" labelText="Annual home maintenance expenses">
              <InputCurrency
                name="annual_home_maintenance_expenses"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.annual_home_maintenance_expenses || 0}
                withoutSign
                icon
              />
            </FormGroup>
          </div>
          <div className="form__button">
            <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
              Save
              {sending && <span className="spinner" />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default MortgageAdmin
