import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../../components/Layout/Layout'
import { BackButton } from '../../components/shared/Buttons'
import Tooltip from '../../components/shared/Tooltip/Tooltip'
import { Slider, Input, FormGroup, FormSelect } from '../../components/shared/Form'
import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import ModalBox from '../../components/shared/Modal/Modal'
import {
  fetchCalculators,
  fetchListResults,
  fetchCurrentResult,
  saveItemResult,
  removeItemResult, setCurrentCalculator
} from '../../redux/slices/calculatorSlice'
import Tabs, { TabPanel } from '../../components/shared/Tabs/Tabs'
import { fetchValuesData } from '../../redux/slices/optionsSlice'
import SpeedometerLimit from '../../components/shared/Speedometer/SpeedometerLimit'
import { fetchValueCircleAdmin } from '../../redux/slices/calculatorsSetupSlice'

const goalsAndDreamsFields = [
  {
    name: '',
    value_id: ''
  },
  {
    name: '',
    value_id: ''
  },
  {
    name: '',
    value_id: ''
  },
  {
    name: '',
    value_id: ''
  },
  {
    name: '',
    value_id: ''
  }
];


const ValueCircle = ({ props }) => {
  const dispatch = useDispatch()
  const [tabActive, setActiveTab] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [isSavedModal, setIsSavedModal] = useState(false)
  const { id, company_id } = useSelector(state => state.user.user)
  const { calculators, current, listExisting, loading } = useSelector(state => state.calculator)
  const baseValues = useSelector(state => state.calculatorsSetup.valueCircleAdmin);
  const listValues = useSelector(state => state.options.values)
  const [currentId, setCurrentId] = useState('')
  const [activeSubTab, setActiveSubTab] = useState('me')
  const [values, setValues] = useState(null)

  useEffect(() => {
    if(baseValues){
      setValues({
        currentId: '',
        name: '',
        conduct_assessment: 'me',
        your_name: '',
        spouse_name: '',
        goals: goalsAndDreamsFields,
        spouse_goals: goalsAndDreamsFields,
        dreams: goalsAndDreamsFields,
        spouse_dreams: goalsAndDreamsFields,
        ...baseValues
      })
    }
  // eslint-disable-next-line
  }, [baseValues])


  const [countValues, setCountValues] = useState({
    totalRankCurrent: 0,
    totalRankPreferred: 0,
    totalRankCurrentSpouse: 0,
    totalRankPreferredSpouse: 0,

    currentToPreferred: 0,
    currentValuesGoals: 0,
    preferredValuesGoals: 0,

    currentToPreferredSpouse: 0,
    currentValuesGoalsSpouse: 0,
    preferredValuesGoalsSpouse: 0,

    currentValuesDreams: 0,
    preferredValuesDreams: 0,
    currentValuesDreamsSpouse: 0,
    preferredValuesDreamsSpouse: 0,
    dreamsCouple: 0,

    currentValuesCouple: 0,
    preferredValuesCouple: 0,
    goalsCouple: 0
  })

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchValueCircleAdmin(company_id))
      await dispatch(fetchCalculators({path: 'value-circle'}));
      await dispatch(fetchListResults('value-circle'));
      await dispatch(fetchValuesData());
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const mappingArrayData = (data, arrayMap, withOption) => {
    return data.map((item, i) =>
      item.value_id === 1
        ? Math.abs(arrayMap[0] - (withOption ? (i + 1) : 0))
        : item.value_id === 2
        ? Math.abs(arrayMap[1] - (withOption ? (i + 1) : 0))
        : item.value_id === 3
          ? Math.abs(arrayMap[2] - (withOption ? (i + 1) : 0))
          : item.value_id === 4
            ? Math.abs(arrayMap[3] - (withOption ? (i + 1) : 0))
            : item.value_id === 5
              ? Math.abs(arrayMap[4] - (withOption ? (i + 1) : 0))
              : item.value_id === 6
                ? Math.abs(arrayMap[5]- (withOption ? (i + 1) : 0))
                : null)
  }

  const diffArray = (array, array2) => {
    if(array.includes(null) || array2.includes(null)) {
      return 0
    }
    return array.map((item, i) => Math.abs(item - array2[i]))
  }

  useEffect(() => {
    if (values) {
      const arr1 = [values.security, values.obligation, values.power, values.happiness, values.health, values.making_difference]
    const arr2 = [values.preferred_security, values.preferred_obligation, values.preferred_power, values.preferred_happiness, values.preferred_health, values.preferred_making_difference]
    const arr3 = [values.spouse_security, values.spouse_obligation, values.spouse_power, values.spouse_happiness, values.spouse_health, values.spouse_making_difference]
    const arr4 = [values.spouse_preferred_security, values.spouse_preferred_obligation, values.spouse_preferred_power, values.spouse_preferred_happiness, values.spouse_preferred_health, values.spouse_preferred_making_difference]

    const totalRankCurrent = +values.security + +values.obligation + +values.power + +values.happiness + +values.health + +values.making_difference
    const totalRankCurrentSpouse = +values.spouse_security + +values.spouse_obligation + +values.spouse_power + +values.spouse_happiness + +values.spouse_health + +values.spouse_making_difference
    const totalRankPreferred = +values.preferred_security + +values.preferred_obligation + +values.preferred_power + +values.preferred_happiness + +values.preferred_health + +values.preferred_making_difference
    const totalRankPreferredSpouse = +values.spouse_preferred_security + +values.spouse_preferred_obligation + +values.spouse_preferred_power + +values.spouse_preferred_happiness + +values.spouse_preferred_health + +values.spouse_preferred_making_difference

    const countCurrent = (+values.security !== 0 ? 1 : 0) + (+values.obligation !== 0 ? 1 : 0) + (+values.power !== 0 ? 1 : 0) + (+values.happiness !== 0 ? 1 : 0) + (+values.health !== 0 ? 1 : 0) + (+values.making_difference !== 0 ? 1 : 0)
    const countCurrentSpouse = (+values.spouse_security !== 0 ? 1 : 0) + (+values.spouse_obligation !== 0 ? 1 : 0) + (+values.spouse_power !== 0 ? 1 : 0) + (+values.spouse_happiness !== 0 ? 1 : 0) + (+values.spouse_health !== 0 ? 1 : 0) + (+values.spouse_making_difference !== 0 ? 1 : 0)

    const currentToPreferred = 1 - (Math.abs(+values.security - +values.preferred_security)
                                 + Math.abs(+values.obligation - +values.preferred_obligation)
                                 + Math.abs(+values.power - +values.preferred_power)
                                 + Math.abs(+values.happiness - +values.preferred_happiness)
                                 + Math.abs(+values.health - +values.preferred_health)
                                 + Math.abs(+values.making_difference - +values.preferred_making_difference))/Math.pow(countCurrent, 2)

    const currentToPreferredSpouse = 1 - (Math.abs(+values.spouse_security - +values.spouse_preferred_security)
                                       + Math.abs(+values.spouse_obligation - +values.spouse_preferred_obligation)
                                       + Math.abs(+values.spouse_power - +values.spouse_preferred_power)
                                       + Math.abs(+values.spouse_happiness - +values.spouse_preferred_happiness)
                                       + Math.abs(+values.spouse_health - +values.spouse_preferred_health)
                                       + Math.abs(+values.spouse_making_difference - +values.spouse_preferred_making_difference))/Math.pow(countCurrentSpouse, 2)

    const currentValuesCouple = 1 - (Math.abs(+values.security - +values.spouse_security)
                                    + Math.abs(+values.obligation - +values.spouse_obligation)
                                    + Math.abs(+values.power - +values.spouse_power)
                                    + Math.abs(+values.happiness - +values.spouse_happiness)
                                    + Math.abs(+values.health - +values.spouse_health)
                                    + Math.abs(+values.making_difference - +values.spouse_making_difference))/Math.pow(countCurrent, 2)

    const preferredValuesCouple = 1 - (Math.abs(+values.preferred_security - +values.spouse_preferred_security)
                                    + Math.abs(+values.preferred_obligation - +values.spouse_preferred_obligation)
                                    + Math.abs(+values.preferred_power - +values.spouse_preferred_power)
                                    + Math.abs(+values.preferred_happiness - +values.spouse_preferred_happiness)
                                    + Math.abs(+values.preferred_health - +values.spouse_preferred_health)
                                    + Math.abs(+values.preferred_making_difference - +values.spouse_preferred_making_difference))/Math.pow(countCurrent, 2)


    const arrayCurrent            = mappingArrayData(values.goals, arr1, true)
    const arrayPreferred          = mappingArrayData(values.goals, arr2, true)
    const arrayCurrentSpouse      = mappingArrayData(values.spouse_goals, arr3, true)
    const arrayPreferredSpouse    = mappingArrayData(values.spouse_goals, arr4, true)
    const arrayCurrentAlt         = mappingArrayData(values.goals, arr1)
    const arrayCurrentSpouseAlt   = mappingArrayData(values.spouse_goals, arr3)
    const diffArrayCouple         = diffArray(arrayCurrentAlt, arrayCurrentSpouseAlt)

    const arrayCurrentDreams            = mappingArrayData(values.dreams, arr1, true)
    const arrayPreferredDreams          = mappingArrayData(values.dreams, arr2, true)
    const arrayCurrentSpouseDreams      = mappingArrayData(values.spouse_dreams, arr3, true)
    const arrayPreferredSpouseDreams    = mappingArrayData(values.spouse_dreams, arr4, true)
    const arrayCurrentAltDreams         = mappingArrayData(values.dreams, arr1)
    const arrayCurrentSpouseAltDreams   = mappingArrayData(values.spouse_dreams, arr3)
    const diffArrayCoupleDreams         = diffArray(arrayCurrentAltDreams, arrayCurrentSpouseAltDreams)

    const currentValuesGoals         = arrayCurrent.includes(null) ? 0 : 1 - arrayCurrent.reduce((a, b) => (b === null) ? a + 5 : a + b, 0)/Math.pow(5,2)
    const preferredValuesGoals       = arrayPreferred.includes(null) ? 0 : 1 - arrayPreferred.reduce((a, b) => (b === null) ? a + 5 : a + b, 0)/Math.pow(5,2)
    const currentValuesGoalsSpouse   = arrayCurrentSpouse.includes(null) ? 0 : 1 - arrayCurrentSpouse.reduce((a, b) => (b === null) ? a + 5 : a + b, 0)/Math.pow(5,2)
    const preferredValuesGoalsSpouse = arrayPreferredSpouse.includes(null) ? 0 : 1 - arrayPreferredSpouse.reduce((a, b) => (b === null) ? a + 5 : a + b, 0)/Math.pow(5,2)

    const currentValuesDreams        = arrayCurrentDreams.includes(null) ? 0 : 1 - arrayCurrentDreams.reduce((a, b) => (b === null) ? a + 5 : a + b, 0)/Math.pow(5,2)
    const preferredValuesDreams      = arrayPreferredDreams.includes(null) ? 0 : 1 - arrayPreferredDreams.reduce((a, b) => (b === null) ? a + 5 : a + b, 0)/Math.pow(5,2)
    const currentValuesDreamsSpouse   = arrayCurrentSpouseDreams.includes(null) ? 0 : 1 - arrayCurrentSpouseDreams.reduce((a, b) => (b === null) ? a + 5 : a + b, 0)/Math.pow(5,2)
    const preferredValuesDreamsSpouse = arrayPreferredSpouseDreams.includes(null) ? 0 : 1 - arrayPreferredSpouseDreams.reduce((a, b) => (b === null) ? a + 5 : a + b, 0)/Math.pow(5,2)

    const goalsCouple = diffArrayCouple === 0 ? 0 : 1 - (diffArrayCouple.reduce((a, b) => a + b, 0))/Math.pow(5, 2)
    const dreamsCouple = diffArrayCoupleDreams === 0 ? 0 : 1 - (diffArrayCoupleDreams.reduce((a, b) => a + b, 0))/Math.pow(5, 2)

    setCountValues({
      totalRankCurrent,
      totalRankPreferred,
      totalRankCurrentSpouse,
      totalRankPreferredSpouse,

      currentToPreferred: currentToPreferred.toFixed(2) * 100,
      currentToPreferredSpouse: currentToPreferredSpouse.toFixed(2) * 100,
      currentValuesCouple: currentValuesCouple.toFixed(2) * 100,
      preferredValuesCouple: preferredValuesCouple.toFixed(2) * 100,

      currentValuesGoals: currentValuesGoals.toFixed(2) * 100,
      preferredValuesGoals: preferredValuesGoals.toFixed(2) * 100,
      currentValuesGoalsSpouse: currentValuesGoalsSpouse.toFixed(2) * 100,
      preferredValuesGoalsSpouse: preferredValuesGoalsSpouse.toFixed(2) * 100,
      goalsCouple: goalsCouple.toFixed(2) * 100,

      currentValuesDreams: currentValuesDreams.toFixed(2) * 100,
      preferredValuesDreams: preferredValuesDreams.toFixed(2) * 100,
      currentValuesDreamsSpouse: currentValuesDreamsSpouse.toFixed(2) * 100,
      preferredValuesDreamsSpouse: preferredValuesDreamsSpouse.toFixed(2) * 100,
      dreamsCouple: dreamsCouple.toFixed(2) * 100
    })
    }

    // eslint-disable-next-line
  }, [values])

  useEffect(() => {
    async function fetchNewData() {
      if (current) {
        setValues({
          name: current ? current.name : '',
          currentId: currentId,
          conduct_assessment: current.conduct_assessment,
          your_name: current.your_name,
          spouse_name: current.spouse_name,
          happiness: +current.happiness,
          health: +current.health,
          making_difference: +current.making_difference,
          obligation: +current.obligation,
          power: +current.power,
          security: +current.security,
          preferred_security: +current.preferred_security,
          preferred_obligation: +current.preferred_obligation,
          preferred_power: +current.preferred_power,
          preferred_happiness: +current.preferred_happiness,
          preferred_health: +current.preferred_health,
          preferred_making_difference: +current.preferred_making_difference,
          spouse_happiness: +current.spouse_happiness,
          spouse_health: +current.spouse_health,
          spouse_making_difference: +current.spouse_making_difference,
          spouse_obligation: +current.spouse_obligation,
          spouse_power: +current.spouse_power,
          spouse_security: +current.spouse_security,
          spouse_preferred_security: +current.spouse_preferred_security,
          spouse_preferred_obligation: +current.spouse_preferred_obligation,
          spouse_preferred_power: +current.spouse_preferred_power,
          spouse_preferred_happiness: +current.spouse_preferred_happiness,
          spouse_preferred_health: +current.spouse_preferred_health,
          spouse_preferred_making_difference: +current.spouse_preferred_making_difference,
          goals: current.goals,
          spouse_goals: current.spouse_goals,
          dreams: current.dreams,
          spouse_dreams: current.spouse_dreams
        })
      }
    }

    if (currentId) {
      fetchNewData()
    }
    // eslint-disable-next-line
  }, [currentId, current])



  const handleInputChange = e => {
    setValues({
      ...values,
      [e.target.name]: +e.target.value
    })
  }

  const changeCurrentData = async e => {
    await setCurrentId(e.target.value)
    await dispatch(fetchCurrentResult(e.target.value, 'value-circle'))
  }

  const onSave = async () => {
    dispatch(saveItemResult(values, 'value-circle')).then(res => {
      const calculator = res?.data?.data;

      if(calculator) {
        setIsSavedModal(true);
        setCurrentId(calculator.id);
        dispatch(setCurrentCalculator(calculator));
      }
    }).finally(() => {
      setIsOpen(false);
    })
  }

  const resetForm = () => {
    setActiveTab(0)
    setActiveSubTab('me')
    setValues({
      currentId: '',
      name: '',
      conduct_assessment: 'me',
      your_name: '',
      spouse_name: '',
      goals: goalsAndDreamsFields,
      spouse_goals: goalsAndDreamsFields,
      dreams: goalsAndDreamsFields,
      spouse_dreams: goalsAndDreamsFields,
      ...baseValues
    })
    setCurrentId('')
  }

  const removeResult = async () => {
    await dispatch(removeItemResult(currentId, 'value-circle'))
    await setCurrentId('')
    await setIsDeleteModal(false)
    await setActiveTab(0)
    await setActiveSubTab('me')
    await setValues({
      conduct_assessment: 'me',
      your_name: '',
      spouse_name: '',
      goals: goalsAndDreamsFields,
      spouse_goals: goalsAndDreamsFields,
      dreams: goalsAndDreamsFields,
      spouse_dreams: goalsAndDreamsFields,
      ...baseValues
    })
  }

  const handleArrayInput = (e, index, array) => {
    const { value, name } = e.target

    const newArray = values[array].map((x, i) =>
      i === index
        ? {...x, [name]: value}
        : x
    )

    setValues({
      ...values,
      [array]: [...newArray]
    })
  }

  return (
    <Layout decor>
      <BackButton title="Back to Calculators" path="/calculators" className="back-button--position" />
      <div className="calculators__title">
        <div>
          {calculators && <h4>{calculators.name}</h4>}
          {calculators &&
          <Tooltip
            title={
              <>
                <h6>{calculators.name}</h6>
                {calculators.tooltip}
                <hr className="tooltip__line"/>
                <h6>Example</h6>
                {calculators.example}
              </>
            }
          />}
        </div>

        {listExisting && listExisting.length > 0 && <div className="calculators__title-select">
          <FormSelect
            options={listExisting}
            id="currentId"
            value={currentId}
            medium
            name="currentId"
            onChange={changeCurrentData}
            placeholder="Select result"
          />
        </div>}
      </div>

      <Tabs
        handleChange={(event, newValue) => setActiveTab(newValue)}
        variant="scrollable"
        value={tabActive}
        tabs={[
          {title: 'Input profile'},
          {title: activeSubTab === 'spouse' ? 'Spouse`s Values' : 'Your Values'},
          {title: activeSubTab === 'spouse' ? 'Spouse`s Goals' : 'Your Goals'},
          {title: activeSubTab === 'spouse' ? 'Spouse`s Dreams' : 'Your Dreams'},
        ]}
      />
    {values &&  
      <div className="calculators__holder">
        <div className="calculators__content">
          <TabPanel value={tabActive} index={0}>
            <h6 className="calculators__subtitle">Step 1: Input profile</h6>
            <div className="calculators__area-label">
              Would you like to conduct a Value Circle Assessment for you, your spouse or both?
            </div>
            <FormGroup>
              <RadioGroup
                name="conduct_assessment"
                value={values.conduct_assessment || 'me'}
                onChange={e => {
                  setValues({
                    ...values,
                    [e.target.name]: e.target.value
                  })
                  setActiveSubTab(e.target.value)
                }}
                className="radio-list-inline"
              >
                <FormControlLabel value="me" control={<Radio/>} label="Me"/>
                <FormControlLabel value="spouse" control={<Radio/>} label="Spouse"/>
                <FormControlLabel value="both" control={<Radio/>} label="Both"/>
              </RadioGroup>
            </FormGroup>
            <FormGroup name="your_name" labelText="Your name">
              <Input
                disabled={values.conduct_assessment === 'spouse'}
                className={values.conduct_assessment !== 'spouse' ? 'form__input--focus' : ''}
                name="your_name"
                value={values.your_name || ''}
                onChange={e => setValues({
                  ...values,
                  [e.target.name]: e.target.value
                })}
              />
            </FormGroup>
            <FormGroup name="spouse_name" labelText="Spouse’s name">
              <Input
                disabled={values.conduct_assessment === 'me'}
                className={values.conduct_assessment !== 'me' ? 'form__input--focus' : ''}
                name="spouse_name"
                value={values.spouse_name || ''}
                onChange={e => setValues({
                  ...values,
                  [e.target.name]: e.target.value
                })}
              />
            </FormGroup>
          </TabPanel>
          <TabPanel value={tabActive} index={1}>
            <h6 className="calculators__subtitle">Step 2: Input values</h6>
            <p>Please provide the relative ranking (on a scale of 1-6, 1 being most important) of each of the values noted below. Please ensure each value has a unique ranking in your current and preferred sections. For example, security and obligation cannot have the same rank within the current ranking.</p>
            <h4>Current ranking</h4>
            {activeSubTab === 'spouse'
              ? countValues.totalRankCurrentSpouse < 6
                ? <div className="calculators__error">Please enter a ranking for each value.</div>
                : countValues.totalRankCurrentSpouse !== 21
                  ? <div className="calculators__error">Duplicate scores for values are prohibited.  Please provide unique scores for each value.</div>
                  : ''
              : countValues.totalRankCurrent < 6
                ? <div className="calculators__error">Please enter a ranking for each value.</div>
                : countValues.totalRankCurrent !== 21
                  ? <div className="calculators__error">Duplicate scores for values are prohibited.  Please provide unique scores for each value.</div>
                  : ''
            }
            <div className="calculators__area">
              <Input
                type="number"
                name={activeSubTab === 'spouse' ? 'spouse_security' : 'security'}
                value={activeSubTab === 'spouse' ? values.spouse_security.toString() : values.security.toString()}
                small
                smallHeight
                max={6}
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={6}
                  step={1}
                  label="Security"
                  value={activeSubTab === 'spouse' ? +values.spouse_security : +values.security}
                  onChange={(e, newValue) => activeSubTab === 'spouse'
                    ? setValues({...values, spouse_security: newValue})
                    : setValues({...values, security: newValue})
                  }
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name={activeSubTab === 'spouse' ? 'spouse_obligation' : 'obligation'}
                value={activeSubTab === 'spouse' ? values.spouse_obligation.toString() : values.obligation.toString()}
                small
                smallHeight
                max={6}
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={6}
                  step={1}
                  label="Obligation"
                  value={activeSubTab === 'spouse' ? +values.spouse_obligation : +values.obligation}
                  onChange={(e, newValue) => activeSubTab === 'spouse'
                    ? setValues({...values, spouse_obligation: newValue})
                    : setValues({...values, obligation: newValue})
                  }
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name={activeSubTab === 'spouse' ? 'spouse_power' : 'power'}
                value={activeSubTab === 'spouse' ? values.spouse_power.toString() : values.power.toString()}
                small
                smallHeight
                max={6}
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={6}
                  step={1}
                  label="Power/Prestige"
                  value={activeSubTab === 'spouse' ? +values.spouse_power : +values.power}
                  onChange={(e, newValue) => activeSubTab === 'spouse'
                    ? setValues({...values, spouse_power: newValue})
                    : setValues({...values, power: newValue})
                  }
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name={activeSubTab === 'spouse' ? 'spouse_happiness' : 'happiness'}
                value={activeSubTab === 'spouse' ? values.spouse_happiness.toString() : values.happiness.toString()}
                small
                smallHeight
                max={6}
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={6}
                  step={1}
                  label="Happiness"
                  value={activeSubTab === 'spouse' ? +values.spouse_happiness : +values.happiness}
                  onChange={(e, newValue) => activeSubTab === 'spouse'
                    ? setValues({...values, spouse_happiness: newValue})
                    : setValues({...values, happiness: newValue})
                  }
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name={activeSubTab === 'spouse' ? 'spouse_health' : 'health'}
                value={activeSubTab === 'spouse' ? values.spouse_health.toString() : values.health.toString()}
                small
                smallHeight
                max={6}
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={6}
                  step={1}
                  label="Health"
                  value={activeSubTab === 'spouse' ? +values.spouse_health : +values.health}
                  onChange={(e, newValue) => activeSubTab === 'spouse'
                    ? setValues({...values, spouse_health: newValue})
                    : setValues({...values, health: newValue})
                  }
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name={activeSubTab === 'spouse' ? 'spouse_making_difference' : 'making_difference'}
                value={activeSubTab === 'spouse' ? values.spouse_making_difference.toString() : values.making_difference.toString()}
                small
                smallHeight
                max={6}
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={6}
                  step={1}
                  label="Making a difference"
                  value={activeSubTab === 'spouse' ? +values.spouse_making_difference : +values.making_difference}
                  onChange={(e, newValue) => activeSubTab === 'spouse'
                    ? setValues({...values, spouse_making_difference: newValue})
                    : setValues({...values, making_difference: newValue})
                  }
                />
              </div>
            </div>
            <h4>Preferred ranking</h4>
            {activeSubTab === 'spouse'
              ? countValues.totalRankPreferredSpouse < 6
                ? <div className="calculators__error">Please enter a ranking for each value.</div>
                : countValues.totalRankPreferredSpouse !== 21
                  ? <div className="calculators__error">Duplicate scores for values are prohibited.  Please provide unique scores for each value.</div>
                  : ''
              : countValues.totalRankPreferred < 6
                ? <div className="calculators__error">Please enter a ranking for each value.</div>
                : countValues.totalRankPreferred !== 21
                  ? <div className="calculators__error">Duplicate scores for values are prohibited.  Please provide unique scores for each value.</div>
                  : ''
            }
            <div className="calculators__area">
              <Input
                type="number"
                name={activeSubTab === 'spouse' ? 'spouse_preferred_security' : 'preferred_security'}
                value={activeSubTab === 'spouse' ? values.spouse_preferred_security.toString() : values.preferred_security.toString()}
                small
                smallHeight
                max={6}
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={6}
                  step={1}
                  label="Security"
                  value={activeSubTab === 'spouse' ? +values.spouse_preferred_security : +values.preferred_security}
                  onChange={(e, newValue) => activeSubTab === 'spouse'
                    ? setValues({...values, spouse_preferred_security: newValue})
                    : setValues({...values, preferred_security: newValue})
                  }
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name={activeSubTab === 'spouse' ? 'spouse_preferred_obligation' : 'preferred_obligation'}
                value={activeSubTab === 'spouse' ? values.spouse_preferred_obligation.toString() : values.preferred_obligation.toString()}
                small
                smallHeight
                max={6}
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={6}
                  step={1}
                  label="Obligation"
                  value={activeSubTab === 'spouse' ? +values.spouse_preferred_obligation : +values.preferred_obligation}
                  onChange={(e, newValue) => activeSubTab === 'spouse'
                    ? setValues({...values, spouse_preferred_obligation: newValue})
                    : setValues({...values, preferred_obligation: newValue})
                  }
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name={activeSubTab === 'spouse' ? 'spouse_preferred_power' : 'preferred_power'}
                value={activeSubTab === 'spouse' ? values.spouse_preferred_power.toString() : values.preferred_power.toString()}
                small
                smallHeight
                max={6}
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={6}
                  step={1}
                  label="Power/Prestige"
                  value={activeSubTab === 'spouse' ? +values.spouse_preferred_power : +values.preferred_power}
                  onChange={(e, newValue) => activeSubTab === 'spouse'
                    ? setValues({...values, spouse_preferred_power: newValue})
                    : setValues({...values, preferred_power: newValue})
                  }
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name={activeSubTab === 'spouse' ? 'spouse_preferred_happiness' : 'preferred_happiness'}
                value={activeSubTab === 'spouse' ? values.spouse_preferred_happiness.toString() : values.preferred_happiness.toString()}
                small
                smallHeight
                max={6}
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={6}
                  step={1}
                  label="Happiness"
                  value={activeSubTab === 'spouse' ? +values.spouse_preferred_happiness : +values.preferred_happiness}
                  onChange={(e, newValue) => activeSubTab === 'spouse'
                    ? setValues({...values, spouse_preferred_happiness: newValue})
                    : setValues({...values, preferred_happiness: newValue})
                  }
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name={activeSubTab === 'spouse' ? 'spouse_preferred_health' : 'preferred_health'}
                value={activeSubTab === 'spouse' ? values.spouse_preferred_health.toString() : values.preferred_health.toString()}
                small
                smallHeight
                max={6}
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={6}
                  step={1}
                  label="Health"
                  value={activeSubTab === 'spouse' ? +values.spouse_preferred_health : +values.preferred_health}
                  onChange={(e, newValue) => activeSubTab === 'spouse'
                    ? setValues({...values, spouse_preferred_health: newValue})
                    : setValues({...values, preferred_health: newValue})
                  }
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name={activeSubTab === 'spouse' ? 'spouse_preferred_making_difference' : 'preferred_making_difference'}
                value={activeSubTab === 'spouse' ? values.spouse_preferred_making_difference.toString() : values.preferred_making_difference.toString()}
                small
                smallHeight
                max={6}
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={6}
                  step={1}
                  label="Making a difference"
                  value={activeSubTab === 'spouse' ? +values.spouse_preferred_making_difference : +values.preferred_making_difference}
                  onChange={(e, newValue) => activeSubTab === 'spouse'
                    ? setValues({...values, spouse_preferred_making_difference: newValue})
                    : setValues({...values, preferred_making_difference: newValue})
                  }
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel value={tabActive} index={2}>
            <h6 className="calculators__subtitle">Step 3: Goals</h6>
            <p>Please provide your top 5 goals, in order of importance to you. A goal is a fairly attainable objective you would like to accomplish. For example, getting your Masters Degree is a goal. Running an upcoming summer marathon or donating a few hundred dollars to charity are goals.</p>
            {activeSubTab === 'spouse'
              ? values.spouse_goals && values.spouse_goals.map((item, i) =>
                  <div key={i} className="calculators__area-line">
                    <div className="container--small">
                      <h6 className="calculators__subtitle">Goal {i+1}</h6>
                      <FormGroup labelText="Description">
                        <Input
                          type="text"
                          value={item && item.name ? item.name : ''}
                          name="name"
                          id={`values.spouse_goals[${i}].name`}
                          onChange={e => handleArrayInput(e, i, 'spouse_goals')}
                          placeholder="Type your goals"
                        />
                      </FormGroup>
                      <FormGroup labelText="Associated values">
                        <FormSelect
                          options={listValues}
                          value={item && item.value_id ? item.value_id : ''}
                          name="value_id"
                          id={`values.spouse_goals[${i}].value_id`}
                          onChange={e => handleArrayInput(e, i, 'spouse_goals')}
                          placeholder="Select"
                        />
                      </FormGroup>
                    </div>
                  </div>)
              : values.goals && values.goals.map((item, i) =>
                  <div key={i} className="calculators__area-line">
                    <div className="container--small">
                      <h6 className="calculators__subtitle">Goal {i+1}</h6>
                      <FormGroup labelText="Description">
                        <Input
                          type="text"
                          value={item && item.name ? item.name : ''}
                          name="name"
                          id={`values.goals[${i}].name`}
                          onChange={e => handleArrayInput(e, i, 'goals')}
                          placeholder="Type your goals"
                        />
                      </FormGroup>
                      <FormGroup labelText="Associated values">
                        <FormSelect
                          options={listValues}
                          value={item && item.value_id ? item.value_id : ''}
                          name="value_id"
                          id={`values.goals[${i}].value_id`}
                          onChange={e => handleArrayInput(e, i, 'goals')}
                          placeholder="Select"
                        />
                      </FormGroup>
                    </div>
                  </div>)
            }
          </TabPanel>
          <TabPanel value={tabActive} index={3}>
            <h6 className="calculators__subtitle">Step 4: Dreams</h6>
            <p>Please provide your top 5 dreams, in order of importance to you. A dream is a fairly attainable objective you would like to accomplish. For example, getting your Masters Degree is a dream. Running an upcoming summer marathon or donating a few hundred dollars to charity are dreams.</p>
            {activeSubTab === 'spouse'
              ? values.spouse_dreams && values.spouse_dreams.map((item, i) =>
              <div key={i} className="calculators__area-line">
                <div className="container--small">
                  <h6 className="calculators__subtitle">Dream {i+1}</h6>
                  <FormGroup labelText="Description">
                    <Input
                      type="text"
                      value={item && item.name ? item.name : ''}
                      name="name"
                      id={`values.spouse_dreams[${i}].name`}
                      onChange={e => handleArrayInput(e, i, 'spouse_dreams')}
                      placeholder="Type your dreams"
                    />
                  </FormGroup>
                  <FormGroup labelText="Associated values">
                    <FormSelect
                      options={listValues}
                      value={item && item.value_id ? item.value_id : ''}
                      name="value_id"
                      id={`values.spouse_dreams[${i}].value_id`}
                      onChange={e => handleArrayInput(e, i, 'spouse_dreams')}
                      placeholder="Select"
                    />
                  </FormGroup>
                </div>
              </div>)
              : values.dreams && values.dreams.map((item, i) =>
              <div key={i} className="calculators__area-line">
                <div className="container--small">
                  <h6 className="calculators__subtitle">Dream {i+1}</h6>
                  <FormGroup labelText="Description">
                    <Input
                      type="text"
                      value={item && item.name ? item.name : ''}
                      name="name"
                      id={`values.dreams[${i}].name`}
                      onChange={e => handleArrayInput(e, i, 'dreams')}
                      placeholder="Type your dreams"
                    />
                  </FormGroup>
                  <FormGroup labelText="Associated values">
                    <FormSelect
                      options={listValues}
                      value={item && item.value_id ? item.value_id : ''}
                      name="value_id"
                      id={`values.dreams[${i}].value_id`}
                      onChange={e => handleArrayInput(e, i, 'dreams')}
                      placeholder="Select"
                    />
                  </FormGroup>
                </div>
              </div>)
            }
          </TabPanel>
        </div>
        <div className="calculators__preview">
          <h6 className="calculators__subtitle">Results</h6>
          <ul className="calculators__preview-tabs">
            {(values.conduct_assessment === 'me' || values.conduct_assessment === 'both') &&
              <li className={activeSubTab === 'me' ? 'active' : ''}>
                <button onClick={e => setActiveSubTab(e.target.value)} value='me'>{values.your_name ? values.your_name : 'You'}</button>
              </li>}
            {(values.conduct_assessment === 'spouse' || values.conduct_assessment === 'both') &&
              <li className={activeSubTab === 'spouse' ? 'active' : ''}>
                <button onClick={e => setActiveSubTab(e.target.value)} value='spouse'>{values.spouse_name ? values.spouse_name : 'Spouse'}</button>
              </li>}
            {values.conduct_assessment === 'both' &&
              <li className={activeSubTab === 'both' ? 'active' : ''}>
                <button onClick={e => setActiveSubTab(e.target.value)} value='both'>As a Couple</button>
              </li>}
          </ul>
          <div className="calculators__preview-tabs-holder">
            {activeSubTab === 'me' &&
              <div className="calculators__preview-tabs-content">
                <div className="calculators__cases">
                  <div>
                    <SpeedometerLimit
                      limit={[30, 70]}
                      count={countValues.currentToPreferred || 0}
                      large
                      label
                      inverse
                      sign
                      decimal
                    />
                    <div className="calculators__case-title">
                      Values Alignment, Current to Preferred
                    </div>
                  </div>
                  <div>
                    <SpeedometerLimit
                      limit={[30, 70]}
                      count={countValues.currentValuesGoals || 0}
                      large
                      label
                      inverse
                      sign
                      decimal
                    />
                    <div className="calculators__case-title">
                      Current Values and Goals Alignment
                    </div>
                  </div>
                  <div>
                    <SpeedometerLimit
                      limit={[30, 70]}
                      count={countValues.preferredValuesGoals || 0}
                      large
                      label
                      inverse
                      sign
                      decimal
                    />
                    <div className="calculators__case-title">
                      Preferred Values and Goals Alignment
                    </div>
                  </div>
                  <div>
                    <SpeedometerLimit
                      limit={[30, 70]}
                      count={countValues.currentValuesDreams || 0}
                      large
                      label
                      inverse
                      sign
                      decimal
                    />
                    <div className="calculators__case-title">
                      Current Values and Dreams Alignment
                    </div>
                  </div>
                  <div>
                    <SpeedometerLimit
                      limit={[30, 70]}
                      count={countValues.preferredValuesDreams || 0}
                      large
                      label
                      inverse
                      sign
                      decimal
                    />
                    <div className="calculators__case-title">
                      Preferred Values and Dreams Alignment
                    </div>
                  </div>
                </div>
              </div>}
            {activeSubTab === 'spouse' &&
              <div className="calculators__preview-tabs-content">
                <div className="calculators__cases">
                  <div>
                    <SpeedometerLimit
                      limit={[30, 70]}
                      count={countValues.currentToPreferredSpouse || 0}
                      large
                      label
                      inverse
                      sign
                      decimal
                    />
                    <div className="calculators__case-title">
                      Values Alignment, Current to Preferred
                    </div>
                  </div>
                  <div>
                    <SpeedometerLimit
                      limit={[30, 70]}
                      count={countValues.currentValuesGoalsSpouse || 0}
                      large
                      label
                      inverse
                      sign
                      decimal
                    />
                    <div className="calculators__case-title">
                      Current Values and Goals Alignment
                    </div>
                  </div>
                  <div>
                    <SpeedometerLimit
                      limit={[30, 70]}
                      count={countValues.preferredValuesGoalsSpouse || 0}
                      large
                      label
                      inverse
                      sign
                      decimal
                    />
                    <div className="calculators__case-title">
                      Preferred Values and Goals Alignment
                    </div>
                  </div>
                  <div>
                    <SpeedometerLimit
                      limit={[30, 70]}
                      count={countValues.currentValuesDreamsSpouse || 0}
                      large
                      label
                      inverse
                      sign
                      decimal
                    />
                    <div className="calculators__case-title">
                      Current Values and Dreams Alignment
                    </div>
                  </div>
                  <div>
                    <SpeedometerLimit
                      limit={[30, 70]}
                      count={countValues.preferredValuesDreamsSpouse || 0}
                      large
                      label
                      inverse
                      sign
                      decimal
                    />
                    <div className="calculators__case-title">
                      Preferred Values and Dreams Alignment
                    </div>
                  </div>
                </div>
              </div>}
            {activeSubTab === 'both' &&
              <div className="calculators__preview-tabs-content">
                <div className="calculators__cases">
                  <div>
                    <SpeedometerLimit
                      limit={[30, 70]}
                      count={countValues.currentValuesCouple || 0}
                      large
                      label
                      inverse
                      sign
                      decimal
                    />
                    <div className="calculators__case-title">
                      Current Values Alignment
                    </div>
                  </div>
                  <div>
                    <SpeedometerLimit
                      limit={[30, 70]}
                      count={countValues.preferredValuesCouple || 0}
                      large
                      label
                      inverse
                      sign
                      decimal
                    />
                    <div className="calculators__case-title">
                      Preferred Values Alignment
                    </div>
                  </div>
                  <div>
                    <SpeedometerLimit
                      limit={[30, 70]}
                      count={countValues.goalsCouple || 0}
                      large
                      label
                      inverse
                      sign
                      decimal
                    />
                    <div className="calculators__case-title">
                      Goals Alignment
                    </div>
                  </div>
                  <div>
                    <SpeedometerLimit
                      limit={[30, 70]}
                      count={countValues.dreamsCouple || 0}
                      large
                      label
                      inverse
                      sign
                      decimal
                    />
                    <div className="calculators__case-title">
                      Dreams Alignment
                    </div>
                  </div>
                </div>
              </div>}
          </div>
        </div>
      </div>
   }
      <div className="calculators__buttons">
        <Button variant="contained" size="large" color="primary" onClick={() => setIsOpen(true)}>Save result</Button>
        {currentId && <Button variant="contained" size="large"  onClick={() => setIsDeleteModal(true)}>Remove result</Button>}
        <Button size="large" className="MuiButton-textGray" onClick={() => resetForm()}>Reset</Button>
      </div>

      <ModalBox
        open={isOpen}
        close={() => setIsOpen(false)}
        onClick={onSave}
        button="Save"
        title="Save Result"
        isLoading={loading}
        isDisabled={values && !values.name && !values.currentId}
      >
        <FormGroup name="name" labelText="Name">
          <Input
            name='name'
            value={values ? values.name : ''}
            placeholder="Type a name of result"
            onChange={e => setValues({
              ...values,
              name: e.target.value
            })}
          />
        </FormGroup>
        {listExisting && listExisting.length > 0 &&
        <>
          <p className="text-center">or</p>
          <FormGroup labelText="Select  Existing Result">
            <FormSelect
              options={listExisting}
              value={values ? values.currentId : ''}
              name="currentId"
              onChange={e => setValues({
                ...values,
                currentId: e.target.value,
                name: listExisting.find(i => i.value === e.target.value).label
              })}
              placeholder="Select Existing Result"
            />
          </FormGroup>
        </>
        }
      </ModalBox>
      <ModalBox
        small
        open={isDeleteModal}
        close={() => setIsDeleteModal(false)}
        onClick={removeResult}
        button="Remove"
        title={`Do you want to remove ${current && current.name}?`}
      />

      <ModalBox
        small
        open={isSavedModal}
        close={() => setIsSavedModal(false)}
        button="Ok"
        onClick={() => setIsSavedModal(false)}
      >
        <div>
          {calculators && calculators.iconLeft &&
          <div className="modal__title-iconLeft">
            <img src={calculators.iconLeft} alt={calculators.name} />
          </div>}
          <h4>Your calculator scenario has been saved.</h4>
          <p>You can access your saved scenarios through the “Select result” dropdown menu at the top of each calculator page.</p>
        </div>
      </ModalBox>
    </Layout>
  )
}

export default ValueCircle
