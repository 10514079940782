import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../../components/Layout/Layout'
import LayoutForm from '../../../components/Layout/LayoutForm'
import { BackButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import { Button } from '@mui/material'
import { fetchNecessity, postNecessity } from '../../../redux/slices/worthSlice'
import { FormGroup, InputCurrency } from '../../../components/shared/Form'
import Modal from '../../../components/shared/Modal/Modal'
import ProgressCircle from '../../../components/shared/ProgressBar/ProgressCircle'
import { setIsOpen } from '../../../redux/slices/modalSlice'
import { worthNecessitiesSchema } from '../../../utils/validationFinances'

const WorthLiabilitiesNecessities = ({ props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.user.user);
  const { necessity } = useSelector(state => state.worth);
  const isOpen = useSelector(state => state.modal.isOpen);
  const sending = useSelector(state => state.sending.isSending);

  useEffect(() => {
    async function fetch() {
      dispatch(fetchNecessity())
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const onSubmit = (values) => {
    dispatch(postNecessity(values, props))
  }

  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }))
  }

  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }));
    history.push('/worth/liabilities')
  }


  return (
    <Layout>
      <LayoutForm small>
        <BackButton title="Back" />
        <PageTitle
          title="Overdue Necessities"
        />
        <div className="layout-form__content-holder">
          <Formik
            enableReinitialize
            initialValues={necessity && {...necessity}}
            validationSchema={worthNecessitiesSchema}
            onSubmit={onSubmit}
          >
            {({ errors, handleChange, handleBlur, values, touched }) => (
              <Form>
                <FormGroup name="utilities" labelText="Overdue utilities ($)">
                  <InputCurrency
                    placeholder="Type your overdue utilities"
                    type="text"
                    name="utilities"
                    onChange={handleChange}
                    value={values.utilities || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.utilities}
                  />
                  {errors.utilities && <span className="form__msg-error">{errors.utilities}</span>}
                </FormGroup>
                <FormGroup name="phone_payments" labelText="Overdue phone payments ($)">
                  <InputCurrency
                    placeholder="Type overdue phone payments ($)"
                    type="text"
                    name="phone_payments"
                    onChange={handleChange}
                    value={values.phone_payments || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.phone_payments}
                  />
                  {errors.phone_payments && <span className="form__msg-error">{errors.phone_payments}</span>}
                </FormGroup>
                <FormGroup name="mortgage_payments" labelText="Overdue mortgage payments ($):">
                  <InputCurrency
                    placeholder="Type overdue mortgage payments ($)"
                    type="text"
                    name="mortgage_payments"
                    onChange={handleChange}
                    value={values.mortgage_payments || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.mortgage_payments}
                  />
                  {errors.mortgage_payments && <span className="form__msg-error">{errors.mortgage_payments}</span>}
                </FormGroup>
                <FormGroup name="car_payments" labelText="Overdue car payments ($):">
                  <InputCurrency
                    placeholder="Type overdue car payments ($)"
                    type="text"
                    name="car_payments"
                    onChange={handleChange}
                    value={values.car_payments || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.car_payments}
                  />
                  {errors.car_payments && <span className="form__msg-error">{errors.car_payments}</span>}
                </FormGroup>
                <FormGroup name="taxes" labelText="Overdue taxes ($)">
                  <InputCurrency
                    placeholder="Type overdue taxes ($)"
                    type="text"
                    name="taxes"
                    onChange={handleChange}
                    value={values.taxes || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.taxes}
                  />
                  {errors.taxes && <span className="form__msg-error">{errors.taxes}</span>}
                </FormGroup>
                <FormGroup name="other_loan_balance" labelText="Other loan balance">
                  <InputCurrency
                    placeholder="Type other loan balance"
                    type="text"
                    name="other_loan_balance"
                    onChange={handleChange}
                    value={values.other_loan_balance || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.other_loan_balance}
                  />
                  {errors.other_loan_balance && <span className="form__msg-error">{errors.other_loan_balance}</span>}
                </FormGroup>

                <div className="form__button form__button--center">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Save
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <Modal
          open={isOpen}
          small
          button="Done"
          onClick={handleClick}
          close={onClose}
        >
          <h3>Good Job!</h3>
          <p>Your progress increased 100%</p>
          <ProgressCircle amount={100} small />
        </Modal>
      </LayoutForm>
    </Layout>
  );
};

export default WorthLiabilitiesNecessities;
