import React from 'react'
import PropTypes from 'prop-types'
import TooltipCustom from '../Tooltip/Tooltip'
import { FormSelect } from './index'
import { Checkbox, FormControlLabel } from '@mui/material'


const ChooseBox = ({ name, nameActive, label, info, options, value, valueActive, onChange }) => {
  return (
    <div className="choose-box">
      <label className="choose-box__label" htmlFor={name}>
        {label}
        {info && (
          <TooltipCustom
            size="small"
            title={info}
          />
        )}
      </label>
      <div className="choose-box__holder">
        <span>Importance:</span>
        <FormSelect
          options={options}
          value={value || ``}
          name={name}
          onChange={onChange}
          placeholder="Select"
        />
        <div className="choose-box__check">
          <FormControlLabel
            label="Active: "
            labelPlacement="start"
            control={
              <Checkbox checked={valueActive}
                        value={valueActive}
                        onChange={onChange}
                        name={nameActive} />
            }
          />
        </div>
      </div>
    </div>
  )
};

ChooseBox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  nameActive: PropTypes.string,
  options: PropTypes.array,
  value:  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  valueActive: PropTypes.bool,
  onChange: PropTypes.func,
};

export default ChooseBox;
