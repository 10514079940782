import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import UserIcon from "../../assets/images/icons/user.svg";
import LayoutAdmin from "../../components/Layout/LayoutAdmin";
import { BackButton } from "../../components/shared/Buttons";
import Loading from "../../components/shared/Loading/Loading";
import SVGIcon from "../../components/shared/SVGIcon/SVGIcon";
import Tabs, { TabPanel } from "../../components/shared/Tabs/Tabs";
import TaskCardSimple from "../../components/Task/TaskCardSimple";
import ImageSearch from '../../assets/images/image-search.png'
import { fetchPushNotificationViewStatistics } from "../../redux/slices/statisticsSlice";
import { fetchCompany } from "../../redux/slices/companiesSlice";

const AdminTaskPlanStatistics = ({ isAdmin }) => {
  const dispatch = useDispatch();
  const { companyId } = useParams();
  const { id } = useParams();
  const { pushNotificationView, idLoading } = useSelector(state => state.statistics);
  const { image, email, name } = useSelector((state) => state.companies.company.info);
  const [value, setValue] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const mobileView = 999;

  const handleResize = () => {
    mobileView >= window.innerWidth ? setIsMobile(true) : setIsMobile(false);
  };

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(fetchCompany(companyId));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(fetchPushNotificationViewStatistics(companyId, id));

    // eslint-disable-next-line
  }, [id]);

  const handleSetValue = (event, newValue) => setValue(newValue);

  return (
    <LayoutAdmin companyId={companyId} isAdmin={isAdmin}>
      <div className="member-comapny">
        <div className="member-comapny__info">
          <div className="member-comapny__logo">
            <img src={image} alt="Company logo" />
          </div>
          <div className="member-comapny__title">
            <h3>{name}</h3>
            {!isMobile && (
              <p>
                Feedback Email: <span>{email}</span>
              </p>
            )}
          </div>
        </div>
        {isMobile && (
          <div className="mobile-feedback">
            Feedback Email: <span>{email}</span>
          </div>
        )}
      </div>
      <Box
        component={BackButton}
        mb={{ xs: 3, md: 5 }}
        title="Back to User statistics"
      />
      <h3 className="statistics__title">Statistics of task plan</h3>

      <div className="mb--small mt--small">
        <Grid container alignItems="center">
          <Grid item>
            <div className="user-avatar user-avatar--md">
              <div className="user-avatar__placeholder">
                {pushNotificationView?.user?.avatar ? (
                  <img
                    src={pushNotificationView?.user?.avatar}
                    width={48}
                    height={48}
                    alt=""
                  />
                ) : (
                  <SVGIcon icon={UserIcon} />
                )}
              </div>
            </div>
          </Grid>
          <Grid item>
            <span className="user-avatar__name typography__semibold fs__16">
              {pushNotificationView?.user?.first_name}{" "}
              {pushNotificationView?.user?.last_name}
            </span>
          </Grid>
        </Grid>
      </div>

      <Tabs
        handleChange={handleSetValue}
        value={value}
        tabs={[{ title: "Overdue" }, { title: "Newest" }]}
      />
      <TabPanel value={value} index={0}>
        {idLoading ? (
          <Loading small />
        ) : pushNotificationView?.overdue?.length ? (
          <div className="grid grid-4-large">
            {pushNotificationView.overdue.map((task) => (
              <div key={`task-${task.id}`}>
                <TaskCardSimple
                  title={task.name}
                  status={task.task_status.name}
                  category={task.category?.name}
                  priority={task.priority?.name}
                  content={task.description}
                  icon={task.category.planning.image}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="search__empty">
            <div className="search__empty-image">
              <img src={ImageSearch} alt="No results found" />
            </div>
            <div className="search__empty-text">
              <h5>No overdue tasks yet</h5>
            </div>
          </div>
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {idLoading ? (
          <Loading small />
        ) : pushNotificationView?.newest?.length ? (
          <div className="grid grid-4-large">
            {pushNotificationView.newest.map((task) => (
              <div key={`task-${task.id}`}>
                <TaskCardSimple
                  title={task.name}
                  status={task.task_status.name}
                  category={task.category.name}
                  priority={task.priority.name}
                  content={task.description}
                  icon={task.category.planning.image}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="search__empty">
            <div className="search__empty-image">
              <img src={ImageSearch} alt="No results found" />
            </div>
            <div className="search__empty-text">
              <h5>No newest tasks yet</h5>
            </div>
          </div>
        )}
      </TabPanel>
    </LayoutAdmin>
  );
}

export default AdminTaskPlanStatistics;