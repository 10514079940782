import React from 'react'
import { Form, Formik } from 'formik'
import { Button } from '@mui/material'
import {FormGroup, Input, InputCurrency} from '../../components/shared/Form'
import Percentage from "../../assets/images/icons/percentage.svg";

const Retirement = ({ title, onSave, values, coreValues, validationSchema, sending, noEdit }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{ ...values }}
      validationSchema={validationSchema}
      onSubmit={(values) => onSave(values)}
    >
      {({ errors, handleChange, handleBlur, values, touched }) => (
        <Form>
          <h4>{title}</h4>
          <div className="form__flow-wrap">
            <FormGroup name="social_security_min_age" labelText="Social security minimum age">
              <Input
                name="social_security_min_age"
                placeholder="Type minimum age"
                type="number"
                onChange={handleChange}
                value={values.social_security_min_age || ''}
                onBlur={handleBlur}
                className={coreValues?.social_security_min_age && !noEdit && 'form__input--core'}
                hasError={!!errors.social_security_min_age && touched.social_security_min_age}
              />
              {touched.social_security_min_age && errors.social_security_min_age ? (
                <span className="form__msg-error">{errors.social_security_min_age}</span>
              ) : coreValues && coreValues.social_security_min_age && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.social_security_min_age}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="early_retirement_social_security" labelText="Early retirement social security amount">
              <InputCurrency
                icon
                placeholder="$0.00"
                name="early_retirement_social_security"
                onChange={handleChange}
                value={values.early_retirement_social_security || ''}
                onBlur={handleBlur}
                className={coreValues?.early_retirement_social_security && !noEdit && 'form__input--core'}
                hasError={!!errors.early_retirement_social_security && touched.early_retirement_social_security}
              />
              {touched.early_retirement_social_security && errors.early_retirement_social_security ? (
                <span className="form__msg-error">{errors.early_retirement_social_security}</span>
              ) : coreValues && coreValues.early_retirement_social_security && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.early_retirement_social_security}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="social_security_delayed_age" labelText="Social security delayed age">
              <Input
                name="social_security_delayed_age"
                placeholder="Type age"
                type="number"
                onChange={handleChange}
                value={values.social_security_delayed_age || ''}
                onBlur={handleBlur}
                className={coreValues?.social_security_delayed_age && !noEdit && 'form__input--core'}
                hasError={!!errors.social_security_delayed_age && touched.social_security_delayed_age}
              />
              {touched.social_security_delayed_age && errors.social_security_delayed_age ? (
                <span className="form__msg-error">{errors.social_security_delayed_age}</span>
              ) : coreValues && coreValues.social_security_delayed_age && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.social_security_delayed_age}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="delayed_retirement_social_security" labelText="Delayed retirement social security amount">
              <InputCurrency
                icon
                placeholder="$0.00"
                name="delayed_retirement_social_security"
                onChange={handleChange}
                value={values.delayed_retirement_social_security || ''}
                onBlur={handleBlur}
                className={coreValues?.delayed_retirement_social_security && !noEdit && 'form__input--core'}
                hasError={!!errors.delayed_retirement_social_security && touched.delayed_retirement_social_security}
              />
              {touched.delayed_retirement_social_security && errors.delayed_retirement_social_security ? (
                <span className="form__msg-error">{errors.delayed_retirement_social_security}</span>
              ) : coreValues && coreValues.delayed_retirement_social_security && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.delayed_retirement_social_security}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="medical_care_min_age" labelText="Medicare minimum age">
              <Input
                name="medical_care_min_age"
                placeholder="Type"
                type="number"
                onChange={handleChange}
                value={values.medical_care_min_age || ''}
                onBlur={handleBlur}
                className={coreValues?.medical_care_min_age && !noEdit && 'form__input--core'}
                hasError={!!errors.medical_care_min_age && touched.medical_care_min_age}
              />
              {touched.medical_care_min_age && errors.medical_care_min_age ? (
                <span className="form__msg-error">{errors.medical_care_min_age}</span>
              ) : coreValues && coreValues.medical_care_min_age && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.medical_care_min_age}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="expenses_during_retirement" labelText="Reduction in expenses during retirement">
              <Input
                name="expenses_during_retirement"
                placeholder="Type"
                type="number"
                onChange={handleChange}
                value={values.expenses_during_retirement || ''}
                onBlur={handleBlur}
                iconName={Percentage}
                className={coreValues?.expenses_during_retirement && !noEdit && 'form__input--core'}
                hasError={!!errors.expenses_during_retirement && touched.expenses_during_retirement}
              />
              {touched.expenses_during_retirement && errors.expenses_during_retirement ? (
                <span className="form__msg-error">{errors.expenses_during_retirement}</span>
              ) : coreValues && coreValues.expenses_during_retirement && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.expenses_during_retirement}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="average_retirement_age" labelText="Average retirement age">
              <Input
                name="average_retirement_age"
                placeholder="Type"
                type="number"
                onChange={handleChange}
                value={values.average_retirement_age || ''}
                onBlur={handleBlur}
                className={coreValues?.average_retirement_age && !noEdit && 'form__input--core'}
                hasError={!!errors.average_retirement_age && touched.average_retirement_age}
              />
              {touched.average_retirement_age && errors.average_retirement_age ? (
                <span className="form__msg-error">{errors.average_retirement_age}</span>
              ) : coreValues && coreValues.average_retirement_age && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.average_retirement_age}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="delayed_retirement_age" labelText="Delayed retirement age">
              <Input
                name="delayed_retirement_age"
                placeholder="Type"
                type="number"
                onChange={handleChange}
                value={values.delayed_retirement_age || ''}
                onBlur={handleBlur}
                className={coreValues?.delayed_retirement_age && !noEdit && 'form__input--core'}
                hasError={!!errors.delayed_retirement_age && touched.delayed_retirement_age}
              />
              {touched.delayed_retirement_age && errors.delayed_retirement_age ? (
                <span className="form__msg-error">{errors.delayed_retirement_age}</span>
              ) : coreValues && coreValues.delayed_retirement_age && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.delayed_retirement_age}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="min_distribution_age" labelText="Required minimum distribution age">
              <Input
                name="min_distribution_age"
                placeholder="Type"
                type="number"
                onChange={handleChange}
                value={values.min_distribution_age || ''}
                onBlur={handleBlur}
                className={coreValues?.min_distribution_age && !noEdit && 'form__input--core'}
                hasError={!!errors.min_distribution_age && touched.min_distribution_age}
              />
              {touched.min_distribution_age && errors.min_distribution_age ? (
                <span className="form__msg-error">{errors.min_distribution_age}</span>
              ) : coreValues && coreValues.min_distribution_age && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.min_distribution_age}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="long_term_care_warning_age" labelText="Long term care warning age">
              <Input
                name="long_term_care_warning_age"
                placeholder="Type"
                type="number"
                onChange={handleChange}
                value={values.long_term_care_warning_age || ''}
                onBlur={handleBlur}
                className={coreValues?.long_term_care_warning_age && !noEdit && 'form__input--core'}
                hasError={!!errors.long_term_care_warning_age && touched.long_term_care_warning_age}
              />
              {touched.long_term_care_warning_age && errors.long_term_care_warning_age ? (
                <span className="form__msg-error">{errors.long_term_care_warning_age}</span>
              ) : coreValues && coreValues.long_term_care_warning_age && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.long_term_care_warning_age}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="long_term_care_alert_age" labelText="Long term care alert age">
              <Input
                name="long_term_care_alert_age"
                placeholder="Type"
                type="number"
                onChange={handleChange}
                value={values.long_term_care_alert_age || ''}
                onBlur={handleBlur}
                className={coreValues?.long_term_care_alert_age && !noEdit && 'form__input--core'}
                hasError={!!errors.long_term_care_alert_age && touched.long_term_care_alert_age}
              />
              {touched.long_term_care_alert_age && errors.long_term_care_alert_age ? (
                <span className="form__msg-error">{errors.long_term_care_alert_age}</span>
              ) : coreValues && coreValues.long_term_care_alert_age && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.long_term_care_alert_age}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="social_security_survivor" labelText="Social security survivor benefit">
              <InputCurrency
                icon
                placeholder="$0.00"
                name="social_security_survivor"
                onChange={handleChange}
                value={values.social_security_survivor || ''}
                onBlur={handleBlur}
                className={coreValues?.social_security_survivor && !noEdit && 'form__input--core'}
                hasError={!!errors.social_security_survivor && touched.social_security_survivor}
              />
              {touched.social_security_survivor && errors.social_security_survivor ? (
                <span className="form__msg-error">{errors.social_security_survivor}</span>
              ) : coreValues && coreValues.social_security_survivor && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.social_security_survivor}</span>
              ) : (
                ''
              )}
            </FormGroup>
          </div>
          <div className="form__button">
            <Button variant="contained" size="large" color="primary" type="submit" disabled={sending || noEdit}>
              Save
              {sending && <span className="spinner" />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default Retirement
