import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import usePlacesAutocomplete, { getGeocode, getZipCode } from 'use-places-autocomplete'
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from '@reach/combobox'
import '@reach/combobox/styles.css'

const AddressAutocomplete = ({ name, initialValue, placeholder, onChange, onBlur, setFieldValue }) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ['address']
    },
    debounce: 300,
    defaultValue: initialValue
  });

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue, false)
    }

    // eslint-disable-next-line
  }, [initialValue])

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = (description) => {
    setValue(description, false);
    clearSuggestions();

    getGeocode({ address: description })
      .then((results) => getZipCode(results[0], false))
      .then((zipCode) => {
        if (zipCode) {
          setValue(`${description}, ${zipCode}`, false)
          setFieldValue(name, `${description}, ${zipCode}`)
        } else {
          setFieldValue(name, description)
        }

        // console.log("ZIP Code: ", zipCode);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  }

  const renderSuggestions = () => {
    const suggestions = data.map(({ id, description }, index) => (
        <ComboboxOption key={`address-${index}`} value={description} />
    ))

    return (
      <>
        {suggestions}
        <li className="address__logo">
          <img
            src="https://developers.google.com/maps/documentation/images/powered_by_google_on_white.png"
            alt="Powered by Google"
          />
        </li>
      </>
    )
  }

  return (
    <Combobox onSelect={handleSelect}>
      <ComboboxInput
        className="form__input"
        value={value}
        onChange={handleInput}
        disabled={!ready}
        name={name}
        type="text"
        placeholder={placeholder}
        onBlur={onBlur}
      />
      <ComboboxPopover>
        <ComboboxList className="address__list">{status === "OK" && renderSuggestions()}</ComboboxList>
      </ComboboxPopover>
    </Combobox>
  )
}

AddressAutocomplete.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  initialValue: PropTypes.string
};

export default AddressAutocomplete;
