import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import isArray from 'lodash/isArray'
import Layout from '../../../components/Layout/Layout'
import { BackButton } from '../../../components/shared/Buttons'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchFutureChildrenData,
  deactivateFutureEducation,
  fetcFutureEducations
} from "../../../redux/slices/childrenSlice";
import TreeSubItem from '../../../components/Tree/TreeSubItem'
import TreeImage from '../../../assets/images/tree/future/Future-tree.png'
import { fetchTotalPercent } from '../../../redux/slices/percentagesSlice'
import { getPercent } from '../../../utils/utils'
import ModalBox from "../../../components/shared/Modal/Modal";
import TreeCount from '../../../components/Tree/TreeCount'
import { Button, Switch } from "@mui/material";
import { setIsOpen } from "../../../redux/slices/modalSlice";

const FutureEducation = ({ props }) => {
  const dispatch = useDispatch()
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(false)
  const { id } = useSelector(state => state.user.user)
  const isBasic = useSelector((state) => state.user.user.plan_type === 1);
  const totalPercent = useSelector(state => state.percentages.total)
  const children = useSelector(state => state.children.future)
  const futureEducationList = useSelector((state) => state.children.futureEducation);
  const percents = useSelector(state => state.percentages.percents)
  const isOpen = useSelector((state) => state.modal.isOpen);
  const [isCheck, setCheck] = useState(false);
  const loading = useSelector(state => state.sending.isSending)
  const mobileView = 999

  const handleResize = () => {
    (mobileView >= window.innerWidth) ? setIsMobile(true) : setIsMobile(false)
  }

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true)
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchFutureChildrenData())
      await dispatch(fetcFutureEducations())
      //await dispatch(fetchPercentages())
      await dispatch(fetchTotalPercent(isBasic))
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const futureEducation = getPercent(percents, 'future-education')

  const close = () => dispatch(setIsOpen({ isOpen: false }));

  const handleClick = () => {
    if (children && children.length > 9) {
      return dispatch(setIsOpen({ isOpen: true }));
    }

    return history.push({pathname: "/future-children/create", state: {type: 'education'}});
  };

  const onClose = () => {
    setCheck(false);
  };

  const handleCheck = () => {
    setCheck(true);
  };

  const handleConfirm = () => {
    dispatch(deactivateFutureEducation(props));
    setCheck(false);
  };

  return (
    <Layout totalPercent={totalPercent} tree>
      <div className="tree__sticky-holder">
        <BackButton
          title={isMobile ? "Back" : "Back to Tree"}
          path={isMobile ? "/future" : "/input"}
          className="back-button--position"
        />
        <div className="tree__title tree__title--progress-bar">
          <div className="tree__title-main">
            <h2>Future Education</h2>
            <TreeCount
              value={futureEducation}
              loading={loading}
              big={!isMobile}
            />
          </div>
          {isMobile && futureEducationList && futureEducationList.length === 0 && (
            <div className="tree__switcher">
              <Switch
                className="custom-switcher"
                checked={isCheck}
                onChange={handleCheck}
              />
              I don't plan to pay for anyone's education.
            </div>
          )}
          {!isMobile && (
            <>
              <p className="tree__title-other">
                Fill in information to complete this step.
              </p>
              <Button
                variant="contained"
                className="tree-bar"
                size="small"
                onClick={handleClick}
                color="primary"
              >
                Add Education
              </Button>
              {futureEducationList && futureEducationList.length === 0 && (
                <div className="tree__switcher">
                  <Switch
                    className="custom-switcher"
                    checked={isCheck}
                    onChange={handleCheck}
                  />
                  I don't plan to pay for anyone's education.
                </div>
              )}
            </>
          )}
        </div>
        <div className="tree tree--sub-page">
          {!isMobile && (
            <div className="tree__base">
              <img src={TreeImage} alt="ValueSoft Tree Insurance" />
            </div>
          )}
          {futureEducationList &&
            isArray(futureEducationList) &&
            futureEducationList.map((item, i) => (
              <React.Fragment key={item.id}>
                <TreeSubItem
                  key={item.id}
                  path={`/future-education/${item.id}`}
                  title={item.name}
                  count={item.percent}
                  value={`future-education-item-${i + 1}`}
                  isLoading={loading}
                />
              </React.Fragment>
            ))}
        </div>
        {isMobile && (
          <div className="tree__add-button">
            <Button
              variant="contained"
              size="small"
              onClick={handleClick}
              color="primary"
            >
              {!isMobile && 'Add Education'}
            </Button>
          </div>
        )}
      </div>
      <ModalBox
        small
        open={isOpen}
        close={close}
        onClick={() => {
          dispatch(setIsOpen({ isOpen: false }));
          history.push("/input");
        }}
        button="Back to tree"
        title="Sorry"
        children={<p>The limit of adding new sections is reached.</p>}
      />
      <ModalBox
        small
        open={isCheck}
        close={onClose}
        onClick={handleConfirm}
        button="Confirm"
        closeButton="Cancel"
        title="Future Education step"
        children={
          <p>
            This step will be disabled, but you can activate it any time by
            clicking on it in the your input.
          </p>
        }
      />
    </Layout>
  );
}

export default FutureEducation
