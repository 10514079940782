import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Form, Formik } from 'formik'
import Layout from '../../../components/Layout/Layout'
import LayoutForm from '../../../components/Layout/LayoutForm'
import { BackButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import { FormGroup, Input, InputPercent } from '../../../components/shared/Form'
import { Button, TextareaAutosize } from '@mui/material'
import Illustration from '../../../assets/images/illustrations/Tree-segment_protection-min.png'
import { fetchInsuranceLife, postInsuranceLife } from '../../../redux/slices/insuranceSlice'
import { insuranceLifeSchema } from '../../../utils/validationProtection'
import { fetchPercentages } from '../../../redux/slices/percentagesSlice'
import Modal from '../../../components/shared/Modal/Modal'
import ProgressCircle from '../../../components/shared/ProgressBar/ProgressCircle'
import { setIsOpen } from '../../../redux/slices/modalSlice'
import { getPercent } from '../../../utils/utils'

const Life = ({ props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.user.user);
  const insurance = useSelector(state => state.insurance.life);
  const sending = useSelector(state => state.sending.isSending);
  const percents = useSelector(state => state.percentages.percents);
  const isOpen = useSelector(state => state.modal.isOpen);
  const [isMobile, setIsMobile] = useState(false);
  const mobileView = 999;
  const handleResize = () => {
    (mobileView >= window.innerWidth) ? setIsMobile(true) : setIsMobile(false)
  }

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchPercentages());
      await dispatch(fetchInsuranceLife());
    }
    if (id) {
      fetch()
    }

    // eslint-disable-next-line
  }, [id])

  const onSubmit = (values) => {
    dispatch(postInsuranceLife(values, props))
  }

  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }))
  }

  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }))
    history.push("/input")
  }

  const handleChangeInput = (e, setValue) => {
    if([null, false].includes(e.target.value)) return false

    setValue(e.target.name, e.target.value)
  }

  const profile  = getPercent(percents, 'profile')
  const life     = getPercent(percents, 'life')

  return (
    <Layout isBlockedPage={percents && profile === 0}>
      <LayoutForm illustration={Illustration} small>
        <BackButton title={isMobile ? 'Back' : 'Back to Tree'} />
        <PageTitle
          title="Life insurance"
          content="Please answer a few life insurance related questions so that ValueSoft can figure out whether you have enough life insurance.  ValueSoft will analyze your life insurance needs and estimate your additional monthly premium."
          status={percents && life ? life : '0'}
          info={
            <>Life insurance should be a critical part of a person's financial plan.  ValueSoft looks at your current assets, liabilities, income, expenses, and expectations in the event of death to assess the amount of life insurance a person would need.  In general, the more liabilities and the less assets you have, the more life insurance you will need.</>
          }
        />
        <div className="layout-form__content-holder">
          <Formik
            enableReinitialize
            validationSchema={insuranceLifeSchema}
            initialValues={insurance}
            onSubmit={onSubmit}
          >
            {({ errors, handleChange, handleBlur, values, touched, setFieldValue }) => (
              <Form>
                <FormGroup name="percent_expenses_you" labelText="% of Total Expenses Attributable to you">
                  <InputPercent
                    placeholder="Type your % of Total Expenses Attributable"
                    name="percent_expenses_you"
                    onChange={handleChange}
                    value={values.percent_expenses_you || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.percent_expenses_you}
                  />
                  {errors.percent_expenses_you && touched.percent_expenses_you && <span className="form__msg-error">{errors.percent_expenses_you}</span>}
                </FormGroup>
                <FormGroup name="number_of_years_cover" labelText="Number of years looking to cover expenses for survivors">
                  <Input
                    placeholder="Type your number of years"
                    type="number"
                    name="number_of_years_cover"
                    // step={0.01}
                    onChange={e => handleChangeInput(e, setFieldValue)}
                    value={values.number_of_years_cover.toString() || ''}
                    info={{
                      content:
                        <>
                          Say you pass away tomorrow.  How many years of your family's expenses would you like to cover?  Do you only want to cover the expenses until your children are in college?  Or beyond that?  Think through this question and fill in the field with the number of years you'd like to cover.  The more the years, the greater your life insurance policy and hence the more expensive your monthly premium will be.
                          <hr className="tooltip__line"/>
                          <h6>Example</h6>
                          <div>Say you are married and have 2 small children, ages 3 and 4.  You have monthly expenses of $5000 which includes your mortgage, insurance, and all other expenses.  You'd like to keep your family financially secure for another 20 years should you pass away tomorrow.  In this case, enter 20 in this field.</div>
                        </>
                    }}
                    onBlur={handleBlur}
                    hasError={!!errors.number_of_years_cover && touched.number_of_years_cover}
                  />
                  {touched.number_of_years_cover && errors.number_of_years_cover && <span className="form__msg-error">{errors.number_of_years_cover}</span>}
                </FormGroup>
                <FormGroup name="spouse_pass_work" labelText="If spouse passed away, how many months would you be unable to work (e.g. due to grieving, funeral arrangement, etc.)? (optional)">
                  <Input
                    placeholder="Type spouse passed away"
                    type="number"
                    name="spouse_pass_work"
                    // step={0.01}
                    onChange={e => handleChangeInput(e, setFieldValue)}
                    value={values.spouse_pass_work ? values.spouse_pass_work.toString() : ''}
                    onBlur={handleBlur}
                    hasError={!!errors.spouse_pass_work}
                  />
                  {errors.spouse_pass_work && <span className="form__msg-error">{errors.spouse_pass_work}</span>}
                </FormGroup>
                <FormGroup name="spouse_years_cover" labelText="Years looking to cover spouse's income if spouse passes away (optional)">
                  <Input
                    placeholder="Type years looking to cover spouse's income "
                    type="number"
                    name="spouse_years_cover"
                    // step={0.01}
                    onChange={e => handleChangeInput(e, setFieldValue)}
                    value={values.spouse_years_cover ? values.spouse_years_cover.toString() : ''}
                    info={{
                      content:
                        <>
                          There are many 2-earner households and as a result, it would require serious re-strategizing of the surviving family members in the event that one of the bread-winners passes away (they may need to consider selling their home, moving to a less expensive state, etc.)
                          <hr className="tooltip__line"/>
                          <h6>Example</h6>
                          <div>Say you are married and depend on your wife's income to cover your current expenses.  That said, say you'd need 12 months to rethink your expenses strategy and reduce it somehow in the event your wife passes away.  In such a case, enter 12 in this field.</div>
                        </>
                    }}
                    onBlur={handleBlur}
                    hasError={!!errors.spouse_years_cover}
                  />
                  {errors.spouse_years_cover && <span className="form__msg-error">{errors.spouse_years_cover}</span>}
                </FormGroup>
                <FormGroup name="pass_work" labelText="If you passed away, how many months would your spouse be unable to work (e.g. due to grieving, funeral arrangement, etc.)? (optional)">
                  <Input
                    placeholder="Type spouse passed away"
                    type="number"
                    name="pass_work"
                    step={0.01}
                    onChange={e => handleChangeInput(e, setFieldValue)}
                    value={values.pass_work ? values.pass_work.toString() : ''}
                    onBlur={handleBlur}
                    hasError={!!errors.pass_work}
                  />
                  {errors.pass_work && <span className="form__msg-error">{errors.pass_work}</span>}
                </FormGroup>
                <FormGroup name="child_pass_work" labelText="If child passed away, how many months would you be unable to work (e.g. due to grieving, funeral arrangement, etc.)?	 (optional)">
                  <Input
                    placeholder="Type child passed away"
                    type="number"
                    name="child_pass_work"
                    // step={0.01}
                    onChange={e => handleChangeInput(e, setFieldValue)}
                    value={values.child_pass_work || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.child_pass_work}
                  />
                  {errors.child_pass_work && <span className="form__msg-error">{errors.child_pass_work}</span>}
                </FormGroup>
                <FormGroup name="child_pass_work_spouse" labelText="If child passed away, how many months would spouse be unable to work (e.g. due to grieving, funeral arrangement, etc.)? (optional)">
                  <Input
                    placeholder="Type child passed away"
                    type="number"
                    name="child_pass_work_spouse"
                    // step={0.01}
                    onChange={e => handleChangeInput(e, setFieldValue)}
                    value={values.child_pass_work_spouse || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.child_pass_work_spouse}
                  />
                  {errors.child_pass_work_spouse && <span className="form__msg-error">{errors.child_pass_work_spouse}</span>}
                </FormGroup>
                <h5 className="mt--medium">Additional info</h5>
                <FormGroup name="additional_info" labelText="Is there any other Life Insurance information that you believe is important for ValueSoft to know about? If so, please let us know about it:	">
                  <TextareaAutosize
                    maxLength="200"
                    name="additional_info"
                    value={values.additional_info || ''}
                    className="form__textarea"
                    placeholder="Maximum characters: 200"
                    onChange={handleChange}
                  />
                </FormGroup>

                <div className="form__button">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Save
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <Modal
          open={isOpen}
          small
          button="Done"
          onClick={handleClick}
          close={onClose}
        >
          <h3>Good Job!</h3>
          <p>Your progress increased 100%</p>
          <ProgressCircle amount={100} small />
        </Modal>
      </LayoutForm>
    </Layout>
  );
};

export default Life;
