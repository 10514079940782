import React from 'react'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'
import { options } from '../../utils/optionsBar'
import Loading from '../../components/shared/Loading/Loading'
import isNumber from 'lodash/isNumber'
import Slider from 'react-slick'
import Tooltip from '../../components/shared/Tooltip/Tooltip'

const AnalysisBox = ({
  title,
  tooltip,
  tooltipTitleOne,
  tooltipTitleTwo,
  tooltipDescriptionOne,
  tooltipDescriptionTwo,
  data,
  legendShow,
  legendText,
  step,
  split,
  maxLength,
  showLabel,
  amount,
  percent,
  loader,
  text,
  isMobile,
  mobileData,
  center,
  handleClick
}) => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  return (
    <div className="analysis__box">
      <div className="analysis__box-title">
        <div className="analysis__box-title-tooltip">
          <h6>{title}</h6>
          {tooltip && <Tooltip
              title={
                <>
                  <h6>{tooltipTitleOne}</h6>
                  {tooltipDescriptionOne}
                  <hr className="tooltip__line"/>
                  <h6>{tooltipTitleTwo}</h6>
                  {tooltipDescriptionTwo}
                </>
              }
            />
          }
        </div>
        {legendShow &&
          <div className="analysis__box-legend">
            <div className="analysis__box-legend-label analysis__box-legend-label-color2">
              You
            </div>
            <div className="analysis__box-legend-label">
              Client аverage
            </div>
            {!isNumber(legendShow) && <div className="analysis__box-legend-label analysis__box-legend-label-color3">
              Client Median
            </div>}
          </div>}
          {legendText &&
          <div className="analysis__box-legend">
            <div className="analysis__box-legend-label analysis__box-legend-label-color2">
                {legendText}
            </div>
          </div>}
      </div>
      {text && <div className={`analysis__box-subtitle${center ? ' analysis__box-subtitle--center' : ''}`}>{text}</div>}
      <div className="analysis__box-content">
        {loader
          ? <Loading small/>
          : isMobile && mobileData
            ? <Slider {...settings} className='analysis__box-slider'>
                {mobileData.length > 0 && mobileData.map((item, i) =>
                  <div key={`bar-${i}`}>
                    <Bar
                      data={item}
                      onElementsClick={handleClick}
                      options={options(step, showLabel, amount, split, maxLength, percent)}
                      // height={120}
                    />
                  </div>
                )}
              </Slider>
            : <Bar
                data={data}
                onElementsClick={handleClick}
                options={options(step, showLabel, amount, split, maxLength, percent)}
                height={110}
              />
        }
      </div>
    </div>
  )
}

AnalysisBox.propTypes = {
  title: PropTypes.string,
  text: PropTypes.any,
  data: PropTypes.any,
  step: PropTypes.string,
  legendShow: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number
  ]),
  legendText: PropTypes.string,
  tooltip: PropTypes.bool,
  tooltipTitleOne: PropTypes.string,
  tooltipTitleTwo: PropTypes.string,
  tooltipDescriptionOne: PropTypes.string,
  tooltipDescriptionTwo: PropTypes.string,
  showLabel: PropTypes.bool,
  amount: PropTypes.bool,
  loader: PropTypes.bool,
  mobileData: PropTypes.any,
  isMobile: PropTypes.bool,
  center: PropTypes.bool
}

export default AnalysisBox
