import { createSlice } from '@reduxjs/toolkit'
import { Export, FAQ } from '../../api/Api'
import { setIsRelogin } from "./loadingSlice";

const additionalSlice = createSlice({
  name: 'additional',
  initialState: {
    loading: false,
    faq: null,
    exportData: null,
    menuOpen: false,
    tours: false,
    playTour: false
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.loading = action.payload
    },
    setFAQ: (state, action) => {
      state.faq = action.payload
    },
    setExportData: (state, action) => {
      state.exportData = action.payload
    },
    setTours: (state, action) => {
      state.tours = {
        ...state.tours,
        ...action.payload
      };
    },
    setOpenMenu: (state, action) => {
      state.menuOpen = action.payload
    },
    setPlayTour: (state, action) => {
      state.playTour = action.payload
    }
  }
})

export const fetchFAQ = () => async (dispatch) => {
  try {
    await dispatch(setIsLoading( true));
    const response = await FAQ.get();
    await dispatch(setFAQ(response.data.data));
    await dispatch(setIsLoading( false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading( false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchExportData = () => async (dispatch) => {
  try {
    await dispatch(setIsLoading( true));
    const response = await Export.getSummary();
    await dispatch(setExportData(response.data.data));
    await dispatch(setIsLoading( false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading( false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchTourStatus = () => async (dispatch) => {
  try {
    const response = await Export.getTour();
    await dispatch(setTours(response.data.data));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postTourStatus = data => async (dispatch) => {
  try {
    await Export.postTour(data);
    await dispatch(setTours(data));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}




export const {
  setIsLoading,
  setFAQ,
  setExportData,
  setTours,
  setPlayTour,
  setOpenMenu
} = additionalSlice.actions

export default additionalSlice.reducer