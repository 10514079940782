import React from 'react'
import { Form, Formik } from 'formik'
import { Button } from "@mui/material";
import { FormGroup, Input, InputCurrency } from "../../components/shared/Form";
import Percentage from '../../assets/images/icons/percentage.svg'

const Additional = ({ title, onSave, values, coreValues, validationSchema, sending, noEdit }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{ ...values }}
      validationSchema={validationSchema}
      onSubmit={(values) => onSave(values)}
    >
      {({ errors, handleChange, handleBlur, values, touched }) => (
        <Form>
          <h4>{title}</h4>
          <div className="form__flow-wrap">
            <FormGroup name="fdic_insurance_limit" labelText="FDIC insurance limit ($)">
              <InputCurrency
                icon
                placeholder="Type FDIC insurance limit"
                name="fdic_insurance_limit"
                onChange={handleChange}
                value={values.fdic_insurance_limit || ''}
                onBlur={handleBlur}
                className={coreValues?.fdic_insurance_limit && !noEdit && 'form__input--core'}
                hasError={!!errors.fdic_insurance_limit && touched.fdic_insurance_limit}
              />
              {touched.fdic_insurance_limit && errors.fdic_insurance_limit ? (
                <span className="form__msg-error">{errors.fdic_insurance_limit}</span>
              ) : coreValues && coreValues.fdic_insurance_limit && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.fdic_insurance_limit}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="jewelry_cost" labelText="Jewelry insurance coverage cost as percent of jewelry value">
              <Input
                name="jewelry_cost"
                placeholder="Type"
                type="number"
                onChange={handleChange}
                value={values.jewelry_cost || ''}
                onBlur={handleBlur}
                iconName={Percentage}
                className={coreValues?.jewelry_cost && !noEdit && 'form__input--core'}
                hasError={!!errors.jewelry_cost && touched.jewelry_cost}
              />
              {touched.jewelry_cost && errors.jewelry_cost ? (
                <span className="form__msg-error">{errors.jewelry_cost}</span>
              ) : coreValues && coreValues.jewelry_cost && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.jewelry_cost}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="investment_return" labelText="Average investment return per year">
              <Input
                name="investment_return"
                placeholder="Type investment return"
                type="number"
                onChange={handleChange}
                value={values.investment_return || ''}
                onBlur={handleBlur}
                iconName={Percentage}
                className={coreValues?.investment_return && !noEdit && 'form__input--core'}
                hasError={!!errors.investment_return && touched.investment_return}
              />
              {touched.investment_return && errors.investment_return ? (
                <span className="form__msg-error">{errors.investment_return}</span>
              ) : coreValues && coreValues.investment_return && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.investment_return}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="valuecircle_threshold" labelText="Valuecircle unalignment threshold">
              <Input
                name="valuecircle_threshold"
                placeholder="Type"
                type="number"
                onChange={handleChange}
                value={values.valuecircle_threshold || ''}
                onBlur={handleBlur}
                className={coreValues?.valuecircle_threshold && !noEdit && 'form__input--core'}
                hasError={!!errors.valuecircle_threshold && touched.valuecircle_threshold}
              />
              {touched.valuecircle_threshold && errors.valuecircle_threshold ? (
                <span className="form__msg-error">{errors.valuecircle_threshold}</span>
              ) : coreValues && coreValues.valuecircle_threshold && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.valuecircle_threshold}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="tax_loss_reminder" labelText="Tax loss harvesting reminder month">
              <Input
                name="tax_loss_reminder"
                placeholder="Type"
                type="number"
                onChange={handleChange}
                value={values.tax_loss_reminder || ''}
                onBlur={handleBlur}
                className={coreValues?.tax_loss_reminder && !noEdit && 'form__input--core'}
                hasError={!!errors.tax_loss_reminder && touched.tax_loss_reminder}
              />
              {touched.tax_loss_reminder && errors.tax_loss_reminder ? (
                <span className="form__msg-error">{errors.tax_loss_reminder}</span>
              ) : coreValues && coreValues.tax_loss_reminder && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.tax_loss_reminder}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="start_rental_car_insurance" labelText="Rental car insurance reminder month (month start)">
              <Input
                name="start_rental_car_insurance"
                placeholder="Type"
                type="number"
                onChange={handleChange}
                value={values.start_rental_car_insurance || ''}
                onBlur={handleBlur}
                className={coreValues?.start_rental_car_insurance && !noEdit && 'form__input--core'}
                hasError={!!errors.start_rental_car_insurance && touched.start_rental_car_insurance}
              />
              {touched.start_rental_car_insurance && errors.start_rental_car_insurance ? (
                <span className="form__msg-error">{errors.start_rental_car_insurance}</span>
              ) : coreValues && coreValues.start_rental_car_insurance && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.start_rental_car_insurance}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="end_rental_car_insurance" labelText="Rental car insurance reminder month (month end)">
              <Input
                name="end_rental_car_insurance"
                placeholder="Type"
                type="number"
                onChange={handleChange}
                value={values.end_rental_car_insurance || ''}
                onBlur={handleBlur}
                className={coreValues?.end_rental_car_insurance && !noEdit && 'form__input--core'}
                hasError={!!errors.end_rental_car_insurance && touched.end_rental_car_insurance}
              />
              {touched.end_rental_car_insurance && errors.end_rental_car_insurance ? (
                <span className="form__msg-error">{errors.end_rental_car_insurance}</span>
              ) : coreValues && coreValues.end_rental_car_insurance && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.end_rental_car_insurance}</span>
              ) : (
                ''
              )}
            </FormGroup>
          </div>
          <div className="form__button">
            <Button variant="contained" size="large" color="primary" type="submit" disabled={sending || noEdit}>
              Save
              {sending && <span className="spinner" />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default Additional
