import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import ModalBox from '../../components/shared/Modal/Modal'
import SVGIcon from '../shared/SVGIcon/SVGIcon'
import Time from '../../assets/images/icons/time.svg'
import User from '../../assets/images/icons/user02.svg'
import Location from '../../assets/images/icons/location.svg'
import Mail from '../../assets/images/icons/mail.svg'
import More from '../../assets/images/icons/more.svg'
import Drawer from '../Task/TaskDrawer'
import { setMode } from '../../redux/slices/modeSlice'
import { setMsg } from "../../redux/slices/taskSlice";
import moment from "moment";


const ReminderCard = ({
  id,
  title,
  name,
  location,
  date,
  email,
  update,
  remove,
  showDetails
}) => {
  const dispatch = useDispatch()
  const [isOpen, setOpen] = useState(false)
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const [loadingTask, setLoadingTask] = useState(false)
  const popup = useRef()
  const currentReminder = useSelector(state => state.task.currentReminder)
  const mode = useSelector(state => state.mode.mode)

  const onToggle = () => setOpen(!isOpen);

  useEffect(() => {
    const listener = event => {
      if (!popup.current || popup.current.contains(event.target)) {
        return
      }

      setOpen(false)
    };

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [popup])

  const openDrawer = async () => {
    await setLoadingTask(true)
    await dispatch(setMode('reminder'))
    await setModalOpen(true)
    await showDetails(id)
    await setLoadingTask(false)
    await setOpen(false)
  }

  const closeDrawer = () => {
    setModalOpen(false)
    dispatch(setMode(null))
    dispatch(setMsg(""));
  }

  return (
    <>
      <div className="reminder-card">
        <div className="reminder-card__menu">
          <button onClick={onToggle}>
            <SVGIcon icon={More} />
          </button>
          <div ref={popup} className={`reminder-card__menu-drop${isOpen ? ' reminder-card__menu-drop--open' : ''}`}>
            <ul>
              <li><button onClick={() => openDrawer()}>Edit</button></li>
              <li><button onClick={() => setIsOpenRemoveModal(true)}>Remove</button></li>
            </ul>
          </div>
        </div>
        <div className="reminder-card__inner" onClick={() => openDrawer()}>
          <h5>{title}</h5>
          <ul className="reminder-card__options">
            <li> <SVGIcon icon={User} /> {name}</li>
            <li> <SVGIcon icon={Mail} /> {email}</li>
            <li> <SVGIcon icon={Time} /> {moment(date).format("MM-DD-YYYY")}</li>
            <li> <SVGIcon icon={Location} /> {location} </li>
          </ul>
        </div>
      </div>
      <ModalBox
        small
        inline
        open={isOpenRemoveModal}
        close={() => setIsOpenRemoveModal(false)}
        onClick={() => {
          remove(id)
          setIsOpenRemoveModal(false)
        }}
        button="Remove"
        closeButton="No"
        title="Are you sure?"
      />

      <Drawer
        loadingTask={loadingTask}
        isOpen={isModalOpen}
        onClose={closeDrawer}
        id={id}
        titleReminder="Reminder"
        mode={mode}
        reminderDetails={currentReminder}
        updateReminder={update}
      />
    </>
  )
}

ReminderCard.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  remove: PropTypes.func,
  update: PropTypes.func,
  name: PropTypes.string,
  location: PropTypes.string,
  date: PropTypes.string,
  email: PropTypes.string,
}

export default ReminderCard



