import React, { useEffect } from 'react'
import { Link, Element } from 'react-scroll'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom';
import LayoutAdmin from '../../components/Layout/LayoutAdmin'
import Loading from '../../components/shared/Loading/Loading'
import { Form, Formik } from 'formik'
import { FormGroup, Input } from '../../components/shared/Form'
import { Button } from '@mui/material'
import { ThresholdsSchema } from '../../utils/validation'
import { fetchThresholds, postThresholds } from '../../redux/slices/notificationsSlice'
import { fetchTaskAlerts } from '../../redux/slices/taskSlice'
import TaskAlert from '../../components/Task/TaskAlert'
import { isEmpty, isNull } from 'lodash'
import { postTaskAlerts } from '../../redux/slices/taskSlice'
import { fetchCompany } from '../../redux/slices/companiesSlice';


const AdminAlerts = ({ props, isAdmin }) => {
  const dispatch = useDispatch()
  const { id } = useSelector(state => state.user.user)
  const thresholds = useSelector(state => state.notifications.thresholds)
  const { permissions } = useSelector(state => state.companies.company.info);
  const sending = useSelector(state => state.sending.isSending);
  const { priorities } = useSelector(state => state.options)
  const { isLoading } = useSelector(state => state.assumptions);
  const { profile, goals, education, budgeting, document, retirement, insurance, estate } = useSelector(state => state.task.taskAlerts);
  const { companyId } = useParams();

  const baseValues = {
    superb_number_of_tasks: null,
    great_number_of_tasks: null,
    ok_to_great_number_of_tasks: null,
    ok_number_of_tasks: null,
    ok_to_poor_number_of_tasks: null,
    poor_number_of_tasks: null,
    really_poor_number_of_tasks: null
  }

  useEffect(() => {
    dispatch(fetchCompany(companyId));
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    async function fetch() {
      Promise.all([
        dispatch(fetchThresholds(companyId)),
        dispatch(fetchTaskAlerts({ id: 1 })),
        dispatch(fetchTaskAlerts({ id: 2 })),
        dispatch(fetchTaskAlerts({ id: 3 })),
        dispatch(fetchTaskAlerts({ id: 4 })),
        dispatch(fetchTaskAlerts({ id: 5 })),
        dispatch(fetchTaskAlerts({ id: 6 })),
        dispatch(fetchTaskAlerts({ id: 7 })),
        dispatch(fetchTaskAlerts({ id: 8 })),
      ]);
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const onSubmit = (values) => {
    dispatch(postThresholds(companyId, values));
  }

  if (!isNull(permissions?.alerts) && !permissions?.alerts) return (<Redirect to="/404" />)

  return (
    <LayoutAdmin companyId={companyId} isAdmin={isAdmin} admin>
      <div className="admin-tasks">
        <div className="admin__side">
          <div className="admin__side-sticky">
            <h3 className="mobile-large-visible">Alerts</h3>
            <h6 className="mobile-large-hidden">Navigation</h6>
            <ul className="admin__side-nav">
              <li>
                <Link
                  activeClass="active"
                  className="admin__side-nav-item"
                  to="thresholds"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-30}
                >
                  Alert Thresholds
                </Link>
              </li>
              {profile && !isEmpty(profile) && (
                <li>
                  <Link
                    activeClass="active"
                    className="admin__side-nav-item"
                    to={`${profile.id}`}
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-30}
                  >
                    {profile.name}
                  </Link>
                </li>
              )}
              {goals && !isEmpty(goals) && (
                <li>
                  <Link
                    activeClass="active"
                    className="admin__side-nav-item"
                    to={`${goals.id}`}
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-30}
                  >
                    {goals.name}
                  </Link>
                </li>
              )}
              {education && !isEmpty(education) && (
                <li>
                  <Link
                    activeClass="active"
                    className="admin__side-nav-item"
                    to={`${education.id}`}
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-30}
                  >
                    {education.name}
                  </Link>
                </li>
              )}
              {budgeting && !isEmpty(budgeting) && (
                <li>
                  <Link
                    activeClass="active"
                    className="admin__side-nav-item"
                    to={`${budgeting.id}`}
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-30}
                  >
                    {budgeting.name}
                  </Link>
                </li>
              )}
              {document && !isEmpty(document) && (
                <li>
                  <Link
                    activeClass="active"
                    className="admin__side-nav-item"
                    to={`${document.id}`}
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-30}
                  >
                    {document.name}
                  </Link>
                </li>
              )}
              {retirement && !isEmpty(retirement) && (
                <li>
                  <Link
                    activeClass="active"
                    className="admin__side-nav-item"
                    to={`${retirement.id}`}
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-30}
                  >
                    {retirement.name}
                  </Link>
                </li>
              )}
              {insurance && !isEmpty(insurance) && (
                <li>
                  <Link
                    activeClass="active"
                    className="admin__side-nav-item"
                    to={`${insurance.id}`}
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-30}
                  >
                    {insurance.name}
                  </Link>
                </li>
              )}
              {estate && !isEmpty(estate) && (
                <li>
                  <Link
                    activeClass="active"
                    className="admin__side-nav-item"
                    to={`${estate.id}`}
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-30}
                  >
                    {estate.name}
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="admin__content">
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <Element name="thresholds" className="admin__content-section">
                <div className="admin__content-holder">
                  <h3 className="mobile-large-hidden">Alerts</h3>
                  <h5>Alert Thresholds</h5>
                  <Formik
                    enableReinitialize
                    initialValues={thresholds ? { ...thresholds } : baseValues}
                    validationSchema={ThresholdsSchema}
                    onSubmit={onSubmit}
                  >
                    {({ errors, handleChange, handleBlur, values, touched }) => (
                      <Form>
                        <FormGroup name="superb_number_of_tasks" labelText="Superb number of tasks">
                          <Input
                            name="superb_number_of_tasks"
                            type="number"
                            onChange={handleChange}
                            value={values.superb_number_of_tasks || ''}
                            onBlur={handleBlur}
                            hasError={!!errors.superb_number_of_tasks && touched.superb_number_of_tasks}
                          />
                          {touched.superb_number_of_tasks && errors.superb_number_of_tasks && (
                            <span className="form__msg-error">{errors.superb_number_of_tasks}</span>
                          )}
                        </FormGroup>
                        <FormGroup name="great_number_of_tasks" labelText="Great number of tasks">
                          <Input
                            name="great_number_of_tasks"
                            type="number"
                            onChange={handleChange}
                            value={values.great_number_of_tasks || ''}
                            onBlur={handleBlur}
                            hasError={!!errors.great_number_of_tasks && touched.great_number_of_tasks}
                          />
                          {touched.great_number_of_tasks && errors.great_number_of_tasks && (
                            <span className="form__msg-error">{errors.great_number_of_tasks}</span>
                          )}
                        </FormGroup>
                        <FormGroup name="ok_to_great_number_of_tasks" labelText="Ok to great number of tasks">
                          <Input
                            name="ok_to_great_number_of_tasks"
                            type="number"
                            onChange={handleChange}
                            value={values.ok_to_great_number_of_tasks || ''}
                            onBlur={handleBlur}
                            hasError={!!errors.ok_to_great_number_of_tasks && touched.ok_to_great_number_of_tasks}
                          />
                          {touched.ok_to_great_number_of_tasks && errors.ok_to_great_number_of_tasks && (
                            <span className="form__msg-error">{errors.ok_to_great_number_of_tasks}</span>
                          )}
                        </FormGroup>
                        <FormGroup name="ok_number_of_tasks" labelText="Ok number of tasks">
                          <Input
                            name="ok_number_of_tasks"
                            type="number"
                            onChange={handleChange}
                            value={values.ok_number_of_tasks || ''}
                            onBlur={handleBlur}
                            hasError={!!errors.ok_number_of_tasks && touched.ok_number_of_tasks}
                          />
                          {touched.ok_number_of_tasks && errors.ok_number_of_tasks && (
                            <span className="form__msg-error">{errors.ok_number_of_tasks}</span>
                          )}
                        </FormGroup>
                        <FormGroup name="ok_to_poor_number_of_tasks" labelText="Ok to poor number of tasks">
                          <Input
                            name="ok_to_poor_number_of_tasks"
                            type="number"
                            onChange={handleChange}
                            value={values.ok_to_poor_number_of_tasks || ''}
                            onBlur={handleBlur}
                            hasError={!!errors.ok_to_poor_number_of_tasks && touched.ok_to_poor_number_of_tasks}
                          />
                          {touched.ok_to_poor_number_of_tasks && errors.ok_to_poor_number_of_tasks && (
                            <span className="form__msg-error">{errors.ok_to_poor_number_of_tasks}</span>
                          )}
                        </FormGroup>
                        <FormGroup name="poor_number_of_tasks" labelText="Poor number of tasks">
                          <Input
                            name="poor_number_of_tasks"
                            type="number"
                            onChange={handleChange}
                            value={values.poor_number_of_tasks || ''}
                            onBlur={handleBlur}
                            hasError={!!errors.poor_number_of_tasks && touched.poor_number_of_tasks}
                          />
                          {touched.poor_number_of_tasks && errors.poor_number_of_tasks && (
                            <span className="form__msg-error">{errors.poor_number_of_tasks}</span>
                          )}
                        </FormGroup>
                        <FormGroup name="really_poor_number_of_tasks" labelText="Really poor number of tasks">
                          <Input
                            name="really_poor_number_of_tasks"
                            type="number"
                            onChange={handleChange}
                            value={values.really_poor_number_of_tasks || ''}
                            onBlur={handleBlur}
                            hasError={!!errors.really_poor_number_of_tasks && touched.really_poor_number_of_tasks}
                          />
                          {touched.really_poor_number_of_tasks && errors.really_poor_number_of_tasks && (
                            <span className="form__msg-error">{errors.really_poor_number_of_tasks}</span>
                          )}
                        </FormGroup>
                        <div className="form__button form__button--center">
                          <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                            Save
                            {sending && <span className="spinner" />}
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Element>
              {profile && !isEmpty(profile) && (
                <Element name={`${profile.id}`} className="admin__content-section">
                  <div className="admin__content-holder">
                    <h5>{profile.name}</h5>

                    {profile.tasks && profile.tasks.length > 0 && (
                      <TaskAlert values={profile.tasks} options={priorities} onSubmit={postTaskAlerts} />
                    )}
                  </div>
                </Element>
              )}

              {goals && !isEmpty(goals) && (
                <Element name={`${goals.id}`} className="admin__content-section">
                  <div className="admin__content-holder">
                    <h5>{goals.name}</h5>

                    {goals.tasks && goals.tasks.length > 0 && (
                      <TaskAlert values={goals.tasks} options={priorities} onSubmit={postTaskAlerts} />
                    )}
                  </div>
                </Element>
              )}

              {education && !isEmpty(education) && (
                <Element name={`${education.id}`} className="admin__content-section">
                  <div className="admin__content-holder">
                    <h5>{education.name}</h5>

                    {education.tasks && education.tasks.length > 0 && (
                      <TaskAlert values={education.tasks} options={priorities} onSubmit={postTaskAlerts} />
                    )}
                  </div>
                </Element>
              )}

              {budgeting && !isEmpty(budgeting) && (
                <Element name={`${budgeting.id}`} className="admin__content-section">
                  <div className="admin__content-holder">
                    <h5>{budgeting.name}</h5>

                    {budgeting.tasks && budgeting.tasks.length > 0 && (
                      <TaskAlert values={budgeting.tasks} options={priorities} onSubmit={postTaskAlerts} />
                    )}
                  </div>
                </Element>
              )}

              {document && !isEmpty(document) && (
                <Element name={`${document.id}`} className="admin__content-section">
                  <div className="admin__content-holder">
                    <h5>{document.name}</h5>

                    {document.tasks && document.tasks.length > 0 && (
                      <TaskAlert values={document.tasks} options={priorities} onSubmit={postTaskAlerts} />
                    )}
                  </div>
                </Element>
              )}

              {document && !isEmpty(document) && (
                <Element name={`${document.id}`} className="admin__content-section">
                  <div className="admin__content-holder">
                    <h5>{document.name}</h5>

                    {document.tasks && document.tasks.length > 0 && (
                      <TaskAlert values={document.tasks} options={priorities} onSubmit={postTaskAlerts} />
                    )}
                  </div>
                </Element>
              )}

              {retirement && !isEmpty(retirement) && (
                <Element name={`${retirement.id}`} className="admin__content-section">
                  <div className="admin__content-holder">
                    <h5>{retirement.name}</h5>

                    {retirement.tasks && retirement.tasks.length > 0 && (
                      <TaskAlert values={retirement.tasks} options={priorities} onSubmit={postTaskAlerts} />
                    )}
                  </div>
                </Element>
              )}

              {insurance && !isEmpty(insurance) && (
                <Element name={`${insurance.id}`} className="admin__content-section">
                  <div className="admin__content-holder">
                    <h5>{insurance.name}</h5>

                    {insurance.tasks && insurance.tasks.length > 0 && (
                      <TaskAlert values={insurance.tasks} options={priorities} onSubmit={postTaskAlerts} />
                    )}
                  </div>
                </Element>
              )}

              {estate && !isEmpty(estate) && (
                <Element name={`${estate.id}`} className="admin__content-section">
                  <div className="admin__content-holder">
                    <h5>{estate.name}</h5>

                    {estate.tasks && estate.tasks.length > 0 && (
                      <TaskAlert values={estate.tasks} options={priorities} onSubmit={postTaskAlerts} />
                    )}
                  </div>
                </Element>
              )}
            </>
          )}
        </div>
        <div className="admin__side admin__side--alt admin__side--mobile-hide" />
      </div>
    </LayoutAdmin>
  );

}

export default AdminAlerts
