import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@mui/material'
import { Formik, Form } from 'formik'
import AuthContent from '../../components/Auth/AuthContent'
import { PersonalInfoSchema } from '../../utils/validation'
import { FormGroup, Input, FormSelect } from '../../components/shared/Form'
import Screen from '../../assets/images/screen-img.png'
import years from '../../Data/years'
import Steps from '../../components/Auth/Steps'
import { registerUser, setPersonalInfo } from '../../redux/slices/authSlice'
import { MAIN_SITE } from '../../constants/additional_links'

const steps = [
  {
    title: 'Account details',
    active: false,
    done: true
  },
  {
    title: 'Personal details',
    active: true,
    done: false
  }
];

const SignUpPersonalInfo = ({ props }) => {
  const dispatch = useDispatch();
  const base = useSelector(state => state.auth.registerUser);
  const sending = useSelector(state => state.sending.isSending);

  const onSubmit = values => {
    dispatch(setPersonalInfo(values));
    dispatch(registerUser(values, props))
  };

  return (
    <AuthContent decor={{image: Screen }} close={MAIN_SITE}>
      <h4>Personal info</h4>
      <Steps options={steps} />
      <Formik
        initialValues={base}
        validationSchema={PersonalInfoSchema}
        validateOnChange={false}
        onSubmit={onSubmit}
      >
        {({ errors, handleChange, handleBlur, values, touched, setFieldValue }) => (
          <Form>
            <div>
              <FormGroup name="first_name" labelText="First Name">
                <Input
                  placeholder="Enter your name"
                  name="first_name"
                  type="text"
                  value={values.first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={!!errors.first_name && touched.first_name}
                />
                {touched.first_name && errors.first_name && <span className="form__msg-error">{errors.first_name}</span>}
              </FormGroup>
              <FormGroup name="last_name" labelText="Last Name">
                <Input
                  placeholder="Enter your last name"
                  name="last_name"
                  type="text"
                  value={values.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={!!errors.last_name && touched.last_name}
                />
                {touched.last_name && errors.last_name && <span className="form__msg-error">{errors.last_name}</span>}
              </FormGroup>
              <FormGroup name="year_of_birth" labelText="Year of birth">
                <FormSelect
                  options={years}
                  id="year_of_birth"
                  value={values.year_of_birth}
                  name="year_of_birth"
                  onChange={(e) => {
                    setFieldValue(e.target.name, e.target.value)
                  }}
                  placeholder="Select your year of birth"
                  hasError={!!errors.year_of_birth && touched.year_of_birth}
                />
                {touched.year_of_birth && errors.year_of_birth && <span className="form__msg-error">{errors.year_of_birth}</span>}
              </FormGroup>
              <div className="form__button">
                <Button variant="contained" size="large" color="primary" type="submit" disabled={!values.first_name || !values.last_name || !values.year_of_birth || sending}>
                  Create account
                  {sending && <span className="spinner" />}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </AuthContent>
  );
};

export default SignUpPersonalInfo;
