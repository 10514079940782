const optionsDays = [
  { id: 1, key: 1, value: 0, label: "Sunday" },
  { id: 2, key: 2, value: 1, label: "Monday" },
  { id: 3, key: 3, value: 2, label: "Tuesday" },
  { id: 4, key: 4, value: 3, label: "Wednesday" },
  { id: 5, key: 5, value: 4, label: "Thursday" },
  { id: 6, key: 6, value: 5, label: "Friday" },
  { id: 7, key: 7, value: 6, label: "Saturday" },
];

export default optionsDays;