import React from 'react'
import PropTypes from 'prop-types'
import { ReactSVG } from 'react-svg'

const SVGIcon = ({ color, icon, size, className }) => {
  return (
    <ReactSVG
      src={icon}
      fallback={() => <span>Error!</span>}
      loading={() => <span />}
      renumerateIRIElements={false}
      style={{
        color: color,
        height: size,
        width: size,
      }}
      className={`icon-svg ${className || ''} ${color ? '' : 'icon-svg--default'}`}
    />
  );
};

SVGIcon.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string.isRequired,
  size: PropTypes.string,
  className: PropTypes.string
};

export default SVGIcon;
