import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink, Link } from 'react-router-dom'
import Logo from '../../assets/images/logo-invert.svg'
import FooterMenu from '../../Data/footerMenu'
import FeedBack from '../Feedback/Feedback'


const Footer = ({ user }) => {
  const { company_logo, company_id } = useSelector(state => state.user.user)

  return (
    <div className={`footer ${company_id !== 1 ? 'footer--custom' : ''}`}>
      <div className={`footer__logo ${(company_id !== 1 && company_logo) ? 'footer__logo--custom' : ''}`}><Link to="/dashboard"><img src={(company_id !== 1 && company_logo) ? company_logo : Logo} alt="ValueSoft" /></Link></div>
      <ul className="footer__menu">
        <li>
          <FeedBack
            user={user}
            title="Share Your Feedback"
          />
        </li>

        {FooterMenu.map((item) => {
          return (
            <li key={item.title}>
              <NavLink
                className="footer__menu-link"
                to={item.path}
              >
                {item.title}
              </NavLink>
            </li>
          )
        })}
      </ul>
    </div>
  )
};

export default Footer
