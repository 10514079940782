import * as Yup from 'yup'
import { convertDecimal } from './utils'

const currentYear = new Date().getFullYear()

export const emailFormat = Yup.string()
  .email('Wrong e-mail address')
  .required('Required')


const passwordFormat = Yup.string()
  .required('Required')
  .min(8, 'Password too short')
  .max(50, 'Password too long')
  .matches(/(?=.*[a-z])(?=.*[A-Z])/, 'Tip: include uppercase and lowercase characters')
  .matches(/(?=.*\d)/, 'Tip: include numbers')


const phoneNumber = Yup.string()
    .nullable()
    .matches(/^\(([0-9]{3})\)[ ]?([0-9]{3})[-]([0-9]{4})$/, 'Invalid phone')
    .required('Phone is required')

export const decimalNumber = Yup.string()
  .matches(/^\d+([,.]\d{1,2})?$/, 'Invalid format, should be number')


export const SignUpSchema = Yup.object().shape({
  email: emailFormat,
  phone: phoneNumber,
  password: passwordFormat,
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Required'),
  agreed: Yup.bool().oneOf([true], 'Field must be checked'),
  recaptcha: Yup.string().required('Required')
});

export const SignUpCompanySchema = Yup.object().shape({
  email: emailFormat,
  phone: phoneNumber,
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  company_name: Yup.string().required('Required'),
  company_website: Yup.string()
  .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Enter correct url!'
  ).required('Required'),
  about_company: Yup.string().required('Required'),
  recaptcha: Yup.string().nullable().required('Required')
});

export const InfoCompanySchema = Yup.object().shape({
  name: Yup.string().nullable().required('Required'),
  website: Yup.string()
  .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Enter correct url!'
  ).required('Required'),
  vsft_name: Yup.string().nullable().required('Required'),
  ein: Yup.string().nullable().required('Required'),
  email: emailFormat,
  address: Yup.string().nullable().required('Required'),
  optional_address: Yup.string().nullable().notRequired(),
  city: Yup.string().nullable().required('Required'),
  state: Yup.string().nullable().required('Required'),
  zip: Yup.string().nullable().required('Required'),
});

export const AdminCompanySchema = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  email: Yup.string().required('Required'),
});

export const AdminEditMemberSchema = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  email: Yup.string().notRequired(),
});

export const AdminCompanyMemberSchema = Yup.object().shape({
  email: Yup.string().required('Required')
});

export const PersonalInfoSchema = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  year_of_birth: Yup.string().required('Required'),
});


export const LoginSchema = Yup.object().shape({
  email: emailFormat,
  password: Yup.string().required('Required')
});


export const CreatePasswordSchema = Yup.object().shape({
  password: passwordFormat,
  password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Required'),
});


export const ResetPasswordSchema = Yup.object().shape({
  old_password: passwordFormat,
  password: passwordFormat,
  password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Required'),
});


export const ProfileSchema = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  year_of_birth: Yup.number().nullable().min(1900, 'Please enter valid year').max(currentYear, 'Please enter valid year').required('Required'),
  gender: Yup.string().nullable().required('Required'),
  phone: phoneNumber,
  email: emailFormat
});

export const InfoUserSchema = Yup.object().shape({
  email: emailFormat,
  first_name: Yup.string().required('Required'),
  // last_name: Yup.string().required('Required'),
  year_of_birth: Yup.number().nullable().min(1900, 'Please enter valid year').max(currentYear, 'Please enter valid year').required('Required'),
  phone: phoneNumber,
  gender: Yup.string().nullable().required('Required'),
  address: Yup.string().nullable().required('Required'),
});

export const DetailsUserSchema = Yup.object().shape({
  gross_salary: Yup.string().nullable().max(18, 'Please check this value').required('Required'),
  future_salary: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  retirement_age: Yup.number().integer('Please check this value').nullable().max(100, 'Please check this value, must be' +
    ' less').required('Required'),
  social_security_analysis: Yup.string().required('Required'),
  has_life_insurance: Yup.boolean().required('Required'),
  life_insurance_amount: Yup.string()
    .nullable()
    .when('has_life_insurance', {
      is: true,
      then: Yup.string().required('Required')
    }),
  is_smoke: Yup.boolean().required('Required'),
  tax_filing_status: Yup.string().required('Required'),
  is_married: Yup.boolean().required('Required'),
  is_auto: Yup.boolean().required('Required'),
  credit_score: Yup.number().integer('Please check this value').nullable().required('Required'),
});

export const UserChildrenSchema = Yup.object().shape({
  total_children: Yup.number().integer('Please check this value').nullable().max(100, 'Please check the number').required('Required'),
  prior_children: Yup.number().integer('Please check this value').nullable().max(100, 'Please check the number').notRequired()
});

export const UserFamilyOthersSchema = Yup.object().shape({
  miles_travelled_per_day: Yup.number().nullable().max(100001, 'Please check miles').required('Required')
});

export const DetailsUserSpouseSchema = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  gross_salary: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  future_salary: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
  retirement_age: Yup.number().integer('Please check this value').nullable().max(100, 'Must be less').notRequired(),
  year_of_birth: Yup.number().nullable().min(1900, 'Please enter valid year').max(currentYear, 'Please enter valid year').required('Required'),
  has_life_insurance: Yup.boolean().required('Required'),
  life_insurance_amount: Yup.string()
    .nullable()
    .when('has_life_insurance', {
      is: true,
      then: Yup.string().min(1).required('Required')
    }),
  spouse_credit_score: Yup.number().integer('Please check this value').nullable().required('Required'),  
});

export const RiskSchema = Yup.object().shape({
  overall_risk: Yup.string().nullable().required('Required'),
  preferred_portfolio: Yup.string().nullable().required('Required'),
  return_risk: Yup.string().nullable().required('Required'),
  lose_twenty: Yup.string().nullable().required('Required'),
  lose_fifty: Yup.string().nullable().required('Required'),
  bengraham: Yup.string().nullable().required('Required')
});

export const RiskPortfolioSchema = Yup.object().shape({
  portfolio_now: Yup.string().nullable().required('Required'),
  portfolio_thirty: Yup.string().nullable().required('Required'),
  portfolio_sixty: Yup.string().nullable().required('Required'),
  portfolio_eighty: Yup.string().nullable().required('Required')
});


export const collectSchema = Yup.object().shape({
  collect: Yup.array()
    .of(
      Yup.object().shape(
        {
          requires_money: Yup.boolean().notRequired(),
          name: Yup.string().when('value_id', {
            is: (value_id) => value_id,
            then: Yup.string().required(),
            otherwise: Yup.string().notRequired(),
          }),
          value_id: Yup.number().when(['name', 'requires_money'], {
            is: (name, requires_money) => name || requires_money,
            then: Yup.number().required(),
            otherwise: Yup.number().notRequired(),
          }),
        },
        [
          ['name', 'value_id', 'requires_money'],
        ]
      )
    )
    .notRequired()
})


export const valuesSchema = Yup.object().shape({
  values: Yup.array().of(
    Yup.object().shape({
      current_ranking: Yup.number().min(1).required('Required'),
      preferred_ranking: Yup.number().min(1).required('Required'),
    })
  )
})


export const EducationSchema = Yup.object().shape({
  your_age_when_child_high_school: Yup.number().nullable().max(151, 'Must be less').required('Required'),
  high_school_type: Yup.string().required('Required'),
  your_age_when_child_college: Yup.number().nullable()
    .moreThan(Yup.ref('your_age_when_child_high_school'), 'Should be more than Your age when child began/begins high school')
    .max(151, 'Must be less')
    .required('Required'),
  college_type: Yup.string().required('Required'),
  pay_for_tuition: Yup.boolean().required('Required'),
  tuition_amount: Yup.string().nullable(),
  funding_529b_plan: Yup.boolean().required('Required'),
  yearly_funding_amount: Yup.string().nullable().max(18, 'Please check this value')
    .when('funding_529b_plan', {
      is: true,
      then: Yup.string()
        .test('val', 'Please check this value', value => convertDecimal(value) >= 0)
        .required('Required')
    }),
  account_amount: Yup.string().nullable().max(18, 'Please check this value')
    .when('funding_529b_plan', {
      is: true,
      then: Yup.string()
        .test('val', 'Please check this value', value => convertDecimal(value) >= 0)
        .required('Required')
    }),
})


export const FutureEducationSchema = Yup.object().shape({
  your_age_when_child_high_school: Yup.number().nullable().max(151, 'Must be less').required('Required'),
  high_school_type: Yup.string().required('Required'),
  your_age_when_child_college: Yup.number().nullable()
    .moreThan(Yup.ref('your_age_when_child_high_school'), 'Should be more than Your age when child begins high school')
    .max(151, 'Must be less').required('Required'),
  college_type: Yup.string().required('Required'),
  pay_for_tuition: Yup.boolean().required('Required'),
  // tuition_amount: Yup.number().nullable().required('Required'),
  funding_529b_plan: Yup.boolean().required('Required'),
  yearly_funding_amount: Yup.string()
    .nullable()
    .when('funding_529b_plan', {
      is: true,
      then: Yup.string()
        .test('val', 'Please check this value', value => convertDecimal(value) > 0.1)
        .max(18, 'Please check this value').required('Required'),
    })
})


export const TaskCreateSchema = Yup.object().shape({
  priority_id: Yup.number().nullable().required('Required'),
  status_id: Yup.number().nullable().required('Required'),
  category_id: Yup.number().nullable().required('Required'),
  name: Yup.string().required('Required'),
  description: Yup.string().required('Required')
})

export const AdminTaskCreateSchema = Yup.object().shape({
  priority_id: Yup.number().nullable().required('Required'),
  category_id: Yup.number().nullable().required('Required'),
  name: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  condition: Yup.string().required('Required'),
})

export const TaskReminderSchema = Yup.object().shape({
  organizer_name: Yup.string().required('Required'),
  required_attendee: Yup.string().required('Required'),
  organizer_email: emailFormat,
  attendee_email: emailFormat,
  meeting_location: Yup.string().required('Required'),
  task_due_date: Yup.string().required('Required'),
  personalized_message: Yup.string().required('Required'),
})

export const FeedBackSchema = Yup.object().shape({
  email: emailFormat,
  name: Yup.string().required('Required'),
  surname: Yup.string().required('Required'),
  reason: Yup.string().required('Required'),
  message: Yup.string().required('Required')
});

export const ThresholdsSchema = Yup.object().shape({
  superb_number_of_tasks: Yup.number().nullable().required('Required'),
  great_number_of_tasks: Yup.number().nullable().required('Required'),
  ok_to_great_number_of_tasks: Yup.number().nullable().required('Required'),
  ok_number_of_tasks: Yup.number().nullable().required('Required'),
  ok_to_poor_number_of_tasks: Yup.number().nullable().required('Required'),
  poor_number_of_tasks: Yup.number().nullable().required('Required'),
  really_poor_number_of_tasks: Yup.number().nullable().required('Required')
});

Yup.addMethod(Yup.array, "uniquePlanningTypePriorities", function (message) {
  return this.test("unique", message, function (list) {
    const mapper = x => x.priority;
    const set = [...new Set(list.map(mapper))];
    const isUnique = list.length === set.length;
    if (isUnique) {
      return true;
    }

    const idx = list.findIndex((l, i) => mapper(l) !== set[i]);
    return this.createError({
      path: `planning_type_priorities[${idx}]`,
      message: message,
    });
  });
});

export const AdminPlansSchema = Yup.array().of(
  Yup.object().shape({
    planning_type_priorities: Yup.array().of(
      Yup.object().shape({
        priority: Yup.number()
      })
    )
      .uniquePlanningTypePriorities(true)
  })
);