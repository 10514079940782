import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useHistory, useLocation } from "react-router-dom";
import clsx from 'classnames'
import { mainMenu } from '../../Data/mainMenu'
import { Button, IconButton } from '@mui/material'
import SVGIcon from '../shared/SVGIcon/SVGIcon'
import NotificationIcon from '../../assets/images/icons/notification.svg'
import Logo from '../../components/shared/Logo/Logo'
import UserAvatar from '../shared/UserAvatar/UserAvatar'
import SearchBox from '../SearchBox/SearchBox'
import NotificationCard from '../../components/Cards/NotificationCard'
import debounce from 'lodash/debounce'
import { searchData, setSearchParam } from '../../redux/slices/searchSlice'
import { Drawer } from '@mui/material'
import placeholderNotification from '../../assets/images/placeholderNotification.png'
import isEmpty from 'lodash/isEmpty'
import { PDFDownload }  from '../../components/PDFDownload/PDFDownLoad'
import TourButton from '../../components/Tour/TourButton'
import { setOpenMenu } from '../../redux/slices/additionalSlice'
import { fetchCompanyPermissions } from '../../redux/slices/companiesSlice';
import { fetchUserData } from '../../redux/slices/userSlice'

const Header = ({ isMobile, notifications, removeNotification, runTour, animate }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation();
  const [openNotifications, setOpenNotifications] = useState(false)
  const { avatar, is_admin, company_id } = useSelector(state => state.user.user)
  const { tasks } = useSelector(state => state.task)
  const companyPermissions = useSelector((state) => state.companies.company.info.permissions);
  const openMenu = useSelector(state => state.additional.menuOpen)
  const [value, setValue] = useState('')
  const token_admin = localStorage.token_admin;

  useEffect(() => {
    openMenu
      ? document.body.style.overflow = 'hidden'
      : document.body.style.overflow = 'visible'
  }, [openMenu])

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchCompanyPermissions(company_id))
    }
    if (company_id) {
      fetch()
    }

    // eslint-disable-next-line
  }, [company_id])

  const handleClick = () => {
    dispatch(setOpenMenu(!openMenu))
  }

  useEffect(() => {
    dispatch(setOpenMenu(false));
    
    // eslint-disable-next-line
  }, [location]);

  const setSearch = async e => {
    const { value } = e.target
    setValue(value)

    return debounce(async () => {
      await dispatch(searchData({
        query: value,
        limit: 5
      }))
      await dispatch(setSearchParam(value))
    }, 500)()
  }

  const onClick = async () => {
    if(token_admin) {
      localStorage.setItem('token', token_admin);
      localStorage.removeItem('token_admin');
      await dispatch(fetchUserData());
      await history.push('/admin');
    } else {
      history.push('/admin')
    }
  }

  return (
    <div className={clsx('header', company_id !== 1 ? 'header--custom' : '', {
      'header--open': openMenu,
      'header--admin': is_admin
    })}
    >
      <button
        onClick={handleClick}
        className="tablet-visible header__toggle"
      >
        <span>Button</span>
      </button>
      <div className="header__logo-box">
        <Logo path="/dashboard" onClick={() => dispatch(setOpenMenu(false))} className="header__logo mobile-hidden" />
      </div>
      <div className="header__holder">
        <div className="header__content">
          <ul className="header__menu">
            {mainMenu.map((item) => {
              return (
                <li className={`${companyPermissions[item.name] === false ? 'menu-hide' : ''}`} key={item.title}>
                  <NavLink
                    onClick={isMobile ? handleClick : undefined}
                    className={`header__menu-link ${item.className || ''}`}
                    to={item.path}
                    activeClassName="header__menu-link--active"
                  >
                    {item.title}
                  </NavLink>
                </li>
              )
            })}
            <li className="tablet-visible">
              <div className="header__tour">
                <TourButton onClick={runTour} />
              </div>
            </li>
          </ul>
          <PDFDownload title="Export your plan" data={tasks && tasks} className="step-export" />
        </div>

        <div className="header__side">
          {(is_admin || token_admin) && <Button variant="contained" size="small" color="primary" onClick={onClick}>Admin Panel</Button>}
          <>
            <div className="header__tour tablet-hidden">
              <TourButton onClick={runTour} className={animate && 'animate'} />
            </div>
            <SearchBox
              path={'/search'}
              isMobile={isMobile}
              setSearch={setSearch}
              value={value}
            />

            <IconButton
              onClick={() => setOpenNotifications(true)}
              className={`${notifications && notifications.hasOwnProperty( 'New') ? 'icon-new' : ''}`}
            >
              <SVGIcon icon={NotificationIcon} size="22px"
                       color={notifications && notifications.hasOwnProperty( 'New') ? '#215E59' : '#8C8C8C'}
              />
            </IconButton>
          </>
          <div className="header__profile">
            <UserAvatar path="/profile" avatar={avatar} />
          </div>
        </div>
      </div>
      <Drawer
        open={openNotifications}
        onClose={() => setOpenNotifications(false)}
        anchor="right"
        className="drawer"
      >
        <button onClick={() => setOpenNotifications(false)} className="drawer__close">Close</button>
        <div className="drawer__holder drawer__holder--notification">
          <div className="drawer__title drawer__title--alt">Notifications</div>
          <div className="notifications">
            {notifications && !isEmpty(notifications)
              ? <div>
                  {Object.keys(notifications).map(key =>
                    <React.Fragment key={key}>
                      <div className={`notifications__list-title${key === 'New' ? ' notifications__list-title--new' : ''}`}>{key}</div>
                      <div className="notifications__list">
                        {notifications[key].map(child =>
                          <div key={child.id}>
                            <NotificationCard
                              title={child.name}
                              content={child.message}
                              type={child.type}
                              onClick={() => setOpenNotifications(false)}
                              remove={() => removeNotification(child.id)}
                            />
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  )}
                </div>
              : <div className="notifications__empty">
                  <img src={placeholderNotification} alt="No notifications" />
                  <h6>You have no notifications</h6>
                </div>
            }
          </div>
        </div>
      </Drawer>
    </div>
  )
}

export default Header
