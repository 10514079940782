import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AuthContent from '../../components/Auth/AuthContent';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Door from '../../assets/images/door-img.png';
import Personal from '../../assets/images/profile-management.png';
import Company from '../../assets/images/car-financing.png';
import { MAIN_SITE } from '../../constants/additional_links';
import Modal from '../../components/shared/Modal/Modal';
import { isEmpty, isNull } from 'lodash';

const SignUpType = () => {
  const authInfo = useSelector((state) => state.auth.authInfo);
  const [authProps, setAuthProps] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isEmpty(authInfo)) {
      setAuthProps({
        registration: authInfo[0].value
      });
    }
  }, [authInfo]);

  useEffect(() => {
    if (!isNull(authProps) && !authProps.registration) {
      setIsOpen(true);
    }
  }, [authProps]);

  return (
    <AuthContent decor={{ image: Door }} close={MAIN_SITE} top>
      <h2 className='auth__form-title'>Sign up</h2>
      <p className='auth__form-suptitle'>
        Choose a registration method for a Valuesoft account
      </p>
      <div className='mb--medium'>
        <Link to='/signup-personal' className='auth__type-button'>
          <div className='auth__type-icon'>
            <img src={Personal} alt='personal account' />
          </div>
          Sign up as a personal account
          <div className='auth__type-icon--arrow'>
            <ChevronRightIcon />
          </div>
        </Link>
        <Link to='/signup-company' className='auth__type-button'>
          <div className='auth__type-icon'>
            <img src={Company} alt='company account' />
          </div>
          Sign up as a company
          <div className='auth__type-icon--arrow'>
            <ChevronRightIcon />
          </div>
        </Link>
      </div>
      <div className='auth__form-info'>
        Already have an account?
        <Link to='/login' className='auth__form-info-link'>
          Sign in here <ChevronRightIcon />
        </Link>
      </div>
      <Modal
        open={isOpen}
        button='OK'
        close={() => {
          setIsOpen(false);
        }}
        onClick={() => {
          setIsOpen(false);
        }}
        customClass='permission__modal'
        children={
          <>
            <h3>Sign up functionality is temporarily disabled</h3>
            <p>
              To get more information, contact us by email
              inquiries@valuesoftinc.com
            </p>
          </>
        }
      />
    </AuthContent>
  );
};

export default SignUpType;
