import React from 'react'
import PropTypes from 'prop-types'
import { Slider } from "@mui/material";

const Range = ({
  value,
  name,
  onChange,
  max,
  label,
  min,
  step,
  disabled
}) => {
  return (
    <div className="range">
      {label && <div className="range__label">{label}</div>}

      <Slider
        disabled={disabled}
        className="range__bar"
        aria-labelledby={name}
        name={name}
        value={value}
        onChange={onChange}
        max={max}
        min={min}
        step={step}
      />
    </div>
  )
}

Range.propTypes = {
  onChange: PropTypes.func,
  value:  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  step: PropTypes.number,
}

export default Range
