import numeral from 'numeral'
import isNull from 'lodash/isNull'

export const color = amount => {
  if (amount <= 28) {
    return '#E33544'
  } else if (amount <= 50) {
    return '#FAC63F'
  }
  return '#215E59'
};

export const colorReverse = amount => {
  if (amount <= 28) {
    return '#215E59'
  } else if (amount <= 70) {
    return '#FAC63F'
  }
  return '#E33544'
};

export const colorInvert = amount => {
  if (amount <= 33) {
    return '#215E59'
  } else if (amount >= 66) {
    return '#E33544'
  }
  return '#FD8A63'
};

export const fillStatus = amount => {
  if (amount >= 99) return 'full'

  if (amount <= 9) {
    return 'base'
  } else if (amount >= 50) {
    return 'large'
  }

  return 'middle'
};

export const normalizeBoolean = value => {
  if (value === 'true') {
    return true;
  }

  if (value === 'false') {
    return false;
  }

  return value;
};

export const capitalize = str => {
  if (typeof str !== 'string') return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const convertDecimal = val => {
  if (isNull(val)) return;
  return Number(numeral(val).format('0.00'))
}

export const createDynamicOptions = (array, isImage, isRate, isValue) => {
  return array.map(item => {
    const { id, name, image, icon, rate, path } = item;

    if (isRate) {
      return {
        id,
        key: id,
        value: id,
        label: name,
        rate
      }
    }

    if (isImage) {
      return {
        id,
        key: id,
        value: id,
        label: name,
        icon: icon || image
      }
    }

    if (isValue) {
      return {
        id,
        key: id,
        value: path,
        label: name,
      }
    }

    return {
      id,
      key: id,
      value: id,
      label: name
    }
  })
}

export const getByParam = (options, id, param) => {
  const object = options.find(i => i.id === id);

  return object[`${param}`]
}


export const getCurrentEntry = (id, array) => array.find(i => i.id === parseInt(id, 10))

export const getPercent = (array, param) => {
  if (!array) return '0'

  const obj = array && array.length && array.find(i => i.name === param)

  if (obj) {
    return obj.percent
  }

  return '0'
}

export const PMTFormula = (rate, period, pv, fv, type) => {
  if (!fv) fv = 0
  if (!type) type = 0

  if (rate === 0) return -(pv + fv) / period

  const pvif = Math.pow(1 + rate, period)
  let pmt = rate / (pvif - 1) * -(pv * pvif + fv)

  if (type === 1) {
    pmt /= (1 + rate)
  }

  return pmt
}

export const phoneValidation = (value) => {
  const regexOne = /^([0-9]{3})$/;
  const regexTwo = /^\(?([0-9]{3})\)?[-. ]?([0-9]{1})$/;
  const regexThree = /^\(?([0-9]{3})\)?[-. ]?([0-9]{1})([0-9]{1})$/;
  const regexFour =
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{1})([0-9]{1})([0-9]{1})[-. ]?$/;
  const regexFive =
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{1})([0-9]{1})([0-9]{1})[-. ]?([0-9]{1})$/;
  const regexSix =
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{1})([0-9]{1})([0-9]{1})[-. ]?([0-9]{1})([0-9]{1})$/;
  const regexSeven =
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{1})([0-9]{1})([0-9]{1})[-. ]?([0-9]{1})([0-9]{1})([0-9]{1})$/;
  const regexEight =
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{1})([0-9]{1})([0-9]{1})[-. ]?([0-9]{1})([0-9]{1})([0-9]{1})([0-9]{1})+$/;
  const onlyNumb = /^[a-zA-Zа-яіїёєА-ЯІЇЁЄ]+$/;
  let phoneNumber = value.replace(
    /[a-zA-Zа-яіїєёА-ЯІЇЄЁ_ --+=@#№%:.[\]()*{}\\'"~`;<>?!,/]/g,
    ""
  );
  if (onlyNumb.test(phoneNumber)) {
    return (phoneNumber = phoneNumber.replace(onlyNumb, ""));
  } else if (regexOne.test(phoneNumber)) {
    return (phoneNumber = phoneNumber.replace(regexOne, "$1"));
  } else if (regexTwo.test(phoneNumber)) {
    return (phoneNumber = phoneNumber.replace(regexTwo, "($1) $2"));
  } else if (regexThree.test(phoneNumber)) {
    return (phoneNumber = phoneNumber.replace(regexThree, "($1) $2$3"));
  } else if (regexFour.test(phoneNumber)) {
    return (phoneNumber = phoneNumber.replace(regexFour, "($1) $2$3$4"));
  } else if (regexFive.test(phoneNumber)) {
    return (phoneNumber = phoneNumber.replace(regexFive, "($1) $2$3$4-$5"));
  } else if (regexSix.test(phoneNumber)) {
    return (phoneNumber = phoneNumber.replace(regexSix, "($1) $2$3$4-$5$6"));
  } else if (regexSeven.test(phoneNumber)) {
    return (phoneNumber = phoneNumber.replace(regexSeven, "($1) $2$3$4-$5$6$7"));
  } else if (regexEight.test(phoneNumber)) {
    return (phoneNumber = phoneNumber.replace(regexEight, "($1) $2$3$4-$5$6$7$8"));
  } else {
    return phoneNumber;
  }
};

export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation)

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  }
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export async function getCroppedImg(
  imageSrc,
  pixelCrop,
  imageType,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return null
  }

  const rotRad = getRadianAngle(rotation)

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  )

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth
  canvas.height = bBoxHeight

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
  ctx.rotate(rotRad)
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
  ctx.translate(-image.width / 2, -image.height / 2)

  // draw rotated image
  ctx.drawImage(image, 0, 0)

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  )

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0)

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      file.size < 3100000 ? resolve(URL.createObjectURL(file)) : resolve(null);
    }, imageType);
  })
}