import React from 'react'
import Layout from '../../components/Layout/Layout'
import Image from '../../assets/images/image404.png'


const NotFound = () => {
  return (
    <Layout>
      <div className="not-found">
        <div className="not-found__title">
          <h3>We are sorry! Something went wrong.</h3>
        </div>
        <div className="not-found__image">
          <img src={Image} alt="Something went wrong" />
        </div>
      </div>
    </Layout>
  );
};

export default NotFound
