import TaskIcon from '../assets/images/icons/calender.svg'
import BlogIcon from '../assets/images/icons/blog-icon.svg'
import AssumptionIcon from '../assets/images/icons/other.svg'
import CalculatorIcon from '../assets/images/icons/percentage.svg'
import NotifyIcon from '../assets/images/icons/notify-icon.svg'
import CompanyIcon from '../assets/images/icons/company-icon.svg'
import MembersIcon from '../assets/images/icons/user02.svg'
import Overview from '../assets/images/icons/info-circle.svg'

export const mainMenu = [
  { title: 'Overview', path: '/dashboard', className: 'step-overview' },
  { title: 'Input', path: '/input'},
  { title: 'Tasks', path: '/tasks'},
  { title: 'Calculators', name: 'calculators', path: '/calculators' },
  { title: 'Blog', name: 'blog', path: '/blog' },
  { title: 'Faq', path: '/faq' }
]

export const mainAdminMenu = (role_id, id) => {
  if (role_id === 1 && id) {
    return [
      { title: "Companies", path: "/admin/companies", icon: CompanyIcon },
      {
        title: "Overview",
        path: `/admin/company/${id}/details`,
        icon: Overview,
      },
      {
        title: "Members",
        path: `/admin/company/${id ? id : 1}/members`,
        icon: MembersIcon,
      },
      {
        title: "Permissions",
        name: "permissions",
        path: `/admin/company/${id ? id : 1}/permissions`,
        icon: TaskIcon,
      },
      {
        title: "Tasks",
        path: `/admin/company/${id ? id : 1}/tasks`,
        icon: TaskIcon,
      },
      {
        title: "Blog",
        name: "blog",
        path: `/admin/company/${id ? id : 1}/blog`,
        icon: BlogIcon,
      },
      {
        title: "Plans",
        name: "plan",
        path: `/admin/company/${id ? id : 1}/plans`,
        icon: AssumptionIcon,
      },
      {
        title: "E-mail statistics",
        name: "email-statistics",
        path: `/admin/company/${id ? id : 1}/email-statistics`,
        icon: TaskIcon,
      },
      {
        title: "User statistics",
        name: "statistics",
        path: `/admin/company/${id ? id : 1}/users-statistics`,
        icon: NotifyIcon,
      },
      {
        title: "Edit Assumptions",
        name: "assumptions",
        path: `/admin/company/${id ? id : 1}/loring`,
        icon: AssumptionIcon,
      },
      {
        title: "Edit Calculators",
        name: "calculators",
        path: `/admin/company/${id}/calculators`,
        icon: CalculatorIcon,
      },
      {
        title: "Notifications",
        path: `/admin/company/${id}/notifications`,
        icon: NotifyIcon,
      },
      {
        title: "Settings",
        path: `/admin/company/${id ? id : 1}/settings`,
        icon: Overview,
      },
    ];
  } else if (role_id === 2 && id) {
    return [
      {
        title: "Overview",
        path: `/admin/company/${id}/details`,
        icon: CompanyIcon,
      },
      {
        title: "Members",
        path: `/admin/company/${id}/members`,
        icon: MembersIcon,
      },
      {
        title: "Permissions",
        path: `/admin/company/${id}/permissions`,
        icon: TaskIcon,
      },
      { title: "Tasks", path: `/admin/company/${id}/tasks`, icon: TaskIcon },
      {
        title: "Blog",
        name: "blog",
        path: `/admin/company/${id}/blog`,
        icon: BlogIcon,
      },
      {
        title: "Plans",
        name: "plan",
        path: `/admin/company/${id}/plans`,
        icon: AssumptionIcon,
      },
      {
        title: "E-mail statistics",
        name: "email-statistics",
        path: `/admin/company/${id}/email-statistics`,
        icon: TaskIcon,
      },
      {
        title: "User statistics",
        name: "statistics",
        path: `/admin/company/${id}/users-statistics`,
        icon: NotifyIcon,
      },
      {
        title: "Edit Assumptions",
        name: "assumptions",
        path: `/admin/company/${id}/loring`,
        icon: AssumptionIcon,
      },
      {
        title: "Edit Calculators",
        name: "calculators",
        path: `/admin/company/${id}/calculators`,
        icon: CalculatorIcon,
      },
      {
        title: "Notifications",
        path: `/admin/company/${id}/notifications`,
        icon: NotifyIcon,
      },
    ];
  } else if (role_id === 3 && id) {
    return [
      {
        title: "Members",
        path: `/admin/company/${id}/members`,
        icon: MembersIcon,
      },
      {
        title: "Permissions",
        path: `/admin/company/${id}/permissions`,
        icon: TaskIcon,
      },
      { title: "Tasks", 
        path: `/admin/company/${id}/tasks`, 
        icon: TaskIcon },
      {
        title: "Blog",
        name: "blog",
        path: `/admin/company/${id}/blog`,
        icon: BlogIcon,
      },
      {
        title: "E-mail statistics",
        name: "email-statistics",
        path: `/admin/company/${id}/email-statistics`,
        icon: TaskIcon,
      },
      {
        title: "User statistics",
        name: "statistics",
        path: `/admin/company/${id}/users-statistics`,
        icon: NotifyIcon,
      },
      {
        title: "Edit Assumptions",
        name: "assumptions",
        path: `/admin/company/${id}/loring`,
        icon: AssumptionIcon,
      },
      {
        title: "Edit Calculators",
        name: "calculators",
        path: `/admin/company/${id}/calculators`,
        icon: CalculatorIcon,
      },
      {
        title: "Notifications",
        path: `/admin/company/${id}/notifications`,
        icon: NotifyIcon,
      },
    ];
  } else if (role_id === 4 && id) {
    return [
      {
        title: "Members",
        path: `/admin/company/${id}/members`,
        icon: MembersIcon,
      },
      { title: "Tasks", 
        path: `/admin/company/${id}/tasks`, 
        icon: TaskIcon },
      {
        title: "Blog",
        name: "blog",
        path: `/admin/company/${id}/blog`,
        icon: BlogIcon,
      },
      {
        title: "E-mail statistics",
        name: "email-statistics",
        path: `/admin/company/${id}/email-statistics`,
        icon: TaskIcon,
      },
      {
        title: "Edit Assumptions",
        name: "assumptions",
        path: `/admin/company/${id}/loring`,
        icon: AssumptionIcon,
      },
      {
        title: "Edit Calculators",
        name: "calculators",
        path: `/admin/company/${id}/calculators`,
        icon: CalculatorIcon,
      },
      {
        title: "Notifications",
        path: `/admin/company/${id}/notifications`,
        icon: NotifyIcon,
      },
    ];
  }
}


