import React, { useState, useCallback } from "react";
import { useDispatch } from 'react-redux'
import Cropper from "react-easy-crop";
import { useDropzone } from "react-dropzone";
import { Button } from "@mui/material";
import { Slider } from "../../../components/shared/Form";
import { getCroppedImg } from "../../../utils/utils";
import {  setUploading, setNameAvatar, setAvatarURL } from '../../../redux/slices/companiesSlice'
import UploadIcon from "../../../assets/images/icons/upload-icon.svg";
import SVGIcon from "../SVGIcon/SVGIcon";
import Trash from '../../../assets/images/icons/trash.svg'
import isNull from "lodash/isNull";

const UploadCompanyPhoto = ({ isUploading }) => {
  const dispatch = useDispatch();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [cropSize, setCropSize] = useState(null)
  const [zoom, setZoom] = useState(1);
  const [preview, setPreview] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [imgError, setImgError] = useState('')
  const [imageType, setImageType] = useState('')

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg,image/png,image/jpg',
    maxSize: 3100000,
    multiple: false,
    onDrop: (acceptedFiles, fileRejections) => {
      acceptedFiles.forEach((file) => {
        if(file.size > 3100000){
          setImgError('Image size must be a maximum of 3MB.');
          return
        } else {
          const newImage = URL.createObjectURL(file);
          const imageNmae = file.name;
          setImageType(file.type);
          setPreview(newImage);
          dispatch(setNameAvatar(imageNmae));
          setImgError('');
        }
      });
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === 'file-too-large') {
            setImgError('Image size must be a maximum of 3MB.');
          }

          if (err.code === 'file-invalid-type') {
            setImgError('Invalid type of file. Allowed .jpeg, .png, .jpg');
          }
        });
      });
    },
  });

  const sendCroppedImage = useCallback(async () => {
    try {
      const cropped = await getCroppedImg(
        preview,
        croppedAreaPixels,
        imageType
      );
      if(!isNull(cropped)){
        await dispatch(setAvatarURL(cropped))
        await dispatch(setUploading(false))
      } else {
        setPreview(null)
        setImgError('Image size must be a maximum of 3MB.');
      }
    } catch (e) {
      console.error(e)
    }
    // eslint-disable-next-line
  }, [preview, croppedAreaPixels])

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, []);

  return (
    <div>
      <div className="dropzone">
        {isNull(preview) ? (
          <div {...getRootProps({ className: 'dropzone__holder' })}>
            <input {...getInputProps()} />
            <Button variant="contained" size="large" color="primary" startIcon={<SVGIcon icon={UploadIcon} />}>
              Upload image
            </Button>
            <p>Upload or drop image here.</p>
            {imgError && <p className="dropzone__holder--error">{imgError}</p>}
          </div>
        ) : (
          <div className="dropzone__prev">
            <div className="dropzone__prev-image">
              <Cropper
                image={preview}
                crop={crop}
                zoom={zoom}
                cropSize={cropSize}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                onMediaLoaded={(mediaSize) => {
                  setCropSize({
                    width: mediaSize.width,
                    height: mediaSize.height,
                  });
                }}
              />
            </div>
            <div className="dropzone__prev-control">
              <Slider min={1} max={3} step={0.1} label="Zoom" value={zoom} onChange={(e, zoom) => setZoom(zoom)} />
            </div>
          </div>
        )}
      </div>
      {!isNull(preview) && (
        <>
          <Button variant="contained" size="large" color="primary" type="submit" onClick={() => sendCroppedImage()}>
            Save
          </Button>
          <button style={{ marginLeft: '15px' }} className="delete-btn" onClick={() => setPreview(null)}>
            <SVGIcon icon={Trash} color="#8c8c8c" />
          </button>
        </>
      )}
      {isUploading && <div className="spinner spinner--dark" />}
    </div>
  );
};

export default UploadCompanyPhoto;
