import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, Checkbox, FormControlLabel } from '@mui/material'
import { FormGroup } from '../Form'

const ModalBox = ({
  open,
  close,
  onClick,
  children,
  small,
  title,
  button,
  closeButton,
  edit,
  inline,
  reverse,
  removeButton,
  remove,
  checkbox,
  check,
  isChecked,
  isLoading,
  isDisabled,
  customClass,
  largeButtons,
  onMouseDown,
  fixHeight,
  disableEnforceFocus = true
}) => {
  return (
    <Modal
      disableEnforceFocus={disableEnforceFocus}
      onMouseDown={onMouseDown}
      className={`modal ${customClass ? customClass : ""} ${
        small ? "modal--small" : ""
      } ${edit ? "modal--edit" : ""}`}
      open={open}
      onClose={close}
    >
      <div className={`modal__holder${fixHeight ? ' modal__holder--fix-height' : ''}`}>
        <button onClick={close} className="modal__close">
          Close
        </button>

        {title && <div className="modal__title">{title}</div>}

        <div className="modal__content">{children}</div>

        <div
          className={`modal__buttons${inline ? " modal__buttons--inline" : ""}${
            !inline && closeButton && button ? " modal__buttons--alt" : ""
          }${largeButtons ? " modal__buttons--large" : ""}${
            reverse ? " modal__buttons--reverse" : ""
          }`}
        >
          {button && (
            <div>
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={onClick}
                disabled={isLoading || isDisabled}
              >
                {button}

                {isLoading && <span className="spinner" />}
              </Button>
            </div>
          )}

          {closeButton && (
            <div>
              <Button
                size="large"
                onClick={close}
                className="MuiButton-textGray"
              >
                {closeButton}
              </Button>
            </div>
          )}
          {removeButton && remove && (
            <div>
              <Button size="large" onClick={remove} variant="outlined">
                {removeButton}
              </Button>
            </div>
          )}
          {checkbox && check && (
            <div className="checkbox">
              <FormGroup name="agreed">
                <FormControlLabel
                  className="MuiFormControlLabel-root--small"
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={check}
                      name="agreed"
                    />
                  }
                  label={<>Don't show again!</>}
                />
              </FormGroup>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

ModalBox.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.any,
  small: PropTypes.bool,
  edit: PropTypes.bool,
  largeButtons: PropTypes.bool,
  button: PropTypes.string,
  title: PropTypes.string,
  closeButton: PropTypes.string,
  remove: PropTypes.func,
  removeButton: PropTypes.string,
  checkbox: PropTypes.bool,
  check: PropTypes.func,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  customClass: PropTypes.string,
};

export default ModalBox