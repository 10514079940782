import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../../components/Layout/Layout'
import LayoutForm from '../../../components/Layout/LayoutForm'
import { BackButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import { Button } from '@mui/material'
import { fetchFixedAsset, postFixedAsset } from '../../../redux/slices/worthSlice'
import { FormGroup, InputCurrency } from '../../../components/shared/Form'
import Priority from '../../../components/shared/Priority/Priority'
import Modal from '../../../components/shared/Modal/Modal'
import ProgressCircle from '../../../components/shared/ProgressBar/ProgressCircle'
import { setIsOpen } from '../../../redux/slices/modalSlice'
import { worthFixedSchema } from '../../../utils/validationFinances'

const WorthAssetsFixed = ({ props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.user.user);
  const { fixedAsset } = useSelector(state => state.worth);
  const isOpen = useSelector(state => state.modal.isOpen);
  const sending = useSelector(state => state.sending.isSending);

  useEffect(() => {
    async function fetch() {
      dispatch(fetchFixedAsset())
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const onSubmit = (values) => {
    dispatch(postFixedAsset(values, props))
  }

  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }))
  }

  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }));
    history.push('/worth/assets')
  }

  return (
    <Layout>
      <LayoutForm small>
        <BackButton title="Back" />
        <PageTitle
          title="Fixed assets"
        />
        <div className="layout-form__content-holder">
          <Formik
            enableReinitialize
            initialValues={fixedAsset && {...fixedAsset}}
            onSubmit={onSubmit}
            validationSchema={worthFixedSchema}
          >
            {({ errors, handleChange, handleBlur, values, touched }) => (
              <Form>
                <FormGroup name="home_primary" labelText="Primary home (current market value) ($)">
                  <InputCurrency
                    placeholder="Type"
                    type="text"
                    name="home_primary"
                    onChange={handleChange}
                    value={values.home_primary || ''}
                    onBlur={handleBlur}
                    info={{
                      content:
                        <>
                          <h6>Primary Home</h6>
                          <Priority option="High" />
                          <div>Please enter the current value of your primary home.  This value is likely the same current value you listed in the Current Fixed Assets and Loans step.</div>
                        </>
                    }}
                    hasError={!!errors.home_primary}
                  />
                  {errors.home_primary && <span className="form__msg-error">{errors.home_primary}</span>}
                </FormGroup>
                <FormGroup name="appreciable_asset" labelText="Other appreciable fixed assets (current market value) ($)">
                  <InputCurrency
                    placeholder="Type"
                    type="text"
                    name="appreciable_asset"
                    onChange={handleChange}
                    value={values.appreciable_asset || ''}
                    onBlur={handleBlur}
                    info={{
                      content:
                        <>
                          <h6>Other Appreciable Assets</h6>
                          <Priority option="High" />
                          <div>Please list any other assets you own that will likely increase in values over time, such as a jewelry collection, art, or vacation property.  This value is likely the sum of all the appreciable assets you listed in the Current Fixed Assets and Loans step.</div>
                        </>
                    }}
                    hasError={!!errors.appreciable_asset}
                  />
                  {errors.appreciable_asset && <span className="form__msg-error">{errors.appreciable_asset}</span>}
                </FormGroup>
                <FormGroup name="depreciable_asset" labelText="Other depreciable fixed assets (current market value) ($)">
                  <InputCurrency
                    placeholder="Type"
                    type="text"
                    name="depreciable_asset"
                    onChange={handleChange}
                    value={values.depreciable_asset || ''}
                    onBlur={handleBlur}
                    info={{
                      content:
                        <>
                          <h6>Other Depreciable Assets</h6>
                          <Priority option="High" />
                          <div>Please list any other assets you own that will likely decrease in values over time, such as a car or boat. This value is likely the sum of all the depreciable assets you listed in the Current Fixed Assets & Loans step.</div>
                        </>
                    }}
                    hasError={!!errors.depreciable_asset}
                  />
                  {errors.depreciable_asset && <span className="form__msg-error">{errors.depreciable_asset}</span>}
                </FormGroup>
                <div className="form__button form__button--center">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Save
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <Modal
          open={isOpen}
          small
          button="Done"
          onClick={handleClick}
          close={onClose}
        >
          <h3>Good Job!</h3>
          <p>Your progress increased 100%</p>
          <ProgressCircle amount={100} small />
        </Modal>
      </LayoutForm>
    </Layout>
  );
};

export default WorthAssetsFixed;
