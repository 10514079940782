import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import AuthorCard from '../../components/Cards/AuthorCard'
import ModalBox from '../../components/shared/Modal/Modal'
import { setIsOpen } from '../../redux/slices/modalSlice'
import { fetchAuthors, removeAuthor, setError } from '../../redux/slices/blogSlice'


const AdminAuthorOverview = ({ props, isAdmin }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { companyId } = useParams();
  const { id } = useSelector(state => state.user.user)
  const { authors, error } = useSelector(state => state.blog)
  const isOpen = useSelector(state => state.modal.isOpen)


  useEffect(() => {
    async function fetch() {
      await dispatch(fetchAuthors(companyId, isAdmin))
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const close = () => {
    dispatch(setError(null))
    dispatch(setIsOpen({isOpen: false}))
  }


  return (
    <>
      {authors &&
        <div className="grid grid-2">
          {authors.map(item =>
            <div key={item.id}>
              <AuthorCard
                authorId={item.id}
                companyId={companyId}
                name={`${item.first_name} ${item.last_name}`}
                onEdit={() => history.push(`/admin/company/${companyId}/authors/${item.id}/edit`)}
                onDelete={removeAuthor}
              />
            </div>
          )}
        </div>
      }
      <ModalBox
        small
        open={isOpen}
        close={close}
        onClick={close}
        button="Got it!"
        title="Sorry"
        children= {<p>{error}</p>}
      />
    </>
  )

}

export default AdminAuthorOverview
