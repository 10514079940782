import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from '@mui/material'
import Logo from '../../components/shared/Logo/Logo'
import { setOpenMenu } from '../../redux/slices/additionalSlice'

const HeaderShort = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const openMenu = useSelector(state => state.additional.menuOpen)
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn)

  useEffect(() => {
    openMenu
      ? document.body.style.overflow = 'hidden'
      : document.body.style.overflow = 'visible'
  }, [openMenu])

  const handleClick = () => {
    dispatch(setOpenMenu(!openMenu))
  }

  const onButtonClick = async (path) => {
    await dispatch(setOpenMenu(true))
    history.push(path)
  }

  return (
    <div className={`shortheader${openMenu ? ' shortheader--open': ''}`}>
      {!isLoggedIn && <button
        onClick={handleClick}
        className="tablet-visible shortheader__toggle"
      >
        <span>Button</span>
      </button>}
      <div className="shortheader__logo-box">
        <Logo path="/dashboard" className="shortheader__logo" />
      </div>
      <div className="shortheader__holder">
        <div className="shortheader__content">
          {!isLoggedIn && <>
            <Button variant="contained" size="small" color="primary" onClick={() => onButtonClick('/login')}>Login</Button>
            <Button variant="contained" size="small" color="primary" onClick={() => onButtonClick('/signup')}>Register</Button>
          </>
          }
        </div>
      </div>
    </div>
  )
};

export default HeaderShort
