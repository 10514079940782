import React, { useEffect } from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../../components/Layout/Layout'
import { BackButton, DeleteButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import LayoutForm from '../../../components/Layout/LayoutForm'
import { Button } from '@mui/material'
import { postData, updateChildData, removeChild, fetchIdData, setLoading } from '../../../redux/slices/childrenSlice'
import { Input, FormGroup, FormSelect } from '../../../components/shared/Form'
import ModalBox from '../../../components/shared/Modal/Modal'
import { setIsOpen } from '../../../redux/slices/modalSlice'

const relationshipType = [
  {
    id: 1,
    key: 1,
    value: "child",
    label: "Child",
  },
  {
    id: 2,
    key: 2,
    value: "relative",
    label: "Relative",
  },
  {
    id: 3,
    key: 3,
    value: "other",
    label: "Other",
  }
]

const ChildrenDetails = ({ props, isEdit }) => {
  const dispatch = useDispatch();
  const userId = useSelector(state => state.user.user.id);
  const sending = useSelector(state => state.children.loading);
  const child = useSelector(state => state.children.currentChild);
  const isOpen = useSelector(state => state.modal.isOpen);
  const { id }  = useParams();

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchIdData(id));
    }

    if (userId && id) {
      fetch()
    }

    // eslint-disable-next-line
  }, [userId, id])

  const onSubmit = async (values) => {
    await dispatch(setLoading(true))
    await dispatch(postData(values, props))
    await dispatch(setLoading(false))
  }

  const updateData = async (values) => {
    await dispatch(setLoading(true))
    await dispatch(updateChildData(id, values, props))
    await dispatch(setLoading(false))
  }

  const deleteChild = () => {
    dispatch(removeChild(id, props))
    dispatch(setIsOpen({isOpen: false}))
  }

  return (
    <Layout>
      <LayoutForm small>
        <BackButton title="Back to Current Dependents" />
        {isEdit && <DeleteButton className="delete-button--position" onClick={() => dispatch(setIsOpen({isOpen: true}))} />}
        <PageTitle
          title={isEdit && child && child.name ? child.name : 'Current Dependent'}
          content="Tell us a little bit more about your dependent."
          status={isEdit ? 100 : '0'}
        />
        <div className="layout-form__content-holder">
          <Formik
            enableReinitialize
            initialValues={isEdit && child
              ? {...child}
              : {
                name: '',
                age: null,
                type: ''
              }
            }
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Required'),
              age: Yup.number().integer('Please check this value').typeError('Age must be a number').max(150, 'Please check age').required('Required'),
              type: Yup.string().required('Required')
            })}
            onSubmit={isEdit ? updateData : onSubmit}
          >
            {({ errors, handleChange, handleBlur, setFieldValue, values, touched }) => (
              <Form>
                <FormGroup name="name" labelText="First name">
                  <Input
                    name="name"
                    placeholder="Type a name"
                    type="text"
                    onChange={handleChange}
                    value={values.name || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.name && touched.name}
                  />
                  {touched.name && errors.name && <span className="form__msg-error">{errors.name}</span>}
                </FormGroup>
                <FormGroup name="age" labelText="Age">
                  <Input
                    name="age"
                    placeholder="Type dependent's age"
                    type="text"
                    onChange={handleChange}
                    value={values.age || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.age && touched.age}
                  />
                  {touched.age && errors.age && <span className="form__msg-error">{errors.age}</span>}
                </FormGroup>
                <FormGroup
                    name="type"
                    labelText="Relationship"
                  >
                    <FormSelect
                      options={relationshipType}
                      value={values.type || ''}
                      name="type"
                      onChange={(e) => {
                        setFieldValue(e.target.name, e.target.value)
                      }}
                      placeholder="Type of relationship"
                      hasError={!!errors.type && touched.type}
                    />
                    {touched.type && errors.type && <span className="form__msg-error">{errors.type}</span>}
                  </FormGroup>
                <div className="form__button form__button--center">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Save
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <ModalBox
          small
          inline
          open={isOpen}
          title="Are you sure?"
          button="Remove Item"
          closeButton="Cancel"
          onClick={deleteChild}
          close={() => dispatch(setIsOpen({isOpen: false}))}
        />
      </LayoutForm>
    </Layout>
  );
};

export default ChildrenDetails;
