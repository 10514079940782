import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import isArray from 'lodash/isArray'
import Layout from '../../../components/Layout/Layout'
import { BackButton } from '../../../components/shared/Buttons'
import TreeSubItem from '../../../components/Tree/TreeSubItem'
import { Button, Switch } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchDataEducation,
  deactivateCurrentEducation,
} from "../../../redux/slices/childrenSlice";
import { getPercent } from '../../../utils/utils'
import { fetchTotalPercent } from '../../../redux/slices/percentagesSlice'
import { fetchUserFamilyChildren, fetchUserFamilyData } from '../../../redux/slices/userSlice'
import TreeImage from '../../../assets/images/tree/about/About-tree.png'
import ModalBox from '../../../components/shared/Modal/Modal'
import TreeCount from '../../../components/Tree/TreeCount'
import { setIsOpen } from '../../../redux/slices/modalSlice'


const Education = ({ props }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [isMobile, setIsMobile] = useState(false)
  const { id } = useSelector(state => state.user.user)
  const isBasic = useSelector((state) => state.user.user.plan_type === 1);
  const totalPercent = useSelector(state => state.percentages.total)
  const isOpen = useSelector(state => state.modal.isOpen)
  const currentEducation = useSelector(state => state.children.currentEducation)
  const percents = useSelector(state => state.percentages.percents)
  const loading = useSelector(state => state.sending.isSending)
  const [isCheck, setCheck] = useState(false);
  const [isOpenModal, setOpenModal] = useState(false)
  const mobileView = 999

  const handleResize = () => {
    (mobileView >= window.innerWidth) ? setIsMobile(true) : setIsMobile(false)
  }

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true)
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    async function fetch() {
      //await dispatch(fetchPercentages())
      await dispatch(fetchUserFamilyChildren(id))
      await dispatch(fetchUserFamilyData())
      await dispatch(fetchDataEducation())
      await dispatch(fetchTotalPercent(isBasic))
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const education = getPercent(percents, 'education')
  const profile   = getPercent(percents, 'profile')

  const handleClick = () => {
    if(currentEducation && currentEducation.length > 9) {
      return dispatch(setIsOpen({isOpen: true}))
    }

    return history.push('/students/create')
  }

  const close = () => dispatch(setIsOpen({isOpen: false}))

  const onClose = () => {
    setCheck(false);
  };

  const handleCheck = () => {
    setCheck(true);
  };

  const handleConfirm = () => {
    dispatch(deactivateCurrentEducation(props));
    setCheck(false);
  };

  return (
    <Layout
      totalPercent={totalPercent}
      tree
      isBlockedPage={percents && profile === 0}
    >
      <div className="tree__sticky-holder">
        <BackButton
          title={isMobile ? "Back" : "Back to Tree"}
          path={isMobile ? "/about-me" : "/input"}
          className="back-button--position"
        />
        <div className="tree__title tree__title--progress-bar">
          <div className="tree__title-main">
            <h2>Current Education Planning</h2>
            <TreeCount value={education} loading={loading} big={!isMobile} />
          </div>
          {isMobile && currentEducation && currentEducation.length === 0 && (
            <div className="tree__switcher">
              <Switch
                className="custom-switcher"
                checked={isCheck}
                onChange={handleCheck}
              />
              I'm not currently paying for anyone's education.
            </div>
          )}
          {!isMobile && (
            <>
              <p className="tree__title-other">
                Fill in information to complete this step.
              </p>
              <Button
                variant="contained"
                className="tree-bar"
                size="small"
                onClick={handleClick}
                color="primary"
              >
                Add Education
              </Button>
              {currentEducation && currentEducation.length === 0 && (
                <div className="tree__switcher">
                  <Switch
                    className="custom-switcher"
                    checked={isCheck}
                    onChange={handleCheck}
                  />
                  I'm not currently paying for anyone's education.
                </div>
              )}
            </>
          )}
        </div>
        <div className="tree tree--sub-page">
          {!isMobile && (
            <div className="tree__base">
              <img src={TreeImage} alt="ValueSoft Tree Education" />
            </div>
          )}
          {currentEducation &&
            isArray(currentEducation) &&
            currentEducation.map((item, i) => (
              <React.Fragment key={item.id}>
                <TreeSubItem
                  key={item.id}
                  path={`/education/${item.id}`}
                  title={item.name}
                  count={item.percent}
                  value={`education-item-${i + 1}`}
                />
              </React.Fragment>
            ))}
        </div>

        {isMobile && (
          <div className="tree__add-button">
            <Button
              variant="contained"
              size="small"
              onClick={handleClick}
              color="primary"
            >
              {!isMobile && "Add Education"}
            </Button>
          </div>
        )}
      </div>

      <ModalBox
        small
        open={isOpen}
        close={close}
        onClick={() => {
          dispatch(setIsOpen({ isOpen: false }));
          history.push("/input");
        }}
        button="Back to tree"
        title="Sorry"
        children={<p>The limit of adding new sections is reached.</p>}
      />

      <ModalBox
        small
        open={isOpenModal}
        close={() => setOpenModal(false)}
        onClick={() => {
          setOpenModal(false);
          history.push("/profile-summary/about-your-family/others");
        }}
        closeButton="Later"
        button="Add information"
        title="Sorry"
        children={
          <p>
            You need to add the relevant information in the{" "}
            <strong className="text-success">About You</strong> page in order to
            activate this section.{" "}
          </p>
        }
      />
      <ModalBox
        small
        open={isCheck}
        close={onClose}
        onClick={handleConfirm}
        button="Confirm"
        closeButton="Cancel"
        title="Current Education step"
        children={
          <p>
            This step will be disabled, but you can activate it any time by
            clicking on it in the your input.
          </p>
        }
      />
    </Layout>
  );
}

export default Education
