import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import { Button } from '@mui/material'
import { BackButton } from '../../../components/shared/Buttons'
import Layout from '../../../components/Layout/Layout'
import PageTitle from '../../../components/PageTitle/PageTitle'
import LayoutForm from '../../../components/Layout/LayoutForm'
import Priority from '../../../components/shared/Priority/Priority'
import { Input, FormGroup, FormSelect } from '../../../components/shared/Form'
import { collectSchema } from '../../../utils/validation'
import { fetchDreams, postDreams } from '../../../redux/slices/userSlice'
import { fetchValuesData } from '../../../redux/slices/optionsSlice'
import Modal from '../../../components/shared/Modal/Modal'
import ProgressCircle from '../../../components/shared/ProgressBar/ProgressCircle'
import {setIsOpen} from '../../../redux/slices/modalSlice'

const options = [
  {
    key: 1,
    value: false,
    label: 'No'
  },
  {
    key: 2,
    value: true,
    label: 'Yes'
  }
]

const titleUser = {
  title: 'Your Dreams',
  content: 'Dreams are objectives you  hope to accomplish, but may seen unrealistic right now and would take extraordinary success of some kind to achive them.',
  info:
    <>
      <h6>Your Dreams</h6>
      <Priority option="Medium" />
      <div>Dreams are objectives you hope to accomplish, but may seem unrealistic right now and would take extraordinary success of some kind to achieve them. Fill in the entries with your top 5 dreams and associate each to its corresponding value and indicate whether it requires money (above $5000).  In general, dreams that require a financial commitment should not be in your list of Objectives in the Objectives task, since they may require a significant cash outlay.</div>
    </>
}

const titleSpouse = {
  title: 'Spouse`s Dreams',
  content: 'Dreams are objectives your hopes to accomplish, but may seem unrealististic right now and would take extraordinary success of some kind to achieve them.',
  info:
    <>
      <h6>Spouse's Dreams </h6>
      <Priority option="Medium" />
      <div>Dreams are objectives your spouse hopes to accomplish, but may seem unrealistic right now and would take extraordinary success of some kind to achieve them. Fill in the entries with your spouse's top 5 dreams and associate each to its corresponding value and indicate whether it requires money (above $5000).  In general, dreams that require a financial commitment should not be in your spouse's list of Objectives in the Objectives task, since they may require a significant cash outlay.</div>
    </>
}

const Dreams = ({ props, isSpouse }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.user.user);
  const { dreams } = useSelector(state => state.user);
  const dreamsSpouse = useSelector(state => state.user.spouse.dreams);
  const listValues = useSelector(state => state.options.values);
  const sending = useSelector(state => state.sending.isSending);
  const isOpen = useSelector(state => state.modal.isOpen);

  useEffect(() => {
    async function fetchOptions() {
      await dispatch(fetchValuesData());
    }

    async function fetch() {
      await dispatch(fetchDreams());
    }

    async function fetchSpouse() {
      await dispatch(fetchDreams(isSpouse));
    }

    if (id) {
      fetchOptions()

      if (isSpouse) {
        fetchSpouse()
      }

      fetch()
    }

    // eslint-disable-next-line
  }, [id])


  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }))
  }

  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }));
    history.push('/values')
  }

  return (
    <Layout>
      <LayoutForm>
        <BackButton title="Back" />
        <PageTitle
          title={isSpouse ? titleSpouse.title : titleUser.title}
          content={isSpouse ? titleSpouse.content : titleUser.content}
          info={isSpouse ? titleSpouse.info : titleUser.info}
        />
        <div className="layout-form__content-holder container container--large">
          <Formik
            enableReinitialize
            validationSchema={collectSchema}
            initialValues={{
              collect: isSpouse
                ? [...dreamsSpouse, ...Array(5)].slice(0,5)
                : [...dreams, ...Array(5)].slice(0,5),
            }}
            onSubmit={(values) => dispatch(postDreams(values.collect, props, isSpouse))}
          >
            {({ errors, handleChange, handleBlur, values, touched, setFieldValue }) => (
              <Form>
                <div className="values__table values__table--goals">
                  <div className="values__table-head">
                    <div>Dream</div>
                    <div>Associated Values</div>
                    <div>Requires Money (Over $5000)?</div>
                  </div>
                  {values.collect && values.collect.map((item, i) => (
                    <div className="values__table-row" key={i}>
                      <div>
                        <FormGroup name={`collect[${i}].name`} labelText="Dream" hideLabel>
                          <Input
                            smallHeight
                            placeholder="Dream"
                            type="text"
                            name={`collect[${i}].name`}
                            onChange={handleChange}
                            value={item && item.name ? item.name : ''}
                            onBlur={handleBlur}
                          />
                        </FormGroup>
                      </div>
                      <div>
                        <FormGroup name={`collect[${i}].value_id`} labelText="Associated Values" hideLabel>
                          <FormSelect
                            small
                            options={[
                              {
                                key: 10,
                                value: '',
                                label: 'None'
                              },
                              ...listValues
                            ]}
                            value={item && item.value_id ? item.value_id : ''}
                            name={`collect[${i}].value_id`}
                            onChange={(e) => {
                              setFieldValue(e.target.name, e.target.value)
                            }}
                            placeholder="Select"
                          />
                        </FormGroup>
                      </div>
                      <div>
                        <FormGroup name={`collect[${i}].requires_money`} labelText="Requires Money (Over $5000)?" hideLabel>
                          <FormSelect
                            small
                            style={{'width': '95px'}}
                            options={options}
                            value={item && item.requires_money ? item.requires_money : false}
                            name={`collect[${i}].requires_money`}
                            onChange={(e) => {
                              setFieldValue(e.target.name, e.target.value)
                            }}
                            placeholder="Select"
                          />
                        </FormGroup>
                        {errors && errors.collect && errors.collect[i] && <div className="form__msg-error">All fields must be filled</div>}
                      </div>
                    </div>
                  ))}

                  <div className="form__button form__button--center">
                    <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                      Save
                      {sending && <span className="spinner" />}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <Modal
          open={isOpen}
          small
          button="Done"
          onClick={handleClick}
          close={onClose}
        >
          <h3>Good Job!</h3>
          <p>Your progress increased 100%</p>
          <ProgressCircle amount={100} small />
        </Modal>
      </LayoutForm>
    </Layout>
  );
};

export default Dreams;
