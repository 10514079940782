import React from 'react';
import Select from '../shared/Form/Select';
import MultipleSelect from '../shared/Form/MultipleSelect';
import Input from '../shared/Form/Input';
import Button from '@mui/material/Button';
import { isEmpty } from 'lodash';

const rolesList = ['Company Instance SuperAdmin', 'Company Admin', 'Planner', 'Member'];

const StatisticFilter = (props) => {
  return (
    <div className='users-statistics'>
      <div className='users-statistics__wrap'>
        <div className='users-statistics__location'>
          <div className='users-statistics__item'>
            <div className='range__label'>State</div>
            <MultipleSelect
              options={props.optionsState}
              value={props.stateValue}
              onChange={props.handleStateOptions}
              small
            />
          </div>
          <div className='users-statistics__item'>
            <div className='range__label'>City</div>
            <MultipleSelect
              disabled={isEmpty(props.optionsCity)}
              options={props.optionsCity}
              value={props.cityValue}
              onChange={props.handleCityOptions}
              small
            />
          </div>
        </div>
        <div className='users-statistics__status'>
          <div className='users-statistics__item'>
            <div className='range__label'>Age</div>
            <div className='users-statistics__input-wrap'>
              <Input
                prefixText='From:'
                type='number'
                name='age-start'
                value={props.ageStartValue}
                onChange={props.handleAgeStartValue}
                autoWidth
                smallHeight
              />
              <Input
                prefixText='To:'
                type='number'
                name='age-finish'
                value={props.ageFinishValue}
                onChange={props.handleAgeFinishValue}
                autoWidth
                smallHeight
              />
            </div>
          </div>
          <div className='users-statistics__item'>
            <div className='range__label'>Married</div>
            <Select
              options={[
                { key: 1, value: 'yes', label: 'Yes' },
                { key: 2, value: 'no', label: 'No' },
              ]}
              value={props.marriedValue}
              onChange={props.handleMarriedValue}
              small
            />
          </div>
          <div className='users-statistics__item'>
            <div className='range__label'>Role</div>
            <MultipleSelect
              options={rolesList}
              value={props.roleValue}
              onChange={props.handleRoleOptions}
              small
            />
          </div>
        </div>
      </div>
      <div className='users-statistics__control'>
        <Button
          variant='outlined'
          color='info'
          size='medium'
          onClick={props.onResetFilter}
        >
          Clear all
        </Button>
        <Button variant='contained' size='medium' onClick={props.onApplyFilter}>
          Apply changes
        </Button>
      </div>
    </div>
  );
};

export default StatisticFilter;
