import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';
import { Auth, Profile } from '../../api/Api';
import { fetchUserData } from './userSlice';
import { setSending } from './sendingSlice';
import { setIsOpen } from './modalSlice';
import { setMode } from './modeSlice';
import { setIsRelogin } from './loadingSlice';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    registerUser: {
      email: '',
      phone: '',
      password: '',
      password_confirmation: '',
      first_name: '',
      last_name: '',
      year_of_birth: '',
      agreed: false,
      auth_token: '',
      recaptcha: '',
    },
    registerCompany: {
      email: '',
      phone: '',
      first_name: '',
      last_name: '',
      company_name: '',
      company_website: '',
      about_company: '',
      recaptcha: '',
    },
    isLoggedIn: false,
    authError: false,
    authMessage: false,
    isLoading: false,
    authInfo: [],
  },
  reducers: {
    setUserBaseData: (state, action) => {
      state.registerUser.email = action.payload.email;
      state.registerUser.phone = action.payload.phone;
      state.registerUser.password = action.payload.password;
      state.registerUser.password_confirmation =
        action.payload.password_confirmation;
    },
    setPersonalInfo: (state, action) => {
      state.registerUser.first_name = action.payload.first_name;
      state.registerUser.last_name = action.payload.last_name;
      state.registerUser.email = action.payload.email;
      state.registerUser.agreed = action.payload.agreed;
      state.registerUser.phone = action.payload.phone;
      state.registerUser.password = action.payload.password;
      state.registerUser.password_confirmation =
        action.payload.password_confirmation;
      state.registerUser.recaptcha = action.payload.recaptcha;
      state.registerUser.auth_token = action.payload.auth_token;
    },
    setCompanyInfo: (state, action) => {
      state.registerCompany.first_name = action.payload.first_name;
      state.registerCompany.last_name = action.payload.last_name;
      state.registerCompany.email = action.payload.email;
      state.registerCompany.phone = action.payload.phone;
      state.registerCompany.company_name = action.payload.company_name;
      state.registerCompany.company_website = action.payload.company_website;
      state.registerCompany.about_company = action.payload.about_company;
      state.registerCompany.recaptcha = action.payload.recaptcha;
    },
    setUserEmail: (state, action) => {
      state.registerUser.email = action.payload.email;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload.isLoggedIn;
      const token = localStorage.token;
      if (token) {
        state.isLoggedIn = true;
      }
    },
    setAuthError: (state, action) => {
      state.authError = action.payload.authError;
    },
    setAuthLoading: (state, action) => {
      state.isLoading = action.payload.isLoading;
    },
    setAuthMessage: (state, action) => {
      state.authMessage = action.payload.authMessage;
    },
    setAuthToken: (state, action) => {
      state.registerUser.auth_token = action.payload.auth_token;
    },
    setAuthInfo: (state, action) => {
      state.authInfo = action.payload;
    },
  },
});

export const getAuthInfo = () => async (dispatch) => {
  try {
    const response = await Auth.authInfo();
    await dispatch(setAuthInfo(response.data.data));
  } catch (e) {
    console.error(e);
  }
};

export const postAuthSettings = (data) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    await dispatch(setMode('success'));
    await dispatch(setIsOpen({ isOpen: true }));
    const response = await Auth.authSettings(data);
    await dispatch(setAuthInfo(response.data.data));
    await dispatch(setSending({ isSending: false }));
  } catch (e) {
    console.error(e);
    await dispatch(setMode('error'));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({ isSending: false }));
  }
};

export const getTempMemberInfo = (token) => async (dispatch) => {
  try {
    const response = await Auth.getMemberInfo(token);
    await dispatch(setPersonalInfo(response.data.data));
  } catch (e) {
    console.error(e);
  }
};

export const registerCompanyMember = (data, props) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    const offset = new Date().getTimezoneOffset() / 60;
    const gmt =
      offset < 0 ? `GMT+${Math.abs(offset)}` : `GMT-${Math.abs(offset)}`;
    await Auth.createMember({
      ...data,
      timezone: gmt,
    });
    await dispatch(setSending({ isSending: false }));
    await dispatch(setIsOpen({ isOpen: true }));
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
    dispatch(setAuthError({ authError: e.response.data.errors }));
  }
};

export const createUser = (data, props) => async (dispatch) => {
  try {
    dispatch(setAuthError({ authError: false }));
    await Auth.create(data);
    await dispatch(() => props.history.push('/signup-personal-info'));
  } catch (e) {
    dispatch(setAuthError({ authError: e.response.data.errors }));
  }
};

export const registerUser = (data, props) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    await dispatch(setAuthError({ authError: false }));

    const offset = new Date().getTimezoneOffset() / 60;
    const gmt =
      offset < 0 ? `GMT+${Math.abs(offset)}` : `GMT-${Math.abs(offset)}`;

    await Auth.register({
      ...data,
      timezone: gmt,
    });
    await dispatch(setSending({ isSending: false }));
    await dispatch(() => props.history.push('/signup-confirm'));
  } catch (e) {
    dispatch(setSending({ isSending: false }));
    dispatch(setAuthError({ authError: e.response.data.errors }));
  }
};

export const registerCompany = (data, props) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    await dispatch(setAuthError({ authError: false }));

    const offset = new Date().getTimezoneOffset() / 60;
    const gmt =
      offset < 0 ? `GMT+${Math.abs(offset)}` : `GMT-${Math.abs(offset)}`;

    await Auth.regCompany({
      ...data,
      timezone: gmt,
    });
    await dispatch(setSending({ isSending: false }));
    await dispatch(() => props.history.push('/signup-company-confirm'));
  } catch (e) {
    console.error(e);
    dispatch(setSending({ isSending: false }));
    dispatch(setAuthError({ authError: e.response.data.errors }));
  }
};

export const resend = (data, props) => async (dispatch) => {
  try {
    await Auth.resend(data);
  } catch (e) {
    console.error(e);
  }
};

export const emailConfirm = (data) => async (dispatch) => {
  try {
    const response = await Auth.mailConfirm(data);
    await dispatch(setAuthToken({ auth_token: response.data.auth_token }));
  } catch (e) {
    console.error(e);
  }
};

export const phoneConfirm = (data) => async (dispatch) => {
  try {
    const response = await Auth.phoneConfirm(data);
    await dispatch(setAuthToken({ auth_token: response.data.auth_token }));
  } catch (e) {
    console.error(e);
  }
};

export const codeConfirm = (data, props) => async (dispatch) => {
  try {
    dispatch(setSending({ isSending: true }));
    dispatch(setAuthError({ authError: false }));
    const response = await Auth.codeConfirm(data);
    if (response.data.token) {
      localStorage.setItem('token', response.data.token);
      localStorage.setItem(
        'expires_in',
        moment().add(response.data.expires_in, 'seconds').format()
      );
    }
    await dispatch(fetchUserData());
    await dispatch(setSending({ isSending: false }));
    await dispatch(() => props.history.push('/dashboard'));
  } catch (e) {
    console.error(e);
    dispatch(setSending({ isSending: false }));

    if (e.response && e.response.data && e.response.data.errors) {
      dispatch(setAuthError({ authError: e.response.data.errors }));
    } else {
      dispatch(setAuthError({ authError: e.response.data.message }));
    }
    if (e.response.status === 400) {
      dispatch(
        setAuthError({
          authError: { code: ['Incorrect verification. Please try again.'] },
        })
      );
    }
  }
};

export const loginUser = (data, props) => async (dispatch) => {
  try {
    dispatch(setSending({ isSending: true }));
    dispatch(setIsRelogin({ isRelogin: false }));
    const response = await Auth.login(data);
    if (response) {
      dispatch(
        setUserBaseData({
          email: response.data.user.email,
          phone: response.data.user.phone,
        })
      );
      await dispatch(setIsLoggedIn({ isLoggedIn: true }));
      await dispatch(setSending({ isSending: false }));
      await dispatch(() => props.history.push('/verification'));
    }
  } catch (e) {
    //console.error(e);
    dispatch(setAuthError({ authError: e.response.data }));
    dispatch(setSending({ isSending: false }));
    dispatch(setIsLoggedIn({ isLoggedIn: false }));
  }
};

export const logout = (props) => async (dispatch) => {
  await localStorage.removeItem('token');
  await localStorage.removeItem('token_admin');
  await dispatch(setIsLoggedIn({ isLoggedIn: false }));
  await dispatch(setSending({ isSending: false }));
  await dispatch(() => props.history.push('/login'));
};

export const forgotPassword = (data, props) => async (dispatch) => {
  try {
    dispatch(setSending({ isSending: true }));
    await Auth.forgot(data);
    dispatch(setSending({ isSending: false }));
    dispatch(setUserBaseData(data));
    dispatch(() => props.history.push('/password-confirm'));
  } catch (e) {
    dispatch(setAuthError({ authError: e.response.data.errors }));
    dispatch(setSending({ isSending: false }));
  }
};

export const resetPassword = (data, props) => async (dispatch) => {
  try {
    const params = new URL(document.location).searchParams;
    const token = params.get('token');
    const email = params.get('email');

    await Auth.reset({
      ...data,
      token: token,
      email: email,
    });
    await dispatch(setIsOpen({ isOpen: true }));
  } catch (e) {
    console.error(e);
  }
};

export const createNewPassword = (data) => async (dispatch) => {
  try {
    dispatch(setSending({ isSending: true }));
    const response = await Profile.reset(data);
    dispatch(setSending({ isSending: false }));
    dispatch(setAuthMessage({ authMessage: response.data.message }));
  } catch (e) {
    dispatch(setSending({ isSending: false }));
    dispatch(setAuthError({ authError: e.response.data.errors }));
  }
};

export const {
  setPersonalInfo,
  setCompanyInfo,
  setUserBaseData,
  setIsLoggedIn,
  setAuthError,
  setAuthLoading,
  setAuthMessage,
  setAuthToken,
  setAuthInfo,
} = authSlice.actions;

export default authSlice.reducer;
