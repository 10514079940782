import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel'
import PropTypes from 'prop-types'
import clsx from 'classnames'


const MultipleSelect = ({
  id,
  options,
  placeholder,
  value,
  name,
  onChange,
  hasError,
  small,
  medium,
  autoWidth,
  style,
  isCreatable,
  setFieldValue,
  renderValue,
  disabled,
  ...rest
}) => {
  
  return (
    <>
        <Select
          disabled={disabled}  
          style={style}
          id={id}
          multiple
          name={name}
          value={value}
          onChange={onChange}
          renderValue={(selected) => selected.join(', ')}
          className={clsx('custom-select', {
            'custom-select--error': hasError,
            'custom-select--small': small,
            'custom-select--medium': medium,
            'custom-select--auto': autoWidth,
            'custom-select--color': value === ''
          })}
          {...rest}
        >
          {options.map((item) => (
            <MenuItem key={item} value={item}>
              <FormControlLabel
                className="MuiFormControlLabel-root--small"
                control={
                  <Checkbox checked={value.indexOf(item) > -1}
                  />}
              />
              <ListItemText primary={item} />
            </MenuItem>
          ))}
        </Select>
    </>
  );
}

MultipleSelect.propTypes = {
  id: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.any,
  name: PropTypes.string,
  hasError: PropTypes.bool,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  disabled: PropTypes.bool
}

export default MultipleSelect

