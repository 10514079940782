import React from 'react'
import { Form, Formik } from 'formik'
import { Button } from '@mui/material'
import { FormGroup, Input, InputCurrency } from '../shared/Form'
import Percentage from '../../assets/images/icons/percentage.svg'

const CarLeasePaymentAdmin = ({ title, onSave, values, sending }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{...values}}
      onSubmit={values => onSave(values)}
    >
      {({ handleChange, handleBlur, values }) => (
        <Form>
          <h4 className="form__main-title">{title}</h4>
          <div className="form__flow-wrap">
          <FormGroup name="retail_price" labelText="Retail price (MSRP)">
            <InputCurrency
              name="retail_price"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.retail_price || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="fees" labelText="Fees">
            <InputCurrency
              name="fees"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.fees || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="down_payment" labelText="Downpayment">
            <InputCurrency
              name="down_payment"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.down_payment || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="trade_in_value" labelText="Trade-in value">
            <InputCurrency
              name="trade_in_value"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.trade_in_value || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="rebates" labelText="Rebates">
            <InputCurrency
              name="rebates"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.rebates || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="rebates_dealer_incentives" labelText="Factory to Dealer Incentives">
            <InputCurrency
              name="rebates_dealer_incentives"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.rebates_dealer_incentives || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="residual_value" labelText="Residul Value % of MSRP">
            <Input
              name="residual_value"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              step={0.1}
              value={values.residual_value || 0}
              iconName={Percentage}
            />
          </FormGroup>
          <FormGroup name="lease_term" labelText="Lease term (months)">
            <Input
              name="lease_term"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lease_term || 0}
            />
          </FormGroup>
          <FormGroup name="interest_rate" labelText="Interest rate">
            <Input
              name="interest_rate"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              step={0.1}
              value={values.interest_rate || 0}
              iconName={Percentage}
            />
          </FormGroup>
          <FormGroup name="sales_tax" labelText="Sales tax">
            <Input
              name="sales_tax"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              step={0.1}
              value={values.sales_tax || 0}
              iconName={Percentage}
            />
          </FormGroup>
          <FormGroup name="mileage_allotted_per_year" labelText="Mileage allotted/year">
            <Input
              name="mileage_allotted_per_year"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.mileage_allotted_per_year || 0}
            />
          </FormGroup>
          <FormGroup name="mileage_overage_cost_per_mile" labelText="Mileage overage cost/mile">
            <InputCurrency
              name="mileage_overage_cost_per_mile"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.mileage_overage_cost_per_mile || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="expected_miles_driven_per_year" labelText="Expected miles driven/year">
            <Input
              name="expected_miles_driven_per_year"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.expected_miles_driven_per_year || 0}
            />
          </FormGroup>
          <FormGroup name="gas_per_month" labelText="Gas/month">
            <InputCurrency
              name="gas_per_month"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.gas_per_month || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="maintenance_per_year" labelText="Maintenance/year">
            <InputCurrency
              name="maintenance_per_year"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.maintenance_per_year || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="yearly_insurance_premium" labelText="Increase in Yearly Insurance Premium">
            <InputCurrency
              name="yearly_insurance_premium"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.yearly_insurance_premium || 0}
              withoutSign
              icon
            />
          </FormGroup>
          </div>
          <div className="form__button">
            <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
              Save
              {sending && <span className="spinner" />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default CarLeasePaymentAdmin
