import { createSlice } from '@reduxjs/toolkit'
import { Search } from '../../api/Api'
import { setIsRelogin } from "./loadingSlice";

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    query: null,
    results: null,
    isLoading: false
  },
  reducers: {
    setSearchResults: (state, action) => {
      state.results = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setSearchParam: (state, action) => {
      state.query = action.payload
    },
  }
})

export const searchData = params => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    const response = await Search.get(params);
    await dispatch(setSearchResults(response.data));
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}


export const { setSearchResults, setSearchParam, setIsLoading } = searchSlice.actions

export default searchSlice.reducer