import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../../components/Layout/Layout'
import LayoutForm from '../../../components/Layout/LayoutForm'
import { BackButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import { Button } from '@mui/material'
import { fetchNonRetirement, postNonRetirement } from '../../../redux/slices/worthSlice'
import { FormGroup, InputCurrency } from '../../../components/shared/Form'
import Priority from '../../../components/shared/Priority/Priority'
import Modal from '../../../components/shared/Modal/Modal'
import ProgressCircle from '../../../components/shared/ProgressBar/ProgressCircle'
import { setIsOpen } from '../../../redux/slices/modalSlice'
import { worthNonRetirementSchema } from '../../../utils/validationFinances'

const WorthAssetsNonRetirement = ({ props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.user.user);
  const { nonRetirement } = useSelector(state => state.worth);
  const isOpen = useSelector(state => state.modal.isOpen);
  const sending = useSelector(state => state.sending.isSending);

  useEffect(() => {
    async function fetch() {
      dispatch(fetchNonRetirement())
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const onSubmit = (values) => {
    dispatch(postNonRetirement(values, props))
  }

  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }))
  }

  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }));
    history.push('/worth/assets')
  }

  return (
    <Layout>
      <LayoutForm small>
        <BackButton title="Back" />
        <PageTitle
          title="Non-retirement accounts"
        />
        <div className="layout-form__content-holder">
          <Formik
            enableReinitialize
            initialValues={nonRetirement && {...nonRetirement}}
            onSubmit={onSubmit}
            validationSchema={worthNonRetirementSchema}
          >
            {({ errors, handleChange, handleBlur, values, touched }) => (
              <Form>
                <FormGroup name="brokerage_account" labelText="Brokerage accounts ($)">
                  <InputCurrency
                    placeholder="Type brokerage accounts"
                    type="text"
                    name="brokerage_account"
                    onChange={handleChange}
                    value={values.brokerage_account || ''}
                    onBlur={handleBlur}
                    info={{
                      content:
                        <>
                          <h6>Brokerage Accounts</h6>
                          <Priority option="High" />
                          <div>A customer's account at a brokerage. There are 3 types of brokerage accounts: cash-management accounts, margin accounts, and discretionary accounts.  A discretionary account is typically what most people have when they buy and sell stocks and bonds within their taxable brokerage account.</div>
                        </>
                    }}
                    hasError={!!errors.brokerage_account}
                  />
                  {errors.brokerage_account && <span className="form__msg-error">{errors.brokerage_account}</span>}
                </FormGroup>
                <FormGroup name="trust_account" labelText="Trust accounts ($)">
                  <InputCurrency
                    placeholder="Type trust accounts"
                    type="text"
                    name="trust_account"
                    onChange={handleChange}
                    value={values.trust_account || ''}
                    onBlur={handleBlur}
                    info={{
                      content:
                        <>
                          <h6>Trust Accounts</h6>
                          <Priority option="High" />
                          <div>A savings account, established under a trust agreement, containing funds administered by a trustee for the benefit of another person or persons.</div>
                          <hr className="tooltip__line"/>
                          <h6>Example</h6>
                          <div>Say you want to put money aside for your children and would like to take advantage of the tax savings since the trust will tax gains at your child's tax rate, enter the amount you currently have in value in that trust.</div>
                        </>
                    }}
                    hasError={!!errors.trust_account}
                  />
                  {errors.trust_account && <span className="form__msg-error">{errors.trust_account}</span>}
                </FormGroup>
                <FormGroup name="annuities_account" labelText="Annuities accounts ($)">
                  <InputCurrency
                    placeholder="Type annuities accounts"
                    type="text"
                    name="annuities_account"
                    onChange={handleChange}
                    value={values.annuities_account || ''}
                    onBlur={handleBlur}
                    info={{
                      content:
                        <>
                          <h6>Annuities Accounts</h6>
                          <Priority option="High" />
                          <div>A contract sold by an insurance company designed to provide payments to the holder at specified intervals, usually after retirement.</div>
                        </>
                    }}
                    hasError={!!errors.annuities_account}
                  />
                  {errors.annuities_account && <span className="form__msg-error">{errors.annuities_account}</span>}
                </FormGroup>
                <FormGroup name="cash_value" labelText="Cash value of life insurance policies ($)">
                  <InputCurrency
                    placeholder="Type cash value"
                    type="text"
                    name="cash_value"
                    onChange={handleChange}
                    value={values.cash_value || ''}
                    onBlur={handleBlur}
                    info={{
                      content:
                        <>
                          <h6>Life Insurance Cash Value</h6>
                          <Priority option="High" />
                          <div>The accumulated cash value of a life insurance policy that can be paid out before death.</div>
                        </>
                    }}
                    hasError={!!errors.cash_value}
                  />
                  {errors.cash_value && <span className="form__msg-error">{errors.cash_value}</span>}
                </FormGroup>
                <FormGroup name="vested_stock" labelText="Vested but Unexercised Stock ($)">
                  <InputCurrency
                    placeholder="Type cash value"
                    type="text"
                    name="vested_stock"
                    onChange={handleChange}
                    value={values.vested_stock || ''}
                    onBlur={handleBlur}
                    info={{
                      content:
                        <>
                          <h6>Vested but Unexercised Stock</h6>
                          <Priority option="High" />
                          <div>Vested, unexercised stocks are stocks you now officially own but have not sold yet.</div>
                        </>
                    }}
                    hasError={!!errors.vested_stock}
                  />
                  {errors.vested_stock && <span className="form__msg-error">{errors.vested_stock}</span>}
                </FormGroup>
                <FormGroup name="unvested_stock" labelText="Unvested Stock ($)">
                  <InputCurrency
                    placeholder="Type cash value"
                    type="text"
                    name="unvested_stock"
                    onChange={handleChange}
                    value={values.unvested_stock || ''}
                    onBlur={handleBlur}
                    info={{
                      content:
                        <>
                          <h6>Unvested Stock</h6>
                          <Priority option="High" />
                          <div>Unvested stock are stocks you will own in the future once you've reached your vesting date. As it stands today, unvested stock are technically not part of your assets yet.</div>
                        </>
                    }}
                    hasError={!!errors.unvested_stock}
                  />
                  {errors.unvested_stock && <span className="form__msg-error">{errors.unvested_stock}</span>}
                </FormGroup>
                <div className="form__button form__button--center">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Save
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <Modal
          open={isOpen}
          small
          button="Done"
          onClick={handleClick}
          close={onClose}
        >
          <h3>Good Job!</h3>
          <p>Your progress increased 100%</p>
          <ProgressCircle amount={100} small />
        </Modal>
      </LayoutForm>
    </Layout>
  );
};

export default WorthAssetsNonRetirement;
