import React from 'react';
import LayoutAdmin from '../../components/Layout/LayoutAdmin';
import { Redirect } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Input, FormGroup, FormSelect } from '../../components/shared/Form';
import { InfoCompanySchema } from '../../utils/validation';
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import UploadCompanyPhoto from '../../components/shared/UploadPhoto/UploadCompanyPhoto';
import { setUploading, setAvatarURL, postCompanyInfo } from '../../redux/slices/companiesSlice';
import { Button } from '@mui/material';
import SVGIcon from '../../components/shared/SVGIcon/SVGIcon';
import Trash from '../../assets/images/icons/trash.svg';
import Modal from '../../components/shared/Modal/Modal';
import isEmpty from 'lodash/isEmpty';
import states from '../../Data/states';



const AdminCompanyAddInfo = ({ props, isAdmin }) => {
  const dispatch = useDispatch();
  const { id, permissions } = useSelector((state) => state.user.user);
  const { uploadingAvatar, nameAvatar } = useSelector((state) => state.companies);
  const companyAvatar = useSelector((state) => state.companies.avatarURL);
  const sending = useSelector((state) => state.sending.isSending);
  const error = useSelector((state) => state.companies.error);

  const onSubmit = async (values, setErrors) => {
    let formData = new FormData();

    const bolbFile = await fetch(companyAvatar).then((res) => res.blob());
    let imgFile = new File([bolbFile], nameAvatar, { type: 'image/jpg' });
    if (imgFile) {
      formData.append('image', imgFile);
    }

    formData.append('name', values.name);
    formData.append('website', values.website);
    formData.append('vsft_name', values.vsft_name);
    formData.append('ein', values.ein);
    formData.append('email', values.email);
    formData.append('address', values.address);
    formData.append('optional_address', values.optional_address);
    formData.append('city', values.city);
    formData.append('state', values.state);
    formData.append('zip', values.zip);

    dispatch(postCompanyInfo(formData, props, setErrors));
  };

  if (!!id && !permissions?.ap_add_company) return <Redirect to="/admin" />;

  return (
    <LayoutAdmin isAdmin={isAdmin}>
      <div className="admin__title">
        <h3>Add new company</h3>
      </div>
      <div className="company-step">
        <div className="company-step__block">
          <div className="company-step__number step-active">1</div>
          <p className="company-step__title">Company info</p>
        </div>
        <div className="company-step__block">
          <div className="company-step__number">2</div>
          <p className="company-step__title">Company Permissions</p>
        </div>
        <div className="company-step__block">
          <div className="company-step__number">3</div>
          <p className="company-step__title">Company SuperAdmin Info</p>
        </div>
      </div>
      <div className="company">
        <Formik
          enableReinitialize
          initialValues={{
            name: '',
            website: '',
            vsft_name: '',
            ein: '',
            email: '',
            address: '',
            optional_address: '',
            city: '',
            state: '',
            zip: '',
          }}
          validationSchema={InfoCompanySchema}
          onSubmit={(values, { setErrors }) => onSubmit(values, setErrors)}
        >
          {({ errors, handleChange, handleBlur, values, touched, setFieldValue }) => (
            <Form>
              <div>
                <div className="comapny__form comapny__logo">
                  <button type="button" onClick={() => dispatch(setUploading(true))} className="comapny__form--avatar">
                    {!isEmpty(companyAvatar) ? (
                      <img src={companyAvatar} alt="Company logo" />
                    ) : (
                      <CameraAltOutlinedIcon style={{ color: '#8C8C8C' }} />
                    )}
                  </button>
                  <div className="comapny__form--avatar-btn details">
                    <h4>Upload logo</h4>
                    <p>PNG, JPG, JPEG formats with size 300x300 px and up to 3 MB.</p>
                    <div className="logo-buttons">
                      <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        onClick={() => dispatch(setUploading(true))}
                      >
                        {!isEmpty(companyAvatar) ? 'Reload logo' : 'Upload logo'}
                      </Button>
                      {!isEmpty(companyAvatar) && (
                        <div>
                          <span>{nameAvatar}</span>
                          <button className="delete-btn" onClick={() => dispatch(setAvatarURL(''))}>
                            <SVGIcon icon={Trash} color="#8c8c8c" />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {error?.image && <span className="form__msg-error">{error?.image[0]}</span>}
              </div>
              <h5 className="mt--medium">About company</h5>
              <div className="comapny__form">
                <FormGroup name="name" labelText="Full Company name">
                  <Input
                    name="name"
                    placeholder="Enter full company name"
                    type="text"
                    onChange={handleChange}
                    value={values.name || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.name && touched.name}
                  />
                  {touched.name && errors.name && <span className="form__msg-error">{errors.name}</span>}
                </FormGroup>
                <FormGroup name="website" labelText="Company website">
                  <Input
                    name="website"
                    placeholder="http://yoursite.com"
                    type="text"
                    onChange={handleChange}
                    value={values.website || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.website && touched.website}
                  />
                  {touched.website && errors.website && <span className="form__msg-error">{errors.website}</span>}
                </FormGroup>
              </div>
              <h5 className="mt--medium">Additional Information</h5>
              <div className="comapny__form">
                <FormGroup name="vsft_name" labelText="Company's desired display name">
                  <Input
                    name="vsft_name"
                    placeholder="Enter your company displayed name"
                    type="text"
                    onChange={handleChange}
                    value={values.vsft_name || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.vsft_name && touched.vsft_name}
                  />
                  {touched.vsft_name && errors.vsft_name && <span className="form__msg-error">{errors.vsft_name}</span>}
                </FormGroup>
                <FormGroup name="ein" labelText="Employer Identification number (EIN)">
                  <Input
                    name="ein"
                    placeholder="Enter EIN"
                    type="text"
                    onChange={handleChange}
                    value={values.ein || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.ein && touched.ein}
                  />
                  {touched.ein && errors.ein && <span className="form__msg-error">{errors.ein}</span>}
                </FormGroup>
                <FormGroup name="email" labelText="Feedback email">
                  <Input
                    name="email"
                    placeholder="example@gmail.com"
                    type="text"
                    onChange={handleChange}
                    value={values.email || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.email && touched.email}
                  />
                  {touched.email && errors.email && <span className="form__msg-error">{errors.email}</span>}
                </FormGroup>
              </div>
              <h5 className="mt--medium">Address</h5>
              <div className="comapny__form">
                <FormGroup name="address" labelText="Address">
                  <Input
                    placeholder="Enter full address"
                    name="address"
                    onChange={handleChange}
                    value={values.address || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.address && touched.address}
                  />
                  {touched.address && errors.address && <span className="form__msg-error">{errors.address}</span>}
                </FormGroup>
                <FormGroup name="optional_address" labelText="Apt/Suite/Unit/Number (optional)">
                  <Input
                    name="optional_address"
                    placeholder="Enter Apt/Suite/Unit/Number"
                    type="text"
                    onChange={handleChange}
                    value={values.optional_address || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.optional_address && touched.optional_address}
                  />
                  {touched.optional_address && errors.optional_address && (
                    <span className="form__msg-error">{errors.optional_address}</span>
                  )}
                </FormGroup>
                <FormGroup name="city" labelText="City">
                  <Input
                    name="city"
                    placeholder="Enter city"
                    type="text"
                    onChange={handleChange}
                    value={values.city || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.city && touched.city}
                  />
                  {touched.city && errors.city && <span className="form__msg-error">{errors.city}</span>}
                </FormGroup>
                <FormGroup name="state" labelText="State">
                  <FormSelect
                    options={states}
                    id="state"
                    value={values.state || ''}
                    name="state"
                    onChange={(e) => {
                      setFieldValue(e.target.name, e.target.value);
                    }}
                    placeholder="Select state"
                    hasError={!!errors.state && touched.state}
                  />
                  {touched.state && errors.state && <span className="form__msg-error">{errors.state}</span>}
                </FormGroup>
                <FormGroup name="zip" labelText="ZIP code">
                  <Input
                    name="zip"
                    placeholder="Enter ZIP code"
                    type="text"
                    onChange={handleChange}
                    value={values.zip || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.zip && touched.zip}
                  />
                  {touched.zip && errors.zip && <span className="form__msg-error">{errors.zip}</span>}
                </FormGroup>
              </div>
              <div className="form__button">
                {(error || !isEmpty(errors)) && <div className="form__msg-error">All inputs must be filled!</div>}
                <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                  Continue
                  {sending && <span className="spinner" />}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <Modal open={uploadingAvatar} close={() => dispatch(setUploading(false))} customClass="upload-modal__content">
          <div className="comapny__form--modal">
            <h3>Upload logo</h3>
            <p>Upload an images only with transparent background in PNG format with size 300x300 px and up to 3 MB.</p>
            <UploadCompanyPhoto />
          </div>
        </Modal>
      </div>
    </LayoutAdmin>
  );
};

export default AdminCompanyAddInfo;
