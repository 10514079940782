import React, { useEffect } from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useParams, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../../components/Layout/Layout'
import { BackButton, DeleteButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import LayoutForm from '../../../components/Layout/LayoutForm'
import { Button } from '@mui/material'
import {
  postFutureChildData,
  updateFutureChildData,
  removeFutureChild,
  fetchFutureChildData
} from '../../../redux/slices/childrenSlice'
import { Input, FormGroup } from '../../../components/shared/Form'
import ModalBox from '../../../components/shared/Modal/Modal'
import { setIsOpen } from '../../../redux/slices/modalSlice'


const FutureChildrenDetails = ({ props, isEdit }) => {
  const dispatch = useDispatch();
  const type = useLocation()?.state?.type;
  const userId = useSelector(state => state.user.user.id);
  const sending = useSelector(state => state.children.loading);
  const child = useSelector(state => state.children.future);
  const isOpen = useSelector(state => state.modal.isOpen);
  const { id }  = useParams();
  
  useEffect(() => {
    async function fetch() {
      await dispatch(fetchFutureChildData(id));
    }

    if (userId && id) {
      fetch()
    }

    // eslint-disable-next-line
  }, [userId, id])

  const onSubmit = async values => {
    await dispatch(postFutureChildData(values, type, props))
  }

  const updateData = values => {
    dispatch(updateFutureChildData(id, values, props))
  }

  const deleteChild = () => {
    dispatch(removeFutureChild(id, props))
    dispatch(setIsOpen({isOpen: false}))
  }

  return (
    <Layout>
      <LayoutForm small>
        <BackButton title="Back to Future Children" />
        {isEdit && (
          <DeleteButton
            className="delete-button--position"
            onClick={() => dispatch(setIsOpen({ isOpen: true }))}
          />
        )}
        <PageTitle
          title={isEdit && child && child.name ? child.name : "Future Child"}
          content="Are you planning to have children?  If so, please let us know a little about those plans."
          status={isEdit ? 100 : "0"}
        />
        <div className="layout-form__content-holder">
          <Formik
            enableReinitialize
            initialValues={
              isEdit && child
                ? { ...child }
                : {
                    name: "",
                    age: null,
                    type: type,
                  }
            }
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Required"),
              age: Yup.number()
                .integer("Please check this value")
                .typeError("Age must be a number")
                .max(150, "Please check age")
                .required("Required"),
            })}
            onSubmit={isEdit ? updateData : onSubmit}
          >
            {({ errors, handleChange, handleBlur, values, touched }) => (
              <Form>
                <FormGroup name="name" labelText="First name">
                  <Input
                    name="name"
                    placeholder="Type a name"
                    type="text"
                    onChange={handleChange}
                    value={values.name || ""}
                    onBlur={handleBlur}
                    hasError={!!errors.name && touched.name}
                  />
                  {touched.name && errors.name && (
                    <span className="form__msg-error">{errors.name}</span>
                  )}
                </FormGroup>
                <FormGroup name="age" labelText="Your age at child's birth">
                  <Input
                    name="age"
                    placeholder="Type your age"
                    type="text"
                    onChange={handleChange}
                    value={values.age || ""}
                    onBlur={handleBlur}
                    hasError={!!errors.age && touched.age}
                  />
                  {touched.age && errors.age && (
                    <span className="form__msg-error">{errors.age}</span>
                  )}
                </FormGroup>
                <div className="form__button form__button--center">
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    type="submit"
                    disabled={sending}
                  >
                    {type === 'education' ? "Next" : "Save" }
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <ModalBox
          small
          inline
          open={isOpen}
          title="Are you sure?"
          button="Remove Item"
          closeButton="Cancel"
          onClick={deleteChild}
          close={() => dispatch(setIsOpen({ isOpen: false }))}
        />
      </LayoutForm>
    </Layout>
  );
};

export default FutureChildrenDetails;
