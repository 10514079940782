import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../shared/SVGIcon/SVGIcon'
import Logo from '../shared/Logo/Logo'
import MailIcon from '../../assets/images/mail.svg'

const CheckEmail = ({ icon, title, children }) => {
  return (
    <div className="check-email">
      <Logo className="check-email__logo" />
      <div className="check-email__content">
        <div className="check-email__icon">
          <Icon icon={MailIcon} size="20px" />
        </div>
        <h2>{title}</h2>
        {children}
      </div>
    </div>
  )
};

CheckEmail.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
};

export default CheckEmail
