import React, { useState } from 'react'
import Modal from '../shared/Modal/Modal'
import {Button, Drawer, TextareaAutosize} from '@mui/material'
import { Form, Formik } from 'formik'
import { FormGroup, FormSelect, Input } from '../shared/Form'
import SVGIcon from '../shared/SVGIcon/SVGIcon'
import Icon from '../../assets/images/icons/icon-ongratulations.svg'
import typeFeedback from '../../Data/typeFeedback'
import { FeedBackSchema } from '../../utils/validation'
import { FeedbackAPI } from '../../api/Api'


const Feedback = ({ title, user }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isDrawOpen, setIsDrawOpen] = useState(false)
  const [sending, setSending] = useState(false)

  const handleClick = async (values) => {
    await setSending(true)
    await FeedbackAPI.post(values)
    await setSending(false)
    await setIsDrawOpen(false)
    await setIsOpen(true)
  }

  const onClose = () => setIsDrawOpen(false)

  return (
    <>
      <button onClick={() => setIsDrawOpen(true)}>{title}</button>

      <Modal
        open={isOpen}
        small
        close={() => setIsOpen(false)}
      >
        <div>
          <div className="modal__title-icon">
            <SVGIcon icon={Icon} />
          </div>
          <h4>Thank you for your feedback!</h4>
        </div>
      </Modal>

      <Drawer open={isDrawOpen} onClose={onClose} anchor="right" className="drawer">
        <button onClick={onClose} className="drawer__close">Close</button>
        <div className="drawer__holder">
          <Formik
            enableReinitialize
            initialValues={{
              name: user ? user.first_name : '',
              surname: user ? user.last_name : '',
              email: user ? user.email : '',
              reason: '',
              message: ''
            }}
            validationSchema={FeedBackSchema}
            onSubmit={values => handleClick(values)}
          >
            {({ errors, handleChange, handleBlur, values, touched }) => (
              <Form>
                <div className="drawer__title drawer__title--alt">{title}</div>
                <p>We really value your feedback and would love it if you could share your insights with us. Please use the fields below to send us an email with your thoughts.</p>
                <FormGroup name="name" labelText="First name">
                  <Input
                    name="name"
                    placeholder="Type name"
                    type="text"
                    onChange={handleChange}
                    value={values.name || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.name && touched.name}
                  />
                  {touched.name && errors.name && <span className="form__msg-error">{errors.name}</span>}
                </FormGroup>
                <FormGroup name="surname" labelText="Last name">
                  <Input
                    name="surname"
                    placeholder="Type name"
                    type="text"
                    onChange={handleChange}
                    value={values.surname || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.surname && touched.surname}
                  />
                  {touched.surname && errors.surname && <span className="form__msg-error">{errors.surname}</span>}
                </FormGroup>
                <FormGroup name="email" labelText="Email">
                  <Input
                    name="email"
                    placeholder="Type email"
                    type="email"
                    onChange={handleChange}
                    value={values.email || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.email && touched.email}
                  />
                  {touched.email && errors.email && <span className="form__msg-error">{errors.email}</span>}
                </FormGroup>
                <FormGroup name="reason" labelText="Type of feedback">
                  <FormSelect
                    options={typeFeedback}
                    value={values.reason || ''}
                    name="reason"
                    onChange={handleChange}
                    placeholder="Select..."
                    hasError={!!errors.reason}
                  />
                  {touched.reason && errors.reason && <span className="form__msg-error">{errors.reason}</span>}
                </FormGroup>

                <FormGroup name="message" labelText="Comments">
                  <TextareaAutosize
                    name="message"
                    value={values.message || ''}
                    className={`form__textarea form__textarea--alt form__textarea--resize ${touched.message && !!errors.message &&  'form__textarea--error'}`}
                    placeholder="Type comments here."
                    onChange={handleChange}
                  />
                  {touched.reason && errors.message && <span className="form__msg-error">{errors.message}</span>}
                </FormGroup>
                <div className="form__button drawer__buttons">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Submit
                    {sending && <span className="spinner" />}
                  </Button>
                  <Button size="large" className="MuiButton-textGray" onClick={onClose}>Cancel</Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Drawer>
    </>
  )
};

export default Feedback
