import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Layout from '../../components/Layout/Layout'
import { BackButton } from '../../components/shared/Buttons'
import TreeSubItem from '../../components/Tree/TreeSubItem'
import { getPercent } from '../../utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserFamilyAssets, fetchUserFamilyChildren, fetchUserFamilyData } from '../../redux/slices/userSlice'
import { fetchTotalPercent } from "../../redux/slices/percentagesSlice";
import {
  fetchFutureChildrenData,
} from "../../redux/slices/childrenSlice";
import TreeCount from '../../components/Tree/TreeCount'
import { fetchAnalysisTasks } from '../../redux/slices/taskSlice'

const Future = () => {
  const dispatch = useDispatch()
  const { id } = useSelector(state => state.user.user)
  const { want_more_children } = useSelector(state => state.user.family.children)
  const { plan_to_purchase } = useSelector(state => state.user.family.assets)
  const { employment_sabbaticals, feature_paying_education } = useSelector(
    (state) => state.user.family.others
  );
  const isBasic = useSelector(state => state.user.user.plan_type === 1)
  const futureChildrenList = useSelector(state => state.children.future)
  const percents = useSelector(state => state.percentages.percents)
  const loading = useSelector(state => state.sending.isSending)
  const analysis = useSelector(state => state.task.analysis)
  const totalPercent = useSelector((state) => state.percentages.total);


  useEffect(() => {
    async function fetch() {
      Promise.all([
        //dispatch(fetchPercentages()),
        dispatch(fetchUserFamilyChildren(id)),
        dispatch(fetchUserFamilyAssets(id)),
        dispatch(fetchUserFamilyData(id)),
        dispatch(fetchFutureChildrenData()),
        dispatch(fetchAnalysisTasks()),
        dispatch(fetchTotalPercent(isBasic)),
      ]);
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const sabbatical      = getPercent(percents, 'sabbatical')
  const futureEducation = getPercent(percents, 'future-education')
  const futureChildren  = getPercent(percents, 'future-children')
  const objectives      = getPercent(percents, 'objectives')
  const retirement      = getPercent(percents, 'retirement')
  const totalFuture =
    ((!isBasic && employment_sabbaticals && sabbatical) +
      (!isBasic && want_more_children && futureChildren) +
      (!isBasic &&
        futureChildrenList?.length > 0 &&
        feature_paying_education &&
        futureEducation) +
      (!isBasic && plan_to_purchase && objectives) +
      retirement) /
    (1 +
      (!isBasic && want_more_children && 1) +
      (!isBasic &&
        feature_paying_education &&
        futureChildrenList?.length > 0 &&
        1) +
      (!isBasic && employment_sabbaticals && 1) +
      (!isBasic && plan_to_purchase && 1));

  const menuList = [
    {
      path: "/future-children",
      count: futureChildren || "0",
      title: "Future children",
      isBasic: isBasic,
      isDisabled: isBasic || !want_more_children,
      redirect: "/profile-summary/about-your-family/children",
      modalContent: (
        <p>
          You need to add the relevant information in the{" "}
          <strong className="text-success">About You</strong> page in order to
          activate this section.{" "}
        </p>
      ),
    },
    {
      path: "/retirement",
      count: retirement || "0",
      title: "Retirement",
    },
    {
      path: "/objectives",
      count: objectives || "0",
      title: "Objectives",
      isBasic: isBasic,
      isDisabled: isBasic || !plan_to_purchase,
      redirect: "/profile-summary/about-your-family/assets",
      modalContent: (
        <p>
          You need to add the relevant information in the{" "}
          <strong className="text-success">About You</strong> page in order to
          activate this section.{" "}
        </p>
      ),
    },
    {
      path: "/sabbatical",
      count: sabbatical || "0",
      title: "Sabbatical",
      isBasic: isBasic,
      isDisabled: isBasic || !employment_sabbaticals,
      redirect: "/profile-summary/about-your-family/others",
      modalContent: (
        <p>
          You need to add the relevant information in the{" "}
          <strong className="text-success">About You</strong> page in order to
          activate this section.{" "}
        </p>
      ),
    },
    {
      path: "/future-education",
      count: futureEducation || "0",
      title: "Future education",
      isBasic: isBasic,
      isDisabled:
        isBasic ||
        futureChildrenList?.length === 0 ||
        !feature_paying_education,
      redirect: !want_more_children
        ? "/profile-summary/about-your-family/children"
        : !feature_paying_education
        ? "/profile-summary/about-your-family/others"
        : "/future-children",
      modalContent: (
        <p>
          You need to add the relevant information in the{" "}
          <strong className="text-success">
            {!want_more_children || !feature_paying_education
              ? "About You"
              : "Future children"}
          </strong>{" "}
          page in order to activate this section.{" "}
        </p>
      ),
    },
  ];

  return (
    <Layout totalPercent={totalPercent} tree>
      <BackButton title="Back" className="back-button--position" path="/input" />
      <div className="tree tree--mobile-page">
        <div className="tree__title">
          <h2>Future</h2>
          <TreeCount value={totalFuture.toFixed()} loading={loading} />
        </div>
        {menuList &&
          menuList.map((item, id) => (
            <TreeSubItem
              key={`menu-${id}`}
              path={item.path}
              title={item.title}
              count={item.count}
              isBasic={item.isBasic}
              isDisabled={item.isDisabled}
              redirect={item.redirect}
              modalContent={item.modalContent}
              isLoading={loading}
            />
          ))}
        <div className="tree__task">
          <Link to="/tasks">
            {analysis && analysis.openCount ? analysis.openCount : "0"}
          </Link>
        </div>
      </div>
    </Layout>
  );
}

export default Future
