import React, { useEffect } from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../../components/Layout/Layout'
import { BackButton, DeleteButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import LayoutForm from '../../../components/Layout/LayoutForm'
import { Button, TextareaAutosize } from '@mui/material'
import { FormGroup, InputCurrency, FormSelect } from '../../../components/shared/Form'
import { fetchUserIncomes, postUserIncomes, removeIncome } from '../../../redux/slices/financesSlice'
import { fetchIncomesData } from '../../../redux/slices/optionsSlice'
import { setIsOpen } from '../../../redux/slices/modalSlice'
import ModalBox from '../../../components/shared/Modal/Modal'

const IncomeDetails = ({ props, isEdit }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(state => state.modal.isOpen);
  const userId = useSelector(state => state.user.user.id);
  const sending = useSelector(state => state.sending.isSending);
  const incomesCategories = useSelector(state => state.options.incomes);
  const userIncomes = useSelector(state => state.finances.incomes);
  const { id }  = useParams();

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchIncomesData());
      await dispatch(fetchUserIncomes())
    }

    if (userId) {
      fetch()
    }

    // eslint-disable-next-line
  }, [userId, isEdit])

  const convertIncomeData = (id, list) => {
    const data = list.find(item => item.id === parseInt(id, 10))
    let { body, income_id } = data

    if (income_id) { body = income_id }

    return {
      ...data,
      body,
      income_id
    }
  }

  const deleteIncome = () => {
    dispatch(removeIncome(id, props));
    dispatch(setIsOpen({isOpen: false}))
  }

  const onSubmit = values => {
    dispatch(postUserIncomes(values, props, id, isEdit))
  };

  return (
    <Layout>
      <LayoutForm small>
        <BackButton title="Back" />
        {isEdit && <DeleteButton className="delete-button--position" onClick={() => dispatch(setIsOpen({isOpen: true}))} />}
        <PageTitle
          title="Income"
          content="Tell us about your sources and amounts of income. If you anticipate changes to your income (beyond standard wage increases), please include them in the blank columns below. Simply enter the year in which those changes will begin and the new amount for a particular change."
          status={60}
        />
        <div className="layout-form__content-holder">
          <Formik
            enableReinitialize
            initialValues={isEdit && userIncomes
              ? {...convertIncomeData(id, userIncomes)}
              : {
                additional_info: '',
                amount: '',
                body: null,
                income_id: null
              }
            }
            validationSchema={Yup.object().shape({
              amount: Yup.string().required('Required')
            })}
            onSubmit={onSubmit}
          >
            {({ errors, handleChange, handleBlur, values, touched, setFieldValue }) => (
              <Form>
                <FormGroup name="body" labelText="Category">
                  <FormSelect
                    isCreatable
                    options={incomesCategories}
                    value={values.body || ''}
                    name="body"
                    onChange={handleChange}
                    placeholder="Select"
                    setFieldValue={setFieldValue}
                  />
                </FormGroup>
                <FormGroup name="amount" labelText="Amount">
                  <InputCurrency
                    placeholder="Amount"
                    name="amount"
                    onChange={handleChange}
                    value={values.amount || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.amount && touched.amount}
                  />
                  {touched.amount && errors.amount && <span className="form__msg-error">{errors.amount}</span>}
                </FormGroup>
                <h5 className="mt--medium">Additional info</h5>
                <FormGroup name="additional_info" labelText="Is there any other Income information that you believe is important for ValueSoft to know about? If so, please let us know about it:">
                  <TextareaAutosize
                    maxLength="200"
                    name="additional_info"
                    value={values.additional_info || ''}
                    className="form__textarea"
                    placeholder="Maximum characters: 200"
                    onChange={handleChange}
                  />
                </FormGroup>
                <div className="form__button form__button--center">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Save
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <ModalBox
          small
          inline
          open={isOpen}
          title="Are you sure?"
          button="Remove Item"
          closeButton="Cancel"
          onClick={deleteIncome}
          close={() => dispatch(setIsOpen({isOpen: false}))}
        />
      </LayoutForm>
    </Layout>
  );
};

export default IncomeDetails;
