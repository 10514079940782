import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Button } from '@mui/material'
import CheckEmail from '../../components/Auth/CheckEmail'


const AdminCompanySuccess = ({ props }) => {
  const history = useHistory();
  const { companyId } = useParams();
  return (
    <CheckEmail title="Success">
      <p className="mb--medium">A signup link has been sent to Company SuperAdmin’s email address.</p>
      <div className="success-button__wrap">
        <Button variant="outlined" onClick={() => history.push(`/admin/company/${companyId}/details`)}>
          My company
        </Button>
        <Button variant="outlined" onClick={() => history.push('/admin/companies')}>
          Back to companies
        </Button>
      </div>
    </CheckEmail>
  )
};

export default AdminCompanySuccess;