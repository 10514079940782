import { createSlice } from '@reduxjs/toolkit'
import { Risk } from '../../api/Api'
import { setSending } from './sendingSlice'
import { setIsOpen } from './modalSlice'
import {setNewTasksCount} from "./taskSlice";
import { setIsRelogin } from "./loadingSlice";

const riskSlice = createSlice({
  name: 'risk',
  initialState: {
    overall_risk: null,
    preferred_portfolio: null,
    return_risk: null,
    lose_twenty: null,
    lose_fifty: null,
    bengraham: null,
    additional_info: '',
    portfolio_now: null,
    portfolio_thirty: null,
    portfolio_sixty: null,
    portfolio_eighty: null
  },
  reducers: {
    setRiskData: (state, action) => ({
      ...state,
      ...action.payload
    }),
  }
})

export const fetchRiskData = () => async (dispatch) => {
  try {
    const response = await Risk.get();
    await dispatch(setRiskData(response.data.data))
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postRiskData = (data, props) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    const response = await Risk.post(data);
    await dispatch(setRiskData(response.data.data));
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
    await dispatch(setSending({ isSending: false }));
    await dispatch(() => props.history.push('/risk-portfolio'));
  } catch (e) {
    console.error(e);
    dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchRiskPortfolioData = () => async (dispatch) => {
  try {
    const response = await Risk.getPortfolio();
    await dispatch(setRiskData(response.data.data))
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postRiskPortfolioData = (data) => async (dispatch) => {
  try {
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({ isSending: true }));
    const response = await Risk.postPortfolio(data);
    await dispatch(setRiskData(response.data.data));
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
    await dispatch(setSending({ isSending: false }));
  } catch (e) {
    console.error(e);
    dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}


export const { setRiskData } = riskSlice.actions

export default riskSlice.reducer