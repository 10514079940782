import { combineReducers } from 'redux'

import authReducer from '../slices/authSlice'
import userReducer from '../slices/userSlice'
import loadingReducer from '../slices/loadingSlice'
import optionsReducer from '../slices/optionsSlice'
import modalReducer from '../slices/modalSlice'
import sendingReducer from '../slices/sendingSlice'
import riskReducer from '../slices/riskSlice'
import childrenReducer from '../slices/childrenSlice'
import modeReducer from '../slices/modeSlice'
import financesReducer from '../slices/financesSlice'
import taskReducer from '../slices/taskSlice'
import notificationsReducer from '../slices/notificationsSlice'
import insuranceReducer from '../slices/insuranceSlice'
import protectionReducer from '../slices/protectionSlice'
import worthReducer from '../slices/worthSlice'
import infoReducer from '../slices/infoSlice'
import futureReducer from '../slices/futureSlice'
import blogReducer from '../slices/blogSlice'
import percentagesReducer from '../slices/percentagesSlice'
import searchReducer from '../slices/searchSlice'
import assumptionsReducer from '../slices/assumptionsSlice'
import analysisReducer from '../slices/analysisSlice'
import calculatorReducer from '../slices/calculatorSlice'
import additionalReducer from '../slices/additionalSlice'
import statisticsSlice from '../slices/statisticsSlice'
import calculatorsSetupSlice from '../slices/calculatorsSetupSlice'
import companiesReducer from '../slices/companiesSlice'
import userCompaniesReducer from '../slices/userCompaniesSlice'

export default combineReducers({
  auth: authReducer,
  user: userReducer,
  children: childrenReducer,
  risk: riskReducer,
  finances: financesReducer,
  task: taskReducer,
  insurance: insuranceReducer,
  protection: protectionReducer,
  worth: worthReducer,
  future: futureReducer,
  options: optionsReducer,
  modal: modalReducer,
  sending: sendingReducer,
  loading: loadingReducer,
  mode: modeReducer,
  notifications: notificationsReducer,
  info: infoReducer,
  blog: blogReducer,
  percentages: percentagesReducer,
  search: searchReducer,
  assumptions: assumptionsReducer,
  analysis: analysisReducer,
  calculator: calculatorReducer,
  additional: additionalReducer,
  calculatorsSetup: calculatorsSetupSlice,
  companies: companiesReducer,
  statistics: statisticsSlice,
  userCompanies: userCompaniesReducer,
})
