import React from 'react'
import PropTypes from 'prop-types'
import Trash from '../../../assets/images/icons/trash.svg'
import SVGIcon from '../../../components/shared/SVGIcon/SVGIcon'

const DeleteButton = ({ onClick, className }) => (
  <button onClick={onClick} className={`delete-button ${className || ''}`}>
    <span className="delete-button__icon">
      <SVGIcon icon={Trash} color="#8c8c8c" />
    </span>
    <span className="delete-button__text">Delete</span>
  </button>
);

DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default DeleteButton;
