import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import numeral from 'numeral'
import { BackButton, AddButton } from '../../../components/shared/Buttons'
import Layout from '../../../components/Layout/Layout'
import PageTitle from '../../../components/PageTitle/PageTitle'
import LayoutForm from '../../../components/Layout/LayoutForm'
import { Button, TextareaAutosize } from '@mui/material'
import {
  fetchCash,
  fetchFixedAsset,
  fetchRetirement,
  fetchNonRetirement,
  fetchWorthOthers
} from '../../../redux/slices/worthSlice'
import { postInfo, fetchInfoByParams } from '../../../redux/slices/infoSlice'
import Priority from '../../../components/shared/Priority/Priority'
import { fetchPercentages, fetchTotalPercent } from '../../../redux/slices/percentagesSlice'
import { getPercent } from '../../../utils/utils'
import Modal from '../../../components/shared/Modal/Modal'
import SVGIcon from '../../../components/shared/SVGIcon/SVGIcon'
import Icon from '../../../assets/images/icons/icon-ongratulations.svg'

const WorthAssets = ({ props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpenSaveModal, setIsOpenSaveModal] = useState(false)
  const [isMobile, setIsMobile] = useState(false);
  const { id } = useSelector(state => state.user.user);
  const isBasic = useSelector((state) => state.user.user.plan_type === 1);
  const totalPercent = useSelector(state => state.percentages.total)
  const { cash, retirement, nonRetirement, fixedAsset, othersAssets } = useSelector(state => state.worth);
  const info = useSelector(state => state.info.info);
  const sending = useSelector(state => state.sending.isSending);
  const percents = useSelector(state => state.percentages.percents);
  const mobileView = 759;

  useEffect(() => {
    async function fetch() {
      Promise.all([
        dispatch(fetchPercentages()),
        dispatch(fetchInfoByParams({type: 'worth-assets'})),
        dispatch(fetchCash()),
        dispatch(fetchRetirement()),
        dispatch(fetchNonRetirement()),
        dispatch(fetchFixedAsset()),
        dispatch(fetchWorthOthers(true)),
        dispatch(fetchTotalPercent(isBasic))
      ])
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const handleResize = () => {
    (mobileView >= window.innerWidth) ? setIsMobile(true) : setIsMobile(false)
  }

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onSubmit = values => {
    dispatch(postInfo(values))
    setIsOpenSaveModal(true)
  }

  const convertNumberToString = val => {
    if (val === 1) return 'No'

    if (val === 2) {
      return 'Yes'
    } else {
      return 'Not Applicable'
    }
  }

  return (
    <Layout totalPercent={totalPercent} sticky>
      <LayoutForm>
        <BackButton title="Back to Worth" path="/worth" />
        <PageTitle
          title="Assets"
          content="Input all assets you currently have. This will be used to assess your overall financial health."
          status={percents ? getPercent(percents, 'your-assets') : '0'}
          info={
              <>
                <h6>Assets</h6>
                <Priority option="High" />
                <div>Input all assets you currently have.  This will be used in assessing your overall financial condition.</div>
                <hr className="tooltip__line"/>
                <h6>Example</h6>
                <div>Say you own a home worth $300,000, Van Gogh Painting worth $3,000,000, have $500,000 in a retirement account, $250,000 in a savings account, and $130,000 in a checking account.  Enter these and other assets in their related assets fields.</div>
              </>
          }
        />
        <div className="layout-form__content-holder container container--large">
          <div className="layout-form__grid">
            <div>
              <div className="box">
                <h5>Cash Accounts</h5>
                <div className="box__row">
                  <div>Checking ($)</div>
                  <div className="box__row-option">{cash && cash.checking ? numeral(cash.checking).format('$0,0.00') : '$0.00'}</div>
                </div>
                <div className="box__row">
                  <div>Savings ($)</div>
                  <div className="box__row-option">{cash && cash.saving ? numeral(cash.saving).format('$0,0.00') : '$0.00'}</div>
                </div>
                <div className="box__row">
                  <div>Cash equivalents ($)</div>
                  <div className="box__row-option">{cash && cash.cash_equivalent ? numeral(cash.cash_equivalent).format('$0,0.00') : '$0.00'}</div>
                </div>
                <div className="box__button">
                  <Button variant="contained" size="medium" color="primary" onClick={() => history.push('/worth/assets/cash')}>
                    Update
                  </Button>
                </div>
              </div>
              <div className="box">
                <h5>Non-Retirement Accounts</h5>
                <div className="box__row">
                  <div>Brokerage accounts ($)</div>
                  <div className="box__row-option">{nonRetirement && nonRetirement.brokerage_account ? numeral(nonRetirement.brokerage_account).format('$0,0.00') : '$0.00'}</div>
                </div>
                <div className="box__row">
                  <div>Trust accounts ($)</div>
                  <div className="box__row-option">{nonRetirement && nonRetirement.trust_account ? numeral(nonRetirement.trust_account).format('$0,0.00') : '$0.00'}</div>
                </div>
                <div className="box__row">
                  <div>Annuities accounts ($)</div>
                  <div className="box__row-option">{nonRetirement && nonRetirement.annuities_account ? numeral(nonRetirement.annuities_account).format('$0,0.00') : '$0.00'}</div>
                </div>
                <div className="box__row">
                  <div>Cash value of life insurance policies ($)</div>
                  <div className="box__row-option">{nonRetirement && nonRetirement.cash_value ? numeral(nonRetirement.cash_value).format('$0,0.00') : '$0.00'}</div>
                </div>
                <div className="box__row">
                  <div>Vested but Unexercised Stock ($)</div>
                  <div className="box__row-option">{nonRetirement && nonRetirement.vested_stock ? numeral(nonRetirement.vested_stock).format('$0,0.00') : '$0.00'}</div>
                </div>
                <div className="box__row">
                  <div>Unvested Stock ($)</div>
                  <div className="box__row-option">{nonRetirement && nonRetirement.unvested_stock ? numeral(nonRetirement.unvested_stock).format('$0,0.00') : '$0.00'}</div>
                </div>
                <div className="box__button">
                  <Button variant="contained" size="medium" color="primary" onClick={() => history.push('/worth/assets/non-retirement')}>
                    Update
                  </Button>
                </div>
              </div>
              <div className="box">
                <h5>Fixed Assets</h5>
                <div className="box__row">
                  <div>Primary home (current market value)</div>
                  <div className="box__row-option">{fixedAsset && fixedAsset.home_primary ? numeral(fixedAsset.home_primary).format('$0,0.00') : '$0.00'}</div>
                </div>
                <div className="box__row">
                  <div>Other appreciable fixed assets (current market value)</div>
                  <div className="box__row-option">{fixedAsset && fixedAsset.appreciable_asset ? numeral(fixedAsset.appreciable_asset).format('$0,0.00') : '$0.00'}</div>
                </div>
                <div className="box__row">
                  <div>Other depreciable fixed assets (current market value)</div>
                  <div className="box__row-option">{fixedAsset && fixedAsset.depreciable_asset ? numeral(fixedAsset.depreciable_asset).format('$0,0.00') : '$0.00'}</div>
                </div>
                <div className="box__button">
                  <Button variant="contained" size="medium" color="primary" onClick={() => history.push('/assets')}>
                    Update
                  </Button>
                </div>
              </div>
            </div>
            <div>
              <div className="box">
                <h5>Retirement Accounts</h5>
                <div className="box__row">
                  <div>401K accounts ($)</div>
                  <div className="box__row-option">
                    {retirement && retirement.account_401k ? numeral(retirement.account_401k).format('$0,0.00') : '$0.00'}
                  </div>
                </div>
                <div className="box__row">
                  <div>403B accounts ($)</div>
                  <div className="box__row-option">
                    {retirement && retirement.account_403b ? numeral(retirement.account_403b).format('$0,0.00') : '$0.00'}
                  </div>
                </div>
                <div className="box__row">
                  <div>Pension ($)</div>
                  <div className="box__row-option">
                    {retirement && retirement.pension ? numeral(retirement.pension).format('$0,0.00') : '$0.00'}
                  </div>
                </div>
                <div className="box__row">
                  <div>Solo 401K accounts ($)</div>
                  <div className="box__row-option">
                    {retirement && retirement.solo_401k_account ? numeral(retirement.solo_401k_account).format('$0,0.00') : '$0.00'}
                  </div>
                </div>
                <div className="box__row">
                  <div>Roth 401K accounts ($)</div>
                  <div className="box__row-option">
                    {retirement && retirement.roth_401k_account ? numeral(retirement.roth_401k_account).format('$0,0.00') : '$0.00'}
                  </div>
                </div>
                <div className="box__row">
                  <div>Is your 401K balance consolidated under one account?</div>
                  <div className="box__row-option">
                    {retirement && retirement.is_401k_under_one && convertNumberToString(retirement.is_401k_under_one)}
                  </div>
                </div>
                <div className="box__row">
                  <div>Traditional IRA accounts ($)</div>
                  <div className="box__row-option">
                    {retirement && retirement.traditional_ira_account ? numeral(retirement.traditional_ira_account).format('$0,0.00') : '$0.00'}
                  </div>
                </div>
                <div className="box__row">
                  <div>Roth IRA accounts ($)</div>
                  <div className="box__row-option">
                    {retirement && retirement.roth_ira_account ? numeral(retirement.roth_ira_account).format('$0,0.00') : '$0.00'}
                  </div>
                </div>
                <div className="box__row">
                  <div>Simple IRA accounts ($)</div>
                  <div className="box__row-option">
                    {retirement && retirement.simple_ira_account ? numeral(retirement.simple_ira_account).format('$0,0.00') : '$0.00'}
                  </div>
                </div>
                <div className="box__row">
                  <div>SEP IRA accounts ($)</div>
                  <div className="box__row-option">
                    {retirement && retirement.sep_ira_account ? numeral(retirement.sep_ira_account).format('$0,0.00') : '$0.00'}
                  </div>
                </div>
                <div className="box__button">
                  <Button variant="contained" size="medium" color="primary" onClick={() => history.push('/worth/assets/retirement')}>
                    Update
                  </Button>
                </div>
              </div>
              {othersAssets && othersAssets.length < 1
                ? !isMobile && <AddButton onClick={() => history.push('/worth/assets/others')} title="Add other" />
                : (
                  <div className="box">
                    <h5>Other</h5>
                    {othersAssets && othersAssets.length > 0 &&
                      othersAssets.map((item, index) => (
                        <div key={`item-${index}`}>
                          <div className="box__row">
                            <div>Additional asset name</div>
                            <div className="box__row-option">
                              {item.name}
                            </div>
                          </div>
                          <div className="box__row">
                            <div>Amount ($)</div>
                            <div className="box__row-option">
                              {item.amount ? numeral(item.amount).format('$0,0.00') : '$0.00'}
                            </div>
                          </div>
                        </div>
                      ))}
                    <div className="box__button">
                      <Button variant="contained" size="medium" color="primary" onClick={() => history.push('/worth/assets/others')}>
                        Update
                      </Button>
                    </div>
                  </div>
              )}
            </div>
          </div>

          <div className="form__comment-box mt--medium">
            <Formik
              enableReinitialize
              initialValues={{
                name: info.name || 'worth-assets',
                content: info.content || ''
              }}
              onSubmit={onSubmit}
            >
              {({ errors, handleChange, handleBlur, values, touched }) => (
                <Form>
                  <h5>Additional info</h5>
                  <p>Is there any other Current net worth information that you believe is important for ValueSoft to know about? If so, please let us know about it:</p>
                  <TextareaAutosize
                    maxLength="200"
                    name="content"
                    value={values.content || ''}
                    className="form__textarea"
                    placeholder="Maximum characters: 200"
                    onChange={handleChange}
                  />
                  {errors.content && touched.content && <span className="form__msg-error">{errors.content}</span>}
                  <div className="form__button form__button--center">
                    <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                      Save
                      {sending && <span className="spinner" />}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>

          <div className="button__mobile-sticky">
            {isMobile && othersAssets.length < 1 && <AddButton onClick={() => history.push('/worth/assets/others')} title="Add other" smallMobile />}
          </div>
        </div>
        <Modal
          open={isOpenSaveModal}
          small
          close={() => setIsOpenSaveModal(false)}
          button="Ok"
          onClick={() => setIsOpenSaveModal(false)}
        >
          <div>
            <div className="modal__title-icon">
              <SVGIcon icon={Icon} />
            </div>
            <h4>You have successfully updated the data</h4>
          </div>
        </Modal>
      </LayoutForm>
    </Layout>
  );
};

export default WorthAssets;
