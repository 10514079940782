import React from 'react'
import { Form, Formik } from 'formik'
import { Button } from '@mui/material'
import { FormGroup, Input, InputCurrency } from '../shared/Form'
import Percentage from '../../assets/images/icons/percentage.svg'

const OwnVsRentAdmin = ({ title, onSave, values, sending }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{...values}}
      onSubmit={values => onSave(values)}
    >
      {({ handleChange, handleBlur, values }) => (
        <Form>
          <h4 className="form__main-title">{title}</h4>
          
          <h6>Home Purchase</h6>
          <div className="form__flow-wrap">
          <FormGroup name="current_age" labelText="Current age">
            <Input
              name="current_age"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.current_age || 0}
            />
          </FormGroup>
          <FormGroup name="age_upon_home_purchase" labelText="Your age upon home purchase">
            <Input
              name="age_upon_home_purchase"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.age_upon_home_purchase || 0}
            />
          </FormGroup>
          <FormGroup name="home_value" labelText="Home value (Today’s $)">
            <InputCurrency
              name="home_value"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.home_value || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="down_payment" labelText="Downpayment">
            <Input
              name="down_payment"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.down_payment || 0}
              iconName={Percentage}
            />
          </FormGroup>
          <FormGroup name="financing_rate" labelText="Financing Rate">
            <Input
              name="financing_rate"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.financing_rate || 0}
              iconName={Percentage}
            />
          </FormGroup>
          <FormGroup name="years_financed" labelText="Years Financed">
            <Input
              name="years_financed"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.years_financed || 0}
            />
          </FormGroup>
          <FormGroup name="home_maintenance_expenses" labelText="Annual home maintenance expenses">
            <InputCurrency
              name="home_maintenance_expenses"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.home_maintenance_expenses || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="points_down" labelText="Points down">
            <Input
              name="points_down"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.points_down || 0}
              iconName={Percentage}
            />
          </FormGroup>
          <FormGroup name="income_tax_bracket" labelText="Income tax bracket">
            <Input
              name="income_tax_bracket"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.income_tax_bracket || 0}
              iconName={Percentage}
            />
          </FormGroup>
          </div>
          <h6>Rental details</h6>
          <div className="form__flow-wrap">
          <FormGroup name="monthly_rent" labelText="Monthly rent">
            <InputCurrency
              name="monthly_rent"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.monthly_rent || 0}
              withoutSign
              icon
            />
          </FormGroup>
          </div>
          <h6>Valuesoft assumptions</h6>
          <div className="form__flow-wrap">
          <FormGroup name="property_appreciation" labelText="Property Appreciation">
            <Input
              name="property_appreciation"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.property_appreciation || 0}
              iconName={Percentage}
            />
          </FormGroup>
          <FormGroup name="stock_market_return" labelText="Stock market return">
            <Input
              name="stock_market_return"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.stock_market_return || 0}
              iconName={Percentage}
            />
          </FormGroup>
          <FormGroup name="closing_costs" labelText="Closing costs">
            <Input
              name="closing_costs"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.closing_costs || 0}
              iconName={Percentage}
            />
          </FormGroup>
          <FormGroup name="commission_costs" labelText="Commission costs">
            <Input
              name="commission_costs"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.commission_costs || 0}
              iconName={Percentage}
            />
          </FormGroup>
          <FormGroup name="inflation_rate" labelText="Inflation rate">
            <Input
              name="inflation_rate"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.inflation_rate || 0}
              iconName={Percentage}
            />
          </FormGroup>
          </div>
          <div className="form__button">
            <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
              Save
              {sending && <span className="spinner" />}
            </Button>
          </div>
        </Form>
      )}
      
    </Formik>
  )
}

export default OwnVsRentAdmin
