import React from 'react'
import { Form, Formik } from 'formik'
import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { FormGroup, Input, InputCurrency } from '../shared/Form'
import Percentage from '../../assets/images/icons/percentage.svg'
import { normalizeBoolean } from '../../utils/utils'

const InvestmentFeesAdmin = ({ title, onSave, values, sending }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{...values}}
      onSubmit={values => onSave(values)}
    >
      {({ handleChange, handleBlur, setFieldValue, values }) => (
        <Form>
          <h4 className="form__main-title">{title}</h4>
          <h6>Option one</h6>
          <div className="form__flow-wrap">
          <FormGroup name="use_valuesoft_expenses_fee" labelText="Use ValueSoft expenses fee estimates?">
            <RadioGroup
              name="use_valuesoft_expenses_fee"
              value={values.use_valuesoft_expenses_fee || false}
              onChange={(e) => {
                setFieldValue('use_valuesoft_expenses_fee', normalizeBoolean(e.target.value))
              }}
              className="radio-list-inline"
            >
              <FormControlLabel value={false} control={<Radio />} label="No" />
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
            </RadioGroup>
          </FormGroup>
          </div>
          <h6>Option two</h6>
          <div className="form__flow-wrap">
          <FormGroup name="option_two_use_valuesoft_expenses_fee" labelText="Use ValueSoft expenses fee estimates?">
            <RadioGroup
              name="option_two_use_valuesoft_expenses_fee"
              value={values.option_two_use_valuesoft_expenses_fee || false}
              onChange={(e) => {
                setFieldValue('option_two_use_valuesoft_expenses_fee', normalizeBoolean(e.target.value))
              }}
              className="radio-list-inline"
            >
              <FormControlLabel value={false} control={<Radio />} label="No" />
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
            </RadioGroup>
          </FormGroup>
          <FormGroup name="investment_amount" labelText="Investment amount">
            <InputCurrency
              name="investment_amount"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.investment_amount || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="investment_year" labelText="Investment years">
            <Input
              name="investment_year"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.investment_year || 0}
            />
          </FormGroup>
          <FormGroup name="additional_return" labelText="Approximate additional return you believe you’ll derive by having full control of your strategic asset allocation strategy">
            <Input
              name="additional_return"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              step={0.1}
              value={values.additional_return || 0} 
              iconName={Percentage}
            />
          </FormGroup>
          </div>
          <h6>Assumptions</h6>
          <div className="form__flow-wrap">
          <FormGroup name="broker_fees" labelText="Broker fees">
            <InputCurrency
              name="broker_fees"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.broker_fees || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="transaction_fee_on_stock" labelText="Transaction fee on stocks & ETFs">
            <InputCurrency
              name="transaction_fee_on_stock"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.transaction_fee_on_stock || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="transaction_fee_on_mutual_fund" labelText="Transaction fee on mutual funds & index funds">
            <InputCurrency
              name="transaction_fee_on_mutual_fund"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.transaction_fee_on_mutual_fund || 0}
              withoutSign
              icon
            />
          </FormGroup>
          </div>
          <div className="form__button">
            <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
              Save
              {sending && <span className="spinner" />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default InvestmentFeesAdmin
