import React, { useState, useEffect, useRef } from 'react';
import LayoutAdmin from '../../components/Layout/LayoutAdmin';
import { useHistory, useParams, Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Pagination } from '@mui/material';
import Loading from '../../components/shared/Loading/Loading';
import { FormGroup, Input } from '../../components/shared/Form';
import SVGIcon from '../../components/shared/SVGIcon/SVGIcon';
import Man from '../../assets/images/icons/user02.svg';
import Edit from '../../assets/images/icons/edit.svg';
import Trash from '../../assets/images/icons/trash.svg';
import Modal from '../../components/shared/Modal/Modal';
import {
  assignPlanner,
  setActiveNumber,
  fetchPlannersList,
  removeMember,
  fetchFilterStatisticsUsers,
  fetchCompany,
  swapTokens,
} from '../../redux/slices/companiesSlice';
import { fetchUserData } from '../../redux/slices/userSlice'
import { isNull, isEmpty } from 'lodash';
import SearchUserBox from '../../components/SearchBox/SearchUserBox';
import SearchIcon from '../../assets/images/icons/search.svg';
import { BackButton } from '../../components/shared/Buttons';

const AdminUserStatisticsResult = ({ props, isAdmin }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, role_id } = useSelector((state) => state.user.user);
  const [isMobile, setIsMobile] = useState(false);
  const [isOpenDel, setOpenDel] = useState(false);
  const [isDelConfirm, setDelConfirm] = useState(false);
  const [inputVal, setInputVal] = useState('');
  const [isError, setError] = useState(true);
  const [isTextError, setTextError] = useState(false);
  const {
    members,
    pagination,
    companyLoading,
    planners,
    activeNumber
  } = useSelector((state) => state.companies);
  const { image, email, name } = useSelector((state) => state.companies.company.info);
  const [filters, setFilters] = useState({
    sort: 'name',
    order: 'asc'
  });
  const [page, setPage] = useState(1);
  const [targetUser, setTargetUser] = useState(null);
  const [plannersList, setPlannersList] = useState(null);
  const [isModalSearch, setModalSearch] = useState(false);
  const [isActiveItem, setActiveItem] = useState('');
  const [value, steValue] = useState('');
  const { companyId } = useParams();
  const { state: { usersId, title, total, filterParams } = {} } = useLocation();
  const inputState = !isEmpty(filterParams?.state) ? filterParams?.state.join(', ') : 'None'
  const inputCity = !isEmpty(filterParams?.city) ? filterParams?.city.join(', ') : 'None'
  const inputAgeStart = !isEmpty(filterParams?.age) ? filterParams?.age[0] : 'None'
  const inputAgeFinish = !isEmpty(filterParams?.age) ? filterParams?.age[1] : 'None'
  const inputMarried = !isEmpty(filterParams?.married) ? filterParams?.married : 'None'
  const inputRoles = !isEmpty(filterParams?.roles) ? filterParams?.roles.join(', ') : 'None'
  const popup = useRef();
  const postParam = {
    users: usersId
  }
  const mobileView = 999;

  const handleResize = () => {
    mobileView >= window.innerWidth ? setIsMobile(true) : setIsMobile(false);
  };

  useEffect(() => {
    if (!isNull(planners)) {
      setPlannersList(planners);
    }
    // eslint-disable-next-line
  }, [planners]);

  useEffect(() => {
    dispatch(fetchCompany(companyId));
    mobileView >= window.innerWidth && setIsMobile(true);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (inputVal === 'DELETE') {
      setError(false);
      setTextError(false);
    } else {
      setError(true);
    }
  }, [inputVal]);

  const handleBlur = () => {
    if (isError) {
      setTextError(true);
    }
  };

  const onChangePage = (event, value) => setPage(value);

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchFilterStatisticsUsers(companyId, postParam, { 
        ...filters,
        page: page 
    }));
      await dispatch(fetchPlannersList(companyId));
    }

    if (id && companyId) {
      fetch();
    }
    // eslint-disable-next-line
  }, [id, companyId, filters, page, usersId]);

  const setSearch = (e) => {
    const serchValue = e.target.value;
    steValue(serchValue);

    if (serchValue.length > 0) {
      setPlannersList(
        planners.filter((item) =>
          `${item.first_name} ${item.last_name}`
            .toLowerCase()
            .includes(serchValue.toLowerCase())
        )
      );
    } else {
      setPlannersList(planners);
    }
  };

  useEffect(() => {
    const listener = (event) => {
      if (
        event.target.classList.contains('search') ||
        event.target.classList.contains('injected-svg')
      ) {
        return;
      }
      dispatch(setActiveNumber(''));
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
    // eslint-disable-next-line
  }, [popup]);

  const onUnassignPlanner = async (memberId, memberStatus) => {
    await dispatch(
      assignPlanner(companyId, {
        planner_id: '',
        member_id: memberId,
        member_confirmed: memberStatus,
      })
    );
    await dispatch(fetchFilterStatisticsUsers(companyId, postParam, { 
        ...filters,
        page: page 
    }));
  };

  const onSelect = async (plannerId) => {
    setTargetUser({
      ...targetUser,
      planner_id: plannerId,
    });
  };

  const onHandlePlaner = (userId, userStatus) => {
    setTargetUser({
      id: userId,
      status: userStatus,
    });
    setModalSearch(true);
  };

  const handleFakeLogin = async (companyId, memberId) => {
    await dispatch(swapTokens(companyId, memberId));
    await dispatch(fetchUserData());
    await history.push('/dashboard');
  };

  if (!usersId) return <Redirect to="/404" />;

  return (
    <LayoutAdmin companyId={companyId} isAdmin={isAdmin} scroll>
      {!image ? (
        <Loading />
      ) : (
        <>
          <div className='member-comapny'>
            <div className='member-comapny__info'>
              <div className='member-comapny__logo'>
                <img src={image} alt='Company logo' />
              </div>
              <div className='member-comapny__title'>
                <h3>{name}</h3>
                {!isMobile && (
                  <p>
                    Feedback Email: <span>{email}</span>
                  </p>
                )}
              </div>
            </div>
            {isMobile && (
              <div className='mobile-feedback'>
                Feedback Email: <span>{email}</span>
              </div>
            )}
          </div>
          <BackButton title="Back to filter" path={`/admin/company/${companyId}/users-statistics`} className="back-button" />
          <div className='member-comapny member-comapny__title'>
            <h4>User statistics</h4>
          </div>
          <div className='member-comapny__filter'>
            <div className='member-comapny__filter--item'>
                <span className='label'>State</span>
                <p title={inputState} className='input'>{inputState}</p>
            </div>
            <div className='member-comapny__filter--item'>
                <span className='label'>City</span>
                <p title={inputCity} className='input'>{inputCity}</p>
            </div>
            <div className='member-comapny__filter--item'>
                <span className='label'>Age</span>
                <div className='age'>
                    <p title={inputAgeStart} className='input'>From: {inputAgeStart}</p>
                    <p title={inputAgeFinish} className='input'>To: {inputAgeFinish}</p>
                </div> 
            </div>
            <div className='member-comapny__filter--item'>
                <span className='label'>Married</span>
                <p title={inputMarried} className='input married'>{inputMarried}</p>
            </div>
            <div className='member-comapny__filter--item'>
                <span className='label'>Role</span>
                <p title={inputRoles} className='input role'>{inputRoles}</p>
            </div>
          </div>
          <div className='member-comapny__filter-total'>{title}:  <span>{total}</span></div>
          <div className='members'>
            <div className='members__table'>
              <ul className='members-column__name'>
                <button
                  onClick={() => {
                    setFilters({
                      ...filters,
                      sort: 'name',
                      order: filters.order === 'asc' ? 'desc' : 'asc',
                    });
                  }}
                  className='column__name titles titles-button'
                >
                  Name{' '}
                  <span
                    className={`title-arrow ${
                      filters.sort === 'name' && filters.order === 'desc'
                        ? 'title-arrow--rotate'
                        : ''
                    }`}
                  />
                </button>
                {!companyLoading && (
                  <>
                    {members &&
                      members.map((item, i) => (
                        <li
                          key={i}
                          style={{ animationDelay: 0.3 + i * 0.1 + 's' }}
                          className={`column__name ${
                            i === isActiveItem ? 'hover' : ''
                          }`}
                          onMouseEnter={() => setActiveItem(i)}
                          onMouseLeave={() => setActiveItem('')}
                        >
                          <div>
                            <div className='column__name-logo'>
                              {item?.avatar ? (
                                <img src={item.avatar} alt={item.name} />
                              ) : (
                                <SVGIcon icon={Man} color='#fff' />
                              )}
                            </div>
                            {item.first_name} {item.last_name}
                          </div>
                        </li>
                      ))}
                  </>
                )}
              </ul>
              <ul className='members-column__other'>
                <li className='column__other titles'>
                  <button
                    onClick={() => {
                      setFilters({
                        ...filters,
                        sort: 'date',
                        order: filters.order === 'asc' ? 'desc' : 'asc',
                      });
                    }}
                    className='column__other--date titles-button'
                  >
                    Date added{' '}
                    <span
                      className={`title-arrow ${
                        filters.sort === 'date' && filters.order === 'desc'
                          ? 'title-arrow--rotate'
                          : ''
                      }`}
                    />
                  </button>
                  <button
                    onClick={() => {
                      setFilters({
                        ...filters,
                        sort: 'last_login',
                        order: filters.order === 'asc' ? 'desc' : 'asc',
                      });
                    }}
                    className='column__other--date titles-button'
                  >
                    Last login{' '}
                    <span
                      className={`title-arrow ${
                        filters.sort === 'last_login' && filters.order === 'desc'
                          ? 'title-arrow--rotate'
                          : ''
                      }`}
                    />
                  </button>
                  <button
                    onClick={() => {
                      setFilters({
                        ...filters,
                        sort: 'role',
                        order: filters.order === 'asc' ? 'desc' : 'asc',
                      });
                    }}
                    className='column__other--role titles-button'
                  >
                    Role{' '}
                    <span
                      className={`title-arrow ${
                        filters.sort === 'role' && filters.order === 'desc'
                          ? 'title-arrow--rotate'
                          : ''
                      }`}
                    />
                  </button>
                  <button
                    onClick={() => {
                      setFilters({
                        ...filters,
                        sort: 'planner',
                        order: filters.order === 'asc' ? 'desc' : 'asc',
                      });
                    }}
                    className='column__other--planner titles-button'
                  >
                    Planner{' '}
                    <span
                      className={`title-arrow ${
                        filters.sort === 'planner' && filters.order === 'desc'
                          ? 'title-arrow--rotate'
                          : ''
                      }`}
                    />
                  </button>
                  <button
                    onClick={() => {
                      setFilters({
                        ...filters,
                        sort: 'status',
                        order: filters.order === 'asc' ? 'desc' : 'asc',
                      });
                    }}
                    className='column__other--status titles-button'
                  >
                    Status{' '}
                    <span
                      className={`title-arrow ${
                        filters.sort === 'status' && filters.order === 'desc'
                          ? 'title-arrow--rotate'
                          : ''
                      }`}
                    />
                  </button>
                  <div className='column__other--control titles' />
                </li>
                {!companyLoading && (
                  <>
                    {members &&
                      members.map((item, i) => (
                        <li
                          key={i}
                          style={{
                            animationDelay: 0.3 + i * 0.1 + 's',
                            zIndex: -i,
                          }}
                          className={`column__other ${
                            i === isActiveItem ? 'hover' : ''
                          }`}
                          onMouseEnter={() => setActiveItem(i)}
                          onMouseLeave={() => setActiveItem('')}
                        >
                          <div className='column__other--date'>{item.date}</div>
                          <div className='column__other--date'>{item.last_login}</div>
                          <div className='column__other--role'>
                            {item.role_name}
                          </div>
                          <div className='column__other--planner'>
                            {item.role_id !== 4 && (
                              <div className='planner-logo__wrap'>
                                {item.planner?.avatar ? (
                                  <div className='column__name-logo'>
                                    <img
                                      src={item.planner.avatar}
                                      alt={item.name}
                                    />
                                  </div>
                                ) : (
                                  <div className='column__name-logo column__name-logo--dashed'>
                                    <SVGIcon icon={Man} color='#8C8C8C' />
                                  </div>
                                )}
                                {isNull(item.planner) ? (
                                  <button
                                    disabled={
                                      role_id > 3 || !item.member_confirmed
                                    }
                                    onClick={() =>
                                      isMobile
                                        ? onHandlePlaner(
                                            item.id,
                                            item.member_confirmed
                                          )
                                        : dispatch(setActiveNumber(i))
                                    }
                                    className='logo__add'
                                  />
                                ) : (
                                  <button
                                    disabled={role_id > 3}
                                    onClick={() =>
                                      onUnassignPlanner(
                                        item.id,
                                        item.member_confirmed
                                      )
                                    }
                                    className='logo__delete'
                                  />
                                )}
                                {!isNull(item.planner) && (
                                  <div className='planner-name'>
                                    {item.planner.first_name}{' '}
                                    {item.planner.last_name}
                                  </div>
                                )}
                              </div>
                            )}
                            <SearchUserBox
                              resSearch={popup}
                              key={i}
                              open={activeNumber === i}
                              companyId={companyId}
                              memberId={item.id}
                              memberStatus={item.member_confirmed}
                              setSearch={setSearch}
                              value={value}
                              result={plannersList}
                            />
                          </div>
                          <div
                            className={`column__other--status ${
                              item.status === 'Active' ? 'status-blue' : ''
                            }`}
                          >
                            {item.status}
                          </div>
                          <div className='column__other--control'>
                            <Button
                              disabled={
                                !item.member_confirmed || item.id === id
                              }
                              variant='contained'
                              size='small'
                              color='primary'
                              onClick={() =>
                                handleFakeLogin(companyId, item.id)
                              }
                            >
                              Log in as user
                            </Button>
                            {role_id < 4 && (
                              <>
                                <button
                                  disabled={
                                    (!isNull(item.role_id) &&
                                      (role_id > 3 ||
                                        role_id > item.role_id)) ||
                                    (role_id === item.role_id &&
                                      item.id !== id) ||
                                    !item.member_confirmed
                                  }
                                  className='control-button'
                                  onClick={() =>
                                    history.push({
                                      pathname: `/admin/company/${companyId}/members/${item.id}/edit`,
                                      hash:
                                        !item.member_confirmed && 'unconfirmed',
                                    })
                                  }
                                >
                                  <SVGIcon icon={Edit} color='#8c8c8c' />
                                </button>
                                <button
                                  disabled={
                                    (!isNull(item.role_id) &&
                                      (role_id > 3 ||
                                        role_id > item.role_id)) ||
                                    (role_id === item.role_id &&
                                      item.id !== id) ||
                                    item.id === id
                                  }
                                  className='control-button'
                                  onClick={() => {
                                    setOpenDel(true);
                                    setTargetUser({
                                      id: item.id,
                                      name: `${item.first_name} ${item.last_name}`,
                                      image: item.avatar,
                                      status: item.member_confirmed,
                                    });
                                  }}
                                >
                                  <SVGIcon icon={Trash} color='#8c8c8c' />
                                </button>
                              </>
                            )}
                          </div>
                        </li>
                      ))}
                  </>
                )}
              </ul>
            </div>
            {companyLoading && <Loading xsmall />}
            {pagination.total_pages > 1 && (
            <Pagination
              size={isMobile ? 'small' : 'medium'}
              count={pagination.total_pages}
              onChange={onChangePage}
            />
          )}
          </div>
          <Modal
            open={isOpenDel}
            small
            inline
            reverse
            removeButton='Yes'
            button='No'
            onClick={() => setOpenDel(false)}
            remove={() => {
              setOpenDel(false);
              setDelConfirm(true);
            }}
            close={() => setOpenDel(false)}
          >
            <div className='company__modal'>
              <h3>Are you sure you want to delete this user?</h3>
              <p>
                Deleting the user will delete all the user's information
                associated to this company.
              </p>
              <div className='company__modal-icon'>
                {!isNull(targetUser?.image) ? (
                  <img src={targetUser?.image} alt='Avatar' />
                ) : (
                  <SVGIcon icon={Man} color='#fff' />
                )}
              </div>
              <h6>{targetUser?.name}</h6>
            </div>
          </Modal>
          <Modal
            open={isDelConfirm}
            button='Delete'
            onClick={async () => {
              await dispatch(
                removeMember(
                  companyId,
                  targetUser?.id,
                  targetUser?.status && 'confirmed'
                )
              );
              await dispatch(fetchFilterStatisticsUsers(companyId, postParam));
              setInputVal('');
              await setDelConfirm(false);
            }}
            close={() => setDelConfirm(false)}
            isDisabled={isError}
          >
            <div className='company__modal'>
              <h3>Are you sure you want to delete this user?</h3>
              <p>
                Deleting the user will delete all the user's information
                associated to this company. This cannot be undone after you type
                DELETE in the field below.
              </p>
              <div className='company__modal-icon'>
                {!isNull(targetUser?.image) ? (
                  <img src={targetUser?.image} alt='Avatar' />
                ) : (
                  <SVGIcon icon={Man} color='#fff' />
                )}
              </div>
              <h6>{targetUser?.name}</h6>
              <FormGroup name='confirm-text' labelText='Enter'>
                <Input
                  name='confirm-text'
                  placeholder='Enter DELETE'
                  type='text'
                  onChange={(e) => {
                    setInputVal(e.target.value);
                  }}
                  value={inputVal || ''}
                  onBlur={handleBlur}
                  hasError={isTextError}
                />
                {isTextError && (
                  <span className='form__msg-error'>
                    Please check entered text!
                  </span>
                )}
              </FormGroup>
            </div>
          </Modal>
          <Modal
            open={isModalSearch}
            small
            button='Apply'
            onClick={async () => {
              await dispatch(
                assignPlanner(companyId, {
                  planner_id: targetUser.planner_id,
                  member_id: targetUser.id,
                  member_confirmed: targetUser.status,
                })
              );
              await dispatch(fetchFilterStatisticsUsers(companyId, postParam));
              await setTargetUser(null);
              await setModalSearch(false);
            }}
            close={() => setModalSearch(false)}
            isDisabled={!targetUser?.planner_id}
          >
            <div className='company__modal--search'>
              <h3>Add planner</h3>
              <p>Choose the user you want to assign.</p>
              <div>
                <div className='search-user__box'>
                  <input
                    type='text'
                    placeholder='Search'
                    value={value}
                    className='search-user__input'
                    onChange={setSearch}
                  />
                  {!value && <SVGIcon icon={SearchIcon} color='#8c8c8c' />}
                  <ul className='search-user__result'>
                    {plannersList && plannersList.length === 0 ? (
                      <li className='search-user__result-item'>No result</li>
                    ) : (
                      plannersList &&
                      plannersList.map((item, i) => (
                        <li
                          key={i}
                          style={{ animationDelay: 0.3 + i * 0.1 + 's' }}
                          className={`search-user__result-item ${
                            targetUser?.planner_id === item.id
                              ? ' search-user__result-item--target'
                              : ''
                          }`}
                        >
                          <button onClick={() => onSelect(item.id)}>
                            <div className='result-item__logo'>
                              {item?.avatar ? (
                                <img src={item.avatar} alt={item.name} />
                              ) : (
                                <SVGIcon icon={Man} color='#fff' />
                              )}
                            </div>
                            {item.first_name} {item.last_name}
                          </button>
                        </li>
                      ))
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
    </LayoutAdmin>
  );
};

export default AdminUserStatisticsResult;
