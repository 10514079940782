import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AUTH } from '../../constants/api'
import { Admin, User, Spouse, Profile, Dropbox } from '../../api/Api'
import { setIsLoggedIn, setAuthLoading } from './authSlice'
import { setIsOpen } from './modalSlice'
import { setSending } from './sendingSlice'
import { setNewTasksCount } from './taskSlice'
import { setIsRelogin } from "./loadingSlice";
import { convertDecimal } from '../../utils/utils'
import isUndefined from 'lodash/isUndefined'
import { setMode } from "./modeSlice";
// import { fetchPercentages } from './percentagesSlice'

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {
      first_name: "",
      last_name: "",
      year_of_birth: null,
      gender: "male",
      email: "",
      phone: "",
      address: null,
      avatar: null,
      dropbox_api_token: false,
      dropbox_popup_show: true,
      is_dropbox_connect: false,
      is_admin: false,
      is_new_year: false,
      plan_type: "",
      plan_popup_show_delay: null,
      company_logo: '',
      plan_choose_task_delay: null,
      push_notification_plans: null,
    },
    profile: {
      details: {
        is_married: false,
        is_smoke: false,
        is_auto: false,
        has_life_insurance: false,
        gross_salary: null,
        future_salary: null,
        life_insurance_amount: null,
        retirement_age: null,
        tax_filing_status: "",
        social_security_analysis: false,
        credit_score: null,
        additional_info: "",
      },
      info: {
        first_name: "",
        last_name: "",
        year_of_birth: null,
        gender: "male",
        email: "",
        phone: "",
        address: null,
      },
    },
    family: {
      assets: {
        own_or_rent_home: "own",
        own_other_assets: false,
        plan_to_purchase: false,
      },
      others: {
        employment_sabbaticals: false,
        factor_in_a_period: false,
        significant_income: false,
        leave_bequests: false,
        miles_travelled_per_day: "",
        anyone_convicted: false,
        high_risk_occupation: false,
        use_online_budget_tracking: false,
        use_dropbox: false,
        additional_info: "",
        paying_education: false,
        feature_paying_education: false,
      },
      children: {
        total_children: "",
        prior_children: "",
        want_more_children: false,
        support_children_after_retire: false,
      },
    },
    values: [],
    goals: [],
    dreams: [],
    spouse: {
      details: {
        first_name: "",
        last_name: "",
        year_of_birth: null,
        gross_salary: null,
        future_salary: null,
        retirement_age: null,
        is_first_marriage: false,
        has_life_insurance: false,
        life_insurance_amount: null,
        is_smoke: false,
        spouse_credit_score: null,
      },
      values: [],
      goals: [],
      dreams: [],
    },
    pushNotificationSchedule: {},
    error: null,
    showProgress: false,
    uploadingAvatar: false,
    push_notification_plans: null,
  },
  reducers: {
    setSplashCompleted: (state, action) => {
      state.user.is_splash_completed = action.payload.is_splash_completed;
    },
    setPlanCompleted: (state, action) => {
      state.user.is_plan_completed = action.payload.is_plan_completed;
    },
    setUserData: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload,
      };
    },
    setUserDetails: (state, action) => {
      state.profile.details = {
        ...state.profile.details,
        ...action.payload,
      };
    },
    setUserInfo: (state, action) => {
      state.profile.info = {
        ...state.profile.info,
        ...action.payload,
      };
    },
    setUsersSpouse: (state, action) => {
      state.spouse.details = {
        ...state.spouse.details,
        ...action.payload,
      };
    },
    setUserAssets: (state, action) => {
      state.family.assets = {
        ...state.family.assets,
        ...action.payload,
      };
    },
    setUserFamily: (state, action) => {
      state.family.others = {
        ...state.family.others,
        ...action.payload,
      };
    },
    setUserChildren: (state, action) => {
      state.family.children = {
        ...state.family.children,
        ...action.payload,
      };
    },
    setUserAvatar: (state, action) => {
      state.user.avatar = action.payload;
    },
    setUserValues: (state, action) => {
      state.values = action.payload;
    },
    setUserDreams: (state, action) => {
      state.dreams = action.payload;
    },
    setUserGoals: (state, action) => {
      state.goals = action.payload;
    },
    setUserSpouseValues: (state, action) => {
      state.spouse.values = action.payload;
    },
    setUserSpouseDreams: (state, action) => {
      state.spouse.dreams = action.payload;
    },
    setUserSpouseGoals: (state, action) => {
      state.spouse.goals = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setIsActivatedDropbox: (state, action) => {
      state.user.dropbox_api_token = action.payload;
    },
    setShowProgress: (state, action) => {
      state.showProgress = action.payload;
    },
    setReset: (state, action) => {
      state.user.is_new_year = action.payload;
    },
    setUploading: (state, action) => {
      state.uploadingAvatar = action.payload;
    },
    setDropboxStatus: (state, action) => {
      state.user.is_dropbox_connect = action.payload;
    },
    setDropboxPopup: (state, action) => {
      state.user.dropbox_popup_show = action.payload;
    },
    setPushNotificationSchedule: (state, action) => {
      state.pushNotificationSchedule = action.payload;
    },
    setUserPushNotificationPlan: (state, action) => {
      state.user.push_notification_plans = action.payload;
    },
  },
});

export const fetchUserData = () => async (dispatch) => {
  try {
    dispatch(setAuthLoading({ isLoading: true }));
    const token = localStorage.token;
    if (token) {
      axios.post(
        `${AUTH}/user`,
        null,
        { headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Authorization': `Bearer ${token}`
        }}
      )
      .then(async res => {
        await dispatch(setIsLoggedIn({ isLoggedIn: true }));
        await dispatch(setUserData(res.data))
        await dispatch(setAuthLoading({ isLoading: false }));
      })
      .catch(e => {
        localStorage.removeItem('token');
        localStorage.removeItem('token_admin');
        dispatch(setAuthLoading({ isLoading: false }));
      })
    } else {
      dispatch(setAuthLoading({ isLoading: false }));
    }
  } catch (e) {
    dispatch(setAuthLoading({ isLoading: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postPlanningTypesUser = (id, data) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }))
    await User.postPlanningTypes(id, data)
    await dispatch(setSplashCompleted({ is_splash_completed: true }))
    await dispatch(setSending({ isSending: false }))
  } catch (e) {
    console.error(e)
    await dispatch(setSending({ isSending: false }))
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postPlanTypeUser = (data) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }))
    const response = await User.postPlanType(data);
    await dispatch(setUserData({ plan_type:  response.data.plan.id}));
    await dispatch(setPlanCompleted({ is_plan_completed: true }));
    await dispatch(setNewTasksCount(response.data.newTasksCount));
    await dispatch(setSending({ isSending: false }))
  } catch (e) {
    console.error(e)
    await dispatch(setSending({ isSending: false }))
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

// User Info
export const fetchUserInfo = (id) => async (dispatch) => {
  try {
    const response = await User.getInfo(id);
    await dispatch(setUserData(response.data.data))
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchDelayTime = () => async (dispatch) => {
  try {
    const response = await User.getDelayTime();
    await dispatch(
      setUserData({
        plan_popup_show_delay: response.data.data.plan_popup_show_delay,
        plan_choose_task_delay: response.data.data.plan_choose_task_delay,
      })
    );
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
};

export const postUserInfo = (data, props, setFormErrors) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    await dispatch(setError(null));
    await User.postInfo(data);
    await dispatch(setUserData(data));
    await dispatch(setSending({ isSending: false }));
    await dispatch(() => props.history.push('/profile-summary/about-you-details'));
  } catch (e) {
    console.error(e);
    dispatch(setSending({ isSending: false }));

    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }

    if(e.response && e.response.data.errors) {
      dispatch(setError(e.response.data.errors));
      setFormErrors(e.response.data.errors)
    }
  }
}


// User Details
export const fetchUserDetails = (id) => async (dispatch) => {
  try {
    const response = await User.getDetails(id);
    await dispatch(setUserDetails(response.data.data))
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postUserDetails = (data, props, setFormErrors) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    let { gross_salary, life_insurance_amount, future_salary } = data;

    gross_salary = convertDecimal(gross_salary);
    life_insurance_amount = convertDecimal(life_insurance_amount);
    future_salary = convertDecimal(future_salary);

    const response = await User.postDetails({ ...data, gross_salary, life_insurance_amount, future_salary});
    await dispatch(setNewTasksCount(response.data.newTasksCount))
    await dispatch(setUserDetails(data));
    await dispatch(setSending({ isSending: false }));
    await dispatch(setIsOpen({ isOpen: true }))
    await dispatch(setError(null));
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
    await dispatch(setError(e.response.data.errors));
    setFormErrors(e.response.data.errors)
  }
}


// User Assets
export const fetchUserFamilyAssets = (id) => async (dispatch) => {
  try {
    const response = await User.getAssets(id);
    await dispatch(setUserAssets(response.data.data));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postUserFamilyAssets = (data, props) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    const response = await User.postAssets(data);
    await dispatch(setUserAssets(data));
    await dispatch(setSending({ isSending: false }));
    await dispatch(setIsOpen({isOpen: true}))
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}


// User Children Data
export const fetchUserFamilyChildren = (id) => async (dispatch) => {
  try {
    const response = await User.getChildrenData(id);
    await dispatch(setUserChildren(response.data.data));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postUserFamilyChildren = (data, props) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    const response = await User.postChildrenData(data);
    await dispatch(setUserChildren(data));
    await dispatch(setSending({ isSending: false }));
    await dispatch(setIsOpen({isOpen: true}))
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}


// User Family Other Information
export const fetchUserFamilyData = (id) => async (dispatch) => {
  try {
    const response = await User.getFamilyData(id);
    await dispatch(setUserFamily(response.data.data));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postUserFamilyData = (data, props) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    const response = await User.postFamilyData(data);
    await dispatch(setUserFamily(data));
    await dispatch(setIsOpen({isOpen: true}));
    await dispatch(setSending({ isSending: false }));
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}


// User Spouse Data
export const fetchUserSpouseData = (id) => async (dispatch) => {
  try {
    const response = await Spouse.get(id);
    await dispatch(setUsersSpouse(response.data.data));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postUserSpouseData = (data, props, setFormErrors) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    let {gross_salary, future_salary, life_insurance_amount} = data
    gross_salary = convertDecimal(gross_salary);
    future_salary =  convertDecimal(future_salary);
    life_insurance_amount = convertDecimal(life_insurance_amount);

    const response = await Spouse.post({...data, gross_salary, future_salary, life_insurance_amount});
    await dispatch(setUsersSpouse({...data, gross_salary, future_salary, life_insurance_amount}));
    await dispatch(setSending({ isSending: false }));
    await dispatch(setIsOpen({ isOpen: true }))
    await dispatch(setNewTasksCount(response.data.newTasksCount));
  } catch (e) {
    console.error(e);
    dispatch(setSending({ isSending: false }));

    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }

    if(e.response && e.response.data.errors) {
      dispatch(setError(e.response.data.errors));
      setFormErrors(e.response.data.errors)
    }
  }
}


// Upload Avatar
export const uploadAvatar = data => async (dispatch) => {
  try {
    await dispatch(setUploading(true));
    await Profile.postAvatar(data);
    await dispatch(setUploading(false));
    // await fetchUserData
    // dispatch(setUserAvatar({avatar: ''}));
  } catch (e) {
    console.error(e);
    await dispatch(setUploading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

// Update Profile Data
export const postProfileData = data => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    await Profile.post(data);
    await dispatch(setUserData(data));
    await dispatch(setSending({ isSending: false }));
  } catch (e) {
    console.error(e);
    dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

// User Values
export const fetchValues = (isSpouse) => async (dispatch) => {
  try {
    if (isSpouse) {
      const response = await Spouse.getValues();
      await dispatch(setUserSpouseValues(response.data.data));
    } else {
      const response = await User.fetchUserValues();
      await dispatch(setUserValues(response.data.data));
    }
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postValues = (data, props, options, isSpouse) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    const newData = data.map(item => {
      const value_id = options.find(i => i.label === item.name).id;
      return {
        ...item,
        value_id
      }
    })

    let response = null

    if (isSpouse) {
      response = await Spouse.postValues(newData);
    } else {
      response = await User.postUserValues(newData);
    }

    await dispatch(setIsOpen({ isOpen: true }))
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
    await dispatch(setSending({ isSending: false }));
    // await dispatch(() => props.history.push('/values'));
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}


// Fetch/Post Goals
export const fetchGoals = (isSpouse) => async (dispatch) => {
  try {
    if (isSpouse) {
      const response = await Spouse.getGoals();
      await dispatch(setUserSpouseGoals(response.data.data));
    } else {
      const response = await User.fetchUserGoals();
      await dispatch(setUserGoals(response.data.data));
    }
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postGoals = (data, props, isSpouse) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    const filterData = data.filter(i => !isUndefined(i));
    const mapData = filterData.map(i => {
      if(!i.requires_money){
        return {
          ...i,
          requires_money: false
        }
      } else {
        return {
          ...i,
          requires_money: true
        }
      }
    });

    let response = null
    if (isSpouse) {
      response = await Spouse.postGoals(mapData);
    } else {
      response = await User.postUserGoals(mapData);
    }

    await dispatch(setIsOpen({ isOpen: true }))

    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }

    await dispatch(setSending({ isSending: false }));
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

// Fetch/Post Dreams
export const fetchDreams = (isSpouse) => async (dispatch) => {
  try {
    if (isSpouse) {
      const response = await Spouse.getDreams();
      await dispatch(setUserSpouseDreams(response.data.data));
    } else {
      const response = await User.fetchUserDreams();
      await dispatch(setUserDreams(response.data.data));
    }
  } catch (e) {
    console.error(e);
  }
}

export const postDreams = (data, props, isSpouse) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    const filterData = data.filter(i => !isUndefined(i));
    const mapData = filterData.map(i => {
      if(!i.requires_money){
        return {
          ...i,
          requires_money: false
        }
      } else {
        return {
          ...i,
          requires_money: true
        }
      }
    });

    let response = null
    if (isSpouse) {
      response = await Spouse.postDreams(mapData);
    } else {
      response = await User.postUserDreams(mapData);
    }

    await dispatch(setIsOpen({ isOpen: true }))

    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
    await dispatch(setSending({ isSending: false }));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}


export const registerDropbox = data => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    await dispatch(setError(null));
    const response = await Dropbox.post(data);
    if (response.data.dropbox_api_token) {
      await dispatch(setSending({ isSending: false }));
      await dispatch(setIsActivatedDropbox(true))
    }
  } catch (e) {
    console.error(e);
    dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
    if(e.response) {
      dispatch(setError(e.response.data.message));
    }
  }
}

export const showDropbox = data => async (dispatch) => {
  try {
    const response = await Dropbox.postNotify(data);
    if (response.data.dropbox_popup_show) {
      await dispatch(setDropboxPopup(true))
    }
  } catch (e) {
    console.error(e);
  }
}

export const fetchDropboxStatus = () => async (dispatch) => {
  try {
    const response = await Dropbox.getConnect();
    await dispatch(setDropboxStatus(response.data.is_connect));
  } catch (e) {
    console.error(e);
  }
}

export const reSaveData = () => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));

    const response = await User.resetData();
    if (response && response.data.message === 'Updated') {
      await dispatch(setReset(false))
      await dispatch(setSending({ isSending: false }));
    }
  } catch (e) {
    console.error(e);
    dispatch(setSending({ isSending: false }));
  }
}

// Set delay time of plan popup when Basic plan is 100%
export const setPlanPopupDelay = data => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    const response = await User.planPopupDelay(data);
    await dispatch(setUserData({
      ...data,
      plan_popup_show_delay: +response.data.date
    }))
    await dispatch(setSending({ isSending: false }));
  } catch (e) {
    console.error(e);
    dispatch(setSending({ isSending: false }));
  }
}

// Set delay time of plan tasks popup when Basic or Comprehansive plan is 100%
export const setPlanTasksPopupDelay = data => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    const response = await User.planTasksPopupDelay(data);
    await dispatch(
      setUserData({
        ...data,
        plan_choose_task_delay: +response.data.date,
      })
    );
    await dispatch(setSending({ isSending: false }));
  } catch (e) {
    console.error(e);
    dispatch(setSending({ isSending: false }));
  }
}

export const fetchPushNotificationSchedule = (companyId) => async (dispatch) => {
  try {
    dispatch(setPushNotificationSchedule({}));
    const response = await Admin.getPushNotificationSchedule(companyId);
    dispatch(setPushNotificationSchedule(response.data.data));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
};

export const postPushNotificationSchedule = (companyId, data) => async (dispatch) => {
  try {
    dispatch(setSending({ isSending: true }));
    await Admin.postPushNotificationSchedule(companyId, data);
    dispatch(setSending({ isSending: false }));
    dispatch(setMode("success"));
    dispatch(setIsOpen({ isOpen: true }));
  } catch (e) {
    console.error(e);
    dispatch(setMode("error"));
    dispatch(setIsOpen({ isOpen: true }));
    dispatch(setSending({ isSending: false }));
  }
};

export const fetchPushNotificationPlan = () => async (dispatch) => {
  try {
    const response = await User.getPushNotificationPlan();
    await dispatch(setUserPushNotificationPlan(response.data.data.sort((a, b) => a.id - b.id)));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
};

export const postPushNotificationPlan = (data) => async (dispatch) => {
  try {
    await dispatch(setMode("success"));
    await User.postPushNotificationPlan(data);
    await dispatch(setUserData({ ...data }));
    await dispatch(setIsOpen({ isOpen: true }));
  } catch (e) {
    console.error(e);
    dispatch(setMode("error"));
    dispatch(setIsOpen({ isOpen: true }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postUnsubscribePushNotificationPlan = data => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    await User.postUnsubscribePushNotificationPlan(data);
    await dispatch(setSending({ isSending: false }));
    dispatch(setMode("success"));
    dispatch(setIsOpen({ isOpen: true }));
  } catch (e) {
    console.error(e);
    dispatch(setMode("error"));
    dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const {
  setSplashCompleted,
  setPlanCompleted,
  setUserData,
  setUserDetails,
  setUserInfo,
  setUserAssets,
  setUserFamily,
  setUserChildren,
  setUsersSpouse,
  setUserAvatar,
  setUserValues,
  setUserDreams,
  setUserGoals,
  setUserSpouseValues,
  setUserSpouseDreams,
  setUserSpouseGoals,
  setError,
  setIsActivatedDropbox,
  setShowProgress,
  setUploading,
  setDropboxStatus,
  setDropboxPopup,
  setPushNotificationSchedule,
  setReset,
  setUserPushNotificationPlan
} = userSlice.actions

export default userSlice.reducer