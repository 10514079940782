import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Layout from '../../../components/Layout/Layout'
import LayoutForm from '../../../components/Layout/LayoutForm'
import { BackButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserFamilyChildren, postUserFamilyChildren} from '../../../redux/slices/userSlice'
import { normalizeBoolean, getPercent } from '../../../utils/utils'
import { Form, Formik } from 'formik'
import {FormGroup, Input} from '../../../components/shared/Form'
import { UserChildrenSchema } from '../../../utils/validation'
import Modal from '../../../components/shared/Modal/Modal'
import ProgressCircle from '../../../components/shared/ProgressBar/ProgressCircle'
import { setIsOpen } from '../../../redux/slices/modalSlice'
import { fetchPercentages } from '../../../redux/slices/percentagesSlice'

const AboutYourFamilyChildren = ({ props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useSelector(state => state.user.user);
  const { children } = useSelector(state => state.user.family);
  const isOpen = useSelector(state => state.modal.isOpen);
  const percents = useSelector(state => state.percentages.percents);
  const sending = useSelector(state => state.sending.isSending);

  useEffect(() => {
    async function fetch() {
      dispatch(fetchUserFamilyChildren(id))
      dispatch(fetchPercentages())
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const total = getPercent(percents, 'about-your-family')
  const totalChildren = getPercent(percents, 'about-your-family-children')

  const onSubmit = (values) => {
    dispatch(postUserFamilyChildren(values, props))
  }

  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }))
  }

  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }));
    if((total > 66 && totalChildren === 0) || (total === 100 && totalChildren === 100)) {
      history.push('/profile-summary')
    } else {
      history.push('/profile-summary/about-your-family')
    }
  }

  const handleChangeInput = (e, setValue) => {
    if([null, false].includes(e.target.value)) return false

    setValue(e.target.name, e.target.value)
  }

  return (
    <Layout>
      <LayoutForm small>
        <BackButton title="Back" />
        <PageTitle
          small
          title="Children/Dependents"
          content="Info about your family"
        />
        <div className="layout-form__content-holder">
          <Formik
            enableReinitialize
            initialValues={children && {...children}}
            validationSchema={UserChildrenSchema}
            onSubmit={onSubmit}
          >
            {({ errors, handleChange, handleBlur, values, touched, setFieldValue }) => (
              <Form>
                <FormGroup name="total_children" labelText="Total number of children/dependents">
                  <Input
                    placeholder="Type your number of children/dependents"
                    type="number"
                    name="total_children"
                    onChange={e => handleChangeInput(e, setFieldValue)}
                    value={values.total_children.toString() || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.total_children}
                  />
                  {errors.total_children && <span className="form__msg-error">{errors.total_children}</span>}
                </FormGroup>
                <FormGroup name="prior_children" labelText="Number of children/dependents from prior  marriage(s) (optional)">
                  <Input
                    placeholder="Type your number of children/dependents"
                    type="number"
                    name="prior_children"
                    onChange={e => handleChangeInput(e, setFieldValue)}
                    value={values.prior_children.toString() || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.prior_children}
                  />
                  {errors.prior_children && <span className="form__msg-error">{errors.prior_children}</span>}
                </FormGroup>
                <FormGroup name="want_more_children" labelText="Are you planning to have any more children/dependents?">
                  <RadioGroup
                    name="want_more_children"
                    value={values.want_more_children || false}
                    onChange={(e) => {
                      setFieldValue('want_more_children', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                </FormGroup>
                <FormGroup name="support_children_after_retire" labelText="Would you like to financially support your children/dependents after you retire? (optional)">
                  <RadioGroup
                    name="support_children_after_retire"
                    value={values.support_children_after_retire || false}
                    onChange={(e) => {
                      setFieldValue('support_children_after_retire', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                </FormGroup>

                <div className="form__button form__button--center">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Save
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </LayoutForm>
      <Modal
        open={isOpen}
        small
        button="Done"
        onClick={handleClick}
        close={onClose}
      >
        <h3>Good Job!</h3>
        <p>Your progress increased 100%</p>
        <ProgressCircle amount={100} small />
      </Modal>
    </Layout>
  );
};

export default AboutYourFamilyChildren;
