import React from 'react'
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik'
import AuthContent from '../../components/Auth/AuthContent'
import { Input, FormGroup, PasswordStrength } from '../../components/shared/Form'
import { Button } from '@mui/material'
import { CreatePasswordSchema } from '../../utils/validation'
import Tree from '../../assets/images/door-lock.png'
import { useDispatch, useSelector } from 'react-redux'
import { resetPassword } from '../../redux/slices/authSlice'
import { setIsOpen } from "../../redux/slices/modalSlice";
import Modal from "../../components/shared/Modal/Modal";

const CreateNewPassword = ({ props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isOpen = useSelector(state => state.modal.isOpen);

  const handleModalClose = () => {
    dispatch(setIsOpen({ isOpen: false }));
    history.push('/login');
  };

  const onSubmit = values => {
    dispatch(resetPassword(values, props))
  };
  return (
    <AuthContent decor={{image: Tree}}>
      <h4>Enter your new password</h4>
      <Formik
        enableReinitialize
        initialValues={{
          password: '',
          password_confirmation: ''
        }}
        validationSchema={CreatePasswordSchema}
        onSubmit={onSubmit}
      >
        {({ errors, handleChange, handleBlur, values, touched }) => (
          <Form>
            <FormGroup name="password" labelText="New password">
              <Input
                name="password"
                placeholder="Enter your new password"
                type="password"
                onChange={handleChange}
                value={values.password}
                onBlur={handleBlur}
                hasError={!!errors.password && touched.password}
              />
              {touched.password && errors.password && <span className="form__msg-error">{errors.password}</span>}
            </FormGroup>
            <FormGroup name="password_confirmation" labelText="Retype your password">
              <Input
                name="password_confirmation"
                placeholder="Enter your new password"
                type="password"
                onChange={handleChange}
                value={values.password_confirmation}
                onBlur={handleBlur}
                hasError={!!errors.password_confirmation && touched.password_confirmation}
              />
              {touched.password_confirmation && errors.password_confirmation && <span className="form__msg-error">{errors.password_confirmation}</span>}
            </FormGroup>
            <div className="form__button">
              <Button variant="contained" size="large" color="primary" type="submit">
                Save password
              </Button>
            </div>
            <div className="auth__form-option">
              <PasswordStrength password={values.password} />
            </div>
          </Form>
        )}
      </Formik>
      <Modal
        open={isOpen}
        small
        button="Ok"
        close={handleModalClose}
        onClick={handleModalClose}
        children={<p>Your password has been reset!</p>}
      />
    </AuthContent>
  );
};

export default CreateNewPassword;
