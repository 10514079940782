import React from 'react'
import { Form, Formik } from 'formik'
import { Button } from '@mui/material'
import {FormGroup, InputCurrency} from '../../components/shared/Form'

const NetWorth = ({ title, onSave, values, coreValues, validationSchema, sending, noEdit }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{ ...values }}
      validationSchema={validationSchema}
      onSubmit={(values) => onSave(values)}
    >
      {({ errors, handleChange, handleBlur, values, touched }) => (
        <Form>
          <h4>{title}</h4>
          <div className="form__flow-wrap">
            <FormGroup name="average_net_worth_thirty_four" labelText="Average net worth at 34 years of age ">
              <InputCurrency
                icon
                placeholder="$0.00"
                name="average_net_worth_thirty_four"
                onChange={handleChange}
                value={values.average_net_worth_thirty_four || ''}
                onBlur={handleBlur}
                className={coreValues?.average_net_worth_thirty_four && !noEdit && 'form__input--core'}
                hasError={!!errors.average_net_worth_thirty_four && touched.average_net_worth_thirty_four}
              />
              {touched.average_net_worth_thirty_four && errors.average_net_worth_thirty_four ? (
                <span className="form__msg-error">{errors.average_net_worth_thirty_four}</span>
              ) : coreValues && coreValues.average_net_worth_thirty_four && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.average_net_worth_thirty_four}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="average_net_worth_forty_four" labelText="Average net worth at 44 years of age">
              <InputCurrency
                icon
                placeholder="$0.00"
                name="average_net_worth_forty_four"
                onChange={handleChange}
                value={values.average_net_worth_forty_four || ''}
                onBlur={handleBlur}
                className={coreValues?.average_net_worth_forty_four && !noEdit && 'form__input--core'}
                hasError={!!errors.average_net_worth_forty_four && touched.average_net_worth_forty_four}
              />
              {touched.average_net_worth_forty_four && errors.average_net_worth_forty_four ? (
                <span className="form__msg-error">{errors.average_net_worth_forty_four}</span>
              ) : coreValues && coreValues.average_net_worth_forty_four && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.average_net_worth_forty_four}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="average_net_worth_fifty_four" labelText="Average net worth at 54 years of age">
              <InputCurrency
                icon
                placeholder="$0.00"
                name="average_net_worth_fifty_four"
                onChange={handleChange}
                value={values.average_net_worth_fifty_four || ''}
                onBlur={handleBlur}
                className={coreValues?.average_net_worth_fifty_four && !noEdit && 'form__input--core'}
                hasError={!!errors.average_net_worth_fifty_four && touched.average_net_worth_fifty_four}
              />
              {touched.average_net_worth_fifty_four && errors.average_net_worth_fifty_four ? (
                <span className="form__msg-error">{errors.average_net_worth_fifty_four}</span>
              ) : coreValues && coreValues.average_net_worth_fifty_four && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.average_net_worth_fifty_four}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="average_net_worth_sixty_four" labelText="Average net worth at 64 years of age">
              <InputCurrency
                icon
                placeholder="$0.00"
                name="average_net_worth_sixty_four"
                onChange={handleChange}
                value={values.average_net_worth_sixty_four || ''}
                onBlur={handleBlur}
                className={coreValues?.average_net_worth_sixty_four && !noEdit && 'form__input--core'}
                hasError={!!errors.average_net_worth_sixty_four && touched.average_net_worth_sixty_four}
              />
              {touched.average_net_worth_sixty_four && errors.average_net_worth_sixty_four ? (
                <span className="form__msg-error">{errors.average_net_worth_sixty_four}</span>
              ) : coreValues && coreValues.average_net_worth_sixty_four && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.average_net_worth_sixty_four}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="average_net_worth_seventy_four" labelText="Average net worth at 74 years of age">
              <InputCurrency
                icon
                placeholder="$0.00"
                name="average_net_worth_seventy_four"
                onChange={handleChange}
                value={values.average_net_worth_seventy_four || ''}
                onBlur={handleBlur}
                className={coreValues?.average_net_worth_seventy_four && !noEdit && 'form__input--core'}
                hasError={!!errors.average_net_worth_seventy_four && touched.average_net_worth_seventy_four}
              />
              {touched.average_net_worth_seventy_four && errors.average_net_worth_seventy_four ? (
                <span className="form__msg-error">{errors.average_net_worth_seventy_four}</span>
              ) : coreValues && coreValues.average_net_worth_seventy_four && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.average_net_worth_seventy_four}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="average_net_worth_over_seventy_four" labelText="Average net worth at 75+ years of age">
              <InputCurrency
                icon
                placeholder="$0.00"
                name="average_net_worth_over_seventy_four"
                onChange={handleChange}
                value={values.average_net_worth_over_seventy_four || ''}
                onBlur={handleBlur}
                className={coreValues?.average_net_worth_over_seventy_four && !noEdit && 'form__input--core'}
                hasError={!!errors.average_net_worth_over_seventy_four && touched.average_net_worth_over_seventy_four}
              />
              {touched.average_net_worth_over_seventy_four && errors.average_net_worth_over_seventy_four ? (
                <span className="form__msg-error">{errors.average_net_worth_over_seventy_four}</span>
              ) : coreValues && coreValues.average_net_worth_over_seventy_four && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.average_net_worth_over_seventy_four}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="median_net_worth_thirty_four" labelText="Median net worth at 34 years of age">
              <InputCurrency
                icon
                placeholder="$0.00"
                name="median_net_worth_thirty_four"
                onChange={handleChange}
                value={values.median_net_worth_thirty_four || ''}
                onBlur={handleBlur}
                className={coreValues?.median_net_worth_thirty_four && !noEdit && 'form__input--core'}
                hasError={!!errors.median_net_worth_thirty_four && touched.median_net_worth_thirty_four}
              />
              {touched.median_net_worth_thirty_four && errors.median_net_worth_thirty_four ? (
                <span className="form__msg-error">{errors.median_net_worth_thirty_four}</span>
              ) : coreValues && coreValues.median_net_worth_thirty_four && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.median_net_worth_thirty_four}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="median_net_worth_forty_four" labelText="Median net worth at 44 years of age">
              <InputCurrency
                icon
                placeholder="$0.00"
                name="median_net_worth_forty_four"
                onChange={handleChange}
                value={values.median_net_worth_forty_four || ''}
                onBlur={handleBlur}
                className={coreValues?.median_net_worth_forty_four && !noEdit && 'form__input--core'}
                hasError={!!errors.median_net_worth_forty_four && touched.median_net_worth_forty_four}
              />
              {touched.median_net_worth_forty_four && errors.median_net_worth_forty_four ? (
                <span className="form__msg-error">{errors.median_net_worth_forty_four}</span>
              ) : coreValues && coreValues.median_net_worth_forty_four && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.median_net_worth_forty_four}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="median_net_worth_fifty_four" labelText="Median net worth at 54 years of age">
              <InputCurrency
                icon
                placeholder="$0.00"
                name="median_net_worth_fifty_four"
                onChange={handleChange}
                value={values.median_net_worth_fifty_four || ''}
                onBlur={handleBlur}
                className={coreValues?.median_net_worth_fifty_four && !noEdit && 'form__input--core'}
                hasError={!!errors.median_net_worth_fifty_four && touched.median_net_worth_fifty_four}
              />
              {touched.median_net_worth_fifty_four && errors.median_net_worth_fifty_four ? (
                <span className="form__msg-error">{errors.median_net_worth_fifty_four}</span>
              ) : coreValues && coreValues.median_net_worth_fifty_four && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.median_net_worth_fifty_four}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="median_net_worth_sixty_four" labelText="Median net worth at 64 years of age">
              <InputCurrency
                icon
                placeholder="$0.00"
                name="median_net_worth_sixty_four"
                onChange={handleChange}
                value={values.median_net_worth_sixty_four || ''}
                onBlur={handleBlur}
                className={coreValues?.median_net_worth_sixty_four && !noEdit && 'form__input--core'}
                hasError={!!errors.median_net_worth_sixty_four && touched.median_net_worth_sixty_four}
              />
              {touched.median_net_worth_sixty_four && errors.median_net_worth_sixty_four ? (
                <span className="form__msg-error">{errors.median_net_worth_sixty_four}</span>
              ) : coreValues && coreValues.median_net_worth_sixty_four && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.median_net_worth_sixty_four}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="median_net_worth_seventy_four" labelText="Median net worth at 74 years of age">
              <InputCurrency
                icon
                placeholder="$0.00"
                name="median_net_worth_seventy_four"
                onChange={handleChange}
                value={values.median_net_worth_seventy_four || ''}
                onBlur={handleBlur}
                className={coreValues?.median_net_worth_seventy_four && !noEdit && 'form__input--core'}
                hasError={!!errors.median_net_worth_seventy_four && touched.median_net_worth_seventy_four}
              />
              {touched.median_net_worth_seventy_four && errors.median_net_worth_seventy_four ? (
                <span className="form__msg-error">{errors.median_net_worth_seventy_four}</span>
              ) : coreValues && coreValues.median_net_worth_seventy_four && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.median_net_worth_seventy_four}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="median_net_worth_over_seventy_four" labelText="Median net worth at 75+ years of age">
              <InputCurrency
                icon
                placeholder="$0.00"
                name="median_net_worth_over_seventy_four"
                onChange={handleChange}
                value={values.median_net_worth_over_seventy_four || ''}
                onBlur={handleBlur}
                className={coreValues?.median_net_worth_over_seventy_four && !noEdit && 'form__input--core'}
                hasError={!!errors.median_net_worth_over_seventy_four && touched.median_net_worth_over_seventy_four}
              />
              {touched.median_net_worth_over_seventy_four && errors.median_net_worth_over_seventy_four ? (
                <span className="form__msg-error">{errors.median_net_worth_over_seventy_four}</span>
              ) : coreValues && coreValues.median_net_worth_over_seventy_four && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.median_net_worth_over_seventy_four}</span>
              ) : (
                ''
              )}
            </FormGroup>
          </div>
          <div className="form__button">
            <Button variant="contained" size="large" color="primary" type="submit" disabled={sending || noEdit}>
              Save
              {sending && <span className="spinner" />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default NetWorth
