import React, { useEffect, useState } from "react";
import LayoutAdmin from "../../components/Layout/LayoutAdmin";
import { useParams, useLocation } from 'react-router-dom'
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Input, FormGroup, FormSelect } from "../../components/shared/Form";
import { Button, FormControl } from "@mui/material";
import { BackButton } from '../../components/shared/Buttons'
import { AdminEditMemberSchema } from "../../utils/validation";
import { updateMember, fetchCompany, fetchMember } from '../../redux/slices/companiesSlice';
import Modal from '../../components/shared/Modal/Modal';
import { setIsOpen } from '../../redux/slices/modalSlice';
import { setMode } from '../../redux/slices/modeSlice';
import { isNull } from "lodash";


const rolesList = [
  {
    id: 5,
    key: 5,
    value: 'superadmin_soft',
    label: 'ValueSoft SuperAdmin',
  },
  {
    id: 4,
    key: 4,
    value: 'superadmin_company',
    label: 'Company Instance SuperAdmin',
  },
  {
    id: 3,
    key: 3,
    value: 'company_admin',
    label: 'Company Admin',
  },
  {
    id: 2,
    key: 2,
    value: 'planner',
    label: 'Planner',
  },
  {
    id: 1,
    key: 1,
    value: 'user',
    label: 'Member',
  }
];

const AdminCompanyEditMember = ({ props, isAdmin }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id, role_id } = useSelector((state) => state.user.user);
  const sending = useSelector((state) => state.sending.isSending);
  const { member } = useSelector((state) => state.companies);
  const companyInfo = useSelector((state) => state.companies.company.info);
  const { companyId, memberId } = useParams();
  const [isMobile, setIsMobile] = useState(false);
  const [isPostModal, setPostModal] = useState(false);
  const [isPlannerModal, setPlannerModal] = useState(false);
  const [isUserModal, setUserModal] = useState(false);
  const [changeRole, setChangeRole] = useState(null);
  const [userValues, setUserValues] = useState(null)
  const isOpen = useSelector((state) => state.modal.isOpen);
  const mode = useSelector((state) => state.mode.mode);

  useEffect(() => {
    if (!isNull(member)) {
      setUserValues(member);
    }
  }, [member]);
  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }));
    dispatch(setMode(null));
  };

  const mobileView = 999;

  const handleResize = () => {
    mobileView >= window.innerWidth ? setIsMobile(true) : setIsMobile(false);
  };

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(fetchCompany(companyId));
    dispatch(fetchMember(companyId, memberId, location.hash));
    // eslint-disable-next-line
  }, []);

  const onSubmit = (values, setErrors) => {
    let formData = new FormData();

    formData.append('first_name', values.first_name);
    formData.append('last_name', values.last_name);
    formData.append('role', values.role);
    formData.append('email', values.email);

    dispatch(updateMember(companyId, memberId, (location.hash ? values : formData), location.hash, setErrors));
  };

  const rolesListMaker = role => {
    if (role === 2 || (role === 1 && id !== member?.id)) {
      return rolesList.slice(1, 5);
    } else if (role === 3) {
      return rolesList.slice(2, 5);
    } else {
      return rolesList;
    }
  }

  const handleRole = () => {
    if (!isNull(member.planner)) {
      setPlannerModal(true)
    } else if (member.planner_posts) {
      setPostModal(true);
    } else {
      setUserModal(true);
    }
  };

  const onConfirm = () => {
    setUserValues({
      ...userValues,
      role: changeRole
    })
    setPostModal(false);
  }

  return (
    <LayoutAdmin companyId={companyId} isAdmin={isAdmin}>
      <div className="member-comapny">
        <div className="member-comapny__info">
          <div className="member-comapny__logo">
            <img src={companyInfo?.image} alt="Company logo" />
          </div>
          <div className="member-comapny__title">
            <h3>{companyInfo?.name}</h3>
            {!isMobile && (
              <p>
                Feedback Email: <span>{companyInfo?.email}</span>
              </p>
            )}
          </div>
        </div>
        {isMobile && (
          <div className="mobile-feedback">
            Feedback Email: <span>{companyInfo?.email}</span>
          </div>
        )}
      </div>
      <BackButton
        title="Back to Members"
        className="back-button--company"
        path={`/admin/company/${companyId}/members`}
      />
      <div className="company">
        <Formik
          enableReinitialize
          initialValues={userValues}
          validationSchema={AdminEditMemberSchema}
          onSubmit={(values, { setErrors }) => onSubmit(values, setErrors)}
        >
          {({ errors, handleChange, handleBlur, values, touched, setFieldValue }) => (
            <Form>
              <h4 className="company__title">Edit member</h4>
              <p className="company__subtitle">Enter the fields below and click the Save button.</p>
              {member?.status === 'Active' ? (
                <p className="company__subtitle--success">The member is active.</p>
              ) : (
                <p className="company__subtitle--error">The member is inactive.</p>
              )}
              <div className="comapny__form">
                <FormGroup name="first_name" labelText="First name">
                  <Input
                    name="first_name"
                    placeholder="Enter name"
                    type="text"
                    onChange={handleChange}
                    value={values?.first_name || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.first_name && touched.first_name}
                  />
                  {touched.first_name && errors.first_name && (
                    <span className="form__msg-error">{errors.first_name}</span>
                  )}
                </FormGroup>
              </div>
              <div className="comapny__form">
                <FormGroup name="last_name" labelText="Last name">
                  <Input
                    name="last_name"
                    placeholder="Enter lastname"
                    type="text"
                    onChange={handleChange}
                    value={values?.last_name || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.last_name && touched.last_name}
                  />
                  {touched.last_name && errors.last_name && <span className="form__msg-error">{errors.last_name}</span>}
                </FormGroup>
              </div>
              <div className="comapny__form">
                <FormControl disabled={id === member?.id}>
                  <FormGroup name="role" labelText="Role">
                    <FormSelect
                      options={rolesListMaker(role_id)}
                      id="role"
                      value={values?.role || ''}
                      name="role"
                      onChange={(e) => {
                        if (!isNull(member.planner)) {
                          handleRole();
                          setChangeRole(e.target.value);
                        } else if (member.role_id === 4 && (member.planner_posts || member.planner_users)) {
                          handleRole();
                          setChangeRole(e.target.value);
                        } else {
                          setFieldValue(e.target.name, e.target.value);
                        }
                      }}
                      onBlur={handleBlur}
                      placeholder="Select role"
                      hasError={!!errors.role && touched.role}
                    />
                    {touched.role && errors.role && <span className="form__msg-error">{errors.role}</span>}
                  </FormGroup>
                </FormControl>
              </div>
              <div className="comapny__form">
                <FormGroup name="email" labelText="Email address">
                  <Input
                    disabled={member?.status !== 'Active'}
                    name="email"
                    placeholder="Enter an email"
                    type="text"
                    onChange={handleChange}
                    value={values?.email || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.email && touched.email}
                  />
                  {touched.email && errors.email && <span className="form__msg-error">{errors.email}</span>}
                </FormGroup>
              </div>
              <div className="form__button">
                <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                  Save
                  {sending && <span className="spinner" />}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <Modal
          open={isOpen}
          small
          button="Ok"
          onClick={handleClick}
          close={handleClick}
          title={mode === 'success' ? 'Success!' : 'Error!'}
          children={
            mode === 'success' ? <p>The data was saved successfully.</p> : <p>Something went wrong. Try again.</p>
          }
        />
        <Modal
          open={isPlannerModal}
          small
          button="Confirm"
          onClick={() => setPlannerModal(false)}
          close={() => setPlannerModal(false)}
          title="Attention!"
          children={
            <p>
              {`Sorry, you can’t change ${member?.first_name} ${member?.last_name} role because user has Planner assistant ${member?.planner?.first_name} ${member?.planner?.last_name}. Please remove Planner before reassigning ${member?.first_name} to a new role.`}
            </p>
          }
        />
        <Modal
          open={isUserModal}
          small
          button="Confirm"
          onClick={() => setUserModal(false)}
          close={() => setUserModal(false)}
          title="Attention!"
          children={
            <p>
              {`Sorry, you can’t change ${member?.first_name} ${member?.last_name} role because they are assigned to either clients as a Planner. Please associate those or clients to a new Planner before reassigning ${member?.first_name} to a new role.`}
            </p>
          }
        />
        <Modal
          open={isPostModal}
          small
          button="Confirm"
          closeButton="Cancel"
          onClick={onConfirm}
          close={() => setPostModal(false)}
          title="Attention!"
          children={
            <p>
              {`${member?.first_name} ${member?.last_name} has created blog posts for their client. Changing their role
              will result in their blog posts being deleted, in which case the blog posts will no longer appear for
              their former clients.`}
            </p>
          }
        />
      </div>
    </LayoutAdmin>
  );
};

export default AdminCompanyEditMember;
