import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { BackButton } from '../../../components/shared/Buttons'
import Layout from '../../../components/Layout/Layout'
import { Button } from '@mui/material'
import isArray from 'lodash/isArray'
import TreeSubItem from '../../../components/Tree/TreeSubItem'
import TreeImage from '../../../assets/images/tree/finances/Finance-tree.png'
import { fetchTotalPercent } from '../../../redux/slices/percentagesSlice'
import { fetchAssets } from '../../../redux/slices/financesSlice'
import { setIsOpen } from '../../../redux/slices/modalSlice'
import ModalBox from '../../../components/shared/Modal/Modal'
import TreeCount from '../../../components/Tree/TreeCount'

const AssetsTree = ({ props }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useSelector(state => state.user.user)
  const isBasic = useSelector((state) => state.user.user.plan_type === 1);
  const totalPercent = useSelector(state => state.percentages.total)
  const assets = useSelector(state => state.finances.assets)
  const [isMobile, setIsMobile] = useState(false)
  const isOpen = useSelector(state => state.modal.isOpen)
  const loading = useSelector(state => state.sending.isSending)
  const mobileView = 999;
  const handleResize = () => {
    (mobileView >= window.innerWidth) ? setIsMobile(true) : setIsMobile(false)
  }

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true)
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])


  useEffect(() => {
    async function fetch() {
      await dispatch(fetchAssets())
      await dispatch(fetchTotalPercent(isBasic))
    }

    if (id) {
      fetch()
    }

    // eslint-disable-next-line
  }, [id])

  const close = () => dispatch(setIsOpen({isOpen: false}))

  const handleClick = () => {
    if(assets && assets.length > 4) {
      return dispatch(setIsOpen({isOpen: true}))
    }

    return history.push('/assets/create')
  }


  return (
    <Layout totalPercent={totalPercent} tree>
      <div className="tree__sticky-holder">
        <BackButton title={isMobile ? 'Back' : 'Back to Tree'} path={isMobile ? '/finance' : "/input"} className="back-button--position" />
        <div style={{maxWidth: !isMobile && '272px'}} className="tree__title tree__title--progress-bar">
          <div className="tree__title-main">
            <h2>Current Fixed Assets & Loans</h2>
            <TreeCount
              value={assets && assets.length > 0 ? 100 : '0'}
              loading={loading}
              big={!isMobile}
            />
          </div>
          {!isMobile && (
            <>
              <p className="tree__title-other">Fill in information to complete this step.</p>
              <Button variant="contained" className="tree-bar" size="small" onClick={handleClick} color="primary">
                Add asset
              </Button>
            </>
          )}
        </div>
        <div className="tree tree--sub-page">
          {!isMobile && (
            <div className="tree__base">
              <img src={TreeImage} alt="ValueSoft Tree Assets" />
            </div>
          )}
          {assets && isArray(assets) && assets.map((item, i) =>
            <React.Fragment key={item.id}>
              <TreeSubItem path={`/assets/${item.id}`}
                           title={item.name}
                           value={`assets-item-${i + 1}`}
                           count={100}
              />
            </React.Fragment>
          )}
        </div>

        {isMobile &&
        <div className="tree__add-button">
          <Button variant="contained" size="small" onClick={handleClick} color="primary">
            {!isMobile && 'Add asset'}
          </Button>
        </div>
        }
      </div>

      <ModalBox
        small
        open={isOpen}
        close={close}
        onClick={close}
        button="Back to tree"
        title="Sorry"
        children={<p>The limit of adding new sections is reached.</p>} />
    </Layout>
  )
}

export default AssetsTree