import React from 'react'
import CheckEmail from '../../components/Auth/CheckEmail'


const EmailSend = () => (
  <CheckEmail title="Check your email">
    <p className="mb--medium">Email sent again</p>
  </CheckEmail>
)

export default EmailSend
