import React from 'react'
import { Button } from '@mui/material'
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const TourButton = ({ onClick, className }) => (
  <div className={`tour__button ${className || ""}`}>
    <Button
      startIcon={<HelpOutlineIcon />}
      size="small"
      className="MuiButton-minimal"
      onClick={onClick}
    >
      Getting started
    </Button>
  </div>
);

export default TourButton