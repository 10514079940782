import React from 'react'
import PropTypes from 'prop-types'
import SVGIcon from '../shared/SVGIcon/SVGIcon'
import notification from '../../assets/images/icons/bell.svg'
import clsx from 'classnames'

const AlertMessage = ({ text, id, remove, animate, className }) => {
  return (
    <div className={clsx('alert', {
           'alert--animate' : animate,
            [`${className}`]: className
         })}
    >
      <div className="alert__icon">
        <SVGIcon icon={notification} color="#ffffff" />
      </div>
      <div 
        className="alert__content"
        dangerouslySetInnerHTML={{ __html: text }}
      />
      <button className="alert__close" onClick={() => remove(id)}>Close</button>
    </div>
  )
}

AlertMessage.propTypes = {
  text: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  remove: PropTypes.func,
  className: PropTypes.string
}

export default AlertMessage
