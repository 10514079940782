import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import {
  Link,
  useLocation,
  useHistory,
  useParams,
  Redirect,
} from "react-router-dom";
import debounce from 'lodash/throttle';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import UserIcon from '../../assets/images/icons/user.svg';
import Loading from '../../components/shared/Loading/Loading';
import { fetchPushNotificationStatistics } from '../../redux/slices/statisticsSlice';
import { setIsOpen } from '../../redux/slices/modalSlice';
import { setMode } from '../../redux/slices/modeSlice';
import { Table, TableBody, TableHead, TableRow, TableCell } from '../../components/shared/Table';
import EditPushNotificationSchedule from '../../components/User/EditPushNotificationSchedule';
import { fetchCompany } from "../../redux/slices/companiesSlice";
import LayoutAdmin from '../../components/Layout/LayoutAdmin';
import SVGIcon from '../../components/shared/SVGIcon/SVGIcon';
import SearchIcon from '../../assets/images/icons/search.svg';
import Pagination from '../../components/Pagination/Pagination';
import Modal from '../../components/shared/Modal/Modal';
import { Input } from '../../components/shared/Form';
import { useQuery } from '../../utils/hooks';

const AdminEmailStatistics = ({ props, isAdmin }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const query = useQuery();
  const history = useHistory();
  const { companyId } = useParams();
  const { role_id } = useSelector((state) => state.user.user);
  const isOpen = useSelector((state) => state.modal.isOpen);
  const { pushNotification, isLoading } = useSelector(
    (state) => state.statistics
  );
  const { image, email, name } = useSelector(
    (state) => state.companies.company.info
  );
  const mode = useSelector((state) => state.mode.mode);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  const mobileView = 999;

  const handleResize = () => {
    mobileView >= window.innerWidth ? setIsMobile(true) : setIsMobile(false);
  };

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(fetchCompany(companyId));
    // eslint-disable-next-line
  }, []);

  const params = {
    ...(query.get("page") && { page: query.get("page") }),
    ...(query.get("search") && { search: query.get("search") }),
    ...(query.get("sort_by") && { sort_by: query.get("sort_by") }),
    ...(query.get("sort_type") && { sort_type: query.get("sort_type") }),
    ...(query.get("created_at") && { created_at: query.get("created_at") }),
  };

  useEffect(() => {
    dispatch(fetchPushNotificationStatistics(companyId, { params }));

    // eslint-disable-next-line
  }, [location]);

  const debouncedSave = useCallback(
    (value) =>
      debounce(() => {
        value ? query.set("search", value) : query.delete("search");
        history.push({ pathname: location.pathname, search: query.toString() });
      }, 1500)(),

    // eslint-disable-next-line
    []
  );

  const handleSetSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    debouncedSave(value);
  };

  const handleCloseInfoModal = () => {
    dispatch(setIsOpen({ isOpen: false }));
    dispatch(setMode(null));
  };

  const handleToggleScheduleModal = () => setIsModalOpen(!isModalOpen);

  if (role_id && role_id > 3) return <Redirect to="/404" />;

  return (
    <LayoutAdmin companyId={companyId} isAdmin={isAdmin}>
      <div className="member-comapny">
        <div className="member-comapny__info">
          <div className="member-comapny__logo">
            <img src={image} alt="Company logo" />
          </div>
          <div className="member-comapny__title">
            <h3>{name}</h3>
            {!isMobile && (
              <p>
                Feedback Email: <span>{email}</span>
              </p>
            )}
          </div>
        </div>
        {isMobile && (
          <div className="mobile-feedback">
            Feedback Email: <span>{email}</span>
          </div>
        )}
      </div>
      <h3 className="mobile-large-hidden">E-mail statistics</h3>
      <div className="mb--small">
        <Grid container wrap="wrap" justifyContent="space-between" spacing={1}>
          <Grid item xs={12} sm="auto">
            <div className="mobile-large-visible">
              <div className="form__area form__area--large">
                <Input
                  smallHeight
                  value={search}
                  onChange={handleSetSearch}
                  placeholder="Search"
                />
                <SVGIcon
                  className="form__icon"
                  icon={SearchIcon}
                  color="#999"
                />
              </div>
            </div>
            <div className="mobile-large-hidden">
              <div className="form__area form__area--large">
                <Input
                  xSmallHeight
                  value={search}
                  onChange={handleSetSearch}
                  placeholder="Search"
                />
                <SVGIcon
                  className="form__icon"
                  icon={SearchIcon}
                  color="#999"
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm="auto">
            <div className="mobile-large-visible">
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick={handleToggleScheduleModal}
              >
                Edit schedule
              </Button>
            </div>
            <div className="mobile-large-hidden">
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={handleToggleScheduleModal}
              >
                Edit mailing schedule
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>

      <h2 className="mobile-large-visible">User statistics</h2>

      {isLoading ? (
        <Loading small />
      ) : (
        <>
          {pushNotification?.data?.length > 0 ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th" offsetLeft sticky sortBy="name">
                    Name
                  </TableCell>
                  <TableCell component="th" nowrap sortBy="newest">
                    Newest tasks
                  </TableCell>
                  <TableCell component="th" nowrap sortBy="overdue">
                    Expired tasks
                  </TableCell>
                  <TableCell component="th" nowrap sortBy="created_at">
                    Created at
                  </TableCell>
                  <TableCell component="th" offsetRight align="right" />
                </TableRow>
              </TableHead>
              <TableBody>
                {pushNotification?.data?.map?.((row, index) => (
                  <TableRow key={index}>
                    <TableCell sticky offsetLeft>
                      <div className="statistics__table_user">
                        <div className="user-avatar">
                          <div className="user-avatar__placeholder">
                            {row.user.avatar ? (
                              <img
                                src={row.user.avatar}
                                width={32}
                                height={32}
                                alt=""
                              />
                            ) : (
                              <SVGIcon icon={UserIcon} />
                            )}
                          </div>
                        </div>
                        <div className="statistics__table_user_name">
                          {row.user.first_name} {row.user.last_name}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>{row.newest}</TableCell>
                    <TableCell>{row.expired}</TableCell>
                    <TableCell>
                      {format(parseISO(row.created_at), "MM-dd-yyyy")}
                    </TableCell>
                    <TableCell offsetRight align="right" nowrap>
                      <Link
                        to={`/admin/company/${companyId}/email-statistics/task-plan-statistics/${row.id}`}
                        className="typography__bold text-muted"
                      >
                        See more
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <div className="filters__no-results text-center">
              <h5>No results</h5>
            </div>
          )}

          <Pagination count={pushNotification?.meta?.last_page} />
        </>
      )}

      <Modal
        small
        open={isModalOpen}
        title="Edit mailing schedule"
        close={handleToggleScheduleModal}
      >
        <EditPushNotificationSchedule
          companyId={companyId}
          onSubmit={handleToggleScheduleModal}
        />
      </Modal>
      <Modal
        small
        open={isOpen}
        button="Ok"
        onClick={handleCloseInfoModal}
        close={handleCloseInfoModal}
        title={mode === "success" ? "Success!" : "Error!"}
        children={
          mode === "success" ? (
            <p>The values were updated successfully.</p>
          ) : (
            <p>Something went wrong. Try again.</p>
          )
        }
      />
    </LayoutAdmin>
  );
};

export default AdminEmailStatistics;
