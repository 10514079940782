import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../../components/Layout/Layout'
import { BackButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import LayoutForm from '../../../components/Layout/LayoutForm'
import { Button } from '@mui/material'
import { postDataStudent, fetchIdData, setLoading, fetchData } from '../../../redux/slices/childrenSlice'
import { fetchUserDetails, fetchUserSpouseData, fetchUserFamilyChildren } from '../../../redux/slices/userSlice'
import { Input, FormGroup, FormSelect } from '../../../components/shared/Form'
import ModalBox from '../../../components/shared/Modal/Modal'

const studentRelationType = [
  {
    id: 1,
    key: 1,
    value: "dependent",
    label: "Dependent",
  },
  {
    id: 2,
    key: 2,
    value: "self",
    label: "Self",
  },
  {
    id: 3,
    key: 3,
    value: "spouse",
    label: "Spouse",
  }
]

const currentYear = new Date().getFullYear();

const StudentsDetails = ({ props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userId = useSelector(state => state.user.user.id);
  const { first_name, year_of_birth } = useSelector(state => state.user.user);
  const { total_children } = useSelector(state => state.user.family.children);
  const children = useSelector(state => state.children.current)
  const spouse_name = useSelector(state => state.user.spouse.details.first_name);
  const spouse_birth = useSelector(state => state.user.spouse.details.year_of_birth);
  const sending = useSelector(state => state.children.loading);
  const isMarried = useSelector(state => state.user.profile.details.is_married);
  const { id }  = useParams();
  const [relateType, setRelateType] = useState('')
  const [isOpenModal, setOpenModal] = useState(false)

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchIdData(id));
    }

    if (userId && id) {
      fetch()
    }

    // eslint-disable-next-line
  }, [userId, id])

  useEffect(() => {
    async function fetchUserData() {
      await dispatch(fetchUserDetails(userId));
      await dispatch(fetchUserSpouseData());
      await dispatch(fetchData());
      await dispatch(fetchUserFamilyChildren(userId));
    }

    if (userId) {
      fetchUserData()
    }

    // eslint-disable-next-line
  }, [userId])

  useEffect(() => {
    relateType === "dependent" && (!total_children || (children && children.length === 0)) && setOpenModal(true)

    // eslint-disable-next-line
  }, [relateType])

  const onSubmit = async (values) => {
    await dispatch(setLoading(true))
    await dispatch(postDataStudent(values, props))
    await dispatch(setLoading(false))
  }

  return (
    <Layout>
      <LayoutForm small>
        <BackButton title="Back to Education Planning" />
        <PageTitle
          title="Current Student"
          content="Whom are you currently paying for education?"
          status="0"
        />
        <div className="layout-form__content-holder">
          <Formik
            enableReinitialize
            initialValues={{
                name: relateType === 'self' && first_name ? first_name : relateType === 'spouse' && spouse_name ? spouse_name : '',
                age: relateType === 'self' && year_of_birth ? currentYear - year_of_birth : relateType === 'spouse' && spouse_birth ? currentYear - spouse_birth : null,
                type: relateType || ''
              }
            }
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Required'),
              age: Yup.number().integer('Please check this value').typeError('Age must be a number').max(150, 'Please check age').required('Required'),
              type: Yup.string().required('Required')
            })}
            onSubmit={onSubmit}
          >
            {({ errors, handleChange, handleBlur, setFieldValue, values, touched }) => (
              <Form>
                <FormGroup
                    name="type"
                    labelText="Relationship"
                  >
                    <FormSelect
                      options={isMarried ? studentRelationType : studentRelationType.slice(0, 2)}
                      value={values.type || ''}
                      name="type"
                      onChange={(e) => {
                        setFieldValue(e.target.name, e.target.value)
                        setRelateType(e.target.value)
                      }}
                      placeholder="Type of relationship"
                      hasError={!!errors.type && touched.type}
                    />
                    {touched.type && errors.type && <span className="form__msg-error">{errors.type}</span>}
                </FormGroup>
                <FormGroup name="name" labelText="First name">
                  <Input
                    name="name"
                    placeholder="Type a name"
                    type="text"
                    onChange={handleChange}
                    value={values.name || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.name && touched.name}
                  />
                  {touched.name && errors.name && <span className="form__msg-error">{errors.name}</span>}
                </FormGroup>
                <FormGroup name="age" labelText="Age">
                  <Input
                    name="age"
                    placeholder="Type dependent's age"
                    type="text"
                    onChange={handleChange}
                    value={values.age || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.age && touched.age}
                  />
                  {touched.age && errors.age && <span className="form__msg-error">{errors.age}</span>}
                </FormGroup>
                <div className="form__button form__button--center">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Next
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <ModalBox
          small
          open={isOpenModal}
          close={() => {
            setOpenModal(false);
            setRelateType('');
          }}
          onClick={() => {
          setOpenModal(false);
          history.push(!total_children 
            ? '/profile-summary/about-your-family/children'
            : children && children.length === 0 ? '/dependents'
            : '');
          }}
          closeButton="Later"
          button={!total_children 
            ? "Add information"
            : children && children.length === 0 ? "Add Dependents"
            : ''}
          title="Sorry"
          children={!total_children 
            ? <p>You need to add the relevant information in the <strong className="text-success">About You</strong> page in order to activate this section.</p>
            : children && children.length === 0 ? <p>You need to add the <strong className="text-success">Dependents</strong> in order to activate this option.</p>
            : ''
          } />
      </LayoutForm>
    </Layout>
  );
};

export default StudentsDetails;
