import React, { useState } from 'react'
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride'


const Tour = ({
                steps,
                run,
                stop,
                isRun,
                handlersOnMobile,
                handlers,
                isMobile
}) => {
  const [stepIndex, setStepIndex] = useState(0)

  const styles = {
    options: {
      textColor: '#000',
      width: 210,
      zIndex: 1000,
    },
    buttonBack: {
      display: 'none'
    },
    buttonClose: {
      display: 'none'
    },
    tooltipContainer: {
      textAlign: 'left',
    },
    tooltipContent: {
      padding: '0',
      fontSize: 14,
    },
    buttonSkip: {
      padding: '0',
      fontWeight: 'bold',
      color: '#8C8C8C',
      fontSize: 14,
    },
    buttonNext: {
      backgroundColor: '#FD8A63',
      fontSize: 14,
      borderRadius: 8,
      fontWeight: 'bold',
      outline: 'none',
      color: '#fff',
      padding: '8px 15px'
    }
  }

  const handleJoyrideCallback = data => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      if (handlersOnMobile && isMobile) {
        handlersOnMobile.filter(i => i.step === index && i.func())
      } else if (handlers) {
        handlers.filter(i => i.step === index && i.func())
      }

      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    }
    else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      stop()
      setStepIndex(0)
    }
  }


  return (
    <Joyride run={isRun}
             stepIndex={stepIndex}
             steps={steps}
             callback={handleJoyrideCallback}
             showSkipButton={true}
             disableOverlay
             continuous={true}
             locale={{
               skip: 'End the tour',
               last: 'Finish',
             }}
             styles={styles}
    />
  )
}

export default Tour