import { createSlice } from '@reduxjs/toolkit'
import { Protection } from '../../api/Api'
import { setSending } from './sendingSlice'
import { convertDecimal } from '../../utils/utils'
import { setNewTasksCount } from './taskSlice'
import { setIsOpen } from './modalSlice'
import { setIsRelogin } from "./loadingSlice";

const protectionSlice = createSlice({
  name: 'protection',
  initialState: {
    estate: {
      will_upto_date: false,
      living_will: false,
      revocable_living_trust: false,
      irrevocable_living_trust: false,
      accounts_upto_date: false,
      executor: false,
      join_estate: false,
      gifting: false,
      charity: false,
      ab_trust: false,
      qualified_tip: false,
      qualified_prt: false,
      insurance_trust: false,
      medicaid: false
    },
    unemployment: {
      model_unemployment: false,
      who_utilized: 'me',
      unemployment_health_care: false,
      coverage_extended_to: 'me',
      leverage_each_other_plan: false,
      months: '',
      spouse_months: '',
      weeks_severance: '',
      accrued_vacation: '',
      additional_severance: '',
      spouse_weeks_severance: '',
      spouse_accrued_vacation: ''
    },
    bequeath: null,
    isLoading: false
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setEstate: (state, action) => {
      state.estate = {
        ...state.estate,
        ...action.payload
      }
    },
    setUnemployment: (state, action) => {
      state.unemployment = {
        ...state.unemployment,
        ...action.payload
      }
    },
    setBequeath: (state, action) => {
      state.bequeath = action.payload
    }
  }
})

export const fetchEstate = () => async (dispatch) => {
  try {
    await dispatch(setLoading(true));
    const response = await Protection.getEstate();
    await dispatch(setEstate(response.data.data));
    await dispatch(setLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postEstate = (data, props) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    const response = await Protection.postEstate(data);
    await dispatch(setSending({ isSending: false }));
    await dispatch(setIsOpen({ isOpen: true }))
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchUnemployment = () => async (dispatch) => {
  try {
    const response = await Protection.getUnemployment();
    await dispatch(setUnemployment(response.data.data));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postUnemployment = (data, props) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    let { additional_severance, spouse_additional_severance } = data;
    additional_severance         = additional_severance ? convertDecimal(additional_severance) : '';
    spouse_additional_severance  = spouse_additional_severance ? convertDecimal(spouse_additional_severance) : '';

    const response = await Protection.postUnemployment({...data, additional_severance, spouse_additional_severance});
    await dispatch(setSending({ isSending: false }));
    await dispatch(setIsOpen({ isOpen: true }))
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchBequeath = () => async (dispatch) => {
  try {
    const response = await Protection.getBequeath();
    await dispatch(setBequeath(response.data.data));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchBequeathById = id => async (dispatch) => {
  try {
    const response = await Protection.showBequeathDetails(id);
    await dispatch(setBequeath(response.data.data));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}


export const postBequeath = (data, props, id, isEdit) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    let response = null
    let { value } = data;
    value = convertDecimal(value);

    if (isEdit) {
      response = await Protection.postBequeathDetails(id, { ...data, value });
    } else {
      response = await Protection.postBequeath({ ...data, value });
    }

    await dispatch(fetchBequeath());
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
    await dispatch(setSending({ isSending: false }));
    await dispatch(() => props.history.push('/bequeath'));
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const removeBequeath = (id, props) => async (dispatch) => {
  try {
    const response = await Protection.removeBequeath(id);
    await dispatch(fetchBequeath());
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
    await dispatch(() => props.history.push('/bequeath'));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const deactivateBequeath = (props) => async (dispatch) => {
  try {
    await Protection.deactivate();
    await dispatch(() => props.history.push("/input"));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}


export const { setEstate, setUnemployment, setLoading, setBequeath } = protectionSlice.actions

export default protectionSlice.reducer