import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import SVGIcon from '../shared/SVGIcon/SVGIcon'
import Dropbox from '../../assets/images/icons/dropbox-small.svg'
import CheckIcon from '../../assets/images/icons/check-circle2.svg'
import InfoIcon from '../../assets/images/icons/info-circle.svg'

const NotificationCard = ({
  id,
  title,
  content,
  type,
  remove,
  onClick
}) => {
  return (
    <div className="notifications__card">
      <div className={`notifications__card-icon notifications__card-icon--${type}`}>
        <SVGIcon icon={(type === 'dropbox' && Dropbox)
                    || (type === 'complete' && CheckIcon)
                    || (type === 'info' && InfoIcon)}
        />
      </div>
      <div>
        <div className="notifications__card-title">{title}</div>
        {type === 'info' ? <>{content} <Link to="/tasks" onClick={onClick}>View</Link></> : content}
      </div>
      <button onClick={() => remove(id)} className="notifications__card-close">Close</button>
    </div>
  )
}

NotificationCard.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func
}

export default NotificationCard
