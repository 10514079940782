import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import { ChooseBox } from '../shared/Form'
import { Button } from '@mui/material'



const TaskAlert = ({ values, options, onSubmit }) => {
  const dispatch = useDispatch()
  const [sending, setSending] = useState(false)

  const onSave = async (values) => {
    await setSending(true)
    await dispatch(onSubmit(values))
    await setSending(false)
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={[...values]}
        onSubmit={onSave}
      >
        {({ errors, handleChange, handleBlur, values }) => (
          <Form>
            {values && values.map((item, i) =>
              <div key={item.id} className="mb--small">
                <ChooseBox
                  name={item.company_task ? `${i}.company_task.priority_id` : `${i}.priority_id`}
                  nameActive={`${i}.active`}
                  label={item.name}
                  options={options}
                  info={item.logic_text}
                  onChange={handleChange}
                  valueActive={item.active}
                  value={item.company_task ? item.company_task.priority_id.toString() : item.priority_id.toString()}
                />
              </div>
            )}

            <div className="form__button form__button--center">
              <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                Save
                {sending && <span className="spinner" />}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
)}

TaskAlert.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  status: PropTypes.string,
  category: PropTypes.string,
  priority: PropTypes.string,
  content: PropTypes.string,
  removeTask: PropTypes.func,
  updateTask: PropTypes.func,
  createTask: PropTypes.func,
  closeTask: PropTypes.func,
  handleClick: PropTypes.func,
  statusList: PropTypes.array,
  categoryList: PropTypes.array,
  priorityList: PropTypes.array
}

export default TaskAlert



