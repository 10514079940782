import React, { useState, useEffect, useRef } from 'react';
import clsx from 'classnames';
import LayoutAdmin from '../../components/Layout/LayoutAdmin';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Pagination } from '@mui/material';
import Loading from '../../components/shared/Loading/Loading';
import { FormGroup, Input, FormSelect } from '../../components/shared/Form';
import SVGIcon from '../../components/shared/SVGIcon/SVGIcon';
import More from '../../assets/images/icons/more.svg';
import Man from '../../assets/images/icons/user02.svg';
import Edit from '../../assets/images/icons/edit.svg';
import Trash from '../../assets/images/icons/trash.svg';
import ImageSearch from '../../assets/images/image-search.png';
import Modal from '../../components/shared/Modal/Modal';
import {
  assignPlanner,
  setSortPlanner,
  sendRequestLimitStatus,
  setActiveNumber,
  fetchPlannersList,
  setRequestLimitStatus,
  postLicenseLimit,
  removeMember,
  fetchMembersList,
  fetchCompany,
  postNotifyLicenseLimit,
  swapTokens
} from '../../redux/slices/companiesSlice';
import { fetchUserData } from '../../redux/slices/userSlice'
import { isNull, isEmpty } from 'lodash';
import SearchUserBox from '../../components/SearchBox/SearchUserBox';
import SearchIcon from '../../assets/images/icons/search.svg';


const relationshipType = [
  {
    id: 1,
    key: 1,
    value: '10',
    label: '10',
  },
  {
    id: 2,
    key: 2,
    value: '20',
    label: '20',
  },
  {
    id: 3,
    key: 3,
    value: '50',
    label: '50',
  },
];



const AdminCompanyMembers = ({ props, isAdmin }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, permissions, role_id } = useSelector((state) => state.user.user);
  const [isMobile, setIsMobile] = useState(false);
  const [isOpenDel, setOpenDel] = useState(false);
  const [isDelConfirm, setDelConfirm] = useState(false);
  const [inputVal, setInputVal] = useState('');
  const [isError, setError] = useState(true);
  const [isTextError, setTextError] = useState(false);
  const [page, setPage] = useState(1);
  const { members, pagination, companyLoading, isSending, requestLimitStatus, planners, activeNumber, sort_by_planner } = useSelector(
    (state) => state.companies
  );
  const seatsLimitColor = useSelector((state) => state.companies.company.info.seatsLimitColor);
  const companyInfo = useSelector((state) => state.companies.company.info);
  const [filters, setFilters] = useState({
    sort: 'name',
    order: 'asc',
    sort_by_planner_id: sort_by_planner?.id,
    size: '10',
  });
  const [targetUser, setTargetUser] = useState(null);
  const [isLicenseModal, setLicenseModal] = useState(false);
  const [isUpgradeModal, setUpgradeModal] = useState(false);
  const [licenseLimit, setLicenseLimit] = useState(null);
  const [plannersList, setPlannersList] = useState(null);
  const [isModalSearch, setModalSearch] = useState(false);
  const [isActiveItem, setActiveItem] = useState('');
  const [value, steValue] = useState('');
  const [isOpen, setOpen] = useState(false);
  const { companyId } = useParams();
  const popup = useRef();
  const dropMenu = useRef();

  const onToggle = () => setOpen(!isOpen);

  useEffect(() => {
    const listener = (event) => {
      if (!dropMenu.current || dropMenu.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [dropMenu]);


  const mobileView = 999;

  const handleResize = () => {
    mobileView >= window.innerWidth ? setIsMobile(true) : setIsMobile(false);
  };

  const onChangePage = (event, value) => setPage(value);
  

  useEffect(() => {
    if (!isNull(planners)) {
      setPlannersList(planners);
    }
    // eslint-disable-next-line
  }, [planners]);

  useEffect(() => {
    dispatch(fetchCompany(companyId));
    mobileView >= window.innerWidth && setIsMobile(true);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (inputVal === 'DELETE') {
      setError(false);
      setTextError(false);
    } else {
      setError(true);
    }
  }, [inputVal]);

  const handleBlur = () => {
    if (isError) {
      setTextError(true);
    }
  };

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchMembersList(companyId, { page, ...filters }));
      await dispatch(fetchPlannersList(companyId));
    }

    if (id && companyId && filters) {
      fetch();
    }
    // eslint-disable-next-line
  }, [id, companyId, page, filters]);

  useEffect(() => {
    sort_by_planner?.id && setFilters({
      ...filters,
      sort_by_planner_id: sort_by_planner?.id,
    });
    // eslint-disable-next-line
  }, [sort_by_planner]);

  const setSearch = (e) => {
    const serchValue = e.target.value;
    steValue(serchValue);

    if (serchValue.length > 0) {
      setPlannersList(
        planners.filter((item) =>
          `${item.first_name} ${item.last_name}`.toLowerCase().includes(serchValue.toLowerCase())
        )
      );
    } else {
      setPlannersList(planners);
    }
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.target.classList.contains('search') || event.target.classList.contains('injected-svg')) {
        return;
      }
      dispatch(setActiveNumber(''));
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
    // eslint-disable-next-line
  }, [popup]);

  const onUnassignPlanner = async (memberId, memberStatus) => {
    await dispatch(
      assignPlanner(companyId, {
        planner_id: '',
        member_id: memberId,
        member_confirmed: memberStatus,
      })
    );
    await dispatch(fetchMembersList(companyId));
  };

  const onSelect = async (plannerId) => {
    setTargetUser({
      ...targetUser,
      planner_id: plannerId
    })
  };

  const onHandlePlaner = (userId, userStatus) => {
    setTargetUser({
      id: userId,
      status: userStatus
    })
    setModalSearch(true)
  }

  const handleFakeLogin = async (companyId, memberId) => {
    await dispatch(swapTokens(companyId, memberId));
    await dispatch(fetchUserData());
    await history.push('/dashboard');
  }

  return (
    <LayoutAdmin companyId={companyId} isAdmin={isAdmin} scroll>
      {!companyInfo?.image ? (
        <Loading />
      ) : (
        <>
          <div className='member-comapny'>
            <div className='member-comapny__info'>
              <div className='member-comapny__logo'>
                <img src={companyInfo?.image} alt='Company logo' />
              </div>
              <div className='member-comapny__title'>
                <h3>{companyInfo?.name}</h3>
                {!isMobile && (
                  <p>
                    Feedback Email: <span>{companyInfo?.email}</span>
                  </p>
                )}
              </div>
              {permissions?.add_company_member.user && (
                <div className='task-card task-card--default more-menu'>
                  <div className='task-card__menu'>
                    <button className='task-card__toggler' onClick={onToggle}>
                      <SVGIcon icon={More} color='#8C8C8C' />
                    </button>
                    <div
                      ref={dropMenu}
                      className={`task-card__menu-drop${
                        isOpen ? ' task-card__menu-drop--open' : ''
                      }`}
                    >
                      <ul>
                        {permissions?.add_company_member.user &&
                          companyInfo?.max_seats !==
                            companyInfo?.current_members && (
                            <li>
                              <button
                                onClick={() =>
                                  history.push(
                                    `/admin/company/${companyId}/members/invite`
                                  )
                                }
                              >
                                Invite member
                              </button>
                            </li>
                          )}
                        {role_id < 3 && companyId > 1 && (
                          <li>
                            <button
                              onClick={() =>
                                history.push(
                                  `/admin/company/${companyId}/members/change-admin`
                                )
                              }
                            >
                              Change owner
                            </button>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {isMobile && (
              <div className='mobile-feedback'>
                Feedback Email: <span>{companyInfo?.email}</span>
              </div>
            )}
            <div className='member-comapny__buttons'>
              {permissions?.add_company_member.user &&
                companyInfo?.max_seats !== companyInfo?.current_members && (
                  <Button
                    variant='contained'
                    size='small'
                    color='primary'
                    onClick={() =>
                      history.push(`/admin/company/${companyId}/members/invite`)
                    }
                  >
                    Invite member
                  </Button>
                )}
              {role_id < 3 && companyId > 1 && (
                <Button
                  disabled={!permissions?.change_company_superadmin}
                  variant='outlined'
                  size='small'
                  color='primary'
                  onClick={() =>
                    history.push(
                      `/admin/company/${companyId}/members/change-admin`
                    )
                  }
                >
                  Change owner
                </Button>
              )}
            </div>
          </div>
          <div
            className={clsx('member-comapny__license', {
              'member-comapny__license--full': seatsLimitColor === 'yellow',
              'member-comapny__license--overfull': seatsLimitColor === 'red',
            })}
          >
            <div className='license-plan'>
              <div className='license-plan__icon'>
                <SVGIcon icon={Man} color='#fff' />
              </div>
              <div className='license-plan__desc'>
                <h6>License limit plan</h6>
                {seatsLimitColor === 'transparent' && (
                  <p>
                    Your license limit is {companyInfo?.max_seats} users now
                  </p>
                )}
                {seatsLimitColor === 'yellow' && (
                  <p>
                    You have almost reached the license limit of{' '}
                    {companyInfo?.max_seats} seats! Please upgrade your license
                    limit plan.
                  </p>
                )}
                {seatsLimitColor === 'red' && (
                  <p>
                    You can’t add members to your company anymore! You reached{' '}
                    {companyInfo?.max_seats} users license limit. Please upgrade
                    your license limit plan.
                  </p>
                )}
              </div>
            </div>
            {role_id > 1 && role_id < 4 && (
              <Button
                variant='outlined'
                size='small'
                color='primary'
                onClick={() => setUpgradeModal(true)}
              >
                Upgrade
              </Button>
            )}
          </div>
          <div className='member-comapny__license'>
            <div className='license-counts'>
              <div className='license-counts__item'>
                <h4>
                  {companyInfo?.current_members
                    ? companyInfo?.current_members
                    : 0}
                </h4>
                <p>Current members</p>
              </div>
              <div className='license-counts__item'>
                <h4>{companyInfo?.max_seats ? companyInfo.max_seats : 0}</h4>
                <p>License limit</p>
              </div>
            </div>
            {role_id === 1 && (
              <div className='license-buttons'>
                <Button
                  variant='outlined'
                  size='small'
                  color='primary'
                  onClick={() => dispatch(postNotifyLicenseLimit(companyId))}
                >
                  Notify
                </Button>
                <Button
                  variant='outlined'
                  size='small'
                  color='primary'
                  onClick={() => setLicenseModal(true)}
                >
                  Set limit
                </Button>
              </div>
            )}
          </div>
          <div className='member-comapny member-comapny__title'>
            <h4>Members</h4>
            <div className='member-comapny__buttons--wrap'>
            {role_id < 4 && (
              <div className='member-comapny__buttons--sort'>
                <span className='text-nowrap'>Filter by planner:</span>
                <button
                  onClick={() => dispatch(setActiveNumber('open'))}
                  className='member-comapny__sort'
                >
                  {!isNull(sort_by_planner) ? (
                    <span className='sort-title sort-title--blue'>
                      {sort_by_planner?.name}
                    </span>
                  ) : (
                    <span className='sort-title sort-title--arrow'>None</span>
                  )}
                </button>
                {sort_by_planner?.id > 0 && (
                  <button
                    className='reset-filter modal__close'
                    onClick={() => {
                      dispatch(setSortPlanner(null));
                      dispatch(setActiveNumber(''));
                    }}
                  />
                )}
                <SearchUserBox
                  filter
                  resSearch={popup}
                  open={activeNumber === 'open'}
                  setSearch={setSearch}
                  value={value}
                  result={plannersList}
                />
              </div>
            )}
            {pagination.total_pages > 1 && <div className='member-comapny__buttons--size'>
              <span className='text-nowrap'>Per page:</span>
              <FormSelect
                options={relationshipType}
                value={filters.size || ''}
                name='type'
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    size: e.target.value,
                  });
                }}
              />
            </div>}
            </div>
          </div>
          <div className='members'>
            <div className='members__table'>
              <ul className='members-column__name'>
                <button
                  onClick={() => {
                    setFilters({
                      ...filters,
                      sort: 'name',
                      order: filters.order === 'asc' ? 'desc' : 'asc',
                    });
                  }}
                  className='column__name titles titles-button'
                >
                  Name{' '}
                  <span
                    className={`title-arrow ${
                      filters.sort === 'name' && filters.order === 'desc'
                        ? 'title-arrow--rotate'
                        : ''
                    }`}
                  />
                </button>
                {!companyLoading && (
                  <>
                    {members &&
                      members.map((item, i) => (
                        <li
                          key={i}
                          style={{ animationDelay: 0.3 + i * 0.1 + 's' }}
                          className={`column__name ${
                            i === isActiveItem ? 'hover' : ''
                          }`}
                          onMouseEnter={() => setActiveItem(i)}
                          onMouseLeave={() => setActiveItem('')}
                        >
                          <div>
                            <div className='column__name-logo'>
                              {item?.avatar ? (
                                <img src={item.avatar} alt={item.name} />
                              ) : (
                                <SVGIcon icon={Man} color='#fff' />
                              )}
                            </div>
                            {item.first_name} {item.last_name}
                          </div>
                        </li>
                      ))}
                  </>
                )}
              </ul>
              <ul className='members-column__other'>
                <li className='column__other titles'>
                  <button
                    onClick={() => {
                      setFilters({
                        ...filters,
                        sort: 'date',
                        order: filters.order === 'asc' ? 'desc' : 'asc',
                      });
                    }}
                    className='column__other--date titles-button'
                  >
                    Date added{' '}
                    <span
                      className={`title-arrow ${
                        filters.sort === 'date' && filters.order === 'desc'
                          ? 'title-arrow--rotate'
                          : ''
                      }`}
                    />
                  </button>
                  <button
                    onClick={() => {
                      setFilters({
                        ...filters,
                        sort: 'last_login',
                        order: filters.order === 'asc' ? 'desc' : 'asc',
                      });
                    }}
                    className='column__other--date titles-button'
                  >
                    Last login{' '}
                    <span
                      className={`title-arrow ${
                        filters.sort === 'last_login' && filters.order === 'desc'
                          ? 'title-arrow--rotate'
                          : ''
                      }`}
                    />
                  </button>
                  <button
                    onClick={() => {
                      setFilters({
                        ...filters,
                        sort: 'role',
                        order: filters.order === 'asc' ? 'desc' : 'asc',
                      });
                    }}
                    className='column__other--role titles-button'
                  >
                    Role{' '}
                    <span
                      className={`title-arrow ${
                        filters.sort === 'role' && filters.order === 'desc'
                          ? 'title-arrow--rotate'
                          : ''
                      }`}
                    />
                  </button>
                  <button
                    onClick={() => {
                      setFilters({
                        ...filters,
                        sort: 'planner',
                        order: filters.order === 'asc' ? 'desc' : 'asc',
                      });
                    }}
                    className='column__other--planner titles-button'
                  >
                    Planner{' '}
                    <span
                      className={`title-arrow ${
                        filters.sort === 'planner' && filters.order === 'desc'
                          ? 'title-arrow--rotate'
                          : ''
                      }`}
                    />
                  </button>
                  <button
                    onClick={() => {
                      setFilters({
                        ...filters,
                        sort: 'status',
                        order: filters.order === 'asc' ? 'desc' : 'asc',
                      });
                    }}
                    className='column__other--status titles-button'
                  >
                    Status{' '}
                    <span
                      className={`title-arrow ${
                        filters.sort === 'status' && filters.order === 'desc'
                          ? 'title-arrow--rotate'
                          : ''
                      }`}
                    />
                  </button>
                  <div className='column__other--control titles' />
                </li>
                {!companyLoading && (
                  <>
                    {members &&
                      members.map((item, i) => (
                        <li
                          key={i}
                          style={{
                            animationDelay: 0.3 + i * 0.1 + 's',
                            zIndex: -i,
                          }}
                          className={`column__other ${
                            i === isActiveItem ? 'hover' : ''
                          }`}
                          onMouseEnter={() => setActiveItem(i)}
                          onMouseLeave={() => setActiveItem('')}
                        >
                          <div className='column__other--date'>{item.date}</div>
                          <div className='column__other--date'>{item.last_login}</div>
                          <div className='column__other--role'>
                            {item.role_name}
                          </div>
                          <div className='column__other--planner'>
                            {item.role_id !== 4 && (
                              <div className='planner-logo__wrap'>
                                {item.planner?.avatar ? (
                                  <div className='column__name-logo'>
                                    <img
                                      src={item.planner.avatar}
                                      alt={item.name}
                                    />
                                  </div>
                                ) : (
                                  <div className='column__name-logo column__name-logo--dashed'>
                                    <SVGIcon icon={Man} color='#8C8C8C' />
                                  </div>
                                )}
                                {isNull(item.planner) ? (
                                  <button
                                    disabled={
                                      role_id > 3 || !item.member_confirmed
                                    }
                                    onClick={() =>
                                      isMobile
                                        ? onHandlePlaner(
                                            item.id,
                                            item.member_confirmed
                                          )
                                        : dispatch(setActiveNumber(i))
                                    }
                                    className='logo__add'
                                  />
                                ) : (
                                  <button
                                    disabled={role_id > 3}
                                    onClick={() =>
                                      onUnassignPlanner(
                                        item.id,
                                        item.member_confirmed
                                      )
                                    }
                                    className='logo__delete'
                                  />
                                )}
                                {!isNull(item.planner) && (
                                  <div className='planner-name'>
                                    {item.planner.first_name}{' '}
                                    {item.planner.last_name}
                                  </div>
                                )}
                              </div>
                            )}
                            <SearchUserBox
                              resSearch={popup}
                              key={i}
                              open={activeNumber === i}
                              companyId={companyId}
                              memberId={item.id}
                              memberStatus={item.member_confirmed}
                              setSearch={setSearch}
                              value={value}
                              result={plannersList}
                            />
                          </div>
                          <div
                            className={`column__other--status ${
                              item.status === 'Active' ? 'status-blue' : ''
                            }`}
                          >
                            {item.status}
                          </div>
                          <div className='column__other--control'>
                            <Button
                              disabled={
                                !item.member_confirmed || item.id === id
                              }
                              variant='contained'
                              size='small'
                              color='primary'
                              onClick={() =>
                                handleFakeLogin(companyId, item.id)
                              }
                            >
                              Log in as user
                            </Button>
                            {role_id < 4 && (
                              <>
                                <button
                                  disabled={
                                    (!isNull(item.role_id) &&
                                      (role_id > 3 ||
                                        role_id > item.role_id)) ||
                                    (role_id === item.role_id &&
                                      item.id !== id) ||
                                    !item.member_confirmed
                                  }
                                  className='control-button'
                                  onClick={() =>
                                    history.push({
                                      pathname: `/admin/company/${companyId}/members/${item.id}/edit`,
                                      hash:
                                        !item.member_confirmed && 'unconfirmed',
                                    })
                                  }
                                >
                                  <SVGIcon icon={Edit} color='#8c8c8c' />
                                </button>
                                <button
                                  disabled={
                                    (!isNull(item.role_id) &&
                                      (role_id > 3 ||
                                        role_id > item.role_id)) ||
                                    (role_id === item.role_id &&
                                      item.id !== id) ||
                                    item.id === id
                                  }
                                  className='control-button'
                                  onClick={() => {
                                    setOpenDel(true);
                                    setTargetUser({
                                      id: item.id,
                                      name: `${item.first_name} ${item.last_name}`,
                                      image: item.avatar,
                                      status: item.member_confirmed,
                                    });
                                  }}
                                >
                                  <SVGIcon icon={Trash} color='#8c8c8c' />
                                </button>
                              </>
                            )}
                          </div>
                        </li>
                      ))}
                  </>
                )}
              </ul>
            </div>
            {companyLoading && <Loading small />}
            {members && members.length < 1 && (
              <div className='search member-comapny__search'>
                <div className='search__empty'>
                  <div className='search__empty-image'>
                    <img src={ImageSearch} alt='No results found' />
                  </div>
                  <div className='search__empty-text'>
                    <h5>No members yet</h5>
                    <p>
                      Click the button below to invite members to your company.
                    </p>
                    <Button
                      disabled={!permissions?.add_company_member.user}
                      variant='contained'
                      size='large'
                      color='primary'
                      onClick={() =>
                        history.push(
                          `/admin/company/${companyId}/members/invite`
                        )
                      }
                    >
                      Invite member
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
          {pagination.total_pages > 1 && (
            <Pagination
              size={isMobile ? 'small' : 'medium'}
              count={pagination.total_pages}
              onChange={onChangePage}
            />
          )}
          <Modal
            open={isOpenDel}
            small
            inline
            reverse
            removeButton='Yes'
            button='No'
            onClick={() => setOpenDel(false)}
            remove={() => {
              setOpenDel(false);
              setDelConfirm(true);
            }}
            close={() => setOpenDel(false)}
          >
            <div className='company__modal'>
              <h3>Are you sure you want to delete this user?</h3>
              <p>
                Deleting the user will delete all the user's information
                associated to this company.
              </p>
              <div className='company__modal-icon'>
                {!isNull(targetUser?.image) ? (
                  <img src={targetUser?.image} alt='Avatar' />
                ) : (
                  <SVGIcon icon={Man} color='#fff' />
                )}
              </div>
              <h6>{targetUser?.name}</h6>
            </div>
          </Modal>
          <Modal
            open={isDelConfirm}
            button='Delete'
            onClick={async () => {
              await dispatch(
                removeMember(
                  companyId,
                  targetUser?.id,
                  targetUser?.status && 'confirmed'
                )
              );
              await dispatch(fetchMembersList(companyId, { ...filters }));
              setInputVal('');
              await setDelConfirm(false);
            }}
            close={() => setDelConfirm(false)}
            isDisabled={isError}
          >
            <div className='company__modal'>
              <h3>Are you sure you want to delete this user?</h3>
              <p>
                Deleting the user will delete all the user's information
                associated to this company. This cannot be undone after you type
                DELETE in the field below.
              </p>
              <div className='company__modal-icon'>
                {!isNull(targetUser?.image) ? (
                  <img src={targetUser?.image} alt='Avatar' />
                ) : (
                  <SVGIcon icon={Man} color='#fff' />
                )}
              </div>
              <h6>{targetUser?.name}</h6>
              <FormGroup name='confirm-text' labelText='Enter'>
                <Input
                  name='confirm-text'
                  placeholder='Enter DELETE'
                  type='text'
                  onChange={(e) => {
                    setInputVal(e.target.value);
                  }}
                  value={inputVal || ''}
                  onBlur={handleBlur}
                  hasError={isTextError}
                />
                {isTextError && (
                  <span className='form__msg-error'>
                    Please check entered text!
                  </span>
                )}
              </FormGroup>
            </div>
          </Modal>
          <Modal
            open={isLicenseModal}
            small
            button='Save'
            isDisabled={
              isNull(licenseLimit) ||
              licenseLimit < companyInfo?.current_members
            }
            onClick={async () => {
              await dispatch(
                postLicenseLimit(companyId, {
                  licence_limit: licenseLimit,
                })
              );
              await dispatch(fetchCompany(companyId));
              setLicenseModal(false);
            }}
            close={() => setLicenseModal(false)}
          >
            <div className='company__modal'>
              <h3>Set the limit of members for this company</h3>
              <FormGroup name='licence_limit' labelText='License limit'>
                <Input
                  name='licence_limit'
                  placeholder='Enter text'
                  type='number'
                  onChange={(e) => {
                    setLicenseLimit(e.target.value);
                  }}
                  value={licenseLimit || ''}
                  onBlur={handleBlur}
                />
                {!isEmpty(licenseLimit) &&
                  licenseLimit < companyInfo?.current_members && (
                    <span className='form__msg-error'>
                      License limit must be greater than or equal to the current
                      number of registered members.
                    </span>
                  )}
              </FormGroup>
            </div>
          </Modal>
          <Modal
            open={isUpgradeModal}
            small
            button='Request'
            isLoading={isSending}
            onClick={async () => {
              await dispatch(sendRequestLimitStatus(companyId));
              await setUpgradeModal(false);
            }}
            close={() => setUpgradeModal(false)}
          >
            <div className='company__modal'>
              <h3>Upgrade your license limit plan</h3>
              <p>
                Click the button below to request additional member licenses to
                ValueSoft for your company.
              </p>
              <div className='company__modal-icon'>
                <img
                  src={companyInfo?.image}
                  alt={companyInfo?.name ?? 'Logo'}
                />
              </div>
              <h6>{companyInfo?.name}</h6>
            </div>
          </Modal>
          <Modal
            open={requestLimitStatus}
            small
            button='Go to members'
            onClick={() => dispatch(setRequestLimitStatus(false))}
            close={() => dispatch(setRequestLimitStatus(false))}
            title='Success!'
            children={
              <p>
                Thank you for your license increase request. A ValueSoft
                representative will contact your company’s ValueSoft
                administrator shortly.
              </p>
            }
          />
          <Modal
            open={isModalSearch}
            small
            button='Apply'
            onClick={async () => {
              await dispatch(
                assignPlanner(companyId, {
                  planner_id: targetUser.planner_id,
                  member_id: targetUser.id,
                  member_confirmed: targetUser.status,
                })
              );
              await dispatch(fetchMembersList(companyId));
              await setTargetUser(null);
              await setModalSearch(false);
            }}
            close={() => setModalSearch(false)}
            isDisabled={!targetUser?.planner_id}
          >
            <div className='company__modal--search'>
              <h3>Add planner</h3>
              <p>Choose the user you want to assign.</p>
              <div>
                <div className='search-user__box'>
                  <input
                    type='text'
                    placeholder='Search'
                    value={value}
                    className='search-user__input'
                    onChange={setSearch}
                  />
                  {!value && <SVGIcon icon={SearchIcon} color='#8c8c8c' />}
                  <ul className='search-user__result'>
                    {plannersList && plannersList.length === 0 ? (
                      <li className='search-user__result-item'>No result</li>
                    ) : (
                      plannersList &&
                      plannersList.map((item, i) => (
                        <li
                          key={i}
                          style={{ animationDelay: 0.3 + i * 0.1 + 's' }}
                          className={`search-user__result-item ${
                            targetUser?.planner_id === item.id
                              ? ' search-user__result-item--target'
                              : ''
                          }`}
                        >
                          <button onClick={() => onSelect(item.id)}>
                            <div className='result-item__logo'>
                              {item?.avatar ? (
                                <img src={item.avatar} alt={item.name} />
                              ) : (
                                <SVGIcon icon={Man} color='#fff' />
                              )}
                            </div>
                            {item.first_name} {item.last_name}
                          </button>
                        </li>
                      ))
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
    </LayoutAdmin>
  );
};

export default AdminCompanyMembers;
