const BASE_URL = process.env.REACT_APP_API_URL
const AUTH = `${BASE_URL}/auth`
const USER = `${BASE_URL}/user`
const COMPANIES = `${BASE_URL}/companies`
const RISK = `${BASE_URL}/risk`
const DEPENDETNS = `${BASE_URL}/dependents`
const USERCOMPANIES = `${BASE_URL}/user-companies`
const EDUCATIONS = `${BASE_URL}/educations`
const FUTURE_CHILDREN = `${BASE_URL}/future`
const INCOMES = `${BASE_URL}/incomes`
const EXPENSES = `${BASE_URL}/user/expenses`
const INCREMENTAL = `${BASE_URL}/user/incremental`
const TASKS = `${BASE_URL}/user/tasks`
const INSURANCE = `${BASE_URL}/insurance`
const STOCK = `${BASE_URL}/stock`
const BLOG = `${BASE_URL}/blog/posts`
const ADMIN = `${BASE_URL}/admin`
const ANALYSIS = `${BASE_URL}/analysis`


export {
  BASE_URL,
  AUTH,
  USER,
  COMPANIES,
  RISK,
  DEPENDETNS,
  USERCOMPANIES,
  EDUCATIONS,
  FUTURE_CHILDREN,
  EXPENSES,
  INCOMES,
  TASKS,
  INSURANCE,
  INCREMENTAL,
  STOCK,
  BLOG,
  ADMIN,
  ANALYSIS
}