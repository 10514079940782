import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Routers from './routers'
import './assets/styles/main.scss'
import { fetchUserData } from './redux/slices/userSlice'
import { fetchPrioritiesData, fetchPlanTypesData } from './redux/slices/optionsSlice'
import Loading from './components/shared/Loading/Loading'
import { fetchCompanyPermissions } from './redux/slices/companiesSlice';
import { getAuthInfo } from './redux/slices/authSlice';
require ('./components/RoundedBar')

function App() {
  const dispatch = useDispatch();
  const token = localStorage.token;
  const loading = useSelector(state => state.auth.isLoading);
  const isAdmin = useSelector(state => state.user.user.is_admin);
  const { company_id } = useSelector(state => state.user.user);

  useEffect(() => {
    dispatch(getAuthInfo());
  }, [dispatch])

  useEffect(() => {
    async function fetchOptions() {
      await dispatch(fetchUserData());
      await dispatch(fetchPlanTypesData());
      await dispatch(fetchPrioritiesData());
    }
    if(token) {
      fetchOptions();
    }
    //new line
    // eslint-disable-next-line
  }, [token])

  useEffect(() => {
    async function fetchPermissions() {
      await dispatch(fetchCompanyPermissions(company_id))
    }
    if(company_id){
      fetchPermissions()
    }
    // eslint-disable-next-line
  }, [company_id])

  if (loading) {
    return <Loading />
  }

  return (
    <Routers isAdmin={isAdmin} />
  );
}

export default App;
