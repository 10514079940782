import * as Yup from 'yup'

export const sabbaticalSchema = Yup.object().shape({
  planning_sabbatical: Yup.boolean().required('Required'),
  age_sabbatical: Yup.number().integer('Please check this value').nullable().max(100, 'Age must be less')
    .when('planning_sabbatical', {
      is: true,
      then: Yup.number().required('Required')
    }),
  sabbatical_last: Yup.number().nullable().max(100, 'Please check this value')
    .when('planning_sabbatical', {
      is: true,
      then: Yup.number().required('Required')
    }),
  salary_percentage: Yup.string().nullable().max(8, 'Please check this value')
    .when('planning_sabbatical', {
      is: true,
      then: Yup.string().required('Required')
    }),
  workforce_percent: Yup.string().nullable().max(8, 'Please check this value')
    .when('planning_sabbatical', {
      is: true,
      then: Yup.string().required('Required')
    }),
  current_refund: Yup.string().nullable().max(8, 'Please check this value')
    .when('planning_sabbatical', {
      is: true,
      then: Yup.string().required('Required')
    })
});


export const objectiveSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  type: Yup.string().required('Required'),
  down_payment: Yup.string().nullable().max(8, 'Please check this value').notRequired(),
  age_when_purchased: Yup.number().integer('Please check this value').nullable().max(100, 'Age must be less').required('Required'),
  value_purchased: Yup.string().nullable().max(18, 'Please check this value').required('Required'),
  category: Yup.string().nullable().required('Required'),
  liquidated_asset: Yup.boolean().required('Required'),
  age_when_liquidated: Yup.number().nullable().max(150, 'Age must be less').notRequired(),
  estimated_value: Yup.string().nullable().max(18, 'Please check this value').notRequired(),
});


export const retirementSchema = Yup.object().shape({
  contribution_401k: Yup.string().nullable().max(18, 'Please check this value, must be less').notRequired(),
  roth_401k: Yup.string().nullable().max(18, 'Please check this value, must be less').notRequired(),
  roth_ira: Yup.string().nullable().max(18, 'Please check this value, must be less').notRequired(),
  traditional_ira: Yup.string().nullable().max(18, 'Please check this value, must be less').notRequired(),
  simple_ira: Yup.string().nullable().max(18, 'Please check this value, must be less').notRequired(),
  solo_401k: Yup.string().nullable().max(18, 'Please check this value, must be less').notRequired(),
})