import React from 'react'
import PropTypes from 'prop-types'

const ValuesCard = ({ title, icon, content, label }) => (
  <div className="values-card">
    <div className="values-card__icon">
      <img src={icon} alt={title} />
    </div>
    <div>
      <div className="values-card__title">{title}</div>
      <div className="values-card__content">{content}</div>
      <div className="values-card__info">
        Requires Money (over $5000): {label ? <span className="values-card__label">Yes</span> : <span className="values-card__label values-card__label--alt">No</span>}
      </div>
    </div>
  </div>
)

ValuesCard.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  icon: PropTypes.string,
  label: PropTypes.bool
};

export default ValuesCard;
