import React, { useEffect, useState, useMemo } from 'react';
import { Form, Formik } from 'formik';
import { useParams } from 'react-router-dom';
import LayoutAdmin from '../../components/Layout/LayoutAdmin';
import debounce from 'lodash/debounce';
import { useSelector, useDispatch } from 'react-redux';
import { Button, TextareaAutosize, FormControlLabel, Checkbox } from '@mui/material';
import { FormGroup, FormSelect, Input } from '../../components/shared/Form';
import SVGIcon from '../../components/shared/SVGIcon/SVGIcon';
import { fetchCategoriesData } from '../../redux/slices/optionsSlice';
import { fetchTaskDetails, postAdminTask, updateTaskDetails, fetchTasksVariables } from '../../redux/slices/taskSlice';
import { fetchCompany } from '../../redux/slices/companiesSlice';
import { AdminTaskCreateSchema } from '../../utils/validation';
import SearchIcon from '../../assets/images/icons/search.svg';
import VariableCard from '../../components/Cards/VariableCard';
import { BackButton } from '../../components/shared/Buttons';

const AdminEditTask = ({ props, isAdmin, isEdit }) => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const { id, permissions } = useSelector((state) => state.user.user);
  const { image, email, name } = useSelector((state) => state.companies.company.info);
  const { current, variables, taskLoading } = useSelector((state) => state.task);
  const { priorities, categories } = useSelector((state) => state.options);
  const { companyId, taskId } = useParams();
  const [isChecked, setChecked] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  const mobileView = 999;

  const handleResize = () => {
    mobileView >= window.innerWidth ? setIsMobile(true) : setIsMobile(false);
  };

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(fetchCompany(companyId));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchTaskDetails(companyId, taskId, isEdit));
    }

    async function fetchOptions() {
      await dispatch(fetchCategoriesData());
      await dispatch(
        fetchTasksVariables({
          q: searchValue,
        })
      );
    }

    if (id) {
      fetchOptions();
    }

    if (id && taskId) {
      fetch();
    }
    // eslint-disable-next-line
  }, [id]);

  const setSearch = async (e) => {
    const { value } = e.target;
    setSearchValue(value.toLowerCase());

    return debounce(async () => {
      await dispatch(
        fetchTasksVariables({
          q: value,
        })
      );
    }, 500)();
  };

  const variablesList = useMemo(() => variables && variables.map((item) => {
      return <VariableCard key={item.id} name={item.name} tooltip={item.tooltip} />
    }), [variables])

  const addVariables = (arg) => (
    <div className={`admin-tasks__side admin-tasks__side${arg ? '--mobile' : '--desktop'}`}>
      <div className="admin__side-sticky">
        <h6>Add variables</h6>
        <div className="admin__search-box">
          <SVGIcon icon={SearchIcon} color="#8C8C8C" />
          <input
            placeholder="Search"
            type="text"
            value={searchValue}
            className="admin__search-box__input"
            onChange={(e) => setSearch(e)}
          />
        </div>
      </div>
      {variablesList && (
        <div className="admin__side-holder">
          <div className="admin__side-inner">
            {variablesList}
          </div>
        </div>
      )}
    </div>
  );

  return (
    <LayoutAdmin companyId={companyId} isAdmin={isAdmin} admin>
      <div className="member-comapny">
        <div className="member-comapny__info">
          <div className="member-comapny__logo">
            <img src={image} alt="Company logo" />
          </div>
          <div className="member-comapny__title">
            <h3>{name}</h3>
            {!isMobile && (
              <p>
                Feedback Email: <span>{email}</span>
              </p>
            )}
          </div>
        </div>
        {isMobile && (
          <div className="mobile-feedback">
            Feedback Email: <span>{email}</span>
          </div>
        )}
      </div>
      <BackButton title="Tasks" path={`/admin/company/${companyId}/tasks`} className="back-button" />
      <div className="admin-tasks">
        <div className="admin-tasks__content">
          <Formik
            enableReinitialize
            initialValues={
              isEdit
                ? {
                    name: current.name,
                    description: current.description,
                    category_id: current.category_id,
                    priority_id: current.priority_id,
                    active: current.active,
                    condition: current.condition,
                  }
                : {
                    name: '',
                    description: '',
                    category_id: null,
                    priority_id: null,
                    active: true,
                    condition: '',
                  }
            }
            validationSchema={AdminTaskCreateSchema}
            onSubmit={
              isEdit
                ? (values, { setErrors }) =>
                    dispatch(updateTaskDetails(companyId, taskId, values, props, isAdmin, setErrors))
                : (values, { setErrors }) => dispatch(postAdminTask(companyId, values, props, setErrors))
            }
          >
            {({ errors, handleChange, handleBlur, values, touched, setFieldValue }) => (
              <Form>
                <h5>{isEdit ? 'Update task' : 'Create a new task'}</h5>
                <FormGroup name="name" labelText="Title">
                  <Input
                    name="name"
                    placeholder="Type title task"
                    type="text"
                    onChange={handleChange}
                    value={values.name || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.name && touched.name}
                  />
                  {touched.name && errors.name && <span className="form__msg-error">{errors.name}</span>}
                </FormGroup>
                <FormGroup name="description" labelText="Task description">
                  <TextareaAutosize
                    name="description"
                    value={values.description || ''}
                    className="form__textarea form__textarea--alt"
                    placeholder="Type description"
                    onChange={handleChange}
                  />
                  {errors.description && <span className="form__msg-error">{errors.description}</span>}
                </FormGroup>
                <FormGroup name="category_id" labelText="Category task">
                  <FormSelect
                    options={categories}
                    value={values.category_id || ''}
                    name="category_id"
                    onChange={handleChange}
                    placeholder="Select category"
                    hasError={!!errors.category_id}
                  />
                  {errors.category_id && <span className="form__msg-error">{errors.category_id}</span>}
                </FormGroup>
                <FormGroup name="priority_id" labelText="Priority task">
                  <FormSelect
                    options={priorities}
                    value={values.priority_id || ''}
                    name="priority_id"
                    onChange={handleChange}
                    placeholder="Select priority"
                    hasError={!!errors.priority_id}
                  />
                  {errors.priority_id && <span className="form__msg-error">{errors.priority_id}</span>}
                </FormGroup>
                <FormGroup name="active">
                  <FormControlLabel
                    className="MuiFormControlLabel-root"
                    control={
                      <Checkbox
                        checked={isEdit ? !values.active : !isChecked}
                        value={isEdit ? !values.active : isChecked}
                        onChange={() => {
                          isEdit ? setFieldValue('active', !values.active) : setChecked(!isChecked);
                        }}
                        name="active"
                      />
                    }
                    label={<>Deactivate task</>}
                  />
                </FormGroup>
                <FormGroup name="condition" labelText="Condition">
                  <TextareaAutosize
                    name="condition"
                    placeholder="E.g.: CURRENT_INCOME > OLD_INCOME"
                    type="text"
                    onChange={handleChange}
                    value={values.condition || ''}
                    onBlur={handleBlur}
                    className="form__textarea form__textarea--alt form__textarea--resize"
                  />
                  {errors.condition && <span className="form__msg-error">{errors.condition}</span>}
                </FormGroup>
                {addVariables('mob')}
                <div className="form__button">
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    type="submit"
                    disabled={taskLoading || !permissions?.manage_company_task}
                  >
                    Save
                    {taskLoading && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {addVariables()}
      </div>
    </LayoutAdmin>
  );
};

export default AdminEditTask;
