import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../../components/Layout/Layout'
import { BackButton } from '../../components/shared/Buttons'
import Tooltip from '../../components/shared/Tooltip/Tooltip'
import { Slider, Input, FormGroup, FormSelect, InputCurrency } from '../../components/shared/Form'
import PercentIcon from '../../assets/images/icons/percentage.svg'
import { Button } from '@mui/material'
import ModalBox from '../../components/shared/Modal/Modal'
import {
  fetchCalculators,
  fetchListResults,
  fetchCurrentResult,
  saveItemResult,
  removeItemResult, setCurrentCalculator
} from '../../redux/slices/calculatorSlice'
import {convertDecimal, PMTFormula} from '../../utils/utils'
import SpeedometerLimit from '../../components/shared/Speedometer/SpeedometerLimit'
import { fetchHomeRefinancingAdmin } from '../../redux/slices/calculatorsSetupSlice'

const HomeRefinancing = ({ props }) => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [isSavedModal, setIsSavedModal] = useState(false)
  const { id, company_id } = useSelector(state => state.user.user)
  const { calculators, current, listExisting, loading } = useSelector(state => state.calculator)
  const baseValues = useSelector(state => state.calculatorsSetup.homeRefinancingAdmin);
  const [currentId, setCurrentId] = useState('')
  const [values, setValues] = useState(null)

  useEffect(() => {
    if(baseValues){
      setValues({
        currentId: '',
        name: '',
        ...baseValues
      })
    }
  // eslint-disable-next-line
  }, [baseValues])

  const [countValues, setCountValues] = useState({
    preTaxBreakevenMonth: 0,
    afterTaxBreakevenMonth: 0,
    breakevenCapitalized: 0
  })

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchHomeRefinancingAdmin(company_id))
      await dispatch(fetchCalculators({
        path: 'home-refinancing'
      }));
      await dispatch(fetchListResults('home-refinancing'))
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    if (values) {
      let months = values.refinance_years * 12
    let closingCostsPoints = values.closing_costs + (values.points/100) * values.loan_amount
    // let rateDifference = values.current_interest_rate/100 - values.refinancing_interest_rate/100

    // let afterTaxRatePreRefi = values.current_interest_rate/100 * (1 - values.marginal_tax_rate/100)
    // let afterTaxRateRefi    = values.refinancing_interest_rate/100 * (1 - values.marginal_tax_rate/100)

    let monthlyPMTPreRefi     = -(PMTFormula((values.current_interest_rate/100)/12, months, values.loan_amount))
    let monthlyPMTRefi        = -(PMTFormula((values.refinancing_interest_rate/100)/12, months, values.loan_amount))

    let interestPreRefi     = values.current_interest_rate/100 * values.loan_amount/12
    let interestRefi        = values.refinancing_interest_rate/100 * values.loan_amount/12

    let monthlyPMTDifference         = monthlyPMTPreRefi - monthlyPMTRefi
    let afterTaxMonthlyPMTDifference = monthlyPMTDifference - (interestPreRefi - interestRefi) * (values.marginal_tax_rate/100)

    let preTaxBreakevenMonth   = closingCostsPoints/monthlyPMTDifference
    let afterTaxBreakevenMonth = closingCostsPoints/afterTaxMonthlyPMTDifference

    let balancePreRefi            = values.loan_amount
    let balanceRefi               = values.loan_amount + closingCostsPoints

    let cumulativeInterestPreRefi = 0
    let cumulativeInterestRefi    = 0

    let principalPaydownPreRefi   = 0
    let principalPaydownRefi      = 0

    let monthlyPMTRefiUpdated     = -(PMTFormula((values.refinancing_interest_rate/100)/12, months, balanceRefi))

    let breakevenCapitalized = 0;

    [...Array(361)].find((u, i) => {
      balanceRefi    = balanceRefi - principalPaydownRefi
      balancePreRefi = balancePreRefi - principalPaydownPreRefi

      cumulativeInterestRefi    = cumulativeInterestRefi + balanceRefi * (values.refinancing_interest_rate/100)/12
      cumulativeInterestPreRefi = cumulativeInterestPreRefi + balancePreRefi * (values.current_interest_rate/100)/12

      principalPaydownRefi      = monthlyPMTRefiUpdated - (balanceRefi * (values.refinancing_interest_rate/100)/12)
      principalPaydownPreRefi   = monthlyPMTPreRefi - (balancePreRefi * (values.current_interest_rate/100)/12)

      let adjustedPrincipal     = balanceRefi - ((cumulativeInterestPreRefi - cumulativeInterestRefi)*(1 - values.marginal_tax_rate/100))

      if((adjustedPrincipal - balancePreRefi) <= 0) {
        return breakevenCapitalized = i+1
      }
      return false
    })

    setCountValues({
      preTaxBreakevenMonth: +preTaxBreakevenMonth.toFixed(0),
      afterTaxBreakevenMonth: +afterTaxBreakevenMonth.toFixed(0),
      breakevenCapitalized

    })
    }
    // eslint-disable-next-line
  }, [values])

  useEffect(() => {
    async function fetchNewData() {
      if (current) {
        setValues({
          name: current ? current.name : '',
          currentId: currentId,
          loan_amount: +current.loan_amount,
          refinance_years: +current.refinance_years,
          marginal_tax_rate: +current.marginal_tax_rate,
          closing_costs: +current.closing_costs,
          points: +current.points,
          current_interest_rate: +current.current_interest_rate,
          refinancing_interest_rate: +current.refinancing_interest_rate
        })
      }
    }

    if (currentId) {
      fetchNewData()
    }
    // eslint-disable-next-line
  }, [currentId, current])

  const handleInputChange = e => {
    setValues({
      ...values,
      [e.target.name]: +e.target.value
    })
  }

  const handleCurrencyChange = e => {
    setValues({
      ...values,
      [e.target.name]: +convertDecimal(e.target.value)
    })
  }

  const changeCurrentData = async e => {
    await setCurrentId(e.target.value)
    await dispatch(fetchCurrentResult(e.target.value, 'home-refinancing'))
  }

  const onSave = async () => {
    dispatch(saveItemResult(values, 'home-refinancing')).then(res => {
      const calculator = res?.data?.data;

      if(calculator) {
        setIsSavedModal(true);
        setCurrentId(calculator.id);
        dispatch(setCurrentCalculator(calculator));
      }
    }).finally(() => {
      setIsOpen(false);
    })
  }

  const resetForm = () => {
    setValues({
      currentId: '',
      name: '',
      ...baseValues
    })
    setCurrentId('')
  }

  const removeResult = async () => {
    await dispatch(removeItemResult(currentId, 'home-refinancing'))
    await setCurrentId('')
    await setIsDeleteModal(false)
    await setValues({
      ...baseValues
    })
  }

  return (
    <Layout decor>
      <BackButton title="Back to Calculators" path="/calculators" className="back-button--position" />
      <div className="calculators__title">
        <div>
          {calculators && <h4>{calculators.name}</h4>}
          {calculators &&
          <Tooltip
            title={
              <>
                <h6>{calculators.name}</h6>
                {calculators.tooltip}
                <hr className="tooltip__line"/>
                <h6>Example</h6>
                {calculators.example}
              </>
            }
          />}
        </div>

        {listExisting && listExisting.length > 0 && <div className="calculators__title-select">
          <FormSelect
            options={listExisting}
            id="currentId"
            value={currentId}
            medium
            name="currentId"
            onChange={changeCurrentData}
            placeholder="Select result"
          />
        </div>}
      </div>
    {values &&
      <div className="calculators__holder">
        <div className="calculators__content">
          <h6 className="calculators__subtitle">Step 1: Input Loan Terms</h6>
          <div className="calculators__area">
            <InputCurrency
              name="loan_amount"
              value={values.loan_amount.toString() ||''}
              small
              smallHeight
              onChange={handleCurrencyChange}
              withoutSign
              iconLeft
            />
            <div className="calculators__area-slider">
              <Slider
                max={2000000}
                step={1000}
                label="Loan amount"
                value={+values.loan_amount}
                onChange={(e, newValue) => setValues({...values, loan_amount: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <Input
              type="number"
              name="refinance_years"
              value={values.refinance_years.toString()}
              small
              smallHeight
              onChange={handleInputChange}
            />
            <div className="calculators__area-slider">
              <Slider
                max={30}
                step={1}
                label="Refinance years"
                value={+values.refinance_years}
                onChange={(e, newValue) => setValues({...values, refinance_years: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <Input
              type="number"
              name="marginal_tax_rate"
              value={values.marginal_tax_rate.toString()}
              small
              smallHeight
              step={0.1}
              onChange={handleInputChange}
              iconName={PercentIcon}
            />
            <div className="calculators__area-slider">
              <Slider
                max={100}
                step={0.1}
                label="Marginal tax rate"
                value={+values.marginal_tax_rate}
                onChange={(e, newValue) => setValues({...values, marginal_tax_rate: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <InputCurrency
              name="closing_costs"
              value={values.closing_costs.toString() ||''}
              small
              smallHeight
              onChange={handleCurrencyChange}
              withoutSign
              iconLeft
            />
            <div className="calculators__area-slider">
              <Slider
                max={25000}
                step={10}
                label="Closing costs"
                value={+values.closing_costs}
                onChange={(e, newValue) => setValues({...values, closing_costs: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <Input
              type="number"
              name="points"
              value={values.points.toString()}
              small
              smallHeight
              step={0.1}
              onChange={handleInputChange}
              iconName={PercentIcon}
            />
            <div className="calculators__area-slider">
              <Slider
                max={100}
                step={0.1}
                label="Points"
                value={+values.points}
                onChange={(e, newValue) => setValues({...values, points: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <Input
              type="number"
              name="current_interest_rate"
              value={values.current_interest_rate.toString()}
              small
              smallHeight
              step={0.1}
              onChange={handleInputChange}
              iconName={PercentIcon}
            />
            <div className="calculators__area-slider">
              <Slider
                max={100}
                step={0.1}
                label="Current interest rate"
                value={+values.current_interest_rate}
                onChange={(e, newValue) => setValues({...values, current_interest_rate: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <Input
              type="number"
              name="refinancing_interest_rate"
              value={values.refinancing_interest_rate.toString()}
              small
              smallHeight
              step={0.1}
              onChange={handleInputChange}
              iconName={PercentIcon}
            />
            <div className="calculators__area-slider">
              <Slider
                max={100}
                step={0.1}
                label="Refinancing interest rate"
                value={+values.refinancing_interest_rate}
                onChange={(e, newValue) => setValues({...values, refinancing_interest_rate: newValue})}
              />
            </div>
          </div>
        </div>
        <div className="calculators__preview">
          <h6 className="calculators__subtitle">Steps 2: Refinance Breakeven results</h6>
          <div className="calculators__preview-box">
            <div className="calculators__cases">
              <div>
                <SpeedometerLimit
                  limit={[25, 60]}
                  count={countValues.preTaxBreakevenMonth}
                  large
                  label
                />
                <div className="calculators__case-title">
                  Pre-tax breakeven months (out of pocket costs)
                </div>
              </div>
              <div>
                <SpeedometerLimit
                  limit={[25, 60]}
                  count={countValues.afterTaxBreakevenMonth}
                  large
                  label
                />
                <div className="calculators__case-title">
                  After-tax breakeven months (out of pocket costs)
                </div>
              </div>
              <div>
                <SpeedometerLimit
                  limit={[25, 60]}
                  count={countValues.breakevenCapitalized}
                  large
                  label
                />
                <div className="calculators__case-title">
                  After-tax breakeven months (capitalized costs)
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
      <div className="calculators__buttons">
        <Button variant="contained" size="large" color="primary" onClick={() => setIsOpen(true)}>Save result</Button>
        {currentId && <Button variant="contained" size="large"  onClick={() => setIsDeleteModal(true)}>Remove result</Button>}
        <Button size="large" className="MuiButton-textGray" onClick={() => resetForm()}>Reset</Button>
      </div>

      <ModalBox
        open={isOpen}
        close={() => setIsOpen(false)}
        onClick={onSave}
        button="Save"
        title="Save Result"
        isLoading={loading}
        isDisabled={values && !values.name && !values.currentId}
      >
        <FormGroup name="name" labelText="Name">
          <Input
            name='name'
            value={values ? values.name : ''}
            placeholder="Type a name of result"
            onChange={e => setValues({
              ...values,
              name: e.target.value
            })}
          />
        </FormGroup>
        {listExisting && listExisting.length > 0 &&
        <>
          <p className="text-center">or</p>
          <FormGroup labelText="Select  Existing Result">
            <FormSelect
              options={listExisting}
              value={values ? values.currentId : ''}
              name="currentId"
              onChange={e => setValues({
                ...values,
                currentId: e.target.value,
                name: listExisting.find(i => i.value === e.target.value).label
              })}
              placeholder="Select Existing Result"
            />
          </FormGroup>
        </>
        }
      </ModalBox>
      <ModalBox
        small
        open={isDeleteModal}
        close={() => setIsDeleteModal(false)}
        onClick={removeResult}
        button="Remove"
        title={`Do you want to remove ${current && current.name}?`}
      />

      <ModalBox
        small
        open={isSavedModal}
        close={() => setIsSavedModal(false)}
        button="Ok"
        onClick={() => setIsSavedModal(false)}
      >
        <div>
          {calculators && calculators.iconLeft &&
          <div className="modal__title-iconLeft">
            <img src={calculators.iconLeft} alt={calculators.name} />
          </div>}
          <h4>Your calculator scenario has been saved.</h4>
          <p>You can access your saved scenarios through the “Select result” dropdown menu at the top of each calculator page.</p>
        </div>
      </ModalBox>
    </Layout>
  )
}

export default HomeRefinancing
