import React from 'react'
import WrapWidget from './WrapWidget'
import TaskAnalysisCard from '../Analysis/TaskAnalysisCard'

const WidgetTasksAnalysis = ({ openTasks, agingTasks, totalTasks, className, addClassName, limits }) => {

  // const count = (total, current) => {
  //   if (total < current) return 100
  //   if (total === 0 || current === 0) {
  //     return '0'
  //   } else {
  //     return current * 100/total
  //   }
  // }

  return (
    <WrapWidget
        className={className}
        title="Analyze tasks"
        addClassName={addClassName}
        link={{
          path: '/tasks-analysis',
          title: 'View all'
        }}
    >
      {limits && limits.length > 0 &&
      <div className="widget__list">
        <div>
          <TaskAnalysisCard path="/tasks-analysis"
                            title="All open tasks by owner"
                            tasks={openTasks || '0'}
                            //count={count(totalTasks, openTasks)}
                            limits={limits}
          />
        </div>
        <div>
          <TaskAnalysisCard path="/tasks-analysis"
                            title="Aging tasks"
                            tasks={agingTasks || '0'}
                            //count={count(openTasks, agingTasks)}
                            limits={limits}
                            tooltip
          />
        </div>
      </div>
      }
    </WrapWidget>
  );
};

export default WidgetTasksAnalysis;
