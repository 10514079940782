const typeIncremental = [
  {
    key: 'one-time-gift',
    label: 'One-Time Gift',
    value: 'one-time-gift',
  },
  {
    key: 'one-time-win',
    label: 'One-Time Winnings',
    value: 'one-time-win',
  },
  {
    key: 'inheritance',
    label: 'Inheritance',
    value: 'inheritance',
  },
  {
    key: 'other-one-time-income',
    label: 'Other One-Time Income',
    value: 'other-one-time-income',
  },
  {
    key: 'wedding-expense',
    label: 'Wedding Expense',
    value: 'wedding-expense',
  },
  {
    key: 'honeymoon-expense',
    label: 'Honeymoon Expense',
    value: 'honeymoon-expense',
  },
  {
    key: 'bar-mitzvah-expense',
    label: 'Bar Mitzvah Expense',
    value: 'bar-mitzvah-expense',
  },
  {
    key: 'other-celebration-expense',
    label: 'Other Celebration Expense',
    value: 'other-celebration-expense',
  },
  {
    key: 'lifetime-vacation-expense',
    label: 'Lifetime Vacation Expense',
    value: 'lifetime-vacation-expense',
  },
  {
    key: 'charitable-gift-expense',
    label: 'Charitable Gift Expense',
    value: 'charitable-gift-expense',
  },
  {
    key: 'major-home-renovation-expense',
    label: 'Major Home Renovation Expense',
    value: 'major-home-renovation-expense',
  },
  {
    key: 'other-one-time-expense',
    label: 'Other One-Time Expense',
    value: 'other-one-time-expense',
  }
]

export default typeIncremental;