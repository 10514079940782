import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import { BackButton } from '../../../components/shared/Buttons'
import Layout from '../../../components/Layout/Layout'
import PageTitle from '../../../components/PageTitle/PageTitle'
import LayoutForm from '../../../components/Layout/LayoutForm'
import { FormSelect } from '../../../components/shared/Form'
import { Button } from '@mui/material'
import { fetchValues, postValues } from '../../../redux/slices/userSlice'
import { fetchValuesData } from '../../../redux/slices/optionsSlice'
import ranking from '../../../Data/ranking'
import Priority from '../../../components/shared/Priority/Priority'
import { valuesSchema } from '../../../utils/validation'
import Modal from '../../../components/shared/Modal/Modal'
import isEmpty from 'lodash/isEmpty'
import ProgressCircle from '../../../components/shared/ProgressBar/ProgressCircle'
import {setIsOpen} from '../../../redux/slices/modalSlice'

const titleUser = {
  title: 'Your values',
  content: 'ValueCircle categorizes your value system into 6 distinct categories. Fill in the Current Ranking column with the relative importance of each of these 6 values to you in your current lifistyle.',
  info:
    <>
      <h6>Your Values</h6>
      <Priority option="Medium" />
      <div>ValueCircle categorizes your value system into 6 distinct categories: security, obligation, power/prestige, happiness, health, and making a difference.  Fill in the Current Ranking column with the relative importance of each of these 6 values to you in your current lifestyle.  Then fill out the Preferred Ranking column with what you wish that ranking would be. Finally give an example or two of what you think that value means in terms of some of your financial or life objectives.</div>
    </>
}

const titleSpouse = {
  title: 'Spouse’s Values',
  content: 'ValueCircle categorizes your value system into 6 distinct categories. Then fill out the “Preferred Ranking” column with what your spouse wishes that ranking be.',
  info:
    <>
      <h6>Spouse’s Values</h6>
      <Priority option="Medium" />
      <div>ValueCircle categorizes your spouse's value system into 6 distinct categories: security, obligation, power/prestige, happiness, health, and making a difference.  Fill in the 'Current Ranking' column with the relative importance of each of these 6 values to your spouse in his/her current lifestyle. Then fill out the 'Preferred Ranking' column with what your spouse wishes that ranking would be.</div>
    </>
}

const ValueDetails = ({ props, isSpouse }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.user.user);
  const values = useSelector(state => state.user.values);
  const valuesSpouse = useSelector(state => state.user.spouse.values);
  const listValues = useSelector(state => state.options.values);
  const sending = useSelector(state => state.sending.isSending);
  const isOpen = useSelector(state => state.modal.isOpen);

  useEffect(() => {
    async function fetchOptions() {
      await dispatch(fetchValuesData());
    }

    async function fetch() {
      await dispatch(fetchValues());
    }

    async function fetchSpouse() {
      await dispatch(fetchValues(isSpouse));
    }

    if (id) {
      fetchOptions()

      if (isSpouse) {
        fetchSpouse()
      }

      fetch()
    }
    // eslint-disable-next-line
  }, [id])


  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }))
  }

  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }));
    history.push('/values')
  }

  return (
    <Layout>
      <LayoutForm>
        <BackButton title="Back" />
        <PageTitle
          title={isSpouse ? titleSpouse.title : titleUser.title}
          content={isSpouse ? titleSpouse.content : titleUser.content}
          info={isSpouse ? titleSpouse.info : titleUser.info}
        />
        <div className="layout-form__content-holder container container--medium">
          <Formik
            enableReinitialize
            validationSchema={valuesSchema}
            initialValues={{values: isSpouse ? valuesSpouse : values }}
            onSubmit={(values) => dispatch(postValues(values.values, props, listValues, isSpouse))}
          >
            {({ errors, values, setFieldValue, totalRankCurrent, totalRankPreferred }) => {
              const arrOfValues = values.values

              if(!isEmpty(arrOfValues)){
                totalRankCurrent = arrOfValues.map(item => item.current_ranking).reduce((previousValue, currentValue) => previousValue + currentValue);
                totalRankPreferred = arrOfValues.map(item => item.preferred_ranking).reduce((previousValue, currentValue) => previousValue + currentValue);
              }

              return (
                <Form>
                  <div className="values__table values__table--edit">
                    <div className="values__table-head">
                      <div>Value</div>
                      <div>Current Ranking (1-6)</div>
                      <div>Preferred Ranking (1-6)</div>
                    </div>
                    {values.values && values.values.map((item, i) => (
                      <div className="values__table-row" key={item.id}>
                        <div>{item.name}</div>
                        <div>
                          <FormSelect
                            small
                            style={{'width': '100px'}}
                            options={ranking}
                            value={item.current_ranking || ''}
                            name={`values.${i}.current_ranking`}
                            onChange={(e) => {
                              setFieldValue(e.target.name, e.target.value)
                            }}
                            placeholder="Select"
                          />
                        </div>
                        <div>
                          <FormSelect
                            small
                            style={{'width': '100px'}}
                            options={ranking}
                            value={item.preferred_ranking || ''}
                            name={`values.${i}.preferred_ranking`}
                            onChange={(e) => {
                              setFieldValue(e.target.name, e.target.value)
                            }}
                            placeholder="Select"
                          />
                        </div>
                      </div>
                    ))}
                    {errors && errors.values 
                      ? <div className="form__msg-error">All fields must be filled!</div> 
                        : totalRankCurrent !== 21 && totalRankCurrent > 0
                          ? <div className="form__msg-error">Duplicate scores for values of Current Ranking are prohibited.  Please provide unique scores for each value.</div>
                            : totalRankPreferred !== 21 && totalRankPreferred > 0
                              ? <div className="form__msg-error">Duplicate scores for values of Preferred Ranking are prohibited.  Please provide unique scores for each value.</div>
                                : ''
                    }
                    <div className="form__button form__button--center">
                      <Button variant="contained" size="large" color="primary" type="submit" disabled={sending || totalRankCurrent !== 21 || totalRankPreferred !== 21}>
                        Save
                        {sending && <span className="spinner" />}
                      </Button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>

        <Modal
          open={isOpen}
          small
          button="Done"
          onClick={handleClick}
          close={onClose}
        >
          <h3>Good Job!</h3>
          <p>Your progress increased 100%</p>
          <ProgressCircle amount={100} small />
        </Modal>
      </LayoutForm>
    </Layout>
  );
};

export default ValueDetails;
