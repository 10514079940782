import React from 'react'
import PropTypes from 'prop-types'
import Logo from '../shared/Logo/Logo'

const AuthContent = ({ children, decor, close, back, top }) => {

  return (
    <div className="auth">
      <Logo className={`auth__logo ${back ? 'auth__logo--center' : ''}`}/>
      {close && <a href={close} className="auth__close">close</a>}
      <div className="auth__content">
        <div className={`auth__side ${top ? 'auth__side--top' : ''}`}>
          <div className="auth__form">
            {children}
          </div>
        </div>
        <div className="auth__image">
          <img src={decor.image} alt={decor.title || 'image description'} />
        </div>
      </div>
    </div>
  )
};

AuthContent.propTypes = {
  children: PropTypes.any,
  decor: PropTypes.object,
  close: PropTypes.string,
  back: PropTypes.bool
};

export default AuthContent
