import { createSlice } from '@reduxjs/toolkit'


const loadingSlice = createSlice({
  name: 'loading',
  initialState: {
    isLoading: false,
    isRelogin: false
  },
  reducers: {
    setIsLoading (state, action) {
      state.isLoading = action.payload.isLoading
    },
    setIsRelogin (state, action) {
      state.isRelogin = action.payload.isRelogin
    }
  }
})

export const { setIsLoading, setIsRelogin } = loadingSlice.actions
export default loadingSlice.reducer
