import { createSlice } from '@reduxjs/toolkit'
import { Calculator } from '../../api/Api'
import { createDynamicOptions } from '../../utils/utils'
import { setIsRelogin } from './loadingSlice'

const calculatorSlice = createSlice({
  name: 'calculator',
  initialState: {
    loading: false,
    calculators: null,
    pagination: null,
    current: null,
    listExisting: null,
    calculatorCategories: null
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.loading = action.payload
    },
    setCalculators: (state, action) => {
      state.calculators = action.payload
    },
    setPagination: (state, action) => {
      state.pagination = action.payload
    },
    setCurrentCalculator: (state, action) => {
      state.current = action.payload
    },
    setListExisting: (state, action) => {
      state.listExisting = action.payload
    },
    setСalculatorCategories: (state, action) => {
      state.calculatorCategories = action.payload
    }
  }
})

export const fetchCalculatorCategories = (params, props) => async (dispatch) => {
  try{
    await dispatch(setIsLoading(true));
    const response = await Calculator.getCategories(params);
    await dispatch(setСalculatorCategories(response.data.data));
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchCalculators = (params, props) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    const response = await Calculator.get(params);
    await dispatch(setCalculators(response.data.data));
    await dispatch(setPagination(response.data.meta));
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchListResults = name => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    let response = null

    switch(name) {
      case 'impact-of-income-and-net-worth':
        response = await Calculator.getImpactIncome();
        break

      case 'mortgage':
        response = await Calculator.getMortgage();
        break

      case 'home-affordability':
        response = await Calculator.getHomeAffordability();
        break

      case 'own-vs-rent-home':
        response = await Calculator.getOwnVsRent();
        break

      case 'time-to-buy-home':
        response = await Calculator.getTimeBuyHome();
        break

      case 'home-evaluation':
        response = await Calculator.getHomeEvaluation();
        break

      case 'home-refinancing':
        response = await Calculator.getHomeRefinancing();
        break

      case 'car-financing-payment':
        response = await Calculator.getCarFinancingPayment();
        break

      case 'car-lease-payment':
        response = await Calculator.getCarLeasePayment();
        break

      case 'new-car-lease-vs-lease-end':
        response = await Calculator.getNewCar();
        break

      case 'healthcare-coverage-type':
        response = await Calculator.getHealthCare();
        break

      case 'investment-fees':
        response = await Calculator.getInvestmentFee();
        break

      case 'life-insurance':
        response = await Calculator.getLifeInsurance();
        break

      case 'umbrella-insurance':
        response = await Calculator.getUmbrellaInsurance();
        break

      case 'value-circle':
        response = await Calculator.getValueCircle();
        break

      default:
        await dispatch(setIsLoading(false));
        break
    }

    const options = await createDynamicOptions(response.data.data);
    await dispatch(setListExisting(options));
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchCurrentResult = (id, name) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    let response = null

    switch(name) {
      case 'impact-of-income-and-net-worth':
        response = await Calculator.showImpactIncome(id);
        break

      case 'mortgage':
        response = await Calculator.showMortgage(id);
        break

      case 'home-affordability':
        response = await Calculator.showHomeAffordability(id);
        break

      case 'own-vs-rent-home':
        response = await Calculator.showOwnVsRent(id);
        break

      case 'time-to-buy-home':
        response = await Calculator.showTimeBuyHome(id);
        break

      case 'home-evaluation':
        response = await Calculator.showHomeEvaluation(id);
        break

      case 'home-refinancing':
        response = await Calculator.showHomeRefinancing(id);
        break

      case 'car-financing-payment':
        response = await Calculator.showCarFinancingPayment(id);
        break

      case 'car-lease-payment':
        response = await Calculator.showCarLeasePayment(id);
        break

      case 'new-car-lease-vs-lease-end':
        response = await Calculator.showNewCar(id);
        break

      case 'healthcare-coverage-type':
        response = await Calculator.showHealthCare(id);
        break

      case 'investment-fees':
        response = await Calculator.showInvestmentFee(id);
        break

      case 'life-insurance':
        response = await Calculator.showLifeInsurance(id);
        break

      case 'umbrella-insurance':
        response = await Calculator.showUmbrellaInsurance(id);
        break

      case 'value-circle':
        response = await Calculator.showValueCircle(id);
        break

      default:
        await dispatch(setIsLoading(false));
        break
    }

    await dispatch(setCurrentCalculator(response.data.data));
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const saveItemResult = (data, name) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    const { currentId } = data
    let response = null;

    switch(name) {
      case 'impact-of-income-and-net-worth':
        if (currentId) {
          response = await Calculator.putImpactIncome(currentId, data);
        } else {
          response = await Calculator.postImpactIncome(data);
        }
        break

      case 'mortgage':
        if (currentId) {
          response = await Calculator.putMortgage(currentId, data);
        } else {
          response = await Calculator.postMortgage(data);
        }
        break

      case 'home-affordability':
        if (currentId) {
          response = await Calculator.putHomeAffordability(currentId, data);
        } else {
          response = await Calculator.postHomeAffordability(data);
        }
        break

      case 'own-vs-rent-home':
        if (currentId) {
          response = await Calculator.putOwnVsRent(currentId, data);
        } else {
          response = await Calculator.postOwnVsRent(data);
        }
        break

      case 'time-to-buy-home':
        if (currentId) {
          response = await Calculator.putTimeBuyHome(currentId, data);
        } else {
          response = await Calculator.postTimeBuyHome(data);
        }
        break

      case 'home-evaluation':
        if (currentId) {
          response = await Calculator.putHomeEvaluation(currentId, data);
        } else {
          response = await Calculator.postHomeEvaluation(data);
        }
        break

      case 'home-refinancing':
        if (currentId) {
          response = await Calculator.putHomeRefinancing(currentId, data);
        } else {
          response = await Calculator.postHomeRefinancing(data);
        }
        break

      case 'car-financing-payment':
        if (currentId) {
          response = await Calculator.putCarFinancingPayment(currentId, data);
        } else {
          response = await Calculator.postCarFinancingPayment(data);
        }
        break

      case 'car-lease-payment':
        if (currentId) {
          response = await Calculator.putCarLeasePayment(currentId, data);
        } else {
          response = await Calculator.postCarLeasePayment(data);
        }
        break

      case 'new-car-lease-vs-lease-end':
        if (currentId) {
          response = await Calculator.putNewCar(currentId, data);
        } else {
          response = await Calculator.postNewCar(data);
        }
        break

      case 'healthcare-coverage-type':
        if (currentId) {
          response = await Calculator.putHealthCare(currentId, data);
        } else {
          response = await Calculator.postHealthCare(data);
        }
        break

      case 'investment-fees':
        if (currentId) {
          response = await Calculator.putInvestmentFee(currentId, data);
        } else {
          response = await Calculator.postInvestmentFee(data);
        }
        break

      case 'life-insurance':
        if (currentId) {
          response = await Calculator.putLifeInsurance(currentId, data);
        } else {
          response = await Calculator.postLifeInsurance(data);
        }
        break

      case 'umbrella-insurance':
        if (currentId) {
          response = await Calculator.putUmbrellaInsurance(currentId, data);
        } else {
          response = await Calculator.postUmbrellaInsurance(data);
        }
        break

      case 'value-circle':
        if (currentId) {
          response = await Calculator.putValueCircle(currentId, data);
        } else {
          response = await Calculator.postValueCircle(data);
        }
        break

      default:
        await dispatch(setIsLoading(false));
        break
    }

    await dispatch(fetchListResults(name));
    await dispatch(setIsLoading(false));

    return Promise.resolve(response);
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
    return Promise.reject(e)
  }
}

export const removeItemResult = (id, name) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));

    switch(name) {
      case 'impact-of-income-and-net-worth':
        await Calculator.deleteImpactIncome(id);
        break

      case 'mortgage':
        await Calculator.deleteMortgage(id);
        break

      case 'home-affordability':
        await Calculator.deleteHomeAffordability(id);
        break

      case 'own-vs-rent-home':
        await Calculator.deleteOwnVsRent(id);
        break

      case 'time-to-buy-home':
        await Calculator.deleteTimeBuyHome(id);
        break

      case 'home-evaluation':
        await Calculator.deleteHomeEvaluation(id);
        break

      case 'home-refinancing':
        await Calculator.deleteHomeRefinancing(id);
        break

      case 'car-financing-payment':
        await Calculator.deleteCarFinancingPayment(id);
        break

      case 'car-lease-payment':
        await Calculator.deleteCarLeasePayment(id);
        break

      case 'new-car-lease-vs-lease-end':
        await Calculator.deleteNewCar(id);
        break

      case 'healthcare-coverage-type':
        await Calculator.deleteHealthCare(id);
        break

      case 'investment-fees':
        await Calculator.deleteInvestmentFee(id);
        break

      case 'life-insurance':
        await Calculator.deleteLifeInsurance(id);
        break

      case 'umbrella-insurance':
        await Calculator.deleteUmbrellaInsurance(id);
        break

      case 'value-circle':
        await Calculator.deleteValueCircle(id);
        break

      default:
        await dispatch(setIsLoading(false));
        break
    }

    await dispatch(fetchListResults(name));
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const {
  setIsLoading,
  setCalculators,
  setСalculatorCategories,
  setCurrentCalculator,
  setListExisting,
  setPagination
} = calculatorSlice.actions

export default calculatorSlice.reducer