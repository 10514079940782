import React from "react";
import clsx from 'classnames';
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";

const ProfileTaskCard = (
  {
    name,
    accent,
    nameDescription,
    title,
    titleDescription,
    selected,
    number_of_tasks,
    number_of_weeks,
    canCancel,
    onChange,
    onCancel
  }) => {

  const classes = clsx("profile__schedule-item", {
    "profile__schedule-item_selected": selected,
    "profile__schedule-item_accent": accent
  })

  return (
    <div className={classes}>
      <div>
        <div className="profile__schedule-item__title">
          {name}
        </div>
      </div>
      <div className="profile__schedule-item__content">
        <p className="profile__schedule-item__subtitle">{nameDescription}</p>
        <p className="text-base typography_gutter_bottom">{title}</p>
        <p className="typography__caption profile__schedule-item__description">{titleDescription}</p>
        <div className="profile__schedule-item__numbers">
          <p className="typography_gutter_bottom">Number of tasks: {number_of_tasks}</p>
          <p className="typography_gutter_bottom">Number of weeks: {number_of_weeks}</p>
        </div>
      </div>
      <div className="profile__schedule-item__button-wrap">
        {(selected && canCancel) && <span className="text-link"><CheckIcon/> Activated</span>}
        <Button disabled={selected && !canCancel} variant="outlined" className="profile__schedule-item__button"
                size="large" fullWidth onClick={selected ? onCancel : onChange}>
          {(selected && !canCancel) ? 'Selected' : selected ? 'Cancel' : 'Select'}
        </Button>
      </div>
    </div>
  )
};

ProfileTaskCard.propTypes = {
  accent: PropTypes.bool,
  selected: PropTypes.bool,
  name: PropTypes.string,
  nameDescription: PropTypes.string,
  title: PropTypes.string,
  titleDescription: PropTypes.string,
  number_of_tasks: PropTypes.number,
  number_of_weeks: PropTypes.number,
}

export default ProfileTaskCard;