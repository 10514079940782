import React, { useState, useEffect } from "react";
import LayoutAdmin from "../../components/Layout/LayoutAdmin";
import { Form, Formik } from "formik";
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Input, FormGroup, FormSelect } from "../../components/shared/Form";
import { InfoCompanySchema } from "../../utils/validation";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import UploadCompanyPhoto from "../../components/shared/UploadPhoto/UploadCompanyPhoto";
import { setUploading, removeCompany, updateCompany,fetchCompany, updateCompanyLogo } from '../../redux/slices/companiesSlice';
import { setIsOpen } from '../../redux/slices/modalSlice'
import { Button, FormControl } from "@mui/material";
import Loading from '../../components/shared/Loading/Loading'
import SVGIcon from "../../components/shared/SVGIcon/SVGIcon";
import Icon from '../../assets/images/icons/icon-ongratulations.svg'
import Camera from '../../assets/images/icons/camera.svg'
import Trash from '../../assets/images/icons/trash.svg'
import Modal from '../../components/shared/Modal/Modal'
import { isEmpty, isNull } from "lodash";
import states from '../../Data/states';

const AdminCompanyDetails = ({ props, isAdmin }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { uploadingAvatar, nameAvatar } = useSelector((state) => state.companies);
  const companyAvatar = useSelector((state) => state.companies.avatarURL);
  const currentCompany = useSelector((state) => state.companies.company.info)
  const isCompanyLoad = useSelector((state) => state.companies.companyLoading);
  const userId = useSelector(state => state.user.user.id);
  const { permissions, company_id } = useSelector(state => state.user.user);
  const sending = useSelector((state) => state.sending.isSending);
  const isOpen = useSelector(state => state.modal.isOpen);
  const [isOpenDel, setOpenDel] = useState(false)
  const [isDelConfirm, setDelConfirm] = useState(false)
  const [inputVal, setInputVal] = useState('')
  const [isError, setError] = useState(true)
  const [isTextError, setTextError] = useState(false)
  const { companyId }  = useParams();

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchCompany(companyId));
    }

    if (userId && companyId) {
      fetch()
    }

    // eslint-disable-next-line
  }, [userId, companyId])

  useEffect(() => {
    if(inputVal === 'DELETE') {
      setError(false)
      setTextError(false)
    } else {
      setError(true)
    }

  }, [inputVal])

  const handleBlur = () => {
    if(isError) {
      setTextError(true)
    }
  }

  useEffect(() => {
    const updateLogo = async () => {
      let formImgData = new FormData();
      const bolbFile = await fetch(companyAvatar).then(res => res.blob())
      let imgFile = new File([bolbFile], nameAvatar, {type: 'image/jpg'})

      if (imgFile) {
        formImgData.append('image', imgFile)
        await dispatch(updateCompanyLogo(companyId,formImgData, props))
      }
    }
    if(!isEmpty(companyAvatar)) {
      updateLogo()
    }
    
    // eslint-disable-next-line
  }, [companyAvatar])

  const onSubmit = async (values, setErrors) => {
    let formData = new FormData();

    formData.append('name', values.name)
    formData.append('website', values.website)
    formData.append('vsft_name', values.vsft_name)
    formData.append('ein', values.ein)
    formData.append('email', values.email)
    formData.append('address', values.address)
    formData.append('optional_address', !isNull(values.optional_address) ? values.optional_address : "")
    formData.append('city', values.city)
    formData.append('state', values.state)
    formData.append('zip', values.zip)

    dispatch(updateCompany(companyId, formData, props, setErrors))
  };

  if (!permissions?.ap_view_company_list && permissions?.view_company && (+companyId !== +company_id)) return (<Redirect to="/404" />)

  return (
    <LayoutAdmin companyId={companyId} isAdmin={isAdmin}>
      {isCompanyLoad ? (
        <Loading />
      ) : (
        <div className="company">
          <div className="comapny__form comapny__logo comapny__logo--details">
            <div style={{ position: 'relative' }}>
              <button type="button" onClick={() => dispatch(setUploading(true))} className="comapny__form--avatar">
                {!isEmpty(currentCompany.image) ? (
                  <>
                    <div style={{ width: '100%', height: '100%' }}>
                      <img src={!isEmpty(companyAvatar) ? companyAvatar : currentCompany.image} alt="Company logo" />
                    </div>
                    <span className="upload-photo__button">
                      <SVGIcon icon={Camera} color="#fff" />
                    </span>
                  </>
                ) : (
                  <CameraAltOutlinedIcon style={{ color: '#8C8C8C' }} />
                )}
              </button>
            </div>
            <div className="comapny__form--avatar-btn details">
              <h3>{currentCompany.name}</h3>
            </div>
            {permissions?.ap_delete_company && companyId !== '1' && (
              <button className="delete-btn" onClick={() => setOpenDel(true)}>
                <SVGIcon icon={Trash} color="#8c8c8c" />
              </button>
            )}
          </div>
          <Formik
            enableReinitialize
            initialValues={currentCompany}
            validationSchema={InfoCompanySchema}
            onSubmit={(values, { setErrors }) => onSubmit(values, setErrors)}
          >
            {({ errors, handleChange, handleBlur, values, touched, setFieldValue }) => (
              <Form>
                <h5 className="mt--medium">About company</h5>
                <div className="comapny__form">
                  <FormGroup name="name" labelText="Full Company name">
                    <Input
                      name="name"
                      placeholder="Enter full company name"
                      type="text"
                      onChange={handleChange}
                      value={values.name || ''}
                      onBlur={handleBlur}
                      hasError={!!errors.name && touched.name}
                      disabled={!permissions?.edit_company_field.name}
                    />
                    {touched.name && errors.name && <span className="form__msg-error">{errors.name}</span>}
                  </FormGroup>
                  <FormGroup name="website" labelText="Company website">
                    <Input
                      name="website"
                      placeholder="Enter a link for a company website"
                      type="text"
                      onChange={handleChange}
                      value={values.website || ''}
                      onBlur={handleBlur}
                      hasError={!!errors.website && touched.website}
                      disabled={!permissions?.edit_company_field.website}
                    />
                    {touched.website && errors.website && <span className="form__msg-error">{errors.website}</span>}
                  </FormGroup>
                </div>
                <h5 className="mt--medium">Additional Information</h5>
                <div className="comapny__form">
                  <FormGroup name="vsft_name" labelText="Company's desired display name">
                    <Input
                      name="vsft_name"
                      placeholder="Enter your company displayed name"
                      type="text"
                      onChange={handleChange}
                      value={values.vsft_name || ''}
                      onBlur={handleBlur}
                      hasError={!!errors.vsft_name && touched.vsft_name}
                      disabled={!permissions?.edit_company_field.vsft_name}
                    />
                    {touched.vsft_name && errors.vsft_name && (
                      <span className="form__msg-error">{errors.vsft_name}</span>
                    )}
                  </FormGroup>
                  <FormGroup name="ein" labelText="Employer Identification number (EIN)">
                    <Input
                      name="ein"
                      placeholder="Enter EIN"
                      type="text"
                      onChange={handleChange}
                      value={values.ein || ''}
                      onBlur={handleBlur}
                      hasError={!!errors.ein && touched.ein}
                      disabled={!permissions?.edit_company_field.ein}
                    />
                    {touched.ein && errors.ein && <span className="form__msg-error">{errors.ein}</span>}
                  </FormGroup>
                  <FormGroup
                    name="email"
                    labelText="Feedback email"
                    info={{
                      content: (
                        <>
                          <div>
                            Feedback email is used for any inquiries a user has as they are using the ValueSoft
                            application. Typically it would be the email of a ValueSoft administrator in your company.
                          </div>
                        </>
                      ),
                    }}
                  >
                    <Input
                      name="email"
                      placeholder="example@gmail.com"
                      type="text"
                      onChange={handleChange}
                      value={values.email || ''}
                      onBlur={handleBlur}
                      hasError={!!errors.email && touched.email}
                      disabled={!permissions?.edit_company_field.email}
                    />
                    {touched.email && errors.email && <span className="form__msg-error">{errors.email}</span>}
                  </FormGroup>
                </div>
                <h5 className="mt--medium">Address</h5>
                <div className="comapny__form">
                  <FormGroup name="address" labelText="Address">
                    <Input
                      placeholder="Enter full adress"
                      name="address"
                      onChange={handleChange}
                      value={values.address || ''}
                      onBlur={handleBlur}
                      hasError={!!errors.address && touched.address}
                      disabled={!permissions?.edit_company_field.address}
                    />
                    {touched.address && errors.address && <span className="form__msg-error">{errors.address}</span>}
                  </FormGroup>
                  <FormGroup name="optional_address" labelText="Apt/Suite/Unit/Number (optional)">
                    <Input
                      name="optional_address"
                      placeholder="Enter Apt/Suite/Unit/Number"
                      type="text"
                      onChange={handleChange}
                      value={!isNull(values.optional_address) ? values.optional_address : ''}
                      onBlur={handleBlur}
                      hasError={!!errors.optional_address && touched.optional_address}
                      disabled={!permissions?.edit_company_field.address}
                    />
                    {touched.optional_address && errors.optional_address && (
                      <span className="form__msg-error">{errors.optional_address}</span>
                    )}
                  </FormGroup>
                  <FormGroup name="city" labelText="City">
                    <Input
                      name="city"
                      placeholder="Enter city"
                      type="text"
                      onChange={handleChange}
                      value={values.city || ''}
                      onBlur={handleBlur}
                      hasError={!!errors.city && touched.city}
                      disabled={!permissions?.edit_company_field.address}
                    />
                    {touched.city && errors.city && <span className="form__msg-error">{errors.city}</span>}
                  </FormGroup>
                  <FormControl disabled={!permissions?.edit_company_field.address}>
                    <FormGroup name="state" labelText="State">
                      <FormSelect
                        options={states}
                        id="state"
                        value={values.state || ''}
                        name="state"
                        onChange={(e) => {
                          setFieldValue(e.target.name, e.target.value);
                        }}
                        placeholder="Select state"
                        hasError={!!errors.state && touched.state}
                      />
                      {touched.state && errors.state && <span className="form__msg-error">{errors.state}</span>}
                    </FormGroup>
                  </FormControl>
                  <FormGroup name="zip" labelText="ZIP code">
                    <Input
                      name="zip"
                      placeholder="Enter ZIP code"
                      type="text"
                      onChange={handleChange}
                      value={values.zip || ''}
                      onBlur={handleBlur}
                      hasError={!!errors.zip && touched.zip}
                      disabled={!permissions?.edit_company_field.address}
                    />
                    {touched.zip && errors.zip && <span className="form__msg-error">{errors.zip}</span>}
                  </FormGroup>
                </div>
                <div className="form__button">
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    type="submit"
                    disabled={sending || !permissions?.edit_company}
                  >
                    Save
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          <Modal open={uploadingAvatar} close={() => dispatch(setUploading(false))} customClass="upload-modal__content">
            <div className="comapny__form--modal">
              <h3>Upload logo</h3>
              <p>
                Upload an images only with transparent background in PNG, JPG, JPEG formats with size 300x300 px and up
                to 3 MB.
              </p>
              <UploadCompanyPhoto />
            </div>
          </Modal>
          <Modal
            open={isOpenDel}
            small
            inline
            reverse
            removeButton="Yes"
            button="No"
            onClick={() => setOpenDel(false)}
            remove={() => {
              setOpenDel(false);
              setDelConfirm(true);
            }}
            close={() => setOpenDel(false)}
          >
            <div className="company__modal">
              <h3>Are you sure you want to delete this company?</h3>
              <p>
                Deleting the company will delete all the company's information and all information of administrators,
                planners, and users associated to this company.
              </p>
              <div className="company__modal-icon">
                <img src={currentCompany?.image} alt={currentCompany?.name ?? 'Logo'} />
              </div>
              <h6>{currentCompany?.name}</h6>
            </div>
          </Modal>
          <Modal
            open={isDelConfirm}
            button="Delete"
            onClick={async () => {
              await dispatch(removeCompany(currentCompany?.id));
              await setDelConfirm(false);
              await history.push("/admin/companies");
            }}
            close={() => setDelConfirm(false)}
            isDisabled={isError}
          >
            <div className="company__modal">
              <h3>Are you sure you want to delete this company?</h3>
              <p>
                {' '}
                Deleting the company will delete all the company's information and all information of administrators,
                planners, and users associated to this company. This cannot be undone after you type DELETE in the field
                below.
              </p>
              <div className="company__modal-icon">
                <img src={currentCompany?.image} alt={currentCompany?.name ?? 'Logo'} />
              </div>
              <h6>{currentCompany?.name}</h6>
              <FormGroup name="confirm-text" labelText="Enter">
                <Input
                  name="confirm-text"
                  placeholder="Enter text"
                  type="text"
                  onChange={(e) => {
                    setInputVal(e.target.value);
                  }}
                  value={inputVal || ''}
                  onBlur={handleBlur}
                  hasError={isTextError}
                />
                {isTextError && <span className="form__msg-error">Please check entered text!</span>}
              </FormGroup>
            </div>
          </Modal>
          <Modal
            open={isOpen}
            small
            button="Ok"
            close={() => dispatch(setIsOpen({ isOpen: false }))}
            onClick={() => dispatch(setIsOpen({ isOpen: false }))}
          >
            <div>
              <div className="modal__title-icon">
                <SVGIcon icon={Icon} />
              </div>
              <h4>You have successfully updated the data</h4>
            </div>
          </Modal>
        </div>
      )}
    </LayoutAdmin>
  );
};

export default AdminCompanyDetails;
