import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'classnames'

const SplashPlanItem = ({ icon, title, handleClick, selected, id }) => {
  return (
    <div className={clsx('splash__item', {'splash__item--selected' : selected})}
         onClick={() => handleClick(id)}
    >
      <div className="splash__item-icon">
        <img src={icon} alt={title} />
      </div>
      {title}
    </div>
  )
};


SplashPlanItem.propTypes = {
  id: PropTypes.number,
  icon: PropTypes.string,
  title: PropTypes.string,
  handleClick: PropTypes.func,
  selected: PropTypes.bool
};

export default SplashPlanItem
