export const group_type = [
  {
    key: 'stocks',
    label: 'Stocks',
    value: 'stocks',
  },
  {
    key: 'mutual_funds',
    label: 'Mutual Funds',
    value: 'mutual_funds',
  },
  {
    key: 'index_funds',
    label: 'Index Funds',
    value: 'index_funds',
  },
  {
    key: 'etfs',
    label: 'ETFs',
    value: 'etfs',
  }
];

export const transaction_method = [
  {
    key: 'over_the_phone',
    label: 'Over the Phone',
    value: 'over_the_phone',
  },
  {
    key: 'online',
    label: 'Online',
    value: 'online',
  },
  {
    key: 'broker_assisted',
    label: 'Broker Assisted',
    value: 'broker_assisted',
  }
];