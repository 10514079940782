import React, {useEffect} from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { BackButton } from '../../../components/shared/Buttons'
import Layout from '../../../components/Layout/Layout'
import LayoutForm from '../../../components/Layout/LayoutForm'
import PageTitle from '../../../components/PageTitle/PageTitle'
import Illustration from '../../../assets/images/illustrations/Tree-segment_future-min.png'
import Priority from '../../../components/shared/Priority/Priority'
import { Button, FormControlLabel, Radio, RadioGroup, TextareaAutosize } from '@mui/material'
import {FormGroup, InputCurrency, InputPercent} from '../../../components/shared/Form'
import { fetchRetirementFunding, postRetirementFunding } from '../../../redux/slices/futureSlice'
import { fetchPercentages } from '../../../redux/slices/percentagesSlice'
import { getPercent } from '../../../utils/utils'
import Modal from '../../../components/shared/Modal/Modal'
import ProgressCircle from '../../../components/shared/ProgressBar/ProgressCircle'
import { setIsOpen } from '../../../redux/slices/modalSlice'
import { retirementSchema } from '../../../utils/validationFuture'

const RetirementDetails = ({ props, isSpouse }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userId = useSelector(state => state.user.user.id);
  const sending = useSelector(state => state.sending.isSending);
  const { user, spouse } = useSelector(state => state.future.retirement);
  const percents = useSelector(state => state.percentages.percents);
  const isOpen = useSelector(state => state.modal.isOpen);

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchPercentages())
      await dispatch(fetchRetirementFunding(isSpouse))
    }

    if (userId) {
      fetch()
    }

    // eslint-disable-next-line
  }, [userId, isSpouse])

  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }))
  }

  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }))
    history.push('/retirement')
  }

  return (
    <Layout>
      <LayoutForm small illustration={Illustration}>
        <BackButton title='Back to Retirement' />
        <PageTitle
          title={
            isSpouse
              ? 'Your Spouse`s Current Retirement Funding'
              : 'Your current retirement funding'
          }
          content='Please provide the details of your current retirement contributions.'
          status={
            isSpouse
              ? getPercent(percents, 'spouse-retirement-planning')
              : getPercent(percents, 'retirement-planning')
          }
          subtitle='Yearly unless otherwise noted'
        />
        <div className='layout-form__content-holder'>
          <Formik
            enableReinitialize
            initialValues={isSpouse ? { ...spouse } : { ...user }}
            validationSchema={retirementSchema}
            onSubmit={(values) =>
              dispatch(postRetirementFunding(values, props, isSpouse))
            }
          >
            {({
              errors,
              handleChange,
              handleBlur,
              values,
              touched,
              setFieldValue,
            }) => (
              <Form>
                <FormGroup
                  name='contribution_401k'
                  labelText='401K/403B Contribution (MONTHLY)'
                >
                  <InputCurrency
                    placeholder='Type Contribution (MONTHLY)'
                    name='contribution_401k'
                    onChange={handleChange}
                    value={values.contribution_401k || ''}
                    onBlur={handleBlur}
                    info={
                      !isSpouse && {
                        content: (
                          <>
                            <h6>401K/403B Funding</h6>
                            <Priority option='High' />
                            <div>
                              A defined contribution plan offered by a
                              corporation to its employees, which allows
                              employees to set aside tax-deferred income for
                              retirement purposes, and in some cases employers
                              will match their contribution dollar-for-dollar.
                              Taking a distribution of the funds before a
                              certain specified age will trigger a penalty tax.
                              The name 401(k) comes from the IRS section
                              describing the program.
                            </div>
                          </>
                        ),
                      }
                    }
                    hasError={!!errors.contribution_401k}
                  />
                  {errors.contribution_401k && (
                    <span className='form__msg-error'>
                      {errors.contribution_401k}
                    </span>
                  )}
                </FormGroup>
                <FormGroup name='roth_401k' labelText='Roth 401K'>
                  <InputCurrency
                    placeholder='Type Roth 401K'
                    name='roth_401k'
                    onChange={handleChange}
                    value={values.roth_401k || ''}
                    onBlur={handleBlur}
                    info={
                      !isSpouse && {
                        content: (
                          <>
                            <h6>Roth 401K Funding</h6>
                            <Priority option='High' />
                            <div>
                              A new employer-sponsored investment savings
                              account that is funded with after-tax money. After
                              the investor reaches age 59.5, withdrawals of any
                              money from the account (including investment
                              gains) are tax-free. Unlike the Roth IRA, the Roth
                              401(k) has no income limitations for those
                              investors who want to participate - anyone, no
                              matter what his/her income, is allowed to invest
                              up to the contribution limit into the plan.
                            </div>
                          </>
                        ),
                      }
                    }
                    hasError={!!errors.roth_401k}
                  />
                  {errors.roth_401k && (
                    <span className='form__msg-error'>{errors.roth_401k}</span>
                  )}
                </FormGroup>
                <FormGroup name='roth_ira' labelText='Roth IRA'>
                  <InputCurrency
                    placeholder='Type Roth IRA'
                    name='roth_ira'
                    onChange={handleChange}
                    value={values.roth_ira || ''}
                    onBlur={handleBlur}
                    info={
                      !isSpouse && {
                        content: (
                          <>
                            <h6>Roth IRA Funding</h6>
                            <Priority option='High' />
                            <div>
                              A new type of IRA, established in the Taxpayer
                              Relief Act of 1997, which allows taxpayers,
                              subject to certain income limits, to save for
                              retirement while allowing the savings to grow
                              tax-free. Taxes are paid on contributions, but
                              withdrawals, subject to certain rules, are not
                              taxed at all.
                            </div>
                          </>
                        ),
                      }
                    }
                    hasError={!!errors.roth_ira}
                  />
                  {errors.roth_ira && (
                    <span className='form__msg-error'>{errors.roth_ira}</span>
                  )}
                </FormGroup>
                <FormGroup name='traditional_ira' labelText='Traditional IRA'>
                  <InputCurrency
                    placeholder='Type Traditional IRA'
                    name='traditional_ira'
                    onChange={handleChange}
                    value={values.traditional_ira || ''}
                    onBlur={handleBlur}
                    info={
                      !isSpouse && {
                        content: (
                          <>
                            <h6>Traditional IRA Funding</h6>
                            <Priority option='High' />
                            <div>
                              Individual Retirement Account. A tax-deferred
                              retirement account for an individual that permits
                              individuals to set aside money each year, with
                              earnings tax-deferred until withdrawals begin at
                              age 59 1/2 or later (or earlier, with a 10 percent
                              penalty).
                            </div>
                          </>
                        ),
                      }
                    }
                    hasError={!!errors.traditional_ira}
                  />
                  {errors.traditional_ira && (
                    <span className='form__msg-error'>
                      {errors.traditional_ira}
                    </span>
                  )}
                </FormGroup>
                <FormGroup name='simple_ira' labelText='SEP/Simple IRA'>
                  <InputCurrency
                    placeholder='Type SEP/Simple IRA'
                    name='simple_ira'
                    onChange={handleChange}
                    value={values.simple_ira || ''}
                    onBlur={handleBlur}
                    info={
                      !isSpouse && {
                        content: (
                          <>
                            <h6>SEP/Simple IRA Funding</h6>
                            <Priority option='High' />
                            <div>
                              A savings incentive match plan for employees of
                              small businesses with 100 or fewer employees. The
                              plan is similar to, but more flexible than, a
                              regular IRA. Employees enter into a qualified
                              salary reduction agreement to contribute a
                              percentage of their annual compensation to a
                              tax-deferred retirement account. The employer may
                              either match employee contributions or contribute
                              a fixed percentage of all employees' pay. All
                              earnings grow tax-deferred.
                            </div>
                          </>
                        ),
                      }
                    }
                    hasError={!!errors.simple_ira}
                  />
                  {errors.simple_ira && (
                    <span className='form__msg-error'>{errors.simple_ira}</span>
                  )}
                </FormGroup>
                <FormGroup name='solo_401k' labelText='Solo 401K'>
                  <InputCurrency
                    placeholder='Type Solo 401K'
                    name='solo_401k'
                    onChange={handleChange}
                    value={values.solo_401k || ''}
                    onBlur={handleBlur}
                    info={
                      !isSpouse && {
                        content: (
                          <>
                            <h6>Solo 401K Funding</h6>
                            <Priority option='High' />
                            <div>
                              A solo 401k plan is a regular 401k plan combined
                              with a profit-sharing plan. However, unlike a
                              regular 401k plan, a solo 401k can be activated
                              only by self-employed individuals or small
                              business owners who have no other full-time
                              employees.
                            </div>
                          </>
                        ),
                      }
                    }
                    hasError={!!errors.solo_401k}
                  />
                  {errors.solo_401k && (
                    <span className='form__msg-error'>{errors.solo_401k}</span>
                  )}
                </FormGroup>
                <FormGroup
                  name='employer_match'
                  labelText='Employer Matching Type (% of)'
                >
                  <RadioGroup
                    name='employer_match'
                    value={values.employer_match || ''}
                    onChange={handleChange}
                    className='radio-list-inline'
                  >
                    <FormControlLabel
                      value='contribution'
                      control={<Radio />}
                      label='Contribution'
                    />
                    <FormControlLabel
                      value='salary'
                      control={<Radio />}
                      label='Salary'
                    />
                  </RadioGroup>
                </FormGroup>
                <FormGroup
                  name='match_amount'
                  labelText='Employer Matching Amount(%)'
                >
                  <InputPercent
                    placeholder='Type Employer Matching Amount'
                    name='match_amount'
                    onChange={handleChange}
                    value={values.match_amount || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.match_amount }
                  />
                  {errors.match_amount && (
                    <span className='form__msg-error'>
                      {errors.match_amount}
                    </span>
                  )}
                </FormGroup>
                {isSpouse && (
                  <>
                    <h5 className='mt--medium'>Additional info</h5>
                    <FormGroup
                      name='additional_info'
                      labelText='Is there any other Retirement Funding information that you believe is important for ValueSoft to know about? If so, please let us know about it:'
                    >
                      <TextareaAutosize
                        maxLength='200'
                        name='additional_info'
                        value={values.additional_info || ''}
                        className='form__textarea'
                        placeholder='Maximum characters: 200'
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </>
                )}
                <div className='form__button'>
                  <Button
                    variant='contained'
                    size='large'
                    color='primary'
                    type='submit'
                    disabled={sending}
                  >
                    Save
                    {sending && <span className='spinner' />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <Modal
          open={isOpen}
          small
          button='Done'
          onClick={handleClick}
          close={onClose}
        >
          <h3>Good Job!</h3>
          <p>Your progress increased 100%</p>
          <ProgressCircle amount={100} small />
        </Modal>
      </LayoutForm>
    </Layout>
  );
};

export default RetirementDetails;
