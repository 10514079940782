import React from 'react'
import { Form, Formik } from 'formik'
import { Button } from '@mui/material'
import { FormGroup, Input, InputCurrency } from '../shared/Form'
import Percentage from '../../assets/images/icons/percentage.svg'

const TimeToBuyHomeAdmin = ({ title, onSave, values, sending }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{...values}}
      onSubmit={values => onSave(values)}
    >
      {({ handleChange, handleBlur, values }) => (
        <Form>
          <h4 className="form__main-title">{title}</h4>
          <h6>Profile info</h6>
          <div className="form__flow-wrap">
          <FormGroup name="current_age" labelText="Current age">
            <Input
              name="current_age"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.current_age || 0}
            />
          </FormGroup>
          <FormGroup name="current_monthly_expenses" labelText="Current monthly expenses">
            <InputCurrency
              name="current_monthly_expenses"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.current_monthly_expenses || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="monthly_savings" labelText="Monthly Savings">
            <InputCurrency
              name="monthly_savings"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.monthly_savings || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="savings_for_down_payment" labelText="% of savings used for downpayment">
            <Input
              name="savings_for_down_payment"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.savings_for_down_payment || 0}
              iconName={Percentage}
            />
          </FormGroup>
          <FormGroup name="months_of_emergency_available" labelText="Months of emergency funds available">
            <Input
              name="months_of_emergency_available"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.months_of_emergency_available || 0}
            />
          </FormGroup>
          </div>
          <h6>Purchase details</h6>
          <div className="form__flow-wrap">
          <FormGroup name="age_buy_home" labelText="Age you’d like to buy home">
            <Input
              name="age_buy_home"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.age_buy_home || 0}
            />
          </FormGroup>
          <FormGroup name="home_value" labelText="Home value (Today’s $)">
            <InputCurrency
              name="home_value"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.home_value || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="home_appreciation_rate" labelText="Home appreciation rate">
            <Input
              name="home_appreciation_rate"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.home_appreciation_rate || 0}
              iconName={Percentage}
            />
          </FormGroup>
          <FormGroup name="down_payment" labelText="Downpayment">
            <Input
              name="down_payment"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.down_payment || 0}
              iconName={Percentage}
            />
          </FormGroup>
          <FormGroup name="closing_costs" labelText="Closing Costs">
            <Input
              name="closing_costs"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.closing_costs || 0}
              iconName={Percentage}
            />
          </FormGroup>
          </div>
          <h6>Available funds</h6>
          <div className="form__flow-wrap">
          <FormGroup name="available_checking_funds" labelText="Available checking funds">
            <InputCurrency
              name="available_checking_funds"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.available_checking_funds || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="available_savings_funds" labelText="Available savings funds">
            <InputCurrency
              name="available_savings_funds"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.available_savings_funds || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="available_brokerage_funds" labelText="Available brokerage funds">
            <InputCurrency
              name="available_brokerage_funds"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.available_brokerage_funds || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="additional_funds_available" labelText="Additional funds slated to be available prior to desired year of purchase">
            <InputCurrency
              name="additional_funds_available"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.additional_funds_available || 0}
              withoutSign
              icon
            />
          </FormGroup>
          </div>
          <div className="form__button">
            <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
              Save
              {sending && <span className="spinner" />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default TimeToBuyHomeAdmin
