import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Redirect } from 'react-router-dom'
import LayoutAdmin from '../../components/Layout/LayoutAdmin'
import { BackButton } from '../../components/shared/Buttons'
import SVGIcon from '../../components/shared/SVGIcon/SVGIcon'
import { Button, TextareaAutosize } from '@mui/material'
import { fetchCompany } from '../../redux/slices/companiesSlice';
import { FormSelect, FormGroup, Input } from '../../components/shared/Form'
import { useDropzone } from 'react-dropzone'
import ImageIcon from '../../assets/images/icons/image.svg'
import isNull from 'lodash/isNull'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'
import { fetchAuthors, fetchPostById, fetchCatalog, addPost, updatePost } from '../../redux/slices/blogSlice'

const AdminPostEdit = ({ isAdmin, isEdit, props }) => {
  const dispatch = useDispatch()
  const userId = useSelector(state => state.user.user.id)
  const { authors, currentPost, isLoading } = useSelector(state => state.blog)
  const { catalog } = useSelector(state => state.options)
  const { permissions, image, email, name } = useSelector(state => state.companies.company.info);
  const { companyId, postId }  = useParams()
  const [preview, setPreview] = useState(null);
  const [file, setFile] = useState(null);
  const [authorsList, setAuthorsList] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  const mobileView = 999;

  const handleResize = () => {
    mobileView >= window.innerWidth ? setIsMobile(true) : setIsMobile(false);
  };

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(fetchCompany(companyId));
    // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      const newImage = URL.createObjectURL(acceptedFiles[0]);
      setPreview(newImage)
      setFile(acceptedFiles[0])
    }
  });

  useEffect(() => () => {
    URL.revokeObjectURL(preview)
  }, [preview]);

  useEffect(() => {
    async function fetchOptions() {
      await dispatch(fetchAuthors(companyId, isAdmin))
      await dispatch(fetchCatalog(companyId, isAdmin))
    }
    async function fetch() {
      await dispatch(fetchPostById(companyId, postId, isAdmin))
    }
    if (userId && postId) {
      fetch()
    }

    if(userId) {
     fetchOptions()
    }
    // eslint-disable-next-line
  }, [userId, postId])

  useEffect(() => {
    if (authors !== []) {
      setAuthorsList(authors.map(item => ({...item, key: item.id, id: item.id, value: item.id, label: `${item.first_name} ${item.last_name}`})))
    }
    // eslint-disable-next-line
  }, [authors])

  const onSubmit = (values, isEdit) => {
    let formData = new FormData();

    if (file) {
      formData.append('image', file)
    }

    formData.append('content', values.content)
    formData.append('title', values.title)
    formData.append('short_description', values.short_description)
    formData.append('catalog_id', values.catalog_id)
    formData.append('author_id', values.author_id)

    if (isEdit) {
      dispatch(updatePost(companyId, postId, formData, props))
    } else {
      dispatch(addPost(companyId, formData, props))
    }
  }

  if (!isNull(permissions?.blog) && !permissions?.blog) return (<Redirect to="/404" />)
  
  return (
    <LayoutAdmin companyId={companyId} isAdmin={isAdmin}>
      <div className="member-comapny">
        <div className="member-comapny__info">
          <div className="member-comapny__logo">
            <img src={image} alt="Company logo" />
          </div>
          <div className="member-comapny__title">
            <h3>{name}</h3>
            {!isMobile && (
              <p>
                Feedback Email: <span>{email}</span>
              </p>
            )}
          </div>
        </div>
        {isMobile && (
          <div className="mobile-feedback">
            Feedback Email: <span>{email}</span>
          </div>
        )}
      </div>

      <BackButton title="Back to Blog" path={`/admin/company/${companyId}/blog`} />

      <div className="container container--large">
        <h5>{isEdit ? 'Edit article' : 'Create a new article'}</h5>
        <Formik
          enableReinitialize
          initialValues={isEdit && currentPost
            ? {
              content: currentPost.content,
              short_description: currentPost.short_description,
              catalog_id: currentPost.catalog_id,
              author_id: currentPost.author_id,
              title: currentPost.title
            }
            : {
              content:'',
              short_description: '',
              catalog_id: null,
              author_id: null,
              title: ''
            }}
          onSubmit={values => onSubmit(values, isEdit)}
          validationSchema={Yup.object().shape({
            title: Yup.string().required('Required'),
            short_description: Yup.string().required('Required'),
            catalog_id: Yup.string().nullable().required('Required'),
            author_id: Yup.string().nullable().required('Required'),
            content: Yup.string().required('Required'),
          })}
        >
          {({ errors, handleChange, handleBlur, values, touched, setFieldValue }) => (
            <Form>
              <div className="admin__form-holder">
                <div className="admin__form-column">
                  <FormGroup
                    name="title"
                    labelText="Type article title"
                  >
                    <Input
                      name="title"
                      placeholder="Type a name"
                      type="text"
                      onChange={handleChange}
                      value={values.title || ''}
                      onBlur={handleBlur}
                      hasError={!!errors.title && touched.title}
                    />
                    {touched.title && errors.title && <span className="form__msg-error">{errors.title}</span>}
                  </FormGroup>
                  <FormGroup
                    name="short_description"
                    labelText="Article description"
                  >
                    <TextareaAutosize
                      maxLength="200"
                      name="short_description"
                      value={values.short_description || ''}
                      className="form__textarea form__textarea--alt"
                      placeholder="Type description"
                      onChange={handleChange}
                    />
                    {touched.short_description && errors.short_description && <span className="form__msg-error">{errors.short_description}</span>}
                  </FormGroup>
                  <FormGroup
                    name="catalog_id"
                    labelText="Category"
                  >
                    <FormSelect
                      options={catalog}
                      value={values.catalog_id || ''}
                      name="catalog_id"
                      onChange={(e) => {
                        setFieldValue(e.target.name, e.target.value)
                      }}
                      placeholder="Select category"
                      hasError={!!errors.category_id && touched.category_id}
                    />
                    {touched.catalog_id && errors.catalog_id && <span className="form__msg-error">{errors.catalog_id}</span>}
                  </FormGroup>
                  <FormGroup
                    name="author_id"
                    labelText="Author"
                  >
                    <FormSelect
                      options={authorsList}
                      value={values.author_id || ''}
                      name="author_id"
                      onChange={(e) => {
                        setFieldValue(e.target.name, e.target.value)
                      }}
                      placeholder="Select author"
                      hasError={!!errors.author_id && touched.author_id}
                    />
                    {touched.author_id && errors.author_id && <span className="form__msg-error">{errors.author_id}</span>}
                  </FormGroup>
                </div>
                <div className="admin__form-column">
                  {!isEdit && !preview && touched.title && touched.short_description && <div className="form__msg-error">The image field is required</div>}
                  <div className="dropzone">
                    <div {...getRootProps({className: 'dropzone__holder'})}>
                      <input {...getInputProps()} />
                      <SVGIcon icon={ImageIcon} />
                      <h5>Drag & Drop image</h5>
                      <Button variant="outlined" size="small">Upload</Button>
                      {isEdit
                        ? <div className="dropzone__preview">
                            <img src={preview ? preview : currentPost.image}
                                 alt="Post illustration" />
                          </div>
                        : !isNull(preview)
                          ? <div className="dropzone__preview">
                              <img src={preview}
                                   alt="Post illustration" />
                            </div>
                          : ''
                      }
                    </div>
                  </div>
                </div>
              </div>
              {touched.content && errors.content && <span className="form__msg-error">{errors.content}</span>}
              <SunEditor
                onChange={content => setFieldValue('content', content)}
                setContents={values.content}
                placeholder="Please type here..."
                setOptions={{
                  height: '400px',
                  font : [
                    'Arial',
                    'Source Sans Pro',
                    'Sora'
                  ],
                  colorList : [
                    ['#000', '#0F9AFF', '#262453', '#E33544', '#F3D9DA', '#E3A45A', '#FD8A63'],
                    ['#FF665C', '#215E59', '#F9F9F9', '#E8E8E8', '#DDDDDD', '#D1D1D1', '#8C8C8C'],
                  ],
                  buttonList : [
                    ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                    ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview']
                  ]
                }}
              />
              <div className="admin__form-button">
                <Button variant="contained" size={isMobile ? "medium" : "small"} color="primary" type="submit" disabled={isLoading}>
                  Save
                  {isLoading && <span className="spinner" />}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </LayoutAdmin>
  )
}

export default AdminPostEdit
