import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@mui/material'
import CheckEmail from '../../components/Auth/CheckEmail'


const SignUpCompanyConfirm = () => {
  const history = useHistory();
  const onSubmit = () => {
    history.push('/login')
  };
  return (
    <CheckEmail title="Success">
      <p className="mb--medium">Your registration request has been sent to ValueSoft. We will get back to you shortly!</p>
      <Button variant="outlined" onClick={onSubmit}>
        Got it
      </Button>
    </CheckEmail>
  )
};

export default SignUpCompanyConfirm;
