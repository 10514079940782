import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import logo from '../../../assets/images/logo.svg'
import { isNull } from 'lodash';

const Logo = ({ path, className, onClick }) => {
  const { company_logo } = useSelector(state => state.user.user);
  const { image } = useSelector(state => state.companies.company.info);

  const [logoSrc, setLogoSrc] = useState(logo);

  useEffect(() => {
    !isNull(image) ? setLogoSrc(image) : company_logo ? setLogoSrc(company_logo) : setLogoSrc(logo)
  }, [image, company_logo]);
  
  return (
    <div className={`logo ${className ? className : ''}`}>
      {path ? (
        <Link to={path} onClick={onClick}>
          <img src={logoSrc} alt="ValueSoft" />
        </Link>
      ) : (
        <img src={logoSrc} alt="ValueSoft" />
      )}
    </div>
  );
}

Logo.propTypes = {
  path: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default Logo
