import React from 'react'
import { Form, Formik } from 'formik'
import { Button } from '@mui/material'
import { FormGroup, Input, InputCurrency } from '../shared/Form'
import Percentage from '../../assets/images/icons/percentage.svg'

const IncomeExpensesNetWorthAdmin = ({ title, onSave, values, sending }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{...values}}
      onSubmit={values => onSave(values)}
    >
      {({ handleChange, handleBlur, values }) => (
        <Form>
          <h4 className="form__main-title">{title}</h4>
          <div className="form__flow-wrap">
          <FormGroup name="reduce_expenses" labelText="Reduce Monthly Expenses by">
            <InputCurrency
              name="reduce_expenses"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.reduce_expenses  || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="increase_expenses" labelText="Increase Monthly Income by">
            <InputCurrency
              name="increase_expenses"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.increase_expenses || 0}
              withoutSign
              icon
            />
          </FormGroup>
          <FormGroup name="annual_rate" labelText="Years Financed">
            <Input
              name="annual_rate"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              step={0.1}
              value={values.annual_rate || 0}
              iconName={Percentage}
            />
          </FormGroup>
          <FormGroup name="annual_inflation" labelText="Annual Inflation Rate">
            <Input
              name="annual_inflation"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              step={0.1}
              value={values.annual_inflation || 0}
              iconName={Percentage}
            />
          </FormGroup>
          <FormGroup name="evaluate_impact" labelText="Years from today to evaluate impact">
            <Input
              name="evaluate_impact"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.evaluate_impact || 0}
            />
          </FormGroup>
          </div>
          <div className="form__button">
            <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
              Save
              {sending && <span className="spinner" />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default IncomeExpensesNetWorthAdmin
