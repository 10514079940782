import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Redirect } from 'react-router-dom';
import LayoutAdmin from '../../components/Layout/LayoutAdmin'
import { BackButton } from '../../components/shared/Buttons'
import SVGIcon from '../../components/shared/SVGIcon/SVGIcon'
import { Button, TextareaAutosize } from '@mui/material'
import { FormGroup, Input } from '../../components/shared/Form'
import { useDropzone } from 'react-dropzone'
import ImageIcon from '../../assets/images/icons/image.svg'
import isNull from 'lodash/isNull'
import { fetchCompany } from '../../redux/slices/companiesSlice';
import { fetchAuthorById, addAuthor, updateAuthor } from '../../redux/slices/blogSlice'

const AdminAuthorEdit = ({ isAdmin, isEdit, props }) => {
  const dispatch = useDispatch()
  const userId = useSelector(state => state.user.user.id)
  const { currentAuthor, isLoading } = useSelector(state => state.blog)
  const { permissions, image, email, name } = useSelector((state) => state.companies.company.info);
  const { companyId, authorId }  = useParams()
  const [preview, setPreview] = useState(null);
  const [file, setFile] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  const mobileView = 999;

  const handleResize = () => {
    mobileView >= window.innerWidth ? setIsMobile(true) : setIsMobile(false);
  };

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(fetchCompany(companyId));
    // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      const newImage = URL.createObjectURL(acceptedFiles[0]);
      setPreview(newImage)
      setFile(acceptedFiles[0])
    }
  });

  useEffect(() => () => {
    URL.revokeObjectURL(preview)
  }, [preview]);

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchAuthorById(companyId, authorId, isAdmin))
    }
    if (userId && authorId) {
      fetch()
    }

    // eslint-disable-next-line
  }, [userId, authorId])

  const onSubmit = (values, isEdit) => {
    let formData = new FormData();

    if (file) {
      formData.append('avatar', file)
    }

    formData.append('first_name', values.first_name)
    formData.append('last_name', values.last_name)
    formData.append('author_title', values.author_title)
    formData.append('author_email', values.author_email)
    formData.append('author_social_link', values.author_social_link)
    formData.append('author_biography', values.author_biography)

    if (isEdit) {
      dispatch(updateAuthor(companyId, authorId, formData, props))
    } else {
      dispatch(addAuthor(companyId, formData, props))
    }
  }

  if (!isNull(permissions?.blog) && !permissions?.blog) return <Redirect to="/404" />;

  return (
    <LayoutAdmin companyId={companyId} isAdmin={isAdmin}>
      <div className="member-comapny">
        <div className="member-comapny__info">
          <div className="member-comapny__logo">
            <img src={image} alt="Company logo" />
          </div>
          <div className="member-comapny__title">
            <h3>{name}</h3>
            {!isMobile && (
              <p>
                Feedback Email: <span>{email}</span>
              </p>
            )}
          </div>
        </div>
        {isMobile && (
          <div className="mobile-feedback">
            Feedback Email: <span>{email}</span>
          </div>
        )}
      </div>

      <BackButton title="Back to  Blog" path={`/admin/company/${companyId}/blog`} />

      <div className="container__blog container--large">
        <h5>{isEdit ? 'Edit author' : 'Create a new author'}</h5>
        <Formik
          enableReinitialize
          initialValues={
            isEdit && currentAuthor
              ? {
                  first_name: currentAuthor.first_name,
                  last_name: currentAuthor.last_name,
                  author_title: currentAuthor.author_title,
                  author_email: currentAuthor.author_email,
                  author_social_link: currentAuthor.author_social_link,
                  author_biography: currentAuthor.author_biography,
                }
              : {
                  first_name: '',
                  last_name: '',
                  author_title: '',
                  author_email: '',
                  author_social_link: '',
                  author_biography: '',
                }
          }
          onSubmit={(values) => onSubmit(values, isEdit)}
          validationSchema={Yup.object().shape({
            first_name: Yup.string().required('Required'),
            last_name: Yup.string().required('Required'),
            author_title: Yup.string().required('Required'),
            author_email: Yup.string().required('Required'),
            author_social_link: Yup.string().notRequired(),
            author_biography: Yup.string().required('Required'),
          })}
        >
          {({ errors, handleChange, handleBlur, values, touched }) => (
            <Form>
              <div className="admin__form-holder admin__form-holder--noline">
                <div className="admin__form-column">
                  <FormGroup name="first_name" labelText="First Name">
                    <Input
                      name="first_name"
                      placeholder="Type a name"
                      type="text"
                      onChange={handleChange}
                      value={values.first_name || ''}
                      onBlur={handleBlur}
                      hasError={!!errors.first_name && touched.first_name}
                    />
                    {touched.first_name && errors.first_name && (
                      <span className="form__msg-error">{errors.first_name}</span>
                    )}
                  </FormGroup>
                  <FormGroup name="last_name" labelText="Last Name">
                    <Input
                      name="last_name"
                      placeholder="Type a last name"
                      type="text"
                      onChange={handleChange}
                      value={values.last_name || ''}
                      onBlur={handleBlur}
                      hasError={!!errors.last_name && touched.last_name}
                    />
                    {touched.last_name && errors.last_name && (
                      <span className="form__msg-error">{errors.last_name}</span>
                    )}
                  </FormGroup>
                  <FormGroup name="author_email" labelText="Email address">
                    <Input
                      name="author_email"
                      placeholder="example@email.com"
                      type="email"
                      onChange={handleChange}
                      value={values.author_email || ''}
                      onBlur={handleBlur}
                      hasError={!!errors.author_email && touched.author_email}
                    />
                    {touched.author_email && errors.author_email && (
                      <span className="form__msg-error">{errors.author_email}</span>
                    )}
                  </FormGroup>
                  <FormGroup name="author_title" labelText="Position">
                    <Input
                      name="author_title"
                      placeholder="Type your position"
                      type="text"
                      onChange={handleChange}
                      value={values.author_title || ''}
                      onBlur={handleBlur}
                      hasError={!!errors.author_title && touched.author_title}
                    />
                    {touched.author_title && errors.author_title && (
                      <span className="form__msg-error">{errors.author_title}</span>
                    )}
                  </FormGroup>
                  <FormGroup name="author_social_link" labelText="Social media link (Twitter, LinkedIn, etc.)">
                    <Input
                      name="author_social_link"
                      placeholder="Type your social media link"
                      type="text"
                      onChange={handleChange}
                      value={values.author_social_link || ''}
                      onBlur={handleBlur}
                      hasError={!!errors.author_social_link && touched.author_social_link}
                    />
                    {touched.author_social_link && errors.author_social_link && (
                      <span className="form__msg-error">{errors.author_social_link}</span>
                    )}
                  </FormGroup>
                  <FormGroup name="author_biography" labelText="Biography">
                    <TextareaAutosize
                      maxLength="600"
                      name="author_biography"
                      value={values.author_biography || ''}
                      className="form__textarea form__textarea--alt"
                      placeholder="Type short biography"
                      onChange={handleChange}
                    />
                    {touched.author_biography && errors.author_biography && (
                      <span className="form__msg-error">{errors.author_biography}</span>
                    )}
                  </FormGroup>
                </div>
                <div className="admin__form-column">
                  {!isEdit && !preview && touched.first_name && touched.last_name && (
                    <div className="form__msg-error">The image field is required</div>
                  )}
                  <div className="dropzone">
                    <div {...getRootProps({ className: 'dropzone__holder' })}>
                      <input {...getInputProps()} />
                      <SVGIcon icon={ImageIcon} />
                      <h5>Drag & Drop image</h5>
                      <Button variant="outlined" size="small">
                        Upload
                      </Button>
                      {isEdit ? (
                        <div className="dropzone__preview">
                          <img src={preview ? preview : currentAuthor.avatar} alt="Author's illustration" />
                        </div>
                      ) : !isNull(preview) ? (
                        <div className="dropzone__preview">
                          <img src={preview} alt="Author's illustration" />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="admin__form-button">
                <Button
                  variant="contained"
                  size={isMobile ? 'medium' : 'small'}
                  color="primary"
                  type="submit"
                  disabled={isLoading}
                >
                  Save
                  {isLoading && <span className="spinner" />}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </LayoutAdmin>
  );
}

export default AdminAuthorEdit
