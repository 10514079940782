import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../../components/Layout/Layout'
import LayoutForm from '../../../components/Layout/LayoutForm'
import { BackButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import { Button } from '@mui/material'
import { fetchCash, postCash } from '../../../redux/slices/worthSlice'
import { FormGroup, InputCurrency } from '../../../components/shared/Form'
import Priority from '../../../components/shared/Priority/Priority'
import Modal from '../../../components/shared/Modal/Modal'
import ProgressCircle from '../../../components/shared/ProgressBar/ProgressCircle'
import { setIsOpen } from '../../../redux/slices/modalSlice'
import { worthCashSchema } from '../../../utils/validationFinances'

const WorthAssetsCash = ({ props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.user.user);
  const { cash } = useSelector(state => state.worth);
  const isOpen = useSelector(state => state.modal.isOpen);
  const sending = useSelector(state => state.sending.isSending);

  useEffect(() => {
    async function fetch() {
      dispatch(fetchCash())
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const onSubmit = (values) => {
    dispatch(postCash(values, props))
  }

  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }))
  }

  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }));
    history.push('/worth/assets')
  }

  return (
    <Layout>
      <LayoutForm small>
        <BackButton title="Back" />
        <PageTitle
          title="Cash accounts"
        />
        <div className="layout-form__content-holder">
          <Formik
            enableReinitialize
            initialValues={cash && {...cash}}
            onSubmit={onSubmit}
            validationSchema={worthCashSchema}
          >
            {({ errors, handleChange, handleBlur, values, touched }) => (
              <Form>
                <FormGroup name="checking" labelText="Checking ($)">
                  <InputCurrency
                    placeholder="Type checking"
                    type="text"
                    name="checking"
                    onChange={handleChange}
                    value={values.checking || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.checking}
                  />
                  {errors.checking && <span className="form__msg-error">{errors.checking}</span>}
                </FormGroup>
                <FormGroup name="saving" labelText="Savings ($)">
                  <InputCurrency
                    placeholder="Type saving"
                    type="text"
                    name="saving"
                    onChange={handleChange}
                    value={values.saving || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.saving}
                  />
                  {errors.saving && <span className="form__msg-error">{errors.saving}</span>}
                </FormGroup>
                <FormGroup name="cash_equivalent" labelText="Cash equivalents ($)">
                  <InputCurrency
                    placeholder="Type cash equivalents"
                    type="text"
                    name="cash_equivalent"
                    onChange={handleChange}
                    value={values.cash_equivalent || ''}
                    onBlur={handleBlur}
                    info={{
                      content:
                        <>
                          <h6>Cash Equivalents</h6>
                          <Priority option="High" />
                          <div>Highly liquid, very safe investments which can be easily converted into cash, such as Treasury Bills, certificates of deposits, and money market funds.</div>
                        </>
                    }}
                    hasError={!!errors.cash_equivalent}
                  />
                  {errors.cash_equivalent && <span className="form__msg-error">{errors.cash_equivalent}</span>}
                </FormGroup>
                <div className="form__button form__button--center">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Save
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <Modal
          open={isOpen}
          small
          button="Done"
          onClick={handleClick}
          close={onClose}
        >
          <h3>Good Job!</h3>
          <p>Your progress increased 100%</p>
          <ProgressCircle amount={100} small />
        </Modal>
      </LayoutForm>
    </Layout>
  );
};

export default WorthAssetsCash;
