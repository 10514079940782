import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Layout from '../../components/Layout/Layout'
import { BackButton } from '../../components/shared/Buttons'
import TreeSubItem from '../../components/Tree/TreeSubItem'
import { getPercent } from '../../utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserFamilyAssets, fetchUserFamilyData } from '../../redux/slices/userSlice'
import { fetchTotalPercent } from "../../redux/slices/percentagesSlice";
import TreeCount from '../../components/Tree/TreeCount'
import { fetchAnalysisTasks } from '../../redux/slices/taskSlice'

const Finance = () => {
  const dispatch = useDispatch()
  const { id } = useSelector(state => state.user.user)
  const { own_other_assets, own_or_rent_home } = useSelector(
    (state) => state.user.family.assets
  );
  const { significant_income } = useSelector(state => state.user.family.others)
  const isBasic = useSelector(state => state.user.user.plan_type === 1)
  const percents = useSelector(state => state.percentages.percents)
  const loading = useSelector(state => state.sending.isSending)
  const analysis = useSelector(state => state.task.analysis)
  const totalPercent = useSelector((state) => state.percentages.total);

  useEffect(() => {
    async function fetch() {
      Promise.all([
        //dispatch(fetchPercentages()),
        dispatch(fetchUserFamilyAssets(id)),
        dispatch(fetchUserFamilyData(id)),
        dispatch(fetchAnalysisTasks()),
        dispatch(fetchTotalPercent(isBasic)),
      ]);
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const incremental = getPercent(percents, 'incremental')
  const assets      = getPercent(percents, 'assets')
  const worth       = getPercent(percents, 'worth')
  const expense     = getPercent(percents, 'expense')
  const income      = getPercent(percents, 'income')
  const totalFinance =
    ((!isBasic && significant_income && incremental) +
      ((own_or_rent_home === "own" || own_other_assets) && assets) +
      worth +
      expense +
      income) /
    (3 +
      (!isBasic && significant_income && 1) +
      ((own_or_rent_home === "own" || own_other_assets) && 1));


  const menuList = [
    {
      path: "/income",
      count: income || "0",
      title: "Income",
    },
    {
      path: "/expenses",
      count: expense || "0",
      title: "Expenses",
    },
    {
      path: "/worth",
      count: worth || "0",
      title: "Worth",
    },
    {
      path: "/assets",
      count: assets || "0",
      title: "Fixed Assets",
      isDisabled: own_or_rent_home === "rent" && !own_other_assets,
      redirect: "/profile-summary/about-your-family/assets",
      modalContent: (
        <p>
          You need to add the relevant information in the{" "}
          <strong className="text-success">About You</strong> page in order to
          activate this section.{" "}
        </p>
      ),
    },
    {
      path: "/incremental-income",
      count: incremental || "0",
      title: "Incremental",
      isBasic: isBasic,
      isDisabled: isBasic || !significant_income,
      redirect: "/profile-summary/about-your-family/others",
      modalContent: (
        <p>
          You need to add the relevant information in the{" "}
          <strong className="text-success">About You</strong> page in order to
          activate this section.{" "}
        </p>
      ),
    },
  ];

  return (
    <Layout totalPercent={totalPercent} tree>
      <BackButton title="Back" className="back-button--position" path="/input" />
      <div className="tree tree--mobile-page">
        <div className="tree__title">
          <h2>Finance</h2>
          <TreeCount value={totalFinance.toFixed()} loading={loading} />
        </div>
        {menuList &&
          menuList.map((item, id) => (
            <TreeSubItem
              key={`menu-${id}`}
              path={item.path}
              title={item.title}
              count={item.count}
              isBasic={item.isBasic}
              isDisabled={item.isDisabled}
              redirect={item.redirect}
              modalContent={item.modalContent}
              isLoading={loading}
            />
          ))}
        <div className="tree__task">
          <Link to="/tasks">
            {analysis && analysis.openCount ? analysis.openCount : "0"}
          </Link>
        </div>
      </div>
    </Layout>
  );
}

export default Finance
