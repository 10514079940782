import React from 'react';
import RangeSlider from '../shared/Form/RangeSlider';
import InputCurrency from '../shared/Form/InputCurrency';
import Select from '../shared/Form/Select';
import MultipleSelect from '../shared/Form/MultipleSelect'
import Input from '../shared/Form/Input';
import Button from '@mui/material/Button';
import { isEmpty } from 'lodash';

const AnalysisFilter = (props) => {
  return (
    <div className='analysis-filter'>
      <h2 className='filter-title'>Filter</h2>
      <p className='filter-subtitle'>
        Use these optional filters to narrow down your comparison to a specific
        user profile. Unused filters will return all user types for that
        specific filter.
      </p>
      <div className='analysis-filter__wrap'>
      <div className='analysis-filter__location'>
        <div className='analysis-filter__item'>
          <div className='range__label'>State</div>
          <MultipleSelect
            options={props.optionsState}
            value={props.stateValue}
            onChange={props.handleStateOptions}
            small
          />
        </div>
        <div className='analysis-filter__item'>
          <div className='range__label'>City</div>
          <MultipleSelect
            disabled={isEmpty(props.optionsCity)}
            options={props.optionsCity}
            value={props.cityValue}
            onChange={props.handleCityOptions}
            small
          />
        </div>
      </div>
      <div className='analysis-filter__status'>
        <div className='analysis-filter__item'>
          <div className='range__label'>Married</div>
          <Select
            options={[
              { key: 1, value: 'yes', label: 'Yes' },
              { key: 2, value: 'no', label: 'No' },
            ]}
            value={props.marriedValue}
            onChange={props.handleMarriedValue}
            small
          />
        </div>
        <div className='analysis-filter__item'>
          <div className='range__label'>Number of dependents</div>
          <Input
            type='number'
            name='dependents'
            value={props.dependentsValue}
            onChange={props.handleDependentsValue}
            autoWidth
            smallHeight
          />
        </div>
      </div>
      <div className='analysis-filter__networth'>
        <div className='analysis-filter__item'>
          <div className='range__label'>Income range{props?.maxIncomRange === 0 && <span style={{ color: '#ff8787' }}> (disabled)</span>}</div>
          <div className='analysis-filter__slide'>
            <RangeSlider
              step={1000}
              max={props.maxIncomRange}
              name='incomeRange'
              value={props.incomeValue}
              onChange={props.handleChange}
              disableSwap
              disabled={!props.maxIncomRange}
            />
            <div className='filter-range-inputs'>
              <InputCurrency
                value={props.incomeValue[0]}
                name='incomeRangeMin'
                onChange={props.onChangeIncome}
                small
                smallHeight
                withoutSign
                iconLeft
                disabled={!props.maxIncomRange}
              />
              <span className='filter-divider' />
              <InputCurrency
                value={props.incomeValue[1]}
                name='incomeRangeMax'
                onChange={props.onChangeIncome}
                small
                smallHeight
                withoutSign
                iconLeft
                disabled={!props.maxIncomRange}
              />
            </div>
          </div>
        </div>
        <div className='analysis-filter__item'>
          <div className='range__label'>Net worth range{props?.maxNetWorthRange === 0 && <span style={{ color: '#ff8787' }}> (disabled)</span>}</div>
          <div className='analysis-filter__slide'>
            <RangeSlider
              name='networthRange'
              step={1000}
              max={props.maxNetWorthRange}
              label='Net worth range'
              value={props.networthValue}
              onChange={props.handleChange}
              disableSwap
              disabled={!props.maxNetWorthRange}
            />
            <div className='filter-range-inputs'>
              <InputCurrency
                disabled={!props.maxNetWorthRange}
                value={props.networthValue[0]}
                onChange={props.onChangeNetworth}
                name='networthRangeMin'
                small
                smallHeight
                withoutSign
                iconLeft
              />
              <span className='filter-divider' />
              <InputCurrency
                disabled={!props.maxNetWorthRange}
                value={props.networthValue[1]}
                onChange={props.onChangeNetworth}
                name='networthRangeMax'
                small
                smallHeight
                withoutSign
                iconLeft
              />
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className='analysis-filter__control'>
        <Button 
          variant="outlined" 
          color='info' 
          size="medium"
          onClick={props.onResetFilter}
        >
          Clear all
        </Button>
        <Button 
          variant="contained" 
          size="medium"
          onClick={props.onApplyFilter}
        >
          Apply changes
        </Button>
      </div>
    </div>
  );
};

export default AnalysisFilter;
