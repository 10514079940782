import { createSlice } from '@reduxjs/toolkit'
import { Assumptions } from '../../api/Api'
import { setSending } from './sendingSlice'
import { convertDecimal } from '../../utils/utils'
import { setIsOpen } from './modalSlice'
import { setMode } from './modeSlice'
import { setIsRelogin } from './loadingSlice';


const assumptionsSlice = createSlice({
  name: 'assumptions',
  initialState: {
    isLoading: false,
    budget: null,
    property: null,
    auto: null,
    umbrella: null,
    retirement: null,
    retirementFunding: null,
    education: null,
    netWorth: null,
    estate: null,
    additional: null
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setBudget: (state, action) => {
      state.budget = action.payload
    },
    setProperty: (state, action) => {
      state.property = action.payload
    },
    setAuto: (state, action) => {
      state.auto = action.payload
    },
    setUmbrella: (state, action) => {
      state.umbrella = action.payload
    },
    setRetirement: (state, action) => {
      state.retirement = action.payload
    },
    setRetirementFunding: (state, action) => {
      state.retirementFunding = action.payload
    },
    setEducation: (state, action) => {
      state.education = action.payload
    },
    setNetWorth: (state, action) => {
      state.netWorth = action.payload
    },
    setEstate: (state, action) => {
      state.estate = action.payload
    },
    setAdditional: (state, action) => {
      state.additional = action.payload
    }
  }
})

export const fetchBudget = (id) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    const response = await Assumptions.getBudget(id);
    await dispatch(setBudget(response.data));
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postBudget = (id, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    await Assumptions.postBudget(id, data);
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchProperty = (id) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    const response = await Assumptions.getProperty(id);
    await dispatch(setProperty(response.data));
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postProperty = (id, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));

    data.personal_liability_coverage = convertDecimal(data.personal_liability_coverage)

    await Assumptions.postProperty(id, data);
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchAuto = (id) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    const response = await Assumptions.getAuto(id);
    await dispatch(setAuto(response.data));
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postAuto = (id, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));

    data.comprehensive_coverage = convertDecimal(data.comprehensive_coverage)
    data.medical_payment_coverage = convertDecimal(data.medical_payment_coverage)
    data.personal_liability_auto_per_incident = convertDecimal(data.personal_liability_auto_per_incident)
    data.personal_liability_auto_per_person = convertDecimal(data.personal_liability_auto_per_person)
    data.property_damage_coverage = convertDecimal(data.property_damage_coverage)
    data.rental_coverage = convertDecimal(data.rental_coverage)
    data.towing_coverage = convertDecimal(data.towing_coverage)
    data.uninsured_incident_coverage = convertDecimal(data.uninsured_incident_coverage)
    data.uninsured_person_coverage = convertDecimal(data.uninsured_person_coverage)

    await Assumptions.postAuto(id, data);
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchUmbrella = (id) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    const response = await Assumptions.getUmbrella(id);
    await dispatch(setUmbrella(response.data));
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postUmbrella = (id, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    let { net_worth } = data;
    net_worth = convertDecimal(net_worth)
    await Assumptions.postUmbrella(id, {...data, net_worth});
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchRetirement = (id) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    const response = await Assumptions.getRetirement(id);
    await dispatch(setRetirement(response.data));
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postRetirement = (id, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    let { early_retirement_social_security,
      delayed_retirement_social_security,
      social_security_survivor
    } = data

    early_retirement_social_security   = convertDecimal(early_retirement_social_security)
    delayed_retirement_social_security = convertDecimal(delayed_retirement_social_security)
    social_security_survivor           = convertDecimal(social_security_survivor)

    await Assumptions.postRetirement(id, {
      ...data,
      early_retirement_social_security,
      delayed_retirement_social_security,
      social_security_survivor
    });
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchRetirementFunding = (id) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    const response = await Assumptions.getRetirementFunding(id);
    await dispatch(setRetirementFunding(response.data));
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postRetirementFunding = (id, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    let {
      ira_max_over_fifty,
      ira_max_under_fifty,
      married_ira_roth_income,
      max_401k_over_fifty,
      max_401k_under_fifty,
      sep_ira_max,
      unmarried_ira_roth_income} = data;

    ira_max_over_fifty        = convertDecimal(ira_max_over_fifty)
    ira_max_under_fifty       = convertDecimal(ira_max_under_fifty)
    married_ira_roth_income   = convertDecimal(married_ira_roth_income)
    max_401k_over_fifty       = convertDecimal(max_401k_over_fifty)
    max_401k_under_fifty      = convertDecimal(max_401k_under_fifty)
    sep_ira_max               = convertDecimal(sep_ira_max)
    unmarried_ira_roth_income = convertDecimal(unmarried_ira_roth_income)

    await Assumptions.postRetirementFunding(id, {
      ira_max_over_fifty,
      ira_max_under_fifty,
      married_ira_roth_income,
      max_401k_over_fifty,
      max_401k_under_fifty,
      sep_ira_max,
      unmarried_ira_roth_income
    });
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchEducation = (id) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    const response = await Assumptions.getEducation(id);
    await dispatch(setEducation(response.data));
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postEducation = (id, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    let { 
      education_payplan_percent,
      private_college_tuition,
      private_min_funding_amount,
      public_college_tuition,
      public_min_funding_amount } = data;

    education_payplan_percent = convertDecimal(education_payplan_percent);  
    private_college_tuition = convertDecimal(private_college_tuition)
    private_min_funding_amount = convertDecimal(private_min_funding_amount)
    public_college_tuition = convertDecimal(public_college_tuition)
    public_min_funding_amount = convertDecimal(public_min_funding_amount)

    await Assumptions.postEducation(id, {
      education_payplan_percent,
      private_college_tuition,
      private_min_funding_amount,
      public_college_tuition,
      public_min_funding_amount
    });
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchNetWorth = (id) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    const response = await Assumptions.getNetWorth(id);
    await dispatch(setNetWorth(response.data));
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postNetWorth = (id, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    let { average_net_worth_fifty_four,
      average_net_worth_forty_four,
      average_net_worth_over_seventy_four,
      average_net_worth_seventy_four,
      average_net_worth_sixty_four,
      average_net_worth_thirty_four,
      median_net_worth_fifty_four,
      median_net_worth_forty_four,
      median_net_worth_over_seventy_four,
      median_net_worth_seventy_four,
      median_net_worth_sixty_four,
      median_net_worth_thirty_four } = data;

    average_net_worth_fifty_four = convertDecimal(average_net_worth_fifty_four)
    average_net_worth_forty_four = convertDecimal(average_net_worth_forty_four)
    average_net_worth_over_seventy_four = convertDecimal(average_net_worth_over_seventy_four)
    average_net_worth_seventy_four = convertDecimal(average_net_worth_seventy_four)
    average_net_worth_sixty_four = convertDecimal(average_net_worth_sixty_four)
    average_net_worth_thirty_four = convertDecimal(average_net_worth_thirty_four)
    median_net_worth_fifty_four = convertDecimal(median_net_worth_fifty_four)
    median_net_worth_forty_four = convertDecimal(median_net_worth_forty_four)
    median_net_worth_over_seventy_four = convertDecimal(median_net_worth_over_seventy_four)
    median_net_worth_seventy_four = convertDecimal(median_net_worth_seventy_four)
    median_net_worth_sixty_four = convertDecimal(median_net_worth_sixty_four)
    median_net_worth_thirty_four = convertDecimal(median_net_worth_thirty_four)

    await Assumptions.postNetWorth(id, {
      average_net_worth_fifty_four,
      average_net_worth_forty_four,
      average_net_worth_over_seventy_four,
      average_net_worth_seventy_four,
      average_net_worth_sixty_four,
      average_net_worth_thirty_four,
      median_net_worth_fifty_four,
      median_net_worth_forty_four,
      median_net_worth_over_seventy_four,
      median_net_worth_seventy_four,
      median_net_worth_sixty_four,
      median_net_worth_thirty_four
    });
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchEstate = (id) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    const response = await Assumptions.getEstate(id);
    await dispatch(setEstate(response.data));
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postEstate = (id, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    let { estate_expenses,
      funeral_costs,
      joint_max_tax_exempt,
      single_max_tax_exempt,
      tax_exemption } = data;

    estate_expenses       = convertDecimal(estate_expenses)
    funeral_costs         = convertDecimal(funeral_costs)
    joint_max_tax_exempt  = convertDecimal(joint_max_tax_exempt)
    single_max_tax_exempt = convertDecimal(single_max_tax_exempt)
    tax_exemption         = convertDecimal(tax_exemption)

    await Assumptions.postEstate(id, {
      ...data,
      estate_expenses,
      funeral_costs,
      joint_max_tax_exempt,
      single_max_tax_exempt,
      tax_exemption
    });
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchAdditional = (id) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    const response = await Assumptions.getAdditional(id);
    await dispatch(setAdditional(response.data));
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postAdditional = (id, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    data.fdic_insurance_limit = convertDecimal(data.fdic_insurance_limit);
    await Assumptions.postAdditional(id, data);
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const revertAssumptionValue = (id, slug) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    await Assumptions.revertAssumption(id, slug);
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchAssumptionValue = (id, name) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    let response = null
    switch (name) {
      case 'budget':
        response = await Assumptions.getBudget(id);
        await dispatch(setBudget(response.data));
        break;

      case 'property':
        response = await Assumptions.getProperty(id);
        await dispatch(setProperty(response.data));
        break;

      case 'auto':
        response = await Assumptions.getAuto(id);
        await dispatch(setAuto(response.data));
        break;

      case 'umbrella':
        response = await Assumptions.getUmbrella(id);
        await dispatch(setUmbrella(response.data));
        break;

      case 'retirement':
        response = await Assumptions.getRetirement(id);
        await dispatch(setRetirement(response.data));
        break;

      case 'retirement-funding':
        response = await Assumptions.getRetirementFunding(id);
        await dispatch(setRetirementFunding(response.data));
        break;

      case 'education-funding':
        response = await Assumptions.getEducation(id);
        await dispatch(setEducation(response.data));
        break;

      case 'net-worth':
        response = await Assumptions.getNetWorth(id);
        await dispatch(setNetWorth(response.data));
        break;

      case 'estate-planning':
        response = await Assumptions.getEstate(id);
        await dispatch(setEstate(response.data));
        break;

      case 'additional':
        response = await Assumptions.getAdditional(id);
        await dispatch(setAdditional(response.data));
        break;

      default:
        await dispatch(setIsLoading(false));
        break;
    }
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const {
  setIsLoading,
  setBudget,
  setProperty,
  setUmbrella,
  setAuto,
  setRetirement,
  setRetirementFunding,
  setAdditional,
  setEducation,
  setEstate,
  setNetWorth
} = assumptionsSlice.actions

export default assumptionsSlice.reducer