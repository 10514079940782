const colors = [
  '#215E59',
  '#926D6D',
  '#FF665C',
  '#7A4B73',
  '#FD8A63',
  '#43378F',
  '#F3D9DA',
  '#DE8D9B',
  '#262453',
  '#9D9E62',
  '#F9D36B',
  '#D47E4D',
  '#E33544',
  '#BDCB74',
  '#40B7AD',
  '#215E59',
  '#926D6D',
  '#FF665C',
  '#7A4B73',
  '#FD8A63',
  '#43378F',
  '#F3D9DA',
  '#DE8D9B',
  '#262453',
  '#9D9E62',
  '#F9D36B',
  '#D47E4D',
  '#E33544',
  '#BDCB74',
  '#40B7AD'
]

export default colors;