import React from 'react'
import { Form, Formik } from 'formik'
import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { FormGroup, InputCurrency, Input } from '../shared/Form'
import { normalizeBoolean } from '../../utils/utils'

const UmbrellaInsuranceAdmin = ({ title, onSave, values, sending }) => {
  return (
    <Formik enableReinitialize initialValues={{ ...values }} onSubmit={(values) => onSave(values)}>
      {({ handleChange, handleBlur, setFieldValue, values }) => (
        <Form>
          <h4 className="form__main-title">{title}</h4>
          <div className="form__flow-wrap form__flow-wrap--column umbrella-h">
            <FormGroup name="current_net_worth" labelText="Current net worth">
              <InputCurrency
                name="current_net_worth"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.current_net_worth || 0}
                withoutSign
                icon
              />
            </FormGroup>
            <FormGroup name="has_umbrella_insurance" labelText="Do you own an umbrella insurance policy?">
              <RadioGroup
                name="has_umbrella_insurance"
                value={values.has_umbrella_insurance || false}
                onChange={(e) => {
                  setFieldValue('has_umbrella_insurance', normalizeBoolean(e.target.value));
                }}
                className="radio-list-inline"
              >
                <FormControlLabel value={false} control={<Radio />} label="No" />
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
              </RadioGroup>
            </FormGroup>
            <FormGroup name="current_face_value" labelText="Current Face value">
              <InputCurrency
                name="current_face_value"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.has_umbrella_insurance ? values.current_face_value : 0}
                withoutSign
                icon
                disabled={!values.has_umbrella_insurance}
              />
            </FormGroup>
            <FormGroup
              name="miles_travelled_per_day"
              labelText="Car miles traveled per day by longest commuter in household"
            >
              <InputCurrency
                name="miles_travelled_per_day"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.miles_travelled_per_day || 0}
                withoutSign
                icon
              />
            </FormGroup>
            <FormGroup
              name="anyone_convicted"
              labelText="Have you or anyone in your family been convicted of a misdemeanor, felony, DUI?"
            >
              <RadioGroup
                name="anyone_convicted"
                value={values.anyone_convicted || false}
                onChange={(e) => {
                  setFieldValue('anyone_convicted', normalizeBoolean(e.target.value));
                }}
                className="radio-list-inline"
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormGroup>
            <FormGroup
              name="high_risk_occupation"
              labelText="Do you, spouse or anyone in your care have a high-risk occupation where they can accidentally cause serious injury or death to another person?"
            >
              <RadioGroup
                name="high_risk_occupation"
                value={values.high_risk_occupation || false}
                onChange={(e) => {
                  setFieldValue('high_risk_occupation', normalizeBoolean(e.target.value));
                }}
                className="radio-list-inline"
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormGroup>
            <FormGroup name="own_or_rent_home" labelText="Do you own or rent a home?">
              <RadioGroup
                name="own_or_rent_home"
                value={values.own_or_rent_home || false}
                onChange={(e) => {
                  setFieldValue('own_or_rent_home', normalizeBoolean(e.target.value));
                }}
                className="radio-list-inline"
              >
                <FormControlLabel value={true} control={<Radio />} label="Own" />
                <FormControlLabel value={false} control={<Radio />} label="Rent" />
              </RadioGroup>
            </FormGroup>
            <FormGroup name="netWorth" labelText="Net worth">
              <InputCurrency
                name="netWorth"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.netWorth || 0}
                withoutSign
                icon
              />
            </FormGroup>
            <FormGroup name="netWorthWeight" labelText="Net worth weight">
              <Input
                name="netWorthWeight"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.netWorthWeight || 0}
              />
            </FormGroup>
            <FormGroup name="childrenRiskAge" labelText="Children risk age">
              <Input
                name="childrenRiskAge"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.childrenRiskAge || 0}
              />
            </FormGroup>
            <FormGroup name="childrenRiskEndsAge" labelText="Children risk ends age">
              <Input
                name="childrenRiskEndsAge"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.childrenRiskEndsAge || 0}
              />
            </FormGroup>
            <FormGroup name="childrenWeight" labelText="Children weight">
              <Input
                name="childrenWeight"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.childrenWeight || 0}
              />
            </FormGroup>
            <FormGroup name="carMilesPerDay" labelText="Car miles per day">
              <Input
                name="carMilesPerDay"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.carMilesPerDay || 0}
              />
            </FormGroup>
            <FormGroup name="carWeight" labelText="Car weight">
              <Input
                name="carWeight"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.carWeight || 0}
              />
            </FormGroup>
            <FormGroup name="legalWeight" labelText="Legal weight">
              <Input
                name="legalWeight"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.legalWeight || 0}
              />
            </FormGroup>
            <FormGroup name="homeWeight" labelText="Home weight">
              <Input
                name="homeWeight"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.homeWeight || 0}
              />
            </FormGroup>
            <FormGroup name="occupationWeight" labelText="Occupation weight">
              <Input
                name="occupationWeight"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.occupationWeight || 0}
              />
            </FormGroup>
            <FormGroup name="totalWeightTrigger" labelText="Total weight trigger">
              <Input
                name="totalWeightTrigger"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.totalWeightTrigger || 0}
              />
            </FormGroup>
            <FormGroup name="firstMillion" labelText="First million">
              <Input
                name="firstMillion"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstMillion || 0}
              />
            </FormGroup>
            <FormGroup name="eachAdditionalMillion" labelText="Each additional million">
              <Input
                name="eachAdditionalMillion"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.eachAdditionalMillion || 0}
              />
            </FormGroup>
          </div>
          <div className="form__button">
            <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
              Save
              {sending && <span className="spinner" />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default UmbrellaInsuranceAdmin
