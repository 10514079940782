import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Loading from '../../components/shared/Loading/Loading';
import { Button } from '@mui/material';
import {
  fetchCompany,
  fetchFilterStatisticsStates,
  fetchFilterStatisticsAgeRange,
  fetchFilterStatisticsCities,
  fetchStatisticsGraphData,
} from '../../redux/slices/companiesSlice';
import StatisticFilter from '../../components/Statistics/StatisticFilter';
import { optionsStacked } from '../../utils/optionsBar';
import { Bar } from 'react-chartjs-2';
import Modal from '../../components/shared/Modal/Modal';
import SVGIcon from '../../components/shared/SVGIcon/SVGIcon';
import filterIcon from '../../assets/images/icons/filter-list.svg';
import LayoutAdmin from '../../components/Layout/LayoutAdmin';
import { isEmpty, isNull } from 'lodash';

const backgroundColor = ['#FDB5A1', '#215E59', '#D4654D'];

const rolesList = [
    {
      id: 5,
      key: 5,
      value: 'superadmin_soft',
      label: 'ValueSoft SuperAdmin',
    },
    {
      id: 4,
      key: 4,
      value: 'superadmin_company',
      label: 'Company Instance SuperAdmin',
    },
    {
      id: 3,
      key: 3,
      value: 'company_admin',
      label: 'Company Admin',
    },
    {
      id: 2,
      key: 2,
      value: 'planner',
      label: 'Planner',
    },
    {
      id: 1,
      key: 1,
      value: 'user',
      label: 'Member',
    }
  ];

const AdminUserStatistics = ({ props, isAdmin }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { companyId } = useParams();
  const { role_id, id } = useSelector((state) => state.user.user);
  const { image, email, name } = useSelector(
    (state) => state.companies.company.info
  );
  const {
    isGraphLoading,
    netWorth,
    totalIncome,
    totalInheritance,
    totalInvestment,
    statisticsStates,
    statisticsCities,
    statisticsAgeRange,
  } = useSelector((state) => state.companies);
  const [isModalFilter, setModalFilter] = useState(false);
  const [statesOption, setStatesOption] = useState([]);
  const [citiesOption, setCitiesOption] = useState([]);
  const [stateValue, setStateValue] = useState([]);
  const [cityValue, setCityValue] = useState([]);
  const [roleName, setRoleName] = useState([]);
  const [roleValue, setRoleValue] = useState([]);
  const [marriedValue, setMarriedValue] = useState('');
  const [ageStartValue, setAgeStartValue] = useState('');
  const [ageFinishValue, setAgeFinishValue] = useState('');

  const [isMobile, setIsMobile] = useState(false);
  const mobileView = 999;

  const handleMobileSize = () => {
    mobileView >= window.innerWidth ? setIsMobile(true) : setIsMobile(false);
  };

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true);
    window.addEventListener('resize', handleMobileSize);

    return () => window.removeEventListener('resize', handleMobileSize);
  }, []);

  useEffect(() => {
    dispatch(fetchCompany(companyId));
    // eslint-disable-next-line
  }, []);

  useMemo(() => {
    async function fetch() {
      await dispatch(fetchStatisticsGraphData(companyId, {}));
    }

    if (companyId) {
      fetch();
    }
  }, [companyId, dispatch]);

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchFilterStatisticsStates(companyId));
      await dispatch(fetchFilterStatisticsAgeRange(companyId));
    }

    if (id) {
      fetch();
    }

    // eslint-disable-next-line
  }, [id]);

  const dataAnalysis = (data) => {
    if (data && !isNull(data)) {
      const { labels, datasets, title, usersId } = data;

      return {
        labels: labels,
        usersId,
        title,
        datasets: [
          {
            label: datasets.label,
            data: [...datasets.data],
            backgroundColor: backgroundColor[0],
            maxBarThickness: 100,
          },
        ],
      };
    }
  };

  const hendleClcik = (event, elements) => {
    if (elements[0]) {
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const label = chart.data.labels[element._index];
      const title = chart.data.title;
      const usersId = chart.data.usersId[element._index];
      chart.active = [];
      chart.lastActive = [];
      chart.reset();
      history.push({
        pathname: `/admin/company/${companyId}/users-statistics/result`,
        state: {
            usersId,
            title,
            total: label,
            filterParams: {
                state: stateValue,
                city: cityValue,
                age: [ageStartValue, ageFinishValue],
                married: marriedValue,
                roles: roleName
            }
        }
      });
    }
  };

  useEffect(() => {
    if (statisticsStates) {
      setStatesOption(Object.values(statisticsStates).filter((key) => key));
    }

    if (statisticsCities) {
      setCitiesOption(Object.values(statisticsCities).filter((key) => key));
    }
  }, [statisticsStates, statisticsCities]);

  useEffect(() => {
    async function fetchCity() {
      await dispatch(
        fetchFilterStatisticsCities(companyId, { states: stateValue.join(',') })
      );
    }

    if (stateValue) {
      fetchCity();
    }

    // eslint-disable-next-line
  }, [stateValue]);

  useEffect(() => {
    if (statisticsAgeRange) {
      setAgeStartValue(statisticsAgeRange?.age?.min);
      setAgeFinishValue(statisticsAgeRange?.age?.max);
    }
  }, [statisticsAgeRange]);

  const handleChangeState = (event) => {
    const {
      target: { value },
    } = event;
    setStateValue(typeof value === 'string' ? value.split(',') : value);
  };

  const handleChangeCity = (event) => {
    const {
      target: { value },
    } = event;
    setCityValue(typeof value === 'string' ? value.split(',') : value);
  };

  const handleMarriedValue = (event) => {
    const {
      target: { value },
    } = event;
    setMarriedValue(value);
  };

  const handleChangeRole = (event) => {
    const {
      target: { value },
    } = event;
    const valuesArr = value.map(item => rolesList.find(obj => obj.label === item)).map(item => item.value);
    setRoleValue(valuesArr);
    setRoleName(typeof value === 'string' ? value.split(',') : value);
  };

  const handleAgeStartValue = (event) => {
    const {
      target: { value },
    } = event;
    setAgeStartValue(value);
  };

  const handleAgeFinishValue = (event) => {
    const {
      target: { value },
    } = event;
    setAgeFinishValue(value);
  };

  const onResetFilter = async () => {
    await setStateValue([]);
    await setCityValue([]);
    await setAgeStartValue(statisticsAgeRange?.age?.min);
    await setAgeFinishValue(statisticsAgeRange?.age?.max);
    await setMarriedValue('');
    await setRoleName([]);
    await setRoleValue([]);
  };

  const onApplyFilter = () => {
    dispatch(
        fetchStatisticsGraphData(companyId, {
        states: stateValue.join(','),
        cities: cityValue.join(','),
        married: marriedValue,
        age: `${ageStartValue}-${ageFinishValue}`,
        roles: roleValue.join(','),
      })
    );
  };

  if (role_id && role_id > 3) return <Redirect to='/404' />;

  return (
    <LayoutAdmin companyId={companyId} isAdmin={isAdmin}>
      <div className='member-comapny'>
        <div className='member-comapny__info'>
          <div className='member-comapny__logo'>
            <img src={image} alt='Company logo' />
          </div>
          <div className='member-comapny__title'>
            <h3>{name}</h3>
            {!isMobile && (
              <p>
                Feedback Email: <span>{email}</span>
              </p>
            )}
          </div>
        </div>
        {isMobile && (
          <div className='mobile-feedback'>
            Feedback Email: <span>{email}</span>
          </div>
        )}
      </div>
      <h3 className='mobile-large-hidden'>Users statistics</h3>
      <p className='users-statistics__description'>
        Use these optional filters to narrow down your comparison to a specific
        user profile. Unused filters will return all user types for that
        specific filter.
      </p>
      <div className='mb--small'>
        <Grid container wrap='wrap' justifyContent='space-between' spacing={1}>
          <Grid item xs={12} sm='auto'>
            <div className='mobile-large-visible'>
              <div className='form__area form__area--large'>
                <Button
                  startIcon={<SVGIcon icon={filterIcon} color='#FD8A63' />}
                  variant='outlined'
                  className='analysis-filter-button'
                  onClick={() => setModalFilter(true)}
                >
                  User filters
                </Button>
              </div>
            </div>
            <div className='mobile-large-hidden'>
              <div className='form__area form__area--large'>
                <StatisticFilter
                  optionsState={statesOption}
                  stateValue={stateValue}
                  handleStateOptions={handleChangeState}
                  optionsCity={citiesOption}
                  cityValue={cityValue}
                  handleCityOptions={handleChangeCity}
                  handleMarriedValue={handleMarriedValue}
                  marriedValue={marriedValue}
                  ageStartValue={ageStartValue}
                  handleAgeStartValue={handleAgeStartValue}
                  ageFinishValue={ageFinishValue}
                  handleAgeFinishValue={handleAgeFinishValue}
                  handleRoleOptions={handleChangeRole}
                  roleValue={roleName}
                  onResetFilter={onResetFilter}
                  onApplyFilter={onApplyFilter}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <h2 className='mobile-large-visible'>Users statistics</h2>
      {!isEmpty(netWorth) && (
        <div className='users-statistics__graph analysis__container'>
          <div className='analysis__box'>
            <div className='analysis__box-title'>
              <h6>Total net worth</h6>
              <div className='analysis__box-legend'>
                <div className='analysis__box-legend-label analysis__box-legend-label-color2'>
                  User
                </div>
              </div>
            </div>
            {isGraphLoading ? (
              <Loading xsmall />
            ) : (
              <Bar
                data={dataAnalysis(netWorth)}
                options={optionsStacked('10', hendleClcik, true)}
                height={120}
              />
            )}
          </div>
        </div>
      )}

      {!isEmpty(totalIncome) && (
        <div className='users-statistics__graph analysis__container'>
          <div className='analysis__box'>
            <div className='analysis__box-title'>
              <h6>Total household income</h6>
              <div className='analysis__box-legend'>
                <div className='analysis__box-legend-label analysis__box-legend-label-color2'>
                  Users and spouse
                </div>
              </div>
            </div>
            {isGraphLoading ? (
              <Loading xsmall />
            ) : (
              <Bar
                data={dataAnalysis(totalIncome)}
                options={optionsStacked('10', hendleClcik, true)}
                height={120}
              />
            )}
          </div>
        </div>
      )}

      {!isEmpty(totalInheritance) && (
        <div className='users-statistics__graph analysis__container'>
          <div className='analysis__box'>
            <div className='analysis__box-title'>
              <h6>Inheritance amount ranges</h6>
              <div className='analysis__box-legend'>
                <div className='analysis__box-legend-label analysis__box-legend-label-color2'>
                  Users
                </div>
              </div>
            </div>
            {isGraphLoading ? (
              <Loading xsmall />
            ) : (
              <Bar
                data={dataAnalysis(totalInheritance)}
                options={optionsStacked('10', hendleClcik, true)}
                height={120}
              />
            )}
          </div>
        </div>
      )}

      {!isEmpty(totalInvestment) && (
        <div className='users-statistics__graph analysis__container'>
          <div className='analysis__box'>
            <div className='analysis__box-title'>
              <h6>Investment account balances ranges</h6>
              <div className='analysis__box-legend'>
                <div className='analysis__box-legend-label analysis__box-legend-label-color2'>
                  Users
                </div>
              </div>
            </div>
            {isGraphLoading ? (
              <Loading xsmall />
            ) : (
              <Bar
                data={dataAnalysis(totalInvestment)}
                options={optionsStacked('10', hendleClcik, true)}
                height={120}
              />
            )}
          </div>
        </div>
      )}
      <Modal fixHeight open={isModalFilter} close={() => setModalFilter(false)}>
        <div>
            <h2>Filter</h2>
            <p className='text-left'>Use these optional filters to narrow down your comparison to a specific user profile. Unused filters will return all user types for that specific filter.</p>
        </div>
        <StatisticFilter
          optionsState={statesOption}
          stateValue={stateValue}
          handleStateOptions={handleChangeState}
          optionsCity={citiesOption}
          cityValue={cityValue}
          handleCityOptions={handleChangeCity}
          handleMarriedValue={handleMarriedValue}
          marriedValue={marriedValue}
          ageStartValue={ageStartValue}
          handleAgeStartValue={handleAgeStartValue}
          ageFinishValue={ageFinishValue}
          handleAgeFinishValue={handleAgeFinishValue}
          roleValue={roleName}
          handleRoleOptions={handleChangeRole}
          onResetFilter={onResetFilter}
          onApplyFilter={() => {
            onApplyFilter();
            setModalFilter(false);
          }}
        />
      </Modal>
    </LayoutAdmin>
  );
};

export default AdminUserStatistics;
