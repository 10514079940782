import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Formik } from 'formik'
import numeral from 'numeral'
import * as Yup from 'yup'
import Layout from '../../components/Layout/Layout'
import {AddButton, BackButton} from '../../components/shared/Buttons'
import Tooltip from '../../components/shared/Tooltip/Tooltip'
import { Slider, Input, FormGroup, FormSelect, InputCurrency } from '../../components/shared/Form'
import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import ModalBox from '../../components/shared/Modal/Modal'
import {
  fetchCalculators,
  fetchListResults,
  fetchCurrentResult,
  saveItemResult,
  removeItemResult, setCurrentCalculator
} from '../../redux/slices/calculatorSlice'
import { Bar } from 'react-chartjs-2'
import { optionsCalculator } from '../../utils/optionsBar'
import Tabs, { TabPanel } from '../../components/shared/Tabs/Tabs'
import { normalizeBoolean, convertDecimal } from '../../utils/utils'
import { fetchUmbrellaChildren, postUmbrellaChildren, removeUmbrellaChild, setChild, setLoading } from '../../redux/slices/childrenSlice'
import { setMode } from '../../redux/slices/modeSlice'
import { fetchUmbrellaInsuranceAdmin } from '../../redux/slices/calculatorsSetupSlice'

const UmbrellaInsurance = ({ props }) => {
  const dispatch = useDispatch()
  const [tabActive, setActiveTab] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const [isSavedModal, setIsSavedModal] = useState(false)
  const { id, company_id } = useSelector(state => state.user.user)
  const { calculators, current, listExisting, loading } = useSelector(state => state.calculator)
  const children = useSelector(state => state.children.current)
  const currentChild = useSelector(state => state.children.currentChild)
  const sending = useSelector(state => state.children.loading)
  const mode = useSelector(state => state.mode.mode)
  const baseValues = useSelector(state => state.calculatorsSetup.umbrellaInsuranceAdmin);
  const [currentId, setCurrentId] = useState('')
  const [values, setValues] = useState(null)
  const [countValues, setCountValues] = useState({
    total: 0,
    perYear: 0
  })

  useEffect(() => {
    if(baseValues){
      setValues({
        currentId: '',
        name: '',
        ...baseValues
      })
    }
  // eslint-disable-next-line
  }, [baseValues])

  const data = {
    labels: [`$ ${countValues.total}`],
    datasets: [
      {
        data: [countValues.total],
        backgroundColor: ['#215E59'],
        maxBarThickness: 114
      }
    ],
  }

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchUmbrellaInsuranceAdmin(company_id))
      await dispatch(fetchUmbrellaChildren())
      await dispatch(fetchCalculators({
        path: 'umbrella-insurance'
      }));
      await dispatch(fetchListResults('umbrella-insurance'))
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    if (values) {
      let countChildren  = children && children.filter(i => i.age >= +values.childrenRiskAge).length
    let countChildren2 = children && children.filter(i => i.age >= +values.childrenRiskEndsAge).length

    let sum = (countChildren - countChildren2) * +values.childrenWeight
      + ((values.own_or_rent_home ? 1 : 0) * +values.homeWeight)
      + ((values.miles_travelled_per_day >= +values.carMilesPerDay ? 1 : 0) * +values.carWeight)
      + ((values.high_risk_occupation ? 1 : 0) * +values.occupationWeight)
      + ((values.anyone_convicted ? 1 : 0) * +values.legalWeight)

    let total = (sum >= +values.totalWeightTrigger && values.current_net_worth >= values.netWorth)
                  ? values.current_net_worth - +values.current_face_value > 0
                    ? values.current_net_worth - +values.current_face_value
                    : 0
                  : 0
    let perYear = +values.firstMillion + (values.current_net_worth >= 1000000 && ((values.current_net_worth - 1000000)/1000000) * +values.eachAdditionalMillion)

    setCountValues({
      total: +total.toFixed(2),
      perYear: +perYear.toFixed(2)
    })
    }
    // eslint-disable-next-line
  }, [values, children])

  useEffect(() => {
    async function fetchNewData() {
      if (current) {
        setValues({
          name: current ? current.name : '',
          currentId: currentId,
          current_net_worth: +current.current_net_worth,
          has_umbrella_insurance: current.has_umbrella_insurance,
          current_face_value: +current.current_face_value,
          miles_travelled_per_day: +current.miles_travelled_per_day,
          anyone_convicted: current.anyone_convicted,
          high_risk_occupation: current.high_risk_occupation,
          own_or_rent_home: current.own_or_rent_home
        })
      }
    }

    if (currentId) {
      fetchNewData()
    }
    // eslint-disable-next-line
  }, [currentId, current])

  const handleInputChange = e => {
    setValues({
      ...values,
      [e.target.name]: +e.target.value
    })
  }

  const handleCurrencyChange = e => {
    setValues({
      ...values,
      [e.target.name]: +convertDecimal(e.target.value)
    })
  }

  const changeCurrentData = async e => {
    await setCurrentId(e.target.value)
    await dispatch(fetchCurrentResult(e.target.value, 'umbrella-insurance'))
  }

  const onSave = async () => {
    dispatch(saveItemResult(values, 'umbrella-insurance')).then(res => {
      const calculator = res?.data?.data;

      if(calculator) {
        setIsSavedModal(true);
        setCurrentId(calculator.id);
        dispatch(setCurrentCalculator(calculator));
      }
    }).finally(() => {
      setIsOpen(false);
    })
  }

  const resetForm = () => {
    setValues({
      currentId: '',
      name: '',
      ...baseValues
    })
    setCurrentId('')
  }

  const removeResult = async () => {
    await dispatch(removeItemResult(currentId, 'umbrella-insurance'))
    await setCurrentId('')
    await setIsDeleteModal(false)
    await setActiveTab(0)
    await setValues({
      ...baseValues
    })
  }

  const saveChild = async (data, id) => {
    await dispatch(setLoading(true))
    if (id) {
      await dispatch(postUmbrellaChildren(id, data));
    } else {
      await dispatch(postUmbrellaChildren(null, data))
    }
    await dispatch(fetchUmbrellaChildren())
    await dispatch(setLoading(false))
    await setIsOpenEditModal(false)
  }

  const removeChildData = async id => {
    await dispatch(removeUmbrellaChild(id))
    await dispatch(fetchUmbrellaChildren())
    await dispatch(setMode(null))
    await setActiveTab(0)
    await setIsDeleteModal(false)
  }

  const openRemoveModal = (open, mode, id) => {
    if (id) {
      dispatch(fetchUmbrellaChildren(id))
    }
    dispatch(setMode(mode))
    setIsDeleteModal(open)
  }

  return (
    <Layout decor>
      <BackButton title="Back to Calculators" path="/calculators" className="back-button--position" />
      <div className="calculators__title">
        <div>
          {calculators && <h4>{calculators.name}</h4>}
          {calculators &&
          <Tooltip
            title={
              <>
                <h6>{calculators.name}</h6>
                {calculators.tooltip}
                <hr className="tooltip__line"/>
                <h6>Example</h6>
                {calculators.example}
              </>
            }
          />}
        </div>

        {listExisting && listExisting.length > 0 && <div className="calculators__title-select">
          <FormSelect
            options={listExisting}
            id="currentId"
            value={currentId}
            medium
            name="currentId"
            onChange={changeCurrentData}
            placeholder="Select result"
          />
        </div>}
      </div>

      <div className="calculators__top-panel">
        <div className="calculators__top-panel-tabs">
          {children && children.length > 0 &&
            <Tabs
              handleChange={(event, newValue) => {
                setActiveTab(newValue)
              }}
              variant="scrollable"
              value={tabActive}
              tabs={[
                {title: 'Profile info'},
                ...children.map(child => {
                  return {
                    'title': child.name
                  }
                })
              ]}
            />
          }
        </div>
        <AddButton
          onClick={() => {
            setIsOpenEditModal(true)
            dispatch(setChild(null))
          }}
          title="Add child"
          isAdaptive={false}
        />
      </div>
    {values &&  
      <div className="calculators__holder">
        <div className="calculators__content">
          <TabPanel value={tabActive} index={0}>
            <h6 className="calculators__subtitle">Step 1: Input your info</h6>
            <div className="calculators__area">
              <InputCurrency
                name="current_net_worth"
                value={values.current_net_worth.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={5000000}
                  step={10}
                  label="Current net worth"
                  value={+values.current_net_worth}
                  onChange={(e, newValue) => setValues({...values, current_net_worth: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area-label">
              Do you own an umbrella insurance policy?
            </div>
            <FormGroup>
              <RadioGroup
                name="has_umbrella_insurance"
                value={values.has_umbrella_insurance || false}
                onChange={e => {
                  setValues({
                    ...values,
                    [e.target.name]: normalizeBoolean(e.target.value),
                    current_face_value: 0
                  })
                }}
                className="radio-list-inline"
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormGroup>
            <div className="calculators__area">
              <InputCurrency
                name="current_face_value"
                value={values.has_umbrella_insurance ? values.current_face_value : 0}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
                disabled={!values.has_umbrella_insurance}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100000000}
                  step={10}
                  label="Current Face value"
                  value={values.has_umbrella_insurance ? +values.current_face_value : 0}
                  onChange={(e, newValue) => setValues({...values, current_face_value: newValue})}
                  disabled={!values.has_umbrella_insurance}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="miles_travelled_per_day"
                value={values.miles_travelled_per_day.toString()}
                small
                smallHeight
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={300}
                  step={1}
                  label="Car miles traveled per day by longest commuter in household"
                  value={+values.miles_travelled_per_day}
                  onChange={(e, newValue) => setValues({...values, miles_travelled_per_day: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area-label">
              Have you or anyone in your family been convicted of a misdemeanor, felony, DUI?
            </div>
            <FormGroup>
              <RadioGroup
                name="anyone_convicted"
                value={values.anyone_convicted || false}
                onChange={e => {
                  setValues({
                    ...values,
                    [e.target.name]: normalizeBoolean(e.target.value)
                  })
                }}
                className="radio-list-inline"
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormGroup>
            <div className="calculators__area-label">
              Do you, spouse or anyone in your care have a high-risk occupation where they can accidentally cause serious injury or death to another person?
            </div>
            <FormGroup>
              <RadioGroup
                name="high_risk_occupation"
                value={values.high_risk_occupation || false}
                onChange={e => {
                  setValues({
                    ...values,
                    [e.target.name]: normalizeBoolean(e.target.value)
                  })
                }}
                className="radio-list-inline"
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormGroup>
            <div className="calculators__area-label">
              Do you own or rent a home?
            </div>
            <FormGroup>
              <RadioGroup
                name="own_or_rent_home"
                value={values.own_or_rent_home || false}
                onChange={e => {
                  setValues({
                    ...values,
                    [e.target.name]: normalizeBoolean(e.target.value)
                  })
                }}
                className="radio-list-inline"
              >
                <FormControlLabel value={true} control={<Radio />} label="Own" />
                <FormControlLabel value={false} control={<Radio />} label="Rent" />
              </RadioGroup>
            </FormGroup>

            <div className="calculators__buttons">
              <Button variant="contained" size="large" color="primary" onClick={() => setIsOpen(true)}>Save result</Button>
              {currentId && <Button variant="contained" size="large"  onClick={() => openRemoveModal(true, null)}>Remove result</Button>}
              <Button size="large" className="MuiButton-textGray" onClick={() => resetForm()}>Reset</Button>
            </div>
          </TabPanel>

          {children && children.length > 0 && children.map((child, index) =>
            <TabPanel value={tabActive} index={index+1} key={child.id}>
              <div className="calculators__area-label">Name</div>
              <h5>{child.name}</h5>
              <div className="calculators__area-label">Age</div>
              <h5>{child.age}</h5>
              <div className="calculators__buttons">
                <Button variant="contained" size="large" color="primary" onClick={() => {
                  setIsOpenEditModal(true)
                  dispatch(fetchUmbrellaChildren(child.id))
                }}>
                  Edit
                </Button>
                <Button size="large" className="MuiButton-textGray" onClick={() => openRemoveModal(true, 'edit', child.id)}>Delete</Button>
              </div>
            </TabPanel>
          )}
        </div>
        <div className="calculators__preview">
          <h6 className="calculators__subtitle">Results</h6>
          <div className="calculators__preview-box">
            <div className="calculators__legend">
              <div className="calculators__legend-label">Umbrella insurance needed</div>
            </div>
            <Bar data={data} options={optionsCalculator(null, true, true, false, true)} />
            <div className="calculators__preview-box-text">
              {countValues.total > 0
                ? <div>
                    Please purchase an additional {numeral(countValues.total).format('$0,0.00')} in umbrella insurance, which will cost you approximately {numeral(countValues.perYear).format('$0,0.00')} per year.
                  </div>
                : <div>No action is needed</div>
              }
            </div>
          </div>
        </div>
      </div>
    }
      <ModalBox
        open={isOpen}
        close={() => setIsOpen(false)}
        onClick={onSave}
        button="Save"
        title="Save Result"
        isLoading={loading}
        isDisabled={values && !values.name && !values.currentId}
      >
        <FormGroup name="name" labelText="Name">
          <Input
            name='name'
            value={values ? values.name : ''}
            placeholder="Type a name of result"
            onChange={e => setValues({
              ...values,
              name: e.target.value
            })}
          />
        </FormGroup>
        {listExisting && listExisting.length > 0 &&
        <>
          <p className="text-center">or</p>
          <FormGroup labelText="Select  Existing Result">
            <FormSelect
              options={listExisting}
              value={values ? values.currentId : ''}
              name="currentId"
              onChange={e => setValues({
                ...values,
                currentId: e.target.value,
                name: listExisting.find(i => i.value === e.target.value).label
              })}
              placeholder="Select Existing Result"
            />
          </FormGroup>
        </>
        }
      </ModalBox>
      <ModalBox
        small
        open={isDeleteModal}
        close={() => openRemoveModal(false, null)}
        onClick={mode === 'edit' ? () => removeChildData(currentChild && currentChild.id) : () => removeResult()}
        button="Remove"
        title={`Do you want to remove ${mode === 'edit' ? currentChild && currentChild.name : current && current.name}?`}
      />
      <ModalBox
        open={isOpenEditModal}
        close={() => {
          setIsOpenEditModal(false)
          dispatch(setChild(null))
        }}
        title={currentChild ? currentChild.name : 'Child'}
      >
        <Formik
          enableReinitialize
          initialValues={currentChild
            ? {...currentChild}
            : {
              name: '',
              age: null
            }
          }
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Required'),
            age: Yup.number().integer('Please check this value').typeError('Age must be a integer number').required('Required')
          })}
          onSubmit={data => saveChild(data, currentChild && currentChild.id)}
        >
          {({ errors, handleChange, handleBlur, values, touched }) => (
            <Form>
              <FormGroup name="name" labelText="First name">
                <Input
                  name="name"
                  placeholder="Type a name"
                  type="text"
                  onChange={handleChange}
                  value={values.name || ''}
                  onBlur={handleBlur}
                  hasError={!!errors.name && touched.name}
                  disabled={loading}
                />
                {touched.name && errors.name && <span className="form__msg-error">{errors.name}</span>}
              </FormGroup>
              <FormGroup name="age" labelText="Age">
                <Input
                  name="age"
                  placeholder="Type child’s age"
                  type="text"
                  onChange={handleChange}
                  value={values.age || ''}
                  onBlur={handleBlur}
                  hasError={!!errors.age && touched.age}
                  disabled={loading}
                />
                {touched.age && errors.age && <span className="form__msg-error">{errors.age}</span>}
              </FormGroup>
              <div className="form__button form__button--center">
                <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                  Save
                  {sending && <span className="spinner" />}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBox>

      <ModalBox
        small
        open={isSavedModal}
        close={() => setIsSavedModal(false)}
        button="Ok"
        onClick={() => setIsSavedModal(false)}
      >
        <div>
          {calculators && calculators.iconLeft &&
          <div className="modal__title-iconLeft">
            <img src={calculators.iconLeft} alt={calculators.name} />
          </div>}
          <h4>Your calculator scenario has been saved.</h4>
          <p>You can access your saved scenarios through the “Select result” dropdown menu at the top of each calculator page.</p>
        </div>
      </ModalBox>
    </Layout>
  )
}

export default UmbrellaInsurance
