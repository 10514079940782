import React from 'react'
import { Form, Formik } from 'formik'
import { Button } from "@mui/material";
import { FormGroup, Input } from '../../components/shared/Form'
import Percentage from '../../assets/images/icons/percentage.svg'

const Budget = ({ title, onSave, values, coreValues, validationSchema, sending, noEdit }) => {
  
  return (
    <Formik
      enableReinitialize
      initialValues={{ ...values }}
      validationSchema={validationSchema}
      onSubmit={(values) => onSave(values)}
    >
      {({ errors, handleChange, handleBlur, values, touched }) => (
        <Form>
          <h4>{title}</h4>
          <div className="form__flow-wrap">
            <FormGroup name="emergency_fund" labelText="Emergency fund (months)">
              <Input
                name="emergency_fund"
                placeholder="Type months"
                type="number"
                onChange={handleChange}
                value={values.emergency_fund || ''}
                onBlur={handleBlur}
                className={coreValues?.emergency_fund && !noEdit && 'form__input--core'}
                hasError={!!errors.emergency_fund && touched.emergency_fund}
              />
              {touched.emergency_fund && errors.emergency_fund ? (
                <span className="form__msg-error">{errors.emergency_fund}</span>
              ) : coreValues && coreValues?.emergency_fund && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues?.emergency_fund}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="mortgage_to_income" labelText="Mortgage to income ratio">
              <Input
                name="mortgage_to_income"
                placeholder="Type"
                type="number"
                onChange={handleChange}
                value={values.mortgage_to_income || ''}
                onBlur={handleBlur}
                iconName={Percentage}
                className={coreValues?.mortgage_to_income && !noEdit && 'form__input--core'}
                hasError={!!errors.mortgage_to_income && touched.mortgage_to_income}
              />
              {touched.mortgage_to_income && errors.mortgage_to_income ? (
                <span className="form__msg-error">{errors.mortgage_to_income}</span>
              ) : coreValues && coreValues.mortgage_to_income && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.mortgage_to_income}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="savings_rate" labelText="Savings rate">
              <Input
                name="savings_rate"
                placeholder="Type savings rate"
                type="number"
                onChange={handleChange}
                value={values.savings_rate || ''}
                onBlur={handleBlur}
                iconName={Percentage}
                className={coreValues?.savings_rate && !noEdit && 'form__input--core'}
                hasError={!!errors.savings_rate && touched.savings_rate}
              />
              {touched.savings_rate && errors.savings_rate ? (
                <span className="form__msg-error">{errors.savings_rate}</span>
              ) : coreValues && coreValues.savings_rate && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.savings_rate}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="fixed_mortgage_rate" labelText="Fixed mortgage rate">
              <Input
                name="fixed_mortgage_rate"
                placeholder="Type fixed mortgage rate"
                type="number"
                onChange={handleChange}
                value={values.fixed_mortgage_rate || ''}
                onBlur={handleBlur}
                iconName={Percentage}
                className={coreValues?.fixed_mortgage_rate && !noEdit && 'form__input--core'}
                hasError={!!errors.fixed_mortgage_rate && touched.fixed_mortgage_rate}
              />
              {touched.fixed_mortgage_rate && errors.fixed_mortgage_rate ? (
                <span className="form__msg-error">{errors.fixed_mortgage_rate}</span>
              ) : coreValues && coreValues.fixed_mortgage_rate && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.fixed_mortgage_rate}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="inflation_rate" labelText="Inflation rate">
              <Input
                name="inflation_rate"
                placeholder="Type inflation rate"
                type="number"
                onChange={handleChange}
                value={values.inflation_rate || ''}
                onBlur={handleBlur}
                iconName={Percentage}
                className={coreValues?.inflation_rate && !noEdit && 'form__input--core'}
                hasError={!!errors.inflation_rate && touched.inflation_rate}
              />
              {touched.inflation_rate && errors.inflation_rate ? (
                <span className="form__msg-error">{errors.inflation_rate}</span>
              ) : coreValues && coreValues.inflation_rate && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.inflation_rate}</span>
              ) : (
                ''
              )}
            </FormGroup>
          </div>
          <div className="form__button">
            <Button variant="contained" size="large" color="primary" type="submit" disabled={sending || noEdit}>
              Save
              {sending && <span className="spinner" />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default Budget
