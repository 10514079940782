import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'classnames'
import DatePicker from 'react-date-picker'
import SVGIcon from '../../../components/shared/SVGIcon/SVGIcon'
import calender from '../../../assets/images/icons/calender.svg'

const InputDatePicker = ({
 value,
 name,
 disabled,
 type,
 setFieldValue,
 hasError,
 small
}) => {
  const classNames = clsx({
    'react-date-picker--error' : hasError,
    'react-date-picker--small' : small
  })

  return (
    <DatePicker
      format="MM-dd-y"
      onChange={(val) => {
        setFieldValue(name, val);
      }}
      className={classNames}
      value={value}
      monthPlaceholder="mm"
      yearPlaceholder="yyyy"
      dayPlaceholder="dd"
      calendarIcon={<SVGIcon icon={calender} color="#8C8C8C" />}
    />
  );
};

InputDatePicker.propTypes = {
  setFieldValue: PropTypes.func,
  value:  PropTypes.any,
  name: PropTypes.string,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
};

export default InputDatePicker;
