import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import isArray from 'lodash/isArray'
import Layout from '../../../components/Layout/Layout'
import { BackButton } from '../../../components/shared/Buttons'
import { useDispatch, useSelector } from 'react-redux'
import { fetchObjectives, deactivateObjective } from '../../../redux/slices/futureSlice'
import { Button, Switch } from '@mui/material'
import TreeSubItem from '../../../components/Tree/TreeSubItem'
import ModalBox from '../../../components/shared/Modal/Modal'
import { setIsOpen } from '../../../redux/slices/modalSlice'
import TreeImage from '../../../assets/images/tree/future/Future-tree.png'
import { fetchUserFamilyAssets } from '../../../redux/slices/userSlice'
import { fetchTotalPercent } from '../../../redux/slices/percentagesSlice'
import TreeCount from '../../../components/Tree/TreeCount'

const Objectives = ({ props }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [isMobile, setIsMobile] = useState(false)
  const { id } = useSelector(state => state.user.user)
  const isBasic = useSelector((state) => state.user.user.plan_type === 1);
  const totalPercent = useSelector(state => state.percentages.total)
  const objectives = useSelector(state => state.future.objectives)
  const isOpen = useSelector(state => state.modal.isOpen)
  const { plan_to_purchase } = useSelector(state => state.user.family.assets)
  const [isCheck, setCheck] = useState(false)
  const loading = useSelector(state => state.sending.isSending)
  const mobileView = 999

  const handleResize = () => {
    (mobileView >= window.innerWidth) ? setIsMobile(true) : setIsMobile(false)
  }

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchUserFamilyAssets(id))
      await dispatch(fetchObjectives())
      //await dispatch(fetchPercentages())
      await dispatch(fetchTotalPercent(isBasic))
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const close = () => dispatch(setIsOpen({isOpen: false}))

  const handleCheck = () => {
    setCheck(true);
  }

  const handleConfirm = () => {
    dispatch(deactivateObjective(props));
    setCheck(false);
  }

  const onClose = () => {
    setCheck(false);
  }

  const handleClick = () => {
    if(objectives && objectives.length > 6) {
      return dispatch(setIsOpen({isOpen: true}))
    }

    return history.push('/objectives/create')
  }

  return (
    <Layout
      totalPercent={totalPercent}
      tree
      isBlockedPage={objectives && !plan_to_purchase}
    >
      <div className="tree__sticky-holder">
        <BackButton
          title={isMobile ? "Back" : "Back to Tree"}
          path={isMobile ? "/future" : "/input"}
          className="back-button--position"
        />
        <div className="tree__title tree__title--progress-bar">
          <div className="tree__title-main">
            <h2>Objectives</h2>
            <TreeCount
              value={objectives && objectives.length > 0 ? 100 : "0"}
              loading={loading}
              big={!isMobile}
            />
          </div>
          {isMobile && objectives && objectives.length === 0 && (
            <div className="tree__switcher">
              <Switch
                className="custom-switcher"
                checked={isCheck}
                onChange={handleCheck}
              />
              I do not have any objectives to add.
            </div>
          )}
          {!isMobile && (
            <>
              <p className="tree__title-other">
                Fill in information to complete this step.
              </p>
              <Button
                variant="contained"
                className="tree-bar"
                size="small"
                onClick={handleClick}
                color="primary"
              >
                Add asset
              </Button>
              {objectives && objectives.length === 0 && (
                <div className="tree__switcher">
                  <Switch
                    className="custom-switcher"
                    checked={isCheck}
                    onChange={handleCheck}
                  />
                  I do not have any objectives to add.
                </div>
              )}
            </>
          )}
        </div>
        <div className="tree tree--sub-page">
          {!isMobile && (
            <div className="tree__base">
              <img src={TreeImage} alt="ValueSoft Tree Insurance" />
            </div>
          )}
          {objectives &&
            isArray(objectives) &&
            objectives.map((item, i) => (
              <React.Fragment key={item.id}>
                <TreeSubItem
                  key={item.id}
                  path={`/objectives/${item.id}`}
                  title={item.name}
                  count={100}
                  value={`objective-${i + 1}`}
                  isLoading={loading}
                />
              </React.Fragment>
            ))}
        </div>

        {isMobile && (
          <div className="tree__add-button">
            <Button
              variant="contained"
              size="small"
              onClick={handleClick}
              color="primary"
            >
              {!isMobile && 'Add asset'}
            </Button>
          </div>
        )}
      </div>

      <ModalBox
        small
        open={isOpen}
        close={close}
        onClick={() => {
          dispatch(setIsOpen({ isOpen: false }));
          history.push("/input");
        }}
        button="Back to tree"
        title="Sorry"
        children={<p>The limit of adding new sections is reached.</p>}
      />
      <ModalBox
        small
        open={isCheck}
        close={onClose}
        onClick={handleConfirm}
        button="Confirm"
        closeButton="Cancel"
        title="Objectives step"
        children={
          <p>
            This step will be disabled, but you can activate it any time by
            clicking on it in the your input.
          </p>
        }
      />
    </Layout>
  );
};

export default Objectives;
