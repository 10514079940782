import { createSlice } from '@reduxjs/toolkit'
import { CalculatorsSetup } from '../../api/Api'
import { setSending } from './sendingSlice'
import { convertDecimal } from '../../utils/utils'
import { setIsOpen } from './modalSlice'
import { setMode } from './modeSlice'
import { setIsRelogin } from './loadingSlice';

const calculatorsSetupSlice = createSlice({
  name: 'calculatorsSetup',
  initialState: {
    isLoading: false,
    mortgageAdmin: null,
    incomeAndExpensesAdmin: null,
    ownVsRentAdmin: null,
    carLeaseVsLeaseEndAdmin: null,
    carLeasePaymentAdmin: null,
    lifeInsuranceAdmin: null,
    timeToBuyHomeAdmin: null,
    homeAffordabilityAdmin: null,
    homeEvaluationAdmin: null,
    valueCircleAdmin: null,
    investmentFeesAdmin: null,
    umbrellaInsuranceAdmin: null,
    homeRefinancingAdmin: null,
    healthcareCoverageAdmin: null
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setMortgageAdmin: (state, action) => {
      state.mortgageAdmin = action.payload
    },
    setIncomeAndExpensesAdmin: (state, action) => {
      state.incomeAndExpensesAdmin = action.payload
    },
    setOwnVsRentAdmin: (state, action) => {
      state.ownVsRentAdmin = action.payload
    },
    setCarLeaseVsLeaseEndAdmin: (state, action) => {
      state.carLeaseVsLeaseEndAdmin = action.payload
    },
    setCarLeasePaymentAdmin: (state, action) => {
      state.carLeasePaymentAdmin = action.payload
    },
    setLifeInsuranceAdmin: (state, action) => {
      state.lifeInsuranceAdmin = action.payload
    },
    setTimeToBuyHomeAdmin: (state, action) => {
      state.timeToBuyHomeAdmin = action.payload
    },
    setHomeAffordabilityAdmin: (state, action) => {
      state.homeAffordabilityAdmin = action.payload
    },
    setHomeEvaluationAdmin: (state, action) => {
      state.homeEvaluationAdmin = action.payload
    },
    setValueCircleAdmin: (state, action) => {
      state.valueCircleAdmin = action.payload
    },
    setInvestmentFeesAdmin: (state, action) => {
      state.investmentFeesAdmin = action.payload
    },
    setUmbrellaInsuranceAdmin: (state, action) => {
      state.umbrellaInsuranceAdmin = action.payload
    },
    setHomeRefinancingAdmin: (state, action) => {
      state.homeRefinancingAdmin = action.payload
    },
    setHealthcareCoverageAdmin: (state, action) => {
      state.healthcareCoverageAdmin = action.payload
    }
  }
})

export const fetchMortgageAdmin = (companyId) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    const response = await CalculatorsSetup.getMortgageAdmin(companyId);
    await dispatch(setMortgageAdmin(response.data.data));
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const createMortgageAdmin = (companyId, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    await CalculatorsSetup.postMortgageAdmin(companyId, data);
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const updateMortgageAdmin = (companyId, id, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    let {
      home_value,
      points_closing_added_to_mortgage,
      pmi_value,
      pmi_estimate_value,
      insurance_amount_in_year,
      utility_amount_in_month,
      annual_home_maintenance_expenses
     } = data

     home_value = convertDecimal(home_value)
     points_closing_added_to_mortgage = convertDecimal(points_closing_added_to_mortgage)
     pmi_value = convertDecimal(pmi_value)
     pmi_estimate_value = convertDecimal(pmi_estimate_value)
     insurance_amount_in_year = convertDecimal(insurance_amount_in_year)
     utility_amount_in_month = convertDecimal(utility_amount_in_month)
     annual_home_maintenance_expenses = convertDecimal(annual_home_maintenance_expenses)
    await CalculatorsSetup.putMortgageAdmin(companyId, id, {
      ...data,
      home_value,
      points_closing_added_to_mortgage,
      pmi_value,
      pmi_estimate_value,
      insurance_amount_in_year,
      utility_amount_in_month,
      annual_home_maintenance_expenses
    });
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchIncomeAndExpensesAdmin = (companyId) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    const response = await CalculatorsSetup.getIncomeAndExpensesAdmin(companyId);
    await dispatch(setIncomeAndExpensesAdmin(response.data.data));
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const createIncomeAndExpensesAdmin = (companyId, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    await CalculatorsSetup.postIncomeAndExpensesAdmin(companyId, data);
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const updateIncomeAndExpensesAdmin = (companyId, id, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    let {
      reduce_expenses,
      increase_expenses
     } = data

     reduce_expenses = convertDecimal(reduce_expenses)
     increase_expenses = convertDecimal(increase_expenses)
    await CalculatorsSetup.putIncomeAndExpensesAdmin(companyId, id, {
      ...data,
      reduce_expenses,
      increase_expenses
    });
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchOwnVsRentAdmin = (companyId) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    const response = await CalculatorsSetup.getOwnVsRentAdmin(companyId);
    await dispatch(setOwnVsRentAdmin(response.data.data));
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const createOwnVsRentAdmin = (companyId, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    await CalculatorsSetup.postOwnVsRentAdmin(companyId, data);
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const updateOwnVsRentAdmin = (companyId, id, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    let {
      home_value,
      home_maintenance_expenses,
      monthly_rent
     } = data

     home_value = convertDecimal(home_value)
     home_maintenance_expenses = convertDecimal(home_maintenance_expenses)
     monthly_rent = convertDecimal(monthly_rent)
    await CalculatorsSetup.putOwnVsRentAdmin(companyId, id, {
      ...data,
      home_value,
      home_maintenance_expenses,
      monthly_rent
    });
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchCarLeaseVsLeaseEndAdmin = (companyId) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    const response = await CalculatorsSetup.getCarLeaseVsLeaseEndAdmin(companyId);
    await dispatch(setCarLeaseVsLeaseEndAdmin(response.data.data));
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const createCarLeaseVsLeaseEndAdmin = (companyId, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    await CalculatorsSetup.postCarLeaseVsLeaseEndAdmin(companyId, data);
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const updateCarLeaseVsLeaseEndAdmin = (companyId, id, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    let {
      retail_price,
      fees,
      down_payment,
      trade_in_value,
      rebates,
      rebates_dealer_incentives,
      mileage_overage_cost_per_mile,
      gas_per_month,
      maintenance_per_year,
      yearly_insurance_premium,
      payoff_value,
      maintenance_year,
      lease_gas_per_month,
      lease_mileage_overage_cost_per_mile,
      increase_yearly_insurance_premium
     } = data

     retail_price = convertDecimal(retail_price)
     fees = convertDecimal(fees)
     down_payment = convertDecimal(down_payment)
     trade_in_value = convertDecimal(trade_in_value)
     rebates = convertDecimal(rebates)
     rebates_dealer_incentives = convertDecimal(rebates_dealer_incentives)
     mileage_overage_cost_per_mile = convertDecimal(mileage_overage_cost_per_mile)
     gas_per_month = convertDecimal(gas_per_month)
     maintenance_per_year = convertDecimal(maintenance_per_year)
     yearly_insurance_premium = convertDecimal(yearly_insurance_premium)
     payoff_value = convertDecimal(payoff_value)
     maintenance_year = convertDecimal(maintenance_year)
     lease_gas_per_month = convertDecimal(lease_gas_per_month)
     lease_mileage_overage_cost_per_mile = convertDecimal(lease_mileage_overage_cost_per_mile)
     increase_yearly_insurance_premium = convertDecimal(increase_yearly_insurance_premium)
    await CalculatorsSetup.putCarLeaseVsLeaseEndAdmin(companyId, id, {
      ...data,
      retail_price,
      fees,
      down_payment,
      trade_in_value,
      rebates,
      rebates_dealer_incentives,
      mileage_overage_cost_per_mile,
      gas_per_month,
      maintenance_per_year,
      yearly_insurance_premium,
      payoff_value,
      maintenance_year,
      lease_gas_per_month,
      lease_mileage_overage_cost_per_mile,
      increase_yearly_insurance_premium
    });
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchCarLeasePaymentAdmin = (companyId) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    const response = await CalculatorsSetup.getCarLeasePaymentAdmin(companyId);
    await dispatch(setCarLeasePaymentAdmin(response.data.data));
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const createCarLeasePaymentAdmin = (companyId, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    await CalculatorsSetup.postCarLeasePaymentAdmin(companyId, data);
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const updateCarLeasePaymentAdmin = (companyId, id, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    let {
      retail_price,
      fees,
      down_payment,
      trade_in_value,
      rebates,
      rebates_dealer_incentives,
      mileage_overage_cost_per_mile,
      gas_per_month,
      maintenance_per_year,
      yearly_insurance_premium
     } = data

     retail_price = convertDecimal(retail_price)
     fees = convertDecimal(fees)
     down_payment = convertDecimal(down_payment)
     trade_in_value = convertDecimal(trade_in_value)
     rebates = convertDecimal(rebates)
     rebates_dealer_incentives = convertDecimal(rebates_dealer_incentives)
     mileage_overage_cost_per_mile = convertDecimal(mileage_overage_cost_per_mile)
     gas_per_month = convertDecimal(gas_per_month)
     maintenance_per_year = convertDecimal(maintenance_per_year)
     yearly_insurance_premium = convertDecimal(yearly_insurance_premium)
    await CalculatorsSetup.putCarLeasePaymentAdmin(companyId, id, {
      ...data,
      retail_price,
      fees,
      down_payment,
      trade_in_value,
      rebates,
      rebates_dealer_incentives,
      mileage_overage_cost_per_mile,
      gas_per_month,
      maintenance_per_year,
      yearly_insurance_premium
    });
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchLifeInsuranceAdmin = (companyId) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    const response = await CalculatorsSetup.getLifeInsuranceAdmin(companyId);
    await dispatch(setLifeInsuranceAdmin(response.data.data));
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const createLifeInsuranceAdmin = (companyId, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    await CalculatorsSetup.postLifeInsuranceAdmin(companyId, data);
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const updateLifeInsuranceAdmin = (companyId, id, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    let {
      take_home_monthly_income,
      spouse_take_home_income,
      social_security_survivors_benefit,
      current_total_assets,
      current_life_insurance,
      spouse_current_life_insurance,
      child_current_life_insurance,
      monthly_household_expenses,
      funeral_costs,
      estate_expenses,
      current_outstanding_liability
     } = data

     take_home_monthly_income = convertDecimal(take_home_monthly_income)
     spouse_take_home_income = convertDecimal(spouse_take_home_income)
     social_security_survivors_benefit = convertDecimal(social_security_survivors_benefit)
     current_total_assets = convertDecimal(current_total_assets)
     current_life_insurance = convertDecimal(current_life_insurance)
     spouse_current_life_insurance = convertDecimal(spouse_current_life_insurance)
     child_current_life_insurance = convertDecimal(child_current_life_insurance)
     monthly_household_expenses = convertDecimal(monthly_household_expenses)
     funeral_costs = convertDecimal(funeral_costs)
     estate_expenses = convertDecimal(estate_expenses)
     current_outstanding_liability = convertDecimal(current_outstanding_liability)
    await CalculatorsSetup.putLifeInsuranceAdmin(companyId, id, {
      ...data,
      take_home_monthly_income,
      spouse_take_home_income,
      social_security_survivors_benefit,
      current_total_assets,
      current_life_insurance,
      spouse_current_life_insurance,
      child_current_life_insurance,
      monthly_household_expenses,
      funeral_costs,
      estate_expenses,
      current_outstanding_liability
    });
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchTimeToBuyHomeAdmin = (companyId) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    const response = await CalculatorsSetup.getTimeToBuyHomeAdmin(companyId);
    await dispatch(setTimeToBuyHomeAdmin(response.data.data));
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const createTimeToBuyHomeAdmin = (companyId, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    await CalculatorsSetup.postTimeToBuyHomeAdmin(companyId, data);
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const updateTimeToBuyHomeAdmin = (companyId, id, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    let {
      current_monthly_expenses,
      monthly_savings,
      home_value,
      available_checking_funds,
      available_savings_funds,
      available_brokerage_funds,
      additional_funds_available
     } = data

     current_monthly_expenses = convertDecimal(current_monthly_expenses)
     monthly_savings = convertDecimal(monthly_savings)
     home_value = convertDecimal(home_value)
     available_checking_funds = convertDecimal(available_checking_funds)
     available_savings_funds = convertDecimal(available_savings_funds)
     available_brokerage_funds = convertDecimal(available_brokerage_funds)
     additional_funds_available = convertDecimal(additional_funds_available)
    await CalculatorsSetup.putTimeToBuyHomeAdmin(companyId, id, {
      ...data,
      current_monthly_expenses,
      monthly_savings,
      home_value,
      available_checking_funds,
      available_savings_funds,
      available_brokerage_funds,
      additional_funds_available
    });
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchHomeAffordabilityAdmin = (companyId) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    const response = await CalculatorsSetup.getHomeAffordabilityAdmin(companyId);
    await dispatch(setHomeAffordabilityAdmin(response.data.data));
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const createHomeAffordabilityAdmin = (companyId, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    await CalculatorsSetup.postHomeAffordabilityAdmin(companyId, data);
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const updateHomeAffordabilityAdmin = (companyId, id, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    let {
      current_household_annual_gross_income,
      additional_annual_gross_income,
      car_loan_monthly_payment,
      student_loan_monthly_payment,
      credit_balance_monthly_interest,
      other_debt_monthly_payment,
      home_value,
      annual_insurance_premium,
      annual_home_maintenance,
      monthly_utilities
     } = data

     current_household_annual_gross_income = convertDecimal(current_household_annual_gross_income)
     additional_annual_gross_income = convertDecimal(additional_annual_gross_income)
     car_loan_monthly_payment = convertDecimal(car_loan_monthly_payment)
     student_loan_monthly_payment = convertDecimal(student_loan_monthly_payment)
     credit_balance_monthly_interest = convertDecimal(credit_balance_monthly_interest)
     other_debt_monthly_payment = convertDecimal(other_debt_monthly_payment)
     home_value = convertDecimal(home_value)
     annual_insurance_premium = convertDecimal(annual_insurance_premium)
     annual_home_maintenance = convertDecimal(annual_home_maintenance)
     monthly_utilities = convertDecimal(monthly_utilities)
    await CalculatorsSetup.putHomeAffordabilityAdmin(companyId, id, {
      ...data,
      current_household_annual_gross_income,
      additional_annual_gross_income,
      car_loan_monthly_payment,
      student_loan_monthly_payment,
      credit_balance_monthly_interest,
      other_debt_monthly_payment,
      home_value,
      annual_insurance_premium,
      annual_home_maintenance,
      monthly_utilities
    });
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchHomeEvaluationAdmin = (companyId) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    const response = await CalculatorsSetup.getHomeEvaluationAdmin(companyId);
    await dispatch(setHomeEvaluationAdmin(response.data.data));
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const createHomeEvaluationAdmin = (companyId, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    await CalculatorsSetup.postHomeEvaluationAdmin(companyId, data);
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const updateHomeEvaluationAdmin = (companyId, id, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    await CalculatorsSetup.putHomeEvaluationAdmin(companyId, id, data);
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchValueCircleAdmin = (companyId) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    const response = await CalculatorsSetup.getValueCircleAdmin(companyId);
    await dispatch(setValueCircleAdmin(response.data.data));
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const createValueCircleAdmin = (companyId, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    await CalculatorsSetup.postValueCircleAdmin(companyId, data);
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const updateValueCircleAdmin = (companyId, id, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    await CalculatorsSetup.putValueCircleAdmin(companyId, id, data);
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchInvestmentFeesAdmin = (companyId) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    const response = await CalculatorsSetup.getInvestmentFeesAdmin(companyId);
    await dispatch(setInvestmentFeesAdmin(response.data.data));
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const createInvestmentFeesAdmin = (companyId, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    await CalculatorsSetup.postInvestmentFeesAdmin(companyId, data);
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const updateInvestmentFeesAdmin = (companyId, id, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    let {
      investment_amount,
      broker_fees,
      transaction_fee_on_stock,
      transaction_fee_on_mutual_fund
     } = data

     investment_amount = convertDecimal(investment_amount)
     broker_fees = convertDecimal(broker_fees)
     transaction_fee_on_stock = convertDecimal(transaction_fee_on_stock)
     transaction_fee_on_mutual_fund = convertDecimal(transaction_fee_on_mutual_fund)
    await CalculatorsSetup.putInvestmentFeesAdmin(companyId, id, {
      ...data,
      investment_amount,
      broker_fees,
      transaction_fee_on_stock,
      transaction_fee_on_mutual_fund
    });
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchUmbrellaInsuranceAdmin = (companyId) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    const response = await CalculatorsSetup.getUmbrellaInsuranceAdmin(companyId);
    await dispatch(setUmbrellaInsuranceAdmin(response.data.data));
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const createUmbrellaInsuranceAdmin = (companyId, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    await CalculatorsSetup.postUmbrellaInsuranceAdmin(companyId, data);
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const updateUmbrellaInsuranceAdmin = (companyId, id, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    let {
      current_net_worth,
      current_face_value,
      miles_travelled_per_day,
      netWorth
     } = data

     current_net_worth = convertDecimal(current_net_worth)
     current_face_value = convertDecimal(current_face_value)
     miles_travelled_per_day = convertDecimal(miles_travelled_per_day)
     netWorth = convertDecimal(netWorth)
    await CalculatorsSetup.putUmbrellaInsuranceAdmin(companyId, id, {
      ...data,
      current_net_worth,
      current_face_value,
      miles_travelled_per_day,
      netWorth
    });
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchHomeRefinancingAdmin = (companyId) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    const response = await CalculatorsSetup.getHomeRefinancingAdmin(companyId);
    await dispatch(setHomeRefinancingAdmin(response.data.data));
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const createHomeRefinancingAdmin = (companyId, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    await CalculatorsSetup.postHomeRefinancingAdmin(companyId, data);
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const updateHomeRefinancingAdmin = (companyId, id, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    let {
      loan_amount,
      closing_costs
     } = data

     loan_amount = convertDecimal(loan_amount)
     closing_costs = convertDecimal(closing_costs)
    await CalculatorsSetup.putHomeRefinancingAdmin(companyId, id, {
      ...data,
      loan_amount,
      closing_costs
    });
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchHealthcareCoverageAdmin = (companyId) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    const response = await CalculatorsSetup.getHealthcareCoverageAdmin(companyId);
    await dispatch(setHealthcareCoverageAdmin(response.data.data));
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const createHealthcareCoverageAdmin = (companyId, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    await CalculatorsSetup.postHealthcareCoverageAdmin(companyId, data);
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const updateHealthcareCoverageAdmin = (companyId, id, data) => async (dispatch) => {
  try {
    await dispatch(setSending({isSending: true}));
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    let {
      willing_to_pay
     } = data

     willing_to_pay = convertDecimal(willing_to_pay)
    await CalculatorsSetup.putHealthcareCoverageAdmin(companyId, id, {
      ...data,
      willing_to_pay
    });
    await dispatch(setSending({isSending: false}));
  } catch (e) {
    console.error(e);
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({isSending: false}));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const {
  setIsLoading,
  setMortgageAdmin,
  setIncomeAndExpensesAdmin,
  setOwnVsRentAdmin,
  setCarLeaseVsLeaseEndAdmin,
  setCarLeasePaymentAdmin,
  setLifeInsuranceAdmin,
  setTimeToBuyHomeAdmin,
  setHomeAffordabilityAdmin,
  setHomeEvaluationAdmin,
  setValueCircleAdmin,
  setInvestmentFeesAdmin,
  setUmbrellaInsuranceAdmin,
  setHomeRefinancingAdmin,
  setHealthcareCoverageAdmin
} = calculatorsSetupSlice.actions

export default calculatorsSetupSlice.reducer