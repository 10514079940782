
const states = [
    {
      id: 1,
      key: 1,
      value: 'alabama',
      label: 'Alabama',
    },
    {
      id: 2,
      key: 2,
      value: 'alaska',
      label: 'Alaska',
    },
    {
      id: 3,
      key: 3,
      value: 'american_samoa',
      label: 'American Samoa',
    },
    {
      id: 4,
      key: 4,
      value: 'arizona',
      label: 'Arizona',
    },
    {
      id: 5,
      key: 5,
      value: 'arkansas',
      label: 'Arkansas',
    },
    {
      id: 6,
      key: 6,
      value: 'california',
      label: 'California',
    },
    {
      id: 7,
      key: 7,
      value: 'colorado',
      label: 'Colorado',
    },
    {
      id: 8,
      key: 8,
      value: 'connecticut',
      label: 'Connecticut',
    },
    {
      id: 9,
      key: 9,
      value: 'delaware',
      label: 'Delaware',
    },
    {
      id: 10,
      key: 10,
      value: 'district_of_columbia',
      label: 'District of Columbia',
    },
    {
      id: 11,
      key: 11,
      value: 'federated_states_of_micronesia',
      label: 'Federated States of Micronesia',
    },
    {
      id: 12,
      key: 12,
      value: 'florida',
      label: 'Florida',
    },
    {
      id: 13,
      key: 13,
      value: 'georgia',
      label: 'Georgia',
    },
    {
      id: 14,
      key: 14,
      value: 'guam',
      label: 'Guam',
    },
    {
      id: 15,
      key: 15,
      value: 'hawaii',
      label: 'Hawaii',
    },
    {
      id: 16,
      key: 16,
      value: 'idaho',
      label: 'Idaho',
    },
    {
      id: 17,
      key: 17,
      value: 'illinois',
      label: 'Illinois',
    },
    {
      id: 18,
      key: 18,
      value: 'indiana',
      label: 'Indiana',
    },
    {
      id: 19,
      key: 19,
      value: 'iowa',
      label: 'Iowa',
    },
    {
      id: 20,
      key: 20,
      value: 'kansas',
      label: 'Kansas',
    },
    {
      id: 21,
      key: 21,
      value: 'kentucky',
      label: 'Kentucky',
    },
    {
      id: 22,
      key: 22,
      value: 'louisiana',
      label: 'Louisiana',
    },
    {
      id: 23,
      key: 23,
      value: 'maine',
      label: 'Maine',
    },
    {
      id: 24,
      key: 24,
      value: 'marshall_islands',
      label: 'Marshall Islands',
    },
    {
      id: 25,
      key: 25,
      value: 'maryland',
      label: 'Maryland',
    },
    {
      id: 26,
      key: 26,
      value: 'massachusetts',
      label: 'Massachusetts',
    },
    {
      id: 27,
      key: 27,
      value: 'michigan',
      label: 'Michigan',
    },
    {
      id: 28,
      key: 28,
      value: 'minnesota',
      label: 'Minnesota',
    },
    {
      id: 29,
      key: 29,
      value: 'mississippi',
      label: 'Mississippi',
    },
    {
      id: 30,
      key: 30,
      value: 'missouri',
      label: 'Missouri',
    },
    {
      id: 31,
      key: 31,
      value: 'montana',
      label: 'Montana',
    },
    {
      id: 32,
      key: 32,
      value: 'nebraska',
      label: 'Nebraska',
    },
    {
      id: 33,
      key: 33,
      value: 'nevada',
      label: 'Nevada',
    },
    {
      id: 34,
      key: 34,
      value: 'new_hampshire',
      label: 'New Hampshire',
    },
    {
      id: 35,
      key: 35,
      value: 'new_jersey',
      label: 'New Jersey',
    },
    {
      id: 36,
      key: 36,
      value: 'new_mexico',
      label: 'New Mexico',
    },
    {
      id: 37,
      key: 37,
      value: 'new_york',
      label: 'New York',
    },
    {
      id: 38,
      key: 38,
      value: 'north_carolina',
      label: 'North Carolina',
    },
    {
      id: 39,
      key: 39,
      value: 'north_dakota',
      label: 'North Dakota',
    },
    {
      id: 40,
      key: 40,
      value: 'northern_mariana_islands',
      label: 'Northern Mariana Islands',
    },
    {
      id: 41,
      key: 41,
      value: 'ohio',
      label: 'Ohio',
    },
    {
      id: 42,
      key: 42,
      value: 'oklahoma',
      label: 'Oklahoma',
    },
    {
      id: 43,
      key: 43,
      value: 'oregon',
      label: 'Oregon',
    },
    {
      id: 44,
      key: 44,
      value: 'palau',
      label: 'Palau',
    },
    {
      id: 45,
      key: 45,
      value: 'Pennsylvania',
      label: 'Pennsylvania',
    },
    {
      id: 46,
      key: 46,
      value: 'puerto_rico',
      label: 'Puerto Rico',
    },
    {
      id: 47,
      key: 47,
      value: 'rhode_island',
      label: 'Rhode Island',
    },
    {
      id: 48,
      key: 48,
      value: 'south_carolina',
      label: 'South Carolina',
    },
    {
      id: 49,
      key: 49,
      value: 'south_dakota',
      label: 'South Dakota',
    },
    {
      id: 50,
      key: 50,
      value: 'tennessee',
      label: 'Tennessee',
    },
    {
      id: 51,
      key: 51,
      value: 'texas',
      label: 'Texas',
    },
    {
      id: 52,
      key: 52,
      value: 'utah',
      label: 'Utah',
    },
    {
      id: 53,
      key: 53,
      value: 'vermont',
      label: 'Vermont',
    },
    {
      id: 54,
      key: 54,
      value: 'virgin_islands',
      label: 'Virgin Islands',
    },
    {
      id: 55,
      key: 55,
      value: 'virginia',
      label: 'Virginia',
    },
    {
      id: 56,
      key: 56,
      value: 'washington',
      label: 'Washington',
    },
    {
      id: 57,
      key: 57,
      value: 'west virginia',
      label: 'West Virginia',
    },
    {
      id: 58,
      key: 58,
      value: 'wisconsin',
      label: 'Wisconsin',
    },
    {
      id: 59,
      key: 59,
      value: 'wyoming',
      label: 'Wyoming',
    },
    {
      id: 60,
      key: 60,
      value: 'armed_forces_americas',
      label: 'Armed Forces Americas',
    },
    {
      id: 61,
      key: 61,
      value: 'armed_forces_europe',
      label: 'Armed Forces Europe',
    },
    {
      id: 62,
      key: 62,
      value: 'Armed_forces_pacific',
      label: 'Armed Forces Pacific',
    }
  ];

export default states;