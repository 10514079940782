import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Redirect, useParams } from 'react-router-dom';
import Loading from '../../components/shared/Loading/Loading';
import { fetchPosts, removePost, fetchCatalog, postActivation } from '../../redux/slices/blogSlice';
import BlogCard from '../../components/Cards/BlogCard';
import Pagination from "@mui/material/Pagination";
import { isNull } from 'lodash';

const AdminPostsOverview = ({ props, isAdmin }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { companyId } = useParams();
  const [page, setPage] = useState(1);
  const { id } = useSelector((state) => state.user.user);
  const { permissions } = useSelector((state) => state.companies.company.info);
  const { adminPosts, catalog, isLoading } = useSelector((state) => state.blog);
  const [filter, setFilter] = useState({ catalog: null, status: null });

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchCatalog());
    }
    if (id) {
      fetch();
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    async function fetch() {
      await dispatch(
        fetchPosts(
          companyId,
          {
            page,
            catalog: filter.catalog,
            status: filter.status,
          },
          isAdmin
        )
      );
    }
    if (id) {
      fetch();
    }
    // eslint-disable-next-line
  }, [id, page, filter]);

  const onChangePage = (event, value) => setPage(value);

  if (!isNull(permissions?.blog) && !permissions?.blog) return <Redirect to="/404" />;

  return (
    <>
      <div className="blog__tags">
        <span
          className={`blog__tags-item${filter.catalog === null ? ' blog__tags-item--selected' : ''}`}
          onClick={() => {
            setFilter({ catalog: null });
          }}
        >
          All
        </span>
        <span
          className={`blog__tags-item${filter.status === 'new' ? ' blog__tags-item--selected' : ''}`}
          onClick={() => {
            setFilter({ status: 'new' });
          }}
        >
          New
        </span>
        {catalog &&
          catalog.length > 1 &&
          catalog.map((item, i) => (
            <span
              key={`tag-${item.id}`}
              className={`blog__tags-item${filter.catalog === item.id ? ' blog__tags-item--selected' : ''}`}
              onClick={() => {
                setFilter({ catalog: item.id });
              }}
            >
              {item.name}
            </span>
          ))}
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="grid grid-3">
            {adminPosts &&
              adminPosts.data &&
              adminPosts.data.map((item) => (
                <div key={item.id}>
                  <BlogCard
                    active={item.active}
                    isAdmin={isAdmin}
                    image={item.image}
                    title={item.title}
                    category={item.catalog && item.catalog.name ? item.catalog.name : 'Uncategorized'}
                    path={`/admin/company/${companyId}/blog/${item.id}`}
                    onDelete={removePost}
                    isVSpost={+item.company_id === +companyId}
                    onEdit={() => history.push(`/admin/company/${companyId}/blog/${item.id}/edit`)}
                    handleStatus={() => dispatch(postActivation(companyId, item.id, { active: !item.active }))}
                    postId={item.id}
                    companyId={companyId}
                    small
                    page={page}
                  />
                </div>
              ))}
          </div>
          {adminPosts && adminPosts.meta && adminPosts.meta.last_page > 1 && (
            <Pagination page={page} count={adminPosts.meta.last_page} onChange={onChangePage} />
          )}
        </>
      )}
    </>
  );
};

export default AdminPostsOverview;
