const typeFeedback = [
  {
    key: 'reason',
    label: 'Something`s not working correctly.',
    value: 'Something`s not working correctly.',
  },
  {
    key: 'positive',
    label: 'Sharing positive feedback!',
    value: 'Sharing positive feedback!',
  },
  {
    key: 'missing',
    label: 'You are missing an important feature.',
    value: 'You are missing an important feature.',
  },
  {
    key: 'question',
    label: 'I have a question.',
    value: 'I have a question.',
  }
]

export default typeFeedback;