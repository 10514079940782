import {
    createSlice
} from "@reduxjs/toolkit";
import {
    Companies,
    Admin
} from "../../api/Api";
import {
    setSending
} from './sendingSlice';
import {
    setIsOpen
} from './modalSlice';
import {
    setIsRelogin
} from './loadingSlice';
import {
    setMode
} from './modeSlice';
import {
    setUserData
} from './userSlice';


const companiesSlice = createSlice({
    name: 'companies',
    initialState: {
        companies: null,
        members: null,
        pagination: {},
        planners: null,
        isSending: false,
        buttonLoading: false,
        sendStatus: false,
        requestLimitStatus: false,
        error: null,
        uploadingAvatar: false,
        nameAvatar: '',
        avatarURL: '',
        activeNumber: '',
        sort_by_planner: null,
        companyNewAdmin: null,
        member: null,
        statisticsStates: null,
        statisticsCities: null,
        statisticsAgeRange: null,
        netWorth: null,
        totalIncome: null,
        totalInheritance: null,
        totalInvestment: null,
        isGraphLoading: false,
        company: {
            info: {
                id: '',
                name: '',
                website: '',
                image: null,
                vsft_name: '',
                ein: '',
                email: '',
                address: '',
                optional_address: '',
                city: '',
                state: '',
                zip: '',
                max_seats: '',
                current_members: '',
                seatsLimitColor: '',
                permissions: {
                    blog: null,
                    calculators: null,
                    alerts: null,
                    assumptions: null,
                },
            },
            owner: {
                first_name: '',
                last_name: '',
                email: '',
            },
        },
    },
    reducers: {
        setIsLoading: (state, action) => {
            state.companyLoading = action.payload;
        },
        setIsGraphLoading: (state, action) => {
            state.isGraphLoading = action.payload;
        },
        setIsSending: (state, action) => {
            state.isSending = action.payload;
        },
        setActiveNumber: (state, action) => {
            state.activeNumber = action.payload;
        },
        setCompanies: (state, action) => {
            state.companies = action.payload;
        },
        setNameAvatar: (state, action) => {
            state.nameAvatar = action.payload;
        },
        setAvatarURL: (state, action) => {
            state.avatarURL = action.payload;
        },
        setCompanyData: (state, action) => {
            state.company.info = {
                ...state.company.info,
                ...action.payload,
            };
        },
        setCompaniesPermissions: (state, action) => {
            state.company.info.permissions = {
                ...state.company.infopermissions,
                ...action.payload,
            };
        },
        setCompanyOwner: (state, action) => {
            state.company.owner = {
                ...state.company.owner,
                ...action.payload,
            };
        },
        setCompanyNewAdmin: (state, action) => {
            state.companyNewAdmin = action.payload;
        },
        setMembers: (state, action) => {
            state.members = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        setMember: (state, action) => {
            state.member = action.payload;
        },
        setPlanners: (state, action) => {
            state.planners = action.payload;
        },
        setUploading: (state, action) => {
            state.uploadingAvatar = action.payload;
        },
        setSeatsLimit: (state, action) => {
            state.max_seats = action.payload;
        },
        setSendStatus: (state, action) => {
            state.sendStatus = action.payload;
        },
        setRequestLimitStatus: (state, action) => {
            state.requestLimitStatus = action.payload;
        },
        setSeatsLimitColor: (state, action) => {
            state.company.info.seatsLimitColor = action.payload;
        },
        setSortPlanner: (state, action) => {
            state.sort_by_planner = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setStatisticsStates: (state, action) => {
            state.statisticsStates = action.payload
        },
        setStatisticsCities: (state, action) => {
            state.statisticsCities = action.payload
        },
        setStatisticsAgeRange: (state, action) => {
            state.statisticsAgeRange = action.payload
        },
        setNetWorthStatistics: (state, action) => {
            state.netWorth = action.payload
        },
        setTotalIncomeStatistics: (state, action) => {
            state.totalIncome = action.payload
        },
        setTotalInheritanceStatistics: (state, action) => {
            state.totalInheritance = action.payload
        },
        setTotalInvestmentStatistics: (state, action) => {
            state.totalInvestment = action.payload
        },
    },
});

export const fetchCompaniesList = (params, props) => async (dispatch) => {
    try {
        await dispatch(setIsLoading(true));
        await dispatch(setSendStatus(false));
        const response = await Companies.get(params);
        await dispatch(setCompanies(response.data.data));
        await dispatch(setCompanyData({
            image: null
        }))
        await dispatch(setIsLoading(false));
    } catch (e) {
        console.error(e);
        await dispatch(setIsLoading(false));
        if (e.response && e.response.status === 401) {
            await dispatch(setIsRelogin({
                isRelogin: true
            }));
        }
    }
}

export const fetchCompanyPermissions = (id, props) => async (dispatch) => {
    try {
        await dispatch(setIsLoading(true));
        const response = await Companies.getPermissions(id);
        await dispatch(setCompaniesPermissions(response.data.data));
        await dispatch(setIsLoading(false));
    } catch (e) {
        console.error(e);
        await dispatch(setIsLoading(false));
        if (e.response && e.response.status === 401) {
            await dispatch(setIsRelogin({
                isRelogin: true
            }));
        }
    }
}

export const postCompanyInfo = (data, props, setFormErrors) => async (dispatch) => {
    try {
        await dispatch(setSending({
            isSending: true
        }));
        await dispatch(setError(null));
        const response = await Companies.postCompInfo(data);
        await dispatch(setCompanyData(response.data.data));
        await dispatch(setSending({
            isSending: false
        }));
        await dispatch(setAvatarURL(''));
        await dispatch(() => props.history.push(`/admin/companies/${response.data.data.id}/add-permissions`));
    } catch (e) {
        console.error(e);
        dispatch(setSending({
            isSending: false
        }));

        if (e.response && e.response.status === 401) {
            dispatch(setIsRelogin({
                isRelogin: true
            }));
            dispatch(setAvatarURL(''));
        }

        if (e.response && e.response.data.errors) {
            dispatch(setError(e.response.data.errors));
            setFormErrors(e.response.data.errors)
        }
    }
}

export const updateCompanyPermissions = (id, data, props) => async (dispatch) => {
    try {
        await dispatch(setSending({
            isSending: true
        }));
        const response = await Companies.putCompPermissions(id, data);
        await dispatch(setCompaniesPermissions(response.data.data));
        await dispatch(setSending({
            isSending: false
        }));
    } catch (e) {
        console.error(e);
        await dispatch(setSending({
            isSending: false
        }));

        if (e.response && e.response.status === 401) {
            await dispatch(setIsRelogin({
                isRelogin: true
            }));
        }
    }
}

export const postCompanyPermissions = (id, data, props) => async (dispatch) => {
    try {
        await dispatch(setSending({
            isSending: true
        }));
        await Companies.postCompPermissions(id, data);
        await dispatch(setSending({
            isSending: false
        }));
        await dispatch(() => props.history.push(`/admin/companies/${id}/add-admin`));
    } catch (e) {
        console.error(e);
        await dispatch(setSending({
            isSending: false
        }));

        if (e.response && e.response.status === 401) {
            await dispatch(setIsRelogin({
                isRelogin: true
            }));
        }
    }
}

export const postCompanyMember = (id, data, props, setFormErrors) => async (dispatch) => {
    try {
        await dispatch(setSending({
            isSending: true
        }));
        await dispatch(setError(null));
        const response = await Companies.postCompMember(id, data);
        await dispatch(setCompanyOwner(response.data.data));
        await dispatch(setSending({
            isSending: false
        }));
        await dispatch(() => props.history.push(`/admin/companies/${id}/success`));
    } catch (e) {
        console.error(e);
        dispatch(setSending({
            isSending: false
        }));

        if (e.response && e.response.status === 401) {
            await dispatch(setIsRelogin({
                isRelogin: true
            }));
        }

        if (e.response && e.response.data.errors) {
            dispatch(setError(e.response.data.errors));
            setFormErrors(e.response.data.errors)
        }
    }
}

export const fetchCompany = id => async (dispatch) => {
    try {
        await dispatch(setIsLoading(true));
        await dispatch(setSendStatus(false));
        const response = await Companies.fetch(id);
        await dispatch(setCompanyData(response.data.data));
        await dispatch(setCompanyNewAdmin(null));
        await dispatch(setSortPlanner(null));
        await dispatch(setIsLoading(false));
    } catch (e) {
        console.error(e);
        await dispatch(setIsLoading(false));

        if (e.response && e.response.status === 401) {
            await dispatch(setIsRelogin({
                isRelogin: true
            }));
        }
    }
}

export const updateCompany = (id, data, setFormErrors) => async (dispatch) => {
    try {
        await dispatch(setSending({
            isSending: true
        }));
        await dispatch(setError(null));
        await Companies.putCompany(id, data, {
            _method: 'PUT'
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        await dispatch(setSending({
            isSending: false
        }));
        await dispatch(setIsOpen({
            isOpen: true
        }))
    } catch (e) {
        console.error(e);
        dispatch(setSending({
            isSending: false
        }));

        if (e.response && e.response.status === 401) {
            await dispatch(setIsRelogin({
                isRelogin: true
            }));
        }

        if (e.response && e.response.data.errors) {
            dispatch(setError(e.response.data.errors));
            setFormErrors(e.response.data.errors)
        }
    }
}

export const updateMember = (id, meberId, data, props, setFormErrors) => async (dispatch) => {
    try {
        await dispatch(setSending({
            isSending: true
        }));
        await dispatch(setError(null));
        await dispatch(setMode('success'));
        await dispatch(setIsOpen({
            isOpen: true
        }));
        if (props) {
            await Companies.updateMemberUnconfirmed(id, meberId, data);
        } else {
            await Companies.updateMember(id, meberId, data, {
                _method: 'PUT'
            });
        }
        await dispatch(setSending({
            isSending: false
        }));
    } catch (e) {
        console.error(e);
        dispatch(setSending({
            isSending: false
        }));
        await dispatch(setMode('error'));
        await dispatch(setIsOpen({
            isOpen: true
        }));

        if (e.response && e.response.status === 401) {
            await dispatch(setIsRelogin({
                isRelogin: true
            }));
        }

        if (e.response && e.response.data.errors) {
            dispatch(setError(e.response.data.errors));
            setFormErrors(e.response.data.errors);
        }
    }
};

export const updateCompanyLogo = (id, data) => async (dispatch) => {
    try {
        await dispatch(setSending({
            isSending: true
        }));
        const response = await Companies.updateLogo(id, data);
        await dispatch(setCompanyData({
            ...data,
            image: response.data.data.image
        }));
        await dispatch(setIsLoading(false));
        await dispatch(setSending({
            isSending: false
        }));
        await dispatch(setAvatarURL(''));
    } catch (e) {
        console.error(e);
        dispatch(setSending({
            isSending: false
        }));
        dispatch(setAvatarURL(''));

        if (e.response && e.response.status === 401) {
            await dispatch(setIsRelogin({
                isRelogin: true
            }));
        }
    }
}

export const removeCompany = id => async (dispatch) => {
    try {
        await Companies.del(id);
    } catch (e) {
        console.error(e);

        if (e.response && e.response.status === 401) {
            await dispatch(setIsRelogin({
                isRelogin: true
            }));
        }
    }
}

export const removeMember = (id, memberId, props) => async (dispatch) => {
    try {
        if (props) {
            await Companies.deleteMember(id, memberId);
        } else {
            await Companies.deleteMemberUnconfirmed(id, memberId);
        }
    } catch (e) {
        console.error(e);

        if (e.response && e.response.status === 401) {
            await dispatch(setIsRelogin({
                isRelogin: true
            }));
        }
    }
};

export const fetchMembersList = (id, params, props) => async (dispatch) => {
    try {
        await dispatch(setIsLoading(true));
        await dispatch(setMembers(null));
        await dispatch(setSendStatus(false));
        const response = await Companies.getMembers(id, params);
        await dispatch(setMembers(response.data.data.members));
        await dispatch(setPagination(response.data.data.pagination));
        await dispatch(setCompanyData({
            current_members: response.data.current_members
        }));
        await dispatch(setSeatsLimitColor(response.data.seats_limit_color))
        await dispatch(setIsLoading(false));
        await dispatch(setActiveNumber(''));
    } catch (e) {
        console.error(e);
        await dispatch(setIsLoading(false));

        if (e.response && e.response.status === 401) {
            await dispatch(setIsRelogin({
                isRelogin: true
            }));
        }
    }
}

export const fetchMember = (id, memberId, props) => async (dispatch) => {
    try {
        await dispatch(setIsLoading(true));
        await dispatch(setMember(null));
        if (props) {
            const response = await Companies.getMemberUnconfirmed(id, memberId);
            await dispatch(setMember(response.data.data));
        } else {
            const response = await Companies.getMember(id, memberId);
            await dispatch(setMember(response.data.data));
        }

        await dispatch(setIsLoading(false));
    } catch (e) {
        console.error(e);
        await dispatch(setIsLoading(false));

        if (e.response && e.response.status === 401) {
            await dispatch(setIsRelogin({
                isRelogin: true
            }));
        }
    }
};

export const fetchPlannersList = (id, props) => async (dispatch) => {
    try {
        await dispatch(setIsLoading(true));
        await dispatch(setPlanners(null));
        await dispatch(setSendStatus(false));
        const response = await Companies.getPlanners(id);
        await dispatch(setPlanners(response.data.data))
        await dispatch(setIsLoading(false));
    } catch (e) {
        console.error(e);
        await dispatch(setIsLoading(false));

        if (e.response && e.response.status === 401) {
            await dispatch(setIsRelogin({
                isRelogin: true
            }));
        }
    }
}

export const assignPlanner = (id, data, props) => async (dispatch) => {
    try {
        await dispatch(setIsLoading(true));
        await Companies.assignPlanner(id, data);
        await dispatch(setIsLoading(false));
    } catch (e) {
        console.error(e);
        await dispatch(setIsLoading(false));

        if (e.response && e.response.status === 401) {
            await dispatch(setIsRelogin({
                isRelogin: true
            }));
        }
    }
}

export const postCompanyMemberInvite = (id, data, props, setFormErrors) => async (dispatch) => {
    try {
        await dispatch(setSending({
            isSending: true
        }));
        await dispatch(setError(null));
        await Companies.postCompMember(id, data);
        await dispatch(setSending({
            isSending: false
        }));
        await dispatch(setSendStatus(true));
    } catch (e) {
        console.error(e);
        dispatch(setSending({
            isSending: false
        }));

        if (e.response && e.response.status === 401) {
            await dispatch(setIsRelogin({
                isRelogin: true
            }));
        }

        if (e.response && e.response.data.errors) {
            dispatch(setError(e.response.data.errors));
            setFormErrors(e.response.data.errors)
        }
    }
}

export const postCompanyMemberCheck = (id, data, props, setFormErrors) => async (dispatch) => {
    try {
        await dispatch(setSending({
            isSending: true
        }));
        await dispatch(setError(null));
        const response = await Companies.postCompNewAdminCheck(id, data);
        await dispatch(setCompanyNewAdmin(response.data));
        await dispatch(setSending({
            isSending: false
        }));
    } catch (e) {
        console.error(e);
        dispatch(setSending({
            isSending: false
        }));

        if (e.response && e.response.status === 401) {
            await dispatch(setIsRelogin({
                isRelogin: true
            }));
        }

        if (e.response && e.response.data.errors) {
            dispatch(setError(e.response.data.errors));
            setFormErrors(e.response.data.errors)
        }
    }
}

export const postLicenseLimit = (id, params, props) => async (dispatch) => {
    try {
        await dispatch(setIsLoading(true));
        await dispatch(setSendStatus(false));
        const response = await Companies.postSeatsLimit(id, params);
        await dispatch(setSeatsLimit(params.licence_limit))
        await dispatch(setSeatsLimitColor(response.data.limit_color));
        await dispatch(setIsLoading(false));
    } catch (e) {
        console.error(e);
        await dispatch(setIsLoading(false));

        if (e.response && e.response.status === 401) {
            await dispatch(setIsRelogin({
                isRelogin: true
            }));
        }
    }
}

export const postNotifyLicenseLimit = (id, props) => async (dispatch) => {
    try {
        await dispatch(setIsLoading(true));
        await dispatch(setSendStatus(false));
        const response = await Companies.putNotifySeatsLimit(id);
        console.log(response.data.limit_color);
        await dispatch(setSeatsLimitColor(response.data.limit_color));
        await dispatch(setIsLoading(false));
    } catch (e) {
        console.error(e);
        await dispatch(setIsLoading(false));

        if (e.response && e.response.status === 401) {
            await dispatch(setIsRelogin({
                isRelogin: true
            }));
        }
    }
}

export const sendRequestLimitStatus = (id, props) => async (dispatch) => {
    try {
        await dispatch(setIsSending(true));
        await Companies.sendRequestSeatsLimit(id);
        await dispatch(setRequestLimitStatus(true));
        await dispatch(setIsSending(false));
    } catch (e) {
        console.error(e);
        await dispatch(setIsSending(false));

        if (e.response && e.response.status === 401) {
            await dispatch(setIsRelogin({
                isRelogin: true
            }));
        }
    }
}

export const postNewOwner = (id, memberId, roleId, props) => async (dispatch) => {
    try {
        await dispatch(setIsLoading(true));
        await dispatch(setMode('success'));
        await dispatch(setIsOpen({
            isOpen: true
        }));
        await Companies.postNewOwner(id, memberId);
        await dispatch(setCompanyNewAdmin(null));
        await dispatch(setIsLoading(false));
    } catch (e) {
        console.error(e);
        await dispatch(setMode('error'));
        await dispatch(setIsOpen({
            isOpen: true
        }));
        await dispatch(setIsLoading(false));
        if (e.response && e.response.status === 401) {
            await dispatch(setIsRelogin({
                isRelogin: true
            }));
        }
    }
};

export const swapTokens = (companyId, memberId, param) => async (dispatch) => {
    try {
        await dispatch(setIsLoading(true));
        const token_admin = localStorage.token;
        const response = await Companies.getUserToken(companyId, memberId);
        if (response.data) {
            localStorage.setItem('token_admin', token_admin);
            localStorage.setItem('token', response.data);
        }
        await dispatch(
            setUserData({
                first_name: '',
                last_name: '',
                year_of_birth: null,
                gender: '',
                address: null,
                avatar: null
            })
        );
        await dispatch(setIsLoading(false));
    } catch (e) {
        console.error(e);
        await dispatch(setIsLoading(false));

        if (e.response && e.response.status === 401) {
            await dispatch(setIsRelogin({
                isRelogin: true
            }));
        }
    }
}

export const fetchStatisticsGraphData = (companyId, params) => async (dispatch) => {
    try {
        await dispatch(setIsGraphLoading(true));
        const response = await Admin.getStatisticsResult(companyId, params);
        await dispatch(setNetWorthStatistics(response.data.data.net_worth));
        await dispatch(setTotalIncomeStatistics(response.data.data.income));
        await dispatch(setTotalInheritanceStatistics(response.data.data.inheritance));
        await dispatch(setTotalInvestmentStatistics(response.data.data.investment));
        await dispatch(setIsGraphLoading(false));
    } catch (e) {
        console.error(e);
        await dispatch(setIsGraphLoading(false));
        if (e.response && e.response.status === 401) {
            await dispatch(setIsRelogin({
                isRelogin: true
            }));
        }
    }
}

export const fetchFilterStatisticsCities = (companyId, data) => async (dispatch) => {
    try {
        const response = await Admin.getStatisticsCities(companyId, data);
        await dispatch(setStatisticsCities(response.data));
    } catch (e) {
        console.error(e);
        if (e.response && e.response.status === 401) {
            await dispatch(setIsRelogin({
                isRelogin: true
            }));
        }
    }
}

export const fetchFilterStatisticsAgeRange = (companyId) => async (dispatch) => {
    try {
        const response = await Admin.getStatisticsAgeRange(companyId);
        await dispatch(setStatisticsAgeRange(response.data));
    } catch (e) {
        console.error(e);
        if (e.response && e.response.status === 401) {
            await dispatch(setIsRelogin({
                isRelogin: true
            }));
        }
    }
}

export const fetchFilterStatisticsStates = (companyId) => async (dispatch) => {
    try {
        const response = await Admin.getStatisticsStates(companyId);
        await dispatch(setStatisticsStates(response.data));
    } catch (e) {
        console.error(e);
        if (e.response && e.response.status === 401) {
            await dispatch(setIsRelogin({
                isRelogin: true
            }));
        }
    }
}

export const fetchFilterStatisticsUsers = (companyId, data, params) => async (dispatch) => {
    try {
        const response = await Admin.getStatisticsUsers(companyId, data, params);
        await dispatch(setMembers(response.data.data.members));
        await dispatch(setPagination(response.data.data.pagination));
    } catch (e) {
        console.error(e);
        if (e.response && e.response.status === 401) {
            await dispatch(setIsRelogin({
                isRelogin: true
            }));
        }
    }
}


export const {
    setMember,
    setCompanyNewAdmin,
    setSeatsLimitColor,
    setSortPlanner,
    setActiveNumber,
    setMembers,
    setPagination,
    setPlanners,
    setRequestLimitStatus,
    setCompaniesPermissions,
    setSeatsLimit,
    setSendStatus,
    setCompanies,
    setIsLoading,
    setIsSending,
    setCompanyData,
    setError,
    setUploading,
    setNameAvatar,
    setAvatarURL,
    setCompanyOwner,
    setNetWorthStatistics,
    setTotalInheritanceStatistics,
    setTotalInvestmentStatistics,
    setTotalIncomeStatistics,
    setStatisticsStates,
    setStatisticsCities,
    setStatisticsAgeRange,
    setIsGraphLoading
} = companiesSlice.actions;

export default companiesSlice.reducer