import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../shared/SVGIcon/SVGIcon'
import Logo from '../shared/Logo/Logo'
import MailIcon from '../../assets/images/mail.svg'

const Verification = ({ icon = MailIcon, title, children }) => {
  return (
    <div className="verification">
      <Logo className="verification__logo" />
      <div className="verification__content">
        <div className="verification__icon">
          <Icon icon={icon} size="20px" />
        </div>
        <h2>{title}</h2>
        {children}
      </div>
    </div>
  )
};

Verification.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
};

export default Verification
