import { Auth, API } from './Api';

let isRefreshing = false;
let failedQueue = [];
//const baseUrl = window.location.origin;

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    })

    failedQueue = [];
}

export const refreshTokenResponse = {
    onSuccess: (response) => {
        if(response.data.expires_in) {
            const time = new Date().getTime() + 60000 + response.data.expires_in * 1000

            localStorage.setItem('lastSession', time);
        }

        return response
    },
    onFailure: async (error) => {
        const originalRequest = error.config;
        const isNotAuthorized = error.response.status === 401;

        if (isNotAuthorized && !originalRequest._retry) {
            originalRequest._retry = true;

            if(isRefreshing) {
                return new Promise(function(resolve, reject) {
                    failedQueue.push({resolve, reject})
                }).then(token => {
                    originalRequest.headers['Authorization'] = 'Bearer ' + token;
                    return API(originalRequest);
                }).catch(err => {
                    return err
                })
            }

            originalRequest._retry = true;
            isRefreshing = true;

            const refreshToken = localStorage.getItem('token');
            const lastSession = localStorage.getItem('lastSession');
            const currentTime = new Date().getTime();

            if (lastSession > currentTime) {
                return new Promise(function (resolve, reject) {
                    Auth.refreshToken({
                        token: refreshToken
                    })
                      .then(({ data }) => {
                          localStorage.setItem('token', data.token);
                          API.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
                          originalRequest.headers['Authorization'] = 'Bearer ' + data.token;
                          processQueue(null, data.token);
                          resolve(API(originalRequest));
                      })
                      .catch((err) => {
                          processQueue(err, null);
                          reject(err);
                      })
                      .then(() => { isRefreshing = false })
                })
            } else {
                isRefreshing = false
                processQueue([], null);
                localStorage.removeItem('token');
                localStorage.removeItem('token_admin');
                //window.location.replace(`${baseUrl}/login`);
            }

        }

        return Promise.reject(error);
    }
}

export const refreshTokenRequest = {
    onSuccess: (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    onFailure: (error) => {
        Promise.reject(error);
    }
}