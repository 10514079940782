import React, { useEffect, useState } from 'react';
import LayoutAdmin from '../../components/Layout/LayoutAdmin';
import { useParams, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../components/shared/Modal/Modal';
import Loading from '../../components/shared/Loading/Loading';
import { Button, Switch, FormControlLabel } from '@mui/material';
import { updateCompanyPermissions, fetchCompany, fetchCompanyPermissions } from '../../redux/slices/companiesSlice';
import { isNull } from 'lodash';

const AdminCompanyPermissions = ({ props, isAdmin }) => {
  const dispatch = useDispatch();
  const { id, permissions } = useSelector((state) => state.user.user);
  const { info } = useSelector((state) => state.companies.company);
  const { companyId } = useParams();
  const [isModal, setModal] = useState(false);
  const [isModalAll, setModalAll] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: '',
    content: '',
  });
  const [values, setValues] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  const mobileView = 999;

  const handleResize = () => {
    mobileView >= window.innerWidth ? setIsMobile(true) : setIsMobile(false);
  };

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isNull(info.permissions.blog)) {
      setValues(info?.permissions);
    }
    // eslint-disable-next-line
  }, [info.permissions]);

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchCompany(companyId));
      await dispatch(fetchCompanyPermissions(companyId));
    }
    
    if(companyId) {
      fetch()
    }
    // eslint-disable-next-line
  }, [companyId]);

  const handleCurrencyChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.checked,
    });
  };

  const handleResetForm = () => {
    setModal(false);
    setValues({
      ...values,
      [modalContent.field]: !values[modalContent.field],
    });
    setModalContent({
      title: '',
      field: '',
      content: '',
    });
  };

  const handleResetAllForm = () => {
    setModalAll(false);
    setValues({
      ...info?.permissions,
    });
    setModalContent({
      title: '',
      field: '',
      content: '',
    });
  };

  if (!!id && !permissions?.change_company_permissions) return <Redirect to="/admin" />;
  
  return (
    <LayoutAdmin companyId={companyId} isAdmin={isAdmin}>
      {isNull(values) ? (
        <Loading />
      ) : (
        <>
          <div className="member-comapny">
        <div className="member-comapny__info">
          <div className="member-comapny__logo">
            <img src={info?.image} alt="Company logo" />
          </div>
          <div className="member-comapny__title">
            <h3>{info?.name}</h3>
            {!isMobile && (
              <p>
                Feedback Email: <span>{info?.email}</span>
              </p>
            )}
          </div>
        </div>
        {isMobile && (
          <div className="mobile-feedback">
            Feedback Email: <span>{info?.email}</span>
          </div>
        )}
      </div>
          <div className="company">
            <div className="switcher__wrap">
              <div className="switcher__wrap--top">
                <h4>Permissions</h4>
                {values?.blog && values?.calculators && values?.alerts && values?.assumptions ? (
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => {
                      setValues({
                        blog: false,
                        calculators: false,
                        alerts: false,
                        assumptions: false,
                      });
                      setModalAll(true);
                    }}
                  >
                    Deactivate all
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => {
                      setValues({
                        blog: true,
                        calculators: true,
                        alerts: true,
                        assumptions: true,
                      });
                      dispatch(
                        updateCompanyPermissions(companyId, {
                          blog: true,
                          calculators: true,
                          alerts: true,
                          assumptions: true,
                        })
                      );
                    }}
                  >
                    Activate all
                  </Button>
                )}
              </div>
              <div className="switcher__wrap--content">
                <FormControlLabel
                  control={
                    <Switch
                      className="custom-switcher"
                      checked={values?.blog}
                      value={values?.blog}
                      onChange={(e) => {
                        handleCurrencyChange(e);
                        setModalContent({
                          title: 'Blog',
                          field: 'blog',
                          content:
                            'Turning off the blog functionality will delete all blog content created by your company.  Are you sure you want to proceed?',
                        });
                        if (!values?.blog) {
                          dispatch(
                            updateCompanyPermissions(companyId, {
                              ...values,
                              blog: true,
                            })
                          );
                        } else {
                          setModal(true);
                        }
                      }}
                      name="blog"
                    />
                  }
                  label="Blog"
                  labelPlacement="start"
                />

                <FormControlLabel
                  control={
                    <Switch
                      className="custom-switcher"
                      checked={values?.calculators}
                      value={values?.calculators}
                      onChange={(e) => {
                        handleCurrencyChange(e);
                        setModalContent({
                          title: 'Calculators',
                          field: 'calculators',
                          content:
                            'Turning off the calculator functionality will disable the calculators for your company and remove all the overrides created by your company, restoring values to the default state. Are you sure you want to proceed?',
                        });
                        if (!values?.calculators) {
                          dispatch(
                            updateCompanyPermissions(companyId, {
                              ...values,
                              calculators: true,
                            })
                          );
                        } else {
                          setModal(true);
                        }
                      }}
                      name="calculators"
                    />
                  }
                  label="Calculators"
                  labelPlacement="start"
                />

                <FormControlLabel
                  control={
                    <Switch
                      className="custom-switcher"
                      checked={values?.alerts}
                      value={values?.alerts}
                      onChange={(e) => {
                        handleCurrencyChange(e);
                        setModalContent({
                          title: 'Alerts',
                          field: 'alerts',
                          content:
                            'Turning off the alerts functionality will disable the functionality and remove all the overrides created by your company, restoring values to the default state. Are you sure you want to proceed?',
                        });
                        if (!values?.alerts) {
                          dispatch(
                            updateCompanyPermissions(companyId, {
                              ...values,
                              alerts: true,
                            })
                          );
                        } else {
                          setModal(true);
                        }
                      }}
                      name="alerts"
                    />
                  }
                  label="Alerts"
                  labelPlacement="start"
                />

                <FormControlLabel
                  control={
                    <Switch
                      className="custom-switcher"
                      checked={values?.assumptions}
                      value={values?.assumptions}
                      onChange={(e) => {
                        handleCurrencyChange(e);
                        setModalContent({
                          title: 'Assumptions',
                          field: 'assumptions',
                          content:
                            'Turning off the assumptions functionality will remove all the overrides created by your company, restoring values to the default state and you won’t be able to customize assumptions for your company. Are you sure you want to proceed?',
                        });
                        if (!values?.assumptions) {
                          dispatch(
                            updateCompanyPermissions(companyId, {
                              ...values,
                              assumptions: true,
                            })
                          );
                        } else {
                          setModal(true);
                        }
                      }}
                      name="assumptions"
                    />
                  }
                  label="Assumptions"
                  labelPlacement="start"
                />
              </div>
            </div>
            <Modal
              open={isModal}
              button="Confirm"
              removeButton="Cancel"
              remove={handleResetForm}
              close={handleResetForm}
              onClick={async () => {
                await dispatch(updateCompanyPermissions(companyId, values));
                setModal(false);
              }}
              customClass="permission__modal"
              children={
                <>
                  <h3>{`Turning Off ${modalContent.title} Functionality`}</h3>
                  <p>{modalContent.content}</p>
                  <div className="company__modal-icon">
                    <img src={info?.image} alt={info?.name ?? 'Logo'} />
                  </div>
                  <h5>{info?.name}</h5>
                </>
              }
            />
            <Modal
              open={isModalAll}
              button="Confirm"
              removeButton="Cancel"
              remove={handleResetAllForm}
              close={handleResetAllForm}
              onClick={async () => {
                await dispatch(updateCompanyPermissions(companyId, values));
                setModalAll(false);
              }}
              customClass="permission__modal"
              children={
                <>
                  <h3>Turning Off All Permissions Functionality</h3>
                  <p>
                    Turning off all the permissions will delete all Blog content and Calculators, Alerts, and Assumptions
                    settings associated with your company. Are you sure you want to proceed?
                  </p>
                  <div className="company__modal-icon">
                    <img src={info?.image} alt={info?.name ?? 'Logo'} />
                  </div>
                  <h5>{info?.name}</h5>
                </>
              }
            />
          </div>
        </>
      )}
    </LayoutAdmin>
  );
};

export default AdminCompanyPermissions;
