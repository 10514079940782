import React from 'react'
import Layout from '../../components/Layout/Layout'
import { BackButton } from '../../components/shared/Buttons'
import Tooltip from '../../components/shared/Tooltip/Tooltip'

const InvestmentCheatSheet = ({ props }) => {

  return (
    <Layout decor>
      <BackButton title="Back to Calculators" path="/calculators" className="back-button--position" />
      <div className="calculators calculators--medium">
        <div className="calculators__title calculators__title--center">
          <div>
            <h2>Investment cheat sheet</h2>
            <Tooltip
              title={
                <>
                  <h6>Investment cheat sheet</h6>
                  This is Ashraf's personal notes of Benjamin Graham's seminal work, "The Intelligent Investor". Benjamin Graham was Warren Buffet's mentor and this is the only book you'll ever need to read on investing.
                  <hr className="tooltip__line"/>
                  <h6>Example</h6>
                  You are curious where you should invest your money, whether it be during a boom or a recession. You're not sure if you should invest in individual stocks or funds, how much to invest in bonds and stocks, and want to know what pitfalls to look out for.
                </>
              }
            />
          </div>
        </div>
        <div className="calculators__text">
          <div className="calculators__text-title">A Financial Guide by <span className="text-accent">Benjamin Graham</span></div>
          <h5>Definition of investing.</h5>
          <p>Must thoroughly analyze the company, protect yourself against serious losses, and aspire to adequate, not extraordinary performance.</p>
          <h5>Market  return estimates</h5>
          <ul>
            <li>Expect 3.5% dividend  and 4% price appreciation. </li>
            <li>2003 estimates = 1.5%-2% EPS real return,2.4% inflation, 1.9% dividend yield = 5.8%-6% nominal return</li>
          </ul>
          <h5>Inflation protection</h5>
          <ul>
            <li>Expert 2.5%-3% inflation;</li>
            <li>Stocks are not perfect hedges against inflation, but do better than bonds as inflation protection;</li>
            <li>Put 10% of retirement assets in TIPs to protect against inflation and do not trade them as they can be volatile in short run</li>
            <li>Consider gold an inflation hedge. Never allocate more than 2% of assets towards gold for those aged 65 or less. NO more than 5% for those over 65</li>
          </ul>
          <h5>On stock picking.</h5>
          <h5>P/E</h5>
          <ul>
            <li>Less than 10 is cheap;</li>
            <li>Between 10 and 20 is moderate;</li>
            <li>Greater than 20 is expensive since price likely already reflects growth estimates. Only 10% of large US companies increase their earnings by 20% for at least 5 consecutive years. Only 3% do this for at least 10 consecutive years. NONE have done it for 15 or more years in a row</li>
          </ul>
          <h5>Price</h5>
          <ul>
            <li>Price / share &lt; 1.33 *  Tangible asset value, where tangible asset  value = (SE - intangibles)</li>
          </ul>
          <h5>Market Cap</h5>
          <ul>
            <li>Greater than $10 billion or do similar amount in business. There are roughly 300 stocks to choose from that fit this market cap profile.</li>
          </ul>
          <h5>Prominence</h5>
          <ul>
            <li>Choose stocks where company ranks among top 1/4 - 1/3 in size in their  industry group</li>
          </ul>
          <h5>Financing</h5>
          <ul>
            <li>Choose stocks where the company is conservatively financed, with a record of continuous dividend payments over the last 10-20 years. This gives 255 companies in <a href="https://en.wikipedia.org/wiki/S%26P_500" target="_blank" rel="noreferrer">S&P 500</a> that fit this profile.</li>
          </ul>
          <h5>On investor confidence in their own abilities</h5>
          <p>Be humble about your forecasting abilities as it will keep you from risking too much on a view of the future that may very well turn out to be wrong.</p>
          <h5>Asset allocation  strategy</h5>
          <p>50% in stocks, 50% in bonds is preferred asset allocation. No more than 75% in stocks, however, no matter what age.</p>
          <h5>On investment management </h5>
          <p><a href="https://www.investopedia.com/terms/d/dollarcostaveraging.asp#:~:text=Dollar%2Dcost%20averaging%20(DCA)%20is%20an%20investment%20strategy%20in,price%20and%20at%20regular%20intervals." target="_blank" rel="noreferrer">Dollar cost averaging</a> is a sound investment strategy for a defensive investor</p>
          <h5><a href="https://www.investopedia.com/terms/j/junkbond.asp" target="_blank" rel="noreferrer">Second grade bonds.</a></h5>
          <ul>
            <li>If you purchase second grade bonds - which an intelligent investor should rarely if ever need to purchase - always purchase at a <a href="https://www.investopedia.com/terms/d/discount.asp#:~:text=When%20a%20bond%20is%20sold,or%20a%20better%20company%20history)." target="_blank" rel="noreferrer">discount</a>, well below <a href="https://www.investopedia.com/terms/p/parvalue.asp" target="_blank" rel="noreferrer">par</a>, since higher coupons typically come with an eventual loss of premium for second grade premium bonds</li>
            <li>Best bet is to invest in a <a href="https://www.investopedia.com/terms/j/junkbond.asp" target="_blank" rel="noreferrer">junk bond</a> fund which helps diversify away risk</li>
          </ul>
          <h5>Caution to active traders</h5>
          <p>You need an average of a 10% return just to break even since it takes 2% - 4% to buy a stock, 4% to sell a stock, 38.6% ordinary income tax versus 20% capital gains tax</p>
          <h5>Three suggestions for enterprising investors</h5>
          <ul>
            <li>Buy unpopular issues: Low P/E, using 7 year or multi-year historical / trailing PEs. For example, purchase 10 most unpopular in <a href="https://www.investopedia.com/terms/d/djia.asp" target="_blank" rel="noreferrer">DJIA</a>, <a href="https://www.investopedia.com/terms/r/rebalancing.asp" target="_blank" rel="noreferrer">re-balancing</a> every year (this has not proved profitable lately, so investors beware).</li>
            <li>Purchase bargain issues: Purchase issues trading at low price relative to 7 year trailing P/E. These bargains should also exhibit constant positive trailing historical earnings with no years of earnings deficit, plus should have sufficient size and strength to meet possible setbacks in the future. Ideally also has (current assets (net) value per share > price per share) or (book value per share > price per share) or (current working capital per share > price per share) where current working capital = current assets  - total liabilities and where book value does not include intangible assets.</li>
            <li>Purchase in special situations: Purchase issues where company is filing bankruptcy, undergoing merger-arbitrage, lawsuit, where company getting unduly punished by the market and hence are selling at a discount to appropriate value.</li>
          </ul>
          <h5><a href="https://www.investopedia.com/terms/d/diversification.asp" target="_blank" rel="noreferrer">Diversification</a></h5>
          <p>Consider diversifying into international markets (including emerging markets) in order to minimize overall portfolio risk. Consider up to 1/3 of a portfolio in international investments.</p>
          <h5>Measuring investment success</h5>
          <p>Best way to measure your investment success is not based on whether you're beating the market but whether you've put in place a financial plan and a behavioral discipline that are likely to get you where you want to go.</p>
          <h5><a href="https://www.investopedia.com/terms/c/closed-endinvestment.asp" target="_blank" rel="noreferrer">Closed-end</a> versus <a href="https://www.investopedia.com/terms/o/open-endfund.asp" target="_blank" rel="noreferrer">open-end</a> funds</h5>
          <p>Better to buy a closed end fund at a discount than an open end fund at a premium (which is usually the case, as average trades at ~9% premium) because premium eats into return with no real evidence of open end funds outperforming closed end funds</p>
          <h5>Mutual funds versus index funds</h5>
          <p>Index funds will almost always outperform mutual funds over a 20 year period due to average expenses of mutual funds being 3.5% year</p>
          <h5>Ratios for intelligent bond valuation</h5>
          <p><a href="https://www.investopedia.com/terms/i/interestcoverageratio.asp" target="_blank" rel="noreferrer">Interest coverage ratio</a>, stock/ debt face, property value of underlying asset.</p>
          <h5>5 key elements of valuing a company</h5>
          <ul>
            <li>Company's general long term  prospects (what makes the company grow, where are profits coming from)</li>
            <li>Quality  of management;</li>
            <li>Financial strength and capital structure;</li>
            <li>Dividend record;</li>
            <li>Current dividend rate.</li>
          </ul>
          <h5>Red flags for the intelligent investor</h5>
          <ul>
            <li>Companies that project based on months versus years, spend very little on R&D;</li>
            <li>Use <a href="https://www.investopedia.com/terms/e/ebitda.asp" target="_blank" rel="noreferrer">EBITDA</a> instead of net profit, speak consistently about non-recurring charges;</li>
            <li>Blame poor quarters and years on economy, re-price employee options;</li>
            <li>Exorbitantly pay C-suite executives, who show managerial inability to forecast growth and profit correctly from one year to the next;</li>
            <li>Have no string brand identity, no monopoly, no economies of scale, no tangible assets and no resistance to substitution.</li>
          </ul>
          <h5>7 criteria for including a stock in a <a href="https://www.investopedia.com/terms/d/defensiveinvestmentstrategy.asp#:~:text=A defensive investment strategy is,risk of losing the principal." target="_blank" rel="noreferrer">defensive investor's</a> portfolio</h5>
          <ul>
            <li>Adequate size > $2 billion <a href="https://www.investopedia.com/terms/m/marketvalue.asp#:~:text=Market value (also known as,a particular equity or business." target="_blank" rel="noreferrer">market value</a>;</li>
            <li>A sufficiently strong financial condition;</li>
            <li>Continued dividends for at least past 20 years;</li>
            <li>No earnings deficit in last 10 years;</li>
            <li>10 year growth of at least 33% in <a href="https://www.investopedia.com/terms/e/eps.asp" target="_blank" rel="noreferrer">per share earnings</a>;</li>
            <li>Price of stock no more than 1.5 times net asset value (book value);</li>
            <li>Price of stock no more than 15 times average earnings over the last 3 years.</li>
          </ul>
          <h5>Enterprising investor versus speculator.</h5>
          <p>Being an enterprising investor - by the very nature of the name - requires the ability to intelligently analyze and calculate and research securities to identify undervalued opportunities. Anything less than that level of effort by default makes a person a speculator</p>
          <h5>On greed</h5>
          <p>Bulls make money bears make money, pigs get slaughtered.</p>
          <h5>On dividends</h5>
          <ul>
            <li>Managers have proven themselves to be worst case wasteful, best case inefficient with utilizing of extra cash (e.g mergers and acquisitions usually fail);</li>
            <li>Stock buy-backs not as good as cash in hand</li>
            <li>Stock options granted aren't in shareholder's best interests because employees usually sell right after they exercise</li>
          </ul>
        </div>
      </div>
    </Layout>
  )
}

export default InvestmentCheatSheet
