import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import clsx from 'classnames'
import { fillStatus } from '../../utils/utils'
import Arrow from '../../assets/images/icons/arrow.svg'
import SVGIcon from '../shared/SVGIcon/SVGIcon'
import ModalBox from '../shared/Modal/Modal'
import { postPlanTypeUser } from '../../redux/slices/userSlice'
import { fetchPercentages } from '../../redux/slices/percentagesSlice';
import TreeCount from '../../components/Tree/TreeCount'

const TreeSubItem = ({
  value,
  title,
  count,
  path,
  isDisabled,
  redirect,
  modalContent,
  isBlocked,
  isLoading,
  className,
  isBasic
}) => {
  const dispatch = useDispatch()
  let history = useHistory();
  const [isOpen, setOpen] = useState(false)
  const [isHover, setIsHover] = useState(false)
  const hoverOn = () => setIsHover(true)
  const hoverOff = () => setIsHover(false)

  const handlePlan = async () => {
    setOpen(false);
    await dispatch(postPlanTypeUser({ plan_type: 2 }));
    await dispatch(fetchPercentages());
  }

  const handleClick = path => {
    history.push(path)
    setOpen(false)
  }

  return (
    <>
      {!isDisabled
        ? (
          <Link to={path}
                className={clsx(`tree-sub-item
                                           tree-sub-item--${value || 'default'}
                                           tree-sub-item--${fillStatus(count)}`,
                                          {
                                            'tree-sub-item--hover' : isHover,
                                            'tree-sub-item--disabled': isDisabled,
                                            'tree-sub-item--blocked': isBlocked,
                                            [`${className}`]: className
                                          })}
                onMouseEnter={hoverOn}
                onMouseLeave={hoverOff}
          >
            <div className="tree-sub-item__label">
              <div>{title}</div>
              <TreeCount
                value={count}
                loading={isLoading}
                small
              />
            </div>

            <SVGIcon icon={Arrow} color="#8C8C8C" className="tree-sub-item__icon tablet-visible" />
          </Link>
        )
        : (<div className={clsx(`tree-sub-item
                                tree-sub-item--${value || 'default'}
                                tree-sub-item--${fillStatus(count)}`,
                                {
                                  'tree-sub-item--hover' : isHover,
                                  'tree-sub-item--disabled': isDisabled,
                                  'tree-sub-item--blocked': isBlocked,
                                  [`${className}`]: className
                                })}
                onMouseEnter={hoverOn}
                onMouseLeave={hoverOff}
                onClick={() => setOpen(true)}
          >
            <div className="tree-sub-item__label">
              <div>{title}</div>

              <TreeCount
                value={count}
                loading={isLoading}
                small
              />
            </div>

            <SVGIcon icon={Arrow} color="#8C8C8C" className="tree-sub-item__icon tablet-visible" />
          </div>
        )
      }
      {isBasic ?
        <ModalBox
        small
        open={isOpen}
        close={() => setOpen(false)}
        onClick={() => handlePlan()}
        closeButton="Cancel"
        button="Switch to a Comprehensive Plan"
        title="This is a comprehensive step!"
        children={
          <p>You chose to create a basic plan. This step is only used for a 
            <strong className="text-success"> Comprehensive plan.</strong> Would you like to change to a comprehensive plan? It 
            will require more input!
          </p>
          } />
        :
      <ModalBox
        small
        open={isOpen}
        close={() => setOpen(false)}
        onClick={() => handleClick(redirect)}
        closeButton="Later"
        button="Add information"
        title="Sorry"
        children={modalContent} />
      }
    </>
  )
}

TreeSubItem.propTypes = {
  value: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  path: PropTypes.string,
  isDisabled: PropTypes.bool,
  isBlocked: PropTypes.bool,
  isBasic: PropTypes.bool,
  handleClick: PropTypes.func,
  modalContent: PropTypes.node,
  redirect: PropTypes.string
}

export default TreeSubItem
