import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import WrapWidget from './WrapWidget'
import TaskCard from '../Task/TaskCard'
import WidgetPlaceholder from './WidgetPlaceholder'
import Image from '../../assets/images/image-tasks.png'
import Slider from 'react-slick'


const WidgetTasks = ({
 tasks,
 loading,
 removeTask,
 updateTask,
 closeTask,
 planning_types,
 priorities,
 status,
 revertTask,
 addClassName,
 showDetails,
  onCloseDrawer = () => {}
}) => {
  const [isPlaceholder, setIsPlaceholder] = useState(true);
  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 640,
      },
      {
        breakpoint: 10000,
        settings: 'unslick'
      }
    ]
  };
  useEffect(() => {
    (tasks.length < 1) ? setIsPlaceholder(true) : setIsPlaceholder(false)
  }, [tasks])

  return (
    <WrapWidget
      title="Implement tasks"
      link={{
        path: "/tasks",
        title: "View all",
      }}
      placeholder={
        <WidgetPlaceholder
          title="You must fill out your input steps before tasks can appear."
          image={Image}
          button={{
            title: "Input",
            href: "/input",
          }}
        />
      }
      className="widget--tasks"
      isPlaceholder={isPlaceholder}
      loading={loading}
      addClassName={addClassName}
    >
      <Slider {...settings} className="widget__list">
        {tasks &&
          tasks.length > 0 &&
          tasks.map((item, i) => (
            <div key={`task-${item.id}`}>
              <TaskCard
                id={item.id}
                removeTask={removeTask}
                updateTask={updateTask}
                closeTask={closeTask}
                revertTask={revertTask}
                title={item.name}
                status={item.status.name}
                sub_status={item.task_status.name}
                category={item.category.planning.name}
                priority={item.priority.name}
                content={item.description}
                icon={item.category.planning.image}
                priorityList={priorities}
                categoryList={planning_types}
                statusList={status}
                showDetails={() => showDetails(item.id, item.task_status.name)}
                onCloseDrawer={onCloseDrawer}
              />
            </div>
          ))}
      </Slider>
    </WrapWidget>
  );
};

WidgetTasks.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.any),
  loading: PropTypes.bool,
  planning_types: PropTypes.array,
  priorities: PropTypes.array,
  status: PropTypes.array,
  removeTask: PropTypes.func,
  updateTask: PropTypes.func,
  closeTask: PropTypes.func,
}

export default WidgetTasks;
