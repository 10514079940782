import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { BackButton } from '../../../components/shared/Buttons'
import Layout from '../../../components/Layout/Layout'
import PageTitle from '../../../components/PageTitle/PageTitle'
import LayoutForm from '../../../components/Layout/LayoutForm'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserIncomes, postUserIncomes } from '../../../redux/slices/financesSlice'
import { Form, Formik } from 'formik'
import { Button, TextareaAutosize } from '@mui/material'
import { FormGroup, InputCurrency, FormSelect } from '../../../components/shared/Form'
import { fetchTotalPercent } from '../../../redux/slices/percentagesSlice'
import Tooltip from '../../../components/shared/Tooltip/Tooltip'
import ProgressCircle from '../../../components/shared/ProgressBar/ProgressCircle'
import Modal from '../../../components/shared/Modal/Modal'
import { setIsOpen } from '../../../redux/slices/modalSlice'
import { incomeSchema } from '../../../utils/validationFinances'
import { getPercent } from '../../../utils/utils'
import Priority from '../../../components/shared/Priority/Priority'

const Income = ({ props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userId = useSelector(state => state.user.user.id);
  const isBasic = useSelector((state) => state.user.user.plan_type === 1);
  const totalPercent = useSelector(state => state.percentages.total)
  const incomes = useSelector(state => state.finances.incomes);
  const sending = useSelector(state => state.sending.isSending);
  const percents = useSelector(state => state.percentages.percents);
  const isOpen = useSelector(state => state.modal.isOpen);
  const [isMobile, setIsMobile] = useState(false);
  const mobileView = 999;
  const handleResize = () => {
    (mobileView >= window.innerWidth) ? setIsMobile(true) : setIsMobile(false)
  }

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchUserIncomes())
      await dispatch(fetchTotalPercent(isBasic))
    }

    if (userId) {
      fetch()
    }

    // eslint-disable-next-line
  }, [userId])

  const onSubmit = (values) => {
    dispatch(postUserIncomes(values, props))
  }

  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }))
  }

  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }))
    history.push("/input")
  }

  const incomePercent = getPercent(percents, 'income')
  const profile       = getPercent(percents, 'profile')

  return (
    <Layout totalPercent={totalPercent} sticky isBlockedPage={percents && profile === 0}>
      <LayoutForm>
        <BackButton title={isMobile ? 'Back' : 'Back to Tree'} />
        <PageTitle
          title="Income"
          content="Tell us about your sources and amount of income. If you anticipate changes to your income (beyond standard wage increases), please include them in the blank columns below. Simply enter the year in which those changes will begin and the new amount for a particular change."
          status={percents && incomePercent ? incomePercent : '0'}
          info={
            <>
              <h6>Incomes</h6>
              <Priority option="High" />
              <div>Enter all your itemized sources of income.  This is an important component to calculating your financial condition and estimating your current and future net worth.</div>
              <hr className="tooltip__line"/>
              <h6>Example</h6>
              <div>Say you earn $5000 per month take-home (after taxes), your spouse earns $2300, and you receive a $10,000 yearly bonus.  Enter all this information -- and more -- in this step.</div>
            </>
          }
        />

        <div className="layout-form__content-holder">
          <Formik
            enableReinitialize
            initialValues={incomes && {...incomes}}
            onSubmit={onSubmit}
            validationSchema={incomeSchema}
          >
            {({ errors, handleChange, handleBlur, values, setFieldValue }) => (
              <Form>
                <div className="incomes-list">
                  <div className="incomes-list__item">
                    <div className="incomes-list__item-title">
                      Income tax filing withholding (1, 2, 3)
                      <Tooltip
                        title={
                          <>
                            <h6>Tax Withholding</h6>
                            <Priority option="Lowest" />
                            <div>The federal government allows you to withhold taxes, depending on your financial situation.  The less you withhold, the less you will be taxed on a paycheck per paycheck basis.  Please enter the withholding number you designated on your W2 form.</div>
                          </>
                        }
                        size="small"
                      />
                    </div>
                    <div className="incomes-list__item-content">
                      <FormGroup name="income_tax_filling" labelText="Number">
                        <FormSelect
                          options={[
                            {
                              key: 1,
                              value: 1,
                              label: '1'
                            },
                            {
                              key: 2,
                              value: 2,
                              label: '2'
                            },
                            {
                              key: 3,
                              value: 3,
                              label: '3'
                            }
                          ]}
                          id="income_tax_filling"
                          value={values.income_tax_filling || ''}
                          name="income_tax_filling"
                          onChange={(e) => {
                            setFieldValue(e.target.name, e.target.value)
                          }}
                          placeholder="Number"
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="incomes-list__item">
                    <div className="incomes-list__item-title">
                      Monthly Income (you, take-home, after pre-tax retirement funding)

                      <Tooltip
                        title={
                          <>
                            <h6>Your Income</h6>
                            <Priority option="High" />
                            <div>This is your paycheck amount, after taxes, benefits and other expenses are deducted.</div>
                          </>
                        }
                        size="small"
                      />
                    </div>
                    <div className="incomes-list__item-content">
                      <FormGroup name="monthly_income" labelText="Amount">
                        <InputCurrency
                          id="monthly_income"
                          placeholder="$0.00"
                          name="monthly_income"
                          onChange={handleChange}
                          value={values.monthly_income || ''}
                          onBlur={handleBlur}
                          hasError={!!errors.monthly_income}
                        />
                        {errors.monthly_income && <span className="form__msg-error">{errors.monthly_income}</span>}
                      </FormGroup>
                    </div>
                  </div>
                  <div className="incomes-list__item">
                    <div className="incomes-list__item-title">Monthly Income (spouse, take-home, after pre-tax retirement funding)</div>
                    <div className="incomes-list__item-content">
                      <FormGroup name="spouse_monthly_income" labelText="Amount">
                        <InputCurrency
                          id="spouse_monthly_income"
                          placeholder="$0.00"
                          name="spouse_monthly_income"
                          onChange={handleChange}
                          value={values.spouse_monthly_income || ''}
                          onBlur={handleBlur}
                          hasError={!!errors.spouse_monthly_income}
                        />
                        {errors.spouse_monthly_income && <span className="form__msg-error">{errors.spouse_monthly_income}</span>}
                      </FormGroup>
                    </div>
                  </div>
                  <div className="incomes-list__item">
                    <div className="incomes-list__item-title">
                      Yearly Bonus (you)

                      <Tooltip
                        title={
                          <>
                            <h6>Your Bonus</h6>
                            <Priority option="High" />
                            <div>Enter any monetary bonus you receive during the course of the year.  For example, say you make $50,000 in salary each year but receive an end of year bonus of $10,000.  Enter $10,000 in this field.</div>
                          </>
                        }
                        size="small"
                      />
                    </div>
                    <div className="incomes-list__item-content">
                      <FormGroup name="yearly_bonus" labelText="Amount">
                        <InputCurrency
                          id="yearly_bonus"
                          placeholder="$0.00"
                          name="yearly_bonus"
                          onChange={handleChange}
                          value={values.yearly_bonus || ''}
                          onBlur={handleBlur}
                          hasError={!!errors.yearly_bonus}
                        />
                        {errors.yearly_bonus && <span className="form__msg-error">{errors.yearly_bonus}</span>}
                      </FormGroup>
                    </div>
                  </div>
                  <div className="incomes-list__item">
                    <div className="incomes-list__item-title">Yearly Bonus (spouse)</div>
                    <div className="incomes-list__item-content">
                      <FormGroup name="spouse_yearly_bonus" labelText="Amount">
                        <InputCurrency
                          id="spouse_yearly_bonus"
                          placeholder="$0.00"
                          name="spouse_yearly_bonus"
                          onChange={handleChange}
                          value={values.spouse_yearly_bonus || ''}
                          onBlur={handleBlur}
                          hasError={!!errors.spouse_yearly_bonus}
                        />
                        {errors.spouse_yearly_bonus && <span className="form__msg-error">{errors.spouse_yearly_bonus}</span>}
                      </FormGroup>
                    </div>
                  </div>
                  <div className="incomes-list__item">
                    <div className="incomes-list__item-title">Other Monthly (e.g. Alimony, Stock Options)</div>
                    <div className="incomes-list__item-content">
                      <FormGroup name="other_monthly" labelText="Amount">
                        <InputCurrency
                          id="other_monthly"
                          placeholder="$0.00"
                          name="other_monthly"
                          onChange={handleChange}
                          value={values.other_monthly || ''}
                          onBlur={handleBlur}
                          hasError={!!errors.other_monthly}
                        />
                        {errors.other_monthly && <span className="form__msg-error">{errors.other_monthly}</span>}
                      </FormGroup>
                    </div>
                  </div>
                  <div className="incomes-list__item">
                    <div className="incomes-list__item-title">
                      Estimated NET taxes due each April when filing taxes (Yearly)

                      <Tooltip
                        title={
                          <>
                            <h6>Net Taxes Due</h6>
                            <Priority option="Medium" />
                            <div>If you owe taxes at tax year end in April, enter the approximate amount you'll owe as a POSITIVE number.  This tax amount is not what you pay in taxes but the net balance you owe and write a check out for when you're doing your taxes.  If you typically get a refund, then enter that amount as a NEGATIVE number.  If you're not sure how much you'll owe, just use last year's amount as an approximation for what you'll owe this year if your life hasn't significantly changed since last year.</div>
                          </>
                        }
                        size="small"
                      />
                    </div>
                    <div className="incomes-list__item-content">
                      <FormGroup name="estimated_taxes" labelText="Amount">
                        <InputCurrency
                          id="estimated_taxes"
                          placeholder="$0.00"
                          name="estimated_taxes"
                          onChange={handleChange}
                          value={values.estimated_taxes || ''}
                          onBlur={handleBlur}
                          hasError={!!errors.estimated_taxes}
                        />
                        {errors.estimated_taxes && <span className="form__msg-error">{errors.estimated_taxes}</span>}
                      </FormGroup>
                    </div>
                  </div>
                  <div className="incomes-list__item">
                    <div className="incomes-list__item-title">
                      Other income (monthly)

                      <Tooltip
                        title={
                          <>
                            <h6>Additional Income</h6>
                            <Priority option="High" />
                            <div>Please enter any other income you generate.  Please also remember to provide a brief name for each additional piece of income.</div>
                          </>
                        }
                        size="small"
                      />
                    </div>
                    <div className="incomes-list__item-content">
                      <FormGroup name="others" labelText="Amount">
                        <InputCurrency
                          id="others"
                          placeholder="$0.00"
                          name="others"
                          onChange={handleChange}
                          value={values.others || ''}
                          onBlur={handleBlur}
                          hasError={!!errors.others}
                        />
                        {errors.others && <span className="form__msg-error">{errors.others}</span>}
                      </FormGroup>
                    </div>
                  </div>
                </div>
                <div className="form__comment-box mt--medium">
                  <h5>Additional info</h5>
                  <p>Is there any other Income information that you believe is important for ValueSoft to know about? If so, please let us know about it:</p>
                  <TextareaAutosize
                    maxLength="200"
                    name="additional_info"
                    value={values.additional_info || ''}
                    className="form__textarea"
                    placeholder="Maximum characters: 200"
                    onChange={handleChange}
                  />
                  <div className="form__button form__button--center">
                    <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                      Save
                      {sending && <span className="spinner" />}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <Modal
          open={isOpen}
          small
          button="Done"
          onClick={handleClick}
          close={onClose}
        >
          <h3>Good Job!</h3>
          <p>Your progress increased 100%</p>
          <ProgressCircle amount={100} small />
        </Modal>
      </LayoutForm>
    </Layout>
  );
};

export default Income;
