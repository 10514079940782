import React, {useEffect, useState} from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../../components/Layout/Layout'
import { BackButton } from '../../components/shared/Buttons'
import ImageSearch from '../../assets/images/image-search.png'
import { searchData } from '../../redux/slices/searchSlice'
import Loading from '../../components/shared/Loading/Loading'
import TaskCard from '../../components/Task/TaskCard'
import BlogCard from '../../components/Cards/BlogCard'
import { Input } from '../../components/shared/Form'
import debounce from 'lodash/debounce'
import {
  deleteTask,
  taskDone,
  updateTaskDetails,
  revertTask,
  taskViewed,
  fetchTaskDetails
} from '../../redux/slices/taskSlice'
import {fetchCategoriesData, fetchStatusData, fetchTaskStatusData} from '../../redux/slices/optionsSlice'

const useQuery = () => new URLSearchParams(useLocation().search)

const Search = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const { id } = useSelector(state => state.user.user)
  const { results, isLoading, query } = useSelector(state => state.search)
  const [value, setValue] = useState(query || '')
  const { priorities, status, categories } = useSelector(state => state.options);

  let searchParams = useQuery().get('query');

  if(!results || (results.tasks.length < 1 && results.posts.length < 1)){
    location.state = undefined
  }

  useEffect(() => {
    async function fetchOptions() {
      Promise.all([
        dispatch(fetchStatusData()),
        dispatch(fetchTaskStatusData()),
        dispatch(fetchCategoriesData())
      ])
    }
    if (id) {
      fetchOptions()
    }
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    async function fetch() {
      dispatch(searchData({
        query: searchParams || value,
        limit: 9
      }))
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const setSearch = async e => {
    const { value } = e.target
    setValue(value)

    return debounce(async () => {
      await dispatch(searchData({
        query: value,
        limit: 9
      }))
      // await dispatch(setSearchParam(value))
    }, 500)()
  }

  const removeTask = async id => {
    await dispatch(deleteTask(null, id))
    await dispatch(searchData({
      query: value,
      limit: 9
    }))
  }

  const updateTask = async (id, values) => {
    await dispatch(updateTaskDetails(id, values))
    await dispatch(searchData({
      query: value,
      limit: 9
    }))
  }

  const closeTask = async id => {
    await dispatch(taskDone(id))
    await dispatch(searchData({
      query: value,
      limit: 9
    }))
  }

  const revert = id => {
    dispatch(revertTask(id, {
      query: value,
      limit: 9
    }))
  }

  const showDetails = async (id, status) => {
    if (status === 'New' || status === 'Updated') {
      await dispatch(taskViewed(id))
    }
    await dispatch(fetchTaskDetails(null, id))
  }

  return (
    <Layout isSearch>
      <BackButton title="Back" className="tablet-hidden" />
      <div className="search">
        <div className="search__title">
          <h4 className="tablet-hidden">Showing results for: <span className="text-accent">{query}</span></h4>
          <h4 className="tablet-visible">Search</h4>
          <button onClick={() => history.goBack()} className="search__close">Close</button>
        </div>
        <div className="search__form tablet-visible">
          <Input
            value={value}
            onChange={e => setSearch(e)}
            placeholder="What would you like to search for?"
          />
        </div>
        {isLoading
          ? <Loading small />
          : (!results || (results.tasks.length < 1 && results.posts.length < 1))
            ? <div className="search__empty">
                <div className="search__empty-image">
                  <img src={ImageSearch} alt="No results found" />
                </div>
                <div className="search__empty-text">
                  <h5>No results found</h5>
                  <p>We can’t find any item matching your search</p>
                </div>
              </div>
            : <>
                {results.posts.length > 0 && <>
                  <h6 className="mb--small">Articles</h6>
                  <div className="grid grid-3 search__list">
                    {results.posts.map(item =>
                      <div key={item.id}>
                        <BlogCard title={item.title}
                                  small
                                  active={item.active}
                                  path={`/blog/${item.id}`}
                                  content={item.short_description}
                                  category={item.catalog.name}
                                  image={item.image}
                        />
                      </div>
                    )}
                  </div>
                </>}
                {results.tasks.length > 0 && <>
                  <h6 className="mb--small">Tasks</h6>
                  <div className="grid grid-3">
                    {results.tasks.map(item =>
                      <div key={`task-${item.id}`}>
                        <TaskCard
                          id={item.id}
                          removeTask={removeTask}
                          updateTask={updateTask}
                          closeTask={closeTask}
                          revertTask={revert}
                          title={item.name}
                          status={item.status.name}
                          sub_status={item.task_status.name}
                          category={item.category.planning.name}
                          priority={item.priority.name}
                          content={item.description}
                          icon={item.category.planning.image}
                          priorityList={priorities}
                          categoryList={categories}
                          statusList={status}
                          showDetails={() => showDetails(item.id, item.task_status.name)}
                        />
                      </div>
                    )}
                  </div>
                </>}
              </>}
      </div>
    </Layout>
  );
};

export default Search
