import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@mui/material'
import CheckEmail from '../../components/Auth/CheckEmail'
import { useDispatch, useSelector } from 'react-redux'
import { resend } from '../../redux/slices/authSlice'


const SignUpConfirm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const email = useSelector(state => state.auth.registerUser.email);
  const emailAuth = useSelector(state => state.user.user.email);

  const onSubmit = () => {
    dispatch(resend({
      email: emailAuth || email
    }))
    history.push('/email-send')
  };
  return (
    <CheckEmail title="Success">
      <p className="mb--medium">We’ve sent you an email with instructions to activate your account. Please check your junk mail, too!</p>
      <Button variant="outlined" onClick={onSubmit}>
        Send again?
      </Button>
    </CheckEmail>
  )
};

export default SignUpConfirm;
