import { createSlice } from '@reduxjs/toolkit'
import { Blog, Admin } from '../../api/Api'
import { setIsOpen } from './modalSlice'
import { setCatalogData } from './optionsSlice'
import { createDynamicOptions } from '../../utils/utils'
import { setMode } from './modeSlice'
import { setIsRelogin } from './loadingSlice'

const blogSlice = createSlice({
  name: 'blog',
  initialState: {
    posts: {
      posts: []
    },
    authors: [],
    currentAuthor: {},
    adminPosts: null,
    morePosts: [],
    currentPost: {},
    catalog: [],
    currentCatalog: {},
    isLoading: false,
    isOpenModal: false,
    error: ''
  },
  reducers: {
    setPosts: (state, action) => {
      state.posts = action.payload;
    },
    setAdminPosts: (state, action) => {
      state.adminPosts = action.payload;
    },
    setPost: (state, action) => {
      state.currentPost = action.payload;
    },
    setCatalog: (state, action) => {
      state.catalog = action.payload;
    },
    setCurrentCatalog: (state, action) => {
      state.currentCatalog = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setAuthors: (state, action) => {
      state.authors = action.payload;
    },
    setAuthor: (state, action) => {
      state.currentAuthor = action.payload;
    },
    setIsOpenModal: (state, action) => {
      state.isOpenModal = action.payload
    },
  }
})

export const fetchAuthors = (companyId, isAdmin) => async (dispatch) => {
  try {
    if (isAdmin) {
      const response = await Admin.getAuthors(companyId);
      await dispatch(setAuthors(response.data.data));
    }
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const addAuthor = (companyId, data, props) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    await Admin.postAuthor(companyId, data, {headers : {'Content-Type': 'multipart/form-data'}});
    await dispatch(setIsLoading(false));
    await dispatch(() => props.history.push(`/admin/company/${companyId}/blog`));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchAuthorById = (companyId, authorId, isAdmin) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    if (isAdmin) {
      const response = await Admin.showAuthor(companyId, authorId);
      await dispatch(setAuthor(response.data.data));
    }
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const updateAuthor = (companyId, authorId, data, props) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    await Admin.putAuthor(companyId, authorId, data, {_method: 'PUT'}, {headers : {'Content-Type': 'multipart/form-data'}});
    await dispatch(setIsLoading(false));
    await dispatch(() => props.history.push(`/admin/company/${companyId}/blog`));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const removeAuthor = (companyId, authorId) => async (dispatch) => {
  try {
    await Admin.delAuthor(companyId, authorId);
    await dispatch(setIsOpen({ isOpen: false }));
    const response = await Admin.getAuthors(companyId);
    await dispatch(setAuthors(response.data.data));
    await dispatch(setError(null))
  } catch (e) {
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setError(e.response.data.message));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchPosts = (companyId, params, isAdmin, props) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    if (isAdmin) {
      const response = await Admin.getPosts(companyId, params);
      await dispatch(setAdminPosts(response.data));
    } else {
      const response = await Blog.get(params);
      await dispatch(setPosts(response.data));
    }
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401 && props) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
    if (e.response && e.response.status === 403) {
      await dispatch(setAdminPosts(null));
    }
  }
}

export const fetchPostById = (companyId, postId, isAdmin) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    if (isAdmin) {
      const response = await Admin.showPost(companyId, postId);
      await dispatch(setPost(response.data.data));
    } else {
      const response = await Blog.show(postId);
      await dispatch(setPost(response.data));
    }
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const addPost = (companyId, data, props) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    await Admin.postPost(companyId, data, {headers : {'Content-Type': 'multipart/form-data'}});
    await dispatch(setIsLoading(false));
    await dispatch(() => props.history.push(`/admin/company/${companyId}/blog`));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const updatePost = (companyId, postId, data, props) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    await Admin.putPost(companyId, postId, data, {_method: 'PUT'}, {headers : {'Content-Type': 'multipart/form-data'}});
    await dispatch(setIsLoading(false));
    await dispatch(() => props.history.push(`/admin/company/${companyId}/blog/${postId}`));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const removePost = (companyId, postId, props, params) => async (dispatch) => {
  try {
    await Admin.delPost(companyId, postId);
    await dispatch(setIsOpen({ isOpen: false }));
    if (props) {
      await dispatch(() => props.history.push(`/admin/company/${companyId}/blog`));
    } else {
      const response = await Admin.getPosts(companyId, params);
      await dispatch(setAdminPosts(response.data));
    }

  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}


export const fetchCatalog = (companyId, isAdmin) => async (dispatch) => {
  try {
    let options = null
    if (isAdmin) {
      const response = await Admin.getCatalog(companyId);
      await dispatch(setCatalog(response.data.data));
      options = await createDynamicOptions(response.data.data);
    } else {
      const response = await Blog.getCatalog();
      await dispatch(setCatalog(response.data.data));
      options = await createDynamicOptions(response.data.data);
    }
    await dispatch(setCatalogData({catalog: options}));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}


export const fetchCatalogById = (companyId, catalogId) => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    const response = await Admin.showCatalog(companyId, catalogId);
    await dispatch(setCurrentCatalog(response.data.data));
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e);
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const createCatalog = (companyId, data, isAdmin) => async (dispatch) => {
  try {
    await Admin.postCatalog(companyId, data);
    await dispatch(setIsOpen({ isOpen: false }));
    await dispatch(setMode(null));
    await dispatch(fetchCatalog(companyId, isAdmin));
    await dispatch(setError(null))
  } catch (e) {
    console.error(e);
    dispatch(setError(e.response.data.errors.name[0]))
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const updateCatalog = (companyId, catalogId, data, isAdmin) => async (dispatch) => {
  try {
    await Admin.putCatalog(companyId, catalogId, data);
    await dispatch(setIsOpen({ isOpen: false }));
    await dispatch(setMode(null));
    await dispatch(fetchCatalog(companyId, isAdmin));
    await dispatch(setError(null))
  } catch (e) {
    console.error(e);
    dispatch(setError(e.response.data.errors.name[0]))
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const removeCatalog = (companyId, catalogId, isAdmin) => async (dispatch) => {
  try {
    await Admin.delCatalog(companyId, catalogId);
    await dispatch(fetchCatalog(companyId, isAdmin));
    await dispatch(setIsOpenModal(false));
    await dispatch(setError(null))
  } catch (e) {
    console.error(e);
    await dispatch(setIsOpenModal(true));
    await dispatch(setError(e.response.data.message))
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postActivation = (companyId, taskId, data) => async (dispatch) => {
  try {
    await Admin.updatePostActivation(companyId, taskId, data)
    const response = await Admin.getPosts(companyId)
    await dispatch(setAdminPosts(response.data));
  } catch (e) {
    console.error(e)
    await dispatch(setIsLoading(false))
  }
}

export const {
  setPosts,
  setAdminPosts,
  setAuthors,
  setAuthor,
  setPost,
  setCatalog,
  setCurrentCatalog,
  setIsLoading,
  setIsOpenModal,
  setError } = blogSlice.actions

export default blogSlice.reducer