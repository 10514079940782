import React from 'react'
import { useHistory } from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import { Button } from '@mui/material'
import CheckEmail from '../../components/Auth/CheckEmail'
import { forgotPassword } from '../../redux/slices/authSlice'


const SignInConfirm = ({ props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const email = useSelector(state => state.auth.registerUser.email);

  const onSubmit = () => {
    dispatch(forgotPassword({email: email}, props))
    history.push('/email-send')
  };

  return (
    <CheckEmail title="Check your email">
      <p className="mb--medium">We’ve sent you an e-mail. Open the email and confirm password change. Please check your junk mail, too!</p>
      <Button variant="outlined" onClick={onSubmit}>
        Send again?
      </Button>
    </CheckEmail>
  )
};

export default SignInConfirm;
