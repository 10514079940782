import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { BackButton } from '../../../components/shared/Buttons'
import Layout from '../../../components/Layout/Layout'
import { Button, Switch } from '@mui/material'
import isArray from 'lodash/isArray'
import TreeSubItem from '../../../components/Tree/TreeSubItem'
import TreeImage from '../../../assets/images/tree/finances/Finance-tree.png'
import { fetchUserIncremental, deactivateIncremental } from '../../../redux/slices/financesSlice'
import { fetchTotalPercent } from '../../../redux/slices/percentagesSlice'
import { setIsOpen } from '../../../redux/slices/modalSlice'
import ModalBox from '../../../components/shared/Modal/Modal'
import {fetchUserFamilyData} from '../../../redux/slices/userSlice'
import TreeCount from '../../../components/Tree/TreeCount'

const IncrementalIncome = ({ props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.user.user);
  const isBasic = useSelector((state) => state.user.user.plan_type === 1);
  const totalPercent = useSelector(state => state.percentages.total)
  const incremental = useSelector(state => state.finances.incremental);
  const { significant_income } = useSelector(state => state.user.family.others);
  const [isMobile, setIsMobile] = useState(false);
  const isOpen = useSelector(state => state.modal.isOpen);
  const loading = useSelector(state => state.sending.isSending)
  const [isCheck, setCheck] = useState(false)
  const mobileView = 999;
  const handleResize = () => {
    (mobileView >= window.innerWidth) ? setIsMobile(true) : setIsMobile(false)
  }

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchUserFamilyData(id))
      await dispatch(fetchUserIncremental())
      await dispatch(fetchTotalPercent(isBasic))
    }

    if (id) {
      fetch()
    }

    // eslint-disable-next-line
  }, [id])

  const close = () => dispatch(setIsOpen({isOpen: false}))

  const handleCheck = () => {
    setCheck(true);
  }

  const handleConfirm = () => {
    dispatch(deactivateIncremental(props));
    setCheck(false);
  }

  const onClose = () => {
    setCheck(false);
  }

  const handleClick = () => {
    if(incremental.length > 5) {
      return dispatch(setIsOpen({isOpen: true}))
    }

    return history.push('/incremental-income/create')
  }


  return (
    <Layout totalPercent={totalPercent} tree isBlockedPage={incremental && !significant_income}>
      <div className="tree__sticky-holder">
        <BackButton title={isMobile ? 'Back' : 'Back to Tree'} path={isMobile ? '/finance' : "/input"} className="back-button--position" />
        <div style={{maxWidth: !isMobile && '346px'}} className="tree__title tree__title--progress-bar">
          <div className="tree__title-main">
            <h2>Incremental Income & Expenses</h2>
            <TreeCount
              value={incremental && incremental.length > 0 ? 100 : '0'}
              loading={loading}
              big={!isMobile}
            />
          </div>
          {isMobile && incremental && incremental.length === 0 && (<div className="tree__switcher">
                <Switch 
                  className="custom-switcher"
                  checked={isCheck}
                  onChange={handleCheck}
                />
                I do not have items to add.
            </div>
          )}
          {!isMobile && (
            <>
              <p className="tree__title-other">Fill in information to complete this step.</p>
              <Button variant="contained" className="tree-bar" size="small" onClick={handleClick} color="primary">
                Add Item
              </Button>
              {incremental && incremental.length === 0 && (<div className="tree__switcher">
                <Switch 
                  className="custom-switcher"
                  checked={isCheck}
                  onChange={handleCheck}
                />
                I do not have items to add.
              </div>)}
            </>  
          )}
        </div>
        <div className="tree tree--sub-page">
          {!isMobile && (
            <div className="tree__base">
              <img src={TreeImage} alt="ValueSoft Tree Insurance" />
            </div>
          )}
          {incremental && isArray(incremental) && incremental.map((item, i) =>
            <React.Fragment key={item.id}>
              <TreeSubItem path={`/incremental-income/${item.id}`}
                           title={item.name}
                           value={`incremental-item-${i + 1}`}
                           count={100}
                           isLoading={loading}
              />
            </React.Fragment>
          )}
        </div>

        {isMobile &&
          <div className="tree__add-button">
            <Button variant="contained" size="small" onClick={handleClick} color="primary">
              {!isMobile && 'Add asset'}
            </Button>
          </div>
        }
      </div>

      <ModalBox
        small
        open={isOpen}
        close={close}
        onClick={() => {
          dispatch(setIsOpen({isOpen: false}))
          history.push("/input");
        }}
        button="Back to tree"
        title="Sorry"
        children={<p>The limit of adding new sections is reached.</p>} />
        <ModalBox
        small
        open={isCheck}
        close={onClose}
        onClick={handleConfirm}
        button="Confirm"
        closeButton="Cancel"
        title="Incremental Income & Expenses step"
        children={<p>This step will be disabled, but you can activate it any time by clicking on it in the your input.</p>} />
    </Layout>
  );
};

export default IncrementalIncome;
