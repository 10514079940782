import React, {useEffect, useState} from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'classnames'
import PropTypes from 'prop-types'
import HeaderAdmin from '../Header/HeaderAdmin'
import SplashPlan from '../Splash/SplashPlan'
import { postPlanTypeUser } from '../../redux/slices/userSlice'
import { fetchNotifyRelogin } from '../../redux/slices/notificationsSlice'
import { setIsRelogin } from '../../redux/slices/loadingSlice'
import Loading from '../../components/shared/Loading/Loading'
import Modal from '../../components/shared/Modal/Modal'

const LayoutAdmin = ({
  children,
  isAdmin,
  admin,
  companyId,
  scroll
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id, is_plan_completed, email_verified_at } = useSelector(state => state.user.user)
  const { isLoading, isRelogin } = useSelector(state => state.loading)
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const plan_types = useSelector(state => state.options.plan_types)
  const [isMobile, setIsMobile] = useState(false)
  const mobileView = 999

  const handleResize = () => {
    (mobileView >= window.innerWidth) ? setIsMobile(true) : setIsMobile(false)
  }

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true)
    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    const token = localStorage.token;
    async function hendleRelogin () {
      await dispatch(fetchNotifyRelogin())
    }

    if(id && !token){
      hendleRelogin()
    }

    // eslint-disable-next-line
  }, [])

  const onClick = async (item) => {
    await dispatch(postPlanTypeUser(id, {plan_types: item}))
  }
  
  const handleClick = async () => {
    await(dispatch(setIsRelogin({ isRelogin: false })));
    await dispatch(() => history.push('/login'));
  }
  if (!isLoggedIn && !localStorage.token) return (<Redirect to="/login" />)
  if (!email_verified_at && id) return (<Redirect to="/signup-confirm" />)
  if (!!id && !isAdmin) return (<Redirect to="/dashboard" />)

  return (
    <>
      {id && !is_plan_completed
        ? <SplashPlan skipStep={onClick} items={plan_types} />
        : (
          <div className={clsx('layout-admin', {
            'layout-admin--wide': admin
          })}>
            <div className="layout-admin__content">
              <HeaderAdmin id={companyId} isMobile={isMobile} />
              <div className={clsx('layout-admin__sidebar', {
                'layout-admin__sidebar--scroll': scroll
              })}>
                {isLoading || isRelogin
                  ? <Loading />
                  : <>{children}</>
                }
              </div>
            </div>
          </div>
        )}
        <Modal
        open={isRelogin}
        small
        close={handleClick}
        button="Ok"
        onClick={handleClick}
      >
        <h3>You’ve been signed out due to inactivity!</h3>
        <p>Please sign back in to continue using the site.</p>
        </Modal>
    </>
  )
}

LayoutAdmin.propTypes = {
  children: PropTypes.any,
  admin: PropTypes.bool,
  scroll: PropTypes.bool,
  isAdmin: PropTypes.bool,
  companyId: PropTypes.string,
}

export default LayoutAdmin
