import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../../components/Layout/Layout'
import { BackButton } from '../../components/shared/Buttons'
import Tooltip from '../../components/shared/Tooltip/Tooltip'
import { Slider, Input, FormGroup, FormSelect, InputCurrency } from '../../components/shared/Form'
import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import ModalBox from '../../components/shared/Modal/Modal'
import {
  fetchCalculators,
  fetchListResults,
  fetchCurrentResult,
  saveItemResult,
  removeItemResult, setCurrentCalculator
} from '../../redux/slices/calculatorSlice'
import Tabs, { TabPanel } from '../../components/shared/Tabs/Tabs'
import { group_type, transaction_method } from '../../Data/optionsInvestment'
import { normalizeBoolean, convertDecimal } from '../../utils/utils'
import PercentIcon from '../../assets/images/icons/percentage.svg'
import { Bar } from 'react-chartjs-2'
import { optionsAltChart } from '../../utils/optionsBar'
import { fetchInvestmentFeesAdmin } from '../../redux/slices/calculatorsSetupSlice'

  const detailOneOption = [
    {
      holding: '',
      weight: 0,
      annual: 0
    },
    {
      holding: '',
      weight: 0,
      annual: 0
    },
    {
      holding: '',
      weight: 0,
      annual: 0
    },
    {
      holding: '',
      weight: 0,
      annual: 0
    },
    {
      holding: '',
      weight: 0,
      annual: 0
    },
    {
      holding: '',
      weight: 0,
      annual: 0
    },
    {
      holding: '',
      weight: 0,
      annual: 0
    },
    {
      holding: '',
      weight: 0,
      annual: 0
    },
    {
      holding: '',
      weight: 0,
      annual: 0
    },
    {
      holding: '',
      weight: 0,
      annual: 0
    }
  ]
  const detailTwoOption = [
    {
      holding: '',
      weight: 0,
      annual: 0
    },
    {
      holding: '',
      weight: 0,
      annual: 0
    },
    {
      holding: '',
      weight: 0,
      annual: 0
    },
    {
      holding: '',
      weight: 0,
      annual: 0
    },
    {
      holding: '',
      weight: 0,
      annual: 0
    },
    {
      holding: '',
      weight: 0,
      annual: 0
    },
    {
      holding: '',
      weight: 0,
      annual: 0
    },
    {
      holding: '',
      weight: 0,
      annual: 0
    },
    {
      holding: '',
      weight: 0,
      annual: 0
    },
    {
      holding: '',
      weight: 0,
      annual: 0
    }
  ]

//constants
const groupTypeParams = [
  {
    name: 'stocks',
    value: 0
  },
  {
    name: 'index_funds',
    value: 0.18,
  },
  {
    name: 'etfs',
    value: 0.09,
  },
  {
    name: 'mutual_funds',
    value: 1.25,
  }
]

const InvestmentFees = ({ props }) => {
  const dispatch = useDispatch()
  const [tabActive, setActiveTab] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [isSavedModal, setIsSavedModal] = useState(false)
  const { id, company_id } = useSelector(state => state.user.user)
  const { calculators, current, listExisting, loading } = useSelector(state => state.calculator)
  const baseValues = useSelector(state => state.calculatorsSetup.investmentFeesAdmin);
  const [currentId, setCurrentId] = useState('')
  const [values, setValues] = useState(null)
  const [detailOneError, setDetailOneError] = useState(false)
  const [detailTwoError, setDetailTwoError] = useState(false)

  const [valuesName, setValuesName] = useState({
    group_name: '',
    option_two_group_name: '',
  })

  const [countValues, setCountValues] = useState({
    unadjustedFeesOne: 0,
    adjustedFeesOne: 0,
    unadjustedFeesTwo: 0,
    adjustedFeesTwo: 0,
  })

  const [totalFees, setTotalFees] = useState({
    option_one_fees: 0,
    option_two_fees: 0
  })

  const data = {
    labels: ['', ''],
    datasets: [
      {
        data: [[0, countValues.unadjustedFeesOne + 0.001], [0, countValues.adjustedFeesOne + 0.001]],
        backgroundColor: '#215E59',
        maxBarThickness: 114,
        label: valuesName.group_name ? valuesName.group_name : 'Option one',
        footer: totalFees.option_one_fees
      },
      {
        data: [[0, countValues.unadjustedFeesTwo + 0.001], [0, countValues.adjustedFeesTwo + 0.001]],
        backgroundColor: '#FDB5A1',
        maxBarThickness: 114,
        label: valuesName.option_two_group_name ? valuesName.option_two_group_name : 'Option two',
        footer: totalFees.option_two_fees
      },
    ],
  }

  useEffect(() => {
    if(baseValues){
      setValues({
        currentId: '',
        name: '',
        group_name: '',
        group_type: '',
        option_two_group_name: '',
        option_two_group_type: '',
        option_two_transaction_method: '',
        transaction_method: '',
        detail_one: detailOneOption,
        detail_two: detailTwoOption,
        ...baseValues
      })
    }
  // eslint-disable-next-line
  }, [baseValues])
  
  useEffect(() => {
    async function fetch() {
      await dispatch(fetchInvestmentFeesAdmin(company_id))
      await dispatch(fetchCalculators({path: 'investment-fees'}));
      await dispatch(fetchListResults('investment-fees'));
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const countSumProduct = (array, param1, param2) => {
    const option = param2 ? groupTypeParams.find(i => i.name === param2).value : 0

    return array.reduce((a, b) => a + (b.weight/100 * (param1 ? option : b.annual)), 0)
  }

  useEffect(() => {
    if (values) {
    const broker             = values.transaction_method === 'broker_assisted' ? +values.broker_fees : 0
    const transaction        = values.group_type === 'stocks' || values.group_type === 'etfs' ? +values.transaction_fee_on_stock : +values.transaction_fee_on_mutual_fund

    const brokerOption2      = values.option_two_transaction_method === 'broker_assisted' ? +values.broker_fees : 0
    const transactionOption2 = values.option_two_group_type === 'stocks' || values.option_two_group_type === 'etfs' ? +values.transaction_fee_on_stock : +values.transaction_fee_on_mutual_fund

    const unadjustedFeesOne = countSumProduct(values.detail_one, +values.use_valuesoft_expenses_fee, values.group_type)
    const unadjustedFeesTwo = countSumProduct(values.detail_two, +values.option_two_use_valuesoft_expenses_fee, values.option_two_group_type)


    const sumOptionOne = values.detail_one.reduce((a, b) => {
      let option = +values.use_valuesoft_expenses_fee
                    ? values.group_type && groupTypeParams.find(i => i.name === values.group_type).value
                    : b.annual

      return a + ((b.weight/100 * +values.investment_amount) > 0 ? broker + transaction : 0)/ +values.investment_year + (option/100 * b.weight/100 * +values.investment_amount)
    }, 0)

    const sumOptionTwo = values.detail_two.reduce((a, b) => {
      let option = +values.option_two_use_valuesoft_expenses_fee
                    ? values.option_two_group_type && groupTypeParams.find(i => i.name === values.option_two_group_type).value
                    : b.annual

      return a + ((b.weight/100 * +values.investment_amount) > 0 ? brokerOption2 + transactionOption2 : 0) / +values.investment_year + (option/100 * b.weight/100 * +values.investment_amount)
    }, 0)

    setTotalFees({
      option_one_fees: sumOptionOne.toFixed(2),
      option_two_fees: sumOptionTwo.toFixed(2)
    })

    const adjustedFeesOne = (sumOptionOne/values.investment_amount) - values.additional_return/100
    const adjustedFeesTwo = sumOptionTwo/values.investment_amount

    setCountValues({
      unadjustedFeesOne: +unadjustedFeesOne.toFixed(2),
      unadjustedFeesTwo: +unadjustedFeesTwo.toFixed(2),
      adjustedFeesOne: +(adjustedFeesOne * 100).toFixed(2),
      adjustedFeesTwo: +(adjustedFeesTwo * 100).toFixed(2)
    })
    const sumOptionOneWeight = values.detail_one.reduce((a, b) => {
      return a + +b.weight
    }, 0)

    const sumOptionTwoWeight = values.detail_two.reduce((a, b) => {
      return a + +b.weight
    }, 0)

    sumOptionOneWeight > 100 ? setDetailOneError(true) : setDetailOneError(false)
    sumOptionTwoWeight > 100 ? setDetailTwoError(true) : setDetailTwoError(false)
    
  }

    // eslint-disable-next-line
  }, [values])

  useEffect(() => {
    async function fetchNewData() {
      if (current) {
        setValues({
          name: current ? current.name : '',
          currentId: currentId,
          group_type: current.group_type,
          transaction_method: current.transaction_method,
          use_valuesoft_expenses_fee: current.use_valuesoft_expenses_fee,
          option_two_group_name: current.option_two_group_name,
          option_two_group_type: current.option_two_group_type,
          option_two_transaction_method: current.option_two_transaction_method,
          option_two_use_valuesoft_expenses_fee: current.option_two_use_valuesoft_expenses_fee,
          investment_amount: +current.investment_amount,
          investment_year: +current.investment_year,
          additional_return: +current.additional_return,
          broker_fees: +current.broker_fees,
          transaction_fee_on_stock: +current.transaction_fee_on_stock,
          transaction_fee_on_mutual_fund: +current.transaction_fee_on_mutual_fund,
          detail_one: current.detail_one,
          detail_two: current.detail_two
        })
        setValuesName({
          group_name: current.group_name,
          option_two_group_name: current.option_two_group_name,
        })

      }
    }

    if (currentId) {
      fetchNewData()
    }
    // eslint-disable-next-line
  }, [currentId, current])

  const handleInputChange = e => {
    const val = +e.target.value
    setValues({
      ...values,
      [e.target.name]: parcentVaidation(val)
    })
  }

  const handleCurrencyChange = e => {
    setValues({
      ...values,
      [e.target.name]: +convertDecimal(e.target.value)
    })
  }

  const changeCurrentData = async e => {
    await setCurrentId(e.target.value)
    await dispatch(fetchCurrentResult(e.target.value, 'investment-fees'))
  }

  const onSave = async () => {
    dispatch(saveItemResult({...values, ...valuesName}, 'investment-fees')).then(res => {
      const calculator = res?.data?.data;

      if(calculator) {
        setIsSavedModal(true);
        setCurrentId(calculator.id);
        dispatch(setCurrentCalculator(calculator));
      }
    }).finally(() => {
      setIsOpen(false);
    })
  }

  const resetForm = () => {
    setActiveTab(0)
    setValues({
      currentId: '',
      name: '',
      group_name: '',
      group_type: '',
      option_two_group_name: '',
      option_two_group_type: '',
      option_two_transaction_method: '',
      transaction_method: '',
      detail_one: detailOneOption,
      detail_two: detailTwoOption,
      ...baseValues
    })
    setValuesName({
      group_name: '',
      option_two_group_name: '',
    })
    setCurrentId('')
  }

  const removeResult = async () => {
    await dispatch(removeItemResult(currentId, 'investment-fees'))
    await setCurrentId('')
    await setIsDeleteModal(false)
    await setActiveTab(0)
    await setValues({
      group_name: '',
      group_type: '',
      option_two_group_name: '',
      option_two_group_type: '',
      option_two_transaction_method: '',
      transaction_method: '',
      detail_one: detailOneOption,
      detail_two: detailTwoOption,
      ...baseValues
    })
    await setValuesName({
      group_name: '',
      option_two_group_name: '',
    })
  }
  
  const parcentVaidation = (val) => val > 100 
    ? (val = 100) 
    : val < 0 
    ? (val = 0)
    : val

  const handleArrayInput = (e, index, array) => {
    const { value, name } = e.target
    const newArray = values[array].map((x, i) =>
      i === index
        ? {...x, [name]: parcentVaidation(value)}
        : x
    )

    setValues({
      ...values,
      [array]: [...newArray]
    })
  }

  const setDetails = (id, value, name, array, param) => {
    const details = array.map((x, i) =>
      i === id
        ? {...x, [name]: value}
        : x
    )
    setValues({
      ...values,
      [param]: [...details]
    })
  }

  return (
    <Layout decor>
      <BackButton title="Back to Calculators" path="/calculators" className="back-button--position" />
      <div className="calculators__title">
        <div>
          {calculators && <h4>{calculators.name}</h4>}
          {calculators &&
          <Tooltip
            title={
              <>
                <h6>{calculators.name}</h6>
                {calculators.tooltip}
                <hr className="tooltip__line"/>
                <h6>Example</h6>
                {calculators.example}
              </>
            }
          />}
        </div>

        {listExisting && listExisting.length > 0 && <div className="calculators__title-select">
          <FormSelect
            options={listExisting}
            id="currentId"
            value={currentId}
            medium
            name="currentId"
            onChange={changeCurrentData}
            placeholder="Select result"
          />
        </div>}
      </div>

      <Tabs
        handleChange={(event, newValue) => setActiveTab(newValue)}
        variant="scrollable"
        value={tabActive}
        tabs={[
          {title: 'Investment info'},
          {title: 'Assumptions'},
          {title: valuesName.group_name ? `Details ${valuesName.group_name}` : 'Details'},
          {title: valuesName.option_two_group_name ? `Details ${valuesName.option_two_group_name}` : 'Details'},
        ]}
      />
    {values &&  
      <div className="calculators__holder">
        <div className="calculators__content">
          <TabPanel value={tabActive} index={0}>
            <h6 className="calculators__subtitle">Step 1: Investment info</h6>
            <h4>Option one</h4>
            <FormGroup name="group_name" labelText="Investment group name">
              <Input
                placeholder="Type a name of group"
                name="group_name"
                value={valuesName.group_name || ''}
                onChange={e => setValuesName({
                  ...valuesName,
                  [e.target.name]: e.target.value
                })}
              />
            </FormGroup>
            <FormGroup labelText="Investment group type">
              <FormSelect
                options={group_type}
                value={values.group_type || ''}
                name="group_type"
                onChange={e => setValues({
                  ...values,
                  [e.target.name]: e.target.value
                })}
                placeholder="Select"
              />
            </FormGroup>
            <FormGroup labelText="Transaction method">
              <FormSelect
                options={transaction_method}
                value={values.transaction_method || ''}
                name="transaction_method"
                onChange={e => setValues({
                  ...values,
                  [e.target.name]: e.target.value
                })}
                placeholder="Select"
              />
            </FormGroup>
            <FormGroup labelText="Use ValueSoft expenses fee estimates?">
              <RadioGroup
                name="use_valuesoft_expenses_fee"
                value={values.use_valuesoft_expenses_fee}
                onChange={e => {
                  setValues({
                    ...values,
                    [e.target.name]: normalizeBoolean(e.target.value)
                  })
                }}
                className="radio-list-inline"
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormGroup>
            <h4>Option two</h4>
            <FormGroup name="option_two_group_name" labelText="Investment group name">
              <Input
                placeholder="Type a name of group"
                name="option_two_group_name"
                value={valuesName.option_two_group_name || ''}
                onChange={e => setValuesName({
                  ...valuesName,
                  [e.target.name]: e.target.value
                })}
              />
            </FormGroup>
            <FormGroup labelText="Investment group type">
              <FormSelect
                options={group_type}
                value={values.option_two_group_type || ''}
                name="option_two_group_type"
                onChange={e => setValues({
                  ...values,
                  [e.target.name]: e.target.value
                })}
                placeholder="Select"
              />
            </FormGroup>
            <FormGroup labelText="Transaction method">
              <FormSelect
                options={transaction_method}
                value={values.option_two_transaction_method || ''}
                name="option_two_transaction_method"
                onChange={e => setValues({
                  ...values,
                  [e.target.name]: e.target.value
                })}
                placeholder="Select"
              />
            </FormGroup>
            <FormGroup labelText="Use ValueSoft expenses fee estimates?">
              <RadioGroup
                name="option_two_use_valuesoft_expenses_fee"
                value={values.option_two_use_valuesoft_expenses_fee}
                onChange={e => {
                  setValues({
                    ...values,
                    [e.target.name]: normalizeBoolean(e.target.value)
                  })
                }}
                className="radio-list-inline"
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormGroup>
            <div className="calculators__area">
              <InputCurrency
                name="investment_amount"
                value={values.investment_amount.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={50000}
                  step={1}
                  label="Investment amount"
                  value={+values.investment_amount}
                  onChange={(e, newValue) => setValues({...values, investment_amount: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="investment_year"
                value={values.investment_year.toString()}
                small
                smallHeight
                onChange={handleInputChange}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100}
                  step={1}
                  label="Investment years"
                  value={+values.investment_year}
                  onChange={(e, newValue) => setValues({...values, investment_year: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <Input
                type="number"
                name="additional_return"
                value={values.additional_return.toString()}
                small
                smallHeight
                step={0.1}
                onChange={handleInputChange}
                iconName={PercentIcon}
              />
              <div className="calculators__area-slider">
                <Slider
                  max={5}
                  step={0.1}
                  label="Approximate additional return you believe you’ll derive by having full control of your strategic asset allocation strategy"
                  value={+values.additional_return}
                  onChange={(e, newValue) => setValues({...values, additional_return: newValue})}
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel value={tabActive} index={1}>
            <h6 className="calculators__subtitle">Step 2: Assumptions</h6>
            <div className="calculators__area">
              <InputCurrency
                name="broker_fees"
                value={values.broker_fees.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100}
                  step={1}
                  label="Broker fees"
                  value={+values.broker_fees}
                  onChange={(e, newValue) => setValues({...values, broker_fees: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <InputCurrency
                name="transaction_fee_on_stock"
                value={values.transaction_fee_on_stock.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={50}
                  step={0.25}
                  label="Transaction fee on stocks & ETFs"
                  value={+values.transaction_fee_on_stock}
                  onChange={(e, newValue) => setValues({...values, transaction_fee_on_stock: newValue})}
                />
              </div>
            </div>
            <div className="calculators__area">
              <InputCurrency
                name="transaction_fee_on_mutual_fund"
                value={values.transaction_fee_on_mutual_fund.toString() ||''}
                small
                smallHeight
                onChange={handleCurrencyChange}
                withoutSign
                iconLeft
              />
              <div className="calculators__area-slider">
                <Slider
                  max={100}
                  step={1}
                  label="Transaction fee on mutual funds & index funds"
                  value={+values.transaction_fee_on_mutual_fund}
                  onChange={(e, newValue) => setValues({...values, transaction_fee_on_mutual_fund: newValue})}
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel value={tabActive} index={2}>
            <h6 className="calculators__subtitle">Step 3: Details</h6>
            {values.detail_one && values.detail_one.map((item, i) =>
              <div key={i} className="calculators__area-line">
                <div className="container--small">
                  <FormGroup labelText="Holdings">
                    <Input
                      type="text"
                      value={item && item.holding ? item.holding : ''}
                      name="holding"
                      id={`values.detail_one[${i}].holding`}
                      onChange={e => handleArrayInput(e, i, 'detail_one')}
                      placeholder="Type a name of group"
                    />
                  </FormGroup>
                </div>
                <div className="calculators__area">
                  <Input
                    type="number"
                    name="weight"
                    value={item.weight.toString() || ''}
                    small
                    smallHeight
                    step={0.1}
                    onChange={e => handleArrayInput(e, i, 'detail_one')}
                    iconName={PercentIcon}
                  />
                  <div className="calculators__area-slider">
                    <Slider
                      max={100}
                      step={0.1}
                      label="Weight"
                      value={+item.weight}
                      onChange={(e, newValue) => setDetails(i, newValue, 'weight', values.detail_one, 'detail_one')}
                    />
                  </div>
                </div>
                {!values.use_valuesoft_expenses_fee && <div className="calculators__area">
                  <Input
                    type="number"
                    name="annual"
                    value={item.annual.toString() || ''}
                    small
                    smallHeight
                    step={0.1}
                    onChange={e => handleArrayInput(e, i, 'detail_one')}
                    iconName={PercentIcon}
                  />
                  <div className="calculators__area-slider">
                    <Slider
                      max={100}
                      step={0.01}
                      label="Annual management fee / Operating expenses"
                      value={+item.annual}
                      onChange={(e, newValue) => setDetails(i, newValue, 'annual', values.detail_one, 'detail_one')}
                    />
                  </div>
                </div>}
                {detailOneError && <div className="calculators__error">The sum of weights cannot be greater than 100%.</div>}
              </div>)}
          </TabPanel>
          <TabPanel value={tabActive} index={3}>
            <h6 className="calculators__subtitle">Step 4: Details</h6>
            {values.detail_two && values.detail_two.map((item, i) =>
              <div key={i} className="calculators__area-line">
                <div className="container--small">
                  <FormGroup labelText="Holdings">
                    <Input
                      type="text"
                      value={item && item.holding ? item.holding : ''}
                      name="holding"
                      id={`values.detail_two[${i}].holding`}
                      onChange={e => handleArrayInput(e, i, 'detail_two')}
                      placeholder="Type a name of group"
                    />
                  </FormGroup>
                </div>
                <div className="calculators__area">
                  <Input
                    type="number"
                    name="weight"
                    value={item.weight.toString() || ''}
                    small
                    smallHeight
                    step={0.1}
                    onChange={e => handleArrayInput(e, i, 'detail_two')}
                    iconName={PercentIcon}
                  />
                  <div className="calculators__area-slider">
                    <Slider
                      max={100}
                      step={0.1}
                      label="Weight"
                      value={+item.weight}
                      onChange={(e, newValue) => setDetails(i, newValue, 'weight', values.detail_two, 'detail_two')}
                    />
                  </div>
                </div>
                {!values.option_two_use_valuesoft_expenses_fee && <div className="calculators__area">
                  <Input
                    type="number"
                    name="annual"
                    value={item.annual.toString() || ''}
                    small
                    smallHeight
                    step={0.1}
                    onChange={e => handleArrayInput(e, i, 'detail_two')}
                    iconName={PercentIcon}
                  />
                  <div className="calculators__area-slider">
                    <Slider
                      max={100}
                      step={0.01}
                      label="Annual management fee / Operating expenses"
                      value={+item.annual}
                      onChange={(e, newValue) => setDetails(i, newValue, 'annual', values.detail_two, 'detail_two')}
                    />
                  </div>
                </div>}
                {detailTwoError && <div className="calculators__error">The sum of weights cannot be greater than 100%.</div>}
              </div>)}
          </TabPanel>
        </div>
        <div className="calculators__preview">
          <h6 className="calculators__subtitle">Results: Fees Comparison</h6>
          <div className="calculators__preview-box">
            <div className="calculators__legend">
              <div className="calculators__legend-label">
                {valuesName.group_name ? `${valuesName.group_name} ` : 'Option one '}
                [{countValues.unadjustedFeesOne}%, {countValues.adjustedFeesOne}%]
              </div>
              <div className="calculators__legend-label calculators__legend-label-color2">
                {valuesName.option_two_group_name ? `${valuesName.option_two_group_name} ` : 'Option two '}
                [{countValues.unadjustedFeesTwo}%, {countValues.adjustedFeesTwo}%]
              </div>
            </div>
            <div className="calculators__preview-box-chart-title">
              <div>Unadjusted Fees (excluding your control of portfolio strategic asset allocation)</div>
              <div>ValueSoft Adjusted Fees (including your control of portfolio strategic asset allocation)</div>
            </div>
            <Bar data={data} options={optionsAltChart()}/>
          </div>
        </div>
      </div>
    }
      <div className="calculators__buttons">
        <Button variant="contained" size="large" color="primary" onClick={() => setIsOpen(true)}>Save result</Button>
        {currentId && <Button variant="contained" size="large"  onClick={() => setIsDeleteModal(true)}>Remove result</Button>}
        <Button size="large" className="MuiButton-textGray" onClick={() => resetForm()}>Reset</Button>
      </div>

      <ModalBox
        open={isOpen}
        close={() => setIsOpen(false)}
        onClick={onSave}
        button="Save"
        title="Save Result"
        isLoading={loading}
        isDisabled={(detailOneError || detailTwoError) && values && !values.name && !values.currentId}
      >
        <FormGroup name="name" labelText="Name">
          <Input
            name='name'
            value={values ? values.name : ''}
            placeholder="Type a name of result"
            onChange={e => setValues({
              ...values,
              name: e.target.value
            })}
          />
        </FormGroup>
        {(detailOneError || detailTwoError) && <div className="form__msg-error">Check please Details inputs for errors.</div>}
        {listExisting && listExisting.length > 0 &&
        <>
          <p className="text-center">or</p>
          <FormGroup labelText="Select  Existing Result">
            <FormSelect
              options={listExisting}
              value={values ? values.currentId : ''}
              name="currentId"
              onChange={e => setValues({
                ...values,
                currentId: e.target.value,
                name: listExisting.find(i => i.value === e.target.value).label
              })}
              placeholder="Select Existing Result"
            />
          </FormGroup>
        </>
        }
      </ModalBox>
      <ModalBox
        small
        open={isDeleteModal}
        close={() => setIsDeleteModal(false)}
        onClick={removeResult}
        button="Remove"
        title={`Do you want to remove ${current && current.name}?`}
      />

      <ModalBox
        small
        open={isSavedModal}
        close={() => setIsSavedModal(false)}
        button="Ok"
        onClick={() => setIsSavedModal(false)}
      >
        <div>
          {calculators && calculators.iconLeft &&
          <div className="modal__title-iconLeft">
            <img src={calculators.iconLeft} alt={calculators.name} />
          </div>}
          <h4>Your calculator scenario has been saved.</h4>
          <p>You can access your saved scenarios through the “Select result” dropdown menu at the top of each calculator page.</p>
        </div>
      </ModalBox>
    </Layout>
  )
}

export default InvestmentFees
