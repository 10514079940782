import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'classnames'
import { MenuItem, Select } from "@mui/material";

const InputPriority = (
  {
    value,
    priorityName,
    priorities,
    name,
    hasError,
    medium,
    disabled,
    onChange,
    small,
  }
) => {
  return (
    <div className='form-group form__priority'>
      <div className="form__priority__item form__priority__left">
        {priorityName}
      </div>
      <div className="form__priority__item-divider"/>
      <div className="form__priority__item form__priority__right">
        <Select
          name={name}
          disabled={disabled}
          value={value}
          onChange={onChange}
          className={clsx('custom-select', {
            'custom-select--error': hasError,
            'custom-select--small': small,
            'custom-select--medium': medium,
          })}
        >
          {priorities && priorities.map(item => (
            <MenuItem key={item.id} value={item.priority}>Priority: {item.priority}</MenuItem>
          ))}
        </Select>
      </div>
        {
          hasError && <span className="form__msg-error">Priority needs to be unique</span>
        }
    </div>
  );
};

InputPriority.propTypes = {
  small: PropTypes.bool,
  medium: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.number,
  name: PropTypes.string,
  hasError: PropTypes.bool,
  priorityName: PropTypes.string,
  priorities: PropTypes.array,
  onChange: PropTypes.func,
};

export default InputPriority;
