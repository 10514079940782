import React, { useEffect, useState } from 'react'
import { useDispatch , useSelector } from 'react-redux'
import Layout from '../../components/Layout/Layout'
import { Accordion, AccordionDetails, AccordionSummary }  from '@mui/material'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { fetchFAQ } from '../../redux/slices/additionalSlice'
import Loading from '../../components/shared/Loading/Loading'

const Faq = () => {
  const dispatch = useDispatch()
  const [expanded, setExpanded] = useState(false)
  const { id } = useSelector(state => state.user.user)
  const { faq, loading } = useSelector(state => state.additional)

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchFAQ())
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }

  return (
    <Layout>
      <div className="faq">
        <div className="faq__page-title">
          <h2>How can we help you?</h2>
          <p>Here are some common questions about ValueSoft.</p>
        </div>
        <div className="faq__content">
          <div className="faq__title">
            <h4>Getting started</h4>
          </div>
          <div className="faq__list">
            {loading
              ? <Loading />
              : <>
                  {faq && faq.length > 0 && faq.map((item, i) => (
                    <Accordion expanded={expanded === i} onChange={handleChange(i)} key={item.id}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {item.name}
                      </AccordionSummary>
                      <AccordionDetails>
                        {item.content}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </>
            }
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Faq;
