import React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';


const RangeSlider = (props) => {
  return (
    <Box sx={{ width: 240, display: 'flex', alignItems: 'center' }}>
      <Slider
        name={props.name}
        max={props.max}
        min={props.min}
        step={props.step}
        className="range__bar"
        value={props.value}
        onChange={props.onChange}
        disableSwap
      />
    </Box>
  );
}

export default RangeSlider
