import React, { useEffect } from 'react'
import { Form, Formik, FieldArray } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../../components/Layout/Layout'
import LayoutForm from '../../../components/Layout/LayoutForm'
import { BackButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import { Button } from '@mui/material'
import { fetchWorthOthers, postWorthOthers } from '../../../redux/slices/worthSlice'
import { FormGroup, Input, InputCurrency } from '../../../components/shared/Form'
import isArray from 'lodash/isArray'

const WorthOthersLiabilities = ({ props }) => {
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.user.user);
  const { othersLiabilities } = useSelector(state => state.worth);
  const sending = useSelector(state => state.sending.isSending);


  useEffect(() => {
    async function fetch() {
      dispatch(fetchWorthOthers())
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const onSubmit = (values) => {
    dispatch(postWorthOthers(values.array, props))
  }

  return (
    <Layout>
      <LayoutForm small>
        <BackButton title="Back" />
        <PageTitle
          title="Other"
        />
        <div className="layout-form__content-holder">
          <Formik
            enableReinitialize
            initialValues={othersLiabilities && othersLiabilities.length > 0
              ? {array: othersLiabilities}
              : {array: [{amount: '', name: null}]}
            }
            onSubmit={onSubmit}
          >
            {({ errors, handleChange, handleBlur, values }) => (
              <Form>
                <FieldArray
                  name="array"
                  render={arrayHelpers => (
                    <div>
                      {values.array && isArray(values.array) && values.array.map((item, index) => (
                        <div key={index}>
                          <div className="form__columns form__columns--small">
                            <div className="form__column form__column--flex">
                              <FormGroup name={`array[${index}].name`} labelText="Additional liability name">
                                <Input
                                  placeholder="Type additional liability name"
                                  type="text"
                                  name={`array[${index}].name`}
                                  onChange={handleChange}
                                  value={item.name || ''}
                                  onBlur={handleBlur}
                                />
                              </FormGroup>
                            </div>
                            <div className="form__column form__column--flex form__column--small">
                              <FormGroup name={`array[${index}].amount`} labelText="Amount ($)">
                                <InputCurrency
                                  placeholder="Type amount ($)"
                                  type="text"
                                  name={`array[${index}].amount`}
                                  onChange={handleChange}
                                  value={item.amount || ''}
                                  onBlur={handleBlur}
                                />
                              </FormGroup>
                            </div>
                          </div>
                        </div>
                      ))}
                      {values.array && values.array.length < 5 &&
                        <button
                          type="button"
                          className="form__add-button"
                          onClick={() => arrayHelpers.push({ name: '', amount: '' })}
                        >
                          <span className="form__add-button-icon" />
                          <span>Add more</span>
                        </button>
                      }
                    </div>
                  )}
                />

                <div className="form__button form__button--center">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Save
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </LayoutForm>
    </Layout>
  );
};

export default WorthOthersLiabilities;
