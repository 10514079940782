import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Logo from '../shared/Logo/Logo'
import { Button } from '@mui/material'
import Modal from '../../components/shared/Modal/Modal'
import SplashPlanItem from './SplashPlanItem'
import isEmpty from 'lodash/isEmpty'

const SplashPlan = ({ onClick, items, sending }) => {
  const [options, setOptions] = useState(1)
  const [openModal, setOpenModal] = useState(false)
  const [planName, setPlanName] = useState('')

  useEffect(() => {
    !isEmpty(items) && setPlanName(items[options - 1].label)

    // eslint-disable-next-line
  },[items])

  const handleClick = item => {
      setPlanName(items[item - 1].label)
      return setOptions(item)
  }

  return (
    <div className="splash">
      <Logo className="splash__logo" />
      <div className="splash__content">
        <div className="splash__title splash__title--plan">
          <h2>Do you want a basic or comprehensive plan?</h2>
          <p>A basic plan will focus on the most important aspects of your
            financial health and will take half the time to fill out compared to a comprehensive plan. That said, a comprehensive plan is where you'll learn the most and fully capture the value of ValueSoft.  
          </p>
          <p>
            You can always change to a comprehensive plan later through your personal settings or by 
            clicking on an inactive step in your input process.
          </p>
        </div>
        <div className="splash__plan-items">
          {items && items.map((item, i) => (
            <div key={`option-${i}`}>
              <SplashPlanItem
                icon={item.icon}
                title={item.label}
                id={item.id}
                selected={options && options === item.id}
                handleClick={handleClick}
              />
            </div>
          ))}
        </div>
        <div className="splash__buttons">
          <div>
            <Button variant="contained" size="large" color="primary" onClick={() => setOpenModal(true)} disabled={sending}>
              Select
              {sending && <span className="spinner" />}
            </Button>
          </div>
        </div>
      </div>
      <Modal
        open={openModal}
        small
        close={() => {
          setOpenModal(false)
          setOptions(1)
        }}
        button="Yes"
        closeButton="No"
        onClick={() => onClick(options)}
      >
        <h3>You selected the <span style={{whiteSpace: 'nowrap'}}>{planName}</span>.<br/>Are you sure?</h3>
      </Modal>
    </div>
  )
};


SplashPlan.propTypes = {
  onClick: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  sending: PropTypes.bool
};

export default SplashPlan
