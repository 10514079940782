import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'classnames'


const AddButton = ({ onClick, title, className, full, smallMobile, isAdaptive = true }) => {
  const textClassName = clsx('add-button__text', {
    'add-button__text-mobile': isAdaptive
  })

  return (
    <button onClick={onClick} className={clsx('add-button', {
      [`${className}`]: className,
      'add-button--full': full,
      'add-button--small-mobile': smallMobile
    })}>
      <span className="add-button__icon" />
      <span className={textClassName}>{ title }</span>
    </button>
  )
};

AddButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  full: PropTypes.bool,
  smallMobile: PropTypes.bool
};

export default AddButton;
