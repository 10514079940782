import { createSlice } from '@reduxjs/toolkit'
import { Notifications, Admin } from '../../api/Api'
import { setSending } from './sendingSlice'
import { setIsRelogin } from './loadingSlice'
import { setIsOpen } from './modalSlice'
import { setMode } from './modeSlice'

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    alertMessage: null,
    notifications: null,
    animate: false,
    notify: {
      message: '',
      insight: ''
    },
    thresholds: {
      superb_number_of_tasks: null,
      great_number_of_tasks: null,
      ok_to_great_number_of_tasks: null,
      ok_number_of_tasks: null,
      ok_to_poor_number_of_tasks: null,
      poor_number_of_tasks: null,
      really_poor_number_of_tasks: null
    }
  },
  reducers: {
    setAlertMessage: (state, action) => {
      state.alertMessage = action.payload;
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    setAnimate: (state, action) => {
      state.animate = action.payload;
    },
    setNotify: (state, action) => {
      state.notify = action.payload;
    },
    setAlertThresholds: (state, action) => {
      state.thresholds = action.payload;
    },
  }
})

export const fetchNotifyRelogin = () => async (dispatch) => {
  try {
    const token = localStorage.token;
    if (!token){
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  } catch (e) {
    console.error(e);
  }
}

export const checkingAlertMsg = () => async (dispatch) => {
  try {
    const response = await Notifications.getMessage()
    await dispatch(setAlertMessage(response.data.data))
  } catch (e) {
    console.error(e);
  }
}

export const removeAlertMsg = id => async (dispatch) => {
  try {
    await dispatch(setAnimate(true))
    await Notifications.delMessage(id)
    await dispatch(checkingAlertMsg())
    await dispatch(setAnimate(false))
  } catch (e) {
    console.error(e);
    await dispatch(setAnimate(false))
  }
}

export const fetchNotifications = () => async (dispatch) => {
  try {
    const response = await Notifications.get()
    await dispatch(setNotifications(response.data))
  } catch (e) {
    console.error(e);
  }
}

export const removeNotification = id => async (dispatch) => {
  try {
    await Notifications.put(id)
    await dispatch(fetchNotifications())
  } catch (e) {
    console.error(e);
  }
}

export const fetchNotifyAdmin = (companyId) => async (dispatch) => {
  try {
    const response = await Notifications.getNotify(companyId)
    await dispatch(setNotify(response.data.data))
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postNotifyAdmin = (companyId, data) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }))
    await Notifications.postNotify(companyId, data)
    await dispatch(setNotify(data))
    await dispatch(setSending({ isSending: false }))
  } catch (e) {
    console.error(e)
    await dispatch(setSending({ isSending: false }))
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchThresholds = (id) => async (dispatch) => {
  try {
    const response = await Admin.getThresholds(id)
    await dispatch(setAlertThresholds(response.data.data))
  } catch (e) {
    console.error(e);
  }
}

export const postThresholds = (id, data) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }))
    await dispatch(setMode("success"));
    await dispatch(setIsOpen({ isOpen: true }));
    await Admin.postThresholds(id, data)
    await dispatch(setAlertThresholds(data))
    await dispatch(setSending({ isSending: false }))
  } catch (e) {
    console.error(e)
    await dispatch(setMode("error"));
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({ isSending: false }))
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const { setAlertMessage, setNotifications, setAnimate, setNotify, setAlertThresholds } = notificationsSlice.actions

export default notificationsSlice.reducer