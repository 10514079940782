import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../../components/Layout/Layout'
import { BackButton } from '../../components/shared/Buttons'
import Tooltip from '../../components/shared/Tooltip/Tooltip'
import { Slider, Input, FormGroup, FormSelect, InputCurrency } from '../../components/shared/Form'
import PercentIcon from '../../assets/images/icons/percentage.svg'
import { Button } from '@mui/material'
import ModalBox from '../../components/shared/Modal/Modal'
import {
  fetchCalculators,
  fetchListResults,
  fetchCurrentResult,
  saveItemResult,
  removeItemResult,
  setCurrentCalculator
} from '../../redux/slices/calculatorSlice'
import { Bar } from 'react-chartjs-2'
import { optionsCalculator } from '../../utils/optionsBar'
import { convertDecimal } from '../../utils/utils'
import { fetchCarLeasePaymentAdmin } from '../../redux/slices/calculatorsSetupSlice'

const CarLeasePayment = ({ props }) => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [isSavedModal, setIsSavedModal] = useState(false)
  const { id, company_id } = useSelector(state => state.user.user)
  const { calculators, current, listExisting, loading } = useSelector(state => state.calculator)
  const baseValues = useSelector(state => state.calculatorsSetup.carLeasePaymentAdmin);
  const [currentId, setCurrentId] = useState('')
  const [values, setValues] = useState(null)

  useEffect(() => {
    if(baseValues){
      setValues({
        currentId: '',
        name: '',
        ...baseValues
      })
    }
  // eslint-disable-next-line
  }, [baseValues])

  const [countValues, setCountValues] = useState({
    monthlyPayment: 0,
    adjustedMonthlyCost: 0,
  })

  const data = {
    labels: [`$ ${countValues.monthlyPayment}`, `$ ${countValues.adjustedMonthlyCost}`],
    datasets: [
      {
        data: [countValues.monthlyPayment, countValues.adjustedMonthlyCost],
        backgroundColor: ['#215E59', '#FDB5A1'],
        maxBarThickness: 114
      }
    ],
  }

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchCarLeasePaymentAdmin(company_id))
      await dispatch(fetchCalculators({
        path: 'car-lease-payment'
      }));
      await dispatch(fetchListResults('car-lease-payment'))
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    if(values) {
      let taxes                = +values.down_payment * +values.sales_tax/100
    let capCostReduction     = +values.down_payment + +values.trade_in_value + +values.rebates_dealer_incentives + +values.rebates
    let netCapCost           = +values.retail_price - capCostReduction + +values.fees + taxes
    let moneyFactor          = +values.interest_rate/2400
    let residualValue        = +values.retail_price * +values.residual_value/100
    let depreciationFee      = (netCapCost-residualValue)/+values.lease_term
    let financeFee           = (netCapCost+residualValue) * moneyFactor
    let salesTax             = +values.sales_tax/100 * (depreciationFee + financeFee)
    let additionalMileageFee = ((+values.mileage_allotted_per_year - 15000) * +values.mileage_overage_cost_per_mile)/12
    let mileageOverageCost   = (+values.expected_miles_driven_per_year > +values.mileage_allotted_per_year)
                                  ? (+values.expected_miles_driven_per_year - +values.mileage_allotted_per_year)*+values.mileage_overage_cost_per_mile*(+values.lease_term/12)
                                  : 0

    let monthlyPayment       = depreciationFee + financeFee + salesTax + additionalMileageFee
    let adjustedMonthlyCost  = monthlyPayment + (mileageOverageCost / +values.lease_term) + +values.gas_per_month + (+values.maintenance_per_year / 12) + +values.yearly_insurance_premium / 12 + +values.down_payment / +values.lease_term

    setCountValues({
      monthlyPayment: +monthlyPayment.toFixed(2),
      adjustedMonthlyCost: +adjustedMonthlyCost.toFixed(2)
    })
    }
    // eslint-disable-next-line
  }, [values])

  useEffect(() => {
    async function fetchNewData() {
      if (current) {
        setValues({
          name: current ? current.name : '',
          currentId: currentId,
          retail_price: +current.retail_price,
          fees: +current.fees,
          down_payment: +current.down_payment,
          trade_in_value: +current.trade_in_value,
          rebates: +current.rebates,
          rebates_dealer_incentives: +current.rebates_dealer_incentives,
          residual_value: +current.residual_value,
          lease_term: +current.lease_term,
          interest_rate: +current.interest_rate,
          sales_tax: +current.sales_tax,
          mileage_allotted_per_year: +current.mileage_allotted_per_year,
          mileage_overage_cost_per_mile: +current.mileage_overage_cost_per_mile,
          expected_miles_driven_per_year: +current.expected_miles_driven_per_year,
          gas_per_month: +current.gas_per_month,
          maintenance_per_year: +current.maintenance_per_year,
          yearly_insurance_premium: +current.yearly_insurance_premium
        })
      }
    }

    if (currentId) {
      fetchNewData()
    }
    // eslint-disable-next-line
  }, [currentId, current])

  const handleInputChange = e => {
    setValues({
      ...values,
      [e.target.name]: +e.target.value
    })
  }

  const handleCurrencyChange = e => {
    setValues({
      ...values,
      [e.target.name]: +convertDecimal(e.target.value)
    })
  }

  const changeCurrentData = async e => {
    await setCurrentId(e.target.value)
    await dispatch(fetchCurrentResult(e.target.value, 'car-lease-payment'))
  }

  const onSave = async () => {
    dispatch(saveItemResult(values, 'car-lease-payment')).then(res => {
      const calculator = res?.data?.data;

      if(calculator) {
        setIsSavedModal(true);
        setCurrentId(calculator.id);
        dispatch(setCurrentCalculator(calculator));
      }
    }).finally(() => {
      setIsOpen(false);
    })
  }

  const resetForm = () => {
    setValues({
      currentId: '',
      name: '',
      ...baseValues
    })
    setCurrentId('')
  }

  const removeResult = async () => {
    await dispatch(removeItemResult(currentId, 'car-lease-payment'))
    await setCurrentId('')
    await setIsDeleteModal(false)
    await setValues({
      ...baseValues
    })
  }

  return (
    <Layout decor>
      <BackButton title="Back to Calculators" path="/calculators" className="back-button--position" />
      <div className="calculators__title">
        <div>
          {calculators && <h4>{calculators.name}</h4>}
          {calculators &&
          <Tooltip
            title={
              <>
                <h6>{calculators.name}</h6>
                {calculators.tooltip}
                <hr className="tooltip__line"/>
                <h6>Example</h6>
                {calculators.example}
              </>
            }
          />}
        </div>

        {listExisting && listExisting.length > 0 && <div className="calculators__title-select">
          <FormSelect
            options={listExisting}
            id="currentId"
            value={currentId}
            medium
            name="currentId"
            onChange={changeCurrentData}
            placeholder="Select result"
          />
        </div>}
      </div>
    {values &&         
      <div className="calculators__holder">
        <div className="calculators__content">
          <h6 className="calculators__subtitle">Step 1: Input Lease Information</h6>
          <div className="calculators__area">
            <InputCurrency
              name="retail_price"
              value={+values.retail_price.toString() ||''}
              small
              smallHeight
              onChange={handleCurrencyChange}
              withoutSign
              iconLeft
            />
            <div className="calculators__area-slider">
              <Slider
                max={100000}
                step={1}
                label="Retail price (MSRP)"
                value={+values.retail_price}
                onChange={(e, newValue) => setValues({...values, retail_price: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <InputCurrency
              name="fees"
              value={values.fees.toString() ||''}
              small
              smallHeight
              onChange={handleCurrencyChange}
              withoutSign
              iconLeft
            />
            <div className="calculators__area-slider">
              <Slider
                max={2000}
                step={10}
                label="Fees"
                value={+values.fees}
                onChange={(e, newValue) => setValues({...values, fees: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <InputCurrency
              name="down_payment"
              value={values.down_payment.toString() ||''}
              small
              smallHeight
              onChange={handleCurrencyChange}
              withoutSign
              iconLeft
            />
            <div className="calculators__area-slider">
              <Slider
                max={5000}
                step={10}
                label="Downpayment"
                value={+values.down_payment}
                onChange={(e, newValue) => setValues({...values, down_payment: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <InputCurrency
              name="trade_in_value"
              value={values.trade_in_value.toString() ||''}
              small
              smallHeight
              onChange={handleCurrencyChange}
              withoutSign
              iconLeft
            />
            <div className="calculators__area-slider">
              <Slider
                max={5000}
                step={10}
                label="Trade-in value"
                value={+values.trade_in_value}
                onChange={(e, newValue) => setValues({...values, trade_in_value: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <InputCurrency
              name="rebates"
              value={values.rebates.toString() ||''}
              small
              smallHeight
              onChange={handleCurrencyChange}
              withoutSign
              iconLeft
            />
            <div className="calculators__area-slider">
              <Slider
                max={5000}
                step={10}
                label="Rebates"
                value={+values.rebates}
                onChange={(e, newValue) => setValues({...values, rebates: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <InputCurrency
              name="rebates_dealer_incentives"
              value={values.rebates_dealer_incentives.toString() ||''}
              small
              smallHeight
              onChange={handleCurrencyChange}
              withoutSign
              iconLeft
            />
            <div className="calculators__area-slider">
              <Slider
                max={5000}
                step={10}
                label="Factory to Dealer Incentives"
                value={+values.rebates_dealer_incentives}
                onChange={(e, newValue) => setValues({...values, rebates_dealer_incentives: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <Input
              type="number"
              name="residual_value"
              value={values.residual_value.toString()}
              small
              smallHeight
              step={0.1}
              onChange={handleInputChange}
              iconName={PercentIcon}
            />
            <div className="calculators__area-slider">
              <Slider
                max={100}
                step={0.1}
                label="Residul Value % of MSRP"
                value={+values.residual_value}
                onChange={(e, newValue) => setValues({...values, residual_value: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <Input
              type="number"
              name="lease_term"
              value={values.lease_term.toString()}
              small
              smallHeight
              onChange={handleInputChange}
            />
            <div className="calculators__area-slider">
              <Slider
                max={60}
                step={1}
                label="Lease term (months)"
                value={+values.lease_term}
                onChange={(e, newValue) => setValues({...values, lease_term: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <Input
              type="number"
              name="interest_rate"
              value={values.interest_rate.toString()}
              small
              smallHeight
              step={0.1}
              onChange={handleInputChange}
              iconName={PercentIcon}
            />
            <div className="calculators__area-slider">
              <Slider
                max={100}
                step={0.1}
                label="Interest rate"
                value={+values.interest_rate}
                onChange={(e, newValue) => setValues({...values, interest_rate: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <Input
              type="number"
              name="sales_tax"
              value={values.sales_tax.toString()}
              small
              smallHeight
              step={0.1}
              onChange={handleInputChange}
              iconName={PercentIcon}
            />
            <div className="calculators__area-slider">
              <Slider
                max={100}
                step={0.1}
                label="Sales tax "
                value={+values.sales_tax}
                onChange={(e, newValue) => setValues({...values, sales_tax: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <Input
              type="number"
              name="mileage_allotted_per_year"
              value={values.mileage_allotted_per_year.toString()}
              small
              smallHeight
              onChange={handleInputChange}
            />
            <div className="calculators__area-slider">
              <Slider
                max={25000}
                step={10}
                label="Mileage allotted/year"
                value={+values.mileage_allotted_per_year}
                onChange={(e, newValue) => setValues({...values, mileage_allotted_per_year: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <InputCurrency
              name="mileage_overage_cost_per_mile"
              value={values.mileage_overage_cost_per_mile.toString() ||''}
              small
              smallHeight
              onChange={handleCurrencyChange}
              withoutSign
              iconLeft
            />
            <div className="calculators__area-slider">
              <Slider
                max={10}
                step={0.1}
                label="Mileage overage cost/mile"
                value={+values.mileage_overage_cost_per_mile}
                onChange={(e, newValue) => setValues({...values, mileage_overage_cost_per_mile: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <Input
              type="number"
              name="expected_miles_driven_per_year"
              value={values.expected_miles_driven_per_year.toString()}
              small
              smallHeight
              onChange={handleInputChange}
            />
            <div className="calculators__area-slider">
              <Slider
                max={25000}
                step={1}
                label="Expected miles driven/year"
                value={+values.expected_miles_driven_per_year}
                onChange={(e, newValue) => setValues({...values, expected_miles_driven_per_year: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <InputCurrency
              name="gas_per_month"
              value={values.gas_per_month.toString() ||''}
              small
              smallHeight
              onChange={handleCurrencyChange}
              withoutSign
              iconLeft
            />
            <div className="calculators__area-slider">
              <Slider
                max={400}
                step={0.5}
                label="Gas/month"
                value={+values.gas_per_month}
                onChange={(e, newValue) => setValues({...values, gas_per_month: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <InputCurrency
              name="maintenance_per_year"
              value={values.maintenance_per_year.toString() ||''}
              small
              smallHeight
              onChange={handleCurrencyChange}
              withoutSign
              iconLeft
            />
            <div className="calculators__area-slider">
              <Slider
                max={3000}
                step={1}
                label="Maintenance/year"
                value={+values.maintenance_per_year}
                onChange={(e, newValue) => setValues({...values, maintenance_per_year: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <InputCurrency
              name="yearly_insurance_premium"
              value={values.yearly_insurance_premium.toString() ||''}
              small
              smallHeight
              onChange={handleCurrencyChange}
              withoutSign
              iconLeft
            />
            <div className="calculators__area-slider">
              <Slider
                max={4000}
                step={1}
                label="Increase in Yearly Insurance Premium"
                value={+values.yearly_insurance_premium}
                onChange={(e, newValue) => setValues({...values, yearly_insurance_premium: newValue})}
              />
            </div>
          </div>
        </div>
        <div className="calculators__preview">
          <h6 className="calculators__subtitle">Results: Monthly payment</h6>
          <div className="calculators__preview-box">
            <div className="calculators__legend">
              <div className="calculators__legend-label">Standard monthly payment</div>
              <div className="calculators__legend-label calculators__legend-label-color2">ValueSoft adjusted monthly cost</div>
            </div>
            <Bar data={data} options={optionsCalculator(null, true, true)} />
          </div>
        </div>
      </div>
    }
      <div className="calculators__buttons">
        <Button variant="contained" size="large" color="primary" onClick={() => setIsOpen(true)}>Save result</Button>
        {currentId && <Button variant="contained" size="large"  onClick={() => setIsDeleteModal(true)}>Remove result</Button>}
        <Button size="large" className="MuiButton-textGray" onClick={() => resetForm()}>Reset</Button>
      </div>

      <ModalBox
        open={isOpen}
        close={() => setIsOpen(false)}
        onClick={onSave}
        button="Save"
        title="Save Result"
        isLoading={loading}
        isDisabled={values && !values.name && !values.currentId}
      >
        <FormGroup name="name" labelText="Name">
          <Input
            name='name'
            value={values ? values.name : ''}
            placeholder="Type a name of result"
            onChange={e => setValues({
              ...values,
              name: e.target.value
            })}
          />
        </FormGroup>
        {listExisting && listExisting.length > 0 &&
        <>
          <p className="text-center">or</p>
          <FormGroup labelText="Select  Existing Result">
            <FormSelect
              options={listExisting}
              value={values ? values.currentId : ''}
              name="currentId"
              onChange={e => setValues({
                ...values,
                currentId: e.target.value,
                name: listExisting.find(i => i.value === e.target.value).label
              })}
              placeholder="Select Existing Result"
            />
          </FormGroup>
        </>
        }
      </ModalBox>
      <ModalBox
        small
        open={isDeleteModal}
        close={() => setIsDeleteModal(false)}
        onClick={removeResult}
        button="Remove"
        title={`Do you want to remove ${current && current.name}?`}
      />

      <ModalBox
        small
        open={isSavedModal}
        close={() => setIsSavedModal(false)}
        button="Ok"
        onClick={() => setIsSavedModal(false)}
      >
        <div>
          {calculators && calculators.iconLeft &&
          <div className="modal__title-iconLeft">
            <img src={calculators.iconLeft} alt={calculators.name} />
          </div>}
          <h4>Your calculator scenario has been saved.</h4>
          <p>You can access your saved scenarios through the “Select result” dropdown menu at the top of each calculator page.</p>
        </div>
      </ModalBox>
    </Layout>
  )
}

export default CarLeasePayment
