import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LayoutAdmin from '../../components/Layout/LayoutAdmin'
import { setCompanyData } from '../../redux/slices/companiesSlice';


const AdminPage = ({ props, isAdmin }) => {
  const dispatch = useDispatch();
  const { company_id } = useSelector((state) => state.user.user);

  useEffect(() => {
    dispatch(
      setCompanyData({
        image: null,
      })
    );
  }, [dispatch]);
  
  return (
    <LayoutAdmin companyId={company_id && company_id.toString()} isAdmin={isAdmin}>
      <div className="admin__welcome">
        <h3>Welcome to Admin Panel</h3>
        <p>You have access to admin navigation</p>
      </div>
    </LayoutAdmin>
  );
}

export default AdminPage
