import React from 'react'
import clsx from 'classnames'
import PropTypes from 'prop-types'

const LayoutForm = ({ children, illustration, small, medium }) => {
  const className = clsx('layout-form__content', {
    'layout-form__content--small': small,
    'layout-form__content--medium': medium,
  })
  return (
    <div className={clsx('layout-form', {'layout-form--with-image': illustration})}>
      {illustration && <div className="layout-form__image"><img src={illustration} alt="" /></div>}
      <div className={className}>
        {children}
      </div>
    </div>
  )
};

LayoutForm.propTypes = {
  children: PropTypes.any,
  grid: PropTypes.string,
  illustration: PropTypes.string,
  small: PropTypes.bool,
  medium: PropTypes.bool
};

export default LayoutForm
