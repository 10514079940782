import React, {useEffect, useState} from 'react'
import { useHistory } from 'react-router-dom'
import Slider from 'react-slick'
import { Form, Formik } from 'formik'
import { BackButton } from '../../../components/shared/Buttons'
import Layout from '../../../components/Layout/Layout'
import PageTitle from '../../../components/PageTitle/PageTitle'
import LayoutForm from '../../../components/Layout/LayoutForm'
import {Button, TextareaAutosize} from '@mui/material'
import ValuesCard from '../../../components/Cards/ValuesCard'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchValues,
  fetchDreams,
  fetchGoals, fetchUserDetails
} from '../../../redux/slices/userSlice'
import { fetchValuesData } from '../../../redux/slices/optionsSlice'
import { getByParam, getPercent } from '../../../utils/utils'
import { postInfo, fetchInfoByParams } from '../../../redux/slices/infoSlice'
import Tooltip from '../../../components/shared/Tooltip/Tooltip'
import { fetchTotalPercent } from '../../../redux/slices/percentagesSlice'
import Priority from '../../../components/shared/Priority/Priority'
import Modal from '../../../components/shared/Modal/Modal'
import SVGIcon from '../../../components/shared/SVGIcon/SVGIcon'
import Icon from '../../../assets/images/icons/icon-ongratulations.svg'


const Values = ({ props }) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [isOpenSaveModal, setIsOpenSaveModal] = useState(false)
  const { id } = useSelector(state => state.user.user);
  const isBasic = useSelector((state) => state.user.user.plan_type === 1);
  const totalPercent = useSelector(state => state.percentages.total)
  const isMarried = useSelector(state => state.user.profile.details.is_married);
  const { values, spouse, dreams, goals } = useSelector(state => state.user);
  const dreamsSpouse = useSelector(state => state.user.spouse.dreams);
  const goalsSpouse = useSelector(state => state.user.spouse.goals);
  const listValues = useSelector(state => state.options.values);
  const info = useSelector(state => state.info.info);
  const sending = useSelector(state => state.sending.isSending);
  const percents = useSelector(state => state.percentages.percents);

  useEffect(() => {
    async function fetch() {
      //await dispatch(fetchPercentages());
      await dispatch(fetchUserDetails(id));
      await dispatch(fetchValuesData());

      await Promise.all([
        dispatch(fetchInfoByParams({type: 'values'})),
        dispatch(fetchValuesData()),
        dispatch(fetchValues()),
        dispatch(fetchValues(true)),
        dispatch(fetchDreams()),
        dispatch(fetchDreams(true)),
        dispatch(fetchGoals()),
        dispatch(fetchGoals(true)),
        dispatch(fetchTotalPercent(isBasic))
      ]);
    }
    if (id) {
      fetch()
    }

    // eslint-disable-next-line
  }, [id])

  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 640,
      },
      {
        breakpoint: 10000,
        settings: 'unslick'
      }
    ]
  }

  const onSubmit = values => {
    dispatch(postInfo(values))
    setIsOpenSaveModal(true)
  }

  const profile = getPercent(percents, 'profile')
  const valuesTotal = getPercent(percents, 'values')

  return (
    <Layout totalPercent={totalPercent} isBlockedPage={percents && (profile === 0)}>
      <LayoutForm>
        <BackButton title="Back to Tree" path="/input" />
        <PageTitle
          title="Your values & goals"
          content="Tell us about your and your spouse's values, goals, and dreams.  Rank your values in order of importance to you, 1 being most important and 6 being least important.  Make sure to give each value a unique ranking within its respective category (i.e. within 'Current' and within 'Preferred')."
          status={percents ? valuesTotal : '0'}
          info={
              <>
                <h6>ValueCircle step</h6>
                <Priority option="High" />
                <div>The Value Circle Module is an optional module that allows you and your spouse to jot down your values, goals and dreams -- in order of importance to each of you -- and determine how well aligned your individual values, goals, and dreams are to each other and how well aligned they are for the two of you as a couple.  This module helps you keep note of some of the items you'd like to accomplish over the years and understand whether they are aligned with your overall values, goals and dreams.</div>
                <hr className="tooltip__line"/>
                <h6>Example</h6>
                <div>Have you really sat down and thought what matters most to you:  happiness, security, power / prestige, health, making a difference, or obligation?  Do you know which of these means most to your spouse?  What are your top 5 goals and dreams?  Do you know what they are for your spouse?  Is your value system in-line with your spouse's?  How about your goals and dreams?  Are your values in-line with your goals and dreams?  Answer these and other similar questions, and get a ValueSoft alignment grade.</div>
              </>
          }
        />
        <div className="layout-form__content-holder">
          <div className="values__grid">
            <div>
              <div className="values__title">
                <h5>Your Values
                  <Tooltip
                    title={<>
                      <h6>Your Values</h6>
                      <Priority option="Medium" />
                      <div>ValueCircle categorizes your value system into 6 distinct categories: security, obligation, power/prestige, happiness, health, and making a difference.  Fill in the Current Ranking column with the relative importance of each of these 6 values to you in your current lifestyle.  Then fill out the Preferred Ranking column with what you wish that ranking would be. Finally give an example or two of what you think that value means in terms of some of your financial or life objectives.</div>
                      <hr className="tooltip__line"/>
                      <h6>Example</h6>
                      <div>Say you currently find yourself putting your lucrative job before your family and health.  You would like to change that over time so that family comes first.  In this case, fill in Power/Prestige as 1 under the Current Ranking and fill in Obligation as 1 under the Preferred Ranking.</div>
                    </>}
                  />
                </h5>
                <p>ValueCircle categorizes your value system into 6 distinct categories. Fill in the Current Ranking column with the relative importance of each of these 6 values to you in your current lifistyle.</p>
              </div>
              <div className="values__table-holder">
                <div className="values__table">
                  <div className="values__table-head">
                    <div>Value</div>
                    <div>Current Ranking (1-6)</div>
                    <div>Preferred Ranking (1-6)</div>
                  </div>
                  {values && values.map(item => (
                    <div className="values__table-row" key={item.id}>
                      <div>{item.name}</div>
                      <div>{item.current_ranking}</div>
                      <div>{item.preferred_ranking}</div>
                    </div>
                  ))}
                </div>
                <Button variant="contained" size="small" color="primary" onClick={() => history.push('/values/details')}>
                  Update
                </Button>
              </div>
            </div>
            <div className={`${!isMarried && 'values__disabled'}`}>
              <div className="values__title">
                <h5>Spouse’s Values
                  <Tooltip
                    title={<>
                      <h6>Spouse Values</h6>
                      <Priority option="Medium" />
                      <div>ValueCircle categorizes your spouse's value system into 6 distinct categories: security, obligation, power/prestige, happiness, health, and making a difference.  Fill in the 'Current Ranking' column with the relative importance of each of these 6 values to your spouse in his/her current lifestyle. Then fill out the 'Preferred Ranking' column with what your spouse wishes that ranking would be.</div>
                      <hr className="tooltip__line"/>
                      <h6>Example</h6>
                      <div>Say your spouse currently finds his/herself putting a lucrative job before family and health.  Your spouse would like to change that over time so that family comes first.  In this case, fill in 'Power/Prestige' as 1 under the 'Current Ranking' and fill in 'Obligation' as 1 under the 'Preferred Ranking'.</div>
                    </>}
                  />
                </h5>
                <p>ValueCircle categorizes your value system into 6 distinct categories. Then fill out the “Preferred Ranking” column with what your spouse wishes that ranking be.</p>
              </div>
              <div className="values__table-holder">
                <div className="values__table">
                  <div className="values__table-head">
                    <div>Value</div>
                    <div>Current Ranking (1-6)</div>
                    <div>Preferred Ranking (1-6)</div>
                  </div>
                  {spouse && spouse.values.map(item => (
                    <div className="values__table-row" key={item.id}>
                      <div>{item.name}</div>
                      <div>{item.current_ranking}</div>
                      <div>{item.preferred_ranking}</div>
                    </div>
                  ))}
                </div>
                <Button variant="contained" size="small" color="primary" onClick={() => history.push('/values/details-spouse')}>
                  Update
                </Button>
              </div>
            </div>
          </div>

          <div className="values__section">
            <div className="values__title">
              <h5>Your Goals
                <Tooltip
                  title={<>
                    <h6>Your Goals</h6>
                    <Priority option="Medium" />
                    <div>Goals are objectives you realistically hope to accomplish, that are within your means, if you plan right.  Fill in the entries with your top 5 goals and associate each to its corresponding value and indicate whether it requires money (above $5000).  In general, goals that require a financial commitment should be in your list of Objectives in the Objectives task.</div>
                    <hr className="tooltip__line"/>
                    <h6>Example</h6>
                    <div>Say you are saving $1000 each month and hope that in 5 years you can purchase a small $20,000 sail boat, which has always been a fascination of yours.  All things considered, this is a realistic objective and hence a goal.  It requires more than $5000 and hence it requires money and hence worth discussing with your wife to ensure she supports this financial objective.  Finally, it best aligns to your happiness value so fill in 'Happiness' under the corresponding value.</div>
                  </>}
                />
              </h5>
              <p>Goals are objectives you realistically hope to accomplish, that are within your means, if you plan right.</p>
            </div>
            {goals.length > 0 && <Slider {...settings} className="values__list">
                {goals && goals.map(item =>
                  <div key={item.id}>
                    <ValuesCard
                      title={getByParam(listValues, item.value_id, 'label')}
                      label={item.requires_money}
                      icon={getByParam(listValues, item.value_id, 'icon')}
                      content={item.name}
                    />
                  </div>
                )}
            </Slider>}
            <div className="form__button form__button--center">
              <Button variant="contained" size="large" color="primary" onClick={() => history.push('/values/goals')}>
                Update
              </Button>
            </div>
          </div>

          <div className="values__section">
            <div className="values__title">
              <h5>Your Dreams
                <Tooltip
                  title={<>
                    <h6>Your Dreams</h6>
                    <Priority option="Medium" />
                    <div>Dreams are objectives you hope to accomplish, but may seem unrealistic right now and would take extraordinary success of some kind to achieve them. Fill in the entries with your top 5 dreams and associate each to its corresponding value and indicate whether it requires money (above $5000).  In general, dreams that require a financial commitment should not be in your list of Objectives in the Objectives task, since they may require a significant cash outlay.</div>
                    <hr className="tooltip__line"/>
                    <h6>Example</h6>
                    <div>Say you are saving $1000 each month and hope that in 5 years you can purchase a large $2,000,000 yacht.  All things considered, this is a stretch objective and hence a dream.  It requires more than $5000 and hence it requires money and hence worth discussing with your wife to ensure she supports this financial objective.  Finally, it best aligns to your happiness value so fill in 'Happiness' under the corresponding value.</div>
                  </>}
                />
              </h5>
              <p>Dreams are objectives you  hope to accomplish, but may seem unrealistic right now and would take extraordinary success of some kind to achieve them.</p>
            </div>
            {dreams.length > 0 && <div className="values__list">
              {dreams && dreams.map(item =>
                <div key={item.id}>
                  <ValuesCard
                    title={getByParam(listValues, item.value_id, 'label')}
                    label={item.requires_money}
                    icon={getByParam(listValues, item.value_id, 'icon')}
                    content={item.name}
                  />
                </div>
              )}
            </div>}
            <div className="form__button form__button--center">
              <Button variant="contained" size="large" color="primary" onClick={() => history.push('/values/dreams')}>
                Update
              </Button>
            </div>
          </div>

          <div className={`values__section ${!isMarried && 'values__disabled'}`}>
            <div className="values__title">
              <h5>Spouse’s Goals
                <Tooltip
                  title={<>
                    <h6>Spouse's Goals</h6>
                    <Priority option="Medium" />
                    <div>Goals are objectives your spouse realistically hopes to accomplish, that are within your spouse's means, if he/she plans right.  Fill in the entries with your spouse's top 5 goals and associate each   to its corresponding value and indicate whether it requires money (above $5000).  In general, goals that require a financial commitment should be in your spouse's list   of Objectives in the Objectives task.</div>
                    <hr className="tooltip__line"/>
                    <h6>Example</h6>
                    <div>Say you are saving $1000 each month and your spouse hopes that in 5 years you can purchase a small $20,000 sail boat, which has always been a fascination of his/hers.  All things considered, this is a realistic objective and hence a goal.  It requires more than $5000 and hence it requires money and hence worth discussing with you to ensure you support this financial objective.  Finally, it best   aligns to your spouse's happiness value so fill in 'Happiness' under the corresponding value.</div>
                  </>}
                />
              </h5>
              <p>Goals are objectives your spouse realistically hopes to accomplish, that are within your spouse’s means, if you plans right.</p>
            </div>
            {goalsSpouse.length > 0 && <div className="values__list">
              {goalsSpouse && goalsSpouse.map(item =>
                <div key={item.id}>
                  <ValuesCard
                    title={getByParam(listValues, item.value_id, 'label')}
                    label={item.requires_money}
                    icon={getByParam(listValues, item.value_id, 'icon')}
                    content={item.name}
                  />
                </div>
              )}
            </div>}
            <div className="form__button form__button--center">
              <Button variant="contained" size="large" color="primary" onClick={() => history.push('/values/goals-spouse')}>
                Update
              </Button>
            </div>
          </div>

          <div className={`values__section ${!isMarried && 'values__disabled'}`}>
            <div className="values__title">
              <h5>Spouse's Dreams
                <Tooltip
                  title={<>
                    <h6>Spouse's Dreams</h6>
                    <Priority option="Medium" />
                    <div>Dreams are objectives your spouse hopes to accomplish, but may seem unrealistic right now and would take extraordinary success of some kind to achieve them. Fill in the entries with your spouse's top 5 dreams and associate each to its corresponding value and indicate whether it requires money (above $5000).  In general, dreams that require a financial commitment should not be in your spouse's list of Objectives in the Objectives task, since they may require a significant cash outlay.</div>
                    <hr className="tooltip__line"/>
                    <h6>Example</h6>
                    <div>Say you are saving $1000 each month and your spouse hopes that in 5 years you can purchase a large $2,000,000 yacht.  All things considered, this is a stretch objective and hence a dream. It requires more than $5000 and hence it requires money and hence worth discussing with you to ensure you support this financial objective. Finally, it best aligns to your spouse's happiness value so fill in 'Happiness' under the corresponding value.</div>
                  </>}
                />
              </h5>
              <p>Dreams are objectives your hopes to accomplish, but may seem unrealistic right now and would take extraordinary success of some kind to achieve them.</p>
            </div>
            {dreamsSpouse.length > 0 && <div className="values__list">
              {dreamsSpouse && dreamsSpouse.map(item =>
                <div key={item.id}>
                  <ValuesCard
                    title={getByParam(listValues, item.value_id, 'label')}
                    label={item.requires_money}
                    icon={getByParam(listValues, item.value_id, 'icon')}
                    content={item.name}
                  />
                </div>
              )}
            </div>}
            <div className="form__button form__button--center">
              <Button variant="contained" size="large" color="primary" onClick={() => history.push('/values/dreams-spouse')}>
                Update
              </Button>
            </div>
          </div>
        </div>
        <div className="form__comment-box mt--medium">
          <Formik
            enableReinitialize
            initialValues={{
              name: info.name || 'values',
              content: info.content || ''
            }}
            // validationSchema={Yup.object().shape({content: Yup.string().required('Required')})}
            onSubmit={onSubmit}
          >
            {({ errors, handleChange, handleBlur, values, touched }) => (
              <Form>
                <h5>Additional info</h5>
                <p>Is there any other Values, Goals, or Dreams information that you believe is important for ValueSoft to know about? If so, please let us know about it:</p>
                <TextareaAutosize
                  maxLength="200"
                  name="content"
                  value={values.content || ''}
                  className="form__textarea"
                  placeholder="Maximum characters: 200"
                  onChange={handleChange}
                />
                {errors.content && touched.content && <span className="form__msg-error">{errors.content}</span>}
                <div className="form__button form__button--center">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Save
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <Modal
          open={isOpenSaveModal}
          small
          close={() => setIsOpenSaveModal(false)}
          button="Ok"
          onClick={() => setIsOpenSaveModal(false)}
        >
          <div>
            <div className="modal__title-icon">
              <SVGIcon icon={Icon} />
            </div>
            <h4>You have successfully updated the data</h4>
          </div>
        </Modal>
      </LayoutForm>
    </Layout>
  );
};

export default Values;
