import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { Button, IconButton } from '@mui/material'
import { setMode } from '../../redux/slices/modeSlice'
import Priority from '../shared/Priority/Priority'
import SVGIcon from '../shared/SVGIcon/SVGIcon'
import check from '../../assets/images/icons/check-circle.svg'
import share from '../../assets/images/icons/share.svg'
import calender from '../../assets/images/icons/calender.svg'
import editIcon from '../../assets/images/icons/edit.svg'
import delIcon from '../../assets/images/icons/trash.svg'
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TooltipCustom from '../shared/Tooltip/Tooltip'
import ModalBox from '../../components/shared/Modal/Modal'

const TaskView = ({ removeTask, id, closeTask, revertTask, data, status }) => {
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = useState(false)
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);
  const { category, priority, description, name, assigned_at, task_status } = data

  const modeTask = mode => {
    dispatch(setMode(mode))
  }

  const revert = () => {
    revertTask(id)
    setOpenModal(false)
  }

  console.log(status)

  return (
    <>
      <div className="drawer__holder">
        <div className="drawer__title drawer__title--alt mobile-visible">
          Task details
        </div>
        <div className="drawer__top">
          <div className="drawer__top-row">
            <div className="drawer__category">{category.name}</div>
            <div className="drawer__data">
              Date Assigned: {moment(assigned_at).format("MM/DD/YYYY")}
            </div>
          </div>
          <div className="drawer__top-row">
            <Priority option={priority.name} large />
            {task_status && task_status.name === "Edited" && (
              <div className="drawer__edit-label">
                <SVGIcon icon={check} />
                Edited
              </div>
            )}
          </div>
        </div>
        <div className="drawer__status">{status}</div>
        <div className="drawer__title drawer__title--small">{name}</div>
        <div
          className="drawer__description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <div className="drawer__buttons">
          <div className="drawer__buttons__wrap">
            <Button
              variant="contained"
              size="large"
              color="primary"
              disabled={status === "Closed"}
              onClick={() => closeTask(id)}
            >
              Mark as done
            </Button>
            <ul className="drawer__buttons-list">
              <li>
                <IconButton onClick={() => modeTask("mail")}>
                  <SVGIcon icon={share} size="22px" color="#8C8C8C" />
                </IconButton>
              </li>
              <li>
                <IconButton onClick={() => modeTask("reminder")}>
                  <SVGIcon icon={calender} size="22px" color="#8C8C8C" />
                </IconButton>
              </li>
              <li>
                <IconButton onClick={() => modeTask("edit")}>
                  <SVGIcon icon={editIcon} size="22px" color="#8C8C8C" />
                </IconButton>
              </li>
              <li>
                <IconButton onClick={() => setIsOpenRemoveModal(true)}>
                  <SVGIcon icon={delIcon} size="22px" color="#8C8C8C" />
                </IconButton>
              </li>
            </ul>
          </div>
          {task_status && task_status.name === "Edited" && (
            <div className="drawer__revert">
              <button onClick={() => setOpenModal(true)}>
                Revert to original version
              </button>
              <TooltipCustom arrow title={<>Revert to base version</>}>
                <IconButton className="MuiIconButton-default">
                  <InfoOutlinedIcon />
                </IconButton>
              </TooltipCustom>
            </div>
          )}
        </div>
      </div>
      <ModalBox
        small
        inline
        open={openModal}
        title="Are you sure you want to revert this task to original version?"
        button="Yes"
        removeButton="Cancel"
        onClick={revert}
        close={() => setOpenModal(false)}
        remove={() => setOpenModal(false)}
      >
        <p>
          You will be taken back to the original version of the task that was
          generated for you.
        </p>
      </ModalBox>
      <ModalBox
        small
        inline
        open={isOpenRemoveModal}
        close={() => setIsOpenRemoveModal(false)}
        onClick={() => {
          removeTask(id);
          setIsOpenRemoveModal(false);
        }}
        button="Remove"
        closeButton="No"
        title="Are you sure you want to delete this task?"
        children={
          <p className="form-group">
            Deleting a task will make it reappear later if you update your
            inputs.  Otherwise, if you do not want to leverage this task
            whatsoever despite its relevance to your financial plan, then you're
            better off marking the task as Done to avoid it showing up again in
            the future.
          </p>
        }
      />
    </>
  );
}

TaskView.propTypes = {
  id: PropTypes.number,
  removeTask: PropTypes.func,
  closeTask: PropTypes.func,
  revertTask: PropTypes.func,
  data: PropTypes.object,
  status: PropTypes.string
}

export default TaskView
