import React from 'react'
import PropTypes from 'prop-types'
import HeaderShort from '../../components/Header/HeaderShort'

const AuthContent = ({ children }) => {

  return (
    <div className="term">
      {true && (<HeaderShort />)}
      <div className="term__content">
        {children}
      </div>
    </div>
  )
};

AuthContent.propTypes = {
  children: PropTypes.any
};

export default AuthContent
