const optionsWidget = [
  {
    key: 'Blog',
    label: 'Blog',
    value: 'blog',
  },
  {
    key: 'Calculators',
    label: 'Calculators',
    value: 'calculators',
  },
]

export default optionsWidget;
