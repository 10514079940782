import React from 'react'
import { Form, Formik } from 'formik'
import { Button } from '@mui/material'
import {FormGroup, Input, InputCurrency} from '../../components/shared/Form'
import Percentage from '../../assets/images/icons/percentage.svg'


const Education = ({ title, onSave, values, coreValues, validationSchema, sending, noEdit }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{ ...values }}
      validationSchema={validationSchema}
      onSubmit={(values) => onSave(values)}
    >
      {({ errors, handleChange, handleBlur, values, touched }) => (
        <Form>
          <h4>{title}</h4>
          <div className="form__flow-wrap">
            <FormGroup name="education_payplan_percent" labelText="Education funding percent">
              <Input
                name="education_payplan_percent"
                placeholder="Type education funding percent"
                type="number"
                onChange={handleChange}
                value={values.education_payplan_percent || ''}
                onBlur={handleBlur}
                iconName={Percentage}
                className={coreValues?.education_payplan_percent && !noEdit && 'form__input--core'}
                hasError={!!errors.education_payplan_percent && touched.education_payplan_percent}
              />
              {touched.education_payplan_percent && errors.education_payplan_percent ? (
                <span className="form__msg-error">{errors.education_payplan_percent}</span>
              ) : coreValues && coreValues.education_payplan_percent && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.education_payplan_percent}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="public_min_funding_amount" labelText="Public tuition minimum funding amount per year">
              <InputCurrency
                icon
                placeholder="$0.00"
                name="public_min_funding_amount"
                onChange={handleChange}
                value={values.public_min_funding_amount || ''}
                onBlur={handleBlur}
                className={coreValues?.public_min_funding_amount && !noEdit && 'form__input--core'}
                hasError={!!errors.public_min_funding_amount && touched.public_min_funding_amount}
              />
              {touched.public_min_funding_amount && errors.public_min_funding_amount ? (
                <span className="form__msg-error">{errors.public_min_funding_amount}</span>
              ) : coreValues && coreValues.public_min_funding_amount && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.public_min_funding_amount}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="private_min_funding_amount" labelText="Private tuition minimum funding amount per year">
              <InputCurrency
                icon
                placeholder="$0.00"
                name="private_min_funding_amount"
                onChange={handleChange}
                value={values.private_min_funding_amount || ''}
                onBlur={handleBlur}
                className={coreValues?.private_min_funding_amount && !noEdit && 'form__input--core'}
                hasError={!!errors.private_min_funding_amount && touched.private_min_funding_amount}
              />
              {touched.private_min_funding_amount && errors.private_min_funding_amount ? (
                <span className="form__msg-error">{errors.private_min_funding_amount}</span>
              ) : coreValues && coreValues.private_min_funding_amount && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.private_min_funding_amount}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="public_college_tuition" labelText="Public college tuition per year">
              <InputCurrency
                icon
                placeholder="$0.00"
                name="public_college_tuition"
                onChange={handleChange}
                value={values.public_college_tuition || ''}
                onBlur={handleBlur}
                className={coreValues?.public_college_tuition && !noEdit && 'form__input--core'}
                hasError={!!errors.public_college_tuition && touched.public_college_tuition}
              />
              {touched.public_college_tuition && errors.public_college_tuition ? (
                <span className="form__msg-error">{errors.public_college_tuition}</span>
              ) : coreValues && coreValues.public_college_tuition && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.public_college_tuition}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="private_college_tuition" labelText="Private college tuition per year">
              <InputCurrency
                icon
                placeholder="$0.00"
                name="private_college_tuition"
                onChange={handleChange}
                value={values.private_college_tuition || ''}
                onBlur={handleBlur}
                className={coreValues?.private_college_tuition && !noEdit && 'form__input--core'}
                hasError={!!errors.private_college_tuition && touched.private_college_tuition}
              />
              {touched.private_college_tuition && errors.private_college_tuition ? (
                <span className="form__msg-error">{errors.private_college_tuition}</span>
              ) : coreValues && coreValues.private_college_tuition && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.private_college_tuition}</span>
              ) : (
                ''
              )}
            </FormGroup>
          </div>
          <div className="form__button">
            <Button variant="contained" size="large" color="primary" type="submit" disabled={sending || noEdit}>
              Save
              {sending && <span className="spinner" />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default Education
