import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

const BackButton = ({ path, title, className, handleClick }) => {
  let history = useHistory();

  const goBack = () => {
    history.goBack()
  }

  return (
    <>
      {path
        ? (
          <Link className={`back-button ${className || ''}`} to={path}>
            <span className="back-button__icon">
              <NavigateBeforeIcon />
            </span>
            <span className="back-button__text">{ title }</span>
          </Link>
        )
        : (
          <button onClick={handleClick ? handleClick : goBack} className={`back-button ${className || ''}`}>
            <span className="back-button__icon">
              <NavigateBeforeIcon />
            </span>
            <span className="back-button__text">{ title }</span>
          </button>
        )
      }
    </>
  );
};

BackButton.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  handleClick: PropTypes.func
};

export default BackButton;
