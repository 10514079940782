import React, { useEffect } from 'react'
import { Form, Formik } from 'formik'
import Layout from '../../../components/Layout/Layout'
import LayoutForm from '../../../components/Layout/LayoutForm'
import { BackButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import ProgressBar from '../../../components/shared/ProgressBar/ProgessBar'
import { Input, FormGroup, AddressAutocomplete } from '../../../components/shared/Form'
import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserInfo, postUserInfo } from '../../../redux/slices/userSlice'
import { InfoUserSchema } from '../../../utils/validation'
import Illustration from '../../../assets/images/illustrations/Tree-segment_aboutMe-min.png'
import { fetchPercentages } from '../../../redux/slices/percentagesSlice'
import isArray from 'lodash/isArray'
import { phoneValidation } from '../../../utils/utils'

const AboutYou = ({ props }) => {
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.user.user);
  const infoUser = useSelector(state => state.user.user);
  const sending = useSelector(state => state.sending.isSending);
  const percents = useSelector(state => state.percentages.percents);

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchUserInfo(id));
      await dispatch(fetchPercentages({name: 'about-your'}));
      // await dispatch(fetchUserDetails(id))
    }
    if (id) {
      fetch()
    }

    // eslint-disable-next-line
  }, [id])

  const onSubmit = (values, setErrors) => {
     dispatch(postUserInfo(values, props, setErrors))
  }

  return (
    <Layout>
      <LayoutForm illustration={Illustration} small>
        <BackButton title="Back to Profile Summary" path="/profile-summary" />
        <PageTitle
          title="About you"
          content="Please fill out your profile information."
          status={percents && percents.percent ? percents.percent : '0'}
        />
        <div className="layout-form__content-holder">
          <div className="mb--medium">
            <ProgressBar amount={percents && percents.percent ? percents.percent : '0'} />
          </div>
          <Formik
            enableReinitialize
            initialValues={infoUser}
            validationSchema={InfoUserSchema}
            onSubmit={(values, { setErrors }) => onSubmit(values, setErrors)}
          >
            {({ errors, handleChange, handleBlur, values, touched, setFieldValue }) => (
              <Form>
                <FormGroup name="first_name" labelText="First name">
                  <Input
                    name="first_name"
                    placeholder="Enter your name"
                    type="text"
                    onChange={handleChange}
                    value={values.first_name || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.first_name && touched.first_name}
                  />
                  {touched.first_name && errors.first_name && <span className="form__msg-error">{errors.first_name}</span>}
                </FormGroup>
                <FormGroup name="last_name" labelText="Last name (optional)">
                  <Input
                    name="last_name"
                    placeholder="Enter your last name"
                    type="text"
                    onChange={handleChange}
                    value={values.last_name || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.last_name && touched.last_name}
                  />
                  {touched.last_name && errors.last_name && <span className="form__msg-error">{errors.last_name}</span>}
                </FormGroup>
                <div className="form__columns">
                  <div className="form__column">
                    <FormGroup name="year_of_birth" labelText="Year of birth">
                      <Input
                        name="year_of_birth"
                        small
                        placeholder="YYYY"
                        type="number"
                        onChange={handleChange}
                        value={values.year_of_birth || ''}
                        onBlur={handleBlur}
                        hasError={!!errors.year_of_birth && touched.year_of_birth}
                      />
                      {touched.year_of_birth && errors.year_of_birth && <span className="form__msg-error">{errors.year_of_birth}</span>}
                    </FormGroup>
                  </div>
                  <div className="form__column form__column--flex">
                    <FormGroup name="gender" labelText="Gender">
                      <RadioGroup
                        name="gender"
                        value={values.gender || null}
                        onChange={handleChange}
                        className="radio-list-inline"
                      >
                        <FormControlLabel value="male" control={<Radio />} label="Male" />
                        <FormControlLabel value="female" control={<Radio />} label="Female" />
                      </RadioGroup>
                      {touched.gender && errors.gender && <span className="form__msg-error">{errors.gender}</span>}
                    </FormGroup>
                  </div>
                </div>
                <FormGroup name="phone" labelText="Phone number">
                  <Input
                    name="phone"
                    placeholder="(XXX) XXX-XXXX"
                    type="text"
                    onChange={(e) => {
                      setFieldValue(e.target.name, phoneValidation(e.target.value))
                    }}
                    value={values.phone || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.phone && touched.phone}
                  />
                  {touched.phone && errors.phone && <span className="form__msg-error">
                    {isArray(errors.phone)
                      ? errors.phone.map(i => i)
                      : errors.phone
                    }
                  </span>}
                </FormGroup>
                <FormGroup name="email" labelText="Email address">
                  <Input
                    placeholder="example@email.com"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    value={values.email || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.email && touched.email}
                  />
                  {touched.email && errors.email && <span className="form__msg-error">
                    {isArray(errors.phone)
                      ? errors.email.map(i => i)
                      : errors.email
                    }
                  </span>}
                </FormGroup>
                <FormGroup name="address" labelText="Your address">
                  <AddressAutocomplete
                    placeholder="Type your address"
                    name="address"
                    setFieldValue={setFieldValue}
                    initialValue={values.address || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.address && touched.address}
                  />
                  {touched.address && errors.address && <span className="form__msg-error">{errors.address}</span>}
                </FormGroup>

                <div className="form__button">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Next
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </LayoutForm>
    </Layout>
  );
};

export default AboutYou;
