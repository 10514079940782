import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../../components/Layout/Layout'
import LayoutForm from '../../../components/Layout/LayoutForm'
import { BackButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import {fetchUserFamilyAssets, postUserFamilyAssets} from '../../../redux/slices/userSlice'
import { normalizeBoolean, getPercent } from '../../../utils/utils'
import { FormGroup } from '../../../components/shared/Form'
import Priority from '../../../components/shared/Priority/Priority'
import Modal from '../../../components/shared/Modal/Modal'
import ProgressCircle from '../../../components/shared/ProgressBar/ProgressCircle'
import { setIsOpen } from '../../../redux/slices/modalSlice'
import { fetchPercentages } from '../../../redux/slices/percentagesSlice'

const AboutYourFamilyAssets = ({ props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useSelector(state => state.user.user);
  const { assets } = useSelector(state => state.user.family);
  const isOpen = useSelector(state => state.modal.isOpen);
  const percents = useSelector(state => state.percentages.percents);
  const sending = useSelector(state => state.sending.isSending);

  useEffect(() => {
    async function fetch() {
      dispatch(fetchUserFamilyAssets(id))
      dispatch(fetchPercentages())
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])


  const total = getPercent(percents, 'about-your-family')
  const totalAssets = getPercent(percents, 'about-your-family-assets')

  const onSubmit = (values) => {
    dispatch(postUserFamilyAssets(values, props))
  }

  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }))
  }

  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }))
    if((total > 66 && totalAssets === 0) || (total === 100 && totalAssets === 100)) {
      history.push('/profile-summary')
    } else {
      history.push('/profile-summary/about-your-family')
    }
  }

  return (
    <Layout>
      <LayoutForm small>
        <BackButton title="Back" />
        <PageTitle
          small
          title="Assets"
          content="Info about your family"
        />
        <div className="layout-form__content-holder">
          <Formik
            enableReinitialize
            initialValues={assets && {...assets}}
            onSubmit={onSubmit}
          >
            {({ errors, handleChange, handleBlur, values, touched, setFieldValue }) => (
              <Form>
                <FormGroup name="own_or_rent_home" labelText="Do you own or rent a home?">
                  <RadioGroup
                    name="own_or_rent_home"
                    value={values.own_or_rent_home || false}
                    onChange={handleChange}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value="own" control={<Radio />} label="Own" />
                    <FormControlLabel value="rent" control={<Radio />} label="Rent" />
                  </RadioGroup>
                </FormGroup>
                <FormGroup
                  name="own_other_assets"
                  labelText="Do you own any other fixed assets or have any other outstanding loans?"
                  info={{
                    content:
                      <>
                        <h6>Fixed Assets</h6>
                        <Priority option="Medium" />
                        <div>This excludes your primary home.  Example are additional property, cars, jewelry collection, boats, as well as their corresponding loans.  If you have student loans, answer Yes to this question, too.</div>
                      </>
                  }}
                >
                  <RadioGroup
                    name="own_other_assets"
                    value={values.own_other_assets || false}
                    onChange={(e) => {
                      setFieldValue('own_other_assets', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                </FormGroup>
                <FormGroup
                  name="plan_to_purchase"
                  labelText="Are you planning to purchase any additional fixed assets (e.g. property, car, boat)?"
                >
                  <RadioGroup
                    name="plan_to_purchase"
                    value={values.plan_to_purchase || false}
                    onChange={(e) => {
                      setFieldValue('plan_to_purchase', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                </FormGroup>
                <FormGroup
                  name="stakes_in_companies"
                  labelText="Do you, your spouse or family member own a significant stake in one or more companies?"
                >
                  <RadioGroup
                    name="stakes_in_companies"
                    value={values.stakes_in_companies || false}
                    onChange={(e) => {
                      setFieldValue('stakes_in_companies', normalizeBoolean(e.target.value))
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                </FormGroup>

                <div className="form__button form__button--center">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Save
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </LayoutForm>

      <Modal
        open={isOpen}
        small
        button="Done"
        onClick={handleClick}
        close={onClose}
      >
        <h3>Good Job!</h3>
        <p>Your progress increased 100%</p>
        <ProgressCircle amount={100} small />
      </Modal>
    </Layout>
  );
};

export default AboutYourFamilyAssets;
