import React from 'react'
import { CircularProgress } from '@mui/material'
import clsx from 'classnames'


const Loading = ({ small, xsmall, position }) => (
  <div className={clsx('loading',
    {'loading--small': small,
     'loading--xsmall': xsmall,
     'loading--position': position})}>
    <CircularProgress />
  </div>
)

export default Loading;