import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../../components/Layout/Layout';
import { BackButton, DeleteButton } from '../../../components/shared/Buttons';
import Illustration from '../../../assets/images/illustrations/Tree-segment_aboutMe-min.png';
import PageTitle from '../../../components/PageTitle/PageTitle';
import LayoutForm from '../../../components/Layout/LayoutForm';
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextareaAutosize,
} from '@mui/material';
import { normalizeBoolean } from '../../../utils/utils';
import {
  postUserCompany,
  updateUserCompany,
  removeUserCompany,
  fetchUserCompany,
  setLoading,
} from '../../../redux/slices/userCompaniesSlice';
import {
  Input,
  InputCurrency,
  FormGroup,
  FormSelect,
  AddressAutocomplete,
} from '../../../components/shared/Form';
import Percentage from '../../../assets/images/icons/percentage.svg';
import ModalBox from '../../../components/shared/Modal/Modal';
import { setIsOpen } from '../../../redux/slices/modalSlice';

const companyType = [
  {
    id: 1,
    key: 1,
    value: 'construction',
    label: 'Construction',
  },
  {
    id: 2,
    key: 2,
    value: 'hardware',
    label: 'Hardware',
  },
  {
    id: 3,
    key: 3,
    value: 'life_science',
    label: 'Life Science',
  },
  {
    id: 4,
    key: 4,
    value: 'manufacturing',
    label: 'Manufacturing',
  },
  {
    id: 5,
    key: 5,
    value: 'non_profit',
    label: 'Non-Profit',
  },
  {
    id: 6,
    key: 6,
    value: 'retail',
    label: 'Retail',
  },
  {
    id: 7,
    key: 7,
    value: 'services',
    label: 'Services',
  },
  {
    id: 8,
    key: 8,
    value: 'software',
    label: 'Software',
  },
  {
    id: 9,
    key: 9,
    value: 'other',
    label: 'Other',
  },
];

const companyStatus = [
  {
    id: 1,
    key: 1,
    value: 'public',
    label: 'Public',
  },
  {
    id: 2,
    key: 2,
    value: 'private',
    label: 'Private',
  },
];

const companyRole = [
  {
    id: 1,
    key: 1,
    value: 'founder',
    label: 'Founder',
  },
  {
    id: 2,
    key: 2,
    value: 'ceo',
    label: 'CEO',
  },
  {
    id: 3,
    key: 3,
    value: 'cfo',
    label: 'CFO',
  },
  {
    id: 4,
    key: 4,
    value: 'cto',
    label: 'CTO',
  },
  {
    id: 5,
    key: 5,
    value: 'coo',
    label: 'COO',
  },
  {
    id: 6,
    key: 6,
    value: 'other_executive',
    label: 'Other Executive',
  },
  {
    id: 7,
    key: 7,
    value: 'upper_management',
    label: 'Upper Management',
  },
  {
    id: 8,
    key: 8,
    value: 'other',
    label: 'Other',
  },
];

const companyLastFunding = [
  {
    id: 1,
    key: 1,
    value: 'friends_family',
    label: 'Friends & family',
  },
  {
    id: 2,
    key: 2,
    value: 'angel',
    label: 'Angel',
  },
  {
    id: 3,
    key: 3,
    value: 'seed',
    label: 'Seed',
  },
  {
    id: 4,
    key: 4,
    value: 'a',
    label: 'A',
  },
  {
    id: 5,
    key: 5,
    value: 'b',
    label: 'B',
  },
  {
    id: 6,
    key: 6,
    value: 'c',
    label: 'C',
  },
  {
    id: 7,
    key: 7,
    value: 'd',
    label: 'D',
  },
  {
    id: 8,
    key: 8,
    value: 'e',
    label: 'E',
  },
  {
    id: 9,
    key: 9,
    value: 'f_plus',
    label: 'F+',
  },
];

const entityTypes = [
  {
    id: 1,
    key: 1,
    value: 'c_corp',
    label: 'C-Corp',
  },
  {
    id: 2,
    key: 2,
    value: 's_corp',
    label: 'S-Corp',
  },
  {
    id: 3,
    key: 3,
    value: 'llc',
    label: 'LLC',
  },
  {
    id: 4,
    key: 4,
    value: 'sole_proprietorship',
    label: 'Sole Proprietorship',
  },
  {
    id: 5,
    key: 5,
    value: 'other',
    label: 'Other',
  },
];

const CompanyDetails = ({ props, isEdit }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.user.id);
  const sending = useSelector((state) => state.userCompanies.loading);
  const company = useSelector((state) => state.userCompanies.userCompany);
  const isOpen = useSelector((state) => state.modal.isOpen);
  const { id } = useParams();

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchUserCompany(id));
    }

    if (userId && id) {
      fetch();
    }

    // eslint-disable-next-line
  }, [userId, id]);

  const onSubmit = async (values) => {
    await dispatch(setLoading(true));
    await dispatch(postUserCompany(values, props));
    await dispatch(setLoading(false));
  };

  const updateData = async (values) => {
    await dispatch(setLoading(true));
    await dispatch(updateUserCompany(id, values, props));
    await dispatch(setLoading(false));
  };

  const deleteChild = () => {
    dispatch(removeUserCompany(id, props));
    dispatch(setIsOpen({ isOpen: false }));
  };

  return (
    <Layout>
      <LayoutForm illustration={Illustration} small>
        <BackButton title='Back to Current Companies' />
        {isEdit && (
          <DeleteButton
            className='delete-button--position'
            onClick={() => dispatch(setIsOpen({ isOpen: true }))}
          />
        )}
        <PageTitle
          title={
            isEdit && company && company.name ? company.name : 'Current Company'
          }
          content='Tell us a little bit more about your company.'
          status={isEdit ? 100 : '0'}
        />
        <div className='layout-form__content-holder'>
          <Formik
            enableReinitialize
            initialValues={
              isEdit && company
                ? { ...company }
                : {
                    name: '',
                    year_founded: '',
                    entity_type: '',
                    type: '',
                    address: '',
                    status: '',
                    valuation: '',
                    revenue: '',
                    net_income: '',
                    company_asset: '',
                    own_percent: '',
                    role: '',
                    backed: false,
                    last_funding_round: '',
                    notes: '',
                  }
            }
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Required'),
              year_founded: Yup.number()
                .integer('Please check this value')
                .typeError('Year must be a number')
                .required('Required'),
              entity_type: Yup.string().required('Required'),
              type: Yup.string().required('Required'),
              address: Yup.string().required('Required'),
              status: Yup.string().required('Required'),
              valuation: Yup.string()
                .nullable()
                .max(18, 'Please check this value')
                .required('Required'),
              revenue: Yup.string()
                .nullable()
                .max(18, 'Please check this value')
                .required('Required'),
              net_income: Yup.string()
                .nullable()
                .max(18, 'Please check this value')
                .required('Required'),
              company_asset: Yup.string()
                .nullable()
                .max(18, 'Please check this value')
                .required('Required'),
              own_percent: Yup.number()
                .integer('Please check this value')
                .nullable()
                .min(0, 'Min value 0')
                .max(100, 'Max value 100')
                .required('Required'),
              role: Yup.string().required('Required'),
              backed: Yup.boolean().when('status', {
                is: 'private',
                then: Yup.boolean().required('Required'),
              }),
              last_funding_round: Yup.string()
                .nullable()
                .when('backed', {
                  is: true,
                  then: Yup.string().required('Required'),
                }),
            })}
            onSubmit={isEdit ? updateData : onSubmit}
          >
            {({
              errors,
              handleChange,
              handleBlur,
              setFieldValue,
              values,
              touched,
            }) => (
              <Form>
                <FormGroup name='name' labelText='Name of company'>
                  <Input
                    name='name'
                    placeholder='Type a name'
                    type='text'
                    onChange={handleChange}
                    value={values.name || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.name && touched.name}
                  />
                  {touched.name && errors.name && (
                    <span className='form__msg-error'>{errors.name}</span>
                  )}
                </FormGroup>
                <FormGroup name='year_founded' labelText=' Year founded'>
                  <Input
                    name='year_founded'
                    placeholder="Type company's year founded"
                    type='number'
                    onChange={handleChange}
                    value={values.year_founded || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.year_founded && touched.year_founded}
                  />
                  {touched.year_founded && errors.year_founded && (
                    <span className='form__msg-error'>
                      {errors.year_founded}
                    </span>
                  )}
                </FormGroup>
                <FormGroup name='entity_type' labelText='Entity type'>
                  <FormSelect
                    options={entityTypes}
                    value={values.entity_type || ''}
                    name='entity_type'
                    onChange={(e) => {
                      setFieldValue('entity_type', e.target.value);
                    }}
                    placeholder='Select entity type'
                    hasError={!!errors.entity_type && touched.entity_type}
                  />
                  {touched.entity_type && errors.entity_type && (
                    <span className='form__msg-error'>
                      {errors.entity_type}
                    </span>
                  )}
                </FormGroup>
                <FormGroup name='type' labelText='Company type'>
                  <FormSelect
                    options={companyType}
                    value={values.type || ''}
                    name='type'
                    onChange={(e) => {
                      setFieldValue('type', e.target.value);
                    }}
                    placeholder='Select company type'
                    hasError={!!errors.type && touched.type}
                  />
                  {touched.type && errors.type && (
                    <span className='form__msg-error'>{errors.type}</span>
                  )}
                </FormGroup>
                <FormGroup name='address' labelText='Company location'>
                  <AddressAutocomplete
                    placeholder='Type company location'
                    name='address'
                    setFieldValue={setFieldValue}
                    initialValue={values.address || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.address && touched.address}
                  />
                  {touched.address && errors.address && (
                    <span className='form__msg-error'>{errors.address}</span>
                  )}
                </FormGroup>
                <FormGroup name='status' labelText='Company status'>
                  <FormSelect
                    options={companyStatus}
                    value={values.status || ''}
                    name='status'
                    onChange={(e) => {
                      setFieldValue('status', e.target.value);
                    }}
                    placeholder='Select company status'
                    hasError={!!errors.status && touched.status}
                  />
                  {touched.status && errors.status && (
                    <span className='form__msg-error'>{errors.status}</span>
                  )}
                </FormGroup>
                <FormGroup name='valuation' labelText='Company valuation'>
                  <InputCurrency
                    withoutSign
                    icon
                    id='valuation'
                    placeholder='Type company valuation'
                    name='valuation'
                    onChange={handleChange}
                    value={values.valuation || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.valuation && touched.valuation}
                  />
                  {touched.valuation && errors.valuation && (
                    <span className='form__msg-error'>{errors.valuation}</span>
                  )}
                </FormGroup>
                <FormGroup name='revenue' labelText=' Annual revenue'>
                  <InputCurrency
                    withoutSign
                    icon
                    id='revenue'
                    placeholder='Type annual revenue'
                    name='revenue'
                    onChange={handleChange}
                    value={values.revenue || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.revenue && touched.revenue}
                  />
                  {touched.revenue && errors.revenue && (
                    <span className='form__msg-error'>{errors.revenue}</span>
                  )}
                </FormGroup>
                <FormGroup
                  name='net_income'
                  labelText='Company annual net income'
                >
                  <InputCurrency
                    withoutSign
                    icon
                    id='net_income'
                    placeholder='Type company annual net income'
                    name='net_income'
                    onChange={handleChange}
                    value={values.net_income || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.net_income && touched.net_income}
                  />
                  {touched.net_income && errors.net_income && (
                    <span className='form__msg-error'>{errors.net_income}</span>
                  )}
                </FormGroup>
                <FormGroup name='company_asset' labelText='Assets'>
                  <InputCurrency
                    withoutSign
                    icon
                    id='company_asset'
                    placeholder='Type the company assets value'
                    name='company_asset'
                    onChange={handleChange}
                    value={values.company_asset || ''}
                    onBlur={handleBlur}
                    hasError={
                      !!errors.company_asset &&
                      touched.company_asset
                    }
                  />
                  {touched.company_asset &&
                    errors.company_asset && (
                      <span className='form__msg-error'>
                        {errors.company_asset}
                      </span>
                    )}
                </FormGroup>
                <FormGroup
                  name='own_percent'
                  labelText='Percent of company you own'
                >
                  <Input
                    name='own_percent'
                    placeholder='Type percent of company you own %'
                    type='number'
                    onChange={handleChange}
                    value={values.own_percent || ''}
                    onBlur={handleBlur}
                    iconName={Percentage}
                    hasError={!!errors.own_percent && touched.own_percent}
                  />
                  {touched.own_percent && errors.own_percent && (
                    <span className='form__msg-error'>
                      {errors.own_percent}
                    </span>
                  )}
                </FormGroup>
                <FormGroup name='role' labelText='Your role in company'>
                  <FormSelect
                    options={companyRole}
                    value={values.role || ''}
                    name='role'
                    onChange={(e) => {
                      setFieldValue(e.target.name, e.target.value);
                    }}
                    placeholder='Select your role'
                    hasError={!!errors.role && touched.role}
                  />
                  {touched.role && errors.role && (
                    <span className='form__msg-error'>{errors.role}</span>
                  )}
                </FormGroup>
                <FormGroup
                  name='backed'
                  labelText='Venture or Private Equity backed or soon to be?'
                >
                  <RadioGroup
                    name='backed'
                    value={values.backed || false}
                    onChange={(e) => {
                      setFieldValue('backed', normalizeBoolean(e.target.value));
                    }}
                    className='radio-list-inline'
                  >
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='No'
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Yes'
                    />
                  </RadioGroup>
                  {touched.backed && errors.backed && (
                    <span className='form__msg-error'>{errors.backed}</span>
                  )}
                </FormGroup>
                <FormGroup
                  name='last_funding_round'
                  labelText='Company last funding round'
                >
                  <FormSelect
                    options={companyLastFunding}
                    value={values.last_funding_round || ''}
                    name='last_funding_round'
                    onChange={(e) => {
                      setFieldValue('last_funding_round', e.target.value);
                    }}
                    placeholder='Select company last funding round'
                    hasError={
                      !!errors.last_funding_round && touched.last_funding_round
                    }
                  />
                  {touched.last_funding_round && errors.last_funding_round && (
                    <span className='form__msg-error'>
                      {errors.last_funding_round}
                    </span>
                  )}
                </FormGroup>
                <h5 className='mt--medium'>Additional info</h5>
                <FormGroup
                  name='notes'
                  labelText='Notable investors (optional):'
                >
                  <TextareaAutosize
                    maxLength='200'
                    name='notes'
                    value={values.notes || ''}
                    className='form__textarea'
                    placeholder='Maximum characters: 200'
                    onChange={handleChange}
                  />
                </FormGroup>
                <div className='form__button form__button--center'>
                  <Button
                    variant='contained'
                    size='large'
                    color='primary'
                    type='submit'
                    disabled={sending}
                  >
                    Save
                    {sending && <span className='spinner' />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <ModalBox
          small
          inline
          open={isOpen}
          title='Are you sure?'
          button='Remove Item'
          closeButton='Cancel'
          onClick={deleteChild}
          close={() => dispatch(setIsOpen({ isOpen: false }))}
        />
      </LayoutForm>
    </Layout>
  );
};

export default CompanyDetails;
