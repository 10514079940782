import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Layout from '../../../components/Layout/Layout'
import LayoutForm from '../../../components/Layout/LayoutForm'
import { BackButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import { Button, FormControlLabel, Radio, RadioGroup, TextareaAutosize } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserFamilyData, postUserFamilyData } from '../../../redux/slices/userSlice'
import { normalizeBoolean, getPercent } from '../../../utils/utils'
import { Form, Formik } from 'formik'
import { FormGroup, Input } from '../../../components/shared/Form'
import Priority from '../../../components/shared/Priority/Priority'
import Modal from '../../../components/shared/Modal/Modal'
import ProgressCircle from '../../../components/shared/ProgressBar/ProgressCircle'
import { setIsOpen } from '../../../redux/slices/modalSlice'
import { fetchPercentages } from '../../../redux/slices/percentagesSlice'
import { UserFamilyOthersSchema } from '../../../utils/validation'

const AboutYourFamilyOthers = ({ props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useSelector(state => state.user.user);
  const { others } = useSelector(state => state.user.family);
  const isOpen = useSelector(state => state.modal.isOpen);
  const percents = useSelector(state => state.percentages.percents);
  const sending = useSelector(state => state.sending.isSending);

  useEffect(() => {
    async function fetch() {
      dispatch(fetchUserFamilyData(id))
      dispatch(fetchPercentages())
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const total = getPercent(percents, 'about-your-family')
  const totalOther = getPercent(percents, 'about-your-family-other')

  const onSubmit = (values) => {
    dispatch(postUserFamilyData(values, props))
  }

  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }))
  }

  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }));
    if((total > 66 && totalOther === 0) || (total === 100 && totalOther === 100)) {
      history.push('/profile-summary')
    } else {
      history.push('/profile-summary/about-your-family')
    }
  }

  const handleChangeInput = (e, setValue) => {
    if([null, false].includes(e.target.value)) return false

    setValue(e.target.name, e.target.value)
  }

  return (
    <Layout>
      <LayoutForm small>
        <BackButton title="Back" />
        <PageTitle small title="Other info" content="Info about your family" />
        <div className="layout-form__content-holder">
          <Formik
            enableReinitialize
            initialValues={others && { ...others }}
            validationSchema={UserFamilyOthersSchema}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleChange,
              handleBlur,
              values,
              touched,
              setFieldValue,
            }) => (
              <Form>
                <FormGroup
                  name="paying_education"
                  labelText="Are you currently paying or planning to pay for anyone’s current or future education (including your own)?"
                >
                  <RadioGroup
                    name="paying_education"
                    value={values.paying_education || false}
                    onChange={(e) => {
                      setFieldValue(
                        "paying_education",
                        normalizeBoolean(e.target.value)
                      );
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                  </RadioGroup>
                </FormGroup>
                <FormGroup
                  name="feature_paying_education"
                  labelText="For any future dependents, do you plan to pay for any of their education?"
                >
                  <RadioGroup
                    name="feature_paying_education"
                    value={values.feature_paying_education || false}
                    onChange={(e) => {
                      setFieldValue(
                        "feature_paying_education",
                        normalizeBoolean(e.target.value)
                      );
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                  </RadioGroup>
                </FormGroup>
                <FormGroup
                  name="employment_sabbaticals"
                  labelText="Are you or your spouse planning any employment sabbaticals prior to retirement?"
                  info={{
                    content: (
                      <>
                        <h6>Employment Sabbatical</h6>
                        <Priority option="Medium" />
                        <div>
                          Employment sabbaticals are temporary absences from
                          work, usually between one to several years. An example
                          of a sabbatical is taking a few years off to raise
                          children.
                        </div>
                      </>
                    ),
                  }}
                >
                  <RadioGroup
                    name="employment_sabbaticals"
                    value={values.employment_sabbaticals || false}
                    onChange={(e) => {
                      setFieldValue(
                        "employment_sabbaticals",
                        normalizeBoolean(e.target.value)
                      );
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                  </RadioGroup>
                </FormGroup>
                <FormGroup
                  name="factor_in_a_period"
                  labelText="Would you like to factor in a period of unemployment for you or your spouse as part of your financial plan?"
                  info={{
                    content: (
                      <>
                        <h6>Unemployment</h6>
                        <Priority option="Medium" />
                        <div>
                          If you believe you or your spouse has a chance of
                          being unemployed in the future and would like to
                          financially plan for this adverse event, answer Yes.
                        </div>
                      </>
                    ),
                  }}
                >
                  <RadioGroup
                    name="factor_in_a_period"
                    value={values.factor_in_a_period || false}
                    onChange={(e) => {
                      setFieldValue(
                        "factor_in_a_period",
                        normalizeBoolean(e.target.value)
                      );
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                  </RadioGroup>
                </FormGroup>
                <FormGroup
                  name="significant_income"
                  labelText="Are you expecting to receive any significant incremental income or expecting to spend money on any significant incremental expenses?"
                  info={{
                    content: (
                      <>
                        <h6>Incremental Items</h6>
                        <Priority option="Medium" />
                        <div>
                          Incremental income and expenses are usually infrequent
                          items. Examples of incremental income include
                          inheritance, lawsuit settlement, etc. Examples of
                          incremental expenses include paying for an expensive
                          wedding, expensive home improvement projects, etc.
                        </div>
                      </>
                    ),
                  }}
                >
                  <RadioGroup
                    name="significant_income"
                    value={values.significant_income || false}
                    onChange={(e) => {
                      setFieldValue(
                        "significant_income",
                        normalizeBoolean(e.target.value)
                      );
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                  </RadioGroup>
                </FormGroup>
                <FormGroup
                  name="leave_bequests"
                  labelText="Are you planning to leave any bequests (to children, charity, etc.) when you and your spouse pass away? "
                >
                  <RadioGroup
                    name="leave_bequests"
                    value={values.leave_bequests || false}
                    onChange={(e) => {
                      setFieldValue(
                        "leave_bequests",
                        normalizeBoolean(e.target.value)
                      );
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                  </RadioGroup>
                </FormGroup>
                <FormGroup
                  name="miles_travelled_per_day"
                  labelText="Miles traveled by car per day by longest commuter in your household:"
                >
                  <Input
                    small
                    placeholder=""
                    type="number"
                    name="miles_travelled_per_day"
                    onChange={(e) => handleChangeInput(e, setFieldValue)}
                    value={values.miles_travelled_per_day.toString() || ""}
                    onBlur={handleBlur}
                    hasError={!!errors.miles_travelled_per_day}
                  />
                  {errors.miles_travelled_per_day && (
                    <span className="form__msg-error">
                      {errors.miles_travelled_per_day}
                    </span>
                  )}
                </FormGroup>
                <FormGroup
                  name="anyone_convicted"
                  labelText="Has anyone in your household been convicted of a crime, felony or DUI?"
                >
                  <RadioGroup
                    name="anyone_convicted"
                    value={values.anyone_convicted || false}
                    onChange={(e) => {
                      setFieldValue(
                        "anyone_convicted",
                        normalizeBoolean(e.target.value)
                      );
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                  </RadioGroup>
                </FormGroup>
                <FormGroup
                  name="high_risk_occupation"
                  labelText="Does anyone in your household have a high-risk occupation where they can accidentally cause serious injury or death to another person?"
                >
                  <RadioGroup
                    name="high_risk_occupation"
                    value={values.high_risk_occupation || false}
                    onChange={(e) => {
                      setFieldValue(
                        "high_risk_occupation",
                        normalizeBoolean(e.target.value)
                      );
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                  </RadioGroup>
                </FormGroup>
                <FormGroup
                  name="use_online_budget_tracking"
                  labelText="Do you use an online budget tracking tool like mint.com?"
                >
                  <RadioGroup
                    name="use_online_budget_tracking"
                    value={values.use_online_budget_tracking || false}
                    onChange={(e) => {
                      setFieldValue(
                        "use_online_budget_tracking",
                        normalizeBoolean(e.target.value)
                      );
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                  </RadioGroup>
                </FormGroup>
                <FormGroup
                  name="use_dropbox"
                  labelText="Do you want to use an online document management and storage tool like dropbox.com to store digital copies of your important documents, such as your insurance policies, copies of your passports and social security cards?"
                >
                  <RadioGroup
                    name="use_dropbox"
                    value={values.use_dropbox || false}
                    onChange={(e) => {
                      setFieldValue(
                        "use_dropbox",
                        normalizeBoolean(e.target.value)
                      );
                    }}
                    className="radio-list-inline"
                  >
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                  </RadioGroup>
                </FormGroup>
                <h5 className="mt--medium">Additional info</h5>
                <FormGroup
                  name="additional_info"
                  labelText="Is there any other Profile Summary information related to the fields above that you believe is important for ValueSoft to know about?"
                >
                  <TextareaAutosize
                    maxLength="200"
                    name="additional_info"
                    value={values.additional_info || ""}
                    className="form__textarea"
                    placeholder="Maximum characters: 200"
                    onChange={handleChange}
                  />
                </FormGroup>

                <div className="form__button form__button--center">
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    type="submit"
                    disabled={sending}
                  >
                    Save
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </LayoutForm>

      <Modal
        open={isOpen}
        small
        button="Done"
        onClick={handleClick}
        close={onClose}
      >
        <h3>Good Job!</h3>
        <p>Your progress increased 100%</p>
        <ProgressCircle amount={100} small />
      </Modal>
    </Layout>
  );
};

export default AboutYourFamilyOthers;
