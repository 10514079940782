import * as Yup from 'yup'

export const BudgetSchema = Yup.object().shape({
  emergency_fund: Yup.string().required('Required'),
  savings_rate: Yup.string().required('Required'),
  mortgage_to_income: Yup.string().required('Required'),
  fixed_mortgage_rate: Yup.string().nullable().required('Required'),
  inflation_rate: Yup.string().nullable().required('Required'),
});


export const PropertySchema = Yup.object().shape({
  dwelling_coverage_replacement: Yup.string().nullable().required('Required'),
  loss_of_use_replacement: Yup.string().nullable().required('Required'),
  personal_property_replacement: Yup.string().nullable().required('Required'),
  separate_structures_replacement: Yup.string().nullable().required('Required'),
  personal_liability_coverage: Yup.string().nullable().required('Required'),
});


export const UmbrellaSchema = Yup.object().shape({
  child_end_age: Yup.string().required('Required'),
  child_start_age: Yup.string().required('Required'),
  miles_per_day: Yup.string().required('Required'),
  net_worth: Yup.string().nullable().required('Required'),
});


export const AutoSchema = Yup.object().shape({
  comprehensive_coverage: Yup.string().nullable().required('Required'),
  medical_payment_coverage: Yup.string().nullable().required('Required'),
  personal_liability_auto_per_incident: Yup.string().nullable().required('Required'),
  personal_liability_auto_per_person: Yup.string().nullable().required('Required'),
  property_damage_coverage: Yup.string().nullable().required('Required'),
  rental_coverage: Yup.string().nullable().required('Required'),
  towing_coverage: Yup.string().nullable().required('Required'),
  uninsured_incident_coverage: Yup.string().nullable().required('Required'),
  uninsured_person_coverage: Yup.string().nullable().required('Required'),
});


export const RetirementSchema = Yup.object().shape({
  expenses_during_retirement: Yup.string().nullable().required('Required'),
  average_retirement_age: Yup.string().nullable().required('Required'),
  delayed_retirement_age: Yup.string().nullable().required('Required'),
  delayed_retirement_social_security: Yup.string().nullable().required('Required'),
  early_retirement_social_security: Yup.string().nullable().required('Required'),
  long_term_care_alert_age: Yup.string().nullable().required('Required'),
  long_term_care_warning_age: Yup.string().nullable().required('Required'),
  medical_care_min_age: Yup.string().nullable().required('Required'),
  min_distribution_age: Yup.string().nullable().required('Required'),
  social_security_delayed_age: Yup.string().nullable().required('Required'),
  social_security_min_age: Yup.string().nullable().required('Required'),
  social_security_survivor: Yup.string().nullable().required('Required'),
});


export const RetirementFundingSchema = Yup.object().shape({
  ira_max_over_fifty: Yup.string().nullable().required('Required'),
  ira_max_under_fifty: Yup.string().nullable().required('Required'),
  married_ira_roth_income: Yup.string().nullable().required('Required'),
  max_401k_over_fifty: Yup.string().nullable().required('Required'),
  max_401k_under_fifty: Yup.string().nullable().required('Required'),
  sep_ira_max: Yup.string().nullable().required('Required'),
  unmarried_ira_roth_income: Yup.string().nullable().required('Required'),
});


export const EducationSchema = Yup.object().shape({
  education_payplan_percent: Yup.string().nullable().required('Required'),
  private_college_tuition: Yup.string().nullable().required('Required'),
  private_min_funding_amount: Yup.string().nullable().required('Required'),
  public_college_tuition: Yup.string().nullable().required('Required'),
  public_min_funding_amount: Yup.string().nullable().required('Required')
});


export const EstateSchema = Yup.object().shape({
  estate_expenses: Yup.string().nullable().required('Required'),
  funeral_costs: Yup.string().nullable().required('Required'),
  joint_max_tax_exempt: Yup.string().nullable().required('Required'),
  life_expectancy: Yup.string().nullable().required('Required'),
  single_max_tax_exempt: Yup.string().nullable().required('Required'),
  tax_exemption: Yup.string().nullable().required('Required')
});


export const NetWorthSchema = Yup.object().shape({
  average_net_worth_fifty_four: Yup.string().nullable().required('Required'),
  average_net_worth_forty_four: Yup.string().nullable().required('Required'),
  average_net_worth_over_seventy_four: Yup.string().nullable().required('Required'),
  average_net_worth_seventy_four: Yup.string().nullable().required('Required'),
  average_net_worth_sixty_four: Yup.string().nullable().required('Required'),
  average_net_worth_thirty_four: Yup.string().nullable().required('Required'),
  median_net_worth_fifty_four: Yup.string().nullable().required('Required'),
  median_net_worth_forty_four: Yup.string().nullable().required('Required'),
  median_net_worth_over_seventy_four: Yup.string().nullable().required('Required'),
  median_net_worth_seventy_four: Yup.string().nullable().required('Required'),
  median_net_worth_sixty_four: Yup.string().nullable().required('Required'),
  median_net_worth_thirty_four: Yup.string().nullable().required('Required'),
});


export const AdditionalSchema = Yup.object().shape({
  fdic_insurance_limit: Yup.string().nullable().required("Required"),
  end_rental_car_insurance: Yup.string().nullable().required("Required"),
  investment_return: Yup.string().nullable().required("Required"),
  jewelry_cost: Yup.string().nullable().required("Required"),
  start_rental_car_insurance: Yup.string().nullable().required("Required"),
  tax_loss_reminder: Yup.string().nullable().required("Required"),
  valuecircle_threshold: Yup.string().nullable().required("Required"),
});