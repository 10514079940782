import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import SVGIcon from '../../../components/shared/SVGIcon/SVGIcon'
import UserIcon from '../../../assets/images/icons/user.svg'

const UserAvatar = ({ avatar, path }) => {
  return (
    <Link to={path} className="user-avatar">
      {avatar
        ? (
          <img src={avatar} alt="name" />
        )
        : (
          <div className="user-avatar__placeholder">
            <SVGIcon icon={UserIcon}/>
          </div>
        )
      }
    </Link>
  );
};

UserAvatar.propTypes = {
  // avatar: PropTypes.string,
  path: PropTypes.string
};


export default UserAvatar;
