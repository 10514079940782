import { createSlice } from '@reduxjs/toolkit'
import { UserCompanies } from '../../api/Api'
import { setIsLoading, setIsRelogin } from "./loadingSlice";
import { convertDecimal } from '../../utils/utils'
import { setNewTasksCount } from './taskSlice'

const userCompaniesSlice = createSlice({
    name: "userCompanies",
    initialState: {
      userCompanies: null,
      userCompany: null,
      loading: false,
    },
    reducers: {
      setUserCompanies: (state, action) => {
        state.userCompanies = action.payload;
      },
      setUserCompany: (state, action) => {
        state.userCompany = action.payload;
      },
      setLoading: (state, action) => {
        state.loading = action.payload;
      },
    },
  });
  
  export const fetchUserCompanies = () => async (dispatch) => {
    try {
      const response = await UserCompanies.get();
      await dispatch(setUserCompanies(response.data.data));
    } catch (e) {
      console.error(e);
      await dispatch(setIsLoading({isLoading : false}));
      if (e.response && e.response.status === 401) {
        await dispatch(setIsRelogin({ isRelogin: true }));
      }
    }
  }
  
  export const fetchUserCompany = id => async (dispatch) => {
    try {
      await dispatch(setLoading(true));
      const response = await UserCompanies.fetch(id);
      await dispatch(setUserCompany(response.data.data))
      await dispatch(setLoading(false));
    } catch (e) {
      console.error(e);
      await dispatch(setLoading(false));
      if (e.response && e.response.status === 401) {
        await dispatch(setIsRelogin({ isRelogin: true }));
      }
    }
  }
  
  export const postUserCompany = (data, props) => async (dispatch) => {
    try {
      data.valuation = convertDecimal(data.valuation)
      data.revenue = convertDecimal(data.revenue)
      data.net_income = convertDecimal(data.net_income)
      data.company_asset = convertDecimal(data.company_asset)
      const response = await UserCompanies.post(data);
      if (response.data) {
        await dispatch(setNewTasksCount(response.data.newTasksCount));
      }
      if (props) {
        await dispatch(() => props.history.push('/companies'));
      }
    } catch (e) {
      console.error(e);
      if (e.response && e.response.status === 401) {
        await dispatch(setIsRelogin({ isRelogin: true }));
      }
    }
  }
  
  export const updateUserCompany = (id, data, props) => async (dispatch) => {
    try {
      data.valuation = convertDecimal(data.valuation)
      data.revenue = convertDecimal(data.revenue)
      data.net_income = convertDecimal(data.net_income)
      data.company_asset = convertDecimal(data.company_asset)  
      const response = await UserCompanies.put(id, data);
      if (response.data) {
        await dispatch(setNewTasksCount(response.data.newTasksCount));
      }
      if (props) {
        await dispatch(() => props.history.push('/companies'));
      }
    } catch (e) {
      console.error(e);
      if (e.response && e.response.status === 401) {
        await dispatch(setIsRelogin({ isRelogin: true }));
      }
    }
  }
  
  export const removeUserCompany = (id, props) => async (dispatch) => {
    try {
      const response = await UserCompanies.del(id);
      if (response) {
        await dispatch(setNewTasksCount(response.data.newTasksCount));
      }
      await dispatch(() => props.history.push('/companies'));
    } catch (e) {
      console.error(e);
      if (e.response && e.response.status === 401) {
        await dispatch(setIsRelogin({ isRelogin: true }));
      }
    }
  }
  
  export const {
    setUserCompanies,
    setUserCompany,
    setLoading,
  } = userCompaniesSlice.actions;
  
  export default userCompaniesSlice.reducer