import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { mainAdminMenu } from '../../Data/mainMenu';
import SVGIcon from '../shared/SVGIcon/SVGIcon';
import { Button } from '@mui/material';
import Logo from '../../components/shared/Logo/Logo';
import UserAvatar from '../shared/UserAvatar/UserAvatar';
import { setOpenMenu } from '../../redux/slices/additionalSlice';
import FeedBack from '../Feedback/Feedback'
import { fetchCompanyPermissions } from '../../redux/slices/companiesSlice';

const HeaderAdmin = ({ id, isMobile }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { avatar, company_id, first_name, last_name, email, role_id } = useSelector((state) => state.user.user);
  const openMenu = useSelector((state) => state.additional.menuOpen);
  const companyPermissions = useSelector((state) => state.companies.company.info.permissions);
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    openMenu ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'visible');
  }, [openMenu]);

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchCompanyPermissions(id ? id : company_id))
    }
    if (company_id) {
      fetch()
    }

    // eslint-disable-next-line
  }, [company_id])


  const handleClick = () => {
    dispatch(setOpenMenu(!openMenu));
  };

  useEffect(() => {
    dispatch(setOpenMenu(false));

    // eslint-disable-next-line
  }, [location]);

  const handleHover = () => {
    setIsHover(!isHover);
  };

  const menuList = mainAdminMenu(role_id, id ? id : company_id);

  return (
    <div className={`header-admin ${company_id !== 1 ? 'header-admin--custom' : ''} ${openMenu ? ' header-admin--open' : ''}`}>
      <div className="header-admin__logo-box">
        <Logo path="/dashboard" className="header-admin__logo" />
      </div>
      <div className="header-admin__holder">
        <div className="header-admin__content">
          <ul className="header-admin__menu">
            {menuList &&
              menuList.map((item) => {
                return (
                  <li
                    className={`${item.title === 'Companies' && !!id && +id !== 1 ? 'menu-underline' : ''} 
                  ${companyPermissions[item.name] === false ? 'menu-hide' : ''}
                  ${item.title === 'Tasks' && !!id && +id !== company_id ? 'menu-hide' : ''}
                  ${item.title === 'Blog' && !!id && +id !== company_id ? 'menu-hide' : ''}
                  ${item.title === 'Notifications' && !!id && +id !== company_id ? 'menu-hide' : ''}
                  ${item.title === 'Overview' && ((!id && company_id === 1) || +id === 1) ? 'menu-hide' : ''}
                  `}
                    key={item.title}
                  >
                    <NavLink
                      onClick={isMobile ? handleClick : handleHover}
                      className="header-admin__menu-link"
                      to={item.path}
                      activeClassName="header-admin__menu-link--active"
                    >
                      <SVGIcon icon={item.icon} />
                      {item.title}
                    </NavLink>
                  </li>
                );
              })}
          </ul>
        </div>

        <div className="header-admin__side">
          <div className="header-admin__profile">
            <UserAvatar path="/profile" avatar={avatar} />
            <h6 className="header-admin__profile--name">{`${first_name} ${last_name}`}</h6>
            <p className="header-admin__profile--email">{email}</p>
          </div>
          <Button variant="outlined" size="small" color="primary" onClick={() => history.push('/profile')}>
            Go to account
          </Button>
        </div>
      </div>
      <button onClick={handleClick} className="tablet-visible header-admin__toggle">
        <span>Button</span>
      </button>
      <div className="header-admin__footer">
        <ul>
          <li>
            <NavLink className="header-admin__footer-link" to="/faq">
              FAQ
            </NavLink>
          </li>
          <li>
            <NavLink className="header-admin__footer-link" to="/terms">
              Terms & Conditions
            </NavLink>
          </li>
          <li>
            <FeedBack
              user={{first_name, last_name, email}}
              title="Share Your Feedback"
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HeaderAdmin;
