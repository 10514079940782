import React from 'react'
import { Form, Formik } from 'formik'
import { Button } from '@mui/material'
import { FormGroup, InputCurrency } from '../../components/shared/Form'

const Auto = ({ title, onSave, values, coreValues, validationSchema, sending, noEdit }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{ ...values }}
      validationSchema={validationSchema}
      onSubmit={(values) => onSave(values)}
    >
      {({ errors, handleChange, handleBlur, values, touched }) => (
        <Form>
          <h4>{title}</h4>
          <div className="form__flow-wrap">
            <FormGroup name="personal_liability_auto_per_incident" labelText="Personal liability coverage / incident ">
              <InputCurrency
                icon
                placeholder="Type personal liability"
                name="personal_liability_auto_per_incident"
                onChange={handleChange}
                value={values.personal_liability_auto_per_incident || ''}
                onBlur={handleBlur}
                className={coreValues?.personal_liability_auto_per_incident && !noEdit && 'form__input--core'}
                hasError={!!errors.personal_liability_auto_per_incident && touched.personal_liability_auto_per_incident}
              />
              {touched.personal_liability_auto_per_incident && errors.personal_liability_auto_per_incident ? (
                <span className="form__msg-error">{errors.personal_liability_auto_per_incident}</span>
              ) : coreValues && coreValues.personal_liability_auto_per_incident && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.personal_liability_auto_per_incident}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="personal_liability_auto_per_person" labelText="Personal liability coverage / person ">
              <InputCurrency
                icon
                placeholder="Type personal liability"
                name="personal_liability_auto_per_person"
                onChange={handleChange}
                value={values.personal_liability_auto_per_person || ''}
                onBlur={handleBlur}
                className={coreValues?.personal_liability_auto_per_person && !noEdit && 'form__input--core'}
                hasError={!!errors.personal_liability_auto_per_person && touched.personal_liability_auto_per_person}
              />
              {touched.personal_liability_auto_per_person && errors.personal_liability_auto_per_person ? (
                <span className="form__msg-error">{errors.personal_liability_auto_per_person}</span>
              ) : coreValues && coreValues.personal_liability_auto_per_person && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.personal_liability_auto_per_person}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="property_damage_coverage" labelText="Property damage coverage">
              <InputCurrency
                icon
                placeholder="Type property damage"
                name="property_damage_coverage"
                onChange={handleChange}
                value={values.property_damage_coverage || ''}
                onBlur={handleBlur}
                className={coreValues?.property_damage_coverage && !noEdit && 'form__input--core'}
                hasError={!!errors.property_damage_coverage && touched.property_damage_coverage}
              />
              {touched.property_damage_coverage && errors.property_damage_coverage ? (
                <span className="form__msg-error">{errors.property_damage_coverage}</span>
              ) : coreValues && coreValues.property_damage_coverage && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.property_damage_coverage}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="medical_payment_coverage" labelText="Medical payment coverage">
              <InputCurrency
                icon
                placeholder="Type Medical payment"
                name="medical_payment_coverage"
                onChange={handleChange}
                value={values.medical_payment_coverage || ''}
                onBlur={handleBlur}
                className={coreValues?.medical_payment_coverage && !noEdit && 'form__input--core'}
                hasError={!!errors.medical_payment_coverage && touched.medical_payment_coverage}
              />
              {touched.medical_payment_coverage && errors.medical_payment_coverage ? (
                <span className="form__msg-error">{errors.medical_payment_coverage}</span>
              ) : coreValues && coreValues.medical_payment_coverage && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.medical_payment_coverage}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="uninsured_incident_coverage" labelText="Uninsured motorist coverage / incident ($)">
              <InputCurrency
                icon
                placeholder="Type Uninsured motorist"
                name="uninsured_incident_coverage"
                onChange={handleChange}
                value={values.uninsured_incident_coverage || ''}
                onBlur={handleBlur}
                className={coreValues?.uninsured_incident_coverage && !noEdit && 'form__input--core'}
                hasError={!!errors.uninsured_incident_coverage && touched.uninsured_incident_coverage}
              />
              {touched.uninsured_incident_coverage && errors.uninsured_incident_coverage ? (
                <span className="form__msg-error">{errors.uninsured_incident_coverage}</span>
              ) : coreValues && coreValues.uninsured_incident_coverage && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.uninsured_incident_coverage}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="uninsured_person_coverage" labelText="Uninsured motorist coverage / person">
              <InputCurrency
                icon
                placeholder="Type Uninsured motorist"
                name="uninsured_person_coverage"
                onChange={handleChange}
                value={values.uninsured_person_coverage || ''}
                onBlur={handleBlur}
                className={coreValues?.uninsured_person_coverage && !noEdit && 'form__input--core'}
                hasError={!!errors.uninsured_person_coverage && touched.uninsured_person_coverage}
              />
              {touched.uninsured_person_coverage && errors.uninsured_person_coverage ? (
                <span className="form__msg-error">{errors.uninsured_person_coverage}</span>
              ) : coreValues && coreValues.uninsured_person_coverage && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.uninsured_person_coverage}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="comprehensive_coverage" labelText="Comprehensive coverage">
              <InputCurrency
                icon
                placeholder="Type Comprehensive"
                name="comprehensive_coverage"
                onChange={handleChange}
                value={values.comprehensive_coverage || ''}
                onBlur={handleBlur}
                className={coreValues?.comprehensive_coverage && !noEdit && 'form__input--core'}
                hasError={!!errors.comprehensive_coverage && touched.comprehensive_coverage}
              />
              {touched.comprehensive_coverage && errors.comprehensive_coverage ? (
                <span className="form__msg-error">{errors.comprehensive_coverage}</span>
              ) : coreValues && coreValues.comprehensive_coverage && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.comprehensive_coverage}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="towing_coverage" labelText="Towing coverage">
              <InputCurrency
                icon
                placeholder="Type Towing coverage"
                name="towing_coverage"
                onChange={handleChange}
                value={values.towing_coverage || ''}
                onBlur={handleBlur}
                className={coreValues?.towing_coverage && !noEdit && 'form__input--core'}
                hasError={!!errors.towing_coverage && touched.towing_coverage}
              />
              {touched.towing_coverage && errors.towing_coverage ? (
                <span className="form__msg-error">{errors.towing_coverage}</span>
              ) : coreValues && coreValues.towing_coverage && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.towing_coverage}</span>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup name="rental_coverage" labelText="Rental coverage">
              <InputCurrency
                icon
                placeholder="Type Rental coverage"
                name="rental_coverage"
                onChange={handleChange}
                value={values.rental_coverage || ''}
                onBlur={handleBlur}
                className={coreValues?.rental_coverage && !noEdit && 'form__input--core'}
                hasError={!!errors.rental_coverage && touched.rental_coverage}
              />
              {touched.rental_coverage && errors.rental_coverage ? (
                <span className="form__msg-error">{errors.rental_coverage}</span>
              ) : coreValues && coreValues.rental_coverage && !noEdit ? (
                <span className="form__msg-core">Core value is {coreValues.rental_coverage}</span>
              ) : (
                ''
              )}
            </FormGroup>
          </div>
          <div className="form__button">
            <Button variant="contained" size="large" color="primary" type="submit" disabled={sending || noEdit}>
              Save
              {sending && <span className="spinner" />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default Auto
