import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isNull from 'lodash/isNull';
import { Link, useHistory } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import { BackButton } from '../../components/shared/Buttons';
import Tabs, { TabPanel } from '../../components/shared/Tabs/Tabs';
import AnalysisBox from '../../components/Analysis/AnalysisBox';
import { Button } from '@mui/material';
import ProgressCircle from '../../components/shared/ProgressBar/ProgressCircle';
import {
  fetchFilterAnalysisState,
  fetchFilterAnalysisCity,
  fetchAnalysisGraphData,
  fetchFilterAnalysisRange
} from '../../redux/slices/analysisSlice';
import { Pie } from 'react-chartjs-2';
import { optionsPie } from '../../utils/optionsBar';
import isEmpty from 'lodash/isEmpty';
import Loading from '../../components/shared/Loading/Loading';
import { getPercent, convertDecimal } from '../../utils/utils';
import { fetchPercentages } from '../../redux/slices/percentagesSlice';
import AnalysisFilter from '../../components/Analysis/AnalysisFilter';
import SVGIcon from '../../components/shared/SVGIcon/SVGIcon';
import filterIcon from '../../assets/images/icons/filter-list.svg';
import Modal from '../../components/shared/Modal/Modal';

const backgroundColor = ['#FDB5A1', '#215E59', '#D4654D'];

const Analysis = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [title, setTitle] = useState('Compare my personal profile');
  const userId = useSelector((state) => state.user.user.id);
  const isBasic = useSelector((state) => state.user.user.plan_type === 1);
  const percents = useSelector((state) => state.percentages.percents);
  const {
    loading,
    age,
    netWorth,
    totalExpense,
    totalIncome,
    insurance,
    workPerDay,
    expectedInheritance,
    takeHome,
    home,
    auto,
    necessities,
    loved,
    luxuries,
    other,
    current,
    preferred,
    bequests,
    futureEducation,
    futureChildren,
    sabbatical,
    sabbaticalDuration,
    supportChildren,
    graphState,
    graphCity,
    graphRange,
  } = useSelector((state) => state.analysis);
  const [statesOption, setStatesOption] = useState([]);
  const [citiesOption, setCitiesOption] = useState([]);
  const [incomeRange, setIncomeRange] = useState([]);
  const [networthRange, setNetworthRange] = useState([]);
  const [stateValue, setStateValue] = useState([]);
  const [cityValue, setCityValue] = useState([]);
  const [marriedValue, setMarriedValue] = useState('');
  const [dependentsValue, setDependentsValue] = useState('');

  const [isMobile, setIsMobile] = useState(false);
  const [isSplit, setIsSplit] = useState(false);
  const [isModalFilter, setModalFilter] = useState(false);
  const mobileView = 999;
  const middleView = 1240;

  const handleMobileSize = () => {
    mobileView >= window.innerWidth ? setIsMobile(true) : setIsMobile(false);
  };

  const handleMiddleSize = () => {
    mobileView < window.innerWidth && middleView > window.innerWidth
      ? setIsSplit(true)
      : setIsSplit(false);
  };

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true);
    window.addEventListener('resize', handleMobileSize);

    return () => window.removeEventListener('resize', handleMobileSize);
  }, []);

  useEffect(() => {
    mobileView < window.innerWidth &&
      middleView > window.innerWidth &&
      setIsSplit(true);
    window.addEventListener('resize', handleMiddleSize);

    return () => window.removeEventListener('resize', handleMiddleSize);
  }, []);

  useEffect(() => {
    async function fetch() {
        await dispatch(fetchFilterAnalysisState())
        await dispatch(fetchFilterAnalysisRange())
      Promise.all([
        await dispatch(fetchPercentages()),
        await dispatch(fetchAnalysisGraphData({}))
      ]);
    }

    if (userId) {
      fetch();
    }

    // eslint-disable-next-line
  }, [userId]);

  const dataAnalysis = (data) => {
    if (data && !isNull(data)) {
      const { labels, datasets } = data;

      return {
        labels: labels,
        datasets:
          datasets &&
          datasets.map((item, i) => {
            return {
              label: item.label,
              data: [...item.data],
              backgroundColor: backgroundColor[i],
              maxBarThickness: 100,
            };
          }),
      };
    }
  };

  const dataAnalysisMobile = (data) => {
    let newArrayData = [];

    if (data && !isNull(data)) {
      const { labels, datasets } = data;

      labels.map((item, i) =>
        newArrayData.push({
          labels: [item],
          datasets:
            datasets &&
            datasets.map((child, index) => {
              return {
                label: child.label,
                data: child.data && [child.data[i]],
                backgroundColor: backgroundColor[index],
                maxBarThickness: 100,
              };
            }),
        })
      );
    }
    return newArrayData;
  };

  const profilePercent = getPercent(percents, 'profile');
  const expensePercent = getPercent(percents, 'expense');
  const valuesPercent = getPercent(percents, 'values');
  const sabbaticalPercent = getPercent(percents, 'sabbatical');
  const futureEducationPercent = getPercent(percents, 'future-education');
  const futureChildrenPercent = getPercent(percents, 'future-children');
  const objectivesPercent = getPercent(percents, 'objectives');
  const retirementPercent = getPercent(percents, 'retirement');
  const totalFuture =
    (sabbaticalPercent +
      futureEducationPercent +
      futureChildrenPercent +
      objectivesPercent +
      retirementPercent) /
    5;

  const handleChange = (event, newValue) => {
    let name = event.target.name;
    if (name === 'incomeRange') {
      setIncomeRange(newValue);
    }
    if (name === 'networthRange') {
      setNetworthRange(newValue);
    }
  };

  const onChangeIncome = (event) => {
    let val = +convertDecimal(event.target.value);
    let name = event.target.name;

    if (name === 'incomeRangeMin') {
      setIncomeRange((prevState) => [(prevState[0] = val), prevState[1]]);
    }
    if (name === 'incomeRangeMax') {
      setIncomeRange((prevState) => [prevState[0], (prevState[1] = val)]);
    }
  };

  const onChangeNetworth = (event) => {
    let val = +convertDecimal(event.target.value);
    let name = event.target.name;

    if (name === 'networthRangeMin') {
      setNetworthRange((prevState) => [(prevState[0] = val), prevState[1]]);
    }
    if (name === 'networthRangeMax') {
      setNetworthRange((prevState) => [prevState[0], (prevState[1] = val)]);
    }
  };

  const handleChangeState = (event) => {
    const {
      target: { value },
    } = event;
    setStateValue(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleChangeCity = (event) => {
    const {
      target: { value },
    } = event;
    setCityValue(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleMarriedValue = (event) => {
    const {
      target: { value },
    } = event;
    setMarriedValue(value)
  }

  const handleDependentsValue = (event) => {
    const {
      target: { value },
    } = event;
    setDependentsValue(value)
  }

  useEffect(() => {
    if(graphState) {
        setStatesOption(Object.values(graphState).filter(key => key))
    }

    if (graphCity) {
        setCitiesOption(Object.values(graphCity).filter(key => key))
    }

  }, [graphState, graphCity])

  useEffect(() => {

    if (graphRange) {
        setIncomeRange([0, graphRange?.income?.max])
        setNetworthRange([0, graphRange?.net_worth?.max])
    }

  }, [graphRange])

  useEffect(() => {
    async function fetchCity () {
        await dispatch(fetchFilterAnalysisCity({states: stateValue.join(',')}))
    }

    if(stateValue) {
        fetchCity()
    }

    // eslint-disable-next-line
  }, [stateValue])

  const onResetFilter = async () => {
    await setStateValue([])
    await setCityValue([])
    await setDependentsValue('')
    await setMarriedValue('')
    await setIncomeRange([])
    await setNetworthRange([])
    await dispatch(fetchFilterAnalysisRange())
    await dispatch(fetchAnalysisGraphData({}))
  }

  const onApplyFilter = () => {
    dispatch(fetchAnalysisGraphData({
        states: stateValue.join(','),
        cities: cityValue.join(','),
        married: marriedValue,
        dependents: dependentsValue,
        net_worth: networthRange.join('-'),
        income: incomeRange.join('-')
    }))
  }

  return (
    <Layout decor>
      <BackButton
        title='Overview'
        path='/dashboard'
        className='back-button--position'
      />
      <h4>{title}</h4>
      {isMobile ? (
        <Button
          startIcon={<SVGIcon icon={filterIcon} color='#FD8A63' />}
          variant='outlined'
          className='analysis-filter-button'
          onClick={() => setModalFilter(true)}
        >
          Compare with filter
        </Button>
      ) : (
        <AnalysisFilter
          maxIncomRange={graphRange?.income?.max}
          maxNetWorthRange={graphRange?.net_worth?.max}
          handleChange={handleChange}
          onChangeIncome={onChangeIncome}
          networthValue={networthRange}
          onChangeNetworth={onChangeNetworth}
          incomeValue={incomeRange}
          optionsState={statesOption}
          optionsCity={citiesOption}
          stateValue={stateValue}
          cityValue={cityValue}
          handleStateOptions={handleChangeState}
          handleCityOptions={handleChangeCity}
          handleMarriedValue={handleMarriedValue}
          marriedValue={marriedValue}
          dependentsValue={dependentsValue}
          handleDependentsValue={handleDependentsValue}
          onResetFilter={onResetFilter}
          onApplyFilter={onApplyFilter}
        />
      )}

      <div className='analysis__tabs'>
        <div className='mobile-large-hidden'>
          <Tabs
            handleChange={(event, newValue) => {
              setValue(newValue);
              setTitle(
                isBasic
                  ? (newValue === 0 && 'Compare my personal profile') ||
                      (newValue === 1 && 'Compare my expenses') ||
                      (newValue === 2 && 'Compare my values')
                  : (newValue === 0 && 'Compare my personal profile') ||
                      (newValue === 1 && 'Compare my expenses') ||
                      (newValue === 2 && 'Compare my objectives') ||
                      (newValue === 3 && 'Compare my values')
              );
            }}
            variant='scrollable'
            value={value}
            tabs={
              isBasic
                ? [
                    { title: 'Compare my profile' },
                    { title: 'Compare my expenses' },
                    { title: 'Compare my values' },
                  ]
                : [
                    { title: 'Compare my profile' },
                    { title: 'Compare my expenses' },
                    { title: 'Compare my objectives' },
                    { title: 'Compare my values' },
                  ]
            }
          />
        </div>
        <div className='mobile-large-visible'>
          <Tabs
            handleChange={(event, newValue) => {
              setValue(newValue);
              setTitle(
                isBasic
                  ? (newValue === 0 && 'Compare my personal profile') ||
                      (newValue === 1 && 'Compare my expenses') ||
                      (newValue === 2 && 'Compare my values')
                  : (newValue === 0 && 'Compare my personal profile') ||
                      (newValue === 1 && 'Compare my expenses') ||
                      (newValue === 2 && 'Compare my objectives') ||
                      (newValue === 3 && 'Compare my values')
              );
            }}
            variant='scrollable'
            value={value}
            tabs={
              isBasic
                ? [
                    { title: 'Profile' },
                    { title: 'Expenses' },
                    { title: 'Values' },
                  ]
                : [
                    { title: 'Profile' },
                    { title: 'Expenses' },
                    { title: 'Objectives' },
                    { title: 'Values' },
                  ]
            }
          />
        </div>
        <TabPanel value={value} index={0}>
          <div className='analysis__holder'>
            {loading ? (
              <Loading />
            ) : profilePercent < 1 ? (
              <div className='analysis__empty'>
                <div>
                  <h5>Fill fields!</h5>
                  <p>
                    You must complete your input steps before you can compare
                    your expenses against ValueSoft benchmark information.{' '}
                  </p>
                  <Button
                    variant='contained'
                    size='large'
                    color='primary'
                    onClick={() => history.push('/input')}
                  >
                    Complete steps
                  </Button>
                </div>
                <div className='analysis__empty-chart'>
                  <ProgressCircle
                    amount={profilePercent}
                    title={
                      <>
                        <strong>{profilePercent} / 100%</strong>About me
                      </>
                    }
                    showPercent
                  />
                </div>
              </div>
            ) : (
              <>
                {(!isEmpty(age) || !isEmpty(netWorth)) && (
                  <div className='analysis__container'>
                    <div className='analysis__grid analysis__grid-2'>
                      {age && !isEmpty(age) && (
                        <div>
                          <AnalysisBox
                            title='Age'
                            isMobile={isMobile}
                            data={dataAnalysis(age)}
                            mobileData={dataAnalysisMobile(age)}
                            legendShow
                            showLabel
                            handleClick={() =>
                              history.push('/profile-summary/about-you')
                            }
                          />
                        </div>
                      )}
                      {netWorth && !isEmpty(netWorth) && (
                        <div>
                          <AnalysisBox
                            title='Net worth'
                            tooltip
                            tooltipTitleOne='Available Net Worth'
                            tooltipTitleTwo='Total Net Worth (includes Unvested Stock)'
                            tooltipDescriptionOne='Vested, unexercised stocks are stocks you now officially own but have not sold yet'
                            tooltipDescriptionTwo="Unvested stock are stocks you will own in the future once you've reached your vesting date. As it stands today, unvested stock are technically not part of your assets yet."
                            isMobile={isMobile}
                            data={dataAnalysis(netWorth)}
                            mobileData={dataAnalysisMobile(netWorth)}
                            legendShow
                            showLabel
                            amount
                            split={isSplit}
                            handleClick={() => history.push('/worth/assets')}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {(!isEmpty(totalIncome) || !isEmpty(totalExpense)) && (
                  <div className='analysis__container'>
                    <div className='analysis__grid'>
                      {totalIncome && !isEmpty(totalIncome) && (
                        <div>
                          <AnalysisBox
                            title='Total household income'
                            isMobile={isMobile}
                            data={dataAnalysis(totalIncome)}
                            mobileData={dataAnalysisMobile(totalIncome)}
                            legendShow
                            amount
                            showLabel
                            loader={loading || isEmpty(totalIncome)}
                            handleClick={() =>
                              history.push('/profile-summary/about-you')
                            }
                          />
                        </div>
                      )}
                      {totalExpense && !isEmpty(totalExpense) && (
                        <div className='analysis__grid-column-alt'>
                          <AnalysisBox
                            title='Total monthly expenses'
                            isMobile={isMobile}
                            data={dataAnalysis(totalExpense)}
                            mobileData={dataAnalysisMobile(totalExpense)}
                            legendShow
                            amount
                            loader={loading || isEmpty(totalExpense)}
                            handleClick={() => history.push('/expenses')}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {(!isEmpty(takeHome) || !isEmpty(workPerDay)) && (
                  <div className='analysis__container'>
                    <div className='analysis__grid'>
                      {takeHome && !isEmpty(takeHome) && (
                        <div>
                          <AnalysisBox
                            title='Total take-home monthly income'
                            isMobile={isMobile}
                            data={takeHome && dataAnalysis(takeHome)}
                            mobileData={dataAnalysisMobile(takeHome)}
                            legendShow
                            amount
                            showLabel
                            loader={loading || isNull(takeHome)}
                            handleClick={() => history.push('/income')}
                          />
                        </div>
                      )}
                      {workPerDay && !isEmpty(workPerDay) && (
                        <div className='analysis__grid-column-alt'>
                          <AnalysisBox
                            title='Work commute miles per day'
                            isMobile={isMobile}
                            data={workPerDay && dataAnalysis(workPerDay)}
                            mobileData={dataAnalysisMobile(workPerDay)}
                            legendShow
                            loader={loading || isEmpty(workPerDay)}
                            handleClick={() =>
                              history.push(
                                '/profile-summary/about-your-family/others'
                              )
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {(!isEmpty(insurance) || !isEmpty(expectedInheritance)) && (
                  <div className='analysis__container'>
                    <div className='analysis__grid'>
                      {insurance && !isEmpty(insurance) && (
                        <div>
                          <AnalysisBox
                            title='Insurance'
                            isMobile={isMobile}
                            data={insurance && dataAnalysis(insurance)}
                            mobileData={dataAnalysisMobile(insurance)}
                            legendShow
                            amount
                            showLabel
                            loader={loading || isEmpty(insurance)}
                            handleClick={() => history.push('/insurance')}
                          />
                        </div>
                      )}
                      {expectedInheritance && !isEmpty(expectedInheritance) && (
                        <div className='analysis__grid-column-alt'>
                          <AnalysisBox
                            title='Expected inheritance amount'
                            isMobile={isMobile}
                            data={
                              expectedInheritance &&
                              dataAnalysis(expectedInheritance)
                            }
                            mobileData={dataAnalysisMobile(expectedInheritance)}
                            legendShow
                            amount
                            loader={loading || isEmpty(expectedInheritance)}
                            handleClick={() =>
                              history.push('/incremental-income')
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          {isEmpty(home) &&
          isEmpty(necessities) &&
          isEmpty(auto) &&
          isEmpty(loved) &&
          isEmpty(luxuries) &&
          isEmpty(other) ? (
            <div className='analysis__empty'>
              <div>
                <h5>Fill fields!</h5>
                <p>
                  You must complete your input steps before you can compare your
                  expenses against ValueSoft benchmark information.{' '}
                </p>
                <Button
                  variant='contained'
                  size='large'
                  color='primary'
                  onClick={() =>
                    profilePercent < 1
                      ? history.push('/input')
                      : history.push('/expenses')
                  }
                >
                  Complete steps
                </Button>
              </div>
              <div className='analysis__empty-chart'>
                <ProgressCircle
                  amount={profilePercent < 1 ? profilePercent : expensePercent}
                  title={
                    <>
                      <strong>
                        {profilePercent < 1 ? profilePercent : expensePercent} /
                        100%
                      </strong>
                      {profilePercent < 1 ? 'About me' : 'Expenses'}
                    </>
                  }
                  showPercent
                />
              </div>
            </div>
          ) : (
            <>
              {home && !isEmpty(home) && (
                <div className='analysis__container'>
                  <div className='analysis__grid'>
                    <div>
                      <AnalysisBox
                        title='Home'
                        isMobile={isMobile}
                        data={home && dataAnalysis(home)}
                        mobileData={dataAnalysisMobile(home)}
                        legendShow
                        amount
                        showLabel
                        split
                        loader={loading || isNull(home)}
                        handleClick={() => history.push('/expenses')}
                      />
                    </div>
                  </div>
                </div>
              )}

              {(!isEmpty(auto) || !isEmpty(necessities)) && (
                <div className='analysis__container'>
                  <div className='analysis__grid'>
                    {auto && !isEmpty(auto) && (
                      <div>
                        <AnalysisBox
                          title='Automobile'
                          isMobile={isMobile}
                          data={auto && dataAnalysis(auto)}
                          mobileData={dataAnalysisMobile(auto)}
                          legendShow
                          amount
                          showLabel
                          loader={loading || isNull(auto)}
                          handleClick={() => history.push('/expenses')}
                        />
                      </div>
                    )}
                    {necessities && !isEmpty(necessities) && (
                      <div>
                        <AnalysisBox
                          title='Necessities'
                          isMobile={isMobile}
                          data={necessities && dataAnalysis(necessities)}
                          mobileData={dataAnalysisMobile(necessities)}
                          legendShow
                          amount
                          showLabel
                          split
                          loader={loading || isNull(necessities)}
                          handleClick={() => history.push('/expenses')}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {loved && !isEmpty(loved) && (
                <div className='analysis__container'>
                  <div className='analysis__grid'>
                    <div>
                      <AnalysisBox
                        title='Loved ones'
                        isMobile={isMobile}
                        data={loved && dataAnalysis(loved)}
                        mobileData={dataAnalysisMobile(loved)}
                        legendShow
                        amount
                        showLabel
                        split
                        maxLength={20}
                        loader={loading || isNull(loved)}
                        handleClick={() => history.push('/expenses')}
                      />
                    </div>
                  </div>
                </div>
              )}
              {(!isEmpty(luxuries) || !isEmpty(other)) && (
                <div className='analysis__container'>
                  <div className='analysis__grid'>
                    {luxuries && !isEmpty(luxuries) && (
                      <div>
                        <AnalysisBox
                          title='Luxuries'
                          isMobile={isMobile}
                          data={luxuries && dataAnalysis(luxuries)}
                          mobileData={dataAnalysisMobile(luxuries)}
                          legendShow
                          amount
                          showLabel
                          split
                          loader={loading || isNull(luxuries)}
                          handleClick={() => history.push('/expenses')}
                        />
                      </div>
                    )}
                    {other && !isEmpty(other) && (
                      <div>
                        <AnalysisBox
                          title='Other'
                          isMobile={isMobile}
                          data={other && dataAnalysis(other)}
                          mobileData={dataAnalysisMobile(other)}
                          legendShow
                          amount
                          showLabel
                          split
                          loader={loading || isNull(other)}
                          handleClick={() => history.push('/expenses')}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </TabPanel>
        {!isBasic && (
          <TabPanel value={value} index={2}>
            {isEmpty(bequests) &&
            isEmpty(sabbatical) &&
            isEmpty(sabbaticalDuration) &&
            isEmpty(supportChildren) &&
            isEmpty(futureChildren) &&
            isEmpty(futureEducation) ? (
              <div className='analysis__empty'>
                <div>
                  <h5>Fill fields!</h5>
                  <p>
                    You must complete your input steps before you can compare
                    your objectives against ValueSoft benchmark information.{' '}
                  </p>
                  <Button
                    variant='contained'
                    size='large'
                    color='primary'
                    onClick={() => history.push('/input')}
                  >
                    Complete steps
                  </Button>
                </div>
                <div className='analysis__empty-chart'>
                  <ProgressCircle
                    amount={profilePercent < 1 ? profilePercent : totalFuture}
                    title={
                      <>
                        <strong>
                          {profilePercent < 1 ? profilePercent : totalFuture} /
                          100%
                        </strong>
                        {profilePercent < 1 ? 'About me' : 'Future'}
                      </>
                    }
                    showPercent
                  />
                </div>
              </div>
            ) : (
              <>
                {(!isEmpty(bequests) || !isEmpty(sabbatical)) && (
                  <div className='analysis__container'>
                    <div className='analysis__grid'>
                      {bequests && !isEmpty(bequests) && (
                        <div>
                          <AnalysisBox
                            title='Bequests'
                            isMobile={isMobile}
                            data={dataAnalysis(bequests)}
                            mobileData={dataAnalysisMobile(bequests)}
                            legendShow
                            showLabel
                            amount
                            loader={loading || isEmpty(bequests)}
                            handleClick={() => history.push('/bequeath')}
                          />
                        </div>
                      )}
                      {sabbatical && !isEmpty(sabbatical) && (
                        <div>
                          <div className='analysis__box'>
                            <div className='analysis__box-title'>
                              <div>
                                <h6>Sabbatical</h6>
                              </div>
                              <div className='analysis__box-legend'>
                                <div className='analysis__box-legend-label'>
                                  No
                                </div>
                                <div className='analysis__box-legend-label analysis__box-legend-label-color4'>
                                  Yes
                                </div>
                              </div>
                            </div>
                            <div className='analysis__box-subtitle analysis__box-subtitle--center'>
                              You answered{' '}
                              {sabbatical && sabbatical.current
                                ? '"Yes"'
                                : '"No"'}{' '}
                              to whether you or your spouse will be taking a
                              sabbatical in the years to come. <br />
                              The remaining clients answered:
                            </div>
                            <div className='analysis__box-content'>
                              <div className='analysis__box-pie'>
                                <Pie
                                  width={100}
                                  height={100}
                                  options={optionsPie()}
                                  data={{
                                    datasets: [
                                      {
                                        data: [
                                          sabbatical.disagreed,
                                          sabbatical.agreed,
                                        ],
                                        backgroundColor: ['#215E59', '#E8E8E8'],
                                        borderColor: '#fff',
                                        borderWidth:
                                          sabbatical.disagreed === 0 ||
                                          sabbatical.agreed === 0
                                            ? 0
                                            : 8,
                                        datalabels: {
                                          color: ['#fff', '#000'],
                                        },
                                      },
                                    ],
                                  }}
                                  onElementsClick={() =>
                                    history.push(
                                      '/profile-summary/about-your-family/others'
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {(!isEmpty(sabbaticalDuration) ||
                  !isEmpty(supportChildren)) && (
                  <div className='analysis__container'>
                    <div className='analysis__grid'>
                      {sabbaticalDuration && !isEmpty(sabbaticalDuration) && (
                        <div>
                          <AnalysisBox
                            title='Sabbatical duration'
                            isMobile={isMobile}
                            data={dataAnalysis(sabbaticalDuration)}
                            mobileData={dataAnalysisMobile(sabbaticalDuration)}
                            legendShow
                            showLabel
                            loader={loading || isEmpty(sabbaticalDuration)}
                            handleClick={() => history.push('/sabbatical')}
                          />
                        </div>
                      )}
                      {supportChildren && !isEmpty(supportChildren) && (
                        <div>
                          <div className='analysis__box'>
                            <div className='analysis__box-title'>
                              <div>
                                <h6>Support children during retirement?</h6>
                              </div>
                              <div className='analysis__box-legend'>
                                <div className='analysis__box-legend-label'>
                                  No
                                </div>
                                <div className='analysis__box-legend-label analysis__box-legend-label-color4'>
                                  Yes
                                </div>
                              </div>
                            </div>
                            <div className='analysis__box-subtitle analysis__box-subtitle--center'>
                              You answered{' '}
                              {supportChildren && supportChildren.current
                                ? '"Yes"'
                                : '"No"'}{' '}
                              to whether you will financially support your
                              children after you retire.
                              <br />
                              The remaining clients answered:
                            </div>
                            <div className='analysis__box-content'>
                              <div className='analysis__box-pie'>
                                <Pie
                                  width={100}
                                  height={100}
                                  options={optionsPie()}
                                  data={{
                                    datasets: [
                                      {
                                        data: [
                                          supportChildren.disagreed,
                                          supportChildren.agreed,
                                        ],
                                        backgroundColor: ['#215E59', '#E8E8E8'],
                                        borderColor: '#fff',
                                        borderWidth:
                                          supportChildren.disagreed === 0 ||
                                          supportChildren.agreed === 0
                                            ? 0
                                            : 8,
                                        datalabels: {
                                          color: ['#fff', '#000'],
                                        },
                                      },
                                    ],
                                  }}
                                  onElementsClick={() =>
                                    history.push(
                                      '/profile-summary/about-your-family/children'
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {(!isEmpty(futureChildren) || !isEmpty(futureEducation)) && (
                  <div className='analysis__container'>
                    <div className='analysis__grid'>
                      {futureChildren && !isEmpty(futureChildren) && (
                        <div>
                          <AnalysisBox
                            title='Desired number of the future children'
                            isMobile={isMobile}
                            data={dataAnalysis(futureChildren)}
                            mobileData={dataAnalysisMobile(futureChildren)}
                            legendShow
                            loader={loading || isEmpty(futureChildren)}
                            center
                            handleClick={() => history.push('/future-children')}
                            text={
                              <>
                                You mentioned in the Profile Summary step of
                                your data gathering form that you did not
                                anticipate having [additional] children. If this
                                is incorrect, please go back to{' '}
                                <Link to='/profile-summary/about-your-family'>
                                  Profile Summary
                                </Link>{' '}
                                step and change your answer.
                              </>
                            }
                          />
                        </div>
                      )}
                      {futureEducation && !isEmpty(futureEducation) && (
                        <div>
                          <AnalysisBox
                            title='Future children education funding'
                            isMobile={isMobile}
                            data={dataAnalysis(futureEducation)}
                            mobileData={dataAnalysisMobile(futureEducation)}
                            legendShow
                            percent
                            loader={loading || isEmpty(futureEducation)}
                            center
                            handleClick={() =>
                              history.push('/future-education')
                            }
                            text={
                              <>
                                You mentioned in the Profile Summary step of
                                your data gathering form that you did not
                                anticipate having [additional] children. If this
                                is incorrect, please go back to{' '}
                                <Link to='/profile-summary/about-your-family'>
                                  Profile Summary
                                </Link>{' '}
                                step and change your answer.
                              </>
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </TabPanel>
        )}
        <TabPanel value={value} index={isBasic ? 2 : 3}>
          {isEmpty(current) && isEmpty(preferred) ? (
            <div className='analysis__empty'>
              <div>
                <h5>Fill fields!</h5>
                <p>
                  You must complete your input steps before you can compare your
                  values against ValueSoft benchmark information.{' '}
                </p>
                <Button
                  variant='contained'
                  size='large'
                  color='primary'
                  onClick={() =>
                    profilePercent < 1
                      ? history.push('/input')
                      : history.push('/values')
                  }
                >
                  Complete steps
                </Button>
              </div>
              <div className='analysis__empty-chart'>
                <ProgressCircle
                  amount={profilePercent < 1 ? profilePercent : valuesPercent}
                  title={
                    <>
                      <strong>
                        {profilePercent < 1 ? profilePercent : valuesPercent} /
                        100%
                      </strong>
                      {profilePercent < 1 ? 'About me' : 'Values'}
                    </>
                  }
                  showPercent
                />
              </div>
            </div>
          ) : (
            <>
              {current && !isEmpty(current) && (
                <div className='analysis__container'>
                  <div className='analysis__grid'>
                    <div>
                      <AnalysisBox
                        title='Current Values'
                        isMobile={isMobile}
                        text='Remember, the lower the value is, the more important it is.'
                        data={current && dataAnalysis(current)}
                        mobileData={dataAnalysisMobile(current)}
                        legendShow={2}
                        showLabel
                        loader={loading || isEmpty(current)}
                        handleClick={() => history.push('/values')}
                      />
                    </div>
                  </div>
                </div>
              )}
              {preferred && !isEmpty(preferred) && (
                <div className='analysis__container'>
                  <div className='analysis__grid'>
                    <div>
                      <AnalysisBox
                        title='Preferred Values'
                        isMobile={isMobile}
                        text='Remember, the lower the value is, the more important it is.'
                        data={preferred && dataAnalysis(preferred)}
                        mobileData={dataAnalysisMobile(preferred)}
                        legendShow={2}
                        showLabel
                        loader={loading || isNull(preferred)}
                        handleClick={() => history.push('/values')}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </TabPanel>
      </div>
      <Modal open={isModalFilter} close={() => setModalFilter(false)} fixHeight>
        <AnalysisFilter
          maxIncomRange={graphRange?.income?.max}
          maxNetWorthRange={graphRange?.net_worth?.max}
          handleChange={handleChange}
          onChangeIncome={onChangeIncome}
          networthValue={networthRange}
          onChangeNetworth={onChangeNetworth}
          incomeValue={incomeRange}
          optionsState={statesOption}
          optionsCity={citiesOption}
          stateValue={stateValue}
          cityValue={cityValue}
          handleStateOptions={handleChangeState}
          handleCityOptions={handleChangeCity}
          handleMarriedValue={handleMarriedValue}
          marriedValue={marriedValue}
          dependentsValue={dependentsValue}
          handleDependentsValue={handleDependentsValue}
          onResetFilter={onResetFilter}
          onApplyFilter={() => {
            onApplyFilter();
            setModalFilter(false);
        }}
        />
      </Modal>
    </Layout>
  );
};

export default Analysis;
