import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../../components/Layout/Layout'
import { BackButton } from '../../components/shared/Buttons'
import Tooltip from '../../components/shared/Tooltip/Tooltip'
import { Bar } from 'react-chartjs-2'
import { Slider, Input, FormGroup, FormSelect, InputCurrency } from '../../components/shared/Form'
import { optionsCalculator } from '../../utils/optionsBar'
import PercentIcon from '../../assets/images/icons/percentage.svg'
import { Button, Checkbox, FormControlLabel } from '@mui/material'
import ModalBox from '../../components/shared/Modal/Modal'
import {
  fetchCalculators,
  fetchListResults,
  fetchCurrentResult,
  saveItemResult,
  removeItemResult, setCurrentCalculator
} from '../../redux/slices/calculatorSlice'
import {convertDecimal, PMTFormula} from '../../utils/utils'
import { fetchMortgageAdmin } from '../../redux/slices/calculatorsSetupSlice'

const Mortgage = ({ props }) => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [isSavedModal, setIsSavedModal] = useState(false)
  const { id, company_id } = useSelector(state => state.user.user)
  const baseValues = useSelector(state => state.calculatorsSetup.mortgageAdmin);
  const { calculators, current, listExisting, loading } = useSelector(state => state.calculator)
  const [currentId, setCurrentId] = useState('')
  const [values, setValues] = useState(null)

  useEffect(() => {
    if(baseValues){
      setValues({
        currentId: '',
        name: '',
        ...baseValues
      })
    }
  // eslint-disable-next-line
  }, [baseValues])

  const [countValues, setCountValues] = useState({
    mortgagePayment: 0,
    mortgagePaymentPerMonth: 0,
    currentValueSoftPredefinedProperty: 0
  })

  const data = {
    labels: [`$ ${countValues.mortgagePayment}`, `$ ${countValues.mortgagePaymentPerMonth}`],
    datasets: [
      {
        data: [countValues.mortgagePayment, countValues.mortgagePaymentPerMonth],
        backgroundColor: ['#215E59', '#FDB5A1'],
        maxBarThickness: 114
      }
    ],
  }

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchMortgageAdmin(company_id))
      await dispatch(fetchCalculators({
        path: 'mortgage'
      }));
      await dispatch(fetchListResults('mortgage'))
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    if(values) {
      let homeValuePurchase = values.home_value * Math.pow((1 + 0.04), (values.age_upon_home_purchase - values.current_age))
    let downpaymentPurchase = homeValuePurchase * values.down_payment/100
    let mortgageAmount = homeValuePurchase - downpaymentPurchase
    let adjustedDownpayment = 1 - (mortgageAmount + values.points_closing_added_to_mortgage)/values.home_value
    let currentValueSoftPredefinedProperty = values.include_pmi && ((1 - adjustedDownpayment/100) > 0.8)
                                              ? ((mortgageAmount + values.points_closing_added_to_mortgage) * 0.01)/12
                                              : 0

    let adjustedPMI = values.include_pmi && (adjustedDownpayment < 0.2)
                      ? values.override_default_pmi_estimate
                          ? values.pmi_estimate_value
                          : currentValueSoftPredefinedProperty
                      : 0

    let interestBenefit = values.income_tax_bracket/100 * (((mortgageAmount + values.points_closing_added_to_mortgage) * values.finance_rate/100) + homeValuePurchase * (values.property_tax_rate/100))


    let mortgagePayment = values.home_value > 0
                           ? (PMTFormula(values.finance_rate/100, values.years_financed, (homeValuePurchase - downpaymentPurchase + values.points_closing_added_to_mortgage)) * -1)/12
                           : 0
    let mortgagePaymentPerMonth = values.home_value > 0
                                    ? mortgagePayment + values.insurance_amount_in_year/12 + values.annual_home_maintenance_expenses/12 + values.utility_amount_in_month + (homeValuePurchase * (values.property_tax_rate/100))/12 + adjustedPMI - interestBenefit/12
                                    : 0

    setCountValues({
      mortgagePayment: mortgagePayment.toFixed(2),
      mortgagePaymentPerMonth: mortgagePaymentPerMonth.toFixed(2),
      currentValueSoftPredefinedProperty: currentValueSoftPredefinedProperty.toFixed(2)
    })
    }

  }, [values])

  useEffect(() => {
    async function fetchNewData() {
      if (current) {
        setValues({
          name: current ? current.name : '',
          currentId: currentId,
          current_age: +current.current_age,
          age_upon_home_purchase: +current.age_upon_home_purchase,
          home_value: +current.home_value,
          down_payment: current.down_payment,
          years_financed: +current.years_financed,
          income_tax_bracket: +current.income_tax_bracket,
          points_down: +current.points_down,
          closing_costs: +current.closing_costs,
          points_closing_added_to_mortgage: +current.points_closing_added_to_mortgage,
          include_pmi: current.include_pmi,
          override_default_pmi_estimate: current.override_default_pmi_estimate,
          pmi_value: +current.pmi_value,
          pmi_estimate_value: +current.pmi_estimate_value,
          finance_rate: +current.finance_rate,
          property_tax_rate: +current.property_tax_rate,
          insurance_amount_in_year: +current.insurance_amount_in_year,
          utility_amount_in_month: +current.utility_amount_in_month,
          annual_home_maintenance_expenses: +current.annual_home_maintenance_expenses
        })
      }
    }

    if (currentId) {
      fetchNewData()
    }
    // eslint-disable-next-line
  }, [currentId, current])

  const handleInputChange = e => {
    setValues({
      ...values,
      [e.target.name]: +e.target.value
    })
  }

  // convert value to integer number
  const handleInputIntegerChange = e => {
    setValues({
      ...values,
      [e.target.name]: e.target.value.replace(/[^0-9]/g, "")
    })
  }

  const handleCurrencyChange = e => {
    setValues({
      ...values,
      [e.target.name]: +convertDecimal(e.target.value)
    })
  }

  const changeCurrentData = async e => {
    await setCurrentId(e.target.value)
    await dispatch(fetchCurrentResult(e.target.value, 'mortgage'))
  }

  const onSave = async () => {
    dispatch(saveItemResult(values, 'mortgage')).then(res => {
      const calculator = res?.data?.data;

      if(calculator) {
        setIsSavedModal(true);
        setCurrentId(calculator.id);
        dispatch(setCurrentCalculator(calculator));
      }
    }).finally(() => {
      setIsOpen(false);
    })
  }

  const resetForm = () => {
    setValues({
      currentId: '',
      name: '',
      ...baseValues
    })
    setCurrentId('')
  }

  const removeResult = async () => {
    await dispatch(removeItemResult(currentId, 'mortgage'))
    await setCurrentId('')
    await setIsDeleteModal(false)
    await setValues({
      ...baseValues
    })
  }

  return (
    <Layout decor>
      <BackButton title="Back to Calculators" path="/calculators" className="back-button--position" />
      <div className="calculators__title">
        <div>
          {calculators && <h4>{calculators.name}</h4>}
          {calculators &&
            <Tooltip
              title={
                <>
                  <h6>{calculators.name}</h6>
                  {calculators.tooltip}
                  <hr className="tooltip__line"/>
                  <h6>Example</h6>
                  {calculators.example}
                </>
              }
            />}
        </div>

        {listExisting && listExisting.length > 0 && <div className="calculators__title-select">
          <FormSelect
            options={listExisting}
            id="currentId"
            value={currentId}
            medium
            name="currentId"
            onChange={changeCurrentData}
            placeholder="Select result"
          />
        </div>}
      </div>

      <div className="calculators__holder">
        <div className="calculators__content">
          <h6 className="calculators__subtitle">Step 1: Input purchase details</h6>
          <div className="calculators__area">
            <Input
              type="number"
              name="current_age"
              value={values ? values.current_age.toString() : ''}
              small
              smallHeight
              onChange={handleInputIntegerChange}
            />
            <div className="calculators__area-slider">
              <Slider
                max={100}
                step={1}
                label="Current age"
                value={values ? +values.current_age : 0}
                onChange={(e, newValue) => setValues({...values, current_age: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <Input
              type="number"
              name="age_upon_home_purchase"
              value={values ? values.age_upon_home_purchase.toString() : ''}
              min={values && +values.current_age}
              small
              smallHeight
              onChange={handleInputIntegerChange}
            />
            <div className="calculators__area-slider">
              <Slider
                max={100}
                min={values && +values.current_age}
                step={1}
                label="Your age upon home purchase "
                value={values ? +values.age_upon_home_purchase : 0}
                onChange={(e, newValue) => setValues({...values, age_upon_home_purchase: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <InputCurrency
              name="home_value"
              value={values ? values.home_value.toString() : ''}
              small
              smallHeight
              onChange={handleCurrencyChange}
              withoutSign
              iconLeft
            />
            <div className="calculators__area-slider">
              <Slider
                max={500000}
                step={5000}
                label="Home value (Today’s $) "
                value={values ? +values.home_value : 0}
                onChange={(e, newValue) => setValues({...values, home_value: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <Input
              type="number"
              name="down_payment"
              value={values ? values.down_payment.toString() : ''}
              small
              step={0.1}
              smallHeight
              onChange={handleInputChange}
              iconName={PercentIcon}
            />
            <div className="calculators__area-slider">
              <Slider
                max={100}
                step={0.5}
                label="Downpayment"
                value={values ? +values.down_payment : 0}
                onChange={(e, newValue) => setValues({...values, down_payment: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <Input
              type="number"
              name="years_financed"
              value={values ? values.years_financed.toString() : ''}
              small
              smallHeight
              onChange={handleInputChange}
            />
            <div className="calculators__area-slider">
              <Slider
                label="Years Financed"
                max={100}
                step={0.5}
                value={values ? +values.years_financed : 0}
                onChange={(e, newValue) => setValues({...values, years_financed: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <Input
              type="number"
              name="finance_rate"
              value={values ? values.finance_rate.toString() : ''}
              small
              smallHeight
              onChange={handleInputChange}
              iconName={PercentIcon}
            />
            <div className="calculators__area-slider">
              <Slider
                label="Financing Rate"
                max={100}
                step={0.5}
                value={values ? +values.finance_rate : 0}
                onChange={(e, newValue) => setValues({...values, finance_rate: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <Input
              type="number"
              name="income_tax_bracket"
              value={values ? values.income_tax_bracket.toString() : ''}
              small
              smallHeight
              onChange={handleInputChange}
              iconName={PercentIcon}
            />
            <div className="calculators__area-slider">
              <Slider
                label="Income tax bracket"
                max={100}
                step={0.5}
                value={values ? +values.income_tax_bracket : 0}
                onChange={(e, newValue) => setValues({...values, income_tax_bracket: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <Input
              type="number"
              name="points_down"
              value={values ? values.points_down.toString() : ''}
              small
              smallHeight
              onChange={handleInputChange}
            />
            <div className="calculators__area-slider">
              <Slider
                label="Points down"
                max={10}
                step={0.25}
                value={values ? +values.points_down : 0}
                onChange={(e, newValue) => setValues({...values, points_down: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <Input
              type="number"
              name="closing_costs"
              value={values ? values.closing_costs.toString() : ''}
              small
              smallHeight
              onChange={handleInputChange}
              iconName={PercentIcon}
            />
            <div className="calculators__area-slider">
              <Slider
                label="Closing costs"
                max={20}
                step={0.25}
                value={values ? +values.closing_costs : 0}
                onChange={(e, newValue) => setValues({...values, closing_costs: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <InputCurrency
              name="points_closing_added_to_mortgage"
              value={values ? values.points_closing_added_to_mortgage.toString() : ''}
              small
              smallHeight
              onChange={handleCurrencyChange}
              withoutSign
              iconLeft
            />
            <div className="calculators__area-slider">
              <Slider
                label="Points & closing costs added to mortgage amount"
                max={20000}
                step={500}
                value={values ? +values.points_closing_added_to_mortgage : 0}
                onChange={(e, newValue) => setValues({...values, points_closing_added_to_mortgage: newValue})}
              />
            </div>
          </div>

          <div className="calculators__area">
            <InputCurrency
              name="currentValueSoftPredefinedProperty"
              value={countValues.currentValueSoftPredefinedProperty.toString() ||''}
              small
              smallHeight
              onChange={handleCurrencyChange}
              withoutSign
              iconLeft
              disabled={values && !values.include_pmi}
            />
            <div className="calculators__area-slider">
                <FormControlLabel
                  label="Include PMI in Calculations?"
                  className="MuiFormControlLabel-root--small"
                  control={
                    <Checkbox checked={values ? values.include_pmi : false}
                              onChange={() => {
                                setValues({
                                  ...values,
                                  include_pmi: !values.include_pmi
                                })
                              }}
                              name="include_pmi"
                    />
                  }
                />
            </div>
          </div>
          <div className="calculators__area">
            <InputCurrency
              name="pmi_estimate_value"
              value={values ? values.pmi_estimate_value.toString() : ''}
              small
              smallHeight
              onChange={handleCurrencyChange}
              withoutSign
              iconLeft
              disabled={values && !values.override_default_pmi_estimate}
            />
            <div className="calculators__area-slider">
              <FormControlLabel
                label="Override Default PMI Estimate?"
                className="MuiFormControlLabel-root--small"
                control={
                  <Checkbox checked={values ? values.override_default_pmi_estimate : false}
                            onChange={() => {
                              setValues({
                                ...values,
                                override_default_pmi_estimate: !values.override_default_pmi_estimate
                              })
                            }}
                            name="override_default_pmi_estimate"/>}
              />
              <Slider
                max={5000}
                step={20}
                value={values ? +values.pmi_estimate_value : 0}
                onChange={(e, newValue) => setValues({...values, pmi_estimate_value: newValue})}
                disabled={values && !values.override_default_pmi_estimate}
              />
            </div>
          </div>
          <div className="calculators__area">
            <Input
              type="number"
              name="property_tax_rate"
              value={values ? values.property_tax_rate.toString() : ''}
              small
              smallHeight
              onChange={handleInputChange}
              iconName={PercentIcon}
            />
            <div className="calculators__area-slider">
              <Slider
                label="Property tax rate"
                max={10}
                step={0.1}
                value={values ? +values.property_tax_rate : 0}
                onChange={(e, newValue) => setValues({...values, property_tax_rate: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <InputCurrency
              name="insurance_amount_in_year"
              value={values ? values.insurance_amount_in_year.toString() : ''}
              small
              smallHeight
              onChange={handleCurrencyChange}
              withoutSign
              iconLeft
            />
            <div className="calculators__area-slider">
              <Slider
                label="Insurance amount/year"
                max={5000}
                step={20}
                value={values ? +values.insurance_amount_in_year : 0}
                onChange={(e, newValue) => setValues({...values, insurance_amount_in_year: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <InputCurrency
              name="utility_amount_in_month"
              value={values ? values.utility_amount_in_month.toString() : ''}
              small
              smallHeight
              onChange={handleCurrencyChange}
              withoutSign
              iconLeft
            />
            <div className="calculators__area-slider">
              <Slider
                label="Utility amount/month"
                max={1000}
                step={20}
                value={values ? +values.utility_amount_in_month : 0}
                onChange={(e, newValue) => setValues({...values, utility_amount_in_month: newValue})}
              />
            </div>
          </div>
          <div className="calculators__area">
            <InputCurrency
              name="annual_home_maintenance_expenses"
              value={values ? values.annual_home_maintenance_expenses.toString() : ''}
              small
              smallHeight
              onChange={handleCurrencyChange}
              withoutSign
              iconLeft
            />
            <div className="calculators__area-slider">
              <Slider
                label="Annual home maintenance expenses"
                max={10000}
                step={500}
                value={values ? +values.annual_home_maintenance_expenses : 0}
                onChange={(e, newValue) => setValues({...values, annual_home_maintenance_expenses: newValue})}
              />
            </div>
          </div>
        </div>
        <div className="calculators__preview">
          <h6 className="calculators__subtitle">Step 2: Analyze monthly mortgage payment</h6>
          <div className="calculators__preview-box">
            <div className="calculators__legend">
              <div className="calculators__legend-label">Standard mortgage payment</div>
              <div className="calculators__legend-label calculators__legend-label-color2">ValueSoft Adjusted mortgage payment</div>
            </div>
            <Bar data={data} options={optionsCalculator(null, true, true)} />
          </div>
        </div>
      </div>

      <div className="calculators__buttons">
        <Button variant="contained" size="large" color="primary" onClick={() => setIsOpen(true)}>Save result</Button>
        {currentId && <Button variant="contained" size="large"  onClick={() => setIsDeleteModal(true)}>Remove result</Button>}
        <Button size="large" className="MuiButton-textGray" onClick={() => resetForm()}>Reset</Button>
      </div>

      <ModalBox
        open={isOpen}
        close={() => setIsOpen(false)}
        onClick={onSave}
        button="Save"
        title="Save Result"
        isLoading={loading}
        isDisabled={values && !values.name && !values.currentId}
      >
        <FormGroup name="name" labelText="Name">
          <Input
            name='name'
            value={values ? values.name : ''}
            placeholder="Type a name of result"
            onChange={e => setValues({
              ...values,
              name: e.target.value
            })}
          />
        </FormGroup>
        {listExisting && listExisting.length > 0 &&
          <>
            <p className="text-center">or</p>
            <FormGroup labelText="Select  Existing Result">
              <FormSelect
                options={listExisting}
                value={values ? values.currentId : ''}
                name="currentId"
                onChange={e => setValues({
                  ...values,
                  currentId: e.target.value,
                  name: listExisting.find(i => i.value === e.target.value).label
                })}
                placeholder="Select Existing Result"
              />
            </FormGroup>
          </>
        }
      </ModalBox>
      <ModalBox
        small
        open={isDeleteModal}
        close={() => setIsDeleteModal(false)}
        onClick={removeResult}
        button="Remove"
        title={`Do you want to remove ${current && current.name}?`}
      />

      <ModalBox
        small
        open={isSavedModal}
        close={() => setIsSavedModal(false)}
        button="Ok"
        onClick={() => setIsSavedModal(false)}
      >
        <div>
          {calculators && calculators.iconLeft &&
          <div className="modal__title-iconLeft">
            <img src={calculators.iconLeft} alt={calculators.name} />
          </div>}
          <h4>Your calculator scenario has been saved.</h4>
          <p>You can access your saved scenarios through the “Select result” dropdown menu at the top of each calculator page.</p>
        </div>
      </ModalBox>
    </Layout>
  )
}

export default Mortgage
