import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import LayoutAdmin from '../../components/Layout/LayoutAdmin';
import { Button } from '@mui/material';
import AdminNav from '../../Data/adminNav';
import Modal from '../../components/shared/Modal/Modal';
import SVGIcon from '../../components/shared/SVGIcon/SVGIcon';
import More from '../../assets/images/icons/more.svg';
import {
  Budget,
  Property,
  Auto,
  Umbrella,
  RetirementFunding,
  Retirement,
  Education,
  Estate,
  NetWorth,
  Additional,
} from '../../components/Assumptions';
import {
  fetchAuto,
  postAuto,
  fetchBudget,
  postBudget,
  fetchProperty,
  postProperty,
  fetchUmbrella,
  postUmbrella,
  fetchRetirement,
  postRetirement,
  fetchRetirementFunding,
  postRetirementFunding,
  fetchEducation,
  postEducation,
  fetchEstate,
  postEstate,
  fetchAdditional,
  postAdditional,
  fetchNetWorth,
  postNetWorth,
  revertAssumptionValue,
  fetchAssumptionValue
} from '../../redux/slices/assumptionsSlice';
import {
  BudgetSchema,
  UmbrellaSchema,
  AutoSchema,
  PropertySchema,
  RetirementSchema,
  RetirementFundingSchema,
  EducationSchema,
  EstateSchema,
  AdditionalSchema,
  NetWorthSchema,
} from '../../utils/validationAssumptions';
import { fetchCompany } from '../../redux/slices/companiesSlice';
import { setIsOpen } from '../../redux/slices/modalSlice';
import { setMode } from '../../redux/slices/modeSlice';
import Tabs, { TabPanel } from '../../components/shared/Tabs/Tabs';
import { isNull } from 'lodash';

const AdminLoring = ({ props, isAdmin }) => {
  const dispatch = useDispatch();
  const { id, role_id, permissions } = useSelector((state) => state.user.user);
  const sending = useSelector((state) => state.sending.isSending);
  const { info } = useSelector((state) => state.companies.company);
  const isOpen = useSelector((state) => state.modal.isOpen);
  const mode = useSelector((state) => state.mode.mode);
  const { companyId } = useParams();
  const { budget, property, auto, umbrella, retirement, retirementFunding, education, netWorth, estate, additional } =
    useSelector((state) => state.assumptions);
  const assumpObject = useSelector((state) => state.assumptions);
  const [isModal, setModal] = useState(false);
  const [value, setValue] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const mobileView = 999;

  const handleResize = () => {
    mobileView >= window.innerWidth ? setIsMobile(true) : setIsMobile(false);
  };

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(fetchCompany(companyId));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function fetch() {
      Promise.all([
        dispatch(fetchAuto(companyId)),
        dispatch(fetchBudget(companyId)),
        dispatch(fetchProperty(companyId)),
        dispatch(fetchUmbrella(companyId)),
        dispatch(fetchRetirement(companyId)),
        dispatch(fetchRetirementFunding(companyId)),
        dispatch(fetchEducation(companyId)),
        dispatch(fetchEstate(companyId)),
        dispatch(fetchNetWorth(companyId)),
        dispatch(fetchAdditional(companyId)),
      ]);
    }
    if (id) {
      fetch();
    }
    // eslint-disable-next-line
  }, [id]);

  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }));
    dispatch(setMode(null));
  };

  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }));
    dispatch(setMode(null));
  };

  const handleValue = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [value]);

  if (!isNull(info?.permissions?.assumptions) && !info?.permissions?.assumptions) return <Redirect to="/404" />;

  return (
    <LayoutAdmin companyId={companyId} isAdmin={isAdmin} admin>
      <div className="admin-tasks">
        <div className="admin__side">
          <div className="admin__side-sticky">
            <Tabs orientation="vertical" handleChange={handleValue} value={value} tabs={AdminNav.map((item) => ({ title: item.title }))} />
          </div>
        </div>
        <div className="admin__content">
          <div className="member-comapny member-comapny--top">
            <div className="member-comapny__info">
              <div className="member-comapny__logo">
                <img src={info?.image} alt="Company logo" />
              </div>
              <div className="member-comapny__title">
                <h3>{info?.name}</h3>
                {!isMobile && (
                  <p>
                    Feedback Email: <span>{info?.email}</span>
                  </p>
                )}
              </div>
              <button className="more-menu" onClick={() => setModal(true)}>
                <SVGIcon icon={More} color="#8C8C8C" />
              </button>
            </div>
            {isMobile && (
              <div className="mobile-feedback">
                Feedback Email: <span>{info?.email}</span>
              </div>
            )}
            <div className="member-comapny__buttons">
              {assumpObject?.[AdminNav[value].name]?.show_revert_button && role_id < 4 && (
                <>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={async () => {
                      await dispatch(revertAssumptionValue(companyId, AdminNav[value].slug));
                      await dispatch(fetchAssumptionValue(companyId, AdminNav[value].slug));
                    }}
                  >
                    Revert back
                  </Button>
                  <p className="member-comapny__buttons--desc">
                    {`One or more of your ${AdminNav[value].title} were changed. 
                    Please click the button to revert them back to
                    the original ValueSoft values.`}
                  </p>
                </>
              )}
            </div>
          </div>
          <TabPanel value={value} index={0} className="admin__content-section">
            <div className="admin__content-holder">
              <Budget
                title="Budget Assumptions"
                values={budget?.data}
                coreValues={budget?.diff_from_company_core}
                onSave={async (data) => {
                  await dispatch(postBudget(companyId, data));
                  await dispatch(fetchBudget(companyId))
                }}
                validationSchema={BudgetSchema}
                sending={sending}
                noEdit={!permissions?.edit_company_assumption}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={1} className="admin__content-section">
            <div className="admin__content-holder">
              <Property
                title="Property insurance assumptions"
                values={property?.data}
                coreValues={property?.diff_from_company_core}
                onSave={async (data) => {
                  await dispatch(postProperty(companyId, data));
                  await dispatch(fetchProperty(companyId));
                }}
                validationSchema={PropertySchema}
                sending={sending}
                noEdit={!permissions?.edit_company_assumption}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={2} className="admin__content-section">
            <div className="admin__content-holder">
              <Auto
                title="Auto insurance assumptions"
                values={auto?.data}
                coreValues={auto?.diff_from_company_core}
                onSave={async (data) => {
                  await dispatch(postAuto(companyId, data));
                  await dispatch(fetchAuto(companyId));
                }}
                validationSchema={AutoSchema}
                sending={sending}
                noEdit={!permissions?.edit_company_assumption}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={3} className="admin__content-section">
            <div className="admin__content-holder">
              <Umbrella
                title="Umbrella insurance assumptions"
                values={umbrella?.data}
                coreValues={umbrella?.diff_from_company_core}
                onSave={async (data) => {
                  await dispatch(postUmbrella(companyId, data));
                  await dispatch(fetchUmbrella(companyId));
                }}
                validationSchema={UmbrellaSchema}
                sending={sending}
                noEdit={!permissions?.edit_company_assumption}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={4} className="admin__content-section">
            <div className="admin__content-holder">
              <Retirement
                title="Retirement assumptions"
                values={retirement?.data}
                coreValues={retirement?.diff_from_company_core}
                onSave={async (data) => {
                  await dispatch(postRetirement(companyId, data));
                  await dispatch(fetchRetirement(companyId));
                }}
                validationSchema={RetirementSchema}
                sending={sending}
                noEdit={!permissions?.edit_company_assumption}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={5} className="admin__content-section">
            <div className="admin__content-holder">
              <RetirementFunding
                title="Retirement funding assumptions"
                values={retirementFunding?.data}
                coreValues={retirementFunding?.diff_from_company_core}
                onSave={async (data) => {
                  await dispatch(postRetirementFunding(companyId, data));
                  await dispatch(fetchRetirementFunding(companyId));
                }}
                validationSchema={RetirementFundingSchema}
                sending={sending}
                noEdit={!permissions?.edit_company_assumption}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={6} className="admin__content-section">
            <div className="admin__content-holder">
              <Education
                title="Education funding assumptions"
                values={education?.data}
                coreValues={education?.diff_from_company_core}
                onSave={async (data) => {
                  await dispatch(postEducation(companyId, data));
                  await dispatch(fetchEducation(companyId));
                }}
                validationSchema={EducationSchema}
                sending={sending}
                noEdit={!permissions?.edit_company_assumption}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={7} className="admin__content-section">
            <div className="admin__content-holder">
              <NetWorth
                title="Net worth assumptions"
                values={netWorth?.data}
                coreValues={netWorth?.diff_from_company_core}
                onSave={async (data) => {
                  await dispatch(postNetWorth(companyId, data));
                  await dispatch(fetchNetWorth(companyId));
                }}
                validationSchema={NetWorthSchema}
                sending={sending}
                noEdit={!permissions?.edit_company_assumption}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={8} className="admin__content-section">
            <div className="admin__content-holder">
              <Estate
                title="Estate planning assumptions"
                values={estate?.data}
                coreValues={estate?.diff_from_company_core}
                onSave={async (data) => {
                  await dispatch(postEstate(companyId, data));
                  await dispatch(fetchEstate(companyId));
                }}
                validationSchema={EstateSchema}
                sending={sending}
                noEdit={!permissions?.edit_company_assumption}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={9} className="admin__content-section">
            <div className="admin__content-holder">
              <Additional
                title="Additional assumptions & seasonal alerts"
                values={additional?.data}
                coreValues={additional?.diff_from_company_core}
                onSave={async (data) => {
                  await dispatch(postAdditional(companyId, data));
                  await dispatch(fetchAdditional(companyId));
                }}
                validationSchema={AdditionalSchema}
                sending={sending}
                noEdit={!permissions?.edit_company_assumption}
              />
            </div>
          </TabPanel>
        </div>
        <Modal
          open={isOpen}
          small
          button="Ok"
          onClick={handleClick}
          close={onClose}
          title={mode === 'success' ? 'Success!' : 'Error!'}
          children={
            mode === 'success' ? <p>The values were updated successfully.</p> : <p>Something went wrong. Try again.</p>
          }
        />
        <Modal
          open={isModal}
          small
          button="Apply"
          onClick={() => setModal(false)}
          close={() => setModal(false)}
          title="Assumptions"
          children={
            <div className="tab__modal-wrap">
              <Tabs orientation="vertical" handleChange={handleValue} value={value} tabs={AdminNav.map((item) => ({ title: item.title }))} />
            </div>
          }
        />
      </div>
    </LayoutAdmin>
  );
};

export default AdminLoring;
