import React, { useEffect, lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { Formik, Form } from 'formik';
import AuthContent from '../../components/Auth/AuthContent';
import { SignUpSchema } from '../../utils/validation';
import { FormGroup, Input, PasswordStrength } from '../../components/shared/Form';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Screen from '../../assets/images/screen-img.png';
import { registerCompanyMember, setAuthError, getTempMemberInfo } from '../../redux/slices/authSlice';
import { MAIN_SITE, RECAPTCHA_SITE_KEY } from '../../constants/additional_links';
import { setOpenMenu } from '../../redux/slices/additionalSlice';
import { setIsOpen } from "../../redux/slices/modalSlice";
import { phoneValidation } from '../../utils/utils';
import Modal from "../../components/shared/Modal/Modal";

const ReCAPTCHA = lazy(() => import('react-google-recaptcha'));

const SignUpMember = ({ props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const base = useSelector(state => state.auth.registerUser);
  const serverError = useSelector(state => state.auth.authError);
  const sending = useSelector(state => state.sending.isSending);
  const openMenu = useSelector(state => state.additional.menuOpen);
  const isOpen = useSelector(state => state.modal.isOpen);

  const query = new URLSearchParams(location.search);
  const token = query.get('token');

  useEffect(() => {
    async function fetch() {
      await dispatch(getTempMemberInfo(token));
    }

    if (token) {
      fetch();
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    openMenu
      ? document.body.style.overflow = 'hidden'
      : document.body.style.overflow = 'visible';
  }, [openMenu]);

  useEffect(() => {
    dispatch(setAuthError({ authError: false }));
    dispatch(setOpenMenu(false));
  }, [dispatch]);

  const onSubmit = values => {
    dispatch(registerCompanyMember(values, props));
  };

  const handleModalClose = () => {
    dispatch(setIsOpen({ isOpen: false }));
    history.push('/login');
  };

  return (
    <>
      <AuthContent decor={{ image: Screen }} close={MAIN_SITE}>
        <h2>Register new<br /> company member</h2>
        <Formik
          enableReinitialize
          initialValues={{
            first_name: base.first_name,
            last_name: base.last_name,
            email: base.email,
            phone: '',
            password: '',
            password_confirmation: '',
            recaptcha: '',
            agreed: false,
            register_token: token,
          }}
          validationSchema={SignUpSchema}
          onSubmit={onSubmit}
        >
          {({ errors, handleChange, handleBlur, setFieldValue, values, touched, isValid, dirty }) => (
            <Form>
              <FormGroup name="first_name" labelText="First Name">
                <Input
                  placeholder="Enter your name"
                  name="first_name"
                  type="text"
                  value={values.first_name || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={!!errors.first_name && touched.first_name}
                />
                {touched.first_name && errors.first_name &&
                  <span className="form__msg-error">{errors.first_name}</span>}
              </FormGroup>
              <FormGroup name="last_name" labelText="Last Name">
                <Input
                  placeholder="Enter your last name"
                  name="last_name"
                  type="text"
                  value={values.last_name || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={!!errors.last_name && touched.last_name}
                />
                {touched.last_name && errors.last_name && <span className="form__msg-error">{errors.last_name}</span>}
              </FormGroup>
              <FormGroup name="email" labelText="Email address">
                <Input
                  placeholder="example@email.com"
                  type="email"
                  name="email"
                  onChange={handleChange}
                  value={values.email || ''}
                  onBlur={handleBlur}
                  disabled
                  hasError={!!errors.email && touched.email}
                />
                {touched.email && errors.email && <span className="form__msg-error">{errors.email}</span>}
                {serverError.email && <span className="form__msg-error">{serverError.email}</span>}
              </FormGroup>
              <FormGroup name="phone" labelText="Phone number">
                <Input
                  placeholder="(XXX) XXX-XXXX"
                  name="phone"
                  type="text"
                  onChange={(e) => {
                    setFieldValue(e.target.name, phoneValidation(e.target.value));
                  }}
                  value={values.phone || ''}
                  onBlur={handleBlur}
                  hasError={!!errors.phone && touched.phone}
                />
                {touched.phone && errors.phone && <span className="form__msg-error">{errors.phone}</span>}
                {serverError.phone && <span className="form__msg-error">{serverError.phone}</span>}
              </FormGroup>
              <FormGroup name="password" labelText="Create password">
                <Input
                  name="password"
                  placeholder="Password"
                  type="password"
                  onChange={handleChange}
                  value={values.password || ''}
                  onBlur={handleBlur}
                  hasError={!!errors.password && touched.password}
                />
                {touched.password && errors.password && <span className="form__msg-error">{errors.password}</span>}
              </FormGroup>
              <FormGroup name="password_confirmation" labelText="Confirm Password">
                <Input
                  name="password_confirmation"
                  placeholder="Confirm Password"
                  type="password"
                  onChange={handleChange}
                  value={values.password_confirmation || ''}
                  onBlur={handleBlur}
                  hasError={!!errors.password_confirmation && touched.password_confirmation}
                />
                {touched.password_confirmation && errors.password_confirmation &&
                  <span className="form__msg-error">{errors.password_confirmation}</span>}
              </FormGroup>
              <PasswordStrength password={values.password} title="Password should consist of:" />
              <FormGroup name="agreed">
                <FormControlLabel
                  className="MuiFormControlLabel-root--small"
                  control={
                    <Checkbox checked={values.agreed || false}
                              onChange={handleChange}
                              name="agreed" />
                  }
                  label={<>I agree with <Link to="/terms">Terms and Conditions</Link></>}
                />
              </FormGroup>
              <FormGroup name="recaptcha">
                <Suspense fallback={<p>Loading...</p>}>
                  <ReCAPTCHA
                    sitekey={RECAPTCHA_SITE_KEY}
                    onChange={value => setFieldValue('recaptcha', value)}
                  />
                  {touched.recaptcha && errors.recaptcha && <span className="form__msg-error">{errors.recaptcha}</span>}
                </Suspense>
              </FormGroup>
              <div className="form__button">
                <Button variant="contained" size="large" color="primary" type="submit"
                        disabled={!(isValid && dirty) || sending}>
                  Sign up
                  {sending && <span className="spinner" />}
                </Button>
              </div>
              <div className="auth__form-info">
                Already have an account?
                <Link to="/login" className="auth__form-info-link">Sign in here <ChevronRightIcon /></Link>
              </div>
            </Form>
          )}
        </Formik>
      </AuthContent>
      <Modal
        open={isOpen}
        small
        button="Ok"
        close={handleModalClose}
        onClick={handleModalClose}
        children={<p>Thank you for signing up. You can now sign in.</p>}
      />
    </>
  );
};

export default SignUpMember;
