import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../../components/Layout/Layout'
import LayoutForm from '../../../components/Layout/LayoutForm'
import { BackButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import { Button } from '@mui/material'
import { fetchDebtCredit, postDebtCredit } from '../../../redux/slices/worthSlice'
import { FormGroup, InputCurrency } from '../../../components/shared/Form'
import Modal from '../../../components/shared/Modal/Modal'
import ProgressCircle from '../../../components/shared/ProgressBar/ProgressCircle'
import { setIsOpen } from '../../../redux/slices/modalSlice'
import { worthDebtCreditSchema } from '../../../utils/validationFinances'

const WorthLiabilitiesDebtCredit = ({ props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.user.user);
  const { debtCredit } = useSelector(state => state.worth);
  const isOpen = useSelector(state => state.modal.isOpen);
  const sending = useSelector(state => state.sending.isSending);

  useEffect(() => {
    async function fetch() {
      dispatch(fetchDebtCredit())
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const onSubmit = (values) => {
    dispatch(postDebtCredit(values, props))
  }

  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }))
  }

  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }));
    history.push('/worth/liabilities')
  }

  return (
    <Layout>
      <LayoutForm small>
        <BackButton title="Back" />
        <PageTitle
          title="Debt & credit balances"
        />
        <div className="layout-form__content-holder">
          <Formik
            enableReinitialize
            initialValues={debtCredit && {...debtCredit}}
            validationSchema={worthDebtCreditSchema}
            onSubmit={onSubmit}
          >
            {({ errors, handleChange, handleBlur, values }) => (
              <Form>
                <FormGroup name="mortgage_balance" labelText="Mortgage balance ($)">
                  <InputCurrency
                    placeholder="Type mortgage balance"
                    type="text"
                    name="mortgage_balance"
                    onChange={handleChange}
                    value={values.mortgage_balance || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.mortgage_balance}
                  />
                  {errors.mortgage_balance && <span className="form__msg-error">{errors.mortgage_balance}</span>}
                </FormGroup>
                <FormGroup name="equity_credit_balance" labelText="Equity line of credit balance ($)">
                  <InputCurrency
                    placeholder="Type equity line of credit balance"
                    type="text"
                    name="equity_credit_balance"
                    onChange={handleChange}
                    value={values.equity_credit_balance || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.equity_credit_balance}
                  />
                  {errors.equity_credit_balance && <span className="form__msg-error">{errors.equity_credit_balance}</span>}
                </FormGroup>
                <FormGroup name="credit_balance" labelText="Credit card balance ($)">
                  <InputCurrency
                    placeholder="Type credit card balance"
                    type="text"
                    name="credit_balance"
                    onChange={handleChange}
                    value={values.credit_balance || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.credit_balance}
                  />
                  {errors.credit_balance && <span className="form__msg-error">{errors.credit_balance}</span>}
                </FormGroup>
                <FormGroup name="overdue_balance" labelText="Overdue credit cards ($)">
                  <InputCurrency
                    placeholder="Type overdue credit cards"
                    type="text"
                    name="overdue_balance"
                    onChange={handleChange}
                    value={values.overdue_balance || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.overdue_balance}
                  />
                  {errors.overdue_balance && <span className="form__msg-error">{errors.overdue_balance}</span>}
                </FormGroup>
                <div className="form__button form__button--center">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Save
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <Modal
          open={isOpen}
          small
          button="Done"
          onClick={handleClick}
          close={onClose}
        >
          <h3>Good Job!</h3>
          <p>Your progress increased 100%</p>
          <ProgressCircle amount={100} small />
        </Modal>
      </LayoutForm>
    </Layout>
  );
};

export default WorthLiabilitiesDebtCredit;
