const optionsHous = [
  { id: 1, key: 1, value: 0, label: "12 AM" },
  { id: 2, key: 2, value: 1, label: "1 AM" },
  { id: 3, key: 3, value: 2, label: "2 AM" },
  { id: 4, key: 4, value: 3, label: "3 AM" },
  { id: 5, key: 5, value: 4, label: "4 AM" },
  { id: 6, key: 6, value: 5, label: "5 AM" },
  { id: 7, key: 7, value: 6, label: "6 AM" },
  { id: 8, key: 8, value: 7, label: "7 AM" },
  { id: 9, key: 9, value: 8, label: "8 AM" },
  { id: 10, key: 10, value: 9, label: "9 AM" },
  { id: 11, key: 11, value: 10, label: "10 AM" },
  { id: 12, key: 12, value: 11, label: "11 AM" },
  { id: 13, key: 13, value: 12, label: "12 PM" },
  { id: 14, key: 14, value: 13, label: "1 PM" },
  { id: 15, key: 15, value: 14, label: "2 PM" },
  { id: 16, key: 16, value: 15, label: "3 PM" },
  { id: 17, key: 17, value: 16, label: "4 PM" },
  { id: 18, key: 18, value: 17, label: "5 PM" },
  { id: 19, key: 19, value: 18, label: "6 PM" },
  { id: 20, key: 20, value: 19, label: "7 PM" },
  { id: 21, key: 21, value: 20, label: "8 PM" },
  { id: 22, key: 22, value: 21, label: "9 PM" },
  { id: 23, key: 23, value: 22, label: "10 PM" },
  { id: 24, key: 24, value: 23, label: "11 PM" },
]

export default optionsHous;