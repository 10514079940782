import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import { Button } from '@mui/material'
import { BackButton } from '../../../components/shared/Buttons'
import Layout from '../../../components/Layout/Layout'
import PageTitle from '../../../components/PageTitle/PageTitle'
import LayoutForm from '../../../components/Layout/LayoutForm'
import FormSelect from '../../../components/shared/Form/Select'
import Priority from '../../../components/shared/Priority/Priority'
import Input from '../../../components/shared/Form/Input'
import FormGroup from '../../../components/shared/Form/FormGroup'
import { collectSchema } from '../../../utils/validation'
import { fetchGoals, postGoals } from '../../../redux/slices/userSlice'
import { fetchValuesData } from '../../../redux/slices/optionsSlice'
import Modal from '../../../components/shared/Modal/Modal'
import ProgressCircle from '../../../components/shared/ProgressBar/ProgressCircle'
import {setIsOpen} from '../../../redux/slices/modalSlice'

const options = [
  {
    key: 1,
    value: false,
    label: 'No'
  },
  {
    key: 2,
    value: true,
    label: 'Yes'
  }
]

const titleUser = {
  title: 'Your goals',
  content: 'Goals are objectives you realistically hope to accomplish, that are within your means,if you plan right.',
  info:
    <>
      <h6>Your goals</h6>
      <Priority option="Medium" />
      <div>ValueCircle categorizes your value system into 6 distinct categories: security, obligation, power/prestige, happiness, health, and making a difference.  Fill in the Current Ranking column with the relative importance of each of these 6 values to you in your current lifestyle.  Then fill out the Preferred Ranking column with what you wish that ranking would be. Finally give an example or two of what you think that value means in terms of some of your financial or life objectives.</div>
    </>
}

const titleSpouse = {
  title: 'Spouses’s Goals',
  content: 'Goals are objectives you realistically hope to accomplish, that are within your means,if you plan right.',
  info:
    <>
      <h6>Spouse Goals</h6>
      <Priority option="Medium" />
      <div>Goals are objectives your spouse realistically hopes to accomplish, that are within your spouse\'s means, if he/she plans right.  Fill in the entries with your spouse\'s top 5 goals and associate each   to its corresponding value and indicate whether it requires money (above $5000).  In general, goals that require a financial commitment should be in your spouse's list   of Objectives in the Objectives task.</div>
    </>
}

const Goals = ({ props, isSpouse }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.user.user);
  const { goals } = useSelector(state => state.user);
  const goalsSpouse = useSelector(state => state.user.spouse.goals);
  const listValues = useSelector(state => state.options.values);
  const sending = useSelector(state => state.sending.isSending);
  const isOpen = useSelector(state => state.modal.isOpen);

  useEffect(() => {
    async function fetchOptions() {
      await dispatch(fetchValuesData());
    }

    async function fetch() {
      await dispatch(fetchGoals());
    }

    async function fetchSpouse() {
      await dispatch(fetchGoals(isSpouse));
    }

    if (id) {
      fetchOptions()

      if (isSpouse) {
        fetchSpouse()
      }

      fetch()
    }

    // eslint-disable-next-line
  }, [id])


  const onClose = () => {
    dispatch(setIsOpen({ isOpen: false }))
  }

  const handleClick = () => {
    dispatch(setIsOpen({ isOpen: false }));
    history.push('/values')
  }

  return (
    <Layout>
      <LayoutForm>
        <BackButton title="Back" />
        <PageTitle
          title={isSpouse ? titleSpouse.title : titleUser.title}
          content={isSpouse ? titleSpouse.content : titleUser.content}
          info={isSpouse ? titleSpouse.info : titleUser.info}
        />
        <div className="layout-form__content-holder container container--large">
          <Formik
            enableReinitialize
            validationSchema={collectSchema}
            initialValues={{
              collect: isSpouse
                ? [...goalsSpouse, ...Array(5)].slice(0,5)
                : [...goals, ...Array(5)].slice(0,5),
            }}
            onSubmit={(values) => dispatch(postGoals(values.collect, props, isSpouse))}
          >
            {({ errors, handleChange, handleBlur, values, touched, setFieldValue }) => (
              <Form>
                <div className="values__table values__table--goals">
                  <div className="values__table-head">
                    <div>Goals</div>
                    <div>Associated Values</div>
                    <div>Requires Money (Over $5000)?</div>
                  </div>
                  {values.collect && values.collect.map((item, i) => (
                    <div className="values__table-row" key={i}>
                      <div>
                        <FormGroup name={`collect[${i}].name`} labelText="Goals" hideLabel>
                          <Input
                            smallHeight
                            placeholder="Goals"
                            type="text"
                            name={`collect[${i}].name`}
                            onChange={handleChange}
                            value={item && item.name ? item.name : ''}
                            onBlur={handleBlur}
                          />
                        </FormGroup>
                      </div>
                      <div>
                        <FormGroup name={`collect[${i}].value_id`} labelText="Associated Values" hideLabel>
                          <FormSelect
                            small
                            options={[
                              {
                                key: 10,
                                value: '',
                                label: 'None'
                              },
                              ...listValues
                            ]}
                            value={item && item.value_id ? item.value_id : ''}
                            name={`collect[${i}].value_id`}
                            onChange={(e) => {
                              setFieldValue(e.target.name, e.target.value)
                            }}
                            placeholder="Select"
                          />
                        </FormGroup>
                      </div>
                      <div>
                        <FormGroup name={`collect[${i}].requires_money`} labelText="Requires Money (Over $5000)?" hideLabel>
                          <FormSelect
                            small
                            style={{'width': '95px'}}
                            options={options}
                            value={item && item.requires_money ? item.requires_money : false}
                            name={`collect[${i}].requires_money`}
                            onChange={(e) => {
                              setFieldValue(e.target.name, e.target.value)
                            }}
                            placeholder="Select"
                          />
                        </FormGroup>

                        {errors && errors.collect && errors.collect[i] && <div className="form__msg-error">All fields must be filled</div>}
                      </div>
                    </div>
                  ))}

                  <div className="form__button form__button--center">
                    <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                      Save
                      {sending && <span className="spinner" />}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <Modal
          open={isOpen}
          small
          button="Done"
          onClick={handleClick}
          close={onClose}
        >
          <h3>Good Job!</h3>
          <p>Your progress increased 100%</p>
          <ProgressCircle amount={100} small />
        </Modal>
      </LayoutForm>
    </Layout>
  );
};

export default Goals;
