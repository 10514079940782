import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'classnames'
import CancellationPlanModal from '../../components/CancellationPlanModal/CancellationPlanModal'
import Layout from '../../components/Layout/Layout'
import { BackButton } from '../../components/shared/Buttons'
import TreeImage from '../../assets/images/tree/Tree-trunk.png'
import TreeItem from '../../components/Tree/TreeItem'
import TreeSubItem from '../../components/Tree/TreeSubItem'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserFamilyAssets, fetchUserFamilyChildren, fetchUserFamilyData, fetchUserDetails } from '../../redux/slices/userSlice'
import {
  fetchData,
  fetchFutureChildrenData
} from "../../redux/slices/childrenSlice";
import { fetchTotalPercent } from '../../redux/slices/percentagesSlice'
import {fetchAnalysisTasks} from '../../redux/slices/taskSlice'
import { getPercent } from '../../utils/utils'
import { fetchTourStatus } from '../../redux/slices/additionalSlice'

const steps = [
  {
    target: '.step-tree',
    content: <>
      <h6>Zen tree</h6>
      This is your ValueSoft financial planning Zen tree.  It all happens here.  You input your information, your tree grows. Your nutrition to financial health!
    </>,
    disableBeacon: true,
    placement: 'center'
  },
  {
    target: '.step-tree-info .tree__label',
    content: <>
      <h6>More info</h6>
      Roll over a branch to see the hidden steps inside.  The whole of these branches are your financial planning tree.  These are all important aspects of your life.
    </>,
    placement: 'left'
  },
  {
    target: '.step-tree-details',
    content: <>
      <h6>Details about you</h6>
      Input your financial information in each of these branch sections.  You'll see your progress underneath each branch area.
    </>,
  },
  {
    target: '.step-tree-help',
    content: <>
      <h6>We help you</h6>
      The more we learn about you, the more action items we will recommend if we see an opportunity to improve your financial health.  This acorn is the fruit of your input labor!
    </>,
  },
]

const stepsMobile = [
  {
    target: '.step-tree',
    content: <>
      <h6>Zen tree</h6>
      This is your ValueSoft financial planning Zen tree.  It all happens here. You input your information, your tree grows. Your nutrition to financial health!
    </>,
    disableBeacon: true,
    placement: 'center'
  },
  // {
  //   target: '.step-tree-info .tree__label',
  //   content: <>
  //     <h6>More info</h6>
  //     Roll over a branch to see the hidden steps inside.  The whole of these branches are your financial planning tree.  These are all important aspects of your life.
  //   </>,
  // },
  {
    target: '.step-tree-info .tree-item__icon',
    content: <>
      <h6>Details about you</h6>
      Input your financial information in each of these branch sections.  You'll see your progress underneath each branch area.
    </>,
    placement: 'left-start'
  },
  {
    target: '.step-tree-help',
    content: <>
      <h6>We help you</h6>
      The more we learn about you, the more action items we will recommend if we see an opportunity to improve your financial health.  This acorn is the fruit of your input labor!
    </>,
  },
]

const MainTree = ({ props }) => {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { id } = useSelector((state) => state.user.user);
  const { total_children, want_more_children } = useSelector(
    (state) => state.user.family.children
  );
  const { own_other_assets, plan_to_purchase, own_or_rent_home, stakes_in_companies } = useSelector(
    (state) => state.user.family.assets
  );
  const {
    leave_bequests,
    factor_in_a_period,
    significant_income,
    employment_sabbaticals,
    paying_education,
    feature_paying_education
  } = useSelector((state) => state.user.family.others);
  const percents = useSelector((state) => state.percentages.percents);
  const loading = useSelector((state) => state.sending.isSending);
  const analysis = useSelector((state) => state.task.analysis);
  const isBasic = useSelector((state) => state.user.user.plan_type === 1);
  const isAuto = useSelector((state) => state.user.profile.details.is_auto);
  const futureChildrenList = useSelector((state) => state.children.future);
  const { playTour, tours } = useSelector((state) => state.additional);
  const totalPercent = useSelector(state => state.percentages.total);
  const [tourDetails, setTourDetails] = useState(false);
  const mobileView = 999;

  const handleResize = () => {
    mobileView >= window.innerWidth ? setIsMobile(true) : setIsMobile(false);
  };

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    async function fetch() {
      Promise.all([
        dispatch(fetchUserDetails()),
        dispatch(fetchTourStatus()),
        dispatch(fetchAnalysisTasks()),
        //dispatch(fetchPercentages(props)),
        dispatch(fetchUserFamilyChildren(id)),
        dispatch(fetchUserFamilyAssets(id)),
        dispatch(fetchUserFamilyData(id)),
        dispatch(fetchData()),
        dispatch(fetchFutureChildrenData()),
        dispatch(fetchTotalPercent(isBasic))
      ]);
    }
    if (id) {
      fetch();
    }
    // eslint-disable-next-line
  }, [id]);

  const profile = getPercent(percents, "profile");
  const values = getPercent(percents, "values");
  const risk = getPercent(percents, "risk");
  const companies = getPercent(percents, "companies");
  const children = getPercent(percents, "children");
  const education = getPercent(percents, "education");
  const totalAbout =
    (profile +
      values +
      (stakes_in_companies && companies) +
      (!isBasic && risk) +
      (!!paying_education && education) +
      (!!total_children && children)) /
    ((isBasic ? 2 : 3) + (total_children ? 1 : 0) + (paying_education ? 1 : 0) + (stakes_in_companies ? 1 : 0));

  const bequeath = getPercent(percents, "bequeath");
  const unemployment = getPercent(percents, "unemployment");
  const estate = getPercent(percents, "estate");
  const disability = getPercent(percents, "disability");
  const life = getPercent(percents, "life");

  const homeInsurance = getPercent(percents, "home-insurance");
  const healthInsurance = getPercent(percents, "health-insurance");
  const autoInsurance = getPercent(percents, "auto-insurance");
  const umbrellaInsurance = getPercent(percents, "umbrella-insurance");
  const insurance =
    (homeInsurance +
      healthInsurance +
      (isAuto && autoInsurance) +
      umbrellaInsurance) /
    (isAuto ? 4 : 3);

  const totalProtection =
    ((!isBasic && leave_bequests && bequeath) +
      (!isBasic && factor_in_a_period && unemployment) +
      (!isBasic && estate) +
      (!isBasic && disability) +
      insurance +
      life) /
    (isBasic
      ? 2
      : 4 +
        (!isBasic && factor_in_a_period && 1) +
        (!isBasic && leave_bequests && 1));

  const incremental = getPercent(percents, "incremental");
  const assets = getPercent(percents, "assets");
  const worth = getPercent(percents, "worth");
  const expense = getPercent(percents, "expense");
  const income = getPercent(percents, "income");
  const totalFinance =
    ((!isBasic && significant_income && incremental) +
      ((own_or_rent_home === "own" || own_other_assets) && assets) +
      worth +
      expense +
      income) /
    (3 +
      (!isBasic && significant_income && 1) +
      ((own_or_rent_home === "own" || own_other_assets) && 1));

  const sabbatical = getPercent(percents, "sabbatical");
  const futureEducation = getPercent(percents, "future-education");
  const futureChildren = getPercent(percents, "future-children");
  const objectives = getPercent(percents, "objectives");
  const retirement = getPercent(percents, "retirement");
  const totalFuture =
    ((!isBasic && employment_sabbaticals && sabbatical) +
      (!isBasic && want_more_children && futureChildren) +
      (!isBasic &&
        futureChildrenList?.length > 0 &&
        feature_paying_education &&
        futureEducation) +
      (!isBasic && plan_to_purchase && objectives) +
      retirement) /
    (1 +
      (!isBasic && want_more_children && 1) +
      (!isBasic &&
        feature_paying_education &&
        futureChildrenList?.length > 0 &&
        1) +
      (!isBasic && employment_sabbaticals && 1) +
      (!isBasic && plan_to_purchase && 1));

  return (
    <Layout
      totalPercent={totalPercent}
      tree
      steps={isMobile ? stepsMobile : steps}
      customCloseTour={() => setTourDetails(false)}
      tourStatus={tours && !tours.tree}
      tourParam="tree"
      titleTour="Take a Tour of Input Section"
      handlers={[
        {
          step: 0,
          func: () => setTourDetails(false),
        },
        {
          step: 1,
          func: () => setTourDetails(true),
        },
        {
          step: 2,
          func: () => setTourDetails(false),
        },
      ]}
    >
      <BackButton
        title="Back to Overview"
        path="/dashboard"
        className="back-button--position"
      />

      <div
        className={clsx("tree tree--scale step-tree", {
          "tree--active": isActive,
          "tree--tour": playTour,
          "tree--tour-details": playTour && tourDetails,
        })}
      >
        {!isMobile && (
          <div className="tree__base">
            <img src={TreeImage} alt="ValueSoft Tree" />
          </div>
        )}

        <TreeItem
          value="protection"
          count={+totalProtection.toFixed() || "0"}
          title="Protection"
          setIsActive={setIsActive}
          isMobile={isMobile}
          path="/protection"
          isLoading={loading}
          isDisabled={!!(percents && getPercent(percents, "profile") === 0)}
        >
          <TreeSubItem
            count={bequeath}
            value="bequeath"
            title="Bequeath"
            path="/bequeath"
            isBasic={isBasic}
            isDisabled={isBasic || !leave_bequests}
            isLoading={loading}
            redirect="/profile-summary/about-your-family/others"
            modalContent={
              <p>
                You need to add the relevant information in the{" "}
                <strong className="text-success">About You</strong> page in
                order to activate this section.{" "}
              </p>
            }
          />
          <TreeSubItem
            count={unemployment}
            value="unemployment"
            title="Unemployment"
            path="/unemployment"
            isBasic={isBasic}
            isDisabled={isBasic || !factor_in_a_period}
            isLoading={loading}
            redirect="/profile-summary/about-your-family/others"
            modalContent={
              <p>
                You need to add the relevant information in the{" "}
                <strong className="text-success">About You</strong> page in
                order to activate this section.{" "}
              </p>
            }
          />
          <TreeSubItem
            count={estate || "0"}
            value="estate"
            title="Estate"
            path="/estate"
            isBasic={isBasic}
            isDisabled={isBasic}
            isLoading={loading}
          />
          <TreeSubItem
            count={disability || "0"}
            value="disability"
            title="Disability"
            path="/disability"
            isBasic={isBasic}
            isDisabled={isBasic}
            isLoading={loading}
          />
          <TreeSubItem
            count={insurance.toFixed() || "0"}
            value="insurance"
            title="Insurance"
            path="/insurance"
            isLoading={loading}
          />
          <TreeSubItem
            count={life || "0"}
            value="life"
            title="Life"
            path="/life"
            isLoading={loading}
          />
        </TreeItem>
        <TreeItem
          value="finance"
          count={+totalFinance.toFixed() || "0"}
          title="Finance"
          setIsActive={setIsActive}
          isMobile={isMobile}
          isLoading={loading}
          path="/finance"
          isDisabled={!!(percents && getPercent(percents, "profile") === 0)}
        >
          <TreeSubItem
            count={incremental || "0"}
            value="incremental-income"
            title="Incremental"
            path="/incremental-income"
            isBasic={isBasic}
            isDisabled={isBasic || !significant_income}
            isLoading={loading}
            redirect="/profile-summary/about-your-family/others"
            modalContent={
              <p>
                You need to add the relevant information in the{" "}
                <strong className="text-success">About You</strong> page in
                order to activate this section.{" "}
              </p>
            }
          />
          <TreeSubItem
            count={assets || "0"}
            value="assets"
            title="Assets"
            path="/assets"
            isDisabled={own_or_rent_home === "rent" && !own_other_assets}
            isLoading={loading}
            redirect="/profile-summary/about-your-family/assets"
            modalContent={
              <p>
                You need to add the relevant information in the{" "}
                <strong className="text-success">About You</strong> page in
                order to activate this section.{" "}
              </p>
            }
          />
          <TreeSubItem
            count={worth || "0"}
            value="worth"
            title="Worth"
            path="/worth"
            isLoading={loading}
          />
          <TreeSubItem
            count={expense || "0"}
            value="expenses"
            title="Expenses"
            path="/expenses"
            isLoading={loading}
          />
          <TreeSubItem
            count={income || "0"}
            value="income"
            title="Income"
            path="/income"
            isLoading={loading}
          />
        </TreeItem>
        <TreeItem
          value="future"
          count={+totalFuture.toFixed() || "0"}
          title="Future"
          setIsActive={setIsActive}
          isMobile={isMobile}
          isLoading={loading}
          path="/future"
          isDisabled={!!(percents && getPercent(percents, "profile") === 0)}
        >
          <TreeSubItem
            count={sabbatical || "0"}
            value="sabbatical"
            title="Sabbatical"
            path="/sabbatical"
            isBasic={isBasic}
            isDisabled={isBasic || !employment_sabbaticals}
            isLoading={loading}
            redirect="/profile-summary/about-your-family/others"
            modalContent={
              <p>
                You need to add the relevant information in the{" "}
                <strong className="text-success">About You</strong> page in
                order to activate this section.{" "}
              </p>
            }
          />
          <TreeSubItem
            count={futureEducation || "0"}
            value="future-education"
            title="Future education"
            path="/future-education"
            isBasic={isBasic}
            isDisabled={
              isBasic ||
              futureChildrenList?.length === 0 ||
              !feature_paying_education
            }
            isLoading={loading}
            redirect={
              !want_more_children
                ? "/profile-summary/about-your-family/children"
                : !feature_paying_education
                ? "/profile-summary/about-your-family/others"
                : "/future-children"
            }
            modalContent={
              <p>
                You need to add the relevant information in the{" "}
                <strong className="text-success">
                  {!want_more_children || !feature_paying_education
                    ? "About You"
                    : "Future children"}
                </strong>{" "}
                page in order to activate this section.
              </p>
            }
          />
          <TreeSubItem
            count={+futureChildren || "0"}
            value="future-children"
            title="Future children"
            path="/future-children"
            isBasic={isBasic}
            isDisabled={isBasic || !want_more_children}
            isLoading={loading}
            redirect="/profile-summary/about-your-family/children"
            modalContent={
              <p>
                You need to add the relevant information in the{" "}
                <strong className="text-success">About You</strong> page in
                order to activate this section.{" "}
              </p>
            }
          />
          <TreeSubItem
            count={objectives || "0"}
            value="objectives"
            title="Objectives"
            path="/objectives"
            isBasic={isBasic}
            isDisabled={isBasic || !plan_to_purchase}
            isLoading={loading}
            redirect="/profile-summary/about-your-family/assets"
            modalContent={
              <p>
                You need to add the relevant information in the{" "}
                <strong className="text-success">About You</strong> page in
                order to activate this section.{" "}
              </p>
            }
          />
          <TreeSubItem
            count={retirement || "0"}
            isLoading={loading}
            value="retirement"
            title="Retirement"
            path="/retirement"
          />
        </TreeItem>
        <TreeItem
          value="about"
          count={+totalAbout.toFixed() || "0"}
          title="About me"
          setIsActive={setIsActive}
          isMobile={isMobile}
          isLoading={loading}
          path="/about-me"
          className="step-tree-info"
        >
          <TreeSubItem
            count={risk || "0"}
            value="risk"
            title="Risk"
            path="/risk"
            isBasic={isBasic}
            isLoading={loading}
            isDisabled={
              isBasic || !!(percents && getPercent(percents, "profile") === 0)
            }
            isBlocked={
              isBasic || !!(percents && getPercent(percents, "profile") === 0)
            }
            redirect="/profile-summary"
            modalContent={
              <p>
                You need to add the relevant information in the{" "}
                <strong className="text-success">About You</strong> page in
                order to activate this section.{" "}
              </p>
            }
          />
          <TreeSubItem
            count={companies || "0"}
            value="companies"
            title="Companies"
            path="/companies"
            isLoading={loading}
            isDisabled={!stakes_in_companies}
            isBlocked={!!(percents && getPercent(percents, "profile") === 0)}
            redirect="/profile-summary/about-your-family"
            modalContent={
              <p>
                You need to add the relevant information in the{" "}
                <strong className="text-success">About You</strong> page in
                order to activate this section.{" "}
              </p>
            }
          />
          <TreeSubItem
            count={education || "0"}
            value="education"
            title="Education"
            path="/education"
            isLoading={loading}
            isDisabled={!paying_education}
            isBlocked={!!(percents && getPercent(percents, "profile") === 0)}
            redirect="/profile-summary/about-your-family/others"
            modalContent={
              <p>
                You need to add the relevant information in the{" "}
                <strong className="text-success">About You</strong> page in
                order to activate this section.{" "}
              </p>
            }
          />
          <TreeSubItem
            count={values || "0"}
            value="values"
            title="Values"
            path="/values"
            isLoading={loading}
            className="step-tree-details"
            isDisabled={!!(percents && getPercent(percents, "profile") === 0)}
            isBlocked={!!(percents && getPercent(percents, "profile") === 0)}
            redirect="/profile-summary"
            modalContent={
              <p>
                You need to add the relevant information in the{" "}
                <strong className="text-success">About You</strong> page in
                order to activate this section.{" "}
              </p>
            }
          />
          <TreeSubItem
            count={children || "0"}
            value="children"
            title="Dependents"
            path="/dependents"
            isLoading={loading}
            isDisabled={!total_children}
            isBlocked={!!(percents && getPercent(percents, "profile") === 0)}
            redirect="/profile-summary/about-your-family/children"
            modalContent={
              <p>
                You need to add the relevant information in the{" "}
                <strong className="text-success">About You</strong> page in
                order to activate this section.{" "}
              </p>
            }
          />
          <TreeSubItem
            count={profile || "0"}
            value="profile"
            title="Profile"
            path="/profile-summary"
            isLoading={loading}
          />
        </TreeItem>
        <div className="tree__task">
          <Link to="/tasks" className="step-tree-help">
            {analysis && analysis.openCount ? analysis.openCount : "0"}
          </Link>
        </div>
      </div>
      <CancellationPlanModal />
    </Layout>
  );
};

export default MainTree;
