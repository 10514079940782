import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Layout from '../../../components/Layout/Layout'
import LayoutForm from '../../../components/Layout/LayoutForm'
import { BackButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import { Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserFamilyData, fetchUserFamilyAssets, fetchUserFamilyChildren } from '../../../redux/slices/userSlice'
import { capitalize, getPercent } from '../../../utils/utils'
import { fetchTotalPercent } from '../../../redux/slices/percentagesSlice'


const AboutYourFamily = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useSelector(state => state.user.user);
  const { assets, others, children } = useSelector(state => state.user.family);
  const percents = useSelector(state => state.percentages.percents);
  const isBasic = useSelector(state => state.user.user.plan_type === 1)

  useEffect(() => {
    async function fetch() {
      Promise.all([
        dispatch(fetchTotalPercent(isBasic)),
        dispatch(fetchUserFamilyChildren(id)),
        dispatch(fetchUserFamilyAssets(id)),
        dispatch(fetchUserFamilyData(id)),
      ])
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const percent = getPercent(percents, 'about-your-family')

  return (
    <Layout>
      <LayoutForm>
        <BackButton title="Back to Profile Summary" path="/profile-summary" />
        <PageTitle
          title="About your family"
          content="Info about your family"
          status={percent || "0"}
        />
        <div className="layout-form__content-holder container container--large">
          <div className="layout-form__grid layout-form__grid--flex">
            <div>
              <div className="box">
                <h5>Children and/or Dependents</h5>
                <div className="box__row">
                  <div>Total number of children and/or dependents</div>
                  <div className="box__row-option">
                    {children && children.total_children}
                  </div>
                </div>
                <div className="box__row">
                  <div>Number of children from prior marriage(s):</div>
                  <div className="box__row-option">
                    {children && children.prior_children}
                  </div>
                </div>
                <div className="box__row">
                  <div>Are you planning to have any more children?</div>
                  <div className="box__row-option">
                    {children && children.want_more_children ? "Yes" : "No"}
                  </div>
                </div>
                <div className="box__row">
                  <div>
                    Would you like to financially support your children and/or
                    dependents after you retire?
                  </div>
                  <div className="box__row-option">
                    {children && children.support_children_after_retire
                      ? "Yes"
                      : "No"}
                  </div>
                </div>
                <div className="box__button">
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    onClick={() =>
                      history.push(
                        "/profile-summary/about-your-family/children"
                      )
                    }
                  >
                    Update
                  </Button>
                </div>
              </div>
            </div>
            <div>
              <div className="box">
                <h5>Assets</h5>
                <div className="box__row">
                  <div>Do you own or rent a home?</div>
                  <div className="box__row-option">
                    {assets && capitalize(assets.own_or_rent_home)}
                  </div>
                </div>
                <div className="box__row">
                  <div>
                    Do you own any other fixed assets or have any other
                    outstanding loans?
                  </div>
                  <div className="box__row-option">
                    {assets && assets.own_other_assets ? "Yes" : "No"}
                  </div>
                </div>
                <div className="box__row">
                  <div>
                    Are you planning to purchase any additional fixed assets
                    (e.g. property, car, boat)?
                  </div>
                  <div className="box__row-option">
                    {assets && assets.plan_to_purchase ? "Yes" : "No"}
                  </div>
                </div>
                <div className="box__row">
                  <div>
                    Do you, your spouse or family member own a significant stake in one or more companies?
                  </div>
                  <div className="box__row-option">
                    {assets && assets.stakes_in_companies ? "Yes" : "No"}
                  </div>
                </div>
                <div className="box__button">
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    onClick={() =>
                      history.push("/profile-summary/about-your-family/assets")
                    }
                  >
                    Update
                  </Button>
                </div>
              </div>
            </div>
            <div>
              <div className="box">
                <h5>Other Information</h5>
                <div className="box__holder">
                  <div>
                    <div className="box__row">
                      <div>
                        Are you currently paying or planning to pay for anyone’s current or future education (including your own)?
                      </div>
                      <div className="box__row-option">
                        {others && others.paying_education ? "Yes" : "No"}
                      </div>
                    </div>
                    <div className="box__row">
                      <div>
                        For any future dependents, do you plan to pay for any of
                        their education?
                      </div>
                      <div className="box__row-option">
                        {others && others.feature_paying_education
                          ? "Yes"
                          : "No"}
                      </div>
                    </div>
                    <div className="box__row">
                      <div>
                        Are you or your spouse planning any employment
                        sabbaticals prior to retirement?
                      </div>
                      <div className="box__row-option">
                        {others && others.employment_sabbaticals ? "Yes" : "No"}
                      </div>
                    </div>
                    <div className="box__row">
                      <div>
                        Would you like to factor in a period of unemployment for
                        you or your spouse as part of your financial plan?
                      </div>
                      <div className="box__row-option">
                        {others && others.factor_in_a_period ? "Yes" : "No"}
                      </div>
                    </div>
                    <div className="box__row">
                      <div>
                        Are you expecting to receive any significant incremental
                        income or expecting to spend money on any significant
                        incremental expenses?
                      </div>
                      <div className="box__row-option">
                        {others && others.significant_income ? "Yes" : "No"}
                      </div>
                    </div>
                    <div className="box__row">
                      <div>
                        Miles traveled by car per day by longest commuter in
                        your household:
                      </div>
                      <div className="box__row-option">
                        {others && others.miles_travelled_per_day}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="box__row">
                      <div>
                        Are you planning to leave any bequests (to children,
                        charity, etc.) when you and your spouse pass away?{" "}
                      </div>
                      <div className="box__row-option">
                        {others && others.leave_bequests ? "Yes" : "No"}
                      </div>
                    </div>
                    <div className="box__row">
                      <div>
                        Has anyone in your household been convicted of a crime,
                        felony or DUI?
                      </div>
                      <div className="box__row-option">
                        {others && others.anyone_convicted ? "Yes" : "No"}
                      </div>
                    </div>
                    <div className="box__row">
                      <div>
                        Does anyone in your household have a high-risk
                        occupation where they can accidentally cause serious
                        injury or death to another person?
                      </div>
                      <div className="box__row-option">
                        {others && others.high_risk_occupation ? "Yes" : "No"}
                      </div>
                    </div>
                    <div className="box__row">
                      <div>
                        Do you use an online budget tracking tool like mint.com?
                      </div>
                      <div className="box__row-option">
                        {others && others.use_online_budget_tracking
                          ? "Yes"
                          : "No"}
                      </div>
                    </div>
                    <div className="box__row">
                      <div>
                        Do you want to use an online document management and
                        storage tool like dropbox.com to store digital copies of
                        your important documents, such as your insurance
                        policies, copies of your passports and social security
                        cards?
                      </div>
                      <div className="box__row-option">
                        {others && others.use_dropbox ? "Yes" : "No"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box__button">
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    onClick={() =>
                      history.push("/profile-summary/about-your-family/others")
                    }
                  >
                    Update
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutForm>
    </Layout>
  );
};

export default AboutYourFamily;
