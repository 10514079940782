const typeAssets = [
  {
    key: 'primary-home',
    label: 'Primary Home',
    value: 'primary-home',
  },
  {
    key: 'additional-property',
    label: 'Additional Property',
    value: 'additional-property',
  },
  {
    key: 'car',
    label: 'Car',
    value: 'car',
  },
  {
    key: 'boat',
    label: 'Boat',
    value: 'boat',
  },
  {
    key: 'loan',
    label: 'Loan',
    value: 'loan',
  },
  {
    key: 'jewelry-collection',
    label: 'Jewelry Collection',
    value: 'jewelry-collection',
  },
  {
    key: 'other',
    label: 'Other',
    value: 'other',
  }
]

export default typeAssets;