import { createSlice } from '@reduxjs/toolkit'
import { Options, API, Calculator } from '../../api/Api'
import { setIsLoading, setIsRelogin } from "./loadingSlice";
import { createDynamicOptions } from '../../utils/utils'

const optionsSlice = createSlice({
  name: 'options',
  initialState: {
    planning_types: [],
    plan_types: [],
    status: [],
    task_status: [],
    priorities: [],
    expenses: [],
    values: [],
    incomes: [],
    categories: [],
    catalog: [],
    calculators: []
  },
  reducers: {
    setPlanningData: (state, action) => {
      state.planning_types = action.payload.planning_types;
    },
    setPlanData: (state, action) => {
      state.plan_types = action.payload.plan_types;
    },
    setPrioritiesData: (state, action) => {
      state.priorities = action.payload;
    },
    setExpensesData: (state, action) => {
      state.expenses = action.payload.expenses;
    },
    setValuesData: (state, action) => {
      state.values = action.payload.values;
    },
    setIncomesData: (state, action) => {
      state.incomes = action.payload.incomes;
    },
    setTaskStatusData: (state, action) => {
      state.task_status = action.payload.task_status;
    },
    setStatusData: (state, action) => {
      state.status = action.payload.status;
    },
    setCategoriesData: (state, action) => {
      state.categories = action.payload.categories;
    },
    setCatalogData: (state, action) => {
      state.catalog = action.payload.catalog;
    },
    setCalculatorsData: (state, action) => {
      state.calculators = action.payload;
    },
  }
})

export const fetchPlanningTypesData = (params) => async (dispatch) => {
  try {
    // dispatch(setIsLoading({ isLoading: true }));

    let token = localStorage.token;
    API.defaults.headers.Authorization = `Bearer ${token}`;

    const response = await Options.fetchPlanningTypes(params);
    const options = await createDynamicOptions(response.data.data, true);
    await dispatch(setPlanningData({ planning_types: options }));

    dispatch(setIsLoading({ isLoading: false }));
  } catch (e) {
    console.error(e);
    dispatch(setIsLoading({ isLoading: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchPlanTypesData = () => async (dispatch) => {
  try {
    const response = await Options.fetchPlanTypes();
    const options = await createDynamicOptions(response.data.data, true);
    await dispatch(setPlanData({ plan_types: options }));

    dispatch(setIsLoading({ isLoading: false }));
  } catch (e) {
    //console.error(e);
    dispatch(setIsLoading({ isLoading: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchPrioritiesData = () => async (dispatch) => {
  try {
    const response = await Options.fetchPriorities();
    const options = await createDynamicOptions(response.data.data, false, true);
    await dispatch(setPrioritiesData(options));
  } catch (e) {
    //console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchExpenses = () => async (dispatch) => {
  try {
    const response = await Options.fetchExpenses();
    const options = await createDynamicOptions(response.data.data, true);
    await dispatch(setExpensesData({ expenses: options }));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchValuesData = () => async (dispatch) => {
  try {
    const response = await Options.fetchValues();
    const options = await createDynamicOptions(response.data.data, true);
    await dispatch(setValuesData({ values: options }));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchIncomesData = () => async (dispatch) => {
  try {
    const response = await Options.fetchIncomes();
    const options = await createDynamicOptions(response.data.data);
    await dispatch(setIncomesData({ incomes: options }));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchStatusData = params => async (dispatch) => {
  try {
    const response = await Options.fetchStatus(params);
    const options = await createDynamicOptions(response.data.data);
    await dispatch(setStatusData({ status: options }));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchTaskStatusData = (params) => async (dispatch) => {
  try {
    const response = await Options.fetchTasksStatus(params);
    const options = await createDynamicOptions(response.data.data);
    await dispatch(setTaskStatusData({ task_status: options }));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchCategoriesData = () => async (dispatch) => {
  try {
    const response = await Options.fetchCategories();
    const options = await createDynamicOptions(response.data.data);
    await dispatch(setCategoriesData({ categories: options }));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchCalculatorsData = () => async (dispatch) => {
  try {
    const response = await Calculator.get();
    const options = await createDynamicOptions(response.data.data, false, false, true);
    await dispatch(setCalculatorsData(options));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const {
  setPlanningData,
  setPlanData,
  setPrioritiesData,
  setExpensesData,
  setValuesData,
  setIncomesData,
  setStatusData,
  setTaskStatusData,
  setCategoriesData,
  setCatalogData,
  setCalculatorsData
} = optionsSlice.actions;

export default optionsSlice.reducer