import React, { useState } from 'react'
import optionsWidget from '../../Data/optionsWidget'
import { FormSelect } from '../shared/Form'
import WidgetBlog from './WidgetBlog'
import WidgetCalculator from './WidgetCalculator'


const WidgetOthers = ({ items, isBlog, isCalculators }) => {
  const [option, setOptions] = useState(isBlog ? 'blog' : 'calculators');

  const handleChange = (event) => {
    setOptions(event.target.value);
  };

  const dynamicOption = () => {
    if (!isBlog) {
      return optionsWidget.slice(1);
    } else if (!isCalculators) {
      return optionsWidget.slice(0, 1);
    } else {
      return optionsWidget;
    }
  };

  return (
    <div className="widget widget--others">
      <div className="widget__top">
        <div className="widget__heading step-educate">Educate</div>

        <FormSelect
          options={dynamicOption()}
          id="view"
          value={option}
          name="view"
          onChange={handleChange}
          small
          autoWidth
        />
      </div>
      <div className="widget__content">
        {option === 'blog' && <WidgetBlog items={items} />}
        {option === 'calculators' && <WidgetCalculator />}
      </div>
    </div>
  );
};

export default WidgetOthers;
