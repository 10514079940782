import React from 'react';

export default function Table({ children }) {
  return (
    <div className="table__container">
      <table className="table">
        {children}
      </table>
    </div>
  )
}