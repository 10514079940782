const years = [
  {
    key: 2020,
    label: '2020',
    value: '2020',
  },
  {
    key: 2019,
    label: '2019',
    value: '2019',
  },
  {
    key: 2018,
    label: '2018',
    value: '2018',
  },
  {
    key: 2017,
    label: '2017',
    value: '2017',
  },
  {
    key: 2016,
    label: '2016',
    value: '2016',
  },
  {
    key: 2015,
    label: '2015',
    value: '2015',
  },
  {
    key: 2014,
    label: '2014',
    value: '2014',
  },
  {
    key: 2013,
    label: '2013',
    value: '2013',
  },
  {
    key: 2012,
    label: '2012',
    value: '2012',
  },
  {
    key: 2011,
    label: '2011',
    value: '2011',
  },
  {
    key: 2010,
    label: '2010',
    value: '2010',
  },
  {
    key: 2009,
    label: '2009',
    value: '2009',
  },
  {
    key: 2008,
    label: '2008',
    value: '2008',
  },
  {
    key: 2007,
    label: '2007',
    value: '2007',
  },
  {
    key: 2006,
    label: '2006',
    value: '2006',
  },
  {
    key: 2005,
    label: '2005',
    value: '2005',
  },
  {
    key: 2004,
    label: '2004',
    value: '2004',
  },
  {
    key: 2003,
    label: '2003',
    value: '2003',
  },
  {
    key: 2002,
    label: '2002',
    value: '2002',
  },
  {
    key: 2001,
    label: '2001',
    value: '2001',
  },
  {
    key: 2000,
    label: '2000',
    value: '2000',
  },
  {
    key: 1999,
    label: '1999',
    value: '1999',
  },
  {
    key: 1998,
    label: '1998',
    value: '1998',
  },
  {
    key: 1997,
    label: '1997',
    value: '1997',
  },
  {
    key: 1996,
    label: '1996',
    value: '1996',
  },
  {
    key: 1995,
    label: '1995',
    value: '1995',
  },
  {
    key: 1994,
    label: '1994',
    value: '1994',
  },
  {
    key: 1993,
    label: '1993',
    value: '1993',
  },
  {
    key: 1992,
    label: '1992',
    value: '1992',
  },
  {
    key: 1991,
    label: '1991',
    value: '1991',
  },
  {
    key: 1990,
    label: '1990',
    value: '1990',
  },
  {
    key: 1989,
    label: '1989',
    value: '1989',
  },
  {
    key: 1988,
    label: '1988',
    value: '1988',
  },
  {
    key: 1987,
    label: '1987',
    value: '1987',
  },
  {
    key: 1986,
    label: '1986',
    value: '1986',
  },
  {
    key: 1985,
    label: '1985',
    value: '1985',
  },
  {
    key: 1984,
    label: '1984',
    value: '1984',
  },
  {
    key: 1983,
    label: '1983',
    value: '1983',
  },
  {
    key: 1982,
    label: '1982',
    value: '1982',
  },
  {
    key: 1981,
    label: '1981',
    value: '1981',
  },
  {
    key: 1980,
    label: '1980',
    value: '1980',
  },
  {
    key: 1979,
    label: '1979',
    value: '1979',
  },
  {
    key: 1978,
    label: '1978',
    value: '1978',
  },
  {
    key: 1977,
    label: '1977',
    value: '1977',
  },
  {
    key: 1976,
    label: '1976',
    value: '1976',
  },
  {
    key: 1975,
    label: '1975',
    value: '1975',
  },
  {
    key: 1974,
    label: '1974',
    value: '1974',
  },
  {
    key: 1973,
    label: '1973',
    value: '1973',
  },
  {
    key: 1972,
    label: '1972',
    value: '1972',
  },
  {
    key: 1971,
    label: '1971',
    value: '1971',
  },
  {
    key: 1970,
    label: '1970',
    value: '1970',
  },
  {
    key: 1969,
    label: '1969',
    value: '1969',
  },
  {
    key: 1968,
    label: '1968',
    value: '1968',
  },
  {
    key: 1967,
    label: '1967',
    value: '1967',
  },
  {
    key: 1966,
    label: '1966',
    value: '1966',
  },
  {
    key: 1965,
    label: '1965',
    value: '1965',
  },
  {
    key: 1964,
    label: '1964',
    value: '1964',
  },
  {
    key: 1963,
    label: '1963',
    value: '1963',
  },
  {
    key: 1962,
    label: '1962',
    value: '1962',
  },
  {
    key: 1961,
    label: '1961',
    value: '1961',
  },
  {
    key: 1960,
    label: '1960',
    value: '1960',
  },
  {
    key: 1959,
    label: '1959',
    value: '1959',
  },
  {
    key: 1958,
    label: '1958',
    value: '1958',
  },
  {
    key: 1957,
    label: '1957',
    value: '1957',
  },
  {
    key: 1956,
    label: '1956',
    value: '1956',
  },
  {
    key: 1955,
    label: '1955',
    value: '1955',
  },
  {
    key: 1954,
    label: '1954',
    value: '1954',
  },
  {
    key: 1953,
    label: '1953',
    value: '1953',
  },
  {
    key: 1952,
    label: '1952',
    value: '1952',
  },
  {
    key: 1951,
    label: '1951',
    value: '1951',
  },
  {
    key: 1950,
    label: '1950',
    value: '1950',
  },
  {
    key: 1949,
    label: '1949',
    value: '1949',
  },
  {
    key: 1948,
    label: '1948',
    value: '1948',
  },
  {
    key: 1947,
    label: '1947',
    value: '1947',
  },
  {
    key: 1946,
    label: '1946',
    value: '1946',
  },
  {
    key: 1945,
    label: '1945',
    value: '1945',
  },
  {
    key: 1944,
    label: '1944',
    value: '1944',
  },
  {
    key: 1943,
    label: '1943',
    value: '1943',
  },
  {
    key: 1942,
    label: '1942',
    value: '1942',
  },
  {
    key: 1941,
    label: '1941',
    value: '1941',
  },
  {
    key: 1940,
    label: '1940',
    value: '1940',
  },
  {
    key: 1939,
    label: '1939',
    value: '1939',
  },
  {
    key: 1938,
    label: '1938',
    value: '1938',
  },
  {
    key: 1937,
    label: '1937',
    value: '1937',
  },
  {
    key: 1936,
    label: '1936',
    value: '1936',
  },
  {
    key: 1935,
    label: '1935',
    value: '1935',
  },
  {
    key: 1934,
    label: '1934',
    value: '1934',
  },
  {
    key: 1933,
    label: '1933',
    value: '1933',
  },
  {
    key: 1932,
    label: '1932',
    value: '1932',
  },
  {
    key: 1931,
    label: '1931',
    value: '1931',
  },
  {
    key: 1930,
    label: '1930',
    value: '1930',
  },
  {
    key: 1929,
    label: '1929',
    value: '1929',
  },
  {
    key: 1928,
    label: '1928',
    value: '1928',
  },
  {
    key: 1927,
    label: '1927',
    value: '1927',
  },
  {
    key: 1926,
    label: '1926',
    value: '1926',
  },
  {
    key: 1925,
    label: '1925',
    value: '1925',
  },
  {
    key: 1924,
    label: '1924',
    value: '1924',
  },
  {
    key: 1923,
    label: '1923',
    value: '1923',
  },
  {
    key: 1922,
    label: '1922',
    value: '1922',
  },
  {
    key: 1921,
    label: '1921',
    value: '1921',
  },
  {
    key: 1920,
    label: '1920',
    value: '1920',
  },
]

export default years