import React, { useState, useRef } from 'react'
import { IconButton } from '@mui/material'
import TooltipCustom from '../../components/shared/Tooltip/Tooltip'
import SVGIcon from '../../components/shared/SVGIcon/SVGIcon'
import CopyIcon from '../../assets/images/icons/copy-text.svg'
import QuestionIcon from '../../assets/images/icons/question.svg'


const VariableCard = ({ name, tooltip }) => {
  const textAreaRef = useRef(null)
  const [copySuccess, setCopySuccess] = useState('')

  const copyToClipboard = (e) => {
    textAreaRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    setCopySuccess('Copied!');
    setTimeout(() => setCopySuccess(''), 3000)
  }

  return (
    <div className="variable-card">
      <textarea
        className="variable-card__title"
        ref={textAreaRef}
        value={name}
        readOnly
      />
      <div className="variable-card__buttons">
        {copySuccess && <div className="variable-card__copy">{copySuccess}</div>}
        <button onClick={copyToClipboard}><SVGIcon icon={CopyIcon} /></button>
        <TooltipCustom
          arrow
          title={<>{tooltip}</>}
        >
          <IconButton className="MuiIconButton-default">
            <SVGIcon icon={QuestionIcon} />
          </IconButton>
        </TooltipCustom>
      </div>
    </div>
  )
}

export default VariableCard
