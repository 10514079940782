import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { BackButton } from '../../../components/shared/Buttons'
import Layout from '../../../components/Layout/Layout'
import { Button, Switch } from '@mui/material'
import isArray from 'lodash/isArray'
import { fetchBequeath, deactivateBequeath } from '../../../redux/slices/protectionSlice'
import { fetchTotalPercent } from '../../../redux/slices/percentagesSlice'
import TreeSubItem from '../../../components/Tree/TreeSubItem'
import TreeImage from '../../../assets/images/tree/protection/Protection-tree.png'
import ModalBox from '../../../components/shared/Modal/Modal'
import { setIsOpen } from '../../../redux/slices/modalSlice'
import TreeCount from '../../../components/Tree/TreeCount'

const Bequeath = ({ props }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useSelector(state => state.user.user)
  const isBasic = useSelector((state) => state.user.user.plan_type === 1);
  const totalPercent = useSelector(state => state.percentages.total)
  const bequeath = useSelector(state => state.protection.bequeath)
  const isOpen = useSelector(state => state.modal.isOpen)
  const loading = useSelector(state => state.sending.isSending)
  const [isCheck, setCheck] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const mobileView = 999

  const handleResize = () => {
    (mobileView >= window.innerWidth) ? setIsMobile(true) : setIsMobile(false)
  }

  useEffect(() => {
    mobileView >= window.innerWidth && setIsMobile(true)
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, []);

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchBequeath())
      await dispatch(fetchTotalPercent(isBasic))
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id])

  const close = () => dispatch(setIsOpen({isOpen: false}))

  const handleCheck = () => {
    setCheck(true);
  }

  const handleConfirm = () => {
    dispatch(deactivateBequeath(props));
    setCheck(false);
  }

  const onClose = () => {
    setCheck(false);
  }

  const handleClick = () => {
    if(bequeath && bequeath.length > 4) {
      return dispatch(setIsOpen({isOpen: true}))
    }

    return history.push('/bequeath/create')
  }

  return (
    <Layout totalPercent={totalPercent} tree>
      <div className="tree__sticky-holder">
        <BackButton title={isMobile ? 'Back' : 'Back to Tree'} path={isMobile ? '/protection' : "/input"} className="back-button--position" />
        <div className="tree__title tree__title--progress-bar">
          <div className="tree__title-main">
            <h2>Bequethment</h2>
            <TreeCount
              value={bequeath && bequeath.length > 0 ? 100 : '0'}
              loading={loading}
              big={!isMobile}
            />
          </div>
          {isMobile && bequeath && bequeath.length === 0 && (<div className="tree__switcher">
                <Switch 
                  className="custom-switcher"
                  checked={isCheck}
                  onChange={handleCheck}
                />
                I do not have any bequethments.
            </div>
          )}
          {!isMobile && (
            <>
              <p className="tree__title-other">Fill in information to complete this step.</p>
              <Button variant="contained" className="tree-bar" size="small" onClick={handleClick} color="primary">
                Add bequethment
              </Button>
              {bequeath && bequeath.length === 0 && (<div className="tree__switcher">
                <Switch 
                  className="custom-switcher"
                  checked={isCheck}
                  onChange={handleCheck}
                />
                I do not have any bequethments.
              </div>)}
            </>
          )}
        </div>
        <div className="tree tree--sub-page">
          {!isMobile &&
            <div className="tree__base">
              <img src={TreeImage} alt="ValueSoft Tree Insurance" />
            </div>
          }
          {bequeath && isArray(bequeath) && bequeath.map((item, i) =>
            <React.Fragment key={item.id}>
              <TreeSubItem key={item.id}
                           path={`/bequeath/${item.id}`}
                           title={item.name}
                           count={100}
                           value={`bequeath-item-${i+1}`}
                           isLoading={loading}
              />
            </React.Fragment>
          )}
        </div>

        {isMobile &&
          <div className="tree__add-button">
            <Button variant="contained" size="small" onClick={handleClick} color="primary">
              {!isMobile && 'Add bequethment'}
            </Button>
          </div>
        }
      </div>

      <ModalBox
        small
        open={isOpen}
        close={close}
        onClick={() => history.push("/input")}
        button="Back to tree"
        title="Sorry"
        children={<p>The limit of adding new sections is reached.</p>} />

      <ModalBox
        small
        open={isCheck}
        close={onClose}
        onClick={handleConfirm}
        button="Confirm"
        closeButton="Cancel"
        title="Bequethment step"
        children={<p>This step will be disabled, but you can activate it any time by clicking on it in the your input.</p>} />
    </Layout>
  )
}

export default Bequeath
