import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'classnames'

const Steps = ({ options }) => {
  return (
    <ol className="steps">
      {options && options.map((option, i) => (
        <li key={`option-${i}`}
            className={clsx('steps__item',{
              'steps__item--active': option.active,
              'steps__item--done': option.done
            })}>
          {option.title}
        </li>
      ))}
    </ol>
  )
};

Steps.propTypes = {
  option: PropTypes.array
};

export default Steps
