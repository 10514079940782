import React from 'react'
import { Form, Formik } from 'formik'
import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { FormGroup, Input } from '../shared/Form'
import Percentage from '../../assets/images/icons/percentage.svg'

const HomeEvaluationAdmin = ({ title, onSave, values, sending }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{...values}}
      onSubmit={values => onSave(values)}
    >
      {({ handleChange, handleBlur, setFieldValue, values }) => (
        <Form>
          <h4 className="form__main-title">{title}</h4>
          <h6>Relative importance</h6>
          <div className="form__flow-wrap">
          <FormGroup name="location" labelText="Location">
            <Input
              name="location"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.location || 0}
            />
          </FormGroup>
          <FormGroup name="neighborhood_curb_appeal" labelText="Neighborhood curb appeal">
            <Input
              name="neighborhood_curb_appeal"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.neighborhood_curb_appeal || 0}
            />
          </FormGroup>
          <FormGroup name="home_curb_appeal" labelText="Home curb appeal">
            <Input
              name="home_curb_appeal"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.home_curb_appeal || 0}
            />
          </FormGroup>
          <FormGroup name="ability_to_weather" labelText="Ability to Weather Catastrophic Events (Hurricane, Earthquake, etc)">
            <Input
              name="ability_to_weather"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.ability_to_weather || 0}
            />
          </FormGroup>
          <FormGroup name="quality_of_backyard" labelText="Quality of backyard">
            <Input
              name="quality_of_backyard"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.quality_of_backyard || 0}
            />
          </FormGroup>
          <FormGroup name="comfort_level_on_price" labelText="Comfort level on price">
            <Input
              name="comfort_level_on_price"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.comfort_level_on_price || 0}
            />
          </FormGroup>
          <FormGroup name="size" labelText="Size">
            <Input
              name="size"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.size || 0}
            />
          </FormGroup>
          <FormGroup name="room_for_improvement" labelText="Room for improvement">
            <Input
              name="room_for_improvement"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.room_for_improvement || 0}
            />
          </FormGroup>
          <FormGroup name="practicality" labelText="Practicality">
            <Input
              name="practicality"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.practicality || 0}
            />
          </FormGroup>
          <FormGroup name="includes_amenities" labelText="Includes amenities">
            <Input
              name="includes_amenities"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.includes_amenities || 0}
            />
          </FormGroup>
          <FormGroup name="quiet_neighbors_and_community" labelText="Quiet neighbors and community">
            <Input
              name="quiet_neighbors_and_community"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.quiet_neighbors_and_community || 0}
            />
          </FormGroup>
          <FormGroup name="enthusiasm_for_home" labelText="My enthusiasm for the home">
            <Input
              name="enthusiasm_for_home"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.enthusiasm_for_home || 0}
            />
          </FormGroup>
          <FormGroup name="spouse_enthusiasm_for_home" labelText="My spouse’s enthusiasm for the home">
            <Input
              name="spouse_enthusiasm_for_home"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.spouse_enthusiasm_for_home || 0}
            />
          </FormGroup>
          <FormGroup name="family_enthusiasm_for_home" labelText="Other family member’s enthusiasm for the home">
            <Input
              name="family_enthusiasm_for_home"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.family_enthusiasm_for_home || 0}
            />
          </FormGroup>
          <FormGroup name="score" labelText="Score">
            <Input
              name="score"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              step={0.1}
              value={values.score || 0}
              iconName={Percentage}
            />
          </FormGroup>
          </div>
          <h6>Ideal home</h6>
          <div className="form__flow-wrap">
          <FormGroup name="location_level" labelText="Location">
            <RadioGroup
              name="location_level"
              value={values.location_level || 'medium'}
              onChange={(e) => {
                setFieldValue('location_level', e.target.value)
              }}
              className="radio-list-inline"
            >
              <FormControlLabel value="high" control={<Radio/>} label="High"/>
              <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
              <FormControlLabel value="low" control={<Radio/>} label="Low"/>
            </RadioGroup>
          </FormGroup>
          <FormGroup name="neighborhood_curb_appeal_level" labelText="Neighborhood curb appeal">
            <RadioGroup
              name="neighborhood_curb_appeal_level"
              value={values.neighborhood_curb_appeal_level || 'medium'}
              onChange={(e) => {
                setFieldValue('neighborhood_curb_appeal_level', e.target.value)
              }}
              className="radio-list-inline"
            >
              <FormControlLabel value="high" control={<Radio/>} label="High"/>
              <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
              <FormControlLabel value="low" control={<Radio/>} label="Low"/>
            </RadioGroup>
          </FormGroup>
          <FormGroup name="home_curb_appeal_level" labelText="Home curb appeal">
            <RadioGroup
              name="home_curb_appeal_level"
              value={values.home_curb_appeal_level || 'medium'}
              onChange={(e) => {
                setFieldValue('home_curb_appeal_level', e.target.value)
              }}
              className="radio-list-inline"
            >
              <FormControlLabel value="high" control={<Radio/>} label="High"/>
              <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
              <FormControlLabel value="low" control={<Radio/>} label="Low"/>
            </RadioGroup>
          </FormGroup>
          <FormGroup name="ability_to_weather_level" labelText="Ability to weather (hurricane and etc)">
            <RadioGroup
              name="ability_to_weather_level"
              value={values.ability_to_weather_level || 'medium'}
              onChange={(e) => {
                setFieldValue('ability_to_weather_level', e.target.value)
              }}
              className="radio-list-inline"
            >
              <FormControlLabel value="high" control={<Radio/>} label="High"/>
              <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
              <FormControlLabel value="low" control={<Radio/>} label="Low"/>
            </RadioGroup>
          </FormGroup>
          <FormGroup name="quality_of_backyard_level" labelText="Quality of backyard">
            <RadioGroup
              name="quality_of_backyard_level"
              value={values.quality_of_backyard_level || 'medium'}
              onChange={(e) => {
                setFieldValue('quality_of_backyard_level', e.target.value)
              }}
              className="radio-list-inline"
            >
              <FormControlLabel value="high" control={<Radio/>} label="High"/>
              <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
              <FormControlLabel value="low" control={<Radio/>} label="Low"/>
            </RadioGroup>
          </FormGroup>
          <FormGroup name="comfort_level_on_price_level" labelText="Comfort level on price">
            <RadioGroup
              name="comfort_level_on_price_level"
              value={values.comfort_level_on_price_level || 'medium'}
              onChange={(e) => {
                setFieldValue('comfort_level_on_price_level', e.target.value)
              }}
              className="radio-list-inline"
            >
              <FormControlLabel value="high" control={<Radio/>} label="High"/>
              <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
              <FormControlLabel value="low" control={<Radio/>} label="Low"/>
            </RadioGroup>
          </FormGroup>
          <FormGroup name="size_level" labelText="Size">
            <RadioGroup
              name="size_level"
              value={values.size_level || 'medium'}
              onChange={(e) => {
                setFieldValue('size_level', e.target.value)
              }}
              className="radio-list-inline"
            >
              <FormControlLabel value="high" control={<Radio/>} label="High"/>
              <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
              <FormControlLabel value="low" control={<Radio/>} label="Low"/>
            </RadioGroup>
          </FormGroup>
          <FormGroup name="room_for_improvement_level" labelText="Room for improvement">
            <RadioGroup
              name="room_for_improvement_level"
              value={values.room_for_improvement_level || 'medium'}
              onChange={(e) => {
                setFieldValue('room_for_improvement_level', e.target.value)
              }}
              className="radio-list-inline"
            >
              <FormControlLabel value="high" control={<Radio/>} label="High"/>
              <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
              <FormControlLabel value="low" control={<Radio/>} label="Low"/>
            </RadioGroup>
          </FormGroup>
          <FormGroup name="practicality_level" labelText="Practicality">
            <RadioGroup
              name="practicality_level"
              value={values.practicality_level || 'medium'}
              onChange={(e) => {
                setFieldValue('practicality_level', e.target.value)
              }}
              className="radio-list-inline"
            >
              <FormControlLabel value="high" control={<Radio/>} label="High"/>
              <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
              <FormControlLabel value="low" control={<Radio/>} label="Low"/>
            </RadioGroup>
          </FormGroup>
          <FormGroup name="includes_amenities_level" labelText="Includes Amenities">
            <RadioGroup
              name="includes_amenities_level"
              value={values.includes_amenities_level || 'medium'}
              onChange={(e) => {
                setFieldValue('includes_amenities_level', e.target.value)
              }}
              className="radio-list-inline"
            >
              <FormControlLabel value="high" control={<Radio/>} label="High"/>
              <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
              <FormControlLabel value="low" control={<Radio/>} label="Low"/>
            </RadioGroup>
          </FormGroup>
          <FormGroup name="quiet_neighbors_and_community_level" labelText="Quiet neighbors and community">
            <RadioGroup
              name="quiet_neighbors_and_community_level"
              value={values.quiet_neighbors_and_community_level || 'medium'}
              onChange={(e) => {
                setFieldValue('quiet_neighbors_and_community_level', e.target.value)
              }}
              className="radio-list-inline"
            >
              <FormControlLabel value="high" control={<Radio/>} label="High"/>
              <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
              <FormControlLabel value="low" control={<Radio/>} label="Low"/>
            </RadioGroup>
          </FormGroup>
          <FormGroup name="enthusiasm_for_home_level" labelText="My enthusiasm for the home">
            <RadioGroup
              name="enthusiasm_for_home_level"
              value={values.enthusiasm_for_home_level || 'medium'}
              onChange={(e) => {
                setFieldValue('enthusiasm_for_home_level', e.target.value)
              }}
              className="radio-list-inline"
            >
              <FormControlLabel value="high" control={<Radio/>} label="High"/>
              <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
              <FormControlLabel value="low" control={<Radio/>} label="Low"/>
            </RadioGroup>
          </FormGroup>
          <FormGroup name="spouse_enthusiasm_for_home_level" labelText="My spouse’s enthusiasm for the home">
            <RadioGroup
              name="spouse_enthusiasm_for_home_level"
              value={values.spouse_enthusiasm_for_home_level || 'medium'}
              onChange={(e) => {
                setFieldValue('spouse_enthusiasm_for_home_level', e.target.value)
              }}
              className="radio-list-inline"
            >
              <FormControlLabel value="high" control={<Radio/>} label="High"/>
              <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
              <FormControlLabel value="low" control={<Radio/>} label="Low"/>
            </RadioGroup>
          </FormGroup>
          <FormGroup name="family_enthusiasm_for_home_level" labelText="Other family member’s enthusiasm for the home">
            <RadioGroup
              name="family_enthusiasm_for_home_level"
              value={values.family_enthusiasm_for_home_level || 'medium'}
              onChange={(e) => {
                setFieldValue('family_enthusiasm_for_home_level', e.target.value)
              }}
              className="radio-list-inline"
            >
              <FormControlLabel value="high" control={<Radio/>} label="High"/>
              <FormControlLabel value="medium" control={<Radio/>} label="Medium"/>
              <FormControlLabel value="low" control={<Radio/>} label="Low"/>
            </RadioGroup>
          </FormGroup>
          </div>
          <div className="form__button">
            <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
              Save
              {sending && <span className="spinner" />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default HomeEvaluationAdmin
