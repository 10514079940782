import React, {useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom';
import Layout from '../../components/Layout/Layout'
import BlogCard from '../../components/Cards/BlogCard'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { FormGroup, Input } from '../../components/shared/Form'
import Loading from '../../components/shared/Loading/Loading'
import { Button } from '@mui/material'
import { fetchPosts, fetchAuthors, fetchCatalog } from '../../redux/slices/blogSlice'
import { Blog } from '../../api/Api'
import SVGIcon from '../../components/shared/SVGIcon/SVGIcon'
import Icon from '../../assets/images/icons/icon-ongratulations.svg'
import Modal from '../../components/shared/Modal/Modal'
import { isNull } from 'lodash';

const BlogPage = ({ props }) => {
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.user.user);
  const { posts, catalog, isLoading } = useSelector(state => state.blog);
  const { permissions } = useSelector(state => state.companies.company.info);
  const [filter, setFilter] = useState({ catalog: null, status: null });
  const [limit, setLimit] = useState(11);
  const [count, setCount] = useState(4);
  const [load, setLoad] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchPosts(null, { catalog: filter.catalog, status: filter.status }, null, props))
      await dispatch(fetchCatalog())
      await dispatch(fetchAuthors())
    }
    if (id) {
      fetch()
    }
    // eslint-disable-next-line
  }, [id, filter])

  const loadMore = async () => {
    await setLoad(true)
    await setCount(count + 4)
    await setLimit(limit + 4)
    await setLoad(false)
  }
  if (!isNull(permissions?.blog) && !permissions?.blog) return (<Redirect to="/404" />)
  return (
    <Layout>
      <div className="blog">
        <div className="blog__tags">
          <span
            className={`blog__tags-item${filter.catalog === null ? ' blog__tags-item--selected' : ''}`}
            onClick={() => {
              setFilter({ catalog: null })
              setCount(4)
              setLimit(11)
            }}
          >
            All
          </span>
          <span
              className={`blog__tags-item${filter.status === 'new' ? ' blog__tags-item--selected' : ''}`}
              onClick={() => {
                setFilter({ status: 'new' })
                setCount(4)
                setLimit(11)
              }}
          >
            New
          </span>
          {catalog && catalog.length > 1 && catalog.map((item, i) => (
            <span
              key={`tag-${item.id}`}
              className={`blog__tags-item${filter.catalog === item.id ? ' blog__tags-item--selected' : ''}`}
              onClick={() => {
                setFilter({ catalog: item.id })
                setCount(4)
                setLimit(11)
              }}
            >
              {item.name}
            </span>
          ))}
        </div>
        {isLoading
          ? <Loading />
          : posts && posts.posts.length > 0
            ? <>
                <div className="grid blog__grid">
                  <div className="column-1-3">
                    <BlogCard
                      large
                      active={posts.posts[0].active}
                      image={posts.posts[0].image}
                      content={posts.posts[0].short_description}
                      title={posts.posts[0].title}
                      path={`/blog/${posts.posts[0].id}`}
                      category={posts.posts[0].catalog && posts.posts[0].catalog.name
                        ? posts.posts[0].catalog.name
                        : 'Uncategorized'
                      }
                    />
                  </div>
                  <div className="column-2-3">
                    <div className="grid grid-2 blog__list">
                      {posts.posts && posts.posts.slice(1,7).map(item => (
                        <div key={item.id}>
                          <BlogCard
                            active={item.active}
                            image={item.image}
                            title={item.title}
                            category={item.catalog && item.catalog.name ? item.catalog.name : 'Uncategorized'}
                            path={`/blog/${item.id}`}
                            small />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {posts && posts.posts.length > 6 &&
                  <div className="grid grid-4 blog__add-list">
                    {posts.posts.slice(7, (7 + count)).map(item => (
                      <div key={item.id}>
                        <BlogCard
                          active={item.active}
                          image={item.image}
                          title={item.title}
                          category={item.catalog && item.catalog.name ? item.catalog.name : 'Uncategorized'}
                          path={`/blog/${item.id}`}
                        />
                      </div>
                    ))}
                  </div>}
                  {posts.total > limit &&
                    <div className="blog__button">
                      <Button onClick={loadMore} variant="outlined" disabled={load}>
                        Load more
                        {load && <span className="spinner" />}
                      </Button>
                    </div>
                  }
              </>
            : <div className="blog__no-post"><h4>No results</h4></div>
        }
      </div>

      <div className="section section--gray section--bottom">
        <div className="section__title">
          <h2>Questions or Suggestions?</h2>
          <p>Tell us about it or your suggestions for other topics you're interested in learning about.</p>
        </div>
        <Formik
          enableReinitialize
          initialValues={{ content: '' }}
          validationSchema={Yup.object().shape({
            content: Yup.string().required('Required'),
          })}
          onSubmit={(values, {resetForm}) => {
            Blog.postText(values)
            setIsOpen(true)
            resetForm({values: ''})
          }}
        >
          {({ errors, handleChange, handleBlur, values, touched }) => (
            <Form>
              <div className="form--email">
                <FormGroup name="text" hideLabel>
                  <Input
                    id="text"
                    placeholder="Enter text"
                    name="content"
                    onChange={handleChange}
                    value={values.content || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.content && touched.content}
                  />
                  {touched.content && errors.content && <span className="form__msg-error">{errors.content}</span>}
                </FormGroup>
                <div className="form__button">
                  <Button variant="contained" size="large" color="primary" type="submit">
                    Send
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <Modal
        open={isOpen}
        small
        close={() => setIsOpen(false)}
      >
        <div className="modal__title-icon">
          <SVGIcon icon={Icon} />
        </div>
        <h4>Thank you for your feedback!</h4>
      </Modal>
    </Layout>
  );
};

export default BlogPage
