import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { setIsOpen } from "../../redux/slices/modalSlice";
import { postUnsubscribePushNotificationPlan } from "../../redux/slices/userSlice";
import ModalBox from '../shared/Modal/Modal';

const CancellationPlanModal = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const isSending = useSelector(state => state.sending.isSending);
  const { id } = useSelector((state) => state.user.user);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const mode = useSelector(state => state.mode.mode);
  const isOpen = useSelector(state => state.modal.isOpen);

  useEffect(() => {
    if (location.pathname === "/unsubscribe-push-notification" && !isSending) {
      setIsCancelModalOpen(true);
    }
    // eslint-disable-next-line
  }, [isSending]);

  const handleClose = () => {
    setIsCancelModalOpen(false);
    history.push("/input");
  }

  const handleSubmit = () => {
    handleClose();
    dispatch(postUnsubscribePushNotificationPlan({ user_id: id }));
  }

  return (
    <>
      <ModalBox
        small
        open={isCancelModalOpen}
        close={handleClose}
        title='Are you sure you want to turn off notifications?'
      >
        <p className="typography text-muted">
          You can turn these weekly notifications back on by going to <Link className="text-accent typography__semibold"
                                                                            to="/profile">Plans</Link> in your user
          settings and choosing a task plan.
        </p>
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <Button
              size="large"
              variant="outlined"
              disabled={isSending}
              onClick={handleSubmit}
            >
              Yes
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="large"
              color="primary"
              variant="contained"
              disabled={isSending}
              onClick={handleClose}
            >
              No
            </Button>
          </Grid>
        </Grid>
      </ModalBox>
      <ModalBox
        small
        button="Ok"
        open={isOpen}
        close={() => dispatch(setIsOpen({ isOpen: false }))}
        onClick={() => dispatch(setIsOpen({ isOpen: false }))}
        children={mode === "success" ?
          <p>The task plan were updated successfully.</p> :
          <p>Something went wrong. Try again.</p>
        }
      />
    </>
  )
};

export default CancellationPlanModal;