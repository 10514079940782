import { createSlice } from '@reduxjs/toolkit'
import { Expenses, Incomes, Incremental, Assets, Stock } from '../../api/Api'
import { setIsOpen } from './modalSlice'
import { setSending } from './sendingSlice'
import { convertDecimal } from '../../utils/utils'
import { createDynamicOptions } from '../../utils/utils'
import moment from 'moment'
import { fetchPercentages } from './percentagesSlice'
import { setNewTasksCount } from './taskSlice'
import { setIsRelogin } from "./loadingSlice";

const financesSlice = createSlice({
  name: 'finances',
  initialState: {
    incomes: {
      others: '',
      income_tax_filling: null,
      monthly_income: '',
      spouse_monthly_income: '',
      yearly_bonus: '',
      spouse_yearly_bonus: '',
      other_monthly: '',
      estimated_taxes: '',
      additional_info: ''
    },
    expenses: null,
    listExpenses: null,
    currentExpenses: null,
    incremental: null,
    assets: [],
    currentAsset: {
      liquidated_asset: false,
      name: '',
      type: '',
      age_when_purchased: null,
      value_purchased: '',
      down_payment: null,
      category: '',
      current_value: null,
      interest_rate: null,
      financing_term: null,
      useful_life: null,
      age_when_liquidated: null,
      estimated_value: null,
      additional_info: ''
    },
    stock: null,
    isLoading: false,
    isOpenModal: false
  },
  reducers: {
    setIncomesData: (state, action) => {
      state.incomes = {
        ...state.incomes,
        ...action.payload
      }
    },
    setExpensesData: (state, action) => {
      state.expenses = action.payload;
    },
    setListExpenses: (state, action) => {
      state.listExpenses = action.payload;
    },
    setCurrentExpense: (state, action) => {
      state.currentExpenses = action.payload;
    },
    setIncrementalData: (state, action) => {
      state.incremental = action.payload;
    },
    setAssetsData: (state, action) => {
      state.assets = action.payload
    },
    setCurrentAsset: (state, action) => {
      state.currentAsset = action.payload
    },
    setStockData: (state, action) => {
      state.stock = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setIsOpenModal: (state, action) => {
      state.isOpenModal = action.payload
    },
  }
})


export const fetchUserIncomes = () => async (dispatch) => {
  try {
    const response = await Incomes.get()
    await dispatch(setIncomesData(response.data.data))
  } catch (e) {
    console.error(e)
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postUserIncomes = (data, props, id, isEdit) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }))
    let response = null
    let {
      others,
      monthly_income,
      spouse_monthly_income,
      yearly_bonus,
      spouse_yearly_bonus,
      other_monthly,
      estimated_taxes } = data;

    others                = others ? convertDecimal(others) : '';
    monthly_income        = monthly_income ? convertDecimal(monthly_income) : '';
    spouse_monthly_income = spouse_monthly_income ? convertDecimal(spouse_monthly_income) : '';
    yearly_bonus          = yearly_bonus ? convertDecimal(yearly_bonus) : '';
    spouse_yearly_bonus   = spouse_yearly_bonus ? convertDecimal(spouse_yearly_bonus) : '';
    other_monthly         = other_monthly ? convertDecimal(other_monthly) : '';
    estimated_taxes       = estimated_taxes ? convertDecimal(estimated_taxes) : '';

    if (isEdit) {
      response = await Incomes.put(id, { ...data,
        others,
        monthly_income,
        spouse_monthly_income,
        yearly_bonus,
        spouse_yearly_bonus,
        other_monthly,
        estimated_taxes
      })
    } else {
      response = await Incomes.post({ ...data,
        others,
        monthly_income,
        spouse_monthly_income,
        yearly_bonus,
        spouse_yearly_bonus,
        other_monthly,
        estimated_taxes
      })
    }

    await dispatch(fetchPercentages({name: 'income'}))
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
    await dispatch(setSending({ isSending: false }))
    await dispatch(setIsOpen({ isOpen: true }))
  } catch (e) {
    console.error(e)
    await dispatch(setSending({ isSending: false }))
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const removeIncome = (id, props) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }))
    await Incomes.del(id)
    await dispatch(setSending({ isSending: false }))
  } catch (e) {
    console.error(e)
    await dispatch(setSending({ isSending: false }))
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchUserExpenses = () => async (dispatch) => {
  try {
    await dispatch(setIsLoading(true));
    const response = await Expenses.get();
    await dispatch(setExpensesData(response.data.data))
    await dispatch(setIsLoading(false));
  } catch (e) {
    console.error(e)
    await dispatch(setIsLoading(false));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchUserExpensesByParam = params => async (dispatch) => {
  try {
    const response = await Expenses.get(params)
    const options = await createDynamicOptions(response.data.data, true)
    await dispatch(setListExpenses(options))
  } catch (e) {
    console.error(e)
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchUserExpensesById = id => async (dispatch) => {
  try {
    const response = await Expenses.show(id);
    await dispatch(setCurrentExpense(response.data.data))
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postExpenses = data => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }))
    let { amount, body, type } = data
    let expense_id = null

    if (typeof body === 'number') {
      expense_id = body
      body = null;
    }

    amount = convertDecimal(amount)

    const response = await Expenses.post({amount, expense_id, body, type})
    await dispatch(fetchUserExpenses())
    await dispatch(fetchUserExpensesByParam({status: 'active'}))
    await dispatch(fetchPercentages())

    if (response.data && response.data.newTasksCount) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }

    await dispatch(setSending({ isSending: false }))
    await dispatch(setIsOpen({ isOpen: false }))
    await dispatch(setIsOpenModal(true))
  } catch (e) {
    console.error(e)
    await dispatch(setSending({ isSending: false }))
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const updateExpenseItem = (id, data) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }))
    let { amount, body, type } = data
    amount = convertDecimal(amount)

    const response = await Expenses.put(id, {expense_id: body, amount, type})
    await dispatch(fetchUserExpenses())
    await dispatch(fetchPercentages())

    if (response.data && response.data.newTasksCount) {
      await dispatch(setNewTasksCount(response.data.newTasksCount))
    }
    await dispatch(setSending({ isSending: false }))
    await dispatch(setIsOpen({isOpen: false}))
    await dispatch(setIsOpenModal(true))
  } catch (e) {
    console.error(e)
    await dispatch(setSending({ isSending: false }))
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const removeExpenseItem = id => async (dispatch) => {
  try {
    await Expenses.del(id)
    await dispatch(fetchUserExpenses())
    await dispatch(fetchUserExpensesByParam({status: 'active'}))
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}


export const fetchUserIncremental = () => async (dispatch) => {
  try {
    const response = await Incremental.get();
    await dispatch(setIncrementalData(response.data.data))
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postUserIncremental = (data, props, id, isEdit) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }))
    let { amount, return_of_cash } = data;
    let response = null
    amount = convertDecimal(amount);
    return_of_cash = return_of_cash ? return_of_cash.slice(0, -1) : ''

    if (isEdit) {
      response = await Incremental.put(id, { ...data, amount, return_of_cash });
    } else {
      response = await Incremental.post({ ...data, amount, return_of_cash });
    }

    await dispatch(fetchUserIncremental());
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
    await dispatch(setIsOpenModal(true));
    await dispatch(setSending({ isSending: false }));
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }))
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchIncrementalById = id => async (dispatch) => {
  try {
    const response = await Incremental.show(id);
    await dispatch(setIncrementalData(response.data.data))
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const removeIncremental = (id, props) => async (dispatch) => {
  try {
    const response = await Incremental.del(id);
    await dispatch(fetchUserIncremental());
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
    await dispatch(() => props.history.push('/incremental-income'));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const deactivateIncremental = (props) => async (dispatch) => {
  try {
    await Incremental.deactivate();
    await dispatch(() => props.history.push("/input"));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchAssets = () => async (dispatch) => {
  try {
    const response = await Assets.get();
    await dispatch(setAssetsData(response.data.data))
  } catch (e) {
    console.error(e)
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchAssetsById = id => async (dispatch) => {
  try {
    const response = await Assets.show(id)
    await dispatch(setCurrentAsset(response.data.data))
  } catch (e) {
    console.error(e)
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const checkAssets = (data, props, id, isEdit) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }))

    let { current_value, value_purchased, estimated_value, down_payment } = data
    current_value   = convertDecimal(current_value)
    value_purchased = convertDecimal(value_purchased)
    estimated_value = convertDecimal(estimated_value)
    down_payment    = down_payment ? down_payment.slice(0, -1) : ''

    await Assets.post({...data, current_value, value_purchased, estimated_value, down_payment});
    await dispatch(setCurrentAsset({...data, current_value, value_purchased, estimated_value, down_payment}));

    await dispatch(setSending({ isSending: false }));
    if(isEdit) {
      await dispatch(() => props.history.push(`/assets/${id}/details`));
    } else {
      await dispatch(() => props.history.push('/assets/create-details'));
    }
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postAssetsDetails = (data, props, id, isEdit) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    let response = null
    let { estimated_value, interest_rate } = data
    estimated_value = convertDecimal(estimated_value)
    interest_rate   = interest_rate ? interest_rate.slice(0, -1) : ''

    if(isEdit) {
      response = await Assets.put(id, {...data, estimated_value, interest_rate});
    } else {
      response = await Assets.postDetails({...data, estimated_value, interest_rate});
    }
    await dispatch(fetchAssets());
    await dispatch(setCurrentAsset(null));
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
    await dispatch(setIsOpen({ isOpen: true }));
    await dispatch(setSending({ isSending: false }));
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const removeAssets = (id, props) => async (dispatch) => {
  try {
    const response = await Assets.del(id);
    await dispatch(fetchAssets());
    await dispatch(setCurrentAsset(null));
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
    await dispatch(() => props.history.push('/assets'));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}


export const fetchUserStocks = () => async (dispatch) => {
  try {
    const response = await Stock.get();
    await dispatch(setStockData(response.data.data))
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const fetchUserStockById = id => async (dispatch) => {
  try {
    const response = await Stock.show(id);
    await dispatch(setStockData(response.data.data))
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const postUserStock = (data, props, id, isEdit) => async (dispatch) => {
  try {
    await dispatch(setSending({ isSending: true }));
    let response = null
    let { exercise_price, fair_value, vesting_date, desired_exercise_date } = data;
    exercise_price = convertDecimal(exercise_price);
    fair_value     = convertDecimal(fair_value);
    vesting_date   = moment(vesting_date).format('YYYY-MM-DD');
    desired_exercise_date = moment(desired_exercise_date).format('YYYY-MM-DD');


    if (isEdit) {
      response = await Stock.put(id, { ...data, exercise_price, fair_value, vesting_date, desired_exercise_date });
    } else {
      response = await Stock.post({ ...data, exercise_price, fair_value, vesting_date, desired_exercise_date });
    }
    await dispatch(fetchUserStocks());
    if (response.data) {
      await dispatch(setNewTasksCount(response.data.newTasksCount));
    }
    await dispatch(setSending({ isSending: false }));
    await dispatch(setIsOpenModal(true));
  } catch (e) {
    console.error(e);
    await dispatch(setSending({ isSending: false }));
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const removeStock = (id, props) => async (dispatch) => {
  try {
    await Stock.del(id);
    await dispatch(fetchUserStocks());
    await dispatch(() => props.history.push('/stocks'));
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status === 401) {
      await dispatch(setIsRelogin({ isRelogin: true }));
    }
  }
}

export const {
  setIncomesData,
  setExpensesData,
  setListExpenses,
  setCurrentExpense,
  setIncrementalData,
  setAssetsData,
  setCurrentAsset,
  setStockData,
  setIsLoading,
  setIsOpenModal
} = financesSlice.actions

export default financesSlice.reducer