import React, { useEffect } from 'react'
import Layout from '../../components/Layout/Layout'
import WidgetProgress from "../../components/Widgets/WidgetProgress";
import WidgetTasks from '../../components/Widgets/WidgetTasks'
import WidgetTasksAnalysis from '../../components/Widgets/WidgetTasksAnalysis'
import WidgetDropbox from '../../components/Widgets/WidgetDropbox'
import WidgetAnalysis from '../../components/Widgets/WidgetAnalysis'
import WidgetOthers from '../../components/Widgets/WidgetOthers'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchTotalPercent,
  fetchTotalTasks,
} from "../../redux/slices/percentagesSlice";
import {
  deleteTask,
  fetchTasks,
  taskDone,
  updateTaskDetails,
  revertTask,
  fetchAnalysisTasks, setTasks, setPagination
} from '../../redux/slices/taskSlice'
import {fetchCategoriesData, fetchStatusData} from '../../redux/slices/optionsSlice'
import {checkingAlertMsg, fetchThresholds, removeAlertMsg} from '../../redux/slices/notificationsSlice'
import { fetchPosts } from '../../redux/slices/blogSlice'
import AlertMessage from '../../components/AlertMessage/AlertMessage'
import { fetchNotifications } from '../../redux/slices/notificationsSlice'
import { fetchProfileAnalysis } from '../../redux/slices/analysisSlice'
import isEmpty from 'lodash/isEmpty'
import { setOpenMenu, fetchTourStatus } from '../../redux/slices/additionalSlice'
import isUndefined from 'lodash/isUndefined'
import { fetchTaskDetails, taskViewed } from '../../redux/slices/taskSlice'
import {Tasks as TasksApi} from "../../api/Api";


const client_id = process.env.REACT_APP_CLIENT_ID
const site_url = process.env.REACT_APP_SITE_URL

const steps = [
  {
    target: '.step-overview',
    content: <>
      <h6>Overview</h6>
      The overview dashboard tells you all you need to know about your financial health and status.  You can launch into the key areas of ValueSoft here: Input, Analyze, Implement, and Educate.
    </>,
    disableBeacon: true,
    placement: 'bottom',
    isFixed: true
  },
  {
    target: '.step-export',
    content: <>
      <h6>Your financial action report </h6>
      Take your ValueSoft action items with you when you're on the go.  Download them, post them on your fridge, so you can have them handy when you're offline.
    </>,
    isFixed: true
  },
  {
    target: '.step-insight',
    content: <>
      <h6>Insight of the week</h6>
      Check this section each week for a new financial planning insight. You'll also find this insight in your weekly status emails.
    </>,
  },
  {
    target: '.step-progress',
    content: <>
      <h6>Your progress</h6>
      The first step is to input your data. Your information will then be run through the ValueSoft financial planning engine which then drives the action items you need to take.
    </>,
    placement: 'bottom'
  },
  {
    target: '.step-implement',
    content: <>
      <h6>Implement</h6>
      ValueSoft provides comprehensive financial planning recommendations. Think about this as your personal financial planning assistant!
    </>
  },
  {
    target: '.step-task-analysis',
    content: <>
      <h6>Task analysis</h6>
      You'll have access to all your tasks here.  Think of these as your to-do list based on your input.  The more tasks you have, the more work you have to do to get your financial health in good shape!  As you make progress and complete your tasks, your task workload will come down and the healthier you'll become!
    </>,
  },
  {
    target: '.step-cloud',
    content: <>
      <h6>Document Management</h6>
      Upload your most important financial documentation so you can access it anywhere.  You never know when you'll need a copy of your drivers license or birth certificate, for example!
    </>
  },
  {
    target: '.step-analysis',
    content: <>
      <h6>Analysis</h6>
      After you’re done inputting your information, head to the 'Analyze' step to double-check your work and compare your financial information against other users.
    </>,
  },
  {
    target: '.step-educate',
    content: <>
      <h6>Educate</h6>
      This is your standalone library of financial calculators and information to get smarter on financial topics and scenarios.
    </>,
  },
]

const Overview = ({ props }) => {
  const dispatch = useDispatch()
  const secondToken = localStorage.token_admin;
  const totalPercent = useSelector((state) => state.percentages.total);
  const { tasks, taskLoading, analysis } = useSelector(state => state.task)
  const userId = useSelector(state => state.user.user.id)
  const { company_id } = useSelector((state) => state.user.user);
  const is_new_year = useSelector(state => state.user.user.is_new_year)
  const dropbox_api_token = useSelector(state => state.user.user.dropbox_api_token)
  const { categories, priorities, status } = useSelector(state => state.options)
  const { alertMessage, animate } = useSelector(state => state.notifications)
  const { posts } = useSelector(state => state.blog.posts)
  const { playTour, tours } = useSelector(state => state.additional)
  const isBasic = useSelector(state => state.user.user.plan_type === 1)
  const thresholds = useSelector(state => state.notifications.thresholds)
  const companyPermissions = useSelector((state) => state.companies.company.info.permissions);
  const totalTasksPercent = useSelector(
    (state) => state.percentages.totalTasks
  );


  const {
    loading,
    netWorth,
    totalExpense,
    totalIncome } = useSelector(state => state.analysis)

  useEffect(() => {
    async function fetch() {  
      await dispatch(fetchTotalPercent(isBasic))
      Promise.all([
        dispatch(fetchTotalTasks()),
        dispatch(fetchTourStatus()),
        dispatch(fetchThresholds(company_id)),
        dispatch(fetchTasks(null, {
          limit: 4
        }, props)),
        dispatch(fetchPosts(null, {
          start: 0,
          limit: 3
        })),
        dispatch(fetchAnalysisTasks()),
        dispatch(checkingAlertMsg()),
        dispatch(fetchStatusData()),
        dispatch(fetchCategoriesData()),
        dispatch(fetchProfileAnalysis('net-worth')),
        dispatch(fetchProfileAnalysis('total-expense')),
        dispatch(fetchProfileAnalysis('total-income'))
      ])
    }

    if ((userId || secondToken) && company_id) {
      fetch()
    }

    // eslint-disable-next-line
  }, [userId, secondToken, company_id])

  const onCloseEditDrawer = async (status) => {
    if (status === 'New' || status === 'Updated') {
      const response = await TasksApi.get();
      await dispatch(setTasks(response.data.data));
      await dispatch(setPagination({...response.data.meta, ...response.data.links}));
    }
  };

  const removeTask = async id => {
    await dispatch(deleteTask(null, id));
    await dispatch(fetchTasks(null, {
      limit: 4
    }, props));
    await dispatch(fetchAnalysisTasks())
    await dispatch(fetchTotalTasks())
  }

  const updateTask = async (id, values) => {
    await dispatch(updateTaskDetails(null, id, values));
    await dispatch(fetchTasks(null, {
      limit: 4
    }, props));
    await dispatch(fetchAnalysisTasks())
    await dispatch(fetchTotalTasks())
  }

  const closeTask = async id => {
    await dispatch(taskDone(id))
    await dispatch(fetchTasks(null, {
      limit: 4
    }, props));
    await dispatch(fetchAnalysisTasks())
    await dispatch(fetchNotifications())
    await dispatch(fetchTotalTasks())
  }

  const revert = id => {
    dispatch(revertTask(id, {
      limit: 4
    }))
  }

  const removeAlert = id => {
    dispatch(removeAlertMsg(id))
  }

  const createArray = (...args) => {
    const array  = []

    args.map(item => !isEmpty(item.data) && array.push({
      data: item.data,
      title: item.title,
      split: item.split || false
    }))

    return array
  }

  const createProgressArray = (...args) => {
    const array = [];

    args.map(
      (item) =>
        array.push({
          percent: item.percent,
          title: item.title,
          content: item.content,
          button: item.button,
          path: item.path,
          tree: item.tree || false
        })
    );

    return array;
  };

  const handlerTour = () => dispatch(setOpenMenu(false))

  return (
    <Layout
      steps={steps}
      tourStatus={tours && !tours.dashboard}
      tourParam="dashboard"
      handlersOnMobile={[
        {
          step: 1,
          func: handlerTour,
        },
      ]}
    >
      {playTour && isUndefined(alertMessage) ? (
        <AlertMessage id={20} text="Some text" className="step-insight" />
      ) : (
        !is_new_year &&
        alertMessage && (
          <AlertMessage
            text={alertMessage.insight}
            id={alertMessage.id}
            remove={removeAlert}
            animate={animate}
            className="step-insight"
          />
        )
      )}

      <h1 className="overview__title">
        <span>Overview</span>
      </h1>
      <div className="overview">
        <div className="overview__columns">
          <div className="overview__column overview__column--slide">
            <WidgetProgress
              totalPercent={totalPercent}
              items={createProgressArray(
                {
                  percent: totalPercent ? totalPercent : "0",
                  title: "Your input progress",
                  content:
                    "This tree represents your input progress.  As you nurture it with more information, it  provides you with a more comprehensive financial plan. Nurture the tree and it nurtures back.",
                  path: "/input",
                  button: "Input",
                  tree: true,
                },
                {
                  percent: totalTasksPercent ? totalTasksPercent : "0",
                  title: "Your task progress",
                  content:
                    "This acorn represents your task progress. The more tasks you complete, the more improvements you will make to your financial health.",
                  path: "/tasks",
                  button: "Tasks",
                }
              )}
              addClassName="step-progress"
            />
          </div>
          <div className="overview__column">
            <WidgetTasks
              tasks={tasks && tasks.slice(0, 4)}
              loading={taskLoading}
              planning_types={categories}
              priorities={priorities}
              removeTask={removeTask}
              updateTask={updateTask}
              closeTask={closeTask}
              revertTask={revert}
              status={status}
              addClassName="step-implement"
              showDetails={async (id, status) => {
                await dispatch(fetchTaskDetails(null, id));
                if (status === 'New' || status === 'Updated') {
                  await dispatch(taskViewed(id, {}, false));
                }
              }}
              onCloseDrawer={onCloseEditDrawer}
            />
            <WidgetTasksAnalysis
              addClassName="step-task-analysis"
              openTasks={analysis && analysis.openCount}
              agingTasks={analysis && analysis.agingTasks}
              totalTasks={analysis && analysis.totalCount}
              limits={
                thresholds &&
                !isEmpty(thresholds) && [
                  thresholds.superb_number_of_tasks,
                  thresholds.great_number_of_tasks,
                  thresholds.ok_to_great_number_of_tasks,
                  thresholds.ok_number_of_tasks,
                  thresholds.ok_to_poor_number_of_tasks,
                  thresholds.poor_number_of_tasks,
                  thresholds.really_poor_number_of_tasks,
                ]
              }
            />
            <WidgetDropbox
              isActivate={dropbox_api_token}
              className="step-cloud"
              client_id={client_id}
              site_url={site_url}
            />
          </div>
        </div>

        <div className="overview__columns">
          <div className="overview__column">
            <WidgetAnalysis
              addClassName="step-analysis"
              loading={loading}
              items={createArray(
                { data: netWorth, title: 'Net worth' },
                { data: totalIncome, title: 'Total household income', split: true },
                { data: totalExpense, title: 'Total monthly expenses' }
              )}
            />
          </div>
          {(companyPermissions.blog || companyPermissions.calculators) && (
            <div className="overview__column overview__column--small">
              <WidgetOthers
                isCalculators={companyPermissions.calculators}
                isBlog={companyPermissions.blog}
                items={posts}
              />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Overview;
