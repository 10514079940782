import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Drawer from './TaskDrawer'
import Priority from '../shared/Priority/Priority'
import Icon from '../../assets/images/icons/drop.svg'
import SVGIcon from '../shared/SVGIcon/SVGIcon'
import More from '../../assets/images/icons/more.svg'
import { setMode } from '../../redux/slices/modeSlice'
import { setMsg } from '../../redux/slices/taskSlice'
import ModalBox from '../../components/shared/Modal/Modal'


const TaskCard = ({
  id,
  title,
  sub_status,
  status,
  category,
  priority,
  statusList,
  categoryList,
  priorityList,
  content,
  icon,
  handleClick,
  removeTask,
  updateTask,
  createTask,
  closeTask,
  revertTask,
  showDetails,
  onCloseDrawer = () => {}
}) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [isOpen, setOpen] = useState(false)
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const [loadingTask, setLoadingTask] = useState(false)
  const popup = useRef()
  const { current } = useSelector(state => state.task)
  const mode = useSelector(state => state.mode.mode)
  const [activeId, setActiveId] = useState('')
  
  const onToggle = () => setOpen(!isOpen);

  useEffect(() => {
    if(location.state && location.state.taskId) {
      setActiveId(location.state.taskId)
    }
    // eslint-disable-next-line
  }, [location.state])

  useEffect(() => {
    if(activeId === id){
      openDrawer('view')
    }
    // eslint-disable-next-line
  }, [activeId])

  useEffect(() => {
      const listener = event => {
        if (!popup.current || popup.current.contains(event.target)) {
          return
        }

        setOpen(false)
      };

      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)

      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    }, [popup])

  const openDrawer = async (mode) => {
    await setLoadingTask(true)
    await dispatch(setMode(mode))
    await setModalOpen(true)
    await showDetails(id)
    await setLoadingTask(false)
    await setOpen(false)
  }

  const closeDrawer = () => {
    setModalOpen(false)
    dispatch(setMode(null))
    dispatch(setMsg(''))
    onCloseDrawer(sub_status);
    location.state = null;
    setActiveId('')
  }

  return (
    <>
      <div className="task-card">
        <div className={`task-card__menu${isOpen ? " task-card__menu--active" : ""}`}>
          <button onClick={onToggle}>
            <SVGIcon icon={More} />
          </button>
          <div
            ref={popup}
            className={`task-card__menu-drop${
              isOpen ? " task-card__menu-drop--open" : ""
            }`}
          >
            <ul>
              <li>
                <button onClick={() => openDrawer("edit")}>Edit</button>
              </li>
              <li>
                <button onClick={() => openDrawer("reminder")}>Reminder</button>
              </li>
              <li>
                <button onClick={() => openDrawer("mail")}>Send Mail</button>
              </li>
              {status !== "Closed" && <li>
                <button onClick={() => closeTask(id)}>Mark as done</button>
              </li>}
              <li>
                <button onClick={() => setIsOpenRemoveModal(true)}>
                  Remove
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="task-card__inner" onClick={() => openDrawer("view")}>
          <div className="task-card__top">
            {status === "Closed" ? (
              <div className="task-card__status task-card__status--closed">
                Closed
              </div>
            ) : (
              <div
                className={`task-card__status task-card__status--${sub_status.toLowerCase()}`}
              >
                {sub_status && sub_status !== "Viewed" ? sub_status : ""}
              </div>
            )}
          </div>
          <div
            className={`task-card__holder ${
              sub_status === "New" ? "task-card__holder--alt" : ""
            }`}
          >
            <div className="task-card__title">
              <div className="task-card__image">
                {icon && <img src={icon} alt={title} />}
              </div>
              <div>
                {sub_status && sub_status !== "New" && (
                  <div className="task-card__substatus">{status}</div>
                )}
                <h5>{title}</h5>
              </div>
            </div>
            <div className="task-card__info">
              <div className="task-card__category">{category}</div>
              <div className="task-card__priority">
                <Priority option={priority} />
                <div className="task-card__priority-text">
                  <SVGIcon icon={Icon} color="#0F9AFF" />
                  {priority}
                </div>
              </div>
            </div>
            <div
              className="task-card__content"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
        </div>
      </div>
      <ModalBox
        small
        inline
        open={isOpenRemoveModal}
        close={() => setIsOpenRemoveModal(false)}
        onClick={() => {
          removeTask(id);
          setIsOpenRemoveModal(false);
        }}
        button="Remove"
        closeButton="No"
        title="Are you sure you want to delete this task?"
        children={
          <p className="form-group">
            Deleting a task will make it reappear later if you update your
            inputs.  Otherwise, if you do not want to leverage this task
            whatsoever despite its relevance to your financial plan, then you're
            better off marking the task as Done to avoid it showing up again in
            the future.
          </p>
        }
      />
      <Drawer
        loadingTask={loadingTask}
        isOpen={isModalOpen}
        onClose={closeDrawer}
        id={id}
        statusTask={status}
        priorities={priorityList}
        categories={categoryList}
        status={statusList}
        removeTask={removeTask}
        updateTask={updateTask}
        createTask={createTask}
        closeTask={closeTask}
        revertTask={revertTask}
        data={current || null}
        mode={mode}
        isCreateReminder
        titleReminder="Prepare your calendar reminder"
      />
    </>
  );
}

TaskCard.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  status: PropTypes.string,
  category: PropTypes.string,
  priority: PropTypes.string,
  content: PropTypes.string,
  removeTask: PropTypes.func,
  updateTask: PropTypes.func,
  createTask: PropTypes.func,
  closeTask: PropTypes.func,
  handleClick: PropTypes.func,
  statusList: PropTypes.array,
  categoryList: PropTypes.array,
  priorityList: PropTypes.array
}

export default TaskCard



