import React, { useEffect } from 'react'
import { Form, Formik } from 'formik'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../../components/Layout/Layout'
import { BackButton, DeleteButton } from '../../../components/shared/Buttons'
import PageTitle from '../../../components/PageTitle/PageTitle'
import LayoutForm from '../../../components/Layout/LayoutForm'
import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import {FormGroup, InputCurrency, FormSelect, Input, InputPercent} from '../../../components/shared/Form'
import { fetchAssetsById, checkAssets, removeAssets } from '../../../redux/slices/financesSlice'
import { setIsOpen } from '../../../redux/slices/modalSlice'
import ModalBox from '../../../components/shared/Modal/Modal'
import typeAssets from '../../../Data/typeAssets'
import Illustration from '../../../assets/images/illustrations/Tree-segment_finances-min.png'
import { assetsSchema } from '../../../utils/validationFinances'
import Priority from '../../../components/shared/Priority/Priority'

const Asset = ({ props, isEdit }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(state => state.modal.isOpen);
  const userId = useSelector(state => state.user.user.id);
  const sending = useSelector(state => state.sending.isSending);
  const currentAsset = useSelector(state => state.finances.currentAsset);
  const { id }  = useParams();

  useEffect(() => {
    async function fetch() {
      await dispatch(fetchAssetsById(id));
    }

    if (userId && id) {
      fetch()
    }

    // eslint-disable-next-line
  }, [userId, id])

  const deleteAsset = () => {
    dispatch(removeAssets(id, props));
    dispatch(setIsOpen({isOpen: false}))
  }

  const onSubmit = values => {
    dispatch(checkAssets(values, props, id, isEdit))
  }

  const defaultValues = {
    liquidated_asset: false,
    name: '',
    type: '',
    age_when_purchased: null,
    value_purchased: '',
    down_payment: null,
    category: '',
    current_value: null,
    interest_rate: null,
    financing_term: null,
    useful_life: null,
    age_when_liquidated: null,
    estimated_value: null,
    additional_info: ''
  }


  return (
    <Layout>
      <LayoutForm small illustration={Illustration}>
        <BackButton title="Back to Fixed Assets" />
        {isEdit && <DeleteButton className="delete-button--position" onClick={() => dispatch(setIsOpen({isOpen: true}))} />}
        <PageTitle
          title={isEdit && currentAsset && currentAsset.name ? currentAsset.name : 'Fixed assets'}
          content="Tell us about any fixed assets or outstanding loans you own."
          status={isEdit ? 100 : '0'}
          info={
            <>
              <h6>Current Fixed Assets</h6>
              <Priority option="Very High" />
              <div>Enter fixed asset information related to your primary home, appreciable fixed assets and depreciable fixed assets.  This information will be used in calculating your overall net worth, cash flow health and overall financial situation.  Since fixed assets play a big role in net worth and cash flow, it's important to accurately reflect their details, such as their appreciation and depreciation rates, financing rates and other important factors.</div>
            </>
          }
        />
        <div className="layout-form__content-holder">
          <Formik
            enableReinitialize
            initialValues={isEdit && currentAsset ? {...currentAsset} : defaultValues}
            validationSchema={assetsSchema}
            onSubmit={onSubmit}
          >
            {({ errors, handleChange, handleBlur, values, touched, setFieldValue }) => (
              <Form>
                <FormGroup name="name" labelText="Name">
                  <Input
                    name="name"
                    placeholder="Enter Name"
                    type="text"
                    onChange={handleChange}
                    value={values.name || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.name && touched.name}
                  />
                  {touched.name && errors.name && <span className="form__msg-error">{errors.name}</span>}
                </FormGroup>
                <FormGroup name="type" labelText="Type of asset / loan">
                  <FormSelect
                    options={typeAssets}
                    id="type"
                    value={values.type || ''}
                    name="type"
                    onChange={(e) => {
                      setFieldValue(e.target.name, e.target.value)
                    }}
                    placeholder="Select"
                    hasError={!!errors.type && touched.type}
                  />
                  {touched.type && errors.type && <span className="form__msg-error">{errors.type}</span>}
                </FormGroup>
                <FormGroup
                  name="age_when_purchased"
                  labelText="Your age when purchased"
                >
                  <Input
                    name="age_when_purchased"
                    placeholder="Type an age"
                    type="number"
                    onChange={handleChange}
                    value={values.age_when_purchased || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.age_when_purchased && touched.age_when_purchased}
                  />
                  {touched.age_when_purchased && errors.age_when_purchased && <span className="form__msg-error">{errors.age_when_purchased}</span>}
                </FormGroup>
                <FormGroup
                  name="value_purchased"
                  labelText="Value when purchased"
                >
                  <InputCurrency
                    placeholder="Value"
                    name="value_purchased"
                    onChange={handleChange}
                    value={values.value_purchased || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.value_purchased && touched.value_purchased}
                  />
                  {touched.value_purchased && errors.value_purchased && <span className="form__msg-error">{errors.value_purchased}</span>}
                </FormGroup>
                <FormGroup
                  name="down_payment"
                  labelText="Downpayment %"
                >
                  <InputPercent
                    placeholder="Type %"
                    name="down_payment"
                    onChange={handleChange}
                    value={values.down_payment || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.down_payment && touched.down_payment}
                  />
                  {touched.down_payment && errors.down_payment && <span className="form__msg-error">{errors.down_payment}</span>}
                </FormGroup>
                <FormGroup
                  name="category"
                  labelText="Category"
                  info={{
                    content:
                      <>
                        <h6>Fixed Asset Category</h6>
                        <Priority option="High" />
                        <div>If you expect your asset to increase in value over time (such as a house), then enter Appreciating.  If you expect it to decrease in value (such as a car), select Depreciating.  For loans, please select Depreciating since principal-payment loan balances are reduced each time you make a payment.</div>
                      </>
                  }}
                >
                  <RadioGroup
                    name="category"
                    value={values.category || ''}
                    onChange={handleChange}
                    className="radio-list-inline"
                  >
                    <FormControlLabel value="appreciating" control={<Radio />} label="Appreciating" />
                    <FormControlLabel value="depreciating" control={<Radio />} label="Depreciating" />
                  </RadioGroup>
                  {touched.category && errors.category && <span className="form__msg-error">{errors.category}</span>}
                </FormGroup>
                <FormGroup
                  name="current_value"
                  labelText="Current value (if asset)"
                >
                  <InputCurrency
                    placeholder="Value"
                    name="current_value"
                    onChange={handleChange}
                    value={values.current_value || ''}
                    onBlur={handleBlur}
                    hasError={!!errors.current_value && touched.current_value}
                  />
                  {touched.current_value && errors.current_value && <span className="form__msg-error">{errors.current_value}</span>}
                </FormGroup>
                <div className="form__button">
                  <Button variant="contained" size="large" color="primary" type="submit" disabled={sending}>
                    Next
                    {sending && <span className="spinner" />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <ModalBox
          small
          inline
          open={isOpen}
          title="Are you sure?"
          button="Remove Item"
          closeButton="Cancel"
          onClick={deleteAsset}
          close={() => dispatch(setIsOpen({isOpen: false}))}
        />
      </LayoutForm>
    </Layout>
  );
};

export default Asset;
